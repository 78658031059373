import { showAudioModal } from "components/modules/conversations/components/messages-list/components/show-audio-modal";
import { showVideoModal } from "components/modules/conversations/components/messages-list/components/show-video-modal";
import _ from "lodash";
import { AddEllipsis } from "utils/add-ellipsis";
import { GetCCFilename } from "utils/get-cc-filename";
import { ExtensionsFileMap } from "utils/get-extension-type";
import { ImageX } from "../image";

export const AttachmentsViewer = ({
  attachments,
}: {
  attachments: string[];
}) => {
  return (
    <>
      {attachments.length > 0 && (
        <>
          <div className="file-attachments">
            {attachments.map((fileUrl, index) => (
              <div className="attachment" key={index.toString()}>
                {isImageExtension(fileUrl) && (
                  <ImageX
                    src={fileUrl}
                    width={"auto"}
                    style={{
                      maxHeight: 120,
                      height: "100%",
                      width: "auto",
                      minHeight: 120,
                    }}
                    className="flex justify-center items-center"
                    // height={120}
                  />
                )}
                {!isImageExtension(fileUrl) && (
                  <div
                    className="flex justify-center items-center flex-col"
                    onClick={() => {
                      if (isPDFExtension(fileUrl)) {
                        openUrlInNewWindow(fileUrl);
                        // showPDFDrawer(fileUrl);
                      } else if (isAudioExtension(fileUrl)) {
                        showAudioModal(fileUrl);
                      } else if (isVideoExtension(fileUrl)) {
                        showVideoModal(fileUrl);
                      } else {
                        openUrlInNewWindow(fileUrl);
                      }
                      // else
                    }}
                  >
                    <ExtensionIcon url={fileUrl} />
                    <div className="text">
                      {AddEllipsis(GetCCFilename(fileUrl), 20)}
                    </div>
                    {}
                    <div className="hover">
                      <i className="ri-eye-line text-xl"></i>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

const ExtensionIcon = ({ url, type }: { url: string; type?: string }) => {
  const extension = _.last(GetCCFilename(url).split(".")) || "unknown";

  const iconMaps = {
    // 'graphics_editor',
    video: <i className="ri-vidicon-fill text-2xl"></i>,
    audio: <i className="ri-file-music-fill text-2xl"></i>,
    image: <i className="ri-image-2-fill text-2xl"></i>,
    spreadsheet: <i className="ri-file-excel-2-fill text-2xl"></i>,
    // 'project_management',
    presentation: <i className="ri-file-ppt-2-fill text-2xl"></i>,
    // 'diagramming',
    word_processing: <i className="ri-file-word-fill text-2xl"></i>,
    document: <i className="ri-file-pdf-fill text-2xl"></i>,
  };
  if (type) {
    if (type === "application/pdf") {
      type = "document";
    }
    if (iconMaps[type]) {
      return iconMaps[type];
    }
    const matchedType = Object.keys(iconMaps).filter(
      (fileType) => type?.toLowerCase().includes(fileType),
    )[0];

    if (matchedType) {
      return iconMaps[matchedType];
    }
  }

  const icon = iconMaps[ExtensionsFileMap[extension.toLowerCase()]];

  if (icon) {
    return icon;
  }

  return <i className="ri-attachment-line text-2xl"></i>;
};

const isImageExtension = (url) => {
  const extension = _.last(GetCCFilename(url).split(".")) || "unknown";
  return ExtensionsFileMap[extension.toLowerCase()] === "image";
};
const isPDFExtension = (url) => {
  const extension = _.last(GetCCFilename(url).split(".")) || "unknown";
  return extension.toLowerCase() === "pdf";
};
const isAudioExtension = (url) => {
  const extension = _.last(GetCCFilename(url).split(".")) || "unknown";
  return ExtensionsFileMap[extension.toLowerCase()] === "audio";
};
const isVideoExtension = (url) => {
  const extension = _.last(GetCCFilename(url).split(".")) || "unknown";
  return ExtensionsFileMap[extension.toLowerCase()] === "video";
};

const openUrlInNewWindow = (url: string) => {
  const win = window.open(url, "_blank");
  win && win.focus();
};
