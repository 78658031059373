import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "store/modules/users/users.selectors";

export const useUserRole = () => {
  const currentUser = useSelector(selectCurrentUser);
  const userRole = useMemo(() => {
    const currentUserRole = getRoleFromPermission(
      currentUser?.permissions || [],
    );
    return currentUserRole;
  }, [currentUser?.permissions]);

  return userRole as "Owner" | "Manager" | "Team Member" | "CUSTOM";
};
