import { Alert, Button, Form, Input, Modal, Tag } from "antd";
import { useMemo, useState } from "react";
import { getTokens } from "utils/extract-tokens";

const AvailableMagicAssistantTokens = [
  "MATCHED_CONTEXT",
  "CUSTOMER_QUERY",
  "WORKSPACE_NAME",
  "CHAT_HISTORY",
];

const samplePrompt = `You are a very enthusiastic customer support assistant of {WORKSPACE_NAME} who loves to help people! Given the following sections from the knowledge base, answer the question using only that information, outputted in markdown format. Try to be concise.  If you are unsure and the answer is not explicitly written in the documentation, say "Sorry, I don't know how to help with that.".
Context sections:
{MATCHED_CONTEXT}

Question: """
{CUSTOMER_QUERY}
"""

Answer as markdown (including related code snippets if necessary. If code snippet is not available or not necessary, don't give one. If there is an image reference available, provide that too):
`;

const AvailableMagicAssistantTokensDescriptions = {
  MATCHED_CONTEXT:
    "Relevant Parts of the documents from KnowledgeKit will be included here",
  CUSTOMER_QUERY: "Last message of the customer will be included here",
  WORKSPACE_NAME: "Your workspace name will be included here",
  CHAT_HISTORY: "Entire Chat History of the customer will be included here",
};

export const MagicAssistantPromptWriter = () => {
  const [value, setValue] = useState("");

  const capturedTokens = useMemo(() => {
    const tokens = getTokens(value);
    const validTokens = tokens.filter((token) =>
      AvailableMagicAssistantTokens.includes(token),
    );
    const invalidTokens = tokens.filter(
      (token) => !AvailableMagicAssistantTokens.includes(token),
    );
    return { validTokens, invalidTokens, all: tokens };
  }, [value]);
  return (
    <>
      <Form.Item
        name={[
          "appData",
          "KNOWLEDGE_BASE",
          "magicAssistantConfig",
          "customPrompt",
        ]}
        label="Prompt"
        help={
          <Button
            type="link"
            onClick={() => {
              Modal.info({
                title: "Sample Prompt",
                content: (
                  <div
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {samplePrompt}
                  </div>
                ),
              });
            }}
          >
            View Sample Prompt
          </Button>
        }
      >
        <Input.TextArea
          placeholder="Enter your prompt here"
          autoSize
          rows={12}
        />
      </Form.Item>

      <table className="simple-table-style w-full">
        <thead>
          <tr>
            <th className="font-bold">Available Token</th>
            <th className="font-bold">Description</th>
          </tr>
        </thead>
        <tbody>
          {AvailableMagicAssistantTokens.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <Tag
                  // onClick={() => {
                  //   setNode({
                  //     text: `${data.text} {${item.token}}`,
                  //   });
                  //   modalRef.destroy();
                  // }}
                  // className="cursor-pointer"
                  >
                    {item}
                  </Tag>
                </td>
                <td>{AvailableMagicAssistantTokensDescriptions[item]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {capturedTokens.invalidTokens.length !== 0 && (
        <div className="token-container">
          <div className="font-bold my">Invalid Tokens:</div>
          {capturedTokens.invalidTokens.map((item, index) => (
            <Tag key={index.toString()}>{item}</Tag>
          ))}
        </div>
      )}

      <Alert
        type="info"
        message="Non-English Language is partially supported now. (Default Replies are not translated yet) We will be improving the support over the time"
        className="my-4"
      />
      <Alert
        type="info"
        message="If you have a specific use case that you are trying to achieve and you are not sure how to accomplish it, speak to us via the live chat. We are also looking for more use cases."
        className="my-4"
      />
    </>
  );
};
