import LeftOutlined from "@ant-design/icons/LeftOutlined";
import MinusOutlined from "@ant-design/icons/MinusOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import RightOutlined from "@ant-design/icons/RightOutlined";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import {
  iChatFAQ,
  iChatFAQCollection,
} from "@sdk/chat-widgets/chat-widgets.models";
import classnames from "classnames";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { DeepPartial } from "redux";
import { mergeDeep } from "utils/merge-deep";
import { StyledWidgetContainer } from "./components/styled-widget-container";
import { defaultWidgetPreviewData } from "./default-widget-data";

export const WidgetPreviewSearchArticles = ({
  color,
  configData,
}: {
  color?: string;
  configData: DeepPartial<typeof defaultWidgetPreviewData.SEARCH_ARTICLES>;
}) => {
  const data: DeepPartial<typeof defaultWidgetPreviewData.SEARCH_ARTICLES> = useMemo(
    () =>
      mergeDeep({ ...defaultWidgetPreviewData.SEARCH_ARTICLES }, configData),
    [configData]
  );

  const [activeKeys, setActiveKeys] = useState([0] as number[]);
  const onClickCollapse = (key) => {
    const initialActiveKeys = [...activeKeys];
    if (activeKeys.includes(key)) {
      const indexOfElement = initialActiveKeys.indexOf(key);
      initialActiveKeys.splice(indexOfElement, 1);
    } else {
      initialActiveKeys.push(key);
    }
    setActiveKeys(initialActiveKeys);
  };

  const { allFaqs, allCollections } = useMemo(() => {
    const allCollections = (configData?.COLLECTIONS ||
      []) as iChatFAQCollection[];

    const allFaqs = _.flatten(
      (allCollections || []).map((collection) => collection?.faqList || [])
    ) as iChatFAQ[];

    return { allFaqs, allCollections };
  }, [configData?.COLLECTIONS]);

  return (
    <StyledWidgetContainer color={color}>
      <div className="h-full w-full rounded-lg main-header">
        <div className="page-overflow-container primary-gradient-bg-2 h-full w-full text-white top-0 z-0 main-header p-2 items-center rounded-lg">
          <div className="header flex flex-row  items-center text-white ">
            <div className="back-button pointer  cursor-pointer rounded-lg p-4">
              <div className="icon-container">
                <LeftOutlined />
              </div>
            </div>
            <div className="title flex-1 text-2xl font-semibold pl-2 ml-3">
              {data.SEARCH_ARTICLE_TITLE}
            </div>
          </div>
        </div>

        <div className="w-full bg-white text-black rounded-lg body-box">
          <div className="p-2">
            <div
              className="page-overflow-container h-full w-full flex-1 p-2 bg-white relative"
              style={{ borderRadius: "8px 8px 0px 0px" }}
            >
              <div className="flex justify-between border-b pb-3 pt-1 pl-2">
                <div className="flex-grow">
                  <input
                    placeholder="Type your questions"
                    className="w-full p-2 outline-none"
                  />
                </div>
                <div className="flex items-center cursor-pointer">
                  <div className="icon-container mr-2">
                    <SearchOutlined style={{ fontSize: "1.5rem" }} />
                  </div>
                </div>
              </div>
              <div className="animate animate fade-in-up">
                <div className="text-lg font-semibold mt-6 mb-3">
                  {data.SUBTITLE}{" "}
                </div>

                {(allCollections || []).length === 0 && (
                  <div className="border border-dashed border-gray-400 p-8 text-center text-gray-600 my-8 rounded-md">
                    You don't have FAQs set up
                  </div>
                )}
                {(allCollections || []).map((collection, index) => (
                  <React.Fragment key={index.toString()}>
                    <div
                      key={index}
                      className={classnames(
                        activeKeys.includes(index)
                          ? "primary-bg text-white"
                          : "article-collection-heading",
                        " mb-2 flex justify-between items-center rounded-lg cursor-pointer font-semibold"
                      )}
                      style={{ height: "44px" }}
                      onClick={() => {
                        onClickCollapse(index);
                      }}
                    >
                      <div className="ml-4">{collection!.label}</div>
                      <div className="mr-3 ">
                        {activeKeys.includes(index) ? (
                          <MinusOutlined className="flex items-center text-lg text-white" />
                        ) : (
                          <PlusOutlined className="flex items-center text-lg" />
                        )}
                      </div>
                    </div>
                    <div
                      className={classnames(
                        activeKeys.includes(index) ? "" : "hidden",
                        "animate fade-in-up"
                      )}
                    >
                      {collection.faqList.map((article, faqIndex) => {
                        return (
                          <div
                            key={(article as any).id!}
                            className="flex flex-row pointer hover:bg-gray-100 p-4 px-8 cursor-pointer justify-between items-center my-2"
                            onClick={() => {
                              // Do Nothing
                            }}
                          >
                            <div className="title">
                              {(article as any).question}
                            </div>
                            <RightOutlined
                              style={{ fontSize: "1.2rem", fontWeight: "900" }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledWidgetContainer>
  );
};
