import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iCCMacro } from "@sdk/user-management/user-management.models";
import { Alert, Button, FormInstance, Select, Space, Tag } from "antd";
import _ from "lodash";
import { MyManagePresets } from "modal-registry";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Mention, MentionsInput } from "react-mentions";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { checkText } from "smile2emoji";
import { setEnteredMessages } from "store/modules/ui-state/ui-state.actions";
import { selectEnteredMessage } from "store/modules/ui-state/ui-state.selectors";
import { AddEllipsis } from "utils/add-ellipsis";
import { EmojiPicker } from "./emoji-picker";
import "./reply-input.scss";
import { iSlashCommand } from "./slash-command";

// export const openMessagePresetsModal = (dispatch: (action: any) => any) => {
//   const newPath: any = {
//     pathname: BrowserHistory.location.pathname,
//     search: qs.stringify({
//       ...qs.parse(BrowserHistory.location.search),
//       "message-presets-modal": "YES",
//     }),
//   };
//   dispatch(push(newPath));
//   // Todo
// };

function moveCursorToEnd(el) {
  setTimeout(() => {
    el.focus();
    if (typeof el.selectionStart == "number") {
      el.selectionStart = el.selectionEnd = el.value.length;
    } else if (typeof el.createTextRange != "undefined") {
      var range = el.createTextRange();
      range.collapse(false);
      range.select();
    }
  }, 50);
}

const SlashCommandRenderer = (slashCommand: iSlashCommand) => {
  return (
    <div className="flex flex-row">
      <div className="icon px-4">
        {slashCommand.id === "NOT_AVAILABLE" && (
          <i className="ri-error-warning-fill"></i>
        )}
        {slashCommand.type === "MESSAGE_PRESET" && (
          <i className="ri-message-2-line"></i>
        )}
      </div>
      <div className="label font-bold  px-2">{slashCommand.label}</div>
      <div className="description text-gray-600 flex-1  px-2">
        {AddEllipsis(slashCommand.description, 30)}
      </div>
      <div className="right">
        {slashCommand.type === "MESSAGE_PRESET" &&
          slashCommand.metaData.folderName && (
            <Tag>{slashCommand.metaData.folderName}</Tag>
          )}
      </div>
    </div>
  );
};

const MacroCommandRenderer = (macro: iCCMacro) => {
  return (
    <div className="flex flex-row">
      <div className="icon px-4">
        <i className="ri-flashlight-fill"></i>
      </div>
      <div className="label font-bold  px-2">{macro.label}</div>
      <div className="description text-gray-600 flex-1  px-2">
        {AddEllipsis(macro.description, 30)}
      </div>
      <div className="right">
        <Tag>{macro.actions.length} Actions</Tag>
      </div>
    </div>
  );
};

export const ReplyInputFirstTab = forwardRef(
  (
    {
      defaultSelectedFromId,
      slashCommands: _slashCommands,
      macroCommands = [],
      messageCharLimitCalculator,
      enableEmojis,
      userList,
      sendMessageOnEnter,
      onSend,
      inputRef,
      requiresFromSelection,
      messageInputPlaceholder,
      onChange,
      fromIds,
      disableSenderSelection,
      sendMessageInputValue,
      setSendMessageInputValue,
      setFileList,
      fileList,
      isFileUploading,
      fileUploadBox,
      fileUploadInput,
      fileUploadIcon,
      onScheduleMessage: _onScheduleMessage,
      messageValidator,
      conversationId,
      tokenReplacer,
      onSlashCommandExecuted,
      onMacroCommandExecuted,
      hideHeader,
      actionsForm,
    }: {
      defaultSelectedFromId: string;
      slashCommands: iSlashCommand[];
      macroCommands?: iCCMacro[];
      messageCharLimitCalculator: (message: string) => {
        current: number;
        limit: number;
      };
      enableEmojis;
      userList: { id: string; display: string }[];
      sendMessageOnEnter?: boolean;
      onSend: (req: {
        text: string;
        fromId: string;
        attachments: string[];
      }) => Promise<any>;
      inputRef?: any;
      requiresFromSelection?: boolean;
      messageInputPlaceholder?: string;
      onChange?: (value: string) => any;
      fromIds: { id: string; label: string }[];
      disableSenderSelection?: boolean;
      sendMessageInputValue: string;
      setSendMessageInputValue: any;
      fileList: any[];
      setFileList: any;
      isFileUploading: boolean;
      fileUploadBox: JSX.Element;
      fileUploadInput: JSX.Element;
      fileUploadIcon: JSX.Element;
      onScheduleMessage?: (req: {
        text: string;
        fromId: string;
        attachments: string[];
      }) => any;
      messageValidator: (message: string) => any[];
      conversationId?: string;
      tokenReplacer?: (message: string) => string;
      onSlashCommandExecuted?: (command: iSlashCommand) => any;
      onMacroCommandExecuted?: (command: iCCMacro) => any;
      hideHeader?: boolean;
      actionsForm?: FormInstance<any>;
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const [selectedFromId, setSelectedFromId] = useState(defaultSelectedFromId);
    //const [sendMessageInputValue, setSendMessageInputValue] = useState("");
    const previouslyEnteredMessage = useSelector(
      selectEnteredMessage(conversationId),
    );

    useEffect(() => {
      setSendMessageInputValue(previouslyEnteredMessage?.message || "");
      const previouslyEnteredFiles = _.cloneDeep(
        previouslyEnteredMessage?.files || [],
      );
      setFileList(previouslyEnteredFiles);
      if (actionsForm) {
        actionsForm.setFieldsValue({
          actions: previouslyEnteredMessage?.actions,
        });
      }
    }, [
      previouslyEnteredMessage,
      conversationId,
      setSendMessageInputValue,
      setFileList,
      actionsForm,
    ]);

    const sendMessageSuggestionContainerRef = useRef<any>();

    const appendContent = useCallback(
      (content) => {
        const previousContent = inputRef.current?.value || "";
        setSendMessageInputValue(previousContent + content);
      },
      [inputRef, setSendMessageInputValue],
    );

    useImperativeHandle(ref, () => ({
      setSendMessageInputValue,
      appendContent: appendContent,
    }));

    const { changePanelState } = useModalPanels();

    const handleMessageInputChange = (
      event,
      newValue,
      newPlainTextValue,
      mentions: {
        childIndex: number;
        display: string;
        id: string;
        index: number;
        plainTextIndex: number;
        type: "PRESET" | "MENTION" | "NOT_AVAILABLE";
      }[],
    ) => {
      if (newValue.indexOf("@[") > -1) {
        const lastMention = _.last(mentions);
        if (lastMention?.id === "NOT_AVAILABLE") {
          changePanelState(MyManagePresets, true, {});
          // openMessagePresetsModal(dispatch);
          return;
        }
        const slashCommand = _.find(slashCommands, { id: lastMention?.id });
        const macroCommand = _.find(macroCommands, { id: lastMention?.id });
        if (
          lastMention &&
          slashCommand &&
          slashCommand.type === "MESSAGE_PRESET"
        ) {
          const mentionRemovedValue = newValue.replace(
            `@[${lastMention.display}](${lastMention.id})`,
            slashCommand.metaData.value,
          );
          // Todo: Find the variables
          // Replace the variables
          // set the message
          setSendMessageInputValue(
            tokenReplacer
              ? tokenReplacer(checkText(mentionRemovedValue))
              : checkText(mentionRemovedValue),
          );
          setFileList(slashCommand.metaData.attachments);
          if (inputRef?.current) {
            moveCursorToEnd(inputRef.current);
          }
          onSlashCommandExecuted && onSlashCommandExecuted(slashCommand);
          return;
        }
        if (lastMention && macroCommand) {
          const mentionRemovedValue = newValue.replace(
            `@[${lastMention.display}](${lastMention.id})`,
            // macroCommand.label
            "",
          );
          // Todo: Find the variables
          // Replace the variables
          // set the message
          setSendMessageInputValue(
            tokenReplacer
              ? tokenReplacer(checkText(mentionRemovedValue))
              : checkText(mentionRemovedValue),
          );
          // Todo
          // setFileList(slashCommand.metaData.attachments);
          if (inputRef?.current) {
            moveCursorToEnd(inputRef.current);
          }
          onMacroCommandExecuted && onMacroCommandExecuted(macroCommand);
          return;
        }
      }

      setSendMessageInputValue(checkText(newValue));
      if (onChange) {
        onChange(newValue);
      }
    };

    const messageCharLimit = useMemo(
      () => messageCharLimitCalculator(sendMessageInputValue),
      [messageCharLimitCalculator, sendMessageInputValue],
    );

    const [isSendMessageProcessing, setSendMessageProcessing] = useState(false);

    const sendMessage = () => {
      setSendMessageProcessing(true);
      onSend({
        text: sendMessageInputValue,
        attachments: fileList.map((item) => item.url),
        fromId: selectedFromId,
      })
        .then((d) => {
          setSendMessageProcessing(false);
          setSendMessageInputValue("");
          setFileList([]);
          conversationId &&
            dispatch(
              setEnteredMessages({
                conversationId: conversationId,
                data: {
                  files: [],
                  message: "",
                  actions: [],
                },
              }),
            );
        })
        .catch((e) => {
          setSendMessageProcessing(false);
        });
    };
    const onScheduleMessage = (e) => {
      if (_onScheduleMessage) {
        _onScheduleMessage({
          text: sendMessageInputValue,
          attachments: fileList.map((item) => item.url),
          fromId: selectedFromId,
        });
      }
      conversationId &&
        dispatch(
          setEnteredMessages({
            conversationId: conversationId,
            data: {
              files: [],
              message: "",
              actions: [],
            },
          }),
        );
    };

    const validateMessages = useMemo(
      () => messageValidator(sendMessageInputValue),
      [messageValidator, sendMessageInputValue],
    );
    const hasError = validateMessages
      .map((message) => message.type)
      .includes("error");

    const slashCommands = useMemo(() => {
      if (_slashCommands.length === 0) {
        return [
          {
            id: "NOT_AVAILABLE",
            type: "NOT_AVAILABLE",
            label: "No Presets Available",
            display: "Click here to setup",
            description: "Click here to setup",
            value: "Click here to setup",
            metaData: {} as any,
          } as iSlashCommand & { display: string },
        ];
      }
      return _slashCommands
        .filter((e) => !e.metaData.isRichText)
        .map((item) => ({
          ...item,
          id: item.id,
          display: item.label || item.description,
        }));
    }, [_slashCommands]);

    const isMobileView = useMediaQuery({ query: "(max-width: 500px)" });

    return (
      <>
        {validateMessages.map((message, index) => (
          <Alert
            message={message.message}
            type={message.type}
            showIcon
            banner
            style={index === 0 ? { marginTop: -15 } : {}}
          />
        ))}
        <div
          className="suggestions-container w-full relative"
          ref={sendMessageSuggestionContainerRef}
        ></div>
        {true && (
          <div className="flex-1 p-2 px-4">
            <MentionsInput
              value={sendMessageInputValue}
              inputRef={inputRef}
              onChange={handleMessageInputChange}
              placeholder={
                messageInputPlaceholder ||
                "Type in a message and hit enter to send a message. Press / for commands and message presets"
              }
              className="w-full send-input"
              // singleLine
              suggestionsPortalHost={sendMessageSuggestionContainerRef.current}
              onKeyDown={(e) => {
                if (sendMessageOnEnter) {
                  if (e.keyCode === 13 && !e.shiftKey) {
                    e.preventDefault();
                    sendMessage();
                  }
                }
              }}
              onBlur={() => {
                conversationId &&
                  dispatch(
                    setEnteredMessages({
                      conversationId: conversationId,
                      data: {
                        files: fileList,
                        message: sendMessageInputValue,
                        actions: actionsForm
                          ? actionsForm.getFieldValue("actions")
                          : [],
                      },
                    }),
                  );
              }}
            >
              <Mention
                trigger="/"
                data={slashCommands}
                renderSuggestion={SlashCommandRenderer}
              />

              <Mention
                trigger="@"
                data={userList}
                // renderSuggestion={renderPreset}
              />

              <Mention
                trigger="\"
                data={macroCommands}
                renderSuggestion={MacroCommandRenderer}
              />
            </MentionsInput>
          </div>
        )}

        {fileUploadBox}

        {!hideHeader && (
          <div className="helper-bar flex flex-row justify-between items-center w-full">
            <div className="left-icons px-4">
              {fileUploadInput}

              <Space>
                {enableEmojis && (
                  <EmojiPicker
                    onEmojiPicked={(emoji) => {
                      setSendMessageInputValue(sendMessageInputValue + emoji);
                    }}
                  />
                )}
                {fileUploadIcon}

                {!isMobileView && (
                  <>
                    {" "}
                    <div className="mr-4 text-gray-500">{`${
                      messageCharLimit.current
                    }${
                      messageCharLimit.limit ? `/${messageCharLimit.limit}` : ""
                    } Characters`}</div>
                  </>
                )}
              </Space>
            </div>

            <div className="right-side-content flex flex-row items-center  px-2">
              {!disableSenderSelection && (
                <div className="flex flex-row items-center">
                  Sending as
                  <Select
                    dropdownRender={(menu) => <div>{menu}</div>}
                    style={{ minWidth: 200 }}
                    placeholder="Select Sender ID"
                    value={selectedFromId}
                    onChange={(value) => setSelectedFromId(value)}
                    bordered={false}
                  >
                    <Select.OptGroup label="Leased Mobile Numbers">
                      {(fromIds || []).map((fromId) => (
                        <Select.Option value={fromId.id} key={fromId.id}>
                          {fromId.label}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </div>
              )}

              {_onScheduleMessage && (
                <Button
                  icon={<i className="ri-calendar-line"></i>}
                  type="dashed"
                  onClick={onScheduleMessage}
                  disabled={
                    (fileList.length === 0 && !sendMessageInputValue) ||
                    (requiresFromSelection && !selectedFromId) ||
                    hasError
                  }
                  loading={isSendMessageProcessing || isFileUploading}
                  className="mr-2"
                >
                  Schedule
                </Button>
              )}

              <Button
                icon={<i className="ri-send-plane-2-line"></i>}
                type="primary"
                onClick={sendMessage}
                disabled={
                  (fileList.length === 0 && !sendMessageInputValue) ||
                  (requiresFromSelection && !selectedFromId) ||
                  hasError
                }
                loading={isSendMessageProcessing || isFileUploading}
              >
                Send
              </Button>
            </div>
          </div>
        )}
      </>
    );
  },
);
