import {
  Fields,
  FieldSettings,
} from "@libs/@awesome-query-builder/raqb-models";
import { durationOptions } from "../helpers/chat-duration-options";
import { messageCountOptions } from "../helpers/chat-message-count-options";
import { chatRatingOptions } from "../helpers/chat-rating-options";
// https://codesandbox.io/s/4crkq?file=/src/demo/config_simple.tsx

interface iReportSchema {
  filters: {
    label: string;
    placeholder: string;
    icon?: string;
    key: string;
    type: "number" | "text" | "select" | "boolean";
    fieldSettings?: FieldSettings;
    // Select Specific Options
    searchable?: boolean;
    allowMultiple?: boolean;
  }[];
  groupByOptions: {
    key: string;
    value: string;
    icon?: string;
  }[];
}

export const GenerateQueryFieldSchema = (reportSchema: iReportSchema) => {
  const fields: Fields = {};
  for (const filterSchema of reportSchema.filters) {
    fields[filterSchema.key] = {
      label: filterSchema.label,
      type: filterSchema.type,
      fieldSettings: filterSchema.fieldSettings,
      valueSources: ["value"],
      //   preferWidgets: ["number","rangeslider"],
    };
  }
  return fields;
};

export const ConversationsReportSchema: iReportSchema = {
  filters: [
    {
      label: "Users",
      icon: "ri-group-line",
      placeholder: "Select a conversation members",
      key: "users",
      type: "select",
      searchable: true,
      allowMultiple: true,
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Tags",
      icon: "ri-price-tag-3-line",
      placeholder: "Select a tag",
      key: "tags",
      type: "select",
      allowMultiple: true,
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Connection",
      icon: "ri-wireless-charging-line",
      placeholder: "Select a connection",
      key: "connectionId",
      type: "select",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Connection Type",
      icon: "ri-sim-card-fill",
      key: "connectionType",
      placeholder: "Select a connection type",
      type: "select",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Facebook Ad ID",
      icon: "ri-facebook-box-line",
      placeholder: "Enter Facebook Ad Id",
      key: "fbAdId",
      type: "text",
    },
    {
      label: "Facebook Post Id",
      icon: "ri-facebook-box-line",
      placeholder: "Enter Facebook Post Id",
      key: "fbPostId",
      type: "text",
    },
    // {
    //   label: "Team",
    //   icon: "ri-link",
    //   placeholder: "Select a team",
    //   key: "team",
    //   type: "select",
    //   fieldSettings: {
    //     listValues: []
    //   }
    // },
    {
      label: "User Groups",
      icon: "ri-group-line",
      placeholder: "Select a user group",
      key: "userGroup",
      type: "select",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Source",
      icon: "ri-arrow-right-line",
      placeholder: "Select a conversation Source",
      key: "source",
      type: "select",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "First Response Time",
      icon: "ri-timer-flash-line",
      placeholder: "Select a First Response Time Period",
      key: "responseTime",
      type: "select",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Duration",
      icon: "ri-timer-line",
      placeholder: "Select a conversation Duration",
      key: "duration",
      type: "select",
      fieldSettings: {
        listValues: durationOptions,
      },
    },
    {
      label: "Message Count",
      icon: "ri-list-ordered",
      placeholder: "Select a conversation Message Count",
      key: "messageCount",
      type: "select",
      fieldSettings: {
        listValues: messageCountOptions,
      },
    },
    {
      label: "Conversation Rating",
      icon: "ri-emotion-happy-line",
      placeholder: "Select a conversation Rating",
      key: "chatRating",
      type: "select",
      fieldSettings: {
        listValues: chatRatingOptions,
      },
    },
  ],
  groupByOptions: [
    {
      key: "users",
      icon: "ri-user-line",
      value: "Users",
    },
    {
      key: "status",
      icon: "ri-price-tag-line",
      value: "Status",
    },
    {
      key: "tags",
      icon: "ri-price-tag-3-line",
      value: "Tags",
    },
    {
      key: "tagsCombination",
      icon: "ri-price-tag-3-line",
      value: "Tags & Tag Groups",
    },
    {
      key: "connections",
      icon: "ri-link",
      value: "Connections",
    },
    {
      key: "categorizedMessageCount",
      icon: "ri-chat-poll-line",
      value: "Message Count",
    },
    {
      key: "source",
      icon: "ri-arrow-right-line",
      value: "Source",
    },
    {
      key: "chatRating",
      icon: "ri-emotion-happy-line",
      value: "Chat Rating",
    },
    {
      key: "fbAdId",
      icon: "ri-facebook-box-line",
      value: "Facebook Ad Reference",
    },
    {
      key: "fbPostId",
      icon: "ri-facebook-box-line",
      value: "Facebook Post Reference",
    },
    {
      key: "categorizedDuration",
      icon: "ri-timer-line",
      value: "Duration",
    },
    {
      key: "categorizedResponseTime",
      icon: "ri-timer-line",
      value: "Response Time",
    },
  ],
};

export const ContactsReportSchema: iReportSchema = {
  filters: [
    {
      label: "Owner",
      icon: "ri-group-line",
      placeholder: "Select a contact owner",
      key: "owner",
      type: "select",
      searchable: true,
      allowMultiple: false,
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Tags",
      icon: "ri-price-tag-3-line",
      placeholder: "Select a tag",
      key: "tags",
      type: "select",
      allowMultiple: true,
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Connection",
      icon: "ri-wireless-charging-line",
      placeholder: "Select a connection",
      key: "connectionId",
      type: "select",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Connection Type",
      icon: "ri-sim-card-fill",
      key: "connectionType",
      placeholder: "Select a connection type",
      type: "select",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Country",
      icon: "ri-flag-fill",
      key: "country",
      placeholder: "Select a country",
      type: "select",
      fieldSettings: {
        listValues: [],
      },
    },
  ],
  groupByOptions: [
    {
      key: "owner",
      icon: "ri-user-line",
      value: "Owner",
    },
    {
      key: "tags",
      icon: "ri-price-tag-3-line",
      value: "Tags",
    },
    {
      key: "connections",
      icon: "ri-link",
      value: "Connections",
    },
    {
      key: "connectionType",
      icon: "ri-link",
      value: "connectionType",
    },
    {
      key: "country",
      icon: "ri-flag-fill",
      value: "Country",
    },
    {
      key: "marketingConsentObtained",
      icon: "ri-quill-pen-line",
      value: "marketingConsentObtained",
    },
    {
      key: "emailExists",
      icon: "ri-mail-line",
      value: "emailExists",
    },
    {
      key: "mobileExists",
      icon: "ri-smartphone-fill",
      value: "mobileExists",
    },
  ],
};

export const ActivitiesReportSchema: iReportSchema = {
  filters: [
    {
      label: "Owner",
      icon: "ri-group-line",
      placeholder: "Select a contact owner",
      key: "owner",
      type: "select",
      searchable: true,
      allowMultiple: false,
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Type",
      icon: "ri-price-tag-3-line",
      placeholder: "Select a tag",
      key: "type",
      type: "select",
      allowMultiple: true,
      fieldSettings: {
        listValues: [],
      },
    },
  ],
  groupByOptions: [
    {
      key: "owner",
      icon: "ri-user-line",
      value: "Owner",
    },
    {
      key: "type",
      icon: "ri-price-tag-3-line",
      value: "Type",
    },
  ],
};

export const CompaniesReportSchema: iReportSchema = {
  filters: [
    {
      label: "Created by",
      icon: "ri-group-line",
      placeholder: "Select a contact owner",
      key: "createdBy",
      type: "select",
      searchable: true,
      allowMultiple: false,
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Type",
      icon: "ri-price-tag-3-line",
      placeholder: "Select a tag",
      key: "type",
      type: "select",
      allowMultiple: true,
      fieldSettings: {
        listValues: [],
      },
    },
  ],
  groupByOptions: [
    {
      key: "Created by",
      icon: "ri-user-line",
      value: "createdBy",
    },
    {
      key: "type",
      icon: "ri-price-tag-3-line",
      value: "Type",
    },
  ],
};

export const EngagementsReportSchema: iReportSchema = {
  filters: [
    {
      label: "Owner",
      icon: "ri-group-line",
      placeholder: "Select a contact owner",
      key: "owner",
      type: "select",
      searchable: true,
      allowMultiple: false,
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Tags",
      icon: "ri-price-tag-3-line",
      placeholder: "Select a tag",
      key: "tags",
      type: "select",
      allowMultiple: true,
      fieldSettings: {
        listValues: [],
      },
    },
  ],
  groupByOptions: [
    {
      key: "owner",
      icon: "ri-user-line",
      value: "Owner",
    },
  ],
};

export const OpportunitiesReportSchema: iReportSchema = {
  filters: [
    {
      label: "Owner",
      icon: "ri-group-line",
      placeholder: "Select a contact owner",
      key: "owner",
      type: "select",
      searchable: true,
      allowMultiple: false,
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Tags",
      icon: "ri-price-tag-3-line",
      placeholder: "Select a tag",
      key: "tags",
      type: "select",
      allowMultiple: true,
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Pipeline Stage",
      icon: "ri-wireless-charging-line",
      placeholder: "Select a connection",
      key: "pipelineStage",
      type: "select",
      fieldSettings: {
        listValues: [],
      },
    },
  ],
  groupByOptions: [
    {
      key: "owner",
      icon: "ri-user-line",
      value: "Owner",
    },
    {
      key: "pipelineStage",
      icon: "ri-price-tag-3-line",
      value: "Pipeline Stage",
    },
  ],
};
