import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iChatBot } from "@sdk/chat-bots/chat-bots.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Dropdown, Menu, Space, Table, Tooltip } from "antd";
import { iFilterOption } from "components/common/filter-schema/filter-config-schema";
import { HelpCenterPromptButton } from "components/common/help-center/embeddable-components/help-center-prompt-button";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { ChatBotUseCases } from "components/pages/chat-bots/chat-bot-templates-helpers/chat-bot-use-cases";
import { InsiderFeedbackCollector } from "components/pages/home/insider-feedback-collector/insider-feedback-collector";
import { iChatBotFlowConfig } from "components/pages/playground/offline-chat-bot/chat-bot-state";
import { push } from "connected-react-router";
import copyToClipboard from "copy-to-clipboard";
import dayjs from "dayjs";
import _ from "lodash";
import { AddChatBot, BotBuilder, EditChatBot } from "modal-registry";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { loadAllChatBots } from "store/modules/chat-bots/chat-bots.helpers";
import { selectAllChatBots } from "store/modules/chat-bots/chat-bots.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useURLData } from "utils/hooks/use-url-filter";
import JSONModal from "utils/json-modal/json-modal";
import "./manage-chat-bots.scss";

const emptyFilters = [] as iFilterOption[];

export const ManageChatBots = ({ showTitle }: { showTitle?: boolean }) => {
  const isAdvancedMode = useSelector(selectIsAdvancedMode);
  const history = useHistory();
  const { changePanelState } = useModalPanels();

  const { state: chatBots, retry: reload } = useQueryWithStore(
    selectAllChatBots,
    loadAllChatBots(),
  );

  const { doAction: onRemoveChatBot } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (chatBotId: string) =>
        SDK.chatBots.deleteById(chatBotId).then((res) => {
          reload();
        }),
      successMessage: "Chatbot has been removed",
      failureMessage: "Something went wrong",
    }),
    [reload],
  );

  const { doAction: duplicateChatBot } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (chatBotTemplate: iChatBot<any>) =>
        SDK.chatBots
          .create({
            ..._.omit(chatBotTemplate, ["id", "metaData"]),
          })
          .then((res) => {
            setTimeout(() => {
              changePanelState(BotBuilder, true, { chatBotId: res.id });
            }, 400);
          }),
      successMessage: "Chatbot has been cloned",
      failureMessage: "Something went wrong",
    }),
    [changePanelState],
  );

  const dispatch = useDispatch();

  const columns = [
    {
      title: "Label",
      dataIndex: "label",
      render: (label: string, record: iChatBot<iChatBotFlowConfig>) => {
        return (
          <div>
            <div className="first-line">
              {label}{" "}
              <Tooltip title="Copy Chatbot Id">
                <Button
                  onClick={(e) => {
                    copyToClipboard(record.id);
                    e.stopPropagation();
                  }}
                  type="link"
                  icon={<i className="ri-clipboard-line"></i>}
                ></Button>
              </Tooltip>
            </div>
            <div className="second-line text-gray-600">
              {record.flow?.nodes?.length || 0} Blocks | Created on{" "}
              {dayjs(record.createdAt).format("DD-MM-YYYY")}
            </div>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "activeBotCount",
      width: 120,
      render: (activeBotCount: number, record: iChatBot<any>) => {
        return (
          <Button
            type="text"
            icon={<i className="ri-eye-line"></i>}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                push(
                  `/chat-bots/responses?chat-bot-responses-filter=((operatorConfig:(value:${record.id}),operator:IS,key:chatbot))`,
                ),
              );
            }}
          >
            ({activeBotCount || 0}) Responses
          </Button>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: 100,
      render: (text, record: iChatBot<any>) => (
        <Space size="middle" onClick={(e) => e.stopPropagation()}>
          <Button
            type="text"
            onClick={(e) => {
              changePanelState(BotBuilder, true, { chatBotId: record.id });
            }}
            icon={<i className="ri-settings-2-line"></i>}
          >
            Configure Flow
          </Button>
          <Dropdown
            overlay={
              <Menu data-click-context="Manage Chat Bot More Button">
                <Menu.Item
                  icon={<i className="ri-delete-bin-line"></i>}
                  onClick={() => onRemoveChatBot(record.id)}
                >
                  Delete Chatbot
                </Menu.Item>
                <Menu.Item
                  icon={<i className="ri-pencil-line"></i>}
                  onClick={() =>
                    changePanelState(EditChatBot, true, {
                      chatBotId: record.id,
                    })
                  }
                >
                  Edit Chatbot
                </Menu.Item>
                <Menu.Item
                  icon={<i className="ri-file-copy-2-line"></i>}
                  onClick={() => duplicateChatBot(record)}
                >
                  Clone Chatbot
                </Menu.Item>
              </Menu>
            }
            placement="topCenter"
            trigger={["click"]}
            arrow
          >
            <Button type="text" icon={<i className="ri-more-line"></i>} />
          </Dropdown>
        </Space>
      ),
    },
  ];
  if (isAdvancedMode) {
    columns.push({
      title: "",
      dataIndex: "id",
      width: 20,
      render: (id: any) => {
        return (
          <i
            className="ri-code-box-line cursor-pointer"
            onClick={() => {
              JSONModal(chatBots.find((chatBot) => chatBot.id === id) || {});
            }}
          />
        );
      },
    });
  }

  const [selectedFilters, setSelectedFilters] = useURLData(
    "chat-bot-templates-filter",
    emptyFilters,
  );

  const activateTemplateFilter = useCallback(
    (section: string) => {
      let filters: iFilterOption[] = [];
      if (section.includes("USE_CASE_")) {
        const useCase = section.replace("USE_CASE_", "");
        filters.push({
          key: "useCases",
          operator: "IS",
          operatorConfig: {
            value: useCase,
          },
        });
      }
      setSelectedFilters(filters);
    },
    [setSelectedFilters],
  );

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin manage-chat-bots">
      <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4">
        <SectionHeader
          title="Manage Chatbots"
          icon={<i className="ri-robot-line"></i>}
          description="Automate conversation experiences for customers from data collection to more advanced integrations"
          addons={
            <>
              <Button
                type="primary"
                icon={<i className="ri-add-box-line"></i>}
                onClick={() => changePanelState(AddChatBot, true, {})}
                className="font-bold"
              >
                New Chatbot
              </Button>
            </>
          }
        />

        <Divider />

        <HelpCenterPromptButton
          type="BANNER"
          text={
            <div className="text-lg p-3">Learn how to create ChatBots 📺</div>
          }
          description=""
          icon="LEARN"
          articleId="fc89e0-c0923"
          className="my-4"
        />

        <Table
          columns={columns}
          dataSource={chatBots}
          pagination={false}
          rowKey="id"
          className="mb-16"
          onRow={(record) => ({
            onClick: () => {
              changePanelState(BotBuilder, true, { chatBotId: record.id });
            },
            className: "cursor-pointer",
          })}
        />

        <InsiderFeedbackCollector
          text="How can we improve our ChatBots feature?"
          className="mt-4"
        />

        <Divider />

        <SectionHeader
          title="Explore Templates"
          icon={<i className="ri-folders-line"></i>}
          description="Create Your Chat Bots with Pre-made templates"
        />

        <div className="flex flex-row gap-4 flex-wrap mt-8">
          {ChatBotUseCases.map((item) => (
            <div
              key={item.label}
              onClick={() => {
                history.push({
                  pathname: `/chat-bots/templates`,
                });
                activateTemplateFilter(`USE_CASE_${item.label}`);
              }}
              className="p-4 rounded-lg border border-gray-300 dark:border-gray-700 flex flex-col cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800"
              style={{ width: 300 }}
            >
              <div className="flex flex-row items-center">
                <div className="text-4xl">{item.icon}</div>
                <div className="text-xl font-bold ml-3">{item.label}</div>
              </div>
              <div className="text-gray-600 flex-1">{item.description}</div>
              <div className="">
                <Button
                  type="text"
                  icon={<i className="ri-arrow-right-line"></i>}
                  block
                >
                  Explore
                </Button>
              </div>
            </div>
          ))}
        </div>

        <div
          className="p-4 rounded-lg border border-gray-300 dark:border-gray-700 flex flex-col cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800 mt-8"
          onClick={() => {
            (window as any).ccWidget?.onWidgetActivated(() => {
              (window as any).ccWidget?.triggerCampaign?.({
                message: "How can we improve Chatbots for you?",
              });
              (window as any).ccWidget.setWidgetVisibility(true);
            });
          }}
        >
          <div className="flex flex-row items-center">
            <div className="text-4xl">🥰</div>
            <div className="text-xl font-bold ml-3">Help us Help you!</div>
          </div>

          <div className="text-gray-600">
            Share your feedback with us to improve Click Connector
          </div>
        </div>
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
