import { iArticle } from "@sdk/knowledge-base/knowledge-base.models";
import { Badge, Tag } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";

export const HelpCenterArticleItem = ({
  article,
  onSelect,
}: {
  article: iArticle;
  onSelect?: () => any;
}) => {
  return (
    <Badge.Ribbon text={article.type || "Article"}>
      <div
        className="my-4 border-gray-200 dark:border-gray-800 border rounded-md flex collection-item draggable-collection mode_transition"
        onClick={onSelect}
      >
        <div
          className={classNames(
            "w-full border-0 bg-white dark:bg-gray-900  cursor-pointer  rounded-lg  mode_transition",
            {
              "hover:bg-blue-100 dark:hover:bg-gray-800 cursor-pointer": Boolean(
                onSelect
              ),
            }
          )}
        >
          <div className="flex flex-row justify-center items-center">
            <div className="flex flex-row flex-1 items-center p-8">
              <div className="flex-1 flex flex-col justify-center pl-4">
                <div className=" text-blue-600 text-xl">{article.title}</div>
                <div className=" text-gray-600 dark:text-gray-400 mode_transition">
                  {article.description}
                </div>
                <div className="">
                  {article.tags?.map((tag) => (
                    <Tag key={tag}>
                      {(tag.toUpperCase && tag.toUpperCase()) || tag}
                    </Tag>
                  ))}
                </div>
                <div className=" text-gray-600 dark:text-gray-400 mode_transition">
                  Created At:{" "}
                  {dayjs(article.metaData.createdAt).format("LL h:mm:ss a ")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Badge.Ribbon>
  );
};
