import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import { iEmailConnectionData } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Badge, Checkbox, List, Menu, Tag, Tooltip } from "antd";
import classNames from "classnames";
import { AvatarGroup, UserAvatar } from "components/common/avatar/avatar";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import { useSelectedUniboxEntity } from "components/pages/conversations/use-unibox-view";
import dayjs from "dayjs";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectPinnedChats } from "store/modules/users/users.selectors";
import {
  selectConnectionContextVisibility,
  selectConversationTags,
} from "store/modules/workspace/workspace.selectors";
import { useDoubleSelectorWithMemoize } from "store/utils/use-double-selector-with-memoize";
import { useSelectorWithMemoize } from "store/utils/use-selector-with-memoize";
import { AddEllipsis } from "utils/add-ellipsis";
import { WithData } from "utils/attach-data-to-event";
import { stringArrayToSentence } from "utils/string-array-to-sentence";
import { selectContactById } from "../../../../../store/modules/contacts/contacts.selectors";
import {
  selectConversationById,
  selectUnreadMessages,
} from "../../../../../store/modules/conversations/conversations.selectors";
import "./chat-list-item.scss";

export const ChatListItem = ({
  conversationId,
  onConversationSelected,
  onSelected,
  isSelected,
  onTagsClicked,
  onUserClicked,
}: {
  conversationId: string;
  onConversationSelected: (conversationId: string) => any;
  onSelected?: (status: boolean, withShiftKey?: boolean) => any;
  isSelected?: boolean;
  onTagsClicked?: (tags: string[]) => any;
  onUserClicked?: (userId) => any;
}) => {
  const {
    contactId,
    connectionId,
    subject,
    simpleId,
    lastMessage,
    tags,
    status,
    requiresAttentionObj,
    members,
    routerMetaData,
    createdAt,
  } = useDoubleSelectorWithMemoize(
    () => selectConversationById(conversationId),
    [conversationId],
    (conversation) => ({
      contactId: conversation?.contactId,
      connectionId: conversation?.connectionId,
      subject: conversation?.subject,
      simpleId: conversation?.data?.simpleId,
      lastMessage: conversation?.metaData.lastMessage,
      tags: conversation?.tags,
      requiresAttentionObj: conversation?.metaData?.flags?.requiresAttention,
      members: conversation?.members,
      routerMetaData: conversation?.metaData.routerMetaData,
      status: conversation?.status,
      createdAt: conversation.metaData.createdAt,
    }),
  );

  const { nameLine } = useDoubleSelectorWithMemoize(
    () => selectContactById(contactId!),
    [contactId],
    (contact) => ({
      nameLine: contact?.data
        ? `${contact?.data.firstName || ""} ${contact?.data.lastName || ""}`
        : "---",
    }),
  );

  const unread = useSelector(selectUnreadMessages(conversationId));

  const connection = useSelectorWithMemoize(
    () => selectConnectionById(connectionId!),
    [connectionId],
  );

  const showConnectionContext = useSelector(selectConnectionContextVisibility);

  const { entityId: selectedConversation } = useSelectedUniboxEntity();

  const store = useStore();

  const showTicketId = useMemo(() => {
    const connection = selectConnectionById(connectionId)(store.getState());
    const isSimpleTicketEnabled = (connection?.data as iEmailConnectionData)
      ?.ticketWorkflowConfig?.simpleTicketIds?.isEnabled;
    return isSimpleTicketEnabled;
  }, [connectionId, store]);

  const subjectLine = useMemo(() => {
    const _subjectLine = subject || "Untitled Conversation";
    if (showTicketId && simpleId) {
      return `#${simpleId}: ${_subjectLine}`;
    }
    return _subjectLine;
  }, [showTicketId, simpleId, subject]);

  const previewLine =
    status === "CLOSED"
      ? "✓✓ Chat has been Closed"
      : lastMessage?.text || "No recent messages";
  const isActive = selectedConversation === conversationId;

  const lastMessageDate = useMemo(
    () => dayjs(lastMessage?.timestamp || createdAt),
    [createdAt, lastMessage?.timestamp],
  );
  const isCurrentDate = lastMessageDate.isSame(new Date(), "day");
  const timeString = lastMessageDate.format(isCurrentDate ? "LT" : "MMM Do");

  const menu = (
    <Menu>
      <Menu.Item key="1">Mark as Read</Menu.Item>
      <Menu.Item key="2">Assign Agent</Menu.Item>
      <Menu.Item key="3">Archive Conversation</Menu.Item>
      <Menu.Item key="3">Edit Conversation Subject</Menu.Item>
    </Menu>
  );

  const tagText = (() => {
    if (!tags || tags.length === 0) {
      return "";
    }
    if (tags.length === 1) {
      return AddEllipsis(tags[0], 18);
    }
    return `${AddEllipsis(tags[0], 17)}+${tags.length - 1}`;
  })();
  const tagToolTipText = stringArrayToSentence(tags);
  const conversationTags = useSelector(selectConversationTags);

  const tagsByColor = useMemo(() => {
    return _.keyBy(conversationTags || [], "label");
  }, [conversationTags]);

  const pinnedChats = useSelector(selectPinnedChats);
  const isPinned = pinnedChats.includes(conversationId!);
  const { doAction: savePins, isProcessing: isPinning } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (pinnedConversations: string[]) =>
        SDK.editCurrentUser({
          data: {
            pinnedConversations,
          },
        }),
      failureMessage: "Something went wrong",
    }),
    [],
  );
  const togglePin = (e) => {
    if (isPinned) {
      savePins(_.without(pinnedChats, conversationId!));
    } else {
      savePins([...pinnedChats, conversationId!]);
    }
    e.stopPropagation();
  };

  const toolTipText = useMemo(() => {
    return subjectLine.length > 16 ? subjectLine : "";
  }, [subjectLine]);

  const requiresAttention = requiresAttentionObj?.isActive;

  const isLargeScreen = useMediaQuery({ query: "(min-width: 1400px)" });

  const selectConversation = useCallback(
    WithData(() => onConversationSelected(conversationId), {
      conversationId: conversationId,
    }),
    [conversationId, conversationId, onConversationSelected],
  );

  const handleEnter = useCallback(
    (e) => {
      if (e.key === "Enter") {
        selectConversation(e);
      }
    },
    [selectConversation],
  );

  return (
    // <Dropdown overlay={menu} trigger={["contextMenu"]}>
    <List.Item
      className={classNames(
        "px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-900  cursor-pointer chat-list-item mode_transition",
        {
          active: isActive,
          "has-unread": unread.hasUnread,
          "bg-orange-200": !isActive && requiresAttention,
          "hover:bg-yellow-300": requiresAttention,
          "dark:bg-orange-900": !isActive && requiresAttention,
          "dark:hover:bg-orange-800": requiresAttention,
          "bg-orange-300": isActive && requiresAttention,
          "dark:bg-orange-800": isActive && requiresAttention,
        },
      )}
      onClick={selectConversation}
      data-click-label="Chat List Item"
      tabIndex={0}
      role="button"
      onKeyDown={handleEnter}
    >
      <div className="flex flex-row justify-start items-center w-full">
        <div className="avatar-container flex flex-row justify-center items-center relative">
          {unread.hasUnread && (
            <Badge
              color="blue"
              count={unread.count || undefined}
              dot={!unread.count ? unread.hasUnread : undefined}
              className="un-read-badge blue"
            ></Badge>
          )}

          <ContactAvatar
            contactId={contactId!}
            size={isLargeScreen ? 40 : 32}
            connectionId={connectionId}
          />
          {onSelected && (
            <div
              className={classNames(
                "checkbox-container w-full h-full flex flex-row justify-center items-center absolute z-10 bg-gray-100  dark:bg-gray-800 rounded-lg mode_transition",
                {
                  "opacity-100": isSelected,
                },
              )}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                checked={isSelected}
                onChange={(e) => {
                  onSelected(e.target.checked, e.nativeEvent.shiftKey);
                  e.stopPropagation();
                }}
                tabIndex={-1}
              ></Checkbox>
            </div>
          )}
        </div>
        <Tooltip title={toolTipText} placement="right" mouseEnterDelay={1}>
          <div className="text-container flex flex-col flex-1 pl-2 w-full">
            <div className="pin-container">
              {isPinning ? (
                <LoadingOutlined style={{ fontSize: 18 }} />
              ) : isPinned ? (
                <div className="pinned" onClick={togglePin}>
                  <i className="ri-pushpin-2-line"></i>
                </div>
              ) : (
                <div className="pin" onClick={togglePin}>
                  <i className="ri-pushpin-line"></i>
                </div>
              )}
            </div>
            <div className="first-line  flex flex-row justify-between items-center">
              <div className="name-line text-sm">{nameLine}</div>
              {tagText && (
                <div
                  className="tags-container truncate"
                  onClick={
                    onTagsClicked
                      ? (e) => {
                          onTagsClicked(tags);
                          e.stopPropagation();
                        }
                      : undefined
                  }
                >
                  <Tooltip title={tagToolTipText} placement="top">
                    <Tag
                      color={
                        (tagsByColor[tags[0] || "NO_COLOR"]?.color as any) ||
                        "blue"
                      }
                    >
                      {tagText}
                    </Tag>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="second-line flex flex-row justify-between items-center">
              <div className="subject-line">{subjectLine}</div>
              <div className="date text-gray-600 dark:text-gray-400 text-xs w-20 text-right mode_transition">
                {timeString}
              </div>
            </div>
            <div className="preview-line text-gray-600 dark:text-gray-400 text-sm mode_transition flex flex-row justify-between items-center">
              <span className="truncate">
                {status !== "CLOSED" &&
                  lastMessage?.team > lastMessage?.client && (
                    <i className="ri-chat-forward-line mr-1"></i>
                  )}

                {AddEllipsis(previewLine, 50)}
              </span>
              {/* {conversation.status === "CLOSED" && (
                <Tooltip title={"Conversation has been closed"}>
                  <Tag
                    color="green"
                    className="flex flex-row items-center text-sm"
                  >
                    <i className="ri-check-line"></i>
                  </Tag>
                </Tooltip>
              )} */}

              <div>
                {showConnectionContext && (
                  <div className="connection-name-container">
                    <Tag>{connection?.label}</Tag>
                  </div>
                )}
                <AvatarGroup>
                  {members.map((member) => (
                    <div
                      className="avatar-container"
                      key={member.userId}
                      onClick={
                        onUserClicked
                          ? (e) => {
                              onUserClicked(member.userId);
                              e.stopPropagation();
                            }
                          : undefined
                      }
                    >
                      <UserAvatar
                        userId={member.userId}
                        key={member.userId}
                        enableToolTip
                        size={20}
                      />
                    </div>
                  ))}
                  {routerMetaData &&
                    routerMetaData?.isActive &&
                    (routerMetaData.lastRoutingExpiry || 0) > Date.now() && (
                      <div className="avatar-container">
                        <UserAvatar
                          userId={routerMetaData.currentInvitedUser!}
                          enableToolTip
                          tooltipTemplate={RoutingInProgressTemple}
                          size={20}
                        />
                      </div>
                    )}
                </AvatarGroup>
              </div>
            </div>
          </div>
        </Tooltip>
      </div>
    </List.Item>
    // </Dropdown>
  );
};

const RoutingInProgressTemple = (name: string) => `Routed to ${name}`;
