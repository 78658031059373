import { SDK, useSDK } from "@sdk";
import { iFileRecord } from "@sdk/file-management/file-management.models";
import {
  Button,
  Menu,
  Modal,
  Pagination,
  Radio,
  Space,
  Table,
  Tabs,
  Tag,
  Upload,
  message,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import classNames from "classnames";
import { ImageX } from "components/common/image";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectUsersMap } from "store/modules/users/users.selectors";
import { AddEllipsis } from "utils/add-ellipsis";
import { usePagination } from "utils/hooks/use-pagination";
import { DummyAvatars } from "./dummy-avatars";
import "./image-selector.scss";

export const ImageSelector = ({
  visible,
  onChangeVisibility,
  onSelected,
  singleSelection,
  reference,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onSelected: (fileResource: iFileRecord) => any;
  singleSelection: boolean;
  reference: {
    type: string;
    entityId: string;
  };
}) => {
  const data = DummyAvatars;

  const [viewMode, setViewMode] = useState<"LIST_VIEW" | "GALLERY_VIEW">(
    "LIST_VIEW",
  );

  const [folder, setFolder] = useState("RECENT");

  const [selectedItems, setSelectedItems] = useState<iFileRecord[]>([]);
  const maxSelectedItems = 1;

  const onItemsSelected = (items: iFileRecord[]) => {
    if (singleSelection) {
      onSelected(items[0]);

      return;
    }
    const allItems = _.uniq([...selectedItems, ...items]);
    if (allItems.length > maxSelectedItems) {
      setSelectedItems(
        allItems.slice(Math.abs(maxSelectedItems - (allItems.length - 1))),
      );
    } else {
      setSelectedItems(allItems);
      console.log("allItems", allItems);
    }
  };

  const toggleItemSelected = (item: iFileRecord) => {
    if (selectedItems.includes(item)) {
      onRemoveSelection(item);
    } else {
      onItemsSelected([item]);
    }
  };

  const onRemoveSelection = (item: iFileRecord) => {
    setSelectedItems(_.without(selectedItems, item));
  };

  const queries = {
    WORKSPACE: {
      tag: "WORKSPACE",
    },
    SELF: {
      tag: "SELF",
    },
    RECENT: {
      uploadedBy: "SELF",
    },
  };

  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: { docs: _records, totalDocs },
  } = useSDK(
    (SDK) =>
      SDK.queryFiles({
        query: queries[folder],
        options: {
          page: paginationState.currentPage,
          limit: paginationState.pageSize,
        },
      }),
    [folder, isUploading],
    false,
    { docs: [] },
  );

  useEffect(() => {
    setIsLoading(false);
  }, [_records]);

  const records = _records as iFileRecord[];
  const usersMap = useSelector(selectUsersMap);
  const transformedData = useMemo(() => {
    return records.map((item) => ({
      url: item.url,
      name: item.metaData.fileName,
      uploadedBy: getUserName(usersMap[item.uploadedBy]),
      tags: [],
      original: item,
    }));
  }, [records, usersMap]);

  const { paginationState, setPaginationState, totalItems, itemsInView } =
    usePagination(transformedData, { pageSize: 15 });

  const fileListColumns = [
    {
      title: "",
      dataIndex: "thumbnail",
      render: (tags, record) => (
        <span>
          <Avatar src={record.url} size={40} />
        </span>
      ),
      width: 50,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    // {
    //   title: "Tags",
    //   dataIndex: "tags",
    //   render: (tags: string[]) => (
    //     <span>
    //       <Space size="middle">
    //         {tags &&
    //           tags.map((tag, index) => <Tag key={index.toString()}>{tag}</Tag>)}
    //         <a
    //           onClick={() => {
    //             // Todo
    //           }}
    //         >
    //           Edit Tags
    //         </a>
    //       </Space>
    //     </span>
    //   ),
    // },
    {
      title: "Uploaded by",
      dataIndex: "uploadedBy",
      render: (uploadedBy) => (
        <span>{uploadedBy && <Tag>{uploadedBy}</Tag>}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {selectedItems.indexOf(record.original) > -1 ? (
            <Button
              type="text"
              onClick={() => onRemoveSelection(record.original)}
            >
              Unselect
            </Button>
          ) : (
            <Button
              type="text"
              onClick={() => onItemsSelected([record.original])}
            >
              Select
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Modal
        title={
          <div className="font-bold text-xl">
            <Space>
              <i className="ri-file-line"></i> File Picker
            </Space>
          </div>
        }
        open={visible}
        onCancel={() => {
          onChangeVisibility(false);
        }}
        onOk={() => {
          // Todo
        }}
        destroyOnClose={true}
        okText="Continue"
        data-click-context="File Picker Modal"
        footer={
          selectedItems.length === 0 ? null : (
            <div className="flex flex-row justify-between items-center">
              <div className="selected-items flex flex-row">
                <span className="text-gray-600 dark:text-gray-400 pr-4 mode_transition">
                  Selected Items:{" "}
                </span>
                <div className="file-attachments">
                  {selectedItems.map((file, index) => (
                    <div
                      className="attachment"
                      key={index.toString()}
                      onClick={() => onRemoveSelection(file)}
                    >
                      {(file.metaData?.mimetype || "").indexOf("image") >
                        -1 && (
                        <div className="flex justify-center items-center flex-col">
                          <ImageX
                            src={file.url}
                            width={"auto"}
                            style={{ maxHeight: 70 }}
                            className="flex justify-center items-center"
                            // height={120}
                          />
                          <div className="hover">
                            <i className="ri-delete-bin-line text-lg"></i>
                          </div>
                        </div>
                      )}
                      {(file.metaData?.mimetype || "").indexOf("image") ===
                        -1 && (
                        <div className="flex justify-center items-center flex-col">
                          <i className="ri-attachment-line text-2xl"></i>
                          <div className="text">
                            {AddEllipsis(
                              file.metaData.fileName || file.url,
                              20,
                            )}
                          </div>
                          <div className="hover">
                            <i className="ri-delete-bin-line text-lg"></i>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <Space>
                <Button>Cancel</Button>
                <Button type="primary">Continue</Button>
              </Space>
            </div>
          )
        }
        width={1000}
        bodyStyle={{ padding: 0 }}
        className="image-selector-modal"
      >
        <Tabs
          defaultActiveKey={"LIBRARY"}
          tabBarStyle={{ paddingLeft: 16 }}
          tabBarExtraContent={
            <div className="pr-8 text-gray-600 dark:text-gray-400 mode_transition">
              {selectedItems.length > 0 && (
                <>
                  Selected Items ({selectedItems.length})
                  <Button type="link" onClick={() => setSelectedItems([])}>
                    Clear Selection
                  </Button>
                </>
              )}
            </div>
          }
        >
          <Tabs.TabPane
            tab={<span className="font-bold text-sm">Library</span>}
            key="LIBRARY"
          >
            <div className="flex flex-row">
              <div className="menu">
                <div
                  className="file-picker-menu-container flex flex-col justify-between h-full"
                  style={{ minWidth: 220 }}
                >
                  <Menu
                    className="flex-1"
                    selectedKeys={[folder]}
                    onClick={({ key }) => {
                      setFolder(String(key));
                      setIsLoading(true);
                    }}
                  >
                    <Menu.Item
                      icon={<i className="ri-global-line pr-2"></i>}
                      key="WORKSPACE"
                    >
                      Workspace Library
                    </Menu.Item>
                    <Menu.Item
                      icon={<i className="ri-user-star-line pr-2"></i>}
                      key="SELF"
                    >
                      My Library
                    </Menu.Item>
                    <Menu.Item
                      icon={<i className="ri-history-line pr-2"></i>}
                      key="RECENT"
                    >
                      Recent Uploads
                    </Menu.Item>
                  </Menu>
                </div>
              </div>
              <div className="view px-8 w-full flex-1">
                <div className="filters flex flex-row justify-between items-center px-8 mt-4">
                  <Space className="font-semibold">
                    {folder === "WORKSPACE"
                      ? "Workspace Library"
                      : folder === "SELF"
                      ? "My Library"
                      : "Recent Uploads"}
                  </Space>
                  <Space>
                    <Upload
                      accept="image/*"
                      fileList={[]}
                      action={(data) => {
                        const file = data;
                        setIsUploading(true);
                        return SDK.uploadFile(
                          file as File,
                          {
                            type: "USER",
                            entityId: "LIBRARY_ITEMS",
                          },
                          [folder],
                        )
                          .then((d) => {
                            message.info("File(s) have been uploaded");
                            onItemsSelected([d]);
                            setIsUploading(false);
                            return d.url;
                          })
                          .catch((e) => {
                            message.error(
                              "Something went wrong while uploading",
                            );
                            setIsUploading(false);
                            throw e;
                          });
                      }}
                    >
                      <Button
                        type="dashed"
                        icon={<i className="ri-upload-cloud-line"></i>}
                        loading={isUploading}
                      >
                        {folder === "WORKSPACE"
                          ? "Upload To Workspace Library"
                          : folder === "SELF"
                          ? "Upload To My Library"
                          : "Upload"}
                      </Button>
                    </Upload>

                    <Radio.Group
                      onChange={(e) => setViewMode(e.target.value)}
                      value={viewMode}
                    >
                      <Radio.Button value="LIST_VIEW">
                        <i className="ri-list-check-2"></i>
                      </Radio.Button>
                      <Radio.Button value="GALLERY_VIEW">
                        <i className="ri-list-check"></i>
                      </Radio.Button>
                    </Radio.Group>
                  </Space>
                </div>
                {viewMode === "GALLERY_VIEW" && (
                  <>
                    <div
                      className={classNames(
                        "file-list flex flex-row flex-wrap mt-4 overflow-auto items-start",
                      )}
                      style={{ height: 420 }}
                    >
                      {itemsInView.map((item) => (
                        <ImageX
                          src={item.url}
                          style={{ width: 130, height: 130 }}
                          className={classNames({
                            "border border-blue-500": selectedItems.includes(
                              item.original,
                            ),
                          })}
                          preview={false}
                          onClick={() => toggleItemSelected(item.original)}
                        />
                      ))}
                    </div>
                    <div className="flex flex-row justify-end items-center py-4">
                      <Pagination
                        pageSize={paginationState.pageSize}
                        current={paginationState.currentPage}
                        total={totalItems}
                        onChange={(currentPage, pageSize) =>
                          setPaginationState({
                            ...paginationState,
                            pageSize: pageSize || 20,
                            currentPage,
                          })
                        }
                      />
                    </div>
                  </>
                )}

                {viewMode === "LIST_VIEW" && (
                  <Table
                    columns={fileListColumns}
                    dataSource={transformedData}
                    pagination={transformedData.length < 10 ? false : undefined}
                    rowKey="url"
                    loading={isLoading}
                    className="mt-2"
                    scroll={{ y: 400 }}
                    style={{ minHeight: 400 }}
                  />
                )}
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<span className="font-bold text-sm">Upload</span>}
            key="UPLOAD"
          >
            <div className="image-selector-drag-and-drop-container p-8">
              <Upload.Dragger
                name="file"
                fileList={[]}
                // accept=".csv"
                // accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                accept="image/*"
                customRequest={() => {}}
                action={(data) => {
                  const file = data;
                  setIsUploading(true);
                  return SDK.uploadFile(file as File, reference, [])
                    .then((d) => {
                      message.info("File(s) have been uploaded");
                      onItemsSelected([d]);
                      setIsUploading(false);
                      return d.url;
                    })
                    .catch((e) => {
                      message.error("Something went wrong while uploading");
                      setIsUploading(false);
                      throw e;
                    });
                }}
                style={{ minHeight: 400 }}
                className="flex-row flex items-center justify-center"
              >
                {isUploading && (
                  <>
                    <p className="ant-upload-drag-icon">
                      <i className="ri-loader-5-line animate-spin text-2xl"></i>
                    </p>
                    <p className="ant-upload-text">Upload in progress</p>
                  </>
                )}
                {!isUploading && (
                  <>
                    <p className="ant-upload-drag-icon">
                      <i className="ri-upload-cloud-line text-4xl text-blue-500"></i>
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      You could select multiple files
                    </p>
                    {/* <a
                  className="ant-upload-hint"
                  href="https://www.youtube.com/watch?v=RW0BTP-XyeY"
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  Got an excel file? (.xlxs). Click here to find out how to
                  convert excel files to csv
                </a> */}
                  </>
                )}
              </Upload.Dragger>
            </div>
          </Tabs.TabPane>
        </Tabs>
        <DarkModeBg />
      </Modal>
    </>
  );
};
