import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ReactFlow, {
  Background,
  Controls,
  EdgeTypes,
  MiniMap,
  Node,
  NodeChange,
  NodeRemoveChange,
  NodeResetChange,
  NodeTypes,
  ReactFlowProvider,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
  useUpdateNodeInternals,
} from "react-flow-renderer";
// you need these styles for React Flow to work properly
import "react-flow-renderer/dist/style.css";
// additionally you can load the default theme
import "react-flow-renderer/dist/theme-default.css";
import "./bot-builder.scss";
import { initialEdges, initialNodes } from "./initial-elements";
import { ActionNode } from "./node-types/action-node/action-node";
import { ConditionNode } from "./node-types/condition-node/condition-node";
import { MessageNode } from "./node-types/message-node/message-node";
import { BotBuilderSideBar } from "./side-bar/bot-builder-side-bar";

import { message } from "antd";
import { GPTAssistantNode } from "./node-types/gpt-assistant-node/gpt-assistant-node";
import { BezierEdgeWithButtonLabel } from "./utils/bezier-edge-with-button";
import { SmartEdgeWithButtonLabel } from "./utils/smart-edge";
// Todo:
// Set 2
// DONE - Design the Action Node
// DONE - Design Data Collectors Input
// DONE - Add - Chat Context Collector - Simple Tet Answer, Number Fields
// Set 3
// https://github.com/tisoap/react-flow-smart-edge
// https://reactflow.dev/docs/examples/drag-handle/
// Only One Connection to one Edge
// Stroke - defaultEdgeOptions
// Edge Label with delete icon
// Set 4
// Work on - src/components/pages/bot-builder/chat-bot-model.ts
// Handle Process Handles
// Function to activate bot, deactivate bot
// Set 5
// Plan a Chatbot Creation UI
// Set 6
// Implement Models and Services
// Implement Handlers in backend
// Set 7
// Create Chatbot Configuration UI and Bind
// Set 8
// Test

const nodeTypes: NodeTypes = {
  MESSAGE_NODE: MessageNode,
  ACTION_NODE: ActionNode,
  CONDITION_NODE: ConditionNode,
  GPT_ASSISTANT_NODE: GPTAssistantNode,
};

const _SmartBezierEdge = (props) => {
  return <SmartEdgeWithButtonLabel {...props} interactionWidth={40} />;
};

const edgeTypes: EdgeTypes = {
  // default: _SmartBezierEdge,
  default: BezierEdgeWithButtonLabel,
};

const snapToGrid: [number, number] = [15, 15];

const flowKey = "example-flow";

export const BotBuilder = forwardRef((props, ref) => {
  const reactFlowWrapper = useRef<any>(null);
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const { setViewport } = useReactFlow();

  useEffect(() => {
    if (onChangeCallback.current) {
      onChangeCallback.current();
    }
  }, [nodes, edges]);

  const onChangeCallback = useRef<any>();

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      // console.log("changes", changes);
      const removedNodes = changes
        .filter((item) => item.type === "remove")
        .map((item) => (item as NodeRemoveChange).id);

      const resetNodes = changes
        .filter((item) => item.type === "reset")
        .map((item) => (item as NodeResetChange<any>).item.id);

      if (
        // Non-Reset Command Issued
        (!removedNodes.includes("START") && resetNodes.length === 0) ||
        // Reset Command Issued
        (resetNodes.length > 0 && resetNodes.includes("START"))
      ) {
        setNodes((nds) => applyNodeChanges(changes, nds));
      } else {
        message.warning("You can't remove the starting node");
      }
    },
    [setNodes],
  );

  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges],
  );

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [],
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      try {
        const newNodeData = JSON.parse(
          event.dataTransfer.getData("application/reactflow"),
        );

        // check if the dropped element is valid
        if (typeof newNodeData === "undefined" || !newNodeData) {
          return;
        }
        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });
        const newNode: Node = {
          ...newNodeData,
          position,
        };

        setNodes((nds) => nds.concat(newNode));
      } catch (e) {
        // Ignore
      }
    },
    [reactFlowInstance],
  );

  const onSave = useCallback(() => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();
      localStorage.setItem(flowKey, JSON.stringify(flow));
    }
  }, [reactFlowInstance]);

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = JSON.parse(localStorage.getItem(flowKey) || "");

      if (flow) {
        const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        setViewport({ x, y, zoom });
      }
    };
    restoreFlow();
  }, [setNodes, setViewport]);

  const updateNodeInternals = useUpdateNodeInternals();

  useImperativeHandle(
    ref,
    () => ({
      setNodes: setNodes,
      setEdges: setEdges,
      setViewport: setViewport,
      getState: () => {
        const flow = reactFlowInstance.toObject();
        return flow;
      },
      refreshView: () => {
        const flow = reactFlowInstance.toObject();
        for (const node of flow.nodes) {
          updateNodeInternals(node.id);
        }
      },
      onChangeCallback,
    }),
    [setViewport, reactFlowInstance, updateNodeInternals],
  );

  return (
    <>
      <div className="reactflow-wrapper h-full flex-1" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          snapGrid={snapToGrid}
          snapToGrid={true}
          onConnect={onConnect}
          onInit={setReactFlowInstance}
          onDrop={onDrop}
          onDragOver={onDragOver}
        >
          {false && (
            <MiniMap
              nodeStrokeColor={(n) => {
                if (n.style?.background) return n.style.background as string;
                if (n.type === "input") return "#0041d0";
                if (n.type === "output") return "#ff0072";
                if (n.type === "default") return "#1a192b";

                return "#eee";
              }}
              nodeColor={(n) => {
                if (n.style?.background) return n.style.background as string;
                return "#fff";
              }}
              nodeBorderRadius={2}
            />
          )}

          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </div>
      <BotBuilderSideBar />
      {/* <div className="flex flex-col">
        <button onClick={onSave}>save</button>
        <button onClick={onRestore}>restore</button>
      </div> */}
    </>
  );
});

export const BotBuilderPlayground = ({
  botBuilderRef,
}: {
  botBuilderRef?: any;
}) => {
  return (
    <div className="w-full h-full flex flex-row">
      <ReactFlowProvider>
        <BotBuilder ref={botBuilderRef} />
      </ReactFlowProvider>
    </div>
  );
};
