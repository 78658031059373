import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Tooltip } from "antd";
import { ImageX } from "components/common/image";
import { useCallback, useEffect, useState } from "react";
import { ImageInputWithPreviewV2 } from "../../common/image-input-with-preview/image-input-with-preview";
import { uploadImageWithValidation } from "./upload-image-with-validation";

export const ImageInputWithUploader = ({
  value,
  onChange,
  type,
  entityId,
  width = 80,
  height = 80,
  allowClear,
}: {
  value?: any;
  onChange?: any;
  type: string;
  entityId: string;
  width?: number;
  height?: number;
  allowClear?: boolean;
}) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(value);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  useEffect(() => {
    setImageUrl(value);
  }, [value]);

  useEffect(() => {
    if (value) {
      setImageUrl(value);
    }
  }, [value]);

  const onImageUploaded = useCallback(
    async (logoUrl) => {
      if (onChange) {
        await onChange(logoUrl);
      }
    },
    [onChange],
  );

  const { doAction: uploadImage, isProcessing: isUploading } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (file) =>
          SDK.uploadFile(file, {
            type: type,
            entityId: entityId,
          })
            .then((fileRecord) => {
              setSelectedFile(undefined);
              setImageUrl(fileRecord.url);
              onImageUploaded(fileRecord.url);
            })
            .catch((e) => {
              setSelectedFile(undefined);
              setImageUrl(undefined);
              throw e;
            }),
      }),
      [entityId, onImageUploaded, type],
    );

  return (
    <div className="flex flex-row justify-end items-center">
      <ImageInputWithPreviewV2
        selectedFile={selectedFile}
        onChange={(file) => {
          setSelectedFile(file);
          uploadImageWithValidation(
            entityId,
            file,
            uploadImage,
            setSelectedFile,
          );
        }}
        isUploading={isUploading}
        className="my-4"
        image={
          imageUrl ? (
            <ImageX
              src={imageUrl}
              style={{
                width: "auto",
                height: "auto",
                maxWidth: width,
                maxHeight: height,
                objectFit: "contain",
              }}
            />
          ) : undefined
        }
        shape="square"
        width={width}
        height={height}
      />
      {allowClear && imageUrl && (
        <Tooltip title="Clear Image">
          <Button
            type="text"
            icon={<i className="ri-delete-bin-line"></i>}
            onClick={() => onChange(null)}
          ></Button>
        </Tooltip>
      )}
    </div>
  );
};
