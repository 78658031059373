import { useSDK } from "@sdk";
import { Button } from "antd";
import { SimpleCollapse } from "components/common/simple-collapse/simple-collapse";
import { useMemo } from "react";
import { GoogleReviews } from "./google-places-reviews";
import { SimpleGallery } from "./simple-itinerary-gallery";

export const GoogleMapsPlaceCard = ({ placeId }: { placeId: string }) => {
  const { data, isLoading, error, reload } = useSDK(
    SDK => SDK.getPlaceDetails(placeId),
    [placeId],
    !placeId
  );

  const imageUrls = useMemo(() => {
    return (data?.result?.photos || [])
      .filter(item => item.width > item.height)
      .map(
        record =>
          `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${record.photo_reference}&key=AIzaSyDgKBIsjuhnfxjV-7Vfv8q7P59AWTVopLo`
      )
      .slice(0, 6);
  }, [data?.result?.photos]);

  if (!data.result) {
    return <></>;
  }
  return (
    <div className="my-8">
      <div className="flex flex-row">
        <div className="right flex-1">
          <div className="text-2xl">{data.result.name}</div>
          <div className="address">
            <i className="ri-global-line"></i> {data.result.formatted_address}
          </div>
          <div className="phone">
            <i className="ri-map-pin-line"></i>{" "}
            {data.result.formatted_phone_number}
          </div>
          <div className="rating flex flex-row">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-yellow-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Rating star</title>
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            <p className="ml-2 text-sm font-bold text-gray-900 dark:text-white">
              {data.result.rating}
            </p>
            <span className="w-1 h-1 mx-1.5 bg-gray-500 rounded-full dark:bg-gray-400"></span>
            <a
              href="#"
              className="text-sm font-medium text-gray-900  dark:text-white"
            >
              {data.result.user_ratings_total} ratings
            </a>
          </div>
          <div className="actions my-4">
            <Button
              type="text"
              target="_blank"
              href={data.result.website}
              icon={<i className="ri-global-line"></i>}
              referrerPolicy="no-referrer"
            >
              Website
            </Button>
            <Button
              type="text"
              target="_blank"
              icon={<i className="ri-map-pin-line"></i>}
              href={`https://www.google.com/maps/search/?api=1&query_place_id=ChIJM5ab7UBZ4joRDjyehWiBZbA&query=Cinnamon%20Grand%20Colombo`}
              referrerPolicy="no-referrer"
            >
              Location
            </Button>
          </div>
        </div>
        <div className="left flex-1">
          <SimpleGallery imageUrls={imageUrls} />
        </div>
      </div>
      <SimpleCollapse title={"Read Reviews"}>
        <div className="reviews my-12">
          <GoogleReviews reviews={data.result.reviews} />
        </div>
      </SimpleCollapse>
    </div>
  );
};
