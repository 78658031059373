import { useMemo, useState } from "react";

export const ReadMoreContent = ({ content }: { content: string }) => {
  const [expanded, setExpanded] = useState(false);

  const visibleContent = useMemo(() => {
    if (expanded) {
      return content;
    }
    return (content || "").substring(0, 200);
  }, [content, expanded]);

  return (
    <>
      {visibleContent}
      {visibleContent !== content && (
        <>
          {!expanded && "...."}
          {!expanded && (
            <a onClick={() => setExpanded(true)} className="cursor-pointer">
              Read More
            </a>
          )}
        </>
      )}
    </>
  );
};
