import { SectionWithSideMenu } from "components/common/section-with-side-menu";
import { iNavigationMenuItem } from "components/common/side-bar-menu/menu-item-mode";
import { SimpleMenuSectionTitle } from "components/common/side-bar-menu/simple-menu-section-title";

import { ManagePipelines } from "components/modules/conversations/components/pipelines/manage-pipelines";
import { CustomCRMProperties } from "./sections/custom-crm-properties/custom-crm-properties";
import { GeneralCRMSettings } from "./sections/general-crm-settings";

const CRMSettingsMenu: iNavigationMenuItem[] = [
  {
    type: "CUSTOM_SECTION",
    key: "crm-settings",
    element: (
      <SimpleMenuSectionTitle className="mb-8">
        CRM Settings
      </SimpleMenuSectionTitle>
    )
  },
  {
    type: "LINK_ITEM",
    key: "general",
    icon: <i className="ri-inbox-line"></i>,
    label: "General",
    component: GeneralCRMSettings
  },
  {
    type: "LINK_ITEM",
    key: "manage-pipelines",
    icon: <i className="ri-git-branch-line rotate-90"></i>,
    label: "Manage Pipelines",
    component: ManagePipelines
  },
  {
    type: "CUSTOM_SECTION",
    key: "extend",
    element: (
      <SimpleMenuSectionTitle className="mt-8 mb-8">
        Extend
      </SimpleMenuSectionTitle>
    )
  },
  {
    type: "LINK_ITEM",
    key: "custom-properties",
    icon: <i className="ri-play-list-add-line"></i>,
    label: "Custom Properties",
    component: CustomCRMProperties
  },
  {
    type: "CUSTOM_SECTION",
    key: "extend",
    element: (
      <img
        src="/assets/illustrations/emails-animate.svg"
        width={250}
        alt="Email"
      />
    )
  }
];

export const CRMSettings = () => {
  return (
    <SectionWithSideMenu
      menu={CRMSettingsMenu}
      baseUrl={`/configurations/crm-settings`}
      defaultRoute={`/configurations/crm-settings/general`}
    />
  );
};
