import { getTokens } from "utils/extract-tokens";
import { containsNonLatinCodePoints } from "utils/has-unicode";

export const GenSMSMetaData = (message: string) => {
  const hasUnicode = containsNonLatinCodePoints(message);
  const extractedToken = getTokens(message);
  const messageLength = message.length + extractedToken.length * 7; // Estimation of for additional characters
  const maximumMessageSize = hasUnicode ? 70 : 160;
  const numberOfSMS = Math.ceil(messageLength / maximumMessageSize);
  const hasExceededMaximumMessageSize = messageLength / maximumMessageSize > 1;
  const messagePartLength = hasUnicode
    ? hasExceededMaximumMessageSize
      ? 67
      : 70
    : hasExceededMaximumMessageSize
    ? 153
    : 160;
  return {
    hasUnicode,
    maximumMessageSize,
    numberOfSMS,
    hasExceededMaximumMessageSize,
    messagePartLength,
    messageLength
  };
};
