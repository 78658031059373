import { useCallback } from "react";
import { useReactFlow } from "react-flow-renderer";
import { AvailableNodes } from "../data-model";

export const useSetNode = <NodeData>(nodeId: string) => {
  const {
    getNode,
    setNodes,
    setEdges,
    getNodes,
    getEdges,
    getEdge
  } = useReactFlow<AvailableNodes, any>();

  const setNode = useCallback(
    (data: Partial<NodeData>) => {
      setNodes(
        getNodes().map(node => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                ...data
              } as any
            };
          }
          return node;
        })
      );
    },
    [setNodes, getNodes, nodeId]
  );

  return setNode;
};
