import MailOutlined from "@ant-design/icons/MailOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDK } from "@sdk";
import { iEmailConnectionData } from "@sdk/conversations/conversations.models";
import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Avatar,
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Space,
  Spin
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { UserAvatar } from "components/common/avatar/avatar";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { AddEmailConnection, ConfigurePersonalMailbox } from "modal-registry";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import {
  selectCurrentUser,
  selectCurrentUserId
} from "store/modules/users/users.selectors";
import {
  selectFeatureRestrictionConfig,
  selectOrganization
} from "store/modules/workspace/workspace.selectors";
import { debouncedEmailAvailabilityWithCurrentEmail } from "../../helpers/email-availability";
import "./user-profile-modal.scss";

export const MyProfileModal = ({
  visible,
  onChangeVisibility,
  onEdited
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onEdited?: () => any;
}) => {
  const [form] = useForm();
  const user = useSelector(selectCurrentUser);

  const currentUserId = useSelector(selectCurrentUserId);
  const currentOrganization = useSelector(selectOrganization);
  const connections = useSelector(selectAllConnections);

  const personalEmailConnection = useMemo(() => {
    return connections.find(
      item =>
        item.type === "EMAIL" &&
        (item.data as iEmailConnectionData).isPersonal &&
        (item.data as iEmailConnectionData).ownerId === currentUserId
    );
  }, [connections, currentUserId]);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        email: user.credentials.email,
        isInternalUser: user.data.isInternalUser,
        role: getRoleFromPermission(user.permissions)
      });
    }
  }, [form, user]);
  // Edit Function
  const { doAction: onSubmit, isProcessing, response } = useSDKActionWithDeps(
    () => ({
      action: SDK => data =>
        SDK.editUser(currentUserId, data).then(res => {
          setVisibleSection("HOME");
          onEdited && onEdited();
        }),
      successMessage: "Profile has been Edited",
      failureMessage: "Something went wrong"
    }),
    [onEdited, currentUserId]
  );

  const { data: groups } = useSDK(SDK => SDK.getAllGroups(), [], false, []);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const changeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0]!;
    if (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }
      if (isJpgOrPng && isLt2M) {
        setSelectedFile(file);
        uploadAvatar(file);
      }
    }
  };
  const {
    doAction: uploadAvatar,
    isProcessing: isUploading
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => file =>
        SDK.uploadFile(file, {
          type: "USER",
          entityId: user.id
        })
          .then(fileRecord => {
            setSelectedFile(undefined);
            return SDK.editUser(currentUserId, {
              data: {
                avatar: fileRecord.url
              }
            });
          })
          .catch(e => {
            setSelectedFile(undefined);
            throw e;
          }),
      successMessage: "Avatar has been updated",
      failureMessage: "Something went wrong"
    }),
    [user.id, currentUserId]
  );

  const {
    doAction: resetPassword,
    isProcessing: isResetPasswordProcessing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (password: string) =>
        SDK.editUser(currentUserId, {
          credentials: {
            password: password
          }
        }).then(res => {
          setVisibleSection("HOME");
          onEdited && onEdited();
        }),
      successMessage: "Password has been reset",
      failureMessage: "Something went wrong"
    }),
    [onEdited, currentUserId]
  );

  const [visibleSection, setVisibleSection] = useState<
    "HOME" | "EDIT_DETAILS" | "EDIT_EMAIL" | "EDIT_PASSWORD" | "EDIT_USER_TYPE"
  >("HOME");

  const { changePanelState } = useModalPanels();

  const featureRestrictionConfig = useSelector(selectFeatureRestrictionConfig);

  const currentUser = useSelector(selectCurrentUser);
  const userRole = useMemo(() => {
    const currentUserRole = getRoleFromPermission(
      currentUser?.permissions || []
    );
    return currentUserRole;
  }, [currentUser?.permissions]);

  return (
    <>
      <Modal
        title={
          <ModalTitle
            title="Profile"
            icon={<i className="ri-shield-user-line"></i>}
          />
        }
        open={visible}
        onCancel={() => {
          onChangeVisibility(false);
        }}
        okText="Save"
        footer={null}
        bodyStyle={{ padding: 0 }}
        width={400}
        data-click-context="User Profile Modal"
      >
        <div className="container flex flex-row p-10">
          {/* <div
          style={{ minWidth: 350 }}
          className="justify-center items-center flex flex-col"
        >
          <img
            src="/assets/illustrations/profile-data-animate.svg"
            alt="User Profile"
          />
        </div> */}
          {visibleSection === "HOME" && (
            <div
              className="content flex-1 overflow-auto"
              // style={{ maxHeight: 450, minHeight: 450 }}
            >
              <div className="flex flex-row justify-center items-center mb-4 pt-12">
                <div className="edit-user-avatar">
                  {selectedFile && (
                    <Spin
                      spinning={isUploading}
                      indicator={<LoadingIndicatorWithSpin />}
                    >
                      <Avatar
                        size={80}
                        src={URL.createObjectURL(selectedFile)}
                      />
                    </Spin>
                  )}
                  {!selectedFile && (
                    <UserAvatar userId={currentUserId} size={80} />
                  )}
                  <input type="file" onChange={changeAvatar} />
                  <div className="upload-icon">
                    <i className="ri-upload-2-line"></i>
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-center items-center pt-8">
                <Space direction="vertical" size="large">
                  <Button
                    size="large"
                    onClick={() => setVisibleSection("EDIT_DETAILS")}
                    style={{ width: 300 }}
                    icon={<i className="ri-pencil-line"></i>}
                  >
                    Edit Profile Details
                  </Button>

                  <Button
                    size="large"
                    onClick={() => setVisibleSection("EDIT_EMAIL")}
                    style={{ width: 300 }}
                    icon={<i className="ri-mail-settings-line"></i>}
                    disabled={
                      featureRestrictionConfig?.["CHANGE_SELF_EMAIL"]?.[
                        userRole
                      ]
                    }
                  >
                    Change Email
                  </Button>
                  <Button
                    size="large"
                    onClick={() => setVisibleSection("EDIT_PASSWORD")}
                    style={{ width: 300 }}
                    icon={<i className="ri-lock-line"></i>}
                  >
                    Change Password
                  </Button>
                  <Button
                    size="large"
                    onClick={() => setVisibleSection("EDIT_USER_TYPE")}
                    style={{ width: 300 }}
                    icon={<i className="ri-user-line"></i>}
                  >
                    Edit User Type
                  </Button>
                  {personalEmailConnection && (
                    <Button
                      size="large"
                      onClick={() => {
                        changePanelState(ConfigurePersonalMailbox, true, {});
                      }}
                      style={{ width: 300 }}
                      icon={<i className="ri-mail-line"></i>}
                    >
                      Configure Personal Email
                    </Button>
                  )}
                  {!personalEmailConnection && (
                    <Button
                      size="large"
                      onClick={() => {
                        changePanelState(AddEmailConnection, true, {
                          isPrivate: true
                        });
                      }}
                      style={{ width: 300 }}
                      icon={<i className="ri-mail-line"></i>}
                    >
                      Connect Personal Email
                    </Button>
                  )}
                </Space>
              </div>
            </div>
          )}

          {visibleSection === "EDIT_DETAILS" && (
            <div
              className="content flex-1 overflow-auto"
              style={{ maxHeight: 450, minHeight: 450 }}
            >
              <div className="row">
                <Button
                  icon={<i className="ri-arrow-left-s-line"></i>}
                  onClick={() => setVisibleSection("HOME")}
                >
                  Back
                </Button>
              </div>

              <Form layout="vertical" form={form} style={{ padding: 24 }}>
                {/* First Name */}

                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!"
                    }
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="First name"
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!"
                    }
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Last name"
                    size="large"
                  />
                </Form.Item>
              </Form>
              <div className="flex flex-row justify-end items-center p-4">
                <Button
                  type="primary"
                  onClick={async () => {
                    try {
                      await form.validateFields();
                      const values = form.getFieldsValue();
                      onSubmit({
                        data: {
                          firstName: values.firstName,
                          lastName: values.lastName
                        }
                      });
                    } catch (e) {
                      message.error("Please check your input");
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )}

          {visibleSection === "EDIT_EMAIL" && (
            <div
              className="content flex-1 overflow-auto"
              style={{ maxHeight: 450, minHeight: 450 }}
            >
              <div className="row">
                <Button
                  icon={<i className="ri-arrow-left-s-line"></i>}
                  onClick={() => setVisibleSection("HOME")}
                >
                  Back
                </Button>
              </div>
              <Form layout="vertical" form={form} style={{ padding: 24 }}>
                <Form.Item
                  name="email"
                  hasFeedback
                  validateFirst={true}
                  rules={[
                    { type: "email" },
                    ({ getFieldValue }) => ({
                      validator: async (rule, value) =>
                        await debouncedEmailAvailabilityWithCurrentEmail(
                          user.credentials.email,
                          value,
                          currentOrganization?.id
                        )
                    })
                  ]}
                  label="Email"
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                    size="large"
                  />
                </Form.Item>
              </Form>
              <div className="flex flex-row justify-end items-center p-4">
                <Button
                  type="primary"
                  onClick={async () => {
                    try {
                      await form.validateFields();
                      const values = form.getFieldsValue();
                      onSubmit({
                        credentials: {
                          email: values.email
                        }
                      });
                    } catch (e) {
                      message.error("Please check your input");
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )}

          {visibleSection === "EDIT_PASSWORD" && (
            <div
              className="content flex-1 overflow-auto"
              style={{ maxHeight: 450, minHeight: 450 }}
            >
              <div className="row">
                <Button
                  icon={<i className="ri-arrow-left-s-line"></i>}
                  onClick={() => setVisibleSection("HOME")}
                >
                  Back
                </Button>
              </div>
              <Form layout="vertical" form={form}>
                <Spin
                  spinning={isUploading}
                  indicator={<LoadingIndicatorWithSpin />}
                >
                  <div
                    className="password-reset-box flex flex-row p-2 bg-gray-100  dark:bg-gray-700  rounded-md mt-4 pt-6 mode_transition"
                    style={{ border: "1px solid #e4e4e4" }}
                  >
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!"
                        }
                      ]}
                      hasFeedback
                      className="flex-1"
                    >
                      <Input.Password placeholder="Password" size="large" />
                    </Form.Item>

                    <Button
                      size="large"
                      style={{ borderLeft: 0, borderRadius: "0 5px 5px 0" }}
                      onClick={async () => {
                        try {
                          await form.validateFields();
                          resetPassword(form.getFieldValue("password"));
                        } catch (e) {
                          message.error("Please check your input");
                        }
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </Spin>
              </Form>
            </div>
          )}
          {visibleSection === "EDIT_USER_TYPE" && (
            <div
              className="content flex-1 overflow-auto"
              style={{ maxHeight: 450, minHeight: 450 }}
            >
              <div className="row">
                <Button
                  icon={<i className="ri-arrow-left-s-line"></i>}
                  onClick={() => setVisibleSection("HOME")}
                >
                  Back
                </Button>
              </div>
              <Form layout="vertical" form={form} style={{ padding: 24 }}>
                <Form.Item name="isInternalUser" valuePropName="checked">
                  <Checkbox>Internal user</Checkbox>
                </Form.Item>
              </Form>
              <div className="flex flex-row justify-end items-center p-4">
                <Button
                  type="primary"
                  onClick={() => {
                    const values = form.getFieldsValue();
                    onSubmit({
                      data: {
                        isInternalUser: values.isInternalUser
                      }
                    });
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
        <DarkModeBg />
      </Modal>
    </>
  );
};
