import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectChatSideBarConfig } from "store/modules/workspace/workspace.selectors";
import { useChatBarAvailableSections } from "./use-chat-sidebar-available-sections";

export const useChatBarSections = () => {
  const currentConfig = useSelector(selectChatSideBarConfig);

  const _sectionHeadings = useChatBarAvailableSections();

  const sectionHeadings = useMemo(
    () => _sectionHeadings.map(item => ({ ...item, id: item.section })),
    [_sectionHeadings]
  );

  const initialSectionOrder = useMemo(() => {
    if (currentConfig?.sections && currentConfig?.sections.length > 0) {
      const newSectionsToAppend = sectionHeadings.filter(
        e => !currentConfig?.sections.includes(e.id)
      );
      return [
        ...currentConfig?.sections.map(id => {
          const section = sectionHeadings.find(item => item.id === id);
          return section;
        }),
        ...newSectionsToAppend
      ].filter(e => e) as typeof sectionHeadings;
    }

    return sectionHeadings;
  }, [currentConfig?.sections, sectionHeadings]);

  const returnValue = useMemo(
    () => ({
      hiddenSections: currentConfig?.hiddenSections,
      allSections: initialSectionOrder,
      visibleSections: initialSectionOrder.filter(
        item => !(currentConfig?.hiddenSections || []).includes(item.id)
      )
    }),
    [currentConfig?.hiddenSections, initialSectionOrder]
  );

  return returnValue;
};
