import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React, { Suspense } from "react";

const EditorJS = React.lazy(() => import("./editor-js"));

export const LazyEditorJS = (props: {
  entityId?: string;
  entityType?: string;
  editorJSRef?: any;
  instanceId?: string;
}) => {
  return (
    <Suspense fallback={<SuspenseLoadingIndicator />}>
      <EditorJS {...props} />
    </Suspense>
  );
};
