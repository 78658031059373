import {
  iHolidayQuote,
  iWhatsIncludedItem,
} from "@sdk/holiday-quotes/holiday-quotes-model";
import _ from "lodash";
import {
  iFlightItineraryItem,
  iHotelItineraryItem,
  iItineraryBlock,
  iWhatsIncludedBlock,
} from "../itineries-model";

export const GenerateWhatsIncluded = (
  whatsIncluded: iWhatsIncludedBlock["data"],
  holidayQuote: iHolidayQuote,
) => {
  const flightBlock = (holidayQuote.data.blocks as iItineraryBlock[]).find(
    (item) => item.type === "FLIGHT_ITINERARY",
  ) as iFlightItineraryItem;

  const { inboundSegments, outboundSegments } = (() => {
    if (flightBlock && flightBlock.data.flightDetailsInputMethod !== "MANUAL") {
      const flightSegments = flightBlock.data.flightData!.flights;
      const returnFlightSegmentIndex = flightSegments.findIndex(
        (item) => (item.flt?.transit_time?.days || 0) > 2,
      );

      const outboundSegments = flightSegments.slice(
        0,
        returnFlightSegmentIndex + 1,
      );
      const inboundSegments = flightSegments.slice(
        returnFlightSegmentIndex + 1,
      );
      return { outboundSegments, inboundSegments };
    }
    return { outboundSegments: [], inboundSegments: [] };
  })();

  const _hotelBlocks = (holidayQuote.data.blocks as iItineraryBlock[]).filter(
    (item) => item.type === "HOTEL_ITEM",
  ) as iHotelItineraryItem[];

  const hotelBlocksToInclude = (() => {
    if (!whatsIncluded.includedHotel || whatsIncluded.includedHotel === "ALL") {
      return _hotelBlocks;
    }
    if (whatsIncluded.includedHotel === "ANY") {
      return _hotelBlocks;
    }
    const hotelBlock = _hotelBlocks.find(
      (item) => item.id === whatsIncluded.includedHotel,
    );
    if (hotelBlock) {
      return [hotelBlock];
    }
    return [];
  })();

  // Todo: Add Timestamp to each item and then order it
  return [
    ...(outboundSegments.length > 0
      ? [
          {
            type: "FLIGHT",
            title: `${_.first(outboundSegments)?.dep.airportcode} to ${_.last(
              outboundSegments,
            )?.arr.airportcode}`,
            description: `${_.first(outboundSegments)?.flt.departure.string}`,
          },
        ]
      : [
          {
            type: "FLIGHT",
            title: `Flights Included`,
            description: `Check the flight itinerary above for details`,
          },
        ]),
    ...(hotelBlocksToInclude || []).map(
      (hotelBlock, index) =>
        ({
          type: "HOTEL",
          title: `${
            whatsIncluded.includedHotel === "ANY" ? `Option: ${index + 1}` : ``
          } ${hotelBlock.data.hotel?.name}`,
          description: `${hotelBlock.data.checkIn} | ${hotelBlock.data.noOfNights} Nights | ${hotelBlock.data.roomType}`,
        }) as iWhatsIncludedItem,
    ),
    ...(inboundSegments.length > 0
      ? [
          {
            type: "FLIGHT",
            title: `${_.first(inboundSegments)?.dep.airportcode} to ${_.last(
              inboundSegments,
            )?.arr.airportcode}`,
            description: `${_.first(inboundSegments)?.flt.departure.string}`,
          },
        ]
      : []),
  ] as iWhatsIncludedItem[];
};
