import { Form, Input } from "antd";
import { ConnectionDataEditor } from "../connection-data-editor";

export const ChannelNameForm = ({ connectionId }: { connectionId }) => {
  return (
    <ConnectionDataEditor
      connectionId={connectionId}
      icon={"ri-folder-info-line"}
      title="Connection Name"
      description="Change this connection's name"
    >
      <Form.Item
        label="Channel Name"
        name="label"
        rules={[{ required: true, message: "Please enter a friendly name" }]}
        help="A friendly name to identify channel Eg: Support Email / Sales Email"
      >
        <Input placeholder="" size="large" />
      </Form.Item>
    </ConnectionDataEditor>
  );
};
