import { Image } from "antd";

export const SimpleGallery = ({ imageUrls }: { imageUrls: string[] }) => {
  return (
    <div className="container mx-auto">
      <div className="-m-1 flex flex-wrap md:-m-2">
        {imageUrls.map(url => (
          <div className="flex w-1/3 flex-wrap" key={url}>
            <div className="w-full p-1 md:p-2">
              <Image
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src={url}
                style={{ width: 150, maxHeight: 120 }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
