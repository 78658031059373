import { iPNRConverterResponse } from "@sdk/app-service/pnr-cerverter-response-model";
import { iWhatsIncludedItem } from "@sdk/holiday-quotes/holiday-quotes-model";
import { iPlaceData_City } from "./place-data-city-model";
import { iPlaceData_Hotel } from "./place-data-hotel-model";

export type iItineraryBlockType =
  | "FLIGHT_ITINERARY"
  | "HOTEL_ITEM"
  | "FINE_PRINT_ITEM"
  | "SIMPLE_DETAILS"
  | "PRICE_SUMMARY"
  | "SHARE_ITINERARY_BLOCK"
  | "PASSENGER_DETAILS"
  | "MAP_BLOCK"
  | "DESTINATION_SUMMARY_BLOCK"
  | "AGENT_CONTACT"
  | "FEEDBACK_RATING"
  | "CONFIRM_AND_PAY"
  | "WHATS_INCLUDED";

export type iItineraryBlock =
  | iFlightItineraryItem
  | iHotelItineraryItem
  | iItineraryFinePrintItem
  | iItinerarySimpleDetails
  | iItineraryPriceSummary
  | iItineraryShareBlock
  | iItineraryPassengerDetails
  | iItineraryMapBlock
  | iDestinationSummaryBlock
  | iAgentContactBlock
  | iFeedbackRatingBlock
  | iConfirmAndBlock
  | iWhatsIncludedBlock;

export const ItineraryBlockTypeList: iItineraryBlockType[] = [
  "FLIGHT_ITINERARY",
  "HOTEL_ITEM",
  "FINE_PRINT_ITEM",
  "SIMPLE_DETAILS",
  "PRICE_SUMMARY",
  "SHARE_ITINERARY_BLOCK",
  "PASSENGER_DETAILS",
  "MAP_BLOCK",
  "DESTINATION_SUMMARY_BLOCK",
  "AGENT_CONTACT",
  "FEEDBACK_RATING",
  "CONFIRM_AND_PAY"
  // "WHATS_INCLUDED",
];

export const ItineraryBlockTypeMap: Record<
  iItineraryBlockType,
  { label: string; icon: JSX.Element }
> = {
  FLIGHT_ITINERARY: {
    label: "Flight Details",
    icon: <i className="ri-flight-takeoff-line"></i>
  },
  HOTEL_ITEM: {
    label: "Hotel Details",
    icon: <i className="ri-hotel-line"></i>
  },
  FINE_PRINT_ITEM: {
    label: "Fine Print",
    icon: <i className="ri-list-check"></i>
  },
  SIMPLE_DETAILS: {
    label: "Additional Details",
    icon: <i className="ri-file-list-line"></i>
  },
  PRICE_SUMMARY: {
    label: "Price Summary",
    icon: <i className="ri-money-pound-box-line"></i>
  },
  SHARE_ITINERARY_BLOCK: {
    label: "Share Itinerary",
    icon: <i className="ri-share-line"></i>
  },
  PASSENGER_DETAILS: {
    label: "Passenger Details",
    icon: <i className="ri-group-2-line"></i>
  },
  MAP_BLOCK: { label: "Map", icon: <i className="ri-map-pin-line"></i> },
  DESTINATION_SUMMARY_BLOCK: {
    label: "Destination Highlight",
    icon: <i className="ri-pushpin-2-line"></i>
  },
  AGENT_CONTACT: {
    label: "Contact",
    icon: <i className="ri-mail-send-line"></i>
  },
  FEEDBACK_RATING: {
    label: "Feedback & Rating",
    icon: <i className="ri-star-smile-line"></i>
  },
  CONFIRM_AND_PAY: {
    label: "Confirm Quote",
    icon: <i className="ri-secure-payment-line"></i>
  },
  WHATS_INCLUDED: {
    label: "What's Included",
    icon: <i className="ri-list-check-3"></i>
  }
};

export interface iItineraryBlockBase {
  id: string;
  type: iItineraryBlockType;
  data: any;
}

export interface iFlightItineraryItem extends iItineraryBlockBase {
  type: "FLIGHT_ITINERARY";
  data: {
    flightDetailsInputMethod?: "PNR" | "MANUAL";
    // Manual
    flightDetailsScreenshots?: string[];
    lengthOfTrip?: number;
    cities?: iPlaceData_Hotel[];
    flightData?: iPNRConverterResponse["flightData"];
    // Using PNR
    pnr?: string;
    itineraryStyle?: string;
  };
}

export interface iHotelItineraryItem extends iItineraryBlockBase {
  type: "HOTEL_ITEM";
  data: {
    placeId: string;
    hotel: iPlaceData_Hotel;
    checkIn: string;
    noOfNights: number;
    roomType: string;
    notes: string;
  };
}

export interface iItineraryFinePrintItem extends iItineraryBlockBase {
  type: "FINE_PRINT_ITEM";
  data: {
    finePrints: string[];
  };
}

export interface iItinerarySimpleDetails extends iItineraryBlockBase {
  type: "SIMPLE_DETAILS";
  data: {
    content: string;
  };
}

export interface iItineraryPriceSummary extends iItineraryBlockBase {
  type: "PRICE_SUMMARY";
  data: iPriceSummaryData;
}

export interface iItineraryShareBlock extends iItineraryBlockBase {
  type: "SHARE_ITINERARY_BLOCK";
  data: {};
}

export interface iItineraryPassengerDetails extends iItineraryBlockBase {
  type: "PASSENGER_DETAILS";
  data: {
    passengers: iPassengerDetails[];
  };
}

export interface iItineraryMapBlock extends iItineraryBlockBase {
  type: "MAP_BLOCK";
  data: {
    placeObj: GoogleMapPlacesObj;
  };
}

export interface iDestinationSummaryBlock extends iItineraryBlockBase {
  type: "DESTINATION_SUMMARY_BLOCK";
  data: {
    placeId: string;
    name: string;
    label: string;
    destination: iPlaceData_City;
    weatherUrl: string;
  };
}

export interface iAgentContactBlock extends iItineraryBlockBase {
  type: "AGENT_CONTACT";
  data: {
    data: {
      firstName: string;
      lastName: string;
      avatar: string;
      bio: string;
      description: string;
    };
    credentials: {
      email: string;
    };
  };
}

export interface iFeedbackRatingBlock extends iItineraryBlockBase {
  type: "FEEDBACK_RATING";
  data: {};
}

export interface iConfirmAndBlock extends iItineraryBlockBase {
  type: "CONFIRM_AND_PAY";
  data: {
    paymentLink: string;
    contact: {
      data: {
        firstName: string;
        lastName: string;
        avatar: string;
        bio: string;
        description: string;
      };
      credentials: {
        email: string;
      };
    };
  };
}

export interface iWhatsIncludedBlock extends iItineraryBlockBase {
  type: "WHATS_INCLUDED";
  data: {
    whatsIncluded: iWhatsIncludedItem[];
    includedHotel?: string;
  };
}

export interface iPassengerDetails {
  title: string;
  firstName: string;
  surName: string;
  dateOfBirth: number;
  passportNumber?: string;
  // Not Used
  frequentFlyerProgramme?: string;
  frequentFlyerNumber?: string;
  email?: string;
  phoneNumber?: string;
  sendReminders?: boolean;
}

export interface iPriceSummaryData {
  price: string;
  currency: string;
  currencySymbol?: string;
  includedHotel?: string;
  discountText: string;
  whatsIncluded: iWhatsIncludedItem[];
}

export interface GoogleMapPlacesObj {
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
  place_id: string;
  html_attributions: any[];
}

//
