import { ReportSchema } from "./backend-report-schema-model";

export const ContactsReportsBackendSchema: ReportSchema = {
  filters: {
    organizationId: {
      type: "EQUALITY",
      path: "organizationId"
    },
    owner: {
      type: "EQUALITY",
      path: "owner"
    },
    company: {
      type: "EQUALITY",
      path: "company"
    },
    marketingLists: {
      type: "IN",
      path: "marketingLists.id"
    },
    marketingConsentObtained: {
      type: "EQUALITY",
      path: "preference.marketingConsentObtained"
    },
    isVerified: {
      type: "EQUALITY",
      path: "metaData.isVerified"
    },
    tagsIn: {
      type: "IN",
      path: "data.tags"
    },
    tags: {
      type: "ALL",
      path: "data.tags"
    },
    connectionId: {
      type: "EQUALITY",
      path: "metaData.connections.connectionId"
    },
    connectionType: {
      type: "EQUALITY",
      path: "metaData.connections.type"
    },
    primaryEmail: {
      type: "EQUALITY",
      path: "data.primaryEmail"
    },
    primaryMobile: {
      type: "EQUALITY",
      path: "data.primaryMobile"
    },
    country: {
      type: "EQUALITY",
      path: "data.country"
    }
  },
  dateFilterTypes: {
    createdTime: "metaData.createdTime",
    lastActivity: "metaData.lastActivity.timestamp",
    lastContactByContact: "metaData.lastContact.byContact"
  },
  // Todo: Wherever there is an array, unwind is required
  groupByOptions: {
    owner: {
      path: "owner"
    },
    marketingConsentObtained: {
      path: "preference.marketingConsentObtained"
    },
    tags: {
      path: "data.tags",
      unwinds: ["data.tags"]
    },
    connections: {
      path: "metaData.connections.connectionId",
      unwinds: ["connections"]
    },
    connectionType: {
      path: "metaData.connections.type",
      unwinds: ["connections"]
    },
    country: {
      path: "data.country"
    },
    emailExists: {
      path: { $gt: ["$data.primaryEmail", null] },
      preTasks: []
    },
    mobileExists: {
      path: { $gt: ["$data.primaryMobile", null] },
      preTasks: []
    }
  },
  unwinds: {
    connections: {
      path: "metaData.connections",
      postTasks: []
    }
  }
};
