import { SDK } from "@sdk";
import { iContact } from "@sdk/crm/crm.models";
import _ from "lodash";
import { Store } from "redux";
import { store } from "store/store";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { contactsDefaultQueryConfig } from "./contacts-default-query";
import { selectContactsMap } from "./contacts.selectors";
import { ContactsSlice, setContacts } from "./contacts.slice";

export const loadAllContacts = (store: Store<iStore>, forceReload?: boolean) =>
  loadContactsQuery(contactsDefaultQueryConfig, "all")(store, forceReload);

export const contactQueryPageSize = 20;

export const {
  loadEntityQueries: loadContactsQuery,
  loadMoreEntityQueries: loadMoreContactsQuery,
  loadEntityById: loadContactById,
  loadEntitiesByIds: bulkLoadContactsByIds
} = CreateHelpersForEntity<iStore, iContact>({
  sliceName: "contacts",
  slice: ContactsSlice,
  isPaginatedQueries: true,
  fetchPageSize: contactQueryPageSize,
  loadEntityQueryProvider: query => SDK.queryContacts(query),
  loadEntityByIdProvider: (entityId: string) => SDK.getContactById(entityId),
  loadEntityByIdsProvider: (contactIds: string[]) =>
    (async () => {
      const loadedContacts = Object.keys(selectContactsMap(store.getState()));
      const contactsToLoad = _.without(contactIds, ...loadedContacts);
      const contacts = await SDK.getContactByIds(contactsToLoad);
      return contacts;
    })()
});

export const refreshAllContacts = (store: Store<iStore>) => async (
  contactId?: string[]
) => {
  const loadedContacts =
    contactId || Object.keys(selectContactsMap(store.getState()));
  const chunksToLoad = _.chunk(loadedContacts, 200);
  for (const chunk of chunksToLoad) {
    const data = await SDK.queryContacts({
      query: { id: { $in: chunk } },
      options: {
        limit: 201
      }
    });
    store.dispatch(setContacts(data.docs));
  }
  return {
    status: true
  };
};

export const loadContactsByCompanyId = (companyId: string) => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  const queryConfig = {
    ...contactsDefaultQueryConfig,
    query: {
      companyId
    }
  };
  const queryAlias = JSON.stringify(queryConfig);
  await loadContactsQuery(queryConfig, queryAlias)(store, forceReload);
};
