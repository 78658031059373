import { Statistic } from "antd";
import { SimpleCardSection } from "components/common/simple-carded-section";

export const HomePageCard = ({
  innerClassName,
  className,
  children
}: {
  innerClassName?: string;
  className?: string;
  children;
}) => {
  return (
    <SimpleCardSection className="max-w-screen-lg  xxl:max-w-screen-xl flex flex-col p-8 w-full mx-auto">
      {children}
    </SimpleCardSection>
  );
  // return (
  //   <div
  //     className={classnames(
  //       "flex flex-row justify-center items-center p-8 w-full",
  //       className
  //     )}
  //   >
  //     <div
  //       className={classnames(
  //         "bg-white dark:bg-gray-900 rounded-lg border dark:border-gray-800 border-gray-200 px-10 py-2 w-full max-w-screen-xl",
  //         innerClassName
  //       )}
  //     >
  //       {children}
  //     </div>
  //   </div>
  // );
};

export const HomePageCardHeader = ({
  icon,
  title
}: {
  icon: JSX.Element;
  title: string;
}) => (
  <div className="title font-bold text-xl flex flex-row justify-start items-center my-4 mb-8">
    {icon} {title}
  </div>
);

export const StatisticWidget = ({
  title,
  value,
  icon,
  onClick,
  label
}: {
  title: string;
  value: number;
  icon: JSX.Element;
  onClick: () => any;
  label?: string;
}) => {
  return (
    <div
      className="hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer p-2 rounded-lg mr-4"
      onClick={onClick}
    >
      <Statistic
        title={title}
        value={`${value} ${label || ""}`}
        prefix={icon}
      />
    </div>
  );
};
