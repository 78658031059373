import produce from "immer";
import { iFAQStoreState } from "./faq-model";
const initialState: iFAQStoreState = {
  collections: {
    byIds: {},
    list: []
  },
  faqs: {
    byIds: {}
  }
};

export const reducer = (state: iFAQStoreState, action) => {
  switch (action.type) {
    case "ADD_FAQ":
      return produce(state, draft => {
        draft.faqs.byIds[action.payload.faq.id] = action.payload.faq;
        draft.collections.byIds[action.payload.collectionId].faqs.push(
          action.payload.faq.id
        );
      });
    case "EDIT_FAQ":
      return produce(state, draft => {
        draft.faqs.byIds[action.payload.faq.id] = action.payload.faq;
      });
    case "DELETE_FAQ":
      return produce(state, draft => {
        const {
          [action.payload.faqId]: removeFaq,
          ...restFaqs
        } = draft.faqs.byIds;
        draft.faqs.byIds = restFaqs;
        const filteredFaqIds = draft.collections.byIds[
          action.payload.collectionId
        ].faqs.filter(item => item !== action.payload.faqId);
        draft.collections.byIds[
          action.payload.collectionId
        ].faqs = filteredFaqIds;
      });
    case "REORDER_FAQ":
      return produce(state, draft => {
        draft.collections.byIds[action.payload.collectionId].faqs =
          action.payload.faqs;
      });
    case "ADD_FAQ_COLLECTION":
      return produce(state, draft => {
        draft.collections.byIds[action.payload.collection.id] =
          action.payload.collection;
        draft.collections.list.push(action.payload.collection.id);
      });
    case "EDIT_FAQ_COLLECTION":
      return produce(state, draft => {
        draft.collections.byIds[action.payload.collection.id].label =
          action.payload.collection.label;
      });
    case "REMOVE_FAQ_COLLECTION":
      return produce(state, draft => {
        const {
          [action.payload.collectionId]: removeCollection,
          ...restCollections
        } = draft.collections.byIds;
        draft.collections.byIds = restCollections;
        //check these things for mutations???????????????????????????????
        const filteredCollectionIds = draft.collections.list.filter(
          item => item !== action.payload.collectionId
        );
        draft.collections.list = filteredCollectionIds;
      });
    case "REORDER_FAQ_COLLECTION":
      return produce(state, draft => {
        draft.collections.list = action.payload.list;
      });
    default:
      throw new Error();
  }
};
