import { WidgetTypes } from "@sdk/scheduled-reports/scheduled-reports";

export const EmailReportWidgets: WidgetTypes[] = [
  "TOTAL_CONVERSATIONS",
  "CONVERSATIONS_BY_STATUS",
  "CONVERSATIONS_BY_TAGS",
  // "CONVERSATIONS_BY_TAGS_GROUP",
  // "CONVERSATIONS_BY_TEAMS",
  // "CONVERSATIONS_HEAT_MAP",
  "CONVERSATIONS_BY_USERS",
  "CONVERSATIONS_BY_USERS_GROUPS",
  // "CONVERSATIONS_BY_USERS_TAGS",
  "CONVERSATIONS_BY_CONNECTIONS",
  "CONVERSATIONS_BY_SOURCE",
  "CONVERSATIONS_BY_FACEBOOK_AD",
  "CONVERSATIONS_BY_FACEBOOK_POST",
  // "CONVERSATIONS_BY_RESPONSE_TIME",
  "CONVERSATIONS_BY_MESSAGE_COUNT"
  // "CONVERSATIONS_TAGS_BY_TAGS_WIDGET",
  // "USER_PERFORMANCE_REPORT"
];
