import { Button, Timeline } from "antd";
import { MDIIcon } from "components/common/mdi-icon";
import { UserPill } from "components/modules/user-management/users/components/user-pill/user-pill";
import dayjs from "dayjs";

import { mdiFormatSize, mdiWebRemove } from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";
import { loadAllActivitiesOfContact } from "store/modules/activities/activities.helpers";
import { selectActivityContactId } from "store/modules/activities/activities.selectors";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { AddEllipsis } from "utils/add-ellipsis";

import { iActivity } from "@sdk/crm/crm.models";
import { SimpleTable } from "components/modules/conversations/components/chat-view-side-bar/components/connector-side-bar";
import { push } from "connected-react-router";
import _ from "lodash";
import { useMemo } from "react";
import { flattenObject } from "utils/flatten-object";
import "./activities-viewer.scss";

export type iActivityType =
  | "CALL"
  | "EMAIL"
  | "VISIT"
  | "ONLINE_MEETING"
  | "CONVERSATION_STARTED"
  | "CONVERSATION_CLOSED"
  | "CONVERSATION_RE_OPENED"
  | "EMAIL_RECEIVED"
  | "EMAIL_SENT"
  | "ENGAGEMENT_ADDED"
  | "EMAIl_OPENED_CAMPAIGN"
  | "EMAIl_DELIVERED_CAMPAIGN"
  | "EMAIl_UNSUBSCRIBED_CAMPAIGN"
  | "EMAIl_COMPLAINED_CAMPAIGN"
  | "EMAIl_CLICKED_CAMPAIGN"
  | "EMAIl_PERMANENT_FAIL_CAMPAIGN"
  | "EMAIl_TEMPORARY_FAIL_CAMPAIGN"
  | "EMAIl_OPENED_AUTOMATION_WORKFLOW"
  | "EMAIl_DELIVERED_AUTOMATION_WORKFLOW"
  | "EMAIl_UNSUBSCRIBED_AUTOMATION_WORKFLOW"
  | "EMAIl_COMPLAINED_AUTOMATION_WORKFLOW"
  | "EMAIl_CLICKED_AUTOMATION_WORKFLOW"
  | "EMAIl_PERMANENT_FAIL_AUTOMATION_WORKFLOW"
  | "EMAIl_TEMPORARY_FAIL_AUTOMATION_WORKFLOW"
  | "CALENDLY_EVENT_SCHEDULED"
  | "FORM_SUBMITTED"
  | "PAGE_VISIT"
  | "CALL_BACK_REQUEST"
  | "CUSTOM_EVENT";

export const AllActivityTypes: iActivityType[] = [
  "CALL",
  "EMAIL",
  "VISIT",
  "ONLINE_MEETING",
  "CONVERSATION_STARTED",
  "CONVERSATION_CLOSED",
  "CONVERSATION_RE_OPENED",
  "EMAIL_RECEIVED",
  "EMAIL_SENT",
  "ENGAGEMENT_ADDED",
  "EMAIl_OPENED_CAMPAIGN",
  "EMAIl_DELIVERED_CAMPAIGN",
  "EMAIl_UNSUBSCRIBED_CAMPAIGN",
  "EMAIl_COMPLAINED_CAMPAIGN",
  "EMAIl_CLICKED_CAMPAIGN",
  "EMAIl_PERMANENT_FAIL_CAMPAIGN",
  "EMAIl_TEMPORARY_FAIL_CAMPAIGN",
  "EMAIl_OPENED_AUTOMATION_WORKFLOW",
  "EMAIl_DELIVERED_AUTOMATION_WORKFLOW",
  "EMAIl_UNSUBSCRIBED_AUTOMATION_WORKFLOW",
  "EMAIl_COMPLAINED_AUTOMATION_WORKFLOW",
  "EMAIl_CLICKED_AUTOMATION_WORKFLOW",
  "EMAIl_PERMANENT_FAIL_AUTOMATION_WORKFLOW",
  "EMAIl_TEMPORARY_FAIL_AUTOMATION_WORKFLOW",
  "CALENDLY_EVENT_SCHEDULED",
  "FORM_SUBMITTED",
  "PAGE_VISIT",
  "CALL_BACK_REQUEST",
  "CUSTOM_EVENT",
];

export const ActivityIconMap = {
  CALL: <i className="ri-phone-fill"></i>,
  EMAIL: <i className="ri-mail-fill"></i>,
  VISIT: <i className="ri-walk-line"></i>,
  ONLINE_MEETING: <i className="ri-calendar-event-line"></i>,
  CONVERSATION_STARTED: <i className="ri-chat-new-line"></i>,
  CONVERSATION_CLOSED: <i className="ri-chat-download-line"></i>,
  CONVERSATION_RE_OPENED: <i className="ri-chat-follow-up-line"></i>,
  EMAIL_RECEIVED: <i className="ri-mail-line"></i>,
  EMAIL_SENT: <i className="ri-mail-send-line"></i>,
  ENGAGEMENT_ADDED: <i className="ri-calendar-todo-line"></i>,
  EMAIl_OPENED_CAMPAIGN: <i className="ri-mail-open-line"></i>,
  EMAIl_DELIVERED_CAMPAIGN: <i className="ri-mail-send-line"></i>,
  EMAIl_UNSUBSCRIBED_CAMPAIGN: <i className="ri-mail-forbid-line"></i>,
  EMAIl_COMPLAINED_CAMPAIGN: <MDIIcon icon={mdiWebRemove} />,
  EMAIl_CLICKED_CAMPAIGN: <i className="ri-cursor-line"></i>,
  EMAIl_PERMANENT_FAIL_CAMPAIGN: <i className="ri-subtract-line"></i>,
  EMAIl_TEMPORARY_FAIL_CAMPAIGN: <i className="ri-subtract-line"></i>,
  EMAIl_OPENED_AUTOMATION_WORKFLOW: <i className="ri-mail-open-line"></i>,
  EMAIl_DELIVERED_AUTOMATION_WORKFLOW: <i className="ri-mail-send-line"></i>,
  EMAIl_UNSUBSCRIBED_AUTOMATION_WORKFLOW: (
    <i className="ri-mail-forbid-line"></i>
  ),
  EMAIl_COMPLAINED_AUTOMATION_WORKFLOW: <MDIIcon icon={mdiWebRemove} />,
  EMAIl_CLICKED_AUTOMATION_WORKFLOW: <i className="ri-cursor-line"></i>,
  EMAIl_PERMANENT_FAIL_AUTOMATION_WORKFLOW: (
    <i className="ri-subtract-line"></i>
  ),
  EMAIl_TEMPORARY_FAIL_AUTOMATION_WORKFLOW: (
    <i className="ri-subtract-line"></i>
  ),
  CALENDLY_EVENT_SCHEDULED: <i className="ri-calendar-line"></i>,
  FORM_SUBMITTED: <MDIIcon icon={mdiFormatSize} size={"1.5rem"} />,
  PAGE_VISIT: <i className="ri-walk-line"></i>,
  CALL_BACK_REQUEST: <i className="ri-phone-fill"></i>,
  CUSTOM_EVENT: <i className="ri-send-plane-2-line"></i>,
};

export const ActivityLabelMap = {
  CALL: "Call Made",
  EMAIL: "Email Sent",
  VISIT: "Visit Made",
  ONLINE_MEETING: "Online Meeting Held",
  CONVERSATION_STARTED: "Conversation Started",
  CONVERSATION_CLOSED: "Conversation Closed",
  CONVERSATION_RE_OPENED: "Conversation Re-opened",
  EMAIL_RECEIVED: "Email Received",
  EMAIL_SENT: "Email Sent",
  ENGAGEMENT_ADDED: "Engagement Added",
  EMAIl_OPENED_CAMPAIGN: "Campaign Email Opened",
  EMAIl_DELIVERED_CAMPAIGN: "Campaign Email Delivered",
  EMAIl_UNSUBSCRIBED_CAMPAIGN: "Unsubscribed Campaign Email",
  EMAIl_COMPLAINED_CAMPAIGN: "Complained Campaign Email",
  EMAIl_CLICKED_CAMPAIGN: "Clicked Campaign Email",
  EMAIl_PERMANENT_FAIL_CAMPAIGN: "Campaign Email Failed Permanently",
  EMAIl_TEMPORARY_FAIL_CAMPAIGN: "Campaign Email Failed Temporarily",
  EMAIl_OPENED_AUTOMATION_WORKFLOW: "Workflow Email Opened",
  EMAIl_DELIVERED_AUTOMATION_WORKFLOW: "Workflow Email Delivered",
  EMAIl_UNSUBSCRIBED_AUTOMATION_WORKFLOW: "Unsubscribed Workflow Email",
  EMAIl_COMPLAINED_AUTOMATION_WORKFLOW: "Complained Workflow Email",
  EMAIl_CLICKED_AUTOMATION_WORKFLOW: "Clicked Workflow Email",
  EMAIl_PERMANENT_FAIL_AUTOMATION_WORKFLOW: "Workflow Email Failed Permanently",
  EMAIl_TEMPORARY_FAIL_AUTOMATION_WORKFLOW: "Workflow Email Failed Temporarily",
  PAGE_VISIT: "Visited Web Page",
  CALENDLY_EVENT_SCHEDULED: "Calendly Form Submission",
  FORM_SUBMITTED: "Form Submitted",
  CALL_BACK_REQUEST: "Call back request submitted",
  CUSTOM_EVENT: "Custom Event",
};

export const ActivitiesViewer = ({ contactId }: { contactId?: string }) => {
  const contact = useSelector(selectContactById(contactId!));

  const {
    state: contactActivities,
    isLoading: isActivitiesLoading,
  } = useQueryWithStore(
    selectActivityContactId(contactId!),
    loadAllActivitiesOfContact(contactId!),
    [contactId],
    !contactId
  );

  const activityList = contactActivities?.list || [];

  const dispatch = useDispatch();

  const activitiesList = useMemo(() => {
    return activityList.map((activity, index) => ({
      dot: (
        <div className="text-2xl mt-8">
          {(activity.type && ActivityIconMap[activity.type]) || (
            <i className="ri-checkbox-blank-circle-fill"></i>
          )}
        </div>
      ),
      children: <ActivityListItem activity={activity} key={activity.id} />,
    }));
  }, [activityList]);

  return (
    <>
      {!((activityList.length || -1) > 0) && (
        <div className="no-content flex flex-col justify-center items-center p-4">
          <i className="ri-walk-fill text-3xl"></i>
          <div className="text-gray-700 dark:text-gray-200 text-sm mode_transition">
            No Activities Found
          </div>
        </div>
      )}
      {(activityList.length || -1) > 0 && <Timeline items={activitiesList} />}
    </>
  );
};

export const ActivityListItem = ({ activity }: { activity: iActivity }) => {
  const dispatch = useDispatch();

  const calendarEventId = useMemo(() => {
    if (activity?.data?.event?.uri) {
      return _.last(activity?.data?.event.uri.split("/"));
    }
    return "";
  }, [activity?.data?.event?.uri]);

  const { columns, rows } = useMemo(() => {
    const flattenedData = flattenObject(
      _.omit(activity.data || {}, "page", "formId", "formName", "formTarget")
    );
    return {
      columns: ["Label", "Value"],
      rows: Object.keys(flattenedData).map((key) => ({
        Label: key,
        Value: activity.data[key],
      })),
    };
  }, [activity?.data]);

  return (
    <div>
      <div className="title flex flex-row items-start justify-between">
        <div className="font-bold flex flex-row items-center">
          {activity.type
            ? ActivityLabelMap[activity.type]
            : "Unidentified Activity"}
          {activity.createdBy && (
            <>
              {" "}
              by <UserPill userId={activity.createdBy} />
            </>
          )}
        </div>
        {activity?.data?.conversationId && (
          <div className="flex flex-row justify-end">
            <Button
              type="text"
              icon={<i className="ri-eye-line"></i>}
              onClick={() =>
                dispatch(
                  push(`/conversations/auto/${activity?.data?.conversationId}`)
                )
              }
            >
              View Conversation
            </Button>
          </div>
        )}
        {/* Todo */}
        {activity?.data?.callbackRequestId && (
          <div className="flex flex-row justify-end">
            <Button
              type="text"
              icon={<i className="ri-eye-line"></i>}
              onClick={() => {
                // Todo
              }}
            >
              View
            </Button>
          </div>
        )}
      </div>
      <div className="text-gray-600 text-sm">
        {dayjs(activity.timestamp).format("dddd, h:mm:ss a - MMMM Do YYYY")}
      </div>

      {activity?.data?.event?.uri && (
        <div className="text-gray-600">Event ID - {calendarEventId}</div>
      )}
      {activity.type === "FORM_SUBMITTED" && (
        <>
          <div className="p-4">
            <SimpleTable rows={rows} columns={columns} />
          </div>
        </>
      )}

      {activity?.data?.description && (
        <div className="text-gray-600">{activity?.data?.description}</div>
      )}

      {activity.notes && (
        <div className="border border-gray-200 shadow-lg p-4">
          <div className="title font-bold text-gray-600">Note</div>
          <div className="body">{activity.notes}</div>
        </div>
      )}
      {activity.data?.page && (
        <div className="flex flex-col">
          <div className="title">
            Page{" : "}
            <a href={activity.data?.page.url} target="_blank" rel="noreferrer">
              {AddEllipsis(activity.data?.page.title, 50)}
            </a>
          </div>
        </div>
      )}

      {activity.data?.landedPage && (
        <div className="flex flex-col">
          <div className="title">
            Visited{" "}
            <a
              href={activity.data.landedPage.url}
              target="_blank"
              rel="noreferrer"
            >
              {AddEllipsis(activity.data.landedPage.title, 50)}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
