import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Form, Input, message, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback } from "react";
import { SingleUserSelector } from "../../../../user-management/users/components/single-user-selector/single-user-selector";
import { MultiContactSelector } from "../../../contacts/components/multi-contact-selector/multi-contact-selector";

const { Option } = Select;

export const AddCompanyModal = ({
  visible,
  onChangeVisibility,
  onCreated,
  hideOnCreate,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated?: (company: any) => any;
  hideOnCreate?: boolean;
}) => {
  const defaultFormValue = {};
  const [addCompanyForm] = useForm();

  // Create Call
  const {
    doAction: createCompany,
    isProcessing: isActionProcessing,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.companies.create(values).then((d) => {
          onChangeVisibility(false);
          onCreated && onCreated(d.id);
        }),
      successMessage: "Done",
      failureMessage: "Something went wrong",
    }),
    [onChangeVisibility, onCreated]
  );

  const saveCompany = useCallback(async () => {
    try {
      await addCompanyForm.validateFields();
    } catch (e) {
      message.warning("Please check your input");
      return;
    }

    const formValues = addCompanyForm.getFieldsValue();

    createCompany({
      ...formValues,
    });
  }, [addCompanyForm, createCompany]);

  return (
    <Modal
      title={
        <ModalTitle
          title="New Company Record"
          icon={<i className="ri-building-line"></i>}
        />
      }
      open={visible}
      onOk={async () => {
        saveCompany();
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okButtonProps={{ loading: isActionProcessing }}
      okText={"Save"}
      data-click-context="Add Company Modal"
    >
      <Form
        layout="vertical"
        form={addCompanyForm}
        initialValues={defaultFormValue}
        className="bold-form-labels"
      >
        {/* Name */}
        <Form.Item label="Name" name={["data", "name"]}>
          <Input placeholder="" size="large" />
        </Form.Item>
        {/* Default Owner */}
        <Form.Item name="owner" label={"Account Owner"} rules={[]}>
          <SingleUserSelector />
        </Form.Item>
        {/* Associated Contacts */}
        <Form.Item name="contacts" label={"Associated Contacts"} rules={[]}>
          <MultiContactSelector />
        </Form.Item>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
