import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCampaignById } from "store/modules/campaigns/campaigns.selectors";
import { EmailCampaignLogsViewer } from "../campaign-logs/email-campaign-logs";

export const ViewCampaignLogsModal = ({
  visible,
  onChangeVisibility,
  campaignId,
  logType
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  campaignId: string;
  logType: string;
}) => {
  const campaign = useSelector(selectCampaignById(campaignId));

  const query = useMemo(
    () => ({
      "event.event": logType
    }),
    [logType]
  );

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-folder-chart-line"></i>}
          title="Campaign Logs"
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Campaign Logs Report Modal"
      width={870}
    >
      {campaignId && (
        <EmailCampaignLogsViewer
          logType={logType}
          query={query}
          campaignId={campaignId}
        />
      )}

      <DarkModeBg />
    </Modal>
  );
};
