import JsonURL from "@jsonurl/jsonurl";
import { find, uniq, uniqBy } from "lodash";

import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { uuidv4 } from "utils/generate-uuid";
import { useUrlState } from "utils/hooks/use-url-state";
import { ModalsStateContext } from "./modal-state-context";

export interface iModalPanelRegistryItem<T extends unknown> {
  name: string;
  component: FC<
    {
      visible: boolean;
      onChangeVisibility: (state: boolean) => any;
    } & T
  >;
}

export interface iModalState {
  name: string;
  visible: any;
}

export const TemporaryModalContainer = ({
  Component,
  onPortalDestroyed,
  ...props
}) => {
  const [isModalVisible, setModalVisibility] = useState(true);
  const [isModalDestroyed, destroyModal] = useState(false);
  useEffect(() => {
    if (!isModalVisible) {
      const timer = setTimeout(() => {
        destroyModal(true);
        onPortalDestroyed && onPortalDestroyed();
      }, 400);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isModalVisible, onPortalDestroyed]);
  if (isModalDestroyed) {
    return <></>;
  }
  return (
    <Component
      {...props}
      visible={isModalVisible}
      onChangeVisibility={setModalVisibility}
    />
  );
};
export const ModalsPanelsRegistry = ({
  modals,
}: {
  modals: iModalPanelRegistryItem<any>[];
}) => {
  const {
    activePortals,
    changePanelState,
    changePanelStateWithName,
  } = useModalPanels();

  const { state, setState } = React.useContext(ModalsStateContext);
  const [activePortalHistory, setActivePortalHistory] = useState(
    [] as string[]
  );

  useEffect(() => {
    const activePortalNames = activePortals.map((item) => item.name);
    setActivePortalHistory((portals) =>
      uniq([...portals, ...activePortalNames])
    );
  }, [activePortals]);

  const currentModals = useMemo(() => {
    return modals.map((modal) => {
      const modalState = find(activePortals, { name: modal.name })!;
      const ModalComponent = modal.component;
      if (!modalState?.visible && !activePortalHistory.includes(modal.name)) {
        return <React.Fragment key={modal.name}></React.Fragment>;
      }
      return (
        <ModalComponent
          key={modal.name}
          {...modalState}
          onChangeVisibility={(state) =>
            changePanelStateWithName(modal.name, state)
          }
        />
      );
    });
  }, [activePortalHistory, activePortals, changePanelStateWithName, modals]);

  const temporaryModals = useMemo(() => {
    return state.map((item) => {
      const modalRecord = find(modals, { name: item.name })!;
      const ModalComponent = modalRecord.component;
      return (
        <TemporaryModalContainer
          key={item.id}
          {...item}
          Component={ModalComponent}
          onPortalDestroyed={() => {
            setState((existingPortals) =>
              existingPortals.filter((itemX) => itemX.id !== item.id)
            );
          }}
        />
      );
    });
  }, [modals, setState, state]);

  return (
    <>
      {currentModals.map((modal) => modal)}
      {temporaryModals.map((modal) => modal)}
    </>
  );
};

export const ModalPanels: iModalPanelRegistryItem<any>[] = [];

export const useModalPanels = () => {
  const [portalsStateString, setPortalStateString] = useUrlState("portals");
  const { state, setState } = React.useContext(ModalsStateContext);

  const activePortals = useMemo(() => {
    if (!portalsStateString) {
      return [] as iModalState[];
    }
    try {
      // * For some odd reason, + are converted back to spaced. Probably by the useUrlState function
      const _portalsStateString = portalsStateString.replace(" ", "+");
      let value = JsonURL.parse(_portalsStateString || "()", { AQF: true });
      if (Array.isArray(value)) {
        return value as iModalState[];
      }
    } catch (e) {
      console.log("e", e);
      // Nothing
    }

    return [] as iModalState[];
  }, [portalsStateString]);

  const activePortalsRef = useRef({ activePortals });

  useLayoutEffect(() => {
    activePortalsRef.current = { activePortals };
  }, [activePortals]);

  const setPortalsState = useCallback(
    (portals: iModalState[]) => {
      let string = JsonURL.stringify(portals, {
        AQF: true,
      });
      if (string) {
        return setPortalStateString(string);
      }
      return setPortalStateString("");
    },
    [setPortalStateString]
  );

  const changePanelStateWithName = useCallback(
    (
      panelName: string,
      visibility: boolean,
      state: Record<string, string | number> = {}
    ) => {
      setPortalsState(
        uniqBy(
          [
            { name: panelName, visible: visibility, ...state },
            ...activePortalsRef.current.activePortals,
          ],
          "name"
        ).filter((item) => item.visible)
      );
    },
    [setPortalsState]
  );

  const changePanelState = useCallback(
    <T extends {}>(
      panel: iModalPanelRegistryItem<T>,
      visibility: boolean,
      state: T
    ) => {
      changePanelStateWithName(panel.name, visibility, state);
    },
    [changePanelStateWithName]
  );

  const triggerTempModal = useCallback(
    <T extends {}>(panel: iModalPanelRegistryItem<T>, state: T) => {
      setState((existingPanels) => [
        ...existingPanels,
        {
          id: uuidv4(),
          name: panel.name,
          ...state,
        },
      ]);
    },
    [setState]
  );

  return {
    changePanelStateWithName,
    changePanelState,
    activePortals,
    triggerTempModal,
  };
};
