import { SectionWithSideMenu } from "components/common/section-with-side-menu";
import { iNavigationMenuItem } from "components/common/side-bar-menu/menu-item-mode";
import { SimpleMenuSectionTitle } from "components/common/side-bar-menu/simple-menu-section-title";

import { CustomConversationProperties } from "components/modules/conversations/components/custom-conversation-properties/custom-conversation-properties";
import { ManageMacros } from "components/modules/conversations/components/macros/manage-macros";
import { ManageChatActions } from "components/modules/conversations/components/manage-chat-connectors/manage-chat-actions";
import { ManageChatSidePanel } from "components/modules/conversations/components/manage-chat-connectors/manage-chat-side-panel";
import { ManagePipelines } from "components/modules/conversations/components/pipelines/manage-pipelines";
import { GeneralInboxSettings } from "./sections/general-inbox-settings";

const InboxSettingsMenu: iNavigationMenuItem[] = [
  {
    type: "CUSTOM_SECTION",
    key: "inbox-settings",
    element: (
      <SimpleMenuSectionTitle className="mb-8">
        Inbox Settings
      </SimpleMenuSectionTitle>
    )
  },
  {
    type: "LINK_ITEM",
    key: "general",
    icon: <i className="ri-inbox-line"></i>,
    label: "General",
    component: GeneralInboxSettings
  },
  {
    type: "LINK_ITEM",
    key: "manage-pipelines",
    icon: <i className="ri-git-branch-line rotate-90"></i>,
    label: "Manage Pipelines",
    component: ManagePipelines
  },
  {
    type: "LINK_ITEM",
    key: "macros",
    icon: <i className="ri-magic-line"></i>,
    label: "Macros",
    component: ManageMacros
  },
  {
    type: "CUSTOM_SECTION",
    key: "extend",
    element: (
      <SimpleMenuSectionTitle className="mt-8 mb-8">
        Extend
      </SimpleMenuSectionTitle>
    )
  },
  {
    type: "LINK_ITEM",
    key: "custom-properties",
    icon: <i className="ri-play-list-add-line"></i>,
    label: "Custom Properties",
    component: CustomConversationProperties
  },
  {
    type: "LINK_ITEM",
    key: "chat-actions",
    icon: <i className="ri-send-plane-2-line"></i>,
    label: "Chat Actions",
    component: ManageChatActions
  },
  {
    type: "LINK_ITEM",
    key: "chat-side-bar-panels",
    icon: <i className="ri-side-bar-line rotate-180"></i>,
    label: "Chat Side Bar Panels",
    component: ManageChatSidePanel
  },
  {
    type: "CUSTOM_SECTION",
    key: "extend",
    element: (
      <img
        src="/assets/illustrations/emails-animate.svg"
        width={250}
        alt="Email"
      />
    )
  }
];

export const InboxSettings = () => {
  return (
    <SectionWithSideMenu
      menu={InboxSettingsMenu}
      baseUrl={`/configurations/inbox-settings`}
      defaultRoute={`/configurations/inbox-settings/general`}
    />
  );
};
