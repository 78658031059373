import { SDK } from "@sdk";
import { iMessage } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { message, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { RcFile } from "antd/lib/upload";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { ReplyComponent } from "components/common/reply-input/reply-input";
import { MultiContactSelector } from "components/modules/crm/contacts/components/multi-contact-selector-v2/multi-contact-selector";
import { useMemo, useRef, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { justWait } from "utils/just-wait";

const tokenSeparators = [",", ";"];

export const MessageForwarder = ({
  message: messageObj,
  onSent,
}: {
  message?: iMessage;
  onSent: () => any;
}) => {
  const [form] = useForm();

  const currentUserId = useSelector(selectCurrentUserId);

  const replyComponentRef = useRef<any>();
  const {
    enableAttachments,
    enableEmojis,
    enableNotes,
    enableRichText,
    defaultSelectedFromId,
    disableSenderSelection,
    fromIds,
    userList,
    slashCommands,
    messageCharLimitCalculator,
    messageValidator,
  } = useMemo(() => {
    const messageValidator = (message: string) => {
      const validationMessages: {
        message: string;
        type: any;
      }[] = [];
      return validationMessages;
    };
    const messageCharLimitCalculator = (message: string) => {
      return {
        current: message.length,
        limit: 0,
      };
    };
    const fromIds = [];
    const defaultSelectedFromId = "";
    return {
      messageValidator,
      messageCharLimitCalculator,
      enableEmojis: true,
      enableNotes: false,
      enableAttachments: true,
      fromIds,
      defaultSelectedFromId,
      disableSenderSelection: true,
      maximumContactLimit: 1,
      maximumContactLimitAlertText:
        "Emails can only have only one primary contact",
      mediumOfSend: "EMAIL",
      enableRichText: true,
      slashCommands: [],
      userList: [],
    };
  }, []);

  const uploadFile = async (file: RcFile) => {
    await justWait(3000);
    const fileRecord = await SDK.uploadFile(file, {
      type: "NEW_MESSAGE",
      entityId: currentUserId,
    });
    return fileRecord.url;
  };

  const {
    doAction: forwardEmail,
    isProcessing,
    response,
    dispatch,
  } = useSDKActionWithDeps(
    () => ({
      action:
        (SDK) =>
        (req: { emails: string[]; body: string; attachments: string[] }) =>
          SDK.forwardMessage(messageObj?.id!, req).then((d) => {
            onSent();
          }),
      successMessage: "Done",
      failureMessage: "Something went wrong",
      actionDependencies: [],
    }),
    [messageObj?.id, onSent],
  );

  const [contacts, setContacts] = useState<any[]>([]);
  const store = useStore();

  return (
    <Spin spinning={isProcessing} indicator={<LoadingIndicatorWithSpin />}>
      <div className="">
        {/* <Form layout="vertical" form={form} initialValues={{}}>
          <Form.Item
            label={<div className="font-bold">Email</div>}
            name={["emails"]}
            hasFeedback={false}
            style={{ marginBottom: 0, marginLeft: 5 }}
            rules={[emailValidatorRuleForSelect]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Add Emails"
              dropdownStyle={{ display: "none" }}
              allowClear
              tokenSeparators={tokenSeparators}
              // onChange={handleChange}
            ></Select>
          </Form.Item>
        </Form> */}
        <div className="recipient mt-4 font-bold">Contacts</div>
        <MultiContactSelector
          selectedContacts={contacts}
          setSelectedContacts={setContacts}
          requiredData={"EMAIL"}
        />
        <div className="reply-component-container mt-8">
          <ReplyComponent
            enableEmojis={enableEmojis}
            enableNotes={false}
            enableAttachments={enableAttachments}
            enableRichText={enableRichText}
            defaultSelectedFromId={defaultSelectedFromId}
            ref={replyComponentRef}
            onSend={async (req) => {
              console.log("On Send");
              // try {
              //   await form.validateFields();
              // } catch (e) {
              //   message.warning("Please check your input");
              //   return;
              // }
              // const { emails } = form.getFieldsValue();
              if (!contacts || !contacts?.length) {
                message.error("Please select a contact");
                return;
              }
              const rootState = store.getState();
              const toEmails = contacts
                .map((option) => option.value)
                .map((contactId) => {
                  const contact = selectContactById(contactId)(rootState);
                  return contact?.data?.primaryEmail;
                })
                .filter((e) => e) as string[];

              if (!toEmails || !toEmails?.length) {
                message.error("No valid emails found");
                return;
              }
              forwardEmail({
                emails: toEmails,
                body: req.text,
                attachments: req.attachments,
              });
            }}
            attachmentUploader={uploadFile}
            fromIds={fromIds}
            messageValidator={messageValidator}
            messageCharLimitCalculator={messageCharLimitCalculator}
            slashCommands={slashCommands}
            userList={userList}
            disableSenderSelection={disableSenderSelection}
            sendMessageOnEnter={false}
            replyHeaderText="Message"
            // additionalTabBarContent={(mode) => {
            //   if (mode === "RICH_TEXT") {
            //     return <AddPresetButton />;
            //   }
            //   if (mode === "REPLY") {
            //     return <AddPresetButton />;
            //   }
            //   return <></>;
            // }}
          />
        </div>
      </div>
    </Spin>
  );
};
