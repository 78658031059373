export const setArrayItemWithId = (
  direction: string,
  items: string[],
  currentItemId: string,
  setArrayItemFunction: (id: string) => any
) => {
  let i = items.indexOf(currentItemId);

  if (direction === "previous" && i > 0) {
    setArrayItemFunction(items[i - 1]!);
    // message.info(successMessage);
  }
  if (direction === "next" && i < items.length - 1) {
    setArrayItemFunction(items[i + 1]!);
    // message.info(successMessage);
  }
};

export const setArrayItem = (
  direction: string,
  items: any[],
  currentItem: string,
  setArrayItemFunction: (id: string) => any
) => {
  const currentItemObject = items.find(
    (conversation) => conversation.id === currentItem
  );

  let i = currentItemObject ? items.indexOf(currentItemObject) : -1;

  if (direction === "previous" && i > 0) {
    setArrayItemFunction(items[i - 1].id!);
    // message.info(successMessage);
  }
  if (direction === "next" && i < items.length - 1) {
    setArrayItemFunction(items[i + 1].id!);
    // message.info(successMessage);
  }
};
