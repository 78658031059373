import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { iArticleCollection } from "@sdk/knowledge-base/knowledge-base.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Tabs, TabsProps } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { SectionHeader } from "components/common/section-header";
import { useState } from "react";
import { DeepPartial } from "redux";
import { loadAllArticleCollections } from "store/modules/article-collection/article-collection.helpers";
import { selectAllArticleCollections } from "store/modules/article-collection/article-collection.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { ArticleEditorModal } from "./article-editor/article-editor.modal";
import { ArticlePreviewModal } from "./article-preview/article-preview.modal";
import { ArticleList } from "./collections/article-list";
import { CollectionAddForm } from "./collections/components/collection-add-form.component";
import SingleCollection from "./collections/components/single-collection.component";
import { ImageSelector } from "./collections/image-selector/image-selector";

export const KnowledgeBaseManageCollectionsInner = () => {
  // Collections and Articles

  const { state: collections, retry: reload } = useQueryWithStore(
    selectAllArticleCollections,
    loadAllArticleCollections,
  );

  const [isAddFormVisible, setIsAddFormVisible] = useState(false);

  const [showImageSelector, setShowImagePicker] = useState(false);
  const [currentSelection, setCurrentSelection] = useState("");
  const [articleEditorState, setArticleEditorState] = useSimpleState({
    visible: false,
    articleId: "",
    collectionId: "",
  });

  const [articlePreviewState, setArticlePreviewState] = useSimpleState({
    visible: false,
    articleId: "",
  });

  const {
    doAction: editCollection,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values: DeepPartial<iArticleCollection>) =>
        SDK.collections.editById(values.id!, values).then((res) => {
          //
        }),
      successMessage: "Collection has been edited",
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const [activeTab, setActiveTab] = useState("COLLECTIONS");

  const items: TabsProps["items"] = [
    {
      key: "COLLECTIONS",
      label: <div className="font-semibold">Collections</div>,
      children: (
        <>
          <div className="collections-container flex  flex-col">
            <div className="mt-6 w-full" style={{ maxWidth: 1000 }}>
              {collections.map((collection, index) => (
                <SingleCollection
                  collection={collection}
                  index={index}
                  key={collection.id!}
                  onAddArticle={() => {
                    setArticleEditorState({
                      articleId: "",
                      visible: true,
                      collectionId: collection.id,
                    });
                  }}
                  onChangeIcon={() => {
                    setCurrentSelection(collection.id!);
                    setShowImagePicker(true);
                  }}
                  onEditArticle={(articleId) => {
                    setArticleEditorState({
                      articleId: articleId,
                      visible: true,
                    });
                  }}
                  onPreviewArticle={(articleId) => {
                    setArticlePreviewState({
                      articleId: articleId,
                      visible: true,
                    });
                  }}
                />
              ))}
              {collections.length === 0 && (
                <EmptyData
                  text="No Collections available in Knowledge Base"
                  icon="ri-search-line"
                />
              )}
              <div
                className="p-4 border border-gray-600 border-dashed rounded-lg text-2xl flex flex-row justify-center items-center gap-2 text-gray-600 hover:bg-gray-200 dark:bg-gray-800 cursor-pointer mt-8"
                style={{ height: 100 }}
                onClick={() => {
                  setIsAddFormVisible(true);
                }}
              >
                <i className="ri-add-line"></i>
                Add Collection
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      key: "ARTICLES",
      label: <div className="font-semibold">Articles</div>,
      children: (
        <>
          <ArticleList
            onAddArticle={() => {
              setArticleEditorState({
                articleId: "",
                visible: true,
                collectionId: undefined,
              });
            }}
            onEditArticle={(articleId) => {
              setArticleEditorState({
                articleId: articleId,
                visible: true,
              });
            }}
            onPreviewArticle={(articleId) => {
              setArticlePreviewState({
                articleId: articleId,
                visible: true,
              });
            }}
            hideTitle={true}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      <SectionHeader
        title="Articles and Collections"
        description=" Collections are group of articles. You can create an article or a
        document under categories"
        icon={<i className="ri-folder-line"></i>}
        addons={
          <>
            <Button
              icon={<PlusOutlined />}
              type={collections.length > 0 ? "dashed" : "primary"}
              onClick={() => {
                setIsAddFormVisible(true);
              }}
            >
              Add Collection
            </Button>
            <CollectionAddForm
              visible={isAddFormVisible}
              onCreated={() => {
                // Todo
              }}
              onChangeVisibility={(visibility) => {
                setIsAddFormVisible(visibility);
              }}
            />
          </>
        }
      />
      <Divider />

      <Tabs activeKey={activeTab} items={items} onChange={setActiveTab} />

      <ImageSelector
        visible={showImageSelector}
        onChangeVisibility={(state) => setShowImagePicker(state)}
        singleSelection={true}
        onSelected={(file) => {
          console.log("file", file);
          editCollection({
            id: currentSelection,
            icon: file.url,
          });
          setShowImagePicker(false);
        }}
        reference={{
          type: "COLLECTION",
          entityId: currentSelection,
        }}
      />

      <ArticleEditorModal
        visible={articleEditorState.visible}
        onChangeVisibility={(state) =>
          setArticleEditorState({ visible: state, collectionId: "" })
        }
        collectionId={articleEditorState.collectionId}
        articleId={articleEditorState.articleId}
        onArticleCreated={async (articleId) => {
          setArticleEditorState({ articleId });
        }}
      />

      <ArticlePreviewModal
        visible={articlePreviewState.visible}
        onChangeVisibility={(state) =>
          setArticlePreviewState({ visible: state })
        }
        articleId={articlePreviewState.articleId}
      />
    </div>
  );
};
