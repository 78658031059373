import { Card, Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import classNames from "classnames";
import _ from "lodash";
import { useMemo } from "react";
import { loadAllUsers } from "store/modules/users/users.helpers";
import { selectAllActiveUsersQuery } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSearch } from "utils/hooks/use-search";
import { UserPill } from "../single-user-selector/user-selector-pill";
import "./multi-user-selector.scss";

const { Meta } = Card;

export const MultiUserSelector = ({
  value: selectedUsers = [],
  onChange: setSelectedUsers = () => {},
  size = "middle",
  simple
}: {
  value?: string[];
  onChange?: (ids: string[]) => any;
  size?: SizeType;
  simple?: boolean;
}) => {
  const {
    state: { list: _users, isLoading },
    retry: reload
  } = useQueryWithStore(selectAllActiveUsersQuery, loadAllUsers());

  const users = useMemo(
    () =>
      _users
        .filter(d => d.metaData)
        .map(user => ({
          id: user.id,
          name: `${user.data.firstName} ${user.data.lastName}`,
          email: user.credentials.email
        })),
    [_users]
  );

  const transformIdToNode = (ids: string[]) => {
    return ids.map(userId => {
      const user = _.find(users, { id: userId })!;
      if (!user) {
        return {
          key: userId,
          value: userId,
          label: "Unknown User Name -> Probably a bug"
        };
      }
      return {
        key: userId,
        value: userId,
        label: simple ? (
          user.name!
        ) : (
          <UserPill name={user.name!} email={user.email} />
        )
      };
    });
  };

  const { searchTerm, setSearchTerm, filteredData } = useSearch(users);

  return (
    <>
      <Select
        mode="multiple"
        labelInValue
        value={transformIdToNode(selectedUsers)}
        placeholder="Select Users"
        filterOption={false}
        onSearch={val => setSearchTerm(val)}
        onChange={(selectedUsers, option) => {
          setSelectedUsers(selectedUsers.map(item => item.value));
        }}
        style={{ width: "100%" }}
        size={size}
        className={classNames("multi-user-selector", {
          "with-pill": !simple
        })}
        allowClear={true}
        maxTagCount={2}
      >
        {filteredData.map(user => (
          <Select.Option key={user.id} value={user.id!}>
            {simple ? (
              user.name!
            ) : (
              <UserPill name={user.name!} email={user.email} />
            )}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};
