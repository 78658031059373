import classnames from "classnames";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import { loadContactById } from "store/modules/contacts/contacts.helpers";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { getContactName } from "../../helpers/get-contact-name";

export const ContactPill = ({
  contactId,
  withoutBg,
}: {
  contactId: string;
  withoutBg?: boolean;
}) => {
  const { state: contact, isLoading: isLoadingSelected } = useQueryWithStore(
    selectContactById(contactId!),
    loadContactById(contactId!)(),
    [contactId],
    !contactId
  );

  return (
    <div className="user-pill inline-flex flex-row justify-start items-center leading-normal">
      <div
        className={classnames(
          "flex-row flex justify-start items-center  rounded-lg px-4",
          {
            "bg-gray-100 dark:bg-gray-800": !withoutBg,
          }
        )}
      >
        <ContactAvatar contactId={contactId} size={24} />
        <div className="flex-col pl-3">
          <div className="font-bold">{getContactName(contact)} </div>
        </div>
      </div>
    </div>
  );
};
