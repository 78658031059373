import { mapCursorToMax } from "map-cursor-to-max";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

type UseKeyboardListNavigationAction =
  | { type: "RESET" }
  | { type: "INTERACT" }
  | { type: "PREV" }
  | { type: "NEXT" }
  | { type: "FIRST" }
  | { type: "LAST" }
  | { type: "SET"; payload: { cursor?: number; interactive?: boolean } };

type UseKeyboardListNavigationState = {
  cursor: number;
  length: number;
  interactive: boolean;
};

const reducer = (defaults: { cursor: number }) => (
  state: UseKeyboardListNavigationState,
  action: UseKeyboardListNavigationAction
): UseKeyboardListNavigationState => {
  switch (action.type) {
    case "RESET":
      return { ...state, interactive: false, cursor: defaults.cursor };
    case "INTERACT":
      return { ...state, interactive: true };
    case "PREV":
      return { ...state, cursor: state.cursor - 1, interactive: true };
    case "NEXT":
      return { ...state, cursor: state.cursor + 1, interactive: true };
    case "FIRST":
      return { ...state, cursor: 0, interactive: true };
    case "LAST":
      return { ...state, cursor: state.length - 1, interactive: true };
    case "SET":
      return { ...state, ...action.payload };
  }
};

export type UseKeyboardListNavigationProps<T> = {
  ref: React.MutableRefObject<any>;
  list: T[];
  defaultValue?: T;
  bindAxis?: "vertical" | "horizontal" | "both";
  onEnter({
    event,
    element,
    state,
    index,
  }: {
    event: KeyboardEvent;
    element: T;
    state: UseKeyboardListNavigationState;
    index: number;
  }): void;
};

//* Improved Version of use-keyboard-list-navigation
export const useKeyboardListNavigation = <T,>({
  ref,
  list,
  defaultValue,
  bindAxis = "vertical",
  onEnter,
}: UseKeyboardListNavigationProps<T>) => {
  const [isAreaFocussed, setAreaFocussed] = useElementActive(ref);

  const defaultCursor = defaultValue ? list.indexOf(defaultValue) : 0;
  const [state, dispatch] = useReducer(reducer({ cursor: defaultCursor }), {
    cursor: defaultCursor,
    length: list.length,
    interactive: false,
  });

  const index = mapCursorToMax(state.cursor, list.length);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const handleUp = () => {
        // event.preventDefault();
        return dispatch({ type: "PREV" });
      };

      const handleDown = () => {
        return dispatch({ type: "NEXT" });
      };

      switch (event.key) {
        case "ArrowUp": {
          if (bindAxis === "horizontal") return;
          return handleUp();
        }
        case "ArrowDown": {
          if (bindAxis === "horizontal") return;
          return handleDown();
        }
        case "ArrowLeft": {
          if (bindAxis === "vertical") return;
          return handleUp();
        }
        case "ArrowRight": {
          if (bindAxis === "vertical") return;
          return handleDown();
        }
        case "Enter": {
          return onEnter({ event, element: list[index], state, index });
        }
        case "Home": {
          return dispatch({ type: "FIRST" });
        }
        case "End": {
          return dispatch({ type: "LAST" });
        }
        default:
          // Set focus based on search term
          break;
      }
    },
    [bindAxis, onEnter, list, index, state]
  );

  useEffect(() => {
    if (isAreaFocussed) {
      const el = window;
      el.addEventListener("keydown", handleKeyDown);
      return () => {
        el.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [handleKeyDown, isAreaFocussed]);

  useEffect(() => dispatch({ type: "RESET" }), [list.length]);

  const interactiveIndex = index;

  const reset = useCallback(() => {
    dispatch({ type: "RESET" });
  }, []);

  const set = useCallback(
    (payload: { cursor?: number; interactive?: boolean }) => {
      dispatch({ type: "SET", payload });
    },
    []
  );

  return {
    ...state,
    index: interactiveIndex,
    selected: list[interactiveIndex],
    reset,
    set,
  };
};

export const useElementActive = (ref: React.MutableRefObject<any>) => {
  const [isActive, setActive] = useState(false);
  const onFocusChange = useCallback(() => {
    if (ref.current) {
      const isActive = ref.current.contains(document.activeElement);
      setActive(isActive);
    }
  }, [ref]);

  useFocusChange(onFocusChange);
  return [isActive, setActive];
};

export const useFocusChange = (cb: () => any) => {
  const lastActiveElementRef = useRef<any>();
  useEffect(() => {
    const checkChanges = () => {
      const isSameActiveElement = () => {
        const currentActiveElement = document.activeElement;
        if (lastActiveElementRef.current !== currentActiveElement) {
          lastActiveElementRef.current = currentActiveElement;
          return false;
        }
        return true;
      };
      if (!isSameActiveElement()) {
        cb();
      }
    };
    window.addEventListener("focus", checkChanges, true);
    window.addEventListener("blur", checkChanges, true);
    return () => {
      window.removeEventListener("focus", checkChanges, true);
      window.removeEventListener("blur", checkChanges, true);
    };
  }, [cb]);
};
