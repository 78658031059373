import { EntityServices } from "@sdk/utils/entity.service";
import { iBadgeRecord } from "./badge-record-model";

export class BadgesServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  badges = Object.assign(
    new EntityServices<iBadgeRecord>(this.config, "badges"),
    {}
  );
}
