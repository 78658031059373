export const ChatBotComplexityList = [
  {
    id: "SIMPLE",
    label: "Simple"
  },
  {
    id: "MODERATELY_COMPLEX",
    label: "Moderately Complex"
  },
  {
    id: "COMPLEX",
    label: "Complex"
  }
];

export const ChatBotComplexityLabels = {
  SIMPLE: "Simple",
  MODERATELY_COMPLEX: "Moderately Complex",
  COMPLEX: "Complex"
};
