import { iPresetState } from "@sdk/user-management/preset-state-model";
import { iEmailSignature } from "@sdk/user-management/user-management.models";
import _ from "lodash";
import { createSelector } from "reselect";
import { unwindBy } from "utils/unwind-array";
import { iStore } from "../../store.model";
import { defaultQueryValue } from "../../utils/list-query";

const selectQueryMap = (state: iStore) => state.users.queries;
const selectQueryMapAll = (state: iStore) => selectQueryMap(state)["all"];
const selectQueryAllList = (state: iStore) => selectQueryMap(state).all?.list;
export const selectUsersMap = (state: iStore) => state.users.byIds;

export const selectAllAPIUsers = createSelector(
  [selectQueryAllList, selectUsersMap],
  (list, map) =>
    list
      ?.map(listId => map[listId])
      .filter(d => d.metaData.isActive && d.metaData.isAPIUser) || []
);

export const selectAllUsers = createSelector(
  [selectQueryAllList, selectUsersMap],
  (list, map) =>
    list
      ?.map(listId => map[listId])
      // Probably the user is not loaded yet
      .filter(user => user)
      .filter(d => d.metaData.isActive && !d.metaData.isAPIUser) || []
);

export const selectUsersByUserGroup = createSelector(
  [selectAllUsers],
  userList => _.groupBy(unwindBy(userList, "userGroups"), "userGroups")
);

export const selectAllActiveUsersQuery = createSelector(
  [selectQueryMapAll, selectUsersMap],
  (query, userMap) => {
    const populatedList = query?.list.map(listId => userMap[listId]);
    return {
      ...defaultQueryValue,
      ...query,
      list:
        populatedList?.filter(
          d => d.metaData.isActive && !d.metaData.isAPIUser
        ) || []
    };
  }
);

export const selectAllUsersQuery = createSelector(
  [selectQueryMapAll, selectUsersMap],
  (query, userMap) => {
    const populatedList = query?.list.map(listId => userMap[listId]);
    return {
      ...defaultQueryValue,
      ...query,
      list: populatedList
    };
  }
);
export const selectAccessToken = (state: iStore) => state.users.accessToken;

export const selectPinnedChats = (state: iStore) =>
  selectUser(state.users.currentUser!)(state)?.data.pinnedConversations || [];

export const selectCurrentUser = (state: iStore) =>
  selectUser(state.users.currentUser!)(state);

export const selectCurrentUserEmail = (state: iStore) =>
  selectUser(state.users.currentUser!)(state)?.credentials?.email;

export const selectCurrentUserId = (state: iStore) => state.users.currentUser!;

export const selectCurrentUserAvailabilityStatus = (state: iStore) =>
  selectUser(state.users.currentUser!)(state)?.metaData?.availabilityStatus;

export const selectCurrentUserBetaFeatures = (state: iStore) =>
  selectUser(state.users.currentUser!)(state)?.data?.betaFeatures;

export const selectCurrentUserPresets = (state: iStore) =>
  (selectUser(state.users.currentUser!)(state).data.presets as string[]) || [];

export const defaultPresetXModel = {
  folders: [],
  presets: []
} as iPresetState;

export const selectCurrentUserPresetsX = (state: iStore) =>
  selectUser(state.users.currentUser!)(state).data.presetsX ||
  defaultPresetXModel;

const emptyArray = [] as any[];

export const selectCurrentUserSignatures = (state: iStore) =>
  selectUser(state.users.currentUser!)(state).data.emailSignatures ||
  (emptyArray as iEmailSignature[]);

export const selectUser = userId => (state: iStore) =>
  state.users.byIds[userId];

export const selectCurrentUserData = (state: iStore) =>
  selectUser(state.users.currentUser!)(state)?.data;

export const selectCurrentUserDisabledFromJoiningChats = (state: iStore) =>
  selectCurrentUser(state)?.metaData?.disableChatJoining;
