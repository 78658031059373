import { iDestinationSummaryBlock } from "../itineries-model";
import { WeatherWidget } from "./weather-widget";
import { DestinationSnippet } from "./wiki-destination-snippet";

export const DestinationHighlightBlock = ({
  data: { destination, weatherUrl }
}: {
  data: iDestinationSummaryBlock["data"];
}) => {
  if (!destination) {
    return (
      <div className="flex flex-row justify-center items-center">
        No Destination Selected
      </div>
    );
  }
  return (
    <div className="my-8">
      {/* <div className="header text-lg mb-2">{destination}</div> */}
      {weatherUrl && (
        <WeatherWidget destination={destination.name} weatherUrl={weatherUrl} />
      )}

      {/* <SimpleGallery imageUrls={dummyImageUrls} /> */}
      <DestinationSnippet destination={destination.name} />
    </div>
  );
};
