import { iRouteItem } from "components/common/side-bar-menu/menu-item-mode";
import { ManageChatBots } from "components/modules/conversations/components/manage-chat-bots/manage-chat-bots";
import { AppsAndIntegrations } from "components/modules/organization-management/general/apps-and-integrations/apps-and-integrations";
import { BillingPortal } from "components/modules/organization-management/general/billing/billing-portal";
import { ManageUserGroups } from "components/modules/user-management/user-groups/manage-user-groups/manage-user-groups";
import { ManageUsers } from "components/modules/user-management/users/components/manage-users/manage-users";
import ProductsPage from "components/pages/products/products.page";
import CampaignsPage from "../campaigns/campaigns.page";
import { ManageDynamicFormsX } from "../dynamic-forms/manage-dynamic-forms/manage-dynamic-forms";

import KnowledgeBasePage from "../knowledge-base/knowledge-base.page";
import { ManageKnowledgeDocuments } from "../knowledge-kit-page/manage-knowledge-documents/manage-knowledge-documents";
import { ConfigurationsQuickAccess } from "./sections/confiugration-quick-access";
import { CRMSettings } from "./sections/crm-settings/crm-settings";
import { GeneralSettings } from "./sections/general-settings/general-settings-home";
import { InboxSettings } from "./sections/inbox-settings/inbox-settings";

export const QuickAccessMenu: iRouteItem[] = [
  {
    type: "LINK_ITEM",
    key: "quick-access",
    icon: <i className="ri-flashlight-line"></i>,
    label: "Quick Access",
    component: ConfigurationsQuickAccess,
  },
];

export const AdministrationMenu: iRouteItem[] = [
  {
    type: "LINK_ITEM",
    key: "general",
    icon: <i className="ri-building-line"></i>,
    label: "General Settings",
    component: GeneralSettings,
    deepLink: "/configurations/general",
    children: [
      {
        label: "About Workspace",
        deepLink: "/configurations/general?activeConfigs=About%20Workspace",
      },
      {
        label: "Logo and Theme",
        deepLink:
          "/configurations/general?activeConfigs=Logo%20and%20Brand%20Color",
      },
      {
        label: "Whitelisted IPs",
        deepLink: "/configurations/general?activeConfigs=Whitelisted%20IPs",
      },
      {
        label: "Auto Kick Users",
        deepLink: "/configurations/general?activeConfigs=Auto%20Kick%20Users",
      },
      {
        label: "Migrate Data",
        deepLink: "/configurations/general?activeConfigs=Migrate%20Data",
      },
    ],
  },
  {
    type: "LINK_ITEM",
    key: "user-management",
    icon: <i className="ri-group-line"></i>,
    label: "User Management",
    component: ManageUsers,
    deepLink: "/configurations/user-management",
    children: [
      {
        label: "View All Users",
        deepLink: "/configurations/user-management?user-list=active-users",
        actions: [
          {
            label: "Edit User",
          },
        ],
      },
      {
        label: "View Deactivated Users",
        deepLink: "/configurations/user-management?user-list=deactivated-users",
        actions: [
          {
            label: "Activate Users",
          },
          {
            label: "Transfer all conversations",
          },
          {
            label: "Unassign all conversations",
          },
        ],
      },
      {
        label: "View Active Invitations",
        deepLink:
          "/configurations/user-management?user-list=active-invitations",
        actions: [
          {
            label: "Cancel Invite",
          },
        ],
      },
      {
        label: "Add New User",
        deepLink:
          "/configurations/user-management?portals=((name:add-users,visible:true))",
      },
      {
        label: "Import Users",
        deepLink:
          "/configurations/user-management?portals=((name:bulk-add-users,visible:true))",
      },
      {
        label: "Invite User",
        deepLink:
          "/configurations/user-management?user-list=active-users&show-invite=true",
      },
    ],
  },
  {
    type: "LINK_ITEM",
    key: "teams-and-departments",
    icon: <i className="ri-team-line"></i>,
    label: "Teams & Departments",
    deepLink: "/configurations/teams-and-departments",
    component: ManageUserGroups,
    children: [
      {
        label: "Set up and Manage Teams",
        deepLink: "/configurations/teams-and-departments",
        actions: [
          {
            label: "Add Team",
          },
        ],
      },
      {
        label: "Set up and Manage Departments",
        deepLink: "/configurations/teams-and-departments",
        actions: [
          {
            label: "Add Department",
          },
        ],
      },
    ],
  },
  {
    type: "LINK_ITEM",
    key: "billing",
    icon: <i className="ri-money-dollar-circle-line"></i>,
    label: "Billing & Subscription",
    deepLink: "/configurations/billing",
    component: BillingPortal,
    children: [
      {
        label: "Subscription Overview",
      },
      {
        label: "Invoice History",
      },
    ],
  },
];

export const WorkspaceManagement: iRouteItem[] = [
  {
    type: "LINK_ITEM",
    key: "apps-and-integrations",
    icon: <i className="ri-plug-line"></i>,
    label: "Apps and Integrations",
    component: AppsAndIntegrations,
    deepLink: "/configurations/apps-and-integrations",
    children: [
      {
        label: "Connect Slack",
        deepLink:
          "/configurations/apps-and-integrations?activeConfigs=Slack%20Notification",
      },
      {
        label: "Connect Zapier",
        deepLink: "/configurations/apps-and-integrations",
      },
      {
        label: "Create API Token",
        deepLink:
          "/configurations/apps-and-integrations?portals=((name:add-api-user,visible:true,type:API+App))",
      },
      {
        label: "API Documentations",
        action: () => {
          const url = "https://documenter.getpostman.com/view/6605246/UyxdLp7d";
          const newWindow = window.open(url);
        },
      },
    ],
  },
  {
    type: "LINK_ITEM",
    key: "inbox-settings",
    icon: <i className="ri-inbox-line"></i>,
    label: "Inbox Settings",
    component: InboxSettings,
    deepLink: "/configurations/inbox-settings",
    children: [
      {
        label: "General",
        sections: [
          {
            label: "New chats will notify everyone",
            deepLink:
              "/configurations/inbox-settings/general?activeConfigs=Notify%20all%20on%20new%20chats",
          },
          {
            label: "Alert all on unassigned chats",
            deepLink:
              "/configurations/inbox-settings/general?activeConfigs=Alert%20all%20on%20unassigned%20chats",
          },
          {
            label: "Prevent Burst Join Chat Requests",
            deepLink: `/configurations/inbox-settings/general?activeConfigs=Prevent%20"Join%20Chat"%20clashes`,
          },
          {
            label: "Conversation Tags",
            deepLink:
              "/configurations/inbox-settings/general?activeConfigs=Conversation%20Tags",
          },
          {
            label: "Connection Context",
            deepLink:
              "/configurations/inbox-settings/general?activeConfigs=Display%20connection%20context",
          },
          {
            label: "Default Inboxes",
            deepLink:
              "/configurations/inbox-settings/general?activeConfigs=Default%20Inboxes",
          },
          {
            label: "Manage Presets",
            deepLink:
              "/configurations/inbox-settings/general?activeConfigs=Message%20Presets",
          },
        ],
      },
      {
        label: "Manage Pipelines",
        deepLink: "/configurations/inbox-settings/manage-pipelines",
        sections: [
          {
            label: "Workflows",
          },
          {
            label: "Add Workflow",
          },
        ],
      },
      {
        label: "Extend",
      },
      {
        label: "Custom Properties",
        deepLink: "/configurations/inbox-settings/custom-properties",
        sections: [
          {
            label: "Add new custom property",
          },
        ],
      },
      {
        label: "Chat Actions",
        deepLink: "/configurations/inbox-settings/chat-actions",
        sections: [
          {
            label: "Add new chat action",
          },
        ],
      },
      {
        label: "Chat Side Bar Panels",
        deepLink: "/configurations/inbox-settings/chat-side-bar-panels",
      },
    ],
  },
  {
    type: "LINK_ITEM",
    key: "crm-settings",
    icon: <i className="ri-contacts-book-line"></i>,
    label: "CRM Settings",
    component: CRMSettings,
    children: [
      {
        label: "General",
        sections: [
          {
            label: "CRM Tags",
            deepLink:
              "/configurations/crm-settings/general?activeConfigs=Contact%20Tags",
          },
        ],
      },
      {
        label: "Manage Pipelines",
        deepLink: "/configurations/crm-settings/manage-pipelines",
        sections: [
          {
            label: "Workflows",
          },
          {
            label: "Add Workflow",
          },
        ],
      },
      {
        label: "Extend",
      },
      {
        label: "CRM Custom Properties",
        deepLink: "/configurations/crm-settings/custom-properties",
        sections: [
          {
            label: "Add new custom property",
          },
        ],
      },
    ],
  },
  {
    type: "LINK_ITEM",
    key: "manage-chatbots",
    icon: <i className="ri-robot-line"></i>,
    label: "Manage Chatbots",
    component: ManageChatBots,
  },
  {
    type: "LINK_ITEM",
    key: "knowledge-kit",
    icon: <i className="ri-folder-5-line"></i>,
    label: "Knowledge Kit",
    component: ManageKnowledgeDocuments,
  },
  {
    type: "LINK_ITEM",
    key: "knowledge-base",
    icon: <i className="ri-book-line"></i>,
    label: "Knowledge Base",
    component: KnowledgeBasePage,
  },
  // {
  //   type: "LINK_ITEM",
  //   key: "deals-settings",
  //   icon: <i className="ri-folder-chart-line"></i>,
  //   label: "Deals Settings",
  //   component: DealsSettings,
  //   children: [
  //     {
  //       label: "Connected Apps"
  //     },
  //     {
  //       label: "API Tokens"
  //     },
  //     {
  //       label: "API Documentations"
  //     }
  //   ]
  // },
];

export const OtherAppsMenu: iRouteItem[] = [
  // {
  //   type: "LINK_ITEM",
  //   key: "knowledge-base",
  //   icon: <i className="ri-book-line"></i>,
  //   label: "Knowledge Base",
  //   component: KnowledgeBasePage,
  //   children: [
  //     {
  //       label: "Connected Apps"
  //     },
  //     {
  //       label: "API Tokens"
  //     },
  //     {
  //       label: "API Documentations"
  //     }
  //   ]
  // },
  {
    type: "LINK_ITEM",
    key: "campaigns",
    icon: <i className="ri-mail-send-fill"></i>,
    label: "Email Campaigns",
    component: CampaignsPage,
    children: [],
  },
  {
    type: "LINK_ITEM",
    key: "forms",
    icon: <i className="ri-file-text-line"></i>,
    label: "Forms",
    component: ManageDynamicFormsX,
    children: [],
  },
  {
    type: "LINK_ITEM",
    key: "products",
    icon: <i className="ri-store-line"></i>,
    label: "Products",
    component: ProductsPage,
    children: [],
  },
  {
    type: "LINK_ITEM",
    key: "/pipelines",
    icon: <i className="ri-layout-column-line"></i>,
    label: "Pipelines",
    component: <></>,
    children: [],
  },
  {
    type: "LINK_ITEM",
    key: "/sequences",
    icon: <i className="ri-mail-send-line"></i>,
    label: "Sequences",
    component: <></>,
    children: [],
  },
];
