import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iCustomField } from "@sdk/user-management/user-management.models";
import { Button, Form, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import useModal from "antd/lib/modal/useModal";
import { ModalTitle } from "components/common/modal-title";
import {
  DynamicFormItem,
  DynamicFormItemInput,
} from "components/pages/dynamic-forms/manage-dynamic-forms/components/dynamic-form";
import _, { cloneDeep, get, set } from "lodash";
import { useCallback, useState } from "react";

import { selectConversationCustomProperties } from "store/modules/workspace/workspace.selectors";

import { EditableTextWithForm } from "components/common/editable-text-with-form/editable-text-with-form";
import { useDynamicFormItemProps } from "components/pages/dynamic-forms/manage-dynamic-forms/components/helpers/use-dynamic-form-item-props";
import dayjs from "dayjs";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { useDoubleSelectorWithMemoize } from "store/utils/use-double-selector-with-memoize";
import { useSelectorWithMemoize } from "store/utils/use-selector-with-memoize";

export const CustomFieldRenderer = ({
  schema,
  value,
}: {
  schema: iCustomField;
  value: any;
}) => {
  if (
    schema.inputType === "TEXT" ||
    schema.inputType === "TEXTAREA" ||
    schema.inputType === "EMAIL" ||
    schema.inputType === "NUMBER" ||
    schema.inputType === "PHONE"
  ) {
    return <div>{value || "N/A"}</div>;
  }

  if (schema.inputType === "CHECK_BOX" || schema.inputType === "SWITCH") {
    if (typeof value === "undefined") {
      return <div>{"N/A"}</div>;
    }
    return <div>{value ? "Yes" : "No"}</div>;
  }

  if (schema.inputType === "DATE") {
    if (typeof value === "undefined") {
      return <div>{"N/A"}</div>;
    }
    return <div>{dayjs(value).format("DD-MM-YYYY")}</div>;
  }

  if (schema.inputType === "MULTI_SELECT") {
    if (typeof value === "undefined") {
      return <div>{"N/A"}</div>;
    }
    return (
      <div>
        {Array.isArray(value)
          ? (value || []).map(
              (item) =>
                _.find(schema.inputConfig.options || [], { value: item })
                  ?.label,
            )
          : "N/A"}
      </div>
    );
  }

  if (schema.inputType === "RADIO" || schema.inputType === "SELECT") {
    if (typeof value === "undefined") {
      return <div>{"N/A"}</div>;
    }
    return (
      <div>
        {_.find(schema.inputConfig.options || [], { value: value })?.label}
      </div>
    );
  }
  return <>Unidentified Field</>;
};

export const CustomFieldEditor = ({
  schema,
  value,
  editCustomProperty,
  onExitEditMode,
}: {
  schema: iCustomField;

  value: any;
  editCustomProperty: (property: any, value: any) => any;
  onExitEditMode: () => any;
}) => {
  const [form] = useForm();

  return (
    <div className="mb-4 border border-gray-200 dark:border-gray-800 p-4">
      <div className="flex flex-row justify-end items-center w-full">
        <i
          className="ri-close-circle-line cursor-pointer close-icon"
          onClick={() => onExitEditMode()}
        ></i>
      </div>
      <Form
        form={form}
        layout={"vertical"}
        hideRequiredMark={true}
        initialValues={{ [schema.key]: value }}
        onFinish={() => {
          const formValues = form.getFieldsValue();
          editCustomProperty(schema.key, formValues[schema.key]);
        }}
      >
        <DynamicFormItem config={schema} />
        <div className="button-container flex flex-row justify-end items-center">
          <Button
            type="primary"
            htmlType="submit"
            loading={false}
            block
            icon={<i className="ri-save-2-line"></i>}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export const EditableCustomFieldLegacy = ({
  schema,
  value,
  editCustomProperty,
}: {
  schema: iCustomField;
  value: any;
  editCustomProperty: (property: any, value: any) => any;
}) => {
  const [isEditMode, setEditMode] = useState(false);
  return (
    <div>
      {!isEditMode && (
        <>
          {schema.description ? (
            <Tooltip title={schema.description}>
              <div className="title flex flex-row items-center">
                {schema.label}

                <i className="ri-information-line ml-2"></i>
              </div>
            </Tooltip>
          ) : (
            <div className="title">{schema.label}</div>
          )}

          <div
            className="body hover:bg-gray-200 dark:hover:bg-gray-800 cursor-pointer p-2 m-2"
            onClick={() => setEditMode(true)}
          >
            <CustomFieldRenderer schema={schema} value={value} />
          </div>
        </>
      )}
      {isEditMode && (
        <CustomFieldEditor
          schema={schema}
          value={value}
          editCustomProperty={(property, value) => {
            editCustomProperty(property, value);
            setEditMode(false);
          }}
          onExitEditMode={() => setEditMode(false)}
        />
      )}
    </div>
  );
};

export const EditableCustomField = ({
  schema,
  conversationId,
}: {
  schema: iCustomField;
  conversationId: string;
}) => {
  const { rules, label, additionProps } = useDynamicFormItemProps(schema);

  const transformFormValues = useCallback(
    (entity) => {
      if (schema.inputType === "DATE") {
        const cloned = cloneDeep(entity);
        const value = get(cloned, `customProperties.${schema.key}`);
        if (value) {
          const dateObj = dayjs(value);
          set(cloned, `customProperties.${schema.key}`, dateObj);
          return cloned;
        }
      }
      return entity;
    },
    [schema?.inputType, schema?.key],
  );

  return (
    <div>
      <EditableTextWithForm
        fieldPath={`customProperties.${schema.key}`}
        entityType="CONVERSATION"
        entityId={conversationId}
        rules={rules}
        label={label}
        formLayout="vertical"
        {...additionProps}
        help={<div className="-mt-2 mb-2">{schema.description}</div>}
        transformEntityValues={transformFormValues}
      >
        <DynamicFormItemInput config={schema} />
      </EditableTextWithForm>
    </div>
  );
};

export const EditableCustomFieldWithModal = ({
  schema,
  value,
  editCustomProperty,
}: {
  schema: iCustomField;
  value: any;
  editCustomProperty: (property: any, value: any) => any;
}) => {
  const [modal, context] = useModal();

  return (
    <>
      <div
        className="body hover:bg-gray-200 dark:hover:bg-gray-800 cursor-pointer"
        onClick={() => {
          const modalRef = modal.info({
            title: (
              <ModalTitle
                title={"Edit Property"}
                icon={<i className="ri-pencil-line"></i>}
              />
            ),
            icon: <></>,
            okButtonProps: { style: { display: "none" } },
            content: (
              <>
                <CustomFieldEditor
                  schema={schema}
                  value={value}
                  editCustomProperty={(property, value) => {
                    editCustomProperty(property, value);
                    modalRef.destroy();
                  }}
                  onExitEditMode={() => {
                    modalRef.destroy();
                  }}
                />
              </>
            ),
          });
        }}
      >
        <CustomFieldRenderer schema={schema} value={value} />
      </div>
      {context}
    </>
  );
};

export const CustomPropertiesLegacy = ({
  conversationId,
}: {
  conversationId: string;
}) => {
  const customerProperties = useSelectorWithMemoize(
    () => selectConversationCustomProperties,
    [],
  );

  const conversationCustomProperties = useDoubleSelectorWithMemoize(
    () => selectConversationById(conversationId),
    [conversationId],
    (conversation) => conversation?.customProperties,
    false,
  );

  const { doAction: editCustomProperty } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (property: string, value: any) =>
        SDK.editConversation(conversationId!, {
          customProperties: {
            [property]: value,
          },
        }),
      failureMessage: "Something went wrong",
    }),
    [conversationId],
  );

  return (
    <>
      <div className="px-4">
        <div className="flex flex-row justify-between mb-4">
          <div className="title font-bold">Custom Properties</div>
        </div>
        <div className="fields-container">
          {customerProperties.map((property) => (
            <div key={property.id}>
              <EditableCustomFieldLegacy
                schema={property}
                value={conversationCustomProperties?.[property.key]}
                editCustomProperty={editCustomProperty}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export const CustomProperties = ({
  conversationId,
}: {
  conversationId: string;
}) => {
  const customerProperties = useSelectorWithMemoize(
    () => selectConversationCustomProperties,
    [],
  );

  return (
    <>
      <div className="px-4">
        <div className="flex flex-row justify-between mb-4">
          <div className="title font-bold">Custom Properties</div>
        </div>
        <div className="fields-container">
          {customerProperties.map((property) => (
            <div key={property.id}>
              <EditableCustomField
                schema={property}
                conversationId={conversationId}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
