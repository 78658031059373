import { Modal, Tabs } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useEffect, useState } from "react";
import { uuidv4 } from "utils/generate-uuid";
import { useViewRefresher } from "utils/hooks/use-view-refresher";
import { iRule } from "./models";
import { defaultRule, RuleEditor } from "./rule-editor";
const { TabPane } = Tabs;

export const RuleEditorModal = ({
  visible,

  onChangeVisibility,
  onSave,
  initialValue
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onSave: (rule) => any;
  initialValue?: iRule;
}) => {
  const [rule, _setRule] = useState({ ...(initialValue || defaultRule) });

  useEffect(() => {
    _setRule({ ...(initialValue || defaultRule) });
  }, [initialValue, visible]);

  const { visible: _visible } = useViewRefresher(visible);

  return (
    (<Modal
      title={
        <ModalTitle
          title="Add Routing Rule"
          icon={<i className="ri-add-line"></i>}
        />
      }
      open={visible}
      onOk={() => {
        // Todo
        onSave({
          ...rule,
          id: initialValue?.id || uuidv4()
        });
        onChangeVisibility(false);
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      className="routing-rule-modal"
      data-click-context="Routing Rule Modal"
    >
      {_visible && <RuleEditor rule={rule} _setRule={_setRule} />}
      <DarkModeBg />
    </Modal>)
  );
};
