import DownOutlined from "@ant-design/icons/DownOutlined";
import dayjs from "dayjs";

import {
  Button,
  DatePicker,
  Dropdown,
  Menu,
  Modal,
  Popconfirm,
  Tag,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import React, { Suspense, memo, useState } from "react";

import { useSelector, useStore } from "react-redux";
import { selectUsersMap } from "store/modules/users/users.selectors";
import { selectFirstResponseIntervalConfig } from "store/modules/workspace/workspace.selectors";
import { durationOptions } from "../../helpers/chat-duration-options";
import { messageCountOptions } from "../../helpers/chat-message-count-options";
import { generateIntervalOptions } from "../../helpers/generate-interval-options";

import { uuidv4 } from "utils/generate-uuid";
import "./legacy-report-filter.scss";

const LegacyFilterForm = React.lazy(() => import("./legacy-filter-form"));

const { Title } = Typography;

const menu2 = (
  <Menu data-click-context="Report filter Menu">
    <Menu.Item key="0">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.alipay.com/"
      >
        1st menu item
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.taobao.com/"
      >
        2nd menu item
      </a>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="3" disabled>
      3rd menu item（disabled）
    </Menu.Item>
  </Menu>
);

const { RangePicker } = DatePicker;

export const LegacyReportFilterPill = ({
  reportType,
  filter,
}: {
  reportType: string;
  filter: any;
}) => {
  const usersMap = useSelector(selectUsersMap);
  const store = useStore();
  if (reportType === "users" || reportType === "usersIn") {
    return (
      <>
        <Tag color="blue" key={reportType} className="cursor-pointer">
          {reportType}:{" "}
          {filter
            .map((userId) => getUserName(usersMap[userId]))
            .join(reportType === "usersIn" ? " or " : " and ")}
        </Tag>
      </>
    );
  }
  if (
    reportType === "duration" ||
    reportType === "responseTime" ||
    reportType === "messageCount"
  ) {
    const label = (() => {
      if (reportType === "duration") {
        return _.find(durationOptions, { value: filter })?.title;
      }
      if (reportType === "responseTime") {
        return _.find(
          generateIntervalOptions(
            selectFirstResponseIntervalConfig(store.getState()),
          ),
          { value: filter },
        )?.title;
      }
      if (reportType === "messageCount") {
        return _.find(messageCountOptions, { value: filter })?.title;
      }
    })();
    return (
      <Tag color="blue" key={reportType} className="cursor-pointer">
        {reportType}: {label || "Custom"}
      </Tag>
    );
  }
  return (
    <Tag color="blue" key={reportType} className="cursor-pointer">
      {reportType}: {Array.isArray(filter) ? filter.join(" or ") : filter}
    </Tag>
  );
};

export const LegacyReportFilter = memo(
  ({
    onFilterChange,
    initialFilter,
    hideGroupBy,
    disableAdvancedQuery,
    customLabel,
    hideDateFilter,
    showPeriodAggregationOption,
  }: {
    onFilterChange: (query: any) => any;
    initialFilter?: any;
    hideGroupBy?: boolean;
    disableAdvancedQuery?: boolean;
    customLabel?: string;
    hideDateFilter?: boolean;
    showPeriodAggregationOption?: boolean;
  }) => {
    const [form] = useForm();

    const [isSimpleQueryMode, setSimpleQueryMode] = useState(true);
    const [query, setQuery] = useState({
      query: {
        period: [dayjs().subtract(7, "day"), dayjs()] as any,
        groupBy: [],
        dateField: "createdTime",
        ...(initialFilter || {}),
      },
      advancedQuery: { id: uuidv4(), type: "group" },
      // advancedQuery: { id: QbUtils.uuid(), type: "group" },
    });

    const [displayedQuery, setDisplayQuery] = useState(query);

    const filterKeys = Object.keys(
      _.omit(displayedQuery.query, "period", "groupBy", "dateField"),
    ).filter((key) => displayedQuery.query[key]);

    const [isReportConfigVisible, setReportConfigVisibility] = useState(false);

    return (
      <>
        <div
          className="flex flex-1 flex-row justify-between items-center cursor-pointer report-filter"
          onClick={() => setReportConfigVisibility(true)}
        >
          {false && (
            <div>
              Showing Conversations Report for{" "}
              <Dropdown overlay={menu2}>
                <Button
                  type="link"
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Domain: trkd.link <DownOutlined />
                </Button>
              </Dropdown>{" "}
              between{" "}
              <RangePicker
                bordered={false}
                defaultValue={displayedQuery.query.period}
              />{" "}
              by{" "}
              <Dropdown overlay={menu2}>
                <Button
                  type="link"
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Country <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          )}

          {true && (
            <div className="filter-summary-cont cursor-pointer">
              {customLabel || "Showing Conversations"}{" "}
              {filterKeys.length > 0 && "where "}
              {filterKeys.map((key) => (
                <LegacyReportFilterPill
                  key={key}
                  reportType={key}
                  filter={displayedQuery.query[key]}
                />
              ))}
              {!hideDateFilter && (
                <>
                  {" "}
                  between{" "}
                  <Tag color="blue" className="cursor-pointer">
                    {dayjs(displayedQuery.query.period?.[0]).format(
                      "DD-MM-YYYY",
                    )}
                  </Tag>
                  <span className="pr-1">{`& `}</span>
                  <Tag color="blue" className="cursor-pointer">
                    {dayjs(displayedQuery.query.period?.[1]).format(
                      "DD-MM-YYYY",
                    )}
                  </Tag>{" "}
                </>
              )}
              {displayedQuery.query.groupBy &&
                displayedQuery.query.groupBy.length > 0 &&
                `by `}
              {(displayedQuery.query.groupBy || []).map((groupBy) => (
                <Tag color="blue" className="cursor-pointer">
                  {" "}
                  {groupBy}
                </Tag>
              ))}
            </div>
          )}

          <div className="configure-container">
            <Button
              icon={<i className="ri-settings-2-line"></i>}
              type="link"
              shape="circle"
            />
          </div>
        </div>
        <Modal
          title={
            <div className="flex-row flex justify-between items-center">
              <ModalTitle
                title="Change Report Filters"
                icon={<i className="ri-filter-line"></i>}
              />
              <div className="right mr-6">
                <Popconfirm
                  title="Are you sure switch modes? Existing filter data will be removed"
                  onConfirm={() => setSimpleQueryMode(!isSimpleQueryMode)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" href="#">
                    {isSimpleQueryMode ? "advanced" : "simple"}
                  </Button>
                </Popconfirm>
              </div>
            </div>
          }
          open={isReportConfigVisible}
          footer={null}
          onCancel={() => {
            setReportConfigVisibility(false);
          }}
          width={isSimpleQueryMode ? 450 : 650}
          className="report-filter"
          data-click-context="Report Filter Modal"
        >
          <div className="content flex flex-col">
            <div style={{ width: isSimpleQueryMode ? 400 : 600 }}>
              <Suspense fallback={<SuspenseLoadingIndicator />}>
                <LegacyFilterForm
                  isSimpleQueryMode={isSimpleQueryMode}
                  query={query}
                  onQueryChange={setQuery}
                  hideGroupBy={hideGroupBy}
                  hideDateFilter={hideDateFilter}
                  showPeriodAggregationOption={showPeriodAggregationOption}
                />
              </Suspense>
            </div>
            <div className="flex flex-row justify-end items-center mt-4">
              <Button
                type="primary"
                size="large"
                icon={<i className="ri-check-line text-xl"></i>}
                onClick={() => {
                  const clonedQuery = _.cloneDeep(query);
                  if (!hideDateFilter) {
                    clonedQuery.query.period = [
                      clonedQuery.query.period[0].startOf("day"),
                      clonedQuery.query.period[1].endOf("day"),
                    ];
                    // clonedQuery.query.dateField = "createdTime";
                  }
                  setDisplayQuery(clonedQuery);
                  if (isSimpleQueryMode) {
                    onFilterChange(clonedQuery.query);
                  } else {
                    onFilterChange({
                      period: clonedQuery.query.period,
                      groupBy: clonedQuery.query.groupBy,
                      dateField: clonedQuery.query.dateField,
                      ...clonedQuery.advancedQuery,
                    });
                  }
                  setReportConfigVisibility(false);
                }}
              >
                Apply Filter
              </Button>
            </div>
          </div>
          <DarkModeBg />
        </Modal>
      </>
    );
  },
);
