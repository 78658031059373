import { axios, AxiosResponse } from "@sdk/axios";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { iTweetRecord } from "./twitter-models";

export class TwitterServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  twitter = {
    getOath1Token: async (userId: string) => {
      const results: AxiosResponse<{
        oauth_token: string;
        oauth_token_secret: string;
        oauth_callback_confirmed: string;
      }> = await axios.post(
        this.config.basePath + `/twitter/request-token`,
        { userId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    transmitOauthData: async (data: any) => {
      const results: AxiosResponse<{
        oauth_token: string;
        oauth_token_secret: string;
        oauth_callback_confirmed: string;
      }> = await axios.post(
        this.config.basePath + `/twitter/transmit-oauth-data`,
        data,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };

  tweets = Object.assign(
    new EntityServices<iTweetRecord>(this.config, "twitter-tweet"),
    {
      syncAllTweets: async (req: {
        connectionId: string;
        forceRefresh?: boolean;
      }) => {
        UserTracker.track("Twitter - Sync All Tweets", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/twitter-tweet/sync`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      reloadMedia: async (tweetId: string) => {
        UserTracker.track("Twitter - Reload Tweet", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/twitter-tweet/${tweetId}/reload`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
