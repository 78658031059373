import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Button, Modal, Result, Steps } from "antd";
import classnames from "classnames";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";

export const AddConnectionModalContainer = ({
  title,
  icon,
  isVisible,
  onChangeVisibility,
  children
}: {
  title: string;
  icon: JSX.Element;
  isVisible;
  onChangeVisibility: (state: boolean) => any;
  children: any;
}) => {
  return (
    (<Modal
      title={<ModalTitle title={title} icon={icon} />}
      open={isVisible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      footer={null}
      className="responsive-modal w-full max-w-6xl"
      data-click-context={`${title} Modal`}
    >
      {children}
      <DarkModeBg />
    </Modal>)
  );
};

export const AddConnectionStepsContainer = ({
  currentStep,
  steps
}: {
  currentStep: number;
  steps: { label: string; icon: JSX.Element }[];
}) => {
  return (
    <div className="hidden md:flex flex-col justify-between items-center">
      <div className="steps-container w-full" style={{ maxWidth: 800 }}>
        <Steps current={currentStep} direction="horizontal">
          {steps.map(item => (
            <Steps.Step
              title={<div className="font-bold">{item.label}</div>}
              icon={item.icon}
              key={item.label}
            />
          ))}
        </Steps>
      </div>
    </div>
  );
};

export const StepContainer = ({
  children,
  stepNumber,
  currentStep
}: {
  children;
  stepNumber: number;
  currentStep: number;
}) => {
  if (stepNumber === currentStep) {
    return (
      <div className="mt-8 w-full h-full animated fadeInRightMin">
        {children}
      </div>
    );
  }

  return <></>;
};

export const IntegrationSuccessfulScreen = ({
  title = "Integration has been successful",
  subTitle,
  onManageConnection,
  onHideWizard,
  children
}: {
  title?: string;
  subTitle: string;
  onManageConnection: () => any;
  onHideWizard: () => any;
  children?: any;
}) => {
  return (
    <Result
      title={title}
      subTitle={subTitle}
      status="success"
      icon={
        <div className="w-full flex flex-row justify-center items-center">
          <div className="text-6xl">🎉</div>
          {/* <img
            src="/assets/illustrations/goal-completed.svg"
            width={350}
            alt="Success"
          /> */}
        </div>
      }
      extra={
        <div className="flex flex-col justify-center items-center gap-4">
          {children}
          <Button
            type="text"
            icon={<i className="ri-tools-line"></i>}
            size="large"
            onClick={() => {
              onManageConnection();
            }}
          >
            Configure Created Connection
          </Button>

          <Button
            type="primary"
            icon={<i className="ri-arrow-go-back-line"></i>}
            size="large"
            onClick={() => onHideWizard()}
          >
            Exit wizard
          </Button>
        </div>
      }
    ></Result>
  );
};

export const AddConnectionLayout = ({
  currentStep,
  totalSteps,
  topBar,
  children,
  stepsWithSidebars,
  sidebarImage
}: {
  currentStep: number;
  totalSteps: number;
  topBar: JSX.Element;
  children;
  stepsWithSidebars: number[];
  sidebarImage?: string | JSX.Element;
}) => {
  const [parent] = useAutoAnimate(/* optional config */);
  const [parent2] = useAutoAnimate(/* optional config */);
  return (
    <div className="flex flex-col" ref={parent as any}>
      {totalSteps !== currentStep && topBar}

      <div className="grid grid-cols-1 md:grid-cols-3">
        {stepsWithSidebars.includes(currentStep) && (
          <div className="md:flex flex-row justify-center items-center hidden">
            {sidebarImage && typeof sidebarImage !== "string" && sidebarImage}
            {(!sidebarImage || typeof sidebarImage === "string") && (
              <img
                src={
                  sidebarImage || "/assets/illustrations/connect-whatsapp.svg"
                }
                alt=""
                className="h-full w-full"
              />
            )}
          </div>
        )}

        <div
          className={classnames("bold-form-labels", {
            "md:col-span-2": stepsWithSidebars.includes(currentStep),
            "md:col-span-3": !stepsWithSidebars.includes(currentStep)
          })}
          ref={parent2 as any}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
