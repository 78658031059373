import { iEngagement } from "@sdk/crm/crm.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import dayjs from "dayjs";
import React, { Suspense, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { MultiUserSelector } from "../../user-management/users/components/multi-user-selector/multi-user-selector";

import { SingleContactSelectorLegacy } from "../contacts/components/single-contact-selector/single-contact-selector-legacy";
import { EngagementTypeIcons } from "./engagement-type-icon-map";

const SingleDayEventView = React.lazy(() =>
  import("../components/single-day-calendar-event-view/single-day-event-view")
);
export const AddEngagement = ({
  visible,
  onChangeVisibility,
  onCreated,
  contactId,
  chooseCurrentUser
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated?: () => any;
  contactId?: string;
  chooseCurrentUser?: boolean;
}) => {
  const [form] = useForm();

  const currentUser = useSelector(selectCurrentUserId);

  let engagement: iEngagement;

  // Create Call
  const {
    doAction: createEngagement,
    isProcessing: isActionProcessing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.engagements.create(values).then(d => onChangeVisibility(false)),
      successMessage: "Done",
      failureMessage: "Something went wrong"
    }),
    [onChangeVisibility]
  );

  const saveEngagement = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (e) {
      message.warning("Please check your input");
      return;
    }

    const values = form.getFieldsValue();

    createEngagement({
      owner: currentUser
        ? [currentUser]
        : values.users
        ? values.users
        : undefined,
      contact: contactId || values.contact?.id,
      // organization: string;
      // opportunity: string;
      // isDone: boolean;
      data: {
        title: values.title,
        type: values.activityType,
        from: values.date.valueOf(),
        to: values.date.valueOf() + values.duration,
        duration: values.duration,
        guests: [],
        location: "",
        description: "",
        availabilityDisplay: values.availability,
        privateNotes: values.notes
      }
    });
  }, [form, createEngagement, currentUser, contactId]);

  const [selectedDateSlot, setSelectedSlot] = useState({
    date: new Date(),
    duration: 15 * 60 * 1000
  });

  const onChangeSlot = (val: { date: Date; duration: number }) => {
    setSelectedSlot(val);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      date: dayjs(val.date),
      duration: val.duration
    });
  };

  const [eventTitle, setEventTitle] = useState("");

  return (
    <>
      <Modal
        title={
          <ModalTitle
            title="Schedule Engagement"
            icon={<i className="ri-calendar-line"></i>}
          />
        }
        open={visible}
        onOk={async () => {
          saveEngagement();
        }}
        okButtonProps={{ loading: isActionProcessing }}
        onCancel={() => {
          onChangeVisibility(false);
        }}
        okText="Save"
        width={800}
        bodyStyle={{ padding: 0 }}
        data-click-context="Add Engagement Modal"
      >
        <div className="flex flex-row">
          <div className="form p-8">
            <Form
              layout="horizontal"
              form={form}
              colon={false}
              requiredMark={false}
              onValuesChange={d => {
                const formValues = form.getFieldsValue();
                if (
                  formValues.date?.valueOf() !== selectedDateSlot.date ||
                  formValues.duration !== selectedDateSlot.duration
                ) {
                  const newValue = { ...selectedDateSlot };
                  if (
                    formValues.date?.valueOf() !==
                    selectedDateSlot.date.valueOf()
                  ) {
                    newValue.date = new Date(formValues.date?.valueOf());
                  }
                  if (formValues.duration !== selectedDateSlot.duration) {
                    newValue.duration = formValues.duration;
                  }
                  setSelectedSlot(newValue);
                }
              }}
              initialValues={{
                date: dayjs(selectedDateSlot.date),
                duration: selectedDateSlot.duration
              }}
              onFinish={saveEngagement}
            >
              {/* Activity Title*/}
              <Form.Item
                name="title"
                label={<i className="ri-calendar-fill text-lg pr-4"></i>}
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!"
                  }
                ]}
              >
                <Input
                  placeholder="Call"
                  size="large"
                  value={eventTitle}
                  onChange={e => setEventTitle(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label={<i className="ri-magic-fill text-lg pr-4"></i>}
                name="activityType"
              >
                <Radio.Group>
                  {[
                    "PHONE",
                    "MEETING",
                    "MILESTONE",
                    "REMINDER",
                    "EMAIL",
                    "LUNCH"
                  ].map(type => (
                    <Radio.Button key={type} value={type}>
                      {EngagementTypeIcons[type]}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
              {/* Date and Time */}
              <Row justify="space-between">
                <Col span={12}>
                  {/* First Name */}

                  <Form.Item
                    name="date"
                    label={
                      <i className="ri-calendar-event-line text-lg pr-4"></i>
                    }
                    rules={[
                      {
                        type: "object",
                        required: true,
                        message: "Please select time!"
                      }
                    ]}
                  >
                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                  </Form.Item>
                </Col>

                {/* Duration */}
                <Col span={12}>
                  <Form.Item label="" name="duration">
                    <Radio.Group>
                      <Radio.Button value={15 * 60 * 1000}>15m</Radio.Button>
                      <Radio.Button value={30 * 60 * 1000}>30m</Radio.Button>
                      <Radio.Button value={60 * 60 * 1000}>1H</Radio.Button>
                      <Radio.Button value={120 * 60 * 1000}>2H</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              {/* Slot Type */}
              <Form.Item
                label={<i className="ri-calendar-todo-line text-lg pr-4"></i>}
                name="availability"
              >
                <Select>
                  <Select.Option value="FREE">Free</Select.Option>
                  <Select.Option value="BUSY">Busy</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="notes"
                label={<i className="ri-sticky-note-fill text-lg pr-4"></i>}
                rules={[]}
                help="Notes are private and only visible to your Click Connector Account"
              >
                <Input.TextArea />
              </Form.Item>
              {!chooseCurrentUser ||
                (!contactId && (
                  <Divider type="horizontal">
                    <i className="ri-link"></i> Link Engagement
                  </Divider>
                ))}

              {!chooseCurrentUser && (
                <Form.Item
                  name="users"
                  label={<i className="ri-sticky-note-fill text-lg pr-4"></i>}
                  rules={[]}
                >
                  <MultiUserSelector />
                </Form.Item>
              )}

              {!contactId && (
                <Form.Item
                  name="contact"
                  label={<i className="ri-sticky-note-fill text-lg pr-4"></i>}
                  rules={[]}
                >
                  <SingleContactSelectorLegacy />
                </Form.Item>
              )}
              <Button htmlType="submit" type="primary" className="hidden">
                Save
              </Button>
            </Form>
          </div>
          <div className="calendar" style={{ width: 300 }}>
            <Suspense fallback={<SuspenseLoadingIndicator />}>
              <SingleDayEventView
                {...selectedDateSlot}
                onEdit={val => {
                  onChangeSlot(val);
                }}
                title={eventTitle}
              />
            </Suspense>
          </div>
        </div>
        <DarkModeBg />
      </Modal>
    </>
  );
};
