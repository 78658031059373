import { iSDK } from "@sdk/sdk";
import { Tag } from "antd";
import { ActivityLabelMap } from "components/modules/crm/activities/activities-viewer/activities-viewer";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import { UserPill } from "components/modules/user-management/users/components/user-pill/user-pill";
import dayjs from "dayjs";

import _ from "lodash";
import { useSelector } from "react-redux";
import { Store } from "redux";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectFbPostById } from "store/modules/fb-posts/fb-posts.selectors";
import { setFbPosts } from "store/modules/fb-posts/fb-posts.slice";
import { selectGroupById } from "store/modules/groups/groups.selectors";
import { selectMarketingReferenceByFacebookAdIdId } from "store/modules/marketing-references/marketing-references.selectors";
import { setMarketingReferences } from "store/modules/marketing-references/marketing-references.slice";
import {
  selectAllUsers,
  selectUser,
  selectUsersByUserGroup,
} from "store/modules/users/users.selectors";
import {
  selectConversationTags,
  selectFirstResponseIntervalConfig,
} from "store/modules/workspace/workspace.selectors";
import { convertMillisecondsToReadableString } from "utils/convert-milliseconds-to-readable-string";
import { CountryCodes } from "utils/country-codes";
import { iReportWidget } from "../components/report-canvas/widget-canvas-models";
import { getMomentDurationFromPeriod } from "../components/report-widget/utils/normalize-graph-data";
import { ConversationsReportsBackendSchema } from "../schemas/backend-report-schemas/conversation-report-backend-schema";
import { durationOptions } from "./chat-duration-options";

import weekOfTheYear from "dayjs/plugin/weekOfYear";
import { ConversationSourceLabelsMap } from "./conversssation-source-label-maps";
import { generateIntervalOptions } from "./generate-interval-options";

dayjs.extend(weekOfTheYear);

export type iReportLabelRenderer = Record<
  string,
  {
    inputTransformers?: (value: string, store: Store) => Record<string, any>;
    label?: string;
    template?: (value: string) => JSX.Element;
    hiddenAttributes?: string[];
    idAttributes?: string[];
    inspectorTransformer?: (
      value,
      store: Store,
      originalFilterConfig: {
        filter: any;
        dateField: string;
        startDate: number;
        endDate: number;
      },
      reportSpec: iReportWidget
    ) => any;
  }
>;

const UserGroupLabel = ({ userGroupId }: { userGroupId: string }) => {
  const userGroup = useSelector(selectGroupById(userGroupId));
  if (userGroupId === "Empty") {
    return <Tag>Empty</Tag>;
  }
  return <Tag>{userGroup.label}</Tag>;
};

export type iReportDimensionProcessor = Record<
  string,
  {
    preProcessor?: (date: any[], sdk: iSDK, store: Store) => Promise<any[]>;
    postProcessor?: (date: any[], sdk: iSDK, store: Store) => Promise<any[]>;
  }
>;
export const ReportLabelRenderers: iReportLabelRenderer = {
  users: {
    inputTransformers: (userId, store) => ({
      "User Name": getUserName(selectUser(userId)(store.getState())),
      users: userId,
    }),
    label: "User",
    template: (userId) => <UserPill userId={userId} />,
    hiddenAttributes: ["User Name"],
    idAttributes: ["users"],
    inspectorTransformer: (rec) => ({
      ..._.omit(rec, "User Name", "users"),
      "members.userId": rec.users,
    }),
  },
  userGroup: {
    inputTransformers: (userGroupId, store) => ({
      "User Group Name":
        userGroupId !== "Empty"
          ? selectGroupById(userGroupId)(store.getState())?.label
          : "Empty",
      userGroup: userGroupId,
    }),
    label: "User Group",
    template: (userGroupId) => <UserGroupLabel userGroupId={userGroupId} />,
    hiddenAttributes: ["User Group Name"],
    idAttributes: ["userGroup"],
    inspectorTransformer: (rec, store) => ({
      ..._.omit(rec, "User Group Name", "userGroup"),
      "members.userId": {
        $in: (() => {
          if (rec.userGroup === "Empty") {
            const allUsers = selectAllUsers(store.getState());
            const noGroupUsers = allUsers.filter(
              (item) => !item.userGroups || item.userGroups.length === 0
            );
            return noGroupUsers.map((user) => user.id);
          }

          return selectUsersByUserGroup(store.getState())[rec.userGroup].map(
            (user) => user.id
          );
        })(),
      },
    }),
  },
  tags: {
    label: "Tag",
    template: (tag) => <Tag>{tag}</Tag>,
    hiddenAttributes: [],
  },
  date: {
    label: "Date",
    hiddenAttributes: ["startOfDay"],
    inspectorTransformer: (rec, store, originalFilterConfig, reportSpec) => {
      const periodOption = reportSpec.aggregateByPeriod;
      const date = rec.date; // 2022-09-19;
      const timeSplits = (date || "").split("-").map((item) => Number(item));
      let startOfPeriod = dayjs();

      for (let i = 0; i < timeSplits.length; i++) {
        if (i === 0) {
          startOfPeriod = startOfPeriod.year(timeSplits[i]);
        } else if (i === 1) {
          if (periodOption === "WEEK") {
            startOfPeriod = startOfPeriod.week(timeSplits[i]);
          } else if (
            periodOption === "MONTH" ||
            periodOption === "DAY" ||
            periodOption === "HOUR"
          ) {
            startOfPeriod = startOfPeriod.month(timeSplits[i] - 1);
          }
        } else if (i === 2) {
          startOfPeriod = startOfPeriod.date(Number(timeSplits[i]));
        } else if (i === 3) {
          startOfPeriod = startOfPeriod.hour(timeSplits[i]);
        }
      }

      // Todo: On a weekly report, if month is added, the inspect will show all month
      return {
        ..._.omit(rec, "date"),
        [ConversationsReportsBackendSchema.dateFilterTypes[
          originalFilterConfig.dateField
        ]]: {
          $gte: startOfPeriod
            .startOf(getMomentDurationFromPeriod(periodOption as any))
            .valueOf(),
          $lt: startOfPeriod
            .endOf(getMomentDurationFromPeriod(periodOption as any))
            .valueOf(),
        },
      };
    },
  },
  messageCount: {
    label: "Number of Messages",
    template: (text) => <Tag>{text}</Tag>,
    inspectorTransformer: (rec) => {
      const label = rec.messageCount;
      delete rec.messageCount;
      if (label === "50+") {
        rec["metaData.totalMessages"] = {
          $gte: 50,
        };
      }
      if (label === "20-50") {
        rec["metaData.totalMessages"] = {
          $gte: 20,
          $lt: 50,
        };
      }
      if (label === "0-20") {
        rec["metaData.totalMessages"] = {
          $lt: 20,
        };
      }
      if (label === "Unknown") {
        rec["metaData.totalMessages"] = {
          $exists: false,
        };
      }
      return rec;
    },
  },
  categorizedMessageCount: {
    label: "Number of Messages",
    template: (text) => <Tag>{text}</Tag>,
    inspectorTransformer: (rec) => {
      const label = rec.categorizedMessageCount;
      delete rec.categorizedMessageCount;
      if (label === "50+") {
        rec["metaData.totalMessages"] = {
          $gte: 50,
        };
      }
      if (label === "20-50") {
        rec["metaData.totalMessages"] = {
          $gte: 20,
          $lt: 50,
        };
      }
      if (label === "0-20") {
        rec["metaData.totalMessages"] = {
          $lt: 20,
        };
      }
      if (label === "Unknown") {
        rec["metaData.totalMessages"] = {
          $exists: false,
        };
      }
      return rec;
    },
  },
  connections: {
    inputTransformers: (connectionId, store) => ({
      "Connection Name": selectConnectionById(connectionId)(store.getState())
        ?.label,
      connections: connectionId,
    }),
    label: "Connection",
    template: (label) => <Tag>{label}</Tag>,
    hiddenAttributes: ["Connection"],
    inspectorTransformer: (rec) => ({
      ..._.omit(rec, "connection", "connections"),
      connectionId: rec.connections,
    }),
  },
  source: {
    label: "Source",
    inputTransformers: (source) => ({
      source: ConversationSourceLabelsMap[source] || source,
    }),
    inspectorTransformer: (rec) => ({
      ..._.omit(rec, "source"),
      "metaData.initSource": Object.keys(ConversationSourceLabelsMap).find(
        (source) => ConversationSourceLabelsMap[source] === rec.source
      ) || { $exists: false }, // Handling Empty
    }),
  },
  fbPostId: {
    label: "Facebook Post Id",
    // template: (fbPostId) => <UserPill userId={userId} />,
    inputTransformers: (fbPostId, store) => ({
      "Facebook Post":
        selectFbPostById(fbPostId)(store.getState())?.message ||
        fbPostId ||
        "Empty",
      fbPostId: fbPostId,
    }),
    hiddenAttributes: ["Facebook Post Id"],
    inspectorTransformer: (rec) => ({
      ..._.omit(rec, "fbPostId", "Facebook Post"),
      "references.value": rec.fbPostId,
    }),
  },
  fbAdId: {
    label: "FB Ad Id",
    // template: (fbPostId) => <UserPill userId={userId} />,
    inputTransformers: (fbAdId, store) => ({
      "FB Ad Name":
        selectMarketingReferenceByFacebookAdIdId(fbAdId)(store.getState())?.data
          .label ||
        fbAdId ||
        "Empty",
      fbAdId: fbAdId,
    }),
    hiddenAttributes: ["FB Ad Id"],
    inspectorTransformer: (rec) => ({
      ..._.omit(rec, "fbAdId", "FB Ad Name"),
      "references.value": rec.fbAdId,
    }),
  },
  categorizedDuration: {
    label: "Duration",
    inspectorTransformer: (rec) => {
      const label = rec.categorizedDuration;
      delete rec.categorizedDuration;
      const durationFilter = _.find(durationOptions, {
        title: label,
      });
      if (durationFilter) {
        rec["metaData.duration"] = JSON.parse(durationFilter.value);
      }
      return rec;
    },
  },
  categorizedResponseTime: {
    label: "Response Time",
    inspectorTransformer: (rec, store) => {
      const label = rec.categorizedResponseTime;
      delete rec.categorizedResponseTime;

      const intervalOptions = selectFirstResponseIntervalConfig(
        store.getState()
      );
      const responseTimeFilter = _.find(
        generateIntervalOptions(intervalOptions),
        {
          title: label,
        }
      );
      if (responseTimeFilter) {
        rec["metaData.firstResponseTime"] = JSON.parse(
          responseTimeFilter.value
        );
      }

      return rec;
    },
  },
  chatRating: {
    label: "Number of Messages",
    template: (text) => <Tag>{text}</Tag>,
    inspectorTransformer: (rec) => ({
      ..._.omit(rec, "chatRating"),
      "chatRating.rating":
        rec.chatRating === "Empty"
          ? {
              $exists: false,
            }
          : rec.chatRating,
    }),
  },
  // Contacts
  country: {
    inputTransformers: (countryCode, store) => ({
      country: _.find(CountryCodes, { code: countryCode })?.name,
      countryCode: countryCode,
    }),
    label: "Country",
    template: (label) => <Tag>{label}</Tag>,
    hiddenAttributes: ["countryCode"],
    inspectorTransformer: (rec) => ({
      ..._.omit(rec, "country", "countryCode"),
      country: rec.countryCode,
    }),
  },
  emailExists: {
    inputTransformers: (emailExists, store) => ({
      emailExists: emailExists,
      "Has Email": (emailExists as any) === true ? "Yes" : "No",
    }),
    label: "Has Email",
    template: (label) => <Tag>{label}</Tag>,
    hiddenAttributes: ["emailExists"],
    inspectorTransformer: (rec) => ({
      ..._.omit(rec, "Has Email", "emailExists"),
      "data.primaryEmail":
        (rec.emailExists as any) === true
          ? { $exists: true, $ne: "" }
          : { $exists: false },
    }),
  },
  mobileExists: {
    inputTransformers: (mobileExists, store) => ({
      mobileExists: mobileExists,
      "Has Mobile": (mobileExists as any) === true ? "Yes" : "No",
    }),
    label: "Has Mobile",
    template: (label) => <Tag>{label}</Tag>,
    hiddenAttributes: ["mobileExists"],
    inspectorTransformer: (rec) => ({
      ..._.omit(rec, "Has Mobile", "mobileExists"),
      "data.primaryMobile":
        (rec.mobileExists as any) === true
          ? { $exists: true, $ne: "" }
          : { $exists: false },
    }),
  },
  marketingConsentObtained: {
    inputTransformers: (marketingConsentObtained, store) => ({
      marketingConsentObtained: marketingConsentObtained,
      "Obtained Marketing Consent":
        (marketingConsentObtained as any) === true ? "Yes" : "No",
    }),
    label: "Obtained Marketing Consent",
    template: (label) => <Tag>{label}</Tag>,
    hiddenAttributes: ["marketingConsentObtained"],
    inspectorTransformer: (rec) => ({
      ..._.omit(rec, "Obtained Marketing Consent", "marketingConsentObtained"),
      "preference.marketingConsentObtained":
        (rec.marketingConsentObtained as any) === true
          ? { $exists: true, $ne: "" }
          : { $exists: false },
    }),
  },
  // Activities
  activityType: {
    inputTransformers: (activityType, store) => ({
      Type: ActivityLabelMap[activityType] || activityType,
      activityType: activityType,
    }),
    label: "Type",
    template: (label) => <Tag>{label}</Tag>,
    hiddenAttributes: ["activityType"],
    inspectorTransformer: (rec) => ({
      ..._.omit(rec, "Type", "activityType"),
      activityType: rec.activityType,
    }),
  },
};

export const DimensionProcessors: iReportDimensionProcessor = {
  messageCount: {
    preProcessor: async (data) => {
      const grouped = _.groupBy(data, (record) => {
        if (record.messageCount >= 50) {
          return "50+";
        }
        if (record.messageCount >= 20) {
          return "20-50";
        }
        if (record.messageCount < 20) {
          return "0-20";
        }
        return "Unknown";
      });
      return Object.keys(grouped).map((group) => ({
        messageCount: group,
        count: _.sumBy(grouped[group], "count"),
      }));
    },
  },
  fbPostId: {
    preProcessor: (data, sdk, store) => {
      return sdk
        .queryFbPosts({
          query: {
            id: { $in: data.map((record) => record.fbPostId) },
          },
          options: {
            limit: 1000,
          },
        })
        .then((res) => {
          store.dispatch(setFbPosts(res.docs));
          return data;
        });
    },
  },
  fbAdId: {
    preProcessor: (data, sdk, store) => {
      return sdk.marketingReferences
        .query({
          query: {
            "data.fbAdId": { $in: data.map((record) => record.fbAdId) },
          },
          options: {
            limit: 1000,
          },
        })
        .then((res) => {
          store.dispatch(setMarketingReferences(res.docs));
          return data;
        });
    },
  },
  tagsCombination: {
    preProcessor: async (data, sdk, store) => {
      const tags = selectConversationTags(store.getState());
      const tagsByGroup = _.groupBy(tags || [], "tagGroup");
      const availableTagGroups = Object.keys(tagsByGroup);
      // const availableTagGroups = _groupList.map((groupList) =>
      //   groupList === "undefined" ? "General Tags" : groupList
      // );

      const processedData = _.flatten(
        data.map((record) => {
          const tagsCombinations = record.tagsCombination;
          const newRecord = {
            count: record.count,
          };
          for (const tagGroup of availableTagGroups) {
            let isTagGroupFound = false;
            for (const tag of tagsCombinations) {
              const tagRecord = _.find(tags, { label: tag });
              if (tagRecord?.tagGroup === tagGroup) {
                newRecord[tagGroup] = tag;
                isTagGroupFound = true;
              }
            }
            if (!isTagGroupFound) {
              newRecord[tagGroup] = "Empty";
            }
          }
          return newRecord;
        })
      );
      return processedData;
    },
  },
};

export const AggregateFunctionLabels = {
  SUM: "Total",
  AVERAGE: "Average",
  BUCKET_AUTO: "Total",
};

export const AggregateTargetLabels = {
  duration: {
    label: "duration",
    template: (value) => convertMillisecondsToReadableString(value),
  },
  responseTime: {
    label: "response time",
    template: (value) => convertMillisecondsToReadableString(value),
  },
};
