import MailOutlined from "@ant-design/icons/MailOutlined";
import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import { SDK, useSDK } from "@sdk";
import { iContact } from "@sdk/crm/crm.models";
import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { processServerError, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { MobileNumberInput } from "components/common/mobile-number-input/mobile-number-input";
import { ModalTitle } from "components/common/modal-title";
import { QuickContactMergeModal } from "components/pages/contacts/components/quick-contact-merge-modal/quick-contact-merge-modal";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { loadAllContactLists } from "store/modules/contact-lists/contact-lists.helpers";
import { selectAllContactLists } from "store/modules/contact-lists/contact-lists.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { cleanMobileNumber } from "utils/clean-mobile-number";
import { CountryCodes } from "utils/country-codes";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { debouncedContactAvailabilityValidator } from "../../helpers/contact-identifier-validator";
import "./edit-contact.scss";

export const EditContactModal = ({
  contactId,
  visible,
  onChangeVisibility,
  isMinimal,
}: {
  contactId: string;
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  isMinimal?: boolean;
}) => {
  const currentUser = useSelector(selectCurrentUser);
  const role = currentUser
    ? getRoleFromPermission(currentUser.permissions)
    : "";

  const onEdited = (contact) => {};

  const { data: contact, isLoading } = useSDK(
    (SDK) => SDK.getContactById(contactId),
    [contactId],
    !contactId
  );

  const alreadyUnsubscribed = (contact?.marketingLists || [])
    .filter((list) => list.isUnsubscribed)
    .map((list) => list.id);

  const defaultFormValue = useMemo(
    () => ({
      ...contact,
      lists: (contact.marketingLists || [])
        .filter((list) => !list.isUnsubscribed)
        .map((list) => list.id!),
    }),
    [contact]
  );

  const [editContactForm] = useForm();

  useEffect(() => {
    editContactForm.resetFields();
  }, [defaultFormValue, editContactForm]);

  // Edit Call

  const [isProcessing, setIsPrecessing] = useState(false);

  const onEditContact = async () => {
    const formValue = editContactForm.getFieldsValue();

    const editedValue: Partial<iContact> = {
      ...formValue,
      marketingLists: _.unionBy(
        [
          ...alreadyUnsubscribed.map((listId) => {
            const existingRecord = _.find(contact.marketingLists, {
              id: listId,
            });
            return existingRecord!;
          }),
          ...(formValue.lists || [])
            .map((listId) => ({
              id: listId,
            }))
            .map((list) => {
              const existingRecord = _.find(contact.marketingLists, {
                id: list.id,
              });
              if (existingRecord) {
                return existingRecord;
              }
              return { ...list, dateAdded: new Date().getTime() };
            }),
        ],
        "id"
      ),
    };

    setIsPrecessing(true);
    try {
      const contact = await SDK.editContact(contactId, editedValue);
      message.success("Contact has been edited");
      onEdited(contact);
      onChangeVisibility(false);
      setIsPrecessing(false);
      setTimeout(() => {
        editContactForm.setFieldsValue(defaultFormValue);
      }, 400);
    } catch (e) {
      console.log("Error while editing contact", e);
      message.error(processServerError(e, "Something went wrong"));
      setIsPrecessing(false);
    }
  };

  const tags: any[] = useMemo(() => [], []);

  const { state: contactLists, retry: reload } = useQueryWithStore(
    selectAllContactLists,
    loadAllContactLists
  );

  const [quickMergeModal, setQuickMergeModal] = useSimpleState({
    isVisible: false,
    email: "",
    phone: "",
  });

  const {
    doAction: deleteContact,
    isProcessing: isDeleteProcessing,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () =>
        SDK.deleteContact(contactId).then((res) => {
          //onEdited();
          onChangeVisibility(false);
        }),
      successMessage: "User has been deleted",
      failureMessage: "Something went wrong",
    }),
    [contactId, onChangeVisibility]
  );

  return (
    <Modal
      title={
        <ModalTitle
          title="Edit Customer Record"
          icon={<i className="ri-folder-user-line"></i>}
        />
      }
      open={visible}
      onOk={async () => onEditContact()}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      footer={undefined}
      okButtonProps={{
        loading: isProcessing,
        icon: <i className="ri-save-line"></i>,
      }}
      okText={"Save Contact"}
      width={isMinimal ? 450 : 900}
      data-click-context="Edit Contact Modal"
    >
      <Form
        layout="vertical"
        form={editContactForm}
        initialValues={defaultFormValue}
        className="bold-form-labels edit-contact-modal"
      >
        <div className="flex flex-row">
          <div className="main-contact-details flex-1 mr-2">
            {/* First Name */}
            <Row justify="space-between">
              <Col span={11}>
                {/* First Name*/}

                <Form.Item
                  name={["data", "firstName"]}
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="First name"
                    size="large"
                  />
                </Form.Item>
              </Col>

              {/* Last Name */}
              <Col span={11}>
                <Form.Item name={["data", "lastName"]} label="Last Name">
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Last name"
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* Mobile Number */}
            <Form.Item
              label="Primary Mobile"
              name={["data", "primaryMobile"]}
              rules={[
                ({ getFieldValue }) => ({
                  async validator(rule, value) {
                    if (value) {
                      const mobile = await cleanMobileNumber(value);
                      if (!mobile.isValid || !mobile.isValidMobileNumber) {
                        return Promise.reject(
                          "Invalid Mobile Number. Please enter complete mobile number with country and area codes"
                        );
                      } else {
                        return Promise.resolve();
                      }
                    }
                    // Phone is not mandatory
                    // return Promise.reject("Invalid Phone Number");
                    return Promise.resolve();
                  },
                }),
                ({ getFieldValue }) => ({
                  validator: async (rule, value) => {
                    const mobile = await cleanMobileNumber(value);
                    return await debouncedContactAvailabilityValidator(
                      {
                        mobile: mobile.mobileId,
                      },
                      contactId
                    );
                  },
                }),
              ]}
            >
              <MobileNumberInput />
              {/* <Input
                placeholder=""
                prefix={
                  <i className="ri-smartphone-line site-form-item-icon"></i>
                }
              /> */}
            </Form.Item>
            <Form.Item shouldUpdate className="error-field">
              {({ getFieldValue, getFieldError }) => {
                const emailError = getFieldError("primaryMobile");
                if (
                  emailError &&
                  emailError.includes("Duplicate Primary Identifier")
                ) {
                  return (
                    <div className="text-red-600 text-center">
                      Similar Contact Exists.{" "}
                      <Button
                        type="dashed"
                        icon={<i className="ri-user-search-line"></i>}
                        onClick={() =>
                          setQuickMergeModal({
                            isVisible: true,
                            phone: getFieldValue("primaryMobile"),
                          })
                        }
                      >
                        Find and Merge Contact
                      </Button>
                    </div>
                  );
                }
                return <div></div>;
              }}
            </Form.Item>
            {/* Email */}
            <Form.Item
              label="Primary Email"
              name={["data", "primaryEmail"]}
              normalize={(value) => ((value || "") as string).toLowerCase()}
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
                ({ getFieldValue }) => ({
                  validator: async (rule, value) => {
                    return await debouncedContactAvailabilityValidator(
                      {
                        email: value,
                      },
                      contactId
                    );
                  },
                }),
              ]}
            >
              <Input
                placeholder=""
                prefix={<MailOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item shouldUpdate className="error-field">
              {({ getFieldValue, getFieldError }) => {
                const emailError = getFieldError("primaryEmail");
                if (
                  emailError &&
                  emailError.includes("Duplicate Primary Identifier")
                ) {
                  return (
                    <div className="text-red-600 text-center">
                      Similar Contact Exists.{" "}
                      <Button
                        type="dashed"
                        icon={<i className="ri-user-search-line"></i>}
                        onClick={() =>
                          setQuickMergeModal({
                            isVisible: true,
                            email: getFieldValue("primaryEmail"),
                          })
                        }
                      >
                        Find and Merge Contact
                      </Button>
                    </div>
                  );
                }
                return <div></div>;
              }}
            </Form.Item>
            {/* Tags */}
            <Form.Item label="Tags" name={["data", "tags"]}>
              <Select
                mode="tags"
                onChange={(selectedTags) => {
                  const difference = _.difference(
                    selectedTags as any,
                    tags.map((item) => item.tag)
                  );
                  if (difference.length > 0) {
                    // Todo:
                    // SDK.addContactTag(difference[0]).catch((e) => {
                    //   console.log("Error while saving tags", e);
                    // });
                  }
                }}
                suffixIcon={<i className="ri-price-tag-3-line"></i>}
              >
                {tags.map((tag) => (
                  <Select.Option key={tag.tag} value={tag.tag}>
                    {tag.tag}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {!isMinimal && (
            <div className="additional-details flex-1 ml-2">
              <Collapse className="border-0" defaultActiveKey={["MARKETING"]}>
                <Collapse.Panel
                  header="Additional Contact Details"
                  key="CONTACT_DETAILS"
                >
                  <div className="label font-bold mb-2">Phone</div>
                  <Form.List name={["data", "phones"]}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Space
                            key={field.key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...field}
                              name={[field.name, "phone"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing phone number",
                                },
                              ]}
                              hasFeedback={false}
                              style={{ marginBottom: 0 }}
                            >
                              <Input placeholder="Phone" />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "label"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing phone number type",
                                },
                              ]}
                              hasFeedback={false}
                              style={{ marginBottom: 0 }}
                            >
                              <Select style={{ minWidth: 150 }}>
                                <Select.Option value="work">Work</Select.Option>
                                <Select.Option value="personal">
                                  Personal
                                </Select.Option>
                              </Select>
                            </Form.Item>
                            <MinusCircleOutlined
                              onClick={() => remove(field.name)}
                            />
                          </Space>
                        ))}
                        <div
                          onClick={() => add({ type: "work", phone: "" })}
                          className="cursor-pointer"
                        >
                          + add one more
                        </div>
                      </>
                    )}
                  </Form.List>
                  <div className="label font-bold mb-2 mt-4">Email</div>
                  <Form.List name={["data", "emails"]}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Space
                            key={field.key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...field}
                              name={[field.name, "email"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing first name",
                                },
                              ]}
                              hasFeedback={false}
                              style={{ marginBottom: 0 }}
                            >
                              <Input placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "label"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing last name",
                                },
                              ]}
                              hasFeedback={false}
                              style={{ marginBottom: 0 }}
                            >
                              <Select style={{ minWidth: 150 }}>
                                <Select.Option value="work">Work</Select.Option>
                                <Select.Option value="personal">
                                  Personal
                                </Select.Option>
                              </Select>
                            </Form.Item>
                            <MinusCircleOutlined
                              onClick={() => remove(field.name)}
                            />
                          </Space>
                        ))}
                        <div
                          onClick={() => add({ type: "work", email: "" })}
                          className="cursor-pointer"
                        >
                          + add one more
                        </div>
                      </>
                    )}
                  </Form.List>
                </Collapse.Panel>
                <Collapse.Panel header="Address" key="ADDRESS">
                  <Form.Item label="Country" name={["data", "country"]}>
                    <Select showSearch>
                      {CountryCodes.map((country) => (
                        <Select.Option
                          value={`${country.code}|${country.name}`}
                          key={country.code}
                        >
                          {country.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Collapse.Panel>
                <Collapse.Panel header="Marketing" key="MARKETING">
                  <Form.Item label="Lists" name={["lists"]}>
                    <Select showSearch mode="multiple">
                      {contactLists.map((list) => (
                        <Select.Option value={list.id!} key={list.id}>
                          {list.data?.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Marketing Preference"
                    name={["preference", "marketingConsentObtained"]}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Collapse.Panel>

                {role === "Owner" || role === "Manager" ? (
                  <Collapse.Panel header="dangerous" key="DANGEROUS">
                    <Popconfirm
                      title={
                        <>
                          <div className="font-semibold">
                            Are you sure that you want to delete the contact?{" "}
                          </div>
                          <div className=" text-gray-700">
                            This will delete the contact record and the
                            conversations associated with the contact.
                          </div>
                          <div className=" text-red-500">
                            This action is irreversible
                          </div>
                        </>
                      }
                      onConfirm={() => {
                        deleteContact();
                      }}
                      okButtonProps={{ loading: isDeleteProcessing }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="link">
                        <i className="ri-close-line"></i> Delete Contact
                      </Button>
                    </Popconfirm>
                  </Collapse.Panel>
                ) : (
                  ""
                )}
              </Collapse>
            </div>
          )}
        </div>
      </Form>
      <QuickContactMergeModal
        visible={quickMergeModal.isVisible}
        contactId={contactId}
        email={quickMergeModal.email}
        phone={quickMergeModal.phone}
        onChangeVisibility={(visibility) => {
          setQuickMergeModal({ isVisible: visibility });
        }}
        onMerged={() => {
          setQuickMergeModal({ isVisible: false });
          onChangeVisibility(false);
          onEdited(contact);
        }}
      />
      <DarkModeBg />
    </Modal>
  );
};
