import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iConnectionType } from "@sdk/conversations/conversations.models";
import { Button, Divider } from "antd";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import {
  AddChatWidget,
  AddEmailConnection,
  AddFacebookConnection,
  AddInstagramConnection,
  AddWhatsappConnection
} from "modal-registry";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { AvailableConnectionListVersion2 } from "../modules/connections/connection-list";

export const ConfigurationsQuickAccess = () => {
  const { changePanelState } = useModalPanels();
  const history = useHistory();

  const quickAccessItems = useMemo(() => {
    return [
      {
        icon: <i className="ri-user-add-line"></i>,
        label: "Invite User",
        onclick: () => {
          history.push(
            {
              pathname: `/configurations/user-management`,
              search: "show-invite=true"
            },
            {
              showInvite: true
            }
          );
        }
      },
      {
        icon: <i className="ri-price-tag-3-line"></i>,
        label: "Manage Conversation Tags",
        onclick: () => {
          history.push({
            pathname: `/configurations/inbox-settings/general`,
            search: "activeConfigs=Conversation%20Tags"
          });
        }
      },
      {
        icon: <i className="ri-slack-line"></i>,
        label: "Connect Slack",
        onclick: () => {
          history.push({
            pathname: `/configurations/apps-and-integrations`,
            search: "activeConfigs=Slack%20Notification"
          });
        }
      }
    ];
  }, [history]);

  const onAddConnection = useCallback(
    (type: iConnectionType) => {
      const connectionMap = {
        EMAIL: AddEmailConnection,
        WEB_CHAT: AddChatWidget,
        FACEBOOK: AddFacebookConnection,
        WHATS_APP_PRIVATE: AddWhatsappConnection,
        INSTAGRAM: AddInstagramConnection
      };
      if (connectionMap[type]) {
        changePanelState(connectionMap[type], true, {});
      }
    },
    [changePanelState]
  );
  return (
    <StyledScrollArea className="h-full w-full animated fadeInLeftMin">
      <SimpleCardSection
        className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4"
        style={{ minHeight: 300, maxWidth: 1000 }}
      >
        <SectionHeader
          title="Quick Access"
          icon={<i className="ri-flashlight-line"></i>}
          description="Easy Access to your most used settings"
        />

        <Divider></Divider>

        <div className="font-4xl font-bold mb-4 mt-12">Commonly Used:</div>
        <div className="flex flex-row gap-4  flex-wrap">
          {quickAccessItems.map(item => (
            <Button
              type="dashed"
              icon={<div className="text-3xl font-bold">{item.icon}</div>}
              className="flex flex-row justify-start border border-gray-300 dark:border-gray-700 p-4 hover:bg-blue-100 dark:hover:bg-gray-800 font-semibold"
              style={{ width: "18em", height: "4em" }}
              onClick={item.onclick}
              key={item.label}
            >
              {item.label}
            </Button>
          ))}
        </div>
        <div className="font-4xl font-bold mb-4 mt-12">
          Configure a New Connection:
        </div>
        <div className="flex flex-row gap-4 flex-wrap">
          {AvailableConnectionListVersion2.map(item => (
            <Button
              type="dashed"
              icon={<div className="text-4xl font-bold">{item.icon}</div>}
              className="flex flex-row justify-start border border-gray-300 dark:border-gray-700 p-4 hover:bg-blue-100 dark:hover:bg-gray-800 font-semibold"
              style={{ width: "18em", height: "4em" }}
              onClick={() => onAddConnection(item.key as any)}
              key={item.label}
            >
              {item.label}
            </Button>
          ))}
        </div>
        <div className="mb-12"></div>

        {/* <Divider className="my-16">
            <Tag>Your Account Manager</Tag>
          </Divider>
          <div className="flex flex-row justify-center items-center w-full">
            <div style={{ maxWidth: 500 }}>
              <ContactAccountManagerCard type="CARD" />
            </div>
          </div> */}
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
