import { Modal } from "antd";
import { EditableTextArea } from "components/common/editable-text/editable-text";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useEffect, useState } from "react";
import { useViewRefresher } from "utils/hooks/use-view-refresher";

export const MessageVariantEditorModal = ({
  visible,
  onChangeVisibility,
  existingVariants,
  onSave
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  existingVariants: string[];
  onSave: (variants: string[]) => any;
}) => {
  const [state, setState] = useState(existingVariants || []);

  useEffect(() => {
    setState(existingVariants);
  }, [existingVariants]);

  const { visible: isAddQuickReplyVisible, reRender } = useViewRefresher();

  return (<>
    <Modal
      title={
        <ModalTitle
          title="Message Variants"
          icon={<i className="ri-chat-new-line"></i>}
        />
      }
      open={visible}
      onOk={async () => {
        onSave(state);
        onChangeVisibility(false);
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText={
        <>
          <i className="ri-save-line"></i> Save
        </>
      }
      bodyStyle={{ padding: 0 }}
      data-click-context="Message Variant Modal"
    >
      <div className="p-8 text-xl">
        {state.map((variant, index) => (
          <div
            key={index.toString()}
            className="flex flex-row justify-between relative"
          >
            <EditableTextArea
              value={variant}
              placeholder={"Edit Message Variant"}
              onSave={value => {
                setState(variants => {
                  const variantsClone = [...variants];
                  variantsClone[index] = value;
                  return variantsClone;
                });
              }}
            />
          </div>
        ))}
        {isAddQuickReplyVisible && (
          <EditableTextArea
            value={""}
            placeholder={"+ Message Variant"}
            onSave={value => {
              setState(variants => [...variants, value]);
              reRender();
            }}
          />
        )}
      </div>
      <DarkModeBg />
    </Modal>
  </>);
};
