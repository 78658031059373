import { iLocation } from "@sdk/sessions/sessions.models";
import { Tooltip } from "antd";

export const LocationText = ({ location }: { location: iLocation }) => {
  return (
    <Tooltip title={location?.ip}>
      <span className="truncate" style={{ width: 50 }}>
        <span
          className={`flag-icon flag-icon-${location?.data?.country?.toLowerCase()}`}
        ></span>{" "}
        {location?.data?.city || "City not identified"}
      </span>
    </Tooltip>
  );
};
