import { layoutGenerator } from "react-break";

const layout = layoutGenerator({
  mobile: 0,
  largeMobile: 600,
  tablet: 900,
  smallDesktop: 1000,
  largeDesktop: 1300
});

type ReactComponent = ({ children }) => JSX.Element;

// Single Breaking Point
export const OnMobile = layout.is("mobile") as ReactComponent;

// OnAtLeast Breaking points
export const OnAtLeastLargeMobile = layout.isAtLeast(
  "largeMobile"
) as ReactComponent;
export const OnAtLeastTablet = layout.isAtLeast("tablet") as ReactComponent;
export const OnAtLeastSmallDesktop = layout.isAtLeast(
  "smallDesktop"
) as ReactComponent;
export const OnAtLeastLargeDesktop = layout.isAtLeast(
  "largeDesktop"
) as ReactComponent;

// OnAtMost Breaking Points
export const OnAtMostMobile = layout.isAtMost("mobile") as ReactComponent;
export const OnAtMostLargeMobile = layout.isAtMost(
  "largeMobile"
) as ReactComponent;
export const OnAtMostTablet = layout.isAtMost("tablet") as ReactComponent;
export const OnAtMostSmallerDesktop = layout.isAtMost(
  "smallDesktop"
) as ReactComponent;

export const OnAtMostLargerDesktop = layout.isAtMost(
  "largeDesktop"
) as ReactComponent;
