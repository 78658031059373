import { iCondition, iConditionOperator } from "./models";

export const convertConditionsToCascadeOptions = (conditions: iCondition[]) => {
  return conditions.map(condition => ({
    value: condition.key,
    label: condition.label,
    children: condition.operators.map(operator => ({
      value: operator.key,
      label: operator.label
    }))
  }));
};

export const getSelectedCondition = (
  selection: [string, string],
  conditions: iCondition[]
): iConditionOperator | undefined => {
  if (!selection[0] || !selection[1]) {
    return undefined;
  }
  for (const condition of conditions) {
    if (condition.key === selection[0]) {
      for (const operator of condition.operators) {
        if (operator.key === selection[1]) {
          return operator;
        }
      }
    }
  }
  return undefined;
};
