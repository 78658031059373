import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Tooltip } from "antd";
import { StyledFileInput } from "components/common/styled-file-input/styled-file-input";
import { useCallback, useEffect, useState } from "react";
import { uploadFileWithValidation } from "./upload-file-with-validation copy";

export const FileInputWithUploader = ({
  value,
  onChange,
  type,
  entityId,
  accept,
}: {
  value?: any;
  onChange?: any;
  type: string;
  entityId: string;
  accept?: string;
}) => {
  const [fileUrl, setFileUrl] = useState<string | undefined>(value);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  useEffect(() => {
    setFileUrl(value);
  }, [value]);

  useEffect(() => {
    if (value) {
      setFileUrl(value);
    }
  }, [value]);

  const onFileUploaded = useCallback(
    async (fileUrl) => {
      if (onChange) {
        await onChange(fileUrl);
      }
    },
    [onChange],
  );

  const { doAction: uploadFile, isProcessing: isUploading } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (file) =>
          SDK.uploadFile(file, {
            type: type,
            entityId: entityId,
          })
            .then((fileRecord) => {
              setSelectedFile(undefined);
              setFileUrl(fileRecord.url);
              onFileUploaded(fileRecord.url);
            })
            .catch((e) => {
              setSelectedFile(undefined);
              setFileUrl(undefined);
              throw e;
            }),
      }),
      [entityId, onFileUploaded, type],
    );

  return (
    <div className="flex flex-row justify-end items-center">
      <StyledFileInput
        selectedFile={selectedFile}
        onChange={(file) => {
          setSelectedFile(file);
          const acceptedFiles = accept ? accept.split(",") : undefined;
          uploadFileWithValidation(
            entityId,
            file,
            uploadFile,
            setSelectedFile,
            acceptedFiles,
          );
        }}
        isUploading={isUploading}
        className="my-4"
        filePreview={
          fileUrl ? (
            <Button
              href={fileUrl}
              target="_blank"
              referrerPolicy="no-referrer"
              icon={<i className="ri-eye-line"></i>}
              type="dashed"
            >
              Preview Uploaded File
            </Button>
          ) : undefined
        }
        accept={accept}
      />
      {fileUrl && (
        <Tooltip title="Clear File">
          <Button
            type="text"
            icon={<i className="ri-delete-bin-line"></i>}
            onClick={() => onChange(null)}
          ></Button>
        </Tooltip>
      )}
    </div>
  );
};
