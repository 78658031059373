import { Button } from "antd";
import { ItineraryBlockTypeMap, iConfirmAndBlock } from "../itineries-model";
import { ContactAgentBlock } from "./contact-agent-block";

export const ConfirmAndPayBlock = ({
  data
}: {
  data: iConfirmAndBlock["data"];
}) => {
  // const currentUser = useSelector(selectCurrentUser);
  return (
    <div className="flex flex-row justify-start items-start">
      <div className="flex-1 pr-8">
        <div className="flex flex-row justify-start items-center">
          <div className="icon text-4xl mr-2">
            {ItineraryBlockTypeMap.CONFIRM_AND_PAY.icon}
          </div>
          <div className="label text-2xl font-bold">
            {ItineraryBlockTypeMap.CONFIRM_AND_PAY.label}:
          </div>
        </div>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <_ConfirmAndPayBlock data={data} />
      </div>

      <div className="flex-1 pl-8">
        <div className="flex flex-row justify-start items-center">
          <div className="icon text-4xl mr-2">
            {ItineraryBlockTypeMap.AGENT_CONTACT.icon}
          </div>
          <div className="label text-2xl font-bold">
            {ItineraryBlockTypeMap.AGENT_CONTACT.label}:
          </div>
        </div>
        <ContactAgentBlock data={data.contact} />
      </div>
    </div>
  );
};

const _ConfirmAndPayBlock = ({ data }: { data: iConfirmAndBlock["data"] }) => {
  return (
    <div className="flex flex-col">
      Confirm this quote and pay online securely so we can complete your booking
      <div className="flex flex-row items-center mt-4">
        <Button
          type="primary"
          size="large"
          href={data.paymentLink}
          target="_blank"
        >
          Confirm & Pay Now
        </Button>
      </div>
    </div>
  );
};
