import dayjs from "dayjs";
import { useMemo } from "react";

export const useDateRangeFilterPresets = () => {
  const dateRangeFilter = useMemo(() => {
    return [
      { label: "Today", value: [dayjs().startOf("day"), dayjs().endOf("day")] },
      {
        label: "Yesterday",
        value: [
          dayjs()
            .subtract(1, "d")
            .startOf("day"),
          dayjs()
            .subtract(1, "d")
            .endOf("day"),
        ],
      },
      {
        label: "This Month",
        value: [dayjs().startOf("month"), dayjs().endOf("month")],
      },
      {
        label: "Last Month",
        value: [
          dayjs()
            .subtract(1, "month")
            .startOf("month"),
          dayjs()
            .subtract(1, "month")
            .endOf("month"),
        ],
      },
      {
        label: "Last Week",
        value: [
          dayjs()
            .subtract(1, "week")
            .startOf("week"),
          dayjs()
            .subtract(1, "week")
            .endOf("week"),
        ],
      },
    ] as {
      label: string;
      value: [dayjs.Dayjs, dayjs.Dayjs];
    }[];
  }, []);
  return dateRangeFilter;
};
