import {
  appFeatureRequestCategories,
  iAppFeatureRequest,
} from "@sdk/app-feature-requests/app-feature-requests-model";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Divider, Form, Input, Modal, Select, message } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { LazyQuillEditor } from "components/common/quill-editor/lazy-quill-editor";
import { StyledSwitch } from "components/common/styled-swtich";
import { WithAttachments } from "components/pages/bot-builder/node-types/message-node/with-attachments";
import { DarkModeBg } from "dark-mode-bg";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";

export const EditFeatureRequestModal = ({
  visible,
  onChangeVisibility,
  feature,
  onEdited,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  feature: iAppFeatureRequest;
  onEdited: (item: iAppFeatureRequest) => any;
}) => {
  const organization = useSelector(selectOrganization);
  const [form] = Form.useForm();

  const initialValue = useMemo(() => ({ ...feature }), [feature]);

  const { doAction: editFeature, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (edits) =>
        SDK.appFeatureRequests.editById(feature?.id!, edits).then((d) => {
          onEdited(d);
          onChangeVisibility(false);
        }),
      failureMessage: "Something went wrong",
    }),
    [feature?.id, onChangeVisibility, onEdited],
  );

  return (
    <Modal
      title={
        <ModalTitle
          title="Edit Feature Request"
          icon={<i className="ri-file-edit-line"></i>}
        />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={600}
      okButtonProps={{
        loading: isProcessing,
        icon: <i className="ri-save-line"></i>,
        className: "font-bold",
      }}
      okText="Save"
      onOk={async () => {
        try {
          await form.validateFields();
          const formValue = form.getFieldsValue();
          await editFeature(formValue);
        } catch (e) {
          message.error("Please check your input");
        }
      }}
      className="request-new-feature-modal"
      data-click-context="Feature Request Modal"
    >
      <div>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValue}
          className="bold-form-labels"
          requiredMark={false}
        >
          <Form.Item label="Team Comments" name={["teamComment"]}>
            <LazyQuillEditor />
          </Form.Item>
          <Form.Item label="Card Status" name={["status"]}>
            <Select
              placeholder="Select an option"
              options={statusOptions}
            ></Select>
          </Form.Item>
          <Form.Item label="Tags" name={["tags"]}>
            <Select placeholder="Select an tags" mode="tags"></Select>
          </Form.Item>
          <StyledSwitch label="Is Archived" name={["isArchived"]} />
          <StyledSwitch label="Is Deleted" name={["isDeleted"]} />

          <Divider />
          <Form.Item
            name="category"
            label={"Feature Category"}
            rules={[
              {
                required: true,
                message: "Please field is required",
              },
            ]}
          >
            <Select showSearch placeholder="Select a category">
              {appFeatureRequestCategories.map((category) => (
                <Select.Option value={category.id} key={category.id}>
                  <div className="text-semibold">{category.id}</div>
                  <div className="text-gray-600">{category.description}</div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="title"
            label={"Describe your feature request in one line"}
            rules={[
              {
                required: true,
                message: "Please field is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="shortDescription"
            label={
              " Write a short description about the feature that you are requesting"
            }
            rules={[
              {
                required: true,
                message: "Please field is required",
              },
            ]}
          >
            <Input.TextArea placeholder="Eg: Use cases, how it should work etc" />
          </Form.Item>
          <Form.Item label="Additional Details" name={["body"]}>
            <LazyQuillEditor />
          </Form.Item>
          <Form.Item
            name={["attachments"]}
            valuePropName="initialAttachments"
            trigger={"onAttachmentsChange"}
          >
            <WithAttachments
              id={`FEATURE_REQUEST`}
              withoutBorder={true}
              entityType="FEATURE_REQUEST"
              entityId={organization?.id!}
            >
              <div className="text-gray-600 text-center border-2 rounded-lg p-4 border-dashed border-gray-600">
                Drag your images here to upload attachments here
                <br />
                or just paste your image
              </div>
            </WithAttachments>
          </Form.Item>
        </Form>
      </div>
      <DarkModeBg />
    </Modal>
  );
};

const statusOptions = [
  {
    value: "In Review",
    label: "In Review",
  },
  {
    value: "In Backlog",
    label: "In Backlog",
  },
  {
    value: "Selected for Build",
    label: "Selected for Build",
  },
  {
    value: "In Build",
    label: "In Build",
  },
  {
    value: "Released",
    label: "Released",
  },
  {
    value: "Rejected",
    label: "Rejected",
  },
];
