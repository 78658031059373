import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { Button, Divider } from "antd";
import classNames from "classnames";
import { EmptyData } from "components/common/empty-data/empty-data";
import { SectionHeader } from "components/common/section-header";
import {
  iMenuItem,
  iRouteItem
} from "components/common/side-bar-menu/menu-item-mode";
import { SimpleMenuSectionTitle } from "components/common/side-bar-menu/simple-menu-section-title";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { push } from "connected-react-router";
import { AddCustomReport } from "modal-registry";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { selectCustomReports } from "store/modules/workspace/workspace.selectors";
import { SideBarMenu } from "../../../../common/side-bar-menu/side-bar-menu";
import { SimpleScrollableLeftSidePanel } from "../../../../common/side-bar-menu/simple-left-side-panel";
import { iLegacyReportTypes } from "../../schemas/legacy-report-type-schema";
import { CustomReport } from "../csv-reports/csv-report";
import { LegacyReportViewer } from "../legacy-report-viewer/legacy-report-viewer";
import { RawReports } from "../raw-reports/raw-reports";
import { TableReport } from "../raw-reports/table-report";
import { ReportCanvas } from "../report-canvas/report-canvas";
import { ScheduledReports } from "../scheduled-reports/scheduled-reports";
import { UserActivityReport } from "../user-activity-report/user-activity-report";

const OldReportToPathMap = {
  OVERVIEW_REPORT: "overview",
  CONVERSATION_FLOW_REPORT: "conversation-flow-report",
  TEAM_REPORT: "user-report",
  MARKETING_REPORT: "marketing-report",
  REPORT_360: "360-report",
  SCHEDULED_REPORTS: "scheduled-reports",
  CUSTOM_REPORT: "csv-report-generator"
};

export const OldReportViewer = () => {
  const location = useLocation();
  const selectedPath = useMemo(() => {
    const activeKey = location.pathname.split("/")[2];
    return activeKey;
  }, [location]);

  const selectedReport = useMemo(() => {
    for (const key in OldReportToPathMap) {
      if (OldReportToPathMap[key] === selectedPath) {
        return key as iLegacyReportTypes;
      }
    }
  }, [selectedPath]);

  const selectedReportMetaData = useMemo(() => {
    for (const report of StandardReportsMenu) {
      if (report.key === selectedPath) {
        return report;
      }
    }
  }, [selectedPath]);

  const dispatch = useDispatch();

  const setSelectedReports = useCallback(
    reportType => {
      dispatch(push(`reports/${OldReportToPathMap[reportType]}`));
    },
    [dispatch]
  );

  return (
    <StyledScrollArea className="w-full h-full flex-1 animated fadeInLeftMin">
      <SimpleCardSection className=" m-auto p-8 lg:p-12 my-4">
        <SectionHeader
          title={selectedReportMetaData?.label!}
          icon={selectedReportMetaData?.icon!}
        />
        <LegacyReportViewer
          type={selectedReport!}
          onChangeReport={setSelectedReports}
        />
      </SimpleCardSection>
    </StyledScrollArea>
  );
};

export const StandardReportsMenu: iRouteItem[] = [
  {
    type: "LINK_ITEM",
    key: "overview",
    icon: <i className="ri-pie-chart-box-line"></i>,
    label: "General Report",
    component: ReportCanvas
  },
  {
    type: "LINK_ITEM",
    key: "conversation-flow-report",
    icon: <i className="ri-time-line"></i>,
    label: "Conversation Flow Report",
    component: ReportCanvas
  },
  {
    type: "LINK_ITEM",
    key: "user-report",
    icon: <i className="ri-group-line"></i>,
    label: "User Report",
    component: ReportCanvas
  },
  {
    type: "LINK_ITEM",
    key: "marketing-report",
    icon: <i className="ri-advertisement-fill"></i>,
    label: "Marketing Report",
    component: ReportCanvas
  },
  {
    type: "LINK_ITEM",
    key: "lead-report",
    icon: <i className="ri-group-line"></i>,
    label: "Lead Report",
    component: ReportCanvas
  },
  {
    type: "LINK_ITEM",
    key: "activities-report",
    icon: <i className="ri-run-line"></i>,
    label: "Activities Report",
    component: ReportCanvas
  }
  // {
  //   type: "LINK_ITEM",
  //   key: "360-report",
  //   icon: <i className="ri-folder-chart-fill"></i>,
  //   label: "360 Report",
  //   component: OldReportViewer
  // },
  // {
  //   type: "LINK_ITEM",
  //   key: "report-canvas",
  //   icon: <i className="ri-folder-chart-fill"></i>,
  //   label: "Report Canvas",
  //   component: ReportCanvas
  // }
];

export const OtherReportMenuItems: iRouteItem[] = [
  {
    type: "LINK_ITEM",
    key: "scheduled-reports",
    icon: <i className="ri-calendar-line"></i>,
    label: "Scheduled Reports",
    component: ScheduledReports
  },
  {
    type: "LINK_ITEM",
    key: "csv-report-generator",
    icon: <i className="ri-grid-line"></i>,
    label: "CSV Report Generator",
    component: CustomReport
  },
  {
    type: "LINK_ITEM",
    key: "raw-report-generator",
    icon: <i className="ri-archive-drawer-line"></i>,
    label: "Raw Report Generator",
    component: RawReports
  },
  {
    type: "LINK_ITEM",
    key: "table-report-generator",
    icon: <i className="ri-table-line"></i>,
    label: "Table Report Generator (Beta)",
    component: TableReport
  },
  {
    type: "LINK_ITEM",
    key: "user-activity-report",
    icon: <i className="ri-mac-line"></i>,
    label: "User Activity Report (Beta)",
    component: UserActivityReport
  }
];

export const ReportTypeIcons = {
  GENERAL: "ri-dashboard-line",
  CONVERSATION: "ri-chat-poll-line",
  LEADS: "ri-group-line",
  ACTIVITIES: "ri-run-line",
  USER_REPORT: "ri-team-line",
  COMPANIES: "ri-building-line",
  OPPORTUNITIES: "ri-money-dollar-circle-line",
  USER_PERFORMANCE_REPORT: "ri-contacts-line"
};

export const ReportsMenuComponent = () => {
  const history = useHistory();
  const location = useLocation();

  const selectedPath = useMemo(() => {
    const activeKey = location.pathname.split("/")[2];
    if (activeKey === "custom-reports") {
      return activeKey + "/" + location.pathname.split("/")[3];
    }
    return activeKey;
  }, [location]);

  console.log("selectedPath", selectedPath);

  const customReports = useSelector(selectCustomReports);

  const customReportsMenu = useMemo(() => {
    return customReports.map(
      item =>
        ({
          type: "LINK_ITEM",
          icon: (
            <i className={classNames(ReportTypeIcons[item.type], "text-lg")} />
          ),
          key: `custom-reports/${item.id}`,
          label: item.title
        } as iMenuItem)
    );
  }, [customReports]);

  const { changePanelState } = useModalPanels();

  return (
    <>
      <div className="illustration-container">
        <img
          src="/assets/illustrations/data-report-animate.svg"
          width={250}
          alt="Email"
        />
      </div>
      <SimpleMenuSectionTitle>Standard Reports</SimpleMenuSectionTitle>
      <SideBarMenu
        menuItems={StandardReportsMenu}
        onMenuClick={item => {
          history.push({
            pathname: `/reports/${item.key}`
          });
        }}
        selectedKeys={selectedPath}
      />
      <Divider></Divider>
      <SimpleMenuSectionTitle className="flex flex-row justify-between">
        <div className="">Custom Reports</div>
        <Button
          className="font-bold"
          type="text"
          icon={<i className="ri-add-line"></i>}
          onClick={() => changePanelState(AddCustomReport, true, {})}
        >
          New
        </Button>
      </SimpleMenuSectionTitle>
      {customReportsMenu.length === 0 && (
        <EmptyData
          icon={<i className="ri-search-line"></i>}
          text="No Custom Reports Created"
        />
      )}
      <SideBarMenu
        menuItems={customReportsMenu}
        onMenuClick={item => {
          history.push({
            pathname: `/reports/${item.key}`
          });
        }}
        selectedKeys={selectedPath}
      />

      <Divider></Divider>
      <SideBarMenu
        menuItems={OtherReportMenuItems}
        onMenuClick={item => {
          history.push({
            pathname: `/reports/${item.key}`
          });
        }}
        selectedKeys={selectedPath}
      />
    </>
  );
};

export const ReportsSideBar = (...props) => {
  return (
    <SimpleScrollableLeftSidePanel className="animated fadeInLeftMin">
      <ReportsMenuComponent />
    </SimpleScrollableLeftSidePanel>
  );
};
