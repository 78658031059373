import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Form, Input, message, Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback } from "react";
import { useStore } from "react-redux";
import { generateDefaultKBConfig } from "./genereate-default-kb-config";

export const AddSelfServicePortalModal = ({
  visible,
  onChangeVisibility,
  onCreated
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated: () => any;
}) => {
  const [form] = Form.useForm();

  const {
    doAction: onAddSelfServicePortal,
    isProcessing,
    response
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.selfServicedPortals
          .create({
            ...values
          })
          .then(res => {
            onCreated();
            onChangeVisibility(false);
            form.resetFields();
          }),
      successMessage: "Portal has been created",
      failureMessage: "Something went wrong"
    }),
    [form, onChangeVisibility, onCreated]
  );

  const store = useStore();

  const createSelfServicePortal = useCallback(
    async req => {
      const config = await generateDefaultKBConfig(store);
      onAddSelfServicePortal({
        ...req,
        data: config
      });
    },
    [onAddSelfServicePortal, store]
  );

  return (
    (<Modal
      title={
        <ModalTitle
          icon={<i className="ri-folder-add-line"></i>}
          title="Create New Self Service Portal"
        />
      }
      open={visible}
      onOk={async () => {
        try {
          await form.validateFields();
          createSelfServicePortal(form.getFieldsValue());
        } catch (e) {
          message.error("Please check your input");
        }
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Create"
      data-click-context="Create New Self Service Portal Modal"
    >
      <Form form={form} layout="vertical" initialValues={{}}>
        <Form.Item
          name="label"
          label=""
          rules={[
            {
              required: true,
              message: "Please enter a label"
            }
          ]}
        >
          <Input placeholder="Label" size="large" />
        </Form.Item>
      </Form>
      <DarkModeBg />
    </Modal>)
  );
};
