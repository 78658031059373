import { iUserAppPermission } from "@sdk/user-management/user-management.models";
import React from "react";
import { useSelector } from "react-redux";
import { iStore } from "./store/store.model";
import { GetSafely } from "./utils/get-safely";

interface iPermissionGuard {
  [appId: string]: {
    [scope: string]: Record<string, unknown>;
  };
}

const CheckPermission = (
  requiredPermissions: iPermissionGuard,
  userPermissions: iUserAppPermission[]
) => {
  // Todo: Check
  let hasPermission = true;
  for (const requiredApp in requiredPermissions) {
    for (const requiredScope in requiredPermissions[requiredApp]) {
      const matched = userPermissions.filter(appPermission => {
        return appPermission.scopes.filter(scopeData => {
          if (
            (requiredScope === "*" || requiredScope === scopeData.scope) &&
            appPermission.appId === requiredApp
          ) {
            return true;
          } else {
            return false;
          }
        }).length;
      });
      if (matched.length === 0) {
        hasPermission = false;
        return false;
      }
    }
  }
  return hasPermission;
};

const convertStringPermissionToGuardObject = (permissions: string[]) => {
  return permissions.reduce((prev, curr) => {
    const parts = curr.split(":");
    if (!prev[parts[0]]) {
      prev[parts[0]] = {};
    }
    if (!prev[parts[0]][parts[1]]) {
      prev[parts[0]][parts[1]] = {};
    }
    return prev;
  }, {} as iPermissionGuard);
};

export const useCan = (requiredPermissions?: iPermissionGuard | string[]) => {
  if (Array.isArray(requiredPermissions)) {
    requiredPermissions = convertStringPermissionToGuardObject(
      requiredPermissions
    );
  }
  const permissions = useSelector((state: iStore) =>
    GetSafely(() => state.users.byIds[state.users.currentUser!].permissions)
  );
  // Permission is not loaded
  if (!permissions) {
    return {
      permissions: permissions,
      isReady: Boolean(permissions)
    };
  }

  // if Permission is provided
  if (requiredPermissions) {
    return {
      hasPermission: CheckPermission(requiredPermissions, permissions),
      role: permissions,
      isReady: Boolean(permissions)
    };
  }

  // No permission is provided
  return {
    can: (requiredPermissions: iPermissionGuard | string[]) => {
      if (Array.isArray(requiredPermissions)) {
        requiredPermissions = convertStringPermissionToGuardObject(
          requiredPermissions
        );
      }
      return CheckPermission(requiredPermissions, permissions);
    },
    role: permissions,
    isReady: Boolean(permissions)
  };
};

export const Has = ({
  requiredPermissions,
  children
}: {
  requiredPermissions?: iPermissionGuard | string[];
  children: JSX.Element;
}) => {
  const { hasPermission } = useCan(requiredPermissions);
  if (hasPermission) {
    return children;
  }
  return <></>;
};
