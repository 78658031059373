import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iUser } from "@sdk/user-management/user-management.models";
import { Button, Divider, Form, Input, Select, Switch } from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { HelpCenterPromptButton } from "components/common/help-center/embeddable-components/help-center-prompt-button";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import copyToClipboard from "copy-to-clipboard";
import dayjs from "dayjs";
import _ from "lodash";
import { AddAPIUser } from "modal-registry";
import { useMemo } from "react";
import { loadAllUsers } from "store/modules/users/users.helpers";
import { selectAllAPIUsers } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import "./apps-and-integrations.scss";
import { MailchimpListSelector } from "./mailchimp-list-selector/mailchimp-list-selector";
import { ManageConnectedApps } from "./manage-conncted-apps/manage-conncted-apps";
import { ShopifyIntegrationStatus } from "./shopify-integration/shopify-integration";

export const AppsAndIntegrations = () => {
  const { state: apiUsers, retry: reload } = useQueryWithStore(
    selectAllAPIUsers,
    loadAllUsers(),
  );

  const zapierTokens = useMemo(() => {
    return _.filter(apiUsers, (user) => user.data.lastName.includes("Zapier"));
  }, [apiUsers]);

  const apiTokens = useMemo(() => {
    return _.filter(apiUsers, (user) => !user.data.lastName.includes("Zapier"));
  }, [apiUsers]);

  const { changePanelState } = useModalPanels();

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin">
      <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4">
        <SectionHeader
          title="Apps and Integrations"
          icon={<i className="ri-plug-line"></i>}
          description="Make ClickConnector work with other tools you use in your day-to-day to supercharge your workflow"
          illustrationImage="/assets/illustrations/server-animate.svg"
        />
        <Divider />

        <div className="apps w-full">
          <div className="flex flex-row justify-between items-center">
            <div className="font-lg font-bold my-8">Native Apps</div>
            <Button
              type="text"
              icon={<i className="ri-arrow-right-circle-line"></i>}
              className=""
              onClick={() => {
                (window as any).ccWidget.setWidgetVisibility(true);
              }}
            >
              Request an Integration
            </Button>
          </div>

          <ConfigurationEditorComponent
            icon={
              <img
                src="/assets/integrations/slack-icon.svg"
                style={{ width: 40 }}
                alt=""
              />
            }
            title={"Slack Notification"}
            description={"Receive notifications via Slack"}
            entityType={"WORKSPACE"}
          >
            <div className="auto-trigger flex flex-row items-center justify-between mb-4">
              <div className="font-bold">Send notifications to Slack</div>
              <Form.Item
                name={[
                  "appData",
                  "HELP_DESK",
                  "slackNotifications",
                  "isActive",
                ]}
                hasFeedback={false}
                valuePropName="checked"
                style={{ marginBottom: 0, marginLeft: 5 }}
              >
                <Switch />
              </Form.Item>
            </div>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const configEnabled = getFieldValue([
                  "appData",
                  "HELP_DESK",
                  "slackNotifications",
                  "isActive",
                ]);
                if (configEnabled) {
                  return (
                    <Form.Item
                      label="Slack Webhook URL"
                      name={[
                        "appData",
                        "HELP_DESK",
                        "slackNotifications",
                        "webhookUrl",
                      ]}
                      className="m-0"
                      help={
                        <a
                          href="https://slack.com/apps/A0F7XDUAZ-incoming-webhooks?tab=more_info"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click Here to install Webhook Slack App
                        </a>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please enter your slack webhook URL",
                        },
                        {
                          type: "url",
                          message: "Please enter a valid webhook URL",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  );
                }
              }}
            </Form.Item>
          </ConfigurationEditorComponent>

          <ConfigurationEditorComponent
            icon={
              <img
                src="/assets/integrations/mailchimp.png"
                style={{ width: 45 }}
                alt=""
                className=""
              />
            }
            title={"Mailchimp"}
            description={"Sync Contacts with your mailing List"}
            entityType={"WORKSPACE"}
          >
            <div className="auto-trigger flex flex-row items-center justify-between mb-4">
              <div className="font-bold">Activate Syncing</div>
              <Form.Item
                name={["appData", "CRM", "mailChimpIntegration", "isActive"]}
                hasFeedback={false}
                valuePropName="checked"
                style={{ marginBottom: 0, marginLeft: 5 }}
              >
                <Switch />
              </Form.Item>
            </div>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const configEnabled = getFieldValue([
                  "appData",
                  "CRM",
                  "mailChimpIntegration",
                  "isActive",
                ]);
                if (configEnabled) {
                  return (
                    <>
                      <Form.Item
                        label="API Key"
                        name={[
                          "appData",
                          "CRM",
                          "mailChimpIntegration",
                          "apiToken",
                        ]}
                        className="m-0"
                        help={
                          <a
                            href="https://mailchimp.com/help/about-api-keys/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            How to find the API Key
                          </a>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please enter your mailchimp API Key",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                          const apiToken = getFieldValue([
                            "appData",
                            "CRM",
                            "mailChimpIntegration",
                            "apiToken",
                          ]);
                          return (
                            <Form.Item
                              label="Mailchimp List"
                              name={[
                                "appData",
                                "CRM",
                                "mailChimpIntegration",
                                "mailChimpListId",
                              ]}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please select your mailchimp audience (List)",
                                },
                              ]}
                            >
                              <MailchimpListSelector apiKey={apiToken} />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                      <Form.Item
                        label="Which Contacts to Sync?"
                        name={[
                          "appData",
                          "CRM",
                          "mailChimpIntegration",
                          "syncTarget",
                        ]}
                        // className="m-0"
                        rules={[
                          {
                            required: true,
                            message: "Please select an option",
                          },
                        ]}
                      >
                        <Select>
                          <Select.Option value={"ALL_CONTACTS"}>
                            All Contacts
                          </Select.Option>
                          <Select.Option value={"GIVEN_CONSENT"}>
                            Contacts that has opted in for to receive marketing
                            materials
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </>
                  );
                }
              }}
            </Form.Item>
          </ConfigurationEditorComponent>

          <ConfigurationEditorComponent
            icon={
              <img
                src="/assets/integrations/shopify.png"
                style={{ width: 45 }}
                alt=""
                className=""
              />
            }
            title={"Shopify"}
            description={
              "Integrate Your Shopify Store with Click Connector for Seamless Customer Support"
            }
            entityType={"WORKSPACE"}
          >
            <ShopifyIntegrationStatus />
          </ConfigurationEditorComponent>

          <div className="flex flex-row justify-between items-center">
            <div className="font-lg font-bold my-8">Zapier Apps</div>
            {zapierTokens.length > 0 && (
              <Button
                type="text"
                icon={<i className="ri-add-line"></i>}
                onClick={() =>
                  changePanelState(AddAPIUser, true, { type: "Zapier App" })
                }
              >
                Create New Zapier Token
              </Button>
            )}
          </div>

          {zapierTokens.map((user) => (
            <APIUserCard user={user} key={user.id} />
          ))}

          <HelpCenterPromptButton
            type="BANNER"
            text={
              <div className="text-lg p-3">Learn how to Connect Zapier 📺</div>
            }
            description=""
            icon="LEARN"
            articleId="fc89e0-04406"
            className="my-4"
          />

          {zapierTokens.length === 0 && (
            <div
              className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-700  flex-col flex p-6 pb-2"
              style={{ height: 150 }}
            >
              <div className="flex flex-row items-center justify-center">
                <div className="icon">
                  <img
                    src="/assets/integrations/zapier.svg"
                    style={{ width: 120 }}
                    alt=""
                  />
                </div>
                {/* <div className="name text-xl font-bold ml-2">Stripe</div> */}
              </div>
              <div className="description text-gray-600 flex-1 text-center">
                Connect to thousands of apps via zapier
              </div>
              <div className="footer">
                <Button
                  type="text"
                  icon={<i className="ri-arrow-right-circle-line"></i>}
                  className="font-bold"
                  block
                  onClick={() =>
                    changePanelState(AddAPIUser, true, { type: "Zapier App" })
                  }
                >
                  Configure New Zapier Integration
                </Button>
              </div>
            </div>
          )}

          <div className="flex flex-row justify-between items-center">
            <div className="font-lg font-bold my-8">API Integrations</div>
            {apiTokens.length > 0 && (
              <Button
                type="text"
                onClick={() =>
                  changePanelState(AddAPIUser, true, { type: "API App" })
                }
                icon={<i className="ri-add-line"></i>}
              >
                Create New API Token
              </Button>
            )}
          </div>

          {apiTokens.map((user) => (
            <APIUserCard user={user} key={user.id} />
          ))}

          {apiTokens.length === 0 && (
            <div
              className="bg-white dark:bg-gray-900 rounded-lg border border-dashed border-gray-200 dark:border-gray-700  flex-col flex p-6 hover:bg-gray-100 cursor-pointer"
              style={{}}
              onClick={() =>
                changePanelState(AddAPIUser, true, { type: "API App" })
              }
            >
              <div className="flex flex-row items-center justify-center">
                <div className="icon">
                  <i className="ri-add-line text-2xl"></i>
                </div>
                <div className="name text-xl font-bold ml-2">
                  Create New API Token
                </div>
              </div>
              <div className="description text-gray-600 flex-1 text-center">
                Bring in data from other platforms for continuity
              </div>
            </div>
          )}

          <div
            className="bg-white dark:bg-gray-900 rounded-lg border border-dashed border-gray-200 dark:border-gray-700  flex-col flex p-6 hover:bg-gray-100 cursor-pointer mt-8"
            style={{}}
            onClick={() => {
              const url =
                "https://documenter.getpostman.com/view/6605246/UyxdLp7d";
              const newWindow = window.open(url);
            }}
          >
            <div className="flex flex-row items-center justify-center">
              <div className="icon">
                <i className="ri-file-text-line text-2xl"></i>
              </div>
              <div className="name text-xl font-bold ml-2">
                View API Documentation
              </div>
            </div>
          </div>

          <ManageConnectedApps />
        </div>
      </SimpleCardSection>
    </StyledScrollArea>
  );
};

export const APIUserCard = ({ user }: { user: iUser }) => {
  const { doAction: copyAccessToken, isProcessing: isActionProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (apiUserId: string) =>
          SDK.getAPIUserSecret(apiUserId).then((d) => {
            return copyToClipboard(d.accessToken);
          }),
        successMessage: "Token has been copied to your clipboard",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const type = useMemo(
    () =>
      user.data.lastName.includes("Zapier") ? "Zapier Token" : "API Token",
    [user.data.lastName],
  );

  return (
    <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-700  flex-row flex items-center p-4 mb-2">
      <div className="flex flex-row items-center">
        <div className="icon">
          {type === "Zapier Token" && (
            <img
              src="/assets/integrations/zapier-logo.png"
              style={{ width: 30 }}
              alt=""
            />
          )}

          {type === "API Token" && (
            <i className="ri-terminal-box-line text-2xl"></i>
          )}
        </div>
      </div>
      <div className="flex flex-col  ml-2 flex-1">
        <div className="name text-lg font-bold">{user.data.firstName}</div>
        <div className="description text-gray-600 flex-1">
          Created on {dayjs(user.metaData.joined).format("DD-MM-YYYY")}
        </div>
      </div>
      <Button
        type="text"
        onClick={() => {
          copyAccessToken(user.id);
        }}
        icon={<i className="ri-clipboard-fill"></i>}
      >
        Copy Secret Token
      </Button>
    </div>
  );
};
