import { Tag } from "antd";
import { AvatarGroup, UserAvatar } from "components/common/avatar/avatar";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { loadContactById } from "store/modules/contacts/contacts.helpers";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectOpportunityById } from "store/modules/opportunities/opportunities.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { AddEllipsis } from "utils/add-ellipsis";
import { useUrlState } from "utils/hooks/use-url-state";
import { convertHtmlToPlainText } from "utils/html-to-plain-text";

export const OpportunityCard = ({
  opportunityId,
  onClick,
}: {
  opportunityId: string;
  onClick?: (opportunityId: string) => any;
}) => {
  const [selectedOpportunity, _setSelectedOpportunity] = useUrlState(
    "opportunity"
  );

  const onSelect = useCallback(
    (opportunityId: string) => {
      if (onClick) {
        onClick(opportunityId);
      } else {
        _setSelectedOpportunity(opportunityId);
      }
    },
    [_setSelectedOpportunity, onClick]
  );

  const opportunity = useSelector(selectOpportunityById(opportunityId));

  const isDueDatePassed = useMemo(() => {
    const currentTime = Date.now();
    if (opportunity?.expectedCloseDate) {
      if (currentTime > opportunity?.expectedCloseDate) {
        return true;
      }
    }
    return false;
  }, [opportunity?.expectedCloseDate]);

  const { state: contact } = useQueryWithStore(
    selectContactById(opportunity?.contactId),
    loadContactById(opportunity?.contactId)(),
    [opportunity?.contactId],
    !opportunity?.contactId
  );

  if (!opportunity) {
    return <div>Loading</div>;
  }
  return (
    <div
      className="p-4 flex flex-row items-center"
      onClick={() => onSelect(opportunityId)}
    >
      <div className="mb-8">
        <ContactAvatar contactId={contact?.id!} size={40} />
      </div>
      <div className="flex flex-col pl-4 flex-1">
        <div className="font-bold text-xs">{contact?.data.firstName}</div>
        <div className="title font-bold">{opportunity?.title}</div>
        <div className="text-gray-600">
          {AddEllipsis(convertHtmlToPlainText(opportunity?.body || " "), 20)}
        </div>
        <div className="footer flex flex-row justify-between items-center">
          <div className="left flex flex-row items-center">
            <div className="owners">
              <AvatarGroup>
                {opportunity.owners.map((member) => (
                  <div className="avatar-container" key={member.userId}>
                    <UserAvatar
                      userId={member.userId}
                      key={member.userId}
                      enableToolTip
                      size={20}
                    />
                  </div>
                ))}
              </AvatarGroup>
            </div>
          </div>
          <div className="left flex flex-row items-center">
            <div className="tags-container">
              {(opportunity.tags || []).map((tag) => (
                <Tag color={"blue"}>{tag}</Tag>
              ))}
            </div>
            <div className="due-date">
              <Tag color={isDueDatePassed ? "red" : "blue"}>
                {dayjs(opportunity.expectedCloseDate).format("DD-MM-YYYY")}
              </Tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
