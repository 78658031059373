import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import { Collapse, Form, Input, Select, Space, Typography } from "antd";

import { BgPatternSelector } from "components/common/bg-pattern-selector/bg-pattern-selector";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { StyledSwitch } from "components/common/styled-swtich";
import {
  ColorPickerFormItem,
  ImageUploaderFormItem,
} from "components/modules/connections/channels/email/components/forms/email-template";
import { GlobalConfig } from "config";
import isUrl from "is-url";
import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.selectors";
import { selectSelfServicePortalById } from "store/modules/self-service-portals/self-service-portals.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useViewRefresher } from "utils/hooks/use-view-refresher";
import { isEmail } from "utils/is-email";
import "../../../modules/connections/channels/chat-widget/components/chat-widget-theme/screenshot-frame.scss";
import { CustomDomainConfigForPortal } from "./custom-domain-config";
import { FrameWithControls } from "./frame";
const { Link, Title } = Typography;
const { Panel } = Collapse;

export const SelfServicePortalPage = ({ portalId }: { portalId: string }) => {
  const selfServicePortal = useSelector(selectSelfServicePortalById(portalId));

  const iframeRef = useRef<any>();

  const { visible } = useViewRefresher(selfServicePortal);

  const { state: widgets } = useQueryWithStore(
    selectAllChatWidgets,
    loadAllChatWidgets(),
    [],
  );

  const portalUrl = useMemo(() => {
    if (GlobalConfig.IS_PROD && selfServicePortal) {
      const domainConfig = selfServicePortal.data.domain;
      const url =
        domainConfig.customDomainConfig?.domain &&
        domainConfig.customDomainConfig?.isVerified &&
        domainConfig.customDomainConfig?.isConfigured
          ? `https://${domainConfig.customDomainConfig?.domain}`
          : `https://${domainConfig.subdomain}.helpcenter.guide`;
      return url;
    }
    return `http://localhost:3000`;
  }, [selfServicePortal]);

  return (
    <>
      <div className="flex" style={{ height: "calc(100vh - 150px)" }}>
        <div className="bg-white dark:bg-gray-900 w-1/4 h-full overflow-auto  mode_transition">
          <div className="p-4">
            <Title className="mb-4" level={3}>
              Configure Portal
            </Title>

            <ConfigurationEditorComponent
              icon={"ri-drop-fill"}
              title={"Theme and Appearance"}
              description={"Configure how your knowledge base would look"}
              entityType={"SELF_SERVICE_PORTAL"}
              entityId={portalId}
            >
              <ColorPickerFormItem
                label="Page Theme Color"
                fieldName={["data", "appearance", "primaryColor"]}
              />
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => {
                  const primaryColor = getFieldValue([
                    "data",
                    "appearance",
                    "primaryColor",
                  ]);
                  return (
                    <Form.Item
                      name={["data", "hero", "pattern"]}
                      label={"Pattern"}
                      rules={[]}
                    >
                      <BgPatternSelector primaryColor={primaryColor} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </ConfigurationEditorComponent>

            <ConfigurationEditorComponent
              icon={"ri-tools-line"}
              title={"SEO"}
              description={"Configure site name, and SEO data"}
              entityType={"SELF_SERVICE_PORTAL"}
              entityId={portalId}
            >
              <Form.Item
                name={["data", "siteName"]}
                label={"Site Name"}
                rules={[
                  { required: true, message: "Please enter a site name" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={["data", "seo", "description"]}
                label={"Meta Description"}
                rules={[]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={["data", "seo", "tags"]}
                label={"Meta Tags"}
                rules={[]}
              >
                <Input />
              </Form.Item>
              <ImageUploaderFormItem
                label="Website Icon (Tab Icon)"
                fieldName={["data", "seo", "favicon"]}
                entityType="SELF_SERVICE_PORTAL"
                entityId={portalId}
              />
              <div className="text-gray-600 mb-8">
                Please Upload 256x256 PNG for best results
              </div>
            </ConfigurationEditorComponent>

            <ConfigurationEditorComponent
              icon={"ri-earth-line"}
              title={"Domain and Website Address"}
              description={"Configure how your knowledge portal is accessed"}
              entityType={"SELF_SERVICE_PORTAL"}
              entityId={portalId}
            >
              <Form.Item
                name={["data", "domain", "subdomain"]}
                label={"Subdomain"}
                rules={[
                  { required: true, message: "Please select a subdomain" },
                ]}
              >
                <Input
                  addonBefore={"https://"}
                  addonAfter={".helpcenter.guide"}
                />
              </Form.Item>

              <CustomDomainConfigForPortal portalId={portalId} />
            </ConfigurationEditorComponent>

            <ConfigurationEditorComponent
              icon={"ri-code-s-slash-line"}
              title={"Custom CSS / Scripts"}
              description={"Setup your custom css and scripts"}
              entityType={"SELF_SERVICE_PORTAL"}
              entityId={portalId}
            >
              <Form.Item
                name={["data", "customHTML", "head"]}
                label={<div className="font-bold">Head Section</div>}
                help="Add Custom CSS / Scripts inside the <head></head> tags"
                rules={[]}
              >
                <Input.TextArea placeholder="Add your customer scripts here" />
              </Form.Item>

              <Form.Item
                name={["data", "customHTML", "body"]}
                help="Add Custom CSS / Scripts inside the <body></body> tags"
                label={<div className="font-bold">Body Section</div>}
                rules={[]}
              >
                <Input.TextArea placeholder="Add your customer scripts here" />
              </Form.Item>
            </ConfigurationEditorComponent>

            <ConfigurationEditorComponent
              icon={"ri-message-2-line"}
              title={"Integrate Live Chat"}
              description={"Setup your your click connector chat widget"}
              entityType={"SELF_SERVICE_PORTAL"}
              entityId={portalId}
              transformFormValues={(item) => {
                if (!item?.data?.liveChatIntegration?.widgetId) {
                  return {
                    data: { liveChatIntegration: { widgetId: null } },
                  };
                }
                return item;
              }}
            >
              <Form.Item
                name={["data", "liveChatIntegration", "widgetId"]}
                label={"Chat Widget"}
                rules={[]}
              >
                <Select allowClear placeholder="Select a chat widget">
                  {widgets.map((widget) => (
                    <Select.Option key={widget.id} value={widget.id!}>
                      {widget.data.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </ConfigurationEditorComponent>

            <ConfigurationEditorComponent
              icon={"ri-layout-top-2-line"}
              title={"Configure Nav Bar"}
              description={"setup and configure navigation bar of your portal"}
              entityType={"SELF_SERVICE_PORTAL"}
              entityId={portalId}
            >
              <StyledSwitch
                label="Enabled"
                name={["data", "navBar", "isEnabled"]}
              />

              <ImageUploaderFormItem
                label="Logo"
                fieldName={["data", "navBar", "logo"]}
                entityType="SELF_SERVICE_PORTAL"
                entityId={portalId}
              />

              <div className="font-bold">Navigation Links</div>
              <Form.List name={["data", "navBar", "links"]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Space
                        key={field.key}
                        style={{ display: "flex" }}
                        align="baseline"
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, "label"]}
                          rules={[
                            {
                              required: true,
                              message: "Enter a label",
                            },
                          ]}
                          hasFeedback={false}
                          style={{ marginBottom: 4 }}
                        >
                          <Input placeholder="Link Label" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "link"]}
                          rules={[
                            {
                              required: true,
                              message: "Enter a URL",
                            },
                            // {
                            //   type: "url",
                            //   message: "Enter a valid URL",
                            // },
                            ({ getFieldValue }) => ({
                              validator: (rule, value) => {
                                return new Promise((resolve, reject) => {
                                  if (isUrl(value)) {
                                    return resolve("");
                                  }
                                  if (value.includes("mailto")) {
                                    const email = value.split(":")[1];
                                    if (email && isEmail(email)) {
                                      return resolve("");
                                    }
                                    return reject("Invalid Email in Mailto");
                                  }
                                  throw reject("Please a valid URL ");
                                });
                              },
                            }),
                          ]}
                          hasFeedback={false}
                          style={{ marginBottom: 4 }}
                        >
                          <Input placeholder="URL" />
                        </Form.Item>

                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    ))}
                    <div onClick={() => add({})} className="cursor-pointer">
                      + add a navigation link
                    </div>
                  </>
                )}
              </Form.List>

              <div className="font-bold mt-4">Action Buttons</div>
              <Form.List name={["data", "navBar", "buttons"]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Space
                        key={field.key}
                        style={{ display: "flex" }}
                        align="baseline"
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, "label"]}
                          rules={[
                            {
                              required: true,
                              message: "Enter a label",
                            },
                          ]}
                          hasFeedback={false}
                          style={{ marginBottom: 4 }}
                        >
                          <Input placeholder="Link Label" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "link"]}
                          rules={[
                            {
                              required: true,
                              message: "Enter a URL",
                            },
                            ({ getFieldValue }) => ({
                              validator: (rule, value) => {
                                return new Promise((resolve, reject) => {
                                  if (isUrl(value)) {
                                    return resolve("");
                                  }
                                  if (value.includes("mailto")) {
                                    const email = value.split(":")[1];
                                    if (email && isEmail(email)) {
                                      return resolve("");
                                    }
                                    return reject("Invalid Email in Mailto");
                                  }
                                  throw reject("Please a valid URL ");
                                });
                              },
                            }),
                            // {
                            //   type: "url",
                            //   message: "Enter a valid URL",
                            // },
                          ]}
                          hasFeedback={false}
                          style={{ marginBottom: 4 }}
                        >
                          <Input placeholder="URL" />
                        </Form.Item>

                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    ))}
                    <div onClick={() => add({})} className="cursor-pointer">
                      + add a button
                    </div>
                  </>
                )}
              </Form.List>
            </ConfigurationEditorComponent>

            <ConfigurationEditorComponent
              icon={"ri-layout-top-line"}
              title={"Configure Header"}
              description={"setup and configure header bar of your portal"}
              entityType={"SELF_SERVICE_PORTAL"}
              entityId={portalId}
            >
              <Form.Item
                name={["data", "hero", "title"]}
                label={"Title"}
                rules={[]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                name={["data", "hero", "introduction"]}
                label={"Introduction"}
                rules={[]}
                initialValue={`Feel free to use the search to find what you are looking for. If you are not able to find what you need, you can always chat with us`}
              >
                <Input.TextArea />
              </Form.Item>
            </ConfigurationEditorComponent>

            <ConfigurationEditorComponent
              icon={"ri-layout-bottom-2-line"}
              title={"Configure Footer"}
              description={"setup and configure footer bar of your portal"}
              entityType={"SELF_SERVICE_PORTAL"}
              entityId={portalId}
            >
              <StyledSwitch
                name={["data", "footer", "hideFooter"]}
                label={"Hide Footer"}
                description="We recommend that you hide the footer if you don't have additional links"
              />

              <Form.Item
                name={["data", "footer", "copyRightText"]}
                label={"Copyrights Text Section"}
                rules={[]}
                className="mt-8"
              >
                <Input.TextArea />
              </Form.Item>

              <ImageUploaderFormItem
                label="Logo"
                fieldName={["data", "footer", "logo"]}
                entityType="SELF_SERVICE_PORTAL"
                entityId={portalId}
              />

              <Form.Item
                name={["data", "footer", "description"]}
                label={"Footer Text"}
                rules={[]}
              >
                <Input.TextArea />
              </Form.Item>

              <div className="font-bold mt-4">Sections</div>
              <Form.List name={["data", "footer", "linkSections"]}>
                {(
                  sectionFields,
                  { add: addSection, remove: removeSection },
                ) => (
                  <>
                    {sectionFields.map((sectionField) => (
                      <div key={sectionField.key} className="mt-8">
                        <Space style={{ display: "flex" }} align="baseline">
                          <Form.Item
                            {...sectionField}
                            name={[sectionField.name, "title"]}
                            rules={[
                              {
                                required: true,
                                message: "Enter a section title",
                              },
                            ]}
                            hasFeedback={false}
                            style={{ marginBottom: 4 }}
                          >
                            <Input placeholder="Section title" />
                          </Form.Item>

                          <MinusCircleOutlined
                            onClick={() => removeSection(sectionField.name)}
                          />
                        </Space>
                        <div className="links ml-4">
                          <div className="font-bold my-4">Links</div>
                          <Form.List name={[sectionField.name, "links"]}>
                            {(
                              linkFields,
                              { add: addLink, remove: removeLink },
                            ) => (
                              <>
                                {linkFields.map((linkField) => (
                                  <Space
                                    key={linkField.key}
                                    style={{ display: "flex" }}
                                    align="baseline"
                                  >
                                    <Form.Item
                                      {...linkField}
                                      name={[linkField.name, "label"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Enter a label",
                                        },
                                      ]}
                                      hasFeedback={false}
                                      style={{ marginBottom: 4 }}
                                    >
                                      <Input placeholder="Link Label" />
                                    </Form.Item>
                                    <Form.Item
                                      {...linkField}
                                      name={[linkField.name, "link"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Enter a URL",
                                        },
                                        ({ getFieldValue }) => ({
                                          validator: (rule, value) => {
                                            return new Promise(
                                              (resolve, reject) => {
                                                if (isUrl(value)) {
                                                  return resolve("");
                                                }
                                                if (value.includes("mailto")) {
                                                  const email =
                                                    value.split(":")[1];
                                                  if (email && isEmail(email)) {
                                                    return resolve("");
                                                  }
                                                  return reject(
                                                    "Invalid Email in Mailto",
                                                  );
                                                }
                                                throw reject(
                                                  "Please a valid URL ",
                                                );
                                              },
                                            );
                                          },
                                        }),
                                        // {
                                        //   type: "url",
                                        //   message: "Enter a valid URL",
                                        // },
                                      ]}
                                      hasFeedback={false}
                                      style={{ marginBottom: 4 }}
                                    >
                                      <Input placeholder="URL" />
                                    </Form.Item>

                                    <MinusCircleOutlined
                                      onClick={() => removeLink(linkField.name)}
                                    />
                                  </Space>
                                ))}
                                <div
                                  onClick={() => addLink({})}
                                  className="cursor-pointer"
                                >
                                  + add a link
                                </div>
                              </>
                            )}
                          </Form.List>
                        </div>
                      </div>
                    ))}
                    <div
                      onClick={() => addSection({})}
                      className="cursor-pointer mt-4"
                    >
                      + add a section
                    </div>
                  </>
                )}
              </Form.List>
            </ConfigurationEditorComponent>

            <ConfigurationEditorComponent
              icon={"ri-settings-2-line"}
              title={"Additional Settings"}
              description={
                "Additional Settings Related to your public KB website"
              }
              entityType={"SELF_SERVICE_PORTAL"}
              entityId={portalId}
            >
              <StyledSwitch
                label="Disable Related Articles"
                name={["data", "disableRelatedArticles"]}
              />
            </ConfigurationEditorComponent>
          </div>
        </div>
        <div className="flex-grow overflow-auto shadow-lg">
          <div className="screenshot-safari border border-gray-200 w-full h-full ">
            {visible && <FrameWithControls url={portalUrl} ref={iframeRef} />}
          </div>
        </div>
      </div>
    </>
  );
};
