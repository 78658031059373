export const roomOptions = [
  {
    value: "All inclusive",
    label: "All inclusive"
  },
  {
    value: "Room Only",
    label: "Room Only"
  },
  {
    value: "Bed & Breakfast",
    label: "Bed & Breakfast"
  },
  {
    value: "Half board",
    label: "Half board"
  },
  {
    value: "Full board",
    label: "Full board"
  }
];
