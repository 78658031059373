import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Input, List, Modal, Space } from "antd";
import { UserAvatar } from "components/common/avatar/avatar";
import { EmptyData } from "components/common/empty-data/empty-data";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { loadAllUsers } from "store/modules/users/users.helpers";
import { selectAllActiveUsersQuery } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSearch } from "utils/hooks/use-search";

interface iUserRecord {
  id: string;
  name: string;
  email: string;
  role: string;
  groups: string[];
}
const UserListItem = ({
  user,
  userGroupId,
}: {
  user: iUserRecord;
  userGroupId: string;
}) => {
  const { doAction: addToGroup, isProcessing: isAdding } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (userId: string, existingGroups: string[]) =>
        SDK.editUser(userId, { userGroups: [...existingGroups, userGroupId] }),
      successMessage: "User has been added to group",
      failureMessage: "Something went wrong",
    }),
    [userGroupId]
  );

  const {
    doAction: removeFromGroup,
    isProcessing: isRemoving,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (userId: string, existingGroups: string[]) =>
        SDK.editUser(userId, {
          userGroups: _.without(existingGroups, userGroupId),
        }),
      successMessage: "User has been removed from group",
      failureMessage: "Something went wrong",
    }),
    [userGroupId]
  );
  const isAlreadyMember = user.groups.includes(userGroupId);
  return (
    <List.Item>
      <List.Item.Meta
        avatar={<UserAvatar userId={user.id} size={40} />}
        title={user.name}
        description={user.role}
      />

      <div className="mr-4">
        {isAlreadyMember && (
          <Button
            type="dashed"
            shape="circle"
            onClick={() => removeFromGroup(user.id, user.groups)}
            loading={isRemoving}
          >
            <i className="ri-subtract-line"></i>
          </Button>
        )}
        {!isAlreadyMember && (
          <Button
            onClick={() => addToGroup(user.id, user.groups)}
            loading={isAdding}
            type="dashed"
            shape="circle"
          >
            <i className="ri-add-line"></i>
          </Button>
        )}
      </div>
    </List.Item>
  );
};

export const ManageUserGroupMembersModal = ({
  visible,
  onChangeVisibility,
  userGroupId,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  userGroupId: string;
}) => {
  const {
    state: { list: _users, isLoading },
    retry: reload,
  } = useQueryWithStore(selectAllActiveUsersQuery, loadAllUsers());

  const users = useMemo(
    () =>
      _users
        // .filter((d) => d.metaData.isActive)
        .map((user) => ({
          id: user.id,
          name: `${user.data.firstName} ${user.data.lastName}`,
          email: user.credentials.email,
          role: getRoleFromPermission(user.permissions),
          groups: user?.userGroups,
        })),
    [_users]
  );

  const { filteredData, searchTerm, setSearchTerm } = useSearch(users);

  const { members, nonMembers } = useMemo(() => {
    return {
      members: filteredData.filter((user) => user.groups.includes(userGroupId)),
      nonMembers: filteredData.filter(
        (user) => !user.groups.includes(userGroupId)
      ),
    };
  }, [filteredData, userGroupId]);

  const { doAction: editMembers, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (userIds: string[]) =>
        SDK.updateMembers(userGroupId, userIds),
      successMessage: "Members have been updated",
      failureMessage: "Something went wrong",
    }),
    [userGroupId]
  );

  const searchInputRef = useRef<any>();
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        searchInputRef?.current?.focus();
      }, 200);
    }
  }, [visible]);

  return (
    <>
      <Modal
        title={
          <div className="font-bold text-xl">
            <Space>
              <i className="ri-user-settings-line"></i> Manager User Group
              Members
            </Space>
          </div>
        }
        open={visible}
        onCancel={() => {
          onChangeVisibility(false);
        }}
        footer={null}
        width={400}
        data-click-context="Manage Members Modal"
        destroyOnClose
      >
        <div className="search-box">
          <Input.Search
            placeholder="Search..."
            size="large"
            className="rounded-md"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            ref={searchInputRef}
          />
        </div>
        <div
          className="user-list mt-4"
          style={{ height: 400, overflow: "auto" }}
        >
          <Divider orientation="left" type="horizontal">
            Members
          </Divider>
          <List
            itemLayout="horizontal"
            dataSource={members}
            locale={{
              emptyText: <EmptyData text="No Records" icon="ri-group-line" />,
            }}
            renderItem={(user) => (
              <UserListItem
                user={user}
                userGroupId={userGroupId}
                key={user.id}
              />
            )}
          />
          <Divider orientation="left" type="horizontal">
            Non Members
          </Divider>
          <List
            itemLayout="horizontal"
            dataSource={nonMembers}
            locale={{
              emptyText: <EmptyData text="No Records" icon="ri-group-line" />,
            }}
            renderItem={(user) => (
              <UserListItem
                user={user}
                userGroupId={userGroupId}
                key={user.id}
              />
            )}
          />
        </div>
        <DarkModeBg />
      </Modal>
    </>
  );
};
