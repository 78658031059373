import { SDK } from "@sdk";
import { iCompany } from "@sdk/crm/crm.models";
import _ from "lodash";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { companiesDefaultQueryConfig } from "./companies-default-query";
import { selectCompaniesMap } from "./companies.selectors";
import { CompaniesSlice, setCompanies } from "./companies.slice";

export const loadAllCompanies = (store: Store<iStore>, forceReload?: boolean) =>
  loadCompaniesQuery(companiesDefaultQueryConfig, "all")(store, forceReload);

export const {
  loadEntityQueries: loadCompaniesQuery,
  loadEntityById: loadCompanyById,
  loadEntitiesByIds: bulkLoadCompaniesByIds
} = CreateHelpersForEntity<iStore, iCompany>({
  sliceName: "companies",
  slice: CompaniesSlice,
  isPaginatedQueries: true,
  loadEntityQueryProvider: query => SDK.companies.query(query),
  loadEntityByIdProvider: (entityId: string) => SDK.companies.getById(entityId),
  loadEntityByIdsProvider: (companyIds: string[]) =>
    (async () => {
      const companies = await Promise.all(
        companyIds.map(companyId => SDK.companies.getById(companyId))
      );
      return companies;
    })()
});

export const refreshAllCompanies = (store: Store<iStore>) => async (
  companyIds?: string[]
) => {
  const loadedCompanies =
    companyIds || Object.keys(selectCompaniesMap(store.getState()));
  const chunksToLoad = _.chunk(loadedCompanies, 200);
  for (const chunk of chunksToLoad) {
    const data = await SDK.companies.query({
      query: { id: { $in: chunk } },
      options: {
        limit: 201
      }
    });
    store.dispatch(setCompanies(data.docs));
  }
  return {
    status: true
  };
};
