import { SimpleCollapse } from "components/common/simple-collapse/simple-collapse";
import { AppSumoTiers } from "components/modules/organization-management/general/billing/appsumo-subscription-card";
import { push } from "connected-react-router";
import { useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { selectSubscriptionDetails } from "store/modules/workspace/workspace.selectors";

export const AppSumoUserGreetings = () => {
  const subscriptionDetails = useSelector(selectSubscriptionDetails);

  const activePlan = useMemo(() => {
    const appSumoSubscription = subscriptionDetails?.appSumoBilling;
    const activatedPlanId = subscriptionDetails?.appSumoBilling?.plan_id;
    return AppSumoTiers.find((item) => item.planId === activatedPlanId);
  }, [subscriptionDetails?.appSumoBilling]);

  const store = useStore();
  return (
    <div className="app-sumo-welcome card w-full p-4">
      <div className="flex flex-row justify-between items-center">
        <div className="flex-1">
          <div className="flex flex-row items-start justify-start">
            <div className="flex flex-col flex-1">
              <div className="text-2xl font-bold">Hey Sumo-Lings! 👋</div>
              <div className="text-gray-600">
                We are absolutely thrilled to have you on board with
                ClickConnector! I'm Ruban, the creator of ClickConnector and
                also serve as the Chief Customer Success Officer. Throughout
                your ClickConnector journey, I'll be here to provide you with
                any assistance you may need.
              </div>
              <div className="flex flex-row justify-center items-center my-4">
                <img
                  src="/assets/app-sumo/app-sumo.svg"
                  alt="AppSumo"
                  style={{ width: 160 }}
                />
              </div>
              <div className="text-gray-600 ">
                It's great to see that you're utilizing an AppSumo License and
                as an Insider, you will gain access to our exclusive beta
                features and upcoming enhancements
              </div>
              {activePlan && (
                <SimpleCollapse title="Your Plan">
                  <div className="ml-8 text-gray-600">
                    Your AppSumo Plan:
                    <strong>{activePlan.title}</strong>
                    <br />
                    <a
                      onClick={() =>
                        store.dispatch(push("/configurations/billing"))
                      }
                    >
                      Click Here to check your license details and billing.
                    </a>
                  </div>
                </SimpleCollapse>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
