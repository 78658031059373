import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Tooltip,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import copyToClipboard from "copy-to-clipboard";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect, useMemo, useState } from "react";

export const OpenAiAssistantModal = ({
  visible,
  onChangeVisibility,
  input,
  onReplaceText,
  instruction,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  input?: string;
  onReplaceText?: (content: string) => any;
  instruction?: string;
}) => {
  const [form] = useForm();

  const {
    doAction: _process,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (req) => SDK.processOpenAiPrompts(req),
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const [responseContent, setResponseContent] = useState(input);

  useEffect(() => {
    setResponseContent(response?.choices?.[0]?.message?.content || "");
    // setResponseContent(response?.choices?.[0]?.text || ""); // For EDIT and COMPLETE
  }, [response]);

  const process = useCallback(async () => {
    try {
      await form.validateFields();
      const { input, instruction } = form.getFieldsValue();
      if (input && instruction) {
        _process({
          type: "CHAT",
          instruction,
          input,
        });
      }
    } catch (e) {
      message.error("Please check your input");
    }
  }, [_process, form]);

  useEffect(() => {
    if (input) {
      process();
    }
  }, [input, process]);

  const initialValue = useMemo(() => {
    return { input, instruction: instruction || "IMPROVE_SENTENCE" };
  }, [input, instruction]);

  return (
    <Modal
      title={
        <ModalTitle
          title="AI Writing Assistant"
          icon={
            <img
              src="/assets/integrations/openai.png"
              alt="Chat GPT"
              style={{ width: "1rem" }}
              className="dark:invert"
            />
          }
        />
      }
      open={visible}
      okButtonProps={{
        loading: isProcessing,
        icon: <i className="ri-save-line"></i>,
        className: "font-bold",
      }}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={500}
      data-click-context="Open AI Assistant  Modal"
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValue}
        className="bold-form-labels"
        requiredMark={false}
      >
        <Form.Item
          name="instruction"
          label={"Instruction"}
          rules={[
            {
              required: true,
              message: "Please select an instruction",
            },
          ]}
        >
          <Select>
            <Select.Option value={"IMPROVE_SENTENCE"}>
              Improve Sentences
            </Select.Option>
            <Select.Option value={"SUMMARIZE_SENTENCE"}>
              Summarize Sentences
            </Select.Option>
            <Select.Option value={"PARAPHRASE_SENTENCE"}>
              Paraphrase Sentences
            </Select.Option>
            <Select.Option value={"EXPAND_SENTENCE"}>
              Expand Sentences
            </Select.Option>
            <Select.Option value={"HELPFUL_ASSISTANT"}>
              Ask a question
            </Select.Option>
          </Select>
          {/* <Input.TextArea placeholder="Your Input Text" /> */}
        </Form.Item>
        <Form.Item
          name="input"
          label={"Input"}
          rules={[
            {
              required: true,
              message: "Please write an input",
            },
          ]}
        >
          <Input.TextArea placeholder="Your Input Text" />
        </Form.Item>

        <Button
          type="primary"
          icon={<i className="ri-magic-line"></i>}
          loading={isProcessing}
          onClick={process}
          size={"large"}
          block
          className="font-bold"
        >
          Let's Go
        </Button>
      </Form>
      {responseContent && (
        <>
          <Divider className="font-semibold text-gray-600">Response</Divider>

          <Input.TextArea
            value={responseContent}
            onChange={(e) => setResponseContent(e.target.value)}
            placeholder="Your Input Text"
            rows={10}
          />

          <div className="my-4 flex flex-row justify-end items-center">
            <Space>
              <Tooltip title="Copy AI response to Clipboard">
                <Button
                  onClick={(e) => {
                    copyToClipboard(responseContent);
                    e.stopPropagation();
                  }}
                  type="link"
                  icon={<i className="ri-clipboard-line"></i>}
                ></Button>
              </Tooltip>

              {onReplaceText && (
                <Button
                  type="text"
                  icon={<i className="ri-check-line"></i>}
                  loading={isProcessing}
                  onClick={() => {
                    onReplaceText(responseContent);
                    onChangeVisibility(false);
                  }}
                >
                  Add to Chat Input
                </Button>
              )}
            </Space>
          </div>
        </>
      )}
      <DarkModeBg />
    </Modal>
  );
};
