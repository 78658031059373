import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import { defaultWidgetPreviewData } from "../default-widget-data";
import { ChatWidgetAvatarGroup } from "./chat-widget-avatar-group";

export const ChatWidgetHomeHeader = ({
  data,
}: {
  data: typeof defaultWidgetPreviewData.HOME_HEADER;
}) => {
  return (
    <div className="primary-gradient-bg-2">
      <div
        className="page-overflow-container text-white top-0 z-0 p-6 pt-2 rounded-lg h-full w-full"
        style={{
          backgroundImage: data.HEADER_PATTERN || undefined,
        }}
      >
        <div className="top-menu flex flex-row justify-end items-center">
          <EllipsisOutlined className="text-3xl cursor-pointer mt-3 h-8 w-12 flex items-center justify-center rounded-full hover:bg-black hover:bg-opacity-25 outline-none" />
        </div>

        <div className="flex flex-col text-white">
          <div className="greeting-message-container text-2xl">
            {data.WELCOME_TEXT}
          </div>
          <div className="guide-message-container">{data.HEADER_TAGLINE}</div>
          <ChatWidgetAvatarGroup data={data} />
        </div>
      </div>
    </div>
  );
};
