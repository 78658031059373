import { iPipelineCard } from "@sdk/pipeline-cards/pipeline-cards-model";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { DatePicker, Form, Input, message, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { MultiUserSelector } from "components/modules/user-management/users/components/multi-user-selector/multi-user-selector";
import { DarkModeBg } from "dark-mode-bg";
import { memo, useCallback, useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import {
  selectPipelineById,
  selectPipelines,
} from "store/modules/workspace/workspace.selectors";
import { PipelineStageSelector } from "../../../modules/crm/components/pipeline-selector/pipeline-stage-selector";
import { SingleContactSelectorLegacy } from "../../../modules/crm/contacts/components/single-contact-selector/single-contact-selector-legacy";

export const CreatePipelineCard = memo(
  ({
    visible,
    onChangeVisibility,
    onCreated,
    initialData: _initialData,
  }: {
    visible: boolean;
    onChangeVisibility: (state: boolean) => any;
    onCreated?: () => any;
    initialData?: Partial<iPipelineCard>;
  }) => {
    const [form] = useForm();
    const pipelines = useSelector(selectPipelines);

    // Create Call
    const { doAction: createPipelineCard, isProcessing: isActionProcessing } =
      useSDKActionWithDeps(
        () => ({
          action: (SDK) => (values) =>
            SDK.pipelineCards
              .create(values)
              .then((d) => onChangeVisibility(false)),
          successMessage: "Done",
          failureMessage: "Something went wrong",
        }),
        [onChangeVisibility],
      );

    const savePipelineCard = useCallback(async () => {
      try {
        await form.validateFields();
      } catch (e) {
        message.warning("Please check your input");
        return;
      }

      const { contact, dueDate, assignedTo, ...formValues } =
        form.getFieldsValue();

      createPipelineCard({
        ..._initialData,
        ...formValues,
        references: {
          contactId: contact?.id,
        },
        dueDate: dueDate?.valueOf(),
        assignedTo:
          (assignedTo || []).map(
            (item) =>
              ({
                userId: item,
              }) as any,
          ) || [],
      } as iPipelineCard);
    }, [form, createPipelineCard, _initialData]);

    const store = useStore();
    const initialData = useMemo(() => {
      const data = {
        ..._initialData,
        owner: [selectCurrentUserId(store.getState())],
      };
      const contactId = _initialData?.references?.contactId;
      if (contactId) {
        (data as any).contact = selectContactById(contactId)(store.getState());
      }
      return data;
    }, [_initialData, store]);

    return (
      <>
        <Modal
          title={
            <ModalTitle
              title="New Item"
              icon={<i className="ri-add-circle-line"></i>}
            />
          }
          open={visible}
          onOk={async () => {
            savePipelineCard();
          }}
          okButtonProps={{
            loading: isActionProcessing,
            className: "font-bold",
          }}
          onCancel={() => {
            onChangeVisibility(false);
          }}
          okText="Add"
          data-click-context="Create Pipeline Card Modal"
          destroyOnClose={true}
        >
          <Form
            layout="vertical"
            form={form}
            initialValues={initialData}
            className="bold-form-labels"
          >
            {/* Title */}
            <Form.Item
              label="Label"
              name="label"
              rules={[
                {
                  required: true,
                  message: "Please enter a label",
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>

            <Form.Item
              label="Pipeline"
              name="pipelineId"
              rules={[
                {
                  required: true,
                  message: "Please select a pipeline",
                },
              ]}
            >
              <Select>
                {pipelines.map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const pipelineId = getFieldValue(["pipelineId"]);
                const pipeline = selectPipelineById(pipelineId)(
                  store.getState(),
                );
                if (pipeline) {
                  return (
                    <>
                      {/* Stage */}
                      <div className="">
                        <Form.Item
                          name="pipelineStageId"
                          label="Pipeline Stage"
                          rules={[
                            {
                              required: true,
                              message: "Please select a pipeline stage",
                            },
                          ]}
                        >
                          <PipelineStageSelector
                            pipeline={{
                              stages: [
                                pipeline.startingStageLabel,
                                ...pipeline.stages.map((item) => item.label),
                              ],
                              successStage: pipeline.successfulExitStageLabel,
                              failureStage:
                                pipeline.failureExistStageLabel || "Discarded",
                            }}
                          />
                        </Form.Item>
                      </div>
                      {/* Value */}
                      <Form.Item label="Value" name="value" rules={[]}>
                        <Input placeholder="" type="number" />
                      </Form.Item>
                      {/* Due Date */}
                      <Form.Item
                        name="dueDate"
                        label="Item Due Date"
                        rules={
                          [
                            // {
                            //   type: "object",
                            //   required: true,
                            //   message: "Please select time!",
                            // },
                          ]
                        }
                      >
                        <DatePicker
                          format="YYYY-MM-DD"
                          suffixIcon={
                            <i className="ri-calendar-event-line"></i>
                          }
                          className="w-full"
                        />
                      </Form.Item>
                    </>
                  );
                }
                return <></>;
              }}
            </Form.Item>

            {/* Owner */}
            <Form.Item name="assignedTo" label={"Assign Item to"} rules={[]}>
              <MultiUserSelector simple={true} size="middle" />
            </Form.Item>

            {/* Linked Contact */}
            <Form.Item
              name="contact"
              label={"Associated Contact"}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please select a contact",
                },
              ]}
            >
              <SingleContactSelectorLegacy />
            </Form.Item>
          </Form>
          <DarkModeBg />
        </Modal>
      </>
    );
  },
);
