import { iFormGroupItem } from "@sdk/dynamic-forms/dynamic-forms-model";
import { Form, Input, message, Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useEffect } from "react";
import { uuidv4 } from "utils/generate-uuid";

export const FieldGroupEditorModal = ({
  visible,
  initialValue,
  onSave,
  onCancel,
  mode,
}: {
  visible: boolean;
  initialValue?: iFormGroupItem;
  onSave: (formGroup: iFormGroupItem) => any;
  onCancel: () => any;
  mode: "ADD" | "EDIT";
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, visible]);

  return (
    <Modal
      open={visible}
      title={
        <ModalTitle
          title={
            mode === "ADD" ? "Add Questions Block" : "Edit Questions Block"
          }
          icon={<i className="ri-pencil-line"></i>}
        />
      }
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onSave({
              id: initialValue?.id! || uuidv4(),
              type: "GROUP",
              title: values.title,
              description: values.description,
              fields: initialValue?.fields || [],
            });
          })
          .catch((info) => {
            message.warning("Please check your input");
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{
        icon: <i className="ri-check-line"></i>,
        className: "font-bold",
      }}
    >
      <Form form={form} layout="vertical" initialValues={initialValue}>
        <Form.Item
          name="title"
          label={<div className="font-bold">Title</div>}
          rules={[
            {
              required: true,
              message: "Please input a title for the group",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label={<div className="font-bold">Additional Text</div>}
          rules={[]}
        >
          <Input />
        </Form.Item>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
