import { escapeRegExp } from "utils/escape-regex-search-string";

export function buildNameSearchQuery(words: string[]) {
  const fullTerm = words.join(",").replace(/,/g, " ");
  return [
    {
      "data.name": {
        $regex: `${escapeRegExp(fullTerm)}`,
        $options: "i"
      }
    }
  ];
}
