import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import MenuOutlined from "@ant-design/icons/MenuOutlined";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iCustomInbox } from "@sdk/user-management/user-management.models";
import { Button, Modal, Table } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  arrayMove,
  SortableContainer as sortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { AddEllipsis } from "utils/add-ellipsis";
import "./manage-custom-views.scss";

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export const MangeCustomInboxViews = ({
  visible,
  onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const currentUser = useSelector(selectCurrentUser);

  const { doAction: editCustomInboxes, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (customInboxes: iCustomInbox[]) =>
        SDK.editCurrentUser({
          data: {
            customInboxes,
          },
        }).then((d) => {
          onChangeVisibility(false);
        }),
      failureMessage: "Something went wrong",
    }),
    [onChangeVisibility]
  );

  const tableData = useMemo(
    () =>
      (currentUser?.data.customInboxes || []).map((e, index) => ({
        key: index,
        label: e.label,
        query: e.query,
      })),
    [currentUser?.data.customInboxes]
  );

  const [state, setState] = useState(tableData);

  useEffect(() => {
    setState(tableData);
  }, [tableData]);

  const saveChanges = () => {
    editCustomInboxes(
      state.map((e) => ({
        label: e.label,
        query: e.query,
      }))
    );
  };

  const columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Inboxes",
      dataIndex: "label",
      className: "drag-visible preset-text",
      render: (text) => AddEllipsis(text, 200),
    },
    {
      title: "Remove",
      width: 40,
      dataIndex: "remove",
      render: (data, record, index) => (
        <Button
          icon={<DeleteOutlined />}
          type="link"
          onClick={() => {
            setState(_.without(state, record));
          }}
        />
      ),
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const dataSource = state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        ([] as any).concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el) as any[];
      setState(newData);
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.key === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  return (<>
    <Modal
      title={
        <ModalTitle
          title="Manage Custom Inboxes"
          icon={<i className="ri-inbox-line"></i>}
        />
      }
      open={visible}
      onOk={() => {
        saveChanges();
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save"
      okButtonProps={{ loading: isProcessing }}
      width={600}
      data-click-context="Manage Custom Inboxes Modal"
    >
      <div className="container flex flex-row manage-custom-views-container">
        <Table
          pagination={false}
          dataSource={state}
          columns={columns}
          rowKey="key"
          showHeader={false}
          className="w-full"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
          locale={{
            emptyText: <EmptyData text="No Views" icon="ri-inbox-line" />,
          }}
        />
      </div>
      <DarkModeBg />
    </Modal>
  </>);
};
