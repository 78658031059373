import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { SDK } from "@sdk";
import { iHolidayQuote } from "@sdk/holiday-quotes/holiday-quotes-model";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Alert,
  Button,
  Dropdown,
  Image,
  Modal,
  Space,
  Tag,
  message
} from "antd";
import { useForm } from "antd/lib/form/Form";
import classnames from "classnames";
import { ModalTitle } from "components/common/modal-title";
import { SimpleDNDList } from "components/common/simple-dnd-list/dnd-list";
import copyToClipboard from "copy-to-clipboard";
import { DarkModeBg } from "dark-mode-bg";
import {
  QuoteBlockEditor,
  QuoteHeaderEditor,
  UnsplashImageSelector
} from "modal-registry";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useSelector } from "react-redux";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { loadContactById } from "store/modules/contacts/contacts.helpers";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { loadHolidayQuoteById } from "store/modules/holiday-quotes/holiday-quotes.helpers";
import { selectHolidayQuoteById } from "store/modules/holiday-quotes/holiday-quotes.selectors";
import {
  selectIsAdvancedMode,
  selectIsDarkMode
} from "store/modules/ui-state/ui-state.selectors";
import {
  selectCurrentUser,
  selectUser
} from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { uuidv4 } from "utils/generate-uuid";
import { useStateWithGetter } from "utils/hooks/use-state-with-getter";
import JSONModal from "utils/json-modal/json-modal";
import {
  FlightItineraryBlock_DefaultStyle,
  FlightItineraryBlock_GoogleStyle,
  FlightItineraryBlock_TableStyle
} from "../pnr-converter/pnr-converter";
import { AdditionalDetailsBlock } from "./components/additonal-details-block";
import { ConfirmAndPayBlock } from "./components/confirm-and-pay-block";
import { ContactAgentBlock } from "./components/contact-agent-block";
import { DestinationHighlightBlock } from "./components/destination-highlight-block";
import { FeedbackAndRatingsBlock } from "./components/feedback-and-rating-block";
import { FinePrintBlock } from "./components/fine-print-block";
import { HotelDetailsBlock } from "./components/hotel-detaiils-block";
import { MapImageBlock } from "./components/map-image-block";
import { PassengerDetailsBlock } from "./components/passenger-details-block";
import { PriceSummaryBlock } from "./components/price-summary-block";
import { ShareBlock } from "./components/share-itinerary-block";
import { WhatsIncludedBlock } from "./components/whats-included-block";
import {
  ItineraryBlockTypeList,
  ItineraryBlockTypeMap,
  iItineraryBlock,
  iItineraryBlockType
} from "./itineries-model";
import "./quote-editor-modal.scss";

export const QuoteEditorModal = ({
  visible,
  onChangeVisibility,
  onAddAsAttachment,
  holidayQuoteId
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onAddAsAttachment?: (content: string) => any;
  holidayQuoteId: string;
}) => {
  const [form] = useForm();
  const currentUser = useSelector(selectCurrentUser);

  const { state: holidayQuote, retry: reload, isLoading } = useQueryWithStore(
    selectHolidayQuoteById(holidayQuoteId),
    loadHolidayQuoteById(holidayQuoteId)(),
    [holidayQuoteId],
    !holidayQuoteId
  );

  const {
    doAction: editHolidayQuote,
    isProcessing,
    response,
    dispatch
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => edits =>
        SDK.holidayQuotes.editById(holidayQuoteId!, edits),
      failureMessage: "Something went wrong"
    }),
    [holidayQuoteId]
  );

  const {
    doAction: emailHolidayQuote,
    isProcessing: isEmailing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (connectionId: string) =>
        SDK.holidayQuotes.sendQuote(holidayQuoteId!, connectionId),
      failureMessage: "Something went wrong"
    }),
    [holidayQuoteId]
  );

  const {
    doAction: generatePDF,
    isProcessing: isGeneratingPDF
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => () =>
        SDK.holidayQuotes.generatePDF(holidayQuoteId!).then(d => {
          window.open(d.file.url, "cc-quote");
          return d;
        }),
      failureMessage: "Something went wrong"
    }),
    [holidayQuoteId]
  );

  const {
    doAction: generateImage,
    isProcessing: isGeneratingImage
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => () =>
        SDK.holidayQuotes.generateImage(holidayQuoteId!).then(d => {
          window.open(d.file.url, "cc-quote");
          return d;
        }),
      failureMessage: "Something went wrong"
    }),
    [holidayQuoteId]
  );

  const allConnections = useSelector(selectAllConnections);

  const renderedPNRRef = useRef<any>();

  const [state, setState, getState] = useStateWithGetter<iItineraryBlock[]>(
    holidayQuote?.data?.blocks || []
  );

  const saveCurrentState = useCallback(() => {
    setTimeout(() => {
      editHolidayQuote({
        data: {
          blocks: [...getState()]
        }
      });
    }, 200);
  }, [editHolidayQuote, getState]);

  const setStateAndSave = useCallback(
    state => {
      setState(state);
      saveCurrentState();
    },
    [saveCurrentState, setState]
  );

  const { triggerTempModal } = useModalPanels();

  useEffect(() => {
    if (holidayQuote?.data?.blocks) {
      setState(holidayQuote?.data?.blocks || []);
    }
  }, [holidayQuote?.data?.blocks, setState]);

  const onEditBlock = useCallback(
    (blockId: string) => {
      const blockToEdit = getState().find(block => block.id === blockId);
      if (blockToEdit) {
        triggerTempModal(QuoteBlockEditor, {
          data: blockToEdit,
          onSave: async data => {
            console.log("Edit Modal Data", data);
            const newBlocks = getState().map(block => {
              if (block.id === data.id) {
                return data;
              }
              return block;
            });
            editHolidayQuote({
              data: {
                blocks: newBlocks
              }
            });
          },
          holidayQuote
        });
      }
    },
    [editHolidayQuote, getState, holidayQuote, triggerTempModal]
  );

  const onAddItineraryType = useCallback(
    (type: iItineraryBlockType) => {
      const newBlock = {
        id: uuidv4(),
        type,
        data: {}
      } as any;
      setState(state => [...state, newBlock]);
      saveCurrentState();
      if (
        !["SHARE_ITINERARY_BLOCK", "AGENT_CONTACT", "FEEDBACK_RATING"].includes(
          type
        )
      ) {
        setTimeout(() => {
          onEditBlock(newBlock.id);
        }, 200);
      }
    },
    [onEditBlock, saveCurrentState, setState]
  );

  const onDeleteBlock = useCallback(
    (blockId: string) => {
      setState(items => items.filter(block => block.id !== blockId));
      saveCurrentState();
    },
    [saveCurrentState, setState]
  );

  const renderItem = useCallback(
    (item: iItineraryBlock) => {
      return itineraryRender(
        holidayQuoteId,
        holidayQuote,
        item,
        onDeleteBlock,
        onEditBlock,
        holidayQuote.status === "FINALIZED"
      );
    },
    [holidayQuote, holidayQuoteId, onDeleteBlock, onEditBlock]
  );

  const editCoverMenu = useMemo(() => {
    return {
      items: [
        {
          key: "UPLOAD",
          label: "Upload Image",
          icon: <i className="ri-upload-2-line"></i>,
          onClick: () => {
            document.getElementById(`holiday_quote_cover_file`)?.click();
          }
        },
        {
          key: "SELECT",
          label: "Select an Image",
          icon: <i className="ri-gallery-line"></i>,
          onClick: () => {
            triggerTempModal(UnsplashImageSelector, {
              initialQuery: holidayQuote.label,
              onPicked: url => {
                editHolidayQuote({
                  data: {
                    cover: url
                  }
                });
              }
            });
          }
        }
      ]
    };
  }, [editHolidayQuote, holidayQuote?.label, triggerTempModal]);

  const [isUploading, setUploading] = useState(false);

  const onUpload = useCallback(
    async (acceptedFiles: File[]) => {
      // console.log("acceptedFiles", acceptedFiles);
      setUploading(true);
      try {
        const firstFile = acceptedFiles[0];
        const fileRecord = await SDK.uploadFile(firstFile, {
          type: "HOLIDAY_QUOTE",
          entityId: holidayQuote?.id!
        });
        console.log("fileRecord", fileRecord);
        editHolidayQuote({
          data: {
            cover: fileRecord.url
          }
        });
      } catch (e) {
        setUploading(false);
        // No need to throw the error from here as the error is handled fully
        // throw e;
      }
    },
    [editHolidayQuote, holidayQuote?.id]
  );

  const createdByUser = useSelector(selectUser(holidayQuote?.createdBy));

  const { state: contact } = useQueryWithStore(
    selectContactById(holidayQuote?.contactId!),
    loadContactById(holidayQuote?.contactId!)(),
    [holidayQuote?.contactId],
    !holidayQuote?.contactId
  );

  const organization = useSelector(selectOrganization);

  const sendQuoteMenu = useMemo(() => {
    const emailConnections = allConnections.filter(
      item => item.type === "EMAIL"
    );

    return {
      items: [
        {
          key: "COPY",
          label: "Copy Sharable Link",
          icon: <i className="ri-clipboard-line"></i>,
          onClick: () => {
            copyToClipboard(
              `https://${organization?.domain}.travelquote.cloud/quotes/${holidayQuote?.id}`
            );
          }
        },
        ...emailConnections.map(item => ({
          key: `EMAIL_${item.id}`,
          label: `Email Customer (${item.label})`,
          icon: <i className="ri-mail-line"></i>,
          onClick: () => {
            emailHolidayQuote(item.id);
          }
        })),
        ...(emailConnections.length === 0
          ? [
              {
                key: `No Email`,
                label: `Email Customer`,
                icon: <i className="ri-mail-line"></i>,
                onClick: () => {
                  message.warning(
                    "You have not configured any email connections"
                  );
                }
              }
            ]
          : []),
        {
          key: "GENERATE_PDF",
          label: "Open as PDF",
          icon: <i className="ri-file-pdf-line"></i>,
          onClick: () => {
            if (holidayQuote.status === "FINALIZED") {
              if (holidayQuote.data.quotePDFUrl) {
                window.open(holidayQuote.data.quotePDFUrl, "cc-quote");
              } else {
                generatePDF();
              }
            } else {
              message.warning(
                "Please finalize the quote before generating PDF"
              );
            }
          }
        },
        {
          key: "GENERATE_IMAGE",
          label: "Open as Image",
          icon: <i className="ri-gallery-line"></i>,
          onClick: () => {
            if (holidayQuote.status === "FINALIZED") {
              if (holidayQuote.data.quotePDFUrl) {
                window.open(holidayQuote.data.quoteImageUrl, "cc-quote");
              } else {
                generateImage();
              }
            } else {
              message.warning(
                "Please finalize the quote before generating Image"
              );
            }
          }
        }
      ]
    };
  }, [
    allConnections,
    emailHolidayQuote,
    generateImage,
    generatePDF,
    holidayQuote?.data?.quoteImageUrl,
    holidayQuote?.data?.quotePDFUrl,
    holidayQuote?.id,
    holidayQuote?.status,
    organization?.domain
  ]);

  const isDarkMode = useSelector(selectIsDarkMode);
  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  if (!holidayQuote || !holidayQuote?.data) {
    return <></>;
  }

  return (
    <Modal
      title={
        <div className="flex flex-row justify-between items-center">
          <ModalTitle
            title="Preview Quote"
            icon={<i className="ri-bill-line"></i>}
          />
          <div className="flex flex-row justify-center items-center mr-8">
            <Space>
              {isAdvancedMode && (
                <i
                  className="ri-code-box-line cursor-pointer"
                  onClick={() => {
                    JSONModal(holidayQuote);
                  }}
                />
              )}
              {holidayQuote.status !== "FINALIZED" && (
                <Button
                  type="primary"
                  className="font-bold"
                  icon={<i className="ri-check-double-line"></i>}
                  loading={isProcessing}
                  onClick={() =>
                    editHolidayQuote({
                      status: "FINALIZED"
                    })
                  }
                >
                  Finalize Quote
                </Button>
              )}
              {holidayQuote.status === "FINALIZED" && <Tag>Finalized</Tag>}

              <Dropdown menu={sendQuoteMenu} trigger={["click"]} arrow>
                <Button
                  type="primary"
                  className="font-bold"
                  loading={isEmailing || isGeneratingImage || isGeneratingPDF}
                >
                  Send Quote <i className="ri-arrow-drop-down-line"></i>
                </Button>
              </Dropdown>
            </Space>
          </div>
        </div>
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      // width={900}
      className="very-big-modal"
      data-click-context="Quote Editor  Modal"
      bodyStyle={{ padding: 0 }}
    >
      <div className="flex flex-col quote-editor-modal">
        <div className="flex flex-col quote-editor-modal">
          {/* https://rest.apitemplate.io/5f177b238cde55ce@ThxrIpr5/image.png?text_from_now.text=this%20is%20a%20text3&image_background.src=https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDEzNzF8MHwxfHNlYXJjaHw2fHxQYW5hbWElMjBDaXR5JTIwLSUyMFBhcmFkaXNlJTIwYXQlMjBQYW5hbWF8ZW58MHwwfHx8MTY4NDg0MzM5N3ww&ixlib=rb-4.0.3&q=80&w=1080 */}
          <div
            className="relative overflow-hidden bg-no-repeat bg-cover flex flex-col justify-end items-end"
            style={{
              backgroundPosition: "50%",
              backgroundImage: `url(${holidayQuote.data.cover}`,
              height: 300
            }}
          >
            {holidayQuote.status === "DRAFT" && (
              <div className="editable-level p-4">
                <input
                  type="file"
                  id={`holiday_quote_cover_file`}
                  className="hidden"
                  multiple
                  onChange={e => {
                    const selectedFiles = (document.getElementById(
                      `holiday_quote_cover_file`
                    )! as any).files;
                    onUpload(Array.from(selectedFiles));
                    (document.getElementById(
                      `holiday_quote_cover_file`
                    )! as any).value = "";
                  }}
                />
                <Dropdown
                  menu={editCoverMenu}
                  // placement="topCenter"
                  trigger={["click"]}
                  arrow
                >
                  <Button
                    icon={<i className="ri-edit-line" />}
                    loading={isUploading}
                  >
                    Edit Cover
                  </Button>
                </Dropdown>
              </div>
            )}
          </div>

          <div className="mx-auto" style={{ width: 740 }}>
            <div
              className="block rounded-lg shadow-lg px-6 py-12 md:py-16 md:px-12"
              style={{
                marginTop: -80,
                background: isDarkMode
                  ? "hsla(0, 0%, 100%, 0.1)"
                  : "hsla(0, 0%, 100%, 0.7)",
                backdropFilter: "blur(30px)"
              }}
            >
              <div
                className={classnames(
                  "border-transparent border-solid border-2",
                  {
                    "hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer hover:border-blue-600 hover:border-dashed":
                      holidayQuote.status === "DRAFT"
                  }
                )}
                onClick={() => {
                  if (holidayQuote.status === "DRAFT") {
                    triggerTempModal(QuoteHeaderEditor, {
                      data: holidayQuote!,
                      onSave: async data => {
                        editHolidayQuote({
                          data: data.data
                        });
                      }
                    });
                  }
                }}
              >
                <div className="px-8">
                  {organization?.data.logo && (
                    <div className="flex flex-row justify-center items-center">
                      <div className="logo">
                        <img
                          src={organization?.data.logo}
                          alt="Logo"
                          style={{ maxWidth: 200 }}
                        />
                      </div>
                    </div>
                  )}

                  {!organization?.data.logo && (
                    <div className="my-8">
                      <Alert
                        type="warning"
                        message="You have not setup your brand logo yet; You can setup this in workspace settings"
                      />
                    </div>
                  )}

                  <div className="text-gray-600">
                    Created by {createdByUser?.data?.firstName} for{" "}
                    {contact?.data?.firstName}
                  </div>
                  <div className="text-2xl font-bold">
                    {holidayQuote.data.quoteTitle}
                  </div>
                  <div className="flex flex-row justify-start items-center flex-1">
                    <div className="text-xs font-bold pr-2">
                      <i className="ri-user-star-line"></i>
                    </div>
                    <div className="text-xs uppercase font-semibold">
                      {holidayQuote.data.adults && (
                        <span className="mr-2">
                          {holidayQuote.data.adults} Adult(s)
                        </span>
                      )}
                      {holidayQuote.data.children && (
                        <span className="mr-2">
                          {holidayQuote.data.children}{" "}
                          {holidayQuote.data.children > 1
                            ? "Children"
                            : "Child"}
                        </span>
                      )}
                      {holidayQuote.data.infants && (
                        <span className="mr-2">
                          {holidayQuote.data.infants} Infant(s)
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="font-bold">
                    {holidayQuote.data.numberOfNights + 1} days /{" "}
                    {holidayQuote.data.numberOfNights} Nights
                  </div>
                </div>

                <div className="flex flex-col items-center relative mb-8 p-8">
                  {holidayQuote.data.greetings}
                </div>
              </div>

              {holidayQuote.status === "DRAFT" && (
                <>
                  <div className="itinerary-container my-8">
                    <SimpleDNDList
                      list={state}
                      onListChange={setStateAndSave}
                      renderItem={renderItem}
                    />

                    <div className="p-4 flex flex-row justify-center items-center">
                      <Dropdown
                        menu={{
                          items: ItineraryBlockTypeList.map(item => ({
                            key: item,
                            label: ItineraryBlockTypeMap[item].label,
                            icon: ItineraryBlockTypeMap[item].icon
                          })),
                          onClick: menu => {
                            const type = menu.key;
                            onAddItineraryType(type as any);
                          }
                        }}
                        placement="topCenter"
                        trigger={["click"]}
                        arrow
                      >
                        <Button
                          type="dashed"
                          icon={<i className="ri-add-line"></i>}
                          className="font-bold p-8"
                          size="large"
                          block
                        >
                          Add Section{" "}
                          <i className="ri-arrow-drop-down-line"></i>
                        </Button>
                      </Dropdown>
                    </div>

                    {/* <div className="add-blocks flex flex-row flex-wrap justify-center items-center">
                {ItineraryBlockTypeList.map((item) => (
                  <div
                    className="cursor-pointer border border-dashed border-gray-600 p-4 m-4 flex flex-col justify-center items-center hover:bg-gray-100 rounded-lg"
                    key={item}
                    style={{ width: "12rem", height: "7rem" }}
                    onClick={() => onAddItineraryType(item)}
                  >
                    <div className="icon-container text-2xl relative">
                      {ItineraryBlockTypeMap[item].icon}
                    </div>
                    <div className="flex flex-row justify-center items-center">
                      <i className="ri-add-line"></i>{" "}
                      {ItineraryBlockTypeMap[item].label}
                    </div>
                  </div>
                ))}
              </div> */}
                  </div>
                </>
              )}

              {holidayQuote.status === "FINALIZED" && (
                <>
                  <div className="itinerary-container my-8">
                    {state.map(block => renderItem(block))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <DarkModeBg />
    </Modal>
  );
};

export const itineraryRender = (
  holidayQuoteId: string,
  holidayQuote: iHolidayQuote,
  item: iItineraryBlock,
  onRemoveBlock: (itemId: string) => any,
  onEditBlock: (itemId: string) => any,
  locked?: boolean
) => {
  const isEditable =
    !locked &&
    !["SHARE_ITINERARY_BLOCK", "AGENT_CONTACT", "FEEDBACK_RATING"].includes(
      item.type
    );

  return (
    <React.Fragment key={item.id}>
      {!locked && (
        <div
          className={classnames(
            "w-6 flex items-center absolute cursor-pointer delete-icon"
          )}
          style={{
            left: -25,
            top: 30
          }}
          onClick={() => onRemoveBlock(item.id)}
        >
          <i className="ri-delete-bin-line"></i>
        </div>
      )}

      <div
        className={classnames("border-transparent border-solid border-2  p-4", {
          "hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer hover:border-blue-600 hover:border-dashed": isEditable
        })}
        onClick={() => {
          isEditable && onEditBlock(item.id);
        }}
      >
        {!["PRICE_SUMMARY", "AGENT_CONTACT", "CONFIRM_AND_PAY"].includes(
          item.type
        ) && (
          <>
            <div className="flex flex-row justify-start items-center">
              <div className="icon text-4xl mr-2">
                {ItineraryBlockTypeMap[item.type].icon}
              </div>
              <div className="label text-2xl font-bold">
                {ItineraryBlockTypeMap[item.type].label}:
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col items-center relative mb-8">
          <div className="details w-full">
            {/* Todo: Change Style */}
            {item.type === "FLIGHT_ITINERARY" && (
              <>
                {item.data.flightDetailsInputMethod === "MANUAL" && (
                  <div className="flex flex-col justify-center items-center gap-4">
                    {(item.data.flightDetailsScreenshots || []).map(url => (
                      <Image key={url} src={url} className="rounded-lg" />
                    ))}
                  </div>
                )}

                {item.data.flightDetailsInputMethod !== "MANUAL" && (
                  <>
                    {(!item.data.itineraryStyle ||
                      item.data.itineraryStyle === "Elegant") && (
                      //  eslint-disable-next-line react/jsx-pascal-case
                      <FlightItineraryBlock_GoogleStyle
                        flightData={item.data.flightData!}
                      />
                    )}
                    {item.data.itineraryStyle === "Simple" && (
                      //  eslint-disable-next-line react/jsx-pascal-case
                      <FlightItineraryBlock_DefaultStyle
                        flightData={item.data.flightData!}
                      />
                    )}
                    {item.data.itineraryStyle === "Table" && (
                      <div className="overflow-auto">
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <FlightItineraryBlock_TableStyle
                          flightData={item.data.flightData!}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {item.type === "HOTEL_ITEM" && (
              <HotelDetailsBlock data={item.data} />
            )}

            {item.type === "FINE_PRINT_ITEM" && (
              <FinePrintBlock items={item.data.finePrints} />
            )}

            {item.type === "SIMPLE_DETAILS" && (
              <AdditionalDetailsBlock html={item.data.content} />
            )}

            {item.type === "PRICE_SUMMARY" && (
              <PriceSummaryBlock data={item.data} holidayQuote={holidayQuote} />
            )}

            {item.type === "SHARE_ITINERARY_BLOCK" && (
              <div className="my-12 flex flex-col justify-center items-center">
                <ShareBlock url="#" text="Checkout this itinerary" />
              </div>
            )}

            {item.type === "PASSENGER_DETAILS" && (
              <PassengerDetailsBlock passengers={item.data.passengers} />
            )}

            {item.type === "MAP_BLOCK" && (
              <MapImageBlock data={item.data.placeObj} />
            )}

            {item.type === "DESTINATION_SUMMARY_BLOCK" && (
              <DestinationHighlightBlock data={item.data} />
            )}

            {item.type === "AGENT_CONTACT" && (
              <ContactAgentBlock data={item.data} />
            )}

            {item.type === "FEEDBACK_RATING" && (
              <FeedbackAndRatingsBlock
                data={item.data}
                holidayQuoteId={holidayQuoteId}
              />
            )}

            {item.type === "CONFIRM_AND_PAY" && (
              <ConfirmAndPayBlock data={item.data} />
            )}

            {item.type === "WHATS_INCLUDED" && (
              <WhatsIncludedBlock
                data={item.data}
                holidayQuote={holidayQuote}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
