import { Button } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useAccountManager } from "./helpers/user-account-manager";

export const ContactAccountManagerCard = ({
  type,
  text,
  buttonType,
  buttonSize,
  buttonClassName
}: {
  type: "CARD" | "BANNER" | "SMALL" | "TINY" | "BUTTON";
  text?: "Are you stuck? Need help?" | "Got Questions?" | string;
  buttonType?: "link" | "text" | "ghost" | "primary" | "default" | "dashed";
  buttonSize?: SizeType;
  buttonClassName?: string;
}) => {
  const {
    fullName,
    shortName,
    avatar,
    email,
    phoneNumber,
    chatId,
    onContactAccountManager
  } = useAccountManager();

  if (type === "CARD") {
    return (
      <div className="contact-card border border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-800 flex flex-row p-8 rounded-lg m-8">
        <div className="avatar-holder mr-8">
          <img src={avatar} style={{ width: 125 }} alt="Account Owner" />
        </div>
        <div className="details-container flex flex-col">
          <div className="list-details">
            <div className="font-bold">{fullName}</div>
          </div>
          <div className="list-details">
            <div className="label text-gray-600 dark:text-gray-400 text-sm mode_transition">
              Email
            </div>
            <div className="">{email}</div>
          </div>
          <div className="list-details">
            <div className="label text-gray-600 dark:text-gray-400 text-sm mode_transition">
              Phone
            </div>
            <div className="">{phoneNumber}</div>
          </div>
          <div className="chat-bow mt-4">
            <Button
              type="primary"
              block
              icon={<i className="ri-chat-smile-3-line"></i>}
              className="font-bold"
              onClick={onContactAccountManager}
            >
              Chat with Me
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (type === "BANNER") {
    return (
      <div className="contact-card border border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-800 flex flex-row p-2 rounded-lg m-8 justify-center items-center">
        <div className="avatar-holder mr-2">
          <img src={avatar} style={{ width: 50 }} alt="Account Owner" />
        </div>
        <div className="details-container flex flex-col px-2">
          <div className="list-details">
            <div className="font-bold">
              I'm {shortName}, from Click Connector and I'm here to help you
            </div>
          </div>
        </div>
        <div className="chat-bow">
          <Button
            type="primary"
            block
            icon={<i className="ri-chat-smile-3-line"></i>}
            className="font-bold"
            onClick={onContactAccountManager}
          >
            Chat with me
          </Button>
        </div>
      </div>
    );
  }

  if (type === "SMALL") {
    return (
      <div className="contact-card border border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-800 flex flex-row p-2 rounded-lg  my-8 justify-center items-center">
        <div className="font-bold mr-4">Got Questions?</div>
        <div className="avatar-holder mr-4">
          <img src={avatar} style={{ width: 50 }} alt="Account Owner" />
        </div>

        <div className="chat-bow">
          <Button
            type="primary"
            block
            icon={<i className="ri-chat-smile-3-line"></i>}
            className="font-bold"
            onClick={onContactAccountManager}
          >
            Chat with me
          </Button>
        </div>
      </div>
    );
  }

  if (type === "TINY") {
    return (
      <div className="contact-card border border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-800 flex flex-row p-2 rounded-lg m-8 justify-center items-center">
        <div className="font-bold mr-4">Got Questions?</div>
        <div className="chat-bow">
          <Button
            type="primary"
            block
            icon={<i className="ri-chat-smile-3-line"></i>}
            className="font-bold"
            onClick={onContactAccountManager}
          >
            {text || "Chat With Customer Success Team"}
          </Button>
        </div>
      </div>
    );
  }

  if (type === "BUTTON") {
    return (
      <div className="px-4">
        <Button
          type={buttonType || "primary"}
          block
          icon={<i className="ri-chat-smile-3-line"></i>}
          className={buttonClassName || "font-bold"}
          onClick={onContactAccountManager}
          size={buttonSize}
        >
          {text || "Got Questions? Chat Now"}
        </Button>
      </div>
    );
  }

  return <div>Unsupported Type</div>;
};
