export function SplitMessage(text: string, l: number) {
  // patch l to add ellipses
  l = l - 4;
  const parts: string[] = [];
  while (text.length > l) {
    let pos = text.substring(0, l).lastIndexOf(" ");
    pos = pos <= 0 ? l : pos;
    parts.push(text.substring(0, pos));
    let i = text.indexOf(" ", pos) + 1;
    if (i < pos || i > pos + l) i = pos;
    text = text.substring(i);
  }
  parts.push(text);
  return parts.map((part, index) => {
    // Add elapses if it's not the last part
    if (index !== parts.length - 1) {
      part = `${part} ...`;
    }
    return part;
  });
}
