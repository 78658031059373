import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iCampaign } from "@sdk/campaigns/campaigns-model";
import { iEmailConnectionData } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Alert,
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { CountrySelector } from "components/common/country-selector/country-selector";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import {
  ColorPickerFormItem,
  ImageUploaderFormItem,
} from "components/modules/connections/channels/email/components/forms/email-template";
import { push } from "connected-react-router";
import copyToClipboard from "copy-to-clipboard";
import dayjs from "dayjs";
import {
  AddCampaign,
  AddNewConnection,
  ConfigureCampaign,
  EditEmailCampaign,
  ViewCampaignReport,
} from "modal-registry";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllCampaigns } from "store/modules/campaigns/campaigns.helpers";
import { selectAllCampaigns } from "store/modules/campaigns/campaigns.selectors";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useUrlState } from "utils/hooks/use-url-state";

const CampaignsPage = () => {
  const { doAction: sendCampaign, isProcessing: isProcessingCampaign } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (campaignId: string) =>
          SDK.campaigns.sendCampaign(campaignId),
        successMessage: "Campaign has been queued",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const { changePanelState, triggerTempModal } = useModalPanels();

  const columns = useMemo(
    () => [
      {
        title: "",
        dataIndex: "label",
        render: (label: string, record: iCampaign) => {
          return (
            <>
              <div className="label text-lg">
                {label}{" "}
                <Tooltip title="Copy Campaign Id">
                  <Button
                    onClick={(e) => {
                      copyToClipboard(record.id!);
                      e.stopPropagation();
                    }}
                    type="link"
                    icon={<i className="ri-clipboard-line"></i>}
                  ></Button>
                </Tooltip>
              </div>
              <div className="text-gray-600">
                Subject: {record.data.subjectLine || "Not Set"}
              </div>
              <div className="text-gray-600">
                Created on: {dayjs(record.metaData.createdAt).format("long")}
              </div>
            </>
          );
        },
      },

      {
        title: "",
        dataIndex: "status",
        width: 120,
        render: (status: string, record: iCampaign) => {
          return <Tag>{record.status}</Tag>;
        },
      },
      {
        title: "",
        key: "action",
        width: 200,
        render: (text, record: iCampaign) => (
          <Space size="middle">
            {record.status === "DRAFT" && (
              <>
                <div className="flex flex-row">
                  <Button
                    onClick={() => {
                      changePanelState(EditEmailCampaign, true, {
                        campaignId: record.id!,
                      });
                    }}
                    icon={<i className="ri-edit-line"></i>}
                    style={{ width: 120 }}
                    className="rounded-r-none"
                  >
                    Edit
                  </Button>
                  <Dropdown
                    trigger={["click"]}
                    overlay={
                      <Menu data-click-context="Campaign More Button">
                        <Menu.Item
                          icon={<i className={"ri-mail-send-line"}></i>}
                          onClick={() => {
                            changePanelState(ConfigureCampaign, true, {
                              campaignId: record.id!,
                              mode: "SEND",
                            });
                          }}
                        >
                          Review and Send
                        </Menu.Item>
                        <Menu.Item
                          icon={<i className="ri-settings-2-line"></i>}
                          onClick={() => {
                            changePanelState(ConfigureCampaign, true, {
                              campaignId: record.id!,
                              mode: "EDIT",
                            });
                          }}
                        >
                          Configure Campaign
                        </Menu.Item>
                        <Menu.Item
                          icon={<i className={"ri-delete-bin-line"}></i>}
                          onClick={() => {}}
                          disabled
                        >
                          Delete Campaign
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button
                      icon={<i className="ri-arrow-down-s-line pl-0 ml-0"></i>}
                      className="rounded-l-none"
                    ></Button>
                  </Dropdown>
                </div>
              </>
            )}

            {record.status === "QUEUED" && (
              <>
                {(record.metaData.sentAt || 0) < Date.now() - 5 * 60 * 60 && (
                  <>
                    <Button
                      // type="text"
                      icon={<i className="ri-folder-chart-line"></i>}
                      onClick={() => {
                        changePanelState(ViewCampaignReport, true, {
                          campaignId: record.id!,
                        });
                      }}
                      style={{ width: 155 }}
                    >
                      View Report
                    </Button>
                  </>
                )}
                {(record.metaData.sentAt || 0) > Date.now() - 5 * 60 * 60 && (
                  <>
                    <Button
                      type="text"
                      disabled
                      icon={<i className="ri-mail-send-fill"></i>}
                    >
                      Queued For Sending
                    </Button>
                  </>
                )}
              </>
            )}
          </Space>
        ),
      },
    ],
    [changePanelState],
  );

  const { state: campaigns, retry } = useQueryWithStore(
    selectAllCampaigns,
    loadAllCampaigns(),
  );

  const { state: connections } = useQueryWithStore(
    selectAllConnections,
    loadAllConnections,
  );

  const emailConnection = useMemo(() => {
    const emailConnections = connections.filter(
      (item) => item.type === "EMAIL",
    );

    const connectionsReady = emailConnections.filter(
      (item) =>
        (item?.data as iEmailConnectionData)?.customDomain?.data
          ?.isMailBoxReady,
    );

    return {
      hasOne: emailConnections.length > 0,
      firstConnectionId: emailConnections[0]?.id,
      hasAtLeastOneReadyConnection: connectionsReady.length > 0,
    };
  }, [connections]);

  const organization = useSelector(selectOrganization);

  const [activeTab, setActiveTab] = useUrlState(
    "active-tab",
    "Campaigns" as string,
  );

  const dispatch = useDispatch();

  const showMailingListWarning = useMemo(() => {
    return !(
      organization?.data.mailingAddress?.addressLine1 &&
      organization?.data.mailingAddress?.city &&
      organization?.data.mailingAddress?.country
    );
  }, [
    organization?.data.mailingAddress?.addressLine1,
    organization?.data.mailingAddress?.city,
    organization?.data.mailingAddress?.country,
  ]);

  return (
    <StyledScrollArea className="w-full h-full flex-1 animated fadeInLeftMin">
      <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4">
        <SectionHeader
          title="Campaigns"
          description="Create Email/SMS campaigns to any customer segment in your CRM"
          icon={<i className="ri-mail-send-fill"></i>}
          addons={
            <>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => {
                  triggerTempModal(AddCampaign, {
                    onCreated: (campaign) => {
                      changePanelState(EditEmailCampaign, true, {
                        campaignId: campaign.id!,
                      });
                    },
                  });
                }}
              >
                Create New Campaign
              </Button>
            </>
          }
        />
        <Divider />

        {!emailConnection.hasOne && (
          <>
            <Alert
              message="Set up an Email Connection to begin with"
              description={
                <div>
                  To Send Email Campaigns, you should setup at least one email
                  connection
                  <Button
                    onClick={() => {
                      changePanelState(AddNewConnection, true, {});
                    }}
                  >
                    Setup Now
                  </Button>
                </div>
              }
              type="warning"
              showIcon
            />
          </>
        )}

        {emailConnection.hasAtLeastOneReadyConnection &&
          !emailConnection.hasAtLeastOneReadyConnection && (
            <>
              <Alert
                message="Setup your domain before sending campaigns"
                description={
                  <div>
                    You have not set up custom domain yet. It is mandatory that
                    you setup your email domain
                    <Button
                      onClick={() => {
                        dispatch(
                          push(
                            `/configurations/connections/${emailConnection.firstConnectionId}/mailbox?activeConfigs=Setup%20Custom%20Email%20Domain`,
                          ),
                        );
                      }}
                    >
                      Setup your domain
                    </Button>
                  </div>
                }
                type="warning"
                showIcon
              />
            </>
          )}

        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          tabBarStyle={{ paddingLeft: 16 }}
        >
          <Tabs.TabPane
            tab={<span className="font-bold text-sm">Campaigns</span>}
            key="Campaigns"
          >
            {showMailingListWarning && (
              <Alert
                message="Set Mailing Address"
                description={
                  <div>
                    You have not set mailing address yet. Please set the mailing
                    address before sending any campaigns
                    <Button
                      onClick={() => {
                        dispatch(
                          push(
                            `/configurations/campaigns?active-tab=Configurations&activeConfigs=Mailing%20Address`,
                          ),
                        );
                      }}
                    >
                      Set Mailing Address
                    </Button>
                  </div>
                }
                type="warning"
                showIcon
              />
            )}

            <Table
              columns={columns}
              dataSource={campaigns}
              pagination={false}
              rowKey="id"
              className="mb-16"
            />
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={<span className="font-bold text-sm">Configurations</span>}
            key="Configurations"
          >
            <ConfigurationEditorComponent
              title="Branding"
              description="Set Logo and Brand Colour"
              icon={"ri-drop-fill"}
              entityType="WORKSPACE"
            >
              <ColorPickerFormItem
                label="Brand Color"
                fieldName={["data", "primaryColor"]}
              />
              <ImageUploaderFormItem
                label="Logo"
                fieldName={["data", "logo"]}
                entityType="WORKSPACE"
                entityId={organization?.id!}
              />
            </ConfigurationEditorComponent>

            <ConfigurationEditorComponent
              icon={"ri-map-pin-line"}
              title={"Mailing Address"}
              description={
                "The mailing address that will be used in newsletters"
              }
              entityType={"WORKSPACE"}
              entityId={organization?.id!}
            >
              <Form.Item
                label="Adders Line 1"
                name={["data", "mailingAddress", "addressLine1"]}
                rules={[{ required: true, message: "Address is required" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Adders Line 2"
                name={["data", "mailingAddress", "addressLine2"]}
                rules={[]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="City"
                name={["data", "mailingAddress", "city"]}
                rules={[{ required: true, message: "City is required" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Postal Code"
                name={["data", "mailingAddress", "postalCode"]}
                rules={[]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Country"
                name={["data", "mailingAddress", "country"]}
                rules={[{ required: true, message: "Country is required" }]}
              >
                <CountrySelector />
              </Form.Item>
            </ConfigurationEditorComponent>
          </Tabs.TabPane>
        </Tabs>
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
export default CampaignsPage;
