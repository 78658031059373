import { iConnection } from "@sdk/conversations/conversations.models";
import { iUser } from "@sdk/user-management/user-management.models";
import { userHasConnectionGroupPermission } from "components/modules/connections/helpers/user-has-connection-permission";
import { useMemo } from "react";

export const usePermissionToJoinChats = (
  connection: iConnection,
  currentUser: iUser
) => {
  const hasPermissionToJoin = useMemo(
    () => hasUserPermissionToJoin(currentUser, connection),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      connection?.userGroups,
      currentUser?.metaData.disableChatJoining,
      currentUser?.userGroups
    ]
  );

  return {
    hasPermissionToJoin
  };
};

export const hasUserPermissionToJoin = (
  currentUser: iUser,
  connection: iConnection
) => {
  if (currentUser.metaData.disableChatJoining) {
    return false;
  }

  if (
    !userHasConnectionGroupPermission(connection?.userGroups || [], [
      currentUser?.id,
      ...(currentUser?.userGroups || [])
    ])
  ) {
    return false;
  }
  return true;
};
