import classnames from "classnames";
import { UserAvatar } from "components/common/avatar/avatar";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import { useSelector } from "react-redux";
import { selectUser } from "store/modules/users/users.selectors";

export const UserPill = ({
  userId,
  withoutBg,
}: {
  userId: string;
  withoutBg?: boolean;
}) => {
  const user = useSelector(selectUser(userId));

  const userName = getUserName(user);

  return (
    <div className="user-pill inline-flex flex-row justify-start items-center leading-normal">
      <div
        className={classnames(
          "flex-row flex justify-start items-center  rounded-lg px-4",
          {
            "bg-gray-100 dark:bg-gray-800": !withoutBg,
          }
        )}
      >
        <UserAvatar userId={userId} size={24} />
        <div className="flex-col pl-3">
          <div className="font-bold">{userName || "Unknown"} </div>
        </div>
      </div>
    </div>
  );
};
