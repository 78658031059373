import { iCampaign } from "@sdk/chat-widgets/chat-widgets.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Table } from "antd";
import {
  DraggableBodyRowGenerator,
  DraggableContainerGenerator,
  DragHandle
} from "components/common/sortable-table/sortable-table-elements";
import { useSortableTable } from "components/common/sortable-table/use-sortable-table";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectChatWidgetByConnectionId } from "store/modules/chat-widgets/chat-widgets.selectors";
import { setChatWidget } from "store/modules/chat-widgets/chat-widgets.slice";
import { useQueryWithStore } from "store/store.hooks";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { AddEditTriggerModal } from "./add-edit-trigger-modal";
import { GetCampaignPresetType } from "./chat-campaign-presets";

export const ManageTriggers = ({ connectionId }: { connectionId?: string }) => {
  const {
    state: widget,
    retry: reload
  } = useQueryWithStore(
    selectChatWidgetByConnectionId(connectionId!),
    loadAllChatWidgets(),
    [connectionId]
  );

  const [editTriggerForm, setEditTriggerForm] = useSimpleState({
    mode: "ADD" as "ADD" | "EDIT",
    visibility: false,
    index: 0
  });

  const tableData = useMemo(
    () => widget?.configurations.conversationFlow.campaigns || [],
    [widget?.configurations.conversationFlow.campaigns]
  );

  const { state, setState, onSortEnd } = useSortableTable(
    tableData,
    newState => {
      console.log("On Order Changed");
      saveWidgetCampaigns(newState);
    }
  );

  const {
    doAction: saveWidgetCampaigns,
    isProcessing,
    dispatch
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => campaigns =>
        SDK.chatWidgets
          .editById(widget?.id!, {
            configurations: {
              conversationFlow: {
                campaigns
              }
            }
          })
          .then(d => {
            // added as a patch for sync not working on chat widget
            dispatch(setChatWidget(d));
          }),
      successMessage: "Saved",
      failureMessage: "Something went wrong"
    }),
    [widget?.id]
  );

  useEffect(() => {
    setState(tableData);
  }, [setState, tableData]);

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />
    },
    {
      title: "When",
      dataIndex: "when",
      render: (data, record: iCampaign, index) =>
        `${GetCampaignPresetType(record) || "Unknown Preset"}`,
      className: "drag-visible"
    },
    {
      title: "Message",
      dataIndex: "message",
      render: (data, record: iCampaign, index) => `${record.data.message}`,
      className: "drag-visible"
    },
    {
      title: "Update",
      dataIndex: "update",
      width: 100,
      render: (data, record, index) => (
        <Button
          icon={<i className="ri-pencil-line"></i>}
          type="link"
          onClick={() => {
            setEditTriggerForm({
              mode: "EDIT",
              visibility: true,
              index
            });
          }}
        />
      )
    },
    {
      title: "Remove",
      dataIndex: "remove",
      width: 100,
      render: (data, record, index) => (
        <Button
          icon={<i className="ri-delete-bin-line"></i>}
          type="link"
          onClick={() => {
            const updatedCampaignsState = _.without(state, record);
            setState(updatedCampaignsState);
            saveWidgetCampaigns(updatedCampaignsState);
          }}
        />
      )
    }
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: change => setSelectedRowKeys(change)
  };
  return (
    <div className="p-4">
      <div className="header flex flex-row justify-end items-center mb-10">
        {/* <div className="title font-bold text-2xl pl-4">
          <i className="ri-chat-follow-up-line"></i>Chat Campaigns
        </div> */}
        <div className="right-buttons">
          <Button
            onClick={() =>
              setEditTriggerForm({ visibility: true, mode: "ADD" })
            }
          >
            Add Campaign
          </Button>
        </div>
      </div>
      <div className="text-gray-600 dark:text-gray-400 mb-6 mode_transition">
        Triggers help you to automatically start a conversations with your
        visitor based on few conditions
      </div>

      <Table
        columns={columns}
        dataSource={state}
        pagination={false}
        rowKey="id"
        components={{
          body: {
            wrapper: DraggableContainerGenerator(onSortEnd),
            row: DraggableBodyRowGenerator(state)
          }
        }}
        // rowSelection={{
        //   type: "checkbox",
        //   ...rowSelection,
        // }}
      />

      <AddEditTriggerModal
        mode={editTriggerForm.mode}
        connectionId={connectionId!}
        visible={editTriggerForm.visibility}
        onChangeVisibility={visibility => setEditTriggerForm({ visibility })}
        campaignIndex={editTriggerForm.index}
        onSaveConnection={() => {
          // reload();
        }}
      />
    </div>
  );
};
