import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Popconfirm, Space, Table } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { SectionHeader } from "components/common/section-header";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { useEffect, useState } from "react";
import { loadOrganization } from "store/modules/workspace/workspace.helpers";
import { selectPipelines } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { AddEditPipeline } from "./add-edit-pipeline";

export const ManagePipelines = () => {
  const { state: availablePipelines, retry: reload } = useQueryWithStore(
    selectPipelines,
    loadOrganization
  );

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedPipeline, setSelectedPipeline] = useState("");
  const [
    isAddEditPipelineModalVisible,
    setAddEditPipelineModalVisibility
  ] = useState(false);

  const {
    doAction: deletePipeline,
    isProcessing: idDeletingPipeline
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (id: string) => SDK.pipelines.deletePipeline(id),
      successMessage: "Pipeline has been removed",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const columns = [
    {
      title: "Label",
      dataIndex: "label"
    },
    {
      title: "Stages",
      dataIndex: "stages",
      render: stages => {
        return (
          <div className="flex-row items-center">
            <div className="boxes"></div>
            <div className="caption text-gray-600 dark:text-gray-400 mode_transition">{`${stages.length} Stages`}</div>
          </div>
        );
      }
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: 200,
      render: (data, record, index) => (
        <Space>
          <Button
            type="link"
            icon={<i className="ri-pencil-line"></i>}
            onClick={() => {
              setSelectedPipeline(record.id);
              setAddEditPipelineModalVisibility(true);
            }}
          >
            Edit
          </Button>
          <Popconfirm
            placement="leftTop"
            title="Are you sure to delete?"
            onConfirm={() => {
              deletePipeline(record.id);
              reload();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              shape="circle"
              icon={<i className="ri-delete-bin-line"></i>}
            ></Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin">
      <SectionHeader
        title="Manage Pipelines"
        icon={<i className="ri-git-branch-line rotate-90"></i>}
        description="Create & Manage Pipelines that help organize and track progress"
        addons={
          <Space>
            <Button
              type="primary"
              // size="large"
              icon={<i className="ri-inbox-fill"></i>}
              onClick={() => setAddEditPipelineModalVisibility(true)}
            >
              Create New Pipeline
            </Button>
          </Space>
        }
      />
      <Divider />

      <Table
        columns={columns}
        dataSource={availablePipelines}
        pagination={false}
        rowKey="id"
        locale={{
          emptyText: (
            <EmptyData text="No pipelines to show" icon="ri-flag-line" />
          )
        }}
      />

      <AddEditPipeline
        visible={isAddEditPipelineModalVisible}
        onChangeVisibility={setAddEditPipelineModalVisibility}
        setSelectedPipeline={setSelectedPipeline}
        onCreated={() => {
          reload();
        }}
        pipelineId={selectedPipeline}
      />
      {/*onChangeVisibility and setSelectedPipeline are used together in AddEditPipeline. Can use it as one single function */}
    </StyledScrollArea>
  );
};
