import { Divider, Form, Input, Switch, Tag } from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { SimpleCollapse } from "components/common/simple-collapse/simple-collapse";
import { ConnectionSelector } from "components/modules/conversations/components/connection-selector/connection-selector";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { ImageUploaderFormItem } from "../../../email/components/forms/email-template";
import "./automatic-email-followups.scss";
import { EmailFollowupTemplate } from "./email-followup-template/email-followup-template";

export const AutomaticEmailFollowups = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const { state: _connection, retry: reloadConnection } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  return (
    <ConfigurationEditorComponent
      title="Automatic Email Followup"
      description="When customer is not on your website, send messages sent by users to customers via email"
      icon="ri-mail-send-fill"
      entityType="CONNECTION"
      entityId={connectionId}
    >
      <div className="auto-trigger flex flex-row items-center justify-between">
        <div className="font-bold">Send follow up emails to Live Chat</div>
        <Form.Item
          name={["data", "notifyClientViaEmail", "active"]}
          hasFeedback={false}
          valuePropName="checked"
          style={{ marginBottom: 0, marginLeft: 5 }}
        >
          <Switch />
        </Form.Item>
      </div>

      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const configEnabled = getFieldValue([
            "data",
            "notifyClientViaEmail",
            "active",
          ]);
          if (configEnabled) {
            return (
              <div className="mt-4">
                <Form.Item
                  label="Connections to Use"
                  name={["data", "notifyClientViaEmail", "connection"]}
                >
                  <ConnectionSelector
                    connectionFilter={(connection) =>
                      connection.type === "EMAIL"
                    }
                    allowClear={true}
                  />
                </Form.Item>

                <Form.Item shouldUpdate>
                  {({ getFieldValue }) => {
                    const connectionToUse = getFieldValue([
                      "data",
                      "notifyClientViaEmail",
                      "connection",
                    ]);
                    if (!connectionToUse) {
                      return (
                        <div className="mt-4">
                          <Form.Item
                            label="Sender Name"
                            name={[
                              "data",
                              "notifyClientViaEmail",
                              "senderName",
                            ]}
                            rules={[
                              {
                                required: true,
                                message:
                                  "When Connection is not selected, sender name is required",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <div className="text-gray-600 mb-10 -mt-5 dark:text-gray-400 mode_transition">
                            The Sender Name in the email
                          </div>
                        </div>
                      );
                    }
                    return <></>;
                  }}
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const connectionData = getFieldValue(["data"]);
                    return (
                      <>
                        <EmailFollowupTemplate
                          connectionId={connectionId}
                          tempConnectionData={connectionData}
                        />
                      </>
                    );
                  }}
                </Form.Item>

                <Divider>
                  <Tag>Customize Email Template</Tag>
                </Divider>

                <ImageUploaderFormItem
                  label="Logo"
                  fieldName={["data", "emailConfiguration", "logo"]}
                  entityType="CONNECTION"
                  entityId={connectionId}
                  rules={[
                    {
                      required: true,
                      message: "Logo is required",
                    },
                  ]}
                />

                <Form.Item
                  label="Home Page URL"
                  name={["data", "emailConfiguration", "homepageAddress"]}
                  rules={[
                    {
                      type: "url",
                      message: "This field must be a valid url.",
                      required: true,
                    },
                  ]}
                >
                  <Input size="large" placeholder="http://www.abc.com" />
                </Form.Item>
                <div className="text-gray-600 mb-10 dark:text-gray-400 mode_transition">
                  Your customer will be redirected to the above page when they
                  click on the reply button. Ideally this page should have the
                  chat widget to continue the conversation
                </div>

                <div className="mb-2">
                  <SimpleCollapse title="Additional Customizations">
                    <div className="my-4">
                      <Form.Item
                        label="Customize Email Subject Line"
                        name={[
                          "data",
                          "notifyClientViaEmail",
                          "customLocale",
                          "title",
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="New Reply from the Team"
                        />
                      </Form.Item>

                      <ImageUploaderFormItem
                        label=" Customize Email Banner Image"
                        fieldName={[
                          "data",
                          "notifyClientViaEmail",
                          "customLocale",
                          "bannerImage",
                        ]}
                        entityType="CONNECTION"
                        entityId={connectionId}
                      />
                    </div>
                  </SimpleCollapse>
                </div>
              </div>
            );
          }
          return <></>;
        }}
      </Form.Item>
    </ConfigurationEditorComponent>
  );
};
