import { useMemo } from "react";
import { iReportTypes } from "../components/report-canvas/widget-canvas-models";
import {
  ActivitiesReportsFilterSchema,
  ContactsReportsFilterSchema,
  OpportunitiesReportsFilterSchema,
  ReportsFilterSchema
} from "../schemas/report-filter-schemas";

export const useReportFilterSchema = (type: iReportTypes | "GENERAL") => {
  const filterSchema = useMemo(() => {
    if (type === "LEADS") {
      return ContactsReportsFilterSchema;
    } else if (type === "ACTIVITIES") {
      return ActivitiesReportsFilterSchema;
    } else if (type === "COMPANIES") {
      return ActivitiesReportsFilterSchema;
    } else if (type === "OPPORTUNITIES") {
      return OpportunitiesReportsFilterSchema;
    }
    return ReportsFilterSchema;
  }, [type]);

  return filterSchema;
};
