export interface iFeatureRequestUserRecord {
  userId: string;
  organizationId: string;
  name: string;
  avatar: string;
}

export interface iFeatureRequestComment {
  id: string;
  from: iFeatureRequestUserRecord;
  comment: string;
  timestamp: number;
}

export interface iFeatureRequestVote extends iFeatureRequestUserRecord {
  timestamp: number;
}

export type iAppFeatureRequestStatus =
  | "In Review"
  | "In Backlog"
  | "Selected for Build"
  | "In Build"
  | "Released"
  | "Rejected";

export interface iAppFeatureRequest {
  id?: string;
  type: "FEATURE_REQUEST" | "BUG";
  category: string;
  submittedBy: iFeatureRequestUserRecord;
  submittedAt: number;
  votes: iFeatureRequestVote[];
  comments: iFeatureRequestComment[];
  title: string;
  shortDescription: string;
  status: iAppFeatureRequestStatus;
  isArchived?: boolean;
  body: string;
  attachments: string[];
  tags: string[];
  teamComment: string;
  isDeleted?: boolean;
}

export const appFeatureRequestCategories: {
  id: string;
  description: string;
  description2?: string;
}[] = [
  {
    id: "Native Integration",
    description:
      "The 3rd Party app must have public API support to have an integration built.",
  },
  {
    id: "Channel Integration",
    description:
      "Additional new channels through which you can send and receive messages.",
  },
  {
    id: "Unibox",
    description:
      "Everything to do with chats, tickets, messages, team collaborations goes here.",
  },
  {
    id: "Live Chat Widget",
    description:
      "Everything to do with the chat widget, appearance, integrations and features goes here",
  },
  {
    id: "Knowledge Base",
    description: "Everything to do with knowledge base portal goes goes here.",
  },
  {
    id: "ChatBot",
    description:
      "Looking for a new feature or a new use case with our chatbots module?",
  },
  {
    id: "CRM",
    description: "Let us know can can we improve our CRM Module",
  },
  {
    id: "Knowledge Kit",
    description: "How can we help you to build your AI Knowledge Repository?",
  },
  {
    id: "New Module",
    description: "Do you have an idea for a new module? We are listening",
  },
  {
    id: "Workspace Configuration",
    description: "Anything that we are missing in our workspace settings?",
  },
  {
    id: "Other",
    description: "Everything else goes here.",
  },
];

export type iAppFeatureCategoryType =
  | "Native Integration"
  | "Channel Integration"
  | "Unibox"
  | "Live Chat Widget"
  | "Knowledge Base"
  | "ChatBot"
  | "Knowledge Kit"
  | "CRM"
  | "New Module"
  | "Workspace Configuration"
  | "Other";

export const FeatureRequestsData: {
  label: string;
  category: iAppFeatureCategoryType;
  shortDescription: string;
  teamComment?: string;
}[] = [
  {
    label: "Integrate with Pabbly Connect",
    category: "Native Integration",
    shortDescription:
      "Ability to use Zapier alternative Pabbly Connect(https://www.pabbly.com/connect/) with Click Connector.",
    teamComment:
      "We are now waiting for more users to upvote with feature before we consider this integration. Please leave your use case in the comments sections for us to expedite this integration",
  },
  {
    label: "Integrate with Twillio SMS",
    category: "Native Integration",
    shortDescription:
      "Ability to use Twilio to send and receive SMS via ClickConnector",
  },
  {
    label: "Support RTL",
    category: "Other",
    shortDescription: "Customer Portals should support RTL and RTL languages.",
  },
  {
    label: "Support for Webhooks",
    category: "Other",
    shortDescription:
      "Ability to programmatically listen to changes in ClickConnector workspace via webhooks",
  },
  {
    label: "HubSpot Integration",
    category: "Native Integration",
    shortDescription:
      "Ability to pull in data from Hubspot when a new conversation is created",
  },
  {
    label:
      "Ability to track custom parameters in landing page as conversation tags",
    category: "Other",
    shortDescription:
      "We should be able to specify additional parameters apart from standard UTM tags to automatically tag conversations",
  },
  {
    label: "Ability to add custom Open AI Keys",
    category: "Other",
    shortDescription:
      "We should be able to enter our own OPEN AI API Keys to process queries with AI. ",
  },
  {
    label: "Ability to add custom Google Translation Keys",
    category: "Other",
    shortDescription:
      "We should be able to upload our own Google Translation Keys to translate messages",
  },
  {
    label: "Multi Language Support",
    category: "Other",
    shortDescription:
      "We should be able to change the language of Customer Portals such as Live Chat and Knowledge base portals",
    teamComment: "We are looking for beta testers",
  },
  {
    label: "Intercom like Tours",
    category: "New Module",
    shortDescription:
      "Ability to design product tours and enable it for users right from ClickConnector",
  },
  {
    label: "Custom AI Prompts",
    category: "Other",
    shortDescription:
      "Ability to change and modify the AI prompts used in AI Integrations",
  },
  {
    label: "LINE app chat Integration",
    category: "Channel Integration",
    shortDescription: "Ability to send and receive messages thorough Line App",
  },
  {
    label: "SLA Tracking",
    category: "Other",
    shortDescription: "Ability",
    teamComment: "Looking for beta testers.",
  },
  {
    label: "Uchat Integration",
    category: "Channel Integration",
    shortDescription: "Ability to send and receive messages thorough UChat",
  },
  {
    label: "Telegram Integration",
    category: "Channel Integration",
    shortDescription: "Ability to send and receive messages thorough Telegram",
  },
  {
    label: "Customizing Bot Image and Name in Chat Widget",
    category: "Live Chat Widget",
    shortDescription:
      "Ability to differentiate chatbot messages with a unique avatar and name",
  },
  {
    label: "Cal.com integration",
    category: "Native Integration",
    shortDescription: "Ability to integrate Cal.com calendars in Chatbot Flow",
  },
  {
    label: "Pre-Chat Form - Link to Privacy",
    category: "Live Chat Widget",
    shortDescription:
      "Ability to display the organizations' Privacy Policy link in the pre-chat-form",
  },
  {
    label: "PDF Document Support in Knowledge Kit",
    category: "Knowledge Kit",
    shortDescription: "Ability to train AI Assistant with PDF Documents",
  },
  {
    label: "Product Cards in Live Chat Widget",
    category: "Live Chat Widget",
    shortDescription: "Ability to send product cards via Live Chat",
  },
  {
    label: "Stripe Integration",
    category: "Native Integration",
    shortDescription: "Ability to see Stripe data right inside ClickConnector",
  },
  {
    label: "Sitemap Support in Knowledge Kit",
    category: "Knowledge Kit",
    shortDescription: "Ability to upload sitemap to train AI Assistant",
  },
  {
    label: "Google Docs Support in Knowledge Kit",
    category: "Knowledge Kit",
    shortDescription: "Ability to train AI Assistant with Google Doc",
  },
  {
    label: "Add Slug to KB Page",
    category: "Knowledge Base",
    shortDescription:
      "Knowledge Base Pages should support human readable URLs for better SEO",
  },
];
