import { useReducer } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import AddFAQCollection from "./components/add-faq-collection.component";
import SingleEditableFAQCollection from "./components/single-editable-faq-collection.component";
import { iFAQStoreState } from "./faq-model";
import { reducer } from "./faq-store-reducer";
import { reorder } from "./helpers/re-order-array";

const EditFAQs = ({
  initialState,
  onSave,
}: {
  initialState: iFAQStoreState;
  onSave?: (state: iFAQStoreState) => any;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffectWhen(
    () => {
      onSave && onSave(state);
    },
    [onSave, state],
    [state],
  );

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (result.type === "droppableItem") {
      const items = reorder(state.collections.list, sourceIndex, destIndex);

      dispatch({
        type: "REORDER_FAQ_COLLECTION",
        payload: {
          list: items,
        },
      });
    } else if (result.type === "droppableSubItem") {
      // const collectionFAQMap = state.collections.byIds.reduce((acc, item) => {
      //   acc[item.id] = item.subItems;
      //   return acc;
      // }, {});
      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;
      const sourceSubItems = state.collections.byIds[sourceParentId].faqs;
      const destSubItems = state.collections.byIds[destParentId].faqs;
      //Items = [...state.items];
      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(
          sourceSubItems,
          sourceIndex,
          destIndex,
        );
        // newItems = newItems.map((item) => {
        //   if (item.id === sourceParentId) {
        //     item.subItems = reorderedSubItems;
        //   }
        //   return item;
        // });
        dispatch({
          type: "REORDER_FAQ",
          payload: {
            collectionId: sourceParentId,
            faqs: reorderedSubItems,
          },
        });
      } else {
        let newSourceSubItems = [...sourceSubItems];
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);
        let newDestSubItems = [...destSubItems];
        newDestSubItems.splice(destIndex, 0, draggedItem);
        // newItems = newItems.map((item) => {
        //   if (item.id === sourceParentId) {
        //     item.subItems = newSourceSubItems;
        //   } else if (item.id === destParentId) {
        //     item.subItems = newDestSubItems;
        //   }
        //   return item;
        // });
        dispatch({
          type: "REORDER_FAQ",
          payload: {
            collectionId: sourceParentId,
            faqs: newSourceSubItems,
          },
        });
        dispatch({
          type: "REORDER_FAQ",
          payload: {
            collectionId: destParentId,
            faqs: newDestSubItems,
          },
        });
      }
    }
  };

  return (
    <div className="container flex flex-col">
      {/* <div className="save-button-container flex flex-row justify-end items-center p-4">
        <Button
          type="primary"
          onClick={() => onSave && onSave(state)}
          icon={<i className="ri-save-2-line"></i>}
          disabled={initialState === state}
        >
          Save Changes
        </Button>
      </div> */}
      <div className="flex flex-col items-center">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" type="droppableItem">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  // background: snapshot.isDraggingOver ? "lightblue" : "lightgrey",
                  width: "100%",
                }}
              >
                {state.collections.list.map((faqCollectionId, index) => (
                  <SingleEditableFAQCollection
                    faqCollectionId={faqCollectionId}
                    values={state}
                    dispatch={dispatch}
                    key={faqCollectionId}
                    index={index}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div style={{}} className="w-full">
          <AddFAQCollection dispatch={dispatch} />
        </div>
      </div>
    </div>
  );
};
export default EditFAQs;
