import { useEffect } from "react";

function getScrollParent(node) {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode);
  }
}

export function useHashFragment(offset = 0, trigger = true) {
  useEffect(() => {
    const scrollToHashElement = () => {
      const { hash } = window.location;
      const elementToScroll = document.getElementById(hash?.replace("#", ""));

      if (!elementToScroll) return;

      const scrollParent = getScrollParent(elementToScroll);
      const scrollPosition = elementToScroll.offsetTop - offset;
      elementToScroll.scrollIntoView({
        behavior: "smooth"
      });
      scrollParent.scrollTo({
        top: scrollPosition
        //   behavior: "smooth",
      });
      highlightElement(elementToScroll);
    };

    if (!trigger) return;

    scrollToHashElement();
    window.addEventListener("hashchange", scrollToHashElement);
    return window.removeEventListener("hashchange", scrollToHashElement);
  }, [offset, trigger]);
}

export function highlightElement(element) {
  let defaultBG = element.style.backgroundColor;
  let defaultTransition = element.style.transition;
  let defaultPadding = element.style.padding;

  element.style.transition = "background 0.5s, padding 0.5s";
  element.style.backgroundColor = "#FDFF47";
  element.style.padding = "10px";

  setTimeout(function() {
    element.style.backgroundColor = defaultBG;
    element.style.padding = defaultPadding;
    setTimeout(function() {
      element.style.transition = defaultTransition;
    }, 300);
  }, 300);
}
