import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDK } from "@sdk";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, List, Space } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { ChatListItem } from "components/modules/conversations/components/chat-list/chat-list-item";
import { ActivitiesViewer } from "components/modules/crm/activities/activities-viewer/activities-viewer";
import { ContactSelectorPill } from "components/modules/crm/contacts/components/contact-selector-pill/contact-selector-pill";
import dayjs from "dayjs";

import { UserPickerModal } from "components/modules/user-management/users/components/user-picker-modal/user-picker-modal";
import { UserPill } from "components/modules/user-management/users/components/user-pill/user-pill";
import { OpportunityCard } from "components/pages/opportunities/components/opportunity-card";
import { push } from "connected-react-router";
import {
  AddActivity,
  ContactPicker,
  ContactProfile,
  ConversationViewer,
  CreateOpportunityModal,
  NewMessageCreator,
} from "modal-registry";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllActivitiesOfContact } from "store/modules/activities/activities.helpers";
import { selectActivityContactId } from "store/modules/activities/activities.selectors";
import { selectCompanyById } from "store/modules/companies/companies.selectors";
import { loadContactsByCompanyId } from "store/modules/contacts/contacts.helpers";
import { selectContactsByCompanyId } from "store/modules/contacts/contacts.selectors";
import { setConversations } from "store/modules/conversations/conversations.slice";
import { loadContactOpportunities } from "store/modules/opportunities/opportunities.helpers";
import { selectOpportunitiesByContactId } from "store/modules/opportunities/opportunities.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { CompanyCard } from "../company-card/company-card";
import "./company-profile.scss";

export const CompanyProfileInner = ({ companyId }: { companyId: string }) => {
  const company = useSelector(selectCompanyById(companyId));

  const dispatch = useDispatch();

  const conversationSearchResult = useSDK(
    async (SDK) => {
      const results = await SDK.queryConversations({
        query: { contactId: companyId },
        options: {},
      });
      dispatch(setConversations(results.docs));
      return results;
    },
    [companyId],
    !companyId,
    {
      data: {
        docs: [],
      },
      isLoading: false,
      error: false,
    }
  );

  const {
    state: contactActivities,
    isLoading: isActivitiesLoading,
  } = useQueryWithStore(
    selectActivityContactId(companyId!),
    loadAllActivitiesOfContact(companyId!),
    [companyId],
    !companyId
  );

  const onSelectConversation = (conversationId: string) => {
    changePanelState(ConversationViewer, true, { conversationId });
    // dispatch(push(`/conversations/auto/${conversationId}`));
  };

  const { doAction: editCompany, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (edits) => SDK.companies.editById(companyId, edits),
      // successMessage: "Done",
      failureMessage: "Something went wrong",
      actionDependencies: [companyId],
    }),
    [companyId]
  );

  const { doAction: editContact } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (contactId, edits) => SDK.editContact(contactId, edits),
      // successMessage: "Done",
      failureMessage: "Something went wrong",
      actionDependencies: [],
    }),
    []
  );

  const [
    contactOwnerPickerModalState,
    setContactOwnerPickerModalState,
  ] = useState(false);

  const { changePanelState, triggerTempModal } = useModalPanels();

  const {
    state: { list: opportunitiesList },
    isLoading: isRecentOpportunityLoading,
    retry: reloadRecentOpportunities,
  } = useQueryWithStore(
    selectOpportunitiesByContactId(companyId!),
    loadContactOpportunities(companyId),
    [companyId],
    !companyId
  );

  const {
    state: companyContactsResult,
    isLoading: isContactsLoading,
    retry: reloadContacts,
  } = useQueryWithStore(
    selectContactsByCompanyId(companyId!),
    loadContactsByCompanyId(companyId!),
    [companyId],
    !companyId
  );

  if (!company) {
    return <></>;
  }
  return (
    <>
      <div className="flex flex-col w-full contact-profile bg-gray p-4">
        <div className="body flex flex-row flex-wrap justify-start">
          <div className="flex-1">
            <div className="header mb-4">
              <CompanyCard companyId={companyId} isEditable={true} />
            </div>
            {/* Top Bar | Action Bar */}
            <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-row flex px-10 py-2 max-w-screen-xl flex-1 ml-4 justify-between items-center">
              <div className="left">
                <div className="text text-gray-600 dark:text-gray-400 mode_transition">
                  Record created on{" "}
                  {dayjs(company.metaData.createdTime).format("LL")}
                  {/* "DD-MM-YYYY" */}
                </div>
              </div>
              <Space>
                {company?.owner && (
                  <div
                    className="flex flex-row justify-center items-center cursor-pointer"
                    onClick={() => setContactOwnerPickerModalState(true)}
                  >
                    <div className="text-xs">Account Owner</div>
                    <UserPill userId={company.owner} />
                  </div>
                )}
                {!company?.owner && (
                  <div className="flex flex-row justify-center items-center">
                    <Button
                      type="text"
                      icon={<i className="ri-user-star-line"></i>}
                      onClick={() => setContactOwnerPickerModalState(true)}
                    >
                      Assign Owner
                    </Button>
                  </div>
                )}
              </Space>
            </div>

            <div
              className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4"
              style={{ minHeight: 200 }}
            >
              <div className="card-header flex flex-row justify-between items-center">
                <div className="text-lg font-bold py-4">Contacts</div>
                <Button
                  type="text"
                  icon={<i className="ri-add-line"></i>}
                  onClick={() => {
                    triggerTempModal(ContactPicker, {
                      onContactSelected: (contactId: string) => {
                        editContact(contactId, {
                          companyId,
                        }).then((d) => {
                          reloadContacts();
                        });
                      },
                      hideOnSelected: true,
                    });
                  }}
                >
                  Add Contact to Account
                </Button>
              </div>

              <div className="contacts-list w-full mb-4">
                <List
                  itemLayout="horizontal"
                  dataSource={companyContactsResult.list || []}
                  loading={
                    isContactsLoading
                      ? {
                          indicator: <LoadingIndicatorWithoutSpin />,
                        }
                      : false
                  }
                  renderItem={(item) => (
                    <div
                      className="w-full border-b border-gray-200 dark:border-gray-800 p-4 cursor-pointer hover:bg-gray-200 dark:bg-gray-700 flex flex-row justify-between"
                      onClick={() => {
                        changePanelState(ContactProfile, true, {
                          contactId: item.id!,
                        });
                      }}
                    >
                      <ContactSelectorPill contactId={item.id!} />
                      <Button
                        type="text"
                        icon={<i className="ri-delete-bin-line"></i>}
                        onClick={(e) => {
                          editContact(item.id, {
                            companyId: null,
                          });
                          e.stopPropagation();
                        }}
                      ></Button>
                    </div>
                  )}
                  locale={{
                    emptyText: (
                      <EmptyData
                        text="No contacts to show"
                        icon="ri-user-line"
                      />
                    ),
                  }}
                />
              </div>
            </div>

            {false && (
              <>
                {/*  Summary Card */}
                <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-row flex justify-between items-center px-10 py-2 max-w-screen-xl flex-1 ml-4 mt-4">
                  <div className="flex flex-row justify-around items-center w-full">
                    <div
                      className="item flex flex-col items-center justify-center rounded-md p-2 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
                      style={{ width: 120 }}
                    >
                      <div className="font-bold text-lg">
                        {conversationSearchResult?.data?.totalDocs || 0}
                      </div>
                      <div className="text text-gray-600 dark:text-gray-400 mode_transition">
                        Conversations
                      </div>
                    </div>
                    <div
                      className="item flex flex-col items-center justify-center rounded-md p-2 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
                      onClick={() => {}}
                      style={{ width: 120 }}
                    >
                      <div className="font-bold text-lg">
                        {isActivitiesLoading && (
                          <div className="w-full flex flex-col justify-center items-center p-20">
                            <i className="ri-loader-5-line animate-spin text-2xl"></i>
                          </div>
                        )}
                        {!isActivitiesLoading &&
                          contactActivities?.list?.length}
                      </div>
                      <div className="text text-gray-600 dark:text-gray-400 mode_transition">
                        Activities
                      </div>
                    </div>

                    <div
                      className="item flex flex-col items-center justify-center rounded-md p-2 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
                      style={{ width: 120 }}
                    >
                      <div className="font-bold text-lg">1</div>
                      <div className="text text-gray-600 dark:text-gray-400 mode_transition">
                        Deals
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4"
                  style={{ minHeight: 200 }}
                >
                  <div className="card-header flex flex-row justify-between items-center">
                    <div className="text-lg font-bold py-4">
                      Recent Conversations
                    </div>
                    <Button
                      type="text"
                      icon={<i className="ri-add-line"></i>}
                      onClick={() => {
                        changePanelState(NewMessageCreator, true, {
                          contactId: companyId,
                        });
                      }}
                    >
                      Start new conversation
                    </Button>
                  </div>

                  <div className="conversation-list w-full">
                    <List
                      itemLayout="horizontal"
                      dataSource={conversationSearchResult.data.docs || []}
                      loading={
                        conversationSearchResult.isLoading
                          ? {
                              indicator: <LoadingIndicatorWithoutSpin />,
                            }
                          : false
                      }
                      renderItem={(item) => (
                        <div className="w-full border border-gray-200 dark:border-gray-800">
                          <ChatListItem
                            onConversationSelected={onSelectConversation}
                            conversationId={item.id!}
                          />
                        </div>
                      )}
                      locale={{
                        emptyText: (
                          <EmptyData
                            text="No conversations to show"
                            icon="ri-chat-4-line"
                          />
                        ),
                      }}
                    />
                  </div>
                </div>
                <div
                  className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4"
                  style={{ minHeight: 200 }}
                >
                  <div className="card-header flex flex-row justify-between items-center">
                    <div className="text-lg font-bold py-4">Recent Deals</div>
                    <Button
                      type="text"
                      icon={<i className="ri-add-line"></i>}
                      onClick={() => {
                        changePanelState(CreateOpportunityModal, true, {
                          initialData: {
                            contactId: companyId,
                          },
                        });
                      }}
                    >
                      Add a Deal
                    </Button>
                  </div>
                  <div className="w-full">
                    <List
                      itemLayout="horizontal"
                      dataSource={opportunitiesList || []}
                      loading={
                        isRecentOpportunityLoading
                          ? {
                              indicator: <LoadingIndicatorWithoutSpin />,
                            }
                          : false
                      }
                      renderItem={(item) => (
                        <div className="hover:bg-blue-100 dark:hover:bg-gray-800 cursor-pointer rounded-lg border-1 border-gray-200">
                          <OpportunityCard
                            opportunityId={item.id!}
                            onClick={(opportunityId) => {
                              dispatch(
                                push(
                                  `/opportunities?opportunity=${opportunityId}`
                                )
                              );
                            }}
                          />
                        </div>
                      )}
                      locale={{
                        emptyText: (
                          <EmptyData
                            text="No opportunities to show"
                            icon="ri-search-line"
                          />
                        ),
                      }}
                    />
                  </div>
                </div>
                <div
                  className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4"
                  style={{ minHeight: 300 }}
                >
                  <div className="card-header flex flex-row justify-between items-center">
                    <div className="text-lg font-bold py-4">
                      Recent Activities
                    </div>
                    <Button
                      type="text"
                      icon={<i className="ri-add-line"></i>}
                      onClick={() => {
                        changePanelState(AddActivity, true, {
                          contactId: companyId,
                        });
                      }}
                    >
                      Add new activity
                    </Button>
                  </div>
                  <div className="w-full mt-8">
                    <ActivitiesViewer contactId={companyId} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <UserPickerModal
        visible={contactOwnerPickerModalState}
        onChangeVisibility={setContactOwnerPickerModalState}
        onUserSelected={async (userId) => {
          editCompany({
            owner: userId,
          });
        }}
        title={
          <ModalTitle
            title="Pick a user to assign"
            icon={<i className="ri-account-circle-line"></i>}
          />
        }
      />
    </>
  );
};
