import dayjs from "dayjs";

import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { message } from "antd";
import { CompanyAvatar } from "components/common/avatar/company-avatar";
import { uploadImageWithValidation } from "components/modules/file-management/upload-image-with-validation";
import React, { useState } from "react";
import { loadCompanyById } from "store/modules/companies/companies.helpers";
import { selectCompanyById } from "store/modules/companies/companies.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { CompanyLogoEditor } from "../company-logo-editor/company-logo-editor";
import "./company-card.scss";

export const CompanyCard = ({
  companyId,
  isEditable,
}: {
  companyId: string;
  isEditable?: boolean;
}) => {
  const { state: company } = useQueryWithStore(
    selectCompanyById(companyId),
    loadCompanyById(companyId)(),
    [companyId],
    !companyId,
  );

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const changeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0]!;
    if (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }
      if (isJpgOrPng && isLt2M) {
        setSelectedFile(file);
        uploadLogo(file);
      }
    }
  };

  const { doAction: uploadLogo, isProcessing: isUploading } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (file) =>
          SDK.uploadFile(file, {
            type: "COMPANY",
            entityId: companyId,
          })
            .then((fileRecord) => {
              setSelectedFile(undefined);
              return SDK.companies.editById(companyId, {
                data: {
                  logo: fileRecord.url,
                },
              });
            })
            .catch((e) => {
              setSelectedFile(undefined);
              throw e;
            }),
        successMessage: "Logo has been updated",
        failureMessage: "Something went wrong",
      }),
      [companyId],
    );

  if (!company) {
    return <></>;
  }

  if (isEditable) {
    return (
      <>
        <div className="flex flex-col w-full company-profile-card">
          <div className="avatar-section flex flex-row justify-center items-center mt-8">
            <div className="flex flex-col justify-center items-center">
              <div className="edit-user-avatar">
                <CompanyLogoEditor
                  selectedFile={selectedFile}
                  onChange={(file) => {
                    setSelectedFile(file);
                    uploadImageWithValidation(
                      companyId,
                      file,
                      uploadLogo,
                      setSelectedFile,
                    );
                  }}
                  isUploading={isUploading}
                  companyId={companyId}
                />
              </div>
              <div className="font-bold text-2xl mt-12">
                {company?.data.name}
              </div>
            </div>
          </div>
          <div className="info-section">
            <div className="flex flex-row justify-center items-center px-5">
              <div className="text text-gray-600 dark:text-gray-400 mode_transition">
                Record created on{" "}
                {dayjs(company.metaData.createdTime).format("LL")}
                {/* "DD-MM-YYYY" */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col w-full company-profile-card">
        <div className="avatar-section flex flex-row justify-center items-center mt-2">
          <div className="flex flex-col justify-center items-center">
            <div className="user-header flex flex-row justify-center items-center mb-2 mt-2">
              <CompanyAvatar companyId={companyId} size={80} />
            </div>
            <div className="font-bold text-2xl mt-2">{company?.data.name}</div>
          </div>
        </div>
        <div className="info-section">
          <div className="flex flex-row justify-center items-center px-5">
            <div className="text text-gray-600 dark:text-gray-400 mode_transition">
              Record created on{" "}
              {dayjs(company.metaData.createdTime).format("LL")}
              {/* "DD-MM-YYYY" */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
