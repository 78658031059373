import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";

export const useSelectorWithMemoize = <RootState, T extends unknown>(
  selectorFn: () => (state: RootState) => T,
  deps: any[],
  useShallowEqualCheck?: boolean
) => {
  const memoizedSelector = useMemo(() => {
    return selectorFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
  const data = useSelector(
    memoizedSelector,
    useShallowEqualCheck ? shallowEqual : undefined
  );
  return data;
};
