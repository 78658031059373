import { iCampaign } from "@sdk/campaigns/campaigns-model";
import { iEmailConnectionData } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  Progress,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { PillSelector } from "components/common/pill-selector/pill-selector";
import { ConnectionSelector } from "components/modules/conversations/components/connection-selector/connection-selector";
import { getContactName } from "components/modules/crm/contacts/helpers/get-contact-name";
import { push } from "connected-react-router";
import { DarkModeBg } from "dark-mode-bg";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "react-redux";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { loadAllContactLists } from "store/modules/contact-lists/contact-lists.helpers";
import { selectAllContactLists } from "store/modules/contact-lists/contact-lists.selectors";
import { loadContactsQuery } from "store/modules/contacts/contacts.helpers";
import { selectContactsQuery } from "store/modules/contacts/contacts.selectors";
import { useQueryWithStore } from "store/store.hooks";

const CampaignTypeOptions = [
  {
    value: "EMAIL",
    label: "Email",
    icon: <i className="ri-mail-line"></i>,
  },
  {
    value: "SMS",
    label: "SMS",
    icon: <i className="ri-message-3-line"></i>,
    disabled: true,
  },
];

export const EmailEditorTypeOptions = [
  {
    value: "SIMPLE_TEXT_EMAIL",
    label: "Plain Text",
    icon: <i className="ri-text"></i>,
  },
  {
    value: "SIMPLE_EMAIL",
    label: "Simple Newsletter",
    icon: <i className="ri-stack-line"></i>,
  },
  {
    value: "ADVANCED_EMAIL_EDITOR",
    label: "Advanced Email Editor",
    icon: <i className="ri-layout-2-line"></i>,
  },
  {
    value: "HTML",
    label: "Code Your Own",
    icon: <i className="ri-code-line"></i>,
  },
];
export const AddCampaignModal = ({
  visible,
  onChangeVisibility,
  onCreated,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated: (campaign: iCampaign) => any;
}) => {
  const [form] = Form.useForm();

  const {
    doAction: onCreateCampaign,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.campaigns
          .create({
            ...values,
          })
          .then((res) => {
            onCreated(res);
            onChangeVisibility(false);
            form.resetFields();
          }),
      successMessage: "Campaign has been created",
      failureMessage: "Something went wrong",
    }),
    [form, onChangeVisibility, onCreated],
  );

  const { state: contactLists, retry: reload } = useQueryWithStore(
    selectAllContactLists,
    loadAllContactLists,
  );

  const initialValue = useMemo(
    () => ({
      type: "EMAIL",
      emailEditorType: "SIMPLE_EMAIL",
    }),
    [],
  );

  useEffect(() => {
    setCurrentStep(1);
    form.resetFields();
  }, [form, visible]);

  const [currentStep, setCurrentStep] = useState(1);

  const moveToStep = useCallback(
    async (step: number) => {
      try {
        await form.validateFields();
        setCurrentStep(step);
      } catch (e) {
        message.error("Please check your input");
      }
    },
    [form],
  );

  const createCampaign = useCallback(async () => {
    try {
      await form.validateFields();
      const {
        label,
        type,
        list,
        connectionId,
        senderName,
        sendingEmail,
        emailEditorType,
        subjectLine,
        previewLine,
      } = form.getFieldsValue(true);
      await onCreateCampaign({
        label,
        status: "DRAFT",
        type,
        to: {
          type: "LIST",
          listId: list,
        },
        sender: {
          connectionId,
          name: senderName,
          email: sendingEmail,
        },
        data: {
          body: "",
          emailEditorType,
          subjectLine,
          previewLine,
        },
      });
    } catch (e) {
      message.error("Please check your input");
    }
  }, [form, onCreateCampaign]);

  const [selectedConnectionId, setSelectedConnectionId] = useState("");
  const [selectedListId, setSelectedListId] = useState("");

  const store = useStore();

  useEffect(() => {
    if (selectedConnectionId) {
      const connectionData = selectConnectionById(selectedConnectionId)(
        store.getState(),
      ).data as iEmailConnectionData;

      const senderName = connectionData.senderName;
      const sendingEmail = connectionData.customDomain?.email;
      form.setFieldsValue({
        sendingEmail,
        senderName,
      });
    }
  }, [form, selectedConnectionId, store]);

  const query = useMemo(() => {
    return {
      query: {
        "marketingLists.id": { $in: selectedListId },
        "data.primaryEmail": { $exists: true, $nin: [null, ""] },
      },
      options: {
        sortBy: ["metaData.createdTime"],
        page: 1,
        limit: 20,
        sort: "metaData.createdTime",
      },
    };
  }, [selectedListId]);

  const { state: contactsQuery, isLoading: loadingListData } =
    useQueryWithStore(
      selectContactsQuery(JSON.stringify(query)),
      loadContactsQuery(query, JSON.stringify(query)),
      [query],
      !selectedListId,
    );

  const goToSelectedList = useCallback(() => {
    const page = `/customers?contact-filter=((key:marketingList,operator:IS_IN,operatorConfig:(value:${selectedListId})))`;
    store.dispatch(push(page));
  }, [selectedListId, store]);

  return (
    <Modal
      title={
        <div>
          <ModalTitle
            icon={<i className="ri-mail-send-fill"></i>}
            title={(() => {
              if (currentStep === 2) {
                return "Select Audience & Sender";
              }

              if (currentStep === 3) {
                return "Write Subject & Preview Text";
              }
              return "Create a New Campaign";
            })()}
          />
          <Progress
            percent={currentStep * 25}
            status="active"
            showInfo={false}
            className="w-full mt-4"
          />
        </div>
      }
      open={visible}
      footer={
        <div className="flex flex-row justify-end items-center">
          <Space>
            {currentStep === 1 && (
              <>
                <Button
                  type="primary"
                  onClick={() => moveToStep(2)}
                  icon={<i className="ri-arrow-right-line"></i>}
                >
                  Select Audience
                </Button>
              </>
            )}
            {currentStep === 2 && (
              <>
                <Button
                  type="text"
                  onClick={() => setCurrentStep(1)}
                  icon={<i className="ri-arrow-left-line"></i>}
                >
                  Back
                </Button>
                <Button
                  type="primary"
                  onClick={() => moveToStep(3)}
                  icon={<i className="ri-arrow-right-line"></i>}
                >
                  Write Subject
                </Button>
              </>
            )}
            {currentStep === 3 && (
              <>
                <Button
                  type="text"
                  onClick={() => setCurrentStep(2)}
                  icon={<i className="ri-arrow-left-line"></i>}
                >
                  Back
                </Button>
                <Button
                  type="primary"
                  onClick={createCampaign}
                  icon={<i className="ri-arrow-right-line"></i>}
                  loading={isProcessing}
                >
                  Design Email
                </Button>
              </>
            )}
          </Space>
        </div>
      }
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Start Creating Campaign"
      data-click-context="Create New Campaign"
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValue}
        hideRequiredMark={true}
        className="bold-form-labels"
        preserve
        onValuesChange={() => {
          // const isFieldsTouched = form.isFieldsTouched();
          // // console.log("isFieldsTouched", isFieldsTouched);
          // setFormTouched(isFieldsTouched);
          const marketingListId = form.getFieldValue(["list"]);
          // console.log("marketingListId", marketingListId);
          if (selectedListId !== marketingListId) {
            setSelectedListId(marketingListId);
          }
          const connectionId = form.getFieldValue(["connectionId"]);
          if (selectedConnectionId !== connectionId) {
            setSelectedConnectionId(connectionId);
          }
        }}
      >
        {currentStep === 1 && (
          <>
            <Form.Item
              name="label"
              label="Name Your Campaign"
              rules={[
                {
                  required: true,
                  message: "Please enter a campaign name",
                },
              ]}
            >
              <Input placeholder="Label" />
            </Form.Item>
            <Form.Item
              name="type"
              label="Campaign Medium"
              rules={[
                {
                  required: true,
                  message: "Please select a campaign Medium",
                },
              ]}
            >
              <PillSelector options={CampaignTypeOptions} />
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const selectedOption = getFieldValue(["type"]);
                if (selectedOption === "EMAIL") {
                  return (
                    <>
                      <Form.Item
                        name="emailEditorType"
                        label="Email Editor Type"
                        rules={[
                          {
                            required: true,
                            message: "Please select a email editor",
                          },
                        ]}
                      >
                        <PillSelector options={EmailEditorTypeOptions} />
                      </Form.Item>
                      <Alert
                        message="If you are not sure, continue with Simple Newsletter"
                        type="info"
                        showIcon
                      />
                    </>
                  );
                }
                return <></>;
              }}
            </Form.Item>
          </>
        )}
        {currentStep === 2 && (
          <>
            <Form.Item
              name="list"
              label="Marketing List"
              rules={[
                {
                  required: true,
                  message: "Please select a marketing list",
                },
              ]}
              help={
                <div className="text-gray-600 text-sm">
                  Select the list you want to send this campaign to
                </div>
              }
            >
              <Select>
                {contactLists.map((list) => (
                  <Select.Option key={list.id} value={list.id!}>
                    {list.data.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {selectedListId && (
              <Spin
                spinning={loadingListData}
                indicator={<LoadingIndicatorWithSpin />}
              >
                <div className="text-gray-600">
                  {contactsQuery.totalItems || 0} Customer(s) found{" "}
                  {(contactsQuery?.totalItems || 0) > 0 && (
                    <>
                      (Eg:{" "}
                      {contactsQuery.list
                        .slice(0, 5)
                        .map(
                          (contact) =>
                            `${getContactName(contact)} - ${
                              contact.data.primaryEmail
                            }`,
                        )
                        .join(", ")}
                      )
                    </>
                  )}
                  <span
                    className="cursor-pointer"
                    onClick={() => goToSelectedList()}
                  >
                    - View All
                  </span>
                </div>
              </Spin>
            )}

            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const selectedOption = getFieldValue(["type"]);
                if (selectedOption === "EMAIL") {
                  return (
                    <>
                      <Form.Item
                        name="connectionId"
                        label="Connection to Use"
                        rules={[
                          {
                            required: true,
                            message: "Please select a connection",
                          },
                        ]}
                        help={
                          <div className="text-gray-600 text-sm">
                            Select the email connection that will be used to
                            deliver the email.
                          </div>
                        }
                      >
                        <ConnectionSelector
                          connectionFilter={(connection) =>
                            connection.type === "EMAIL"
                          }
                          autoSelectFirstOption
                        />
                      </Form.Item>

                      <Form.Item
                        name="senderName"
                        label="Sender Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a sender name",
                          },
                        ]}
                        help={
                          <div className="text-gray-600 text-sm">
                            Choose a name this list will recognize instantly
                            like the company’s name or the account manager’s
                            name.
                          </div>
                        }
                      >
                        <Input type="eg: Click Connector" />
                      </Form.Item>

                      <Form.Item
                        name="sendingEmail"
                        label="Sender's Email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a sender email",
                          },
                        ]}
                        help={
                          <div className="text-gray-600 text-sm">
                            This Email will be visible to the receiver while the
                            email in “Connection to Use” is on the server side
                            and will not be seen by the receiver.
                          </div>
                        }
                      >
                        <Input type="eg: info@clickconnector.com" />
                      </Form.Item>
                    </>
                  );
                }
                return <></>;
              }}
            </Form.Item>
          </>
        )}
        {currentStep === 3 && (
          <>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const selectedOption = getFieldValue(["type"]);
                const subjectLine = getFieldValue(["subjectLine"]) || "";
                const previewLine = getFieldValue(["previewLine"]) || "";
                if (selectedOption === "EMAIL") {
                  return (
                    <>
                      <Form.Item
                        name="subjectLine"
                        className="full-w-label"
                        label={
                          <div className="flex flex-row justify-between items-center w-full">
                            <div className="">Subject</div>
                            <div className="text-gray-600 flex flex-row justify-end items-center font-normal text-xs">
                              {subjectLine.length} Characters
                            </div>
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message:
                              "Please enter a subject line to continue. (You can edit this later)",
                          },
                        ]}
                        help={
                          <>
                            <div className="text-gray-600 flex flex-row justify-start items-center text-sm">
                              A great subject line gives your audience a reason
                              to open your email!
                            </div>
                            <div className=" text-gray-600 text-sm font-semibold">
                              Recommended Best Practices:
                            </div>
                            <ul className="list-disc ml-8 text-gray-600 mb-8 text-sm">
                              <li>
                                Avoid using more than 60 characters or 9 words
                              </li>
                              <li>Avoid using more than 3 punctuation marks</li>
                            </ul>
                          </>
                        }
                      >
                        <Input
                          placeholder="Enter a subject line"
                          spellCheck={"true"}
                        />
                      </Form.Item>

                      <Form.Item
                        name="previewLine"
                        className="full-w-label"
                        label={
                          <div className="flex flex-row justify-between items-center w-full">
                            <div className="">Preview Text</div>
                            <div className="text-gray-600 flex flex-row justify-end items-center font-normal text-xs">
                              {previewLine.length} Characters
                            </div>
                          </div>
                        }
                        rules={[]}
                        help={
                          <div className="text-gray-600 text-sm">
                            Preview Text appears in the inbox, underneath the
                            Subject Line and provides a sneak peak into the
                            email.
                          </div>
                        }
                      >
                        <Input spellCheck={"true"} />
                      </Form.Item>
                      <div className="mb-12"></div>
                    </>
                  );
                }
                return <></>;
              }}
            </Form.Item>
          </>
        )}
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
