import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";

import { useMemo, useReducer } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUserSignatures } from "store/modules/users/users.selectors";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { EmailSignatureManager } from "./email-signature-manager";
import { reducer } from "./signature-manager-state-manager";

export const EmailSignatureManagerModal = ({
  visible,
  onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const signatures = useSelector(selectCurrentUserSignatures);
  const defaultState = useMemo(() => {
    return {
      signatures: signatures
    };
  }, [signatures]);

  const {
    doAction: updateSignatures,
    isProcessing,
    response
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => data =>
        SDK.editCurrentUser({
          data: {
            emailSignatures: data
          }
        } as any),
      // successMessage: "Presets have been saved",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffectWhen(
    () => {
      updateSignatures(state.signatures);
    },
    [state, updateSignatures],
    [state]
  );

  return (<>
    <Modal
      title={
        <ModalTitle
          title="My Email Signatures"
          icon={<i className="ri-ball-pen-line"></i>}
        />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      footer={null}
      width={660}
      data-click-context="Manage Signatures Modal"
    >
      <div
        className="content flex-1 overflow-auto"
        style={{ minHeight: 550 }}
      >
        <EmailSignatureManager state={state} dispatch={dispatch} />
      </div>
      <DarkModeBg />
    </Modal>
  </>);
};
