import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import { Button, Space, Table } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import {
  DraggableBodyRowGenerator,
  DraggableContainerGenerator,
  DragHandle,
} from "components/common/sortable-table/sortable-table-elements";
import { useSortableTable } from "components/common/sortable-table/use-sortable-table";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { useSimpleState } from "utils/hooks/use-simple-state";

import { ActionEditorModal } from "./action-editor-modal";
import { ActionLabels } from "./actions";
import { iAction } from "./models";

export const ActionList = ({
  value,
  onChange,
}: {
  value?: iAction[];
  onChange?: (actions: iAction[]) => any;
}) => {
  const tableData = useMemo(() => value || [], [value]);

  const { state, setState, onSortEnd } = useSortableTable(tableData);

  useEffectWhen(
    () => {
      if (onChange) {
        onChange(state);
      }
    },
    [onChange, state],
    [state]
  );

  useEffect(() => {
    setState(tableData);
  }, [setState, tableData]);

  const [actionEditorState, setActionEditorState] = useSimpleState({
    visibility: false,
    action: undefined as iAction | undefined,
  });

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (data, record: iAction, index) =>
        `${ActionLabels[record.action] || "Unknown Action"}`,
      className: "drag-visible",
    },
    {
      title: "",
      dataIndex: "description",
      className: "drag-visible",
      render: (data, record: iAction, index) => {
        return ``;
      },
    },
    {
      title: "",
      dataIndex: "remove",
      width: 100,
      render: (data, record, index) => (
        <Space>
          <Button
            icon={<i className="ri-edit-line" />}
            type="link"
            onClick={() => {
              setActionEditorState({
                visibility: true,
                action: record,
              });
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            type="link"
            onClick={() => {
              setState(_.without(state, record));
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className="w-full">
      {state.length > 0 && (
        <Table
          columns={columns}
          dataSource={state}
          pagination={false}
          showHeader={false}
          rowKey="id"
          components={{
            body: {
              wrapper: DraggableContainerGenerator(onSortEnd),
              row: DraggableBodyRowGenerator(state),
            },
          }}
          locale={{
            emptyText: (
              <EmptyData
                text="No Actions have been added"
                icon="ri-route-line"
              />
            ),
          }}
          className="w-full"
        />
      )}

      <div className="flex flex-row justify-center items-center my-8">
        <Button
          type="dashed"
          icon={<i className="ri-add-line"></i>}
          onClick={() =>
            setActionEditorState({ visibility: true, action: null as any })
          }
          className="font-bold"
        >
          Add Further Action
        </Button>
      </div>

      <ActionEditorModal
        visible={actionEditorState.visibility}
        onChangeVisibility={(visibility) =>
          setActionEditorState({ visibility })
        }
        initialValue={actionEditorState.action}
        onSave={(action) => {
          setState((actions) => {
            if (_.find(actions, { id: action.id })) {
              return actions.map((item) => {
                if (item.id === action.id) {
                  return action;
                }
                return item;
              });
            }
            return [...actions, action];
          });
        }}
      />
    </div>
  );
};
