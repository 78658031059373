import { EntityServices } from "@sdk/utils/entity.service";
import {
  PaginatedOptions,
  PaginatedResults,
} from "@sdk/utils/paginated-results";
import axios, { AxiosResponse } from "axios";
import { UserTracker } from "user-tracker";
import {
  iAutomationContact,
  iAutomationWorkflow,
} from "./automation-workflows-model";

export class AutomationWorkflowsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  automationWorkflows = Object.assign(
    new EntityServices<iAutomationWorkflow>(
      this.config,
      "automation-workflows",
      {
        onCreate: () => {
          UserTracker.track("WORKFLOWS - Create", {});
        },
        onDelete: () => {
          UserTracker.track("WORKFLOWS - Delete", {});
        },
      },
    ),
    {
      queryAutomationWorkflowContacts: async (
        query: any,
        options: PaginatedOptions,
      ) => {
        const results: AxiosResponse<PaginatedResults<iAutomationContact>> =
          await axios.post(
            this.config.basePath + `/automation-workflows/query-contacts`,
            { query, options },
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
      addContactToAutomation: async (
        workflowId: string,
        req: { contactIds: string[]; query?: any; skipSafeCheck?: boolean },
      ) => {
        UserTracker.track("WORKFLOWS - Add Contact to Automation", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/automation-workflows/${workflowId}/contacts`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      stopAutomationInstance: async (
        workflowId: string,
        instanceId: string,
      ) => {
        UserTracker.track("WORKFLOWS - Stop Automation Instance", {});
        const results: AxiosResponse<any> = await axios.delete(
          this.config.basePath +
            `/automation-workflows/${workflowId}/instances/${instanceId}`,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
