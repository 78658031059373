import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDK } from "@sdk";
import { Button, Input, Modal } from "antd";
import { Avatar } from "components/common/avatar/avatar";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { AddCompany } from "modal-registry";
import { useCallback, useState } from "react";
import { CompanySearchQueryBuilder } from "../../helpers/company-search-query-builder";
import "./company-picker-modal.scss";

export const CompanyPickerModal = ({
  visible,
  onChangeVisibility,
  onCompanySelected: _onCompanySelected,
  title,
  hideAdd,
  hideOnSelected
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCompanySelected: (companyId: string) => any;
  title?: JSX.Element;
  hideAdd?: boolean;
  hideOnSelected?: boolean;
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const { data: originalData, isLoading } = useSDK(
    async SDK =>
      SDK.companies.query({
        query: CompanySearchQueryBuilder(searchQuery),
        options: {
          offset: 0,
          page: 1,
          limit: 20
        }
      }),
    [searchQuery],
    false,
    {}
  );

  const { triggerTempModal } = useModalPanels();

  const onCompanySelected = useCallback(
    (itemId: string) => {
      _onCompanySelected(itemId);
      if (hideOnSelected) {
        onChangeVisibility(false);
      }
    },
    [_onCompanySelected, hideOnSelected, onChangeVisibility]
  );

  return (<>
    <Modal
      title={
        title || (
          <ModalTitle
            title="Select a Company"
            icon={<i className="ri-group-line"></i>}
          />
        )
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save"
      data-click-context="Company Picker Modal"
    >
      <div className="flex flex-col w-full company-picker">
        <div className="search-container flex flex-row">
          <Input.Search
            placeholder="Search..."
            className="rounded-md"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />

          <Button
            type="text"
            onClick={() =>
              triggerTempModal(AddCompany, {
                onCreated: (companyId: string) => {
                  onCompanySelected(companyId);
                }
              })
            }
            className="add-button cursor-pointer hover:text-blue-600 flex flex-row justify-center items-center"
          >
            <i className="ri-add-line"></i>
          </Button>
        </div>
        <div className="companies-container mt-8">
          {isLoading && <div>Loading</div>}

          {!isLoading &&
            originalData.docs &&
            originalData.docs.map(company => (
              <div
                className="flex flex-row items-center p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-900 border-b border-gray-200 dark:border-gray-800 mode_transition"
                onClick={() => onCompanySelected(company.id!)}
                key={company.id}
              >
                <div className="avatar-container">
                  <Avatar
                    name={company.data.name}
                    avatarUrl={company.data.logo}
                  />
                </div>
                <div className="texts flex flex-col flex-1 ml-2">
                  <div className="name font-bold">{company.data.name}</div>
                  <div className="email">
                    {company.data.tags} {company.data.emailDomain}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <DarkModeBg />
    </Modal>
  </>);
};
