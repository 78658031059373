export const MobileMenu = ({
  isVisible,
  onHideMenu,
  children
}: {
  isVisible: boolean;
  onHideMenu: () => any;
  children;
}) => {
  return (
    <div
      className={
        isVisible
          ? "fixed w-full h-full top-0 left-0 bg-gray z-50 flex flex-col justify-center items-center"
          : "hidden"
      }
    >
      <div
        className="absolute top-0 right-0  z-50 px-8 py-8 cursor-pointer"
        onClick={() => onHideMenu()}
      >
        <svg
          className="h-8 w-8 text-gray-600"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </div>
      {children}
    </div>
  );
};
