import { Apps } from "@sdk/roles-and-permissions/roles-and-permissions";
import { HQLayout } from "components/pages/hq/layout/hq-layout/hq-layout.template";
import { Playground } from "components/pages/playground/playground";
import { ComponentType, createElement, lazy } from "react";
import { SetLocalStorageItem } from "utils/hooks/use-local-storage-store";
import { FullScreenLayout } from "./components/layout/full-screen-layout/full-screen-layout.template";
import { MainLayout } from "./components/layout/main-layout/main-layout.template";
import { GenerateNormalizedRoutes } from "./route-helper";

// Uptime Monitor Pages

export type PreloadableComponent<T extends ComponentType<any>> = T & {
  preload: () => Promise<void>;
};

// https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("page-has-been-force-refreshed") || "false",
    );
    try {
      const component = await componentImport();
      SetLocalStorageItem("page-has-been-force-refreshed", "false");
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        SetLocalStorageItem("page-has-been-force-refreshed", "true");
        return window.location.reload();
      }
      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

const lazyWithPreload = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
) => {
  let LoadedComponent: T | undefined;
  let factoryPromise: Promise<void> | undefined;

  const LazyComponent = lazyWithRetry(factory);

  const loadComponent = () =>
    factory().then((module) => {
      LoadedComponent = module.default;
    });

  const Component = ((props) =>
    createElement(
      LoadedComponent || LazyComponent,
      props,
    )) as PreloadableComponent<T>;

  Component.preload = () => factoryPromise || loadComponent();

  return Component;
};

const LoginPage = lazyWithPreload(
  () => import("./components/pages/login/login.page"),
);

const RegisterWithStorePage = lazyWithRetry(
  () =>
    import("./components/pages/register-with-store/register-with-store.page"),
);

const RegisterWithAppSumoPage = lazyWithRetry(
  () =>
    import(
      "./components/pages/register-with-appsumo/register-with-appsumo.page"
    ),
);

const ExternalAuthConnectionPage = lazyWithPreload(
  () =>
    import(
      "./components/pages/external-auth-connections/external-auth-connections"
    ),
);

const RegisterPage = lazyWithPreload(
  () => import("./components/pages/register/register.page"),
);
const ResetPasswordPage = lazyWithPreload(
  () => import("./components/pages/reset-password/reset-password.page"),
);
const AcceptInvitationPage = lazyWithPreload(
  () => import("./components/pages/accept-invitation/accept-invitation.page"),
);

const Conversations = lazyWithRetry(
  () => import("./components/pages/conversations/conversations.page"),
);

const LiveViewPage = lazyWithRetry(
  () => import("components/pages/live-view/live-view.page"),
);

const HomePage = lazyWithRetry(() => import("components/pages/home/home.page"));

const Opportunities = lazyWithRetry(
  () => import("./components/pages/opportunities/opportunities.page"),
);

const PipelinesPage = lazyWithRetry(
  () => import("./components/pages/pipelines/pipelines.page"),
);
const ConfigurationsPage = lazyWithRetry(
  () => import("./components/pages/configurations/configurations.page"),
);
const ContactPage = lazyWithRetry(
  () => import("./components/pages/contacts/contacts.page"),
);

const CompaniesPage = lazyWithRetry(
  () => import("./components/pages/companies/companies.page"),
);

const ReportsPage = lazyWithRetry(
  () => import("./components/pages/reports/reports.page"),
);

const KnowledgeBasePage = lazyWithRetry(
  () => import("./components/pages/knowledge-base/knowledge-base.page"),
);

const ChatBotsPage = lazyWithRetry(
  () => import("./components/pages/chat-bots/chat-bots.page"),
);

const DynamicFormsPage = lazyWithRetry(
  () => import("./components/pages/dynamic-forms/dynamic-forms.page"),
);

const CallBackRequestsPage = lazyWithRetry(
  () => import("./components/pages/call-back-requests/call-back-requests.page"),
);

const CallLogsPage = lazyWithRetry(
  () => import("./components/pages/call-logs-page/call-logs.page"),
);

const SequencesPage = lazyWithRetry(
  () => import("./components/pages/sequences-page/sequences.page"),
);

const KnowledgeKitPage = lazyWithRetry(
  () => import("./components/pages/knowledge-kit-page/knowledge-kit.page"),
);

const HolidayQuotesPage = lazyWithRetry(
  () => import("./components/pages/holiday-quotes/holiday-quotes.page"),
);

const HQOrganizationsPage = lazyWithRetry(
  () => import("./components/pages/hq/organizations/organizations"),
);
const HQUsersPage = lazyWithRetry(
  () => import("./components/pages/hq/organizations/users"),
);
const HQConnectionsPage = lazyWithRetry(
  () => import("./components/pages/hq/organizations/connections"),
);
const HQChangeLogsPage = lazyWithRetry(
  () => import("./components/pages/hq/change-logs/change-logs"),
);
const HQfbEventLogBrowser = lazyWithRetry(
  () =>
    import("./components/pages/hq/fb-event-log-browser/fb-event-log-browser"),
);
const HQEmailEventLogBrowser = lazyWithRetry(
  () =>
    import(
      "./components/pages/hq/email-event-log-browser/email-event-log-browser"
    ),
);
const HQLoginPage = lazyWithRetry(
  () => import("./components/pages/hq/hq-login.page"),
);

const RoadMapPublicPage = lazyWithRetry(
  () =>
    import(
      "./components/feature-requests/components/road-map/road-map-public-page"
    ),
);

export const AuthenticatedRoutes = GenerateNormalizedRoutes([
  {
    path: "home",
    component: HomePage,
    label: "Home",
    layout: MainLayout,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    path: "website-live-view",
    component: LiveViewPage,
    label: "Live View",
    layout: MainLayout,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    path: "conversations",
    component: Conversations,
    label: "Conversations",
    layout: MainLayout,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    path: "customers",
    component: ContactPage,
    label: "Customers",
    layout: MainLayout,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    path: "companies",
    component: CompaniesPage,
    label: "Companies",
    layout: MainLayout,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    path: "opportunities",
    component: Opportunities,
    label: "Opportunities",
    layout: MainLayout,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    path: "pipelines",
    component: PipelinesPage,
    label: "Pipelines",
    layout: MainLayout,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    path: "reports",
    component: ReportsPage,
    label: "Reports",
    layout: MainLayout,
    requiredPermission: [`${Apps.REPORTS}:*`],
  },

  {
    path: "chat-bots",
    component: ChatBotsPage,
    label: "ChatBots",
    layout: MainLayout,
  },
  {
    path: "configurations",
    component: ConfigurationsPage,
    label: "Configurations",
    layout: MainLayout,
    requiredPermission: [`${Apps.ORGANIZATION_MANAGEMENT}:*`],
  },
  {
    path: "forms",
    component: DynamicFormsPage,
    label: "Forms",
    layout: MainLayout,
  },
  {
    path: "call-back-requests",
    component: CallBackRequestsPage,
    label: "Call Back Requests",
    layout: MainLayout,
  },
  {
    path: "call-logs",
    component: CallLogsPage,
    label: "Call Logs",
    layout: MainLayout,
  },
  {
    path: "sequences",
    component: SequencesPage,
    label: "Sequences",
    layout: MainLayout,
  },
  {
    path: "knowledge-kit",
    component: KnowledgeKitPage,
    label: "Knowledge Kit",
    layout: MainLayout,
  },
  {
    path: "holiday-quotes",
    component: HolidayQuotesPage,
    label: "Holiday Quotes",
    layout: MainLayout,
  },
  {
    path: "knowledge-base",
    component: KnowledgeBasePage,
    label: "Dynamic Form",
    layout: MainLayout,
  },
  {
    path: "external-auth-connector",
    layout: FullScreenLayout,
    component: ExternalAuthConnectionPage,
  },
  // Common For Auth and Non Auth
  {
    path: "playground",
    component: Playground,
    label: "Configurations",
    layout: FullScreenLayout,
  },
  {
    path: "accept-invitation",
    layout: FullScreenLayout,
    component: AcceptInvitationPage,
  },
  {
    path: "register-with-store",
    layout: FullScreenLayout,
    component: RegisterWithStorePage,
  },
  {
    path: "register-with-appsumo",
    layout: FullScreenLayout,
    component: RegisterWithAppSumoPage,
  },
  {
    path: "roadmap",
    layout: FullScreenLayout,
    component: RoadMapPublicPage,
  },
]);

export const UnauthenticatedRoutes = GenerateNormalizedRoutes([
  {
    path: "login",
    layout: FullScreenLayout,
    component: LoginPage,
  },
  {
    path: "external-auth-connector",
    layout: FullScreenLayout,
    component: ExternalAuthConnectionPage,
  },
  {
    path: "register",
    layout: FullScreenLayout,
    component: RegisterPage,
  },
  {
    path: "register-with-store",
    layout: FullScreenLayout,
    component: RegisterWithStorePage,
  },
  {
    path: "register-with-appsumo",
    layout: FullScreenLayout,
    component: RegisterWithAppSumoPage,
  },
  {
    path: "accept-invitation",
    layout: FullScreenLayout,
    component: AcceptInvitationPage,
  },
  {
    path: "reset-password",
    layout: FullScreenLayout,
    component: ResetPasswordPage,
  },
  {
    path: "roadmap",
    layout: FullScreenLayout,
    component: RoadMapPublicPage,
  },
  // {
  //   path: "bot-builder",
  //   component: BotBuilderPlayground,
  //   label: "Configurations",
  //   layout: FullScreenLayout,
  // },
]);
export const AuthenticatedRoutesHQ = GenerateNormalizedRoutes([
  {
    path: "hq/organizations",
    component: HQOrganizationsPage,
    label: "Organizations",
    layout: HQLayout,
  },
  {
    path: "hq/organizations/users/:id",
    component: HQUsersPage,
    label: "Users",
    layout: HQLayout,
  },
  {
    path: "hq/organizations/connections/:id",
    component: HQConnectionsPage,
    label: "Connections",
    layout: HQLayout,
  },
  {
    path: "hq/change-logs",
    component: HQChangeLogsPage,
    label: "Change Logs",
    layout: HQLayout,
  },
  {
    path: "hq/fb-event-log-browser",
    component: HQfbEventLogBrowser,
    label: "FB Event Log Browser",
    layout: HQLayout,
  },
  {
    path: "hq/email-event-log-browser",
    component: HQEmailEventLogBrowser,
    label: "Email Event Log Browser",
    layout: HQLayout,
  },
]);
export const UnauthenticatedRoutesHQ = GenerateNormalizedRoutes([
  {
    path: "hq",
    layout: FullScreenLayout,
    component: HQLoginPage,
  },
]);
