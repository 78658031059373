import { SocketConnector } from "@sdk/@libs/socket-connector";
import { iConversation } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { message } from "antd";
import { useEffect } from "react";

export const useEmailTester = (connectionId: string) => {
  const {
    doAction: testIncomingEmail,
    isProcessing: isTestingIncomingEmail
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => () => SDK.connections.testIncomingEmail(connectionId!),
      successMessage:
        "Email Triggered. You should be getting a new conversation",
      failureMessage: "Something went wrong"
    }),
    [connectionId]
  );

  const {
    doAction: testOutgoingEmail,
    isProcessing: isTestingOutgoingEmail
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => () => SDK.connections.testOutgoingEmail(connectionId!),
      successMessage:
        "Email Triggered. You should be getting a new email to your account email",
      failureMessage: "Something went wrong"
    }),
    [connectionId]
  );

  useEffect(() => {
    const listener = (data: {
      name: string;
      data: { connectionId: string };
    }) => {
      console.log("Received Special Socket Event", data);
      if (data.name === "TEST_EMAIL") {
        message.success("Successfully received test email");
      }
    };
    if (connectionId && SocketConnector.socket) {
      SocketConnector.socket.on("SPECIAL_EVENTS", listener);
    }

    return () => {
      if (SocketConnector.socket) {
        SocketConnector.socket.off("SPECIAL_EVENTS", listener);
      }
    };
  }, [connectionId]);

  useEffect(() => {
    const listener = (data: {
      data: iConversation;
      entityType: "CONVERSATION";
      type: "NEW";
    }) => {
      console.log("Received SYNC Event", data);
      if (
        data.type === "NEW" &&
        data.entityType === "CONVERSATION" &&
        data.data?.data?.subject === "Test Email"
      ) {
        message.success("Successfully received test email");
      }
    };
    if (connectionId && SocketConnector.socket) {
      SocketConnector.socket.on("SYNC", listener);
    }

    return () => {
      if (SocketConnector.socket) {
        SocketConnector.socket.off("SYNC", listener);
      }
    };
  }, [connectionId]);
  return {
    testIncomingEmail,
    isTestingIncomingEmail,
    testOutgoingEmail,
    isTestingOutgoingEmail
  };
};
