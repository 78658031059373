import { SDK, useSDK } from "@sdk";
import { processServerError } from "@sdk/sdk.hooks";
import { BulkUserActions } from "@sdk/user-management/user-management.controller-models";
import { Form, message, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DarkModeBg } from "dark-mode-bg";
import { useState } from "react";

const { Option } = Select;

export const BulkEditUsersModal = ({
  visible,
  onChangeVisibility,
  onActionCompleted,
  action,
  query
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onActionCompleted?: () => any;
  action: BulkUserActions;
  query: any;
}) => {
  const [bulkEditForm] = useForm();
  const [isProcessingBulkEdit, setIsProcessingBulkEdit] = useState(false);

  const onComplete = async () => {
    const { groups, tags } = bulkEditForm.getFieldsValue();
    setIsProcessingBulkEdit(true);
    try {
      const res = await SDK.bulkActionUsers({
        action: action,
        groups,
        tags,
        query
      });
      switch (action) {
        case "ACTIVATE": {
          message.success("User(s) accounts have been activated");
          break;
        }
        case "DEACTIVATE": {
          message.success("User(s) has been deactivated");
          break;
        }
        case "ADD_TO_USER_GROUP":
        case "REMOVE_FROM_USER_GROUP": {
          message.success("User groups have been updated");
          break;
        }
      }
      onActionCompleted && onActionCompleted();
      onChangeVisibility(false);
      setIsProcessingBulkEdit(false);
      bulkEditForm.resetFields();
    } catch (e) {
      console.log("Error while processing bulk action", e);
      message.error(processServerError(e, "Something went wrong"));
      setIsProcessingBulkEdit(false);
    }
  };

  const { data: groups } = useSDK(SDK => SDK.getAllGroups(), [], false, []);

  const title = (() => {
    switch (action) {
      case "ACTIVATE": {
        return "Activate Selected Users";
      }
      case "DEACTIVATE": {
        return "Deactivate Selected Users";
      }
      case "ADD_TO_USER_GROUP": {
        return "Add Selected Users to Group";
      }
      case "REMOVE_FROM_USER_GROUP": {
        return "Remove Selected Users from Group";
      }
    }
  })();

  return (
    (<Modal
      title={title}
      open={visible}
      onOk={async () => onComplete()}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Continue"
      data-click-context="Bulk Edit Users Modal"
    >
      <Form layout="vertical" form={bulkEditForm} preserve={true}>
        {/* {action === 'TAGS' && (
          <Form.Item label="Tags" name="tags">
            <Select
              mode="tags"
              onChange={(selectedTags) => {
                const difference = _.difference(
                  selectedTags as any,
                  tags.map((item) => item.tag)
                );
                if (difference.length > 0) {
                  SDK.addContactTag(difference[0]).catch((e) => {
                    console.log("Error while saving tags", e);
                  });
                }
              }}
            >
              {tags.map((tag) => (
                <Select.Option key={tag.tag} value={tag.tag}>
                  {tag}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )} */}

        {action === "ACTIVATE" && (
          <div>You are about to activate the selected users</div>
        )}

        {action === "DEACTIVATE" && (
          <div>You are about to deactivate the selected users</div>
        )}

        {(action === "ADD_TO_USER_GROUP" ||
          action === "REMOVE_FROM_USER_GROUP") && (
          <div>Select groups to continue</div>
        )}

        {action === "ADD_TO_USER_GROUP" && (
          <Form.Item label="User Groups" name="groups">
            <Select showSearch mode="multiple">
              {groups.map(group => (
                <Select.Option value={group.id!} key={group.id}>
                  {group.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
      <DarkModeBg />
    </Modal>)
  );
};
