import { EditorState, Modifier, SelectionState } from "draft-js";
import escapeRegExp from "lodash/escapeRegExp";

interface SearchTextAtResult {
  begin: number;
  end: number;
  matchingString: string;
}
/**
 * Return tail end of the string matching trigger upto the position.
 */
function getSearchTextAt(
  blockText: string,
  position: number,
  triggers: string[]
): SearchTextAtResult {
  const str = blockText.substr(0, position);

  const triggerPattern = triggers
    .map((trigger) => escapeRegExp(trigger))
    .join("|");

  const TRIGGER_REGEX = new RegExp(`(\\s|^)(${triggerPattern})`, "g");

  const matches = str.matchAll(TRIGGER_REGEX) as any;

  let triggerStartIndex = 0;
  let valueStartIndex = 0;

  for (const match of matches) {
    const spaceLen = match[1].length;
    const matchLen = match[2].length;

    triggerStartIndex = (match.index || 0) + spaceLen;
    valueStartIndex = triggerStartIndex + matchLen;
  }

  const matchingString = str.slice(valueStartIndex);

  const end = str.length;

  return {
    begin: triggerStartIndex,
    end,
    matchingString,
  };
}

const getSearchText = (
  editorState: EditorState,
  selection: SelectionState,
  triggers: string[]
): SearchTextAtResult => {
  const anchorKey = selection.getAnchorKey();
  const anchorOffset = selection.getAnchorOffset();
  const currentContent = editorState.getCurrentContent();
  const currentBlock = currentContent.getBlockForKey(anchorKey);
  const blockText = currentBlock.getText();
  return getSearchTextAt(blockText, anchorOffset, triggers);
};

export function replaceTrigger(
  editorState: EditorState,
  mentionTrigger: string,
  replaceText: string
): EditorState {
  const currentSelectionState = editorState.getSelection();
  const { begin, end } = getSearchText(editorState, currentSelectionState, [
    mentionTrigger,
  ]);

  // get selection of the @mention search text
  const mentionTextSelection = currentSelectionState.merge({
    anchorOffset: begin,
    focusOffset: end,
  });

  let mentionReplacedContent = Modifier.replaceText(
    editorState.getCurrentContent(),
    mentionTextSelection,
    replaceText,
    editorState.getCurrentInlineStyle()
    // entityKey
  );

  if (replaceText.length > 0) {
    // If the mention is inserted at the end, a space is appended right after for
    // a smooth writing experience.
    const blockKey = mentionTextSelection.getAnchorKey();
    const blockSize = editorState
      .getCurrentContent()
      .getBlockForKey(blockKey)
      .getLength();
    if (blockSize === end) {
      mentionReplacedContent = Modifier.insertText(
        mentionReplacedContent,
        mentionReplacedContent.getSelectionAfter(),
        " "
      );
    }
  }

  const newEditorState = EditorState.push(
    editorState,
    mentionReplacedContent,
    "insert-fragment"
  );
  return EditorState.forceSelection(
    newEditorState,
    mentionReplacedContent.getSelectionAfter()
  );
}
