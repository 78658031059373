import {
  iChatFAQ,
  iChatFAQCollection,
} from "@sdk/chat-widgets/chat-widgets.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { WidgetPreviewSearchArticles } from "components/modules/connections/channels/chat-widget/components/configure-chat-widget/widget-preivew/widget-preview-search-articles";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { DeepPartial } from "redux";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectChatWidgetByConnectionId } from "store/modules/chat-widgets/chat-widgets.selectors";
import { setChatWidget } from "store/modules/chat-widgets/chat-widgets.slice";
import { useQueryWithStore } from "store/store.hooks";
import { useViewRefresher } from "utils/hooks/use-view-refresher";
import { defaultWidgetPreviewData } from "../configure-chat-widget/widget-preivew/default-widget-data";
import EditFAQs from "../faq-editor/edit-faqs.component";
import { iCollection, iFAQ, iFAQStoreState } from "../faq-editor/faq-model";

const emptyFaqState: iFAQStoreState = {
  collections: {
    byIds: {},
    list: [],
  },
  faqs: {
    byIds: {},
  },
};

const transformStateToFaqCollection = (state: iFAQStoreState) => {
  const collections: iChatFAQCollection[] = [];

  for (const collectionId of state.collections.list) {
    const collection = state.collections.byIds[collectionId];
    const collectionRecord: iChatFAQCollection = {
      label: collection.label,
      faqList: [],
    };

    for (const faqId of collection.faqs) {
      const faq = state.faqs.byIds[faqId];
      const faqRecord: iChatFAQ = {
        question: faq.question,
        body: faq.body,
      };
      collectionRecord.faqList.push(faqRecord);
    }
    collections.push(collectionRecord);
  }
  return collections;
};

const transformFaqCollectionsToState = (collections: iChatFAQCollection[]) => {
  const state = _.cloneDeep(emptyFaqState);

  collections.forEach((collection, collectionIndex) => {
    const collectionId = `COLLECTION-${collectionIndex.toString()}`;
    const collectionRecord: iCollection = {
      id: collectionId,
      ...collection,
      faqs: [],
    };

    (collection.faqList || []).forEach((faq, faqIndex) => {
      const faqId = `FAQ-${collectionIndex.toString()}-${faqIndex.toString()}`;
      const faqRecord: iFAQ = {
        id: faqId,
        ...faq,
      };
      // Add FAQ
      collectionRecord.faqs.push(faqId);
      state.faqs.byIds[faqId] = faqRecord;
    });
    // Add Collection
    state.collections.byIds[collectionId] = collectionRecord;
    state.collections.list.push(collectionId);
  });
  return state;
};

export const ManageWidgetFaqs = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const { state: widget, retry: reload } = useQueryWithStore(
    selectChatWidgetByConnectionId(connectionId),
    loadAllChatWidgets(),
    [connectionId],
  );

  const {
    doAction: saveFAQs,
    isProcessing,
    dispatch,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (faqCollections) =>
        SDK.chatWidgets
          .editById(widget?.id!, {
            configurations: {
              faqCollections,
            },
          })
          .then((d) => {
            // added as a patch for sync not working on chat widget
            dispatch(setChatWidget(d));
          }),
      // successMessage: "Saved",
      failureMessage: "Something went wrong",
    }),
    [widget?.id],
  );

  const { visible: showFaqs } = useViewRefresher(widget);

  const initialFAQState = useMemo(() => {
    const collections = widget?.configurations.faqCollections || [];
    return transformFaqCollectionsToState(collections);
  }, [widget?.configurations.faqCollections]);

  const onFaqSave = useCallback(
    (state) => {
      if (state !== initialFAQState) {
        saveFAQs(transformStateToFaqCollection(state));
      }
    },
    [initialFAQState, saveFAQs],
  );

  const widgetPreviewConfig = useMemo(() => {
    return {
      SEARCH_ARTICLES: {
        COLLECTIONS: widget?.configurations.faqCollections,
      },
    } as DeepPartial<typeof defaultWidgetPreviewData>;
  }, [widget?.configurations.faqCollections]);

  return (
    <div className="grid xl:grid-cols-2 grid-cols-1">
      <div className="flex flex-col">
        <div className="header flex flex-row justify-between items-center">
          <div className="title font-bold text-xl">Configure FAQs</div>
          <div className="text-gray-600 dark:text-gray-400 mb-8 mode_transition">
            Faqs are grouped intro collections for easy access.
          </div>
        </div>
        {showFaqs && (
          <EditFAQs initialState={initialFAQState} onSave={onFaqSave} />
        )}
      </div>
      <div className="flex-row justify-center items-center hidden xl:flex">
        <div
          className="chat-widget-container cursor-not-allowed"
          style={{
            width: "28rem",
            height: "45rem",
            overflow: "hidden",
            borderRadius: "10px 10px 0 0",
            boxShadow: "0 0 20px #00000038",
          }}
        >
          <WidgetPreviewSearchArticles
            color={
              widget?.configurations?.appearance.colorPallet.primary ||
              "#0466ff"
            }
            configData={widgetPreviewConfig.SEARCH_ARTICLES || {}}
          />
        </div>
      </div>
    </div>
  );
};
