import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  iCustomInbox,
  iUserGroup,
} from "@sdk/user-management/user-management.models";
import { Form, message, Modal, Segmented } from "antd";
import { useForm } from "antd/es/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { UserGroupSelector } from "components/modules/user-management/user-groups/user-group-selector/user-group-selector";
import { SingleUserSelector } from "components/modules/user-management/users/components/single-user-selector/single-user-selector";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useCallback } from "react";
import { useSelector, useStore } from "react-redux";
import { selectGroupById } from "store/modules/groups/groups.selectors";
import { DefaultConversationQueryConfig } from "store/modules/ui-state/ui-state.slice";
import {
  selectCurrentUser,
  selectUser,
  selectUsersByUserGroup,
} from "store/modules/users/users.selectors";
import { ConversationTagSelector } from "../converation-tag-selector";
import "./add-quick-custom-inbox.scss";

const watchType = [
  {
    value: "USER",
    label: "User",
  },
  {
    value: "TAG",
    label: "Tag",
  },
  {
    value: "TEAM",
    label: "Team",
  },
];

export const AddQuickCustomInboxModal = ({
  visible,
  onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const currentUser = useSelector(selectCurrentUser);

  const { doAction: editCustomInboxes, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (customInboxes: iCustomInbox[]) =>
        SDK.editCurrentUser({
          data: {
            customInboxes,
          },
        }).then((d) => {
          onChangeVisibility(false);
        }),
      failureMessage: "Something went wrong",
    }),
    [onChangeVisibility]
  );

  const addInbox = useCallback(
    (inbox: iCustomInbox) => {
      editCustomInboxes([...(currentUser?.data.customInboxes || []), inbox]);
    },
    [currentUser?.data.customInboxes, editCustomInboxes]
  );

  const [form] = useForm();

  const store = useStore();

  return (<>
    <Modal
      title={
        <ModalTitle
          title="Add Inbox"
          icon={<i className="ri-add-line"></i>}
        />
      }
      open={visible}
      onOk={async () => {
        try {
          await form.validateFields();
          const { type, tags, user, team } = form.getFieldsValue();
          const queryConfig = {
            ...DefaultConversationQueryConfig,
            query: (() => {
              if (type === "TAG") {
                return {
                  tags: { $in: tags },
                };
              }
              if (type === "USER") {
                return {
                  "members.userId": { $in: [user] },
                };
              }
              if (type === "TEAM") {
                const teamMembers = selectUsersByUserGroup(store.getState())[
                  team
                ].map((user) => user.id);
                return {
                  "members.userId": { $in: teamMembers },
                };
              }
              return {};
            })(),
          };
          addInbox({
            query: JSON.stringify(_.omit(queryConfig, "alias")),
            label: (() => {
              if (type === "TAG") {
                return tags.join(", ");
              }
              if (type === "USER") {
                const userObj = selectUser(user)(store.getState());
                return userObj.data.firstName;
              }
              if (type === "TEAM") {
                const userGroup: iUserGroup = selectGroupById(team)(
                  store.getState()
                );
                return userGroup.label;
              }
              return {};
            })(),
          });
          // Todo
        } catch (e) {
          console.log("e", e);
          message.error("Please check your input");
        }
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Add"
      okButtonProps={{ loading: isProcessing }}
      // width={600}
      data-click-context="Add Custom Inboxes Modal"
    >
      <div className="container flex flex-row add-quick-custom-inbox-container">
        <Form
          layout="vertical"
          form={form}
          initialValues={{ type: "USER" }}
          requiredMark={false}
          className="w-full"
        >
          <Form.Item
            label={"I want to watch a"}
            name="type"
            rules={[{ required: true, message: "Please select a type" }]}
          >
            <Segmented
              options={watchType}
              defaultValue={"USER"}
              block
              className="w-full"
            />
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const type = getFieldValue(["type"]);
              // Tags
              if (type === "TAG") {
                return (
                  <Form.Item
                    label={"Select Tag(s)"}
                    name="tags"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one tag",
                      },
                    ]}
                  >
                    <ConversationTagSelector />
                  </Form.Item>
                );
              }
              if (type === "USER") {
                return (
                  <Form.Item
                    label={"Select a User"}
                    name="user"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one user",
                      },
                    ]}
                  >
                    <SingleUserSelector />
                  </Form.Item>
                );
              }
              if (type === "TEAM") {
                return (
                  <Form.Item
                    label={"Select a Team"}
                    name="team"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one team",
                      },
                    ]}
                  >
                    <UserGroupSelector />
                  </Form.Item>
                );
              }
              return <></>;
            }}
          </Form.Item>
        </Form>
      </div>
      <DarkModeBg />
    </Modal>
  </>);
};
