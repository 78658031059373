import axios, { AxiosResponse } from "axios";

export const getWAAccounts = async (
  accessToken: string,
): Promise<iWAAccountWithNumber[]> => {
  console.log("accessToken", accessToken);
  const baseUrl = "https://graph.facebook.com/v17.0";
  const accountsFields = "id,name,access_token,category,category_list,business";
  let accounts: FacebookAccountRecord[] = [];
  const uri = `${baseUrl}/me/accounts?access_token=${accessToken}&fields=${accountsFields}`;

  const loadPages = async (url: string) => {
    const results: AxiosResponse<FacebookGetAccountResponse> = await axios.get(
      url,
    );
    const data = results.data;
    if (!data) {
      throw new Error("Something went wrong");
    } else {
      accounts = accounts.concat(data.data);
      if (data && data.paging && data.paging.next) {
        await loadPages(data.paging.next);
      }
    }
  };

  await loadPages(uri);

  const accountsWithBusiness = accounts.filter((item) => item.business);

  console.log("accountsWithBusiness", accountsWithBusiness);

  let waAccounts: (iWAAccount & { page: FacebookAccountRecord })[] = [];

  const loadWAAccounts = async (account: FacebookAccountRecord) => {
    const url = `${baseUrl}/${account.business?.id}/owned_whatsapp_business_accounts?access_token=${accessToken}`;
    const results: AxiosResponse<FbWAAccountList> = await axios.get(url);
    const data = results.data;
    if (!data) {
      throw new Error("Something went wrong");
    } else {
      for (const waAccount of data.data) {
        waAccounts.push({
          ...waAccount,
          page: account,
        });
      }

      // Todo: Support Paging
      // if (data && data.paging && data.paging.next) {
      //   await loadWAAccounts(data.paging.next);
      // }
    }
  };

  for (const account of accountsWithBusiness) {
    await loadWAAccounts(account);
  }

  console.log("waAccounts", waAccounts);

  const waAccountsWithNumbers = [] as iWAAccountWithNumber[];

  const loadWAPhoneNumbers = async (
    item: iWAAccount & { page: FacebookAccountRecord },
  ) => {
    const url = `${baseUrl}/${item.id}/phone_numbers?access_token=${accessToken}&fields= verified_name, code_verification_status, quality_rating, id, display_phone_number, name_status`;
    const results: AxiosResponse<FbWAPhoneListRes> = await axios.get(url);
    const data = results.data;
    if (!data) {
      throw new Error("Something went wrong");
    } else {
      waAccountsWithNumbers.push({
        ...item,
        numbers: data.data,
      });
      // Todo: Support Paging
      // if (data && data.paging && data.paging.next) {
      //   await loadWAAccounts(data.paging.next);
      // }
    }
  };

  for (const waAccount of waAccounts) {
    await loadWAPhoneNumbers(waAccount);
  }

  console.log("waAccountsWithNumbers", waAccountsWithNumbers);

  return waAccountsWithNumbers;
};

export type iWAAccountWithNumber = iWAAccount & {
  page: FacebookAccountRecord;
  numbers: iWAPhoneNumber[];
};

type FacebookGetAccountResponse =
  iFacebookGetListResponse<FacebookAccountRecord>;

export interface iFacebookGetListResponse<Record> {
  data: Record[];
  paging: FacebookResponsePaging;
}

interface FacebookResponsePaging {
  cursors: FacebookResponseCursors;
  next?: string;
}

interface FacebookResponseCursors {
  before: string;
  after: string;
}

interface FacebookAccountRecord {
  access_token: string;
  category: string;
  category_list: FacebookCategoryList[];
  name: string;
  id: string;
  tasks: string[];
  instagram_business_account?: {
    id: string;
  };
  business?: {
    id: string;
    name: string;
  };
}

interface FacebookCategoryList {
  id: string;
  name: string;
}

type FbWAAccountList = iFacebookGetListResponse<iWAAccount>;

interface iWAAccount {
  id: number;
  name: string;
  currency: string;
  timezone_id: string;
  message_template_namespace: string;
}

type FbWAPhoneListRes = iFacebookGetListResponse<iWAPhoneNumber>;

interface iWAPhoneNumber {
  id: string;
  verified_name: string;
  code_verification_status: string;
  quality_rating: string;
  display_phone_number: string;
  certificate: string;
  new_certificate: string;
  name_status: string;
  new_name_status: string;
}
