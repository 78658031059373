export const ChatBotPlatforms = [
  {
    id: "FACEBOOK",
    label: "Facebook",
  },
  {
    id: "EMAIL",
    label: "Email",
  },
  {
    id: "WEB_CHAT",
    label: "Live Chat",
  },
  {
    id: "INSTAGRAM",
    label: "Instagram",
  },
  {
    id: "WHATS_APP_PRIVATE",
    label: "Whatsapp Private",
  },
  {
    id: "WHATS_APP_CLOUD",
    label: "Whatsapp Business",
  },
  // {
  //   id: "WHATS_APP_CLOUD_NATIVE",
  //   label: "Whatsapp Business"
  // }
];
