import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { iArticle } from "@sdk/knowledge-base/knowledge-base.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Divider,
  Dropdown,
  Input,
  Menu,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import classNames from "classnames";
import { BuildViewQuery } from "components/common/filter-schema/build-view-config";
import {
  AddFilterButton,
  FilterBar,
} from "components/common/filter-schema/filter-bar";
import { iFilterOption } from "components/common/filter-schema/filter-config-schema";
import { CCPopover } from "components/common/popover/popover";
import { push } from "connected-react-router";
import copyToClipboard from "copy-to-clipboard";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { articleDefaultQueryConfig } from "store/modules/articles/article-default-query";
import {
  loadAllArticles,
  loadArticlesOfCollection,
} from "store/modules/articles/articles.helpers";
import {
  selectAllArticles,
  selectArticleByCollectionIdFromQuery,
} from "store/modules/articles/articles.selectors";
import { loadAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.helpers";
import { selectAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSearch } from "utils/hooks/use-search";
import { getArticleSlug } from "../helpers/get-article-slug";
import "./article-list.scss";
import { ArticlesFilterSchema } from "./articles-filter-schema";
import { ArticlesSearchQueryBuilder } from "./articles-search-query-builder";

const emptyFilters = [] as iFilterOption[];

export const ArticleList = ({
  collectionId,
  onEditArticle,
  onPreviewArticle,
  onAddArticle,
  hideTitle,
  showFilters,
}: {
  collectionId?: string;
  onEditArticle: (articleId?: string) => any;
  onPreviewArticle: (articleId?: string) => any;
  onAddArticle: () => any;
  hideTitle?: boolean;
  showFilters?: boolean;
}) => {
  const {
    state: {
      list: articlesOfCollections,
      isLoading: loadingCollectionArticles,
    },
    retry: reloadCollectionArticle,
  } = useQueryWithStore(
    selectArticleByCollectionIdFromQuery(collectionId!),
    loadArticlesOfCollection(collectionId!),
    [collectionId],
    !collectionId,
  );

  const {
    state: allArticles,
    retry: reloadAllArticles,
    isLoading: allArticlesLoading,
  } = useQueryWithStore(
    selectAllArticles,
    loadAllArticles,
    [],
    Boolean(collectionId),
  );

  const { state: selfServicePortals } = useQueryWithStore(
    selectAllSelfServicePortals,
    loadAllSelfServicePortals,
  );

  const defaultPortal = selfServicePortals[0];

  const isLoading = loadingCollectionArticles || allArticlesLoading;

  const reload = useCallback(() => {
    if (collectionId) {
      reloadCollectionArticle();
    }
    reloadAllArticles();
  }, [collectionId, reloadAllArticles, reloadCollectionArticle]);

  const {
    doAction: deleteArticle,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (articleId: string) =>
        SDK.articles.deleteById(articleId).then((d) => {
          return reload();
        }),
      successMessage: "Article has been deleted",
      failureMessage: "Something went wrong",
    }),
    [reload],
  );

  const allData = collectionId ? articlesOfCollections : allArticles;

  const dispatch = useDispatch();

  const articleListColumns = useMemo(
    () => [
      {
        title: "Article Title",
        dataIndex: "title",
        render: (title) => {
          return (
            <div className="font-bold flex flex-row items-center">
              <i className="ri-article-line mr-2"></i> {title}
            </div>
          );
        },
      },
      // {
      //   title: "",
      //   dataIndex: "type",
      //   width: 80,
      //   render: (text: string, article: iArticle) => {
      //     return <Tag>{article.type}</Tag>;
      //   },
      // },
      {
        title: "Created",
        dataIndex: "metaData",
        width: 100,
        defaultSortOrder: "descend",
        sorter: (a: iArticle, b: iArticle) =>
          a.metaData.createdAt - b.metaData.createdAt,
        render: (text: string, article: iArticle) => {
          return (
            <div className="text-sm text-gray-600">
              {dayjs(article.metaData.createdAt).format("DD-MM-YYYY")}
            </div>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "tags",
        width: 100,
        render: (tags: string[], article: iArticle) => (
          <span>
            <Space size="middle">
              {tags &&
                tags.map((tag, index) => (
                  <Tag key={index.toString()}>{tag}</Tag>
                ))}
              <Tag>{article.metaData.status}</Tag>
            </Space>
          </span>
        ),
        filters: [
          {
            text: "PUBLISHED",
            value: "PUBLISHED",
          },
          {
            text: "DRAFT",
            value: "DRAFT",
          },
        ],
        onFilter: (value: string, record: iArticle) =>
          record.metaData.status === value,
      } as any,
      {
        title: "",
        key: "action",
        width: 50,
        render: (text, record) => (
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
              overlay={
                <Menu data-click-context="Article Item More button menu">
                  <Menu.Item
                    icon={<i className="ri-eye-line"></i>}
                    onClick={(e) => {
                      onPreviewArticle(record.id);
                      // e.stopPropagation();
                      // dispatch(push(`/articles/${record.id}`));
                    }}
                  >
                    Preview
                  </Menu.Item>
                  <Menu.Item
                    icon={<i className="ri-delete-bin-line"></i>}
                    onClick={(e) => {
                      deleteArticle(record.id);
                      // e.stopPropagation();
                      // dispatch(push(`/articles/${record.id}`));
                    }}
                  >
                    Delete Article
                  </Menu.Item>
                  <Menu.Item
                    icon={<i className="ri-clipboard-line"></i>}
                    onClick={(e) => {
                      copyToClipboard(record.id);
                      message.success("Copied");
                      // e.stopPropagation();
                    }}
                  >
                    Copy Article Id
                  </Menu.Item>
                  <Menu.Item
                    icon={<i className="ri-clipboard-line"></i>}
                    onClick={(e) => {
                      const domainConfig = defaultPortal.data.domain;
                      const url =
                        domainConfig.customDomainConfig?.domain &&
                        domainConfig.customDomainConfig?.isVerified &&
                        domainConfig.customDomainConfig?.isConfigured
                          ? `https://${domainConfig.customDomainConfig?.domain}`
                          : `https://${domainConfig.subdomain}.helpcenter.guide`;
                      const articleUrl = `${url}/articles/${getArticleSlug(
                        record.title,
                        record.id,
                      )}`;
                      copyToClipboard(articleUrl);
                      message.success("Copied");

                      // e.stopPropagation();
                    }}
                  >
                    Copy Article URL
                  </Menu.Item>
                </Menu>
              }
              placement="topCenter"
              trigger={["click"]}
              arrow
            >
              <Button
                type="text"
                icon={<i className="ri-more-line"></i>}
                onClick={(e) => e.stopPropagation()}
              />
            </Dropdown>
          </div>
        ),
      },
    ],
    [deleteArticle, onPreviewArticle],
  );

  const { filteredData: data, searchTerm, setSearchTerm } = useSearch(allData);

  // const [selectedFilters, setSelectedFilters] = useURLData(
  //   "articles-filters",
  //   emptyFilters
  // );
  const [selectedFilters, setSelectedFilters] = useState(emptyFilters);

  const [filterVisibility, setFilterVisibility] = useState(false);
  const [sortBy, setSortBy] = useState<"UPDATED_TIME" | "CREATED_TIME">(
    "UPDATED_TIME",
  );

  const store = useStore();

  const query = useMemo(() => {
    return BuildViewQuery({
      searchText: searchTerm,
      searchQueryBuilder: ArticlesSearchQueryBuilder,
      selectedFilters,
      defaultQuery: articleDefaultQueryConfig,
      schema: ArticlesFilterSchema,
      store,
    });
  }, [searchTerm, selectedFilters, store]);

  return (
    <div className="p-8 pt-0 article-list">
      {!hideTitle && <Divider>Articles</Divider>}
      <div className="flex justify-end items-center mb-4">
        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            onAddArticle ? onAddArticle() : dispatch(push(`/articles/new`));
          }}
          type="dashed"
          className="font-semibold"
        >
          Add a new Article
        </Button>
      </div>

      <div className="flex flex-col w-full z-20 relative mb-6">
        <div className="flex flex-row justify-between search-bar w-full flex-1 px-5">
          <Input.Search
            placeholder="Search articles..."
            className="rounded-md"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onSearch={setSearchTerm}
          />
          {showFilters && (
            <>
              <CCPopover
                content={({ closePopover }) => (
                  <AddFilterButton
                    onAddFilter={(filter) => {
                      const existingFilters = selectedFilters;
                      setSelectedFilters([...existingFilters, filter]);
                      closePopover();
                    }}
                    existingFilters={selectedFilters.map((item) => item.key)}
                    schema={ArticlesFilterSchema}
                  />
                )}
                trigger="click"
                open={filterVisibility}
                onOpenChange={setFilterVisibility}
                buttonClassName="h-full"
                // overlayClassName="styled-popover"
              >
                <div
                  className={classNames(
                    "cursor-pointer filter-button hover:text-blue-600 flex flex-row justify-center items-center h-full",
                    {
                      "text-blue-500": selectedFilters.length > 0,
                    },
                  )}
                >
                  <i className="ri-filter-fill"></i>
                </div>
              </CCPopover>
              <div className="ml-2">
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu data-click-context="Chat List Menu">
                      {/* <Menu.Item
                            key="REFRESH_INBOX"
                            icon={<i className="ri-refresh-line"></i>}
                            onClick={() => reload()}
                          >
                            Refresh Inbox
                          </Menu.Item> */}
                      {/* <Menu.SubMenu
                            title={
                              <span className="text-lg">
                                <i className="ri-sort-desc"></i> Sort by
                              </span>
                            }
                          >   */}
                      <Menu.Item
                        onClick={() => setSortBy("UPDATED_TIME")}
                        className="text-lg"
                      >
                        <i
                          className={
                            sortBy === "UPDATED_TIME"
                              ? "ri-checkbox-circle-line"
                              : "ri-checkbox-blank-circle-line"
                          }
                        ></i>
                        Updated Time
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => setSortBy("CREATED_TIME")}
                        className="text-lg"
                      >
                        <i
                          className={
                            sortBy === "CREATED_TIME"
                              ? "ri-checkbox-circle-line"
                              : "ri-checkbox-blank-circle-line"
                          }
                        ></i>
                        Created Time
                      </Menu.Item>

                      {/* </Menu.SubMenu> */}
                    </Menu>
                  }
                >
                  <Button
                    type="text"
                    // icon={<i className="ri-more-2-fill"></i>}
                    icon={<i className="ri-sort-desc"></i>}
                    className="px-2"
                  ></Button>
                </Dropdown>
              </div>
            </>
          )}
        </div>
      </div>
      {selectedFilters.length > 0 && (
        <div>
          <FilterBar
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            schema={ArticlesFilterSchema}
            label={
              <div className="w-full flex flex-row justify-between items-center">
                <div className="">Applied Filters </div>
              </div>
            }
          ></FilterBar>
        </div>
      )}

      <Table
        className="article-list-table"
        columns={articleListColumns}
        dataSource={data}
        pagination={
          data.length < 10
            ? false
            : {
                showSizeChanger: false,
              }
        }
        rowKey="id"
        loading={isLoading || isProcessing}
        // scroll={{ y: 400 }}
        // style={{ maxHeight: 400 }}
        tableLayout="auto"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => onEditArticle(record.id), // click row
          };
        }}
      />
    </div>
  );
};
