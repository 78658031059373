import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Modal, Result } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import React, { useMemo, useState } from "react";
import {
  loadContactById,
  loadContactsQuery
} from "store/modules/contacts/contacts.helpers";
import {
  selectContactById,
  selectContactsQuery
} from "store/modules/contacts/contacts.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { generateSimilarContactsQuery } from "../../helpers/similar-contacts-query-generator";
import { ContactListItem } from "./contact-list-item";
import { SimilarContactsExplorer_ContactProfile } from "./similar-contacts-contact-profile";

export const SimilarContactsExplorer = ({
  visible,
  onChangeVisibility,
  contactId
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  contactId: string;
}) => {
  const { state: contact } = useQueryWithStore(
    selectContactById(contactId),
    loadContactById(contactId)(),
    [contactId],
    !contactId
  );

  const [selectedContact, setSelectedContact] = useState("");

  const similarContactsQueryConfig = useMemo(() => {
    const query = contact ? generateSimilarContactsQuery(contact) : {};
    return {
      query: query,
      options: {
        sortBy: ["metaData.createdTime"]
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contact?.data.firstName,
    contact?.data.lastName,
    contact?.data.primaryEmail,
    contact?.data.primaryMobile
  ]);

  const { state: contactsQuery, retry: reloadContacts } = useQueryWithStore(
    selectContactsQuery(JSON.stringify(similarContactsQueryConfig)),
    loadContactsQuery(
      similarContactsQueryConfig,
      JSON.stringify(similarContactsQueryConfig)
    ),
    [similarContactsQueryConfig, visible],
    !(contact && visible)
  );

  const {
    doAction: mergeContact,
    isProcessing: isMergingContact
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (target: string) =>
        SDK.mergeContacts({
          target,
          source: contactId,
          dataOverrides: {}
        }).then(d => {
          onChangeVisibility(false);
        }),
      successMessage: "Done",
      failureMessage: "Something went wrong"
    }),
    [contactId, onChangeVisibility]
  );

  return (<>
    <Modal
      title={
        <ModalTitle
          title="Similar Contacts"
          icon={<i className="ri-user-search-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save"
      className="very-big-modal"
      data-click-context="Similar Contact Explorer Modal"
    >
      {contact && (
        <div
          className="flex flex-row"
          style={{ minHeight: 500, height: "75vh" }}
        >
          <div
            className="contacts-list-container flex flex-col h-full"
            style={{ width: 320 }}
          >
            <div className="contact-profil p-4 border border-gray-200 dark:border-gray-800 rounded-lg mb-4 mode_transition">
              <ContactListItem
                contactId={contactId}
                isSelected={false}
                onContactSelected={() => {
                  //
                }}
              />
            </div>
            <Divider>
              <i className="ri-user-search-line"></i> Similar Contacts
            </Divider>
            <div className="contact-list flex-1 overflow-y-auto">
              {contactsQuery.list
                .filter(contact => contact.id !== contactId)
                .map(item => (
                  <ContactListItem
                    contactId={item.id!}
                    onContactSelected={setSelectedContact}
                    isSelected={item.id === selectedContact}
                  />
                ))}
            </div>
          </div>
          <div className="contact-profile p-4 w-full flex-1">
            {selectedContact && (
              <div className="w-full h-full flex flex-col">
                <div className="w-full flex-1 h-full">
                  {/* eslint-disable-next-line react/jsx-pascal-case */}
                  <SimilarContactsExplorer_ContactProfile
                    contactId={selectedContact}
                    onEditCustomer={() => {
                      // Nothing
                    }}
                  />
                </div>
                <div className="merge-button-container flex-row flex justify-center items-center mb-2">
                  <Button
                    icon={<i className="ri-git-merge-line"></i>}
                    className="font-bold"
                    onClick={() => mergeContact(selectedContact)}
                    loading={isMergingContact}
                    type="primary"
                  >
                    Merge
                  </Button>
                </div>
              </div>
            )}

            {!selectedContact && (
              <Result
                title={"Select a Contact"}
                icon={<i className="ri-user-3-line text-3xl"></i>}
                className="flex-1 h-full flex flex-col items-center justify-center"
              />
            )}
          </div>
        </div>
      )}
      <DarkModeBg />
    </Modal>
  </>);
};
