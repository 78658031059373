import { Menu } from "antd";
import {
  OnAtLeastLargeDesktop,
  OnAtMostSmallerDesktop
} from "components/common/breaking-points";
import { SideBarMenu } from "components/common/side-bar-menu/side-bar-menu";
import { SimpleScrollableLeftSidePanel } from "components/common/side-bar-menu/simple-left-side-panel";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";

import { useMemo } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import {
  iMenuLinkItem,
  iNavigationMenuItem,
  iRouteItem
} from "./side-bar-menu/menu-item-mode";

export const SectionWithSideMenu = ({
  menu,
  baseUrl,
  additionalProps,
  defaultRoute
}: {
  menu: iNavigationMenuItem[];
  baseUrl: string;
  additionalProps?: Record<string, any>;
  defaultRoute?: string;
}) => {
  const location = useLocation();
  const history = useHistory();

  const selectedPath = useMemo(() => {
    return location.pathname.replace(`${baseUrl}/`, "");
  }, [baseUrl, location.pathname]);

  const routes = useMemo(
    () => menu.filter(item => item.type === "LINK_ITEM") as iRouteItem[],
    [menu]
  );

  const menuLinkItems = useMemo(
    () =>
      menu
        .filter(item => item.type === "LINK_ITEM")
        .map(e => e as iMenuLinkItem),
    [menu]
  );

  return (
    <div className="flex flex-row flex-1 overflow-hidden">
      <OnAtLeastLargeDesktop>
        <SimpleScrollableLeftSidePanel className="animated fadeInLeftMin">
          <SideBarMenu
            menuItems={menu}
            onMenuClick={item => {
              history.push({
                pathname: `${baseUrl}/${item.key}`
              });
            }}
            selectedKeys={selectedPath}
          />
        </SimpleScrollableLeftSidePanel>
      </OnAtLeastLargeDesktop>

      <StyledScrollArea className="flex-1">
        <OnAtMostSmallerDesktop>
          <SimpleCardSection className="">
            <Menu
              className="flex-1"
              style={{ width: "99%" }}
              selectedKeys={[selectedPath]}
              mode="horizontal"
              overflowedIndicator={<i className="ri-menu-line"></i>}
            >
              {menuLinkItems.map(item => {
                return (
                  <Menu.Item
                    icon={item.icon}
                    key={item.key}
                    onClick={() => {
                      history.push({
                        pathname: `${baseUrl}/${item.key}`
                      });
                    }}
                    className="font-bold ml-0"
                  >
                    {item.label}
                  </Menu.Item>
                );
              })}
            </Menu>
          </SimpleCardSection>
        </OnAtMostSmallerDesktop>
        <SimpleCardSection
          // style={{ maxWidth: 850 }}
          className="p-8"
        >
          <Switch>
            {routes.map(item => (
              <Route
                path={`${baseUrl}/${item.key}`}
                render={props => (
                  <item.component {...props} {...(additionalProps || {})} />
                )}
                key={item.key}
              ></Route>
            ))}
            {defaultRoute && (
              <Route
                path="*"
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: defaultRoute
                    }}
                  />
                )}
              />
            )}
          </Switch>
        </SimpleCardSection>
      </StyledScrollArea>
    </div>
  );
};
