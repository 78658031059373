import { Button, Divider, Form, FormInstance } from "antd";
import {
  ActionConfig,
  ActionConfigLabels,
  ActionIcons,
  ActionLabels,
} from "components/modules/conversations/components/action-editor/actions";
import { SimpleCollapseWithHeader } from "../simple-collapse-with-header/simple-collapse-with-header";

export const ActionsBar = ({ form }: { form: FormInstance<any> }) => {
  //   const [actionsForm] = useForm(form);

  return (
    <div>
      <Form form={form} layout="vertical">
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const actions = getFieldValue(["actions"]);
            if ((actions || []).length > 0) {
              return (
                <SimpleCollapseWithHeader
                  headerRow={
                    <div className="flex flex-row items-center p-2">
                      <i className="ri-flashlight-fill"></i>{" "}
                      <div className="ml-4 flex flex-row items-center">
                        <span className="font-bold mr-1">
                          {(actions || []).length}
                        </span>
                        <div className="mr-2">Actions performed on Send</div>
                        <Button
                          icon={<i className="ri-close-line"></i>}
                          type="text"
                          onClick={() => {
                            form.setFieldsValue({
                              actions: [],
                            });
                          }}
                        >
                          Clear All Actions
                        </Button>
                      </div>
                    </div>
                  }
                >
                  <div style={{ height: 300 }} className="overflow-auto">
                    <Form.List name={"actions"}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item shouldUpdate noStyle key={field.key}>
                              {({ getFieldValue }) => {
                                const actionName = getFieldValue([
                                  "actions",
                                  field.name,
                                  "action",
                                ]);
                                if (actionName) {
                                  return (
                                    <div className="flex flex-col">
                                      <div className="action-header flex flex-row justify-between items-center p-2 border-t border-gray-200">
                                        <div className="left flex flex-row justify-between items-center flex-1">
                                          <div className="title flex flex-row items-center p-2 flex-1 font-bold">
                                            {ActionIcons[actionName]}{" "}
                                            <div className="ml-4 flex flex-row items-center">
                                              <div className="">
                                                {ActionLabels[actionName]}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="config">
                                            {(actionName ===
                                              "ADD_CONVERSATION_TAG" ||
                                              actionName ===
                                                "REMOVE_CONVERSATION_TAG" ||
                                              actionName ===
                                                "ADD_CONTACT_TAG" ||
                                              actionName ===
                                                "REMOVE_CONTACT_TAG" ||
                                              actionName ===
                                                "ADD_MARKETING_LIST" ||
                                              actionName ===
                                                "REMOVE_MARKETING_LIST" ||
                                              actionName ===
                                                "UNSUBSCRIBE_MARKETING_LIST" ||
                                              // SHopify
                                              actionName ===
                                                "SHOPIFY_LAST_ORDER_CANCEL" ||
                                              actionName ===
                                                "SHOPIFY_LAST_ORDER_DUPLICATE" ||
                                              actionName ===
                                                "SHOPIFY_LAST_ORDER_REFUND" ||
                                              actionName ===
                                                "SHOPIFY_LAST_ORDER_EDIT_NOTE") && (
                                              <div className="action-body">
                                                <ActionConfig
                                                  actionType={actionName}
                                                  ignoreLabel
                                                  noFormItemStyle
                                                  parentFieldName={[field.name]}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="right">
                                          <Button
                                            type="text"
                                            icon={
                                              <i className="ri-delete-back-2-line"></i>
                                            }
                                            onClick={() => {
                                              remove(index);
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {(actionName === "ROUTE_TO_HUMAN" ||
                                        actionName === "SEND_MESSAGE" ||
                                        actionName ===
                                          "SHOPIFY_LAST_ORDER_EDIT_SHIPPING_ADDRESS" ||
                                        actionName ===
                                          "SHOPIFY_ORDER_CANCEL") && (
                                        <div className="action-body p-8">
                                          <ActionConfig
                                            actionType={actionName}
                                            actionConfigHeader={
                                              <Divider
                                                type="horizontal"
                                                className="w-full"
                                              >
                                                <div className="">
                                                  {
                                                    ActionConfigLabels[
                                                      actionName
                                                    ]
                                                  }
                                                </div>
                                              </Divider>
                                            }
                                            parentFieldName={[field.name]}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                                return <></>;
                              }}
                            </Form.Item>
                          ))}
                          {/* Todo: Add Action */}
                          {/* <div
                    onClick={() =>
                      add({
                        key: "New Key",
                        value: "",
                      })
                    }
                    className="cursor-pointer pl-4"
                  >
                    + add a data
                  </div> */}
                        </>
                      )}
                    </Form.List>
                  </div>
                </SimpleCollapseWithHeader>
              );
            }
            return <></>;
          }}
        </Form.Item>
      </Form>
    </div>
  );
};
