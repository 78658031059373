import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iPresetState } from "@sdk/user-management/preset-state-model";
import { reducer } from "components/modules/message-presets/preset-manager-state-manager";
import { PresetManager } from "components/modules/message-presets/presets-manager";
import { useCallback, useReducer } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { setOrganization } from "store/modules/workspace/workspace.actions";
import {
  selectOrganization,
  selectOrganizationWideMessagePresetsX
} from "store/modules/workspace/workspace.selectors";
import { useEffectWhen } from "utils/hooks/use-effect-when";

export const PresetMessagesX = () => {
  const organization = useSelector(selectOrganization);
  if (!organization) {
    return <></>;
  }

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_PresetMessagesX />
  );
};

const _PresetMessagesX = () => {
  const presetMessages = useSelector(selectOrganizationWideMessagePresetsX);

  const dispatchX = useDispatch();

  const { doAction: onUpdate, isProcessing, response } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.editCurrentOrganization(values).then(res => {
          dispatchX(setOrganization(res));
        }),
      // successMessage: "Account has been updated",
      failureMessage: "Something went wrong"
    }),
    [dispatchX]
  );

  const saveChanges = useCallback(
    (state?: iPresetState) => {
      onUpdate({
        appData: {
          HELP_DESK: {
            messagePresetsX: state
          }
        }
      });
    },
    [onUpdate]
  );

  const [state, dispatch] = useReducer(reducer, presetMessages);

  useEffectWhen(
    () => {
      saveChanges(state);
    },
    [saveChanges, state],
    [state]
  );

  return (
    <div className="h-full flex flex-col">
      {/* <div className="header flex flex-row justify-between items-center mb-4">
        <div className="text-gray-700 dark:text-gray-200 flex-1  mode_transition">
          <div className="text-5xl">Message Presets</div>
        </div>
      </div> */}
      <div className="flex-1 overflow-hidden w-full">
        <PerfectScrollbar className="my-6 p-6">
          <PresetManager state={state} dispatch={dispatch} />
        </PerfectScrollbar>
      </div>
    </div>
  );
};
