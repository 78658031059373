import { useSDK } from "@sdk/sdk.hooks";
import { Button, Card, Progress, Spin, message } from "antd";
import { ModalTitle } from "components/common/modal-title";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import {
  HomePageCard,
  HomePageCardHeader,
  StatisticWidget
} from "./components/home-page-card";

import { iReportQuery } from "@sdk/reports/reports-controller-models";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { MyBadges } from "components/modules/user-management/users/components/my-profile/components/my-badges";
import { iReportTypes } from "../reports/components/report-canvas/widget-canvas-models";

export const TotalConversationStatCard = ({
  icon,
  title,
  query
}: {
  icon: JSX.Element;
  title: string;
  query: any;
}) => {
  const { data, isLoading, reload } = useSDK(
    sdk =>
      sdk.queryConversations({
        query: query,
        options: { limit: 1 }
      } as any),
    [],
    false,
    []
  );

  return (
    <Spin spinning={isLoading} indicator={<LoadingIndicatorWithSpin />}>
      <StatisticWidget
        title={title}
        value={data?.totalDocs || 0}
        icon={icon}
        onClick={() => {
          message.info("Refreshing Stat");
          reload();
        }}
      />
    </Spin>
  );
};

export const SimpleReportStatCard = ({
  icon,
  title,
  reportType,
  query,
  label
}: {
  icon: JSX.Element;
  title: string;
  reportType: iReportTypes;
  query: iReportQuery;
  label?: string;
}) => {
  const { data, isLoading, reload } = useSDK(
    sdk => {
      const ReportTypeToAPIMap = {
        CONVERSATION: sdk.getConversationReports,
        LEADS: sdk.getContactReports,
        ACTIVITIES: sdk.getActivitiesReports,
        USER_REPORT: sdk.getConversationReports,
        COMPANIES: sdk.generateCompaniesReport,
        OPPORTUNITIES: sdk.generateOpportunitiesReport,
        ENGAGEMENTS: sdk.generateEngagementsReport,
        ANALYTICS: sdk.generateAnalyticsReport,
        MESSAGES: sdk.generateMessagesReport
      };
      const APIFunction = ReportTypeToAPIMap[
        reportType
      ] as typeof sdk.getConversationReports;

      return APIFunction(query);
    },
    [query, reportType],
    false,
    []
  );

  return (
    <Spin spinning={isLoading} indicator={<LoadingIndicatorWithSpin />}>
      <StatisticWidget
        title={title}
        value={Math.round(data?.count || 0)}
        label={label}
        icon={icon}
        onClick={() => {
          message.info("Refreshing Stat");
          reload();
        }}
      />
    </Spin>
  );
};

export const YourAchievements = () => {
  const currentUserId = useSelector(selectCurrentUserId);

  return (
    <HomePageCard>
      <HomePageCardHeader
        icon={<i className="ri-award-fill text-4xl"></i>}
        title="Your Achievements"
      />
      <div className="my-4">
        <div className="mt-8">
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
            <div className="col-span-2">
              <MyBadges />
            </div>
            <div className="">
              <Card
                title={
                  <div className="flex flex-row justify-between items-center">
                    <ModalTitle
                      title="My Streaks"
                      icon={<i className="ri-fire-line"></i>}
                    />
                    <Button
                      type="text"
                      onClick={() => {
                        // reloadScheduledMessages();
                      }}
                      icon={<i className="ri-refresh-line"></i>}
                    ></Button>
                  </div>
                }
                bodyStyle={{ padding: 0 }}
                className="dark:bg-gray-950"
              >
                <PerfectScrollbar style={{ height: 500 }}>
                  <div className="p-8">
                    <div className="streak mb-2">
                      <div className="title mb-2 font-semibold">
                        Handling Chat Invites
                      </div>
                      <Progress percent={20} size={[300, 20]} />
                    </div>
                    <div className="streak mb-2">
                      <div className="title mb-2 font-semibold">
                        Picking Conversations
                      </div>
                      <Progress percent={22} size={[300, 20]} />
                    </div>
                    <div className="streak mb-2">
                      <div className="title mb-2 font-semibold">
                        Closing Conversations
                      </div>
                      <Progress percent={24} size={[300, 20]} />
                    </div>
                    <div className="streak mb-2">
                      <div className="title mb-2 font-semibold">
                        Use Click Connector{" "}
                      </div>
                      <Progress percent={43} size={[300, 20]} />
                    </div>
                  </div>
                </PerfectScrollbar>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </HomePageCard>
  );
};
