import { iOpportunity } from "@sdk/crm/crm.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { memo, useCallback, useMemo } from "react";
import { useStore } from "react-redux";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { SingleUserSelector } from "../../user-management/users/components/single-user-selector/single-user-selector";
import { PipelineStageSelector } from "../components/pipeline-selector/pipeline-stage-selector";
import { SingleContactSelectorLegacy } from "../contacts/components/single-contact-selector/single-contact-selector-legacy";
import { WorldCurrencies } from "../products/currency";

export const CreateOpportunity = memo(
  ({
    visible,
    onChangeVisibility,
    onCreated,
    initialData: _initialData
  }: {
    visible: boolean;
    onChangeVisibility: (state: boolean) => any;
    onCreated?: () => any;
    initialData?: Partial<iOpportunity>;
  }) => {
    const [form] = useForm();

    let opportunity: iOpportunity;

    // Create Call
    const {
      doAction: createOpportunity,
      isProcessing: isActionProcessing
    } = useSDKActionWithDeps(
      () => ({
        action: SDK => values =>
          SDK.opportunities.create(values).then(d => onChangeVisibility(false)),
        successMessage: "Done",
        failureMessage: "Something went wrong"
      }),
      [onChangeVisibility]
    );

    const saveOpportunity = useCallback(async () => {
      try {
        await form.validateFields();
      } catch (e) {
        message.warning("Please check your input");
        return;
      }

      const values = form.getFieldsValue();

      createOpportunity({
        ..._initialData,
        owners: [
          {
            userId: values.owner
          }
        ],
        //
        contactId: values.contact?.id,
        title: values.title,
        value: {
          amount: values.value,
          currency: values.currency
        },
        products: [
          //   {
          //   label: string;
          //   price: number;
          //   quantity: number;
          //   amount: number;
          // }
        ],
        pipelineStage: values.pipelineStage,
        expectedCloseDate: values.expectedCloseDate?.valueOf()
      });
    }, [form, createOpportunity, _initialData]);

    const store = useStore();
    const initialData = useMemo(() => {
      const data = {
        ..._initialData,
        owner: selectCurrentUserId(store.getState()),
        currency: "USD",
        pipelineStage: "Lead"
      };
      if (_initialData?.contactId) {
        data.contact = selectContactById(_initialData?.contactId)(
          store.getState()
        );
      }
      return data;
    }, [_initialData, store]);

    return (
      <>
        <Modal
          title={
            <ModalTitle
              title="New Opportunity"
              icon={<i className="ri-money-dollar-box-line"></i>}
            />
          }
          open={visible}
          onOk={async () => {
            saveOpportunity();
          }}
          okButtonProps={{ loading: isActionProcessing }}
          onCancel={() => {
            onChangeVisibility(false);
          }}
          okText="Save"
          data-click-context="Create Opportunity Modal"
          destroyOnClose={true}
        >
          <Form
            layout="vertical"
            form={form}
            initialValues={initialData}
            className="bold-form-labels"
            onFinish={saveOpportunity}
          >
            {/* Title */}
            <Form.Item
              label="Label"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter a label"
                }
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            {/* Value */}
            <Row justify="space-between">
              {/* Value */}
              <Col span={11}>
                <Form.Item
                  label="Value"
                  name="value"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a value"
                    }
                  ]}
                >
                  <Input placeholder="" type="number" />
                </Form.Item>
              </Col>
              {/* Currency */}
              <Col span={11}>
                <Form.Item label="Currency" name="currency">
                  <Select style={{ minWidth: 150 }} showSearch disabled>
                    {WorldCurrencies.map(currency => (
                      <Select.Option value={currency.cc} key={currency.name}>
                        {currency.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {/* Stage */}
            <div className="">
              <Form.Item name="pipelineStage" label="Pipeline Stage" rules={[]}>
                <PipelineStageSelector
                  pipeline={{
                    stages: [
                      "Lead",
                      "Deal Proposed",
                      "Deal Negotiation",
                      "Deal Accepted"
                    ],
                    successStage: "Deal Completed",
                    failureStage: "Deal Lost"
                  }}
                />
              </Form.Item>
            </div>
            {/* Expected Close Date */}
            <Form.Item
              name="expectedCloseDate"
              label="Expected Close Date"
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please select time!"
                }
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                suffixIcon={<i className="ri-calendar-event-line"></i>}
                className="w-full"
              />
            </Form.Item>
            {/* Owner */}
            <Form.Item name="owner" label={"Opportunity Owner"} rules={[]}>
              <SingleUserSelector size="middle" />
            </Form.Item>

            {/* Linked Contact */}
            <Form.Item
              name="contact"
              label={"Associated Contact"}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please select a contact"
                }
              ]}
            >
              <SingleContactSelectorLegacy />
            </Form.Item>
            <Button htmlType="submit" type="primary" className="hidden">
              Save
            </Button>
          </Form>
          <DarkModeBg />
        </Modal>
      </>
    );
  }
);
