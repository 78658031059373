import produce from "immer";
import _ from "lodash";

export interface iEmailSignature {
  id: string;
  label: string;
  content: string;
}

export interface iEmailSignatureManagerState {
  signatures: iEmailSignature[];
}

export const initialBlockBuilderState: iEmailSignatureManagerState = {
  signatures: [],
};

export const reducer = (
  state: iEmailSignatureManagerState,
  action,
): iEmailSignatureManagerState => {
  switch (action.type) {
    case "RESET_STATE":
      return action.payload.state;
    case "ADD_SIGNATURE":
      return produce(state, (draft) => {
        const signature = action.payload.signature;
        draft.signatures.push(signature);
      });
    case "EDIT_SIGNATURE":
      return produce(state, (draft) => {
        const signature = action.payload.signature;
        const signatureToEdit = _.find(draft.signatures, { id: signature.id });
        if (signatureToEdit) {
          Object.assign(signatureToEdit, signature);
        }
      });
    case "REMOVE_SIGNATURE":
      return produce(state, (draft) => {
        const signature = action.payload.signature;
        const signatureToRemove = _.find(draft.signatures, {
          id: signature.id,
        });
        _.pull(draft.signatures, signatureToRemove);
      });
    default:
      // console.log("action", action);
      // console.log("state", state);
      throw new Error();
  }
};
