import { Cascader, Divider, Select, Tag } from "antd";
import { useEffect, useState } from "react";
import { LoadAllGroups } from "store/modules/groups/groups.helpers";
import { selectAllGroupsQuery } from "store/modules/groups/groups.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { ActionList } from "../action-editor/action-list";
import { RoutingConditionConfigurations, RoutingOptions } from "./config";
import { iRule } from "./models";
import "./rule-editor.scss";
import {
  convertConditionsToCascadeOptions,
  getSelectedCondition
} from "./utils";

export const defaultRule: iRule = {
  field: "adName",
  operator: "is",
  value: "",
  routeTo: "STANDARD_ROUTING",
  routingConfig: {}
};

const RuleConditionEditor = ({
  rule,
  onChange
}: {
  rule: iRule;
  onChange: (rule: iRule) => void;
}) => {
  const [cascaderValue, setCascaderValue] = useState([
    rule.field,
    rule.operator
  ] as any);
  const [conditionValue, setConditionValue] = useState(rule.value);
  const options = convertConditionsToCascadeOptions(
    RoutingConditionConfigurations
  );
  const { widget: SelectedWidget, help: helpText } = getSelectedCondition(
    cascaderValue as any,
    RoutingConditionConfigurations
  )!;

  useEffect(() => {
    const ruleClone = { ...rule };
    let isChanged = false;
    if (
      ruleClone.field !== cascaderValue[0] ||
      ruleClone.operator !== cascaderValue[1]
    ) {
      // Todo:
      ruleClone.field = cascaderValue[0] as any;
      ruleClone.operator = cascaderValue[1] as any;
      isChanged = true;
    }
    if (ruleClone.value !== conditionValue) {
      ruleClone.value = conditionValue;
      isChanged = true;
    }
    if (isChanged) {
      onChange(ruleClone);
    }
  }, [
    rule,
    onChange,
    cascaderValue,
    setCascaderValue,
    conditionValue,
    setConditionValue
  ]);
  return (
    <div>
      <div className="flex flex-row">
        <Cascader
          value={cascaderValue}
          options={options}
          onChange={setCascaderValue}
          placeholder="Please select"
          size="large"
          className="mr-2"
          style={{ height: 40 }}
          allowClear={false}
        />
        {SelectedWidget && (
          <SelectedWidget value={conditionValue} onChange={setConditionValue} />
        )}
      </div>
      <div className="help-text text-gray-600 dark:text-gray-400 mt-4 mode_transition">
        {helpText}
      </div>
    </div>
  );
};

export const RuleEditor = ({
  rule,
  _setRule
}: {
  rule: iRule;
  _setRule: (rule: iRule) => any;
}) => {
  // const [rule, _setRule] = useState(defaultRule);
  const setRule = (change: Partial<iRule>) => _setRule({ ...rule, ...change });
  const {
    state: { list: userGroups }
  } = useQueryWithStore(selectAllGroupsQuery, LoadAllGroups);

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="rule-card rounded-lg p-8">
        <div className="title flex flex-row items-center justify-center mb-8">
          <Tag color="#108ee9">When</Tag>
        </div>
        <div className="font-bold">Choose a rule condition</div>
        <div className="text-gray-600 dark:text-gray-400 mb-6 mode_transition">
          Routing rules will be applied when the configured rule matches the
          routing event
        </div>
        <RuleConditionEditor rule={rule} onChange={setRule} />
      </div>
      <Divider>
        <i className="ri-arrow-down-line text-2xl font-bold"></i>
      </Divider>
      <div className="rule-card rounded-lg p-8">
        <div className="title flex flex-row items-center justify-center mb-8">
          <Tag color="#108ee9">Then</Tag>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row justify-center items-center mt-4">
            <div className="routing-action flex flex-row justify-center items-center bg-gray-200 dark:bg-gray-800 p-2 rounded-lg">
              <div className="destination-selector font-bold">
                <Select
                  value={rule.routeTo}
                  onChange={destination => setRule({ routeTo: destination })}
                  bordered={false}
                  style={{ minWidth: 200 }}
                >
                  {RoutingOptions.map(item => (
                    <Select.Option value={item.value} key={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          {rule.routeTo === "CUSTOM_ROUTING" && (
            <div className="flex flex-row justify-center items-center mt-4">
              <div className="routing-action flex flex-row justify-center items-center bg-gray-200 dark:bg-gray-800 p-2 rounded-lg">
                <div className="pill">User Group:</div>
                <div className="destination-selector font-bold">
                  <Select
                    value={rule.routingConfig?.groupsToRoute}
                    onChange={groups =>
                      setRule({
                        routingConfig: {
                          ...rule.routingConfig,
                          groupsToRoute: groups
                        }
                      })
                    }
                    bordered={false}
                    style={{ minWidth: 200 }}
                    placeholder="Select groups"
                    mode="multiple"
                  >
                    {userGroups.map(group => (
                      <Select.Option value={group.id} key={group.id}>
                        {group.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ActionList
        value={rule.actions}
        onChange={actions => setRule({ actions })}
      />
    </div>
  );
};
