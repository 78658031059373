import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iArticle } from "@sdk/knowledge-base/knowledge-base.models";
import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { iPreset } from "@sdk/user-management/preset-state-model";
import { Button, Collapse, Divider, Input, Popover } from "antd";
import classnames from "classnames";
import { findIndex } from "lodash";
import { KnowledgeBaseManageArticles } from "modal-registry";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { loadAllArticleCollections } from "store/modules/article-collection/article-collection.helpers";
import { selectAllArticleCollections } from "store/modules/article-collection/article-collection.selectors";
import { loadAllArticles } from "store/modules/articles/articles.helpers";
import { selectAllArticles } from "store/modules/articles/articles.selectors";
import { loadAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.helpers";
import { selectAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSearch } from "utils/hooks/use-search";
import { useStateWithGetter } from "utils/hooks/use-state-with-getter";
import { useKeyboardListNavigation } from "../../../../utils/hooks/use-keyboard-list-navigation";
import { getArticleSlug } from "../helpers/get-article-slug";
import "./article-selector.scss";
import { ArticleItem, ArticlesFolderViewer } from "./articles-folder-viewer";

export const AddArticleButton = forwardRef(
  (
    {
      onPresetSelect,
      showRichText,
    }: {
      onPresetSelect: (preset: iPreset) => any;
      showRichText?: boolean;
    },
    ref,
  ) => {
    const { state: selfServicePortals } = useQueryWithStore(
      selectAllSelfServicePortals,
      loadAllSelfServicePortals,
    );

    const KnowledgeBaseUrl = useMemo(() => {
      if (selfServicePortals.length > 0) {
        const domainConfig = selfServicePortals?.[0]?.data?.domain;
        if (domainConfig) {
          const url =
            domainConfig.customDomainConfig?.domain &&
            domainConfig.customDomainConfig?.isVerified &&
            domainConfig.customDomainConfig?.isConfigured
              ? `https://${domainConfig.customDomainConfig?.domain}`
              : `https://${domainConfig.subdomain}.helpcenter.guide`;
          return url;
        }
        return "";
      }
      return "";
    }, [selfServicePortals]);

    const [popOverVisible, setPopoverVisibility] = useState(false);

    const isMobileView = useMediaQuery({ query: "(max-width: 800px)" });

    useImperativeHandle(
      ref,
      () => ({
        setPopoverVisibility: setPopoverVisibility,
      }),
      [],
    );
    if (!KnowledgeBaseUrl) {
      return <></>;
    }

    return (
      <Popover
        open={popOverVisible}
        onOpenChange={setPopoverVisibility}
        destroyTooltipOnHide={true}
        content={
          <ArticleExplorer
            onPresetSelect={onPresetSelect}
            showRichText={showRichText}
            onExit={() => setPopoverVisibility(false)}
          />
        }
        trigger="click"
        overlayClassName="article-selector-popover"
      >
        <Button
          // className="font-semibold"
          type="text"
          icon={<i className="ri-folder-5-line"></i>}
          size="small"
        >
          {!isMobileView && <> Knowledge Base</>}
        </Button>
      </Popover>
    );
  },
);

const ArticleExplorer = ({
  onPresetSelect: _onPresetSelect,
  showRichText,
  onExit,
}: {
  onPresetSelect: (preset: iPreset) => any;
  showRichText?: boolean;
  onExit: () => any;
}) => {
  const { state: selfServicePortals } = useQueryWithStore(
    selectAllSelfServicePortals,
    loadAllSelfServicePortals,
  );

  const KnowledgeBaseUrl = useMemo(() => {
    if (selfServicePortals.length > 0) {
      const domainConfig = selfServicePortals[0].data.domain;
      const url =
        domainConfig.customDomainConfig?.domain &&
        domainConfig.customDomainConfig?.isVerified &&
        domainConfig.customDomainConfig?.isConfigured
          ? `https://${domainConfig.customDomainConfig?.domain}`
          : `https://${domainConfig.subdomain}.helpcenter.guide`;
      return url;
    }
    return "";
  }, [selfServicePortals]);

  const onPresetSelect = useCallback(
    (article: iArticle) => {
      const preset = {
        id: article.id,
        value: `${article.title} (${KnowledgeBaseUrl}/articles/${getArticleSlug(
          article.title,
          article.id!,
        )})`,
        label: article.title,
        type: "SIMPLE_TEXT",
      } as iPreset;
      _onPresetSelect(preset);
      onExit();
    },
    [KnowledgeBaseUrl, _onPresetSelect, onExit],
  );

  const { state: collections, retry: reload } = useQueryWithStore(
    selectAllArticleCollections,
    loadAllArticleCollections,
  );
  const {
    state: allArticles,
    retry: reloadAllArticles,
    isLoading: allArticlesLoading,
  } = useQueryWithStore(selectAllArticles, loadAllArticles, []);

  const {
    filteredData: filteredArticles,
    searchTerm,
    setSearchTerm,
  } = useSearch(allArticles);

  const articlesWithoutFolders = useMemo(() => {
    return allArticles.filter((preset) => preset.collections.length === 0);
  }, [allArticles]);

  const { changePanelState } = useModalPanels();

  const currentList = searchTerm.length > 0 ? filteredArticles : allArticles;

  const containerRef = useRef<any>();

  const {
    index: selectedIndex,
    selected,
    set,
  } = useKeyboardListNavigation({
    list: currentList,
    onEnter: ({ element }) => element && onPresetSelect(element),
    ref: containerRef,
  });
  const onSelectItemUnderCursor = useCallback(() => {
    if (selected) {
      onPresetSelect(selected);
    }
  }, [onPresetSelect, selected]);

  // Auto Focus Search Input
  const searchInputRef = useRef<any>();
  useEffect(() => {
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 200);
  }, []);

  // When Moved through Cursor, automatically expand folders
  const [openedFolders, setOpenedFolders, getOpenedFolders] =
    useStateWithGetter([] as string[]);
  useEffect(() => {
    if (searchTerm.length === 0 && selected) {
      if (selected.collections?.[0]) {
        if (!getOpenedFolders().includes(selected.collections?.[0])) {
          setOpenedFolders([selected.collections?.[0]]);
        }
      }
    }
  }, [getOpenedFolders, searchTerm.length, selected, setOpenedFolders]);

  const currentUser = useSelector(selectCurrentUser);
  const userRole = useMemo(() => {
    const currentUserRole = getRoleFromPermission(
      currentUser?.permissions || [],
    );
    return currentUserRole;
  }, [currentUser?.permissions]);

  return (
    <div
      className="flex flex-col preset-selector p-4"
      style={{ width: 375, height: 450 }}
      ref={containerRef}
    >
      <div className="panel-title flex flex-row justify-between items-center">
        <div
          className={classnames(
            "flex flex-row items-center flex-1 font-bold text-xl",
          )}
        >
          <i className="ri-folder-5-line"></i>
          <div className="title ml-2">Knowledge Base</div>
        </div>
        {userRole === "Owner" && (
          <Button
            icon={<i className="ri-settings-2-line"></i>}
            type="text"
            shape="circle"
            onClick={(e) => {
              changePanelState(KnowledgeBaseManageArticles, true, {});
              onExit();
            }}
          />
        )}
      </div>

      <div className="body h-full flex-1 overflow-auto border-t border-gray-200 dark:border-gray-700 mt-2 pt-6">
        <div className="search-bar mb-6">
          <Input.Search
            placeholder="Search KnowledgeBase..."
            className="rounded-md"
            value={searchTerm}
            ref={searchInputRef}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoFocus={true}
            onSearch={onSelectItemUnderCursor}
          />
        </div>
        <div className="body">
          {searchTerm.length > 0 ? (
            <div className="search-results">
              <Divider className="text-sm">
                {filteredArticles.length} Articles
              </Divider>
              {filteredArticles.map((preset, index) => (
                <ArticleItem
                  key={preset.id}
                  article={preset}
                  onSelected={onPresetSelect}
                  isActive={selected?.id === preset.id}
                  onMouseOver={() => set({ cursor: index })}
                />
              ))}
              {filteredArticles.length === 0 && (
                <div className="text-xl my-6 mb-16 flex flex-col items-center">
                  <div>
                    <i className="ri-emotion-sad-line text-8xl text-blue-400"></i>
                  </div>
                  <div className="text-gray-500 leading-none">
                    Search yielded no results
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              <Divider className="text-sm">
                {allArticles.length} Articles
              </Divider>
              <div className="folders">
                <Collapse
                  className="border-0 w-full"
                  activeKey={openedFolders}
                  onChange={(key) =>
                    setOpenedFolders(Array.isArray(key) ? key : [key])
                  }
                  accordion
                  ghost
                >
                  {collections.map((collection) => (
                    <ArticlesFolderViewer
                      showRichText={showRichText}
                      key={collection.id}
                      collection={collection}
                      articleList={allArticles}
                      onSelected={onPresetSelect}
                      articleUnderCursor={selected?.id!}
                      setArticleUnderCursor={(presetId) => {
                        const index = findIndex(allArticles, { id: presetId });
                        set({ cursor: index });
                      }}
                    />
                  ))}
                </Collapse>
              </div>
              <div className="non-folder">
                {articlesWithoutFolders.map((preset) => (
                  <ArticleItem
                    key={preset.id}
                    article={preset}
                    onSelected={onPresetSelect}
                    isActive={selected?.id === preset.id}
                    onMouseOver={() => {
                      const index = findIndex(allArticles, { id: preset.id });
                      set({ cursor: index });
                    }}
                  />
                ))}
              </div>
              {allArticles.length === 0 && (
                <div className="text-xl my-6 mb-16 flex flex-col items-center">
                  <div>
                    <i className="ri-emotion-sad-line text-8xl text-blue-400"></i>
                  </div>
                  <div className="text-gray-500 leading-none">
                    Knowledge base is empty
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
