import { iTelegramConnectionData } from "@sdk/conversations/conversations.models";
import { Descriptions, Divider, Spin } from "antd";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { SectionHeader } from "components/common/section-header";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const ManageTelegramConnection = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const tgConnectionData = connection?.data as iTelegramConnectionData;

  return (
    <Spin
      spinning={false}
      indicator={<LoadingIndicatorWithoutSpin />}
      wrapperClassName="flex-1 flex fill-spinner"
    >
      <div className="animated fadeInLeftMin  flex flex-col w-full">
        <SectionHeader
          title="Connected Account"
          icon={<i className="ri-telegram-line"></i>}
          description="Channel Settings"
        />
        <Divider />
        {/* <div className="flex flex-row w-full justify-end items-center mb-4">
          <Space>
            <Button
              type="link"
              loading={isConversationSyncing}
              onClick={() => {
                syncConversations(connectionId);
              }}
              icon={<i className="ri-refresh-line"></i>}
            >
              (Re)Sync Conversations
            </Button>
          </Space>
        </div> */}

        <Descriptions
          title="Integration Details"
          bordered
          layout="horizontal"
          column={1}
          style={{ maxWidth: 600 }}
        >
          <Descriptions.Item label="Username">
            {tgConnectionData?.tgUserName}
          </Descriptions.Item>
          <Descriptions.Item label="ID">
            {tgConnectionData?.tgUserId}
          </Descriptions.Item>
        </Descriptions>

        <div
          className="p-4 rounded-lg border border-gray-300 dark:border-gray-700 flex flex-col cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800 mt-8"
          onClick={() => {
            (window as any).ccWidget?.onWidgetActivated(() => {
              (window as any).ccWidget?.triggerCampaign?.({
                message: "How can we improve Telegram Integration for you?",
              });
              (window as any).ccWidget.setWidgetVisibility(true);
            });
          }}
        >
          <div className="flex flex-row items-center">
            <div className="text-2xl">💡</div>
            <div className="text-xl font-bold ml-3">
              Suggest Telegram Integration Improvement
            </div>
          </div>

          <div className="text-gray-600">
            Share your feedback with us to improve Telegram Integration
          </div>
        </div>
      </div>
    </Spin>
  );
};
