import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { PlanCard } from "./pricing-plans";
import { iPricingPlan } from "./pricing-plans-data";
import { UpdateCard } from "./update-card";

export const ActivateSubscriptionModal = ({
  visible,
  onChangeVisibility,
  onActivated,
  isActivated,
  plan
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onActivated: () => any;
  isActivated?: boolean;
  plan?: iPricingPlan;
}) => {
  return (<>
    <Modal
      title={
        <ModalTitle
          title={
            isActivated ? "Update Billing Details" : "Activate Subscription"
          }
          icon={<i className="ri-bank-card-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Activate Subscriptions Modal"
    >
      <div className="flex flex-col w-full">
        {plan && (
          <>
            <div className="pricing-card">
              <PlanCard plan={plan} />
            </div>
            <div className="payment-details flex-1">
              <UpdateCard plan={plan} onSuccess={onActivated} />
            </div>
          </>
        )}
      </div>
      <DarkModeBg />
    </Modal>
  </>);
};
