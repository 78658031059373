import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationMode } from "electron-app-models";
import { QueryConfig } from "store/utils/query-config";
import {
  iEnteredMessages,
  iUIState,
  iWindowState,
  WindowSizes
} from "./ui-state.model";

export const DefaultConversationQueryConfig: QueryConfig = {
  query: {},
  options: { sortBy: ["-metaData.lastMessage.timestamp"] }
};

const defaultWindowState: iWindowState = {
  isPinned: false,
  isMinimized: false,
  isFullScreen: false,
  windowMode: "NORMAL"
};

export const uiStore_initialState: iUIState = {
  sideBar: {
    isCollapsed: true
  },
  theme: {
    isDarkMode: false
  },
  awayDetectorAlert: {
    isActive: false
  },
  conversationView: {
    lastAutoCancelScheduleMessagePreference: false,
    enteredMessages: {}
  },
  isAdvancedMode: false,
  isElectronMode: false,
  applicationMode: ApplicationMode.WINDOW,
  windowState: defaultWindowState
};

const UIStateSlice = createSlice({
  name: "uiState",
  initialState: uiStore_initialState,
  reducers: {
    setSideBarCollapsed(state, action: PayloadAction<boolean>) {
      state.sideBar.isCollapsed = action.payload;
    },
    setDarkMode(state, action: PayloadAction<boolean>) {
      if (!state.theme) {
        state.theme = {
          isDarkMode: action.payload
        };
      } else {
        state.theme.isDarkMode = action.payload;
      }
    },
    setLastAutoCancelScheduleMessagePreference(
      state,
      action: PayloadAction<boolean>
    ) {
      state.conversationView.lastAutoCancelScheduleMessagePreference =
        action.payload;
    },
    setEnteredMessages(
      state,
      action: PayloadAction<{
        conversationId: string;
        data: Partial<iEnteredMessages>;
      }>
    ) {
      if (!state.conversationView.enteredMessages) {
        state.conversationView.enteredMessages = {};
      }
      state.conversationView.enteredMessages = {
        ...state.conversationView.enteredMessages,
        [action.payload.conversationId]: {
          ...(state.conversationView.enteredMessages[
            action.payload.conversationId
          ] || {}),
          ...action.payload.data
        }
      };
    },
    setAwayDetectorState(state, action: PayloadAction<boolean>) {
      state.awayDetectorAlert = {
        isActive: action.payload
      };
    },
    setIsAdvancedMode(state, action: PayloadAction<boolean>) {
      state.isAdvancedMode = action.payload;
    },
    // Electron App - START
    setElectronMode(state, action: PayloadAction<boolean>) {
      state.isElectronMode = action.payload;
    },
    setWindowState(state, action: PayloadAction<iWindowState>) {
      state.windowState = action.payload;
    },
    setFullScreen(state, action: PayloadAction<boolean>) {
      if (!state.windowState) {
        state.windowState = { ...defaultWindowState };
      }
      state.windowState.isFullScreen = action.payload;
    },
    setIsPinned(state, action: PayloadAction<boolean>) {
      if (!state.windowState) {
        state.windowState = { ...defaultWindowState };
      }
      state.windowState.isPinned = action.payload;
    },
    setWindowSize(state, action: PayloadAction<WindowSizes>) {
      if (!state.windowState) {
        state.windowState = { ...defaultWindowState };
      }
      state.windowState.windowMode = action.payload;
    }
    // Electron App - END
  }
});

export const UIStateSliceReducers = UIStateSlice.reducer;

export const UIStateSliceActions = UIStateSlice.actions;
