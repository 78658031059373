import { message } from "antd";
import axios, { Method } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { useStateIfMountedX } from "utils/hooks/use-state-if-mounted";
import { SDK } from "./sdk";
import { PaginatedOptions, PaginatedResults } from "./utils/paginated-results";

export const useSDK = <T extends unknown>(
  callFunction: (sdk: typeof SDK) => Promise<T>,
  dependencies: any[] = [],
  disable?: boolean,
  defaultValue = {},
) => {
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState(defaultValue as T);
  const [isLoading, setLoading] = useState(true);
  const [reloadCounter, setReloadCounter] = useState(0);
  const reload = useCallback(() => {
    setReloadCounter((reloadCounter) => reloadCounter + 1);
  }, []);

  useEffect(() => {
    setLoading(true);
    const callAPI = async () => {
      let d = await callFunction(SDK);
      setData(d);
      setLoading(false);
      setHasError(false);
    };
    if (!disable) {
      callAPI().catch((d) => {
        setLoading(false);
        setHasError(true);
        setData(defaultValue as T);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, reloadCounter, disable]);
  return { data, isLoading, error: hasError, reload, setData };
};

interface iSDKActionConfig<T> {
  action: (sdk: typeof SDK) => (...args) => Promise<T>;
  successMessage?: string;
  onSuccess?: (res) => any;
  failureMessage?: string;
  onFailed?: (error: any) => any;
  actionDependencies?: any[];
  defaultResponse?: any;
  throwError?: boolean;
}

export const processServerError = (e, defaultErrorMessage?: string) => {
  let failureMessage =
    (e as any)?.response?.data?.message ||
    defaultErrorMessage ||
    (e as any)?.message;

  if (failureMessage.includes("Converting circular structure to JSON")) {
    failureMessage = defaultErrorMessage;
  }

  return failureMessage;
};

export const useSDKActionWithDeps = <T extends unknown>(
  configGenerator: () => iSDKActionConfig<T>,
  dependencies: any[],
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const config = useMemo(configGenerator, dependencies);
  const [hasError, setHasError] = useStateIfMountedX(false);
  const [response, setResponse] = useStateIfMountedX(
    (config.defaultResponse || {}) as T,
  );
  const [isProcessing, setIsProcessing] = useStateIfMountedX(false);
  const dispatch = useDispatch();

  const doAction: (...args: any[]) => Promise<T | undefined> = useCallback(
    async (...args) => {
      try {
        setIsProcessing(true);
        let res = await config.action(SDK)(...args);
        setResponse(res);
        setIsProcessing(false);
        setHasError(false);
        config.successMessage && message.success(config.successMessage);
        config.onSuccess && config.onSuccess(res);
        return res;
      } catch (e) {
        setHasError(true);
        setIsProcessing(false);
        const failureMessage = processServerError(e, config?.failureMessage);
        config.failureMessage && message.error(failureMessage);
        config.onFailed && config.onFailed(e);
        if (config.throwError) {
          throw e;
        }
      }
    },
    [setHasError, setIsProcessing, setResponse, config],
  );

  return {
    response,
    isProcessing,
    hasError,
    doAction,
    dispatch,
  };
};

export const useSDKAction = <T extends unknown>(
  config: {
    action: (sdk: typeof SDK) => (...args) => Promise<T>;
    successMessage?: string;
    onSuccess?: (res) => any;
    failureMessage?: string;
    onFailed?: (error: any) => any;
    actionDependencies?: any[];
  },
  defaultResponse: any = {},
) => {
  const [hasError, setHasError] = useStateIfMountedX(false);
  const [response, setResponse] = useStateIfMountedX(defaultResponse as T);
  const [isProcessing, setIsProcessing] = useStateIfMountedX(false);
  const dispatch = useDispatch();

  const doAction = useCallback(
    async (...args) => {
      try {
        setIsProcessing(true);
        let res = await config.action(SDK)(...args);
        setResponse(res);
        setIsProcessing(false);
        setHasError(false);
        config.successMessage && message.success(config.successMessage);
        config.onSuccess && config.onSuccess(res);
      } catch (e) {
        setHasError(true);
        setIsProcessing(false);
        const failureMessage = processServerError(e, config?.failureMessage);

        config.failureMessage && message.error(failureMessage);
        config.onFailed && config.onFailed(e);
      }
    },
    [setHasError, setIsProcessing, setResponse, config],
  );

  return {
    response,
    isProcessing,
    hasError,
    doAction,
    dispatch,
  };
};

export const useSDKWithRemotePagination = <T extends unknown>(
  callFunction: (
    sdk: typeof SDK,
  ) => (
    query: any,
    paginationOptions: PaginatedOptions,
  ) => Promise<PaginatedResults<T>>,
  query: any,
  dependencies: any[] = [],
  {
    pageSize: initialPageSize = 20,
    currentPage: initialStartPage = 1,
    paginationOptions = {},
  }: {
    pageSize?: number;
    currentPage?: number;
    paginationOptions?: PaginatedOptions;
  },
  disable?: boolean,
) => {
  const [paginationState, setPaginationState] = useSimpleState({
    totalItems: 0,
    pageSize: initialPageSize,
    currentPage: initialStartPage,
  });

  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState([] as T[]);
  const [isLoading, setLoading] = useState(true);
  const [reloadCounter, setReloadCounter] = useState(0);
  const reload = useCallback(
    (skipReset?: boolean) => {
      if (!skipReset) {
        setPaginationState({
          currentPage: 1,
        });
      }
      setReloadCounter((reloadCounter) => reloadCounter + 1);
    },
    [setPaginationState],
  );

  useEffect(
    () => {
      setLoading(true);
      const callAPI = async () => {
        let d = await callFunction(SDK).bind(SDK)(query, {
          offset: (paginationState.currentPage - 1) * paginationState.pageSize,
          limit: paginationState.pageSize,
          ...paginationOptions,
        });
        setPaginationState({
          totalItems: d.totalDocs,
        });
        setData(d.docs);
        setLoading(false);
      };
      if (!disable) {
        callAPI()
          .then((d) => {
            setHasError(false);
          })
          .catch((e) => {
            setHasError(true);
          });
      } else {
        setLoading(false);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      reloadCounter,
      paginationState.pageSize,
      paginationState.currentPage,
      disable,
    ],
  );

  useEffectWhen(
    () => {
      reload();
    },
    [paginationState.currentPage, reload, setPaginationState],
    [...dependencies],
  );

  return {
    paginationState,
    setPaginationState,
    data,
    reload,
    isLoading,
    setData,
  };
};

export const useAxios = (
  method: Method,
  url: string,
  postData?: any,
  headers?: any,
  disabled?: boolean,
) => {
  const [data, setData] = useState({});
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reloadCounter, setReloadCounter] = useState(0);
  const reload = useCallback(() => {
    setReloadCounter((reloadCounter) => reloadCounter + 1);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: response } = await axios(url, {
          method: method,
          data: postData,
          headers,
        });
        setData(response);
        setHasError(false);
      } catch (error) {
        setHasError(true);
      }
      setLoading(false);
    };
    if (!disabled) {
      fetchData();
    }
  }, [disabled, headers, method, postData, reloadCounter, url]);

  return {
    data,
    loading,
    reload,
    hasError,
  };
};

export const useAxiosAction = (
  method: Method,
  url: string,
  apiOptions: {
    onSuccess?: (response) => any;
    onError?: (response) => any;
  } = {},
) => {
  const [data, setData] = useState({});
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  const callAPI = useCallback(
    async (postData?: any, headers?: any) => {
      try {
        setLoading(true);
        const { data: response } = await axios(url, {
          method: method,
          data: postData,
          headers,
        });
        setData(response);
        setHasError(false);
        apiOptions.onSuccess && apiOptions.onSuccess(response);
      } catch (error) {
        setHasError(true);
        apiOptions.onError && apiOptions.onError(error);
      }
      setLoading(false);
    },
    [apiOptions, method, url],
  );

  return {
    callAPI,
    data,
    loading,
    hasError,
  };
};
