import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { KnowledgeQueryLogsViewer } from "./knowledge-query-logs-viewer";

export const KnowledgeQueryLogsViewerModal = ({
  visible,
  onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-folder-5-line"></i>}
          title="Knowledge Query Logs"
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Knowledge Query Logs Viewer Modal"
      width={960}
    >
      <KnowledgeQueryLogsViewer hideTitle={true} />
      <DarkModeBg />
    </Modal>
  );
};
