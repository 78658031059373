export function cleanHtmlForSimpleEmail(html) {
  const dom = new DOMParser().parseFromString(
    "<!doctype html><body>" + html,
    "text/html",
  );

  const allElements = dom.querySelectorAll("*");

  allElements.forEach((element) => {
    element.removeAttribute("class");
    element.removeAttribute("id");
  });

  return dom.body.innerHTML || "";
}
