import { Button, Divider, Drawer, Input, Tag } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useMemo } from "react";
import { useSearch } from "utils/hooks/use-search";
import {
  ActivitiesReportWidgetPresets,
  CompaniesReportWidgetPresets,
  ContactsReportWidgetPresets,
  OpportunitiesReportWidgetPresets,
  ReportWidgetPresets,
} from "../../schemas/report-widget-presets";
import {
  iReportCanvas,
  iReportWidget,
} from "../report-canvas/widget-canvas-models";
import { ChartTypeIcons } from "../report-widget/report-widget";

const ReportTypeLabelMap = {
  CONVERSATION: "Conversation Report Widgets",
  LEADS: "Lead Report Widgets",
  ACTIVITIES: "Activities Report Widget",
  USER_REPORT: "User Report Widgets",
  COMPANIES: "Companies Report Widgets",
  OPPORTUNITIES: "Opportunities Report Widgets",
};

export const AddReportWidgetDrawer = ({
  isVisible,
  onChangeVisibility,
  onAddWidget,
  canvasType,
}: {
  isVisible;
  onChangeVisibility: (state: boolean) => any;
  onAddWidget: (widget: iReportWidget) => any;
  canvasType?: iReportCanvas["type"];
}) => {
  const reportPresets = useMemo(() => {
    if (canvasType === "CONVERSATION") {
      return [...ReportWidgetPresets];
    }
    if (canvasType === "LEADS") {
      return [...ContactsReportWidgetPresets];
    }
    if (canvasType === "ACTIVITIES") {
      return [...ActivitiesReportWidgetPresets];
    }
    if (canvasType === "COMPANIES") {
      return [...CompaniesReportWidgetPresets];
    }
    if (canvasType === "OPPORTUNITIES") {
      return [...OpportunitiesReportWidgetPresets];
    }

    return [
      ...ReportWidgetPresets,
      ...ContactsReportWidgetPresets,
      ...ActivitiesReportWidgetPresets,
      ...CompaniesReportWidgetPresets,
      ...OpportunitiesReportWidgetPresets,
    ];
  }, [canvasType]);

  const { filteredData, searchTerm, setSearchTerm } = useSearch(reportPresets);

  const groupedResults = useMemo(() => {
    const grouped = _.groupBy(filteredData, "type");

    return Object.keys(grouped).map((type) => ({
      type: type,
      reports: grouped[type],
    }));
  }, [filteredData]);

  return (
    <Drawer
      title={
        <ModalTitle
          title={"Add Report Widget"}
          icon={<i className="ri-add-line"></i>}
        />
      }
      placement="right"
      onClose={(e) => onChangeVisibility(false)}
      open={isVisible}
      width={"60vw"}
      bodyStyle={{ padding: 0 }}
    >
      <div className="search-bar p-4">
        <div className="bg-white dark:bg-gray-900">
          <Input.Search
            placeholder="Search Report Widgets..."
            className="rounded-md"
            value={searchTerm}
            onChange={(e) => {
              // if (e.target.value.length > 2) {
              setSearchTerm(e.target.value);
              //setArticleSearchTerm(e.target.value);
              // }
            }}
          />
        </div>
      </div>
      <div className="bg-gray dark:bg-gray-900 min-h-full py-8 p-4">
        {groupedResults.map((group) => (
          <div key={group.type}>
            <Divider>
              <Tag>{ReportTypeLabelMap[group.type]}</Tag>
            </Divider>
            <div className="flex flex-row flex-wrap gap-8">
              {group.reports.map((item) => (
                <div
                  className="border border-gray-300 dark:border-gray-600 rounded-lg p-6 pb-2 bg-white dark:bg-black cursor-pointer flex flex-col hover:bg-gray-100 dark:hover:bg-gray-800"
                  style={{ width: 300, height: 150 }}
                  onClick={() => {
                    // setWidgets([
                    //   ...widgets,
                    //   {
                    //     i: uuidv4(),
                    //     x: 0,
                    //     y: 2,
                    //     w: 2,
                    //     h: 2,
                    //     ...item,
                    //   },
                    // ]);
                  }}
                  key={item.id}
                >
                  <div className="header font-bold">{item.title}</div>
                  <div className="description text-gray-600 text-xs truncate">
                    {item.description}
                  </div>
                  <div className="illustration flex-1 text-4xl text-gray-300 flex flex-row justify-end items-center">
                    {item.reportViewConfig.defaultVisualization ? (
                      ChartTypeIcons[item.reportViewConfig.defaultVisualization]
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="footer">
                    <Button
                      onClick={() => onAddWidget(item)}
                      type="text"
                      block
                      icon={<i className="ri-add-line"></i>}
                    >
                      Add to Report
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        {groupedResults.length === 0 && (
          <div className="flex flex-row justify-center items-center w-full">
            <EmptyData text="No widgets to show" icon="ri-inbox-line" />
          </div>
        )}
      </div>
      <DarkModeBg />
    </Drawer>
  );
};
