import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { iScheduledReport } from "./scheduled-reports";

export class ScheduledReportsService {
  constructor(protected config: { basePath: string; token: string }) {}

  /* ---------------------------------- Scheduled Reports --------------------------------- */

  scheduledReports = Object.assign(
    new EntityServices<iScheduledReport>(this.config, "scheduled-reports", {
      onCreate: () => {
        UserTracker.track("SCHEDULED_REPORTS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("SCHEDULED_REPORTS - Delete", {});
      },
    }),
    {},
  );
}
