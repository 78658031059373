import { EntityServices } from "@sdk/utils/entity.service";
import { iCallLog } from "./call-logs-model";

export class CallLogsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  callLogs = Object.assign(
    new EntityServices<iCallLog>(this.config, "call-logs"),
    {}
  ) as EntityServices<iCallLog>;
}
