import { ShopifyActions } from "components/modules/conversations/components/action-editor/actions";

export type NodeTypes = "CONDITION_NODE" | "ACTION_NODE" | "MESSAGE_NODE";

export type iValidationType =
  | "NONE"
  | "WORK_EMAIL"
  | "EMAIL"
  | "MOBILE_NUMBER"
  | "DATE"
  | "NUMBER"
  | "REGEX";

export type iPersistenceTarget =
  | "NONE"
  | "CONTACT_NAME"
  | "CONTACT_EMAIL"
  | "CONTACT_MOBILE_NUMBER"
  | "CONTEXT";

export interface iMessageEmitBlock {
  type: string;
  value: string;
}

export interface iMessageAutoFollowupConfig {
  threshold: number;
  message: string;
}

export interface iMessageNode {
  text: string;
  quickButtons: { id: string; label: string }[];
  attachments?: string[];
  validation?: {
    type?: iValidationType;
    invalidMessage?: string;
    regex?: string;
  };
  persistence?: {
    target?: iPersistenceTarget;
    key?: string;
  };
  processNextNodeImmediately?: boolean;
  skipIfDataAlreadyCollection?: boolean;
  textVariants?: string[];
  messageEmitBlocks?: iMessageEmitBlock[];
  autoFollowUpConfig?: iMessageAutoFollowupConfig;
}

export interface iGPTAssistantNode {
  inputType: "GPT_ASSISTANT";
  text: string;
  quickButtons?: { id: string; label: string }[];
  // Just pass to the next node
  // messageOnFailure: string;
  // messageOnNotHelpful: string;
}

export type iConditionTarget =
  | "REPLY_TEXT"
  | "INTENT"
  | "CONTACT_FIRST_NAME"
  | "CONTACT_LAST_NAME"
  | "CONTACT_EMAIL"
  | "CONTACT_PHONE"
  | "CONVERSATION_CONNECTION_TYPE"
  | "CONTEXT";

export const DataCollectorLabel: { [key: string]: string } = {
  NONE: "None",
  CONTACT_NAME: "Contact Name",
  CONTACT_EMAIL: "Contact Email",
  CONTACT_MOBILE_NUMBER: "Contact Mobile Number",
  CONTEXT: "",
};

export type iConditionTypes = "CONTAINS" | "EXIST" | "DOES_NOT_EXIST";

export interface iConditionNode {
  conditionTarget: iConditionTarget;
  conditionTargetConfig?: {
    propertyName?: string;
  };
  condition: iConditionTypes;
  conditionConfig?: {
    input: string;
  };
}

export interface iMessageConfig {
  text: string;
  attachments?: string[];
}

export type iActionTypes =
  | "END_CONVERSATION"
  | "ROUTE_TO_HUMAN"
  | "ADD_CONVERSATION_TAG"
  | "REMOVE_CONVERSATION_TAG"
  | "ADD_CONTACT_TAG"
  | "REMOVE_CONTACT_TAG"
  | "ADD_MARKETING_LIST"
  | "REMOVE_MARKETING_LIST"
  | "UNSUBSCRIBE_MARKETING_LIST"
  | "SUBSCRIBE_MAILCHIMP"
  | "SEND_MESSAGE"
  | "ACTIVATE_CHAT_BOT"
  | ShopifyActions;

export interface iActionNode {
  action: iActionTypes;
  data: {
    userGroup?: string;
    secondaryUserGroup?: string;
    routingStrategy?: "ROUND_ROBIN" | "RANDOM" | "NO_ROUTE";
    routingMethod?: "INVITE" | "ASSIGN";
    routeOnlyToOnlineUsers?: boolean;
    tags?: string[];
    marketingList?: string;
    mailChimpIntegration?: {
      apiKey?: string;
      listId?: string;
    };
    messageConfig?: iMessageConfig;
  };
}

export type AvailableNodes =
  | iMessageNode
  | iActionNode
  | iConditionNode
  | iGPTAssistantNode;
