import { defaultAdditionalExcludedBraftControllers } from "components/common/draft-js-advanced/default-excluded-braft-controllers";
import { BraftJSInput } from "components/common/draft-js-advanced/draft-js-advanced";
import { useMemo, useRef, useState } from "react";
import { convertHtmlToPlainText } from "utils/html-to-plain-text";

export const InlineBraftEditor = ({
  value,
  onChange,
  placeholder,
  defaultValue,
  disableBlurFeedback,
}: {
  value?: string;
  onChange?: (value: string) => any;
  placeholder?: string;
  defaultValue?: string;
  disableBlurFeedback?: boolean;
}) => {
  const draftJsRef = useRef<any>();
  const [isEditMode, setEditMode] = useState(true);

  const plainText = useMemo(() => {
    return convertHtmlToPlainText(value || "");
  }, [value]);

  return (
    <>
      {!isEditMode && (
        <div
          dangerouslySetInnerHTML={{
            __html: plainText ? value! : placeholder || "Click to edit",
          }}
          className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 p-4"
          onClick={() => {
            setEditMode(true);
            setTimeout(() => {
              draftJsRef.current?.focusInput();
            }, 200);
          }}
        />
      )}

      {isEditMode && (
        <div className="draft-js-container border border-gray-200 dark:border-gray-700 rounded-lg">
          <BraftJSInput
            initialValue={value || defaultValue || ""}
            ref={draftJsRef}
            additionalExcludedControls={
              defaultAdditionalExcludedBraftControllers
            }
            onBlur={() => {
              const value = draftJsRef.current.getHtmlContent();
              onChange && onChange(value);
              if (!disableBlurFeedback) {
                setEditMode(false);
              }
            }}
            showControlsOnTop={true}
          />
        </div>
      )}
    </>
  );
};
