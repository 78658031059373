import { iUserAppPermission } from "@sdk/user-management/user-management.models";
import { find, flattenDeep, isEqual, sortBy } from "lodash";

export enum Apps {
  TEXTS = "TEXTS",
  ORGANIZATION_MANAGEMENT = "ORGANIZATION_MANAGEMENT",
  CRM = "CRM",
  CONVERSATION = "CONVERSATION",
  HELP_DESK = "HELP_DESK",
  REPORTS = "REPORTS",
}

export enum HelpDeskScopes {
  CREATE_INBOXES = "CREATE_INBOXES",
  MANAGE_INBOXES = "MANAGE_INBOXES",
}
export enum ReportScopes {
  VIEW_REPORT = "VIEW_REPORT",
}

export enum OrganizationManagementScopes {
  ADMIN_ACCOUNT = "ADMIN_ACCOUNT",
}

export enum CRMScopes {
  MANAGE_CONTACTS = "MANAGE_CONTACTS",
  MANAGE_AUTOMATIONS = "MANAGE_AUTOMATIONS",
}

export enum ConversationScopes {
  CHAT = "CHAT",
}

export const Roles: {
  label: string;
  permissions: iUserAppPermission[];
}[] = [
  {
    label: "Owner",
    permissions: [
      {
        appId: Apps.TEXTS,
        scopes: [
          {
            scope: HelpDeskScopes.CREATE_INBOXES,
            data: {},
          },
          {
            scope: HelpDeskScopes.MANAGE_INBOXES,
            data: {},
          },
        ],
      },
      {
        appId: Apps.ORGANIZATION_MANAGEMENT,
        scopes: [
          {
            scope: OrganizationManagementScopes.ADMIN_ACCOUNT,
            data: {},
          },
        ],
      },
      {
        appId: Apps.CRM,
        scopes: [
          {
            scope: CRMScopes.MANAGE_AUTOMATIONS,
            data: {},
          },
          {
            scope: CRMScopes.MANAGE_CONTACTS,
            data: {},
          },
        ],
      },
      {
        appId: Apps.CONVERSATION,
        scopes: [
          {
            scope: ConversationScopes.CHAT,
            data: {},
          },
        ],
      },
      {
        appId: Apps.REPORTS,
        scopes: [
          {
            scope: ReportScopes.VIEW_REPORT,
            data: {},
          },
        ],
      },
    ],
  },
  {
    label: "Manager",
    permissions: [
      {
        appId: Apps.CONVERSATION,
        scopes: [
          {
            scope: ConversationScopes.CHAT,
            data: {},
          },
        ],
      },
      {
        appId: Apps.REPORTS,
        scopes: [
          {
            scope: ReportScopes.VIEW_REPORT,
            data: {},
          },
        ],
      },
    ],
  },
  {
    label: "Team Member",
    permissions: [
      {
        appId: Apps.CONVERSATION,
        scopes: [
          {
            scope: ConversationScopes.CHAT,
            data: {},
          },
        ],
      },
    ],
  },
];

export const getRoleFromPermission = (permissions: iUserAppPermission[]) => {
  const currentScopes = flattenDeep(
    permissions.map((permission) =>
      permission.scopes.map((scope) => ({
        app: permission.appId,
        scope: scope.scope,
      }))
    )
  ).map((scope) => `${scope.app}:${scope.scope}`);
  for (const role of Roles) {
    const requiredScopesForRole = flattenDeep(
      role.permissions.map((permission) =>
        permission.scopes.map((scope) => ({
          app: permission.appId,
          scope: scope.scope,
        }))
      )
    ).map((scope) => `${scope.app}:${scope.scope}`);
    if (isEqual(sortBy(requiredScopesForRole), sortBy(currentScopes))) {
      return role.label;
    }
  }
  if (currentScopes.length > 0) {
    return "CUSTOM";
  }
  return "N/A";
};

export const getPermissionFromRole = (role: string) => {
  return find(Roles, { label: role })?.permissions;
};
