import { mdiBullhornOutline } from "@mdi/js";
import { Form, Input, Select } from "antd";
import { MDIIcon } from "components/common/mdi-icon";
import { SimpleCollapse } from "components/common/simple-collapse/simple-collapse";
import { MailchimpListSelector } from "components/modules/organization-management/general/apps-and-integrations/mailchimp-list-selector/mailchimp-list-selector";
import { iActionTypes } from "components/pages/bot-builder/data-model";
import { ChatBotSelector } from "components/pages/chat-bots/chat-bot-selector/chat-bot-selector";
import { ContactListSelector } from "components/pages/contacts/components/contact-list-selector/contact-list-selector";
import React from "react";
import { useSelector } from "react-redux";
import { selectAllGroups } from "store/modules/groups/groups.selectors";
import { cancellationReasons } from "../chat-view-side-bar/components/shopify-side-bar/cancel-shopify-order-modal";
import { ConversationTagSelector } from "../converation-tag-selector";
import { MessageConfigInput } from "./action-editor";

export const ShopifyActionLabels = {
  SHOPIFY_LAST_ORDER_CANCEL: "Cancel Last Order",
  SHOPIFY_ORDER_CANCEL: "Cancel Order",
  SHOPIFY_LAST_ORDER_DUPLICATE: "Duplicate Last Order",
  SHOPIFY_LAST_ORDER_EDIT_SHIPPING_ADDRESS: "Edit Shipping Address",
  SHOPIFY_LAST_ORDER_REFUND_SHIPPING_COST: "Refund Shipping Cost",
  SHOPIFY_LAST_ORDER_REFUND: "RefundLast Order",
  SHOPIFY_LAST_ORDER_PARTIAL_REFUND: "Partially Refund Last Order",
  SHOPIFY_LAST_ORDER_EDIT_NOTE: "Edit Last Order's Note",
};

export type ShopifyActions =
  | "SHOPIFY_LAST_ORDER_CANCEL"
  | "SHOPIFY_ORDER_CANCEL"
  | "SHOPIFY_LAST_ORDER_DUPLICATE"
  | "SHOPIFY_LAST_ORDER_EDIT_SHIPPING_ADDRESS"
  | "SHOPIFY_LAST_ORDER_REFUND_SHIPPING_COST"
  | "SHOPIFY_LAST_ORDER_REFUND"
  | "SHOPIFY_LAST_ORDER_PARTIAL_REFUND"
  | "SHOPIFY_LAST_ORDER_EDIT_NOTE";

export const ShopifyActionDescriptions = {
  SHOPIFY_LAST_ORDER_CANCEL: "Cancel Last Order of current customer",
  SHOPIFY_ORDER_CANCEL: "Cancel Order with custom order Id of current customer",
  SHOPIFY_LAST_ORDER_DUPLICATE: "Duplicate Order with the order ID",
  SHOPIFY_LAST_ORDER_EDIT_SHIPPING_ADDRESS:
    "Edit Shipping Address of last order",
  SHOPIFY_LAST_ORDER_REFUND_SHIPPING_COST: "Refund Shipping Cost of last order",
  SHOPIFY_LAST_ORDER_REFUND: "Refund last order",
  SHOPIFY_LAST_ORDER_PARTIAL_REFUND: "Partially Refund Last Order",
  SHOPIFY_LAST_ORDER_EDIT_NOTE: "Edit Last Order's Note in Shopify Store",
};

export const ActionLabels: Record<iActionTypes, string> = {
  END_CONVERSATION: "End Conversation",
  ROUTE_TO_HUMAN: "Route to Human",
  ADD_CONVERSATION_TAG: "Add Tag to Chat",
  REMOVE_CONVERSATION_TAG: "Remove Tag from Chat",
  ADD_CONTACT_TAG: "Add Tag to Contact",
  REMOVE_CONTACT_TAG: "Remove Tag from Contact",
  ADD_MARKETING_LIST: "Add to Marketing List",
  UNSUBSCRIBE_MARKETING_LIST: "Unsubscribe from Marketing List",
  REMOVE_MARKETING_LIST: "Remove From Marketing List",
  SUBSCRIBE_MAILCHIMP: "Subscribe to Mailchimp List",
  SEND_MESSAGE: "Send a Message",
  ACTIVATE_CHAT_BOT: "Activate Chat Bot",
  ...ShopifyActionLabels,
};

export const ActionDescriptions: Record<iActionTypes, string> = {
  END_CONVERSATION: "Close the current conversation",
  ROUTE_TO_HUMAN: "Route the chat to your team to handle",
  ADD_CONVERSATION_TAG: "Tag the current conversation",
  REMOVE_CONVERSATION_TAG: "Remove a tag from the current conversation",
  SEND_MESSAGE: "Send a new message in the current conversation",
  ADD_CONTACT_TAG: "Tag the current contact record",
  REMOVE_CONTACT_TAG: "Remove a tag from the current contact record",
  ADD_MARKETING_LIST: "Add the current contact to selected marketing list",
  UNSUBSCRIBE_MARKETING_LIST:
    "Unsubscribe the current contact to selected marketing list",
  REMOVE_MARKETING_LIST:
    "Remove the current contact to selected marketing list",
  SUBSCRIBE_MAILCHIMP: "Add the current contact to your Mailchimp list",
  ACTIVATE_CHAT_BOT:
    "Instantly activate a chatbot for the ongoing conversation, deactivating any existing bots",
  ...ShopifyActionDescriptions,
};

export const ActionIcons = {
  END_CONVERSATION: <i className="ri-check-line"></i>,
  ROUTE_TO_HUMAN: <i className="ri-route-line"></i>,
  ADD_CONVERSATION_TAG: <i className="ri-price-tag-3-line"></i>,
  REMOVE_CONVERSATION_TAG: <i className="ri-price-tag-3-line"></i>,
  ADD_CONTACT_TAG: <i className="ri-price-tag-3-line"></i>,
  REMOVE_CONTACT_TAG: <i className="ri-price-tag-3-line"></i>,
  ADD_MARKETING_LIST: <MDIIcon icon={mdiBullhornOutline} />,
  UNSUBSCRIBE_MARKETING_LIST: <i className="ri-close-line"></i>,
  REMOVE_MARKETING_LIST: <i className="ri-delete-bin-line"></i>,
  SUBSCRIBE_MAILCHIMP: (
    <img
      src="/assets/integrations/mailchimp.png"
      style={{ width: 20 }}
      alt=""
      className=""
    />
  ),
  SEND_MESSAGE: <i className="ri-send-plane-2-line"></i>,
  ACTIVATE_CHAT_BOT: <i className="ri-robot-line"></i>,
};

export const AllActions: iActionTypes[] = [
  "END_CONVERSATION",
  "ROUTE_TO_HUMAN",
  "ADD_CONVERSATION_TAG",
  "REMOVE_CONVERSATION_TAG",
  "ADD_CONTACT_TAG",
  "REMOVE_CONTACT_TAG",
  "ADD_MARKETING_LIST",
  "UNSUBSCRIBE_MARKETING_LIST",
  "SUBSCRIBE_MAILCHIMP",
  "SEND_MESSAGE",
  "ACTIVATE_CHAT_BOT",
];

export const AllActionsByGroup = [
  {
    label: "Conversation Actions",
    actions: [
      "END_CONVERSATION",
      "ROUTE_TO_HUMAN",
      "ADD_CONVERSATION_TAG",
      "REMOVE_CONVERSATION_TAG",
      "SEND_MESSAGE",
      "ACTIVATE_CHAT_BOT",
    ],
  },
  {
    label: "Contact Actions",
    actions: [
      "ADD_CONTACT_TAG",
      "REMOVE_CONTACT_TAG",
      "ADD_MARKETING_LIST",
      "UNSUBSCRIBE_MARKETING_LIST",
      "SUBSCRIBE_MAILCHIMP",
    ],
  },
  {
    label: "Shopify Actions",
    actions: Object.keys(ShopifyActionLabels).filter(
      (e) =>
        ![
          "SHOPIFY_LAST_ORDER_REFUND_SHIPPING_COST",
          "SHOPIFY_LAST_ORDER_PARTIAL_REFUND",
          "SHOPIFY_LAST_ORDER_EDIT_SHIPPING_ADDRESS",
          "SHOPIFY_LAST_ORDER_DUPLICATE",
        ].includes(e),
    ),
  },
];

export const ActionConfigLabels = {
  END_CONVERSATION: "",
  ROUTE_TO_HUMAN: "Routing Configuration",
  ADD_CONVERSATION_TAG: "Tag Configuration",
  REMOVE_CONVERSATION_TAG: "Tag Configuration",
  ADD_CONTACT_TAG: "Tag Configuration",
  REMOVE_CONTACT_TAG: "Tag Configuration",
  ADD_MARKETING_LIST: "Tag Configuration",
  UNSUBSCRIBE_MARKETING_LIST: "List Configuration",
  REMOVE_MARKETING_LIST: "List Configuration",
  SUBSCRIBE_MAILCHIMP: "List Configuration",
  SEND_MESSAGE: "Message",
  ACTIVATE_CHAT_BOT: "Activate Chat Bot",
  ...ShopifyActionLabels,
};

export const ActionConfig = ({
  actionType,
  actionConfigHeader,
  fieldItemContainer,
  ignoreLabel,
  noFormItemStyle,
  parentFieldName = [],
}: {
  actionType: iActionTypes;
  actionConfigHeader?: JSX.Element;
  fieldItemContainer?;
  ignoreLabel?: boolean;
  noFormItemStyle?: boolean;
  parentFieldName?: (string | number)[];
}) => {
  const userGroups = useSelector(selectAllGroups);
  const FieldItemContainer = fieldItemContainer || React.Fragment;

  if (actionType === "ROUTE_TO_HUMAN") {
    return (
      <>
        {actionConfigHeader}
        <FieldItemContainer>
          <Form.Item
            name={[...parentFieldName, "data", "userGroup"]}
            label={<div className="font-bold">User Group</div>}
            help="Select the user groups that can receive conversation invites"
            rules={[]}
          >
            <Select allowClear>
              {(userGroups || []).map((userGroup) => (
                <Select.Option key={userGroup.label} value={userGroup.id}>
                  {userGroup.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </FieldItemContainer>
        <FieldItemContainer>
          <SimpleCollapse title="Secondary User Group">
            <Form.Item
              name={[...parentFieldName, "data", "secondaryUserGroup"]}
              label={<div className="font-bold">User Group (Secondary)</div>}
              help="Select the user groups that can receive conversation invites if no one is available in primary user group"
              rules={[]}
            >
              <Select allowClear>
                {(userGroups || []).map((userGroup) => (
                  <Select.Option key={userGroup.label} value={userGroup.id}>
                    {userGroup.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </SimpleCollapse>
        </FieldItemContainer>

        <FieldItemContainer>
          <Form.Item
            label={<div className="font-bold">Routing Method</div>}
            name={[...parentFieldName, "data", "routingMethod"]}
            help="How routed user gets the invite"
            rules={[
              {
                required: true,
                message: "Please enter a property name",
              },
            ]}
          >
            <Select defaultValue="ASSIGN">
              <Select.Option value={"INVITE"}>Invite User</Select.Option>
              <Select.Option value={"ASSIGN"}>Assign User</Select.Option>
            </Select>
          </Form.Item>
        </FieldItemContainer>

        <div className="mb-8"></div>
      </>
    );
  } else if (
    actionType === "ADD_CONVERSATION_TAG" ||
    actionType === "REMOVE_CONVERSATION_TAG"
  ) {
    return (
      <>
        {actionConfigHeader}
        <FieldItemContainer>
          <Form.Item
            name={[...parentFieldName, "data", "tags"]}
            label={!ignoreLabel && <div className="font-bold">Select Tags</div>}
            rules={[
              {
                required: true,
                message: "Please select a tag",
              },
            ]}
            noStyle={noFormItemStyle}
          >
            <ConversationTagSelector />
          </Form.Item>
        </FieldItemContainer>
      </>
    );
  } else if (
    actionType === "ADD_CONTACT_TAG" ||
    actionType === "REMOVE_CONTACT_TAG"
  ) {
    return (
      <>
        {actionConfigHeader}
        <FieldItemContainer>
          <Form.Item
            name={[...parentFieldName, "data", "tags"]}
            label={!ignoreLabel && <div className="font-bold">Select Tags</div>}
            rules={[
              {
                required: true,
                message: "Please select a tag",
              },
            ]}
            noStyle={noFormItemStyle}
          >
            <Select
              mode="tags"
              dropdownStyle={{ display: "none" }}
              style={{ minWidth: 300 }}
            />
          </Form.Item>
        </FieldItemContainer>
      </>
    );
  } else if (
    actionType === "ADD_MARKETING_LIST" ||
    actionType === "REMOVE_MARKETING_LIST" ||
    actionType === "UNSUBSCRIBE_MARKETING_LIST"
  ) {
    return (
      <>
        {actionConfigHeader}
        <FieldItemContainer>
          <Form.Item
            name={[...parentFieldName, "data", "marketingList"]}
            label={
              !ignoreLabel && (
                <div className="font-bold">Select a Marketing List</div>
              )
            }
            rules={[
              {
                required: true,
                message: "Please select a list",
              },
            ]}
            noStyle={noFormItemStyle}
          >
            <ContactListSelector />
          </Form.Item>
        </FieldItemContainer>
      </>
    );
  } else if (actionType === "SUBSCRIBE_MAILCHIMP") {
    return (
      <>
        {actionConfigHeader}

        <>
          <FieldItemContainer>
            <Form.Item
              label="API Key"
              name={[...parentFieldName, "data", "mailchimpConfig", "apiKey"]}
              className="m-0"
              help={
                <a
                  href="https://mailchimp.com/help/about-api-keys/"
                  target="_blank"
                  rel="noreferrer"
                >
                  How to find the API Key
                </a>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter your mailchimp API Key",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </FieldItemContainer>
          <FieldItemContainer>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const apiToken = getFieldValue([
                  "data",
                  "mailchimpConfig",
                  "apiKey",
                ]);
                return (
                  <Form.Item
                    label="Mailchimp List"
                    name={[
                      ...parentFieldName,
                      "data",
                      "mailchimpConfig",
                      "listId",
                    ]}
                    rules={[
                      {
                        required: true,
                        message: "Please select your mailchimp audience (List)",
                      },
                    ]}
                  >
                    <MailchimpListSelector apiKey={apiToken} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </FieldItemContainer>
        </>
      </>
    );
  } else if (actionType === "SEND_MESSAGE") {
    return (
      <>
        {actionConfigHeader}
        <>
          <FieldItemContainer>
            <Form.Item
              label=""
              name={[...parentFieldName, "data", "messageConfig"]}
              className="m-0"
              noStyle={noFormItemStyle}
            >
              <MessageConfigInput />
            </Form.Item>
          </FieldItemContainer>
        </>
      </>
    );
  } else if (actionType === "ACTIVATE_CHAT_BOT") {
    return (
      <>
        {actionConfigHeader}
        <>
          <FieldItemContainer>
            <Form.Item
              label=""
              name={[...parentFieldName, "data", "chatBotId"]}
              className="m-0"
              noStyle={noFormItemStyle}
            >
              <ChatBotSelector />
            </Form.Item>
          </FieldItemContainer>
        </>
      </>
    );
  } else if (actionType === "SHOPIFY_LAST_ORDER_CANCEL") {
    return (
      <>
        {actionConfigHeader}
        <>
          <FieldItemContainer>
            <Form.Item
              label="Cancellation Reason"
              name={[...parentFieldName, "data", "cancelReason"]}
              noStyle={noFormItemStyle}
            >
              <Select
                placeholder="Select a reason"
                options={cancellationReasons}
              ></Select>
            </Form.Item>
          </FieldItemContainer>
        </>
      </>
    );
  } else if (actionType === "SHOPIFY_ORDER_CANCEL") {
    return (
      <>
        {actionConfigHeader}
        <>
          <FieldItemContainer>
            <Form.Item
              label="Order Id"
              name={[...parentFieldName, "data", "orderId"]}
              noStyle={noFormItemStyle}
            >
              <Input />
            </Form.Item>
          </FieldItemContainer>
          <FieldItemContainer>
            <Form.Item
              label="Cancellation Reason"
              name={[...parentFieldName, "data", "cancelReason"]}
              noStyle={noFormItemStyle}
            >
              <Select
                placeholder="Select a reason"
                options={cancellationReasons}
              ></Select>
            </Form.Item>
          </FieldItemContainer>
        </>
      </>
    );
  } else if (actionType === "SHOPIFY_LAST_ORDER_REFUND") {
    return (
      <>
        {actionConfigHeader}
        <>
          <FieldItemContainer>
            <Form.Item
              label="Refund Reason"
              name={[...parentFieldName, "data", "refundReason"]}
              noStyle={noFormItemStyle}
            >
              <Input />
            </Form.Item>
          </FieldItemContainer>
        </>
      </>
    );
  } else if (actionType === "SHOPIFY_LAST_ORDER_EDIT_SHIPPING_ADDRESS") {
    return (
      <>
        {actionConfigHeader}
        <>
          <div className="font-bold my-2">Address</div>
          <FieldItemContainer>
            <Form.Item
              label="First Name"
              name={[...parentFieldName, "data", "address", "first_name"]}
              noStyle={noFormItemStyle}
            >
              <Input />
            </Form.Item>
          </FieldItemContainer>
          <FieldItemContainer>
            <Form.Item
              label="Last Name"
              name={[...parentFieldName, "data", "address", "last_name"]}
              noStyle={noFormItemStyle}
            >
              <Input />
            </Form.Item>
          </FieldItemContainer>
          <FieldItemContainer>
            <Form.Item
              label="First Name"
              name={[...parentFieldName, "data", "address", "address1"]}
              noStyle={noFormItemStyle}
            >
              <Input />
            </Form.Item>
          </FieldItemContainer>
          <FieldItemContainer>
            <Form.Item
              label="Phone"
              name={[...parentFieldName, "data", "address", "phone"]}
              noStyle={noFormItemStyle}
            >
              <Input />
            </Form.Item>
          </FieldItemContainer>
          <FieldItemContainer>
            <Form.Item
              label="City"
              name={[...parentFieldName, "data", "address", "city"]}
              noStyle={noFormItemStyle}
            >
              <Input />
            </Form.Item>
          </FieldItemContainer>
          <FieldItemContainer>
            <Form.Item
              label="Province"
              name={[...parentFieldName, "data", "address", "province"]}
              noStyle={noFormItemStyle}
            >
              <Input />
            </Form.Item>
          </FieldItemContainer>
          <FieldItemContainer>
            <Form.Item
              label="Country"
              name={[...parentFieldName, "data", "address", "country"]}
              noStyle={noFormItemStyle}
            >
              <Input />
            </Form.Item>
          </FieldItemContainer>
          <FieldItemContainer>
            <Form.Item
              label="Zip"
              name={[...parentFieldName, "data", "address", "zip"]}
              noStyle={noFormItemStyle}
            >
              <Input />
            </Form.Item>
          </FieldItemContainer>
        </>
      </>
    );
  } else if (actionType === "SHOPIFY_LAST_ORDER_EDIT_NOTE") {
    return (
      <>
        {actionConfigHeader}
        <>
          <FieldItemContainer>
            <Form.Item
              label="Order Note"
              name={[...parentFieldName, "data", "orderNote"]}
              className="m-0"
              noStyle={noFormItemStyle}
            >
              <Input />
            </Form.Item>
          </FieldItemContainer>
        </>
      </>
    );
  }
  return <div></div>;
};
