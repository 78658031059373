import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { UserGroupType } from "@sdk/user-management/user-management.models";
import { Button, Divider, Popconfirm, Space } from "antd";
import classnames from "classnames";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import _ from "lodash";
import { AddEditUserGroup, ManageUserGroupMembers } from "modal-registry";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { LoadAllGroups } from "store/modules/groups/groups.helpers";
import {
  selectAllGroupsQuery,
  selectGroupById
} from "store/modules/groups/groups.selectors";
import { loadAllUsers } from "store/modules/users/users.helpers";
import { selectAllActiveUsersQuery } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const ManageUserGroups = ({
  onEditUser
}: {
  onEditUser: (id: string) => any;
}) => {
  const {
    state: { list: groups, isLoading },
    retry: reload
  } = useQueryWithStore(selectAllGroupsQuery, LoadAllGroups);

  const {
    doAction: onDeleteUserGroup,
    isProcessing: isDeleteGroupProcessing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (id: string) => SDK.deleteGroup(id).then(d => reload()),
      successMessage: "User Group has been removed",
      failureMessage: "Something went wrong"
    }),
    [reload]
  );

  const teams = useMemo(() => {
    return _.filter(groups, { type: "TEAM" });
  }, [groups]);

  const departments = useMemo(() => {
    return _.filter(groups, { type: "DEPARTMENT" });
  }, [groups]);

  const otherUserGroups = useMemo(() => {
    return _.filter(
      groups,
      group => group.type !== "TEAM" && group.type !== "DEPARTMENT"
    );
  }, [groups]);

  const { triggerTempModal, changePanelState } = useModalPanels();

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin">
      <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4">
        <div className="my-8">
          <SectionHeader
            title="Departments"
            icon={<i className="ri-group-2-line"></i>}
            description="Group users into departments that reflect how your business functions to standardize access to features and restrictions"
          />
          <Divider />

          <div className="flex flex-row flex-wrap gap-4 mt-4">
            {departments.map(record => (
              <UserGroupCard
                onManageMembers={() =>
                  changePanelState(ManageUserGroupMembers, true, {
                    userGroupId: record.id
                  })
                }
                onEdit={() => {
                  changePanelState(AddEditUserGroup, true, {
                    userGroupId: record.id,
                    userGroupType: record.type
                  });
                }}
                onDelete={() => onDeleteUserGroup(record.id!)}
                userGroupId={record.id!}
              />
            ))}
            <AddUserGroupButton
              type="Department"
              onAdd={() =>
                changePanelState(AddEditUserGroup, true, {
                  userGroupId: "",
                  userGroupType: "DEPARTMENT" as UserGroupType
                })
              }
            />
          </div>
        </div>

        <div className="my-8">
          <SectionHeader
            title="Teams"
            icon={<i className="ri-team-line"></i>}
            description="Organize Users into teams, within departments to organize people better and maximize productivity"
          />
          <Divider />

          <div className="flex flex-row flex-wrap gap-4 mt-4">
            {teams.map(record => (
              <UserGroupCard
                onManageMembers={() =>
                  changePanelState(ManageUserGroupMembers, true, {
                    userGroupId: record.id
                  })
                }
                onEdit={() => {
                  changePanelState(AddEditUserGroup, true, {
                    userGroupId: record.id,
                    userGroupType: record.type
                  });
                }}
                onDelete={() => onDeleteUserGroup(record.id!)}
                userGroupId={record.id!}
              />
            ))}
            <AddUserGroupButton
              type="Team"
              onAdd={() =>
                changePanelState(AddEditUserGroup, true, {
                  userGroupId: "",
                  userGroupType: "TEAM" as UserGroupType
                })
              }
            />
          </div>
        </div>

        {otherUserGroups.length > 0 && (
          <div className="my-8">
            <SectionHeader
              title="Custom Groups"
              icon={<i className="ri-group-line"></i>}
              description="Create custom groups that work across departments fro more flexibility or special projects"
            />

            <Divider />
            <div className="flex flex-row flex-wrap gap-4 mt-4">
              {otherUserGroups.map(record => (
                <UserGroupCard
                  onManageMembers={() =>
                    changePanelState(ManageUserGroupMembers, true, {
                      userGroupId: record.id
                    })
                  }
                  onEdit={() => {
                    changePanelState(AddEditUserGroup, true, {
                      userGroupId: record.id,
                      userGroupType: record.type
                    });
                  }}
                  onDelete={() => onDeleteUserGroup(record.id!)}
                  userGroupId={record.id!}
                />
              ))}
            </div>
          </div>
        )}
      </SimpleCardSection>
    </StyledScrollArea>
  );
};

export const UserGroupCard = ({
  onEdit,
  onManageMembers,
  onDelete,
  userGroupId
}: {
  onEdit: () => any;
  onManageMembers: () => any;
  onDelete: () => any;
  userGroupId: string;
}) => {
  const {
    state: { list: users }
  } = useQueryWithStore(selectAllActiveUsersQuery, loadAllUsers());

  const members = useMemo(
    () => users.filter(user => user?.userGroups.includes(userGroupId)),
    [userGroupId, users]
  );

  const userGroup = useSelector(selectGroupById(userGroupId));

  const [isDeleteConfirmVisible, setDeleteConfirmVisibility] = useState(false);

  return (
    <div
      className="border border-gray-200 dark:border-gray-700 rounded-lg p-4 flex flex-row cursor-pointer hover:bg-gray-100 dark:bg-gray-800 group"
      style={{ width: 300 }}
      onClick={onManageMembers}
    >
      <div className="">
        <i className="ri-team-line pr-2"></i>
      </div>
      <div className="flex flex-col flex-1">
        <div className="font-bold">{userGroup.label}</div>

        <div className="footer text-gray-600">({members.length}) members</div>
      </div>
      <div
        className={classnames("group-hover:show", {
          hidden: !isDeleteConfirmVisible
        })}
        onClick={e => e.stopPropagation()}
      >
        <Space>
          <Button
            icon={<i className="ri-edit-line"></i>}
            type="text"
            onClick={e => {
              onEdit();
            }}
          ></Button>
        </Space>
        <Popconfirm
          title="Are you sure to delete this？"
          onConfirm={onDelete}
          onOpenChange={setDeleteConfirmVisibility}
        >
          <Button
            icon={<i className="ri-delete-bin-line"></i>}
            type="text"
          ></Button>
        </Popconfirm>
      </div>
    </div>
  );
};

export const AddUserGroupButton = ({
  type,
  onAdd
}: {
  type: "Team" | "Department";
  onAdd: () => any;
}) => {
  return (
    <div
      className="border border-gray-300 dark:border-gray-400 border-dashed rounded-lg p-4 flex flex-row cursor-pointer hover:bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-500 justify-start items-center"
      style={{ width: 300 }}
      onClick={onAdd}
    >
      <div className="text-xl">
        <i className="ri-add-line pr-2"></i>
      </div>
      <div className="flex flex-col text-lg">
        <div className="">Create New {type}</div>
      </div>
    </div>
  );
};
