import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Form, Modal, Select, Space, Tooltip } from "antd";
import { ConfigurationEditorInner } from "components/common/configuration-editor";
import { ModalTitle } from "components/common/modal-title";
import { RouterPrompt } from "components/common/router-prompt/router-prompt";
import { SimpleDNDList } from "components/common/simple-dnd-list/dnd-list";
import { useChatBarSections } from "components/modules/conversations/components/chat-view-side-bar/helpers/use-chat-side-bar-sections";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { SideBarContactProfileSectionConfig } from "modal-registry";
import { useCallback, useEffect, useRef, useState } from "react";

const contactViewSections: {
  value: string;
  label: string;
}[] = [
  {
    value: "AVATAR",
    label: "Customer Avatar",
  },
  {
    value: "VERIFIED_CONTACT",
    label: "Verified Contact Badge",
  },
  {
    value: "NOTES",
    label: "Customer Notes",
  },
  {
    value: "CONTACT_TAGS",
    label: "Customer Tags",
  },
  {
    value: "MARKETING_LIST",
    label: "Marketing Lists",
  },
  {
    value: "SOCIAL_MEDIA_PROFILES",
    label: "Social Media Profiles",
  },
  {
    value: "CUSTOM_CONTACT_PROPERTIES",
    label: "Custom Contact Properties",
  },
  {
    value: "SESSION_DATA",
    label: "Live Chat Session Data",
  },
  {
    value: "EXTRA_ACTIONS",
    label: "Additional Actions",
  },
];

export const ChatSideBarConfig = () => {
  const {
    allSections: initialSectionOrder,
    hiddenSections: initialHiddenSections,
  } = useChatBarSections();

  const [sections, _setSections] = useState(initialSectionOrder);

  const [hiddenSections, _setHiddenSections] = useState(
    initialHiddenSections || [],
  );

  const [hasChanged, setHasChanged] = useState(false);

  const setSections = useCallback((items) => {
    _setSections(items);
    setHasChanged(true);
  }, []);

  const setHiddenSections = useCallback((items) => {
    _setHiddenSections(items);
    setHasChanged(true);
  }, []);

  useEffect(() => {
    setHasChanged(false);
    _setHiddenSections(initialHiddenSections || []);
  }, [initialHiddenSections, initialSectionOrder]);

  const toggleSectionVisibility = useCallback(
    (section: string) => {
      if (hiddenSections?.includes(section)) {
        // Unhide
        setHiddenSections(_.without(hiddenSections, section));
      } else {
        // Hide
        setHiddenSections([...hiddenSections, section]);
      }
    },
    [hiddenSections, setHiddenSections],
  );

  const { doAction: editWorkspace, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (edits) => {
        return SDK.editCurrentOrganization({
          appData: {
            HELP_DESK: {
              chatSideBarConfig: edits,
            },
          },
        });
      },
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const onSave = useCallback(() => {
    editWorkspace({
      sections: sections.map((item) => item.id),
      hiddenSections,
    });
  }, [editWorkspace, hiddenSections, sections]);

  const { triggerTempModal } = useModalPanels();

  return (
    <div className="p-4">
      <RouterPrompt when={hasChanged} />
      <SimpleDNDList
        list={sections}
        onListChange={setSections}
        renderItem={(section, index) => (
          <div
            className="p-2 border border-gray-200 rounded-lg mb-4 flex flex-row justify-between items-center"
            key={section.id}
          >
            <div className="flex flex-row justify-center items-center">
              <div className=" mr-2">
                {typeof section.icon === "string" ? (
                  <i className={section.icon}></i>
                ) : (
                  <section.icon />
                )}
              </div>
              {section.title}
            </div>
            <div className="flex flex-row justify-center items-center">
              <Space>
                {section.id === "PROFILE" && (
                  <Tooltip title="Configure Section">
                    <Button
                      icon={<i className="ri-tools-line"></i>}
                      type="text"
                      onClick={() => {
                        triggerTempModal(
                          SideBarContactProfileSectionConfig,
                          {},
                        );
                      }}
                    />
                  </Tooltip>
                )}
                <Tooltip title="Toggle Visibility">
                  <Button
                    icon={
                      hiddenSections.includes(section.id) ? (
                        <i className="ri-eye-off-line"></i>
                      ) : (
                        <i className="ri-eye-line"></i>
                      )
                    }
                    type="text"
                    onClick={() => toggleSectionVisibility(section.id)}
                  />
                </Tooltip>
              </Space>
            </div>
          </div>
        )}
      />
      <div
        className="flex flex-row justify-end items-center"
        style={{ height: 30 }}
      >
        {hasChanged && (
          <Button
            onClick={onSave}
            className="font-bold animated fadeInRight"
            icon={<i className="ri-save-line"></i>}
            loading={isProcessing}
            type="primary"
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
};

const SideBarContactProfileConfig = () => {
  const panelRef = useRef<any>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (panelRef.current) {
        panelRef.current.openPanel();
      }
    }, 400);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <ConfigurationEditorInner
        entityType="WORKSPACE"
        panelRef={panelRef}
        title="Hidden Chat Side bar Elements"
      >
        <>
          <Form.Item
            label={"Hidden Fields"}
            name={[
              "appData",
              "HELP_DESK",
              "chatSideBarConfig",
              "contactProfile",
              "hiddenElements",
            ]}
            hasFeedback={false}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Select Fields"
              allowClear
              options={contactViewSections}
            ></Select>
          </Form.Item>
        </>
      </ConfigurationEditorInner>
    </>
  );
};

export const SideBarContactProfileConfigModal = ({
  visible,
  onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  return (
    <Modal
      title={
        <ModalTitle
          title={"Configure Section"}
          icon={<i className="ri-tools-line"></i>}
        />
      }
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Chat Side Bar Profile Modal"
      destroyOnClose={true}
      visible={visible}
    >
      <SideBarContactProfileConfig />
      <DarkModeBg />
    </Modal>
  );
};
