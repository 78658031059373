import { Select } from "antd";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectShopifyStoreConfig } from "store/modules/workspace/workspace.selectors";
import {
  ActionDescriptions,
  ActionLabels,
  AllActionsByGroup as _AllActionsByGroup,
} from "../action-editor/actions";

export const ActionSelector = ({
  value,
  onChange,
  excludeActions,
  ...props
}: {
  value?: string;
  onChange?: (action?: string) => any;
  excludeActions?: string[];
}) => {
  const shopifyStoreConfig = useSelector(selectShopifyStoreConfig);

  const AllActionsByGroup = useMemo(() => {
    const isShopifyConnected = shopifyStoreConfig?.isActive;
    return _AllActionsByGroup.filter((item) =>
      item.label === "Shopify Actions" ? isShopifyConnected : true,
    );
  }, [shopifyStoreConfig?.isActive]);

  return (
    <Select
      style={{ minWidth: 150 }}
      showSearch
      value={value}
      onChange={onChange}
      {...props}
    >
      {AllActionsByGroup.map((item) => (
        <Select.OptGroup label={item.label} key={item.label}>
          {item.actions.map((action) => (
            <Select.Option value={action} key={action}>
              <div className="text-semibold">{ActionLabels[action]}</div>
              <div className="text-gray-600">{ActionDescriptions[action]}</div>
            </Select.Option>
          ))}
        </Select.OptGroup>
      ))}
    </Select>
  );
};
