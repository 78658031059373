import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iCustomInbox } from "@sdk/user-management/user-management.models";
import { Form, Input, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { QueryConfig } from "store/utils/query-config";

export const CreateChatListView = ({
  visible,
  onChangeVisibility,
  queryConfig
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  queryConfig: QueryConfig;
}) => {
  const [form] = useForm();

  const currentUser = useSelector(selectCurrentUser);

  const { doAction: editCustomInboxes, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: SDK => (customInboxes: iCustomInbox[]) =>
        SDK.editCurrentUser({
          data: {
            customInboxes
          }
        }).then(d => {
          onChangeVisibility(false);
          form.resetFields();
        }),
      failureMessage: "Something went wrong"
    }),
    [form, onChangeVisibility]
  );

  const saveView = () => {
    const { label } = form.getFieldsValue();
    const existingInboxes = currentUser.data.customInboxes || [];
    editCustomInboxes([
      ...existingInboxes,
      { query: JSON.stringify(_.omit(queryConfig, "alias")), label }
    ]);
  };

  return (
    (<Modal
      title={
        <ModalTitle title="Save View" icon={<i className="ri-save-line"></i>} />
      }
      open={visible}
      okButtonProps={{
        loading: isProcessing,
        icon: <i className="ri-save-line"></i>,
        className: "font-bold"
      }}
      okText="Save"
      onOk={() => {
        saveView();
      }}
      cancelText="Cancel"
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={500}
      data-click-context="Create Chat List View Modal"
    >
      <Form
        layout="vertical"
        form={form}
        // defaultValue={{ shouldCancelOnCustomerMessage: true }}
      >
        <Form.Item
          name="label"
          label={"View Name"}
          rules={[
            {
              required: true,
              message: "Please input a name"
            }
          ]}
        >
          <Input placeholder="Eg: My whatsapp chats" size="large" />
        </Form.Item>
      </Form>
      <DarkModeBg />
    </Modal>)
  );
};
