import { iFilterConfigSchema } from "components/common/filter-schema/filter-config-schema";
import { ConnectionPill } from "components/modules/connections/connection-pill/connection-pill";
import { ConnectionTypeLabelMap } from "components/modules/connections/helpers/connection-type-labels";
import {
  ActivityIconMap,
  ActivityLabelMap,
  AllActivityTypes,
} from "components/modules/crm/activities/activities-viewer/activities-viewer";
import { UserPill } from "components/modules/user-management/users/components/user-pill/user-pill";
import dayjs from "dayjs";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import {
  selectAllGroups,
  selectGroupById,
} from "store/modules/groups/groups.selectors";
import { selectUsersByUserGroup } from "store/modules/users/users.selectors";
import { selectFirstResponseIntervalConfig } from "store/modules/workspace/workspace.selectors";
import { CountryCodes } from "utils/country-codes";
import { durationOptions } from "../helpers/chat-duration-options";
import { messageCountOptions } from "../helpers/chat-message-count-options";
import { chatRatingOptions } from "../helpers/chat-rating-options";
import { generateIntervalOptions } from "../helpers/generate-interval-options";

export const ReportsFilterSchema: iFilterConfigSchema = {
  filters: [
    {
      label: "Filter by Period",
      icon: "ri-calendar-line",
      key: "period",
      fieldOptions: {
        operators: [
          {
            id: "STARTED_IS_BETWEEN",
            label: "is started between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              createdTime: {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
          {
            id: "CREATED_IS_BETWEEN",
            label: "is created between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              objectCreated: {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
          {
            id: "CLOSED_BETWEEN",
            label: "is closed between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              closedAt: {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
          {
            id: "LAST_MESSAGE",
            label: "has last message between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              lastMessageTimestamp: {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
        ],
        defaultOperator: "STARTED_IS_BETWEEN",
      },
    },
    {
      label: "Conversation Member",
      icon: "ri-user-fill",
      key: "users",
      fieldOptions: {
        operators: [
          {
            id: "IS_ONE_OF",
            label: "is one of",
            queryGenerator: ({ value: userId }) => ({
              usersIn: userId,
            }),
            valueField: {
              type: "USER_SELECTOR",
            },
            viewRenderer: (userId: string) => <UserPill userId={userId} />,
          },
          {
            id: "IS_ALL_OF",
            label: "is all of",
            valueField: {
              type: "USER_SELECTOR",
            },
            viewRenderer: (userId: string) => <UserPill userId={userId} />,
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },
    {
      label: "Tags",
      icon: "ri-price-tag-3-fill",
      key: "tags",
      fieldOptions: {
        operators: [
          {
            id: "IS_ONE_OF",
            label: "includes one of :",
            queryGenerator: ({ value: tags }) => ({
              tagsIn: tags,
            }),
            valueField: {
              type: "CONVERSATION_TAG_SELECTOR",
            },
          },
          {
            id: "IS_ALL_OF",
            label: "includes all of",
            valueField: {
              type: "CONVERSATION_TAG_SELECTOR",
            },
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },
    {
      label: "Connection",
      icon: "ri-wireless-charging-line",
      key: "connectionId",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            valueField: {
              type: "CONNECTION_SELECTOR",
            },
            viewRenderer: (connectionId: string) => (
              <ConnectionPill connectionId={connectionId} />
            ),
          },
        ],
        defaultOperator: "IS",
      },
    },
    {
      label: "Connection Type",
      icon: "ri-sim-card-fill",
      key: "connectionType",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            valueField: {
              type: "SELECT",
              config: {
                optionGenerator: (state) => {
                  const connections = selectAllConnections(state);
                  return _.uniqBy(
                    connections.map((connection) => ({
                      value: connection.type,
                      label: connection.type,
                    })),
                    "value"
                  );
                },
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    {
      label: "Facebook Ad ID",
      icon: "ri-facebook-box-line",
      key: "fbAdId",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            valueField: {
              type: "INPUT",
              config: {
                placeholder: "Enter Facebook Ad Id",
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    {
      label: "Facebook Post Id",
      icon: "ri-facebook-box-line",
      key: "fbPostId",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            valueField: {
              type: "INPUT",
              config: {
                placeholder: "Enter Facebook Post Id",
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    {
      label: "User Groups",
      icon: "ri-group-line",
      key: "userGroup",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            valueField: {
              type: "SELECT",
              config: {
                optionGenerator: (state) => {
                  const userGroups = selectAllGroups(state);
                  return _.uniqBy(
                    userGroups.map((userGroup) => ({
                      value: userGroup.id,
                      label: userGroup.label,
                    })),
                    "value"
                  );
                },
              },
            },
            queryGenerator: ({ value: userGroupId }, state) => {
              const users = selectUsersByUserGroup(state)[userGroupId];
              return {
                usersIn: users.map((user) => user.id),
              };
            },
            viewRenderer: (userGroupId: string) => {
              const UserGroupRenderer = ({ userGroupId }) => {
                const userGroup = useSelector(selectGroupById(userGroupId));
                return <>{userGroup?.label}</>;
              };
              return <UserGroupRenderer userGroupId={userGroupId} />;
            },
          },
        ],

        defaultOperator: "IS",
      },
    },
    {
      label: "Source",
      icon: "ri-arrow-right-line",
      key: "source",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            valueField: {
              type: "INPUT",
              config: {
                placeholder: "Select a conversation Source",
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    {
      label: "First Response Time",
      icon: "ri-timer-flash-line",
      key: "responseTime",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value: label }) => ({
              responseTime: label,
            }),
            valueField: {
              type: "SELECT",
              config: {
                placeholder: "Select a First Response Time Period",
                optionGenerator: (state) => {
                  return generateIntervalOptions(
                    selectFirstResponseIntervalConfig(state)
                  ).map((item) => ({
                    label: item.title,
                    value: item.value,
                  }));
                },
              },
            },
            viewRenderer: (value: string) => {
              const ResponseOptionLabelRenderer = ({ value }) => {
                const store = useStore();

                const optionLabel = useMemo(() => {
                  const options = generateIntervalOptions(
                    selectFirstResponseIntervalConfig(store.getState())
                  );
                  const selectedOption = _.find(options, { value });
                  return selectedOption?.title;
                }, [store, value]);
                return <>{optionLabel}</>;
              };
              return <ResponseOptionLabelRenderer value={value} />;
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    {
      label: "Duration",
      icon: "ri-timer-line",
      key: "duration",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value: label }) => ({
              duration: label,
            }),
            valueField: {
              type: "SELECT",
              config: {
                placeholder: "Select a conversation Duration",
                optionGenerator: (state) => {
                  return durationOptions.map((item) => ({
                    label: item.title,
                    value: item.value,
                  }));
                },
              },
            },
            viewRenderer: (value: string) => {
              return <>{_.find(durationOptions, { value })?.title}</>;
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    {
      label: "Message Count",
      icon: "ri-list-ordered",
      key: "messageCount",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value: label }) => ({
              messageCount: label,
            }),
            valueField: {
              type: "SELECT",
              config: {
                placeholder: "Select a conversation Message Count range",
                optionGenerator: (state) => {
                  return messageCountOptions.map((item) => ({
                    label: item.title,
                    value: item.value,
                  }));
                },
              },
            },
            viewRenderer: (value: string) => {
              return <>{_.find(messageCountOptions, { value })?.title}</>;
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    {
      label: "Conversation Rating",
      icon: "ri-emotion-happy-line",
      key: "chatRating",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            valueField: {
              type: "SELECT",
              config: {
                placeholder: "Select a conversation Rating",
                optionGenerator: (state) => {
                  return chatRatingOptions.map((item) => ({
                    label: item.title,
                    // Todo
                    value: item.value as any,
                  }));
                },
              },
            },
            viewRenderer: (value: string) => {
              return (
                <>{_.find(chatRatingOptions, { value: value as any })?.title}</>
              );
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
  ],
  generateDefaultFilter: () => {
    return {
      operatorConfig: {
        value: [
          dayjs()
            .subtract(7, "day")
            .startOf("day")
            .valueOf(),
          dayjs().valueOf(),
        ],
      },
      operator: "STARTED_IS_BETWEEN",
      key: "period",
    };
  },
};

export const ContactsReportsFilterSchema: iFilterConfigSchema = {
  filters: [
    {
      label: "Filter by Period",
      icon: "ri-calendar-line",
      key: "period",
      fieldOptions: {
        operators: [
          {
            id: "CREATED_IS_BETWEEN",
            label: "is created between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              createdTime: {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
          {
            id: "LAST_ACTIVITY_IS_BETWEEN",
            label: "has last activity between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              lastActivity: {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
          {
            id: "LAST_CONTACT_IS_BETWEEN",
            label: "has last message between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              lastContactByContact: {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
        ],
        defaultOperator: "CREATED_IS_BETWEEN",
      },
    },
    {
      label: "Contact Owner",
      icon: "ri-user-fill",
      key: "owner",
      fieldOptions: {
        operators: [
          {
            id: "DOES_NOT_EXISTS",
            label: "does not exist",
            queryGenerator: () => ({
              owner: { $exists: false },
            }),
          },
          {
            id: "IS_ONE_OF",
            label: "is",
            queryGenerator: ({ value: userId }) => ({
              owner: userId,
            }),
            valueField: {
              type: "USER_SELECTOR",
            },
            viewRenderer: (userId: string) => <UserPill userId={userId} />,
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },
    {
      label: "Tags",
      icon: "ri-price-tag-3-fill",
      key: "tags",
      fieldOptions: {
        operators: [
          {
            id: "DOES_NOT_EXISTS",
            queryGenerator: () => ({
              tags: { $exists: false },
            }),
            label: "does not exist",
          },
          {
            id: "IS_ONE_OF",
            label: "includes one of :",
            queryGenerator: ({ value: tags }) => ({
              tagsIn: { $in: tags },
            }),
            valueField: {
              type: "SELECT",
              config: {
                selectionMode: "tags",
              },
            },
          },
          {
            id: "IS_ALL_OF",
            label: "includes all of",
            queryGenerator: ({ value: tags }) => ({
              tags: { $all: tags },
            }),
            valueField: {
              type: "SELECT",
              config: {
                selectionMode: "tags",
              },
            },
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },
    {
      label: "Connection",
      icon: "ri-wireless-charging-line",
      key: "connectionId",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value: connectionId }) => ({
              connectionId: connectionId,
            }),
            valueField: {
              type: "CONNECTION_SELECTOR",
            },
            viewRenderer: (connectionId: string) => (
              <ConnectionPill connectionId={connectionId} />
            ),
          },
        ],
        defaultOperator: "IS",
      },
    },
    {
      label: "Connection Type",
      icon: "ri-sim-card-fill",
      key: "connectionType",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value: connectionType }) => ({
              connectionType: connectionType,
            }),
            valueField: {
              type: "SELECT",
              config: {
                optionGenerator: (state) => {
                  const connections = selectAllConnections(state);
                  return _.uniqBy(
                    connections.map((connection) => ({
                      value: connection.type,
                      label: ConnectionTypeLabelMap[connection.type],
                    })),
                    "value"
                  );
                },
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    {
      label: "Country",
      icon: "ri-map-pin-line",
      key: "country",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            valueField: {
              type: "SELECT",
              config: {
                options: CountryCodes.map((country) => ({
                  value: `${country.code}`,
                  label: country.name,
                })),
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
  ],
  generateDefaultFilter: () => {
    return {
      operatorConfig: {
        value: [
          dayjs()
            .subtract(7, "day")
            .startOf("day")
            .valueOf(),
          dayjs().valueOf(),
        ],
      },
      operator: "CREATED_IS_BETWEEN",
      key: "period",
    };
  },
};

export const ActivitiesReportsFilterSchema: iFilterConfigSchema = {
  filters: [
    {
      label: "Filter by Period",
      icon: "ri-calendar-line",
      key: "period",
      fieldOptions: {
        operators: [
          {
            id: "CREATED_IS_BETWEEN",
            label: "is created between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              createdTime: {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
        ],
        defaultOperator: "CREATED_IS_BETWEEN",
      },
    },
    {
      label: "Activity Owner",
      icon: "ri-user-fill",
      key: "owner",
      fieldOptions: {
        operators: [
          {
            id: "DOES_NOT_EXISTS",
            label: "does not exist",
            queryGenerator: () => ({
              createdBy: { $exists: false },
            }),
          },
          {
            id: "IS_ONE_OF",
            label: "is",
            queryGenerator: ({ value: userId }) => ({
              createdBy: userId,
            }),
            valueField: {
              type: "USER_SELECTOR",
            },
            viewRenderer: (userId: string) => <UserPill userId={userId} />,
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },
    {
      label: "Tags",
      icon: "ri-price-tag-3-fill",
      key: "tags",
      fieldOptions: {
        operators: [
          {
            id: "DOES_NOT_EXISTS",
            queryGenerator: () => ({
              tags: { $exists: false },
            }),
            label: "does not exist",
          },
          {
            id: "IS_ONE_OF",
            label: "includes one of :",
            queryGenerator: ({ value: tags }) => ({
              tagsIn: { $in: tags },
            }),
            valueField: {
              type: "SELECT",
              config: {
                selectionMode: "tags",
              },
            },
          },
          {
            id: "IS_ALL_OF",
            label: "includes all of",
            queryGenerator: ({ value: tags }) => ({
              tags: { $all: tags },
            }),
            valueField: {
              type: "SELECT",
              config: {
                selectionMode: "tags",
              },
            },
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },
    {
      label: "Type",
      icon: "ri-wireless-charging-line",
      key: "type",
      fieldOptions: {
        operators: [
          {
            id: "IS_ONE_OF",
            label: "includes one of :",
            queryGenerator: ({ value: types }) => ({
              activityType: { $in: types },
            }),
            valueField: {
              type: "SELECT",
              config: {
                selectionMode: "multiple",
                options: AllActivityTypes.map((activityType) => ({
                  label: ActivityLabelMap[activityType],
                  value: activityType,
                  icon: ActivityIconMap[activityType],
                })),
              },
            },
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },
  ],
  generateDefaultFilter: () => {
    return {
      operatorConfig: {
        value: [
          dayjs()
            .subtract(7, "day")
            .startOf("day")
            .valueOf(),
          dayjs().valueOf(),
        ],
      },
      operator: "CREATED_IS_BETWEEN",
      key: "period",
    };
  },
};

export const CompaniesReportsFilterSchema: iFilterConfigSchema = {
  filters: [
    {
      label: "Filter by Period",
      icon: "ri-calendar-line",
      key: "period",
      fieldOptions: {
        operators: [
          {
            id: "CREATED_IS_BETWEEN",
            label: "is created between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              "metaData.createdTime": {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
        ],
        defaultOperator: "CREATED_IS_BETWEEN",
      },
    },
    {
      label: "Account Owner",
      icon: "ri-user-fill",
      key: "owner",
      fieldOptions: {
        operators: [
          {
            id: "DOES_NOT_EXISTS",
            label: "does not exist",
            queryGenerator: () => ({
              users: { $exists: false },
            }),
          },
          {
            id: "IS_ONE_OF",
            label: "is",
            queryGenerator: ({ value: userId }) => ({
              users: userId,
            }),
            valueField: {
              type: "USER_SELECTOR",
            },
            viewRenderer: (userId: string) => <UserPill userId={userId} />,
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },

    {
      label: "Type",
      icon: "ri-wireless-charging-line",
      key: "type",
      fieldOptions: {
        operators: [
          {
            id: "IS_ONE_OF",
            label: "includes one of :",
            queryGenerator: ({ value: tags }) => ({
              type: { $in: tags },
            }),
            valueField: {
              type: "SELECT",
            },
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },
  ],
  generateDefaultFilter: () => {
    return {
      operatorConfig: {
        value: [
          dayjs()
            .subtract(7, "day")
            .startOf("day")
            .valueOf(),
          dayjs().valueOf(),
        ],
      },
      operator: "CREATED_IS_BETWEEN",
      key: "period",
    };
  },
};

export const EngagementReportsFilterSchema: iFilterConfigSchema = {
  filters: [
    {
      label: "Filter by Period",
      icon: "ri-calendar-line",
      key: "period",
      fieldOptions: {
        operators: [
          {
            id: "CREATED_IS_BETWEEN",
            label: "is created between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              "metaData.createdTime": {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
        ],
        defaultOperator: "CREATED_IS_BETWEEN",
      },
    },
    {
      label: "Owner",
      icon: "ri-user-fill",
      key: "owner",
      fieldOptions: {
        operators: [
          {
            id: "DOES_NOT_EXISTS",
            label: "does not exist",
            queryGenerator: () => ({
              users: { $exists: false },
            }),
          },
          {
            id: "IS_ONE_OF",
            label: "is",
            queryGenerator: ({ value: userId }) => ({
              users: userId,
            }),
            valueField: {
              type: "USER_SELECTOR",
            },
            viewRenderer: (userId: string) => <UserPill userId={userId} />,
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },
    {
      label: "Tags",
      icon: "ri-price-tag-3-fill",
      key: "tags",
      fieldOptions: {
        operators: [
          {
            id: "DOES_NOT_EXISTS",
            queryGenerator: () => ({
              tags: { $exists: false },
            }),
            label: "does not exist",
          },
          {
            id: "IS_ONE_OF",
            label: "includes one of :",
            queryGenerator: ({ value: tags }) => ({
              tags: { $in: tags },
            }),
            valueField: {
              type: "SELECT",
            },
          },
          {
            id: "IS_ALL_OF",
            label: "includes all of",
            queryGenerator: ({ value: tags }) => ({
              tags: { $all: tags },
            }),
            valueField: {
              type: "SELECT",
            },
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },
  ],
  generateDefaultFilter: () => {
    return {
      operatorConfig: {
        value: [
          dayjs()
            .subtract(7, "day")
            .startOf("day")
            .valueOf(),
          dayjs().valueOf(),
        ],
      },
      operator: "CREATED_IS_BETWEEN",
      key: "period",
    };
  },
};

export const OpportunitiesReportsFilterSchema: iFilterConfigSchema = {
  filters: [
    {
      label: "Filter by Period",
      icon: "ri-calendar-line",
      key: "period",
      fieldOptions: {
        operators: [
          {
            id: "CREATED_IS_BETWEEN",
            label: "is created between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              "metaData.createdTime": {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
          {
            id: "LAST_ACTIVITY_IS_BETWEEN",
            label: "has last message between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              "metaData.createdTime": {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
        ],
        defaultOperator: "STARTED_IS_BETWEEN",
      },
    },
    {
      label: "Owner",
      icon: "ri-user-fill",
      key: "owner",
      fieldOptions: {
        operators: [
          {
            id: "DOES_NOT_EXISTS",
            label: "does not exist",
            queryGenerator: () => ({
              users: { $exists: false },
            }),
          },
          {
            id: "IS_ONE_OF",
            label: "is",
            queryGenerator: ({ value: userId }) => ({
              users: userId,
            }),
            valueField: {
              type: "USER_SELECTOR",
            },
            viewRenderer: (userId: string) => <UserPill userId={userId} />,
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },
    {
      label: "Tags",
      icon: "ri-price-tag-3-fill",
      key: "tags",
      fieldOptions: {
        operators: [
          {
            id: "DOES_NOT_EXISTS",
            queryGenerator: () => ({
              tags: { $exists: false },
            }),
            label: "does not exist",
          },
          {
            id: "IS_ONE_OF",
            label: "includes one of :",
            queryGenerator: ({ value: tags }) => ({
              tags: { $in: tags },
            }),
            valueField: {
              type: "SELECT",
            },
          },
          {
            id: "IS_ALL_OF",
            label: "includes all of",
            queryGenerator: ({ value: tags }) => ({
              tags: { $all: tags },
            }),
            valueField: {
              type: "SELECT",
            },
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },
    {
      label: "Pipeline Stage",
      icon: "ri-wireless-charging-line",
      key: "pipelineStage",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value: userId }) => ({
              owner: userId,
            }),
            valueField: {
              type: "SELECT",
            },
            viewRenderer: (connectionId: string) => (
              <ConnectionPill connectionId={connectionId} />
            ),
          },
        ],
        defaultOperator: "IS",
      },
    },
  ],
  generateDefaultFilter: () => {
    return {
      operatorConfig: {
        value: [
          dayjs()
            .subtract(7, "day")
            .startOf("day")
            .valueOf(),
          dayjs().valueOf(),
        ],
      },
      operator: "CREATED_IS_BETWEEN",
      key: "period",
    };
  },
};
