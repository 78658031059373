function getPointCategoryName(point, dimension) {
  var series = point.series,
    isY = dimension === "y",
    axis = series[isY ? "yAxis" : "xAxis"];
  return axis.categories[point[isY ? "y" : "x"]];
}
//  Highcharts.PlotHeatmapOptions

const data = {
  title: "Conversations by hour of the day",
  data: {
    x: 0,
    y: 0,
    count: 0,
  },
};

export const generateHeatMapConfig = ({
  title,
  xAxisLabels,
  yAxisLabels,
  data,
  toolTipTemplate,
}: {
  title: string;
  xAxisLabels?: string[];
  yAxisLabels?: string[];
  data?: { x: number; y: number; count: number }[];
  toolTipTemplate: (x: string, y: string, value: number) => string;
}) => {
  const seriesData = data?.map((point) => [point.x, point.y, point.count]);

  const heatMapConfig = {
    chart: {
      type: "heatmap",
      marginTop: 40,
      marginBottom: 80,
      plotBorderWidth: 1,
    },
    title: {
      text: title,
    },
    xAxis: {
      categories: xAxisLabels,
    },
    yAxis: {
      categories: yAxisLabels,
      title: null,
    },
    accessibility: {
      point: {
        descriptionFormatter: function (point) {
          var ix = point.index + 1,
            xName = getPointCategoryName(point, "x"),
            yName = getPointCategoryName(point, "y"),
            val = point.value;
          return ix + ". " + xName + " sales " + yName + ", " + val + ".";
        },
      },
    },

    colorAxis: {
      min: 0,
      minColor: "#FFFFFF",
      maxColor: "#1A73E8",
    },
    legend: {
      align: "right",
      layout: "vertical",
      margin: 0,
      verticalAlign: "top",
      y: 25,
      symbolHeight: 280,
    },
    tooltip: {
      formatter: function () {
        return toolTipTemplate(
          getPointCategoryName((this as any).point, "x"),
          getPointCategoryName((this as any).point, "y"),
          (this as any).point.value,
        );
      },
    },
    series: [
      {
        name: "Sales per employee",
        borderWidth: 1,
        data: seriesData,
        dataLabels: {
          enabled: true,
          color: "#000000",
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            yAxis: {
              labels: {
                formatter: function () {
                  return ((this as any).value || "").charAt(0);
                },
              },
            },
          },
        },
      ],
    },
  };
  return heatMapConfig;
};
