import { iSDK } from "@sdk/sdk";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Form, message } from "antd";
import { Rule } from "antd/es/form";
import { FormLayout, useForm } from "antd/es/form/Form";
import classNames from "classnames";
import { get, set } from "lodash";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { selectChatWidgetById } from "store/modules/chat-widgets/chat-widgets.selectors";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { selectDynamicFormById } from "store/modules/dynamic-forms/dynamic-forms.selectors";
import { selectSelfServicePortalById } from "store/modules/self-service-portals/self-service-portals.selectors";
import { selectUser } from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { useDoubleSelectorWithMemoize } from "store/utils/use-double-selector-with-memoize";
import { BuildObjectWithPathString } from "utils/build-object-with-path-string";
import "./editable-text-with-form.scss";

export const EditableTextWithForm = memo(
  ({
    label,
    fieldPath: fieldPathString,
    formLayout,
    placeholder,
    rules,
    entityType,
    entityId,
    transformFormValues,
    transformEntityValues,
    onFormValueChange,
    children,
    onSaveFailed,
    onClearError,
    saveOnChange,
    valuePropName,
    help
  }: {
    label?: string | JSX.Element;
    placeholder?: string;
    fieldPath: string;
    formLayout?: FormLayout;
    rules?: Rule[];
    entityType:
      | "CONNECTION"
      | "CONTACT"
      | "WORKSPACE"
      | "MY_PROFILE"
      | "USER"
      | "WIDGET"
      | "SELF_SERVICE_PORTAL"
      | "DYNAMIC_FORM"
      | "CONVERSATION";
    entityId?: string;
    transformFormValues?: (formValues: any) => any;
    transformEntityValues?: (entity: any) => any;
    onFormValueChange?: (formValues: any) => any;
    children: JSX.Element;
    onSaveFailed?: (
      error: any,
      value: any,
      setAdditionalErrorNode: (element: JSX.Element) => any
    ) => any;
    onClearError?: () => any;
    saveOnChange?: boolean;
    valuePropName?: string;
    help?: string | JSX.Element;
  }) => {
    const fieldPath = useMemo(() => fieldPathString.split("."), [
      fieldPathString
    ]);
    const [form] = useForm();
    const [additionalErrorNode, setAdditionalErrorNode] = useState(<></>);
    const [inputFeedback, setInputFeedback] = useState(
      undefined as
        | ""
        | "validating"
        | "success"
        | "warning"
        | "error"
        | undefined
    );

    useEffect(() => {
      if (inputFeedback === "success") {
        const timer = setTimeout(() => {
          setInputFeedback(undefined);
        }, 2000);
        return () => {
          clearTimeout(timer);
        };
      }
    }, [inputFeedback]);

    const selector = useMemo(() => {
      if (entityType === "CONNECTION") {
        return selectConnectionById(entityId!);
      } else if (entityType === "WORKSPACE") {
        return selectOrganization;
      } else if (entityType === "USER") {
        return selectUser(entityId!);
      } else if (entityType === "WIDGET") {
        return selectChatWidgetById(entityId!);
      } else if (entityType === "SELF_SERVICE_PORTAL") {
        return selectSelfServicePortalById(entityId!);
      } else if (entityType === "DYNAMIC_FORM") {
        return selectDynamicFormById(entityId!);
      } else if (entityType === "CONTACT") {
        return selectContactById(entityId!);
      } else if (entityType === "CONVERSATION") {
        return selectConversationById(entityId!);
      }
      return selectUser(entityId!);
    }, [entityId, entityType]);

    const entityValue = useDoubleSelectorWithMemoize(
      () => selector as any,
      [selector],
      entity => get(entity, fieldPath),
      false
    ) as any;

    const entity = useMemo(
      () => BuildObjectWithPathString(fieldPath, entityValue),
      [entityValue, fieldPath]
    );

    const initialValues = useMemo(() => {
      if (!entity) {
        return {};
      }
      return {
        ...(transformEntityValues ? transformEntityValues(entity) : entity)
      };
    }, [entity, transformEntityValues]);

    useEffect(() => {
      form.resetFields();
    }, [entity, form]);

    useEffect(() => {
      setEditable(false);
      setAdditionalErrorNode(<></>);
      setInputFeedback(undefined);
    }, [entityId]);

    const { doAction: editEntity, isProcessing } = useSDKActionWithDeps(
      () => ({
        action: ((SDK: iSDK) => edits => {
          if (transformFormValues) {
            edits = transformFormValues(edits);
          }
          let editFunction: any = () => {};
          if (entityType === "WORKSPACE") {
            editFunction = SDK.editCurrentOrganization(edits);
          } else if (entityType === "USER") {
            editFunction = SDK.editUser(entityId!, edits);
          } else if (entityType === "CONNECTION") {
            editFunction = SDK.connections.editById(entityId!, edits);
          } else if (entityType === "WIDGET") {
            editFunction = SDK.chatWidgets.editById(entityId!, edits);
          } else if (entityType === "SELF_SERVICE_PORTAL") {
            editFunction = SDK.selfServicedPortals.editById(entityId!, edits);
          } else if (entityType === "DYNAMIC_FORM") {
            editFunction = SDK.dynamicForms.editById(entityId!, edits);
          } else if (entityType === "CONTACT") {
            editFunction = SDK.editContact(entityId!, edits);
          } else if (entityType === "CONVERSATION") {
            editFunction = SDK.editConversation(entityId!, edits);
          } else {
            editFunction = SDK.editCurrentUser(edits);
          }
          return editFunction;
        }) as any,
        failureMessage: "Something went wrong",
        throwError: true
      }),
      [entityId, entityType, transformFormValues]
    );

    const inputRefContainer = useRef<any>();
    const inputRef = useRef<any>();

    const [isEditable, setEditable] = useState(false);

    useEffect(() => {
      if (isEditable) {
        const inputElement = (() => {
          if (inputRef.current) {
            return inputRef.current;
          }
          const container = inputRefContainer.current;
          const inputElement = container.getElementsByTagName("input")[0];
          const textareaElement = container.getElementsByTagName("textarea")[0];
          const selectElement = container.getElementsByTagName("select")[0];
          return inputElement || textareaElement || selectElement;
        })();

        if (inputElement) {
          inputElement.focus();
        }
      }
    }, [isEditable]);

    const focusInput = useCallback(() => {
      setEditable(true);
    }, []);

    const onEdited = useCallback(async () => {
      try {
        await form.validateFields();
        const formValues = form.getFieldsValue();
        // console.log("onEdited", formValues);
        const value = get(formValues, fieldPath);
        const initialValue = get(initialValues, fieldPath);
        if (value !== initialValue) {
          setInputFeedback("validating");
          // console.log("formValues", formValues);
          if (value === undefined) {
            set(formValues, fieldPath, null);
          }
          editEntity(formValues)
            .then(d => {
              setInputFeedback("success");
              setAdditionalErrorNode(<></>);
              onClearError && onClearError();
            })
            .catch(e => {
              onSaveFailed && onSaveFailed(e, value, setAdditionalErrorNode);
              setInputFeedback("error");
            });
        }

        setEditable(false);
        onClearError && onClearError();
      } catch (e) {
        message.error("Please check your input");
        // Ignore
      }
    }, [
      editEntity,
      fieldPath,
      form,
      initialValues,
      onClearError,
      onSaveFailed
    ]);

    const WrappedInputField = useMemo(() => {
      if (React.isValidElement(children)) {
        const existingClassName = (children?.props as any)?.className || "";
        return React.cloneElement(children, {
          onClick: focusInput,
          onBlur: onEdited,
          onChange: saveOnChange ? onEdited : undefined,
          // disabled: !isEditable,
          className: classNames(existingClassName, {}),
          ref: inputRef
        } as any);
      }
      return children;
    }, [children, focusInput, onEdited, saveOnChange]);

    return (
      <>
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={onEdited}
          layout={formLayout}
          className={classNames("editable-input-field-form", {
            "display-mode": !isEditable
          })}
        >
          <div onClick={focusInput} ref={inputRefContainer}>
            <Form.Item
              label={label}
              name={fieldPath}
              rules={rules}
              hasFeedback={!!inputFeedback}
              validateStatus={inputFeedback}
              valuePropName={valuePropName}
              help={help}
            >
              {WrappedInputField}
            </Form.Item>
            <Button htmlType="submit" type="primary" className="hidden">
              Save
            </Button>
          </div>
        </Form>
        {additionalErrorNode}
      </>
    );
  }
);
