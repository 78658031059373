import { Button, Form, FormInstance, Input, Select, Switch } from "antd";
import { BraftJSInput } from "components/common/draft-js-advanced/draft-js-advanced";
import { ChatBotComplexityList } from "components/pages/chat-bots/chat-bot-templates-helpers/chat-bot-complexity-list";
import { ChatBotIndustries } from "components/pages/chat-bots/chat-bot-templates-helpers/chat-bot-industries";
import { ChatBotPlatforms } from "components/pages/chat-bots/chat-bot-templates-helpers/chat-bot-platforms";
import { ChatBotTags } from "components/pages/chat-bots/chat-bot-templates-helpers/chat-bot-tags";
import { ChatBotUseCases } from "components/pages/chat-bots/chat-bot-templates-helpers/chat-bot-use-cases";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";

export const AddEditChatBotForm = ({
  form,
  onSubmit,
}: {
  form: FormInstance<any>;
  onSubmit;
}) => {
  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  const isTemplateCreator =
    window.location.href.includes("localhost") ||
    window.location.href.includes("reach-it-right") ||
    isAdvancedMode;

  const DraftEditorRef = useRef<any>();
  const { setHtmlContent, getHtmlContent: _getHtmlContent } =
    DraftEditorRef.current || {};

  const [htmlInput, setHtmlInput] = useState("");
  useEffect(() => {
    setHtmlContent && setHtmlContent(htmlInput);
  }, [htmlInput, setHtmlContent]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
      initialValues={{}}
      hideRequiredMark={true}
      className="bold-form-labels"
      onFinish={onSubmit}
    >
      <Form.Item
        name="label"
        label="Label"
        rules={[
          {
            required: true,
            message: "Please enter a label",
          },
        ]}
      >
        <Input placeholder="Enter a label" size="large" />
      </Form.Item>
      {isTemplateCreator && (
        <>
          <Form.Item label="Description" shouldUpdate>
            {({ getFieldValue }) => {
              const description = getFieldValue(["data", "description"]);
              setHtmlInput(description);
              return (
                <div className="draft-js-container border border-gray-200 dark:border-gray-700 rounded-lg">
                  <BraftJSInput
                    initialValue={description}
                    ref={DraftEditorRef}
                    onBlur={() => {
                      const value = DraftEditorRef.current.getHtmlContent();
                      form.setFieldValue(["data", "description"], value);
                    }}
                    additionalExcludedControls={[
                      "font-size",
                      "fullscreen",
                      "hr",
                      "letter-spacing",
                      "remove-styles",
                      "undo",
                      "redo",
                      "text-indent",
                      "headings",
                      "text-align",
                    ]}
                  />
                </div>
              );
            }}
          </Form.Item>

          <Form.Item
            name={["data", "description"]}
            label="Description"
            rules={[
              {
                required: true,
                message: "Please enter a description",
              },
            ]}
            hidden
          >
            <Input.TextArea placeholder="Enter a description" />
          </Form.Item>
          <Form.Item name={["data", "tags"]} label="Tags" rules={[]}>
            <Select allowClear mode="tags" placeholder="Select one or more">
              {ChatBotTags.map((tag) => (
                <Select.Option key={tag.label} value={tag.label}>
                  {tag.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={["data", "useCases"]} label="Use Cases" rules={[]}>
            <Select allowClear mode="tags" placeholder="Select one or more">
              {ChatBotUseCases.map((tag) => (
                <Select.Option key={tag.label} value={tag.label}>
                  {tag.icon} {tag.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={["data", "industries"]}
            label="Industries"
            rules={[]}
          >
            <Select allowClear mode="tags" placeholder="Select one or more">
              {ChatBotIndustries.map((tag) => (
                <Select.Option key={tag.label} value={tag.label}>
                  {tag.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={["data", "platforms"]}
            label="Supported Platforms"
            rules={[]}
          >
            <Select allowClear mode="tags" placeholder="Select one or more">
              {ChatBotPlatforms.map((tag) => (
                <Select.Option key={tag.id} value={tag.id}>
                  {tag.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={["data", "complexity"]}
            label="Complexity"
            rules={[]}
          >
            <Select allowClear placeholder="Select one">
              {ChatBotComplexityList.map((tag) => (
                <Select.Option key={tag.id} value={tag.id}>
                  {tag.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={["data", "isPublic"]}
            valuePropName="checked"
            label="Make it Public"
            rules={[]}
          >
            <Switch />
          </Form.Item>
        </>
      )}
      <Button htmlType="submit" className="hidden">
        Submit
      </Button>
    </Form>
  );
};
