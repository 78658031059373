import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  AddConnectionLayout,
  AddConnectionModalContainer,
  AddConnectionStepsContainer,
  IntegrationSuccessfulScreen,
  StepContainer,
} from "components/modules/connections/add-connection-modal-templates";

import { Spin } from "antd";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { generateDefaultConnectionConfig } from "components/modules/connections/common-connection-settings/default-connection-config-generator";
import { push } from "connected-react-router";
import { useCallback, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { TelnyxPhoneSearcher } from "../phone-searcher";

export const AddTelnyxConnectionModal = ({
  visible,
  onChangeVisibility: _onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [createdConnection, setCreatedConnection] = useState("");

  const onManageConnection = (connectionId: string) => {};

  const dispatch = useDispatch();
  const store = useStore();

  const { doAction: createConnection, isProcessing: isActionProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (val) =>
          SDK.connections.create(val).then((d) => {
            setCreatedConnection(d.id!);
            return d;
          }),
        failureMessage: "Something went wrong",
        throwError: true,
      }),
      [],
    );

  const {
    doAction: buyAndConnectNumber,
    isProcessing: isNumberBuyingProcessing,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (val) =>
        SDK.telnyx.buyAndConnect(val).then((d) => {
          setCurrentStep(2);
        }),
      successMessage: "Phone Number has been secured",
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const onChangeVisibility = (visibility: boolean) => {
    _onChangeVisibility(visibility);
    setCurrentStep(0);
  };

  const onSubmit = useCallback(
    async (phoneNumber: string) => {
      const label = `Phone: ${phoneNumber}`;
      const configData = generateDefaultConnectionConfig("TELNYX", store);
      const connection = await createConnection({
        type: "TELNYX",
        label,
        data: configData,
      });
      await buyAndConnectNumber({
        connectionId: connection?.id,
        phoneNumber: phoneNumber,
      });
    },
    [buyAndConnectNumber, createConnection, store],
  );

  return (
    <>
      <AddConnectionModalContainer
        title="Connect Phone Number"
        icon={<i className="ri-phone-line"></i>}
        isVisible={visible}
        onChangeVisibility={onChangeVisibility}
      >
        <AddConnectionLayout
          currentStep={currentStep}
          totalSteps={1}
          stepsWithSidebars={[]}
          topBar={
            <AddConnectionStepsContainer
              currentStep={currentStep}
              steps={[
                {
                  label: "Pick a number",
                  icon: <i className="ri-search-line"></i>,
                },
                {
                  label: "Done",
                  icon: <i className="ri-check-line"></i>,
                },
              ]}
            />
          }
        >
          <StepContainer stepNumber={0} currentStep={currentStep}>
            <div className="description text-gray-600 dark:text-gray-400 mb-4 mode_transition">
              Pick a phone number to continue with
            </div>
            <Spin
              spinning={isNumberBuyingProcessing || isActionProcessing}
              indicator={<LoadingIndicatorWithSpin />}
            >
              <TelnyxPhoneSearcher
                onBuyNumber={(record) => onSubmit(record.phone_number)}
              />
            </Spin>
          </StepContainer>

          <StepContainer stepNumber={2} currentStep={currentStep}>
            <IntegrationSuccessfulScreen
              onManageConnection={() => {
                dispatch(
                  push(`/configurations/connections/${createdConnection}/`),
                );
              }}
              onHideWizard={() => onChangeVisibility(false)}
              subTitle="Now you will be able to send and receive SMS and make calls from Click Connector"
            ></IntegrationSuccessfulScreen>
          </StepContainer>
        </AddConnectionLayout>
      </AddConnectionModalContainer>
    </>
  );
};
