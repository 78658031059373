import { iEmailConnectionData } from "@sdk/conversations/conversations.models";
import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { AutoCloseConfigurationForm } from "components/modules/connections/common-connection-settings/auto-close-configuration/auto-close-configuration-form";
import { ChannelNameForm } from "components/modules/connections/common-connection-settings/channel-name/channel-name-form";
import { DarkModeBg } from "dark-mode-bg";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { ConfigureMailbox } from "../configure-mailbox/configure-mailbox";
const debounce = require("debounce-promise");

export const ConfigurePersonalMailboxModal = ({
  visible,
  onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const currentUserId = useSelector(selectCurrentUserId);
  const connections = useSelector(selectAllConnections);

  const personalEmailConnection = useMemo(() => {
    return connections.find(
      item =>
        item.type === "EMAIL" &&
        (item.data as iEmailConnectionData).isPersonal &&
        (item.data as iEmailConnectionData).ownerId === currentUserId
    );
  }, [connections, currentUserId]);

  return (
    (<Modal
      title={
        <ModalTitle
          title="Configure Mailbox"
          icon={<i className="ri-mail-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Configure Personal Mailbox Modal"
      destroyOnClose={true}
    >
      {personalEmailConnection?.id && (
        <ChannelNameForm connectionId={personalEmailConnection?.id} />
      )}
      {personalEmailConnection?.id && (
        <ConfigureMailbox
          connectionId={personalEmailConnection?.id}
          hideHeader={true}
        />
      )}
      {personalEmailConnection?.id && (
        <AutoCloseConfigurationForm
          connectionId={personalEmailConnection?.id}
        />
      )}
      <DarkModeBg />
    </Modal>)
  );
};
