import { CompanyAvatar } from "components/common/avatar/company-avatar";
import { ImageInputWithPreview } from "components/common/image-input-with-preview/image-input-with-preview";

export const CompanyLogoEditor = ({
  selectedFile,
  onChange,
  isUploading = false,
  companyId = " "
}: {
  selectedFile: any;
  onChange: any;
  isUploading?: any;
  companyId?: string;
}) => {
  return (
    <div className="user-header flex flex-row justify-center items-center mb-8 mt-8">
      <ImageInputWithPreview
        selectedFile={selectedFile}
        onChange={onChange}
        isUploading={isUploading}
        image={<CompanyAvatar companyId={companyId} size={80} />}
      />
    </div>
  );
};
