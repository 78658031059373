import DownOutlined from "@ant-design/icons/DownOutlined";
import { SDK } from "@sdk";
import { BulkConversationsAction } from "@sdk/conversations/conversations.controller-models";
import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import {
  Affix,
  Button,
  Divider,
  Dropdown,
  Menu,
  Segmented,
  Space,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import classNames from "classnames";
import { EmptyData } from "components/common/empty-data/empty-data";
import { BuildViewQuery } from "components/common/filter-schema/build-view-config";
import { FilterBar } from "components/common/filter-schema/filter-bar";
import { iFilterOption } from "components/common/filter-schema/filter-config-schema";
import { VirtualizedInfiniteList } from "components/common/infinity-list/virtualized-infinite-list";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";

import { Action, useKBar, useRegisterActions } from "kbar";
import _, { last } from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  loadMoreConversationsQueries,
  loadQueryConversations,
} from "store/modules/conversations/conversations.helpers";
import { selectConversationQueryWithIds } from "store/modules/conversations/conversations.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";

import { BadgesController } from "badge-controller";
import { selectFeatureRestrictionConfig } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStoreWithMemoize } from "store/store.hooks";
import { QueryConfig } from "store/utils/query-config";
import { useDebouncedValue } from "utils/hooks/use-debounced-value";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { useURLData } from "utils/hooks/use-url-filter";
import { justWait } from "utils/just-wait";
import { setArrayItemWithId } from "utils/set-array-item";
import {
  BuildChatMessageSearchQuery,
  ConversationSearchQueryBuilder,
} from "../../helpers/build-chat-search";
import { BulkEditConversations } from "../bulk-edit-conversations/bulk-edit-conversations";
import { ChatSearchInput } from "../chat-search-input/chat-search-input";
import { CreateChatListView } from "../create-chat-list-view/create-chat-list-view";
import { ChatFilterSchemaNew } from "./chat-filter-schema-new";
import { ChatListItem } from "./chat-list-item";
import "./chat-list-view.scss";

const statusOptions = [
  {
    value: "UNRESOLVED",
    label: "Open",
  },
  {
    value: "CLOSED",
    label: "Closed",
  },
];

export const ChatList = memo(
  ({
    selectedConversation,
    onSelectConversation,
    defaultQueryConfig,
    defaultQueryAlias,
    title,
    breakByChatStatus,
  }: {
    selectedConversation: string;
    onSelectConversation: (conversationId: string) => any;
    defaultQueryConfig: QueryConfig;
    defaultQueryAlias: string;
    title: string;
    breakByChatStatus?: boolean;
  }) => {
    const [_searchText, setSearchText] = useState("");
    const [searchText] = useDebouncedValue(_searchText, 600);
    const [shouldSearchMessages, setShouldSearchMessages] = useState(false);

    const store = useStore();
    const currentUser = useSelector(selectCurrentUser);
    const userRole = useMemo(() => {
      const currentUserRole = getRoleFromPermission(
        currentUser?.permissions || [],
      );
      return currentUserRole;
    }, [currentUser?.permissions]);

    // Toggle Back Text Message searches
    useEffectWhen(
      () => {
        if (shouldSearchMessages) {
          setShouldSearchMessages(false);
        }
      },
      [searchText, shouldSearchMessages],
      [searchText],
    );

    // const [filter, setFilter] = useState<any>(null); // Legacy - Should Remove this
    const [selectedFilters, setSelectedFilters] = useURLData(
      "chat-filter",
      [] as iFilterOption[],
    );

    const [maximizedFiltersVisible, setMaximizedFiltersVisibility] =
      useState(false);

    const [subFilter, setSubFilter] = useState("UNRESOLVED");
    const [sortBy, setSortBy] = useState<"UPDATED_TIME" | "CREATED_TIME">(
      "UPDATED_TIME",
    );

    const viewQuery: QueryConfig & { alias: string } = useMemo(() => {
      if (
        !searchText &&
        selectedFilters.length === 0 &&
        (!breakByChatStatus || subFilter === "UNRESOLVED") &&
        sortBy === "UPDATED_TIME"
      ) {
        return {
          ...defaultQueryConfig,
          alias: defaultQueryAlias,
        };
      }
      const filterQuery = BuildViewQuery({
        searchText: shouldSearchMessages ? "" : searchText,
        searchQueryBuilder: ConversationSearchQueryBuilder,
        selectedFilters,
        defaultQuery: defaultQueryConfig,
        schema: ChatFilterSchemaNew,
        store,
      });

      if (!defaultQueryConfig) {
        // todo:
        SDK.sendErrorLog({
          subject: "Error Log",
          data: {
            title: "Error Context",
            url: window.location.href,
            defaultQueryConfig: JSON.stringify(defaultQueryConfig || {}),
            viewTitle: title,
            breakByChatStatus: breakByChatStatus ? "Yes" : "No",
            defaultQueryAlias,
          },
        });
      }

      const query = {
        $and: [
          filterQuery?.query,
          _.omit(
            defaultQueryConfig.query,
            breakByChatStatus || searchText ? ["status"] : [],
          ),
          breakByChatStatus && !searchText
            ? subFilter === "UNRESOLVED"
              ? { status: "OPEN" }
              : { status: "CLOSED" }
            : {},
        ].filter((e) => e),
      };
      if (shouldSearchMessages) {
        query["messagesQuery"] = BuildChatMessageSearchQuery(searchText);
      }

      const queryConfig = {
        query: query,
        options: {
          ...defaultQueryConfig.options,
          sortBy:
            sortBy === "UPDATED_TIME"
              ? ["-metaData.lastMessage.timestamp"]
              : ["-metaData.createdAt"],
        },
      };
      return {
        ...queryConfig,
        alias: JSON.stringify(queryConfig),
      };
    }, [
      searchText,
      breakByChatStatus,
      subFilter,
      sortBy,
      selectedFilters,
      defaultQueryConfig,
      store,
      shouldSearchMessages,
      defaultQueryAlias,
      title,
    ]);

    const {
      state: { list: conversationsIds, isLoading, totalItems, lastFetchedPage },
      retry: reload,
    } = useQueryWithStoreWithMemoize(
      () => selectConversationQueryWithIds(viewQuery.alias),
      [viewQuery.alias],
      false,
      () => loadQueryConversations(_.omit(viewQuery, "alias"), viewQuery.alias),
      [viewQuery],
      [viewQuery],
    );

    useEffect(() => {
      if (title === "Pinned") {
        console.log("Reload");
        reload();
      }
    }, [reload, title]);

    const loadMore = useCallback(() => {
      console.log("Load More");
      loadMoreConversationsQueries(
        _.omit(viewQuery, ["alias"]),
        viewQuery.alias,
      )(store);
    }, [store, viewQuery]);

    const [scrollContainer, setScrollContainer] =
      useState<HTMLDivElement | null>(null);
    const [isHeaderFixed, setHeaderFixed] = useState(false);

    const onHeaderViewChanged = (fixed?: boolean) => {
      setHeaderFixed(fixed!);
    };

    // Patch to refresh the chatListState - START
    const [showChatList, setShowChatList] = useState(true);
    useEffect(() => {
      setShowChatList(false);
      const timer = setTimeout(() => {
        setShowChatList(true);
      }, 50);
      return () => {
        clearTimeout(timer);
      };
    }, [viewQuery]);
    // Patch to refresh the chatListState - END

    const [selectedConversations, setSelectedConversations] = useState(
      [] as string[],
    );

    const [bulkActionState, setBulkActionState] = useSimpleState({
      modalVisibility: false,
      action: "OPEN_CONVERSATION" as BulkConversationsAction,
    });

    const [createViewModalState, setCreateViewModalState] = useSimpleState({
      isVisible: false,
      filter: viewQuery,
    });

    const bulkActionQuery = useMemo(() => {
      return selectedConversations.length === 0
        ? {
            ...viewQuery.query,
          }
        : {
            id: { $in: selectedConversations },
          };
    }, [selectedConversations, viewQuery]);

    useEffectWhen(
      () => {
        if (selectedConversations.length > 0) {
          setSelectedConversations([]);
        }
      },
      [selectedConversations.length],
      [viewQuery],
    );

    const rootSearchAction = useMemo(
      () => [
        {
          id: "pre-conversation",
          name: "Previous Conversation",
          shortcut: ["ArrowUp"],
          keywords: "navigate",
          perform: () =>
            setArrayItemWithId(
              "previous",
              conversationsIds,
              selectedConversation,
              onSelectConversation,
            ),
          // section: "Documentation",
        },
        {
          id: "next-conversation",
          name: "Next Conversation",
          shortcut: ["ArrowDown"],
          keywords: "navigate",
          perform: () =>
            setArrayItemWithId(
              "next",
              conversationsIds,
              selectedConversation,
              onSelectConversation,
            ),
          // section: "Documentation",
        },
      ],
      [conversationsIds, onSelectConversation, selectedConversation],
    );

    useRegisterActions([...rootSearchAction].filter(Boolean) as Action[], [
      rootSearchAction,
    ]);

    const renderChatListItem = useMemo(
      () => (conversationId: string) => (
        <ChatListItem
          onConversationSelected={onSelectConversation}
          conversationId={conversationId!}
          key={conversationId!}
          isSelected={selectedConversations.includes(conversationId!)}
          onSelected={(status, shiftKey) => {
            if (status) {
              const lastSelectedConversation = last(selectedConversations);
              if (shiftKey && lastSelectedConversation) {
                const lastSelectedConversationIndex = conversationsIds.indexOf(
                  lastSelectedConversation!,
                );

                // console.log("conversationsIds", conversationsIds);

                const newSelectedIndex = conversationsIds.indexOf(
                  conversationId!,
                );

                // console.log("newSelectedIndex", newSelectedIndex);
                const conversationIdsToSelect =
                  lastSelectedConversationIndex < newSelectedIndex
                    ? conversationsIds.slice(
                        lastSelectedConversationIndex,
                        newSelectedIndex + 1,
                      )
                    : conversationsIds.slice(
                        newSelectedIndex,
                        lastSelectedConversationIndex,
                      );

                // console.log("conversationIdsToSelect", conversationIdsToSelect);
                setSelectedConversations([
                  ...selectedConversations,
                  ...conversationIdsToSelect!,
                ]);
              } else {
                setSelectedConversations([
                  ...selectedConversations,
                  conversationId!,
                ]);
              }
            } else {
              setSelectedConversations(
                _.without(selectedConversations, conversationId!),
              );
            }
          }}
          onTagsClicked={(tags) => {
            setSelectedFilters([
              {
                operatorConfig: {
                  value: tags,
                },
                operator: "IS_ONE_OF",
                key: "tags",
              },
            ]);
          }}
          onUserClicked={(userId) => {
            setSelectedFilters([
              {
                operatorConfig: {
                  value: userId,
                },
                operator: "IS",
                key: "users",
              },
            ]);
          }}
        />
      ),
      [onSelectConversation, selectedConversations, setSelectedFilters],
    );

    useEffectWhen(
      () => {
        setSelectedFilters([]);
      },
      [defaultQueryConfig, setSelectedFilters],
      [defaultQueryConfig],
    );

    const featureRestrictionConfig = useSelector(
      selectFeatureRestrictionConfig,
    );

    const BulkActionMenu = useMemo(() => {
      const modifier = selectedConversations.length > 0 ? "Selected" : "All";
      return (
        <Menu
          data-click-context="Bulk Chat Action Menu"
          onClick={({ key: action }) => {
            setBulkActionState({
              modalVisibility: true,
              action: action as any,
            });
          }}
        >
          <Menu.Item
            key="TRANSFER"
            icon={<i className="ri-chat-forward-line"></i>}
          >
            Transfer {modifier} Conversations
          </Menu.Item>
          <Menu.Item
            key="ASSIGN_USER"
            icon={<i className="ri-user-add-line"></i>}
          >
            Assign User
          </Menu.Item>
          <Menu.Item
            key="UNASSIGN_USER"
            icon={<i className="ri-user-shared-2-line"></i>}
          >
            Unassign User
          </Menu.Item>
          <Menu.Item key="ADD_TAG" icon={<i className="ri-price-tag-line"></i>}>
            Add Tag
          </Menu.Item>
          <Menu.Item
            key="REMOVE_TAG"
            icon={<i className="ri-price-tag-line"></i>}
          >
            Remove Tag
          </Menu.Item>
          <Menu.Item
            key="OPEN_CONVERSATION"
            icon={<i className="ri-inbox-unarchive-line"></i>}
          >
            Open {modifier} Conversation
          </Menu.Item>
          <Menu.Item
            key="CLOSE_CONVERSATION"
            icon={<i className="ri-inbox-archive-line"></i>}
          >
            Close {modifier} Conversation
          </Menu.Item>
          <Menu.Item
            key="DISMISS_ALERT"
            icon={<i className="ri-indeterminate-circle-line"></i>}
          >
            Dismiss Alert
          </Menu.Item>

          <Menu.Item
            key="SEND_MESSAGE"
            icon={<i className="ri-chat-new-line"></i>}
            disabled={
              !(
                userRole === "Owner" ||
                !featureRestrictionConfig?.["BULK_MESSAGE_SENDING"]?.[userRole]
              )
            }
          >
            Send Message
          </Menu.Item>
        </Menu>
      );
    }, [
      featureRestrictionConfig,
      selectedConversations.length,
      setBulkActionState,
      userRole,
    ]);

    const isLargeScreen = useMediaQuery({ query: "(min-width: 1400px)" });

    const {
      query: { toggle: toggleGlobalSearch, setSearch: setGlobalSearch },
    } = useKBar();

    useEffect(() => {
      if (selectedFilters.length > 0) {
        BadgesController.processBadgeEvent("use-chat-filters");
      }
    }, [selectedFilters]);

    return (
      <div
        className="conversation-list-container flex flex-col h-full"
        id="conversation-list-container"
        data-click-context="Chat List"
        style={{ width: "22rem" }}
        ref={setScrollContainer}
      >
        <div
          className="header pt-6 bg-white dark:bg-gray-900 mode_transition"
          style={{ width: "22rem" }}
        >
          <Affix target={() => scrollContainer} onChange={onHeaderViewChanged}>
            <div
              id="chat-list-header"
              className={classNames(
                "side-bar-header px-4 pt-1 bg-white dark:bg-gray-900 mode_transition",
                {
                  "border-gray-500": searchText.length > 0,
                },
              )}
            >
              <div
                className={classNames(
                  "title-bar flex flex-row justify-between items-center transition-all",
                  {
                    "mb-4": !isHeaderFixed,
                    "my-1": isHeaderFixed,
                    "text-gray-600 dark:text-gray-400 mode_transition":
                      isHeaderFixed,
                  },
                )}
              >
                <div
                  className={classNames("title font-bold truncate", {
                    "text-2xl": !isHeaderFixed,
                    "text-l": isHeaderFixed,
                  })}
                >
                  {title}
                </div>

                {selectedConversations.length === 0 && (
                  <>
                    <Space>
                      <Button
                        type="text"
                        icon={<i className="ri-refresh-line"></i>}
                        shape="circle"
                        onClick={() => reload()}
                      ></Button>
                      <Dropdown
                        trigger={["click"]}
                        overlay={
                          <Menu data-click-context="Chat List Menu">
                            {/* <Menu.Item
                            key="REFRESH_INBOX"
                            icon={<i className="ri-refresh-line"></i>}
                            onClick={() => reload()}
                          >
                            Refresh Inbox
                          </Menu.Item> */}
                            {/* <Menu.SubMenu
                            title={
                              <span className="text-lg">
                                <i className="ri-sort-desc"></i> Sort by
                              </span>
                            }
                          >   */}
                            <Menu.Item
                              onClick={() => setSortBy("UPDATED_TIME")}
                              className="text-lg"
                            >
                              <i
                                className={
                                  sortBy === "UPDATED_TIME"
                                    ? "ri-checkbox-circle-line"
                                    : "ri-checkbox-blank-circle-line"
                                }
                              ></i>
                              Updated Time
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => setSortBy("CREATED_TIME")}
                              className="text-lg"
                            >
                              <i
                                className={
                                  sortBy === "CREATED_TIME"
                                    ? "ri-checkbox-circle-line"
                                    : "ri-checkbox-blank-circle-line"
                                }
                              ></i>
                              Created Time
                            </Menu.Item>

                            {/* </Menu.SubMenu> */}
                          </Menu>
                        }
                      >
                        <Button
                          type="text"
                          // icon={<i className="ri-more-2-fill"></i>}
                          icon={<i className="ri-sort-desc"></i>}
                        ></Button>
                      </Dropdown>
                    </Space>
                  </>
                )}
                {selectedConversations.length > 0 && (
                  <Dropdown trigger={["click"]} overlay={BulkActionMenu}>
                    <Button>
                      Actions <DownOutlined />
                    </Button>
                  </Dropdown>
                )}
              </div>

              <div className="search-bar">
                <ChatSearchInput
                  selectedInbox={title}
                  searchText={_searchText}
                  onSearchTextChange={setSearchText}
                  loading={
                    (searchText.length === 0 && _searchText.length !== 0) ||
                    (_searchText.length > 0 && isLoading)
                  }
                />
                {!searchText && breakByChatStatus && (
                  <div className="sub-filter pt-4 justify-center items-center flex w-full">
                    <Segmented
                      options={statusOptions}
                      value={subFilter}
                      onChange={(e) => setSubFilter(e.toString())}
                      block
                      className="w-full"
                    />
                  </div>
                )}
                {/* <div className="flex flex-row justify-center items-center">
                  <div className="sadf">Mine</div>
                  <Switch size="small" />
                  <div className="asdfs">All</div>
                </div> */}

                {searchText && (
                  <div className="help-text mt-2 flex flex-row justify-center items-center">
                    <Tag color="blue">
                      <i className="ri-information-line"></i> Showing search
                      results
                    </Tag>
                  </div>
                )}
                {searchText.length > 0 && !shouldSearchMessages && (
                  <div className="help-text mt-2">
                    <i
                      className="ri-information-line relative"
                      style={{ top: 1 }}
                    ></i>{" "}
                    Messages are not searched
                    <div className="flex flex-row justify-end items-center">
                      <Button
                        type="link"
                        onClick={() => setShouldSearchMessages(true)}
                      >
                        Search Messages
                      </Button>
                    </div>
                  </div>
                )}
                {searchText.length > 0 && shouldSearchMessages && (
                  <div className="help-text mt-2">
                    <i
                      className="ri-information-line relative"
                      style={{ top: 1 }}
                    ></i>{" "}
                    Only message search results is shown
                    <div className="flex flex-row justify-end items-center">
                      <Button
                        type="link"
                        onClick={() => setShouldSearchMessages(false)}
                      >
                        Stop message search
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <Divider
                type="horizontal"
                className={classNames("w-full", {
                  // "my-2": isHeaderFixed,
                  "mb-0": !(searchText.length > 0 && !shouldSearchMessages),
                  "my-0": searchText.length > 0 && !shouldSearchMessages,
                })}
              >
                {/* When Message Query is present, bulk action does not work */}
                {selectedConversations.length > 0 ||
                (viewQuery?.query as any)?.messagesQuery ? (
                  <span className="text-sm text-gray-600 dark:text-gray-400 mode_transition">
                    {totalItems} Conversations
                  </span>
                ) : (
                  <span
                    className="text-sm text-gray-600 dark:text-gray-400 mode_transition"
                    id="chat-bulk-action-menu"
                  >
                    <Dropdown trigger={["click"]} overlay={BulkActionMenu}>
                      <Button type="link">
                        {totalItems} Conversations <DownOutlined />
                      </Button>
                    </Dropdown>
                  </span>
                )}
              </Divider>
              {selectedConversations.length > 0 && (
                <>
                  <div className="justify-between flex-row flex items-center">
                    <span>{selectedConversations.length} chat(s) selected</span>
                    <Button
                      type="link"
                      onClick={() => setSelectedConversations([])}
                    >
                      clear
                    </Button>
                  </div>
                  <Divider type="horizontal" className="my-0"></Divider>
                </>
              )}
            </div>
          </Affix>
        </div>

        {selectedFilters.length > 0 && (
          <div className="filters">
            {selectedFilters.length === 1 && (
              <>
                <FilterBar
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                  schema={ChatFilterSchemaNew}
                  prioritizeFilters={true}
                  hideAddFilters={true}
                  hideLabel={true}
                ></FilterBar>
                <div className="flex flex-row justify-center items-center mb-2">
                  <Button
                    size="small"
                    icon={<i className="ri-save-line"></i>}
                    type="dashed"
                    className="ml-2"
                    style={{ paddingLeft: 4 }}
                    onClick={() =>
                      setCreateViewModalState({
                        isVisible: true,
                        filter: viewQuery,
                      })
                    }
                  >
                    Save Filter as an Inbox
                  </Button>
                </div>
              </>
            )}
            {selectedFilters.length > 1 && (
              <div className="chat-filters-bar">
                {!maximizedFiltersVisible && (
                  <div className="tag-container flex flex-row justify-center items-center p-2">
                    <Tag
                      color={"blue"}
                      onClick={() => setMaximizedFiltersVisibility(true)}
                      className="cursor-pointer"
                    >
                      {selectedFilters.length} Filters Added{" "}
                      <i className="ri-arrow-down-s-line"></i>
                    </Tag>
                    <Tooltip title="Clear Filters">
                      <Button
                        type="dashed"
                        size="small"
                        icon={<i className="ri-filter-off-line"></i>}
                        onClick={() => setSelectedFilters([])}
                      ></Button>
                    </Tooltip>
                    <Tooltip title="Create Inbox with Current Filters">
                      <Button
                        size="small"
                        icon={<i className="ri-save-line"></i>}
                        type="dashed"
                        style={{ paddingLeft: 4 }}
                        onClick={() =>
                          setCreateViewModalState({
                            isVisible: true,
                            filter: viewQuery,
                          })
                        }
                      ></Button>
                    </Tooltip>
                  </div>
                )}
                {maximizedFiltersVisible && (
                  <>
                    <FilterBar
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                      schema={ChatFilterSchemaNew}
                      prioritizeFilters={true}
                      hideAddFilters={true}
                      hideLabel={true}
                    ></FilterBar>
                    <div className="tag-container flex flex-row justify-center items-center p-2">
                      <Tag
                        color={"blue"}
                        onClick={() => setMaximizedFiltersVisibility(false)}
                        className="cursor-pointer"
                      >
                        Hide Filters
                        <i className="ri-arrow-up-s-line"></i>
                      </Tag>
                      <Tooltip title="Clear Filters">
                        <Button
                          type="dashed"
                          size="small"
                          icon={<i className="ri-filter-off-line"></i>}
                          onClick={() => setSelectedFilters([])}
                        ></Button>
                      </Tooltip>
                      <Tooltip title="Create Inbox with Current Filters">
                        <Button
                          size="small"
                          icon={<i className="ri-save-line"></i>}
                          type="dashed"
                          style={{ paddingLeft: 4 }}
                          onClick={() =>
                            setCreateViewModalState({
                              isVisible: true,
                              filter: viewQuery,
                            })
                          }
                        ></Button>
                      </Tooltip>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}

        <Spin
          spinning={isLoading && conversationsIds.length === 0}
          indicator={<LoadingIndicatorWithoutSpin />}
          wrapperClassName="flex-1 flex chat-list-spin-container"
        >
          <div className="chat-list-container flex-1 flex h-full">
            {conversationsIds.length === 0 && (
              <div className="w-full h-full flex flex-col justify-center items-center absolute">
                <EmptyData
                  text="No conversations to show"
                  icon="ri-bill-line"
                  className="w-full"
                />
                <Button
                  type="text"
                  icon={<i className="ri-search-line"></i>}
                  onClick={async () => {
                    toggleGlobalSearch();
                    await justWait(200);
                    setGlobalSearch(searchText);
                  }}
                >
                  Try Global Search
                </Button>
              </div>
            )}
            {/* * Note: (isLoading && conversations.length === 0) is added as a patch since load more function does not get triggered at the end of list */}
            {!(isLoading && conversationsIds.length === 0) && showChatList && (
              <VirtualizedInfiniteList
                data={conversationsIds}
                renderItem={renderChatListItem}
                loadingIndicator={<LoadingIndicatorWithoutSpin />}
                isLoading={isLoading}
                hasMore={(totalItems || 0) > (lastFetchedPage || 0) * 20}
                onLoadMore={loadMore}
                rowHeight={isLargeScreen ? 85 : 78}
              />
            )}
          </div>
        </Spin>

        <BulkEditConversations
          visible={bulkActionState.modalVisibility}
          onChangeVisibility={(visibility) =>
            setBulkActionState({
              modalVisibility: visibility,
            })
          }
          onActionCompleted={() => {
            setSelectedConversations([]);
          }}
          action={bulkActionState.action}
          query={bulkActionQuery}
        />

        <CreateChatListView
          visible={createViewModalState.isVisible}
          queryConfig={createViewModalState.filter}
          onChangeVisibility={(visibility) =>
            setCreateViewModalState({ isVisible: visibility })
          }
        />
      </div>
    );
  },
);
