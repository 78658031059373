import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Dropdown, Input, Menu, Table, Tooltip, message } from "antd";
import { push } from "connected-react-router";
import copyToClipboard from "copy-to-clipboard";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { loadAllArticles } from "store/modules/articles/articles.helpers";
import { selectAllArticles } from "store/modules/articles/articles.selectors";
import { loadAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.helpers";
import { selectAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSearch } from "utils/hooks/use-search";
import { getArticleSlug } from "../helpers/get-article-slug";
import "./article-list.scss";

export const ArticleListSimple = ({
  onEditArticle,
  onAddArticle,
  disableMaxHeight,
}: {
  onEditArticle: (articleId?: string) => any;
  onAddArticle: () => any;
  disableMaxHeight?: boolean;
}) => {
  const { state: selfServicePortals } = useQueryWithStore(
    selectAllSelfServicePortals,
    loadAllSelfServicePortals,
  );

  const defaultPortal = selfServicePortals[0];

  const {
    state: allData,
    retry: reload,
    isLoading,
  } = useQueryWithStore(selectAllArticles, loadAllArticles, []);

  const {
    doAction: deleteArticle,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (articleId: string) =>
        SDK.articles.deleteById(articleId).then((d) => {
          return reload();
        }),
      successMessage: "Article has been deleted",
      failureMessage: "Something went wrong",
    }),
    [reload],
  );

  const dispatch = useDispatch();

  const articleListColumns = useMemo(
    () => [
      {
        title: "",
        dataIndex: "title",
        render: (title) => {
          return (
            <div className="font-bold flex flex-row items-center pl-4">
              <i className="ri-article-line mr-2"></i> {title}
            </div>
          );
        },
      },
      // {
      //   title: "",
      //   dataIndex: "type",
      //   width: 80,
      //   render: (text: string, article: iArticle) => {
      //     return <Tag>{article.type}</Tag>;
      //   },
      // },
      // {
      //   title: "",
      //   dataIndex: "tags",
      //   width: 100,
      //   render: (tags: string[], article: iArticle) => (
      //     <span>
      //       <Space size="middle">
      //         {tags &&
      //           tags.map((tag, index) => (
      //             <Tag key={index.toString()}>{tag}</Tag>
      //           ))}
      //         <Tag>{article.metaData.status}</Tag>
      //       </Space>
      //     </span>
      //   ),
      // },
      {
        title: "",
        key: "action",
        width: 50,
        render: (text, record) => (
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
              overlay={
                <Menu data-click-context="Article Item More button menu">
                  <Menu.Item
                    icon={<i className="ri-delete-bin-line"></i>}
                    onClick={(e) => {
                      deleteArticle(record.id);
                      // e.stopPropagation();
                      // dispatch(push(`/articles/${record.id}`));
                    }}
                  >
                    Delete Article
                  </Menu.Item>
                  <Menu.Item
                    icon={<i className="ri-clipboard-line"></i>}
                    onClick={(e) => {
                      copyToClipboard(record.id);
                      message.success("Copied");
                      // e.stopPropagation();
                    }}
                  >
                    Copy Article Id
                  </Menu.Item>
                  <Menu.Item
                    icon={<i className="ri-clipboard-line"></i>}
                    onClick={(e) => {
                      const domainConfig = defaultPortal.data.domain;
                      const url =
                        domainConfig.customDomainConfig?.domain &&
                        domainConfig.customDomainConfig?.isVerified &&
                        domainConfig.customDomainConfig?.isConfigured
                          ? `https://${domainConfig.customDomainConfig?.domain}`
                          : `https://${domainConfig.subdomain}.helpcenter.guide`;
                      const articleUrl = `${url}/articles/${getArticleSlug(
                        record.title,
                        record.id,
                      )}`;
                      copyToClipboard(articleUrl);
                      message.success("Copied");

                      // e.stopPropagation();
                    }}
                  >
                    Copy Article URL
                  </Menu.Item>
                </Menu>
              }
              placement="topCenter"
              trigger={["click"]}
              arrow
            >
              <Button
                type="text"
                icon={<i className="ri-more-2-line"></i>}
                onClick={(e) => e.stopPropagation()}
              />
            </Dropdown>
          </div>
        ),
      },
    ],
    [deleteArticle],
  );

  const { filteredData: data, searchTerm, setSearchTerm } = useSearch(allData);

  return (
    <div className="article-list">
      <div className="search-bar mb-4 px-4">
        <Input.Search
          placeholder="Search articles..."
          className="rounded-md"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          autoFocus={true}
          onSearch={setSearchTerm}
          addonAfter={
            <Tooltip title="Add Article">
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  onAddArticle
                    ? onAddArticle()
                    : dispatch(push(`/articles/new`));
                }}
                type="default"
                className="font-semibold rounded-r-lg"
              ></Button>
            </Tooltip>
          }
        />
      </div>

      <Table
        className="article-list-table"
        columns={articleListColumns}
        dataSource={data}
        pagination={data.length < 10 ? false : undefined}
        rowKey="id"
        loading={isLoading || isProcessing}
        // scroll={{ y: 400 }}
        style={{ maxHeight: disableMaxHeight ? undefined : 400 }}
        tableLayout="auto"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => onEditArticle(record.id), // click row
          };
        }}
      />
    </div>
  );
};
