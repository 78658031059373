export const BraftMessageVariableExtension = {
  type: "entity",
  name: "VARIABLE-TOKEN-ITEM",
  // control: {
  //   text: "VARIABLE TOKEN",
  // },
  mutability: "IMMUTABLE",
  component: (props) => {
    return <span className="variable-token-item">{props.children}</span>;
  },
  importer: (nodeName, node, source) => {
    if (
      nodeName.toLowerCase() === "span" &&
      node.classList &&
      node.classList.contains("variable-token-item")
    ) {
      return {
        mutability: "IMMUTABLE",
        data: {},
      };
    }
  },
  exporter: (entityObject, originalText) => {
    const { foo } = entityObject.data;
    return (
      <span data-foo={foo} className="variable-token-item">
        {originalText}
      </span>
    );
  },
};
