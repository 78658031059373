import {
  iConnection,
  iEmailConnectionData,
} from "@sdk/conversations/conversations.models";
import { Divider, Form, Input } from "antd";
import { CollapsibleConfigurationSection } from "components/common/collapsible-configuration-panel/collapsible-configuration-container";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { SectionHeader } from "components/common/section-header";
import { ConnectionDataEditor } from "components/modules/connections/common-connection-settings/connection-data-editor";
import { GlobalConfig } from "config";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { isEmail } from "utils/is-email";
import { debouncedMailboxEmailAvailability } from "../../helpers/mailbox-availabilty-checker";
import { ConfigureInbuiltMailServer } from "../configure-inbuilt-mail-server/configure-inbuilt-mail-server";
import { ConfigureCustomSMTP } from "../configure-smtp-connection/configure-smtp-connection";
import { ConnectGmailToConnection } from "../connect-gmail-to-connection/connect-gmail-to-connection";
const debounce = require("debounce-promise");

export const ConfigureMailbox = ({
  connectionId,
  hideHeader,
}: {
  connectionId: string;
  hideHeader?: boolean;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const { customSmtp, customDomain, gmailMailboxData } =
    (connection?.data as iEmailConnectionData) || {};

  const isCustomEmailConfigured =
    customSmtp || customDomain || gmailMailboxData;

  return (
    <div className="animated fadeInLeftMin">
      {!hideHeader && (
        <>
          <SectionHeader
            title="Configure Mailbox"
            icon={<i className="ri-mail-settings-line"></i>}
            description="Configure your mailbox"
          />
          <Divider />
        </>
      )}

      <ConnectionDataEditor
        connectionId={connectionId}
        icon={"ri-user-smile-line"}
        title="Change Sender Name"
        description="Change the name a customer sees when sending an email through Click Connector"
      >
        <Form.Item
          label="Sender Name"
          name={["data", "senderName"]}
          help="Your name which your clients will see Eg: Support @ ABC"
          rules={[{ required: true, message: "Please select an email" }]}
        >
          <Input placeholder="" size="large" />
        </Form.Item>
      </ConnectionDataEditor>

      <ConfigurationEditorComponent
        entityType="CONNECTION"
        entityId={connectionId}
        icon={"ri-at-line"}
        title="Change Click Connector Email Address"
        description="Change the email address used within Click Connector"
        transformFormValues={(values) => ({
          data: {
            email: `${values.data.email}@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN}`,
          },
        })}
        transformEntityValues={(connection: iConnection) => ({
          data: {
            email: connection.data.email.replace(
              `@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN}`,
              "",
            ),
          },
        })}
      >
        <Form.Item
          label="Email"
          name={["data", "email"]}
          rules={[
            { required: true, message: "Please select an email" },
            ({ getFieldValue }) => ({
              validator: async (rule, value) => {
                const isValid = isEmail(
                  `${value}@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN}`,
                );
                if (!isValid) {
                  throw `${value}@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN} is not a valid email`;
                }
              },
            }),
            ({ getFieldValue }) => ({
              validator: async (rule, value) =>
                await debouncedMailboxEmailAvailability(
                  `${value}@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN}`,
                  connectionId,
                ),
            }),
          ]}
        >
          <Input
            placeholder="support.myCompany"
            suffix={`@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN}`}
            size="large"
          />
        </Form.Item>
      </ConfigurationEditorComponent>

      {(!isCustomEmailConfigured || customDomain) && (
        <CollapsibleConfigurationSection
          icon={"ri-mail-settings-line"}
          title="Setup Custom Email Domain"
          description="Setup a custom email address if you have a domain but no email server"
        >
          <ConfigureInbuiltMailServer connectionId={connectionId} />
        </CollapsibleConfigurationSection>
      )}

      {(!isCustomEmailConfigured || customSmtp) && (
        <CollapsibleConfigurationSection
          icon={"ri-mail-settings-line"}
          title="Connect Gmail/AWS/Custom SMTP"
          description="Use an existing mail service provider through Click Connector"
        >
          <ConfigureCustomSMTP connectionId={connectionId} />
        </CollapsibleConfigurationSection>
      )}

      {(!isCustomEmailConfigured || gmailMailboxData) && (
        <CollapsibleConfigurationSection
          icon={"ri-mail-settings-line"}
          title="Connect Gmail (Beta)"
          description="Use an existing Gmail account through Click Connector"
        >
          <ConnectGmailToConnection connectionId={connectionId} />
        </CollapsibleConfigurationSection>
      )}
    </div>
  );
};
