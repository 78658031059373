import { Badge, Button, Divider, Segmented, Tag } from "antd";
import classNames from "classnames";
import { useMemo, useState } from "react";
import {
  APPSUMO_PLANS,
  BUSINESS_PRICING,
  STARTUP_PRICING,
  TEAM_PRICING,
  iPricingPlan,
} from "./pricing-plans-data";
import "./pricing-plans.scss";

const _planGroups = [
  {
    label: "Deals",
    plans: APPSUMO_PLANS,
  },
  {
    label: "For Startups",
    plans: STARTUP_PRICING,
  },
  {
    label: "For Small Teams",
    plans: TEAM_PRICING,
  },
  {
    label: "For Growing Businesses",
    plans: BUSINESS_PRICING,
  },
];

export const PricingPlans = ({
  onPlanSelect,
  selectedPlan,
  hideYearly,
  removeFreePlan,
  hideStartupPlans,
}: {
  onPlanSelect: (plan: iPricingPlan, isYearly?: boolean) => any;
  selectedPlan?: string;
  hideYearly?: boolean;
  removeFreePlan?: boolean;
  hideStartupPlans?: boolean;
}) => {
  const [mode, setMode] = useState<"Startup" | "Team" | "Business">("Startup");
  const [period, setPeriod] = useState("MONTHLY");

  const planGroups = useMemo(() => {
    return _planGroups.filter((e) => {
      if (hideStartupPlans && e.label.includes("Startup")) {
        return false;
      }
      if (
        e.label.includes("Deals") &&
        !window.location.href.includes("localhost")
      ) {
        return false;
      }
      return true;
    });
  }, [hideStartupPlans]);

  return (
    <div className="pricing-plans-container flex flex-col items-center max-w-2xl margin-auto">
      <div className="pricing-table-container w-full">
        {!hideYearly && (
          <div className="flex flex-row justify-center items-center">
            <div
              className="period-selector flex flex-row justify-center items-center"
              style={{ width: 320 }}
            >
              <Segmented
                value={period}
                options={[
                  { label: "Monthly", value: "MONTHLY" },
                  { label: "Yearly", value: "YEARLY" },
                ]}
                onChange={(e) => setPeriod(e.toString())}
              />
            </div>
          </div>
        )}

        {planGroups.map((planGroup) => (
          <div key={planGroup.label}>
            <Divider className="text-sm font-bold text-gray-600">
              {planGroup.label}
            </Divider>
            <div className="flex flex-col w-full px-4">
              {planGroup.plans
                .filter((item) =>
                  removeFreePlan ? item.price !== "Free" : true,
                )
                .map((plan, index) =>
                  plan.isRecommended ? (
                    <Badge.Ribbon text="Recommended" key={index.toString()}>
                      <PlanCard
                        key={index.toString()}
                        plan={plan}
                        selectedPlan={selectedPlan}
                        isSelectable={true}
                        onSelect={() => onPlanSelect(plan, period === "YEARLY")}
                        yearly={period === "YEARLY"}
                      />
                    </Badge.Ribbon>
                  ) : (
                    <PlanCard
                      key={index.toString()}
                      plan={plan}
                      selectedPlan={selectedPlan}
                      isSelectable={true}
                      onSelect={() => onPlanSelect(plan, period === "YEARLY")}
                      yearly={period === "YEARLY"}
                    />
                  ),
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const PlanCard = ({
  plan,
  isSelectable,
  onSelect: _onSelect,
  selectedPlan,
  yearly,
}: {
  plan: iPricingPlan;
  onSelect?: (plan: iPricingPlan) => any;
  isSelectable?: boolean;
  selectedPlan?: string;
  yearly?: boolean;
}) => {
  const [isProcessing, setProcessing] = useState(false);

  const onSelect = async () => {
    setProcessing(true);
    try {
      _onSelect && (await _onSelect(plan));
    } catch (e) {
      // Ignore
    }
    setProcessing(false);
  };

  const { price, isSelected, planId, showYearly } = useMemo(() => {
    const showYearly =
      (plan.planId && plan.planId.indexOf("YEARLY") > -1) || yearly;
    const planId =
      plan.planId || `${plan.id}_${showYearly ? "YEARLY" : "MONTHLY"}`;

    const annualPrice = Number(plan.price.slice(1))
      ? `$${Math.round((Number(plan.price.slice(1)) * 10) / 12)}`
      : plan.price;

    return {
      price: showYearly ? annualPrice : plan.price,
      isSelected:
        plan.id === "STARTUP"
          ? plan.id === selectedPlan
          : planId === selectedPlan,
      showYearly,
      planId,
    };
  }, [selectedPlan, plan, yearly]);

  const isFreePlan = plan.price === "Free";
  const isCustomPricing =
    !isFreePlan && !Number(plan.price.slice(1)) && plan.price.slice(1) !== "0";

  return (
    <div
      className={classNames(
        "pricing-card items-start justify-between flex flex-row border dark:border-gray-700 dark:bg-gray-900 my-4 mode_transition rounded-lg  p-6 hover:bg-gray-100 w-full",
        {
          "border-blue-600": plan.isRecommended,
        },
      )}
    >
      <div className="right-side flex-1">
        <div className="title font-bold">{plan.name}</div>
        <div className="description text-gray-600 text-xs">
          {plan.description}
        </div>
        <div className="line-items w-full flex-row flex-wrap items-center mt-2">
          {plan.listItems.map((item) => (
            <Tag
              key={item.content}
              className="flex-row hover:bg-gray-100 mr-2 mb-2 inline-flex font-normal"
            >
              <div className="icon pr-2">{item.icon}</div>
              {item.content}
            </Tag>
          ))}
        </div>
      </div>
      <div className="left-side flex flex-col justify-between items-center">
        <div className="flex flex-col">
          <div className="title font-bold">
            {price}
            {!(isCustomPricing || isFreePlan) && (
              <>
                /<span className="text-sm">month</span>
              </>
            )}
          </div>
          {!(isCustomPricing || isFreePlan) && (
            <>
              <div className="text-gray-600 dark:text-gray-400 mb-4 mode_transition text-xs">
                {showYearly ? `Billed Yearly` : `Billed Monthly`}
              </div>
            </>
          )}
        </div>
        <div className="plan-selector">
          {!isSelected && isSelectable && !isCustomPricing && (
            <Button
              type="primary"
              onClick={onSelect}
              loading={isProcessing}
              style={{ width: 100 }}
              size="small"
              className="font-bold"
              icon={<i className="ri-check-line"></i>}
            >
              Activate
            </Button>
          )}
          {isSelected && (
            <Button
              type="primary"
              className="cursor-text font-bold"
              style={{ width: 100 }}
              size="small"
            >
              Active
            </Button>
          )}
          {isCustomPricing && (
            <Button
              type="primary"
              className="cursor-pointer font-bold"
              style={{ width: 100 }}
              size="small"
              icon={<i className="ri-chat-4-line"></i>}
              onClick={() =>
                (window as any).ccWidget?.setWidgetVisibility(true)
              }
            >
              Chat
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
