import UsergroupAddOutlined from "@ant-design/icons/UsergroupAddOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  iUserGroup,
  UserGroupType,
} from "@sdk/user-management/user-management.models";
import { Button, Form, Input, message, Modal, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DarkModeBg } from "dark-mode-bg";
import { ManageUserGroupMembers } from "modal-registry";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { DeepPartial } from "redux";
import { selectGroupById } from "store/modules/groups/groups.selectors";

export const AddEditUserGroupModal = ({
  visible,
  onChangeVisibility,
  userGroupId,
  userGroupType,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  userGroupId: string;
  userGroupType: UserGroupType;
}) => {
  const [form] = useForm();

  const userGroup = useSelector(selectGroupById(userGroupId));

  useEffect(() => {
    if (userGroup) {
      form.setFieldsValue({
        label: userGroup.label,
      });
    }
  }, [form, userGroup]);

  useEffect(() => {
    form.resetFields();
  }, [form, visible]);

  const initialValues = useMemo(() => {
    return {
      label: userGroup?.label,
    };
  }, [userGroup]);

  const {
    doAction: onAddUserGroup,
    isProcessing: isAddGroupProcessing,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (label: string) => SDK.createGroup(label, userGroupType),
      successMessage: "User Group has been created",
      failureMessage: "Something went wrong",
    }),
    [userGroupType]
  );

  const {
    doAction: onEditUserGroup,
    isProcessing: isEditGroupProcessing,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (req: { id: string; edits: DeepPartial<iUserGroup> }) =>
        SDK.editGroupById(req.id, req.edits),
      successMessage: "User Group has been modified",
      failureMessage: "Something went wrong",
    }),
    []
  );

  const { changePanelState } = useModalPanels();

  const _onSubmit = useCallback(
    async (values) => {
      if (userGroupId) {
        // Update Mode
        await onEditUserGroup({
          id: userGroupId,
          edits: {
            label: values.label,
          },
        });
        onChangeVisibility(false);
      } else {
        // Create Mode
        const userGroup = await onAddUserGroup(values.label);
        onChangeVisibility(false);
        changePanelState(ManageUserGroupMembers, true, {
          userGroupId: userGroup?.id!,
        });
      }
    },
    [
      changePanelState,
      onAddUserGroup,
      onChangeVisibility,
      onEditUserGroup,
      userGroupId,
    ]
  );

  const modalTitle = useMemo(() => {
    const verb = !userGroupId ? "Create New" : "Edit";
    const noun =
      userGroupType === "TEAM"
        ? "Team"
        : userGroupType === "DEPARTMENT"
        ? "Department"
        : "User Group";

    return `${verb} ${noun}`;
  }, [userGroupId, userGroupType]);

  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      _onSubmit(form.getFieldsValue());
    } catch (e) {
      message.error("Please check your input");
    }
  }, [_onSubmit, form]);

  return (
    <>
      <Modal
        title={
          <div className="font-bold text-xl">
            <Space>
              <UsergroupAddOutlined className="icon-fix" /> {modalTitle}
            </Space>
          </div>
        }
        open={visible}
        onOk={onSubmit}
        onCancel={() => {
          onChangeVisibility(false);
        }}
        okText="Save"
        data-click-context="Add Edit User Group Modal"
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          {/* Label */}
          <Form.Item
            name="label"
            label="Label"
            rules={[{ required: true, message: "Please enter a label" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="User Group Name"
              size="large"
              autoFocus
            />
          </Form.Item>
          <Button htmlType="submit" className="hidden">
            Submit
          </Button>
        </Form>
        <DarkModeBg />
      </Modal>
    </>
  );
};
