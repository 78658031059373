import { Button, Space, Spin } from "antd";
import { LoadingIndicatorWithSpin } from "../loading-indicator/loading-indicator";
import "./styled-file-input.scss";

export const StyledFileInput = ({
  selectedFile,
  onChange,
  isUploading = false,
  filePreview,
  className,
  accept,
}: {
  selectedFile: any;
  onChange: any;
  isUploading?: any;
  filePreview?: JSX.Element;
  className?: string;
  accept?: string;
}) => {
  return (
    <div className={className}>
      <Space>
        {filePreview}
        <div className="styled-file-input flex flex-row justify-center relative">
          {selectedFile && (
            <Spin
              spinning={isUploading}
              indicator={<LoadingIndicatorWithSpin />}
            >
              <Button
                icon={<i className="ri-upload-2-line"></i>}
                type="dashed"
                disabled
              >
                File Selected
              </Button>
            </Spin>
          )}
          {!selectedFile &&
            (filePreview ? (
              <></>
            ) : (
              !isUploading && (
                <Button
                  icon={<i className="ri-upload-2-line"></i>}
                  type="dashed"
                >
                  Upload File
                </Button>
              )
            ))}
          <input
            type="file"
            onChange={(event) => {
              if (event.target.files !== null) {
                onChange(event.target.files[0]);
              }
            }}
            accept={accept}
          />
        </div>
      </Space>
    </div>
  );
};
