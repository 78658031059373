import DragOutlined from "@ant-design/icons/DragOutlined";
import dayjs from "dayjs";

import { useSDK } from "@sdk";
import { Button, Dropdown, Menu, Modal, Segmented, Space, Spin } from "antd";
import useModal from "antd/lib/modal/useModal";
import classnames from "classnames";
import { EmptyData } from "components/common/empty-data/empty-data";
import { BuildViewQuery } from "components/common/filter-schema/build-view-config";
import { ModalTitle } from "components/common/modal-title";

import React, { useContext, useEffect, useMemo, useState } from "react";
import { useStore } from "react-redux";
import { useViewRefresher } from "utils/hooks/use-view-refresher";

import { DarkModeBg } from "dark-mode-bg";
import { useMeasure } from "react-use";
import { useDebounce } from "utils/hooks/use-debounce";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { useAvailableCharts } from "../../helpers/use-available-charts";

import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import _ from "lodash";
import { useReportFilterSchema } from "../../helpers/use-report-filter-schema";
import { SimplePivotTable } from "../pivot-tables/pivot-table-simple";
import { ReportCanvasStateContext } from "../report-canvas/report-canvas-state-context";
import { iReportWidget } from "../report-canvas/widget-canvas-models";
import { ReportWidgetBarChart } from "./components/bar-chart-2d";
import { Heatmap } from "./components/heat-map";
import { ReportWidgetPieChart2D } from "./components/pie-chart-2d";

export const UserPerformanceReport = ({
  reportConfig
}: {
  reportConfig: iReportWidget;
}) => {
  return (
    <div className="flex flex-row justify-center items-center h-full w-full">
      <EmptyData
        icon={<i className="ri-information-line text-4xl"></i>}
        text="Widget is not available for your account"
      />
    </div>
  );
};

const CustomerReportBodyComponentMap = {
  USER_PERFORMANCE_REPORT: UserPerformanceReport
};

export const DraggableReportWidget = React.forwardRef(
  (
    {
      data,
      className,
      style,
      children,
      onEditWidget,
      onRemoveWidget,
      onInspectSegment,
      isEditMode,
      ...props
    }: {
      data: iReportWidget;
      style?: any;
      className?: string;
      children?: any;
      onEditWidget?: (widgetId: string) => any;
      onInspectSegment?: (point) => any;
      onRemoveWidget?: () => any;
      isEditMode?: boolean;
    },
    ref
  ) => {
    const [fullScreenEnabled, setFullScreen] = useState(false);
    const [modal, context] = useModal();

    const { visible: showWidget, reRender: refreshWidget } = useViewRefresher(
      data
    );

    const ReportComponent =
      CustomerReportBodyComponentMap[data.type] || ReportBody;

    return (
      <div
        className={classnames(
          "flex flex-col justify-start items-start border border-dashed border-gray-400 dark:border-gray-600 rounded-lg overflow-hidden",
          className
        )}
        style={{ ...(style || {}) }}
        ref={ref as any}
      >
        <div className="flex flex-row w-full p-4">
          {isEditMode && (
            <div className="drag-handle">
              <Button
                {...props}
                type="text"
                icon={
                  <DragOutlined style={{ cursor: "pointer", color: "#999" }} />
                }
              ></Button>
            </div>
          )}

          <div className="header-inner flex flex-col ml-2 flex-1">
            <div className="title font-bold text-lg">{data.title}</div>
            <div className="text-gray-600">{data.description}</div>
          </div>
          <Space>
            <Button
              type="text"
              icon={
                fullScreenEnabled ? (
                  <i className="ri-fullscreen-exit-line"></i>
                ) : (
                  <i className="ri-fullscreen-line"></i>
                )
              }
              onClick={() => setFullScreen(true)}
            ></Button>
            {isEditMode && (
              <>
                <Button
                  type="text"
                  onClick={() => onEditWidget && onEditWidget(data.id)}
                  icon={<i className="ri-edit-line"></i>}
                ></Button>
                <Button
                  type="text"
                  onClick={() => onRemoveWidget && onRemoveWidget()}
                  icon={<i className="ri-delete-bin-line"></i>}
                ></Button>
              </>
            )}

            <Button
              type="text"
              onClick={() => refreshWidget()}
              icon={<i className="ri-refresh-line"></i>}
            ></Button>
          </Space>
        </div>
        <div className="body w-full flex-1 overflow-hidden">
          {showWidget && (
            <ReportComponent
              reportConfig={data}
              onInspectSegment={onInspectSegment}
            />
          )}

          <Modal
            title={
              <ModalTitle
                title={data.title}
                icon={<i className="ri-report-line"></i>}
              />
            }
            open={fullScreenEnabled}
            footer={null}
            onCancel={() => {
              setFullScreen(false);
            }}
            data-click-context="Full Screen Widget"
            className="very-big-modal"
          >
            <ReportComponent
              reportConfig={data}
              onInspectSegment={onInspectSegment}
            />
            <DarkModeBg />
          </Modal>
        </div>
        {children}
      </div>
    );
  }
);

export const ChartTypeIcons = {
  DIGIT: <i className="ri-number-1"></i>,
  BAR_CHART: <i className="ri-bar-chart-fill"></i>,
  TABLE: <i className="ri-grid-line"></i>,
  LINE_CHART: <i className="ri-line-chart-line"></i>,
  PIVOT_TABLE: <i className="ri-table-line"></i>,
  PIE: <i className="ri-pie-chart-line"></i>,
  DOUGHNUT_CHART: <i className="ri-donut-chart-line"></i>,
  VEN_DIAGRAM: <i className="ri-pie-chart-box-line"></i>,
  "2D_BAR_CHART": <i className="ri-bar-chart-fill"></i>,
  "2D_PIE_CHART": <i className="ri-pie-chart-line"></i>
};

export const ReportBody = ({
  reportConfig: _reportConfig,
  onInspectSegment
}: {
  reportConfig: iReportWidget;
  onInspectSegment?: (point) => any;
}) => {
  const availableChartTypes = useAvailableCharts(_reportConfig);

  const [selectedChartType, setSelectedChartType] = useState(
    availableChartTypes.includes(
      _reportConfig?.reportViewConfig?.defaultVisualization!
    )
      ? _reportConfig?.reportViewConfig?.defaultVisualization!
      : availableChartTypes[0]!
  );

  // Automatically change Chart Type
  useEffect(() => {
    if (!availableChartTypes.includes(selectedChartType)) {
      setSelectedChartType(availableChartTypes[0]);
    }
  }, [availableChartTypes, selectedChartType]);

  const { state: canvasState } = useContext(ReportCanvasStateContext);
  const filterSchema = useReportFilterSchema(canvasState?.type);
  const store = useStore();

  const filterConfig = useMemo(() => {
    const periodFilterSpecFromCanvas = (canvasState.filters || []).find(
      item => item.key === "period"
    );
    const periodFilterSpecFromReport = (_reportConfig.filters || []).find(
      item => item.key === "period"
    );

    const canvasFilterSpec = (canvasState.filters || []).filter(
      item => item.key !== "period"
    );

    const reportFilterSpec = (_reportConfig.filters || []).filter(
      item => item.key !== "period"
    );

    const filter = BuildViewQuery({
      selectedFilters: [...canvasFilterSpec, ...reportFilterSpec],
      defaultQuery: {} as any,
      schema: filterSchema,
      store: store
    }).query;

    const dateFilter = (() => {
      let dateField = "createdTime";
      const periodFilterSpec =
        periodFilterSpecFromReport || periodFilterSpecFromCanvas;
      const dateSpec = (periodFilterSpec?.operatorConfig.value as number[]) || [
        dayjs()
          .subtract(7, "day")
          .valueOf(),
        dayjs().valueOf()
      ];
      if (periodFilterSpec) {
        const periodFilterSchema = _.find(filterSchema.filters, {
          key: periodFilterSpec.key
        });
        const operatorConfig = _.find(
          periodFilterSchema?.fieldOptions?.operators,
          {
            id: periodFilterSpec.operator
          }
        );
        const periodQueryObj = operatorConfig?.queryGenerator?.(
          dateSpec,
          store.getState()
        );
        if (periodQueryObj) {
          // There will be only one field
          dateField = Object.keys(periodQueryObj)[0];
        }
      }

      return {
        dateField: dateField,
        startDate:
          dateSpec && typeof dateSpec[0] === "string"
            ? dayjs(dateSpec[0]).valueOf()
            : dateSpec[0].valueOf(),
        endDate:
          dateSpec && typeof dateSpec[1] === "string"
            ? dayjs(dateSpec[1]).valueOf()
            : dateSpec[1].valueOf()
      };
    })();

    return {
      ...dateFilter,
      filter: {
        ...filter,
        schemaOptions: _reportConfig.reportViewConfig.schemaOptions
      }
    };
  }, [
    _reportConfig.filters,
    _reportConfig.reportViewConfig.schemaOptions,
    canvasState.filters,
    filterSchema,
    store
  ]);

  const { data: rawData, isLoading } = useSDK(
    sdk => {
      const ReportTypeToAPIMap = {
        CONVERSATION: sdk.getConversationReports,
        LEADS: sdk.getContactReports,
        ACTIVITIES: sdk.getActivitiesReports,
        USER_REPORT: sdk.getConversationReports,
        COMPANIES: sdk.generateCompaniesReport,
        OPPORTUNITIES: sdk.generateOpportunitiesReport,
        ENGAGEMENTS: sdk.generateEngagementsReport,
        ANALYTICS: sdk.generateAnalyticsReport,
        MESSAGES: sdk.generateMessagesReport
      };
      const APIFunction = ReportTypeToAPIMap[
        reportConfig.type
      ] as typeof sdk.getConversationReports;

      return APIFunction({
        // Period
        dateField: filterConfig.dateField,
        startDate: filterConfig.startDate,
        endDate: filterConfig.endDate,
        // Filters
        filters: filterConfig.filter,
        // Group By
        groupBy: (_reportConfig.groupBy || []).join(","),
        // Aggregate
        period: _reportConfig.aggregateByPeriod,
        aggregateFunction: _reportConfig.aggregateFunction,
        aggregateTargetField: _reportConfig.aggregateTargetField
      });
    },
    [_reportConfig, canvasState.filters],
    false,
    []
  );

  const reportConfig = useMemo(() => {
    return {
      ..._reportConfig,
      dateField: filterConfig.dateField,
      period: [filterConfig.startDate, filterConfig.endDate]
    } as iReportWidget;
  }, [
    _reportConfig,
    filterConfig.dateField,
    filterConfig.endDate,
    filterConfig.startDate
  ]);

  const { visible: showChart, reRender: reRenderChart } = useViewRefresher();
  const refreshViewOnResize = useDebounce(reRenderChart, 400);
  const [isFirstRender, setFirstRender] = useState(true);
  const [setRef, { width, height }] = useMeasure();

  useEffectWhen(
    () => {
      if (isFirstRender) {
        setFirstRender(false);
      } else {
        refreshViewOnResize();
      }
    },
    [width, height, refreshViewOnResize, isFirstRender],
    [width, height]
  );

  const { isInspectAvailable, reportNoun } = useMemo(() => {
    if (canvasState.type === "LEADS") {
      return {
        isInspectAvailable: true,
        reportNoun: "Customers"
      };
    } else if (canvasState.type === "CONVERSATION") {
      return {
        isInspectAvailable: true,
        reportNoun: "Conversations"
      };
    } else if (canvasState.type === "ACTIVITIES") {
      return {
        isInspectAvailable: false,
        reportNoun: "Activities"
      };
    }
    return {
      isInspectAvailable: false,
      reportNoun: "Records"
    };
  }, [canvasState.type]);

  return (
    <div className="h-full w-fill overflow-hidden" ref={setRef}>
      <div className="h-full w-fill flex flex-col overflow-auto">
        {availableChartTypes.length > 1 && (
          <div className="flex flex-row justify-center items-center">
            <Segmented
              options={availableChartTypes.map(chartType => ({
                value: chartType,
                label: "",
                icon: ChartTypeIcons[chartType] ? (
                  ChartTypeIcons[chartType]
                ) : (
                  <i className="ri-question-line"></i>
                )
              }))}
              block
              onChange={e => setSelectedChartType(e.toString() as any)}
              value={selectedChartType}
            />
          </div>
        )}

        <Spin
          spinning={isLoading || !showChart}
          indicator={<LoadingIndicatorWithoutSpin />}
          wrapperClassName="flex-1 flex fill-spinner"
        >
          {showChart && (
            <div className="flex-1 ">
              {selectedChartType === "DIGIT" && (
                <div className="w-full h-full flex flex-col justify-center items-center">
                  <Dropdown
                    disabled={!isInspectAvailable}
                    overlay={
                      onInspectSegment ? (
                        <Menu>
                          <Menu.Item
                            icon={<i className="ri-eye-line pr-2"></i>}
                            onClick={() =>
                              onInspectSegment &&
                              onInspectSegment!({
                                action: "VIEW",
                                record: rawData,
                                originalFilterConfig: filterConfig,
                                reportConfig: reportConfig
                              })
                            }
                          >
                            View {reportNoun}
                          </Menu.Item>
                        </Menu>
                      ) : (
                        <></>
                      )
                    }
                    trigger={["click", "contextMenu"]}
                  >
                    <div
                      className={classnames("text-6xl", {
                        "cursor-pointer": onInspectSegment
                      })}
                    >
                      {rawData.count || 0}
                    </div>
                  </Dropdown>
                  <div className="text-gray-600">{reportNoun}</div>
                </div>
              )}

              {rawData !== "DIGIT" && (!rawData || rawData.length === 0) && (
                <div className="flex flex-col w-full h-full justify-center items-center">
                  <EmptyData
                    text="Not enough data to display report"
                    icon={
                      <div className="text-4xl">
                        {ChartTypeIcons[selectedChartType]}
                      </div>
                    }
                  />
                </div>
              )}

              {Array.isArray(rawData) && rawData.length > 0 && (
                <>
                  {selectedChartType === "HEAT_MAP" && (
                    <>
                      <Heatmap data={rawData} reportConfig={reportConfig} />
                    </>
                  )}

                  {(selectedChartType === "PIE" ||
                    selectedChartType === "2D_PIE_CHART") && (
                    <>
                      <ReportWidgetPieChart2D
                        data={rawData}
                        reportConfig={reportConfig}
                        onInspectSegment={packet =>
                          onInspectSegment &&
                          onInspectSegment!({
                            ...packet,
                            originalFilterConfig: filterConfig,
                            reportConfig: reportConfig
                          })
                        }
                        isInspectAvailable={isInspectAvailable}
                        reportNoun={reportNoun}
                      />
                    </>
                  )}

                  {(selectedChartType === "BAR_CHART" ||
                    selectedChartType === "2D_BAR_CHART") && (
                    <>
                      <ReportWidgetBarChart
                        data={rawData}
                        reportConfig={reportConfig}
                        onInspectSegment={packet =>
                          onInspectSegment &&
                          onInspectSegment!({
                            ...packet,
                            originalFilterConfig: filterConfig,
                            reportConfig: reportConfig
                          })
                        }
                        isInspectAvailable={isInspectAvailable}
                        reportNoun={reportNoun}
                      />
                    </>
                  )}

                  {(selectedChartType === "TABLE" ||
                    selectedChartType === "PIVOT_TABLE") && (
                    <>
                      <SimplePivotTable
                        data={rawData}
                        reportConfig={reportConfig}
                        onInspectSegment={packet =>
                          onInspectSegment &&
                          onInspectSegment!({
                            ...packet,
                            originalFilterConfig: filterConfig,
                            reportConfig: reportConfig
                          })
                        }
                        isInspectAvailable={isInspectAvailable}
                        reportNoun={reportNoun}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </Spin>
      </div>
    </div>
  );
};
