import { SDK } from "@sdk";
import { iArticle } from "@sdk/knowledge-base/knowledge-base.models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { articleDefaultQueryConfig } from "./article-default-query";
import { ArticlesSlice } from "./articles.slice";

export const loadAllArticles = (store: Store<iStore>, forceReload?: boolean) =>
  loadArticlesQuery(articleDefaultQueryConfig, "all")(store, forceReload);

export const loadArticlesOfCollection =
  (collectionId: string) => (store: Store<iStore>, forceReload?: boolean) =>
    loadArticlesQuery(
      {
        query: { collections: collectionId },
        options: articleDefaultQueryConfig.options,
      },
      JSON.stringify({
        query: { collections: collectionId },
        options: articleDefaultQueryConfig.options,
      }),
    )(store, forceReload);

export const {
  loadEntityQueries: loadArticlesQuery,
  loadEntityById: loadArticleById,
  loadEntitiesByIds: bulkLoadArticlesByIds,
} = CreateHelpersForEntity<iStore, iArticle>({
  sliceName: "articles",
  slice: ArticlesSlice,
  isPaginatedQueries: true,
  fetchPageSize: 500,
  loadEntityQueryProvider: (query) => SDK.articles.query(query),
  loadEntityByIdProvider: (entityId: string) => SDK.articles.getById(entityId),
  loadEntityByIdsProvider: (articleIds: string[]) =>
    (async () => {
      const articles = await Promise.all(
        articleIds.map((articleId) => SDK.articles.getById(articleId)),
      );
      return articles;
    })(),
});
