import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iWAPrivateConnectionData } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iOrganization } from "@sdk/user-management/user-management.models";
import { Button, Divider, Modal, Progress } from "antd";
import { useForm } from "antd/lib/form/Form";

import { SectionHeader } from "components/common/section-header";
import { ConfigureWhatsappPrivateConnection } from "components/modules/connections/channels/whatsapp-private/configure-whatsapp-private-connection/configure-whatsapp-private-connection";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useCalendlyEventListener } from "react-calendly";
import { useDispatch, useSelector } from "react-redux";
import { DeepPartial } from "redux";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { selectAllUsers } from "store/modules/users/users.selectors";
import { selectOrganizationOnboardingState } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { BulkInviteUsers } from "../../bulk-invite-users";
import { ExpressAccountFinalSetup } from "../common/onboarding-final-step-demo";
import "./whatsapp-onboarding-experience.scss";

const finalStepNumber = 3;
const stepPercentage = Math.floor(100 / finalStepNumber);

export const WhatsappCustomizeExperienceModal = ({
  visible,
  onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const [form] = useForm();
  const currentOnboardingState = useSelector(selectOrganizationOnboardingState);
  const allUsers = useSelector(selectAllUsers);

  const { state: connections } = useQueryWithStore(
    selectAllConnections,
    loadAllConnections
  );

  const whatsappConnection = useMemo(() => {
    return _.find(connections, { type: "WHATS_APP_PRIVATE" });
  }, [connections]);

  const lastCompletedStep = useMemo(() => {
    if (currentOnboardingState) {
      if (
        (whatsappConnection?.data as iWAPrivateConnectionData)?.waConnectionX
          ?.state === "CONNECTED"
      ) {
        return 2;
      }
      return 1;
    } else {
      return 1;
    }
  }, [currentOnboardingState, whatsappConnection?.data]);

  const [currentStep, setCurrentStep] = useState(lastCompletedStep);

  useEffect(() => {
    setCurrentStep(lastCompletedStep);
  }, [lastCompletedStep]);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: e => console.log(e.data.payload)
  });

  const { doAction: editCurrentOrganization } = useSDKActionWithDeps(
    () => ({
      action: SDK => edits => SDK.editCurrentOrganization(edits),
      // successMessage: "Done",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const onComplete = useCallback(() => {
    editCurrentOrganization({
      appData: {
        ONBOARDING: {
          preferenceCompleted: true
        }
      }
    } as DeepPartial<iOrganization>);
    setCurrentStep(3);
  }, [editCurrentOrganization]);

  const { changePanelState } = useModalPanels();

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentStep === 1) {
      const timer = setTimeout(() => {
        (window as any).ccWidget?.onWidgetActivated(() => {
          (window as any).ccWidget?.triggerCampaign?.({
            message: "If you need any help integrating Whatsapp, we are here!"
          });
        });
      }, 15000);
      return () => {
        clearTimeout(timer);
      };
    } else if (currentStep === 3) {
      // Todo
      (window as any).ccWidget?.dismissAllCampaigns?.();
    }
  }, [currentStep]);

  return (
    <Modal
      title={
        null
        // <ModalTitle
        //   title="Getting Started"
        //   icon={<i className="ri-star-smile-line"></i>}
        // />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      footer={null}
      className="max-w-screen-md w-full customize-experience-modal"
      data-click-context="Customize Experience Modal"
      closable={currentStep === finalStepNumber}
      maskClosable={currentStep === finalStepNumber}
    >
      <div className="flex flex-col max-w-screen-xl w-full whatsapp-onboarding-experience">
        {currentStep === 1 && (
          <>
            <SectionHeader
              title="Scan the QR Code Below to get Started!"
              description="Setting it up is simple as logging in to WhatsApp Web"
              icon={<span></span>}
            />

            <Progress percent={60} status="active" showInfo={false} />

            <Divider />

            <div className="description text-gray-600 dark:text-gray-400 mb-4 mode_transition text-center">
              <a
                href="https://faq.whatsapp.com/290759475293742/?cms_platform=android"
                target={"_blank"}
                rel="noreferrer"
              >
                Learn how to scan the QR code
              </a>
            </div>
            {whatsappConnection && (
              <>
                <ConfigureWhatsappPrivateConnection
                  connectionId={whatsappConnection?.id!}
                  hideHeader={true}
                  showOnlyQR={true}
                />
                <div className="flex flex-row justify-center items-center">
                  Authentication can take upto 20 seconds
                </div>
              </>
            )}
            {!whatsappConnection && (
              <div
                className="flex flex-row justify-center items-center"
                style={{
                  width: 300,
                  height: 340
                }}
              >
                <LoadingOutlined
                  style={{ fontSize: 24 }}
                  spin
                  className="mb-4"
                />
              </div>
            )}

            {/* Todo */}
          </>
        )}

        {currentStep === 2 && (
          <div className="h-full flex flex-col">
            <div className="flex flex-row bg-blue-100 dark:bg-black rounded-lg p-4 m-4">
              <div className="icon-container font-bold text-2xl mr-2">
                <i className="ri-team-line"></i>
              </div>
              <div className="flex-1">
                <div className="font-bold text-2xl">Invite your Team</div>
                <div className="text-gray-600">
                  Click Connector works better when you have your team in
                </div>
              </div>
            </div>
            <Progress
              percent={90}
              status="active"
              showInfo={false}
              className="opacity-25"
            />

            <div className="p-4">
              <BulkInviteUsers onComplete={onComplete} />
              <div className="flex flex-row justify-end items-center gap-2 mt-2">
                <Button
                  // className="font-bold"
                  type="text"
                  icon={<i className="ri-arrow-right-fill"></i>}
                  onClick={onComplete}
                >
                  Skip
                </Button>
                <Button
                  // className="font-bold"
                  type="text"
                  icon={<i className="ri-check-line"></i>}
                  onClick={onComplete}
                >
                  I have already invited
                </Button>
              </div>
            </div>
          </div>
        )}

        {currentStep === finalStepNumber && (
          <>
            <ExpressAccountFinalSetup />
          </>
        )}
      </div>
      <DarkModeBg />
    </Modal>
  );
};
