import { Avatar } from "components/common/avatar/avatar";
import { loadContactById } from "store/modules/contacts/contacts.helpers";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { GetContactIdentifier } from "../../helpers/get-contact-identifier";
import { getContactName } from "../../helpers/get-contact-name";

export const ContactSelectorPill = ({ contactId }: { contactId: string }) => {
  const { state: contact, retry: reload } = useQueryWithStore(
    selectContactById(contactId),
    loadContactById(contactId)(),
    [contactId],
    !contactId
  );

  return (
    <div className="user-pill leading-normal">
      <div className="flex-row flex justify-start items-center">
        <Avatar name={contact.data.firstName!} />
        <div className="flex-col pl-3">
          <div className="font-bold">{getContactName(contact)} </div>
          <div className="description">{GetContactIdentifier(contact)}</div>
        </div>
      </div>
    </div>
  );
};
