import { Modal } from "antd";
import { DarkModeBg } from "dark-mode-bg";
import { useMemo } from "react";
import { ChatList } from "../chat-list/chat-list";
import "./conversation-picker-modal.scss";

export const ConversationsPickerModal = ({
  visible,
  onChangeVisibility,
  onConversationSelected,
  title,
  query
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onConversationSelected: (
    conversationId: string,
    onChangeVisibility: any
  ) => any;
  title?: JSX.Element;
  query?: any;
}) => {
  const defaultQuery = useMemo(() => {
    return {
      query: query || {},
      options: {
        sortBy: ["-metaData.lastMessage.timestamp"]
      }
    };
  }, [query]);

  return (<>
    <Modal
      // title={
      //   title || (
      //     <ModalTitle
      //       title="Select a conversation"
      //       icon={<i className="ri-group-line"></i>}
      //     />
      //   )
      // }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save"
      data-click-context="User Picker Modal"
      className="conversation-picker"
      destroyOnClose={true}
    >
      <div className="flex flex-col w-full h-full -m-2">
        <ChatList
          title={"Conversations"}
          defaultQueryConfig={defaultQuery}
          defaultQueryAlias={JSON.stringify(defaultQuery)}
          selectedConversation={"xxx2"}
          onSelectConversation={e => {
            onConversationSelected(e, onChangeVisibility);
          }}
          breakByChatStatus={false}
        />
      </div>
      <DarkModeBg />
    </Modal>
  </>);
};
