import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Button, Result, Space } from "antd";
import React from "react";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { EmailSignatureEditorModal } from "./email-signature-editor-modal";
import { SignatureItemEditorView } from "./signatire-item";
import { iEmailSignatureManagerState } from "./signature-manager-state-manager";

type ModalMode = "ADD" | "EDIT";

export const EmailSignatureManager = ({
  state,
  dispatch
}: {
  dispatch: React.Dispatch<any>;
  state: iEmailSignatureManagerState;
}) => {
  const [signatureEditorState, setSignatureEditorState] = useSimpleState({
    visibility: false,
    mode: "ADD" as ModalMode,
    state: undefined as any
  });

  const [parent] = useAutoAnimate(/* optional config */);

  return (
    <div className="w-full">
      <div className="flex flex-row justify-end items-center my-8">
        <Space>
          <Button
            type="primary"
            icon={<i className="ri-file-add-line"></i>}
            className="font-bold"
            onClick={e => {
              e.stopPropagation();
              setSignatureEditorState({
                visibility: true,
                mode: "ADD",
                state: null
              });
            }}
          >
            Add Signature
          </Button>
        </Space>
      </div>

      <div ref={parent as any}>
        {state.signatures.map(signature => (
          <SignatureItemEditorView
            key={signature.id}
            preset={signature}
            onDelete={() => {
              dispatch({
                type: "REMOVE_SIGNATURE",
                payload: {
                  signature: signature
                }
              });
            }}
            onEdit={() => {
              setSignatureEditorState({
                visibility: true,
                mode: "EDIT",
                state: signature
              });
            }}
          />
        ))}
      </div>

      {state.signatures.length === 0 && (
        <div>
          <Result
            status="info"
            title="Go ahead and add your first email signature"
            icon={<i className="ri-chat-new-line text-6xl"></i>}
            subTitle="Email Signatures helps you store your email footers"
          />
        </div>
      )}

      <EmailSignatureEditorModal
        visible={signatureEditorState.visibility}
        mode={signatureEditorState.mode}
        initialValue={signatureEditorState.state}
        onChangeVisibility={visibility =>
          setSignatureEditorState({ visibility })
        }
        onSaved={signature => {
          console.log("preset", signature);
          dispatch({
            type:
              signatureEditorState.mode === "EDIT"
                ? "EDIT_SIGNATURE"
                : "ADD_SIGNATURE",
            payload: {
              signature: signature
            }
          });
          setSignatureEditorState({ visibility: false });
        }}
      />
    </div>
  );
};
