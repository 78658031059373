import {
  AddActivity,
  AddCompany,
  AddContact,
  AddEngagementModal,
  AddProduct,
  CreateOpportunityModal,
  NewMessageCreator
} from "modal-registry";

export const QuickActions = [
  {
    label: "Add Contact",
    keywords: "Add Customer",
    icon: <i className="ri-add-line"></i>,
    modal: AddContact,
    additionalInput: {}
  },
  {
    label: "Add Activity",
    keywords: "Record Activity",
    icon: <i className="ri-add-line"></i>,
    modal: AddActivity,
    additionalInput: {}
  },
  {
    label: "Add Opportunity",
    keywords: "Create Opportunity",
    icon: <i className="ri-add-line"></i>,
    modal: CreateOpportunityModal,
    additionalInput: {}
  },
  {
    label: "Add Engagement Modal",
    keywords: "Log Engagement",
    icon: <i className="ri-add-line"></i>,
    modal: AddEngagementModal,
    additionalInput: {}
  },
  {
    label: "Add Company",
    keywords: "Log Account",
    icon: <i className="ri-add-line"></i>,
    modal: AddCompany,
    additionalInput: {}
  },
  {
    label: "Add Product",
    keywords: "",
    icon: <i className="ri-add-line"></i>,
    modal: AddProduct,
    additionalInput: {}
  },
  {
    label: "New Conversation",
    keywords: "Start New Chat, Create New Chat, New Chat",
    icon: <i className="ri-add-line"></i>,
    modal: NewMessageCreator,
    additionalInput: {}
  }
];
