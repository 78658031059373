import { LexicalEditorApp } from "@libs/@lexical-playground/Async-App";
import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Form, Input, message, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { OpenAiAssistant } from "modal-registry";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { loadKnowledgeDocumentById } from "store/modules/knowledge-documents/knowledge-documents.helpers";
import { selectKnowledgeDocumentById } from "store/modules/knowledge-documents/knowledge-documents.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { uuidv4 } from "utils/generate-uuid";
import { justWait } from "utils/just-wait";
import "./add-edit-document-modal.scss";

export const AddEditKnowledgeDocumentModal = ({
  visible,
  onChangeVisibility,
  documentId,
  initialValue,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  documentId?: string;
  initialValue?: string;
}) => {
  const [form] = useForm();

  const mode = documentId ? "EDIT" : "ADD";

  const { state: document, retry: reloadDocument } = useQueryWithStore(
    selectKnowledgeDocumentById(documentId!),
    loadKnowledgeDocumentById(documentId!)(),
    [documentId],
    !documentId,
  );

  const { doAction: onAddDocument, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.knowledgeDocuments
          .create({
            ...values,
          })
          .then((res) => {
            onChangeVisibility(false);
            form.resetFields();
          }),
      successMessage: "Document has been created",
      failureMessage: "Something went wrong",
    }),
    [form, onChangeVisibility],
  );

  const { doAction: onEditDocument, isProcessing: isEditProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (edits) =>
          SDK.knowledgeDocuments.editById(documentId!, edits).then((res) => {
            onChangeVisibility(false);
            form.resetFields();
          }),
        successMessage: "Document has been edited",
        failureMessage: "Something went wrong",
      }),
      [documentId, form, onChangeVisibility],
    );

  const onFinish = useCallback(async () => {
    try {
      await form.validateFields();
      const formValue = form.getFieldsValue();
      const html = await editorRef.current.getEditorStateHtml();
      formValue.content = html;

      // Todo: Could Upload Inline Images if Required.

      if (mode === "ADD") {
        onAddDocument({
          ...formValue,
        });
      } else {
        onEditDocument({
          ...formValue,
        });
      }
    } catch (e) {
      message.error("Please check your input");
    }
  }, [form, mode, onAddDocument, onEditDocument]);

  const draftJsRef = useRef<any>();
  const editorRef = useRef<any>(null);

  const initialValues = useMemo(
    () =>
      documentId
        ? { ...(document || {}) }
        : ({
            title: "",
          } as any),
    [document, documentId],
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [documentId, form, initialValues]);

  const setEditorStateHtml = useCallback(
    (html: string, retries: number = 0) => {
      if (editorRef.current) {
        editorRef.current.setEditorStateHtml(html || "");
        // 2 Seconds
      } else if (retries < 10) {
        // Retry
        setTimeout(() => {
          setEditorStateHtml(html, retries + 1);
        }, 200);
      }
    },
    [],
  );

  useEffect(() => {
    // draftJsRef.current.setHtmlContent(initialValues.content || "");
    setEditorStateHtml(initialValues.content || initialValue || "");
  }, [initialValue, initialValues.content, setEditorStateHtml]);

  const { triggerTempModal } = useModalPanels();

  const { doAction: uploadImage, isProcessing: isUploading } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (file) =>
          SDK.uploadFile(file, {
            type: "KNOWLEDGE_DOCUMENT",
            entityId: documentId || `NEW_${uuidv4()}`,
          }),
        throwError: true,
      }),
      [documentId],
    );

  const imageUploader = useCallback(
    async (file: File) => {
      await justWait(2000);
      const fileDoc = await uploadImage(file);
      return fileDoc?.url!;
    },
    [uploadImage],
  );

  return (
    <Modal
      title={
        mode === "ADD" ? (
          <ModalTitle
            icon={<i className="ri-folder-5-line"></i>}
            title="Add New Document"
          />
        ) : (
          <ModalTitle
            icon={<i className="ri-folder-5-line"></i>}
            title="Edit Document"
          />
        )
      }
      open={visible}
      onOk={onFinish}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okButtonProps={{
        icon: <i className="ri-check-line"></i>,
        loading: isProcessing || isEditProcessing,
      }}
      okText="Save"
      data-click-context="Add New Knowledge Document"
      width={960}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Form.Item
          name="title"
          label="Document Title"
          rules={[
            {
              required: true,
              message: "Please enter a title",
            },
          ]}
        >
          <Input placeholder="Document Title" />
        </Form.Item>

        <div className="knowledge-document-draft-js-container border border-gray-200 dark:border-gray-700 rounded-lg">
          {/* <Form.Item
            name="content"
            label="Document Content"
            rules={[
              {
                required: true,
                message: "Please enter your document content",
              },
            ]}
            noStyle
            valuePropName="initialValue"
          >
            <BraftJSInput ref={draftJsRef} />
          </Form.Item> */}
          <LexicalEditorApp
            editorId={"editor 1"}
            editorRef={editorRef}
            onChange={(e) => {
              // console.log("Change", e);
            }}
            imageUploader={imageUploader}
            toolBarExtra={
              <Button
                type="text"
                icon={
                  <img
                    src="/assets/integrations/openai.png"
                    alt="Chat GPT"
                    style={{ width: "1rem" }}
                    className="dark:invert"
                  />
                }
                onClick={() => triggerTempModal(OpenAiAssistant, {})}
              />
            }
          />
        </div>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
