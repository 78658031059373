import { CountryCodes } from "../../../../../utils/country-codes";

export const generateCountryKeyFromCountryCode = (countryCode: string) => {
  const record = CountryCodes.find(obj => obj.code === countryCode);
  if (record) {
    return `${record?.code}|${record?.name}`;
  }
  return null;
};

export const generateCountryCodeFromCountryKey = (countryKey: string) => {
  return countryKey.split("|")[0];
};
