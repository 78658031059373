import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { ChatView } from "components/pages/conversations/chat-view";
import "components/pages/conversations/conversations.page.style.scss";
import { DarkModeBg } from "dark-mode-bg";
import { memo } from "react";

export const ConversationViewerModal = memo(
  ({
    visible,
    onChangeVisibility,
    conversationId
  }: {
    visible: boolean;
    onChangeVisibility: (state: boolean) => any;
    conversationId: string;
  }) => {
    return (<>
      <Modal
        title={
          <ModalTitle
            title={<>Conversation Viewer</>}
            icon={<i className="ri-chat-4-line"></i>}
          />
        }
        open={visible}
        footer={null}
        onCancel={() => {
          onChangeVisibility(false);
        }}
        className="responsive-modal very-big-modal max-w-4xl"
        bodyStyle={{ padding: 0 }}
        data-click-context="Conversation Viewer Modal"
      >
        {conversationId && <ChatView conversationId={conversationId} />}
        <DarkModeBg />
      </Modal>
    </>);
  }
);
