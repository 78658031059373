import { Button, Drawer } from "antd";
import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React, { Suspense, useState } from "react";
// import { PDFViewer } from "react-view-pdf";
const PdfViewer = React.lazy(() => import("./pdf-viewer-lazy"));

export const PDFDrawer = ({
  PDFDrawerVisible,
  onPDFDrawerClose,
  PDFFileUrl,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Drawer
      title="PDF Attachment"
      placement="right"
      width="1200"
      closable={false}
      onClose={onPDFDrawerClose}
      open={PDFDrawerVisible}
    >
      <Suspense fallback={<SuspenseLoadingIndicator />}>
        <PdfViewer
          url={PDFFileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          pageNumber={pageNumber}
        />
      </Suspense>
      <div>
        <p>
          Page {pageNumber || (Number(numPages) ? 1 : "--")} of{" "}
          {Number(numPages) || "--"}
        </p>
        <div className="flex flex-row">
          <Button disabled={pageNumber <= 1} onClick={previousPage}>
            Previous
          </Button>
          <Button disabled={pageNumber >= Number(numPages)} onClick={nextPage}>
            Next
          </Button>
        </div>
      </div>
      <div>
        <Button target="_blank" href={PDFFileUrl} type="primary">
          Download
        </Button>
      </div>
    </Drawer>
  );
};
