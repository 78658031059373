import { iFileRecord } from "@sdk/file-management/file-management.models";

export const DummyAvatars = (() => {
  const domain = "https://dashboard.clickconnector.com";

  const avatarList: iFileRecord[] = [];
  for (let i = 1; i < 67; i++) {
    const fileName = i.toString().padStart(2, "0");
    avatarList.push({
      id: fileName,
      organizationId: "x",
      url: `${domain}/assets/img/avatars/${fileName}.jpg`,
      tags: [],
      reference: {
        type: "USER",
        entityId: "AVATARS"
      },
      uploadedBy: "xxx",
      metaData: {
        fileName,
        mimetype: "image/JPEG",
        uploadedTime: new Date().getTime(),
        referenceCount: 0
      }
    });
  }

  avatarList.push({
    id: `Missing - Avatar`,
    organizationId: "x",
    url: `${domain}/assets/img/avatars/missing-profile-picture.jpg`,
    tags: [],
    reference: {
      type: "USER",
      entityId: "AVATARS"
    },
    uploadedBy: "xxx",
    metaData: {
      fileName: `Missing - Avatar`,
      mimetype: "image/JPEG",
      uploadedTime: new Date().getTime(),
      referenceCount: 0
    }
  });
  return avatarList;
})();
