export const NameTitleOptions = [
  {
    label: "Mr.",
    value: "Mr.",
    gender: "male"
  },
  {
    label: "Mrs.",
    value: "Mrs.",
    gender: "female"
  },
  {
    label: "Ms.",
    value: "Ms.",
    gender: "female"
  },
  {
    label: "Miss",
    value: "Miss",
    gender: "female"
  },
  {
    label: "Dr.",
    value: "Dr.",
    gender: "neutral"
  },
  {
    label: "Prof.",
    value: "Prof.",
    gender: "neutral"
  },
  {
    label: "Rev.",
    value: "Rev.",
    gender: "neutral"
  }
];
