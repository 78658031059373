import { useResponsive } from "@libs/@react-hooks-responsive";
import { Divider, Layout } from "antd";

import { useBetaFeatures } from "components/pages/conversations/use-beta-features";
import { MainNavigationBar } from "../main-navigation-bar/main-navigation-bar";
import { MainNavigationBar2 } from "../main-navigation-bar/main-navigation-bar-2";
import "./main-layout.style.scss";

const breakpoints = { xs: 0, sm: 480, md: 1024 };

const { Content, Footer } = Layout;

export const MainLayout = (props: {
  children: JSX.Element;
  banner?: JSX.Element;
}) => {
  const { size, orientation, screenIsAtLeast, screenIsAtMost } = useResponsive(
    breakpoints
  );

  const betaFeatures = useBetaFeatures();

  return (
    <>
      <Layout className="main-layout-outer-container">
        {props.banner}
        {!screenIsAtLeast("md") && (
          <>
            <MainNavigationBar mobileMode={true} />
            <Divider type="horizontal" className="my-0" />
          </>
        )}

        <Layout className="bg-white dark:bg-gray-900 mode_transition mode_transition flex flex-row">
          {screenIsAtLeast("md") && (
            <>
              {betaFeatures?.SEQUENCES ? (
                <MainNavigationBar2 />
              ) : (
                <MainNavigationBar />
              )}

              <Divider type="vertical" className="ml-0 mr-0 h-full" />
            </>
          )}

          <Content
            style={{}}
            className="bg-white dark:bg-gray-900 main-layout-container mode_transition"
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
