import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { ViewCampaignReportLogs } from "modal-registry";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCampaignById } from "store/modules/campaigns/campaigns.selectors";
import { EmailCampaignStatCard } from "../campaign-stat-card/email-campaign-stat-card";

export const ViewCampaignReportModal = ({
  visible,
  onChangeVisibility,
  campaignId
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  campaignId: string;
}) => {
  const campaign = useSelector(selectCampaignById(campaignId));

  const query = useMemo(() => {
    return {
      delivered: {
        "event.event": "delivered"
      },
      totalOpens: {
        "event.event": "opened"
      },
      totalClicks: {
        "event.event": "clicked"
      },
      totalComplained: {
        "event.event": "complained"
      },
      totalPermanentFail: {
        "event.event": "permanent_fail"
      },
      totalTemporaryFail: {
        "event.event": "temporary_fail"
      },
      totalUnsubscribes: {
        "event.event": "unsubscribed"
      }
    };
  }, []);

  const { triggerTempModal } = useModalPanels();

  const viewCampaignLogs = useCallback(
    (logType: string) => {
      triggerTempModal(ViewCampaignReportLogs, {
        campaignId,
        logType
      });
    },
    [campaignId, triggerTempModal]
  );

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-folder-chart-line"></i>}
          title="Campaign Report"
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Campaign Report Modal"
      width={870}
    >
      {campaignId && (
        <div className="flex flex-row justify-between items-center">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <EmailCampaignStatCard
              title="Total Delivered"
              query={query.delivered}
              campaignId={campaignId}
              onClick={() => {
                viewCampaignLogs("delivered");
              }}
            />

            <EmailCampaignStatCard
              title="Total Opens"
              query={query.totalOpens}
              campaignId={campaignId}
              onClick={() => {
                viewCampaignLogs("opened");
              }}
            />

            <EmailCampaignStatCard
              title="Total Clicks"
              query={query.totalClicks}
              campaignId={campaignId}
              onClick={() => {
                viewCampaignLogs("clicked");
              }}
            />
            <EmailCampaignStatCard
              title="Complained"
              query={query.totalComplained}
              campaignId={campaignId}
              onClick={() => {
                viewCampaignLogs("complained");
              }}
            />

            <EmailCampaignStatCard
              title="Temporary Fails"
              query={query.totalTemporaryFail}
              campaignId={campaignId}
              onClick={() => {
                viewCampaignLogs("temporary_fail");
              }}
            />

            <EmailCampaignStatCard
              title="Permanent Fails"
              query={query.totalPermanentFail}
              campaignId={campaignId}
              onClick={() => {
                viewCampaignLogs("permanent_fail");
              }}
            />
            <EmailCampaignStatCard
              title="Unsubscribes"
              query={query.totalUnsubscribes}
              campaignId={campaignId}
              onClick={() => {
                viewCampaignLogs("unsubscribed");
              }}
            />
          </div>
        </div>
      )}
      <DarkModeBg />
    </Modal>
  );
};
