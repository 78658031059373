import _ from "lodash";

export interface iReportGroupByIntervalConfig {
  intervals: number[];
  labels: string[];
}

export const generateIntervalOptions = (
  config: iReportGroupByIntervalConfig
) => {
  const firstOption = _.first(config.intervals);
  const lastOption = _.last(config.intervals);

  const options: { value: string; title: string }[] = [
    {
      value: JSON.stringify({ $lt: firstOption }),
      title: config.labels[0]
    },
    ...config.intervals
      .slice(0, config.intervals.length - 1)
      .map((intervalPoint, index) => ({
        value: JSON.stringify({
          $gte: intervalPoint,
          $lt: config.intervals[index + 1]
        }),
        title: config.labels[index + 1]
      })),
    {
      value: JSON.stringify({ $gte: lastOption }),
      title: _.last(config.labels)!
    }
  ];

  return options;
};
