import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  Reducer
} from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { useDispatch } from "react-redux";
import { createEpicMiddleware } from "redux-observable";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import { BehaviorSubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { UserTracker } from "user-tracker";
import { SetLocalStorageItem } from "utils/hooks/use-local-storage-store";
import { BrowserHistory } from "./browser-history";
import { ActivitiesSliceReducer } from "./modules/activities/activities.slice";
import { AppStateSliceReducers } from "./modules/app-state/app-state.slice";
import { ArticleCollectionsSliceReducer } from "./modules/article-collection/article-collection.slice";
import { ArticlesSliceReducer } from "./modules/articles/articles.slice";
import { AutomationWorkflowsSliceReducer } from "./modules/automation-workflows/automation-workflows.slice";
import { BadgesSliceReducer } from "./modules/badges/badges.slice";
import { CallBackRequestsSliceReducer } from "./modules/call-back-requests/call-back-requests.slice";
import { CallLogsSliceReducer } from "./modules/call-logs/call-logs.slice";
import { CampaignsSliceReducer } from "./modules/campaigns/campaigns.slice";
import { CCArticleCollectionsSliceReducer } from "./modules/cc-article-collection/cc-article-collection.slice";
import { CCArticlesSliceReducer } from "./modules/cc-articles/cc-articles.slice";
import { ChatBotInstancesSliceReducer } from "./modules/chat-bot-instance/chat-bot-instance.slice";
import { ChatBotsSliceReducer } from "./modules/chat-bots/chat-bots.slice";
import { ChatConnectorsSliceReducer } from "./modules/chat-connectors/chat-connectors.slice";
import { ChatWidgetsSliceReducer } from "./modules/chat-widgets/chat-widgets.slice";
import { CompaniesSliceReducer } from "./modules/companies/companies.slice";
import { ConnectionsSliceReducer } from "./modules/connections/connections.slice";
import { ContactListsSliceReducer } from "./modules/contact-lists/contact-lists.slice";
import { ContactsSliceReducer } from "./modules/contacts/contacts.slice";
import { ConversationsSliceReducer } from "./modules/conversations/conversations.slice";
import { ConversionWidgetsSliceReducer } from "./modules/conversion-widgets/conversion-widgets.slice";
import { DynamicFormsSliceReducer } from "./modules/dynamic-forms/dynamic-forms.slice";
import { EngagementsSliceReducer } from "./modules/engagements/engagements.slice";
import { FbPostsSliceReducer } from "./modules/fb-posts/fb-posts.slice";
import { GroupsSliceReducer } from "./modules/groups/groups.slice";
import { HolidayQuotesSliceReducer } from "./modules/holiday-quotes/holiday-quotes.slice";
import { HQSliceReducers } from "./modules/hq/hq.store";
import { IgMediasSliceReducer } from "./modules/ig-medias/ig-medias.slice";
import { IndexedWebPagesSliceReducer } from "./modules/indexed-web-pages/indexed-web-pages.slice";
import { KnowledgeDocumentsSliceReducer } from "./modules/knowledge-documents/knowledge-documents.slice";
import { MarketingReferencesSliceReducer } from "./modules/marketing-references/marketing-references.slice";
import { MessagesSliceReducers } from "./modules/messages/messages.store";
import { NotificationsSliceReducer } from "./modules/notifications/notifications.slice";
import { OpportunitiesSliceReducer } from "./modules/opportunities/opportunities.slice";
import { PipelineCardsSliceReducer } from "./modules/pipeline-cards/pipeline-cards.slice";
import { PreferenceSliceReducers } from "./modules/preferences/preferences.store";
import { ProductsSliceReducer } from "./modules/products/products.slice";
import { RemindersSliceReducer } from "./modules/reminders/reminders.slice";
import { ScheduledMessagesSliceReducer } from "./modules/scheduled-messages/scheduled-messages.slice";
import { ScheduledReportsSliceReducer } from "./modules/scheduled-reports/scheduled-reports.slice";
import { SelfServicePortalsSliceReducer } from "./modules/self-service-portals/self-service-portals.slice";
import { SessionsSliceReducer } from "./modules/sessions/sessions.slice";
import { SpamEmailsSliceReducer } from "./modules/spam-emails/spam-emails.slice";
import { TasksSliceReducer } from "./modules/tasks/tasks.slice";
import {
  UIStateSliceReducers,
  uiStore_initialState
} from "./modules/ui-state/ui-state.slice";
import { UserPresenceSliceReducers } from "./modules/user-presence/user-presence.slice";
import { UsersSliceReducers } from "./modules/users/users.store";
import { OrganizationsSliceReducers } from "./modules/workspace/workspace.store";
import { storage } from "./storage.browser";
import conversationListStateMaintainerEpic from "./store.epics";
import { storeMigrations } from "./store.migrations";
import { iStore } from "./store.model";
import { RegisterWatchers } from "./store.watchers";

// Epics
export const epicMiddleware = createEpicMiddleware();

// If Local Storage is null, fill it with empty object
// Fix for (autoRehydrate.js:54)
const persistKey = "root";
(() => {
  if (!localStorage.getItem(`persist:${persistKey}`)) {
    SetLocalStorageItem(`persist:${persistKey}`, "{}");
  }
})();

const rootPersistConfig = {
  key: persistKey,
  storage,
  // Note: Does not accept dot notation
  // blacklist: [
  //   "messages",
  //   "conversations",
  //   "contacts",
  //   "connections",
  //   "contactLists",
  //   "fbPosts",
  // ],
  version: 4,
  whitelist: ["users", "uiState", "preferences", "appState", "hq"],
  // stateReconciler: autoMergeLevel2 as any,
  migrate: createMigrate(storeMigrations as any, { debug: false }),
  transforms: []
};

const rootReducer: Reducer<iStore> = combineReducers({
  appState: AppStateSliceReducers,
  uiState: UIStateSliceReducers,
  preferences: PreferenceSliceReducers,
  notifications: NotificationsSliceReducer,
  organizations: OrganizationsSliceReducers,
  users: UsersSliceReducers,
  userPresence: UserPresenceSliceReducers,
  userGroups: GroupsSliceReducer,
  connections: ConnectionsSliceReducer,
  conversations: ConversationsSliceReducer,
  messages: MessagesSliceReducers,
  contacts: ContactsSliceReducer,
  contactLists: ContactListsSliceReducer,
  companies: CompaniesSliceReducer,
  engagements: EngagementsSliceReducer,
  activities: ActivitiesSliceReducer,
  opportunities: OpportunitiesSliceReducer,
  fbPosts: FbPostsSliceReducer,
  chatWidgets: ChatWidgetsSliceReducer,
  sessions: SessionsSliceReducer,
  articles: ArticlesSliceReducer,
  articleCollections: ArticleCollectionsSliceReducer,
  selfServicePortals: SelfServicePortalsSliceReducer,
  marketingReferences: MarketingReferencesSliceReducer,
  igMedias: IgMediasSliceReducer,
  scheduledMessages: ScheduledMessagesSliceReducer,
  scheduledReports: ScheduledReportsSliceReducer,
  hq: HQSliceReducers,
  router: connectRouter(BrowserHistory),
  chatBots: ChatBotsSliceReducer,
  chatBotInstances: ChatBotInstancesSliceReducer,
  chatConnectors: ChatConnectorsSliceReducer,
  tasks: TasksSliceReducer,
  // Local Knowledge Base
  ccArticles: CCArticlesSliceReducer,
  ccArticleCollections: CCArticleCollectionsSliceReducer,
  spamEmails: SpamEmailsSliceReducer,
  products: ProductsSliceReducer,
  campaigns: CampaignsSliceReducer,
  dynamicForms: DynamicFormsSliceReducer,
  conversionWidgets: ConversionWidgetsSliceReducer,
  callLogs: CallLogsSliceReducer,
  callBackRequests: CallBackRequestsSliceReducer,
  automationWorkflows: AutomationWorkflowsSliceReducer,
  pipelineCards: PipelineCardsSliceReducer,
  reminders: RemindersSliceReducer,
  badges: BadgesSliceReducer,
  holidayQuotes: HolidayQuotesSliceReducer,
  knowledgeDocuments: KnowledgeDocumentsSliceReducer,
  indexedWebPages: IndexedWebPagesSliceReducer
});

const rootPersistedReducer = persistReducer(rootPersistConfig, rootReducer);

const rootPersistedReducerWithReset = (state, action) => {
  if (action.type === "users/logout") {
    const { router, _persist, preferences, appState, uiState, hq } = state;
    state = {
      router,
      _persist,
      preferences,
      appState,
      hq,
      uiState: {
        ...uiStore_initialState,
        theme: { isDarkMode: uiState?.theme?.isDarkMode }
      }
    };
    UserTracker.reset();
  }
  return rootPersistedReducer(state, action);
};

export const store = configureStore({
  reducer: rootPersistedReducerWithReset,
  middleware: getDefaultMiddleware({
    serializableCheck: false
  }).concat(routerMiddleware(BrowserHistory), epicMiddleware),
  devTools: true,
  enhancers: []
});

(window as any).reduxStore = store;

const epic$ = new BehaviorSubject(conversationListStateMaintainerEpic);
// Every time a new epic is given to epic$ it
// will unsubscribe from the previous one then
// call and subscribe to the new one because of
// how switchMap works
const hotReloadingEpic = (...args) =>
  epic$.pipe(switchMap(epic => (epic as any)(...args)));

epicMiddleware.run(hotReloadingEpic as any);

if ((module as any).hot) {
  (module as any).hot.accept("./store.epics", () => {
    const nextRootEpic = require("./store.epics").default;
    epic$.next(nextRootEpic);
  });
}

export const persistor = persistStore(store);

// (async () => {
//   await persistor.purge();
// })();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

RegisterWatchers(store);
