import { iEmailConnectionData } from "@sdk/conversations/conversations.models";
import { Button, Form, Input, Select, Tag } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSelector, useStore } from "react-redux";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import {
  selectMessageByConversationId,
  selectMessageListByConversationId,
} from "store/modules/messages/messages.selectors";
import { parseEmailHeader } from "utils/parse-email-header";

// https://ant.design/components/select/

const tokenSeparators = [",", ";"];

export const emailValidatorRuleForSelect = ({ getFieldValue }) => ({
  validator(_, emails: string[]) {
    if (emails) {
      // eslint-disable-next-line no-useless-escape
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const invalidInputs = emails.filter((value) => !value.match(emailRegex));
      if (invalidInputs.length === 0) {
        return Promise.resolve();
      } else if (invalidInputs.length === 1) {
        return Promise.reject(
          new Error(invalidInputs.join("") + " is not a valid email")
        );
      } else {
        return Promise.reject(
          new Error(
            invalidInputs.slice(0, -1).join(", ") +
              " and " +
              invalidInputs.slice(-1) +
              " are not valid emails"
          )
        );
      }
    }
    return Promise.resolve();
  },
});

export const EmailModifier = forwardRef(
  ({ conversationId }: { conversationId: string }, ref) => {
    const [form] = useForm();
    const [isCollapsed, setCollapsed] = useState(true);

    const conversation = useSelector(selectConversationById(conversationId));
    const contact = useSelector(selectContactById(conversation?.contactId));
    const messageList = useSelector(
      selectMessageListByConversationId(conversationId)
    );
    const store = useStore();

    useEffect(() => {
      if (conversation?.subject) {
        const ccMembers = (() => {
          const connection = selectConnectionById(conversation.connectionId)(
            store.getState()
          );
          const messages = selectMessageByConversationId(conversation?.id!)(
            store.getState()
          );
          const lastMessage = _.last(
            messages?.data.filter((e) => e.from.senderType !== "SYSTEM") || []
          );
          if (!lastMessage || !connection) {
            return [];
          }
          const body = lastMessage?.data || {};

          const FromMembers = body.From ? parseEmailHeader(body.From) : [];
          const ToMembers =
            body.to || (body.To ? parseEmailHeader(body.To) : []);
          const CcMembers =
            body.cc || (body.Cc ? parseEmailHeader(body.Cc) : []);

          const myEmails = (() => {
            const { customSmtp, customDomain, senderName, email } =
              (connection?.data as iEmailConnectionData) || {};

            return [
              customSmtp
                ? customSmtp.data.email || customSmtp.data.username
                : undefined,
              ...(customDomain
                ? [
                    `${conversationId}@${customDomain.domain}`,
                    ` ${customDomain?.email}`,
                  ]
                : []),
              `${conversationId}@helpdesk.clickconnector.com`,
              customDomain?.email,
              email,
            ].filter((e) => e);
          })();
          const toEmail = conversation?.data?.email;

          const ToCCMembers = [
            ...[...FromMembers, ...ToMembers, ...CcMembers].map(
              (item) => item.email
            ),
          ].filter((email) => !myEmails.includes(email) && email !== toEmail);

          return ToCCMembers;
        })();

        form.setFieldsValue({
          subject: conversation?.subject,
          cc: ccMembers,
          to: conversation.data?.email
            ? [conversation.data?.email]
            : contact?.data?.primaryEmail
            ? [contact?.data?.primaryEmail]
            : [],
        });
      }
    }, [
      conversation.connectionId,
      conversation.data?.email,
      conversation?.id,
      conversation.subject,
      conversationId,
      form,
      store,
      messageList,
      contact?.data?.primaryEmail,
    ]);

    const getMessageData = useCallback(() => {
      const formValue = form.getFieldsValue();
      return formValue;
    }, [form]);

    const resetFields = useCallback(() => {
      form.setFieldsValue({
        cc: [],
        to: [],
        bcc: [],
      });
    }, [form]);

    const validateForm = useCallback(() => {
      return form.validateFields();
    }, [form]);

    useImperativeHandle(
      ref,
      () => ({
        getMessageData: getMessageData,
        resetFields: resetFields,
        validateForm: validateForm,
      }),
      [getMessageData, resetFields, validateForm]
    );

    const ccCount = (form.getFieldValue("cc") || []).length;

    return (
      <div className="chat-input-email-data-editor">
        <div className="flex flex-row justify-center items-center">
          <Button type="link" onClick={() => setCollapsed(!isCollapsed)}>
            {isCollapsed ? (
              <div className="flex flex-row justify-center items-center">
                <i className="ri-arrow-drop-up-line text-3xl"></i>
                {Boolean(ccCount) && <Tag>CC: ({ccCount} Emails)</Tag>}
              </div>
            ) : (
              <i className="ri-arrow-drop-down-line text-3xl"></i>
            )}
          </Button>
        </div>
        {!isCollapsed && (
          <Form layout="horizontal" form={form} initialValues={{}}>
            <div className="w-full p-4">
              <Form.Item
                label={
                  <div className="font-bold" style={{ width: 100 }}>
                    Subject Line
                  </div>
                }
                name={["subject"]}
                hasFeedback={false}
                style={{ marginBottom: 0, marginLeft: 5 }}
              >
                <Input
                  placeholder="Enter a subject line"
                  className="border-0"
                />
              </Form.Item>
              <Form.Item
                label={
                  <div className="font-bold" style={{ width: 100 }}>
                    To
                  </div>
                }
                name={["to"]}
                hasFeedback={false}
                style={{ marginBottom: 0, marginLeft: 5 }}
                rules={[
                  emailValidatorRuleForSelect,
                  {
                    validator: async (rule, value) => {
                      if (!value?.[0]) {
                        throw "At least one email is required";
                      }
                    },
                  },
                ]}
              >
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Add Emails"
                  dropdownStyle={{ display: "none" }}
                  allowClear
                  tokenSeparators={tokenSeparators}
                  // onChange={handleChange}
                ></Select>
              </Form.Item>
              <Form.Item
                label={
                  <div className="font-bold" style={{ width: 100 }}>
                    CC
                  </div>
                }
                name={["cc"]}
                hasFeedback={false}
                style={{ marginBottom: 0, marginLeft: 5 }}
                rules={[emailValidatorRuleForSelect]}
              >
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Add Emails"
                  dropdownStyle={{ display: "none" }}
                  allowClear
                  tokenSeparators={tokenSeparators}
                  // onChange={handleChange}
                ></Select>
              </Form.Item>
              <Form.Item
                label={
                  <div className="font-bold" style={{ width: 100 }}>
                    BCC
                  </div>
                }
                name={["bcc"]}
                hasFeedback={false}
                style={{ marginBottom: 0, marginLeft: 5 }}
                rules={[emailValidatorRuleForSelect]}
              >
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Add Emails"
                  dropdownStyle={{ display: "none" }}
                  allowClear
                  tokenSeparators={tokenSeparators}
                  // onChange={handleChange}
                ></Select>
              </Form.Item>
            </div>
          </Form>
        )}
      </div>
    );
  }
);
