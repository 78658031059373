import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iCCMacro } from "@sdk/user-management/user-management.models";

import { mdiCogPlay } from "@mdi/js";
import { Form, Input, message, Modal, Tag, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ExtendControlType } from "braft-editor";
import { BraftMessageInput } from "components/common/draft-js-advanced/draft-js-advanced-playground-2";
import { MDIIcon } from "components/common/mdi-icon";
import { ModalTitle } from "components/common/modal-title";
import { useSortableTable } from "components/common/sortable-table/use-sortable-table";
import { WithAttachments } from "components/pages/bot-builder/node-types/message-node/with-attachments";
import { AvailableShopifyTokenList } from "components/pages/conversations/shopify/shopify-token-resolver";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import {
  selectMacroById,
  selectOrganizationId,
} from "store/modules/workspace/workspace.selectors";
import { ActionList } from "../action-editor/action-list";
import { iAction } from "../action-editor/models";
import { AvailableTokenTable } from "../chat-input/available-message-tokens";
import "./add-edit-macro.scss";

export const AddEditMacro = ({
  visible,
  onChangeVisibility,
  setSelectedMacro,
  onCreated,
  macroId,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  setSelectedMacro: (string) => any;
  onCreated: () => any;
  macroId: string;
}) => {
  const [form] = useForm();

  const macroData = useSelector(selectMacroById(macroId));

  const {
    state: actions,
    setState: setStages,
    onSortEnd,
  } = useSortableTable([] as iAction[]);
  useEffect(() => {
    setStages(macroData?.actions || []);
    form.setFieldsValue({
      ...macroData,
    });
  }, [form, macroData, setStages]);

  const { doAction: createMacro, isProcessing: isAddingMacro } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (data: iCCMacro) =>
          SDK.macros.addMacro(data).then((res) => {
            onCreated();
            setSelectedMacro("");
            onChangeVisibility(false);
          }),
        successMessage: "Macro has been created",
        failureMessage: "Something went wrong",
      }),
      [onChangeVisibility, onCreated, setSelectedMacro],
    );

  const { doAction: editMacro, isProcessing: isEditingMacro } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (id: string, data: iCCMacro) =>
          SDK.macros.editMacro(id, data).then((res) => {
            onCreated();
            setSelectedMacro("");
            onChangeVisibility(false);
          }),
        successMessage: "Macro has been edited",
        failureMessage: "Something went wrong",
      }),
      [onChangeVisibility, onCreated, setSelectedMacro],
    );

  const onSubmit = useCallback(async () => {
    const formValues = form.getFieldsValue();
    const html = replyInputRef.current.getHtmlContent();

    const macroData: Partial<iCCMacro> = {
      id: String(new Date().getTime()),
      label: formValues.label,
      description: formValues.description,
      message: html,
      attachments: formValues.attachments,
      actions: formValues.actions,
    };

    if (macroId) {
      editMacro(macroId, macroData);
    } else {
      createMacro(macroData);
    }
    form.resetFields();
  }, [createMacro, editMacro, form, macroId]);

  const replyInputRef = useRef<any>();

  const braftEditorProps = useMemo(() => {
    return {
      mentions: [],
      actions: [],
      presets: [],
    };
  }, []);

  const organizationId = useSelector(selectOrganizationId);

  const extendControls = useMemo(() => {
    const extendControls: ExtendControlType[] = [
      {
        key: "message-presets",
        type: "modal",
        text: (
          <Tooltip title="Message Personalization">
            <i className="ri-braces-line"></i>
          </Tooltip>
        ),
        modal: {
          id: "message-personalization-modal",
          title: (
            <span>
              <ModalTitle
                title="Message Personalization"
                icon={<i className="ri-braces-line"></i>}
              />
            </span>
          ) as any,
          showFooter: false,
          closeOnBlur: true,
          className: "message-personalization-modal",
          children: (
            <div
              style={{ width: 600, padding: "0 10px", height: 400 }}
              className="overflow-auto"
            >
              <div className="my-4">
                You can use variable tokens in your messages
                <div className="font-bold my-4">Available Tokens:</div>
                <table className="simple-table-style w-full">
                  <thead>
                    <tr>
                      <th className="font-bold">Variable</th>
                      <th className="font-bold">Token</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...AvailableTokenTable, ...AvailableShopifyTokenList].map(
                      (item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.label}</td>
                            <td>
                              <Tag
                                onClick={() => {
                                  replyInputRef.current.insertHtmlToCursor(
                                    `<span class="variable-token-item">{${item.token}}</span>`,
                                  );
                                  const cb =
                                    replyInputRef.current.getControlBarInstance();
                                  cb?.extendedModals?.[
                                    "message-personalization-modal"
                                  ].close();
                                }}
                                className="cursor-pointer"
                              >
                                {item.token}
                              </Tag>
                            </td>
                          </tr>
                        );
                      },
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ),
        },
      },
    ];
    return extendControls;
  }, []);

  useEffect(() => {
    if (macroData) {
      replyInputRef.current.setHtmlContent(macroData.message || "");
    }
  }, [macroData]);

  return (
    <>
      <Modal
        title={
          <ModalTitle
            icon={<MDIIcon icon={mdiCogPlay} size="2rem" />}
            title={macroId === "" ? `Create Macro` : `Edit Macro`}
          />
        }
        open={visible}
        okButtonProps={{ loading: isAddingMacro || isEditingMacro }}
        onOk={async () => {
          try {
            await form.validateFields();
            onSubmit();
          } catch (e) {
            message.error("Please check your input");
          }
        }}
        onCancel={() => {
          form.resetFields();
          setSelectedMacro("");
          onChangeVisibility(false);
        }}
        okText={macroId === "" ? `Add` : `Save`}
        data-click-context="Add Edit Macro Modal"
        className="add-edit-macro-container"
      >
        <Form layout="vertical" form={form} className="bold-form-labels">
          {/* Label */}

          <Form.Item
            name="label"
            label="Label"
            rules={[{ required: true, message: "Please enter a label" }]}
          >
            <Input
              prefix={<i className="ri-info-line"></i>}
              placeholder="Eg: Welcome Reply"
            />
          </Form.Item>

          <Form.Item name="description" label="Description" rules={[]}>
            <Input />
          </Form.Item>

          <div className="font-bold mb-2">Message</div>

          <Form.Item
            name={["attachments"]}
            valuePropName="initialAttachments"
            trigger={"onAttachmentsChange"}
          >
            <WithAttachments
              id={`EDIT_MACRO`}
              entityType="EDIT_MACRO"
              entityId={organizationId!}
            >
              <div className="">
                <BraftMessageInput
                  placeholder="Tip: You can also use message personalization tokens"
                  initialValue=""
                  ref={replyInputRef}
                  {...braftEditorProps}
                  extendControls={extendControls}
                  editorId={"MACRO_EDITOR"}
                  noControls={false}
                  additionalExcludedControls={[
                    "text-indent",
                    "media",
                    "fullscreen",
                  ]}
                  // onPressEnter={onReplyInputPressEnter}
                  // handlePastedFiles={handleReplyInputPastedFiles}
                  // onChange={onReplyInputContentChange}
                  // onFocus={onReplyInputFocus}
                  // onBlur={onReplyInputBlur}
                  // onActionSelected={onReplyInputActionSelected}
                  // onPresetSelected={onPresetSelected}
                />
              </div>
              <div className="text-gray-600 text-center border-t border-gray-200 dark:border-gray-800">
                Drag your files here to upload attachments.
              </div>
            </WithAttachments>
          </Form.Item>

          <Form.Item
            name="actions"
            label="Actions to Execute"
            rules={[]}
            className="mt-8"
          >
            <ActionList
            // value={rule.actions}
            // onChange={(actions) => setRule({ actions })}
            />
          </Form.Item>
        </Form>
        <DarkModeBg />
      </Modal>
    </>
  );
};
