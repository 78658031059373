import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { message } from "antd";
import { BadgesController } from "badge-controller";
import _ from "lodash";
import { useCallback } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { loadConversationById } from "store/modules/conversations/conversations.helpers";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { loadMessages } from "store/modules/messages/messages.helpers";
import { useQueryWithStore } from "store/store.hooks";
import { useUniboxView } from "./use-unibox-view";

export const useChatActions = (conversationId: string) => {
  const store = useStore();
  const dispatch = useDispatch();

  const inboxConfig = useUniboxView();

  const {
    state: conversation,
    retry: reloadConversation,
    hasError: conversationNotFound,
  } = useQueryWithStore(
    selectConversationById(conversationId),
    loadConversationById(conversationId)(),
  );

  const connection = useSelector(
    selectConnectionById(conversation?.connectionId),
  );

  const { doAction: joinConversation } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () => SDK.joinConversation(conversationId),
      // successMessage: "Joined Conversation",
      failureMessage: "Something went wrong",
    }),
    [conversationId],
  );

  const { doAction: leaveConversation } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () => SDK.leaveConversation(conversationId),
      // successMessage: "Left Conversation",
      failureMessage: "Something went wrong",
    }),
    [conversationId],
  );

  const { doAction: removeMembers } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (userIds: string[]) =>
        SDK.removeMembersFromConversation(conversationId, userIds),
      // successMessage: "Joined Conversation",
      failureMessage: "Something went wrong",
    }),
    [conversationId],
  );

  const { doAction: editTags } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (tags: string[]) =>
        SDK.updateConversationTags(conversationId, _.uniq(tags)).then((d) => {
          if (tags.length > 0) {
            BadgesController.processBadgeEvent("tag-a-conversation");
          }
          return d;
        }),
      failureMessage: "Something went wrong",
    }),
    [conversationId],
  );

  const { doAction: transferChat } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (userId: string) =>
        SDK.requestToTransferConversation(conversationId, { userId }).then(
          (d) => {
            BadgesController.processBadgeEvent("transfer-a-conversation");
            return d;
          },
        ),
      failureMessage: "Something went wrong",
    }),
    [conversationId],
  );

  const { doAction: changeSubject, isProcessing: isSubjectChangeProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (subject: string) =>
          SDK.editConversation(conversationId, { subject: subject }),
        failureMessage: "Something went wrong",
      }),
      [conversationId],
    );

  const {
    doAction: changeConversationStatus,
    isProcessing: isStatusChangeProcessing,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (status) =>
        status === "OPEN"
          ? SDK.openConversation(conversationId)
          : SDK.closeConversation(conversationId),
      failureMessage: "Something went wrong",
    }),
    [conversationId],
  );

  const { doAction: syncFbConversation, isProcessing: isConversationSyncing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.syncFbConversation(conversationId).then((d) =>
            loadMessages(conversationId)(store, true),
          ),
        failureMessage: "Something went wrong",
      }),
      [conversationId, store],
    );

  const {
    doAction: syncIgConversation,
    isProcessing: isIgConversationSyncing,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () =>
        SDK.syncIgConversation(conversationId).then((d) =>
          loadMessages(conversationId)(store, true),
        ),
      failureMessage: "Something went wrong",
    }),
    [conversationId, store],
  );

  const {
    doAction: syncWAPrivateConversationOld,
    isProcessing: isWAPrivateConversationSyncingOld,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () =>
        SDK.whatsAppPrivateX
          .syncConversation(conversationId)
          .then((d) => loadMessages(conversationId)(store, true)),
      failureMessage: "Something went wrong",
    }),
    [conversationId, store],
  );

  const { doAction: blockUser, isProcessing: isBlockingUser } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (connectionId: string, userId: string) =>
          SDK.connections.blockUserFromPage(connectionId, userId),
        successMessage: "User has been blocked",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const {
    doAction: syncWAPrivateConversationX,
    isProcessing: isWAPrivateConversationSyncingX,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () =>
        SDK.whatsAppPrivateX
          .syncConversation(conversationId)
          .then((d) => loadMessages(conversationId)(store, true)),
      failureMessage: "Something went wrong",
    }),
    [conversationId, store],
  );

  const { doAction: cancelTransfer, isProcessing: isCancelTransferInProgress } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (userId: string) =>
          SDK.cancelTransferRequestToConversation(conversationId, { userId }),
        failureMessage: "Something went wrong",
      }),
      [conversationId],
    );

  const { doAction: refreshMessagesList, isProcessing: isMessagesRefreshing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () => loadMessages(conversationId)(store, true),
        failureMessage: "Something went wrong",
      }),
      [conversationId, store],
    );

  const {
    doAction: dismissAttentionFlag,
    isProcessing: isDismissingAttentionFlag,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (flag: "requiresAttention" | "requiresFollowup") =>
        SDK.dismissFlag(conversationId, { flag }),
      failureMessage: "Something went wrong",
    }),
    [conversationId],
  );

  const { doAction: activateChatBot, isProcessing: isActivatingChatbot } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (chatBotId: string) =>
          SDK.activateChatBot(conversationId, { chatBotId }),
        failureMessage: "Something went wrong",
      }),
      [conversationId],
    );

  const { doAction: deactivateChatBot, isProcessing: isDeactivatingChatbot } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (chatBotId: string) =>
          SDK.takeOverFromBot(conversationId),
        failureMessage: "Something went wrong",
      }),
      [conversationId],
    );

  const { doAction: bidToJoin, isProcessing: isBidding } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () => SDK.bidToJoin(conversationId),
      failureMessage: "Something went wrong",
    }),
    [conversationId],
  );

  const {
    doAction: routeToUserGroup,
    isProcessing: isRouteToUserGroupProcessing,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (group: string) =>
        SDK.autoRoute(conversationId, {
          groupsToRoute: [group],
          routingStrategy: "ROUND_ROBIN",
          routingMethod: "ASSIGN",
          routeOnlyToOnlineUsers: true,
          maximumHops: 3,
          inviteExpiry: 45 * 1000,
        }),
      failureMessage: "Something went wrong",
    }),
    [conversationId],
  );

  const isWAPrivateConversationSyncing =
    isWAPrivateConversationSyncingX || isWAPrivateConversationSyncingOld;

  const syncWAPrivateConversation = useCallback(() => {
    if (connection?.data?.isNewPrivateConnection) {
      syncWAPrivateConversationX();
    }
    return syncWAPrivateConversationOld();
  }, [
    connection?.data?.isNewPrivateConnection,
    syncWAPrivateConversationOld,
    syncWAPrivateConversationX,
  ]);

  const {
    doAction: permanentlyRemoveConversation,
    isProcessing: isRemovingConversation,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (confirmation: string) =>
        SDK.dangerousDeletion
          .deleteConversation(conversationId, confirmation)
          .then((d) => {
            message.success("Conversation has been removed");
            message.loading("Reloading View");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }),
      failureMessage: "Something went wrong",
    }),
    [conversationId],
  );

  const {
    doAction: activateTranslation,
    isProcessing: isActivatingTranslation,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (isEnabled: boolean, language?: string) =>
        SDK.setAutoTranslationMode(conversationId, { isEnabled, language }),
      failureMessage: "Something went wrong",
    }),
    [conversationId],
  );

  return {
    joinConversation,
    leaveConversation,
    removeMembers,
    editTags,
    transferChat,
    changeSubject,
    isSubjectChangeProcessing,
    changeConversationStatus,
    isStatusChangeProcessing,
    syncFbConversation,
    isConversationSyncing,
    syncIgConversation,
    isIgConversationSyncing,
    syncWAPrivateConversationOld,
    isWAPrivateConversationSyncingOld,
    blockUser,
    isBlockingUser,
    syncWAPrivateConversationX,
    isWAPrivateConversationSyncingX,
    cancelTransfer,
    isCancelTransferInProgress,
    refreshMessagesList,
    isMessagesRefreshing,
    dismissAttentionFlag,
    isDismissingAttentionFlag,
    activateChatBot,
    isActivatingChatbot,
    deactivateChatBot,
    isDeactivatingChatbot,
    bidToJoin,
    isBidding,
    routeToUserGroup,
    isRouteToUserGroupProcessing,
    isWAPrivateConversationSyncing,
    syncWAPrivateConversation,
    permanentlyRemoveConversation,
    isRemovingConversation,
    activateTranslation,
    isActivatingTranslation,
  };
};
