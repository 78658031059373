import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { MultiUserSelector } from "components/modules/user-management/users/components/multi-user-selector/multi-user-selector";
import { DarkModeBg } from "dark-mode-bg";
import React, { useCallback, useMemo, useState } from "react";
import { loadAllUsers } from "store/modules/users/users.helpers";
import { selectAllActiveUsersQuery } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSearch } from "utils/hooks/use-search";

export const AssignUsersModal = ({
  conversationId,
  visible,
  onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  conversationId: string;
}) => {
  const [selectedUsers, setSelectedUsers] = useState([] as string[]);
  const {
    state: { list: allUsers, isLoading },
    retry: reload
  } = useQueryWithStore(selectAllActiveUsersQuery, loadAllUsers());

  const userList = useMemo(
    () =>
      allUsers.map(user => ({
        id: user.id,
        name: `${user.data.firstName} ${user.data.lastName}`,
        email: user.credentials.email
      })),
    [allUsers]
  );

  const closeModal = useCallback(() => {
    onChangeVisibility(false);
    setSelectedUsers([]);
  }, [onChangeVisibility]);

  const { doAction: addMembers, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: SDK => (userIds: string[]) =>
        SDK.addMembersToConversation(conversationId, userIds).then(d => {
          closeModal();
        }),
      successMessage: "Users has been assigned",
      failureMessage: "Something went wrong"
    }),
    [closeModal, conversationId]
  );

  const { setSearchTerm, searchTerm, filteredData } = useSearch(userList);
  return (<>
    <Modal
      title={
        <ModalTitle
          title="Assign Users"
          icon={<i className="ri-group-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
      okText="Save"
      data-click-context="Assign Users Modal"
    >
      <div className="flex flex-col w-full user-picker">
        <MultiUserSelector
          value={selectedUsers}
          onChange={setSelectedUsers}
        />
        <div className="flex flex-row justify-end items-center mt-4">
          <Button
            type="primary"
            onClick={() => addMembers(selectedUsers)}
            loading={isProcessing}
          >
            Assign Users
          </Button>
        </div>
      </div>
      <DarkModeBg />
    </Modal>
  </>);
};
