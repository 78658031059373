import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iOrganization } from "@sdk/user-management/user-management.models";
import { Button, Divider, Form, Input, Modal, Progress } from "antd";
import { useForm } from "antd/lib/form/Form";

import { PillSelector } from "components/common/pill-selector/pill-selector";
import { SectionHeader } from "components/common/section-header";
import {
  TEAM_PRICING,
  iPricingPlan,
} from "components/modules/organization-management/general/billing/pricing-plans-data";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { DeepPartial } from "redux";
import {
  selectAccountStatus,
  selectDPAState,
  selectOrganizationOnboardingState,
} from "store/modules/workspace/workspace.selectors";
import { DPAStep } from "../common/dpa-step";
import { BrandingReviewStep } from "../common/onboarding-branding-review";
import { AppSumoExpressAccountFinalSetup } from "./appsumo-final-step";
import "./appsumo-onboarding-experience.scss";

const finalStepNumber = 9;
const stepPercentage = Math.floor(100 / finalStepNumber);

export const AppsumoCustomizeExperienceModal = ({
  visible,
  onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const [form] = useForm();
  const currentOnboardingState = useSelector(selectOrganizationOnboardingState);
  const dpaState = useSelector(selectDPAState);
  const accountStatus = useSelector(selectAccountStatus);

  const lastCompletedStep = useMemo(() => {
    if (currentOnboardingState) {
      if (currentOnboardingState.preferenceCompleted) {
        return finalStepNumber;
      } else if (currentOnboardingState.autoSetupCompleted) {
        return 9;
      } else if (currentOnboardingState?.dpaSkipped || dpaState?.signed) {
        return 8;
      } else if (
        currentOnboardingState.useCases &&
        currentOnboardingState.useCases.length > 0
      ) {
        return 7;
      } else if (
        currentOnboardingState.featuresInterested &&
        currentOnboardingState.featuresInterested.length > 0
      ) {
        return 6;
      } else if (currentOnboardingState.role) {
        return 5;
      } else if (currentOnboardingState.teamSize) {
        return 4;
      } else if (currentOnboardingState.industry) {
        return 3;
      } else if (currentOnboardingState.organizationWebsite) {
        return 2;
      }
      return 1;
    } else {
      return 1;
    }
  }, [currentOnboardingState, dpaState?.signed]);

  const [currentStep, setCurrentStep] = useState(lastCompletedStep);

  useEffect(() => {
    setCurrentStep(lastCompletedStep);
  }, [lastCompletedStep]);

  const [selectedPlan, setSelectedPlan] = useState<{
    plan: iPricingPlan;
    isYearly?: boolean;
  }>({
    plan: TEAM_PRICING[0],
    isYearly: false,
  });

  const { doAction: editCurrentOrganization } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (edits) => SDK.editCurrentOrganization(edits),
      // successMessage: "Done",
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const onNextStep = useCallback(() => {
    const formValues = form.getFieldsValue();
    setCurrentStep((currentStep) => currentStep + 1);
    editCurrentOrganization({
      appData: {
        ONBOARDING: formValues,
      },
    } as DeepPartial<iOrganization>);
  }, [editCurrentOrganization, form]);

  const { changePanelState } = useModalPanels();

  useEffect(() => {
    if (visible && currentStep === finalStepNumber) {
      editCurrentOrganization({
        metaData: {
          status: "PAID",
        },
        appData: {
          ONBOARDING: {
            preferenceCompleted: true,
          },
        },
      } as DeepPartial<iOrganization>);
    }
  }, [accountStatus, currentStep, editCurrentOrganization, visible]);

  const onSkipDPA = useCallback(() => {
    editCurrentOrganization({
      appData: {
        ONBOARDING: {
          dpaSkipped: true,
        },
      },
    } as DeepPartial<iOrganization>);
  }, [editCurrentOrganization]);

  return (
    <Modal
      title={
        null
        // <ModalTitle
        //   title="Getting Started"
        //   icon={<i className="ri-star-smile-line"></i>}
        // />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      footer={null}
      className="max-w-screen-md w-full customize-experience-modal"
      data-click-context="Customize Experience Modal"
      closable={currentStep === finalStepNumber}
      maskClosable={currentStep === finalStepNumber}
    >
      <div className="flex flex-col max-w-screen-xl w-full appsumo-onboarding-experience">
        {currentStep < 7 && (
          <>
            {currentStep === 1 && (
              <SectionHeader
                title="Welcome to Click Connector"
                description=" Let's quickly customize your experience and set up your
            workspace"
                icon={<span> 🎉</span>}
              />
            )}

            <Progress
              percent={
                currentStep === finalStepNumber
                  ? 100
                  : currentStep * stepPercentage
              }
              status="active"
              showInfo={false}
              className="opacity-25"
            />

            <Divider />

            <Form
              layout="vertical"
              onFinish={() => {
                const formValues = form.getFieldsValue();
              }}
              form={form}
              initialValues={currentOnboardingState}
              preserve
              requiredMark={false}
            >
              {currentStep === 1 && (
                <>
                  <Form.Item
                    label={
                      <div className="mb-8 text-center w-full">
                        <div className="font-semibold text-2xl">
                          Your Organization Website
                        </div>
                        <div className="text-gray-600">
                          We'll use this as when setting up email and live chat.
                        </div>
                      </div>
                    }
                    name="organizationWebsite"
                    rules={[
                      {
                        type: "url",
                        message: "Please enter a valid URL",
                        // required: true,
                      },
                    ]}
                    className="my-16 mb-24 full-w-label"
                  >
                    <Input
                      placeholder=""
                      size="large"
                      defaultValue={"https://"}
                    />
                  </Form.Item>
                  <div className="text-gray-600"></div>
                </>
              )}

              {currentStep === 2 && (
                <>
                  <Form.Item
                    label={
                      <div className="mb-8 text-center w-full">
                        <div className="font-semibold text-2xl">
                          Your Industry
                        </div>
                        <div className="text-gray-600">
                          Your Click Connector experience will be customized
                          based on this
                        </div>
                      </div>
                    }
                    name="industry"
                    rules={[
                      {
                        required: true,
                        message: "Please select your industry",
                      },
                    ]}
                    className="mb-16 full-w-label"
                  >
                    <PillSelector options={IndustryOptions} className="mt-2" />
                  </Form.Item>
                </>
              )}

              {currentStep === 3 && (
                <>
                  <Form.Item
                    label={
                      <div className="mb-8 text-center w-full">
                        <div className="font-semibold text-2xl">
                          How big is your team?
                        </div>
                        <div className="text-gray-600">
                          We'll use this to customize your team set up
                          experience
                        </div>
                      </div>
                    }
                    name="teamSize"
                    rules={[
                      {
                        required: true,
                        message: "Please select your team size",
                      },
                    ]}
                    className="mb-16 full-w-label"
                  >
                    <PillSelector options={TeamSizeOptions} className="mt-2" />
                  </Form.Item>
                </>
              )}

              {currentStep === 4 && (
                <>
                  <Form.Item
                    label={
                      <div className="mb-8 text-center w-full">
                        <div className="font-semibold text-2xl">
                          What is your role in your organization?
                        </div>
                      </div>
                    }
                    name="role"
                    rules={[
                      { required: true, message: "Please select your role" },
                    ]}
                    className="mb-16 full-w-label"
                  >
                    <PillSelector options={UserRoleOptions} />
                  </Form.Item>
                </>
              )}

              {currentStep === 5 && (
                <>
                  <Form.Item
                    label={
                      <div className="mb-8 text-center w-full">
                        <div className="font-semibold text-2xl">
                          What are the communication channels that you want to
                          use?
                        </div>

                        <div className="text-gray-600">
                          You can select as many as you want
                        </div>
                      </div>
                    }
                    name="featuresInterested"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one",
                      },
                    ]}
                    className="mb-16 full-w-label"
                  >
                    <PillSelector
                      options={InterestedFeaturesOptions}
                      multiple={true}
                      className="mt-2"
                    />
                  </Form.Item>
                </>
              )}
              {currentStep === 6 && (
                <>
                  <Form.Item
                    label={
                      <div className="mb-8 text-center w-full">
                        <div className="font-semibold text-2xl">
                          What will you be using Click Connector for?
                        </div>

                        <div className="text-gray-600">
                          You can select as many as you want
                        </div>
                      </div>
                    }
                    name="useCases"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one",
                      },
                    ]}
                    className="mb-16 full-w-label"
                  >
                    <PillSelector options={WhyUseCCOptions} multiple={true} />
                  </Form.Item>
                </>
              )}
            </Form>

            <Button
              type="primary"
              icon={<i className="ri-arrow-right-circle-line"></i>}
              className="font-bold"
              size="large"
              onClick={() => {
                form.validateFields().then((e) => {
                  onNextStep();
                });
              }}
            >
              Continue
            </Button>
          </>
        )}

        {/*  Agreement Signing */}

        {currentStep === 7 && (
          <>
            <DPAStep
              currentStep={currentStep}
              stepPercentage={stepPercentage}
              onSkipDPA={onSkipDPA}
            />
          </>
        )}

        {currentStep === 8 && (
          <>
            <BrandingReviewStep
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              stepPercentage={stepPercentage}
            />
          </>
        )}

        {currentStep === finalStepNumber && (
          <>
            <AppSumoExpressAccountFinalSetup />
          </>
        )}
      </div>
      <DarkModeBg />
    </Modal>
  );
};

const InterestedFeaturesOptions = [
  {
    value: "LIVE_CHAT",
    label: "Website Live Chat",
    icon: <i className="ri-message-3-line"></i>,
  },
  {
    value: "EMAIL_INBOX",
    label: "Email",
    icon: <i className="ri-mail-line"></i>,
  },
  {
    value: "FACEBOOK",
    label: "Facebook",
    icon: <i className="ri-facebook-box-line"></i>,
  },
  {
    value: "INSTAGRAM",
    label: "Instagram",
    icon: <i className="ri-instagram-line"></i>,
  },
  {
    value: "WHATSAPP",
    label: "Whatsapp",
    icon: <i className="ri-whatsapp-line"></i>,
  },
];

const WhyUseCCOptions = [
  {
    value: "Customer Support",
    label: "Customer Support",
    icon: <i className="ri-customer-service-2-line"></i>,
  },
  {
    value: "Sales",
    label: "Sales",
    icon: <i className="ri-file-user-line"></i>,
  },
  {
    value: "Lead Generation",
    label: "Lead Generation",
    icon: <i className="ri-customer-service-2-line"></i>,
  },
  {
    value: "Marketing Automation",
    label: "Marketing Automation",
    icon: <i className="ri-magic-line"></i>,
  },
  {
    value: "CRM",
    label: "CRM",
    icon: <i className="ri-contacts-book-line"></i>,
  },
];

const UserRoleOptions = [
  {
    value: "Founder / Owner",
    label: "Founder / Owner",
    icon: <i className="ri-user-star-line"></i>,
  },
  {
    value: "Marketing",
    label: "Marketing",
    icon: <i className="ri-funds-box-line"></i>,
  },
  {
    value: "Sales",
    label: "Sales",
    icon: <i className="ri-money-dollar-circle-line"></i>,
  },
  {
    value: "IT Manager",
    label: "IT Manager",
    icon: <i className="ri-computer-line"></i>,
  },
  {
    value: "Other",
    label: "Other",
    icon: <i className="ri-account-box-line"></i>,
  },
];

const TeamSizeOptions = [
  {
    value: "1-3",
    label: "1-3",
  },
  {
    value: "3-10",
    label: "3-10",
  },
  {
    value: "10-20",
    label: "10-20",
  },
  {
    value: "20-50",
    label: "20-50",
  },
  {
    value: "50+",
    label: "50+",
  },
];

const IndustryOptions = [
  {
    value: "Travel and Tourism",
    label: "Travel and Tourism",
    icon: <i className="ri-flight-takeoff-line"></i>,
  },
  {
    value: "E-Commerce",
    label: "E-Commerce",
    icon: <i className="ri-shopping-cart-line"></i>,
  },
  {
    value: "Automobile",
    label: "Automobile",
    icon: <i className="ri-car-line"></i>,
  },
  {
    value: "Telecommunication",
    label: "Telecommunication",
    icon: <i className="ri-radar-line"></i>,
  },
  {
    value: "Softwares and Application",
    label: "Softwares and Application",
    icon: <i className="ri-mac-line"></i>,
  },
  {
    value: "Other",
    label: "Other",
    icon: <i className="ri-more-line"></i>,
  },
];
