import { iMessageAttachment } from "@sdk/conversations/conversations.models";
import { iMatchedKnowledgeDocument } from "@sdk/knowledge-documents/knowledge-documents-model";
import { useCallback } from "react";

const SourceIcons = {
  KB_ARTICLE: <i className="ri-article-line"></i>,
  TEXT: <i className="ri-file-text-line"></i>,
  WEB_PAGE: <i className="ri-globe-line"></i>
};

export const MessageRelatedArticles = ({
  attachment
}: {
  attachment: iMessageAttachment;
}) => {
  const onNavigateToSource = useCallback((doc: iMatchedKnowledgeDocument) => {
    // Do Nothing
  }, []);
  return (
    <div className="w-full" style={{}}>
      <div className="text-sm font-semibold flex flex-row items-center mb-2">
        <i className="ri-sparkling-line"></i>{" "}
        <div className="ml-2">Related Articles</div>
      </div>
      {((attachment.payload.matchedDocuments ||
        []) as iMatchedKnowledgeDocument[]).map(doc => (
        <div
          key={doc.id}
          className="p-2 flex flex-row justify-between items-center  rounded-lg"
          onClick={() => onNavigateToSource(doc)}
        >
          <div className="flex flex-row items-center">
            {SourceIcons[doc.type]}
            <div className="ml-2"> {doc.title}</div>
          </div>
          <div className="">
            <i className="ri-arrow-right-s-line"></i>
          </div>
        </div>
      ))}
    </div>
  );
};
