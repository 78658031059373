import { ConnectionAutomationConfig } from "../../../common-automation-configs/connection-automations-config";
import { AutomaticEmailFollowups } from "./automatic-email-followups/automatic-email-followups";

export const LiveChatAutomationConfig = ({
  connectionId
}: {
  connectionId?: string;
}) => {
  return (
    <>
      <ConnectionAutomationConfig connectionId={connectionId} />
      <AutomaticEmailFollowups connectionId={connectionId!} />
    </>
  );
};
