import { SDK } from "@sdk";

import { iKnowledgeDocument } from "@sdk/knowledge-documents/knowledge-documents-model";
import { Store } from "redux";
import { QueryConfig } from "store/utils/query-config";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { knowledgeDocumentsDefaultQueryConfig } from "./knowledge-documents-default-query";
import { KnowledgeDocumentsSlice } from "./knowledge-documents.slice";

export const loadAllKnowledgeDocuments =
  () => async (store: Store<iStore>, forceReload?: boolean) => {
    console.log("forceReload", forceReload);
    await loadKnowledgeDocumentsQuery(
      knowledgeDocumentsDefaultQueryConfig,
      "all",
    )(store, forceReload);
  };

export const loadQueryKnowledgeDocuments =
  (queryConfig: QueryConfig, queryAlias?: string) =>
  async (store: Store<iStore>, forceReload?: boolean) => {
    queryAlias = queryAlias || JSON.stringify(queryConfig);
    await loadKnowledgeDocumentsQuery(queryConfig, queryAlias)(
      store,
      forceReload,
    );
  };

export const {
  loadEntityQueries: loadKnowledgeDocumentsQuery,
  loadEntityById: loadKnowledgeDocumentById,
  loadMoreEntityQueries: loadMoreKnowledgeDocumentsQueries,
} = CreateHelpersForEntity<iStore, iKnowledgeDocument>({
  sliceName: "knowledgeDocuments",
  slice: KnowledgeDocumentsSlice,
  isPaginatedQueries: true,
  fetchPageSize: 50,
  loadEntityQueryProvider: (query) => SDK.knowledgeDocuments.query(query),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.knowledgeDocuments.getById(entityId),
});
