import { Button, Input, Pagination } from "antd";
import { useMemo } from "react";
import { loadAllCCArticleCollections } from "store/modules/cc-article-collection/cc-article-collection.helpers";
import { selectCCArticleCollectionById } from "store/modules/cc-article-collection/cc-article-collection.selectors";
import { ccLoadArticlesOfCollection } from "store/modules/cc-articles/cc-articles.helpers";
import { selectCCArticleByCollectionIdFromQuery } from "store/modules/cc-articles/cc-articles.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { usePagination } from "utils/hooks/use-pagination";
import { useUrlState } from "utils/hooks/use-url-state";
import { HelpCenterArticleItem } from "../components/help-center-article-item";
import "../help-center.scss";

export const HelpCenterCollection = () => {
  const [selectedCollection, onSelectCollection] = useUrlState(
    "help-center-collection",
  );

  const [selectedArticle, onSelectArticle] = useUrlState("help-center-article");

  // Todo: Replace
  const { state: collection, isLoading } = useQueryWithStore(
    selectCCArticleCollectionById(selectedCollection),
    loadAllCCArticleCollections,
  );

  // Todo: Replace
  const { state: __articles, isLoading: isLoadingArticles } = useQueryWithStore(
    selectCCArticleByCollectionIdFromQuery(selectedCollection),
    ccLoadArticlesOfCollection(selectedCollection),
    [selectedCollection],
    !selectedCollection,
  );
  const _articles = useMemo(() => __articles?.list || [], [__articles?.list]);

  const articles = useMemo(
    () => _articles.filter((item) => item.metaData.status === "PUBLISHED"),
    [_articles],
  );

  const { paginationState, setPaginationState, itemsInView, totalItems } =
    usePagination(articles, {});

  return (
    <div className="flex flex-col">
      {(isLoadingArticles || isLoading) && (
        <div className="w-full flex flex-col justify-center items-center p-20">
          <i className="ri-loader-5-line animate-spin text-2xl"></i>
        </div>
      )}
      {!(isLoadingArticles || isLoading) && collection && (
        <>
          <div className="header">
            <div className="header">
              <div className=" bg-gray-100 border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 px-8 flex flex-row py-4">
                <Button
                  type="text"
                  className="h-full font-bold"
                  size="large"
                  onClick={() => {
                    onSelectCollection("");
                  }}
                >
                  <i className="ri-arrow-left-line"></i>
                </Button>
                <Input.Search
                  placeholder="Search Help Center..."
                  size="large"
                  className="rounded-md"
                  onSearch={(value) => {
                    onSelectArticle("", {
                      searchTerm: value,
                      "help-center-collection": "",
                    });
                  }}
                />
              </div>
            </div>

            <div
              className="help-center-collection-banner flex flex-col justify-center items-center font-bold"
              style={{ backgroundImage: `url(${collection?.icon})` }}
            >
              <div className="text-3xl">{collection?.label}</div>
            </div>
          </div>

          <div className="text-gray-600 px-12">{collection?.description}</div>
          <div>
            {articles.length > 0 ? (
              <div className="p-8">
                {itemsInView.map((article) => (
                  <HelpCenterArticleItem
                    key={article.id}
                    article={article}
                    onSelect={() => onSelectArticle(article.id!)}
                  />
                ))}
              </div>
            ) : (
              <div className="text-xl my-6 mb-16 flex flex-col items-center">
                <div>
                  <i className="ri-emotion-sad-line text-8xl text-blue-400"></i>
                </div>
                <div className="text-gray-500 leading-none">
                  Sorry! We couldn't find any results
                </div>
                {/* Todo: Chat with us */}
              </div>
            )}
          </div>
          <div className="flex flex-row justify-end items-center py-4 px-8">
            <Pagination
              pageSize={paginationState.pageSize}
              current={paginationState.currentPage}
              total={totalItems}
              onChange={(currentPage, pageSize) =>
                setPaginationState({
                  ...paginationState,
                  pageSize: pageSize || 20,
                  currentPage,
                })
              }
            />
          </div>

          <div className="flex flex-col justify-center items-center my-16">
            <div className="text-gray-600">
              Not able to find what you are looking for?
            </div>
            <Button
              type="primary"
              className="font-bold mt-4"
              icon={<i className="ri-chat-smile-3-line"></i>}
              onClick={() => {
                (window as any).ccWidget.setWidgetVisibility(true);
              }}
            >
              Chat with us
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
