export const columnChartConfig: Highcharts.Options = {
  colors: ["#2b9eff", "#1A73E8"],
  title: {
    text: "",
  },
  plotOptions: {
    series: {
      cursor: "pointer",
      point: {},
    },
  },
  exporting: {
    enabled: false,
  },
  series: [
    {
      animation: false,
      type: "column",
      data: [1, 2, 3, 4, 5, 6, 7],
      cursor: "pointer",
      point: {
        events: {
          click: function (e) {
            // console.log(this);
            // console.log(e.point.category);
            // console.log(e.point.y);
          },
        },
      },
    },
  ],
  credits: {
    enabled: false,
  },
  yAxis: {
    title: {
      text: "",
    },
    gridLineWidth: 0,
  },
  legend: {
    enabled: true,
  },
  tooltip: {
    enabled: true,
  },
  chart: { backgroundColor: "#00000000" },
};
