import { iContact } from "@sdk/crm/crm.models";
import { ContactSearchQueryBuilder } from "components/modules/crm/contacts/helpers/contact-search-query-builder";

export const generateSimilarContactsQuery = (contact: iContact) => {
  const query: any = {
    $or: [],
    "metaData.isVerified": true,
  };
  const firstName = contact.data.firstName;
  const lastName = contact.data.lastName;
  const emailPart = (contact.data.primaryEmail || "").split("@")[0];
  if (!(firstName || lastName || emailPart)) {
    return [];
  }

  if (firstName) {
    query["$or"] = [...query["$or"], ContactSearchQueryBuilder(firstName)];
  }
  if (lastName) {
    query["$or"] = [...query["$or"], ContactSearchQueryBuilder(lastName)];
  }
  if (emailPart) {
    query["$or"] = [...query["$or"], ContactSearchQueryBuilder(emailPart)];
  }

  return query;
};
