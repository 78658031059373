import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { iConnector } from "./chat-connectors.models";

export class ChaConnectorsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  chatConnectors = Object.assign(
    new EntityServices<iConnector>(this.config, "chat-connectors", {
      onCreate: () => {
        UserTracker.track("CHAT_CONNECTORS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("CHAT_CONNECTORS - Delete", {});
      },
    }),
  );
}
