import { iModalPanelRegistryItem } from "@libs/modal-panels/modal-panels";
import {
  ApiUserType,
  BulkUserActions,
} from "@sdk/user-management/user-management.controller-models";
import { UserGroupType } from "@sdk/user-management/user-management.models";
import { AddChatWidgetConnectionModal } from "components/modules/connections/channels/chat-widget/components/add-chat-widget-connection-modal/add-chat-widget-connection-modal";
import { AddEmailConnectionModal } from "components/modules/connections/channels/email/components/add-email-connection-modal/add-email-connection-modal";
import { ConfigurePersonalMailboxModal } from "components/modules/connections/channels/email/components/configure-personal-mailbox/configure-personal-mailbox";
import { AddFacebookConnectionModal } from "components/modules/connections/channels/facebook/add-facebook-connection-modal/add-facebook-connection-modal";
import { AddInstagramConnectionModal } from "components/modules/connections/channels/instagram/add-instagram-connection-modal/add-instagram-connection-modal";
import { AddTelnyxConnectionModal } from "components/modules/connections/channels/telnyx/add-telnyx-connection-modal/add-telnyx-connection-modal";
import { AddTwitterConnectionModal } from "components/modules/connections/channels/twitter/add-twitter-connection-modal/add-twitter-connection-modal";
import { AddWACloudConnectionModal } from "components/modules/connections/channels/whatsapp-cloud/add-whatsapp-cloud-connection-modal/add-whatsapp-cloud-connection-modal";
import { AddWhatsappConnectionModal } from "components/modules/connections/channels/whatsapp-private/add-whatsapp-private-connection-modal/add-whatsapp-private-connection-modal";
import { ScheduleReminderModal } from "components/modules/conversations/components/chat-view-side-bar/components/reminder-scheduler";
import { ConversationsPickerModal } from "components/modules/conversations/components/conversation-picker-modal/conversation-picker-modal";
import { AddChatBotModal } from "components/modules/conversations/components/manage-chat-bots/add-chat-bot-modal";
import { BotBuilderModal } from "components/modules/conversations/components/manage-chat-bots/bot-builder-modal";
import { EditChatBotModal } from "components/modules/conversations/components/manage-chat-bots/edit-chat-bot-modal";
import { AddMessagePresetModal } from "components/modules/conversations/components/manage-presets-x/add-message-preset.modal";
import { ManagePresetsModalX } from "components/modules/conversations/components/manage-presets-x/manage-presets-x.modal";

import { NewMessage } from "components/modules/conversations/components/new-message/new-message";
import { AddActivityModal } from "components/modules/crm/activities/add-activity/add-activity";
import { AddCompanyModal } from "components/modules/crm/companies/components/add-company/add-company";
import { CompanyPickerModal } from "components/modules/crm/companies/components/company-picker-modal/company-picker-modal";
import { AddContactModal } from "components/modules/crm/contacts/components/add-contact/add-contact";
import { BulkAddContacts } from "components/modules/crm/contacts/components/bulk-add-contacts/bulk-add-contacts";
import { ContactPickerModal } from "components/modules/crm/contacts/components/contact-picker-modal/contact-picker-modal";
import { EditContactModal } from "components/modules/crm/contacts/components/edit-contact/edit-contact";
import { AddEngagement } from "components/modules/crm/engagements/add-engagement";
import { CreateOpportunity } from "components/modules/crm/opportunities/create-opportunity";
import { AddProductModal } from "components/modules/crm/products/add-product";
import { AddAPIUserModal } from "components/modules/organization-management/general/apps-and-integrations/add-api-user/add-api-user-modal";
import { AddEditUserGroupModal } from "components/modules/user-management/user-groups/add-edit-user-group/add-edit-user-group-modal";
import { ManageUserGroupMembersModal } from "components/modules/user-management/user-groups/manage-members/manager-members";
import { AddUserModal } from "components/modules/user-management/users/components/add-user/add-user-modal";
import { BulkAddUsersModal } from "components/modules/user-management/users/components/bulk-add-users/bulk-add-users";
import { BulkEditUsersModal } from "components/modules/user-management/users/components/bulk-edit-users/bulk-edit-users";
import { EditUserModal } from "components/modules/user-management/users/components/edit-user/edit-user-modal";
import { UserPickerModal } from "components/modules/user-management/users/components/user-picker-modal/user-picker-modal";
import { MessageVariantEditorModal } from "components/pages/bot-builder/node-types/message-node/message-variant-editor";
import { CompanyProfileModal } from "components/pages/companies/components/company-profile/company-profile-modal";
import { AddNewConnectionModal } from "components/pages/configurations/sections/add-new-connection-modal";
import { ContactProfileV2 } from "components/pages/contacts/components/contact-profile/contact-profile-v2";
import { AddDynamicFormModal } from "components/pages/dynamic-forms/manage-dynamic-forms/add-dynamic-form-modal";
import { ConfigureDynamicFormModal } from "components/pages/dynamic-forms/manage-dynamic-forms/configure-dynamic-form-modal";
import { AcademyViewerModal } from "components/pages/home/academy-viewer-modal";

import { TroubleshootWhatsappConnectionModal } from "components/modules/connections/channels/whatsapp-private/troubleshoot-whatsapp-private-connection-modal/troubleshoot-whatsapp-private-connection-modal";
import { AddQuickCustomInboxModal } from "components/modules/conversations/components/add-quick-custom-inbox/add-quick-custom-inbox";
import { ConversationViewerModal } from "components/modules/conversations/components/conversation-viewer-modal/conversation-viewer-modal";
import { ExpressAccountSetUpModal } from "components/pages/home/express-account-setup-modal";

import { iAppFeatureRequest } from "@sdk/app-feature-requests/app-feature-requests-model";
import { iAutomationWorkflowStep } from "@sdk/automation-workflows/automation-workflows-model";
import { iCampaign } from "@sdk/campaigns/campaigns-model";
import { iConnectedApp } from "@sdk/connected-apps/connected-apps.models";
import { iHolidayQuote } from "@sdk/holiday-quotes/holiday-quotes-model";
import { BgPatternPickerModal } from "components/common/bg-pattern-selector/bg-pattern-selector";
import { ChangeLogsModal } from "components/common/change-logs/change-logs";
import { HelpCenterModal } from "components/common/help-center/help-center";
import { UnsplashImageSelectorModal } from "components/common/unsplash-image-selector/unsplash-image-selector-modal";
import { DownloadAppModal } from "components/electron-app/download-app/download-app";
import { EditFeatureRequestModal } from "components/feature-requests/components/edit-feature-request-modal/edit-feature-request-modal";
import { FeatureViewerModal } from "components/feature-requests/components/feature-viewer-modal/feature-viewer-modal";
import { ReportABugModal } from "components/feature-requests/components/report-bug-modal/report-bug-modal-modal";
import { RequestFeatureModal } from "components/feature-requests/components/request-feature-modal/request-feature-modal";
import { FeatureRequestsModal } from "components/feature-requests/feature-requests-modal";
import { AddTelegramConnectionModal } from "components/modules/connections/channels/telegram/add-telegram-connection-modal/add-telegram-connection-modal";
import { AddWACloudNativeConnectionModal } from "components/modules/connections/channels/whatsapp-cloud-native/add-whatsapp-cloud-native-connection-modal/add-whatsapp-cloud-native-connection-modal";
import { AddWhatsappOfficialConnection } from "components/modules/connections/channels/whatsapp-official/add-whatsapp-official-connection-modal/add-whatsapp-official-connection-modal";
import {
  iShopifyProductVariant,
  ShopifyProductBrowserModal,
} from "components/modules/conversations/components/chat-view-side-bar/components/shopify-side-bar/shopify-product-browser-modal";
import { EmailSignatureManagerModal } from "components/modules/email-signatures/email-signature-manager.modal";
import { DPASignerModal } from "components/modules/legal/dpa-signer";
import { AddWebhookAppModel } from "components/modules/organization-management/general/apps-and-integrations/add-webhook-app-model/add-webhook-app-model";
import { TestWebhookAppModel } from "components/modules/organization-management/general/apps-and-integrations/test-webhook-app-model/test-webhook-app-model";
import { CreateSubAccountWorkspaceModal } from "components/modules/organization-management/general/billing/create-sub-account-modal";
import { AddTaskModal } from "components/modules/tasks/add-task-modal/add-task-modal";
import { TestimonialCollectionModal } from "components/modules/testimonial-collection-modal/testimonial-collection-modal";
import { ChangeMyEmailModal } from "components/modules/user-management/users/components/my-profile/components/change-email";
import { ChangeMyPasswordModal } from "components/modules/user-management/users/components/my-profile/components/change-password";
import { MyProfileModalX } from "components/modules/user-management/users/components/my-profile/my-profile-modal";
import { MyProfileModal } from "components/modules/user-management/users/components/user-profile/user-profile-modal";
import { AddCampaignModal } from "components/pages/campaigns/add-campaign-modal";
import { ViewCampaignLogsModal } from "components/pages/campaigns/components/campaign-logs-modal/view-campaign-logs-modal";
import { ViewCampaignReportModal } from "components/pages/campaigns/components/campaign-report-modal/view-campaign-report-modal";
import { ConfigureCampaignModal } from "components/pages/campaigns/configure-campaign-modal";
import { EditEmailCampaignModal } from "components/pages/campaigns/edit-email-campaign-modal";
import {
  iEmailBlock,
  SimpleEmailBlockEditorModal,
} from "components/pages/campaigns/simple-email-creator/simple-email-block-editor";
import { SideBarContactProfileConfigModal } from "components/pages/configurations/sections/inbox-settings/components/chat-side-bar-config";
import { ConversationSummaryModal } from "components/pages/conversations/conversation-summary-modal";
import { MagicAssistantModal } from "components/pages/conversations/magic-assistant";
import { OpenAiAssistantModal } from "components/pages/conversations/open-ai-assistant";
import { PNRConverterModal } from "components/pages/conversations/pnr-converter/pnr-converter";
import { HolidayQuoteCreatorModal } from "components/pages/conversations/quote-creator/holiday-quote-creator";
import { iItineraryBlock } from "components/pages/conversations/quote-creator/itineries-model";
import { QuoteBlockEditorModal } from "components/pages/conversations/quote-creator/quote-block-editor-modal";
import { QuoteEditorModal } from "components/pages/conversations/quote-creator/quote-editor-modal";
import { QuoteHeaderEditorModal } from "components/pages/conversations/quote-creator/quoter-header-editor-modal";
import { AllBadgesModal } from "components/pages/home/all-badges-modal";
import { BadgeViewerModal } from "components/pages/home/badge-viewer-modal";
import { NewBadgeEarnedModal } from "components/pages/home/new-badge-earned-modal";
import CustomizeExperienceModal from "components/pages/home/onboarding/customize-experience-modal";
import { KnowledgeBaseManageArticlesModal } from "components/pages/knowledge-base/knowledge-base-manage-articles-modal";
import { KnowledgeBaseManageCollectionsModal } from "components/pages/knowledge-base/knowledge-base-manage-collection-modal";
import { AddEditKnowledgeDocumentModal } from "components/pages/knowledge-kit-page/add-edit-knowledge-document/add-edit-document-modal";
import { KnowledgeQueryLogsViewerModal } from "components/pages/knowledge-kit-page/knowledge-query-logs-viewer/knowledge-query-logs-viewer-modal";
import { CreatePipelineCard } from "components/pages/pipelines/components/create-pipeline-card";
import { AddCustomReportModal } from "components/pages/reports/components/add-custom-report-modal/add-custom-report-modal";
import { AddPSequenceModal } from "components/pages/sequences-page/add-sequence/add-sequence-modal";
import { SequenceDelayEditorModal } from "components/pages/sequences-page/delay-editor/delay-editor";
import { SequenceEditorModal } from "components/pages/sequences-page/sequence-editor-modal/sequence-editor-modal";
import {
  iEmailEditorData,
  SequenceEmailEditorModal,
} from "components/pages/sequences-page/sequence-email-editor/sequence-email-editor-modal";
import { SequenceStatusModal } from "components/pages/sequences-page/sequence-status-modal/sequence-status-modal";
import { StepEditorModal } from "components/pages/sequences-page/step-editor-modal/step-editor-modal";
import { ComponentProps, FC } from "react";

type iModalProps<
  T extends FC<
    {
      visible: boolean;
      onChangeVisibility: (state: boolean) => any;
    } & L
  >,
  L = unknown,
> = iModalPanelRegistryItem<
  Omit<Omit<ComponentProps<T>, "onChangeVisibility">, "visible">
>;
// Onboarding

export const CustomizeExperience: iModalProps<typeof CustomizeExperienceModal> =
  {
    name: "customize-experience",
    component: CustomizeExperienceModal,
  };

export const ExpressAccountSetUp: iModalProps<typeof AcademyViewerModal> = {
  name: "express-account-set-up",
  component: ExpressAccountSetUpModal,
};

export const AcademyViewer: iModalProps<typeof AcademyViewerModal> = {
  name: "academy-viewer",
  component: AcademyViewerModal,
};

// Connections
export const AddNewConnection: iModalProps<typeof AddNewConnectionModal> = {
  name: "add-new-connection",
  component: AddNewConnectionModal,
};

export const AddChatWidget: iModalProps<typeof AddChatWidgetConnectionModal> = {
  name: "add-chat-widget",
  component: AddChatWidgetConnectionModal,
};

export const AddFacebookConnection: iModalProps<
  typeof AddFacebookConnectionModal
> = {
  name: "add-facebook-connection",
  component: AddFacebookConnectionModal,
};

export const AddInstagramConnection: iModalProps<
  typeof AddInstagramConnectionModal
> = {
  name: "add-instagram-connection",
  component: AddInstagramConnectionModal,
};

export const AddWACloudConnection: iModalProps<
  typeof AddWACloudConnectionModal
> = {
  name: "add-wa-cloud-connection",
  component: AddWACloudConnectionModal,
};

export const AddWACloudNativeConnection: iModalProps<
  typeof AddWACloudNativeConnectionModal
> = {
  name: "add-wa-cloud-native-connection",
  component: AddWACloudNativeConnectionModal,
};

export const AddTelegramConnection: iModalProps<
  typeof AddTelegramConnectionModal
> = {
  name: "add-telegram-connection",
  component: AddTelegramConnectionModal,
};

export const AddEmailConnection: iModalProps<typeof AddEmailConnectionModal> = {
  name: "add-email-connection",
  component: AddEmailConnectionModal,
};

export const AddWhatsappConnection: iModalProps<
  typeof AddWhatsappConnectionModal
> = {
  name: "add-whatsapp-connection",
  component: AddWhatsappConnectionModal,
};

export const AddWhatsappD360Connection: iModalProps<
  typeof AddWhatsappConnectionModal
> = {
  name: "add-wa-d360-connection",
  component: AddWhatsappOfficialConnection,
};

export const AddTwitterConnection: iModalProps<
  typeof AddTwitterConnectionModal
> = {
  name: "add-twitter-connection",
  component: AddTwitterConnectionModal,
};

export const AddTelnyxConnection: iModalProps<typeof AddTelnyxConnectionModal> =
  {
    name: "add-telnyx-connection",
    component: AddTelnyxConnectionModal,
  };

export const TroubleshootWhatsappConnection: iModalProps<
  typeof TroubleshootWhatsappConnectionModal,
  { connectionId: string }
> = {
  name: "troubleshoot-wa-connection",
  component: TroubleshootWhatsappConnectionModal,
};

// CRM

export const ContactProfile: iModalProps<
  typeof ContactProfileV2,
  { contactId: string }
> = {
  name: "contact-profile",
  component: ContactProfileV2,
};

export const EditContact: iModalProps<
  typeof EditContactModal,
  { contactId: string }
> = {
  name: "edit-contact",
  component: EditContactModal,
};

export const BulkAddContactsModal: iModalProps<typeof BulkAddContacts> = {
  name: "bulk-add-contacts",
  component: BulkAddContacts,
};

export const AddContact: iModalProps<typeof AddContactModal> = {
  name: "add-contact",
  component: AddContactModal,
};

export const ContactPicker: iModalProps<
  typeof ContactPickerModal,
  {
    onContactSelected: (contactId: string) => any;
    title?: JSX.Element;
    hideAdd?: boolean;
    hideOnSelected?: boolean;
  }
> = {
  name: "contact-picker",
  component: ContactPickerModal,
};

export const AddActivity: iModalProps<typeof AddActivityModal> = {
  name: "add-activity",
  component: AddActivityModal,
};

export const CreateOpportunityModal: iModalProps<typeof CreateOpportunity> = {
  name: "add-opportunity",
  component: CreateOpportunity,
};

export const AddEngagementModal: iModalProps<typeof AddEngagement> = {
  name: "add-engagement",
  component: AddEngagement,
};

export const AddCompany: iModalProps<typeof AddCompanyModal> = {
  name: "add-company",
  component: AddCompanyModal,
};

export const CompanyProfile: iModalProps<
  typeof CompanyProfileModal,
  { companyId: string }
> = {
  name: "company-viewer",
  component: CompanyProfileModal,
};

export const CompanyPicker: iModalProps<
  typeof CompanyPickerModal,
  {
    onCompanySelected: (companyId: string) => any;
    title?: JSX.Element;
    hideAdd?: boolean;
    hideOnSelected?: boolean;
  }
> = {
  name: "company-picker",
  component: CompanyPickerModal,
};

export const AddProduct: iModalProps<typeof AddProductModal> = {
  name: "add-product",
  component: AddProductModal,
};

// Conversations

export const NewMessageCreator: iModalProps<typeof NewMessage> = {
  name: "new-message",
  component: NewMessage,
};

export const ConversationViewer: iModalProps<
  typeof ConversationViewerModal,
  { conversationId: string }
> = {
  name: "conversation-viewer",
  component: ConversationViewerModal,
};

export const ConversationsPicker: iModalProps<
  typeof ConversationsPickerModal,
  { onConversationSelected: (conversationId: string) => any; query: any }
> = {
  name: "conversation-picker",
  component: ConversationsPickerModal,
};

// Opportunities

// User Management
export const AddUser: iModalProps<typeof AddUserModal> = {
  name: "add-users",
  component: AddUserModal,
};

export const EditUser: iModalProps<typeof EditUserModal, { userId: string }> = {
  name: "edit-users",
  component: EditUserModal,
};

export const BulkAddUsers: iModalProps<typeof BulkAddUsersModal> = {
  name: "bulk-add-users",
  component: BulkAddUsersModal,
};

export const BulkEditUsers: iModalProps<
  typeof BulkEditUsersModal,
  { query: any; action: BulkUserActions }
> = {
  name: "bulk-edit-users",
  component: BulkEditUsersModal,
};

export const UserPicker: iModalProps<
  typeof UserPickerModal,
  { onUserSelected: (userId: string) => any; title?: JSX.Element }
> = {
  name: "user-picker",
  component: UserPickerModal,
};

export const ManageUserGroupMembers: iModalProps<
  typeof ManageUserGroupMembersModal,
  { userGroupId: string }
> = {
  name: "manage-user-group-members",
  component: ManageUserGroupMembersModal,
};

export const AddEditUserGroup: iModalProps<
  typeof AddEditUserGroupModal,
  { userGroupId: string; userGroupType: UserGroupType }
> = {
  name: "add-edit-user-group",
  component: AddEditUserGroupModal,
};

// API Tokens
export const AddAPIUser: iModalProps<
  typeof AddAPIUserModal,
  { type: ApiUserType }
> = {
  name: "add-api-user",
  component: AddAPIUserModal,
};

export const AddWebhookApp: iModalProps<
  typeof AddWebhookAppModel,
  { onCreated: (app: iConnectedApp) => any }
> = {
  name: "add-webhook-app",
  component: AddWebhookAppModel,
};

export const TestWebhookApp: iModalProps<
  typeof TestWebhookAppModel,
  { connectedAppId: string }
> = {
  name: "test-webhook-app",
  component: TestWebhookAppModel,
};

// User Editor
// My Profile
export const ConfigurePersonalMailbox: iModalProps<
  typeof ConfigurePersonalMailboxModal
> = {
  name: "configure-personal-mailbox",
  component: ConfigurePersonalMailboxModal,
};

export const AddQuickCustomInbox: iModalProps<typeof AddQuickCustomInboxModal> =
  {
    name: "add-quick-custom-inbox",
    component: AddQuickCustomInboxModal,
  };

// Reports
export const AddCustomReport: iModalProps<typeof AddCustomReportModal, {}> = {
  name: "add-custom-report",
  component: AddCustomReportModal,
};

// Chat Bots
export const BotBuilder: iModalProps<
  typeof BotBuilderModal,
  { chatBotId: string }
> = {
  name: "bot-builder",
  component: BotBuilderModal,
};

export const EditChatBot: iModalProps<
  typeof EditChatBotModal,
  { chatBotId: string }
> = {
  name: "edit-chat-bot",
  component: EditChatBotModal,
};

export const AddChatBot: iModalProps<typeof AddChatBotModal, {}> = {
  name: "add-chat-bot",
  component: AddChatBotModal,
};

export const MessageVariantEditor: iModalProps<
  typeof MessageVariantEditorModal,
  {
    existingVariants: string[];
    onSave: (variants: string[]) => any;
  }
> = {
  name: "message-variant-editor",
  component: MessageVariantEditorModal,
};

// Forms
export const ManageForm: iModalProps<
  typeof ConfigureDynamicFormModal,
  { dynamicFormId: string }
> = {
  name: "manage-form",
  component: ConfigureDynamicFormModal,
};

export const AddNewForm: iModalProps<typeof AddDynamicFormModal, {}> = {
  name: "add-new-form",
  component: AddDynamicFormModal,
};

// Sequences
export const AddPSequence: iModalProps<typeof AddPSequenceModal, {}> = {
  name: "add-new-sequence",
  component: AddPSequenceModal,
};

export const SequenceStatus: iModalProps<
  typeof SequenceStatusModal,
  { sequenceId: string }
> = {
  name: "sequence-status",
  component: SequenceStatusModal,
};

export const SequenceEditor: iModalProps<
  typeof SequenceEditorModal,
  { sequenceId: string }
> = {
  name: "sequence-editor",
  component: SequenceEditorModal,
};

export const SequenceEmailEditor: iModalProps<
  typeof SequenceEmailEditorModal,
  { data: iEmailEditorData; onSave: (data: iEmailEditorData) => any }
> = {
  name: "sequence-email-editor",
  component: SequenceEmailEditorModal,
};

export const SequenceDelayEditor: iModalProps<
  typeof SequenceDelayEditorModal,
  {
    step?: iAutomationWorkflowStep;
    onSave: (step: iAutomationWorkflowStep) => any;
  }
> = {
  name: "sequence-delay-editor",
  component: SequenceDelayEditorModal,
};

export const SequenceStepEditor: iModalProps<
  typeof StepEditorModal,
  {
    step?: iAutomationWorkflowStep;
    onSave: (step: iAutomationWorkflowStep) => any;
  }
> = {
  name: "sequence-step-editor",
  component: StepEditorModal,
};

export const SimpleEmailBlockEditor: iModalProps<
  typeof SimpleEmailBlockEditorModal,
  {
    blockInContext: iEmailBlock;
    onSave: (data: iEmailBlock) => any;
  }
> = {
  name: "simple-email-block-editor",
  component: SimpleEmailBlockEditorModal,
};

// Pipelines

export const CreatePipelineCardModal: iModalProps<
  typeof CreatePipelineCard,
  {}
> = {
  name: "create-pipeline-card",
  component: CreatePipelineCard,
};

// Knowledge Kit
export const AddEditKnowledgeDocument: iModalProps<
  typeof AddEditKnowledgeDocumentModal,
  { documentId?: string; initialValue?: string }
> = {
  name: "manage-knowledge-document",
  component: AddEditKnowledgeDocumentModal,
};

export const KnowledgeQueryLogsViewer: iModalProps<
  typeof KnowledgeQueryLogsViewerModal,
  {}
> = {
  name: "knowledge-query-logs",
  component: KnowledgeQueryLogsViewerModal,
};

export const MagicAssistant: iModalProps<
  typeof MagicAssistantModal,
  { query?: string; onReplaceText: (content: string) => any }
> = {
  name: "magic-assistant",
  component: MagicAssistantModal,
};

// Knowledge Base

export const KnowledgeBaseManageCollections: iModalProps<
  typeof KnowledgeBaseManageCollectionsModal,
  {}
> = {
  name: "manage-knowledge-base",
  component: KnowledgeBaseManageCollectionsModal,
};

export const KnowledgeBaseManageArticles: iModalProps<
  typeof KnowledgeBaseManageArticlesModal,
  {}
> = {
  name: "manage-articles-base",
  component: KnowledgeBaseManageArticlesModal,
};

// Configs

export const SideBarContactProfileSectionConfig: iModalProps<
  typeof CreatePipelineCard,
  {}
> = {
  name: "side-bar-profile-section",
  component: SideBarContactProfileConfigModal,
};

// My Profile

export const MyProfile: iModalProps<typeof MyProfileModal, {}> = {
  name: "my-profile",
  component: MyProfileModal,
};

export const MyProfileX: iModalProps<typeof MyProfileModalX, {}> = {
  name: "my-profile-x",
  component: MyProfileModalX,
};

export const ChangeMyPassword: iModalProps<typeof ChangeMyPasswordModal, {}> = {
  name: "change-password",
  component: ChangeMyPasswordModal,
};

export const ChangeMyEmail: iModalProps<typeof ChangeMyEmailModal, {}> = {
  name: "change-email",
  component: ChangeMyEmailModal,
};

export const MyManagePresets: iModalProps<typeof ManagePresetsModalX, {}> = {
  name: "manage-presets",
  component: ManagePresetsModalX,
};

export const AddMessagePreset: iModalProps<typeof AddMessagePresetModal, {}> = {
  name: "add-message-preset",
  component: AddMessagePresetModal,
};

export const MyEmailSignatures: iModalProps<
  typeof EmailSignatureManagerModal,
  {}
> = {
  name: "email-signatures",
  component: EmailSignatureManagerModal,
};

export const AllBadges: iModalProps<typeof AllBadgesModal, {}> = {
  name: "badges",
  component: AllBadgesModal,
};
export const BadgeViewer: iModalProps<
  typeof BadgeViewerModal,
  { badgeId: string }
> = {
  name: "view-badge",
  component: BadgeViewerModal,
};

export const NewBadgeEarned: iModalProps<
  typeof NewBadgeEarnedModal,
  { badgeId: string }
> = {
  name: "new-badge-earned",
  component: NewBadgeEarnedModal,
};

// Reminders

export const ScheduleReminder: iModalProps<
  typeof ScheduleReminderModal,
  {
    entityType: string;
    entityId: string;
    onScheduled?: (company: any) => any;
    hideOnScheduled?: boolean;
  }
> = {
  name: "add-reminder",
  component: ScheduleReminderModal,
};

// Task
export const AddTask: iModalProps<typeof AddTaskModal, {}> = {
  name: "add-task",
  component: AddTaskModal,
};

// Open AI Assistant
export const OpenAiAssistant: iModalProps<
  typeof OpenAiAssistantModal,
  {
    input: string;
    onReplaceText: (content: string) => any;
  }
> = {
  name: "open-ai-assistant",
  component: OpenAiAssistantModal,
};

export const ConversationSummary: iModalProps<
  typeof ConversationSummaryModal,
  {
    conversationId: string;
  }
> = {
  name: "chat-analyzer",
  component: ConversationSummaryModal,
};

// PNR Converter
export const PNRConverter: iModalProps<
  typeof PNRConverterModal,
  {
    onAddAsAttachment: (content: string) => any;
  }
> = {
  name: "pnr-converter",
  component: PNRConverterModal,
};
// Holiday Quotes
export const QuoteEditor: iModalProps<
  typeof QuoteEditorModal,
  {
    onAddAsAttachment?: (content: string) => any;
    holidayQuoteId: string;
  }
> = {
  name: "quote-editor",
  component: QuoteEditorModal,
};

export const HolidayQuoteCreator: iModalProps<
  typeof HolidayQuoteCreatorModal,
  {
    onAddAsAttachment: (content: string) => any;
    conversationId: string;
    contactId: string;
  }
> = {
  name: "generate-quote",
  component: HolidayQuoteCreatorModal,
};

export const QuoteBlockEditor: iModalProps<
  typeof QuoteBlockEditorModal,
  {
    onSave: (content: iItineraryBlock) => any;
    data: iItineraryBlock;
    holidayQuote: iHolidayQuote;
  }
> = {
  name: "quote-block-editor",
  component: QuoteBlockEditorModal,
};

export const QuoteHeaderEditor: iModalProps<
  typeof QuoteHeaderEditorModal,
  {
    onSave: (content: iHolidayQuote) => any;
    data: iHolidayQuote;
  }
> = {
  name: "quote-header-editor",
  component: QuoteHeaderEditorModal,
};

// Shopify Modals
export const ShopifyProductBrowser: iModalProps<
  typeof ShopifyProductBrowserModal,
  {
    onSelected?: (product: iShopifyProductVariant) => any;
  }
> = {
  name: "shopify-product-browser",
  component: ShopifyProductBrowserModal,
};

// Campaign Modals

export const AddCampaign: iModalProps<
  typeof AddCampaignModal,
  {
    onCreated: (content: iCampaign) => any;
  }
> = {
  name: "add-campaign",
  component: AddCampaignModal,
};

export const ConfigureCampaign: iModalProps<
  typeof ConfigureCampaignModal,
  {
    campaignId: string;
    mode?: "SEND" | "EDIT";
  }
> = {
  name: "configure-campaign",
  component: ConfigureCampaignModal,
};

export const EditEmailCampaign: iModalProps<
  typeof EditEmailCampaignModal,
  {
    campaignId: string;
  }
> = {
  name: "edit-email",
  component: EditEmailCampaignModal,
};

export const ViewCampaignReport: iModalProps<
  typeof ViewCampaignReportModal,
  {
    campaignId: string;
  }
> = {
  name: "campaign-report",
  component: ViewCampaignReportModal,
};

export const ViewCampaignReportLogs: iModalProps<
  typeof ViewCampaignLogsModal,
  {
    campaignId: string;
    logType: string;
  }
> = {
  name: "campaign-report-logs",
  component: ViewCampaignLogsModal,
};

// Sub Account Management
export const CreateSubAccountWorkspace: iModalProps<
  typeof CreateSubAccountWorkspaceModal,
  {
    onCreated?: () => any;
  }
> = {
  name: "create-sub-account",
  component: CreateSubAccountWorkspaceModal,
};

// App Modals
export const HelpCenter: iModalProps<typeof HelpCenterModal, {}> = {
  name: "help-center",
  component: HelpCenterModal,
};

export const ChangeLogs: iModalProps<typeof ChangeLogsModal, {}> = {
  name: "change-logs",
  component: ChangeLogsModal,
};

export const DPASigner: iModalProps<typeof DPASignerModal, {}> = {
  name: "dpa-signer",
  component: DPASignerModal,
};

export const FeatureRequests: iModalProps<
  typeof FeatureRequestsModal,
  {
    initialTab: "FEATURE_REQUESTS" | "ROAD_MAP" | "RELEASE_NOTES" | "BUG_BOARD";
  }
> = {
  name: "feature-requests",
  component: FeatureRequestsModal,
};

export const FeatureViewer: iModalProps<
  typeof FeatureViewerModal,
  {
    feature: iAppFeatureRequest;
    onUpdateRequest: (item: iAppFeatureRequest) => any;
  }
> = {
  name: "feature-request-viewer",
  component: FeatureViewerModal,
};

export const EditFeatureRequest: iModalProps<
  typeof EditFeatureRequestModal,
  {
    feature: iAppFeatureRequest;
    onEdited: (data: iAppFeatureRequest) => any;
  }
> = {
  name: "edit-feature-request",
  component: EditFeatureRequestModal,
};

export const RequestFeature: iModalProps<
  typeof RequestFeatureModal,
  {
    onAdded?: () => any;
  }
> = {
  name: "request-feature",
  component: RequestFeatureModal,
};

export const ReportBug: iModalProps<
  typeof ReportABugModal,
  {
    onAdded?: () => any;
  }
> = {
  name: "report-bug",
  component: ReportABugModal,
};

export const TestimonialCollection: iModalProps<
  typeof TestimonialCollectionModal,
  {}
> = {
  name: "testimonial-collection",
  component: TestimonialCollectionModal,
};

export const DownloadApp: iModalProps<typeof DownloadAppModal, {}> = {
  name: "download-app",
  component: DownloadAppModal,
};

export const UnsplashImageSelector: iModalProps<
  typeof UnsplashImageSelectorModal,
  {
    initialQuery?: string;
    onPicked: (url: string) => any;
  }
> = {
  name: "unsplash-picker",
  component: UnsplashImageSelectorModal,
};

export const BgPatternPicker: iModalProps<
  typeof BgPatternPickerModal,
  {
    primaryColor: string;
    onSelected: (url: string) => any;
  }
> = {
  name: "bg-pattern-picker",
  component: BgPatternPickerModal,
};

export const RegisteredModal = [
  CustomizeExperience,
  ExpressAccountSetUp,
  AcademyViewer,
  // Add Connection
  AddNewConnection,
  AddChatWidget,
  AddFacebookConnection,
  AddInstagramConnection,
  AddWACloudConnection,
  AddWACloudNativeConnection,
  AddEmailConnection,
  AddWhatsappConnection,
  AddWhatsappD360Connection,
  AddTwitterConnection,
  AddTelnyxConnection,
  AddTelegramConnection,
  ConfigurePersonalMailbox,
  // CRM
  ContactProfile,
  EditContact,
  BulkAddContactsModal,
  AddContact,
  ContactPicker,
  AddActivity,
  CreateOpportunityModal,
  AddEngagementModal,
  AddCompany,
  CompanyProfile,
  CompanyPicker,
  AddProduct,
  TroubleshootWhatsappConnection,
  // Conversations
  NewMessageCreator,
  ConversationViewer,
  ConversationsPicker,
  // Workspace Management
  AddUser,
  EditUser,
  BulkAddUsers,
  BulkEditUsers,
  UserPicker,
  ManageUserGroupMembers,
  AddEditUserGroup,
  // Apps and Integrations
  AddAPIUser,
  AddWebhookApp,
  TestWebhookApp,
  // Reports
  AddCustomReport,
  // Chat Bots
  BotBuilder,
  EditChatBot,
  AddChatBot,
  MessageVariantEditor,
  // Forms
  ManageForm,
  AddNewForm,
  // Sequences
  AddPSequence,
  SequenceStatus,
  SequenceEditor,
  SequenceEmailEditor,
  SequenceStepEditor,
  SequenceDelayEditor,
  SimpleEmailBlockEditor,
  // Pipelines
  CreatePipelineCardModal,
  // Workspace Settings
  SideBarContactProfileSectionConfig,
  // My Profile
  MyProfile,
  MyProfileX,
  ChangeMyPassword,
  ChangeMyEmail,
  MyEmailSignatures,
  AddMessagePreset,
  MyManagePresets,
  AddQuickCustomInbox,
  AllBadges,
  BadgeViewer,
  NewBadgeEarned,
  // Reminders
  ScheduleReminder,
  // Tasks
  AddTask,
  // Open AI Assistant
  OpenAiAssistant,
  ConversationSummary,
  // PNR Converter
  PNRConverter,
  // Holiday Quote
  QuoteBlockEditor,
  QuoteEditor,
  QuoteHeaderEditor,
  HolidayQuoteCreator,
  // Shopify
  ShopifyProductBrowser,
  // Campaigns
  AddCampaign,
  ConfigureCampaign,
  EditEmailCampaign,
  ViewCampaignReport,
  ViewCampaignReportLogs,
  // Knowledge Kit
  AddEditKnowledgeDocument,
  KnowledgeQueryLogsViewer,
  MagicAssistant,
  // Knowledge Base
  KnowledgeBaseManageCollections,
  KnowledgeBaseManageArticles,
  // Sub Account Management
  CreateSubAccountWorkspace,
  // App Modal
  HelpCenter,
  ChangeLogs,
  DPASigner,
  FeatureRequests,
  FeatureViewer,
  EditFeatureRequest,
  RequestFeature,
  ReportBug,
  TestimonialCollection,
  DownloadApp,
  UnsplashImageSelector,
  BgPatternPicker,
];
