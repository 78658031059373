import { useSimpleState } from "utils/hooks/use-simple-state";
import { TeamStatusWidgetModal } from "./components/team-staus-viewer-modal";
import { useTeamStatusStats } from "./hooks/use-team-status-stats";

export const TeamStatusWidget = ({
  widgetStyle = "ONE"
}: {
  widgetStyle?: "ONE" | "TWO" | "PHONE";
}) => {
  const {
    users,
    onlineUsers,
    awayUsers,
    busyUsers,
    OfflineUsers,
    onlineAndAvailableForCall,
    awayAndAvailableForCall,
    notAvailableForCall
  } = useTeamStatusStats();

  const [teamBrowserModalState, setTeamBrowserModalState] = useSimpleState({
    visibility: false,
    type: ""
  });

  if (widgetStyle === "TWO") {
    return (
      <>
        <div className=" border border-gray-200 dark:border-gray-700 mb-3  hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer p-2 rounded-lg">
          <div className="flex flex-row justify-center items-center font-bold">
            {onlineUsers > 1
              ? `Your Team is With you!`
              : `Your Team is not online!`}
          </div>
          <div
            className="flex items-center justify-center"
            onClick={() => setTeamBrowserModalState({ visibility: true })}
          >
            <div className="flex items-center">
              <i className="ri-checkbox-blank-circle-fill text-green-500 mr-1"></i>
              {onlineUsers}
            </div>
            <div className="flex items-center ml-5">
              <i className="ri-checkbox-blank-circle-fill text-yellow-500 mr-1"></i>
              {awayUsers}
            </div>
            <div className="flex items-center ml-5">
              <i className="ri-checkbox-blank-circle-fill text-red-500 mr-1"></i>
              {busyUsers}
            </div>
            <div className="flex items-center ml-5">
              <i className="ri-checkbox-blank-circle-fill text-black dark:text-white mr-1"></i>
              {OfflineUsers}
            </div>
          </div>
        </div>
        <TeamStatusWidgetModal
          visibility={teamBrowserModalState.visibility}
          onChangeVisibilityChange={visibility =>
            setTeamBrowserModalState({ visibility: false })
          }
        />
      </>
    );
  }

  if (widgetStyle === "PHONE") {
    return (
      <>
        <div className=" border border-gray-200 dark:border-gray-700 mb-3  hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer p-2 rounded-lg">
          {/* <div className="flex flex-row justify-center items-center font-bold">
            {onlineAndAvailableForCall > 1
              ? `Your Team is With you!`
              : `Your Team is not online!`}
          </div> */}
          <div
            className="flex items-center justify-center"
            onClick={() => setTeamBrowserModalState({ visibility: true })}
          >
            <div className="flex items-center">
              <i className="ri-checkbox-blank-circle-fill text-green-500 mr-1"></i>
              {onlineAndAvailableForCall}
            </div>
            <div className="flex items-center ml-5">
              <i className="ri-checkbox-blank-circle-fill text-yellow-500 mr-1"></i>
              {awayAndAvailableForCall}
            </div>

            <div className="flex items-center ml-5">
              <i className="ri-checkbox-blank-circle-fill text-black dark:text-white mr-1"></i>
              {notAvailableForCall}
            </div>
          </div>
        </div>
        <TeamStatusWidgetModal
          visibility={teamBrowserModalState.visibility}
          onChangeVisibilityChange={visibility =>
            setTeamBrowserModalState({ visibility: false })
          }
        />
      </>
    );
  }

  return (
    <>
      <div className="border-t border-b border-gray-200 dark:border-gray-700 mb-3 bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer">
        <div className="flex flex-row justify-center items-center font-bold">
          Team Availability
        </div>
        <div
          className="flex items-center justify-center"
          onClick={() => setTeamBrowserModalState({ visibility: true })}
        >
          <div className="flex items-center">
            <i className="ri-checkbox-blank-circle-fill text-green-500 mr-1"></i>
            {onlineUsers}
          </div>
          <div className="flex items-center ml-5">
            <i className="ri-checkbox-blank-circle-fill text-yellow-500 mr-1"></i>
            {awayUsers}
          </div>
          <div className="flex items-center ml-5">
            <i className="ri-checkbox-blank-circle-fill text-red-500 mr-1"></i>
            {busyUsers}
          </div>
          <div className="flex items-center ml-5">
            <i className="ri-checkbox-blank-circle-fill text-black dark:text-white mr-1"></i>
            {OfflineUsers}
          </div>
        </div>
      </div>
      <TeamStatusWidgetModal
        visibility={teamBrowserModalState.visibility}
        onChangeVisibilityChange={visibility =>
          setTeamBrowserModalState({ visibility: false })
        }
      />
    </>
  );
};
