import { iEmailConnectionData } from "@sdk/conversations/conversations.models";
import { Divider, Form, Select } from "antd";
import { HelpCenterPromptButton } from "components/common/help-center/embeddable-components/help-center-prompt-button";
import { SectionHeader } from "components/common/section-header";
import { StyledSwitch } from "components/common/styled-swtich";
import { ConnectionDataEditor } from "components/modules/connections/common-connection-settings/connection-data-editor";
import { InlineBraftEditor } from "components/modules/tasks/inline-braft-editor/inline-braft-editor";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";
const debounce = require("debounce-promise");

export const ConfigureTicketWorkflows = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const { customSmtp, customDomain } =
    (connection?.data as iEmailConnectionData) || {};

  return (
    <div className="animated fadeInLeftMin">
      <SectionHeader
        title="Ticket Workflows"
        icon={<i className="ri-shuffle-line"></i>}
        description="Configure automated ticket workflows for your email to improve customer experience"
      />
      <Divider />

      <HelpCenterPromptButton
        type="BANNER"
        text={
          <div className="text-lg p-3">
            Video Guide: Introduction to Ticket Workflows 📺
          </div>
        }
        description=""
        icon="LEARN"
        articleId="fc89e0-3dc92"
        className="my-4"
      />

      <ConnectionDataEditor
        connectionId={connectionId}
        icon={"ri-chat-new-line"}
        title="Confirmation Email on New Ticket"
        description="Send a confirmation email when a ticket is created"
      >
        <StyledSwitch
          label="Active"
          name={[
            "data",
            "ticketWorkflowConfig",
            "onCreationEmail",
            "isEnabled",
          ]}
        />
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const isActive = getFieldValue([
              "data",
              "ticketWorkflowConfig",
              "onCreationEmail",
              "isEnabled",
            ]);
            if (isActive) {
              return (
                <>
                  <Form.Item
                    label="Template"
                    name={[
                      "data",
                      "ticketWorkflowConfig",
                      "onCreationEmail",
                      "template",
                    ]}
                  >
                    <InlineBraftEditor
                      placeholder={defaultAckTemplate}
                      defaultValue={defaultAckTemplate}
                      disableBlurFeedback={true}
                    />
                  </Form.Item>
                </>
              );
            }
            return <></>;
          }}
        </Form.Item>
      </ConnectionDataEditor>

      <ConnectionDataEditor
        connectionId={connectionId}
        icon={"ri-inbox-archive-line"}
        title="Confirmation Email on Closing a Ticket"
        description="Send a confirmation email when a ticket is closed"
      >
        <StyledSwitch
          label="Active"
          name={["data", "ticketWorkflowConfig", "onClosedEmail", "isEnabled"]}
        />
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const isActive = getFieldValue([
              "data",
              "ticketWorkflowConfig",
              "onClosedEmail",
              "isEnabled",
            ]);
            if (isActive) {
              return (
                <>
                  <Form.Item
                    label="Template"
                    name={[
                      "data",
                      "ticketWorkflowConfig",
                      "onClosedEmail",
                      "template",
                    ]}
                  >
                    <InlineBraftEditor
                      placeholder={defaultCloseAckTemplate}
                      defaultValue={defaultCloseAckTemplate}
                    />
                  </Form.Item>
                </>
              );
            }
            return <></>;
          }}
        </Form.Item>
      </ConnectionDataEditor>

      <ConnectionDataEditor
        connectionId={connectionId}
        icon={"ri-layout-top-2-line"}
        title="Send Replies as Ticket Updates"
        description="Format all replies in a standard ticket template"
      >
        <StyledSwitch
          label="Active"
          name={[
            "data",
            "ticketWorkflowConfig",
            "formatRepliesInTemplate",
            "isEnabled",
          ]}
        />
      </ConnectionDataEditor>

      <ConnectionDataEditor
        connectionId={connectionId}
        icon={"ri-chat-settings-line"}
        title="Enable Simple Ticket Ids"
        description="5 digit ticket ID allows you to easily refer conversations with customer"
      >
        <StyledSwitch
          label="Active"
          name={[
            "data",
            "ticketWorkflowConfig",
            "simpleTicketIds",
            "isEnabled",
          ]}
        />
      </ConnectionDataEditor>

      <ConnectionDataEditor
        connectionId={connectionId}
        icon={"ri-mail-lock-line"}
        // icon={"ri-chat-forward-line"}
        title="Whitelisted Forwarding Emails"
        description="When emails are forwarded from these address, ClickConnector will attempt to parse the original email that is forwarded"
      >
        <Form.Item
          name={["data", "whiteListedForwarderEmails"]}
          label="Whitelisted Forwarding Email Addresses"
          rules={[]}
        >
          <Select
            mode="tags"
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            dropdownStyle={{ display: "none" }}
          >
            {" "}
          </Select>
        </Form.Item>
      </ConnectionDataEditor>

      <ConnectionDataEditor
        connectionId={connectionId}
        icon={"ri-mail-star-line"}
        title="Read 'Reply-to' Headers as Sender"
        description="Automatically read 'Reply-to' headers as Sender"
      >
        <StyledSwitch label="Active" name={["data", "readReplyToAsFrom"]} />
      </ConnectionDataEditor>
    </div>
  );
};

const defaultAckTemplate = `<p style="text-align:start;" size="0">Dear Recipient,</p><p></p><p><strong><u>Thank you for emailing Our Customer Service.</u></strong></p><p style="text-align:start;text-indent:2em;"><br/>This is an auto response generated by our Customer Service team as an acknowledgement to your email.</p><p>We are sure you must have provided your Name, telephone number, a clear description of your feedback with the other necessary background consisting of names of people, dates, and places in connection with your feedback. This will certainly help us investigate the issue better, <strong>if not please send the details across to serve you better.</strong></p><p style="text-align:start;text-indent:2em;"><br/>We will take your feedback seriously, and will provide a full reply within 2 working days excluding the day of the complaint. If we cannot give you a reply within this time we will tell you when we expect to do so and keep you up to date with progress.</p><p style="text-align:start;text-indent:2em;"><br/>Customer Service Team</p>`;

const defaultCloseAckTemplate = `<p style="text-align:start;" size="0">Dear Recipient,</p><p><strong><u>Ticket has been marked closed</u></strong></p><p style="text-align:start;text-indent:2em;"></p><p>This is an auto generated email by our Customer Service team</p><p></p><p>If you think this is a mistake or if you feel that the ticket has not been resolved yet, Feel free to reply to this email</p><p style="text-align:start;text-indent:2em;"><br/>Customer Service Team</p>`;
