import { Badge } from "antd";
import { selectTaskQuery } from "store/modules/tasks/tasks.selectors";
import { useSelectorWithMemoize } from "store/utils/use-selector-with-memoize";

const pageCount = 20;

export const QueryTasksBadgeCount = ({ query }: { query?: string }) => {
  const queryObject = useSelectorWithMemoize(() => selectTaskQuery(query!), [
    query
  ]);

  const taskCount = queryObject?.list?.length || 0;

  const totalAvailableItems = queryObject.totalItems || 0;
  const totalFetchedItems = (queryObject.lastFetchedPage || 0) * pageCount;
  const hasMore = totalAvailableItems > totalFetchedItems;

  return (
    <>
      {Boolean(taskCount) && (
        <Badge
          count={hasMore ? taskCount + 1 : taskCount}
          overflowCount={Math.min(totalFetchedItems, 99)}
          className="un-read-badge blue"
        ></Badge>
      )}
    </>
  );
};
