import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { Spin } from "antd";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import { KeyboardShortcut } from "components/common/keyboard-shortcut/keyboard-shortcut";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { GetContactIdentifier } from "components/modules/crm/contacts/helpers/get-contact-identifier";
import { getContactName } from "components/modules/crm/contacts/helpers/get-contact-name";
import { push } from "connected-react-router";
import { DarkModeBg } from "dark-mode-bg";
import {
  ActionId,
  ActionImpl,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarResults,
  KBarSearch,
  VisualState,
  useKBar,
  useMatches,
  useRegisterActions,
} from "kbar";
import _ from "lodash";
import { ContactProfile } from "modal-registry";

import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useStore } from "react-redux";
import { UserTracker } from "user-tracker";
import { GlobalEventEmitter } from "utils/global-event-emitter";
import { useDebouncedValue } from "utils/hooks/use-debounced-value";
import { useUrlState } from "utils/hooks/use-url-state";
import "./k-bar.scss";
import { useGlobalSearch } from "./use-global-search";

const searchStyle = {
  padding: "12px 16px",
  fontSize: "16px",
  width: "100%",
  boxSizing: "border-box" as React.CSSProperties["boxSizing"],
  outline: "none",
  border: "none",
  // background: "var(--background)",
  // color: "var(--foreground)",
};

const animatorStyle = {
  maxWidth: "600px",
  width: "100%",
  // background: "var(--background)",
  // color: "var(--foreground)",
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow: "var(--shadow)",
};

const groupNameStyle = {
  padding: "8px 16px",
  fontSize: "10px",
  textTransform: "uppercase" as const,
  opacity: 0.5,
};

export const KBar = () => {
  const store = useStore();
  const { results } = useMatches();

  const [globalShortcuts, setGlobalShortcuts] = useState([] as any[]);

  useEffect(() => {
    const registerEvents = (data) => {
      setGlobalShortcuts((globalEvents) => [...globalEvents, ...data]);
    };
    const removeEvents = (data) => {
      setGlobalShortcuts((globalEvents) => _.without(globalEvents, ...data));
    };
    GlobalEventEmitter.addListener("SHORT_CUT_REGISTRY_ADD", registerEvents);
    GlobalEventEmitter.addListener("SHORT_CUT_REGISTRY_REMOVE", removeEvents);
    return () => {
      GlobalEventEmitter.removeListener("SHORT_CUT_REGISTRY", registerEvents);
      GlobalEventEmitter.removeListener(
        "SHORT_CUT_REGISTRY_REMOVE",
        removeEvents,
      );
    };
  }, []);

  const actions = useMemo(() => {
    const actions = [...globalShortcuts];
    return actions;
  }, [globalShortcuts]);

  useRegisterActions(actions, [actions]);

  const { visualState } = useKBar((state) => ({
    visualState: state.visualState,
  }));

  useEffect(() => {
    if (visualState === VisualState.showing) {
      UserTracker.track("Global Search - Visible", {});
    }
  }, [visualState]);

  return (
    <KBarPortal>
      <KBarPositioner className="kb-mask">
        <KBarAnimator style={animatorStyle} className="dark-gradient-bg">
          <KBarSearch
            style={searchStyle}
            className="bg-white dark:bg-gray-900"
            defaultPlaceholder="Search Your Workspace"
          />
          <RenderResults />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
};

const useDebouncedSearchBar = () => {
  const { search } = useKBar((state) => ({
    search: state.searchQuery,
  }));

  const [searchText] = useDebouncedValue(search, 600);
  return searchText;
};

function RenderResults() {
  const { results, rootActionId } = useMatches();
  //  Todo: use parent and section property to group the results
  // Todo: You can also use priority: Priority.LOW

  const search = useDebouncedSearchBar();

  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => {
    setProcessing(true);
    const timer = setTimeout(() => {
      setProcessing(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  const entitySearchResults = useGlobalSearch(search);

  useEffect(() => {}, [entitySearchResults]);

  const { changePanelState } = useModalPanels();
  const [isHelpCenterVisible, setHelpCenterVisibility] = useUrlState(
    "activate-help-center",
  );

  const store = useStore();

  // Todo: Ideally this needs to be separated out in different hooks to stop extra processing
  const kbSearchBindings = useMemo(() => {
    const conversations = entitySearchResults.conversations

      .slice(0, 5)
      .map((conversation) => ({
        id: conversation.id!,
        section: "Matched Conversations",
        icon: (
          <ContactAvatar
            contactId={conversation.contactId}
            connectionId={conversation.connectionId}
          />
        ),
        subtitle: (conversation as any).contact
          ? `conversation with ${(conversation as any).contact?.data
              ?.firstName} ${(conversation as any).contact?.data
              ?.lastName} started on ${dayjs(
              conversation.metaData.createdAt,
            ).format("DD-YY-YYYY")}`
          : undefined,
        name: `${conversation.subject}`,
        keywords: `${conversation.subject} tags: ${conversation.tags?.join(
          " ",
        )} ${conversation.contact?.data?.firstName}  ${conversation.contact
          ?.data?.lastName}  ${conversation.contact?.data
          ?.primaryEmail}  ${conversation.contact?.data?.primaryMobile} ${
          conversation.id
        } # ${conversation.data.simpleId || ""}`,
        perform: () =>
          store.dispatch(push(`/conversations/auto/${conversation.id}`)),
      }));

    const contacts = entitySearchResults.contacts
      .slice(0, 5)
      .map((contact) => ({
        id: contact.id!,
        section: "Matched Contacts",
        icon: <ContactAvatar contactId={contact.id!} />,
        subtitle: `${GetContactIdentifier(contact)} | Record created on ${dayjs(
          contact.metaData.createdTime,
        ).format("DD-YY-YYYY")}`,
        name:
          getContactName(contact) ||
          contact?.data?.primaryEmail ||
          contact?.data?.primaryMobile ||
          "",
        keywords: `${getContactName(contact)} ${contact.data.tags?.join(
          " ",
        )} ${contact?.data?.primaryEmail}  ${contact?.data?.primaryMobile}`,
        perform: () => {
          changePanelState(ContactProfile, true, { contactId: contact.id! });
        },
      }));

    const opportunities = entitySearchResults.opportunities
      .slice(0, 5)
      .map((opportunity) => ({
        id: opportunity.id!,
        section: "Matched Opportunities",
        icon: <i className="ri-money-dollar-box-line"></i>,
        subtitle: (opportunity as any).contact
          ? `conversation with ${(opportunity as any).contact?.data
              ?.firstName} ${(opportunity as any).contact?.data
              ?.lastName} started on ${dayjs(opportunity.createdAt).format(
              "DD-YY-YYYY",
            )}`
          : undefined,
        name: opportunity.title,
        keywords: `${opportunity.title} ${opportunity.tags?.join(
          " ",
        )} ${opportunity.contact?.data?.firstName}  ${opportunity.contact?.data
          ?.lastName}  ${opportunity.contact?.data?.primaryEmail}  ${opportunity
          .contact?.data?.primaryMobile}`,
        perform: () =>
          store.dispatch(push(`/opportunities?opportunity=${opportunity.id}`)),
      }));
    const articles = entitySearchResults.articles
      .slice(0, 5)
      .map((article) => ({
        id: article.id!,
        section: "Matched Help Desk Articles",
        icon: <i className="ri-question-line"></i>,
        subtitle: article.description,
        name: article.title,
        keywords: `${article.title} ${article.description} ${article.tags?.join(
          " ",
        )}`,
        perform: () => {
          setHelpCenterVisibility("YES", {
            "help-center-article": article.id!,
            // "help-center-collection": collectionId,
          });
        },
      }));

    const contactLists = entitySearchResults.contactLists
      .slice(0, 5)
      .map((contactList) => ({
        id: contactList.id!,
        section: "Matched Contact Lists",
        icon: <i className="ri-mail-send-line"></i>,
        // subtitle: contactList.data?.subjectLine,
        name: contactList.data.label,
        keywords: `${contactList.data.label}`,
        perform: () => {
          store.dispatch(
            push(
              `/customers?contact-filter=((key:marketingList,operator:IS_IN,operatorConfig:(value:${contactList.id})))`,
            ),
          );
        },
      }));

    // Todo: Action Needs to be decided

    // const campaigns = entitySearchResults.campaigns
    //   .slice(0, 5)
    //   .map((campaign) => ({
    //     id: campaign.id!,
    //     section: "Matched Campaigns",
    //     icon: <i className="ri-mail-send-line"></i>,
    //     subtitle: campaign.data?.subjectLine,
    //     name: campaign.label,
    //     keywords: `${campaign.label} ${campaign.data?.subjectLine}`,
    //     perform: () => {
    //       store.dispatch(push(`/campaigns`));
    //     },
    //   }));

    // const chatBots = entitySearchResults.chatBots
    //   .slice(0, 5)
    //   .map((chatBot) => ({
    //     id: chatBot.id!,
    //     section: "Matched Chat Bots",
    //     icon: <i className="ri-mail-send-line"></i>,
    //     // subtitle: chatBot.data?.subjectLine,
    //     name: chatBot.label,
    //     keywords: `${chatBot.label}`,
    //     perform: () => {
    //       store.dispatch(push(`/campaigns`));
    //     },
    //   }));

    // const collections = entitySearchResults.collections
    //   .slice(0, 5)
    //   .map((collection) => ({
    //     id: collection.id!,
    //     section: "Matched Collections",
    //     icon: <i className="ri-mail-send-line"></i>,
    //     subtitle: collection.description,
    //     name: collection.label,
    //     keywords: `${collection.label} ${collection.description}`,
    //     perform: () => {
    //       store.dispatch(push(`/campaigns`));
    //     },
    //   }));

    // const dynamicForms = entitySearchResults.dynamicForms
    //   .slice(0, 5)
    //   .map((dynamicForm) => ({
    //     id: dynamicForm.id!,
    //     section: "Matched Forms",
    //     icon: <i className="ri-file-text-line"></i>,
    //     // subtitle: dynamicForm.data?.subjectLine,
    //     name: dynamicForm.label,
    //     keywords: `${dynamicForm.label}`,
    //     perform: () => {},
    //   }));

    // const sequences = entitySearchResults.sequences
    //   .slice(0, 5)
    //   .map((sequence) => ({
    //     id: sequence.id!,
    //     section: "Matches Automation Sequences",
    //     icon: <i className="ri-mail-send-line"></i>,
    //     subtitle: `${sequence.steps.length} Step(s)`,
    //     name: sequence.label,
    //     keywords: `${sequence.label}`,
    //     perform: () => {
    //       store.dispatch(push(`/campaigns`));
    //     },
    //   }));

    return [
      ...conversations,
      ...contacts,
      ...opportunities,
      ...articles,
      // ...campaigns,
      // ...chatBots,
      // ...collections,
      ...contactLists,
      // ...dynamicForms,

      // ...sequences,
    ];
  }, [
    changePanelState,
    entitySearchResults.articles,
    entitySearchResults.contactLists,
    entitySearchResults.contacts,
    entitySearchResults.conversations,
    entitySearchResults.opportunities,
    setHelpCenterVisibility,
    store,
  ]);

  useRegisterActions(kbSearchBindings, [kbSearchBindings]);

  return (
    <div className="bg-white dark:bg-gray-900">
      <KBarResults
        items={results}
        onRender={({ item, active }) =>
          typeof item === "string" ? (
            <div style={groupNameStyle}>{item}</div>
          ) : (
            <ResultItem
              action={item}
              active={active}
              currentRootActionId={rootActionId!}
            />
          )
        }
      />
      {isProcessing && search && (
        <div className="text-gray-600 text-sm p-4 flex flex-row items-center justify-center">
          <Spin spinning={true} indicator={<LoadingIndicatorWithSpin />}></Spin>
        </div>
      )}
      {entitySearchResults.isConversationLoading && (
        <div className="text-gray-600 text-sm p-4 flex flex-row items-center">
          <Spin spinning={true} indicator={<LoadingIndicatorWithSpin />}></Spin>
          <div className="text ml-2">Searching Conversations...</div>
        </div>
      )}
      {entitySearchResults.isContactsLoading && (
        <div className="text-gray-600 text-sm p-4 flex flex-row items-center">
          <Spin spinning={true} indicator={<LoadingIndicatorWithSpin />}></Spin>
          <div className="text ml-2">Searching Contacts...</div>
        </div>
      )}
      {entitySearchResults.isOpportunitiesLoading && (
        <div className="text-gray-600 text-sm p-4 flex flex-row items-center">
          <Spin spinning={true} indicator={<LoadingIndicatorWithSpin />}></Spin>
          <div className="text ml-2">Searching Opportunities...</div>
        </div>
      )}
      <DarkModeBg />
    </div>
  );
}

const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl;
      active: boolean;
      currentRootActionId: ActionId;
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors;
      const index = action.ancestors.findIndex(
        (ancestor) => ancestor.id === currentRootActionId,
      );
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1);
    }, [action.ancestors, currentRootActionId]);

    return (
      <div
        ref={ref}
        style={{
          padding: "12px 16px",
          background: active ? "var(--a1)" : "transparent",
          borderLeft: `2px solid ${
            active ? "var(--foreground)" : "transparent"
          }`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            fontSize: 14,
          }}
        >
          {action.icon && action.icon}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              {ancestors.length > 0 &&
                ancestors.map((ancestor) => (
                  <React.Fragment key={ancestor.id}>
                    <span
                      style={{
                        opacity: 0.5,
                        marginRight: 8,
                      }}
                    >
                      {ancestor.name}
                    </span>
                    <span
                      style={{
                        marginRight: 8,
                      }}
                    >
                      &rsaquo;
                    </span>
                  </React.Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && (
              <span style={{ fontSize: 12 }}>{action.subtitle}</span>
            )}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div
            aria-hidden
            style={{ display: "grid", gridAutoFlow: "column", gap: "4px" }}
          >
            {action.shortcut.map((sc) => (
              <KeyboardShortcut label={sc} />
              // <kbd
              //   key={sc}
              //   style={{
              //     padding: "4px 6px",
              //     background: "rgba(0 0 0 / .1)",
              //     borderRadius: "4px",
              //     fontSize: 14,
              //   }}
              // >
              //   {replaceKeyBindingToActualKey(sc)}
              // </kbd>
            ))}
          </div>
        ) : null}
      </div>
    );
  },
);
