import { iAgentContactBlock } from "../itineries-model";

export const ContactAgentBlock = ({
  data
}: {
  data: iAgentContactBlock["data"];
}) => {
  return (
    <div className="flex flex-col justify-center">
      <div className="">
        <div className="flex-row flex justify-start items-center">
          {/* <Avatar src={data?.data?.avatar} size={120} /> */}
          <div className="flex-col pl-3">
            <div className="font-bold">{`${data?.data?.firstName} ${data?.data?.lastName}`}</div>
            {data?.data?.description && (
              <div className="description">{`${data?.data?.description}`}</div>
            )}
            {data?.credentials?.email && (
              <div className="description">{`${data?.credentials?.email}`}</div>
            )}
            {data?.data?.bio && (
              <div className="description">{`${data?.data?.bio}`}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
