import { Modal } from "antd";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useEffect } from "react";
import { loadArticleById } from "store/modules/articles/articles.helpers";
import { selectArticleById } from "store/modules/articles/articles.selectors";
import { useQueryWithStore } from "store/store.hooks";
import "./article-preview.modal.scss";
import { edjsParser } from "./edj-parser";

export const ArticlePreviewModal = ({
  visible,
  onChangeVisibility,
  articleId,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  articleId: string;
}) => {
  const {
    state: article,
    retry: reload,
    isLoading,
  } = useQueryWithStore(
    selectArticleById(articleId),
    loadArticleById(articleId!)(),
  );

  useEffect(() => {
    if (article) {
      // console.log("Testt", article.body);
    }
  }, [article]);

  return (
    <Modal
      title={
        <ModalTitle
          title="Preview Article"
          icon={<i className="ri-file-text-line"></i>}
        />
      }
      open={visible}
      onCancel={() => onChangeVisibility(false)}
      footer={null}
      width={730}
      className="article-preview-modal"
      data-click-context="Article Preview Modal"
    >
      {isLoading && <LoadingIndicatorWithoutSpin />}
      {article && (
        <div
          className="editor-js-preview"
          dangerouslySetInnerHTML={{
            __html:
              typeof article.body === "string"
                ? article.body
                : edjsParser.parse(article.body).join(""),
          }}
        />
      )}
      <DarkModeBg />
    </Modal>
  );
};
