import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Pagination,
  Rate,
  Space,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { BadgesController } from "badge-controller";
import { UserAvatar } from "components/common/avatar/avatar";
import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { ImageInputWithPreview } from "../../common/image-input-with-preview/image-input-with-preview";
import { uploadImageWithValidation } from "../file-management/upload-image-with-validation";
import { SampleTestimonials } from "./sample-testimonials";

const VideoRecorder = React.lazy(() => import("react-video-recorder"));

export const TestimonialCollectionModal = ({
  visible,
  onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const currentUserId = useSelector(selectCurrentUserId);
  const [step, setStep] = useState<
    "BEGIN" | "RECORD_VIDEO" | "SEND_IN_TEXT" | "COMPLETED"
  >("BEGIN");

  const [form] = useForm();

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [selectedNewAvatar, setSelectedNewAvatar] = useState("");
  const { doAction: uploadAvatar, isProcessing: isUploading } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (file) =>
          SDK.uploadFile(file, {
            type: "TESTIMONIAL",
            entityId: currentUserId,
          })
            .then((fileRecord) => {
              setSelectedNewAvatar(fileRecord.url);
              // setSelectedFile(undefined);
            })
            .catch((e) => {
              setSelectedFile(undefined);
              throw e;
            }),
        successMessage: "Avatar has been updated",
        failureMessage: "Something went wrong",
      }),
      [currentUserId],
    );

  const { doAction: sendTestimonial, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (data) =>
        SDK.sendRequest({
          subject: "Testimonial",
          data,
        }).then((res) => {
          BadgesController.processBadgeEvent("send-a-testimonial");
          setStep("COMPLETED");
        }),
      successMessage: "Testimonial has been submitted",
      failureMessage: "Something went wrong",
    }),
    [],
  );

  useEffect(() => {
    // Reset
    if (!visible) {
      setStep("BEGIN");
    }
  }, [visible]);

  const onSubmitTextTestimonial = useCallback(async () => {
    const formValues = form.getFieldsValue();
    await sendTestimonial({ ...formValues, uploadedAvatar: selectedNewAvatar });
    setStep("COMPLETED");
  }, [form, selectedNewAvatar, sendTestimonial]);

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const { doAction: uploadTestimonialVideo, isProcessing: isUploadingVideo } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (file) =>
          SDK.uploadFile(file, {
            type: "TESTIMONIAL_VIDEO",
            entityId: currentUserId,
          })
            .then((fileRecord) => {
              console.log("fileRecord", fileRecord);
              sendTestimonial({ videoTestimonial: fileRecord.url });
              // setSelectedFile(undefined);
            })
            .catch((e) => {
              // setSelectedFile(undefined);
              throw e;
            }),
        failureMessage: "Something went wrong",
      }),
      [currentUserId, sendTestimonial],
    );

  const [testimonialBlob, setTestimonialBlob] = useState<File | undefined>(
    undefined,
  );

  const initialValues = useMemo(() => {
    return { marketingConsent: true };
  }, []);

  return (
    <Modal
      title={<ModalTitle title="" icon={<i className="ri-pulse-fill"></i>} />}
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={1050}
      bodyStyle={{ padding: 0 }}
      data-click-context="Testimonial Collection Modal"
    >
      {/* Begin Form */}
      {step === "BEGIN" && (
        <div className="container flex flex-row p-6">
          <div
            style={{ minWidth: 300 }}
            className="justify-center items-center flex flex-col"
          >
            <img src="/assets/illustrations/feedback.svg" alt="Feedback" />
          </div>
          <div
            className="content flex-1 flex flex-col justify-center items-center text-center"
            style={{ maxHeight: 450, minHeight: 450 }}
          >
            <div className="text-4xl mb-4 font-bold">
              Would you like to give a testimonial?
            </div>
            <div className="text-gray-600 mb-8">
              We'd like to hear from you 🤗
            </div>
            <div className="flex flex-row justify-center items-center">
              <Space>
                <Button
                  type="primary"
                  icon={<i className="ri-video-chat-line"></i>}
                  size="large"
                  onClick={() => setStep("RECORD_VIDEO")}
                >
                  Record a video
                </Button>
                <Button
                  type="primary"
                  icon={<i className="ri-pencil-line"></i>}
                  size="large"
                  className="bg-gray-900"
                  onClick={() => setStep("SEND_IN_TEXT")}
                >
                  Send in text
                </Button>
              </Space>
            </div>
          </div>
        </div>
      )}
      {/* Send in Text */}
      {step === "SEND_IN_TEXT" && (
        <div className="container flex flex-row">
          <div
            style={{ minWidth: 430, minHeight: 600 }}
            className="justify-center items-center flex flex-col bg-gray-100 rounded-md"
          >
            <div className="title text-2xl font-bold mb-10">
              Featured Testimonials
            </div>

            {[SampleTestimonials[currentTestimonial]].map(
              (testimonial, index) => (
                <div
                  className="px-3 mb-6"
                  key={index.toString()}
                  style={{ width: 400 }}
                >
                  <div className="flex flex-col h-full p-6 border bg-white border-gray-200 hover:bg-gray-50 rounded-lg text-testimonial shadow-md">
                    <div className="flex items-center mb-4">
                      <img
                        className="h-16 w-16 rounded-full object-cover"
                        src={testimonial.avatar}
                        alt="User Avatar"
                      />
                      <div className="pl-4">
                        <p className="text-xl font-bold">{testimonial.name}</p>
                        <p className="text-blue-600">{testimonial.title}</p>
                      </div>
                    </div>
                    <div
                      className="text-gray-600 leading-loose"
                      dangerouslySetInnerHTML={{
                        __html: `${testimonial.content}`,
                      }}
                    ></div>
                  </div>
                </div>
              ),
            )}
            <Pagination
              simple
              current={currentTestimonial + 1}
              onChange={(pageNumber) => setCurrentTestimonial(pageNumber - 1)}
              pageSize={1}
              total={SampleTestimonials.length}
            />
          </div>
          <Divider type="vertical" className="h-full"></Divider>
          <div
            className="content flex-1 flex flex-col overflow-auto p-4"
            style={{ maxHeight: 600, minHeight: 600 }}
          >
            <div className="title text-2xl font-bold mb-10">
              Your Testimonial
            </div>
            {/* Avatar */}
            <div className="label">Upload Your Photo</div>
            <ImageInputWithPreview
              selectedFile={selectedFile}
              onChange={(file) => {
                setSelectedFile(file);
                uploadImageWithValidation(
                  currentUserId,
                  file,
                  uploadAvatar,
                  setSelectedFile,
                );
              }}
              isUploading={isUploading}
              image={<UserAvatar userId={currentUserId} size={80} />}
            />
            <Form
              initialValues={initialValues}
              onFinish={onSubmitTextTestimonial}
              form={form}
              layout="vertical"
            >
              <Form.Item
                name="name"
                label="Your Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your name",
                  },
                ]}
              >
                <Input placeholder="Your name" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Your Email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="organizationName"
                label="Your Company"
                rules={[
                  {
                    required: true,
                    message: "Please enter your organization name",
                  },
                ]}
              >
                <Input placeholder="Your organization / company name" />
              </Form.Item>

              <Form.Item
                name="role"
                label="Your Role/Title"
                rules={[
                  {
                    required: true,
                    message: "Please enter your role",
                  },
                ]}
              >
                <Input placeholder="Your role: Eg: Sales Executive / Marketing Manager / Operations Manager" />
              </Form.Item>

              <Divider></Divider>

              <Form.Item
                name="testimonial"
                label="How do you feel about Click Connector Connector"
                rules={[
                  { required: true, message: "Please input Testimonial" },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={400}
                  minLength={90}
                  placeholder="Your Testimonial"
                />
              </Form.Item>

              <Form.Item name="rate" label="Your Rating">
                <Rate />
              </Form.Item>

              <Form.Item>
                <Form.Item
                  name="marketingConsent"
                  valuePropName="checked"
                  noStyle
                >
                  <Checkbox>
                    I give permission to use this testimonial across social
                    channels and other marketing efforts
                  </Checkbox>
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={isProcessing}
                  size="large"
                >
                  Send Your Testimonial
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
      {step === "RECORD_VIDEO" && (
        <div className="container flex flex-col justify-center items-center">
          <div
            className="video-recorder-bar p-10"
            style={{ height: 500, width: 850 }}
          >
            <Suspense fallback={<SuspenseLoadingIndicator />}>
              <VideoRecorder
                showReplayControls={true}
                onRecordingComplete={async (videoBlob) => {
                  // Do something with the video...
                  // console.log("videoBlob", videoBlob);
                  // const mediaBlob = await fetch(videoBlob).then((response) =>
                  //   response.blob()
                  // );

                  const videoFile = new File([videoBlob], "testimonial.webm", {
                    type: "video/webm",
                  });
                  setTestimonialBlob(videoFile);
                  // console.log("myFile", videoFile);

                  // const elem = window.document.createElement("a");
                  // elem.href = window.URL.createObjectURL(videoFile);
                  // elem.download = "download";
                  // document.body.appendChild(elem);
                  // elem.click();
                  // document.body.removeChild(elem);
                  // await uploadTestimonialVideo(videoFile);
                }}
              />
            </Suspense>
          </div>
          <div className="flex flex-row justify-center items-center my-4">
            {testimonialBlob && (
              <Button
                type="primary"
                loading={isUploadingVideo}
                icon={<i className="ri-check-line"></i>}
                onClick={async () => {
                  const videoFile = new File(
                    [testimonialBlob],
                    "testimonial.webm",
                    {
                      type: "video/webm",
                    },
                  );
                  await uploadTestimonialVideo(videoFile);

                  setStep("COMPLETED");
                }}
              >
                Upload Testimonial
              </Button>
            )}
          </div>
        </div>
      )}
      {/* COMPLETED */}
      {step === "COMPLETED" && (
        <div className="container flex flex-row  p-6">
          <div
            style={{ minWidth: 300 }}
            className="justify-center items-center flex flex-col"
          >
            {/* <img src="/assets/illustrations/gift.svg" alt="Thank you" /> */}
            <img src="/assets/illustrations/thank-you.svg" alt="Thank you" />
          </div>
          <div
            className="content flex-1 flex flex-col justify-center items-center text-center"
            style={{ maxHeight: 450, minHeight: 450 }}
          >
            <div className="text-4xl mb-4 font-bold">Thank you!</div>
            <div className="text-gray-600 mb-8">
              We appreciate that you took time to write a testimonial. We will
              be forever grateful for this.
            </div>
          </div>
        </div>
      )}
      <DarkModeBg />
    </Modal>
  );
};
