import { Form, Input, InputNumber, Switch } from "antd";
import { ConnectionDataEditor } from "../connection-data-editor";

export const AutoCloseConfigurationForm = ({
  connectionId
}: {
  connectionId;
}) => {
  return (
    <ConnectionDataEditor
      connectionId={connectionId}
      icon={"ri-inbox-archive-line"}
      title="Auto-Close Conversations"
      description="Automatically closes conversations after a period of inactivity"
    >
      <div className="auto-trigger flex flex-row items-center justify-between">
        <div className="font-bold">
          Enable 'Auto Close Conversations' on inactivity
        </div>
        <Form.Item
          name={["data", "autoClose", "active"]}
          hasFeedback={false}
          valuePropName="checked"
          style={{ marginBottom: 0, marginLeft: 5 }}
        >
          <Switch />
        </Form.Item>
      </div>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const configEnabled = getFieldValue(["data", "autoClose", "active"]);
          if (configEnabled) {
            return (
              <div className="mt-4">
                <Form.Item
                  label="Inactivity Period"
                  name={["data", "autoClose", "timeout"]}
                  help="Inactivity period in hours"
                >
                  <InputNumber
                    defaultValue={48}
                    min={1}
                    max={1500}
                    step={1}
                    className="w-full"
                    {...{ addonAfter: "Hours" }}
                    // formatter={(value) => `${value} Hours`}
                  />
                </Form.Item>
                <Form.Item
                  label="Message"
                  name={["data", "autoClose", "message"]}
                  help="Message that will be added in conversation when conversation is closed"
                >
                  <Input placeholder="Conversation has been closed due to inactivity" />
                </Form.Item>
              </div>
            );
          }
          return <></>;
        }}
      </Form.Item>
    </ConnectionDataEditor>
  );
};
