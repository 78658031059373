import React, { useEffect } from "react";

export const TwoColumnTour = ({
  illustration,
  sectionName,
  title,
  children
}: {
  illustration?: string;
  sectionName: string;
  title: string;
  children;
}) => {
  useEffect(() => {
    try {
      const synth = window.speechSynthesis;
      let timeout = 0;
      const maxTimeout = 2000;
      const interval = 250;
      const loadVoices = cb => {
        const voices = speechSynthesis.getVoices();
        if (voices.length > 0) {
          return cb(undefined, voices);
        }
        if (timeout >= maxTimeout) {
          return cb(new Error("loadVoices max timeout exceeded"));
        }
        timeout += interval;
        setTimeout(() => loadVoices(cb), interval);
      };
      loadVoices(() => {
        const synth = window.speechSynthesis;
        const voices = synth.getVoices();
        const tourContent = document.getElementById("tour-content");
        const SelectedVoice = voices.find(
          v => v.name === "Google UK English Male"
        );
        if (SelectedVoice) {
          let speech = new SpeechSynthesisUtterance(tourContent?.innerText!);
          speech.voice = SelectedVoice;
          synth.speak(speech);
        }
      });
      return () => {
        console.log("Pause");
        synth.cancel();
      };
    } catch (e) {
      console.log("Error while synthesis", e);
      // Todo
    }
  }, [children]);
  return (
    <div className="flex flex-row gap-8">
      {illustration && (
        <div className="flex flex-row justify-center items-center flex-1">
          <img
            alt="Tour Illustration"
            src={illustration}
            style={{ width: 360 }}
          />
        </div>
      )}

      <div className="flex flex-col flex-1" style={{ width: 300 }}>
        <div className="text-lg uppercase -mb-3">{sectionName}</div>
        <div className="text-4xl font-semibold mb-2">{title}</div>
        <div className="text-lg" id="tour-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export const SingleColumnTour = ({
  illustration,
  illustrationStyle,
  title,
  children
}: {
  illustration: string;
  illustrationStyle?: React.CSSProperties;
  title: string;
  children;
}) => {
  useEffect(() => {
    try {
      // https://dev.to/jankapunkt/cross-browser-speech-synthesis-the-hard-way-and-the-easy-way-353
      // import EasySpeech from 'easy-speech'
      const synth = window.speechSynthesis;
      let timeout = 0;
      const maxTimeout = 2000;
      const interval = 250;
      const loadVoices = cb => {
        const voices = speechSynthesis.getVoices();
        if (voices.length > 0) {
          return cb(undefined, voices);
        }
        if (timeout >= maxTimeout) {
          return cb(new Error("loadVoices max timeout exceeded"));
        }
        timeout += interval;
        setTimeout(() => loadVoices(cb), interval);
      };
      loadVoices(() => {
        const synth = window.speechSynthesis;
        const voices = synth.getVoices();
        const selectedVoice = voices.find(
          v => v.name === "Google UK English Male"
        );
        if (selectedVoice) {
          const tourContent = document.getElementById("tour-content");
          let speech = new SpeechSynthesisUtterance(tourContent?.innerText!);
          speech.voice = selectedVoice;
          synth.speak(speech);
        }
      });
      return () => {
        console.log("Pause");
        synth.cancel();
      };
    } catch (e) {
      console.log("Error while synthesis", e);
      // Todo
    }
  }, [children]);
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-row justify-center items-center">
        <img
          alt="Tour Illustration"
          src={illustration}
          style={{ width: 600, ...illustrationStyle }}
        />
      </div>
      <div className="flex flex-col flex-1 justify-center items-center">
        <div className="text-4xl">{title}</div>
        <div className="text-xl text-center" id="tour-content">
          {children}
        </div>
      </div>
    </div>
  );
};
