import { Button } from "antd";
import {
  getBezierPath,
  getEdgeCenter,
  getMarkerEnd,
  useReactFlow,
} from "react-flow-renderer";

const foreignObjectSize = 40;

export const BezierEdgeWithButtonLabel = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
}: any) => {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const { setEdges, getEdges } = useReactFlow();

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <Button
          type="dashed"
          onClick={(event) => {
            event.stopPropagation();
            setEdges(getEdges().filter((item) => item.id !== id));
          }}
          className="flow-delete-button"
          icon={<i className="ri-delete-bin-2-line pr-0"></i>}
        ></Button>
      </foreignObject>
    </>
  );
};
