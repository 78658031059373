export function extractMentions(str: string) {
  const results: string[] = [];
  const re = /@\[([^\\)]+)\)/g;
  let text: RegExpExecArray | null;
  while ((text = re.exec(str))) {
    results.push(`@[${text[1]})`);
  }
  return results.map(mention => {
    const labelRegex = /@\[([^\]]+)\]/g;
    const idRegex = /\(([^\\)]+)\)/g;
    return {
      mention: mention,
      label: labelRegex.exec(mention)?.[1],
      id: idRegex.exec(mention)?.[1]
    };
  });
}
