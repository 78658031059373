import { useSDK } from "@sdk";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Alert, Button, Modal } from "antd";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { loadContactById } from "store/modules/contacts/contacts.helpers";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { SimpleContactCard } from "../similar-contacts-explorer/simple-contact-card";

export const VerifyContactModal = ({
  visible,
  onChangeVisibility,
  contactId,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  contactId: string;
}) => {
  const { state: contact } = useQueryWithStore(
    selectContactById(contactId),
    loadContactById(contactId)(),
    [contactId],
    !contactId
  );

  const isVerified = contact?.metaData.isVerified;

  const { data: similarContacts, isLoading, error, reload } = useSDK(
    (SDK) => {
      const query = {
        $or: _.flattenDeep(
          [
            contact.data.primaryEmail
              ? {
                  "data.primaryEmail": contact.data.primaryEmail,
                }
              : undefined,
            contact.data.primaryMobile
              ? {
                  "data.primaryMobile": contact.data.primaryMobile,
                }
              : undefined,
          ].filter((e) => e)
        ),
        id: { $ne: contactId },
        "metaData.isVerified": true,
      };
      return SDK.queryContacts({ query, options: {} });
    },
    [contact],
    !(contact && !isVerified && visible)
  );

  const {
    doAction: mergeContact,
    isProcessing: isMergingContact,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (target: string) =>
        SDK.mergeContacts({
          target,
          source: contactId,
          dataOverrides: {},
        }).then((d) => {
          onChangeVisibility(false);
        }),
      successMessage: "Done",
      failureMessage: "Something went wrong",
    }),
    [contactId, onChangeVisibility]
  );

  const {
    doAction: verifyContact,
    isProcessing: isVerifying,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () =>
        SDK.verifyContact(contactId).then((d) => {
          onChangeVisibility(false);
        }),
      successMessage: "Done",
      failureMessage: "Something went wrong",
    }),
    [contactId, onChangeVisibility]
  );

  return (<>
    <Modal
      title={
        <ModalTitle
          title="Verify Contact"
          icon={<i className="ri-shield-user-fill"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save"
      width={500}
      data-click-context="Verify Contact Modal"
    >
      {contactId && (
        <div
          className="flex flex-row w-full justify-center items-center"
          style={{ minHeight: 320 }}
        >
          {isVerified && (
            <div className="flex flex-col text-center">
              <i className="ri-shield-check-fill text-8xl text-green-500"></i>
              <div className="font-bold"> Contact has been verified</div>
            </div>
          )}
          {!isVerified && (
            <>
              {isLoading && (
                <div className="flex flex-col text-center">
                  <LoadingIndicatorWithSpin />
                  <div className="font-bold"> Processing</div>
                </div>
              )}
              {error && (
                <div className="flex flex-col text-center">
                  <div className="font-bold">
                    {" "}
                    Something went wrong{" "}
                    <Button onClick={() => reload}>Retry</Button>
                  </div>
                </div>
              )}
              {!error &&
                !isLoading &&
                similarContacts &&
                similarContacts.docs &&
                similarContacts.docs.length > 0 && (
                  <div className="flex flex-col text-center">
                    <Alert
                      message={<b>Existing Contact Record Found</b>}
                      type="info"
                      description={
                        "Contact's primary email or primary mobile number matches with the following contact."
                      }
                      className="mt-3"
                    />
                    <SimpleContactCard
                      contactId={similarContacts.docs[0].id!}
                    />
                    <Button
                      type="primary"
                      onClick={() =>
                        mergeContact(similarContacts.docs[0].id!)
                      }
                      loading={isMergingContact}
                      size="large"
                    >
                      Merge Contact
                    </Button>
                  </div>
                )}

              {!error &&
                !isLoading &&
                similarContacts &&
                similarContacts.docs &&
                similarContacts.docs.length === 0 && (
                  <div className="flex flex-col text-center">
                    <div className="font-bold">
                      <Alert
                        message={`Contact will be marked as verified and future emails and text messages will be automatically assigned to this contact`}
                        type="info"
                        className="mb-3"
                      />

                      <Button
                        type="primary"
                        onClick={() => verifyContact()}
                        loading={isVerifying}
                        size="large"
                        icon={<i className="ri-check-line"></i>}
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                )}
            </>
          )}
        </div>
      )}
      <DarkModeBg />
    </Modal>
  </>);
};
