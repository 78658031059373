export const copyElementToClipboardWithStyles = (element: HTMLElement) => {
  const doc = document;
  let range;
  let selection;

  if ((doc.body as any).createTextRange) {
    range = (doc.body as any).createTextRange();
    range.moveToElement(element);
    range.select();
  } else if (window.getSelection) {
    selection = window.getSelection();

    range = doc.createRange();
    range.selectNodeContents(element);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  document.execCommand("copy");
  window.getSelection()?.removeAllRanges();
};
