import { Button, Spin } from "antd";
import classnames from "classnames";
import { ImageX } from "components/common/image";
import { LoadingIndicatorWithSpin } from "../loading-indicator/loading-indicator";
import "./image-input.scss";

export const ImageInputWithPreview = ({
  selectedFile,
  onChange,
  isUploading = false,
  image,
  className,
  shape = "round",
  width = 80,
  height = 80,
}: {
  selectedFile: any;
  onChange: any;
  isUploading?: any;
  image?: JSX.Element;
  className?: string;
  shape?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <div className={className} style={{ width, height }}>
      <div className="image-uploader-with-input">
        {selectedFile && (
          <Spin spinning={isUploading} indicator={<LoadingIndicatorWithSpin />}>
            <ImageX
              style={{ width, height }}
              src={URL.createObjectURL(selectedFile)}
            />
          </Spin>
        )}
        {!selectedFile &&
          (image ? (
            <>{image}</>
          ) : (
            !isUploading && (
              <div
                className={classnames(
                  shape === "round" ? "rounded-full" : "rounded-lg",
                  "bg-gray-300 flex items-center justify-center"
                )}
                style={{ width, height }}
              >
                <i className="ri-upload-2-line"></i>
              </div>
            )
          ))}
        <input
          type="file"
          onChange={(event) => {
            if (event.target.files !== null) {
              onChange(event.target.files[0]);
            }
          }}
        />
        <div
          className={classnames(
            "upload-icon",
            shape === "round" ? "rounded-full" : "rounded-lg"
          )}
        >
          <i className="ri-upload-2-line"></i>
        </div>
      </div>
    </div>
  );
};

export const ImageInputWithPreviewV2 = ({
  selectedFile,
  onChange,
  isUploading = false,
  image,
  className,
  shape = "round",
  width = 80,
  height = 80,
}: {
  selectedFile: any;
  onChange: any;
  isUploading?: any;
  image?: JSX.Element;
  className?: string;
  shape?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <div
      className={className}
      style={{
        maxWidth: width,
        maxHeight: height,
        width: "100%",
        height: "100%",
      }}
    >
      <div className="image-uploader-with-input flex flex-row justify-center relative">
        {selectedFile && (
          <Spin spinning={isUploading} indicator={<LoadingIndicatorWithSpin />}>
            <ImageX
              style={{
                width: "auto",
                height: "auto",
                maxWidth: width,
                maxHeight: height,
                objectFit: "contain",
              }}
              src={URL.createObjectURL(selectedFile)}
            />
          </Spin>
        )}
        {!selectedFile &&
          (image ? (
            <>{image}</>
          ) : (
            !isUploading && (
              <Button icon={<i className="ri-upload-2-line"></i>} type="dashed">
                Upload File
              </Button>
            )
          ))}
        <input
          type="file"
          onChange={(event) => {
            if (event.target.files !== null) {
              onChange(event.target.files[0]);
            }
          }}
        />
        {(selectedFile || image) && (
          <div
            className={classnames(
              "upload-icon",
              shape === "round" ? "rounded-full" : "rounded-lg"
            )}
          >
            <i className="ri-upload-2-line"></i>
          </div>
        )}
      </div>
    </div>
  );
};
