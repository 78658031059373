import { Form, Input, Select, Switch } from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { BraftJSInput } from "components/common/draft-js-advanced/draft-js-advanced";
import { useEffect, useRef, useState } from "react";
import { DynamicFormsComplexityList } from "../dynamic-form-templates-helpers/dynamic-forms-complexity-list";
import { DynamicFormsIndustries } from "../dynamic-form-templates-helpers/dynamic-forms-industries";
import { DynamicFormsTags } from "../dynamic-form-templates-helpers/dynamic-forms-tags";
import { DynamicFormsUseCases } from "../dynamic-form-templates-helpers/dynamic-forms-use-cases";

export const DynamicFormEditorTemplateCreatorForm = ({ dynamicFormId }) => {
  const DraftEditorRef = useRef<any>();
  const { setHtmlContent, getHtmlContent: _getHtmlContent } =
    DraftEditorRef.current || {};

  const [htmlInput, setHtmlInput] = useState("");
  useEffect(() => {
    setHtmlContent && setHtmlContent(htmlInput);
  }, [htmlInput, setHtmlContent]);

  return (
    <ConfigurationEditorComponent
      icon={"ri-magic-line"}
      title={"Save as Template"}
      description={"Make this template available to public"}
      entityType={"DYNAMIC_FORM"}
      entityId={dynamicFormId}
    >
      <>
        <Form.Item label="Description" shouldUpdate>
          {({ getFieldValue, setFieldValue }) => {
            const description = getFieldValue(["data", "description"]);
            setHtmlInput(description);
            return (
              <div className="draft-js-container border border-gray-200 dark:border-gray-700 rounded-lg">
                <BraftJSInput
                  initialValue={description}
                  ref={DraftEditorRef}
                  onBlur={() => {
                    const value = DraftEditorRef.current.getHtmlContent();
                    setFieldValue(["data", "description"], value);
                  }}
                  additionalExcludedControls={[
                    "font-size",
                    "fullscreen",
                    "hr",
                    "letter-spacing",
                    "remove-styles",
                    "undo",
                    "redo",
                    "text-indent",
                    "headings",
                    "text-align",
                  ]}
                />
              </div>
            );
          }}
        </Form.Item>

        <Form.Item
          name={["data", "description"]}
          label="Description"
          rules={[
            {
              required: true,
              message: "Please enter a description",
            },
          ]}
          hidden
        >
          <Input.TextArea placeholder="Enter a description" />
        </Form.Item>
        <Form.Item name={["data", "tags"]} label="Tags" rules={[]}>
          <Select allowClear mode="tags" placeholder="Select one or more">
            {DynamicFormsTags.map((tag) => (
              <Select.Option key={tag.label} value={tag.label}>
                {tag.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={["data", "useCases"]} label="Use Cases" rules={[]}>
          <Select allowClear mode="tags" placeholder="Select one or more">
            {DynamicFormsUseCases.map((tag) => (
              <Select.Option key={tag.label} value={tag.label}>
                {tag.icon} {tag.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={["data", "industries"]} label="Industries" rules={[]}>
          <Select allowClear mode="tags" placeholder="Select one or more">
            {DynamicFormsIndustries.map((tag) => (
              <Select.Option key={tag.label} value={tag.label}>
                {tag.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
          name={["data", "platforms"]}
          label="Supported Platforms"
          rules={[]}
        >
          <Select allowClear mode="tags" placeholder="Select one or more">
            {DynamicFormsPlatforms.map((tag) => (
              <Select.Option key={tag.id} value={tag.id}>
                {tag.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item name={["data", "complexity"]} label="Complexity" rules={[]}>
          <Select allowClear placeholder="Select one">
            {DynamicFormsComplexityList.map((tag) => (
              <Select.Option key={tag.id} value={tag.id}>
                {tag.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={["data", "isPublic"]}
          valuePropName="checked"
          label="Make it Public"
          rules={[]}
        >
          <Switch />
        </Form.Item>
      </>
    </ConfigurationEditorComponent>
  );
};
