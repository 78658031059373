import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { TourProps, TourStepProps } from "antd";

import { Store } from "redux";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { selectFeatureRestrictionConfig } from "store/modules/workspace/workspace.selectors";
import { SingleColumnTour, TwoColumnTour } from "./tour-modals";

export const GenerateNavigationTour = (store: Store) => {
  const connections = selectAllConnections(store.getState());

  const liveChatConnection = connections.find(item => item.type === "WEB_CHAT");

  const currentUser = selectCurrentUser(store.getState());
  const userRole = getRoleFromPermission(currentUser?.permissions || []);

  const featureRestrictionConfig = selectFeatureRestrictionConfig(
    store.getState()
  );

  const steps: TourProps["steps"] = [
    // Welcome
    {
      title: <></>,
      description: (
        <SingleColumnTour
          title="Your account is here!"
          illustration="/logo192.png"
          illustrationStyle={{ width: 120 }}
        >
          Here’s a quick tour of the platform so that you know where everything
          is.
        </SingleColumnTour>
      ),
      placement: "center"
    },
    // Home
    {
      title: <></>,
      placement: "right",
      description: (
        <TwoColumnTour
          title="Home"
          sectionName="NAVIGATION 101"
          illustration="/assets/tours/navigation/home.svg"
        >
          This is the home page.
          <br />
          <br />
          We’ve designed it to be a dashboard that helps you quickly see your
          tasks, performance, scheduled events and your team’s performance.
          <br />
          <br />
          You can also access your achievements and our training module that’ll
          grant you access to the secrets of Click Connector.
        </TwoColumnTour>
      ),
      target: () => document.getElementById("MAIN_MENU_/home")!
    },
    // Live View
    ...(liveChatConnection
      ? [
          {
            title: <></>,
            placement: "right",
            description: (
              <TwoColumnTour
                title="Live View"
                sectionName="NAVIGATION 101"
                illustration="/assets/tours/navigation/live-view.svg"
              >
                Live view gives you a snapshot of the data our chat widget gets
                when potential leads navigate your website.
                <br />
                <br />
                You can view the data by country, city and the pages they visit,
                or create a custom filter that’s right for your travel agency
              </TwoColumnTour>
            ),
            target: () =>
              document.getElementById("MAIN_MENU_/website-live-view")!
          } as TourStepProps
        ]
      : []),
    // Inbox
    {
      title: <></>,
      placement: "right",
      description: (
        <TwoColumnTour
          title="Inbox"
          sectionName="NAVIGATION 101"
          illustration="/assets/tours/inbox/welcome.svg"
        >
          The inbox is where all your conversations happen.
          <br />
          <br />
          We have everything you need to communicate with lead and passengers
          here, plus helpful automations that lighten your workload.
          <br />
          <br />
          Be sure to set them up to get the most out of Click Connector!
        </TwoColumnTour>
      ),
      target: () => document.getElementById("MAIN_MENU_/conversations")!
    },
    // CRM
    ...(featureRestrictionConfig?.["CRM_ACCESS"]?.[userRole]
      ? []
      : [
          {
            title: <></>,
            placement: "right",
            description: (
              <TwoColumnTour
                title="CRM"
                sectionName="NAVIGATION 101"
                illustration="/assets/tours/navigation/crm.svg"
              >
                The CRM holds all your customer information. If you communicate
                with a customer on Click Connector, they don’t leave the CRM
                unless they’re manually removed.
                <br />
                <br />
                The CRM can be used along with our tagging feature to segment
                audiences and run powerful email and messaging campaigns.
              </TwoColumnTour>
            ),
            target: () => document.getElementById("MAIN_MENU_/customers")!
          } as TourStepProps
        ]),
    // Reports
    ...(!["Manager", "Owner"].includes(userRole)
      ? []
      : [
          {
            title: <></>,
            placement: "right",
            description: (
              <TwoColumnTour
                title="Reports"
                sectionName="NAVIGATION 101"
                illustration="/assets/tours/navigation/reports.svg"
              >
                Reports takes the rich dataset that’s produced when agents and
                leads have conversations and creates a set of reports than
                provide operational insights.
                <br />
                <br />
                Try out our custom reports to see exactly what your agency needs
                to see.
              </TwoColumnTour>
            ),
            target: () => document.getElementById("MAIN_MENU_/reports")!
          } as TourStepProps
        ]),
    ...(!["Owner"].includes(userRole)
      ? []
      : [
          {
            title: <></>,
            placement: "right",
            description: (
              <TwoColumnTour
                title="Workspace Settings"
                sectionName="NAVIGATION 101"
                illustration="/assets/tours/navigation/workspace-settings.svg"
              >
                This is where you set up and tweak all the modules that makes
                Click Connector work.
                <br />
                <br />
                Only accessible to Manager and Owner user roles.
              </TwoColumnTour>
            ),
            target: () => document.getElementById("MAIN_MENU_/configurations")!
          } as TourStepProps
        ]),
    // {
    //   title: <></>,
    //   description: (
    //     <TwoColumnTour
    //       title="Calls"
    //       sectionName="NAVIGATION 101"
    //       illustration="/assets/tours/inbox/step-2.png"
    //     >
    //       Your callback requests and call log are stored here.
    //     </TwoColumnTour>
    //   ),
    //   target: () => document.getElementById("MAIN_MENU_/conversations")!,
    // },
    {
      title: <></>,
      placement: "right",
      description: (
        <TwoColumnTour
          title="Notifications"
          sectionName="NAVIGATION 101"
          illustration="/assets/tours/navigation/notifications.svg"
        >
          We’ll send you notifications of everything you need to know on Click
          Connector for easy access.
        </TwoColumnTour>
      ),
      target: () => document.getElementById("notification-icon-nav")!
    },
    {
      title: <></>,
      placement: "right",
      description: (
        <TwoColumnTour
          title="Profile"
          sectionName="NAVIGATION 101"
          illustration="/assets/tours/navigation/profile.svg"
        >
          Where you change your display picture, name, switch between light mode
          and dark mode, send testimonials and many more!
        </TwoColumnTour>
      ),
      target: () => document.getElementById("profile-menu-icon")!
    }
  ];

  return steps;
};
