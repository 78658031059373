import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Form, Input, message, Modal, Space } from "antd";
import { DarkModeBg } from "dark-mode-bg";
import React from "react";

export const CollectionAddForm = ({
  visible,
  onChangeVisibility,
  onCreated
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated: () => any;
}) => {
  const [form] = Form.useForm();

  const { doAction: onSubmit, isProcessing, response } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.collections
          .create({
            ...values
          })
          .then(res => {
            onCreated();
            onChangeVisibility(false);
            form.resetFields();
          }),
      successMessage: "Collection has been created",
      failureMessage: "Something went wrong"
    }),
    [form, onChangeVisibility, onCreated]
  );

  return (
    (<Modal
      title={
        <div className="font-bold text-xl">
          <Space>
            <i className="ri-folder-add-line"></i> Create New Collection
          </Space>
        </div>
      }
      open={visible}
      onOk={async () => {
        try {
          await form.validateFields();
          onSubmit(form.getFieldsValue());
        } catch (e) {
          message.error("Please check your input");
        }
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Create"
      data-click-context="Create New Collection Modal"
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{}}
      >
        <Form.Item
          name="label"
          label=""
          rules={[
            {
              required: true,
              message: "Please enter a label"
            }
          ]}
        >
          <Input placeholder="Label" size="large" />
        </Form.Item>
        <Form.Item name="description" label="">
          <Input.TextArea placeholder="description" />
        </Form.Item>
      </Form>
      <DarkModeBg />
    </Modal>)
  );
};
