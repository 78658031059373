import { SDK } from "@sdk";
import { iArticleCollection } from "@sdk/knowledge-base/knowledge-base.models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { ccArticleCollectionDefaultQueryConfig } from "./cc-article-collection-default-query";
import { CCArticleCollectionsSlice } from "./cc-article-collection.slice";

export const loadAllCCArticleCollections = (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadCCArticleCollectionsQuery(ccArticleCollectionDefaultQueryConfig, "all")(
    store,
    forceReload
  );

export const {
  loadEntityQueries: loadCCArticleCollectionsQuery,
  loadEntityById: loadCCArticleCollectionById,
  loadEntitiesByIds: bulkCCLoadArticleCollectionsByIds
} = CreateHelpersForEntity<iStore, iArticleCollection>({
  sliceName: "ccArticleCollections",
  slice: CCArticleCollectionsSlice,
  isPaginatedQueries: true,
  loadEntityQueryProvider: query => SDK.ccCollections.query(query),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.ccCollections.getById(entityId),
  loadEntityByIdsProvider: (articleCollectionIds: string[]) =>
    (async () => {
      const articleCollections = await Promise.all(
        articleCollectionIds.map(articleCollectionId =>
          SDK.ccCollections.getById(articleCollectionId)
        )
      );
      return articleCollections;
    })()
});
