export const SampleTestimonials: {
  name: string;
  title: string;
  avatar: string;
  content: string;
}[] = [
  {
    name: "John Bailey",
    title: "Exploore Tours",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/testimonialto.appspot.com/o/testimonials%2F71158b41-2616-44a7-a66a-82bc01e63085%2Favatar?alt=media&token=9493f044-c21b-4dbd-ad7a-1e74697c1937",
    content:
      "<p>I absolutely adore CC because it saves us so much time; for example the unified inbox solves the time lost between switching between different platforms and the customizable pre-chat forms saves time for us and the customers by clearing out generic questions that they would be having.<br></p>"
  },
  {
    name: "Thomas",
    title: "Team Lead - Tripola",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/testimonialto.appspot.com/o/testimonials%2Fb621b799-d993-402b-ad3b-7e2fa0bdd596%2Favatar?alt=media&token=f80b5dfb-4856-4351-97e2-9fec5ab18024",
    content:
      "<p>Being able to pick up and continue conversations with clients and leads via SMS through Click connector is a massive win in my book and given that the perfect experience is offered through the Unibox, it just sings quality to me!</p>"
  },
  {
    name: "Aria Thomas - Exploore Tours",
    title: "Sales - Exploore Tours",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/testimonialto.appspot.com/o/testimonials%2Fff8b4122-aaba-40cc-9725-a123b2b92d26%2Favatar?alt=media&token=a6af47a8-90bc-4051-9932-9c29a0e92f2a",
    content:
      "<p>Click Connector helps me out a lot whilst being in and out of the seat. it helps me schedule messages for later so that I can stick to follow ups easily and also when I'm unavailable or engaged I'm able to change my status to busy where leads will be directed to other available agents. Smooth sailing with click connector!</p>"
  },
  {
    name: "Rachel",
    title: "Marketing - Exploore",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/testimonialto.appspot.com/o/testimonials%2F40695f99-b93f-43fd-956f-950bb5a8d7f6%2Favatar?alt=media&token=68efdf58-4d10-4465-bbd8-17424578fa8a",
    content:
      "<p>The reports generated are super neat and very understandable and it saves so much time for the management and presents just the proper insight through analytics. Also the Unibox, the numbers presented, the replied answers, Snooze, star markers are added advantages to categorize the emails.</p><p>We can also check out the most frequently asked topics during the week so that any structural steps can be taken towards it if required.</p>"
  },
  {
    name: "Blake",
    title: "Customer Support - Luxury Cruises",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/testimonialto.appspot.com/o/testimonials%2F5bfc7626-b161-4db0-84bd-2d5e4a8d582e%2Favatar?alt=media&token=afbb2dea-d348-4df2-9e2d-f85735d6da9d",
    content:
      "<p>Real deal, customers are able to access the FAQs and pre programmed chats which help them with their queries instantly and also helps us invest our time and effort productively in other prominent conversations! Absolutely amazing stuff!</p>"
  },
  {
    name: "Andrew",
    title: "Sales - Holiday House",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/testimonialto.appspot.com/o/testimonials%2F5c0d35b5-a4ee-4137-b7db-493846e660dd%2Favatar?alt=media&token=80328360-3500-4b20-b0d2-5133df44b166",
    content:
      "<p>The fact that I can view all the details about my client on the same screen as my conversation sealed the deal for me! It allows me to stay updated with the information and also the note function helps me take crucial points down as well. Superb stuff!</p>"
  }
];
