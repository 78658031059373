import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDK } from "@sdk";
import { iAutomationContact } from "@sdk/automation-workflows/automation-workflows-model";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Dropdown, List, Menu, Space, Tag, Tooltip } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { ChatListItem } from "components/modules/conversations/components/chat-list/chat-list-item";
import { ActivitiesViewer } from "components/modules/crm/activities/activities-viewer/activities-viewer";
import { ContactSelectorPill } from "components/modules/crm/contacts/components/contact-selector-pill/contact-selector-pill";
import { EditContactSection } from "components/modules/crm/contacts/components/edit-contact-mini/edit-contact-mini";
import dayjs from "dayjs";

import { UserPickerModal } from "components/modules/user-management/users/components/user-picker-modal/user-picker-modal";
import { UserPill } from "components/modules/user-management/users/components/user-pill/user-pill";
import { CompanyCard } from "components/pages/companies/components/company-card/company-card";
import { OpportunityCard } from "components/pages/opportunities/components/opportunity-card";
import { PipelineCard } from "components/pages/pipelines/components/pipeline-card";
import { push } from "connected-react-router";
import _ from "lodash";
import {
  AddActivity,
  CompanyPicker,
  ContactProfile,
  ConversationViewer,
  CreateOpportunityModal,
  CreatePipelineCardModal,
  NewMessageCreator,
} from "modal-registry";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { loadAllActivitiesOfContact } from "store/modules/activities/activities.helpers";
import { selectActivityContactId } from "store/modules/activities/activities.selectors";
import { loadAllAutomationWorkflows } from "store/modules/automation-workflows/automation-workflows.helpers";
import {
  selectAllAutomationWorkflows,
  selectAutomationWorkflowById,
} from "store/modules/automation-workflows/automation-workflows.selectors";
import { loadCompanyById } from "store/modules/companies/companies.helpers";
import { selectCompanyById } from "store/modules/companies/companies.selectors";
import {
  loadContactById,
  loadContactsByCompanyId,
} from "store/modules/contacts/contacts.helpers";
import {
  selectContactById,
  selectContactsByCompanyId,
} from "store/modules/contacts/contacts.selectors";
import { setConversations } from "store/modules/conversations/conversations.slice";
import { loadContactOpportunities } from "store/modules/opportunities/opportunities.helpers";
import { selectOpportunitiesByContactId } from "store/modules/opportunities/opportunities.selectors";
import { loadCardsByContactId } from "store/modules/pipeline-cards/pipeline-cards.helpers";
import { selectCardsByContactId } from "store/modules/pipeline-cards/pipeline-cards.selectors";
import { useQueryWithStore } from "store/store.hooks";
import "./contact-profile.scss";

export const ContactProfileV2Inner = ({
  contactId,
  hideHeader,
  onDeleteContact,
}: {
  contactId: string;
  hideHeader?: boolean;
  onDeleteContact?: () => any;
}) => {
  const { state: contact } = useQueryWithStore(
    selectContactById(contactId),
    loadContactById(contactId!)(),
    [contactId],
    !contactId,
  );

  const { state: company } = useQueryWithStore(
    selectCompanyById(contact?.companyId!),
    loadCompanyById(contact?.companyId!)(),
    [contact?.companyId],
    !contact?.companyId,
  );

  const dispatch = useDispatch();

  const conversationSearchResult = useSDK(
    async (SDK) => {
      const results = await SDK.queryConversations({
        query: { contactId },
        options: {},
      });
      dispatch(setConversations(results.docs));
      return results;
    },
    [contactId],
    !contactId,
    {
      data: {
        docs: [],
      },
      isLoading: false,
      error: false,
    },
  );

  const { state: contactActivities, isLoading: isActivitiesLoading } =
    useQueryWithStore(
      selectActivityContactId(contactId!),
      loadAllActivitiesOfContact(contactId!),
      [contactId],
      !contactId,
    );

  const onSelectConversation = (conversationId: string) => {
    changePanelState(ConversationViewer, true, { conversationId });
    // dispatch(push(`/conversations/auto/${conversationId}`));
  };

  const { doAction: editContact, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (edits) => SDK.editContact(contactId, edits),
      // successMessage: "Done",
      failureMessage: "Something went wrong",
      actionDependencies: [contactId],
    }),
    [contactId],
  );

  const {
    doAction: editForeignContact,
    isProcessing: isProcessingEditForeignContact,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (contactId, edits) => SDK.editContact(contactId, edits),
      // successMessage: "Done",
      failureMessage: "Something went wrong",
      actionDependencies: [],
    }),
    [],
  );

  const { doAction: addContactToAutomation } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (workflowId: string) =>
        SDK.automationWorkflows.addContactToAutomation(workflowId, {
          contactIds: [contactId],
        }),
      successMessage: "Contact has been added to workflow",
      failureMessage: "Something went wrong",
      actionDependencies: [contactId],
    }),
    [contactId],
  );

  const removeFromCompany = useCallback(
    () => editContact({ companyId: null }),
    [editContact],
  );

  const addToCompany = useCallback(
    (companyId: string) => editContact({ companyId: companyId }),
    [editContact],
  );

  const [contactOwnerPickerModalState, setContactOwnerPickerModalState] =
    useState(false);

  const { changePanelState, triggerTempModal } = useModalPanels();

  const {
    state: { list: opportunitiesList },
    isLoading: isRecentOpportunityLoading,
    retry: reloadRecentOpportunities,
  } = useQueryWithStore(
    selectOpportunitiesByContactId(contactId!),
    loadContactOpportunities(contactId),
    [contactId],
    !contactId,
  );

  const { state: contactCards, isLoading: isCardsLoading } = useQueryWithStore(
    selectCardsByContactId(contactId!),
    loadCardsByContactId(contactId!),
    [contactId],
    !contactId,
  );

  const { state: automationWorkflows, retry: reload } = useQueryWithStore(
    selectAllAutomationWorkflows,
    loadAllAutomationWorkflows(),
  );

  const {
    state: companyContactsResult,
    isLoading: isContactsLoading,
    retry: reloadContacts,
  } = useQueryWithStore(
    selectContactsByCompanyId(contact?.companyId!),
    loadContactsByCompanyId(contact?.companyId!),
    [contact?.companyId],
    !contact?.companyId,
  );

  const otherContacts = useMemo(
    () =>
      _.filter(
        companyContactsResult?.list || [],
        (item) => item.id !== contactId,
      ),
    [companyContactsResult?.list, contactId],
  );

  const workflowInstancesQuery = useMemo(() => {
    return {
      contactId,
    };
  }, [contactId]);

  const {
    data: workflowInstances,
    isLoading: isWorkflowInstancesLoading,
    reload: reloadWorkflowInstances,
  } = useSDK(
    (SDK) =>
      SDK.automationWorkflows.queryAutomationWorkflowContacts(
        workflowInstancesQuery,
        {},
      ),
    [workflowInstancesQuery],
    !contactId,
  );

  const store = useStore();

  if (!contact) {
    return <></>;
  }
  return (
    <>
      <div className="flex flex-col w-full contact-profile bg-gray p-4">
        <div className="body flex flex-row flex-wrap justify-start">
          <div className="left-col">
            <div
              className="bg-white dark:bg-gray-900  rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex py-8"
              style={{ width: 320 }}
            >
              <EditContactSection
                contactId={contactId}
                hideHeader={hideHeader}
                disableContactViewer={true}
                onDeleteContact={onDeleteContact}
              />
            </div>
          </div>
          <div className="right-section flex-1">
            {/* Top Bar | Action Bar */}
            <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-row flex px-10 py-2 max-w-screen-xl flex-1 ml-4 justify-between items-center">
              <div className="left">
                <div className="text text-gray-600 dark:text-gray-400 mode_transition">
                  Record created on{" "}
                  {dayjs(contact.metaData.createdTime).format("LL")}
                  {/* "DD-MM-YYYY" */}
                </div>
              </div>
              <Space>
                {contact?.owner && (
                  <div className="flex flex-row justify-center items-center">
                    <div className="text-xs">Contact Owner</div>
                    <UserPill userId={contact.owner} />
                  </div>
                )}
                {!contact?.owner && (
                  <div className="flex flex-row justify-center items-center">
                    <Button
                      type="text"
                      icon={<i className="ri-user-star-line"></i>}
                      onClick={() => setContactOwnerPickerModalState(true)}
                    >
                      Assign Owner
                    </Button>
                  </div>
                )}
              </Space>
            </div>
            {/*  Summary Card */}
            <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-row flex justify-between items-center px-10 py-2 max-w-screen-xl flex-1 ml-4 mt-4">
              <div className="flex flex-row justify-around items-center w-full">
                <div
                  className="item flex flex-col items-center justify-center rounded-md p-2 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
                  style={{ width: 120 }}
                >
                  <div className="font-bold text-lg">
                    {conversationSearchResult?.data?.totalDocs || 0}
                  </div>
                  <div className="text text-gray-600 dark:text-gray-400 mode_transition">
                    Conversations
                  </div>
                </div>
                <div
                  className="item flex flex-col items-center justify-center rounded-md p-2 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
                  onClick={() => {}}
                  style={{ width: 120 }}
                >
                  <div className="font-bold text-lg">
                    {isActivitiesLoading && (
                      <div className="w-full flex flex-col justify-center items-center p-20">
                        <i className="ri-loader-5-line animate-spin text-2xl"></i>
                      </div>
                    )}
                    {!isActivitiesLoading && contactActivities?.list?.length}
                  </div>
                  <div className="text text-gray-600 dark:text-gray-400 mode_transition">
                    Activities
                  </div>
                </div>

                <div
                  className="item flex flex-col items-center justify-center rounded-md p-2 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
                  style={{ width: 120 }}
                >
                  <div className="font-bold text-lg">1</div>
                  <div className="text text-gray-600 dark:text-gray-400 mode_transition">
                    Deals
                  </div>
                </div>
              </div>
            </div>

            {/* Recent Conversations */}
            <div
              className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4"
              style={{ minHeight: 200 }}
            >
              <div className="card-header flex flex-row justify-between items-center">
                <div className="text-lg font-bold py-4">
                  Recent Conversations
                </div>
                <Button
                  type="text"
                  icon={<i className="ri-add-line"></i>}
                  onClick={() => {
                    changePanelState(NewMessageCreator, true, {
                      contactId,
                    });
                  }}
                >
                  Start new conversation
                </Button>
              </div>

              <div className="conversation-list w-full">
                <List
                  itemLayout="horizontal"
                  dataSource={conversationSearchResult.data.docs || []}
                  loading={
                    conversationSearchResult.isLoading
                      ? {
                          indicator: <LoadingIndicatorWithoutSpin />,
                        }
                      : false
                  }
                  renderItem={(item) => (
                    <div className="w-full border border-gray-200 dark:border-gray-800">
                      <ChatListItem
                        onConversationSelected={onSelectConversation}
                        conversationId={item.id!}
                      />
                    </div>
                  )}
                  locale={{
                    emptyText: (
                      <EmptyData
                        text="No conversations to show"
                        icon="ri-chat-4-line"
                      />
                    ),
                  }}
                />
              </div>
            </div>
            {/* Recent Sequence Instance */}
            <div
              className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4"
              style={{ minHeight: 200 }}
            >
              <div className="card-header flex flex-row justify-between items-center">
                <div className="text-lg font-bold py-4">
                  Sequences & Workflows
                </div>
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu data-click-context="Contact Add to Automation Menu">
                      {automationWorkflows.map((workflow) => (
                        <Menu.Item
                          key={workflow.id}
                          onClick={() => {
                            addContactToAutomation(workflow.id).then((d) =>
                              reloadWorkflowInstances(),
                            );
                          }}
                        >
                          {workflow.label}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Button type="text" icon={<i className="ri-add-line"></i>}>
                    Add to Sequence
                  </Button>
                </Dropdown>
              </div>
              <div className="w-full">
                <List
                  itemLayout="horizontal"
                  dataSource={workflowInstances?.docs || []}
                  loading={
                    isWorkflowInstancesLoading
                      ? {
                          indicator: <LoadingIndicatorWithoutSpin />,
                        }
                      : false
                  }
                  renderItem={(item) => (
                    <ContactWorkflowInstanceItem
                      automationContactInstance={item}
                      reloadWorkflowInstance={reloadWorkflowInstances}
                    />
                  )}
                  locale={{
                    emptyText: (
                      <EmptyData
                        text="Contact is not added to any workflows"
                        icon="ri-search-line"
                      />
                    ),
                  }}
                />
              </div>
            </div>

            {/* Linked Pipelines */}
            <div
              className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4"
              style={{ minHeight: 200 }}
            >
              <div className="card-header flex flex-row justify-between items-center">
                <div className="text-lg font-bold py-4">Linked Cards</div>
                <Button
                  type="text"
                  icon={<i className="ri-add-line"></i>}
                  onClick={() => {
                    triggerTempModal(CreatePipelineCardModal, {
                      initialData: {
                        references: {
                          contactId: contactId,
                        },
                      },
                    });
                  }}
                >
                  Add to Pipeline
                </Button>
              </div>
              <div className="w-full">
                <List
                  itemLayout="horizontal"
                  dataSource={contactCards?.list || []}
                  loading={
                    isCardsLoading
                      ? {
                          indicator: <LoadingIndicatorWithoutSpin />,
                        }
                      : false
                  }
                  renderItem={(item) => (
                    <div className="hover:bg-blue-100 dark:hover:bg-gray-800 cursor-pointer rounded-lg border-1 border-gray-200">
                      <PipelineCard
                        pipelineCardId={item.id!}
                        onClick={(cardId) => {
                          dispatch(
                            push(
                              `/pipelines?pipeline=${item.pipelineId}&card=${cardId}`,
                            ),
                          );
                        }}
                      />
                    </div>
                  )}
                  locale={{
                    emptyText: (
                      <EmptyData
                        text="No cards to show"
                        icon="ri-search-line"
                      />
                    ),
                  }}
                />
              </div>
            </div>

            {/* Company Profile */}
            <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4">
              <div className="card-header flex flex-row justify-between items-center">
                <div className="text-lg font-bold py-4">Company</div>
                {!contact.companyId && (
                  <>
                    <Button
                      type="text"
                      icon={<i className="ri-add-line"></i>}
                      onClick={() => {
                        triggerTempModal(CompanyPicker, {
                          onCompanySelected: addToCompany,
                          hideOnSelected: true,
                        });
                      }}
                    >
                      Link a company
                    </Button>
                  </>
                )}
                {contact.companyId && (
                  <>
                    <Dropdown
                      trigger={["click"]}
                      overlay={
                        <Menu data-click-context="Contact Company Menu">
                          <Menu.Item
                            key="DEACTIVATE"
                            icon={<i className="ri-edit-line"></i>}
                            onClick={() => {
                              triggerTempModal(CompanyPicker, {
                                onCompanySelected: addToCompany,
                                hideOnSelected: true,
                              });
                            }}
                          >
                            Link a different company
                          </Menu.Item>
                          <Menu.Item
                            key="DEACTIVATE"
                            icon={<i className="ri-delete-bin-line"></i>}
                            onClick={() => {
                              removeFromCompany();
                            }}
                          >
                            Delete contact from company
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Button
                        type="text"
                        icon={<i className="ri-more-2-line"></i>}
                      ></Button>
                    </Dropdown>
                  </>
                )}
              </div>
              {contact.companyId && (
                <>
                  <div className="header">
                    <CompanyCard companyId={contact.companyId} />
                  </div>
                  <div className="py-8 text-center">Contacts in company</div>
                  <div className="w-full">
                    <List
                      itemLayout="horizontal"
                      dataSource={companyContactsResult.list || []}
                      loading={
                        isContactsLoading
                          ? {
                              indicator: <LoadingIndicatorWithoutSpin />,
                            }
                          : false
                      }
                      renderItem={(item) => (
                        <div
                          className="w-full border-b border-gray-200 dark:border-gray-800 p-4 cursor-pointer hover:bg-gray-200 dark:bg-gray-700 flex flex-row justify-between"
                          onClick={() => {
                            changePanelState(ContactProfile, true, {
                              contactId: item.id!,
                            });
                          }}
                        >
                          <ContactSelectorPill contactId={item.id!} />
                          <Button
                            type="text"
                            icon={<i className="ri-delete-bin-line"></i>}
                            onClick={(e) => {
                              editForeignContact(item.id, {
                                companyId: null,
                              });
                              e.stopPropagation();
                            }}
                            loading={isProcessingEditForeignContact}
                          ></Button>
                        </div>
                      )}
                      locale={{
                        emptyText: (
                          <EmptyData
                            text="No contacts to show"
                            icon="ri-user-line"
                          />
                        ),
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            {/* Recent Opportunities / Deals */}
            <div
              className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4"
              style={{ minHeight: 200 }}
            >
              <div className="card-header flex flex-row justify-between items-center">
                <div className="text-lg font-bold py-4">
                  Recent Opportunities
                </div>
                <Button
                  type="text"
                  icon={<i className="ri-add-line"></i>}
                  onClick={() => {
                    changePanelState(CreateOpportunityModal, true, {
                      initialData: {
                        contactId: contactId,
                      },
                    });
                  }}
                >
                  Add an Opportunity
                </Button>
              </div>
              <div className="w-full">
                <List
                  itemLayout="horizontal"
                  dataSource={opportunitiesList || []}
                  loading={
                    isRecentOpportunityLoading
                      ? {
                          indicator: <LoadingIndicatorWithoutSpin />,
                        }
                      : false
                  }
                  renderItem={(item) => (
                    <div className="hover:bg-blue-100 dark:hover:bg-gray-800 cursor-pointer rounded-lg border-1 border-gray-200">
                      <OpportunityCard
                        opportunityId={item.id!}
                        onClick={(opportunityId) => {
                          dispatch(
                            push(`/opportunities?opportunity=${opportunityId}`),
                          );
                        }}
                      />
                    </div>
                  )}
                  locale={{
                    emptyText: (
                      <EmptyData
                        text="No opportunities to show"
                        icon="ri-search-line"
                      />
                    ),
                  }}
                />
              </div>
            </div>

            {/* Recent Activities */}
            <div
              className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4"
              style={{ minHeight: 300 }}
            >
              <div className="card-header flex flex-row justify-between items-center">
                <div className="text-lg font-bold py-4">Recent Activities</div>
                <Button
                  type="text"
                  icon={<i className="ri-add-line"></i>}
                  onClick={() => {
                    changePanelState(AddActivity, true, {
                      contactId,
                    });
                  }}
                >
                  Add new activity
                </Button>
              </div>
              <div className="w-full mt-8">
                <ActivitiesViewer contactId={contactId} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserPickerModal
        visible={contactOwnerPickerModalState}
        onChangeVisibility={setContactOwnerPickerModalState}
        onUserSelected={async (userId) => {
          console.log("user", userId);
          editContact({
            owner: userId,
          });
        }}
        title={
          <ModalTitle
            title="Pick a user to assign"
            icon={<i className="ri-account-circle-line"></i>}
          />
        }
      />
    </>
  );
};

export const ContactWorkflowInstanceItem = ({
  automationContactInstance,
  reloadWorkflowInstance,
}: {
  automationContactInstance: iAutomationContact;
  reloadWorkflowInstance: () => any;
}) => {
  const workflowConfig = useSelector(
    selectAutomationWorkflowById(
      automationContactInstance.automationWorkflowId,
    ),
  );

  const { doAction: stopAutomationInstance, isProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.automationWorkflows
            .stopAutomationInstance(
              automationContactInstance?.automationWorkflowId,
              automationContactInstance?.id!,
            )
            .then((d) => reloadWorkflowInstance),
        successMessage: "Contact has been removed to workflow",
        failureMessage: "Something went wrong",
        actionDependencies: [automationContactInstance?.id],
      }),
      [
        automationContactInstance?.automationWorkflowId,
        automationContactInstance?.id,
        reloadWorkflowInstance,
      ],
    );

  return (
    // <div className="cursor-pointer rounded-lg border-1 border-gray-200">
    <div className="flex flex-row justify-between items-center hover:bg-blue-100 dark:hover:bg-gray-800 p-2 group">
      <div className="Label">
        {workflowConfig?.label}{" "}
        {automationContactInstance.isRemoved && <Tag>Stopped</Tag>}
      </div>
      <div className="flex flex-row gap-4 items-center">
        <div className="text-gray-600">{`Step ${
          (workflowConfig?.steps || []).findIndex(
            (item) => item.id === automationContactInstance.currentStep,
          ) + 1
        }`}</div>
        {automationContactInstance.isRemoved && (
          <div>
            {" "}
            <Button
              icon={<i className="ri-delete-back-2-line"></i>}
              type="text"
              className="opacity-0"
            />
          </div>
        )}
        {!automationContactInstance.isRemoved && (
          <Tooltip title="Remove contact from this workflow">
            <Button
              icon={<i className="ri-delete-back-2-line"></i>}
              type="text"
              className="item-visible-on-hover"
              loading={isProcessing}
              onClick={() => {
                stopAutomationInstance();
              }}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};
