export const DynamicFormsIndustries = [
  {
    label: "Healthcare"
  },
  {
    label: "Real Estate"
  },
  {
    label: "Retail"
  },
  {
    label: "Salon"
  },
  {
    label: "Marketing"
  },
  {
    label: "Web & Software Development"
  },
  {
    label: "Banking"
  },
  {
    label: "Insurance"
  },
  {
    label: "Logistic"
  },
  {
    label: "E-commerce / Online Store"
  },
  {
    label: "Recruitment"
  },
  {
    label: "Education"
  },
  {
    label: "Financial Services"
  },
  {
    label: "Travel"
  },
  {
    label: "Automobile"
  },
  {
    label: "Telecommunication"
  },
  {
    label: "SAAS Platforms"
  },
  {
    label: "Restaurant"
  },
  {
    label: "GYM"
  },
  {
    label: "B2B"
  },
  {
    label: "Event"
  },
  {
    label: "Legal"
  },
  {
    label: "NGO"
  }
];
