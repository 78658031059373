import { DatePicker, Divider } from "antd";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import dayjs from "dayjs";

import { MyActivityReport } from "components/modules/user-management/users/components/my-profile/components/my-activity-report";
import { MyActivityToday } from "components/modules/user-management/users/components/my-profile/components/my-activity-today";
import { SingleUserSelector } from "components/modules/user-management/users/components/single-user-selector/single-user-selector";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectAllUsers } from "store/modules/users/users.selectors";

export const UserActivityReport = () => {
  const [activityDate, setActivityDate] = useState(dayjs());
  const [activityDateRange, setActivityDateRange] = useState([
    dayjs()
      .subtract(7, "day")
      .startOf("day"),
    dayjs().endOf("day")
  ]);

  const allUsers = useSelector(selectAllUsers);

  const [selectedUser, setSelectedUser] = useState();

  return (
    <StyledScrollArea className="w-full h-full flex-1 animated fadeInLeftMin">
      <SimpleCardSection className="m-auto p-8 lg:p-12 my-4">
        <div className="p-10">
          <SectionHeader
            title={"User Activity Report"}
            icon={<i className="ri-mac-line"></i>}
            addons={
              <SingleUserSelector
                value={selectedUser}
                onChange={setSelectedUser as any}
                placeholder="Filter by user"
              />
            }
          />
          <Divider className="" />
          <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4">
            <div className="card-header flex flex-row justify-between items-center">
              <div className="text-lg font-bold py-4">Daily Activities</div>
              <div className="">
                <DatePicker
                  value={activityDate}
                  onChange={setActivityDate as any}
                  format={"DD/MM/YYYY"}
                  bordered={false}
                  allowClear={false}
                />
              </div>
            </div>
            <div className="w-full my-4">
              <MyActivityToday date={activityDate} userId={selectedUser} />
            </div>
          </div>

          <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4">
            <div className="card-header flex flex-row justify-between items-center">
              <div className="text-lg font-bold py-4">
                Daily Activity Summaries
              </div>
              <div className="">
                <DatePicker.RangePicker
                  value={activityDateRange as any}
                  onChange={setActivityDateRange as any}
                  format={"DD/MM/YYYY"}
                  bordered={false}
                  allowClear={false}
                />
              </div>
            </div>
            <div className="w-full my-4">
              <MyActivityReport
                dateRange={activityDateRange as any}
                userId={selectedUser}
              />
            </div>
          </div>
        </div>
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
