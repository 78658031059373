import { SDK } from "@sdk";
import { iScheduledReport } from "@sdk/scheduled-reports/scheduled-reports";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { scheduledReportsDefaultQueryConfig } from "./scheduled-reports-default-query";
import { ScheduledReportsSlice } from "./scheduled-reports.slice";

// Todo: only 100 scheduled messages are loaded. Ideally all scheduled messages should be loaded per conversation
export const loadAllScheduledReports = (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadScheduledReportsQuery(scheduledReportsDefaultQueryConfig, "all")(
    store,
    forceReload
  );

export const {
  loadEntityQueries: loadScheduledReportsQuery,
  loadEntityById: loadScheduledReportById,
  loadEntitiesByIds: bulkLoadScheduledReportsByIds
} = CreateHelpersForEntity<iStore, iScheduledReport>({
  sliceName: "scheduledReports",
  slice: ScheduledReportsSlice,
  isPaginatedQueries: true,
  loadEntityQueryProvider: query =>
    SDK.scheduledReports.query({
      query: query.query,
      options: { ...query.options, limit: 100 }
    }),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.scheduledReports.getById(entityId),
  loadEntityByIdsProvider: (scheduledReportsIds: string[]) =>
    (async () => {
      const ScheduledReports = await Promise.all(
        scheduledReportsIds.map(scheduledReportsId =>
          SDK.scheduledReports.getById(scheduledReportsId)
        )
      );
      return ScheduledReports;
    })()
});
