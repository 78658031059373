import { iPipelineCheckListItem } from "@sdk/user-management/user-management.models";
import { Form, Input, message, Modal, Radio, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { StyledSwitch } from "components/common/styled-swtich";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { URLBuilder } from "../manage-chat-connectors/url-builder";

export const AddEditPipelineStageCheckListItem = ({
  visible,
  onChangeVisibility,
  onSubmit: _onSubmit,
  data
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onSubmit: (data: iPipelineCheckListItem) => any;
  data?: iPipelineCheckListItem;
}) => {
  const [form] = useForm();
  const [isProcessing, setProcessing] = useState(false);

  const onSubmit = useCallback(async () => {
    const formValues = form.getFieldsValue();
    try {
      setProcessing(true);
      const additionalFields = (() => {
        if (formValues.type === "DATA_COLLECTION") {
          return {
            label: FieldIdLabels[formValues?.config?.dataCollectionTarget]
          };
        }
        return {};
      })();
      await _onSubmit({
        ...formValues,
        ...additionalFields
      });
      setProcessing(false);
      onChangeVisibility(false);
      form.resetFields();
    } catch (e) {
      setProcessing(false);
    }
  }, [_onSubmit, form, onChangeVisibility]);

  useEffect(() => {
    form.setFieldsValue({
      ...data
    });
  }, [form, data]);

  const initialValues = useMemo(
    () => (data ? _.cloneDeep(data) : { type: "TASK" }),
    [data]
  );

  return (<>
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-flag-line"></i>}
          title={data ? `Edit Check List Item` : `Add Check List Item`}
        />
      }
      open={visible}
      okButtonProps={{ loading: isProcessing }}
      onOk={async () => {
        try {
          await form.validateFields();
          onSubmit();
        } catch (e) {
          message.error("Please check your input");
        }
      }}
      onCancel={() => {
        onChangeVisibility(false);
        form.resetFields();
      }}
      okText="Save"
      destroyOnClose={true}
      data-click-context="Add Edit Pipeline Stage Check List Modal"
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        className="bold-form-labels"
      >
        <Form.Item
          name="type"
          label="Type"
          rules={[
            { required: true, message: "Please select a check list type" }
          ]}
        >
          <Radio.Group buttonStyle="solid" style={{ width: "100%" }}>
            <Radio.Button
              value="TASK"
              style={{ width: "33.33%", textAlign: "center" }}
            >
              Task
            </Radio.Button>
            <Radio.Button
              value="DATA_COLLECTION"
              style={{ width: "33.33%", textAlign: "center" }}
            >
              Data Collection
            </Radio.Button>
            {/* <Radio.Button
              value="CONDITION"
              style={{ width: "33.33%", textAlign: "center" }}
            >
              Condition
            </Radio.Button> */}
            <Radio.Button
              value="DATA_INTEGRATION"
              style={{ width: "33.33%", textAlign: "center" }}
            >
              Data Integration
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const type = getFieldValue(["type"]);

            if (type === "TASK") {
              return (
                <>
                  <Form.Item
                    name="label"
                    label="Label"
                    rules={[
                      { required: true, message: "Please enter a name" }
                    ]}
                  >
                    <Input
                      prefix={<i className="ri-flag-line"></i>}
                      placeholder="Eg: Do X,Y and Z"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item name="helpText" label="Help Text" rules={[]}>
                    <Input
                      prefix={<i className="ri-flag-line"></i>}
                      placeholder="Eg: Do X,Y and Z"
                      size="large"
                    />
                  </Form.Item>
                </>
              );
            }
            if (type === "DATA_COLLECTION") {
              return (
                <Form.Item
                  name={["config", "dataCollectionTarget"]}
                  label={"Data to Collect"}
                >
                  <Select style={{ minWidth: 250 }} bordered={true}>
                    <Select.OptGroup label="Contact">
                      <Select.Option value="CONTACT_FIRST_NAME">
                        {FieldIdLabels["CONTACT_FIRST_NAME"]}
                      </Select.Option>
                      <Select.Option value="CONTACT_LAST_NAME">
                        {FieldIdLabels["CONTACT_LAST_NAME"]}
                      </Select.Option>
                      <Select.Option value="CONTACT_EMAIL">
                        {FieldIdLabels["CONTACT_EMAIL"]}
                      </Select.Option>
                      <Select.Option value="CONTACT_PHONE">
                        {FieldIdLabels["CONTACT_PHONE"]}
                      </Select.Option>
                    </Select.OptGroup>
                    <Select.OptGroup label="Conversation">
                      <Select.Option value="CONVERSATION_CONNECTION_TYPE">
                        {FieldIdLabels["CONVERSATION_CONNECTION_TYPE"]}
                      </Select.Option>
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              );
            }
            if (type === "DATA_INTEGRATION") {
              return (
                <>
                  <Form.Item
                    name="label"
                    label="Label"
                    rules={[
                      { required: true, message: "Please enter a name" }
                    ]}
                  >
                    <Input
                      prefix={<i className="ri-flag-line"></i>}
                      placeholder="Eg: Do X,Y and Z"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item name="helpText" label="Help Text" rules={[]}>
                    <Input
                      prefix={<i className="ri-flag-line"></i>}
                      placeholder="Eg: Do X,Y and Z"
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    name={["config", "api"]}
                    label={<div className="font-bold">Action API Call</div>}
                  >
                    <URLBuilder />
                  </Form.Item>
                </>
              );
            }
            return <></>;
          }}
        </Form.Item>

        <div className="font-bold">
          <StyledSwitch name={["isRequired"]} label="Is Required?" />
        </div>
      </Form>
      <DarkModeBg />
    </Modal>
  </>);
};

export const FieldIdLabels = {
  CONTACT_FIRST_NAME: "Contact First Name",
  CONTACT_LAST_NAME: "Contact Last Name",
  CONTACT_EMAIL: "Contact Email",
  CONTACT_PHONE: "Contact Phone",
  CONVERSATION_CONNECTION_TYPE: "Connection Type"
};
