import { SDK } from "@sdk";
import { iFbPost } from "@sdk/facebook-posts/facebook-posts.models";
import { iMarketingReference } from "@sdk/marketing-references/marketing-references.models";
import {
  getUserGroups,
  getUserName,
} from "components/modules/user-management/helpers/get-user-name";

import _ from "lodash";
import { Store } from "redux";
import { selectConnectionsMap } from "store/modules/connections/connections.selectors";
import { selectGroupsMap } from "store/modules/groups/groups.selectors";
import {
  selectUsersByUserGroup,
  selectUsersMap,
} from "store/modules/users/users.selectors";
import {
  selectConversationTags,
  selectFirstResponseIntervalConfig,
} from "store/modules/workspace/workspace.selectors";
import { iStore } from "store/store.model";
import { convertMillisecondsToReadableString } from "utils/convert-milliseconds-to-readable-string";
import { durationOptions } from "../helpers/chat-duration-options";
import { ConversationSourceLabelsMap } from "../helpers/conversssation-source-label-maps";
import { generateIntervalOptions } from "../helpers/generate-interval-options";

type iSDK = typeof SDK;
type ChartType = "COLUMN" | "PIE" | "TABLE" | "DIGIT" | "HEAT_MAP";
type ChartSize = "small" | "medium" | "large";

export interface iLegacyReportWidgetConfig {
  title: string;
  description: string;
  chartType: ChartType;
  chartConfig?: any;
  size: ChartSize;
  queryFunction: (query: any, state: any) => any;
  transformFunction: (
    data: any,
    sdk: iSDK,
    store: Store<iStore>,
    query?: any,
  ) => any;
  inspectorFunction?: (filter: any, state?: any) => any;
}

export type iLegacyWidgetTypes =
  | "TOTAL_CONVERSATIONS"
  | "CONVERSATIONS_BY_STATUS"
  | "CONVERSATIONS_BY_TAGS"
  | "CONVERSATIONS_BY_TAGS_GROUP"
  | "CONVERSATIONS_BY_TEAMS"
  | "CONVERSATIONS_HEAT_MAP"
  | "CONVERSATIONS_BY_USERS"
  | "CONVERSATIONS_BY_USERS_GROUPS"
  | "CONVERSATIONS_BY_USERS_TAGS"
  | "CONVERSATIONS_BY_CONNECTIONS"
  // Todo
  | "CONVERSATIONS_BY_SOURCE"
  // Todo
  | "CONVERSATIONS_BY_FACEBOOK_AD"
  // Todo
  | "CONVERSATIONS_BY_FACEBOOK_POST"
  | "CONVERSATIONS_BY_MESSAGE_COUNT"
  // Todo
  | "CONVERSATIONS_TAGS_BY_TAGS_WIDGET"
  // Todo
  | "USER_PERFORMANCE_REPORT"
  | "CONVERSATIONS_BY_PERIOD"
  // Todo
  | "CONVERSATIONS_BY_RESPONSE_TIME_BY_PERIOD"
  // Todo
  | "CONVERSATIONS_BY_DURATION_BY_PERIOD"
  // Todo
  | "CONVERSATIONS_BY_RESPONSE_TIME"
  // Todo
  | "CONVERSATIONS_BY_DURATION"
  | "CONVERSATIONS_BY_CHAT_RATING";

const generateInspectorFunction = (field: string, data?: any) => {
  const generateSubQuery = (existingFilter: any, value: string, storeState) => {
    if (field === "source") {
      const modifiedValue = Object.keys(ConversationSourceLabelsMap).find(
        (source) => ConversationSourceLabelsMap[source] === value,
      );
      if (modifiedValue) {
        value = modifiedValue;
      }
    }

    if (field === "responseTime") {
      const intervalOptions = selectFirstResponseIntervalConfig(storeState);
      const responseTimeFilter = _.find(
        generateIntervalOptions(intervalOptions),
        {
          title: value,
        },
      );
      if (responseTimeFilter) {
        value = JSON.parse(responseTimeFilter.value);
      }
    }

    if (field === "duration") {
      const durationFilter = _.find(durationOptions, {
        title: value,
      });
      if (durationFilter) {
        value = JSON.parse(durationFilter.value);
      }
    }

    if (value === "Empty") {
      return { $in: [null, []] };
    }
    return existingFilter
      ? Array.isArray(existingFilter)
        ? [...existingFilter, value]
        : [existingFilter, value]
      : value;
  };

  return (filter, state) => {
    const innerQuery = filter.innerQuery;
    if (innerQuery) {
      return {
        ..._.omit(filter, "innerQuery"),
        [field]: generateSubQuery(filter[field], innerQuery, state),
      };
    }
    return filter;
  };
};

const generateReportQueryFilter = (query) => {
  const filter = _.omit(query, "groupBy", "period", "dateField");
  for (let field in filter) {
    //* Patch. Need to rewrite this. should take it as a configuration option
    if (filter[field] === "Empty") {
      if (field === "users") {
        filter[field] = [];
      } else {
        filter[field] = { $size: 0 };
      }
    }
  }
  return filter;
};

export const LegacyReportWidgets: {
  [key in iLegacyWidgetTypes]: iLegacyReportWidgetConfig;
} = {
  // Total Conversations
  TOTAL_CONVERSATIONS: {
    title: "Total Conversations",
    description: "Total Conversations in the selected period",
    chartType: "DIGIT",
    size: "small",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      dateField: query.dateField,
    }),
    transformFunction: (data) => data.count,
  },
  // Conversations over time
  CONVERSATIONS_BY_PERIOD: {
    title: "Conversations by day",
    description: "Total Conversations in the selected period",
    chartType: "COLUMN",
    size: "large",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      period: "DAY",
      groupBy: "status",
      dateField: query.dateField,
    }),
    chartConfig: {
      series: ["status"],
    },
    transformFunction: (data) =>
      _.orderBy(data, ["timestamp"], ["asc"]).map((e) => ({
        name: e.date,
        y: e.count,
        status: e.status,
      })),
  },
  // Conversations By status
  CONVERSATIONS_BY_STATUS: {
    title: "Conversations by Status",
    description: "Total Conversations in the selected period by status",
    chartType: "PIE",
    size: "small",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "status",
      period: undefined,
      dateField: query.dateField,
    }),
    // groupFilter:''
    transformFunction: (data) =>
      data.map((e) => ({
        name: e.status,
        y: e.count,
      })),
    inspectorFunction: generateInspectorFunction("status"),
  },
  // Conversations By Tags
  CONVERSATIONS_BY_TAGS: {
    title: "Conversations by Tags",
    description: "Total Conversations in the selected period by tags",
    chartType: "PIE",
    size: "small",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "tags",
      period: undefined,
      dateField: query.dateField,
    }),
    // groupFilter:''
    transformFunction: (data) =>
      data.map((e) => ({
        name: e.tags,
        y: e.count,
      })),
    inspectorFunction: generateInspectorFunction("tags"),
  },
  // Conversations By Tags
  CONVERSATIONS_BY_TAGS_GROUP: {
    title: "By Tags",
    description: "Total Conversations in the selected period by tags",
    chartType: "PIE",
    size: "small",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "tags",
      period: undefined,
      dateField: query.dateField,
    }),
    // groupFilter:''
    transformFunction: (data) =>
      data.map((e) => ({
        name: e.tags,
        y: e.count,
      })),
    inspectorFunction: generateInspectorFunction("tags"),
  },
  // Conversations By Teams
  CONVERSATIONS_BY_TEAMS: {
    title: "Conversations by Team",
    description: "Total Conversations in the selected period by teams",
    chartType: "PIE",
    size: "small",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "teams",
      period: undefined,
      dateField: query.dateField,
    }),
    transformFunction: (data) =>
      data.map((e) => ({
        name: e.teams,
        y: e.count,
      })),
    inspectorFunction: generateInspectorFunction("teams"),
  },
  // Conversations By Heat Map
  CONVERSATIONS_HEAT_MAP: {
    title: "Conversation Flow Heat Map",
    description: "Total Conversations in the selected period",
    chartType: "HEAT_MAP",
    size: "large",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: undefined,
      period: "HEAT_MAP",
      dateField: query.dateField,
    }),
    transformFunction: (data) => data,
    inspectorFunction: generateInspectorFunction("timeOfDay"),
  },
  // Conversations By Users
  CONVERSATIONS_BY_USERS: {
    title: "Conversations by Users",
    description: "Total Conversations in the selected period",
    chartType: "TABLE",
    size: "large",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "users",
      dateField: query.dateField,
    }),
    transformFunction: async (data, sdk, store, query) => {
      const routingLogReport: {
        count: number;
        users: string;
        isAccepted: boolean | "Empty";
      }[] = await sdk.getRoutingLogsReports({
        startDate: query.period && query.period[0].valueOf(),
        endDate: query.period && query.period[1].valueOf(),
        filters: {},
        groupBy: "users,isAccepted",
      });
      const usersMap = selectUsersMap(store.getState());

      return data.map((e) => ({
        id: e.users,
        name: getUserName(usersMap[e.users]) || e.users,
        count: e.count,
        "Total Invites": _.sumBy(
          _.filter(routingLogReport, {
            users: e.users,
          }),
          "count",
        ),
        "Accepted Invites":
          _.find(routingLogReport, {
            users: e.users,
            isAccepted: true,
          })?.count || 0,
      }));
    },
    inspectorFunction: generateInspectorFunction("users"),
  },
  // Conversations By User GROUPS
  CONVERSATIONS_BY_USERS_GROUPS: {
    title: "Conversations by User Group",
    description: "Total Conversations in the selected period",
    chartType: "PIE",
    size: "medium",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "users",
      dateField: query.dateField,
    }),
    transformFunction: (data, sdk, store) => {
      const userGroupsMap = selectGroupsMap(store.getState());

      const byUserGroup = selectUsersByUserGroup(store.getState());

      const reportByUser = _.keyBy(data, "users");

      const userGroupReport = Object.keys(byUserGroup).map((userGroupId) => ({
        id: userGroupId,
        name: userGroupsMap[userGroupId]?.label,
        y: (() => {
          return _.sum(
            byUserGroup[userGroupId].map(
              (user) => reportByUser?.[user.id]?.count || 0,
            ),
          );
        })(),
      }));
      return userGroupReport;
    },
    inspectorFunction: (filter, state) => {
      const newFilter = generateInspectorFunction("userGroup")(filter, state);
      const userGroup = newFilter.userGroup;
      const byUserGroup = selectUsersByUserGroup(state);
      delete newFilter.userGroup;
      newFilter.usersIn = byUserGroup[userGroup].map((user) => user.id);
      return newFilter;
    },
  },
  // Conversations By Users , Tags
  CONVERSATIONS_BY_USERS_TAGS: {
    title: "Conversations by Users and Tags",
    description: "Total Conversations in the selected period",
    chartType: "TABLE",
    chartConfig: {
      transformData: true,
      primaryField: "name",
      secondaryField: "tag",
      valueField: "count",
      fixedLeft: ["name"],
      fixedRight: [],
      customColumnWidths: {
        name: 300,
      },
    },
    size: "large",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "users,tags",
      dateField: query.dateField,
    }),
    transformFunction: (data, sdk, store) => {
      const usersMap = selectUsersMap(store.getState());
      const tags = selectConversationTags(store.getState());
      const getTagType = (tag: string) => {
        const tagType = tags?.find((group) => group.label === tag);
        return tagType?.tagGroup || "General";
      };

      return data.map((e) => ({
        id: e.users,
        name: getUserName(usersMap[e.users]) || e.users,
        groups: getUserGroups(usersMap[e.users]) || "",
        tagType: getTagType(e.tags),
        tag: e.tags,
        count: e.count,
      }));
    },
    inspectorFunction: generateInspectorFunction("users"),
  },
  // All User Report
  USER_PERFORMANCE_REPORT: {
    title: "User Performance Report - Beta",
    description: "User Performance Report",
    chartType: "TABLE",
    chartConfig: {
      transformData: true,
      primaryField: "name",
      secondaryField: "column",
      valueField: "count",
      fixedLeft: ["name"],
      fixedRight: [],
      customColumnWidths: {
        name: 300,
      },
    },
    size: "large",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "users,tags",
      dateField: query.dateField,
    }),
    transformFunction: async (data, sdk, store, query) => {
      // console.log("USER_PERFORMANCE_REPORT query", query);
      // Todo: Add login time

      const otherReports = [
        // Routing Logs
        sdk.getRoutingLogsReports({
          startDate: query.period && query.period[0].valueOf(),
          endDate: query.period && query.period[1].valueOf(),
          filters: _.omit(query, "period", "groupBy"),
          groupBy: "users,isAccepted",
        }),
        // By Users
        sdk.getConversationReports({
          startDate: query.period && query.period[0].valueOf(),
          endDate: query.period && query.period[1].valueOf(),
          filters: _.omit(query, "period", "groupBy", "dateField"),
          groupBy: "users",
          dateField: query.dateField,
        }),
        // By Users, Source
        sdk.getConversationReports({
          startDate: query.period && query.period[0].valueOf(),
          endDate: query.period && query.period[1].valueOf(),
          filters: _.omit(query, "period", "groupBy", "dateField"),
          groupBy: "users,source",
          dateField: query.dateField,
        }),
        // By Tags, for Sale
        sdk.getConversationReports({
          startDate: query.period && query.period[0].valueOf(),
          endDate: query.period && query.period[1].valueOf(),
          filters: {
            ..._.omit(query, "period", "groupBy", "dateField"),
            tagsIn: ["SALE", "sale"],
          },
          groupBy: "users,tags",
          dateField: query.dateField,
        }),
      ];
      const otherReportResults = await Promise.all(otherReports);

      return {
        routingLogReport: otherReportResults[0],
        userReport: otherReportResults[1],
        usersBySource: otherReportResults[2],
        campaignSaleReport: otherReportResults[3],
        userByTagsReport: data,
      };
    },
    inspectorFunction: generateInspectorFunction("users"),
  },
  // Conversations by Tags x Tags
  CONVERSATIONS_TAGS_BY_TAGS_WIDGET: {
    title: "Conversation Tag Relation Explorer",
    description: "Total Conversations in the selected period",
    chartType: "TABLE",
    chartConfig: {
      transformData: true,
      primaryField: "primaryTag",
      secondaryField: "secondaryTag",
      valueField: "count",
      fixedLeft: ["primaryTag"],
      fixedRight: [],
      customColumnWidths: {
        primaryTag: 300,
      },
    },
    size: "large",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "tagsCombination",
      dateField: query.dateField,
    }),
    transformFunction: (data, sdk, store) => {
      return data;
    },
    inspectorFunction: generateInspectorFunction("tags"),
  },
  // Conversations By Connections
  CONVERSATIONS_BY_CONNECTIONS: {
    title: "Conversations by Connection",
    description: "Total conversations in the selected period by connections",
    chartType: "PIE",
    size: "medium",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "connections",
      dateField: query.dateField,
    }),
    transformFunction: (data, sdk, store) => {
      const connectionsMap = selectConnectionsMap(store.getState());
      return data.map((record) => ({
        id: record.connections,
        name: connectionsMap[record.connections]?.label || "Un labelled",
        y: record.count,
      }));
    },
    inspectorFunction: generateInspectorFunction("connectionId"),
  },
  // Conversations by Message Count
  CONVERSATIONS_BY_MESSAGE_COUNT: {
    title: "By message count",
    description: "Total conversations in the selected period by message count",
    chartType: "PIE",
    size: "medium",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "messageCount",
      dateField: query.dateField,
    }),
    transformFunction: (data, sdk, store) => {
      const grouped = _.groupBy(data, (record) => {
        if (record.messageCount >= 50) {
          return "50+";
        }
        if (record.messageCount >= 20) {
          return "20-50";
        }
        if (record.messageCount < 20) {
          return "0-20";
        }
        return "Unknown";
      });
      return Object.keys(grouped).map((group) => ({
        id: group,
        name: group,
        y: _.sumBy(grouped[group], "count"),
      }));
    },
    inspectorFunction: (filter, state) => {
      const newFilter = generateInspectorFunction("messageCount")(
        filter,
        state,
      );
      if (newFilter.messageCount === "50+") {
        newFilter.messageCount = {
          $gte: 50,
        };
      }
      if (newFilter.messageCount === "20-50") {
        newFilter.messageCount = {
          $gte: 20,
          $lt: 50,
        };
      }
      if (newFilter.messageCount === "0-20") {
        newFilter.messageCount = {
          $lt: 20,
        };
      }
      if (newFilter.messageCount === "Unknown") {
        newFilter.messageCount = {
          $exists: false,
        };
      }
      return newFilter;
    },
  },
  // Conversations By Source
  CONVERSATIONS_BY_SOURCE: {
    title: "Conversations by Source",
    description: "Total conversations in the selected period by source",
    chartType: "PIE",
    size: "medium",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "source",
      dateField: query.dateField,
    }),
    transformFunction: (data, sdk, store) => {
      return data.map((record) => ({
        name: ConversationSourceLabelsMap[record.source] || "Empty",
        y: record.count,
      }));
    },
    inspectorFunction: generateInspectorFunction("source"),
  },
  // Conversations By Facebook Ad
  CONVERSATIONS_BY_FACEBOOK_AD: {
    title: "Conversations by Facebook Ad",
    description: "Total conversations in the selected period by Facebook ads",
    chartType: "TABLE",
    size: "medium",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "fbAdId",
      dateField: query.dateField,
    }),
    transformFunction: (data, sdk, store) => {
      return sdk.marketingReferences
        .query({
          query: {
            "data.fbAdId": { $in: data.map((record) => record.fbAdId) },
          },
          options: {
            limit: 1000,
          },
        })
        .then((marketingRefs) => {
          const marketingRefMap = _.keyBy(
            marketingRefs.docs,
            (item) => item.data.fbAdId,
          ) as Record<string, iMarketingReference>;
          return data.map((record) => ({
            "Facebook Ad": marketingRefMap[record.fbAdId]?.data.label,
            count: record.count,
          }));
        });
    },
    inspectorFunction: generateInspectorFunction("fbAdId"),
  },
  // Conversations By Facebook Post
  CONVERSATIONS_BY_FACEBOOK_POST: {
    title: "Conversations by Facebook Post",
    description: "Total conversations in the selected period by Facebook post",
    chartType: "TABLE",
    size: "medium",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "fbPostId",
      dateField: query.dateField,
    }),
    transformFunction: (data, sdk, store) => {
      return sdk
        .queryFbPosts({
          query: {
            id: { $in: data.map((record) => record.fbPostId) },
          },
          options: {
            limit: 1000,
          },
        })
        .then((fbPosts) => {
          const fbPostsMap = _.keyBy(fbPosts.docs, "id") as Record<
            string,
            iFbPost
          >;
          return data.map((record) => ({
            id: record.fbPostId,
            "Facebook Post":
              fbPostsMap[record.fbPostId]?.message ||
              record.fbPostId ||
              "Empty",
            count: record.count,
          }));
        });
    },
    inspectorFunction: generateInspectorFunction("fbPostId"),
  },
  // Conversations By Response Time by Period
  CONVERSATIONS_BY_RESPONSE_TIME_BY_PERIOD: {
    title: "Average Response Times of Conversations",
    description: "Average Response Times for selected period",
    chartType: "COLUMN",
    size: "large",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      period: "DAY",
      aggregateFunction: "AVERAGE",
      aggregateTargetField: "responseTime",
      dateField: query.dateField,
    }),
    transformFunction: (data) =>
      _.orderBy(data, ["timestamp"], ["asc"]).map((e) => ({
        name: e.date,
        y: e.count,
        yLabel: convertMillisecondsToReadableString(e.count * 1000),
      })),
    chartConfig: {
      highChartsOptions: {
        tooltip: {
          formatter: function () {
            return `${convertMillisecondsToReadableString(
              (this as any).y * 1000,
            )}`;
          },
        },
      },
    },
    inspectorFunction: generateInspectorFunction("responseTime"),
  },
  // Conversations By Duration by Period
  CONVERSATIONS_BY_DURATION_BY_PERIOD: {
    title: "Average Durations of Conversations",
    description: "Average durations for selected period",
    chartType: "COLUMN",
    size: "large",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      period: "DAY",
      aggregateFunction: "AVERAGE",
      aggregateTargetField: "duration",
      dateField: query.dateField,
    }),
    transformFunction: (data) =>
      _.orderBy(data, ["timestamp"], ["asc"]).map((e) => ({
        name: e.date,
        y: e.count,
        yLabel: convertMillisecondsToReadableString(e.count * 1000),
      })),
    chartConfig: {
      highChartsOptions: {
        tooltip: {
          formatter: function () {
            return `${convertMillisecondsToReadableString((this as any).y)}`;
          },
        },
      },
    },
    inspectorFunction: generateInspectorFunction("duration"),
  },
  // Conversation By Response Time
  CONVERSATIONS_BY_RESPONSE_TIME: {
    title: "Conversations by First Response Speed",
    description: "First Response Speed in the selected period by status",
    chartType: "PIE",
    size: "small",
    queryFunction: (query, state) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: {
        ...generateReportQueryFilter(query),
        schemaOptions: {
          groupByOptions: {
            categorizedResponseTime: {
              intervals: selectFirstResponseIntervalConfig(state),
            },
          },
        },
      },
      groupBy: "categorizedResponseTime",
      period: undefined,
      dateField: query.dateField,
    }),
    // groupFilter:''
    transformFunction: (data) =>
      data.map((e) => ({
        name: e.categorizedResponseTime,
        y: e.count,
      })),
    inspectorFunction: generateInspectorFunction("responseTime"),
  },
  // Conversation By Duration
  CONVERSATIONS_BY_DURATION: {
    title: "Conversations by durations",
    description: "Conversation durations in the selected period by status",
    chartType: "PIE",
    size: "small",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "categorizedDuration",
      period: undefined,
      dateField: query.dateField,
    }),
    // groupFilter:''
    transformFunction: (data) =>
      data.map((e) => ({
        name: e.categorizedDuration,
        y: e.count,
      })),
    inspectorFunction: generateInspectorFunction("duration"),
  },
  CONVERSATIONS_BY_CHAT_RATING: {
    title: "Conversations by Chat Ratings",
    description: "Conversation in the selected period by ratings",
    chartType: "PIE",
    size: "small",
    queryFunction: (query) => ({
      startDate: query.period && query.period[0].valueOf(),
      endDate: query.period && query.period[1].valueOf(),
      filters: generateReportQueryFilter(query),
      groupBy: "chatRating",
      period: undefined,
      dateField: query.dateField,
    }),
    // groupFilter:''
    transformFunction: (data) =>
      data.map((e) => ({
        name: e.chatRating,
        y: e.count,
      })),
    inspectorFunction: generateInspectorFunction("chatRating"),
  },
};
