import { Dropdown, Menu, message } from "antd";
import classNames from "classnames";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import {
  Connection,
  Edge,
  Handle,
  Node,
  Position,
  useReactFlow,
} from "react-flow-renderer";
import { uuidv4 } from "utils/generate-uuid";
import { useThrottle } from "utils/hooks/use-throttle";
import {
  AvailableNodes,
  iActionNode,
  iConditionNode,
  iGPTAssistantNode,
  iMessageNode,
} from "../data-model";
import "./chat-flow-handle.scss";

export const ChatFlowHandle = ({
  handleId,
  nodeId,
  type = "OPTION",
  order = 0,
}: {
  handleId: string;
  nodeId: string;
  type?: "OPTION" | "DEFAULT" | "INPUT" | "YES" | "NO";
  order?: number;
}) => {
  const { getNode, setNodes, setEdges, getNodes, getEdges, getEdge } =
    useReactFlow<AvailableNodes, any>();

  const edge = useMemo(() => {
    return _.find(getNodes(), {
      source: nodeId,
      sourceHandle: handleId,
    });
  }, [getNodes(), nodeId, handleId]);

  const addNode = () => {
    const node = getNode(nodeId) as Node<iMessageNode>;
    const currentNodePosition = node.position;
    const newNodeId = uuidv4();
    const newNode: Node<iMessageNode> = {
      id: newNodeId,
      type: "MESSAGE_NODE",
      // dragHandle: ".cc-drag-handle",
      data: {
        text: "Change your Text",
        quickButtons: [],
      },
      position: {
        x: currentNodePosition.x + 320 + 100,
        y: currentNodePosition.y + (order || 0) * 100,
      },
    };
    const newEdge: Edge = {
      id: `e${nodeId}${handleId}-${newNodeId}`,
      source: nodeId,
      sourceHandle: handleId,
      target: newNodeId,
      targetHandle: "INPUT",
      // label: (
      //   <div>
      //     Hello <i className="ri-delete-bin-line"></i>
      //   </div>
      // ),
    };
    setNodes([...getNodes(), newNode]);
    setEdges([...getEdges(), newEdge]);
  };

  const addActionNode = () => {
    const node = getNode(nodeId) as Node<iMessageNode>;
    const currentNodePosition = node.position;
    console.log("currentNodePosition", currentNodePosition);
    const newNodeId = uuidv4();
    const newNode: Node<iActionNode> = {
      id: newNodeId,
      type: "ACTION_NODE",
      // dragHandle: ".cc-drag-handle",
      data: {
        action: "END_CONVERSATION",
        data: {},
      },
      position: {
        x: currentNodePosition.x + 320 + 100,
        y: currentNodePosition.y + (order || 0) * 100,
      },
    };
    const newEdge: Edge = {
      id: `e${nodeId}${handleId}-${newNodeId}`,
      source: nodeId,
      sourceHandle: handleId,
      target: newNodeId,
      targetHandle: "INPUT",
      // label: "this is an edge label",
    };
    setNodes([...getNodes(), newNode]);
    setEdges([...getEdges(), newEdge]);
  };

  const addConditionNode = () => {
    const node = getNode(nodeId) as Node<iMessageNode>;
    const currentNodePosition = node.position;
    console.log("currentNodePosition", currentNodePosition);
    const newNodeId = uuidv4();
    const newNode: Node<iConditionNode> = {
      id: newNodeId,
      type: "CONDITION_NODE",
      // dragHandle: ".cc-drag-handle",
      data: {
        conditionTarget: "REPLY_TEXT",
        condition: "CONTAINS",
        conditionConfig: {
          input: "",
        },
      },
      position: {
        x: currentNodePosition.x + 320 + 100,
        y: currentNodePosition.y + (order || 0) * 100,
      },
    };
    const newEdge: Edge = {
      id: `e${nodeId}${handleId}-${newNodeId}`,
      source: nodeId,
      sourceHandle: handleId,
      target: newNodeId,
      targetHandle: "INPUT",
      // label: "this is an edge label",
    };
    setNodes([...getNodes(), newNode]);
    setEdges([...getEdges(), newEdge]);
  };

  const addGPTAssistantNode = () => {
    const node = getNode(nodeId) as Node<iMessageNode>;
    const currentNodePosition = node.position;
    console.log("currentNodePosition", currentNodePosition);
    const newNodeId = uuidv4();
    const newNode: Node<iGPTAssistantNode> = {
      id: newNodeId,
      type: "GPT_ASSISTANT_NODE",
      // dragHandle: ".cc-drag-handle",
      data: {
        inputType: "GPT_ASSISTANT",
        text: "Change your Text",
        quickButtons: [],
      },
      position: {
        x: currentNodePosition.x + 320 + 100,
        y: currentNodePosition.y + (order || 0) * 100,
      },
    };
    const newEdge: Edge = {
      id: `e${nodeId}${handleId}-${newNodeId}`,
      source: nodeId,
      sourceHandle: handleId,
      target: newNodeId,
      targetHandle: "INPUT",
      // label: "this is an edge label",
    };
    setNodes([...getNodes(), newNode]);
    setEdges([...getEdges(), newEdge]);
  };

  const showOnlyOneNodePerHandleWarning = useThrottle(
    () => message.warning("You can only connect to one node"),
    1000,
    { leading: true, trailing: false },
  );

  const isValidConnection = useCallback(
    (connection: Connection) => {
      const sourceNodeId = connection.source;
      const sourceHandle = connection.sourceHandle;

      const targetNodeId = connection.target;
      const sourceNode = getNode(sourceNodeId!);
      const targetNode = getNode(targetNodeId!);

      const allEdges = getEdges();
      const edgesForSourceHandle = _.filter(
        allEdges,
        (edge) =>
          edge.source === sourceNodeId && edge.sourceHandle === sourceHandle,
      );
      // console.log("targetNode?.type ", targetNode?.type, edgesForSourceHandle);

      // if (targetNode?.type === "MESSAGE_NODE") {
      //   // Todo
      // }

      const isEdgeConnectable = (() => {
        if (edgesForSourceHandle.length === 0) {
          return true;
        }
        let hasValidConnection = true;
        for (const edge of edgesForSourceHandle) {
          const endNodeId = edge.target;
          const endNode = getNode(endNodeId);
          if (!endNode) {
            hasValidConnection = false;
          }
        }
        // If it doesn't have a valid connection then it is connectable
        return !hasValidConnection;
      })();

      if (!isEdgeConnectable) {
        showOnlyOneNodePerHandleWarning();
        return false;
      }
      return true;
    },
    [getEdges, getNode, showOnlyOneNodePerHandleWarning],
  );

  return (
    <Handle
      type={type === "INPUT" ? "target" : "source"}
      id={handleId}
      position={
        type === "DEFAULT" || type === "YES" || type === "NO"
          ? Position.Bottom
          : type === "INPUT"
          ? Position.Left
          : Position.Right
      }
      className={classNames("chat-flow-handle", {
        "absolute right-0": type === "OPTION",
        "condition-yes": type === "YES",
        "condition-no": type === "NO",
      })}
      style={type === "OPTION" ? { top: 20 } : {}}
      isValidConnection={isValidConnection}
    >
      {type === "DEFAULT" && (
        <div className="chat-handle-label default">Next</div>
      )}

      {type === "YES" && <div className="yes chat-handle-label">Then</div>}
      {type === "NO" && <div className="no chat-handle-label">Else</div>}

      {!edge && type !== "INPUT" && (
        <Dropdown
          overlay={
            <Menu data-click-context="Chat Flow Handle Menu">
              <Menu.Item
                onClick={(e) => {
                  // e && e.stopPropagation();
                  addNode();
                }}
              >
                Message Node
              </Menu.Item>
              <Menu.Item
                onClick={(e) => {
                  // e && e.stopPropagation();
                  addActionNode();
                }}
              >
                Action Node
              </Menu.Item>
              <Menu.Item
                onClick={(e) => {
                  // e && e.stopPropagation();
                  addConditionNode();
                }}
              >
                Condition Node
              </Menu.Item>
              <Menu.Item
                onClick={(e) => {
                  // e && e.stopPropagation();
                  addGPTAssistantNode();
                }}
              >
                Magic Assistant Node
              </Menu.Item>
            </Menu>
          }
          placement="bottomCenter"
          trigger={["click"]}
        >
          <div
            className={classNames("add-button", {
              "pos-bottom":
                type === "DEFAULT" || type === "NO" || type === "YES",
              // "pos-left": type === "INPUT",
              "pos-right": type === "OPTION",
              "condition-yes": type === "YES",
              "condition-no": type === "NO",
            })}
          >
            <i className="ri-add-circle-fill"></i>
          </div>
        </Dropdown>
      )}
    </Handle>
  );
};
