import { Button, Select, Space } from "antd";
import _ from "lodash";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEnteredMessages } from "store/modules/ui-state/ui-state.actions";
import { selectEnteredMessage } from "store/modules/ui-state/ui-state.selectors";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { convertHtmlToPlainText } from "utils/html-to-plain-text";
import { defaultAdditionalExcludedBraftControllers } from "../draft-js-advanced/default-excluded-braft-controllers";
import { BraftJSInput } from "../draft-js-advanced/draft-js-advanced";
import "./reply-input.scss";
import { iSlashCommand } from "./slash-command";

export const ReplyInputRichTextTab = forwardRef(
  (
    {
      defaultSelectedFromId,
      slashCommands,
      onSend,
      inputRef,
      requiresFromSelection,
      onChange,
      fromIds,
      disableSenderSelection,

      setFileList,
      fileList,
      isFileUploading,
      fileUploadBox,
      fileUploadInput,
      fileUploadIcon,
      onScheduleMessage: _onScheduleMessage,
      conversationId,
      onCommandActivated,
    }: {
      defaultSelectedFromId: string;
      slashCommands: iSlashCommand[];
      onSend: (req: {
        text: string;
        fromId: string;
        attachments: string[];
      }) => Promise<any>;
      inputRef?: any;
      requiresFromSelection?: boolean;
      onChange?: (value: string) => any;
      fromIds: { id: string; label: string }[];
      disableSenderSelection?: boolean;
      fileList: any[];
      setFileList: any;
      isFileUploading: boolean;
      fileUploadBox: JSX.Element;
      fileUploadInput: JSX.Element;
      fileUploadIcon: JSX.Element;
      onScheduleMessage?: (req: {
        text: string;
        fromId: string;
        attachments: string[];
      }) => any;
      conversationId?: string;
      onCommandActivated?: (command: string) => any;
    },
    ref,
  ) => {
    const DraftEditorRef = useRef<any>();

    const { setHtmlContent, getHtmlContent: _getHtmlContent } =
      DraftEditorRef.current || {};

    const getHtmlContent = useCallback(
      () => (_getHtmlContent ? _getHtmlContent() : ""),
      [_getHtmlContent],
    );

    const dispatch = useDispatch();
    const [selectedFromId, setSelectedFromId] = useState(defaultSelectedFromId);
    const previouslyEnteredMessage = useSelector(
      selectEnteredMessage(conversationId),
    );

    useEffectWhen(
      () => {
        setHtmlContent &&
          setHtmlContent(previouslyEnteredMessage?.richText || "");
        const previouslyEnteredFiles = _.cloneDeep(
          previouslyEnteredMessage?.files || [],
        );
        setFileList(previouslyEnteredFiles);
      },
      [previouslyEnteredMessage, setFileList, setHtmlContent],
      [conversationId, setHtmlContent],
    );

    const sendMessageSuggestionContainerRef = useRef<any>();

    const appendContent = useCallback(
      (content) => {
        const html = getHtmlContent();
        if (convertHtmlToPlainText(html).trim() === "") {
          setHtmlContent(content);
        } else {
          setHtmlContent(html + content);
        }
      },
      [getHtmlContent, setHtmlContent],
    );

    useImperativeHandle(
      ref,
      () => ({
        setSendMessageInputValue: setHtmlContent,
        appendContent: appendContent,
      }),
      [appendContent, setHtmlContent],
    );

    const [isSendMessageProcessing, setSendMessageProcessing] = useState(false);

    const sendMessage = () => {
      setSendMessageProcessing(true);
      onSend({
        text: getHtmlContent(),
        attachments: fileList.map((item) => item.url),
        fromId: selectedFromId,
      })
        .then((d) => {
          setSendMessageProcessing(false);
          setHtmlContent("");
          setFileList([]);
          conversationId &&
            dispatch(
              setEnteredMessages({
                conversationId: conversationId,
                data: {
                  files: [],
                  message: "",
                  richText: "",
                  actions: [],
                },
              }),
            );
        })
        .catch((e) => {
          setSendMessageProcessing(false);
        });
    };
    const onScheduleMessage = (e) => {
      if (_onScheduleMessage) {
        _onScheduleMessage({
          text: getHtmlContent(),
          attachments: fileList.map((item) => item.url),
          fromId: selectedFromId,
        });
      }
      conversationId &&
        dispatch(
          setEnteredMessages({
            conversationId: conversationId,
            data: {
              files: [],
              message: "",
              richText: "",
              actions: [],
            },
          }),
        );
    };

    const onBlur = useCallback(() => {
      if (conversationId) {
        const html = getHtmlContent();
        dispatch(
          setEnteredMessages({
            conversationId: conversationId,
            data: {
              richText: html,
            },
          }),
        );
      }
    }, [conversationId, dispatch, getHtmlContent]);

    return (
      <>
        <div
          className="suggestions-container w-full relative"
          ref={sendMessageSuggestionContainerRef}
        ></div>
        <div className="flex-1 -mt-4">
          <BraftJSInput
            initialValue={""}
            ref={DraftEditorRef}
            additionalExcludedControls={
              defaultAdditionalExcludedBraftControllers
            }
            onBlur={onBlur}
            conversationId={conversationId}
            // extendControls={extendControls}
            onCommandActivated={onCommandActivated}
            showControlsOnTop={true}
          />
        </div>
        {fileUploadBox}

        <div className="helper-bar flex flex-row justify-between items-center w-full">
          <div className="left-icons px-4">
            {fileUploadInput}

            <Space>{fileUploadIcon}</Space>
          </div>
          <div className="right-side-content flex flex-row items-center  px-2">
            {!disableSenderSelection && (
              <div className="flex flex-row items-center">
                Sending as
                <Select
                  dropdownRender={(menu) => <div>{menu}</div>}
                  style={{ minWidth: 200 }}
                  placeholder="Select Sender ID"
                  value={selectedFromId}
                  onChange={(value) => setSelectedFromId(value)}
                  bordered={false}
                >
                  <Select.OptGroup label="Leased Mobile Numbers">
                    {(fromIds || []).map((fromId) => (
                      <Select.Option value={fromId.id} key={fromId.id}>
                        {fromId.label}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>
              </div>
            )}

            {_onScheduleMessage && (
              <Button
                icon={<i className="ri-calendar-line"></i>}
                type="dashed"
                onClick={onScheduleMessage}
                disabled={
                  (fileList.length === 0 && !getHtmlContent()) ||
                  (requiresFromSelection && !selectedFromId)
                }
                loading={isSendMessageProcessing || isFileUploading}
                className="mr-2"
              >
                Schedule
              </Button>
            )}

            <Button
              icon={<i className="ri-send-plane-2-line"></i>}
              type="primary"
              onClick={sendMessage}
              disabled={
                (fileList.length === 0 && !getHtmlContent()) ||
                (requiresFromSelection && !selectedFromId)
              }
              loading={isSendMessageProcessing || isFileUploading}
            >
              Send
            </Button>
          </div>
        </div>
      </>
    );
  },
);
