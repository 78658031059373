import {
  iMessage,
  iMessageSenderType
} from "@sdk/conversations/conversations.models";

/**
 * Decide whether to show or not the contact's avatar in the message row
 *
 * @param message
 * @param i
 * @returns {boolean}
 */
export function shouldShowContactAvatar(
  message: iMessage,
  i: number,
  messages: iMessage[]
): boolean {
  return (
    message.from.senderType === iMessageSenderType.CLIENT &&
    ((messages[i + 1] &&
      messages[i + 1].from.senderType !== iMessageSenderType.CLIENT) ||
      !messages[i + 1])
  );
}

/**
 * Check if the given message is the first message of a group
 *
 * @param message
 * @param i
 * @returns {boolean}
 */
export function isFirstMessageOfGroup(
  message: iMessage,
  i: number,
  messages: iMessage[]
): boolean {
  return (
    i === 0 ||
    (messages[i - 1] &&
      messages[i - 1].from.senderType !== message.from.senderType)
  );
}

/**
 * Check if the given message is the last message of a group
 *
 * @param message
 * @param i
 * @returns {boolean}
 */
export function isLastMessageOfGroup(
  message: iMessage,
  i: number,
  messages: iMessage[]
): boolean {
  return (
    message.from.senderType === iMessageSenderType.SYSTEM ||
    i === messages.length - 1 ||
    (messages[i + 1] &&
      messages[i + 1].from.senderType !== message.from.senderType)
  );
}
