import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Alert,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Segmented,
  Select,
  Space,
  message
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { LottiePlayer } from "components/common/lottie-player/lottie-player";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { QuoteEditor } from "modal-registry";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { PlaceSelector } from "./components/google-places-auto-complete";
import { GenerateQuoteFromBaseInput } from "./helpers/generate-quote-from-base-input";
import { roomOptions } from "./helpers/hotel-room-options";
import RobotAnimation from "./lottie/robot.json";
// import ProcessingAnimation from "./lottie/card-processing.json";
import { WithAttachments } from "components/pages/bot-builder/node-types/message-node/with-attachments";
import { TextLoop } from "react-text-loop-next";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { CountryCodes } from "utils/country-codes";
import { WhatsIncludedIconMap } from "./components/whats-included-block";
import { ItineraryBlockTypeMap } from "./itineries-model";
import { NameTitleOptions } from "./name-title-options";
// import ProcessingAnimation from "./lottie/process-automation.json";

const holidayTypes = ["Single Destination", "Multi City"];

export const flightDetailsInputMethod = [
  {
    value: "PNR",
    label: "Using PNR"
  },
  {
    value: "MANUAL",
    label: "Manually Enter"
  }
];

export const HolidayQuoteCreatorModal = ({
  visible,
  onChangeVisibility,
  onAddAsAttachment,
  conversationId,
  contactId
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onAddAsAttachment?: (content: string) => any;
  conversationId: string;
  contactId: string;
}) => {
  const [form] = useForm();
  const currentUser = useSelector(selectCurrentUser);
  const { changePanelState } = useModalPanels();
  const [isProcessing, setProcessing] = useState(false);

  const { doAction: createQuote, response, hasError } = useSDKActionWithDeps(
    () => ({
      action: SDK => req => SDK.holidayQuotes.create(req),
      failureMessage: "Something went wrong"
    }),
    []
  );

  const store = useStore();
  const organization = useSelector(selectOrganization);

  const { currency, currencySymbol } = useMemo(() => {
    const country = organization?.data.country || "GB";
    const countryData = CountryCodes.find(item => item.code === country);
    if (!countryData?.currencyCode) {
      return {
        currency: "GBP",
        currencySymbol: "£"
      };
    }
    return {
      currency: countryData?.currencyCode || "GBP",
      currencySymbol: countryData?.currencySymbol
    };
  }, [organization?.data?.country]);

  const process = useCallback(async () => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();
      setProcessing(true);
      console.log("formValues", formValues);
      const quote = await GenerateQuoteFromBaseInput(formValues, store);
      console.log("quote", quote);
      const createdQuote = await createQuote({
        ...quote,
        conversationId,
        contactId
      });
      onChangeVisibility(false);
      changePanelState(QuoteEditor, true, {
        onAddAsAttachment,
        holidayQuoteId: createdQuote?.id!
      });
      setProcessing(false);
    } catch (e) {
      console.log("Error while creating quote", e);
      setProcessing(false);
      message.error("Please check your input");
    }
  }, [
    changePanelState,
    contactId,
    conversationId,
    createQuote,
    form,
    onAddAsAttachment,
    onChangeVisibility,
    store
  ]);

  const defaultFormValue = useMemo(
    () => ({
      holidayType: "Single Destination"
    }),
    []
  );

  const attachmentRefs = useRef<any>();

  const handleOnPaste = useCallback((event: ClipboardEvent) => {
    const items = (
      event.clipboardData || (event as any).originalEvent.clipboardData
    ).items;
    let blob = null;

    for (const item of items) {
      if (item.type.indexOf("image") === 0) {
        blob = item.getAsFile();
      }
    }

    // load image if there is a pasted image
    if (blob !== null) {
      attachmentRefs.current.onDrop([
        new File([blob], `clipboard-${new Date().getTime()}.png`, {
          type: "image/png"
        })
      ]);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("paste", handleOnPaste, false);
    return function cleanup() {
      window.removeEventListener("paste", handleOnPaste, false);
    };
  }, [handleOnPaste]);

  return (
    <Modal
      title={
        <ModalTitle
          title="Generate Quote"
          icon={<i className="ri-bill-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={500}
      data-click-context="Quote Creator  Modal"
    >
      {isProcessing && (
        <div className="flex flex-col w-full justify-center items-center">
          <div className="text-2xl text-center font-bold">Hold Tight!</div>
          <div className="robot" style={{ width: 300, height: 300 }}>
            <LottiePlayer animation={RobotAnimation} loop play />
          </div>
          <TextLoop interval={2000}>
            <div style={{ width: 200 }} className="text-center">
              Converting PNR...
            </div>
            <div style={{ width: 200 }} className="text-center">
              Fetching Hotel Details...
            </div>
            <div style={{ width: 200 }} className="text-center">
              Fetching Destination Details
            </div>
            <div style={{ width: 200 }} className="text-center">
              Writing Content...
            </div>
            <div style={{ width: 200 }} className="text-center">
              Making the Quote Pretty
            </div>
            <div style={{ width: 200 }} className="text-center">
              Finalizing Quote
            </div>
          </TextLoop>
          <div className="text-gray-600 text-center font-bold mb-16 mt-2">
            A little bit of AI magic going behind the scene
          </div>
        </div>
      )}
      {!isProcessing && (
        <div className="flex flex-col w-full">
          <div className="header flex flex-row justify-center items-center">
            <img
              src="/assets/illustrations/quote-generation-2.svg"
              alt="Quote Generator"
              style={{ width: 300, height: 300 }}
            />
          </div>
          <div className="left flex-1 w-full">
            <Form
              layout="vertical"
              form={form}
              className="bold-form-labels"
              requiredMark={false}
              initialValues={defaultFormValue}
            >
              <Form.Item name="flightDetailsInputMethod" label={""}>
                <Segmented
                  options={flightDetailsInputMethod}
                  block
                  className="w-full"
                  defaultValue="PNR"
                />
              </Form.Item>

              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const type = getFieldValue(["flightDetailsInputMethod"]);
                  if (type === "MANUAL") {
                    return (
                      <>
                        <Form.Item
                          name={["flightDetailsScreenshots"]}
                          valuePropName="initialAttachments"
                          trigger={"onAttachmentsChange"}
                        >
                          <WithAttachments
                            id={`HOLIDAY_QUOTE_CREATOR`}
                            withoutBorder={true}
                            entityType="HOLIDAY_QUOTE_CREATOR"
                            entityId={organization?.id!}
                            ref={attachmentRefs}
                          >
                            <div className="text-gray-600 text-center border-2 rounded-lg p-4 border-dashed border-gray-600">
                              Drag your images here to upload attachments here
                              <br />
                              or just paste your image
                            </div>
                          </WithAttachments>
                        </Form.Item>

                        <Form.Item
                          name="lengthOfTrip"
                          label={"Length of Trip"}
                          rules={[
                            {
                              required: true,
                              message: "Please enter a value"
                            }
                          ]}
                        >
                          <InputNumber
                            min={1}
                            className="w-full"
                            addonAfter="Nights"
                          />
                        </Form.Item>

                        <Form.List
                          name="cities"
                          rules={[
                            {
                              validator: async (_, cities) => {
                                if (!cities || cities.length < 1) {
                                  return Promise.reject(
                                    new Error("At least 1 city")
                                  );
                                }
                              }
                            }
                          ]}
                        >
                          {(fields, { add, remove }, { errors }) => (
                            <>
                              {fields.map((field, index) => (
                                <div className="flex flex-row justify-center items-start">
                                  <div className="flex-1 pr-4">
                                    <Form.Item
                                      {...field}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing City"
                                        }
                                      ]}
                                    >
                                      <PlaceSelector
                                        type="cities"
                                        placeholder="Select City"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="flex flex-col justify-center items-center mt-2">
                                    {fields.length > 1 ? (
                                      <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              ))}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Add City
                                </Button>
                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </>
                    );
                  }
                  return (
                    <Form.Item
                      name="flightsPNR"
                      label={"Flight PNR"}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a PNR"
                        }
                      ]}
                    >
                      <Input.TextArea placeholder="Your Input PNR" rows={6} />
                    </Form.Item>
                  );
                }}
              </Form.Item>

              <Form.Item
                name="totalPrice"
                label={"Total Price"}
                rules={[
                  {
                    required: true,
                    message: "Please enter a value"
                  }
                ]}
              >
                <InputNumber
                  prefix={currencySymbol || currency}
                  min={1}
                  className="w-full"
                />
              </Form.Item>

              <Alert
                type="info"
                message="You can add more price options once your quote is ready"
              />

              <div className="flex flex-col">
                <div className="font-bold my-3">Passengers</div>
                <Space style={{ display: "flex" }}>
                  <Form.Item name={["adults"]} rules={[]}>
                    <InputNumber
                      min={0}
                      max={10}
                      className="w-full"
                      addonAfter="Adults"
                      defaultValue={0}
                    />
                  </Form.Item>
                  <Form.Item name={["children"]} rules={[]}>
                    <InputNumber
                      min={0}
                      max={10}
                      className="w-full"
                      addonAfter="Children"
                      defaultValue={0}
                    />
                  </Form.Item>
                  <Form.Item name={["infants"]} rules={[]}>
                    <InputNumber
                      min={0}
                      max={10}
                      className="w-full"
                      addonAfter="Infants"
                      defaultValue={0}
                    />
                  </Form.Item>
                </Space>
              </div>

              <Form.List
                name="passengerDetails"
                rules={
                  [
                    // {
                    //   validator: async (_, names) => {
                    //     if (!names || names.length < 2) {
                    //       return Promise.reject(new Error("At least 2 passengers"));
                    //     }
                    //   },
                    // },
                  ]
                }
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "title"]}
                          rules={[{ required: true, message: "Missing Title" }]}
                        >
                          <Select
                            placeholder="Title"
                            options={NameTitleOptions}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "first"]}
                          rules={[
                            { required: true, message: "Missing first name" }
                          ]}
                        >
                          <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "last"]}
                          rules={[
                            { required: true, message: "Missing last name" }
                          ]}
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                        <Form.Item
                          name={[name, "dob"]}
                          rules={[
                            {
                              type: "object" as const,
                              required: true,
                              message: "Please select DOB!"
                            }
                          ]}
                        >
                          <DatePicker placeholder="D.O.B" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: "60%" }}
                        icon={<PlusOutlined />}
                        className="w-full"
                      >
                        Add Passenger Details
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <div className="font-bold mt-8 mb-4">Hotel Details</div>

              <Form.List
                name="hotelDetails"
                rules={
                  [
                    // {
                    //   validator: async (_, names) => {
                    //     if (!names || names.length < 2) {
                    //       return Promise.reject(new Error("At least 2 passengers"));
                    //     }
                    //   },
                    // },
                  ]
                }
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div
                        key={key}
                        className="flex flex-col border border-gray-200 rounded-lg p-2 my-2 pt-6"
                      >
                        <Divider className=" w-full">
                          <div className="flex flex-row justify-center items-center">
                            <div className="icon text-2xl">
                              {ItineraryBlockTypeMap.HOTEL_ITEM.icon}
                            </div>
                            <div className="label">
                              {ItineraryBlockTypeMap.HOTEL_ITEM.label}
                            </div>
                            <div className="p-2 flex flex-col justify-center items-center">
                              <Button
                                type="text"
                                icon={<i className="ri-delete-bin-line"></i>}
                                onClick={() => remove(name)}
                              />
                            </div>
                          </div>
                        </Divider>
                        <div key={key} className="flex flex-col">
                          <Form.Item
                            {...restField}
                            name={[name, "hotel"]}
                            rules={[
                              { required: true, message: "Missing Hotel" }
                            ]}
                            className="w-full"
                          >
                            <PlaceSelector
                              type="lodging"
                              placeholder="Select Hotel"
                            />
                          </Form.Item>
                          <Space style={{ display: "flex" }}>
                            <Form.Item
                              {...restField}
                              name={[name, "roomType"]}
                              rules={[{ required: true, message: "Room Type" }]}
                            >
                              <Select
                                placeholder="Stay Type"
                                className="w-full"
                                options={roomOptions}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "checkIn"]}
                              rules={[
                                {
                                  type: "object" as const,
                                  required: true,
                                  message: "Please select DOB!"
                                }
                              ]}
                            >
                              <DatePicker placeholder="Check in" />
                            </Form.Item>
                            <Form.Item
                              name={[name, "noOfNights"]}
                              rules={[{ required: true, message: "Fill this" }]}
                            >
                              <InputNumber
                                min={1}
                                max={100}
                                className="w-full"
                                placeholder="Nights"
                                // addonAfter="Nights"
                              />
                            </Form.Item>
                          </Space>
                          <Form.Item
                            {...restField}
                            name={[name, "notes"]}
                            className="w-full"
                          >
                            <Input
                              placeholder="Additional Notes"
                              className="w-full"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: "60%" }}
                        icon={<PlusOutlined />}
                        className="w-full"
                      >
                        Add Hotel Details
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <div className="font-bold mt-8 mb-4">Extras</div>
              <Form.List
                name="whatsIncluded"
                rules={
                  [
                    // {
                    //   validator: async (_, names) => {
                    //     if (!names || names.length < 2) {
                    //       return Promise.reject(new Error("At least 2 passengers"));
                    //     }
                    //   },
                    // },
                  ]
                }
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex" }}
                        align="baseline"
                      >
                        <Form.Item {...restField} noStyle shouldUpdate>
                          {({ getFieldValue }) => {
                            const type = getFieldValue([
                              "whatsIncluded",
                              name,
                              "type"
                            ]);
                            if (type) {
                              return (
                                <div className="text-xl flex flex-col justify-center items-center">
                                  {WhatsIncludedIconMap[type]}
                                </div>
                              );
                            }
                          }}
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "title"]}
                          className="flex-1"
                          rules={[{ required: true, message: "Missing title" }]}
                        >
                          <Input placeholder="Title" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          className="flex-1"
                          rules={[]}
                        >
                          <Input placeholder="Description" />
                        </Form.Item>

                        <Button
                          type="text"
                          icon={<i className="ri-delete-bin-line"></i>}
                          onClick={() => remove(name)}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <div className="flex flex-row gap-4">
                        <Button
                          type="dashed"
                          onClick={() =>
                            add({
                              type: "VISA",
                              title: "Visa Included",
                              description: "Visa fee included"
                            })
                          }
                          style={{ width: "60%" }}
                          icon={<PlusOutlined />}
                          className="w-full flex-1"
                          block
                        >
                          Add Visa
                        </Button>
                        <Button
                          type="dashed"
                          onClick={() =>
                            add({
                              type: "EXCURSION",
                              title: "Excursion Name",
                              description: ""
                            })
                          }
                          style={{ width: "60%" }}
                          icon={<PlusOutlined />}
                          className="w-full flex-1"
                          block
                        >
                          Add Excursion
                        </Button>
                      </div>
                      <div className="flex flex-row gap-4 mt-4">
                        <Button
                          type="dashed"
                          onClick={() =>
                            add({
                              type: "INSURANCE",
                              title: "Travel Insurance",
                              description: "Full Coverage"
                            })
                          }
                          style={{ width: "60%" }}
                          icon={<PlusOutlined />}
                          className="w-full flex-1"
                          block
                        >
                          Add Insurance
                        </Button>
                        <Button
                          type="dashed"
                          onClick={() =>
                            add({
                              type: "MISCELLANEOUS",
                              title: "Item Title",
                              description: "Item Description"
                            })
                          }
                          style={{ width: "60%" }}
                          icon={<PlusOutlined />}
                          className="w-full flex-1"
                          block
                        >
                          Add Extra
                        </Button>
                      </div>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.Item name="finePrint" label={"Fine Print"} rules={[]}>
                <Input.TextArea
                  placeholder={`✅ Condition 1 \n✅ Condition 2 \n✅ Condition 3`}
                  rows={3}
                />
              </Form.Item>

              <Button
                type="primary"
                icon={<i className="ri-magic-line"></i>}
                loading={isProcessing}
                onClick={process}
                size={"large"}
                block
                className="font-bold"
              >
                Generate Quote
              </Button>
            </Form>
          </div>
        </div>
      )}

      <DarkModeBg />
    </Modal>
  );
};
