import { axios, AxiosResponse } from "@sdk/axios";
import { EntityServices } from "@sdk/utils/entity.service";
import {
  PaginatedOptions,
  PaginatedResults,
} from "@sdk/utils/paginated-results";
import { UserTracker } from "user-tracker";
import { iChatBot, iChatBotInstance } from "./chat-bots.models";

export class ChaBotServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  chatBots = Object.assign(
    new EntityServices<iChatBot<any>>(this.config, "chat-bots", {
      onCreate: () => {
        UserTracker.track("CHAT_BOTS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("CHAT_BOTS - Delete", {});
      },
    }),
    {
      queryTemplates: async (query: any, options: PaginatedOptions) => {
        const results: AxiosResponse<PaginatedResults<iChatBot<any>>> =
          await axios.post(
            this.config.basePath + `/chat-bots/query-templates`,
            { query, options },
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
    },
    {
      queryResponses: async (query: any, options: PaginatedOptions) => {
        const results: AxiosResponse<PaginatedResults<iChatBotInstance>> =
          await axios.post(
            this.config.basePath + `/chat-bots/query-responses`,
            {
              query,
              options: {
                ...options,
                sort: "-startAt",
              },
            },
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
    },
  );

  chatBotInstances = Object.assign(
    new EntityServices<iChatBotInstance>(this.config, "chat-bot-instances"),
    {},
  );
}
