import { processServerError, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Alert, Button, Descriptions, Form, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  ExternalAuthCallbackData,
  ExternalAuthConnector,
} from "components/common/external-auth-connector-button/external-auth-connector-button";
import {
  AddConnectionLayout,
  AddConnectionModalContainer,
  AddConnectionStepsContainer,
  IntegrationSuccessfulScreen,
  StepContainer,
} from "components/modules/connections/add-connection-modal-templates";
import { generateDefaultConnectionConfig } from "components/modules/connections/common-connection-settings/default-connection-config-generator";
import { ConnectionIcons } from "components/modules/connections/helpers/connection-icons";
import { push } from "connected-react-router";
import _ from "lodash";
import { useCallback, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { UserTracker } from "user-tracker";
import { getWAAccounts, iWAAccountWithNumber } from "./wa-cloud-native-helpers";

export const AddWACloudNativeConnectionModal = ({
  visible,
  onChangeVisibility: _onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const [connectionForm] = useForm();

  const [currentStep, setCurrentStep] = useState(0);

  const [accessToken, setAccessToken] = useState("");
  const [pageSelectionForm] = useForm();
  const [createdConnection, setCreatedConnection] = useState("");

  const onManageConnection = (connectionId: string) => {};

  const [waPhoneNumbers, setWaPhoneNumbers] = useState<iWAAccountWithNumber[]>(
    [],
  );

  const onAuthenticated = useCallback(
    ({ accessToken }: ExternalAuthCallbackData) => {
      setAccessToken(accessToken!);
      getWAAccounts(accessToken!)
        .then((d) => {
          setWaPhoneNumbers(d);
          setCurrentStep(1);
        })
        .catch((e) => {
          message.error(processServerError(e, "Something went wrong"));
        });
    },
    [],
  );

  const { doAction: completeIntegration, isProcessing: isActionProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (val) =>
          SDK.connections.create(val).then((d) => {
            setCreatedConnection(d.id!);
            setCurrentStep(2);
          }),
        successMessage: "Integration has been created",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const onChangeVisibility = (visibility: boolean) => {
    _onChangeVisibility(visibility);
    setCurrentStep(0);
  };

  const dispatch = useDispatch();
  const store = useStore();

  return (
    <>
      <AddConnectionModalContainer
        title="Connect Whatsapp"
        icon={<i className="ri-whatsapp-line"></i>}
        isVisible={visible}
        onChangeVisibility={onChangeVisibility}
      >
        <AddConnectionLayout
          currentStep={currentStep}
          totalSteps={2}
          stepsWithSidebars={[0, 1]}
          topBar={
            <AddConnectionStepsContainer
              currentStep={currentStep}
              steps={[
                {
                  label: "Authenticate",
                  icon: <i className="ri-lock-line"></i>,
                },
                {
                  label: "Select Account",
                  icon: <i className="ri-whatsapp-line"></i>,
                },
                {
                  label: "Done",
                  icon: <i className="ri-check-line"></i>,
                },
              ]}
            />
          }
          sidebarImage={
            <div className="text-10xl opacity-50">
              <i className={ConnectionIcons.WHATS_APP_CLOUD}></i>
            </div>
          }
        >
          <StepContainer stepNumber={0} currentStep={currentStep}>
            <div className="description text-gray-600 dark:text-gray-400 mb-4 mode_transition">
              Now click on 'Connect through Facebook' button and authenticate to
              allow Click Connector to access your Whatsapp Account
            </div>

            <Descriptions
              title="Required Permissions"
              bordered
              layout="horizontal"
              column={1}
            >
              <Descriptions.Item label="pages_messaging">
                This will allow us to connect to your WhatsApp Account.
              </Descriptions.Item>
              <Descriptions.Item label="whatsapp_business_management">
                The whatsapp_business_management permission allows your app to
                read and/or manage WhatsApp business assets you own or have been
                granted access to by other businesses through this permission.
                These business assets include WhatsApp business accounts, phone
                numbers, message templates, QR codes and their associated
                messages, and webhook subscriptions.
              </Descriptions.Item>
              <Descriptions.Item label="whatsapp_business_messaging">
                The whatsapp_business_messaging permission allows an app to send
                WhatsApp messages to a specific phone number, upload and
                retrieve media from messages, manage and get WhatsApp business
                profile information, and to register those phone numbers with
                Meta.
              </Descriptions.Item>
            </Descriptions>
            <div className="button-container my-4 flex flex-row justify-center items-center">
              <ExternalAuthConnector
                type="WHATSAPP"
                onAuthenticated={onAuthenticated}
                label="Connect Through Facebook"
              />
            </div>
            <div className="">
              <Alert
                type="info"
                showIcon
                message="Have you already connected WhatsApp Account with ClickConnector?"
                description={`If you have already connected Whatsapp Business Account with ClickConnector and if you get message "Business already linked to embedded signup", You can simply close the authentication window to continue the setup.`}
              />
            </div>
          </StepContainer>

          <StepContainer stepNumber={1} currentStep={currentStep}>
            <div className="description text-gray-600 dark:text-gray-400 mb-4 mode_transition">
              Select a WhatsApp Number that you wish to connect to the Click
              Connector
            </div>
            {waPhoneNumbers.length === 0 && (
              <Alert
                message="It looks like you do not have active whatsapp accounts or you have missed to select the pages while authenticating"
                type="warning"
                showIcon
                className="p-4 rounded-lg mb-4"
              />
            )}

            <Form layout="vertical" form={pageSelectionForm} initialValues={{}}>
              {/* Welcome Message */}
              <Form.Item
                label="Whatsapp Number to Connect"
                name="phoneId"
                rules={[
                  {
                    required: true,
                    message: "Please select an account",
                  },
                ]}
              >
                <Select size="large">
                  {waPhoneNumbers.map((waAccount) => (
                    <Select.OptGroup
                      key={waAccount.id}
                      label={`${waAccount.name} | ${waAccount.page.name}`}
                    >
                      {waAccount.numbers.map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                          {item.verified_name} | {item.display_phone_number}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                </Select>
              </Form.Item>
            </Form>

            <div className="">
              <Alert
                type="info"
                showIcon
                message="Connect New Number?"
                description={
                  <>
                    If you want to connect a new number to your business
                    account, please add the new number in your Meta Business
                    Suite.
                    <div className="m-2">
                      <Button
                        target="_blank"
                        referrerPolicy="no-referrer"
                        href={`https://business.facebook.com/wa/manage/phone-numbers/`}
                      >
                        Manage Phone Numbers
                      </Button>
                    </div>
                  </>
                }
              />
            </div>

            <div className="flex flex-row justify-between items-center mt-4">
              <Button
                onClick={() => {
                  setCurrentStep(0);
                }}
                size="large"
                icon={<i className="ri-arrow-left-circle-fill"></i>}
              >
                Back
              </Button>
              <Button
                type="primary"
                loading={isActionProcessing}
                size="large"
                onClick={async () => {
                  const { phoneId } = pageSelectionForm.getFieldsValue();
                  try {
                    await pageSelectionForm.validateFields();

                    const allWAPhoneNumbers = _.flatten(
                      waPhoneNumbers.map((item) =>
                        item.numbers.map((number) => ({
                          ...number,
                          waAccount: item,
                        })),
                      ),
                    );

                    const selectedOption = _.find(allWAPhoneNumbers, {
                      id: phoneId,
                    });

                    const label = `WA: ${selectedOption?.verified_name}`;
                    const configData = generateDefaultConnectionConfig(
                      "WHATS_APP_CLOUD_NATIVE",
                      store,
                    );

                    completeIntegration({
                      type: "WHATS_APP_CLOUD_NATIVE",
                      label,
                      wabaId: selectedOption?.waAccount.id,
                      phoneId: phoneId,
                      accessToken,
                      data: configData,
                    });

                    UserTracker.track(
                      "Add WHATS_APP_CLOUD_NATIVE Integration",
                      {
                        label,
                        phoneId,
                        accessToken,
                      },
                    );
                  } catch (e) {
                    if (!phoneId) {
                      message.warning("Please select a phone number");
                      return;
                    }
                  }
                }}
                icon={<i className="ri-arrow-right-circle-fill"></i>}
              >
                Continue
              </Button>
            </div>
          </StepContainer>

          <StepContainer stepNumber={2} currentStep={currentStep}>
            <IntegrationSuccessfulScreen
              onManageConnection={() => {
                dispatch(
                  push(`/configurations/connections/${createdConnection}/`),
                );
              }}
              onHideWizard={() => onChangeVisibility(false)}
              subTitle="Now you will be able to reply to WhatsApp messages from Click Connector"
              title="Your Integration is ready!"
            ></IntegrationSuccessfulScreen>
          </StepContainer>
        </AddConnectionLayout>
      </AddConnectionModalContainer>
    </>
  );
};
