import { iCCMacro } from "@sdk/user-management/user-management.models";
import { Badge, Button, message, Space, Tabs, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import Upload, { RcFile } from "antd/lib/upload";
import classNames from "classnames";
import { iAction } from "components/modules/conversations/components/action-editor/models";
import _ from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { Mention, MentionsInput } from "react-mentions";
import { useDispatch, useSelector } from "react-redux";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { setEnteredMessages } from "store/modules/ui-state/ui-state.actions";
import { useStateWithGetter } from "utils/hooks/use-state-with-getter";
import { LoadingIndicatorWithSpin } from "../loading-indicator/loading-indicator";
import { ActionsBar } from "./actions-bar";
import { EmailModifier } from "./email-modifer";
import { EmojiPicker } from "./emoji-picker";
import { ReplyInputFirstTab } from "./reply-input-first-tab";
import { ReplyInputRichTextTab } from "./reply-input-rich-text-tab";
import "./reply-input.scss";
import { iSlashCommand } from "./slash-command";

const { TabPane } = Tabs;

export type ValidationMessageType = "success" | "info" | "warning" | "error";

// Alphanumeric senders (cannot be replied to) can have a maximum of 11 characters. No spaces.
// A part is 160 characters for a single SMS or 153 characters per part for multi-part SMS.
// Unicode UTF8 encoded characters such as emoji’s or non latin character sets, then a single part is 70 characters or 67 characters for multiple parts.

// Opt-out reply STOP
// replacement - [Firstname][Lastname][Mobile]
// [unsub-reply-link]

export const ReplyComponent = forwardRef(
  (
    {
      defaultSelectedFromId,
      enableEmojis,
      enableAttachments,
      enableRichText,
      enableNotes,
      onSend: _onSend,
      onSaveNotes: _onSaveNotes,
      fromIds,
      attachmentUploader: _attachmentUploader,
      messageValidator,
      messageCharLimitCalculator,
      slashCommands,
      macroCommands,
      userList,
      replyHeaderText,
      messageInputPlaceholder,
      style,
      requiresFromSelection,
      inputRef,
      disableSenderSelection,
      helpText,
      disableBorder,
      sendMessageOnEnter,
      onChange,
      onScheduleMessage: _onScheduleMessage,
      conversationId,
      additionalTabBarContent,
      tokenReplacer,
      onSlashCommandExecuted,
      hideFooter,
      hideHeader,
      onCommandActivated,
    }: {
      defaultSelectedFromId: string;
      enableEmojis: boolean;
      enableNotes: boolean;
      enableRichText?: boolean;
      enableAttachments: boolean;
      onSend: (req: {
        text: string;
        fromId: string;
        attachments: string[];
        subject?: string;
        cc?: string[];
        bcc?: string[];
        to?: string[];
        actions?: iAction[];
      }) => Promise<any>;
      onSaveNotes?: (req: { text: string }) => Promise<any>;
      fromIds: { id: string; label: string }[];
      attachmentUploader?: (file: RcFile) => any;
      messageValidator: (
        message: string
      ) => { message: string; type: ValidationMessageType }[];
      messageCharLimitCalculator: (
        message: string
      ) => { current: number; limit: number };
      slashCommands: iSlashCommand[];
      macroCommands?: iCCMacro[];
      userList: { id: string; display: string }[];
      replyHeaderText?: string;
      messageInputPlaceholder?: string;
      style?: React.CSSProperties;
      requiresFromSelection?: boolean;
      inputRef?: any;
      disableSenderSelection?: boolean;
      helpText?: string | JSX.Element;
      disableBorder?: boolean;
      sendMessageOnEnter?: boolean;
      onChange?: (value: string) => any;
      onScheduleMessage?: (req: {
        text: string;
        fromId: string;
        attachments: string[];
        subject?: string;
        cc?: string[];
        bcc?: string[];
        to?: string[];
        actions?: iAction[];
      }) => any;
      conversationId?: string;
      additionalTabBarContent?: (mode) => JSX.Element;
      tokenReplacer?: (message: string) => string;
      onSlashCommandExecuted?: (command: iSlashCommand) => any;
      // Hide Footer is not implemented
      hideFooter?: boolean;
      hideHeader?: boolean;
      onCommandActivated?: (command: string) => any;
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [mode, setMode] = useState<"REPLY" | "NOTES">("REPLY");
    const [notesInputValue, setNotesInputValue] = useState("");
    const [
      sendMessageInputValue,
      setSendMessageInputValue,
      getSendMessageInputValue,
    ] = useStateWithGetter("");
    const [fileList, setFileList] = useState<any[]>([]);
    const [actionsForm] = useForm();

    //const sendMessageSuggestionContainerRef = useRef<any>();
    const notesSuggestionContainerRef = useRef<any>();
    const ReplyInputFirstTabRef = useRef<any>();
    const ReplyRichTextTabRef = useRef<any>();
    useImperativeHandle(ref, () => ({
      ReplyInputFirstTabRef,
      ReplyRichTextTabRef,
      setNotesInputValue,
      setFileList,
    }));

    const handleNotesInputChange = (
      event,
      newValue,
      newPlainTextValue,
      mentions: {
        childIndex: number;
        display: string;
        id: string;
        index: number;
        plainTextIndex: number;
        type: "PRESET" | "MENTION";
      }[]
    ) => {
      if (newValue.indexOf("@[") > -1) {
        const lastMention = _.last(mentions);
        if (lastMention && lastMention.type === "PRESET") {
          const mentionRemovedValue = newValue.replace(
            `@[${lastMention.display}](${lastMention.id})`,
            lastMention.display
          );
          setNotesInputValue(mentionRemovedValue);
          return;
        }
      }
      setNotesInputValue(newValue);
    };

    const onFileListChange = ({ fileList: newFileList }) => {
      // console.log("newFileList", newFileList);
    };

    const [uploadingFiles, setUploadingFiles] = useState([] as string[]);

    const attachmentUploader = useCallback(
      async (file) => {
        if (!_attachmentUploader) {
          throw {
            message: "No File Uploader Provided",
          };
        }
        try {
          const url = await _attachmentUploader(file);
          return url;
        } catch (e) {
          const a = "11";
          throw e;
        }
      },
      [_attachmentUploader]
    );

    const uploadFileWithLoading = useCallback(
      async (file: RcFile) => {
        if (!_attachmentUploader) {
          throw {
            message: "No File Uploader Provided",
          };
        }
        try {
          setIsUploading(true);
          const url = await _attachmentUploader(file);
          setIsUploading(false);
          return url;
        } catch (e) {
          setIsUploading(false);
          throw e;
        }
      },
      [_attachmentUploader]
    );

    const uploadFileX = async (options) => {
      const { onSuccess, onError, file, onProgress } = options;
      if (!_attachmentUploader) {
        console.log("Error: No File Uploader Provided");
        throw {
          message: "No File Uploader Provided",
        };
      }
      try {
        setIsUploading(true);
        const url = await _attachmentUploader(file);
        const fileListRecord = {
          uid: new Date().getTime().toString(),
          name: file.name,
          status: "done", // options：uploading, done, error, removed. Intercepted file by beforeUpload don't have status field.
          response: '{"status": "success"}', // response from server
          linkProps: '{"download": "image"}', // additional html props of file link
          xhr: "XMLHttpRequest{ ... }", // XMLHttpRequest Header
          url: url,
          thumbUrl: url,
        };
        setFileList((state) => [...state, fileListRecord]);
        setIsUploading(false);

        // Persist Attachments - START
        const attachmentsToPersist = [...fileList, fileListRecord]
          .filter((item) => item.status === "done")
          .map((item) => {
            delete item.originFileObj;
            return {
              uid: item.uid,
              name: item.name,
              status: item.status, // options：uploading, done, error, removed. Intercepted file by beforeUpload don't have status field.
              response: '{"status": "success"}', // response from server
              linkProps: '{"download": "image"}', // additional html props of file link
              xhr: "XMLHttpRequest{ ... }", // XMLHttpRequest Header
              url: item.url,
              thumbUrl: item.thumbUrl,
            };
          });
        conversationId &&
          dispatch(
            setEnteredMessages({
              conversationId: conversationId,
              data: {
                files: attachmentsToPersist,
                message: sendMessageInputValue,
                actions: actionsForm.getFieldValue("actions"),
              },
            })
          );
        // Persist Attachments -END
        onSuccess("Ok");
      } catch (err) {
        console.log("Error while uploading file", err);
        onError({ err });
        setIsUploading(false);
      }
    };

    const onDrop = useCallback(
      async (acceptedFiles: File[]) => {
        setIsUploading(true);
        const ids = acceptedFiles.map(
          (file, index) => `${Date.now().toString()}-${index}`
        );
        setUploadingFiles((state) => [...state, ...ids]);
        try {
          const newFileListRecords: any[] = [];
          for (const file of acceptedFiles) {
            const url = await attachmentUploader(file as any);
            newFileListRecords.push({
              uid: new Date().getTime().toString(),
              name: file.name,
              status: "done", // options：uploading, done, error, removed. Intercepted file by beforeUpload don't have status field.
              response: '{"status": "success"}', // response from server
              linkProps: '{"download": "image"}', // additional html props of file link
              xhr: "XMLHttpRequest{ ... }", // XMLHttpRequest Header
              url: url,
              thumbUrl: url,
            });
          }
          setUploadingFiles((state) => _.without(state, ...ids));
          setIsUploading(false);
          setFileList((state) => [...state, ...newFileListRecords]);
          conversationId &&
            dispatch(
              setEnteredMessages({
                conversationId: conversationId,
                data: {
                  files: [...fileList, ...newFileListRecords],
                  message: getSendMessageInputValue(),
                  actions: actionsForm.getFieldValue("actions"),
                },
              })
            );
        } catch (e) {
          setUploadingFiles((state) => _.without(state, ...ids));
          setIsUploading(false);
          // No need to throw the error from here as the error is handled fully
          // throw e;
        }
      },
      [
        actionsForm,
        attachmentUploader,
        conversationId,
        dispatch,
        fileList,
        getSendMessageInputValue,
      ]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      noClick: true,
    });

    const handleOnPaste = useCallback(
      (event: ClipboardEvent) => {
        const items = (
          event.clipboardData || (event as any).originalEvent.clipboardData
        ).items;
        let blob = null;

        for (const item of items) {
          if (item.type.indexOf("image") === 0) {
            blob = item.getAsFile();
          }
        }

        // load image if there is a pasted image
        if (blob !== null) {
          onDrop([
            new File([blob], `clipboard-${new Date().getTime()}.png`, {
              type: "image/png",
            }),
          ]);
        }
      },
      [onDrop]
    );

    // Todo: Ideally isUploading shoudl be removed as uploadingFiles variable is there
    const [isUploading, setIsUploading] = useState(false);

    //const [isSendMessageProcessing, setSendMessageProcessing] = useState(false);

    useEffect(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.addEventListener("paste", handleOnPaste, false);
        inputRef.current.setAttribute("data-enable-grammarly", "true");
        inputRef.current.setAttribute("data-gramm", "true");
        const currentInputRef = inputRef.current;
        return function cleanup() {
          if (currentInputRef) {
            currentInputRef.removeEventListener("paste", handleOnPaste, false);
          }
        };
      }
    }, [handleOnPaste, inputRef]);

    const conversation = useSelector(selectConversationById(conversationId!));

    const emailDataCapture = useRef<any>();

    const onSaveNotes = useCallback(
      async (req) => {
        if (_onSaveNotes) {
          await _onSaveNotes(req);
          setNotesInputValue("");
        }
      },
      [_onSaveNotes]
    );

    const onSend = useCallback(
      async (req) => {
        const { actions } = actionsForm.getFieldsValue();
        if (
          conversation?.connectionType === "EMAIL" &&
          emailDataCapture?.current
        ) {
          try {
            await emailDataCapture.current.validateForm();
          } catch (e) {
            message.error("Please check your input");
            throw e;
          }
          const additionData = emailDataCapture.current.getMessageData();
          emailDataCapture.current.resetFields();
          return _onSend({ ...req, ...additionData, actions: actions || [] });
        }
        return await _onSend({ ...req, actions: actions || [] });
      },
      [_onSend, actionsForm, conversation?.connectionType]
    );

    const onScheduleMessage = useCallback(
      async (req) => {
        if (_onScheduleMessage) {
          const { actions } = actionsForm.getFieldsValue();
          if (
            conversation?.connectionType === "EMAIL" &&
            emailDataCapture?.current
          ) {
            try {
              await emailDataCapture.current.validateForm();
            } catch (e) {
              message.error("Please check your input");
              throw e;
            }
            const additionData = emailDataCapture.current.getMessageData();
            emailDataCapture.current.resetFields();
            return _onScheduleMessage({
              ...req,
              ...additionData,
              actions: actions || [],
            });
          }
          return await _onScheduleMessage({ ...req, actions: actions || [] });
        }
      },
      [_onScheduleMessage, actionsForm, conversation?.connectionType]
    );

    const onMacroCommandExecuted = useCallback(
      (command: iCCMacro) => {
        actionsForm.setFieldsValue({
          actions: command.actions,
        });
      },
      [actionsForm]
    );

    return (
      <div
        className={classNames("reply-input-container flex flex-col relative", {
          "border-gray-200 dark:border-gray-800 border border-solid rounded-lg mode_transition": !disableBorder,
        })}
        style={style}
        {...(enableAttachments ? getRootProps() : {})}
        tabIndex={-1}
      >
        {enableAttachments && <input {...getInputProps()} />}

        {isDragActive && (
          <div className="drop-indicator">Drop the files here ...</div>
        )}

        {conversation?.connectionType === "EMAIL" && (
          <EmailModifier
            conversationId={conversationId!}
            ref={emailDataCapture}
          />
        )}

        <Tabs
          activeKey={mode}
          onChange={(mode) => setMode(mode as any)}
          renderTabBar={(props, TabBar) =>
            hideHeader ? (
              <></>
            ) : (
              <div className="flex flex-row w-full items-center">
                <TabBar {...(props as any)} tabIndex={-1} />
              </div>
            )
          }
          tabBarExtraContent={
            <Space>
              {additionalTabBarContent && additionalTabBarContent(mode)}
              {helpText ? (
                <Button
                  type="link"
                  icon={<i className="ri-question-line"></i>}
                  tabIndex={-1}
                />
              ) : (
                <></>
              )}
            </Space>
          }
          tabBarStyle={{ paddingLeft: 20, paddingRight: 20, flex: 1 }}
          className={classNames("w-full overflow-visible", {
            "border-2 border-dashed border-blue-500": isDragActive,
          })}
          tabIndex={-1}
        >
          <TabPane
            tab={
              <div className="font-bold" tabIndex={-1}>
                {replyHeaderText || "Reply"}
              </div>
            }
            key="REPLY"
          >
            <ReplyInputFirstTab
              ref={ReplyInputFirstTabRef}
              defaultSelectedFromId={defaultSelectedFromId}
              messageCharLimitCalculator={messageCharLimitCalculator}
              enableEmojis={enableEmojis}
              userList={userList}
              sendMessageOnEnter={sendMessageOnEnter}
              onSend={onSend}
              inputRef={inputRef}
              slashCommands={slashCommands}
              macroCommands={macroCommands}
              actionsForm={actionsForm}
              requiresFromSelection={requiresFromSelection}
              messageInputPlaceholder={messageInputPlaceholder}
              onChange={onChange}
              fromIds={fromIds}
              sendMessageInputValue={sendMessageInputValue}
              setSendMessageInputValue={setSendMessageInputValue}
              fileList={fileList}
              setFileList={setFileList}
              messageValidator={messageValidator}
              onScheduleMessage={onScheduleMessage}
              disableSenderSelection={disableSenderSelection}
              isFileUploading={isUploading}
              tokenReplacer={tokenReplacer}
              onSlashCommandExecuted={onSlashCommandExecuted}
              onMacroCommandExecuted={onMacroCommandExecuted}
              fileUploadBox={
                <FileUploadSection
                  fileList={fileList}
                  isUploading={isUploading}
                  uploadingFiles={uploadingFiles}
                  setFileList={setFileList}
                  onUpload={uploadFileX}
                />
              }
              fileUploadInput={
                <input
                  type="file"
                  id="uploadField"
                  className="hidden"
                  multiple
                  onChange={(e) => {
                    const selectedFiles = (document.getElementById(
                      "uploadField"
                    )! as any).files;
                    onDrop(Array.from(selectedFiles));
                    (document.getElementById("uploadField")! as any).value = "";
                  }}
                />
              }
              fileUploadIcon={
                enableAttachments ? (
                  <Button
                    type="link"
                    onClick={(e) =>
                      document.getElementById("uploadField")?.click()
                    }
                    icon={<i className="ri-attachment-line"></i>}
                  />
                ) : (
                  <></>
                )
              }
              conversationId={conversationId}
              hideHeader={hideHeader}
            />
          </TabPane>
          {enableRichText && (
            <TabPane
              tab={<div className="font-bold">Rich Text</div>}
              key="RICH_TEXT"
            >
              <ReplyInputRichTextTab
                ref={ReplyRichTextTabRef}
                defaultSelectedFromId={defaultSelectedFromId}
                slashCommands={slashCommands}
                onSend={onSend}
                // inputRef={inputRef}
                requiresFromSelection={requiresFromSelection}
                onChange={onChange}
                fromIds={fromIds}
                fileList={fileList}
                setFileList={setFileList}
                onScheduleMessage={onScheduleMessage}
                disableSenderSelection={disableSenderSelection}
                isFileUploading={isUploading}
                fileUploadBox={
                  <FileUploadSection
                    fileList={fileList}
                    isUploading={isUploading}
                    uploadingFiles={uploadingFiles}
                    setFileList={setFileList}
                    onUpload={uploadFileX}
                  />
                }
                fileUploadInput={
                  <input
                    type="file"
                    id="uploadField"
                    className="hidden"
                    multiple
                    onChange={(e) => {
                      const selectedFiles = (document.getElementById(
                        "uploadField"
                      )! as any).files;
                      onDrop(Array.from(selectedFiles));
                      (document.getElementById("uploadField")! as any).value =
                        "";
                    }}
                  />
                }
                fileUploadIcon={
                  enableAttachments ? (
                    <Button
                      type="link"
                      onClick={(e) =>
                        document.getElementById("uploadField")?.click()
                      }
                      icon={<i className="ri-attachment-line"></i>}
                    />
                  ) : (
                    <></>
                  )
                }
                conversationId={conversationId}
                onCommandActivated={onCommandActivated}
              />
            </TabPane>
          )}
          {enableNotes && (
            <TabPane tab={<div className="font-bold">Notes</div>} key="NOTES">
              <div
                className="suggestions-container w-full relative"
                ref={notesSuggestionContainerRef}
              ></div>
              <div className="flex-1 p-2 px-4">
                <MentionsInput
                  value={notesInputValue}
                  onChange={handleNotesInputChange}
                  placeholder="Type a note and hit enter to save in the conversation"
                  className="w-full send-input"
                  // singleLine
                  suggestionsPortalHost={notesSuggestionContainerRef.current}
                  onKeyDown={(e) => {
                    if (sendMessageOnEnter) {
                      if (e.keyCode === 13 && !e.shiftKey) {
                        e.preventDefault();
                        onSaveNotes &&
                          onSaveNotes({
                            text: notesInputValue,
                          });
                      }
                    }
                  }}
                >
                  <Mention
                    trigger="@"
                    data={userList}
                    // renderSuggestion={renderPreset}
                  />
                </MentionsInput>
              </div>
              <div className="helper-bar flex flex-row justify-between items-center w-full">
                <div className="left-icons px-4">
                  <Space>
                    {enableEmojis && (
                      <EmojiPicker
                        onEmojiPicked={(emoji) => {
                          setNotesInputValue(notesInputValue + emoji);
                        }}
                      />
                    )}
                    {enableAttachments && (
                      <Button
                        type="link"
                        icon={<i className="ri-attachment-line"></i>}
                      />
                    )}
                  </Space>
                </div>

                <div className="right-side-content flex flex-row items-center px-2">
                  <Button
                    icon={<i className="ri-save-2-line"></i>}
                    type="primary"
                    onClick={() => {
                      onSaveNotes &&
                        onSaveNotes({
                          text: notesInputValue,
                        });
                    }}
                  >
                    Save Note
                  </Button>
                </div>
              </div>
            </TabPane>
          )}
        </Tabs>
        {/* Actions Bar */}
        <ActionsBar form={actionsForm} />
      </div>
    );
  }
);

export const FileUploadSection = ({
  fileList,
  isUploading,
  uploadingFiles,
  setFileList,
  onUpload,
}: {
  fileList: any[];
  isUploading: boolean;
  uploadingFiles: string[];
  setFileList: (list) => any;
  onUpload: (file) => any;
}) => {
  const onPreview = async (file) => {
    let src = file.url;
    if (src) {
      const win = window.open(src, "_blank");
      win && win.focus();
      return;
    }
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    if (imgWindow) {
      imgWindow.document.write(image.outerHTML);
    }
  };

  return (fileList.length || isUploading || uploadingFiles.length > 0) > 0 ? (
    <div className="attachments-container p-4 relative">
      <Upload
        // action={(file) => uploadFileWithLoading(file)}
        customRequest={onUpload}
        listType="picture-card"
        fileList={fileList as any}
        onRemove={(item) => {
          setFileList(_.filter(fileList, (itemX) => item.uid !== itemX.uid));
        }}
        onPreview={onPreview}
        // showUploadList={true}
        openFileDialogOnClick={true}
      >
        {isUploading || uploadingFiles.length > 0 ? (
          <Badge count={uploadingFiles.length}>
            <LoadingIndicatorWithSpin />
          </Badge>
        ) : fileList.length < 5 ? (
          "+ Upload"
        ) : (
          ""
        )}
      </Upload>
      {fileList.length > 0 && (
        <div style={{ right: 5, top: 50 }} className="absolute">
          <Tooltip title="Clear Attachments">
            <Button
              onClick={() => setFileList([])}
              icon={<i className="ri-delete-bin-line"></i>}
              type="text"
              shape="circle"
            />
          </Tooltip>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};
