import { useSDK } from "@sdk";
import { Button, Col, Modal, Row, message } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import "./download-app.style.scss";

export const DownloadAppModal = ({
  visible,
  onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const { data, error, isLoading } = useSDK(
    SDK => SDK.getAppDownloadLinks(),
    []
  );

  const downloadLinks = useMemo(() => {
    if (data) {
      const downloadData: {
        windows?: string;
        mac?: string;
        linux?: string;
        version?: string;
        date?: string;
      } = {
        version: data.tag_name,
        date: dayjs(data.published_at).format("LL h:mm:ss a ")
      };
      const matchedAssets = (data?.assets || []).filter(
        item => !item.browser_download_url.includes(".blockmap")
      );
      for (const asset of matchedAssets) {
        if (asset.browser_download_url.indexOf(".dmg") > -1) {
          downloadData.mac = asset.browser_download_url;
        } else if (asset.browser_download_url.indexOf(".exe") > -1) {
          downloadData.windows = asset.browser_download_url;
        } else if (asset.browser_download_url.indexOf(".deb") > -1) {
          downloadData.linux = asset.browser_download_url;
        }
      }
      return downloadData;
    } else {
      return {};
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      message.error("Something went wrong while fetching the latest software");
    }
  }, [error]);

  return (
    <Modal
      title={
        <ModalTitle
          title={"Download Desktop App"}
          icon={<i className="ri-mac-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Download App Modal"
      width={800}
    >
      <div className="download-app-container flex flex-col">
        <div className="flex flex-col justify-center items-center">
          <div className="text-center">
            {" "}
            Go ahead and download the desktop version available for all three
            platforms and stat feeling productive
          </div>
          <div className="text-center">{downloadLinks.version}</div>
          <div className="text-center">{downloadLinks.date}</div>
        </div>

        <Row className="flex-1 pb-10">
          <Col
            className="flex flex-col justify-center items-center"
            flex="auto"
          >
            <i className="ri-apple-fill text-8xl mb-5"></i>
            {!error && (
              <Button
                type="dashed"
                icon={<i className="ri-download-2-line"></i>}
                size="large"
                loading={isLoading}
                href={downloadLinks?.mac}
                disabled={!isLoading && !downloadLinks.mac}
              >
                {!isLoading && !downloadLinks.mac
                  ? "Not Available"
                  : "Download"}
              </Button>
            )}
          </Col>
          <Col
            className="flex flex-col justify-center items-center"
            flex="auto"
          >
            <i className="ri-windows-fill text-8xl mb-5"></i>

            {!error && (
              <Button
                type="dashed"
                icon={<i className="ri-download-2-line"></i>}
                size="large"
                loading={isLoading}
                href={downloadLinks?.windows}
                disabled={!isLoading && !downloadLinks.windows}
              >
                {!isLoading && !downloadLinks.windows
                  ? "Not Available"
                  : "Download"}
              </Button>
            )}
          </Col>
          <Col
            className="flex flex-col justify-center items-center"
            flex="auto"
          >
            <i className="ri-ubuntu-fill text-8xl mb-5"></i>
            {!error && (
              <Button
                type="dashed"
                icon={<i className="ri-download-2-line"></i>}
                size="large"
                loading={isLoading}
                href={downloadLinks?.linux}
                disabled={!isLoading && !downloadLinks.linux}
              >
                {!isLoading && !downloadLinks.linux
                  ? "Not Available"
                  : "Download"}
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <DarkModeBg />
    </Modal>
  );
};
