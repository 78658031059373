import styled from "styled-components";
import { LightenDarkenColor } from "utils/color-utils";
import { GenerateColorTheme } from "./generate-theme";

export const StyledWidgetContainer = styled.div`
  ${props => {
    const primaryColor = props.color || "#000000";

    const additionalStyle = `
    .chat-widget-avatar-container {
      border:2px solid ${primaryColor};
    }
    `;
    return additionalStyle + "\n" + GenerateColorTheme(primaryColor, "#333");
  }}
`;

export const _StyledWidgetContainer = styled.div`
  ${props => {
    const primaryColor = props.color || "#000000";

    const additionalStyle = `
    .chat-widget-avatar-container {
      border:2px solid ${primaryColor};
    }
    `;

    return `
    ${additionalStyle}
    .main-header {
      background-color: ${primaryColor};
    }
    .primary-button {
      background: ${primaryColor};
      color:#fff;
    }
    .primary-button:hover{
      background: ${LightenDarkenColor(primaryColor, 10)};
    }
  
    .secondary-button {
      background: ${LightenDarkenColor(primaryColor, 210)};
      color: ${primaryColor};
    }
    .secondary-button:hover{
      background: ${LightenDarkenColor(primaryColor, 200)};
    }
  
    .quick-reply-pill {
      border: 2px solid ${primaryColor};
      color: ${primaryColor}
    }
  
    .quick-reply-pill:hover {
      background: ${LightenDarkenColor(primaryColor, 200)};
    }
  
    .primary-bg {
      background: ${primaryColor};
    }
  
    .article-collection-heading {
      background: ${LightenDarkenColor(primaryColor, 200)};
      color: ${primaryColor}
    }
  
  
    .chat-messages .message-row.contact .bubble{
      color: rgba(0, 0, 0, 0.8);
      background-color: ${
        LightenDarkenColor(primaryColor, 210) === "#ffffff"
          ? "#f6f6f6"
          : LightenDarkenColor(primaryColor, 210)
      };
    }
  
    .chat-messages .message-row.me .bubble{
      color:#fff;
      background-color: ${primaryColor};
    }
  
  
    .primary-gradient-bg {
      background: ${primaryColor};
      background: radial-gradient(${primaryColor}, ${LightenDarkenColor(
      primaryColor,
      -20
    )});
    }
  
    .primary-gradient-bg-2 {
      background: ${primaryColor};
      background: radial-gradient(${primaryColor}, ${LightenDarkenColor(
      primaryColor,
      -40
    )});
    }
  
    .switch {
      --thumb-color-off:${LightenDarkenColor(primaryColor, 150)};
      --thumb-color-on:${primaryColor};
      --color-bg-on: ${LightenDarkenColor(primaryColor, 215)};
    }
    .switch__gfx{
      width:35px;
    }
    `;
  }}
`;
