import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { Button, Divider, Tag, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import modal from "antd/lib/modal";
import { EditableTextArea } from "components/common/editable-text/editable-text";
import { ModalTitle } from "components/common/modal-title";
import _ from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Node,
  useReactFlow,
  useUpdateNodeInternals,
} from "react-flow-renderer";
import { useSelector } from "react-redux";
import { selectOrganizationId } from "store/modules/workspace/workspace.selectors";
import { getTokens } from "utils/extract-tokens";
import { uuidv4 } from "utils/generate-uuid";
import { useViewRefresher } from "utils/hooks/use-view-refresher";
import { DragHandler } from "../../components/drag-handler/drag-handler";
import { DragPreventer } from "../../components/drag-preventer/drag-preventer";
import {
  AvailableNodes,
  iGPTAssistantNode,
  iMessageNode,
} from "../../data-model";
import { useSetNode } from "../../utils/use-set-node";
import { ChatFlowHandle } from "../chat-flow-handle";
import "./gpt-assistant-node.scss";

export const AvailableTokensForBots = [
  "CONTACT_FIRST_NAME",
  "CONTACT_LAST_NAME",
  "CONTACT_EMAIL",
  "CONTACT_PHONE",
  "WORKSPACE_NAME",
];

export const AvailableTokenTableForBots = [
  {
    token: "CONTACT_FIRST_NAME",
    label: "Customer's First Name",
  },
  {
    token: "CONTACT_LAST_NAME",
    label: "Customer's Last Name",
  },
  {
    token: "CONTACT_EMAIL",
    label: "Customer's Email",
  },
  {
    token: "CONTACT_PHONE",
    label: "Customer's Phone Number",
  },
  {
    token: "WORKSPACE_NAME",
    label: "Your Workspace Name",
  },
];

export const GPTAssistantNode = memo(
  ({ data, id }: { id: string; data: iGPTAssistantNode }) => {
    const organizationId = useSelector(selectOrganizationId);
    const setNode = useSetNode<iGPTAssistantNode>(id);
    const { getNode, setNodes, setEdges, getNodes, getEdges, getEdge } =
      useReactFlow<AvailableNodes, any>();

    const updateNodeInternals = useUpdateNodeInternals();
    const { visible: isAddQuickReplyVisible, reRender } = useViewRefresher();

    const [form] = useForm();

    const [configVisibility, setConfigVisibility] = useState(false);

    useEffect(() => {
      updateNodeInternals(id);
    }, [id, updateNodeInternals, configVisibility, data]);

    useEffect(() => {
      const timer = setTimeout(() => {
        updateNodeInternals(id);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }, [id, updateNodeInternals]);

    const cloneNode = useCallback(() => {
      const node = getNode(id) as Node<iMessageNode>;
      const currentNodePosition = node.position;
      const newNodeId = uuidv4();
      const order = 0;
      const newNode = {
        ..._.pick(_.cloneDeep(node), ["type", "data"]),
        id: newNodeId,
        position: {
          x: currentNodePosition.x + 320 + 100,
          y: currentNodePosition.y + (order || 0) * 100,
        },
      };
      setNodes([...getNodes(), newNode]);
    }, [getNode, getNodes, id, setNodes]);

    const deleteNode = useCallback(() => {
      setNodes(_.filter([...getNodes()], (node) => node.id !== id));
    }, [getNodes, id, setNodes]);

    const capturedTokens = useMemo(() => {
      const tokens = getTokens(data?.text || "");
      // console.log("tokens", tokens);
      const validTokens = tokens.filter((token) =>
        AvailableTokensForBots.includes(token),
      );

      // console.log("validTokens", validTokens, AvailableTokensForBots);
      const invalidTokens = tokens.filter(
        (token) => !AvailableTokensForBots.includes(token),
      );

      // console.log("invalidTokens", invalidTokens, AvailableTokensForBots);
      return { validTokens, invalidTokens, all: tokens };
    }, [data?.text]);

    const { triggerTempModal } = useModalPanels();

    return (
      <div className="p-4 gpt-assistant-node-container node-container group border-2 dark:border-black hover:border-blue-600">
        <div className="hidden group-hover:show">
          <div
            className="hover-action-box flex flex-row gap-4 justify-center items-center absolute w-full left-0 pb-4"
            style={{ top: -40 }}
          >
            <Tooltip title="Delete Block">
              <Button
                type="link"
                onClick={(e) => {
                  deleteNode();
                }}
                icon={<i className="ri-delete-bin-5-line"></i>}
              />
            </Tooltip>
            <Tooltip title="Clone Block">
              <Button
                type="link"
                onClick={(e) => {
                  cloneNode();
                }}
                icon={<i className="ri-file-copy-line"></i>}
              />
            </Tooltip>
          </div>
        </div>

        <div className="w-full flex flex-col items-center justify-center -mt-6">
          <Tag className="font-bold dark:bg-black" color={"blue"}>
            Magic Assistant
          </Tag>
        </div>

        <div className="drag-handler-container flex flex-row justify-center items-center">
          <DragHandler />
        </div>

        <ChatFlowHandle handleId="DEFAULT" nodeId={id} type="DEFAULT" />
        <ChatFlowHandle handleId="INPUT" nodeId={id} type="INPUT" />

        <div>
          <DragPreventer>
            <EditableTextArea
              value={data?.text || ""}
              placeholder="Your Message"
              onSave={(text) => {
                setNode({
                  text,
                });
              }}
            />
          </DragPreventer>
          <div className="flex flex-row justify-end items-center">
            <Button
              type="link"
              onClick={() => {
                const modalRef = modal.info({
                  icon: null,
                  title: (
                    <ModalTitle
                      title="Message Personalization"
                      icon={<i className="ri-braces-line"></i>}
                    />
                  ),
                  content: (
                    <div>
                      <Divider />
                      <div className="mt-4">
                        You can use variable tokens in your messages
                        <div className="font-bold my-4">Available Tokens:</div>
                        <table className="simple-table-style w-full">
                          <thead>
                            <tr>
                              <th className="font-bold">Variable</th>
                              <th className="font-bold">Token</th>
                            </tr>
                          </thead>
                          <tbody>
                            {AvailableTokenTableForBots.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.label}</td>
                                  <td>
                                    <Tag
                                      onClick={() => {
                                        setNode({
                                          text: `${data.text} {${item.token}}`,
                                        });
                                        modalRef.destroy();
                                      }}
                                      className="cursor-pointer"
                                    >
                                      {item.token}
                                    </Tag>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ),
                });
              }}
            >
              Personalization{" "}
              {capturedTokens.validTokens.length > 0
                ? `(${capturedTokens.validTokens.length})`
                : ""}
            </Button>
          </div>
          <div>
            {capturedTokens.invalidTokens.length !== 0 && (
              <div className="token-container">
                <div className="font-bold my">Invalid Tokens:</div>
                {capturedTokens.invalidTokens.map((item, index) => (
                  <Tag key={index.toString()}>{item}</Tag>
                ))}
              </div>
            )}
          </div>
          <div className="text-gray-600">
            If the Magic Assistant is not able to resolve the query, the flow
            will continue
          </div>
        </div>
      </div>
    );
  },
);
