// import "@vime/core/themes/default.css";
import { Modal } from "antd";
import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React, { Suspense } from "react";

const ReactPlayer = React.lazy(() => import("react-player"));

export const showVideoModal = (url) => {
  Modal.info({
    title: "",
    icon: "",
    content: (
      <div className="w-full h-full flex flex-row justify-center items-center">
        <Suspense fallback={<SuspenseLoadingIndicator />}>
          <ReactPlayer url={url} controls={true} light={true} />
        </Suspense>
      </div>
    ),
    okText: "Close",
    width: 800,
  });
};
