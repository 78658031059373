import { stringToNumberHash } from "utils/string-to-number-hash";
import { getAnimalName } from "./random-animal-names";

export class AnonymousAvatarsService {
  static basePath = "/assets/avatars/anonymous-avatars";

  static getAnonymousAvatar(id: string) {
    return `${this.basePath}/${getAnimalName(id)}.png`;
  }

  static getAnonymousAvatarColor(id: string) {
    const pastelColors = [
      "#1abc9c",
      "#2ecc71",
      "#3498db",
      "#9b59b6",
      "#34495e",
      "#16a085",
      "#27ae60",
      "#2980b9",
      "#8e44ad",
      "#2c3e50",
      "#f1c40f",
      "#e67e22",
      "#e74c3c",
      "#ecf0f1",
      "#95a5a6",
      "#f39c12",
      "#d35400",
      "#c0392b",
      "#bdc3c7",
      "#7f8c8d"
    ];

    // tslint:disable-next-line:max-line-length
    const materialColors = [
      "#f44336",
      "#e91e63",
      "#9c27b0",
      "#673ab7",
      "#3f51b5",
      "#2196f3",
      "#03a9f4",
      "#00bcd4",
      "#009688",
      "#4caf50",
      "#8bc34a",
      "#cddc39",
      "#ffeb3b",
      "#ffc107",
      "#ff9800",
      "#ff5722",
      "#795548",
      "#9e9e9e",
      "#607d8b"
    ];

    const numberHash = stringToNumberHash(id);
    const colourIndex = numberHash % materialColors.length;
    return materialColors[Math.abs(colourIndex - 1)];
  }

  static getAnonymousName(id: string) {
    return getAnimalName(id);
  }
}
