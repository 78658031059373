import { iStore } from "../../store.model";

export const selectSideBarIsCollapsed = (state: iStore) =>
  state.uiState.sideBar.isCollapsed;
export const selectIsDarkMode = (state: iStore) =>
  state.uiState.theme?.isDarkMode;

export const selectLastAutoCancelScheduleMessagePreference = (state: iStore) =>
  state.uiState.conversationView.lastAutoCancelScheduleMessagePreference;
export const selectEnteredMessage = conversationId => (state: iStore) =>
  state.uiState.conversationView.enteredMessages?.[conversationId];

export const selectIsAdvancedMode = (state: iStore) =>
  state.uiState.isAdvancedMode;
export const persistorSelector = (state: any) => state._persist;

export const selectAwayDetectorAlert = (state: iStore) =>
  state.uiState.awayDetectorAlert?.isActive || false;

export const selectUIState = (state: iStore) => state.uiState;

export const selectIsElectronMode = (state: iStore) =>
  state.uiState.isElectronMode;

export const selectIsPinned = (state: iStore) =>
  state.uiState.windowState?.isPinned;

export const selectWindowState = (state: iStore) => state.uiState.windowState;

export const selectWindowType = (state: iStore) =>
  state.uiState.windowState?.windowMode;

export const selectApplicationMode = (state: iStore) =>
  state.uiState.applicationMode;
