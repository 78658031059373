import { Button, Dropdown, Menu, Space, Tooltip } from "antd";
import { iEmailSignature } from "../signature-manager-state-manager";
import "./email-signature-selector.scss";

import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { MyEmailSignatures } from "modal-registry";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { selectCurrentUserSignatures } from "store/modules/users/users.selectors";

export const AddSignatureButton = ({
  onSignatureSelect,
  showRichText,
}: {
  onSignatureSelect: (signature: iEmailSignature) => any;
  showRichText?: boolean;
}) => {
  const { changePanelState } = useModalPanels();

  const signatures = useSelector(selectCurrentUserSignatures);

  const isMobileView = useMediaQuery({ query: "(max-width: 500px)" });

  const menu = (
    <Menu>
      {signatures.map((signature) => (
        <Menu.Item
          icon={<i className="ri-ball-pen-line"></i>}
          key={signature.id}
          onClick={() => onSignatureSelect(signature)}
        >
          {signature.label}
        </Menu.Item>
      ))}
      {signatures.length === 0 && (
        <Menu.Item
          icon={<i className="ri-ball-pen-line"></i>}
          key={"no-signature"}
          onClick={() => changePanelState(MyEmailSignatures, true, {})}
        >
          No Saved Signature - Create Now
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Tooltip title="Email Signature">
        <Button
          type="text"
          // className="font-semibold"
          icon={<i className="ri-quill-pen-line"></i>}
        >
          <Space>
            {!isMobileView && (
              <>
                <i className="ri-arrow-down-s-line"></i>
              </>
            )}
          </Space>
        </Button>
      </Tooltip>
    </Dropdown>
  );
};
