import { useSelector } from "react-redux";
import { selectOrganizationOnboardingState } from "store/modules/workspace/workspace.selectors";

import { AppsumoCustomizeExperienceModal } from "./appsumo-onboarding/appsumo-onboarding-experience";
import { ShopifyCustomizeExperienceModal } from "./shopify-onboarding/shopify-onboarding-experience";
import { StandardCustomizeExperienceModal } from "./standard-onboarding/standard-onboarding-experience";
import { SubAccountCustomizeExperienceModal } from "./sub-account-onboarding/sub-account-onboarding-experience";
import { WhatsappCustomizeExperienceModal } from "./whatsapp-onboarding/whatsapp-onboarding-experience";

const CustomizeExperienceModal = (props: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const onboardingState = useSelector(selectOrganizationOnboardingState);

  if (onboardingState?.onboardingFlow === "WHATSAPP_TEAM_INBOX") {
    return <WhatsappCustomizeExperienceModal {...props} />;
  }
  if (onboardingState?.onboardingFlow === "SHOPIFY_CONNECT") {
    return <ShopifyCustomizeExperienceModal {...props} />;
  }
  if (onboardingState?.onboardingFlow === "APP_SUMO") {
    return <AppsumoCustomizeExperienceModal {...props} />;
  }
  if (onboardingState?.onboardingFlow === "SUB_ACCOUNT") {
    return <SubAccountCustomizeExperienceModal {...props} />;
  }
  return <StandardCustomizeExperienceModal {...props} />;
};

export default CustomizeExperienceModal;
