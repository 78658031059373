import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import { iArticleCollection } from "@sdk/knowledge-base/knowledge-base.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Dropdown, Menu, message, Popconfirm, Tag } from "antd";
import {
  EditableText,
  EditableTextArea,
} from "components/common/editable-text/editable-text";
import { ImageX } from "components/common/image";
import { SimpleCollapsePane } from "components/common/simple-collapse-pane/simple-collapse-pane";
import copyToClipboard from "copy-to-clipboard";
import _ from "lodash";
import { useState } from "react";
import { useStore } from "react-redux";
import { DeepPartial } from "redux";
import { loadAllArticleCollections } from "store/modules/article-collection/article-collection.helpers";
import { loadArticlesOfCollection } from "store/modules/articles/articles.helpers";
import { selectArticleByCollectionIdFromQuery } from "store/modules/articles/articles.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { ArticleList } from "../article-list";
import "./collection-item.scss";

const SingleCollection = ({
  collection,
  index,
  onChangeIcon,
  onEditArticle,
  onPreviewArticle,
  onAddArticle,
}: {
  collection: iArticleCollection;
  index: number;
  onChangeIcon: () => any;
  onEditArticle: (articleId?: string) => any;
  onPreviewArticle: (articleId?: string) => any;
  onAddArticle: () => any;
}) => {
  const [isMenuVisible, SetIsMenuVisible] = useState(false);
  const saveTitle = (text?: string) => {
    editCollection({
      id: collection.id,
      label: text,
    });
  };
  const saveContent = (text?: string) => {
    editCollection({
      id: collection.id,
      description: text,
    });
  };

  const handleVisibleChange = (flag) => {
    SetIsMenuVisible(flag);
  };

  const menu = (
    <Menu data-click-context="Collection More Menu">
      <Menu.Item key="1">
        <Popconfirm
          title="Are you sure to delete this？"
          onConfirm={() => deleteCollection()}
        >
          <Button
            type="link"
            href="#"
            icon={<i className="ri-delete-bin-line"></i>}
          >
            Delete
          </Button>
        </Popconfirm>
      </Menu.Item>
      {/* Todo */}
      {collection?.metaData?.isHidden ? (
        <Menu.Item key="VISIBLE">
          <Button
            type="text"
            icon={<i className="ri-eye-line"></i>}
            onClick={() =>
              editCollection({
                id: collection.id!,
                metaData: {
                  isHidden: false,
                },
              })
            }
          >
            Un-hide Collection
          </Button>
        </Menu.Item>
      ) : (
        <Menu.Item key="HIDE">
          <Button
            type="text"
            icon={<i className="ri-eye-off-line"></i>}
            onClick={() =>
              editCollection({
                id: collection.id!,
                metaData: {
                  isHidden: true,
                },
              })
            }
          >
            Hide Collection
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="COPY">
        <Button
          type="text"
          icon={<i className="ri-clipboard-line"></i>}
          onClick={() => {
            copyToClipboard(collection.id!);
            message.success("Copied");
          }}
        >
          Copy Collection ID
        </Button>
      </Menu.Item>
    </Menu>
  );

  const {
    doAction: editCollection,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values: DeepPartial<iArticleCollection>) =>
        SDK.collections
          .editById(values.id!, _.omit(values, "id"))
          .then((res) => {
            //
          }),
      successMessage: "Collection has been edited",
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const store = useStore();

  const { doAction: deleteCollection } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () =>
        SDK.collections
          .deleteById(collection.id!)
          .then((res) => {
            //
          })
          .then((d) => {
            loadAllArticleCollections(store, true);
          }),
      successMessage: "Collection has been removed",
      failureMessage: "Something went wrong",
    }),
    [collection.id, store],
  );

  const [isArticlesVisible, setArticlesVisible] = useState(false);

  const {
    state: { totalItems },
    retry: reload,
  } = useQueryWithStore(
    selectArticleByCollectionIdFromQuery(collection.id!),
    loadArticlesOfCollection(collection.id!),
    [collection.id!],
    !collection.id!,
  );

  return (
    <div className="my-4 border-gray-200 dark:border-gray-800 border rounded-md flex collection-item draggable-collection mode_transition">
      <div className="w-full border-0 bg-white dark:bg-gray-900 rounded-lg  mode_transition">
        <div className="flex flex-row justify-center items-center">
          <div className="flex flex-row flex-1 items-center p-8 relative">
            <div className="flex items-center w-20">
              <ImageX
                src={collection.icon || "/knowledge-base/icons/article.png"}
                width={85}
                onClick={onChangeIcon}
                preview={false}
                className="cursor-pointer rounded-lg"
              />
            </div>
            <div className="flex-1 flex flex-col justify-center pl-4">
              <div className="text-xl text-gray-900 dark:text-gray-100">
                <EditableText
                  value={collection.label}
                  onSave={saveTitle}
                  className="font-bold"
                />
              </div>
              <div className=" text-gray-600 dark:text-gray-400 mode_transition">
                <EditableTextArea
                  value={collection.description}
                  placeholder="No Description Added"
                  onSave={saveContent}
                  className="h-auto"
                />
              </div>
            </div>
            <div className="" onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={menu}
                placement="bottomRight"
                onOpenChange={handleVisibleChange}
                open={isMenuVisible}
              >
                <Button type="link">
                  <EllipsisOutlined className="text-2xl" />
                </Button>
              </Dropdown>
            </div>
            {/* Is Hidden */}
            {collection?.metaData?.isHidden && (
              <div className="absolute" style={{ right: 25, top: 25 }}>
                <Tag>Hidden</Tag>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <Tag
            className="cursor-pointer m-0"
            onClick={() => setArticlesVisible(!isArticlesVisible)}
          >
            {totalItems} articles
          </Tag>
          <Button
            type="link"
            onClick={() => setArticlesVisible(!isArticlesVisible)}
            block
          >
            {isArticlesVisible ? (
              <i className="ri-arrow-up-s-line text-2xl"></i>
            ) : (
              <i className="ri-arrow-down-s-line text-2xl"></i>
            )}
          </Button>
        </div>

        <SimpleCollapsePane isCollapsed={isArticlesVisible}>
          <ArticleList
            collectionId={collection.id!}
            onEditArticle={onEditArticle}
            onPreviewArticle={onPreviewArticle}
            onAddArticle={onAddArticle}
          />
        </SimpleCollapsePane>
      </div>
    </div>
  );
};
export default SingleCollection;
