import { iHolidayQuote } from "@sdk/holiday-quotes/holiday-quotes-model";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectHolidayQuoteQueryMap,
  selectEntityMap: selectHolidayQuotesMap,
  selectQueryMapAll: selectHolidayQuotesMapAll,
  selectQueryAllList: selectHolidayQuotesAllList,
  selectAllEntities: selectAllHolidayQuotes,
  selectAllEntitiesQuery: selectAllHolidayQuotesQuery,
  selectEntityById: selectHolidayQuoteById,
  selectEntityQuery: selectHolidayQuoteQuery,
  selectEntityQueryList: selectHolidayQuoteQueryList
} = CreateSelectorForEntities<iStore, iHolidayQuote>({
  sliceName: "holidayQuotes"
});

export const selectHolidayQuoteByConnectionId = (connectionId: string) => (
  state: iStore
) => {
  return _.find(selectAllHolidayQuotes(state), {
    connectionId
  });
};
