import { iArticleCollection } from "@sdk/knowledge-base/knowledge-base.models";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectCCArticleCollectionQueryMap,
  selectEntityMap: selectCCArticleCollectionsMap,
  selectQueryMapAll: selectCCArticleCollectionsMapAll,
  selectQueryAllList: selectCCArticleCollectionsAllList,
  selectAllEntities: selectAllCCArticleCollections,
  selectAllEntitiesQuery: selectAllCCArticleCollectionsQuery,
  selectEntityById: selectCCArticleCollectionById,
  selectEntityQuery: selectCCArticleCollectionsQuery
} = CreateSelectorForEntities<iStore, iArticleCollection>({
  sliceName: "ccArticleCollections"
});
