import { useSDK } from "@sdk";
import { Button } from "antd";
import { ActivityIndicator } from "components/common/activity-indicator/activity-indicator.component";
import { EmptyData } from "components/common/empty-data/empty-data";
import dayjs from "dayjs";
import { flattenDeep, sumBy } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { convertMillisecondsToMinutesAndSeconds } from "utils/convert-milliseconds-to-minutes-and-seconds";
import { convertMillisecondsToReadableString } from "utils/convert-milliseconds-to-readable-string";

export const MyActivityToday = ({
  date,
  userId: _userId
}: {
  date: any;
  userId?: string;
}) => {
  const currentUserId = useSelector(selectCurrentUserId);
  const userId = _userId || currentUserId;

  const { data, reload } = useSDK(
    SDK =>
      SDK.queryUserPresenceLogs({
        query: {
          startedTime: {
            $gt: dayjs(date)
              .startOf("day")
              .valueOf(),
            $lt: dayjs(date)
              .endOf("day")
              .valueOf()
          },
          userId: userId ? userId : undefined
        },
        options: { limit: 500 }
      }),
    [date, userId],
    false,
    {}
  );

  const {
    startTime,
    endTime,
    activities,
    totalConnectedTime,
    totalActiveTime
  } = useMemo(() => {
    const colors = {
      AVAILABLE: "#4caf50",
      BUSY: "#f44336",
      AWAY: "#ffc107"
    };

    const totalConnectedTime = sumBy(
      (data?.docs || []).filter(item => item.connected),
      log => {
        return log.endedTime - log.startedTime;
      }
    );

    const totalActiveTime = sumBy(
      flattenDeep(
        (data?.docs || [])
          .filter(item => item.connected)
          .map(item => item.windowVisibilityLogs)
      ).filter(item => item.visibility),
      log => {
        return log.endedTime - log.startedTime;
      }
    );

    return {
      startTime: dayjs(date)
        .startOf("day")
        .valueOf(),
      endTime: dayjs(date)
        .endOf("day")
        .valueOf(),
      totalConnectedTime,
      totalActiveTime,
      activities: (data?.docs || []).map(log => ({
        id: log.id!,
        start: log.startedTime,
        end: log.endedTime,
        color: colors[log.status],
        title: `${convertMillisecondsToMinutesAndSeconds(
          log.endedTime - log.startedTime
        )}\n  (Active |${convertMillisecondsToMinutesAndSeconds(
          sumBy(
            log.windowVisibilityLogs.filter(item => item.visibility),
            log => log.endedTime - log.startedTime
          )
        )})`
      }))
    };
  }, [data?.docs, date]);

  if ((data.docs || []).length === 0) {
    return <EmptyData text="Not enough data" />;
  }

  return (
    <div className="flex flex-col">
      <div className="flex flew-row items-center">
        <ActivityIndicator
          activities={activities}
          // startTime={startTime}
          // endTime={endTime}
        />

        <Button
          type="text"
          icon={<i className="ri-refresh-line"></i>}
          onClick={reload}
        ></Button>
      </div>
      <div className="text-center text-gray-500 text-sm">
        Total Connected Time:{" "}
        {convertMillisecondsToReadableString(totalConnectedTime)}
      </div>
      <div className="text-center text-gray-500 text-sm">
        Total Active Screen Time:{" "}
        {convertMillisecondsToReadableString(totalActiveTime)}
      </div>
    </div>
  );
};
