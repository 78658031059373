import { useMemo } from "react";

export const ShareBlock = ({
  text: _text,
  url: _url
}: {
  text: string;
  url: string;
}) => {
  const { text, url } = useMemo(() => {
    return {
      text: encodeURIComponent(_text),
      url: encodeURIComponent(_url)
    };
  }, [_text, _url]);
  return (
    <>
      <div className="sharing-buttons flex flex-wrap">
        <a
          className="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-3 rounded-lg text-white  bg-black hover:bg-gray-900 hover:border-blue-700"
          target="_blank"
          rel="noreferrer"
          href={`https://wa.me/?text=${text}%20${url}`}
          aria-label="Share on Whatsapp"
          draggable="false"
        >
          <svg
            aria-hidden="true"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-6 h-6"
          >
            <title>Whatsapp</title>
            <path d="M413 97A222 222 0 0 0 64 365L31 480l118-31a224 224 0 0 0 330-195c0-59-25-115-67-157zM256 439c-33 0-66-9-94-26l-7-4-70 18 19-68-4-7a185 185 0 0 1 287-229c34 36 56 82 55 131 1 102-84 185-186 185zm101-138c-5-3-33-17-38-18-5-2-9-3-12 2l-18 22c-3 4-6 4-12 2-32-17-54-30-75-66-6-10 5-10 16-31 2-4 1-7-1-10l-17-41c-4-10-9-9-12-9h-11c-4 0-9 1-15 7-5 5-19 19-19 46s20 54 23 57c2 4 39 60 94 84 36 15 49 17 67 14 11-2 33-14 37-27s5-24 4-26c-2-2-5-4-11-6z"></path>
          </svg>
        </a>
        <a
          className="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-3 rounded-lg text-white  bg-black hover:bg-gray-900 hover:border-blue-700"
          target="_blank"
          rel="noreferrer"
          href={`https://telegram.me/share/url?text=${text}%20Plan&amp;url=${url}%2F`}
          aria-label="Share on Telegram"
          draggable="false"
        >
          <svg
            aria-hidden="true"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-6 h-6"
          >
            <title>Telegram</title>
            <path d="M256 8a248 248 0 1 0 0 496 248 248 0 0 0 0-496zm115 169c-4 39-20 134-28 178-4 19-10 25-17 25-14 2-25-9-39-18l-56-37c-24-17-8-25 6-40 3-4 67-61 68-67l-1-4-5-1q-4 1-105 70-15 10-27 9c-9 0-26-5-38-9-16-5-28-7-27-16q1-7 18-14l145-62c69-29 83-34 92-34 2 0 7 1 10 3l4 7a43 43 0 0 1 0 10z"></path>
          </svg>
        </a>
        <a
          className="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-3 rounded-lg text-white  bg-black hover:bg-gray-900 hover:border-blue-700"
          target="_blank"
          rel="noreferrer"
          href={`mailto:?subject=${text}&amp;body=${url}%2F`}
          aria-label="Share by Email"
          draggable="false"
        >
          <svg
            aria-hidden="true"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-6 h-6"
          >
            <title>Email</title>
            <path d="M464 64a48 48 0 0 1 29 86L275 314c-11 8-27 8-38 0L19 150a48 48 0 0 1 29-86h416zM218 339c22 17 54 17 76 0l218-163v208c0 35-29 64-64 64H64c-35 0-64-29-64-64V176l218 163z"></path>
          </svg>
        </a>
      </div>
    </>
  );
};
