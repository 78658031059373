import { Select } from "antd";
import { loadAllContactLists } from "store/modules/contact-lists/contact-lists.helpers";
import { selectAllContactLists } from "store/modules/contact-lists/contact-lists.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const ContactListSelector = ({
  value,
  onChange
}: {
  value?: string;
  onChange?: (listId: string) => any;
}) => {
  const { state: allContactLists, retry: reload } = useQueryWithStore(
    selectAllContactLists,
    loadAllContactLists
  );

  return (
    <Select
      placeholder="Select a list"
      value={value}
      onChange={onChange}
      style={{ minWidth: 300 }}
    >
      {allContactLists.map(list => (
        <Select.Option value={list.id} key={list.id}>
          <span>{list.data.label}</span>
        </Select.Option>
      ))}
    </Select>
  );
};
