import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { SDK } from "@sdk";
import {
  FeatureRequestsData,
  iAppFeatureRequest,
} from "@sdk/app-feature-requests/app-feature-requests-model";
import {
  useSDKActionWithDeps,
  useSDKWithRemotePagination,
} from "@sdk/sdk.hooks";
import {
  Badge,
  Button,
  Input,
  Pagination,
  Select,
  Space,
  Spin,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import classNames from "classnames";
import { EmptyData } from "components/common/empty-data/empty-data";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { escapeRegExp } from "lodash";
import {
  EditFeatureRequest,
  FeatureViewer,
  RequestFeature,
} from "modal-registry";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { selectOrganizationId } from "store/modules/workspace/workspace.selectors";
import { useDebouncedValue } from "utils/hooks/use-debounced-value";
import { useUrlQuery } from "utils/hooks/use-url-query";

const statusOptions = [
  ...[
    "In Review",
    "In Backlog",
    "Selected for Build",
    "In Build",
    "Released",
  ].map((item) => ({
    value: item,
    label: item,
  })),
  {
    value: "Other",
    label: "Other",
  },
];

export const FeatureRequests = () => {
  const { triggerTempModal } = useModalPanels();

  const [_searchText, setSearchText] = useState("");
  const [searchText] = useDebouncedValue(_searchText, 600);

  const scrollableViewRef = useRef<HTMLDivElement>(null);

  const currentUserId = useSelector(selectCurrentUserId);

  const isAdvancedMode = useSelector(selectIsAdvancedMode);
  const [showInReview, setShowInReview] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  const {
    paginationState,
    setPaginationState,
    data: itemsInView,
    setData,
    isLoading,
    reload: resetAndReloadData,
  } = useSDKWithRemotePagination(
    (SDK) => (query, options) =>
      SDK.appFeatureRequests.query({ query, options }),
    {
      type: "FEATURE_REQUEST",
      isDeleted: { $ne: true },
      isArchived: { $ne: true },
      $and: [
        {
          $or: [
            showInReview
              ? {}
              : {
                  status: { $nin: ["In Review"] },
                },
            {
              "submittedBy.userId": currentUserId,
            },
          ].filter((e) => e),
        },
        ...[
          selectedStatus
            ? {
                status:
                  selectedStatus === "Other"
                    ? {
                        $nin: statusOptions.map((item) => item.value),
                      }
                    : {
                        $in: [selectedStatus],
                      },
              }
            : undefined,
        ].filter((e) => e),
        searchText
          ? {
              $or: [
                {
                  title: {
                    $regex: `${escapeRegExp(searchText)}`,
                    $options: "i",
                  },
                },
                {
                  shortDescription: {
                    $regex: `${escapeRegExp(searchText)}`,
                    $options: "i",
                  },
                },
                {
                  body: {
                    $regex: `${escapeRegExp(searchText)}`,
                    $options: "i",
                  },
                },
              ],
            }
          : undefined,
      ].filter((e) => e),
    },
    [searchText, currentUserId, showInReview, selectedStatus],
    {
      paginationOptions: {
        sort: "votes -submittedAt",
      },
    },
  );

  const onUpdateRequest = useCallback(
    (updatedItem: iAppFeatureRequest) => {
      setData((data) => {
        return data.map((item) => {
          if (item.id === updatedItem.id) {
            return updatedItem;
          }
          return item;
        });
      });
    },
    [setData],
  );

  const { doAction: requestFeature, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (req) =>
        SDK.appFeatureRequests.create(req).then((d) => {}),
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const seedData = useCallback(() => {
    for (const request of FeatureRequestsData) {
      requestFeature({
        title: request.label,
        body: "",
        shortDescription: request.shortDescription,
        tags: [],
        teamComment: request.teamComment,
        type: "FEATURE_REQUEST",
        category: request.category,
      });
      //
    }
  }, [requestFeature]);

  const urlQuery = useUrlQuery();
  const featureRequestId = urlQuery.get("feature-request-id")!;
  const history = useHistory();

  useEffect(() => {
    const process = async () => {
      if (featureRequestId) {
        const request = await SDK.appFeatureRequests.getById(featureRequestId);
        triggerTempModal(FeatureViewer, {
          feature: request,
          onUpdateRequest,
        });
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete("feature-request-id");
        history.replace({
          search: queryParams.toString(),
        });
      }
    };
    process();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full flex flex-col">
      <div className="search-box flex-1 flex flex-row gap-4">
        <Input.Search
          placeholder="Search requests..."
          className="rounded-md flex-1"
          value={_searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Button
          className="font-bold"
          type="primary"
          icon={<i className="ri-lightbulb-line"></i>}
          onClick={() => {
            triggerTempModal(RequestFeature, {
              onAdded: () => {
                resetAndReloadData();
              },
            });
          }}
        >
          Request a new Feature
        </Button>
      </div>
      <div className="flex flex-row justify-end items-center mt-2">
        <Space>
          <Button
            type="link"
            onClick={() => resetAndReloadData()}
            loading={isLoading}
            icon={<i className="ri-refresh-line"></i>}
          >
            Reload Data
          </Button>
          {isAdvancedMode && (
            <div className="flex flex-row justify-center items-center">
              <Switch onChange={setShowInReview} checked={showInReview} />{" "}
              <span className="ml-2">Show In Review</span>
            </div>
          )}
          {isAdvancedMode && (
            <>
              <Select
                onChange={setSelectedStatus}
                value={selectedStatus}
                placeholder="Status"
                options={statusOptions}
                style={{ minWidth: 120 }}
                allowClear
              />
            </>
          )}
        </Space>
      </div>
      <div className="w-full flex flex-col gap-4 mt-8" ref={scrollableViewRef}>
        {itemsInView.map((item) => (
          <FeatureRequestCard
            item={item}
            key={item.id}
            onUpdateRequest={onUpdateRequest}
          />
        ))}
      </div>
      {itemsInView.length === 0 && (
        <EmptyData
          text="No feature requests to display"
          icon={<i className="ri-search-line text-3xl"></i>}
          className="text-gray-600"
        />
      )}
      {/* Pagination */}
      <div className="pagination-container p-4 flex flex-row justify-end">
        <Pagination
          pageSize={paginationState.pageSize}
          current={paginationState.currentPage}
          total={paginationState.totalItems}
          onChange={(currentPage, pageSize) => {
            setPaginationState({
              ...paginationState,
              pageSize: pageSize || 20,
              currentPage,
            });
            scrollableViewRef.current?.scrollIntoView({
              behavior: "smooth",
            });
          }}
        />
      </div>
    </div>
  );
};

export const FeatureRequestCard = ({
  item,
  onUpdateRequest,
}: {
  item: iAppFeatureRequest;
  onUpdateRequest: (item: iAppFeatureRequest) => any;
}) => {
  const { triggerTempModal } = useModalPanels();
  const currentUserId = useSelector(selectCurrentUserId);

  const { doAction: voteFeature, isProcessing: isVoting } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (reqId: string) =>
          SDK.appFeatureRequests.vote(reqId).then((d) => {
            onUpdateRequest(d);
          }),
        failureMessage: "Something went wrong",
      }),
      [onUpdateRequest],
    );

  const { doAction: unVoteFeature, isProcessing: isUnVoting } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (reqId: string) =>
          SDK.appFeatureRequests.unVote(reqId).then((d) => {
            onUpdateRequest(d);
          }),
        failureMessage: "Something went wrong",
      }),
      [onUpdateRequest],
    );

  const hasVoted = useMemo(() => {
    const vote = item.votes.find((item) => item.userId === currentUserId);
    return Boolean(vote);
  }, [currentUserId, item.votes]);

  const _isAdvancedMode = useSelector(selectIsAdvancedMode);
  const organizationId = useSelector(selectOrganizationId);
  const isAdvancedMode =
    _isAdvancedMode &&
    (organizationId === "fc89e0" || window.location.href.includes("localhost"));

  return (
    <div
      className="flex flex-row gap-4 hover:bg-gray-100 dark:hover:bg-gray-800 p-4 rounded-lg cursor-pointer"
      onClick={() => {
        triggerTempModal(FeatureViewer, {
          feature: item,
          onUpdateRequest,
        });
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <Spin
          spinning={isVoting || isUnVoting}
          indicator={<LoadingIndicatorWithSpin />}
        >
          <Tooltip
            title={
              hasVoted
                ? `You have already upvoted. Click again to remove your vote`
                : "Click to upvote"
            }
          >
            <div
              className={classNames(
                "flex flex-col border border-gray-600 rounded-lg cursor-pointer hover:bg-gray-200 dark:bg-gray-800",
                {
                  "border-blue-600": hasVoted,
                  "text-blue-600": hasVoted,
                },
              )}
              style={{ width: 60 }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (!hasVoted) {
                  voteFeature(item.id);
                } else {
                  unVoteFeature(item.id);
                }
              }}
            >
              <div className="flex w-full justify-center items-center border-b border-gray-600">
                <i className="ri-arrow-up-s-line"></i>
              </div>
              <div className="font-bold flex justify-center items-center">
                {item.votes.length}
              </div>
            </div>
          </Tooltip>
        </Spin>
      </div>
      <div className="flex-1 flex flex-col">
        <div className="font-bold text-xl">{item.title}</div>
        <div className="">
          <Tag className="font-bold">Category: {item.category || "Other"}</Tag>{" "}
          <Tag>{item.status || "In Review"}</Tag>
          {item.tags.map((tag, index) => (
            <Tag key={index.toString()}>{tag}</Tag>
          ))}
        </div>
        <div className="text-gray-600">{item.shortDescription}</div>
      </div>
      <div className="flex flex-col justify-center items-center">
        {Boolean(item.comments.length) && (
          <Badge
            count={item.comments.length || 0}
            color="blue"
            showZero
            offset={[5, 0]}
          >
            <i className="ri-question-answer-line"></i>
          </Badge>
        )}
        {isAdvancedMode && (
          <Button
            type="link"
            onClick={(e) => {
              e.stopPropagation();
              triggerTempModal(EditFeatureRequest, {
                feature: item,
                onEdited: onUpdateRequest,
              });
            }}
            icon={<i className="ri-pencil-line"></i>}
          >
            Edit Card
          </Button>
        )}
      </div>
    </div>
  );
};
