import { Button, message } from "antd";
import { useState } from "react";
import { ActivateSubscriptionModal } from "./activate-subscription";
import { GetPricingPlanById, iPricingPlan } from "./pricing-plans-data";
import { useSubscriptionDetails } from "./use-subscription-details";

export const PaymentMethodSummaryCard = ({
  reloadStripeData,
}: {
  reloadStripeData: () => any;
}) => {
  const { isSubscribed, subscriptionDetails, isYearly, plan } =
    useSubscriptionDetails();

  const [selectedPlan, setSelectedPlan] = useState<undefined | iPricingPlan>(
    undefined,
  );

  const [activatePaymentModalVisibility, setActivatePaymentModalVisibility] =
    useState(false);

  return (
    <>
      <div className="plan-card flex flex-col rounded-lg border border-gray-200 p-4">
        <div className="font-bold">Payment Method</div>
        <div className="text-gray-600  text-xs">
          Change how your pay for your plan
        </div>
        <div className="plan-card flex flex-col rounded-lg border border-gray-200 p-2 w-full  mt-4">
          <div className="flex flex-row justify-between">
            <div className="text-2xl">
              {/* {subscriptionDetails?.billing.card?.type && (
                          <CardIcon
                            type={subscriptionDetails?.billing.card?.type}
                            style={{ top: 3, position: "relative" }}
                          />
                        )} */}
              <i
                className={`ri-${subscriptionDetails?.billing.card?.type}-line`}
              ></i>
            </div>
            <div className="flex flex-col">
              <div className="line-1 flex flex-row justify-start items-center">
                <div className="">
                  {subscriptionDetails?.billing.card?.type} ending in{" "}
                  {subscriptionDetails?.billing.card?.last4}
                </div>
              </div>
              <div className="text-gray-600  text-xs">
                Expiry {subscriptionDetails?.billing.card?.expiry}
              </div>
            </div>
            <Button
              type="text"
              icon={<i className="ri-arrow-up-circle-line"></i>}
              className="font-bold"
              onClick={() => {
                setSelectedPlan(
                  GetPricingPlanById(subscriptionDetails?.planId!)!,
                );
                setActivatePaymentModalVisibility(true);
              }}
            >
              Change
            </Button>
          </div>
        </div>
      </div>
      <ActivateSubscriptionModal
        isActivated={isSubscribed}
        visible={activatePaymentModalVisibility}
        onChangeVisibility={setActivatePaymentModalVisibility}
        onActivated={() => {
          setActivatePaymentModalVisibility(false);
          message.success("Subscription has been activated");
          reloadStripeData();
        }}
        plan={selectedPlan}
      />
    </>
  );
};
