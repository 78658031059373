import { Button, Modal } from "antd";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useRef, useState } from "react";
import ArticleEditorNew from "./article-editor/article-editor-new";
import { ArticleListSimple } from "./collections/article-list-simple";

export const KnowledgeBaseManageArticlesModal = ({
  visible,
  onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const [selectedArticleId, setSelectedArticleId] = useState("");
  const onArticleCreated = useCallback(() => {
    //
  }, []);
  const ArticleEditorRef = useRef<any>();
  const [isSaving, setSaving] = useState(false);

  const onSave = useCallback(async () => {
    try {
      setSaving(true);
      const saveStatus = await ArticleEditorRef.current.saveArticle();
      setSaving(false);
    } catch (e) {
      setSaving(false);
    }
  }, []);

  return (
    <Modal
      title={null}
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Manage Articles Modal"
      width={1100}
      bodyStyle={{ padding: 0 }}
    >
      <div
        className="flex flex-row"
        style={{
          maxHeight: "calc(100vh - 60px)",
          minHeight: "calc(100vh - 300px)",
        }}
      >
        <div
          className="article-list h-full py-4 border-r border-gray-200"
          style={{ width: "22rem" }}
        >
          <ArticleListSimple
            onAddArticle={() => {
              setSelectedArticleId("NEW");
            }}
            onEditArticle={(articleId) => {
              setSelectedArticleId(articleId!);
            }}
            disableMaxHeight={true}
          />
        </div>
        <div className="article-editor overflow-auto flex-1">
          {!selectedArticleId && (
            <div className="flex flex-col justify-center items-center h-full w-full">
              <i className="ri-information-line text-4xl"></i>
              <div className="text-2xl text-gray-600">
                Select an Article to start editing
              </div>
            </div>
          )}
          {selectedArticleId && (
            <div className="relative w-full h-full overflow-hidden">
              <div className="relative w-full h-full overflow-auto">
                <ArticleEditorNew
                  articleId={
                    selectedArticleId === "NEW" ? "" : selectedArticleId
                  }
                  onArticleCreated={onArticleCreated}
                  ref={ArticleEditorRef}
                />
              </div>
              <div className="save-bar w-full absolute bottom-0 flex flex-row justify-end items-center">
                <Button
                  className="m-2 font-bold"
                  type="primary"
                  icon={<i className="ri-save-line"></i>}
                  onClick={onSave}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <DarkModeBg />
    </Modal>
  );
};
