import { iReminder } from "@sdk/reminders/reminder-model";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectReminderQueryMap,
  selectEntityMap: selectRemindersMap,
  selectQueryMapAll: selectRemindersMapAll,
  selectQueryAllList: selectRemindersAllList,
  selectAllEntities: selectAllReminders,
  selectAllEntitiesQuery: selectAllRemindersQuery,
  selectEntityById: selectReminderById,
  selectEntityQuery: selectRemindersQuery
} = CreateSelectorForEntities<iStore, iReminder>({
  sliceName: "reminders"
});

export const selectRemindersOfConversation = (conversationId: string) => (
  state: iStore
) => {
  return selectAllReminders(state).filter(
    item => item.entityId === conversationId
  );
};
