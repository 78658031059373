import { iFilterConfigSchema } from "components/common/filter-schema/filter-config-schema";
import { UserPill } from "components/modules/user-management/users/components/user-pill/user-pill";
import { selectCurrentUserId } from "store/modules/users/users.selectors";

export const ArticlesFilterSchema: iFilterConfigSchema = {
  filters: [
    // {
    //   label: "Created Date",
    //   icon: "ri-calendar-line",
    //   key: "createdTime",
    //   fieldOptions: {
    //     operators: [
    //       {
    //         id: "IS_BETWEEN",
    //         label: "is between",
    //         queryGenerator: ({
    //           value: [startDate, endDate] = [
    //             dayjs().subtract(7, "d").valueOf(),
    //             dayjs().valueOf(),
    //           ],
    //         }: {
    //           value?: [number, number];
    //         }) => ({
    //           "metaData.createdTime": {
    //             $gte: startDate,
    //             $lt: endDate,
    //           },
    //         }),
    //         valueField: {
    //           type: "DATE_RANGE",
    //           config: {},
    //         },
    //         viewRenderer: ([startDate, endDate]: [number, number]) => (
    //           <span>
    //             {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
    //             {dayjs(endDate).format("DD-MM-YYYY")}
    //           </span>
    //         ),
    //       },
    //       {
    //         id: "IS_LAST_7_DAYS",
    //         label: "is in the last 7 days",
    //         queryGenerator: () => ({
    //           "metaData.createdTime": {
    //             $gte: dayjs().subtract(7, "d").valueOf(),
    //             $lt: dayjs().valueOf(),
    //           },
    //         }),
    //       },
    //       {
    //         id: "IS_THIS_MONTH",
    //         label: "is in this month",
    //         queryGenerator: () => ({
    //           "metaData.createdTime": {
    //             $gte: dayjs().startOf("month").valueOf(),
    //             $lt: dayjs().valueOf(),
    //           },
    //         }),
    //       },
    //       {
    //         id: "IS_THIS_WEEK",
    //         label: "is in this this week",
    //         queryGenerator: () => ({
    //           "metaData.createdTime": {
    //             $gte: dayjs().startOf("week").valueOf(),
    //             $lt: dayjs().valueOf(),
    //           },
    //         }),
    //       },
    //       {
    //         id: "IS_LAST_MONTH",
    //         label: "is in last",
    //         queryGenerator: () => ({
    //           "metaData.createdTime": {
    //             $gte: dayjs().subtract(1, "month").startOf("month").valueOf(),
    //             $lt: dayjs().subtract(1, "month").endOf("month").valueOf(),
    //           },
    //         }),
    //       },
    //     ],
    //     defaultOperator: "IS_BETWEEN",
    //   },
    // },
    {
      label: "Status",
      icon: "ri-eye-line",
      key: "status",
      fieldOptions: {
        operators: [
          {
            id: "PUBLISHED",
            label: "Published",
            queryGenerator: () => ({
              "metaData.status": "PUBLISHED",
            }),
          },
          {
            id: "DRAFT",
            label: "Draft",
            queryGenerator: () => ({
              "metaData.status": "DRAFT",
            }),
          },
        ],
        defaultOperator: "EXISTS",
      },
    },

    // {
    //   label: "Tags",
    //   icon: "ri-price-tag-3-fill",
    //   key: "tags",
    //   fieldOptions: {
    //     operators: [
    //       {
    //         id: "DOES_NOT_EXISTS",
    //         queryGenerator: () => ({
    //           "data.tags.0": { $exists: false },
    //         }),
    //         label: "does not exist",
    //       },
    //       {
    //         id: "IS_ONE_OF",
    //         label: "includes one of",
    //         queryGenerator: ({ value: tags }) => ({
    //           "data.tags": { $in: tags },
    //         }),
    //         valueField: {
    //           type: "SELECT",
    //           config: {
    //             selectionMode: "tags",
    //             dropdownStyle: { display: "none" },
    //             placeholder: "Enter a tag",
    //           },
    //         },
    //       },
    //       {
    //         id: "IS_ALL_OF",
    //         label: "includes all of",
    //         queryGenerator: ({ value: tags }) => ({
    //           "data.tags": { $all: tags },
    //         }),
    //         valueField: {
    //           type: "SELECT",
    //           config: {
    //             selectionMode: "tags",
    //             dropdownStyle: { display: "none" },
    //             placeholder: "Enter a tag",
    //           },
    //         },
    //       },
    //     ],
    //     defaultOperator: "IS_ONE_OF",
    //   },
    // },
    {
      label: "Created By",
      icon: "ri-user-fill",
      key: "user",
      fieldOptions: {
        operators: [
          {
            id: "IS_ME",
            label: "Myself",
            queryGenerator: (v, store) => {
              const currentUserId = selectCurrentUserId(store);
              return {
                "metaData.createdBy": currentUserId,
              };
            },
          },
          {
            id: "IS_ONE_OF",
            label: "is",
            queryGenerator: ({ value: userId }) => ({
              "metaData.createdBy": userId,
            }),
            valueField: {
              type: "USER_SELECTOR",
            },
            viewRenderer: (userId: string) => <UserPill userId={userId} />,
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },
    // {
    //   label: "Collection",
    //   icon: "ri-folder-line",
    //   key: "collection",
    //   fieldOptions: {
    //     operators: [
    //       {
    //         id: "IS",
    //         label: "is ",
    //         queryGenerator: ({ value }) => ({
    //           collections: [value],
    //         }),
    //         valueField: {
    //           type: "CONNECTION_SELECTOR",
    //         },
    //         viewRenderer: (connectionId: string) => (
    //           <ConnectionPill connectionId={connectionId} />
    //         ),
    //       },
    //     ],
    //     defaultOperator: "IS",
    //   },
    // },
  ],
};
