export function convertMillisecondsToHoursAndMinutes(time: number = 0) {
  const milliseconds = time % 1000;
  time = parseInt((time / 1000).toString(), 10);
  const seconds = time % 60;
  time = parseInt((time / 60).toString(), 10);
  const minutes = time % 60;
  time = parseInt((time / 60).toString(), 10);
  const hours = time % 24;
  time = parseInt((time / 24).toString(), 10);
  const days = time;
  let out = "";

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
}
