import { useEffect } from "react";

export const WeatherWidget = ({
  destination,
  weatherUrl,
}: {
  destination: string;
  weatherUrl: string;
}) => {
  useEffect(() => {
    const scriptTag = "script";
    const id = "weatherwidget-io-js";
    const fjs = document.getElementsByTagName(scriptTag)[0];
    if (!document.getElementById(id)) {
      const js = document.createElement(scriptTag);
      js.id = id;
      js.src = "https://weatherwidget.io/js/widget.min.js";
      fjs!.parentNode!.insertBefore(js, fjs);
    } else {
      const initWidget = () => {
        if ((window as any).__weatherwidget_init) {
          (window as any).__weatherwidget_init();
          return true;
        }
        return false;
      };
      if (!initWidget()) {
        setTimeout(() => {
          initWidget();
        }, 4000);
      }
    }
  }, []);
  return (
    <>
      <a
        className="weatherwidget-io"
        href={`https://forecast7.com/en/${weatherUrl}/`}
        data-label_1={destination.toUpperCase()}
        data-label_2="WEATHER"
        data-theme="pure"
      >
        {destination.toUpperCase()} WEATHER
      </a>
    </>
  );
};
