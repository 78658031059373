import { useAutoAnimate } from "@formkit/auto-animate/react";
import { iPresetState } from "@sdk/user-management/preset-state-model";
import { Button, Collapse, Result, Space } from "antd";
import { KeyboardShortcut } from "components/common/keyboard-shortcut/keyboard-shortcut";
import { PresetEditorModal } from "components/modules/message-presets/preset-editor-modal";
import { PresetFolderEditorModal } from "components/modules/message-presets/preset-folder-editor-modal";
import {
  PresetFolderManager,
  PresetItemEditorView,
} from "components/modules/message-presets/preset-folder-manager";
import { useRegisterActions } from "kbar";
import React, { useMemo } from "react";
import { useSimpleState } from "utils/hooks/use-simple-state";

type ModalMode = "ADD" | "EDIT";

export const PresetManager = ({
  state,
  dispatch,
}: {
  dispatch: React.Dispatch<any>;
  state: iPresetState;
}) => {
  // const [state, dispatch] = useReducer(reducer, DummyPresetStateValue);

  const [presetEditorState, setPresetEditorState] = useSimpleState({
    visibility: false,
    mode: "ADD" as ModalMode,
    state: undefined as any,
  });

  const [presetFolderEditorState, setPresetFolderEditorState] = useSimpleState({
    visibility: false,
    mode: "ADD" as ModalMode,
    state: undefined as any,
  });

  const presetsWithoutFolders = useMemo(() => {
    return state.presets.filter((preset) => !preset.folder);
  }, [state.presets]);

  const [parent] = useAutoAnimate(/* optional config */);

  const kbBindings = useMemo(() => {
    return [
      {
        id: "Add Message Preset",
        name: "Add Message Preset",
        shortcut: ["n"],
        keywords: `Add Message Preset`,
        perform: () => {
          setPresetEditorState({
            visibility: true,
            mode: "ADD",
            state: null,
          });
        },
      },
    ];
  }, [setPresetEditorState]);

  useRegisterActions(kbBindings, [kbBindings]);

  return (
    <div className="w-full">
      <div className="flex flex-row justify-end items-center my-8">
        <Space>
          {" "}
          <Button
            icon={<i className="ri-folder-add-line"></i>}
            className="font-bold"
            onClick={(e) => {
              e.stopPropagation();
              setPresetFolderEditorState({
                visibility: true,
                mode: "ADD",
                state: null,
              });
            }}
          >
            Add Folder
          </Button>
          <Button
            type="primary"
            icon={<i className="ri-file-add-line"></i>}
            className="font-bold"
            onClick={(e) => {
              e.stopPropagation();
              setPresetEditorState({
                visibility: true,
                mode: "ADD",
                state: null,
              });
            }}
          >
            Add Preset{" "}
            <KeyboardShortcut label="n" disableToolTip={true} inDarkBg={true} />
          </Button>
        </Space>
      </div>

      <Collapse
        className="border-0 w-full"
        defaultActiveKey={[]}
        accordion
        ghost
      >
        {state.folders.map((folder) => (
          <PresetFolderManager
            key={folder.id}
            folder={folder}
            state={state}
            onEditPreset={(preset) => {
              setPresetEditorState({
                visibility: true,
                mode: "EDIT",
                state: preset,
              });
            }}
            onAddPreset={(folder) => {
              setPresetEditorState({
                visibility: true,
                mode: "ADD",
                state: {
                  folder: folder.id,
                },
              });
            }}
            onEditFolder={(folder) => {
              setPresetFolderEditorState({
                visibility: true,
                mode: "EDIT",
                state: folder,
              });
            }}
            onDeleteFolder={(folder, withPresets) => {
              if (withPresets) {
                dispatch({
                  type: "REMOVE_FOLDER_WITH_PRESETS",
                  payload: {
                    folder,
                  },
                });
              } else {
                dispatch({
                  type: "REMOVE_FOLDER",
                  payload: {
                    folder,
                  },
                });
              }
            }}
            onDeletePreset={(preset) => {
              dispatch({
                type: "REMOVE_PRESET",
                payload: {
                  preset,
                },
              });
            }}
          />
        ))}
      </Collapse>

      <div ref={parent as any}>
        {presetsWithoutFolders.map((preset) => (
          <PresetItemEditorView
            key={preset.id}
            preset={preset}
            onDelete={() => {
              dispatch({
                type: "REMOVE_PRESET",
                payload: {
                  preset,
                },
              });
            }}
            onEdit={() => {
              setPresetEditorState({
                visibility: true,
                mode: "EDIT",
                state: preset,
              });
            }}
          />
        ))}
      </div>

      {state.presets.length === 0 && (
        <div>
          <Result
            status="info"
            title="Go ahead and add your first message preset"
            icon={<i className="ri-chat-new-line text-6xl"></i>}
            subTitle="Message presets helps you to store your frequently sent replies and send them in a gist"
          />
        </div>
      )}

      <PresetEditorModal
        visible={presetEditorState.visibility}
        mode={presetEditorState.mode}
        initialValue={presetEditorState.state}
        folders={state.folders}
        onChangeVisibility={(visibility) =>
          setPresetEditorState({ visibility })
        }
        onSaved={(preset) => {
          // console.log("preset", preset);
          dispatch({
            type:
              presetEditorState.mode === "EDIT" ? "EDIT_PRESET" : "ADD_PRESET",
            payload: {
              preset,
            },
          });
          setPresetEditorState({ visibility: false });
        }}
      />

      <PresetFolderEditorModal
        visible={presetFolderEditorState.visibility}
        mode={presetFolderEditorState.mode}
        initialValue={presetFolderEditorState.state}
        onChangeVisibility={(visibility) =>
          setPresetFolderEditorState({ visibility })
        }
        onSaved={(folder) => {
          console.log("folder", folder);
          dispatch({
            type:
              presetFolderEditorState.mode === "EDIT"
                ? "EDIT_FOLDER"
                : "ADD_FOLDER",
            payload: {
              folder,
            },
          });
          setPresetFolderEditorState({ visibility: false });
        }}
      />
    </div>
  );
};
