export const DynamicFormsTags = [
  {
    label: "Lead Generation"
  },
  {
    label: "Conversion"
  },
  {
    label: "Web Traffic"
  },
  {
    label: "Product Recommendation"
  },
  {
    label: "Customer support"
  },
  {
    label: "Track customer behavior"
  },
  {
    label: "Data Collection"
  },
  {
    label: "Advertisement / Promotion"
  },
  {
    label: "Feedback Collection for Improvement"
  },
  {
    label: "Visibility"
  },
  {
    label: "Engagement"
  },
  {
    label: "Brand Quality"
  },
  {
    label: "Recruitment"
  },
  {
    label: "Package tracking"
  },
  {
    label: "Seasonal bots"
  },
  {
    label: "appointment booking"
  },
  {
    label: "registrations"
  }
];
