import { Button } from "antd";
import { useState } from "react";
import { Collapse } from "react-collapse";

export const SimpleCollapse = ({
  title,
  children
}: {
  title: string;
  children;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <div className="">
      <Button
        type="link"
        icon={
          isCollapsed ? (
            <i className="ri-arrow-down-s-fill"></i>
          ) : (
            <i className="ri-arrow-right-s-fill"></i>
          )
        }
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="font-bold p-0"
      >
        {title}
      </Button>

      <Collapse isOpened={isCollapsed}>{children}</Collapse>
    </div>
  );
};

export const SimpleCollapseWithHeaderRow = ({
  title,
  children
}: {
  title: JSX.Element;
  children;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <div className="">
      <div
        className="header-row flex flex-row items-center cursor-pointer"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div className="icon-container">
          {isCollapsed ? (
            <i className="ri-arrow-down-s-fill"></i>
          ) : (
            <i className="ri-arrow-right-s-fill"></i>
          )}
        </div>
        <div className="header-container flex-1">{title}</div>
      </div>

      <Collapse isOpened={isCollapsed}>{children}</Collapse>
    </div>
  );
};

export const SimpleCollapseWithRightIcon = ({
  title,
  children
}: {
  title: JSX.Element;
  children;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <div className="hover:bg-gray-100 dark:hover:bg-gray-900">
      <div
        className="header-row flex flex-row items-center cursor-pointer"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div className="header-container flex-1">{title}</div>
        <div className="icon-container">
          {isCollapsed ? (
            <i className="ri-arrow-up-s-line"></i>
          ) : (
            <i className="ri-arrow-down-s-line"></i>
          )}
        </div>
      </div>

      <Collapse isOpened={isCollapsed}>{children}</Collapse>
    </div>
  );
};
