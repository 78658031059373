import _ from "lodash";
import { useMemo } from "react";
import { DeepPartial } from "redux";
import { mergeDeep } from "utils/merge-deep";
import { ChatWidgetFAQSection } from "./components/chat-widget-faq-section";
import { ChatWidgetHomeHeader } from "./components/chat-widget-home-header";
import { StyledWidgetContainer } from "./components/styled-widget-container";
import { ChatWidgetUserAvatarContainer } from "./components/user-avatar-container";
import { defaultWidgetPreviewData } from "./default-widget-data";

export const WidgetPreviewHome = ({
  color,
  configData
}: {
  color?: string;
  configData: DeepPartial<typeof defaultWidgetPreviewData>;
}) => {
  const data: typeof defaultWidgetPreviewData = useMemo(
    () => mergeDeep({ ...defaultWidgetPreviewData }, configData),
    [configData]
  );

  const { faqs } = useMemo(() => {
    const faqCollections = configData.FAQ?.COLLECTIONS;

    const faqs = _.flatten(
      (faqCollections || []).map(collection => collection?.faqList || [])
    );
    return { faqs };
  }, [configData.FAQ?.COLLECTIONS]);

  return (
    <StyledWidgetContainer color={color}>
      <div className="h-full w-full main-header rounded-lg">
        <ChatWidgetHomeHeader data={data.HOME_HEADER} />
        <div className="w-full h-full bg-white text-black rounded-lg">
          <div className="p-6">
            <div className="title-section flex flex-row justify-between items-center px-4 mb-4">
              <div className="font-semibold text-lg text-gray-800">
                {data.CONVERSATIONS_SECTION_TITLE}
              </div>
              <div className="text-gray-600 text-xs  cursor-pointer">
                {data.SEE_ALL_TEXT}
              </div>
            </div>

            <div>
              <div className="relative">
                <div className="conversation-list-container animate fade-in-right ">
                  <div className="flex flex-row pointer hover:bg-gray-100 p-4 cursor-pointer items-center">
                    <ChatWidgetUserAvatarContainer
                      data={data.HOME_HEADER.AVATARS_IMAGES}
                    />
                    <div className="message-container flex-col flex-1 pl-4">
                      <div className="first-line flex flex-row items-center">
                        <div className="name flex-1">
                          {data.CONVERSATION_NAME}
                        </div>
                        <div className="last-activity-data text-xs text-gray-600">
                          {data.DATE_COUNT}
                        </div>
                      </div>
                      <div className="last-message text-gray-600 text-sm">
                        {data.CONVERSATION_TAGLINE}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {faqs.length > 0 && (
              <div>
                <ChatWidgetFAQSection data={data.FAQ} />
              </div>
            )}

            {faqs.length === 0 && (
              <div className="border border-dashed border-gray-400 p-8 text-center text-gray-600 my-8 rounded-md">
                You don't have FAQs set up
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledWidgetContainer>
  );
};
