import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Form, Input, message, Modal, Radio } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { ContactCard } from "components/pages/contacts/components/contact-card/contact-card";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useCallback, useEffect } from "react";
import { loadActivityById } from "store/modules/activities/activities.helpers";
import { selectActivityById } from "store/modules/activities/activities.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { SingleContactSelectorLegacy } from "../../contacts/components/single-contact-selector/single-contact-selector-legacy";

import "./add-activity.scss";

export const AddActivityModal = ({
  contactId,
  activityId,
  visible,
  onChangeVisibility
}: {
  contactId?: string;
  activityId?: string;
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const [activityEditorForm] = useForm();

  const { state: activity, isLoading: isActivityLoading } = useQueryWithStore(
    selectActivityById(activityId!),
    loadActivityById(activityId!)(),
    [activityId],
    !activityId
  );

  useEffect(() => {
    if (activity && activityId) {
      activityEditorForm.setFieldsValue(activity);
    }
  }, [activity, activityEditorForm, activityId]);

  const {
    doAction: addActivity,
    isProcessing: isActionProcessing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.activities.create(values).then(d => onChangeVisibility(false)),
      successMessage: "Done",
      failureMessage: "Something went wrong"
    }),
    [onChangeVisibility]
  );

  const {
    doAction: editActivity,
    isProcessing: isEditProcessing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (activityId: string, values) =>
        SDK.activities
          .editById(activityId, values)
          .then(d => onChangeVisibility(false)),
      successMessage: "Done",
      failureMessage: "Something went wrong"
    }),
    [onChangeVisibility]
  );

  useEffect(() => {
    if (!visible) {
      activityEditorForm.resetFields();
    }
  }, [activityEditorForm, visible]);

  const saveActivity = useCallback(async () => {
    try {
      await activityEditorForm.validateFields();
    } catch (e) {
      message.warning("Please check your input");
      return;
    }

    const formValues = activityEditorForm.getFieldsValue();
    if (!activityId) {
      addActivity({
        ..._.omit(formValues, "contact"),
        contactId: contactId || formValues.contact.id
      });
    } else {
      editActivity(activityId, {
        ..._.omit(formValues, "contact", "id")
      });
    }
  }, [activityEditorForm, activityId, addActivity, contactId, editActivity]);

  const contactIdInContext = contactId || activity?.contactId;

  return (
    <Modal
      title={
        <ModalTitle
          title={activityId ? "Edit Activity Record" : "Add Activity Record"}
          icon={<i className="ri-suitcase-line"></i>}
        />
      }
      open={visible}
      onOk={async () => saveActivity()}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okButtonProps={{ loading: isEditProcessing || isActionProcessing }}
      okText={
        <>
          <i className="ri-save-line"></i> Save
        </>
      }
      width={900}
      data-click-context="Activity Editor Modal"
    >
      <Form
        layout="vertical"
        form={activityEditorForm}
        initialValues={{ type: "CALL" }}
        className="bold-form-labels add-activity-modal"
      >
        <div className="flex flex-row">
          <div className="main-contact-details flex-1 mr-2">
            {/* Contact Selector */}
            {contactIdInContext ? (
              <ContactCard contactId={contactIdInContext} />
            ) : (
              <Form.Item
                name="contact"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Contact"
                  }
                ]}
              >
                <SingleContactSelectorLegacy />
              </Form.Item>
            )}

            {/* Type */}
            <Form.Item
              label="Activity Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please Select a activity type"
                }
              ]}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={"CALL"}>Call</Radio.Button>
                <Radio.Button value={"EMAIL"}>Email</Radio.Button>
                <Radio.Button value={"VISIT"}>Visit</Radio.Button>
                <Radio.Button value={"ONLINE_MEETING"}>
                  Online Meeting
                </Radio.Button>
              </Radio.Group>
            </Form.Item>

            {/* Notes */}

            <Form.Item label="Notes" name={"notes"} rules={[]}>
              <Input.TextArea
                // placeholder="Type in your message"
                placeholder="Enter your notes here"
                style={{ minWidth: 300, minHeight: 100 }}
                size="large"
              />
            </Form.Item>
          </div>
        </div>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
