import { Button, Form, Input, Space } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";

import { GlobalConfig } from "config";
import { useState } from "react";
import { isEmail } from "utils/is-email";
import { debouncedMailboxEmailAvailability } from "../../helpers/mailbox-availabilty-checker";
import { useEmailTester } from "../../helpers/use-email-tester";

export const ConfigureEmailConnectionForm = ({
  formInstance,
  showCreateHelpText,
  connectionId,
}: {
  formInstance?: FormInstance;
  showCreateHelpText?: boolean;
  connectionId?: string;
}) => {
  const [form] = useForm(formInstance);

  const {
    testIncomingEmail,
    isTestingIncomingEmail,
    testOutgoingEmail,
    isTestingOutgoingEmail,
  } = useEmailTester(connectionId!);

  const [searchQuery, setSearchQuery] = useState("");

  return (
    <Form layout="vertical" form={form} initialValues={{}} requiredMark={false}>
      <div className="rounded-lg border border-gray-200 dark:border-gray-800 p-4">
        {connectionId && (
          <div className="flex flex-row justify-end items-center">
            <Space>
              <Button
                type="link"
                icon={<i className="ri-flask-line"></i>}
                onClick={testIncomingEmail}
                loading={isTestingIncomingEmail}
              >
                Test Incoming Email
              </Button>
              <Button
                type="link"
                icon={<i className="ri-flask-line"></i>}
                onClick={testOutgoingEmail}
                loading={isTestingOutgoingEmail}
              >
                Test Outgoing Email
              </Button>
            </Space>
          </div>
        )}

        <Form.Item
          label="Your Desired Email Address"
          name="existingEMail"
          rules={[
            {
              required: true,
              message: "Please select an email",
              type: "email",
            },
          ]}
        >
          <Input placeholder="eg: support@abc.com" size="large" />
        </Form.Item>

        <div className="flex flex-row justify-center -my-4">
          <i className="ri-arrow-up-down-line text-2xl"></i>
        </div>

        {/* Email */}

        <div className="mt-8 mb-12">
          <Form.Item
            label="Your Unique Click Connector Email"
            name="email"
            rules={[
              { required: true, message: "Please select an email" },
              ({ getFieldValue }) => ({
                validator: async (rule, value) => {
                  const isValid = isEmail(
                    `${value}@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN}`,
                  );
                  if (!isValid) {
                    throw `${value}@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN} is not a valid email`;
                  }
                },
              }),
              ({ getFieldValue }) => ({
                validator: async (rule, value) =>
                  await debouncedMailboxEmailAvailability(
                    `${value}@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN}`,
                    connectionId,
                  ),
              }),
            ]}
          >
            <Input
              placeholder="support.myCompany"
              suffix={`@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN}`}
            />
          </Form.Item>
        </div>

        {/* Sender Name */}
        <Form.Item
          label="Sender Name"
          name="senderName"
          help="Your name which your clients will see"
          rules={[{ required: true, message: "Please select an email" }]}
        >
          <Input placeholder="" size="large" />
        </Form.Item>
      </div>
    </Form>
  );
};
