import InboxOutlined from "@ant-design/icons/InboxOutlined";
import { SDK, useSDK } from "@sdk";
import { processServerError } from "@sdk/sdk.hooks";
import {
  Button,
  Divider,
  Form,
  Modal,
  Result,
  Select,
  Space,
  Steps,
  Switch,
  Table,
  Upload,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import Papa from "papaparse";
import { useState } from "react";
import { loadAllContactLists } from "store/modules/contact-lists/contact-lists.helpers";
import { selectAllContactLists } from "store/modules/contact-lists/contact-lists.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { CountryCodes } from "../../../../../../utils/country-codes";
import { generateCountryCodeFromCountryKey } from "../../helpers/country-key-helpers";
import "./bulk-add-contacts.scss";

const { Dragger } = Upload;
const { Option } = Select;

const bulkUpdateHeaderMapping = [
  {
    key: "firstName",
    label: "First Name",
  },
  {
    key: "lastName",
    label: "Last Name",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "mobile",
    label: "Mobile",
  },
];

const steps = [
  {
    title: "Upload",
    description: "upload data via file",
  },
  {
    title: "Map",
    description: "define column headings",
  },
  {
    title: "Configure",
    description: "set up tags and lists",
  },
];

export const BulkAddContacts = ({
  visible,
  onChangeVisibility: _onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const defaultFormValue = {
    countryCode: "+44",
    country: "GB|United Kingdom",
  };
  const [currentStep, setCurrentStep] = useState(1);
  const [uploadedRecords, setUploadedRecords] = useState([]);
  const [bulkAddColumnMapForm] = useForm();
  const [columnMappings, setColumnMappings] = useState({});
  const [bulkAddConfigForm] = useForm();
  const [response, setResponse] = useState<any | undefined>(undefined);

  // Bulk Update
  const [isProcessingBulkUpload, setIsProcessingBulkUpload] = useState(false);
  const [isFirstRecordHeader, setIsFirstRecordHeader] = useState(false);

  const { data: tags } = useSDK((SDK) => SDK.getContactTags(), [], false, []);

  const { state: contactLists, retry: reload } = useQueryWithStore(
    selectAllContactLists,
    loadAllContactLists,
  );

  const onChangeVisibility = (visibility: boolean) => {
    setCurrentStep(1);
    _onChangeVisibility(false);
  };

  const onCreated = () => {};

  const onBulkCreate = async () => {
    setIsProcessingBulkUpload(true);
    try {
      const records = [...uploadedRecords];
      if (isFirstRecordHeader) {
        records.shift();
      }

      const columnMappingForm = columnMappings;
      const columnMapping: any = {};
      for (let key in columnMappingForm) {
        if (columnMappingForm[key] !== "NA") {
          // column-$index
          const index = key.replace("column-", "");
          columnMapping[columnMappingForm[key]] = index;
        }
      }

      const getValue = (key: string, record: any) =>
        typeof columnMapping[key] === "undefined"
          ? undefined
          : record[columnMapping[key]];

      const contactRecords = records.map((record) => ({
        firstName: getValue("firstName", record),
        lastName: getValue("lastName", record),
        primaryEmail: getValue("email", record),
        primaryMobile: getValue("mobile", record),
        tags: [],
      }));

      const config = bulkAddConfigForm.getFieldsValue();

      const res = await SDK.bulkCreateContacts({
        contacts: contactRecords,
        country: generateCountryCodeFromCountryKey(config.country),
        lists: config.lists,
        tags: config.tags,
      } as any);
      console.log("res", res);

      setResponse(res);

      message.success(`${res.success} contacts have been uploaded`);
      onCreated();
      setCurrentStep(4);
      setIsProcessingBulkUpload(false);
    } catch (e) {
      console.log("Error while crating list", e);
      message.error(processServerError(e, "Something went wrong"));
      setIsProcessingBulkUpload(false);
    }
  };

  return (
    <Modal
      title={
        <ModalTitle
          title="Import Customer Records"
          icon={<i className="ri-upload-2-fill"></i>}
        />
      }
      open={visible}
      footer={currentStep !== 3 ? null : undefined}
      onOk={() => {
        onBulkCreate();
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Import"
      width="90%"
      okButtonProps={{ icon: <i className="ri-check-line"></i> }}
      confirmLoading={isProcessingBulkUpload}
      data-click-context="Bulk Add Contacts Modal"
      className="bulk-add-contacts-modal"
    >
      <div className="flex flex-row justify-center items-center">
        <div
          className="bulk-add-steps-container w-full px-12"
          style={{ maxWidth: 1200 }}
        >
          <Steps current={currentStep - 1}>
            {steps.map((item) => (
              <Steps.Step
                key={item.title}
                title={item.title}
                description={item.description}
              />
            ))}
          </Steps>
        </div>
      </div>
      <Divider />
      {currentStep === 1 && (
        <div className="drag-and-drop-container">
          <Dragger
            name="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            customRequest={() => {}}
            onChange={(data) => {
              const file = data.fileList[0].originFileObj;
              // console.log("File Selected", file, Papa);
              const parsedData = Papa.parse(file, {
                error: function (err, file, inputElem, reason) {
                  // executed if an error occurs while loading the file,
                  // or if before callback aborted for some reason
                  // console.log("Error while parsing file", err, reason);
                },
                complete: function (results, file) {
                  // executed after all files are complete
                  // console.log("Parsing Complte", results, file);
                  setUploadedRecords(results.data);
                  setCurrentStep(2);
                },
              });
              // console.log("parsedData", parsedData);
            }}
            style={{ minHeight: 400 }}
            className="flex-row flex items-center justify-center"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Upload contact data in CSV format</p>
          </Dragger>
        </div>
      )}
      {currentStep === 2 && (
        <div>
          <div className="first-row-header flex flex-row justify-between items-center p-4">
            <Space>
              <div className="label">Is first row header</div>
              <Switch
                onChange={(value) => setIsFirstRecordHeader(value)}
                checked={isFirstRecordHeader}
              />
            </Space>
            <div className="record-coun">
              {isFirstRecordHeader
                ? uploadedRecords.length - 1
                : uploadedRecords.length}
              Record(s)
            </div>
          </div>
          <Form
            form={bulkAddColumnMapForm}
            onValuesChange={(change) =>
              setColumnMappings(bulkAddColumnMapForm.getFieldsValue())
            }
            preserve={true}
          >
            <Table
              columns={(uploadedRecords[0] as any).map((item, index) => ({
                title: (
                  <>
                    <Form.Item label="" name={`column-${index}`}>
                      <Select placeholder="Please select">
                        <Option value={"NA"}>Not Mapped</Option>
                        {bulkUpdateHeaderMapping.map((header) => (
                          <Option key={header.key} value={header.key}>
                            {header.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                ),
                dataIndex: index,
              }))}
              dataSource={(isFirstRecordHeader
                ? uploadedRecords.slice(1)
                : uploadedRecords
              ).map((row) =>
                (row as string[]).reduce(
                  (acc, curr, index) => ({ ...acc, [index]: curr }),
                  {},
                ),
              )}
              pagination={{ defaultPageSize: 5 }}
              scroll={{ x: "100vw" }}
            />
          </Form>
          <div className="flex flex-row justify-end">
            <Button type="primary" onClick={() => setCurrentStep(3)}>
              Next
            </Button>
          </div>
        </div>
      )}
      {currentStep === 3 && (
        <div style={{ maxWidth: 450 }}>
          <div className="back-button-container my-10">
            <Button onClick={() => setCurrentStep(2)}>Back</Button>
          </div>
          <Form
            layout="vertical"
            form={bulkAddConfigForm}
            initialValues={defaultFormValue}
          >
            {/* Country */}
            <Form.Item label="Default Country" name="country">
              <Select showSearch>
                {CountryCodes.map((country) => (
                  <Select.Option
                    value={`${country.code}|${country.name}`}
                    key={country.code}
                  >
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* Lists */}
            <Form.Item label="Lists" name="lists">
              <Select showSearch mode="multiple">
                {contactLists.map((list) => (
                  <Select.Option value={list.id!} key={list.id}>
                    {list.data?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* Tags */}
            <Form.Item label="Tags" name="tags">
              <Select
                mode="tags"
                onChange={(selectedTags) => {
                  const difference = _.difference(
                    selectedTags as any,
                    tags.map((item) => item.tag),
                  );
                  if (difference.length > 0) {
                    SDK.addContactTag(difference[0]).catch((e) => {
                      console.log("Error while saving tags", e);
                    });
                  }
                }}
              >
                {tags.map((tag) => (
                  <Select.Option key={tag.tag} value={tag.tag}>
                    {tag.tag}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
      )}
      {currentStep === 4 && (
        <Result
          status="success"
          icon={<i className="ri-checkbox-circle-line text-10xl"></i>}
          title="Records has been imported"
          subTitle={`Successful: ${(response as any)?.success} ; Failed ${(
            response as any
          )?.failed}`}
          extra={[
            <Button
              key="another"
              onClick={() => {
                setCurrentStep(1);
              }}
              icon={<i className="ri-file-upload-line"></i>}
            >
              Upload another file
            </Button>,
          ]}
        />
      )}
      <DarkModeBg />
    </Modal>
  );
};
