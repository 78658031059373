import { SDK } from "@sdk";
import { iShopifyCustomer, iShopifyOrder } from "@sdk/shopify/shopify.service";
import dayjs from "dayjs";

export type ShopifyMessageToken =
  | "SHOPIFY_LAST_ORDER_NUMBER"
  | "SHOPIFY_NUMBER_OF_LAST_ORDER"
  | "SHOPIFY_LAST_ORDER_DATE"
  | "SHOPIFY_LAST_ORDER_TRACKING_URL"
  | "SHOPIFY_LAST_ORDER_TRACKING_NUMBER"
  | "SHOPIFY_LAST_ORDER_DELIVERY_STATUS"
  | "SHOPIFY_LAST_ORDER_STATUS"
  | "SHOPIFY_LAST_ORDER_SHIPPING_DATE"
  | "SHOPIFY_LAST_ORDER_DESTINATION_COUNTRY"
  | "SHOPIFY_LAST_ORDER_SHIPPING_ADDRESS";

export const ShopifyTokenLabels = {
  SHOPIFY_LAST_ORDER_NUMBER: "Number of last order",
  SHOPIFY_NUMBER_OF_LAST_ORDER: "Number of last order",
  SHOPIFY_LAST_ORDER_DATE: "Date of last order",
  SHOPIFY_LAST_ORDER_TRACKING_URL: "Tracking URL of last order",
  SHOPIFY_LAST_ORDER_TRACKING_NUMBER: "Tracking Number of last order",
  SHOPIFY_LAST_ORDER_DELIVERY_STATUS: "Status URL of last order",
  SHOPIFY_LAST_ORDER_STATUS: "Delivery Status of last order",
  SHOPIFY_LAST_ORDER_SHIPPING_DATE: "Shipping Date of last order",
  SHOPIFY_LAST_ORDER_DESTINATION_COUNTRY: "Destination Country of last order",
  SHOPIFY_LAST_ORDER_SHIPPING_ADDRESS: "Shipping Address of last order",
};

export const AvailableShopifyTokens = Object.keys(ShopifyTokenLabels);

export const AvailableShopifyTokenList = Object.keys(ShopifyTokenLabels)
  .filter((e) => e !== "SHOPIFY_NUMBER_OF_LAST_ORDER")
  .map((token) => ({
    token,
    label: ShopifyTokenLabels[token],
  }));

export const resolveShopifyCustomer = async (
  email?: string,
  phoneNumber?: string,
) => {
  return await SDK.shopify.resolveShopifyCustomer(email, phoneNumber);
};

export const resolveOrder = async (lastOrderId: string) => {
  return await SDK.shopify.getOrderById(String(lastOrderId));
};

export const ShopifyMessageTokenResolver = async (
  token: ShopifyMessageToken,
  email?: string,
  phoneNumber?: string,
  customer?: iShopifyCustomer | null,
  lastOrder?: iShopifyOrder | null,
) => {
  if (token.includes("SHOPIFY_")) {
    if (!email && !phoneNumber) {
      return "Not Available";
    }
    try {
      if (customer === null || lastOrder === null) {
        return "Customer Not Available";
      }

      if (!customer) {
        customer = await resolveShopifyCustomer(email, phoneNumber);
        if (!customer) {
          return "Customer Not Available";
        }
      }

      if (!lastOrder) {
        const lastOrderId = customer.last_order_id;
        lastOrder = await resolveOrder(String(lastOrderId));
        if (!lastOrder) {
          return "Not Available";
        }
      }

      switch (token) {
        case "SHOPIFY_LAST_ORDER_NUMBER":
        case "SHOPIFY_NUMBER_OF_LAST_ORDER": {
          return `#${lastOrder.order_number}`;
        }
        case "SHOPIFY_LAST_ORDER_DATE": {
          return dayjs(lastOrder.processed_at).format("LL h:mm:ss a ");
        }
        case "SHOPIFY_LAST_ORDER_TRACKING_URL": {
          return lastOrder.order_status_url;
        }
        case "SHOPIFY_LAST_ORDER_TRACKING_NUMBER": {
          return "Unavailable";
        }
        case "SHOPIFY_LAST_ORDER_DELIVERY_STATUS": {
          return lastOrder.fulfillment_status || "Unfulfilled";
        }
        case "SHOPIFY_LAST_ORDER_STATUS": {
          return `${lastOrder.fulfillment_status || "Unfulfilled"}/${
            lastOrder.financial_status || "Not Paid"
          }`;
        }
        case "SHOPIFY_LAST_ORDER_SHIPPING_DATE": {
          return "Unavailable";
        }
        case "SHOPIFY_LAST_ORDER_DESTINATION_COUNTRY": {
          return lastOrder.shipping_address?.country || "Not Available";
        }
        case "SHOPIFY_LAST_ORDER_SHIPPING_ADDRESS": {
          if (lastOrder.shipping_address) {
            const shippingAddress = lastOrder.shipping_address;
            return `${shippingAddress.first_name} ${
              shippingAddress.last_name
            }\n${shippingAddress.address1 || ""}\n${
              shippingAddress.address2 || ""
            }\n${shippingAddress.city || ""}\n${
              shippingAddress.province || ""
            }\n${shippingAddress.country || ""}\n${shippingAddress.zip || ""}`;
          }
          return "Not Available";
        }
      }
    } catch (e) {
      console.log("Error while resolving shopify token", e);
      return "Not Available";
    }
  }

  return token;
};
