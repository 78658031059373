export const ExtractNameFromString = (str: string) => {
  const parts = str.split(" ");
  if (parts.length < 4) {
    return {
      firstName: parts[0],
      lastName: parts.splice(1).join(" ")
    };
  } else {
    return {
      firstName: undefined,
      lastName: undefined
    };
  }
};
