import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { Apps } from "@sdk/roles-and-permissions/roles-and-permissions";
import { Divider, Dropdown, Menu, Popover } from "antd";
import classNames from "classnames";
import { KeyboardShortcut } from "components/common/keyboard-shortcut/keyboard-shortcut";
import { QueryUnreadCountBadge } from "components/pages/conversations/components/conversation-query-unread-count-badge/conversation-query-unread-count-badge";
import { QueryConversationsBadgeCount } from "components/pages/conversations/components/query-conversations-count-badge/query-conversations-count-badge";
import { push } from "connected-react-router";

import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  selectInboxConfig,
  selectOrganization,
} from "store/modules/workspace/workspace.selectors";
import { selectSideBarIsCollapsed } from "../../../store/modules/ui-state/ui-state.selectors";
import { selectCurrentUser } from "../../../store/modules/users/users.selectors";

import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iCustomInbox } from "@sdk/user-management/user-management.models";
import { UserAvatar } from "components/common/avatar/avatar";
import { ModalTitle } from "components/common/modal-title";
import { NotificationsInbox } from "components/common/notification-inbox/notifications-inbox";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import { InboxSelectorMenu } from "components/pages/conversations/inbox-selector-v2";
import { InboxMenuItem } from "components/pages/conversations/use-inboxes";
import { useUniboxView } from "components/pages/conversations/use-unibox-view";
import { useKBar } from "kbar";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { ProfileMenuOverlay } from "./main-navigation-bar";
import "./main-navigation-bar.style.scss";

const inboxOptions = {
  omitTitle: true,
  omitTasks: true,
};

const _navigation = [
  {
    label: "Home",
    path: "/home",
    keyboardShortcut: "$mod+h",
    icon: <i className="ri-home-3-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    label: "Live View",
    path: "/website-live-view",
    // keyboardShortcut: "$mod+l",
    icon: <i className="ri-global-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    label: "Inbox",
    path: "/conversations",
    keyboardShortcut: "$mod+i",
    icon: <i className="ri-inbox-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    label: "Customers",
    path: "/customers",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-group-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    label: "Opportunities",
    path: "/opportunities",
    keyboardShortcut: "$mod+o",
    icon: <i className="ri-money-dollar-box-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Holiday Quotes",
    path: "/holiday-quotes",
    keyboardShortcut: "$mod+p",
    icon: <i className="ri-bill-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    label: "Pipelines",
    path: "/pipelines",
    keyboardShortcut: "$mod+p",
    icon: <i className="ri-layout-column-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  // {
  //   label: "Automations",
  //   path: "/automation",
  //   icon: <i className="ri-magic-line"></i>,
  //   requiredPermission: [`${Apps.ORGANIZATION_MANAGEMENT}:*`]
  // },
  {
    label: "Chatbots",
    path: "/chat-bots",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-robot-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Forms",
    path: "/forms",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-file-text-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Callback Requests",
    path: "/call-back-requests",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-folder-5-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Call Logs",
    path: "/call-logs",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-folder-5-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Sequences",
    path: "/sequences",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-mail-send-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Knowledge Base",
    path: "/knowledge-base",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-book-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Reports",
    path: "/reports",
    // keyboardShortcut: "$mod+r",
    icon: <i className="ri-folder-chart-line"></i>,
    requiredPermission: [`${Apps.REPORTS}:*`],
  },
  {
    label: "Workspace Settings",
    path: "/configurations",
    keyboardShortcut: "$mod+s",
    icon: <i className="ri-home-gear-line"></i>,
    requiredPermission: [`${Apps.ORGANIZATION_MANAGEMENT}:*`],
  },
];

const PathHistory: {
  [rootPath: string]: {
    path: string;
    search: string;
  };
} = {};

export const MainNavigationBar2 = ({
  mobileMode,
}: {
  mobileMode?: boolean;
}) => {
  const store = useStore();
  const dispatch = useDispatch();
  const currentOrganization = useSelector(selectOrganization);
  const isSidebarCollapsed = useSelector(selectSideBarIsCollapsed);
  const user = useSelector(selectCurrentUser);
  const location = useLocation();
  const inboxConfig = useUniboxView();
  const currentUser = useSelector(selectCurrentUser);

  const workspaceInboxConfig = useSelector(selectInboxConfig);

  const { doAction: editCustomInboxes, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (customInboxes: iCustomInbox[]) =>
        SDK.editCurrentUser({
          data: {
            customInboxes,
          },
        }),
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const onRemoveCustomInbox = useCallback(
    (inboxId: string) => {
      const currentUser = selectCurrentUser(store.getState());
      const customInboxes = currentUser?.data.customInboxes || [];
      editCustomInboxes(customInboxes.filter((item) => item.label !== inboxId));
    },
    [editCustomInboxes, store],
  );

  const [isManageCustomInboxModalVisible, setManageCustomInboxModalVisibility] =
    useState(false);

  const onInboxSelected = useCallback(
    (selection: InboxMenuItem) => {
      if (selection.menuItemType === "ITEM") {
        inboxConfig.setEntityId(
          selection.type === inboxConfig.inboxType ? undefined : null,
          selection.id,
        );
        // onActionCompleted();
      }
    },
    [inboxConfig],
  );

  const { changePanelState } = useModalPanels();

  const selectedPaths = useMemo(() => {
    const topLevel = `/${location.pathname.split("/")[1]}`;
    return [topLevel, inboxConfig.inboxId];
  }, [inboxConfig.inboxId, location.pathname]);

  const {
    query: { toggle },
  } = useKBar();

  const [isProfileMenuVisible, setIsProfileMenuVisible] = useState(false);

  const handleMenuClick = useCallback((e) => {
    setIsProfileMenuVisible(false);
  }, []);

  const [notificationVisibility, setNotificationVisibility] = useState(false);

  return (
    <div className="flex flex-col h-full" style={{ width: "24rem" }}>
      <div className="flex flex-row items-center justify-between p-2">
        <div className="flex flex-row items-center">
          <img
            src="/cc-assets/logo.png"
            style={{ width: "4rem" }}
            alt="Click Connector"
          />
          <div className="font-semibold">Travel Center Workspace</div>
        </div>
        <div className="collase-button">
          {/* <i
            className={classNames(
              "ri-arrow-down-s-line text-2xl transition-all transform"
            )}
          ></i> */}
        </div>
      </div>
      <Divider className="m-0" />
      <div className="flex-1 flex overflow-hidden">
        <PerfectScrollbar className={"flex-1 flex h-full"}>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[]}
            // className="m-auto"
            onClick={({ key }) => {
              console.log("key", key);
              const rootPath = String(key);
              const LastPath = PathHistory[rootPath.split("/")[1]];
              dispatch(
                push(
                  LastPath ? `${LastPath.path}${LastPath.search}` : rootPath,
                ),
              );
            }}
            selectedKeys={selectedPaths}
          >
            <div className="top-menu px-4 mt-4">
              <div className="" onClick={(e) => e.stopPropagation()}>
                <Popover
                  content={<NotificationsInbox />}
                  trigger="click"
                  // open={notificationVisibility}
                  // onOpenChange={setNotificationVisibility}
                  placement={"rightBottom"}
                  title={
                    <div className="my-2">
                      <ModalTitle
                        title="Notifications"
                        icon={<i className="ri-notification-3-fill"></i>}
                      />
                    </div>
                  }
                >
                  <Menu.Item
                    id={`MAIN_MENU_NOTIFICATIONS`}
                    key={"MAIN_MENU_NOTIFICATIONS"}
                    icon={
                      <div className="relative">
                        <i className={classNames("ri-notification-3-fill")}></i>
                        <>
                          <span
                            className="absolute"
                            style={{
                              top: "0.5rem",
                              right: "0.5rem",
                            }}
                          >
                            <QueryConversationsBadgeCount
                              query={"Unassigned"}
                            />
                          </span>
                          <span
                            className="absolute"
                            style={{
                              top: "0.5rem",
                              right: "0.5rem",
                            }}
                          >
                            <QueryUnreadCountBadge query={"My Inbox"} />
                          </span>
                        </>
                      </div>
                    }
                    className={"p-4"}
                  >
                    <div className="flex flex-row justify-between items-center">
                      Notifications
                    </div>
                  </Menu.Item>
                </Popover>
              </div>

              <Menu.Item
                id={`MAIN_MENU_MENTIONS`}
                key={"MAIN_MENU_MENTIONS"}
                icon={
                  <div className="relative">
                    <i className="ri-task-line"></i>
                    <>
                      <span
                        className="absolute"
                        style={{
                          top: "0.5rem",
                          right: "0.5rem",
                        }}
                      >
                        <QueryConversationsBadgeCount query={"Unassigned"} />
                      </span>
                      <span
                        className="absolute"
                        style={{
                          top: "0.5rem",
                          right: "0.5rem",
                        }}
                      >
                        <QueryUnreadCountBadge query={"My Inbox"} />
                      </span>
                    </>
                  </div>
                }
                className={"p-4"}
              >
                <div className="flex flex-row justify-between items-center">
                  Mentions & Tasks
                </div>
              </Menu.Item>
              <Menu.Item
                id={`MAIN_MENU_WORKSPACE_SETTINGS`}
                key={"MAIN_MENU_WORKSPACE_SETTINGS"}
                icon={
                  <div className="relative">
                    <i className={classNames("ri-home-gear-line")}></i>
                    <>
                      <span
                        className="absolute"
                        style={{
                          top: "0.5rem",
                          right: "0.5rem",
                        }}
                      >
                        <QueryConversationsBadgeCount query={"Unassigned"} />
                      </span>
                      <span
                        className="absolute"
                        style={{
                          top: "0.5rem",
                          right: "0.5rem",
                        }}
                      >
                        <QueryUnreadCountBadge query={"My Inbox"} />
                      </span>
                    </>
                  </div>
                }
                className={"p-4"}
              >
                <div className="flex flex-row justify-between items-center">
                  Workspace Settings
                </div>
              </Menu.Item>
              <Menu.Item
                id={`MAIN_MENU_SEARCH_WORKSPACE`}
                key={"MAIN_MENU_SEARCH_WORKSPACE"}
                icon={
                  <div className="relative">
                    <i className="ri-search-line"></i>
                  </div>
                }
                onClick={() => {
                  toggle();
                }}
                className={"p-4"}
              >
                <div className="flex flex-row justify-between items-center">
                  Search Workspace
                  <KeyboardShortcut label={"$mod+k"} disableToolTip={true} />
                </div>
              </Menu.Item>
            </div>
            <Divider />

            {_navigation
              .filter((e) => !e.forInsider)
              .map((item) => {
                if (item.label === "Inbox") {
                  return (
                    <Menu.SubMenu
                      title={
                        <span className="">
                          <i className="ri-customer-service-line"></i> Inbox
                        </span>
                      }
                      key={item.path}
                    >
                      <div className="ml-8 mt-8">
                        <Menu.Item
                          id={`MAIN_MENU_NERER`}
                          key={item.path}
                          icon={
                            <div className="relative">
                              <i className="ri-pencil-line"></i>
                            </div>
                          }
                          className={classNames(
                            item.path === "/configurations"
                              ? "config-side-bar"
                              : "",
                          )}
                        >
                          <div className="flex flex-row justify-between items-center">
                            New Conversation
                            <PlusOutlined />
                          </div>
                        </Menu.Item>

                        <InboxSelectorMenu
                          onInboxSelected={onInboxSelected}
                          sidebarCollapsed={false}
                          changePanelState={changePanelState as any}
                          setManageCustomInboxModalVisibility={
                            setManageCustomInboxModalVisibility
                          }
                          onRemoveCustomInbox={onRemoveCustomInbox}
                          showCount={workspaceInboxConfig.showInboxCounts}
                          options={inboxOptions}
                        />
                      </div>
                    </Menu.SubMenu>
                  );
                }
                return (
                  <Menu.Item
                    id={`MAIN_MENU_${item.path}`}
                    key={item.path}
                    icon={
                      <div className="relative">
                        {item.icon}
                        {item.label === "Inbox" && (
                          <>
                            <span
                              className="absolute"
                              style={{
                                top: "0.5rem",
                                right: "0.5rem",
                              }}
                            >
                              <QueryConversationsBadgeCount
                                query={"Unassigned"}
                              />
                            </span>
                            <span
                              className="absolute"
                              style={{
                                top: "0.5rem",
                                right: "0.5rem",
                              }}
                            >
                              <QueryUnreadCountBadge query={"My Inbox"} />
                            </span>
                          </>
                        )}
                      </div>
                    }
                    className={classNames(
                      item.path === "/configurations" ? "config-side-bar" : "",
                    )}
                  >
                    <div className="flex flex-row justify-between items-center">
                      {item.label}
                      {item.keyboardShortcut && (
                        <KeyboardShortcut
                          label={item.keyboardShortcut}
                          // inDarkBg={isSidebarCollapsed}
                        />
                      )}
                    </div>
                  </Menu.Item>
                );
              })}
          </Menu>
        </PerfectScrollbar>
      </div>
      <div className="fixed-footer flex flex-col">
        <Divider className="m-0" />
        <Dropdown
          overlay={<ProfileMenuOverlay handleMenuClick={handleMenuClick} />}
          trigger={["click"]}
          placement="topLeft"
          onOpenChange={(flag) => {
            setIsProfileMenuVisible(flag);
          }}
          open={isProfileMenuVisible}
          // arrow
        >
          <div className="flex flex-row items-center justify-between p-4 cursor-pointer hover:bg-gray-100">
            <div className="flex flex-row items-center">
              <UserAvatar
                userId={user.id}
                size={44}
                className="cursor-pointer"
              />
              <div className="name">{getUserName(user)}</div>
            </div>
            <i
              className={classNames(
                "ri-arrow-down-s-line text-2xl transition-all transform",
              )}
            ></i>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
