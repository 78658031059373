import { ModalsPanelsRegistry } from "@libs/modal-panels/modal-panels";
import { RegisteredModal } from "modal-registry";
import { Suspense, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AnimatedSwitch, spring } from "react-router-transition";
import { HQRouter } from "router-hq";
import { useCan } from "./can";
import { FullScreenLayout } from "./components/layout/full-screen-layout/full-screen-layout.template";
import { AuthenticatedRoutes, UnauthenticatedRoutes } from "./navigation";
import { GetRouteMeta } from "./route-helper";
import { selectAccessToken } from "./store/modules/users/users.selectors";

export const LoadingView = (
  <div className="flex items-center justify-center h-screen w-screen">
    {/* <i className="ri-loader-5-line animate-spin text-4xl"></i> */}
    <div className="loader animate-spin text-4xl">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="48"
        height="48"
        style={{ fill: "currentColor" }}
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z" />
      </svg>
    </div>
    {/* Loading */}
  </div>
);

// we need to map the `scale` prop we define below
// to the transform style property
export function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

// child matches will...
export const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

export const MainRouter = () => {
  const isAuthenticated = useSelector(selectAccessToken);
  const { can, isReady } = useCan();
  const location = useLocation();

  const isAuthDomain = window.location.host.split(".")[0] === "auth";
  const isHQLocation = location.pathname.includes("/hq");

  const Container =
    GetRouteMeta(
      isAuthenticated ? AuthenticatedRoutes : UnauthenticatedRoutes,
      location?.pathname,
    )?.layout || FullScreenLayout;

  useEffect(() => {
    if (!isAuthenticated) {
      UnauthenticatedRoutes.forEach((item) => {
        (item.component as any).preload && (item.component as any).preload();
      });
    }
  }, [isAuthenticated]);

  // useEffect(() => {
  //   UserTracker.track(`Navigated to ${location.pathname}`, {
  //     path: location.pathname,
  //     search: location.search
  //   });
  // }, [location.pathname, location.search]);

  const enableSubDomains =
    !window.location.href.includes("localhost") &&
    !window.location.href.includes("vercel.app");

  const specialDefaultPage = useMemo(() => {
    const isRegisterDomain = window.location.host.split(".")[0] === "register";
    const isHQDomain = window.location.host.split(".")[0] === "hq";
    const isRoadmapDomain = window.location.host.split(".")[0] === "roadmap";
    if (isRegisterDomain) {
      return "/register";
    }
    if (isHQDomain) {
      return `/hq`;
    }
    if (isRoadmapDomain) {
      return `/roadmap`;
    }
    return `/login`;
  }, []);

  if (isHQLocation) {
    return <HQRouter />;
  }

  if (isAuthenticated) {
    if (!isReady) {
      return LoadingView;
    }
    return (
      <>
        <Container>
          <Suspense fallback={LoadingView}>
            <Switch>
              {AuthenticatedRoutes.filter(
                (e) => !e.requiredPermission || can!(e.requiredPermission),
              ).map((item) => (
                <Route
                  path={item.path}
                  component={item.component}
                  key={item.path}
                  // exact={true}
                ></Route>
              ))}
              <Route
                path="*"
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: `/home`,
                    }}
                  />
                )}
              />
            </Switch>
          </Suspense>
          <ModalsPanelsRegistry modals={RegisteredModal} />
        </Container>
      </>
    );
  }
  return (
    <Suspense fallback={LoadingView}>
      <AnimatedSwitch
        atEnter={bounceTransition.atEnter}
        atLeave={bounceTransition.atLeave}
        atActive={bounceTransition.atActive}
        mapStyles={mapStyles}
        className="route-wrapper"
      >
        {UnauthenticatedRoutes.map((item) => (
          <Route
            path={item.path}
            component={item.component}
            key={item.path}
          ></Route>
        ))}
        <Route
          path="*"
          component={({ location }) => (
            <Redirect
              to={{
                ...location,
                pathname: specialDefaultPage,
              }}
            />
          )}
        />
      </AnimatedSwitch>
    </Suspense>
  );
};
