import { BadgeRegistry } from "@sdk/badges/badge-registry";
import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { find, groupBy } from "lodash";
import { useMemo } from "react";
import { loadAllBadges } from "store/modules/badges/badges.helpers";
import { selectAllBadges } from "store/modules/badges/badges.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { toKebabCase } from "utils/to-kebab-case";

export const BadgeViewerModal = ({
  visible,
  onChangeVisibility,
  badgeId
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  badgeId: string;
}) => {
  const badge = useMemo(() => find(BadgeRegistry, { id: badgeId }), [badgeId]);

  const { state: badges, retry: reload, isLoading } = useQueryWithStore(
    selectAllBadges,
    loadAllBadges,
    []
  );

  const badgeMap = useMemo(() => {
    return groupBy(badges, "badgeId");
  }, [badges]);

  return (
    <Modal
      title={
        <ModalTitle
          title={badge?.name || ""}
          icon={<i className="ri-medal-2-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Badge Viewer Modal"
      width={400}
    >
      <div className="flex flex-col justify-center items-center mb-12">
        {badgeMap[badge?.id || ""] ? (
          <div
            style={{ width: 120, height: 160 }}
            className="flex flex-row justify-center items-center"
          >
            <img
              src={`/assets/badges/${toKebabCase(badge?.category || "")}/${
                badge?.id
              }.svg`}
              style={{ width: 300 }}
              alt=""
            />
          </div>
        ) : (
          <div
            style={{ width: 120, height: 160 }}
            className="flex flex-row justify-center items-center"
          >
            <i className="ri-lock-2-line text-6xl"></i>
          </div>
        )}

        <div className="text-2xl">{badge?.name}</div>
        <div className="text-gray-600 text-lg">{badge?.description}</div>
      </div>
      <DarkModeBg />
    </Modal>
  );
};
