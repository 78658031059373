import { iPipelineCard } from "@sdk/pipeline-cards/pipeline-cards-model";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import { pipelineCardsDefaultQueryConfig } from "./pipeline-cards-default-query";

export const {
  selectQueryMap: selectPipelineCardQueryMap,
  selectEntityMap: selectPipelineCardsMap,
  selectQueryMapAll: selectPipelineCardsMapAll,
  selectQueryAllList: selectPipelineCardsAllList,
  selectAllEntities: selectAllPipelineCards,
  selectAllEntitiesQuery: selectAllPipelineCardsQuery,
  selectEntityById: selectPipelineCardById,
  selectEntityQuery: selectPipelineCardQuery,
  selectEntityQueryList: selectPipelineCardQueryList
} = CreateSelectorForEntities<iStore, iPipelineCard>({
  sliceName: "pipelineCards"
});

export const generateCardsByContactIdQuery = _.memoize((contactId: string) => {
  const queryConfig = {
    ...pipelineCardsDefaultQueryConfig,
    query: {
      "references.contactId": contactId
    }
  };
  return JSON.stringify(queryConfig);
});

export const selectCardsByContactId = (contactId: string) => {
  return selectPipelineCardQuery(generateCardsByContactIdQuery(contactId));
};
