import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iAutomationWorkflowStep } from "@sdk/automation-workflows/automation-workflows-model";
import { iEmailEditorTypes } from "@sdk/campaigns/campaigns-model";
import { iEmailConnectionData } from "@sdk/conversations/conversations.models";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Tooltip,
} from "antd";
import { useWatch } from "antd/es/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { PillSelector } from "components/common/pill-selector/pill-selector";
import { ConnectionSelector } from "components/modules/conversations/components/connection-selector/connection-selector";
import { EmailEditorTypeOptions } from "components/pages/campaigns/add-campaign-modal";
import { DarkModeBg } from "dark-mode-bg";
import { SequenceEmailEditor } from "modal-registry";
import { useCallback, useEffect, useMemo } from "react";
import ReactIframeResizer from "react-iframe-resizer-super";
import { useStore } from "react-redux";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useStateWithGetter } from "utils/hooks/use-state-with-getter";
import "./step-editor-modal.scss";

export const StepEditorModal = ({
  visible,
  onChangeVisibility,
  step,
  onSave,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  step?: iAutomationWorkflowStep;
  onSave: (step: iAutomationWorkflowStep) => any;
}) => {
  if (step?.type === "EMAIL") {
    return (
      <EmailStepEditorModal
        visible={visible}
        onChangeVisibility={onChangeVisibility}
        step={step}
        onSave={onSave}
      />
    );
  } else if (step?.type === "LINKEDIN") {
    return (
      <LinkedInEditorModal
        visible={visible}
        onChangeVisibility={onChangeVisibility}
        step={step}
        onSave={onSave}
      />
    );
  }
  return <></>;
};

export const LinkedInEditorModal = ({
  visible,
  onChangeVisibility,
  step,
  onSave,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  step?: iAutomationWorkflowStep;
  onSave: (step: iAutomationWorkflowStep) => any;
}) => {
  const [form] = Form.useForm();

  const initialValue = useMemo(
    () => ({
      ...step?.data,
    }),
    [step?.data],
  );

  useEffect(() => {
    form.resetFields();
  }, [visible, step, form]);

  const onFinish = useCallback(async () => {
    try {
      await form.validateFields();
      const { ...formValue } = form.getFieldsValue();
      onSave({
        ...step!,
        data: formValue,
      });
      onChangeVisibility(false);
    } catch (e) {
      message.error("Please check your input");
    }
  }, [form, onChangeVisibility, onSave, step]);

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-pencil-line"></i>}
          title="Edit LinkedIn Step"
        />
      }
      open={visible}
      onOk={onFinish}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save Step"
      okButtonProps={{ icon: <i className="ri-check-line"></i> }}
      data-click-context="Step Editor Modal"
      width={600}
    >
      <div className="flex flex-col h-full w-full">
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValue}
          requiredMark={false}
          className="bold-form-labels"
          onFinish={onFinish}
        >
          <>
            <Form.Item
              label="Action"
              name={["linkedIn", "task"]}
              rules={[
                {
                  required: true,
                  message: "Please select an action",
                },
              ]}
            >
              <Select style={{ minWidth: 150 }}>
                <Select.Option value="MESSAGE">Message Contact</Select.Option>
                <Select.Option value="CONNECT">Connect Contact</Select.Option>
                <Select.Option value="VIEW_PROFILE">View Profile</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const action = getFieldValue(["linkedIn", "task"]);
                const message = getFieldValue(["linkedIn", "body"]);
                if (action === "MESSAGE" || action === "CONNECT") {
                  return (
                    <Form.Item
                      label="Message to be sent"
                      name={["linkedIn", "body"]}
                      rules={
                        action === "MESSAGE"
                          ? [
                              {
                                required: true,
                                message: "Please enter your your message",
                              },
                            ]
                          : []
                      }
                      help={`${(message || "").length}/300`}
                    >
                      <Input.TextArea maxLength={300} />
                    </Form.Item>
                  );
                }
                return <></>;
              }}
            </Form.Item>
          </>
        </Form>
      </div>
      <DarkModeBg />
    </Modal>
  );
};

const emptyEmailData = {
  body: "",
  emailEditorType: undefined as iEmailEditorTypes | undefined,
  advancedEmailPageData: undefined as any | undefined,
  simpleMailBlocks: undefined as any | undefined,
};

export const EmailStepEditorModal = ({
  visible,
  onChangeVisibility,
  step,
  onSave,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  step?: iAutomationWorkflowStep;
  onSave: (step: iAutomationWorkflowStep) => any;
}) => {
  const store = useStore();
  const [form] = Form.useForm();

  const initialValue = useMemo(
    () => ({
      ...step?.data,
    }),
    [step?.data],
  );

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, step, form]);

  const { triggerTempModal } = useModalPanels();

  const [emailData, setEmailData, getEmailData] = useStateWithGetter({
    ...emptyEmailData,
  });

  useEffect(() => {
    const emailData = step?.data?.email;
    setEmailData({
      body: emailData?.body || "",
      emailEditorType: emailData?.emailEditorType as any as iEmailEditorTypes,
      advancedEmailPageData: emailData?.advancedEmailPageData,
      simpleMailBlocks: emailData?.simpleMailBlocks,
    });
  }, [setEmailData, step?.data]);

  const saveData = useCallback(async () => {
    try {
      await form.validateFields();
      const { ...formValue } = form.getFieldsValue();

      formValue.email = { ...formValue.email, ...getEmailData() };
      // console.log("delay", delay, delay * 24 * 60 * 60 * 1000);
      onSave({
        ...step!,
        data: {
          ...formValue,
        },
      });
    } catch (e) {
      message.error("Please check your input");
    }
  }, [form, getEmailData, onSave, step]);

  const onFinish = useCallback(async () => {
    await saveData();
    onChangeVisibility(false);
  }, [onChangeVisibility, saveData]);

  const connectionId = useWatch(["email", "connectionId"], form);

  useEffect(() => {
    const formValues = form.getFieldsValue();
    if (
      connectionId &&
      !formValues?.email?.senderName &&
      !formValues?.email?.sendingEmail
    ) {
      const connection = selectConnectionById(connectionId)(store.getState());
      const emailAddress = (connection.data as iEmailConnectionData)
        .customDomain
        ? (connection.data as iEmailConnectionData).customDomain?.email
        : (connection.data as iEmailConnectionData).customSmtp
        ? ((connection.data as iEmailConnectionData).customSmtp.data as any)
            .email ||
          ((connection.data as iEmailConnectionData).customSmtp.data as any)
            .username
        : (connection.data as iEmailConnectionData).email;

      form.setFieldValue(
        ["email", "senderName"],
        (connection.data as iEmailConnectionData).senderName,
      );
      form.setFieldValue(["email", "sendingEmail"], emailAddress);
    }
  }, [connectionId, form, store]);

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-pencil-line"></i>}
          title="Edit Email Step"
        />
      }
      open={visible}
      onOk={onFinish}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save Step"
      okButtonProps={{ icon: <i className="ri-check-line"></i> }}
      data-click-context="Step Editor Modal"
      width={1100}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValue}
        requiredMark={false}
        className="bold-form-labels"
        onFinish={onFinish}
      >
        <div className="flex flex-row h-full w-full">
          <div className="form h-full overflow-auto" style={{ width: 300 }}>
            <div className="flex flex-col">
              <Form.Item
                name={["email", "connectionId"]}
                label="Connection to Use"
                rules={[
                  {
                    required: true,
                    message: "Please select a connection",
                  },
                ]}
                help={
                  <div className="text-gray-600 text-sm">
                    Select the email connection that will be used to deliver the
                    email.
                  </div>
                }
              >
                <ConnectionSelector
                  connectionFilter={(connection) => connection.type === "EMAIL"}
                  autoSelectFirstOption
                />
              </Form.Item>

              <Form.Item
                name={["email", "senderName"]}
                label="Sender Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter a sender name",
                  },
                ]}
                help={
                  <div className="text-gray-600 text-sm">
                    Choose a name this list will recognize instantly like the
                    company’s name or the account manager’s name.
                  </div>
                }
              >
                <Input type="eg: ClickConnector" />
              </Form.Item>

              <Form.Item
                name={["email", "sendingEmail"]}
                label="Sender's Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter a sender email",
                  },
                ]}
                help={
                  <div className="text-gray-600 text-sm">
                    This Email will be visible to the receiver while the email
                    in “Connection to Use” is on the server side and will not be
                    seen by the receiver.
                  </div>
                }
              >
                <Input type="eg: info@clickconnector.com" />
              </Form.Item>

              <Divider />
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const subjectLine = getFieldValue(["email", "subjectLine"]);
                  return (
                    <>
                      <Form.Item
                        name={["email", "subjectLine"]}
                        className="full-w-label"
                        label={
                          <div className="flex flex-row justify-between items-center w-full">
                            <div className="">Subject Line</div>
                            <div className="text-gray-600 flex flex-row justify-end items-center font-normal text-xs">
                              {subjectLine?.length || 0} Characters
                            </div>
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message:
                              "Please enter a subject line to continue. (You can edit this later)",
                          },
                        ]}
                        help={
                          <>
                            <div className="text-gray-600 flex flex-row justify-start items-center text-sm">
                              A great subject line gives your audience a reason
                              to open your email!
                            </div>
                            <div className=" text-gray-600 text-sm font-semibold">
                              Recommended Best Practices:
                            </div>
                            <ul className="list-disc ml-8 text-gray-600 mb-8 text-sm">
                              <li>
                                Avoid using more than 60 characters or 9 words
                              </li>
                              <li>Avoid using more than 3 punctuation marks</li>
                            </ul>
                          </>
                        }
                      >
                        <Input
                          placeholder="Enter a subject line"
                          spellCheck={"true"}
                        />
                      </Form.Item>
                    </>
                  );
                }}
              </Form.Item>

              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const previewLine = getFieldValue(["email", "previewLine"]);
                  return (
                    <>
                      <Form.Item
                        name={["email", "previewLine"]}
                        className="full-w-label"
                        label={
                          <div className="flex flex-row justify-between items-center w-full">
                            <div className="">Preview Text</div>
                            <div className="text-gray-600 flex flex-row justify-end items-center font-normal text-xs">
                              {previewLine?.length || 0} Characters
                            </div>
                          </div>
                        }
                        rules={[]}
                        help={
                          <div className="text-gray-600 text-sm">
                            Preview Text appears in the inbox, underneath the
                            Subject Line and provides a sneak peak into the
                            email.
                          </div>
                        }
                      >
                        <Input spellCheck={"true"} />
                      </Form.Item>
                    </>
                  );
                }}
              </Form.Item>

              <div className="mb-12"></div>
            </div>
          </div>
          {/* <Divider type="vertical" /> */}
          <div className="preview flex-1 p-4 relative">
            {!(emailData.emailEditorType && emailData.body) && (
              <>
                <div className="email-preview border border-gray-200 rounded-lg dark:border-gray-800 p-8 overflow-auto w-full h-full flex flex-col justify-center items-center">
                  <div
                    style={{ maxWidth: 600 }}
                    className="flex flex-col justify-center items-center"
                  >
                    <div className="mb-4 text-4xl text-gray-600">
                      How would you like to write the email?
                    </div>
                    <PillSelector
                      options={EmailEditorTypeOptions}
                      value={emailData.emailEditorType}
                      onChange={(_emailEditorType) => {
                        if (_emailEditorType) {
                          const emailEditorType = (
                            typeof _emailEditorType === "string"
                              ? _emailEditorType
                              : (_emailEditorType[0] as any)
                          ) as iEmailEditorTypes;

                          setEmailData((data) => ({
                            ...data,
                            emailEditorType,
                          }));
                          triggerTempModal(SequenceEmailEditor, {
                            data: {
                              body: emailData.body || "",
                              emailEditorType:
                                emailEditorType || "SIMPLE_TEXT_EMAIL",
                              advancedEmailPageData:
                                emailData.advancedEmailPageData,
                              simpleMailBlocks: emailData.simpleMailBlocks,
                            },
                            onSave: (data) => {
                              setEmailData(data as any);
                              setTimeout(() => {
                                saveData();
                              }, 200);
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            {emailData.emailEditorType && emailData.body && (
              <>
                <Tooltip title="Clear Email">
                  <Button
                    icon={<i className="ri-eraser-line"></i>}
                    type="dashed"
                    className="absolute right-0 top-0"
                    onClick={() => {
                      setEmailData({ ...emptyEmailData });
                    }}
                  />
                </Tooltip>
                <div
                  className="email-preview-with-editor-hover border border-gray-200 rounded-lg dark:border-gray-800 p-8 overflow-auto w-full h-full cursor-pointer hover:border-blue-600 hover:border-dashed group relative"
                  onClick={() => {
                    triggerTempModal(SequenceEmailEditor, {
                      data: {
                        body: emailData.body || "",
                        emailEditorType:
                          emailData.emailEditorType || "SIMPLE_TEXT_EMAIL",
                        advancedEmailPageData: emailData.advancedEmailPageData,
                        simpleMailBlocks: emailData.simpleMailBlocks,
                      },
                      onSave: (data) => {
                        setEmailData(data as any);
                        setTimeout(() => {
                          saveData();
                        }, 200);
                      },
                    });
                  }}
                >
                  <div className="email-preview-inner">
                    <ReactIframeResizer
                      iframeResizerEnable={true}
                      content={`${emailData.body}`}
                      iframeResizerOptions={{
                        autoResize: true,
                        checkOrigin: false,
                        heightCalculationMethod: "max",
                        resizeFrom: "parent",
                      }}
                    ></ReactIframeResizer>
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: emailData.body,
                      }}
                    /> */}
                  </div>
                  <div
                    className="item-visible-on-hover w-full h-full absolute top-0 left-0 flex flex-col justify-center items-center"
                    style={{ background: "#ffffff22" }}
                  >
                    <div className="icon">
                      <i className="ri-pencil-line text-10xl text-gray-600"></i>
                    </div>
                    <div className="label text-4xl text-gray-600">
                      Edit Email
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Form>

      <DarkModeBg />
    </Modal>
  );
};
