import { SDK } from "@sdk";
import { find } from "lodash";
import { loadAllBadges } from "store/modules/badges/badges.helpers";
import { selectAllBadges } from "store/modules/badges/badges.selectors";
import { store } from "store/store";

export class BadgesController {
  static init() {
    loadAllBadges(store);
  }
  static processBadgeEvent(
    badgeId:
      | "create-your-first-task"
      | "send-a-testimonial"
      | "use-availability"
      | "join-a-conversation" // Not Done
      | "make-your-first-comment"
      | "finish-2x-tours" // Multiple Events
      | "use-dark-mode"
      | "explore-2-report-types" // Multiple Events
      | "watch-an-academy-video" // Not done
      | "invite-a-user"
      | "use-chat-filters"
      | "make-your-first-private-reply"
      | "use-mentions"
      | "use-bulk-actions"
      | "use-presets"
      | "use-report-inspector"
      | "transfer-a-conversation"
      | "explore-1-report-type" // Not done
      | "use-reminders"
      | "use-cross-channel-communication" // Not Done
      | "suggest-a-feature" // Not Done
      | "use-scheduled-message"
      | "explore-all-reports" // Multiple Events
      | "update-crm-record" // Not Done
      | "send-your-first-message"
      | "tag-a-conversation"
      | "finish-5x-tours" // Multiple Events
  ) {
    // Check if the badge is already earned and then add the badge
    const earnedBadges = selectAllBadges(store.getState());
    const isBadgeEarned = find(earnedBadges, { badgeId });
    if (!isBadgeEarned) {
      SDK.badges.create({
        badgeId,
        isEarned: true
      });
    }
  }
}
