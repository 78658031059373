import { NotificationInstance } from "antd/lib/notification";
import _ from "lodash";
import { AudioNotificationsService } from "./audio-notifications-controller";
import { LocalNotificationsService } from "./local-notifications-controller";
import {
  dismissInAppNotification,
  fireInAppNotification,
  iNotification
} from "./on-screen-notifications-controller";

interface iNotificationPreference {
  sound: boolean;
  visual: boolean;
}

export class Notifications {
  static notifications: iNotification[] = [];
  static inAppNotificationAPI: NotificationInstance;

  static fire = (notification: iNotification) => {
    if (notification.audio) {
      AudioNotificationsService.playAudio(notification.audio);
    }

    for (const channel of notification.channels) {
      switch (channel) {
        case "BROWSER": {
          const browserNotification = LocalNotificationsService.displayNotification(
            {
              title: notification.title,
              body: notification.body,
              tag: notification.tag,
              data: notification.data,
              callback: notification.onClick
              // icon: notification.icon
            }
          );
          //  Add Refs
          if (browserNotification) {
            notification.refs.browser = browserNotification;
          }
          break;
        }
        case "ONSCREEN": {
          // console.log("On Screen", notification.type);
          // Check if notification with the same tag exist, if so dismiss them
          const notificationWithTagReference = _.find(
            Notifications.notifications,
            {
              tag: notification.tag
            }
          );
          if (
            notificationWithTagReference &&
            notificationWithTagReference !== notification
          ) {
            // console.log('Dismissing Notification', notificationWithTagReference, notification);
            Notifications.dismiss(notificationWithTagReference);
          }

          if (notification.type === "PROMPT") {
            fireInAppNotification(notification, this.inAppNotificationAPI);
            // Add Refs
            notification.refs.antd = notification.id;
          } else if (notification.type === "SIMPLE") {
            fireInAppNotification(notification, this.inAppNotificationAPI);
            // Add Refs
            notification.refs.antd = notification.id;
          } else {
            console.log("Un Supported Notification Type");
          }
          break;
        }
      }
    }
    Notifications.notifications.push(notification);
  };

  static dismiss = (notification: iNotification) => {
    if (notification.refs.antd) {
      dismissInAppNotification(
        notification.refs.antd,
        this.inAppNotificationAPI
      );
    }
    if (notification.refs.browser) {
      LocalNotificationsService.dismiss({
        notification: notification.refs.browser
      });
    }
    // remove notification from this.notifications
    _.pull(Notifications.notifications, notification);
  };
}
