import { appFeatureRequestCategories } from "@sdk/app-feature-requests/app-feature-requests-model";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Form, Input, Modal, Select, message } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { LazyQuillEditor } from "components/common/quill-editor/lazy-quill-editor";
import { WithAttachments } from "components/pages/bot-builder/node-types/message-node/with-attachments";
import { DarkModeBg } from "dark-mode-bg";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";

export const RequestFeatureModal = ({
  visible,
  onChangeVisibility,
  onAdded,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onAdded?: () => any;
}) => {
  const organization = useSelector(selectOrganization);
  const [form] = Form.useForm();

  const initialValue = useMemo(() => ({}), []);

  const { doAction: requestFeature, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (req) =>
        SDK.appFeatureRequests.create(req).then((d) => {
          onChangeVisibility(false);
          onAdded && onAdded();
        }),
      successMessage:
        "Our Product team will review your request before making it public",
      failureMessage: "Something went wrong",
    }),
    [onAdded, onChangeVisibility],
  );

  return (
    <Modal
      title={
        <ModalTitle
          title="Request a New Feature"
          icon={<i className="ri-file-edit-line"></i>}
        />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={600}
      okButtonProps={{
        loading: isProcessing,
        icon: <i className="ri-send-plane-2-line"></i>,
        className: "font-bold",
      }}
      okText="Send Request"
      onOk={async () => {
        try {
          await form.validateFields();
          const formValue = form.getFieldsValue();
          await requestFeature({
            type: "FEATURE_REQUEST",
            ...formValue,
          });
        } catch (e) {
          message.error("Please check your input");
        }
      }}
      className="request-new-feature-modal"
      data-click-context="Feature Request Modal"
    >
      <div>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValue}
          className="bold-form-labels"
          requiredMark={false}
        >
          <Form.Item
            name="category"
            label={"Feature Category"}
            rules={[
              {
                required: true,
                message: "Please field is required",
              },
            ]}
          >
            <Select showSearch placeholder="Select a category">
              {appFeatureRequestCategories.map((category) => (
                <Select.Option value={category.id} key={category.id}>
                  <div className="text-semibold">{category.id}</div>
                  <div className="text-gray-600">{category.description}</div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="title"
            label={"Describe your feature request in one line"}
            rules={[
              {
                required: true,
                message: "Please field is required",
              },
            ]}
          >
            <Input placeholder="Eg: Ability to do xyz" />
          </Form.Item>
          <Form.Item
            name="shortDescription"
            label={
              " Write a short description about the feature that you are requesting"
            }
            rules={[
              {
                required: true,
                message: "Please field is required",
              },
            ]}
          >
            <Input.TextArea placeholder="Eg: Use cases, how it should work etc" />
          </Form.Item>
          <Form.Item label="Additional Details" name={["body"]}>
            <LazyQuillEditor />
          </Form.Item>

          <Form.Item
            name={["attachments"]}
            valuePropName="initialAttachments"
            trigger={"onAttachmentsChange"}
          >
            <WithAttachments
              id={`FEATURE_REQUEST`}
              withoutBorder={true}
              entityType="FEATURE_REQUEST"
              entityId={organization?.id!}
            >
              <div className="text-gray-600 text-center border-2 rounded-lg p-4 border-dashed border-gray-600">
                Drag your images here to upload attachments here
                <br />
                or just paste your image
              </div>
            </WithAttachments>
          </Form.Item>
        </Form>
      </div>
      <DarkModeBg />
    </Modal>
  );
};
