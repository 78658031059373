import { mdiFilePdfBox } from "@mdi/js";
import { iMessageAttachment } from "@sdk/conversations/conversations.models";
import { Button, Tooltip } from "antd";
import { ImageX } from "components/common/image";
import { MDIIcon } from "components/common/mdi-icon";
import { TaskCard } from "components/modules/tasks/task-card";
import _ from "lodash";
import "react-h5-audio-player/lib/styles.css";
import { AddEllipsis } from "utils/add-ellipsis";
import { GetCCFilename } from "utils/get-cc-filename";
import { ExtensionsFileMap } from "utils/get-extension-type";
import { ActionLabels } from "../action-editor/actions";
import { iAction } from "../action-editor/models";
import { AiAssistMessageList } from "./ai-assist-message-list";
import { LinkAttachment } from "./components/link-attachment";
import { MessageRelatedArticles } from "./components/related-articles";
import { showAudioModal } from "./components/show-audio-modal";
import { showVideoModal } from "./components/show-video-modal";
import { VCard } from "./components/vcard";
import "./message-attachment.scss";
const ExtensionIcon = ({ url, type }: { url: string; type?: string }) => {
  const extension = _.last(GetCCFilename(url).split(".")) || "unknown";

  const iconMaps = {
    // 'graphics_editor',
    video: <i className="ri-vidicon-fill text-2xl"></i>,
    audio: <i className="ri-file-music-fill text-2xl"></i>,
    image: <i className="ri-image-2-fill text-2xl"></i>,
    spreadsheet: <i className="ri-file-excel-2-fill text-2xl"></i>,
    // 'project_management',
    presentation: <i className="ri-file-ppt-2-fill text-2xl"></i>,
    // 'diagramming',
    word_processing: <i className="ri-file-word-fill text-2xl"></i>,
    document: <i className="ri-file-pdf-fill text-2xl"></i>,
    pdf: <MDIIcon icon={mdiFilePdfBox} size="3rem" />,
  };
  if (type) {
    if (type === "application/pdf") {
      type = "pdf";
    }
    if (iconMaps[type]) {
      return iconMaps[type];
    }
    const matchedType = Object.keys(iconMaps).filter(
      (fileType) => type?.toLowerCase().includes(fileType),
    )[0];

    if (matchedType) {
      return iconMaps[matchedType];
    }
  }

  const icon = iconMaps[ExtensionsFileMap[extension.toLowerCase()]];

  if (icon) {
    return icon;
  }

  return <i className="ri-attachment-line text-2xl"></i>;
};

const isImageExtension = (url) => {
  const extension = _.last(GetCCFilename(url).split(".")) || "unknown";
  return ExtensionsFileMap[extension.toLowerCase()] === "image";
};
const isPDFExtension = (url) => {
  const extension = _.last(GetCCFilename(url).split(".")) || "unknown";
  return extension.toLowerCase() === "pdf";
};
const isAudioExtension = (url) => {
  const extension = _.last(GetCCFilename(url).split(".")) || "unknown";
  return ExtensionsFileMap[extension.toLowerCase()] === "audio";
};
const isVideoExtension = (url) => {
  const extension = _.last(GetCCFilename(url).split(".")) || "unknown";
  return ExtensionsFileMap[extension.toLowerCase()] === "video";
};

export const MessageAttachments = ({
  attachments,
  showPDFDrawer,
  ignoreTasks,
}: {
  attachments: iMessageAttachment[];
  showPDFDrawer: (any) => any;
  ignoreTasks?: boolean;
}) => {
  const fileAttachments = attachments.filter(
    (attachment) => attachment.type === "FILE",
  );
  const linkAttachments = attachments.filter(
    (attachment) => attachment.type === "LINK",
  );

  const otherAttachments = attachments.filter(
    (attachment) =>
      attachment.type === "FB_POST_REF" ||
      attachment.type === "FB_MESSENGER_REFERRAL",
  );

  const locationAttachments = attachments.filter(
    (attachment) => attachment.type === "LOCATION",
  );

  const callLogsAttachments = attachments.filter(
    (attachment) => attachment.type === "CALL_LOG",
  );

  const vCardAttachments = attachments.filter(
    (attachment) => attachment.type === "V_CARD",
  );

  const messageReferenceAttachments = attachments.filter(
    (attachment) => attachment.type === "MESSAGE_REFERENCE",
  );

  const taskReferenceAttachments = attachments.filter(
    (attachment) => attachment.type === "TASK",
  );

  const executedActionsAttachments = attachments.filter(
    (attachment) => attachment.type === "ACTION_LIST",
  );

  const relatedArticles = attachments.filter(
    (attachment) => attachment.type === "RELATED_ARTICLES",
  );

  const aiAssistSessions = attachments.filter(
    (attachment) => attachment.type === "AI_ASSIST_SESSION",
  );

  const openUrlInNewWindow = (url: string) => {
    const win = window.open(url, "_blank");
    win && win.focus();
  };

  return (
    <>
      {fileAttachments.length > 0 && (
        <div className="file-attachments">
          {fileAttachments.map((attachment, index) => (
            <div className="attachment" key={index.toString()}>
              {(attachment.payload?.type?.indexOf("image") > -1 ||
                isImageExtension(attachment.payload?.file?.url)) && (
                <ImageX
                  src={attachment.payload?.file?.url}
                  width={"auto"}
                  style={{
                    maxHeight: 120,
                    height: "100%",
                    width: "auto",
                    minHeight: 120,
                  }}
                  className="flex justify-center items-center"
                  // height={120}
                />
              )}
              {!(
                attachment.payload?.type?.indexOf("image") > -1 ||
                isImageExtension(attachment.payload?.file?.url)
              ) && (
                <div
                  className="flex justify-center items-center flex-col"
                  onClick={() => {
                    if (isPDFExtension(attachment.payload?.file?.url)) {
                      openUrlInNewWindow(attachment.payload?.file?.url);
                      // showPDFDrawer(attachment.payload?.file?.url);
                    } else if (
                      isAudioExtension(attachment.payload?.file?.url)
                    ) {
                      showAudioModal(attachment.payload?.file?.url);
                    } else if (
                      isVideoExtension(attachment.payload?.file?.url)
                    ) {
                      showVideoModal(attachment.payload?.file?.url);
                    } else {
                      openUrlInNewWindow(attachment.payload?.file?.url);
                    }
                    // else
                  }}
                >
                  <ExtensionIcon
                    url={attachment.payload?.file?.url}
                    type={attachment.payload?.type}
                  />
                  <div className="text">
                    {AddEllipsis(
                      GetCCFilename(
                        attachment.payload?.file?.name ||
                          attachment.payload?.file?.url,
                      ),
                      20,
                    )}
                  </div>
                  {}
                  <div className="hover">
                    <i className="ri-eye-line text-xl"></i>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {linkAttachments.length > 0 &&
        linkAttachments.map((attachment, index) => (
          <LinkAttachment attachment={attachment} key={index.toString()} />
        ))}
      {locationAttachments.length > 0 &&
        locationAttachments.map((attachment, index) => (
          <div
            className="attachment-inner-cont location"
            key={index.toString()}
          >
            <ImageX
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${attachment.payload.latitude},${attachment.payload.longitude}&zoom=14&size=400x400&key=AIzaSyA0M-_OMjaUU7ikaUJXM5y9yhFSc7Efr1o`}
              width={"auto"}
              style={{ maxHeight: 120, height: "100%", width: "auto" }}
              className="flex justify-center items-center"
              // height={120}
            />
            <div className="more-links mt-4">
              <Button
                type="link"
                className="clickable"
                href={`https://www.google.com/maps/search/?api=1&query=${attachment.payload.latitude}%2C${attachment.payload.longitude}`}
                target="_blank"
              >
                View in Maps
              </Button>
            </div>
          </div>
        ))}
      {callLogsAttachments.length > 0 &&
        callLogsAttachments.map((attachment, index) => (
          <div
            className="attachment-inner-cont call-log"
            key={index.toString()}
          >
            {attachment.payload.isMissedCall ? (
              <div className="call flex flex-row items-center">
                <i className="ri-phone-line"></i> Missed Call
              </div>
            ) : (
              <div className="call flex flex-row items-center">
                <i className="ri-phone-line"></i> Call Log
              </div>
            )}
          </div>
        ))}
      {vCardAttachments.length > 0 &&
        vCardAttachments.map((attachment, index) => (
          <div className="attachment-inner-cont vcard" key={index.toString()}>
            <VCard data={attachment.payload.vCard} />
          </div>
        ))}
      {messageReferenceAttachments.length > 0 &&
        messageReferenceAttachments.map((attachment, index) => (
          <div
            className="attachment-inner-cont message-reference"
            key={index.toString()}
          >
            <div className="text-gray-600 dark:text-gray-400 mode_transition">
              Reply to:{" "}
            </div>
            <div className="bubble">{attachment.payload.message?.message}</div>
            <MessageAttachments
              attachments={attachment.payload.message?.attachments || []}
              showPDFDrawer={showPDFDrawer}
            />
          </div>
        ))}
      {taskReferenceAttachments.length > 0 &&
        taskReferenceAttachments.map((attachment, index) => (
          <div
            className="attachment-inner-cont task-reference"
            key={index.toString()}
          >
            {ignoreTasks ? "" : <TaskCard taskId={attachment.payload.taskId} />}
          </div>
        ))}
      {otherAttachments.map((attachment, index) => (
        <div className="attachment" key={index.toString()}>
          {attachment.type === "FB_POST_REF" && (
            <div className="attachment-inner-cont link">
              <div className="text">
                {attachment?.payload?.postData?.message}
              </div>
              <div className="mat-caption secondary-text">
                {attachment?.payload?.postRefUrl}
              </div>
              {attachment?.payload?.postData?.image && (
                <div className="image">
                  <img src={attachment.payload.postData.image} alt="" />
                </div>
              )}

              {/* <div className="more-links mt-4">
                <a className="clickable" onClick={()=>{}}>Open Post</a>
              </div> */}
            </div>
          )}
          {attachment.type === "FB_MESSENGER_REFERRAL" && (
            <div className="attachment-inner-cont link">
              <div className="text">
                {attachment?.payload?.ads_context_data?.ad_title}
              </div>
              <div className="mat-caption secondary-text">
                {attachment?.payload?.ad_id}
              </div>
              {(attachment?.payload?.ads_context_data?.ad_title ||
                attachment?.payload?.ads_context_data?.video_url) && (
                <div className="image">
                  <img
                    src={
                      attachment?.payload?.ads_context_data?.ad_title ||
                      attachment?.payload?.ads_context_data?.video_url
                    }
                    alt=""
                  />
                </div>
              )}
              {/* {attachment?.payload?.ads_context_data?.post_id && (
                <div className="more-links mt-4">
                  <a className="clickable">Open Post</a>
                </div>
              )} */}
            </div>
          )}
          {/* Old */}
          {
            // else if (
            //   attachment.type == "FB_MESSENGER_REFERRAL" &&
            //   loadReferenceToCache(attachment.payload.ad_id) &&
            //   fbAddReferencesCache[attachment.payload.ad_id]
            // ) {
            //   return (
            //     <div className="attachment-inner-cont link">
            //       <div className="text">
            //         {
            //           fbAddReferencesCache[attachment.payload.ad_id]
            //             .data?.label
            //         }
            //       </div>
            //       <div className="mat-caption secondary-text">
            //         {
            //           fbAddReferencesCache[attachment.payload.ad_id]
            //             .data?.body
            //         }
            //       </div>
            //       {fbAddReferencesCache[attachment.payload.ad_id]
            //         ?.data.attachments && (
            //         <div className="image">
            //           <img
            //             src={
            //               fbAddReferencesCache[
            //                 attachment.payload.ad_id
            //               ].data.attachments[0]
            //             }
            //           />
            //         </div>
            //       )}
            //       <div className="more-links mt-4">
            //         <a className="clickable">View</a>
            //       </div>
            //     </div>
            //   );
            // }
          }
        </div>
      ))}
      {executedActionsAttachments.length > 0 &&
        executedActionsAttachments.map((attachment, index) => (
          <div
            className="attachment-inner-cont action-execution-status"
            key={index.toString()}
          >
            <Tooltip title="Executed Actions">
              <div className="flex flex-row flex-wrap gap-4">
                {attachment.payload.actions.map(
                  (action: iAction & { status?: "SUCCESS" | "FAILED" }) => (
                    <div
                      key={action.id}
                      className="p-2 rounded-lg border border-gray-200 dark:border-gray-800 flex flex-row justify-center items-center"
                    >
                      <div className="status mr-2">
                        {action.status === "SUCCESS" && (
                          <i className="ri-checkbox-circle-line"></i>
                        )}
                        {action.status === "FAILED" && (
                          <i className="ri-error-warning-line"></i>
                        )}
                        {!action.status && (
                          <i className="ri-loader-line animate-spin block"></i>
                        )}
                      </div>
                      <div className="label">{ActionLabels[action.action]}</div>
                    </div>
                  ),
                )}
              </div>
            </Tooltip>
          </div>
        ))}

      {relatedArticles.length > 0 &&
        relatedArticles.map((attachment, index) => (
          <div
            className="attachment-inner-cont action-execution-status"
            key={index.toString()}
          >
            <MessageRelatedArticles attachment={attachment} />
          </div>
        ))}

      {aiAssistSessions.length > 0 &&
        aiAssistSessions.map((attachment, index) => (
          <div
            className="attachment-inner-cont action-execution-status"
            key={index.toString()}
          >
            <div className="flex flex-row gap-4 font-bold">
              <i className="ri-magic-line"></i> Magic Assistant
            </div>
            <AiAssistMessageList
              conversationId={"xxxx"}
              messages={attachment.payload.messages}
            />
          </div>
        ))}
    </>
  );
};
