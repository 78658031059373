import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Badge, Button, Divider, message, Select } from "antd";
import { push } from "connected-react-router";
import _ from "lodash";
import { forwardRef, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { selectContactTags } from "store/modules/workspace/workspace.selectors";

export const ContactTagSelector = forwardRef(
  (
    {
      value,
      onChange,
      ...props
    }: {
      value?: string[];
      onChange?: (tags: string[]) => any;
      autoFocus?: boolean;
    },
    ref,
  ) => {
    const contactTags = useSelector(selectContactTags);
    const tagGroups = useMemo(
      () => _.groupBy(contactTags, "tagGroup"),
      [contactTags],
    );

    const [inputTerm, setInputTerm] = useState("");

    const tagSelector = useMemo(
      () => ({
        mode: "multiple" as const,
        style: { width: "100%" },
        value: value || [],
        // options: (conversationTags || []).map((tag) => ({
        //   label: (
        //     <span>
        //       <Badge color={tag.color} dot></Badge> {tag.label}
        //     </span>
        //   ),
        //   value: tag.label,
        //   color: tag.color,
        // })),
        onChange: (val) => {
          onChange && onChange(val);
          setInputTerm("");
        },
        placeholder: "Tag...",
        maxTagCount: "responsive" as const,
        onSearch: (val) => console.log("Search", val),
      }),
      [onChange, value],
    );

    const dispatch = useDispatch();

    const currentUser = useSelector(selectCurrentUser);
    const userRole = useMemo(() => {
      const currentUserRole = getRoleFromPermission(currentUser?.permissions);
      return currentUserRole;
    }, [currentUser?.permissions]);

    const onConfigureTagClick = useCallback(() => {
      if (userRole === "Owner") {
        dispatch(
          push(
            `/configurations/crm-settings/general?activeConfigs=Customer%20Tags`,
          ),
        );
      } else {
        message.warning(
          "Please contact your workspace administrator to configure tags",
        );
      }
    }, [dispatch, userRole]);

    const otherTags = useMemo(
      () =>
        _.difference(
          value,
          (contactTags || [])
            .filter((e) => !e.isHidden)
            ?.map((item) => item.label),
        ),
      [contactTags, value],
    );

    const isMatchFound = useMemo(
      () => (contactTags || []).find((item) => item.label === inputTerm),
      [contactTags, inputTerm],
    );

    const { doAction: addContactTag } = useSDKActionWithDeps(
      () => ({
        action: (SDK) => (tag: string) =>
          SDK.addContactTag({
            label: tag,
            color: "#1273de",
            tagGroup: "General Tags",
          }),
        // successMessage: "Done",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

    const onAddTagClicked = useCallback(() => {
      addContactTag(inputTerm);
      if (value && onChange) {
        onChange && onChange([...value, inputTerm]);
      }
      setInputTerm("");
    }, [addContactTag, inputTerm, onChange, value]);

    const [isDropDownOpen, setDropDownOpen] = useState(true);

    return (
      <Select
        {...tagSelector}
        {...props}
        searchValue={inputTerm}
        onSearch={setInputTerm}
        bordered={false}
        ref={ref as any}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            {!isMatchFound && inputTerm.length > 2 && (
              <Button
                icon={<i className="ri-add-line"></i>}
                block
                type="text"
                onClick={onAddTagClicked}
              >
                Create {inputTerm} as Tag
              </Button>
            )}
            <Button
              icon={<i className="ri-chat-settings-line"></i>}
              block
              type="text"
              onClick={onConfigureTagClick}
            >
              Configure Contact Tags
            </Button>
          </>
        )}
      >
        {Object.keys(tagGroups).map((tagGroup) => (
          <Select.OptGroup
            label={tagGroup === "undefined" ? "General" : tagGroup}
            key={tagGroup}
          >
            {tagGroups[tagGroup]
              .filter((e) => !e.isHidden)
              .sort((a, b) => (a.label || "").localeCompare(b.label))
              .map((tag) => (
                <Select.Option value={tag.label} key={tag.label}>
                  <span>
                    <Badge color={tag.color} dot></Badge> {tag.label}
                  </span>
                </Select.Option>
              ))}
          </Select.OptGroup>
        ))}
        {otherTags.length > 0 && (
          <Select.OptGroup label={"Other"} key={"other--not-saved"}>
            {otherTags.map((tag) => (
              <Select.Option value={tag} key={tag}>
                <span>
                  <Badge color="blue" dot></Badge> {tag}
                </span>
              </Select.Option>
            ))}
          </Select.OptGroup>
        )}
      </Select>
    );
  },
);
