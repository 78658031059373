import { Tag } from "antd";
import _ from "lodash";

export interface iPricingPlan {
  id: string;
  name: string;
  description: string;
  price: string;
  planId?: string;
  limits: {
    users: number;
    connections: number;
  };
  isRecommended?: boolean;
  listItems: {
    icon: any;
    content: string;
  }[];
}

const CheckMark = <i className="ri-check-line"></i>;
const Users = <i className="ri-group-line"></i>;
const Support = <i className="ri-customer-service-line"></i>;
const AccountManager = <i className="ri-account-pin-box-line"></i>;
const NotIncluded = <i className="ri-close-line"></i>;
const powerCord = <i className="ri-plug-line"></i>;
const power = <i className="ri-flashlight-fill"></i>;
const warning = <i className="ri-error-warning-fill"></i>;
const magicWand = <i className="ri-magic-line"></i>;

export const CalculateAnnualPlanPrice = (plan: iPricingPlan) => {
  const annualPrice = Number(plan.price.slice(1))
    ? `$${Math.round((Number(plan.price.slice(1)) * 10) / 12)}`
    : plan.price;
  return annualPrice;
};

export const TEAM_PRICING: iPricingPlan[] = [
  {
    id: "TEAM",
    name: "Team",
    description: "For Small Teams",
    price: "$99",
    limits: {
      users: 3,
      connections: 3,
    },
    listItems: [
      // {
      //   icon: CheckMark,
      //   content: "Everything in 'Startup' plan"
      // },
      // {
      //   icon: powerCord,
      //   content: "Omni Channel Conversations"
      // },
      // {
      //   icon: powerCord,
      //   content: "Facebook | Instagram | Whatsapp"
      // },
      // {
      //   icon: power,
      //   content: "Basic Reports"
      // },
      {
        icon: warning,
        content: "3 Channels",
      },
      {
        icon: warning,
        content: "3 users",
      },
    ],
  },
  {
    id: "TEAM_PLUS",
    name: "Team Plus",
    description: "For Growing Teams",
    price: "$249",
    limits: {
      users: 10,
      connections: 5,
    },
    listItems: [
      // {
      //   icon: CheckMark,
      //   content: "Everything in 'Team' plan"
      // },

      // {
      //   icon: magicWand,
      //   content: "Zapier Integration"
      // },
      // {
      //   icon: magicWand,
      //   content: "Workflow Automation",
      // },
      {
        icon: warning,
        content: "6 Channels",
      },
      {
        icon: warning,
        content: "6 Users",
      },
    ],
  },
];

export const APPSUMO_PLANS: iPricingPlan[] = [
  {
    id: "APP_SUMO_LICENSE",
    name: "Appsumo License",
    description: "LTD Purchased from AppSumo",
    price: "$0",
    limits: {
      users: 3,
      connections: 2,
    },
    listItems: [],
  },
];

export const STARTUP_PRICING: iPricingPlan[] = [
  {
    id: "STARTUP",
    name: "Startup",
    description: "For startups that are sprouting",
    price: "Free",
    limits: {
      users: 3,
      connections: 2,
    },
    listItems: [
      // {
      //   icon: powerCord,
      //   content: "Live Chat"
      // },
      // {
      //   icon: powerCord,
      //   content: "Email Help Desk"
      // },
      // {
      //   icon: power,
      //   content: "CRM"
      // },
      // {
      //   icon: power,
      //   content: "Auto triggered conversation starters"
      // },
      // {
      //   icon: power,
      //   content: "Lead Collection"
      // },
      {
        icon: warning,
        content: "3 users",
      },
      {
        icon: warning,
        content: "2 channels",
      },
      {
        icon: warning,
        content: "50 Conversations/months",
      },
    ],
  },
  // {
  //   id: "STARTUP_PLUS",
  //   name: "Startup Plus",
  //   description: "A little extra",
  //   price: "$49",
  //   limits: {
  //     users: 3,
  //     connections: 2,
  //   },
  //   listItems: [
  //     // {
  //     //   icon: CheckMark,
  //     //   content: "Everything in 'Startup' plan"
  //     // },
  //     // {
  //     //   icon: magicWand,
  //     //   content: "Zapier Integration"
  //     // },
  //     // {
  //     //   icon: magicWand,
  //     //   content: "Workflow Automation"
  //     // },
  //     // {
  //     //   icon: power,
  //     //   content: "Mobile App"
  //     // },
  //     // {
  //     //   icon: CheckMark,
  //     //   content: "Unlimited Conversation"
  //     // },

  //     {
  //       icon: warning,
  //       content: "3 users",
  //     },
  //     {
  //       icon: warning,
  //       content: "2 Channels",
  //     },
  //   ],
  // },
];

export const BUSINESS_PRICING: iPricingPlan[] = [
  {
    id: "BUSINESS",
    name: "Business",
    description: "For Growing Businesses",
    price: "$499",
    limits: {
      users: 15,
      connections: 10,
    },
    isRecommended: true,
    listItems: [
      // {
      //   icon: CheckMark,
      //   content: "Everything in Team"
      // },
      {
        icon: CheckMark,
        content: "10 Channels",
      },
      {
        icon: CheckMark,
        content: "15 users",
      },
    ],
  },
  {
    id: "BUSINESS_PLUS",
    name: "Business Plus",
    description: "For Large Businesses",
    price: "$799",
    limits: {
      users: 25,
      connections: 10,
    },
    listItems: [
      // {
      //   icon: CheckMark,
      //   content: "Everything in Business plan"
      // },
      // {
      //   icon: magicWand,
      //   content: "Workflow Automation",
      // },
      {
        icon: CheckMark,
        content: "10 Channels",
      },
      {
        icon: CheckMark,
        content: "25 users",
      },
      // {
      //   icon: magicWand,
      //   content: "Zapier Integration"
      // },
      // {
      //   icon: magicWand,
      //   content: "Workflow Automation"
      // }
    ],
  },
  {
    id: "ENTERPRISE",
    name: "Enterprise",
    description: "For large teams and enterprises \n -",
    price: "Talk to us",
    limits: {
      users: 50,
      connections: 20,
    },
    listItems: [
      // {
      //   icon: CheckMark,
      //   content: "Everything in Business+ plan"
      // },
      {
        icon: CheckMark,
        content: "50 Users",
      },
      {
        icon: CheckMark,
        content: "User Training",
      },
      {
        icon: CheckMark,
        content: "SSO",
      },
      {
        icon: magicWand,
        content: "Custom Integrations",
      },
    ],
  },
];

export const GetPricingPlanById = (planId: string) => {
  const id = planId.replace(`_YEARLY`, "").replace(`_MONTHLY`, "");
  const plan = _.find(
    [...STARTUP_PRICING, ...TEAM_PRICING, ...BUSINESS_PRICING],
    {
      id,
    },
  );
  if (plan) {
    return {
      ...plan,
      planId,
    };
  }
  return undefined;
};

export interface iAddOnOption {
  addOnId: string;
  title: string;
  pricing: {
    monthly: string;
    yearly: string;
  };
  description: string | JSX.Element;
}

export const AddOnLabels = {
  WHATSAPP: "Whatsapp Instance",
  MOBILE_NUMBER: "Mobile Number",
};

export const AvailableAddons: iAddOnOption[] = [
  {
    addOnId: "WHATSAPP",
    title: "WhatApp Instance",
    pricing: {
      monthly: "$49",
      yearly: "$41",
    },
    description: (
      <>
        Connect Either Whatsapp Business Account or Private Whatsapp Integration
        to send and receive WA messages via ClickConnector.
        <br />
        <div className="mt-2 mb-2">* Phone Number Not Included</div>
      </>
    ),
  },
  {
    addOnId: "MOBILE_NUMBER",
    title: "Mobile Number",
    pricing: {
      monthly: "$15",
      yearly: "$13",
    },
    description: (
      <>
        You can buy a number of your choice and you can use it to send SMS and
        make phone calls.
        <br /> <Tag>20 Minutes/Month</Tag>
        <Tag> 100 SMS/Month</Tag>
        <br />
        <div className="mt-2">* Speak to Sales for Additional Quota</div>
      </>
    ),
  },
];
