import { iAutomationWorkflowStep } from "@sdk/automation-workflows/automation-workflows-model";
import { Form, InputNumber, message, Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect, useMemo } from "react";

export const SequenceDelayEditorModal = ({
  visible,
  onChangeVisibility,
  step,
  onSave,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  step?: iAutomationWorkflowStep;
  onSave: (step: iAutomationWorkflowStep) => any;
}) => {
  const [form] = Form.useForm();

  const initialValue = useMemo(
    () => ({
      ...step?.data,
      delay: (step?.delay || 0) / (24 * 60 * 60 * 1000),
    }),
    [step?.data, step?.delay],
  );

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, step, form]);

  const onFinish = useCallback(async () => {
    try {
      await form.validateFields();
      const { delay } = form.getFieldsValue();
      onSave({
        ...step!,
        delay: delay * (24 * 60 * 60 * 1000),
      });
      onChangeVisibility(false);
    } catch (e) {
      message.error("Please check your input");
    }
  }, [form, onChangeVisibility, onSave, step]);

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-pencil-line"></i>}
          title="Edit Delay"
        />
      }
      open={visible}
      onOk={onFinish}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save Delay"
      okButtonProps={{ icon: <i className="ri-check-line"></i> }}
      data-click-context="Step Editor Modal"
      width={300}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValue}
        requiredMark={false}
        className="bold-form-labels w-full"
        onFinish={onFinish}
      >
        <div className="flex flex-col h-full w-full">
          <Form.Item
            name={["delay"]}
            label="Time to wait after this step"
            rules={[
              {
                required: true,
                message: "Please enter time to wait after this step ",
              },
            ]}
          >
            <InputNumber addonAfter={"Days"} max={7} className="w-full" />
          </Form.Item>
        </div>
      </Form>

      <DarkModeBg />
    </Modal>
  );
};
