import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Result } from "antd";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { TelnyxPhone } from "./components/telnyx-phone/telnyx-phone";

export const SoftPhoneInterface = () => {
  const { state: connections, retry: reload } = useQueryWithStore(
    selectAllConnections,
    loadAllConnections
  );
  const currentUser = useSelector(selectCurrentUser);

  const sipCredential = currentUser?.data?.sipUser;

  const telephoneConnection = useMemo(() => {
    return connections.find(item => item.type === "TELNYX");
  }, [connections]);

  const { doAction: activateSipUser, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.telnyx.registerForSip(telephoneConnection?.id!),
      successMessage: "Phone is activated",
      failureMessage: "Something went wrong"
    }),
    [telephoneConnection?.id]
  );

  return (
    <div className="w-screen max-w-sm h-screen" style={{ maxHeight: 500 }}>
      {!telephoneConnection && (
        <div className="max-w-sm">
          <Result
            status="info"
            title="No Telephony Connections Found"
            subTitle={`Please connect a number before you could make calls from Click Connector`}
          />
        </div>
      )}
      {telephoneConnection && !sipCredential && (
        <div className="flex flex-col gap-4 w-full justify-center items-center">
          <i className="ri-phone-fill text-4xl"></i>
          <div className="text-gray-600">
            You still have not activated telephony
          </div>

          <Button
            type="primary"
            key="activate"
            onClick={activateSipUser}
            loading={isProcessing}
          >
            Activate Phone
          </Button>
        </div>
      )}
      {telephoneConnection && sipCredential && (
        <div className="w-full h-full">
          <TelnyxPhone />
        </div>
      )}
    </div>
  );
};
