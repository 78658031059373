import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { Button, Modal, Table } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import {
  GenerateContactsTableColumns,
  iContactListItem,
} from "components/pages/contacts/contacts-table-columns";
import "components/pages/conversations/conversations.page.style.scss";
import { DarkModeBg } from "dark-mode-bg";
import dayjs from "dayjs";
import _ from "lodash";
import { ContactProfile } from "modal-registry";
import { memo, useMemo, useState } from "react";
import { useStore } from "react-redux";
import { loadAllContactLists } from "store/modules/contact-lists/contact-lists.helpers";
import { selectAllContactLists } from "store/modules/contact-lists/contact-lists.selectors";
import { contactsDefaultQueryConfig } from "store/modules/contacts/contacts-default-query";
import {
  contactQueryPageSize,
  loadContactsQuery,
  loadMoreContactsQuery,
} from "store/modules/contacts/contacts.helpers";
import { selectContactsQuery } from "store/modules/contacts/contacts.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { QueryConfig } from "store/utils/query-config";
import { useUrlState } from "utils/hooks/use-url-state";
import { getContactName } from "../../helpers/get-contact-name";

export const ContactsExplorerModal = memo(
  ({
    visible,
    onChangeVisibility,
    filter,
  }: {
    visible: boolean;
    onChangeVisibility: (state: boolean) => any;
    filter: any;
  }) => {
    const store = useStore();
    const [selectedContact, setSelectedContact] = useUrlState("contactId");

    const queryConfigObj: {
      queryConfig: QueryConfig;
      alias: string;
    } = useMemo(() => {
      const queryConfig = {
        query: filter,
        options: contactsDefaultQueryConfig.options,
      };
      return {
        queryConfig: queryConfig,
        alias: JSON.stringify(queryConfig),
      };
    }, [filter]);

    const {
      state: contactsQuery,
      retry: reloadContacts,
    } = useQueryWithStore(
      selectContactsQuery(queryConfigObj.alias),
      loadContactsQuery(queryConfigObj.queryConfig, queryConfigObj.alias),
      [queryConfigObj]
    );

    const { state: allContactLists, retry: reload } = useQueryWithStore(
      selectAllContactLists,
      loadAllContactLists
    );

    const data = useMemo(() => {
      const contactListMap = _.keyBy(allContactLists, "id");
      return (contactsQuery.list || []).map(
        (item) =>
          ({
            id: item.id,
            key: item.id,
            name: getContactName(item),
            phone: item.data.primaryMobile,
            email: item.data.primaryEmail,
            lists: item.marketingLists.map((list) => ({
              ...list,
              label: contactListMap[list.id]?.data.label,
            })),
            tags: item.data.tags,
            connections: item.metaData.connections,
            lastActivity: item?.metaData?.lastActivity,
            lastContact: item?.metaData?.lastContact,
          } as iContactListItem)
      );
    }, [allContactLists, contactsQuery]);

    const [isLoadingMore, setLoadingMore] = useState(false);
    const loadMore = () => {
      setLoadingMore(true);
      loadMoreContactsQuery(
        queryConfigObj.queryConfig,
        queryConfigObj.alias
      )(store).finally(() => {
        setLoadingMore(false);
      });
    };

    const columns = useMemo(() => GenerateContactsTableColumns(), []);

    const { changePanelState } = useModalPanels();

    return (
      <>
        <Modal
          title={
            <ModalTitle
              title={<>Contacts Explorer </>}
              icon={<i className="ri-filter-line"></i>}
            />
          }
          open={visible}
          footer={null}
          onCancel={() => {
            onChangeVisibility(false);
          }}
          // className="responsive-modal very-big-modal"
          // bodyStyle={{ padding: 0 }}
          data-click-context="Contacts Explorer Modal"
          width={900}
        >
          {visible && (
            <div className="content flex flex-col" style={{ height: "80vh" }}>
              {/* <div className="table-header py-6 flex flex-row justify-between items-center">
                <div className="left-side">
                  <div className="title text-xl font-bold">
                    Filtered Customers
                  </div>
                </div>
              </div> */}

              {Boolean(contactsQuery?.lastFetched) && (
                <div className="flex flex-row justify-end items-center text-gray-600 dark:text-gray-400 mode_transition">
                  Last Fetched on{" "}
                  {dayjs(contactsQuery.lastFetched).format("LL")}
                  <Button
                    type="link"
                    icon={<i className="ri-refresh-line"></i>}
                    onClick={() => reloadContacts()}
                  >
                    Reload records
                  </Button>
                </div>
              )}

              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                columns={columns}
                dataSource={data}
                pagination={false}
                onRow={(row) => ({
                  onClick: () => {
                    changePanelState(ContactProfile, true, {
                      contactId: row.id,
                    });
                  },
                  className: "cursor-pointer",
                })}
                locale={{
                  emptyText: (
                    <EmptyData
                      text="No Customer Records Found"
                      icon="ri-group-2-line"
                    />
                  ),
                }}
                loading={
                  contactsQuery.isLoading
                    ? {
                        indicator: <LoadingIndicatorWithSpin />,
                      }
                    : false
                }
                // components={AnimatedAntTableComponents}
              />
              {/* <CRMForms /> */}
              <div className="pagination-container p-4 flex flex-row justify-center">
                {(contactsQuery.lastFetchedPage || 1) * contactQueryPageSize <
                  (contactsQuery.totalItems || 0) && (
                  <Button
                    icon={<i className="ri-download-line"></i>}
                    onClick={loadMore}
                  >
                    Load More
                  </Button>
                )}
              </div>
            </div>
          )}
          <DarkModeBg />
        </Modal>
      </>
    );
  }
);
