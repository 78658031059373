import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDK } from "@sdk";
import { Button } from "antd";

import { ExpressAccountSetUp } from "modal-registry";

import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";

export const SubAccountFinalSetup = () => {
  const { changePanelState } = useModalPanels();

  const organization = useSelector(selectOrganization);

  const { data: parentWorkspace, isLoading } = useSDK(
    (SDK) =>
      SDK.getOrganizationPublicData(organization?.data?.parentOrganizationId!),
    [organization?.data?.parentOrganizationId],
    !organization?.data?.parentOrganizationId,
  );

  return (
    <div
      className="help flex flex-col justify-center items-center ml-4 relative overflow-hidden"
      style={{
        minHeight: 300,
      }}
    >
      <img
        src={parentWorkspace?.data?.logo}
        alt="Parent Workspace"
        style={{}}
      />
      <div style={{}}>
        Your workspace subscription billing is managed by{" "}
        {parentWorkspace?.data?.name}
      </div>

      <div
        className="button-container mb-4 flex flex-row justify-center items-center"
        style={{ width: "60%" }}
      >
        <Button
          type="primary"
          className="font-bold mt-4"
          icon={<i className="ri-rocket-2-line"></i>}
          onClick={() => {
            changePanelState(ExpressAccountSetUp, true, {});
          }}
        >
          Proceed to Express Account Setup
        </Button>
      </div>
    </div>
  );
};
