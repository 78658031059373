import { Modal, Tabs } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useEffect, useState } from "react";
import { uuidv4 } from "utils/generate-uuid";
import { useViewRefresher } from "utils/hooks/use-view-refresher";
import { ActionEditor } from "./action-editor";
import { iAction } from "./models";
const { TabPane } = Tabs;

const defaultAction: iAction = {
  action: "ADD_CONVERSATION_TAG",
  data: {}
};

export const ActionEditorModal = ({
  visible,
  onChangeVisibility,
  onSave,
  initialValue
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onSave: (action) => any;
  initialValue?: iAction;
}) => {
  const [action, setAction] = useState({ ...(initialValue || defaultAction) });

  useEffect(() => {
    setAction({ ...(initialValue || defaultAction) });
  }, [initialValue, visible]);

  const { visible: _visible } = useViewRefresher(visible);

  return (
    (<Modal
      title={
        <ModalTitle title="Add Action" icon={<i className="ri-add-line"></i>} />
      }
      open={visible}
      onOk={() => {
        // Todo
        onSave({
          ...action,
          id: initialValue?.id || uuidv4()
        });
        onChangeVisibility(false);
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save"
      className="action-editor-modal"
      data-click-context="Action Editor Modal"
    >
      {_visible && <ActionEditor action={action} _setAction={setAction} />}
      <DarkModeBg />
    </Modal>)
  );
};
