import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import {
  iMessage,
  iMessageSenderType,
} from "@sdk/conversations/conversations.models";
import { Image } from "antd";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import _, { findLast } from "lodash";
import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useStore } from "react-redux";
import { animated, useSpring } from "react-spring";
import useStayScrolled, { UseStayScrolledOptions } from "react-stay-scrolled";
import { useSelectorWithMemoize } from "store/utils/use-selector-with-memoize";
import { flattenObject } from "utils/flatten-object";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { useViewRefresher } from "utils/hooks/use-view-refresher";
import { loadConversationById } from "../../../../../store/modules/conversations/conversations.helpers";
import {
  selectConversationExists,
  selectConversationProperties,
} from "../../../../../store/modules/conversations/conversations.selectors";
import {
  loadMessages,
  loadMoreMessages,
} from "../../../../../store/modules/messages/messages.helpers";
import { selectMessageByConversationId } from "../../../../../store/modules/messages/messages.selectors";
import {
  useQueryWithStore,
  useQueryWithStoreWithMemoize,
} from "../../../../../store/store.hooks";
import { SimpleTable } from "../chat-view-side-bar/components/connector-side-bar";
import { SessionData } from "../chat-view-side-bar/components/session-data";
import { MessageForwarderModal } from "./message-forwarder-modal";
import {
  isFirstMessageOfGroup,
  isLastMessageOfGroup,
  shouldShowContactAvatar,
} from "./message-list-helpers";
import { MessagesListItem } from "./message-list-item";
import { RichMessageList } from "./rich-messsage-list";

export const MessagesList = memo(
  ({
    conversationId,
    onUpdateWatermark,
  }: {
    conversationId: string;
    onUpdateWatermark: (timestamp: number) => any;
  }) => {
    const {
      state: conversationExists,
      retry: reloadConversation,
      hasError: conversationNotFound,
    } = useQueryWithStoreWithMemoize(
      () => selectConversationExists(conversationId),
      [conversationId],
      false,
      () => loadConversationById(conversationId)(),
      [conversationId],
    );

    const [isLoadingMore, setLoadingMore] = useState(false);

    const {
      state: { data: messages, isFetching },
      retry: reloadMessages,
    } = useQueryWithStore(
      selectMessageByConversationId(conversationId),
      loadMessages(conversationId),
      [conversationId],
      !conversationId,
    );

    // useEffect(() => {
    //   if (conversationId) {
    //     reloadMessages();
    //   }
    // }, [conversationId]);

    const { visible } = useViewRefresher(conversationId);

    const store = useStore();

    const loadMore = useCallback(
      (ref: any) => {
        // console.log("Load More");
        // console.log('Ref',ref.current.scrollTop,ref.current.scrollHeight)
        const initialScrollHeight = ref?.current?.scrollHeight;
        setLoadingMore(true);
        loadMoreMessages(conversationId)(store)
          .then((d) => {
            setLoadingMore(false);
            if (ref?.current) {
              ref.current.scrollTop =
                ref?.current?.scrollHeight - initialScrollHeight;
            }
          })
          .catch((e) => {
            console.log("Error while loading more messages", e);
            setLoadingMore(false);
          });
      },
      [conversationId, store],
    );

    if (!visible || (isFetching && messages.length === 0)) {
      return (
        <div className="chat-messages flex-1 overflow-x-hidden overflow-y-auto flex flex-row justify-center items-center">
          <LoadingIndicatorWithoutSpin />
        </div>
      );
    }

    if (!conversationExists && conversationNotFound) {
      return (
        <div className="flex-1 flex flex-col overflow-hidden relative">
          <div className="flex w-full h-full absolute left-0 top-0 flex-row justify-center items-center z-10 bg-white dark:bg-gray-900 mode_transition">
            Conversation not found!
          </div>
        </div>
      );
    }

    return (
      <>
        {isLoadingMore && (
          <div className="flex flex-row justify-center items-center py-2">
            <LoadingOutlined style={{ fontSize: 20 }} />
          </div>
        )}

        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <_MessageList
          conversationId={conversationId}
          onUpdateWatermark={onUpdateWatermark}
          messages={messages}
          loadMore={loadMore}
        />
      </>
    );
  },
);

const _MessageList = memo(
  ({
    conversationId,
    onUpdateWatermark,
    messages,
    loadMore,
  }: {
    conversationId: string;
    onUpdateWatermark: (timestamp: number) => any;
    messages: iMessage[];
    loadMore: (ref: any) => any;
  }) => {
    const [notifyNewMessage, setNotifyNewMessage] = useState(false);
    const ref = useRef<HTMLDivElement>();

    const [{ scrollTop }, updateScroll] = useSpring(() => ({ scrollTop: 0 }));

    const runScroll = useCallback(
      (offset) => {
        updateScroll({
          scrollTop: offset,
          from: { scrollTop: ref.current ? ref.current.scrollTop : 0 },
          reset: true,
        });
      },
      [updateScroll],
    );

    const { scrollBottom, stayScrolled, isScrolled } = useStayScrolled(
      ref as React.RefObject<HTMLElement>,
      {
        runScroll,
        initialScroll: null,
      } as UseStayScrolledOptions,
    );

    const {
      conversationExists,
      lastMessageTimestamp,
      contactId,
      readWatermarks,
      connectionType,
      conversationData,
    } = useSelectorWithMemoize(
      () =>
        selectConversationProperties(conversationId, (conversation) => ({
          conversationExists: Boolean(conversation),
          lastMessageTimestamp: conversation?.metaData.lastMessage.timestamp,
          readWatermarks: conversation?.metaData.readWatermarks,
          connectionType: conversation?.connectionType,
          conversationData: conversation?.data?.data,
          contactId: conversation?.contactId,
        })),
      [conversationId],
      true,
    );

    // The element just scrolled down - remove new messages notification, if any
    const onScroll = useCallback(() => {
      if (isScrolled()) setNotifyNewMessage(false);
      if (isScrolled()) {
        // Remove Unread
        if (lastMessageTimestamp) {
          onUpdateWatermark(lastMessageTimestamp);
        }
      }
      if (ref.current?.scrollTop === 0) {
        // Todo: Load more message
        loadMore(ref);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      isScrolled,
      onUpdateWatermark,
      lastMessageTimestamp,
      conversationData,
      loadMore,
    ]);

    useLayoutEffect(() => {
      // Tell the user to scroll down to see the newest messages if the element wasn't scrolled down
      setNotifyNewMessage(!(stayScrolled as any)());
    }, [messages.length, stayScrolled]);

    const componentInitTimestamp = useMemo(() => Date.now(), []);

    useLayoutEffect(() => {
      if (componentInitTimestamp + 1000 > Date.now()) {
        setTimeout(() => {
          if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
          }
          setInitProcessing(false);
        }, 0);
      } else {
        scrollBottom();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isInitProcessing, setInitProcessing] = useState(true);

    const [clientTypedMessage, setClientTypedMessage] = useState("");

    useEffect(() => {
      let timer;
      const processTypingIndicator = (data: {
        userType: "CONTACT" | "USER";
        contactId: string;
        value: string;
        conversationId: string;
      }) => {
        // console.log("processTypingIndicator", data);
        if (
          data.userType === "CONTACT" &&
          data.conversationId === conversationId!
        ) {
          setClientTypedMessage(data.value);
        }
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          setClientTypedMessage("");
        }, 5000);
      };
      if (SocketConnector.socket) {
        SocketConnector.socket.on("TYPING_INDICATOR", processTypingIndicator);
      }

      return () => {
        if (SocketConnector.socket) {
          SocketConnector.socket.off(
            "TYPING_INDICATOR",
            processTypingIndicator,
          );
        }

        if (timer) {
          clearTimeout(timer);
        }
      };
    }, [conversationId, setClientTypedMessage]);

    useEffectWhen(
      () => {
        if (clientTypedMessage) {
          setClientTypedMessage("");
        }
      },
      [clientTypedMessage, conversationId],
      [conversationId],
    );

    useEffect(() => {
      setClientTypedMessage("");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(messages || []).length]);

    const [messageForwarderState, setMessageForwarderState] = useSimpleState({
      visibility: false,
      message: undefined as any as iMessage,
    });

    const forwardMessage = useCallback(
      (message: iMessage) => {
        setMessageForwarderState({
          visibility: true,
          message,
        });
      },
      [setMessageForwarderState],
    );

    const { columns, rows } = useMemo(() => {
      const flattenedData = flattenObject(
        _.omit(
          conversationData || {},
          "page",
          "formId",
          "formName",
          "formTarget",
        ),
      );
      return {
        columns: ["Field", "Data"],
        rows: Object.keys(flattenedData).map((key) => ({
          Field: key,
          Data: conversationData[key],
        })),
      };
    }, [conversationData]);

    const lastMessageId = useMemo(() => {
      return findLast(
        messages || [],
        (item) =>
          item.from.senderType === iMessageSenderType.CLIENT ||
          item.from.senderType === iMessageSenderType.USER,
      )?.id;
    }, [messages]);

    return (
      <Image.PreviewGroup>
        <div className="flex-1 flex flex-col overflow-hidden relative">
          {isInitProcessing && (
            <div className="flex w-full h-full absolute left-0 top-0 flex-row justify-center items-center z-10 bg-white dark:bg-gray-900">
              <LoadingIndicatorWithoutSpin />
            </div>
          )}
          <animated.div
            ref={ref as any}
            {...{ scrollTop: scrollTop }}
            onScroll={onScroll}
            className="chat-messages flex-1 overflow-x-hidden overflow-y-auto  pb-24"
          >
            {connectionType === "WEB_CHAT" && contactId && (
              <div className="">
                <SessionData
                  contactId={contactId}
                  title="Chat Widget Session Details"
                />
              </div>
            )}
            {conversationData && (
              <div className="p-4">
                <SimpleTable rows={rows} columns={columns} />
              </div>
            )}
            {messages.map((message, index) =>
              message.connectionType === "EMAIL" &&
              (message.from.senderType === "CLIENT" ||
                message.from.senderType === "USER") ? (
                <RichMessageList
                  message={message}
                  index={index}
                  isLastMessage={message.id === lastMessageId}
                  key={message.id}
                  forwardMessage={forwardMessage}
                  conversationId={conversationId}
                />
              ) : (
                <MessagesListItem
                  message={message}
                  index={index}
                  shouldShowContactAvatar={shouldShowContactAvatar(
                    message,
                    index,
                    messages,
                  )}
                  isLastMessageOfGroup={isLastMessageOfGroup(
                    message,
                    index,
                    messages,
                  )}
                  isFirstMessageOfGroup={isFirstMessageOfGroup(
                    message,
                    index,
                    messages,
                  )}
                  key={message.id}
                  forwardMessage={forwardMessage}
                  conversationId={conversationId}
                />
              ),
            )}
            {connectionType === "WEB_CHAT" && clientTypedMessage && (
              <div className="animate fade-in-down text-gray-600 dark:text-gray-400 mt-8 flex flex-row justify-start items-center mode_transition">
                <i className="ri-edit-2-line"></i> {clientTypedMessage}...
              </div>
            )}
          </animated.div>
          {notifyNewMessage && (
            <div className="relative">
              <div
                className="new-message-notification cursor-pointer"
                onClick={() => scrollBottom()}
              >
                Scroll down to new message
              </div>
            </div>
          )}

          <MessageForwarderModal
            visible={messageForwarderState.visibility}
            message={messageForwarderState.message}
            onChangeVisibility={(visibility) =>
              setMessageForwarderState({ visibility })
            }
          />
        </div>
      </Image.PreviewGroup>
    );
  },
);
