import { useCallback } from "react";
import { useStateWithGetter } from "utils/hooks/use-state-with-getter";
import { useCCWidgetEvents } from "./use-cc-widget-events";

export const useCCCampaignTrigger = () => {
  const [isWidgetLoaded, setWidgetLoaded, getWidgetLoaded] = useStateWithGetter(
    Boolean((window as any)?.ccWidget?.isReady),
  );

  const [lastMessage, setLastMessage, getLastMessage] = useStateWithGetter("");

  const loadChatWithCampaign = useCallback(
    (message) => {
      if (getWidgetLoaded()) {
        (window as any).ccWidget.setWidgetVisibility(true);
        (window as any).ccWidget.dismissAllCampaigns();
        (window as any).ccWidget.triggerCampaign({
          message,
        });
      } else {
        (window as any).ccWidget.setWidgetVisibility(true);
        setLastMessage(message);
      }
    },
    [getWidgetLoaded, setLastMessage],
  );

  const onWidgetLoaded = useCallback(() => {
    setWidgetLoaded(true);
    if (getLastMessage()) {
      loadChatWithCampaign(getLastMessage());
    }
  }, [getLastMessage, loadChatWithCampaign, setWidgetLoaded]);

  useCCWidgetEvents({ onInit: onWidgetLoaded });
  return loadChatWithCampaign;
};
