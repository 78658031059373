import { axios, AxiosResponse } from "@sdk/axios";
import { EntityServices } from "@sdk/utils/entity.service";
import { iIndexedWebPage } from "./indexed-web-pages-model";

export class IndexedWebPagesServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  indexedWebPages = Object.assign(
    new EntityServices<iIndexedWebPage>(this.config, "indexed-web-pages"),
    {
      reCrawlWebsites: async () => {
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/indexed-web-pages/re-crawl-websites`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
