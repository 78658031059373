import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { selectAllUsers } from "store/modules/users/users.selectors";
import { selectOrganizationOnboardingState } from "store/modules/workspace/workspace.selectors";

export const useOnboardingSteps = () => {
  const connections = useSelector(selectAllConnections);
  const users = useSelector(selectAllUsers);
  const persistedOnboardingState = useSelector(
    selectOrganizationOnboardingState,
  );

  const onboardingSteps = useMemo(() => {
    const steps: {
      id: string;
      label: string;
      isDone?: boolean;
    }[] = [];

    const featuresInterested =
      persistedOnboardingState?.featuresInterested || [];

    if (featuresInterested?.includes("LIVE_CHAT")) {
      const LiveChatConnection = _.find(connections, { type: "WEB_CHAT" });
      steps.push({
        id: "LIVE_CHAT",
        label: "Install Live Chat",
        isDone: Boolean(LiveChatConnection),
      });
    }

    if (featuresInterested?.includes("EMAIL_INBOX")) {
      const EmailConnection = _.find(connections, { type: "EMAIL" });
      steps.push({
        id: "EMAIL_INBOX",
        label: "Setup Email Inbox",
        isDone: Boolean(EmailConnection),
      });
    }

    if (featuresInterested?.includes("FACEBOOK")) {
      const FacebookConnection = _.find(connections, { type: "FACEBOOK" });
      steps.push({
        id: "FACEBOOK",
        label: "Connect Facebook",
        isDone: Boolean(FacebookConnection),
      });
    }

    if (featuresInterested?.includes("INSTAGRAM")) {
      const InstagramConnection = _.find(connections, { type: "INSTAGRAM" });
      steps.push({
        id: "INSTAGRAM",
        label: "Connect Instagram",
        isDone: Boolean(InstagramConnection),
      });
    }

    // if (featuresInterested?.includes("WHATSAPP")) {
    //   const WhatsappConnection = _.find(connections, {
    //     type: "WHATS_APP_PRIVATE"
    //   });
    //   steps.push({
    //     id: "WHATSAPP",
    //     label: "Connect Whatsapp",
    //     isDone: Boolean(WhatsappConnection)
    //   });
    // }

    steps.push({
      id: "INVITE_USERS",
      label: "Invite Users",
      isDone: users.length > 1,
    });

    // Could make a screen with invited users table and then a form to invite users

    // steps.push({
    //   id: "REVIEW_TAGS",
    //   label: "Review Tags",
    // });

    return steps;
  }, [connections, persistedOnboardingState?.featuresInterested, users.length]);
  return onboardingSteps;
};
