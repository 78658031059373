import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { createSelector } from "reselect";

export const useDoubleSelectorWithMemoize = <
  RootState,
  T extends unknown,
  Z extends unknown
>(
  selectorFn: () => (state: RootState) => T,
  deps: any[],
  selectorFn2: (d: T) => Z,
  useShallowEqualCheck: boolean = true
) => {
  const memoizedSelector = useMemo(() => {
    return createSelector([selectorFn()], selectorFn2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
  const data = useSelector(
    memoizedSelector,
    useShallowEqualCheck ? shallowEqual : undefined
  );
  return data;
};
