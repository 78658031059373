import { Comment } from "@ant-design/compatible";
import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iAppFeatureRequest } from "@sdk/app-feature-requests/app-feature-requests-model";
import { useSDK, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Avatar,
  Button,
  Divider,
  Input,
  Modal,
  Space,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import classNames from "classnames";
import { AttachmentsViewer } from "components/common/attachments-viewer/attachments-viewer";
import { EmptyData } from "components/common/empty-data/empty-data";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import dayjs from "dayjs";
import { EditFeatureRequest } from "modal-registry";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { selectOrganizationId } from "store/modules/workspace/workspace.selectors";

export const FeatureViewerModal = ({
  visible,
  onChangeVisibility,
  feature: _feature,
  onUpdateRequest,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  feature: iAppFeatureRequest;
  onUpdateRequest: (item: iAppFeatureRequest) => any;
}) => {
  const [addCommentInput, setAddCommentInput] = useState("");

  const currentUserId = useSelector(selectCurrentUserId);

  const {
    data: feature,
    error,
    isLoading,
    setData: setFeature,
    reload,
  } = useSDK((SDK) => SDK.appFeatureRequests.getById(_feature.id!), []);

  const { doAction: voteFeature, isProcessing: isVoting } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (reqId: string) =>
          SDK.appFeatureRequests.vote(reqId).then((d) => {
            onUpdateRequest(d);
            setFeature(d);
          }),
        failureMessage: "Something went wrong",
      }),
      [onUpdateRequest, setFeature],
    );

  const { doAction: unVoteFeature, isProcessing: isUnVoting } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (reqId: string) =>
          SDK.appFeatureRequests.unVote(reqId).then((d) => {
            onUpdateRequest(d);
            setFeature(d);
          }),
        failureMessage: "Something went wrong",
      }),
      [onUpdateRequest, setFeature],
    );

  const hasVoted = useMemo(() => {
    const vote = (feature?.votes || []).find(
      (item) => item.userId === currentUserId,
    );
    return Boolean(vote);
  }, [currentUserId, feature?.votes]);

  const { doAction: addComment, isProcessing: isAddingComment } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (reqId: string, comment: string) =>
          SDK.appFeatureRequests.addComment(reqId, comment).then((d) => {
            onUpdateRequest(d);
            setFeature(d);
          }),
        failureMessage: "Something went wrong",
      }),
      [onUpdateRequest, setFeature],
    );

  const { doAction: removeComment, isProcessing: isRemovingComment } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (reqId: string, commentId: string) =>
          SDK.appFeatureRequests.removeComment(reqId, commentId).then((d) => {
            onUpdateRequest(d);
            setFeature(d);
          }),
        failureMessage: "Something went wrong",
      }),
      [onUpdateRequest, setFeature],
    );

  const { triggerTempModal } = useModalPanels();

  const _isAdvancedMode = useSelector(selectIsAdvancedMode);
  const organizationId = useSelector(selectOrganizationId);
  const isAdvancedMode =
    _isAdvancedMode &&
    (organizationId === "fc89e0" || window.location.href.includes("localhost"));

  const { doAction: deleteCard, isProcessing: isDeleting } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.appFeatureRequests.deleteById(feature?.id!).then((d) => {
            onChangeVisibility(false);
          }),
        failureMessage: "Something went wrong",
      }),
      [feature?.id, onChangeVisibility],
    );

  if (!feature.id) {
    return <></>;
  }

  return (
    <Modal
      title={
        feature?.type === "BUG" ? (
          <ModalTitle title="Bug" icon={<i className="ri-bug-line"></i>} />
        ) : (
          <ModalTitle
            title="Feature Request"
            icon={<i className="ri-lightbulb-flash-line"></i>}
          />
        )
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={750}
      className="feature-requests-modal"
      data-click-context="Feature Requests Modal"
    >
      <div>
        <div className="flex flex-row justify-end items-center">
          <Space>
            {isAdvancedMode && (
              <Button
                type="link"
                onClick={() => {
                  triggerTempModal(EditFeatureRequest, {
                    feature,
                    onEdited: reload,
                  });
                }}
                icon={<i className="ri-pencil-line"></i>}
              >
                Edit Card
              </Button>
            )}
            {isAdvancedMode && (
              <Button
                type="link"
                onClick={() => {
                  deleteCard();
                }}
                icon={<i className="ri-delete-bin-line"></i>}
                loading={isDeleting}
              >
                Delete Card
              </Button>
            )}

            <Button
              type="link"
              onClick={() => reload()}
              loading={isLoading}
              icon={<i className="ri-refresh-line"></i>}
            >
              Reload Data
            </Button>
          </Space>
        </div>
        <div className="flex flex-row gap-4 p-4 rounded-lg">
          <div className="flex flex-col justify-center items-center">
            <Spin
              spinning={isVoting || isUnVoting}
              indicator={<LoadingIndicatorWithSpin />}
            >
              <Tooltip
                title={
                  hasVoted
                    ? `You have already upvoted. Click again to remove your vote`
                    : "Click to upvote"
                }
              >
                <div
                  className={classNames(
                    "flex flex-col border border-gray-600 rounded-lg cursor-pointer hover:bg-gray-200 dark:bg-gray-800",
                    {
                      "border-blue-600": hasVoted,
                      "text-blue-600": hasVoted,
                    },
                  )}
                  style={{ width: 60 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (!hasVoted) {
                      voteFeature(feature.id);
                    } else {
                      unVoteFeature(feature.id);
                    }
                  }}
                >
                  <div className="flex w-full justify-center items-center border-b border-gray-600">
                    <i className="ri-arrow-up-s-line"></i>
                  </div>
                  <div className="font-bold flex justify-center items-center">
                    {feature.votes.length}
                  </div>
                </div>
              </Tooltip>
            </Spin>
          </div>
          <div className="flex-1 flex flex-col">
            <div className="font-bold text-xl">{feature.title}</div>
            <div className="">
              <Tag>{feature.status || "In Review"}</Tag>
              {feature.tags.map((tag, index) => (
                <Tag key={tag + index}>{tag}</Tag>
              ))}
              {feature.isArchived && <Tag>Archived</Tag>}
              {feature.isDeleted && <Tag>Deleted</Tag>}
            </div>
            <div className="text-gray-600">{feature.shortDescription}</div>
          </div>
        </div>
        <Divider />
        {feature.body && (
          <div className="p-4">
            <div className="font-bold">Additional Details:</div>
            <div dangerouslySetInnerHTML={{ __html: feature.body }} />
          </div>
        )}

        {feature.attachments.length > 0 && (
          <div className="p-4">
            <div className="font-bold mb-2">Attachments:</div>
            <AttachmentsViewer attachments={feature.attachments} />
          </div>
        )}
        {feature.teamComment && (
          <div className="p-4">
            <div className="border border-gray-600 rounded-lg p-4">
              <div className="font-bold">Team Comment:</div>
              <div dangerouslySetInnerHTML={{ __html: feature.teamComment }} />
            </div>
          </div>
        )}

        <div className="bg-white dark:bg-gray-900 p-4 border-t border-1 border-gray-200 dark:border-gray-600 flex flex-col">
          <Input.TextArea
            autoSize={true}
            style={{ minHeight: 60 }}
            value={addCommentInput}
            onChange={(e) => setAddCommentInput(e.target.value)}
            placeholder="Add a new comment..."
          />
          <div className="flex flex-row items-center justify-end mt-2">
            <Button
              icon={<i className="ri-send-plane-2-line"></i>}
              type="primary"
              onClick={async () => {
                try {
                  await addComment(feature.id, addCommentInput);
                  setAddCommentInput("");
                } catch (e) {
                  // Nothing
                }
              }}
              loading={isAddingComment}
              disabled={!addCommentInput}
              // loading={isSendMessageProcessing || isFileUploading}
            >
              Add Comment
            </Button>
          </div>
        </div>
        {feature.comments.length === 0 && (
          <EmptyData
            text="No comments yet"
            icon={<i className="ri-question-answer-line text-3xl"></i>}
            className="text-gray-600"
          />
        )}
        {feature.comments.length > 0 && (
          <div className="p-4">
            <div className="font-bold mb-2">Comments:</div>
            {feature.comments.map((comment) => (
              <Comment
                key={comment.id}
                avatar={<Avatar src={comment.from.avatar} />}
                datetime={dayjs(comment.timestamp).format("LL")}
                content={
                  <div className="flex flex-col justify-start">
                    <div className="content">{comment.comment}</div>
                    {currentUserId === comment.from.userId && (
                      <div className="">
                        <Button
                          type="link"
                          onClick={() => removeComment(feature.id, comment.id)}
                          loading={isLoading}
                          icon={<i className="ri-delete-bin-line"></i>}
                          disabled={isRemovingComment}
                        >
                          Remove
                        </Button>
                      </div>
                    )}
                  </div>
                }
                author={comment.from.name}
              />
            ))}
          </div>
        )}
      </div>
      <DarkModeBg />
    </Modal>
  );
};
