import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "./draggable-cards-container.scss";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };

export const DashboardCardsContainer = ({
  layouts,
  onLayoutChange,
  layoutModifiable,
  children
}: {
  layouts;
  onLayoutChange: (layout) => any;
  children;
  layoutModifiable: boolean;
}) => {
  return (
    <ResponsiveGridLayout
      {...{
        className: "layout",
        // rowHeight: breakPointConfig.rowHeight,
        // cols: breakPointConfig.cols,
        initialLayout: layouts,
        onLayoutChange: onLayoutChange,
        layoutModifiable
      }}
    >
      {children}
    </ResponsiveGridLayout>
  );
};

const SouthEastArrow = () => (
  <svg
    width="20px"
    height="20px"
    version="1.1"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m70.129 67.086l1.75-36.367c-0.035156-2.6523-2.9414-3.6523-4.8164-1.7773l-8.4531 8.4531-17.578-17.574c-2.3438-2.3438-5.7188-1.5625-8.0586 0.78125l-13.078 13.078c-2.3438 2.3438-2.4141 5.0117-0.074219 7.3516l17.574 17.574-8.4531 8.4531c-1.875 1.875-0.83594 4.8203 1.8164 4.8555l36.258-1.8594c1.6836 0.019531 3.1328-1.2812 3.1133-2.9688z" />
  </svg>
);

const BottomRightHandle = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div
      style={{
        width: "20px",
        height: "20px",
        background: "#fff",
        borderRadius: "2px",
        border: "1px solid #ddd",
        position: "absolute",
        bottom: 0,
        right: 0,
        padding: 0,
        cursor: "se-resize"
      }}
      className="handle-se react-resizable-handle"
      ref={ref}
      {...props}
    >
      <SouthEastArrow />
    </div>
  );
});

class ResponsiveGridLayout extends React.Component {
  state = {
    currentBreakpoint: "lg",
    compactType: "vertical",
    mounted: false,
    layouts: { lg: undefined as any },
    rowHeight: 300,
    cols: { lg: 3, md: 3, sm: 3, xs: 1, xxs: 1 }
  };
  constructor(props) {
    super(props);

    this.state = {
      currentBreakpoint: "lg",
      compactType: "vertical",
      mounted: false,
      layouts: { lg: props.initialLayout },
      rowHeight: 300,
      cols: { lg: 3, md: 3, sm: 3, xs: 1, xxs: 1 }
    };

    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onCompactTypeChange = this.onCompactTypeChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  onBreakpointChange(breakpoint) {
    this.setState({
      currentBreakpoint: breakpoint,
      rowHeight: (() => {
        if (["sm", "xs", "xxs"].includes(breakpoint)) {
          return 200;
        }
        return 300;
      })()
    });
  }

  onCompactTypeChange() {
    const { compactType: oldCompactType } = this.state;
    const compactType =
      oldCompactType === "horizontal"
        ? "vertical"
        : oldCompactType === "vertical"
        ? null
        : "horizontal";
    this.setState({ compactType });
  }

  onLayoutChange(layout, layouts) {
    (this.props as any).onLayoutChange(layout, layouts);
  }

  render() {
    const { children, layoutModifiable, ...otherProps } = this.props as any;
    return (
      <ResponsiveReactGridLayout
        {...otherProps}
        rowHeight={this.state.rowHeight}
        cols={this.state.cols}
        layouts={this.state.layouts}
        onBreakpointChange={this.onBreakpointChange}
        onLayoutChange={this.onLayoutChange}
        // WidthProvider option
        measureBeforeMount={false}
        // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
        // and set `measureBeforeMount={true}`.
        useCSSTransforms={this.state.mounted}
        compactType={this.state.compactType}
        preventCollision={!this.state.compactType}
        resizeHandles={layoutModifiable ? ["se"] : []}
        resizeHandle={<BottomRightHandle />}
      >
        {children}
      </ResponsiveReactGridLayout>
    );
  }
}
