export const chatRatingOptions = [
  {
    value: 5,
    title: "Very Happy"
  },
  {
    value: 4,
    title: "Happy"
  },
  {
    value: 3,
    title: "Neutral"
  },
  {
    value: 2,
    title: "Unhappy"
  },
  {
    value: 1,
    title: "Very Unhappy"
  }
];
