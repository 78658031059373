import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { CompanyProfileInner } from "./company-profile-inner";

export const CompanyProfileModal = ({
  visible,
  onChangeVisibility,
  companyId
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  companyId: string;
}) => {
  return (<>
    <Modal
      title={
        <ModalTitle
          title="Company Viewer"
          icon={<i className="ri-global-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={960}
      data-click-context="Company Profile Modal"
      bodyStyle={{ padding: 0 }}
    >
      <CompanyProfileInner companyId={companyId} />
      <DarkModeBg />
    </Modal>
  </>);
};
