import MailOutlined from "@ant-design/icons/MailOutlined";
import UserAddOutlined from "@ant-design/icons/UserAddOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import {
  getPermissionFromRole,
  Roles,
} from "@sdk/roles-and-permissions/roles-and-permissions";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { DarkModeBg } from "dark-mode-bg";
import { last } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { uploadImageWithValidation } from "../../../../file-management/upload-image-with-validation";
import { debouncedEmailAvailability } from "../../helpers/email-availability";
import { PasswordStrengthIndicators } from "../my-profile/components/password-strength-indicator";
import { UploadAvatar } from "../upload-avatar/upload-avatar";

export const AddUserModal = ({
  visible,
  onChangeVisibility,
  onCreated,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated?: () => any;
}) => {
  const [form] = useForm();

  const currentOrganization = useSelector(selectOrganization);

  const [IsEmailReadOnly, serIsEmailReadOnly] = useState(true);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);

  const { doAction: uploadAvatar, isProcessing: isUploading } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (file, userId) =>
          SDK.uploadFile(file, {
            type: "USER",
            entityId: userId,
          })
            .then((fileRecord) => {
              setSelectedFile(undefined);
              return SDK.editUser(userId, {
                data: {
                  avatar: fileRecord.url,
                },
              });
            })
            .catch((e) => {
              setSelectedFile(undefined);
              throw e;
            }),
        successMessage: "Avatar has been updated",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const {
    doAction: _onSubmit,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.createUser({
          ...values,
          permissions: getPermissionFromRole(values.role),
        }).then((res) => {
          if (selectedFile !== undefined) {
            uploadImageWithValidation(
              res.id,
              selectedFile,
              uploadAvatar,
              setSelectedFile,
            );
          }
          onCreated && onCreated();
          form.resetFields();
          onChangeVisibility(false);
          form.resetFields();
        }),
      successMessage: "User has been added",
      failureMessage: "Something went wrong",
    }),
    [form, onChangeVisibility, onCreated, selectedFile, uploadAvatar],
  );

  const firstInputRef = useRef<any>();

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        firstInputRef.current?.focus();
      }, 100);
    }
  }, [visible]);

  const onSubmit = useCallback(async () => {
    try {
      console.log("form.getFieldsValue()", form.getFieldsValue());
      await form.validateFields();
      _onSubmit(form.getFieldsValue());
    } catch (e) {
      message.error("Please check your input");
    }
  }, [_onSubmit, form]);

  const initialValues = useMemo(
    () => ({
      role: last(Roles)?.label,
      data: {
        description: currentOrganization?.data.defaultUserDescription,
        bio: currentOrganization?.data.defaultUserBio,
      },
    }),
    [
      currentOrganization?.data.defaultUserBio,
      currentOrganization?.data.defaultUserDescription,
    ],
  );

  return (
    <>
      <Modal
        title={
          <div className="font-bold text-xl">
            <Space>
              <UserAddOutlined className="icon-fix" /> Create New User
            </Space>
          </div>
        }
        open={visible}
        onOk={onSubmit}
        onCancel={() => {
          onChangeVisibility(false);
          form.resetFields();
        }}
        okText={
          <>
            <i className="ri-save-line"></i> Save
          </>
        }
        okButtonProps={{ loading: isProcessing }}
        bodyStyle={{ padding: 0 }}
        data-click-context="Add User Modal"
      >
        {/* <div className="user-header flex flex-row justify-center items-center mb-12 mt-5">
        <div className="edit-user-avatar">
          {selectedFile && (
            <Spin
              spinning={isUploading}
              indicator={<LoadingIndicator />}
            >
              <Avatar size={80} src={URL.createObjectURL(selectedFile)} />
            </Spin>
          )}
          {!selectedFile && <UserAvatar userId=" " size={80} />}
          <input
            type="file"
            onChange={(event) => {
              if (event.target.files !== null) {
                setSelectedFile(event.target.files![0]);
              }
            }}
          />
          <div className="upload-icon">
            <i className="ri-upload-2-line"></i>
          </div>
        </div>
      </div> */}
        <div style={{ height: "calc(80vh)" }}>
          <PerfectScrollbar>
            <UploadAvatar
              selectedFile={selectedFile}
              onChange={(file) => {
                setSelectedFile(file);
              }}
              isUploading={isUploading}
            />
            <Form
              layout="vertical"
              form={form}
              initialValues={initialValues}
              style={{ padding: 24 }}
              onFinish={onSubmit}
            >
              {/* Firs Name */}

              <Row justify="space-between">
                <Col span={11}>
                  {/* First Name */}

                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="First name"
                      autoFocus
                      ref={firstInputRef}
                    />
                  </Form.Item>
                </Col>

                {/* Last Name */}
                <Col span={11}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Last name"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Email */}
              <Form.Item
                name="email"
                hasFeedback
                validateFirst={true}
                normalize={(value) => ((value || "") as string).toLowerCase()}
                rules={[
                  { type: "email", required: true },
                  ({ getFieldValue }) => ({
                    validator: async (rule, value) =>
                      await debouncedEmailAvailability(
                        value,
                        currentOrganization?.id,
                      ),
                  }),
                ]}
                label="Email"
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  readOnly={IsEmailReadOnly}
                  onFocus={() => {
                    serIsEmailReadOnly(false);
                  }}
                  // onMouseOut={() => {
                  //   serIsEmailReadOnly(true);
                  // }}
                />
              </Form.Item>

              {/* Role */}
              <Form.Item
                label="Role"
                name="role"
                rules={[{ required: true, message: "Please select a role" }]}
              >
                <Select>
                  {Roles.map((role) => (
                    <Select.Option key={role.label} value={role.label}>
                      {role.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {/* Description */}
              <Form.Item
                name={["data", "description"]}
                label="Description"
                rules={[]}
              >
                <Input placeholder="Description" />
              </Form.Item>
              {/* Bio */}
              <Form.Item name={["data", "bio"]} label="Bio">
                <Input.TextArea placeholder="Bio" />
              </Form.Item>
              {/* Password */}
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <PasswordStrengthIndicators />

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!",
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
              <Form.Item
                name="isInternalUser"
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox>Internal user</Checkbox>
              </Form.Item>
              <Button htmlType="submit" className="hidden">
                Submit
              </Button>
            </Form>
          </PerfectScrollbar>
        </div>
        <DarkModeBg />
      </Modal>
    </>
  );
};
