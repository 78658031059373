import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iDynamicForm } from "@sdk/dynamic-forms/dynamic-forms-model";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Dropdown, Menu, Space, Table, Tooltip } from "antd";
import { iFilterOption } from "components/common/filter-schema/filter-config-schema";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { GlobalConfig } from "config";
import { push } from "connected-react-router";
import copyToClipboard from "copy-to-clipboard";
import dayjs from "dayjs";
import _ from "lodash";
import { AddNewForm, ManageForm } from "modal-registry";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { loadAllDynamicForms } from "store/modules/dynamic-forms/dynamic-forms.helpers";
import { selectAllDynamicForms } from "store/modules/dynamic-forms/dynamic-forms.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useURLData } from "utils/hooks/use-url-filter";
import JSONModal from "utils/json-modal/json-modal";
import { DynamicFormsUseCases } from "../dynamic-form-templates-helpers/dynamic-forms-use-cases";
import "./manage-dynamic-forms.scss";

const emptyFilters = [] as iFilterOption[];

export const ManageDynamicFormsX = () => {
  const isAdvancedMode = useSelector(selectIsAdvancedMode);
  const history = useHistory();
  const { changePanelState } = useModalPanels();
  const organization = useSelector(selectOrganization);

  const { state: dynamicForms, retry: reload } = useQueryWithStore(
    selectAllDynamicForms,
    loadAllDynamicForms()
  );

  const { doAction: onRemoveForm } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (dynamicFormId: string) =>
        SDK.dynamicForms.deleteById(dynamicFormId).then((res) => {
          reload();
        }),
      successMessage: "Form has been removed",
      failureMessage: "Something went wrong",
    }),
    [reload]
  );

  const { doAction: duplicateForm } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (formConfig: iDynamicForm) =>
        SDK.dynamicForms
          .create({
            ..._.omit(formConfig, ["id", "metaData"]),
          })
          .then((res) => {
            setTimeout(() => {
              changePanelState(ManageForm, true, { dynamicFormId: res.id! });
            }, 400);
          }),
      successMessage: "Form has been cloned",
      failureMessage: "Something went wrong",
    }),
    [changePanelState]
  );

  const dispatch = useDispatch();

  const columns = [
    {
      title: "Label",
      dataIndex: "label",
      render: (label: string, record: iDynamicForm) => {
        return (
          <div>
            <div className="first-line">
              {label}{" "}
              <Tooltip title="Copy Form Id">
                <Button
                  onClick={(e) => {
                    copyToClipboard(record.id!);
                    e.stopPropagation();
                  }}
                  type="link"
                  icon={<i className="ri-clipboard-line"></i>}
                ></Button>
              </Tooltip>
            </div>
            <div className="second-line text-gray-600">
              {record.layout?.body?.items?.length || 0} Groups | Created on{" "}
              {dayjs(record?.metaData?.createdAt).format("DD-MM-YYYY")}
            </div>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "responsesCount",
      width: 120,
      render: (responsesCount: number, record: iDynamicForm) => {
        return (
          <Button
            type="text"
            icon={<i className="ri-eye-line"></i>}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                push(
                  `/forms/responses?forms-responses-filter=((operatorConfig:(value:${record.id}),operator:IS,key:formId))`
                )
              );
            }}
          >
            ({responsesCount || 0}) Responses
          </Button>
        );
      },
    },
    {
      title: "",
      dataIndex: "link",
      width: 120,
      render: (responsesCount: number, formConfig: iDynamicForm) => {
        return (
          <Button
            type="text"
            icon={<i className="ri-clipboard-line"></i>}
            onClick={(e) => {
              e.stopPropagation();
              const formURL = (() => {
                if (GlobalConfig.IS_PROD && formConfig) {
                  const domainConfig =
                    organization?.appData.HELP_DESK?.customPagesDomainConfig;
                  const url =
                    domainConfig?.domain &&
                    domainConfig?.isVerified &&
                    domainConfig?.isConfigured
                      ? `https://${domainConfig?.domain}/forms/${formConfig.id}`
                      : `http://www.ccpages.xyz/forms/${formConfig.id}`;
                  return url;
                }
                return `http://localhost:3000/forms/${formConfig.id}`;
              })();
              copyToClipboard(formURL);
            }}
          >
            Copy Form Link
          </Button>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: 100,
      render: (text, record: iDynamicForm) => (
        <Space size="middle" onClick={(e) => e.stopPropagation()}>
          <Button
            type="text"
            onClick={(e) => {
              changePanelState(ManageForm, true, { dynamicFormId: record.id! });
            }}
            icon={<i className="ri-settings-2-line"></i>}
          >
            Configure Form
          </Button>
          <Dropdown
            overlay={
              <Menu data-click-context="Manage Forms More Button">
                <Menu.Item
                  icon={<i className="ri-delete-bin-line"></i>}
                  onClick={() => onRemoveForm(record.id)}
                >
                  Delete Form
                </Menu.Item>
                <Menu.Item
                  icon={<i className="ri-file-copy-2-line"></i>}
                  onClick={() => duplicateForm(record)}
                >
                  Clone Form
                </Menu.Item>
              </Menu>
            }
            placement="topCenter"
            trigger={["click"]}
            arrow
          >
            <Button type="text" icon={<i className="ri-more-line"></i>} />
          </Dropdown>
        </Space>
      ),
    },
  ];
  if (isAdvancedMode) {
    columns.push({
      title: "",
      dataIndex: "id",
      width: 20,
      render: (id: any) => {
        return (
          <i
            className="ri-code-box-line cursor-pointer"
            onClick={() => {
              JSONModal(dynamicForms.find((form) => form.id === id) || {});
            }}
          />
        );
      },
    });
  }

  const [selectedFilters, setSelectedFilters] = useURLData(
    "forms-templates-filter",
    emptyFilters
  );

  const activateTemplateFilter = useCallback(
    (section: string) => {
      let filters: iFilterOption[] = [];
      if (section.includes("USE_CASE_")) {
        const useCase = section.replace("USE_CASE_", "");
        filters.push({
          key: "useCases",
          operator: "IS",
          operatorConfig: {
            value: useCase,
          },
        });
      }
      setSelectedFilters(filters);
    },
    [setSelectedFilters]
  );

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin manage-dynamic-forms">
      <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4">
        <SectionHeader
          title="Manage Forms"
          icon={<i className="ri-file-text-line"></i>}
          description="Create Forms that are sharable by a link"
          addons={
            <>
              <Button
                type="primary"
                icon={<i className="ri-add-box-line"></i>}
                onClick={() => changePanelState(AddNewForm, true, {})}
                className="font-bold"
              >
                New Form
              </Button>
            </>
          }
        />

        <Divider />

        <Table
          columns={columns}
          dataSource={dynamicForms}
          pagination={false}
          rowKey="id"
          className="mb-16"
          onRow={(record) => ({
            onClick: () => {
              changePanelState(ManageForm, true, { dynamicFormId: record.id! });
            },
            className: "cursor-pointer",
          })}
        />

        <Divider />

        <SectionHeader
          title="Explore Templates"
          icon={<i className="ri-folders-line"></i>}
          description="Create Your Forms with Pre-made templates"
        />

        <div className="flex flex-row gap-4 flex-wrap mt-8">
          {DynamicFormsUseCases.map((item) => (
            <div
              key={item.label}
              onClick={() => {
                history.push({
                  pathname: `/forms/templates`,
                });
                activateTemplateFilter(`USE_CASE_${item.label}`);
              }}
              className="p-4 rounded-lg border border-gray-300 dark:border-gray-700 flex flex-col cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800"
              style={{ width: 300 }}
            >
              <div className="flex flex-row items-center">
                <div className="text-4xl">{item.icon}</div>
                <div className="text-xl font-bold ml-3">{item.label}</div>
              </div>
              <div className="text-gray-600 flex-1">{item.description}</div>
              <div className="">
                <Button
                  type="text"
                  icon={<i className="ri-arrow-right-line"></i>}
                  block
                >
                  Explore
                </Button>
              </div>
            </div>
          ))}
        </div>

        <div
          className="p-4 rounded-lg border border-gray-300 dark:border-gray-700 flex flex-col cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800 mt-8"
          onClick={() => {
            (window as any).ccWidget?.onWidgetActivated(() => {
              (window as any).ccWidget?.triggerCampaign?.({
                message: "How can we improve Forms for you?",
              });
              (window as any).ccWidget.setWidgetVisibility(true);
            });
          }}
        >
          <div className="flex flex-row items-center">
            <div className="text-4xl">🥰</div>
            <div className="text-xl font-bold ml-3">Help us Help you!</div>
          </div>

          <div className="text-gray-600">
            Share your feedback with us to improve Click Connector
          </div>
        </div>
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
