import { useSDKWithRemotePagination } from "@sdk/sdk.hooks";
import { Modal, Tabs } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import dayjs from "dayjs";
import _ from "lodash";
import { useMemo } from "react";
import "./change-logs.scss";
const { TabPane } = Tabs;

export const ChangeLogsModal = ({
  visible,
  onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const {
    paginationState,
    setPaginationState,
    data: _itemsInView,
    setData: setItemsInView,
    isLoading,
    reload: resetAndReloadChangeLogs,
  } = useSDKWithRemotePagination((SDK) => SDK.HQ_getHQChangeLogs, {}, [], {
    pageSize: 10,
    currentPage: 1,
    paginationOptions: {
      sort: "-version",
    },
  });

  const itemsInView = useMemo(
    () => _itemsInView.filter((item) => item.version),
    [_itemsInView],
  );

  return (
    <Modal
      title={
        <ModalTitle
          title="Product Updates"
          icon={<i className="ri-magic-fill"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={800}
      className="change-logs-modal"
      data-click-context="Product Updates Modal"
    >
      <div>
        <div className="flex flex-row justify-center items-center font-bold text-2xl bg-gray-100 dark:bg-gray-800">
          New Updates 🎁
        </div>
        <div className="flex flex-row justify-center items-center">
          <img
            src="/assets/illustrations/product-updates.svg"
            alt="Product Updates"
            style={{ height: 200 }}
          />
        </div>
        <Tabs tabPosition="left" className="border-b">
          {_.orderBy(itemsInView, "version", "desc").map((log) => {
            return (
              <TabPane tab={`Note ${log.version}`} key={log.version}>
                <div className=" ">
                  <div className="mb-5 ">
                    <b> Date:</b> {dayjs(log.releasedTime).format("DD-MM-YYYY")}
                  </div>
                  <div className="mb-5 ">
                    <b>{log.releaseNoteTitle}</b>
                  </div>
                  <div className="mb-5 flex ">
                    <div
                      className="raw-html-rendered-block"
                      dangerouslySetInnerHTML={{
                        __html: log.releaseNoteBody,
                      }}
                    />
                  </div>
                </div>
              </TabPane>
            );
          })}
        </Tabs>
        {/* <div className="flex items-center justify-between mt-3">
          <Pagination
            pageSize={paginationState.pageSize}
            current={paginationState.currentPage}
            total={paginationState.totalItems}
            onChange={(currentPage, pageSize) => {
              setPaginationState({
                ...paginationState,
                pageSize: pageSize || 15,
                currentPage
              });
              console.log("currentPage", currentPage);
            }}
          />
          <div>Total items: {paginationState.totalItems}</div>
        </div> */}
      </div>
      <DarkModeBg />
    </Modal>
  );
};
