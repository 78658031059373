import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Alert, Button, Tag, Tooltip } from "antd";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import { selectShopifyStoreConfig } from "store/modules/workspace/workspace.selectors";
import { useUrlQuery } from "utils/hooks/use-url-query";
import { ShopifyInstallInstruction } from "../shopify-install-instructions/shopify-install-instructions";

export const ShopifyIntegrationStatus = () => {
  const shopifyStoreConfig = useSelector(selectShopifyStoreConfig);
  const isDarkMode = useSelector(selectIsDarkMode);

  const urlQuery = useUrlQuery();
  const shop = useMemo(() => {
    return urlQuery.get("shop")!;
  }, [urlQuery]);

  const { doAction: completeIntegration, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (shop: string) =>
        SDK.shopify.completeShopifyIntegration({ shop }),
      successMessage: "Integration has been successful",
      failureMessage: "Something went wrong",
      actionDependencies: [],
    }),
    [],
  );

  useEffect(() => {
    if (!shopifyStoreConfig?.config?.shop && shop) {
      completeIntegration(shop);
    }
  }, [completeIntegration, shop, shopifyStoreConfig?.config?.shop]);

  const { doAction: loadShopifyAuthUrl, isProcessing: isShopifyAuthLoading } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (shop: string) =>
          SDK.shopify.getAuthUrl(shop).then((d) => {
            window.location.href = d.url;
          }),
        // successMessage: "Done",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  return (
    <>
      {shopifyStoreConfig?.config?.shop && (
        <div>
          <Alert
            message="Shopify integration is active"
            type="success"
            showIcon
          />
          <div className="flex flex-row justify-between my-8">
            <div className="text">Connected Shop</div>
            <div className="">
              <Tag className="font-bold">
                {shopifyStoreConfig?.config?.shop}
              </Tag>
              <Tooltip title="Re-Authenticate">
                <Button
                  type="link"
                  icon={<i className="ri-refresh-line"></i>}
                  // href={"https://apps.shopify.com/click-connector"}
                  // target="_blank"
                  onClick={() => {
                    loadShopifyAuthUrl(shopifyStoreConfig?.config?.shop);
                  }}
                ></Button>
              </Tooltip>
            </div>
          </div>
          {/* Todo  */}
          <ShopifyInstallInstruction
            storeDomain={shopifyStoreConfig?.config?.shop}
          />
        </div>
      )}

      {!shopifyStoreConfig?.config?.shop && (
        <div>
          <Alert
            message="Install the Click Connector app in Shopify to complete the
                  integration"
            type="info"
            showIcon
          />
          <div className="p-4 flex flex-row justify-center items-center">
            <a
              href="https://apps.shopify.com/click-connector"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src={
                  isDarkMode
                    ? "/assets/integrations/Shopify-App-Store-Badge-Final-Black.svg"
                    : "/assets/integrations/Shopify-App-Store-Badge-Final-White.svg"
                }
                alt="Find us in Shopify App Store"
                style={{ width: 300 }}
              />
            </a>
          </div>
        </div>
      )}
    </>
  );
};
