import { Tooltip } from "antd";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import "./keyboard-shortcut.scss";

export const replaceKeyBindingToActualKey = (str: string) => {
  const isMac =
    typeof navigator === "object" &&
    /Mac|iPod|iPhone|iPad/.test(navigator.platform);
  return str
    .replace("$mod", isMac ? "⌘" : "Ctrl")
    .replace("Meta", "⌘")
    .replace("Alt", isMac ? "⌥" : "Alt")
    .replace("Control", isMac ? "^" : "Ctrl")
    .replace("Escape", "Esc");
};

export const KeyboardShortcut = ({
  label,
  inDarkBg,
  disableToolTip,
}: {
  label: string;
  inDarkBg?: boolean;
  disableToolTip?: boolean;
}) => {
  const keys = useMemo(() => {
    // const parts = parseKeybinding(label);
    const parts = label.split("+");
    const flattenedParts = parts.map((part) =>
      Array.isArray(part) ? part.join("") : part,
    );
    return flattenedParts.map((part) => replaceKeyBindingToActualKey(part));
  }, [label]);

  const Container = disableToolTip ? React.Fragment : Tooltip;

  const isMobileView = useMediaQuery({ query: "(max-width: 500px)" });

  if (isMobileView) {
    return <></>;
  }

  return (
    <Container {...(disableToolTip ? {} : { title: "Keyboard Shortcut" })}>
      <div className="ml-2 inline-block">
        {keys.map((item) => (
          <div
            className={classNames(
              "keyboard-shortcut border border-gray-300 dark:border-gray-800 rounded-md px-1 mr-1",
              {
                "text-gray-600": !inDarkBg,
                "text-gray-100": inDarkBg,
              },
            )}
            key={item}
          >
            {item}
          </div>
        ))}
      </div>
    </Container>
  );
};
