import { Button, Form, Input } from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";

const customTexts = [
  {
    label: "Greeting Text",
    value: "GREETINGS_MESSAGE",
    placeholder: "Hey 👋",
  },
  {
    label: "Thank You Message",
    value: "POST_SUBMIT",
    placeholder: "",
  },
  {
    label: "GDPR Consent",
    value: "GDPR_CONSENT",
    placeholder: "",
  },
  {
    label: "Company Name",
    value: "COMPANY_NAME",
    placeholder: "",
  },
  {
    label: "Team Name",
    value: "TEAM_NAME",
    placeholder: "",
  },
  {
    label: "Out Of Office Hours Notice",
    value: "OUT_OF_OFFICE",
    placeholder: "",
  },
  {
    label: "Not Available Notice",
    value: "NOT_AVAILABLE",
    placeholder: "",
  },
];

export const CustomCallbackWidgetTexts = ({
  connectionId,
}: {
  connectionId: string;
  onSave?: () => any;
}) => {
  return (
    <ConfigurationEditorComponent
      icon={"ri-text"}
      title="Customize Widget Content"
      description="Customize Greeting Messages, Team Name, and other texts/content in callback widget"
      entityId={connectionId}
      entityType="CONNECTION"
    >
      {customTexts.map((customText) => (
        <Form.Item
          label={customText.label}
          name={[
            "data",
            "callBackWidgetConfig",
            "locale",
            "custom",
            customText.value,
          ]}
          rules={[]}
        >
          <Input
            size="large"
            placeholder={customText.placeholder}
            suffix={
              <Button type="text" icon={<i className="ri-eye-line"></i>} />
            }
          />
        </Form.Item>
      ))}
    </ConfigurationEditorComponent>
  );
};
