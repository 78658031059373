import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { Button, Divider, Progress, Space } from "antd";

import { DPASigner } from "modal-registry";

import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";

export const DPAStep = ({
  onSkipDPA,
  currentStep,
  stepPercentage,
}: {
  onSkipDPA: () => any;
  currentStep: number;
  stepPercentage: number;
}) => {
  const { changePanelState } = useModalPanels();

  const organization = useSelector(selectOrganization);
  return (
    <div
      className="help flex-col justify-center items-center ml-4 relative overflow-hidden"
      style={{
        minHeight: 300,
      }}
    >
      <Progress
        percent={currentStep * stepPercentage}
        status="active"
        showInfo={false}
        className="opacity-25"
      />

      <Divider />

      <div className="mb-8 text-center w-full">
        <div className="font-semibold text-2xl">Data Processing Agreement</div>

        <div className="text-gray-600">
          Would you like to have a Data Processing Agreement signed? If your
          business is in the EU and you want to ensure GDPR compliance, you can
          choose to opt in and sign the Data Processing Agreement.
        </div>
      </div>

      {/* <div className="flex flex-col p-5">
        <div className="">
          <img
            src="/assets/gdpr/gdpr.jpeg"
            alt="GDPR"
            style={{ width: 200 }}
            className="rounded-lg"
          />
        </div>
      </div> */}
      <div className="mt-4 flex flex-row justify-end items-center">
        <Space>
          <Button
            type="primary"
            className="font-bold mt-4"
            icon={<i className="ri-arrow-right-line"></i>}
            onClick={onSkipDPA}
          >
            Skip
          </Button>
          <Button
            type="primary"
            className="font-bold mt-4"
            icon={<i className="ri-corner-down-right-line"></i>}
            onClick={() => {
              changePanelState(DPASigner, true, {});
            }}
          >
            Yes. Let's Sign
          </Button>
        </Space>
      </div>
    </div>
  );
};
