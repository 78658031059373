import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { message } from "antd";
import BraftEditor, {
  BuiltInControlType,
  ExtendControlType,
} from "braft-editor";
import "braft-editor/dist/index.css";
import { KeyBindingUtil, getDefaultKeyBinding } from "draft-js";

import { ContentUtils } from "braft-utils";
import classNames from "classnames";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { uuidv4 } from "utils/generate-uuid";
import { useStateWithGetter } from "utils/hooks/use-state-with-getter";
import "./draft-js-advanced.scss";
const { hasCommandModifier } = KeyBindingUtil;

const entityExtension = {
  type: "entity",
  name: "VARIABLE-TOKEN-ITEM",
  // control: {
  //   text: "VARIABLE TOKEN",
  // },
  mutability: "IMMUTABLE",
  component: (props) => {
    return <span className="variable-token-item">{props.children}</span>;
  },
  importer: (nodeName, node, source) => {
    if (
      nodeName.toLowerCase() === "span" &&
      node.classList &&
      node.classList.contains("variable-token-item")
    ) {
      return {
        mutability: "IMMUTABLE",
        data: {},
      };
    }
  },
  exporter: (entityObject, originalText) => {
    const { foo } = entityObject.data;
    return (
      <span data-foo={foo} className="variable-token-item">
        {originalText}
      </span>
    );
  },
};

BraftEditor.use(entityExtension);

export const BraftJSInput = forwardRef(
  (
    {
      initialValue,
      onChange,
      onBlur,
      conversationId,
      additionalExcludedControls,
      extendControls,
      onCommandActivated,
      showControlsOnTop,
    }: {
      initialValue?: string;
      onChange?: (html: string) => any;
      onBlur?: () => any;
      conversationId?: string;
      additionalExcludedControls?: BuiltInControlType[];
      extendControls?: ExtendControlType[];
      onCommandActivated?: (command: string) => any;
      showControlsOnTop?: boolean;
    },
    ref,
  ) => {
    let initialEditorState = ((initialValue: string) => {
      return BraftEditor.createEditorState(initialValue || "");
    })(initialValue || "");

    const draftEditorRef = useRef<any>();

    const [editorState, setEditorState, getCurrentState] =
      useStateWithGetter(initialEditorState);

    const setHtmlContent = useCallback(
      (html: string) => {
        const editorState = BraftEditor.createEditorState(
          html || initialValue || "",
        );
        setEditorState(editorState);
      },
      [initialValue, setEditorState],
    );

    const getHtmlContent = useCallback(() => {
      const htmlContent = getCurrentState().toHTML();
      return htmlContent;
    }, [getCurrentState]);

    const focusInput = useCallback(() => {
      draftEditorRef.current?.getDraftInstance()?.focus();
    }, []);

    const getControlBarInstance = useCallback(() => {
      return draftEditorRef.current?.controlBarInstance;
    }, []);

    const insertHtmlToCursor = useCallback(
      (html: string) => {
        setEditorState(ContentUtils.insertHTML(getCurrentState(), html || ""));
      },
      [getCurrentState, setEditorState],
    );

    useImperativeHandle(
      ref,
      () => ({
        setHtmlContent: setHtmlContent,
        getHtmlContent: getHtmlContent,
        focusInput,
        insertHtmlToCursor,
        getControlBarInstance,
      }),
      [
        focusInput,
        getControlBarInstance,
        getHtmlContent,
        insertHtmlToCursor,
        setHtmlContent,
      ],
    );

    useEffect(() => {
      if (onChange) {
        const htmlContent = editorState.toHTML();
        onChange(htmlContent);
      }
    }, [editorState, onChange]);

    const { doAction: uploadImage, isProcessing: isUploading } =
      useSDKActionWithDeps(
        () => ({
          action: (SDK) => (file) =>
            SDK.uploadFile(file, {
              type: conversationId ? "CONVERSATIONS" : "NEW_MESSAGE",
              entityId: conversationId || `BRAFT_${uuidv4()}`,
            })
              // .then(fileRecord => {
              //   // fileRecord.url
              // })
              .catch((e) => {
                throw e;
              }),
          throwError: true,
        }),
        [conversationId],
      );

    const handleKeyCommand = useCallback(
      (command: string) => {
        if (command === "open-presets") {
          onCommandActivated && onCommandActivated(command);
          return "handled";
        }
        return "not-handled";
      },
      [onCommandActivated],
    );

    const myKeyBindingFn = useCallback(function (e): string | null {
      if (e.keyCode === 191 /* `?/` key */ && hasCommandModifier(e)) {
        return "open-presets";
      }
      return getDefaultKeyBinding(e);
    }, []);

    return (
      <div
        className={classNames("draft-js-advanced", {
          "top-controls": showControlsOnTop,
        })}
      >
        <BraftEditor
          ref={draftEditorRef}
          value={editorState}
          onChange={setEditorState}
          // onSave={this.submitContent}
          language={"en"}
          onBlur={onBlur}
          handleKeyCommand={handleKeyCommand}
          {...{
            keyBindingFn: myKeyBindingFn,
          }}
          excludeControls={[
            "line-height",
            "blockquote",
            "code",
            "font-family",
            "letter-spacing",
            "subscript",
            "superscript",
            ...(additionalExcludedControls || []),
          ]}
          extendControls={extendControls}
          media={{
            uploadFn: ({ file, progress, success, error }) => {
              progress(20);
              const uploadingMessage = message.loading("Uploading");
              uploadImage(file)
                .then((file) => {
                  uploadingMessage();
                  success({
                    url: file?.url!,
                    meta: {
                      id: file?.id!,
                      title: "",
                      alt: "",
                      loop: false,
                      autoPlay: false,
                      controls: true,
                      poster: "",
                    },
                  });
                })
                .catch((e) => {
                  uploadingMessage();
                  error({ msg: "Unable to upload the file" });
                });
            },
            accepts: {
              video: false,
              audio: false,
            },
            externals: {
              video: false,
              audio: false,
              embed: false,
            },
          }}
          // handlePastedFiles={(file, file2) => {
          //   console.log("file", file, file2);
          // }}
          // handleDroppedFiles={(file, file2) => {
          //   console.log("file", file, file2);
          // }}
        />
      </div>
    );
  },
);
