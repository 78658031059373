import { Button, Form, Input, message } from "antd";
import { CountrySelector } from "components/common/country-selector/country-selector";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";

export const DPAInput = ({
  onContinue,
}: {
  onContinue: (data: any) => any;
}) => {
  const organization = useSelector(selectOrganization);
  const currentUser = useSelector(selectCurrentUser);
  const [form] = Form.useForm();

  const initialValue = useMemo(
    () => ({
      signerName: `${currentUser?.data?.firstName} ${
        currentUser?.data?.lastName || ""
      }`,
      companyName: `${organization?.data?.name}`,
      ...organization?.data?.mailingAddress,
    }),
    [
      currentUser?.data?.firstName,
      currentUser?.data?.lastName,
      organization?.data?.mailingAddress,
      organization?.data?.name,
    ],
  );

  const okOkay = useCallback(async () => {
    try {
      await form.validateFields();
      const formValue = form.getFieldsValue();
      onContinue(formValue);
    } catch (e) {
      message.error("Please check your input");
    }
  }, [form, onContinue]);

  return (
    <>
      <div>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValue}
          className="bold-form-labels"
          requiredMark={false}
        >
          <Form.Item
            name="signerName"
            label={"Your Name"}
            rules={[
              {
                required: true,
                message: "Your name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="signerTitle"
            label={"Title"}
            rules={[
              {
                required: true,
                message: "Title is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="companyName"
            label={"Company Name"}
            rules={[
              {
                required: true,
                message: "Company Name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Address"
            name={["addressLine1"]}
            rules={[{ required: true, message: "Address is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="City"
            name={["city"]}
            rules={[{ required: true, message: "City is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Postal Code" name={["postalCode"]} rules={[]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Country"
            name={["country"]}
            rules={[{ required: true, message: "Country is required" }]}
          >
            <CountrySelector />
          </Form.Item>
        </Form>
        <div className="flex flex-row justify-end items-center">
          <Button
            type="primary"
            icon={<i className="ri-arrow-right-line"></i>}
            onClick={() => okOkay()}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};
