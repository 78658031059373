import { Table } from "antd";
import React from "react";

export const StartTroubleshoot = ({
  troubleshootOrganizationResults
}: {
  troubleshootOrganizationResults: any;
}) => {
  // const [
  //   troubleshootOrganizationResults,
  //   setTroubleshootOrganizationResults,
  // ] = useState({} as any);
  // const {
  //   doAction: troubleshootOrganization,
  //   isProcessing: isTroubleshootProcessing,
  // } = useSDKActionWithDeps(()=>({
  //   action: (SDK) => (organizationId: string) =>
  //     SDK.troubleshoot(organizationId).then((data) => {
  //       setTroubleshootOrganizationResults(data);
  //       // console.log("troubleshootOrganization result", data);
  //     }),
  //   successMessage: "Done",
  //   failureMessage: "Something went wrong",
  // }),[]);

  // useEffect(() => {
  //   organization && troubleshootOrganization(organization?.id);
  // }, [organization]);

  // const dataSource = [
  //   {
  //     key: "1",
  //     title: "attentionRaiserJobs",
  //     previousState: "attentionRaiserJobs",
  //     currentState: "attentionRaiserJobs",
  //   },
  // ];

  const dataSource = [
    {
      key: "1",
      title: "attentionRaiserJobs",
      previousState:
        troubleshootOrganizationResults?.attentionRaiserJobs?.previousStatus ===
        {}
          ? "false"
          : "true",
      currentState: troubleshootOrganizationResults?.attentionRaiserJobs?.status
        ? "true"
        : "false"
    },
    {
      key: "2",
      title: "facebookAutoCloseJob",
      previousState:
        troubleshootOrganizationResults?.facebookAutoCloseJob
          ?.previousStatus === {}
          ? "false"
          : "true",
      currentState: troubleshootOrganizationResults?.facebookAutoCloseJob
        ?.status
        ? "true"
        : "false"
    },
    {
      key: "3",
      title: "sendNewMessageNotificationsToCustomersJob",
      previousState:
        troubleshootOrganizationResults
          ?.sendNewMessageNotificationsToCustomersJob?.previousStatus === {}
          ? "false"
          : "true",
      currentState: troubleshootOrganizationResults
        ?.sendNewMessageNotificationsToCustomersJob?.status
        ? "true"
        : "false"
    }
  ];

  const columns = [
    {
      title: "Job",
      dataIndex: "title",
      key: "title"
    },
    {
      title: "Previous State",
      dataIndex: "previousState",
      key: "previousState"
    },
    {
      title: "Current State",
      dataIndex: "currentState",
      key: "currentState"
    }
  ];
  return (
    <>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </>
  );
};
