import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { EditableCustomFieldLegacy } from "components/modules/conversations/components/chat-view-side-bar/components/custom-properties";
import { useSelector } from "react-redux";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectCRMCustomProperties } from "store/modules/workspace/workspace.selectors";

export const CustomContactProperties = ({
  contactId
}: {
  contactId: string;
}) => {
  const customerProperties = useSelector(selectCRMCustomProperties);
  const contact = useSelector(selectContactById(contactId));

  const { doAction: editCustomProperty } = useSDKActionWithDeps(
    () => ({
      action: SDK => (property: string, value: any) =>
        SDK.editContact(contactId!, {
          data: {
            customProperties: {
              [property]: value
            }
          }
        }),
      failureMessage: "Something went wrong"
    }),
    [contactId]
  );

  if (customerProperties.length === 0 || !contact) {
    return <></>;
  }

  return (
    <>
      <div className="">
        <div className="flex flex-row justify-between mb-4">
          <div className="title font-bold">Custom Contact Properties</div>
        </div>
        <div className="fields-container">
          {customerProperties.map(property => (
            <div key={property.id}>
              <EditableCustomFieldLegacy
                schema={property}
                value={contact.data.customProperties?.[property.key]}
                editCustomProperty={editCustomProperty}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
