import { iMessageAttachment } from "@sdk/conversations/conversations.models";
import { iScheduledMessage } from "@sdk/scheduled-messages/scheduled-messages";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Checkbox, DatePicker, Form, message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { BadgesController } from "badge-controller";
import { ModalTitle } from "components/common/modal-title";
import { iAction } from "components/modules/conversations/components/action-editor/models";
import { DarkModeBg } from "dark-mode-bg";
import dayjs from "dayjs";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLastAutoCancelScheduleMessagePreference } from "store/modules/ui-state/ui-state.actions";

import { selectLastAutoCancelScheduleMessagePreference } from "store/modules/ui-state/ui-state.selectors";

const getRange = (start: number, end: number) =>
  [...(Array(end - start + 1) as any).keys()].map(i => i + start);

export const MessageScheduler = ({
  visible,
  onChangeVisibility,
  data,
  onScheduled
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  data: {
    contactId: string;
    connectionId: string;
    conversationId: string;
    // Message Props
    subject?: string;
    message: string;
    attachments: iMessageAttachment[] | string[];
    cc?: string[];
    bcc?: string[];
    to?: string[];
    actions?: iAction[];
  };
  onScheduled?: () => any;
}) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const scheduleMessageTickBox = useSelector(
    selectLastAutoCancelScheduleMessagePreference
  );
  const { doAction: _scheduleMessage, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: SDK => data =>
        SDK.scheduledMessages.create(data).then(d => {
          onScheduled && onScheduled();
          onChangeVisibility(false);
          BadgesController.processBadgeEvent("use-scheduled-message");
        }),
      successMessage: "Done",
      failureMessage: "Something went wrong"
    }),
    [onChangeVisibility, onScheduled]
  );

  const scheduleMessage = () => {
    const { time, shouldCancelOnCustomerMessage } = form.getFieldsValue();
    if (!time) {
      message.error("Please select a time");
    }
    const req: Partial<iScheduledMessage> = {
      //   organizationId: string;
      // scheduledBy: string;
      contactId: data.contactId,
      conversationId: data.conversationId,
      connectionId: data.connectionId,
      data: {
        message: {
          ..._.omit(data, "contactId", "conversationId", "connectionId")
        },
        shouldCancelOnCustomerMessage: shouldCancelOnCustomerMessage
      },
      schedule: {
        time: time.valueOf()
      }
    };
    dispatch(
      setLastAutoCancelScheduleMessagePreference(shouldCancelOnCustomerMessage)
    );
    _scheduleMessage(req);
  };

  const { minDate, maxDate } = useMemo(() => {
    return {
      minDate: dayjs(),
      maxDate: dayjs().add(7, "day")
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const disabledDate = useCallback(
    d => {
      if (d == null) {
        return null;
      }

      return (
        (minDate != null && d.isBefore(minDate) && !d.isSame(minDate, "day")) ||
        (maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, "day"))
      );
    },
    [minDate, maxDate]
  );

  const disabledTime = useCallback(
    d => {
      if (d == null) {
        return null;
      }

      if (d.isSame(minDate, "day")) {
        return {
          disabledHours: () =>
            minDate.hour() > 0 ? getRange(0, minDate.hour() - 1) : [],
          disabledMinutes: hour =>
            hour === minDate.hour() && minDate.minute() > 0
              ? getRange(0, minDate.minute() - 1)
              : []
        };
      }

      if (d.isSame(maxDate, "day")) {
        return {
          disabledHours: () => getRange(maxDate.hour() + 1, 24),
          disabledMinutes: hour =>
            hour === maxDate.hour() ? getRange(maxDate.minute() + 1, 60) : []
        };
      }

      return null;
    },
    [minDate, maxDate]
  );

  const options = useMemo(() => {
    const day1 = dayjs()
      .add(1, "day")
      .startOf("day")
      .hour(9);
    const day2 = dayjs()
      .add(2, "day")
      .startOf("day")
      .hour(9);
    const day3 = dayjs()
      .add(3, "day")
      .startOf("day")
      .hour(9);
    const day4 = dayjs()
      .add(3, "day")
      .startOf("day")
      .hour(9);
    const day5 = dayjs()
      .add(3, "day")
      .startOf("day")
      .hour(9);
    const dates = [
      // Tomorrow at 9am
      {
        value: day1,
        label: "Tomorrow at 9 AM"
      },
      // Day After Tomorrow at 9am
      {
        value: day2,
        label: day2.format("ddd [at] LT")
      },
      // Next Day at 9am
      {
        value: day3,
        label: day3.format("ddd [at] LT")
      },
      // More Options
      {
        value: day4,
        label: day4.format("ddd [at] LT")
      },
      {
        value: day5,
        label: day5.format("ddd [at] LT")
      }
    ];
    return dates;
  }, []);

  const [isPanelOpen, setPanelOpen] = useState(true);

  useEffect(() => {
    if (visible) {
      setPanelOpen(true);
    }
  }, [visible]);

  return (
    <Modal
      title={
        <ModalTitle
          title="Schedule Message"
          icon={<i className="ri-calendar-line"></i>}
        />
      }
      open={visible}
      okButtonProps={{
        loading: isProcessing,
        icon: <i className="ri-calendar-line"></i>,
        className: "font-bold"
      }}
      okText="Schedule"
      onOk={() => {
        scheduleMessage();
        form.setFieldsValue({ time: dayjs().add(2, "days") });
      }}
      cancelText="Cancel"
      onCancel={() => {
        onChangeVisibility(false);
        form.setFieldsValue({ time: dayjs().add(2, "days") });
      }}
      width={500}
      data-click-context="Message Scheduler Modal"
      destroyOnClose
    >
      <Form
        layout="vertical"
        initialValues={{ shouldCancelOnCustomerMessage: true }}
        form={form}
      >
        <Form.Item
          name="time"
          label={"Date and Time"}
          rules={[
            {
              type: "object",
              required: true,
              message: "Please select time!"
            }
          ]}
          initialValue={dayjs().add(2, "days")}
        >
          <DatePicker
            suffixIcon={<i className="ri-calendar-event-line text-lg pr-4"></i>}
            showTime
            autoFocus
            showSecond={false}
            // format="ddd [at] LT"
            format="YYYY-MM-DD HH:mm"
            className="w-full"
            disabledDate={disabledDate}
            open={isPanelOpen}
            onOpenChange={setPanelOpen}
            disabledTime={disabledTime as any}
            presets={options}
          />
        </Form.Item>
        <Form.Item
          name="shouldCancelOnCustomerMessage"
          valuePropName="checked"
          initialValue={scheduleMessageTickBox}
        >
          <Checkbox>
            Cancel scheduled message if the customer sends a message before{" "}
          </Checkbox>
        </Form.Item>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
