import { Popover, Tooltip } from "antd";
import { last, orderBy } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { CurrentTime } from "utils/current-time";
import { isToday } from "utils/is-today";
import { roundTime } from "utils/rount-time";
import "./activity-indicator.component.style.scss";

const colors = [
  "#2196F3",
  "#607D8B",
  "#9E9E9E",
  "#795548",
  "#FF5722",
  "#FFEB3B",
  "#CDDC39",
  "#8BC34A",
  "#4CAF50",
  "#00BCD4",
  "#3F51B5",
  "#673AB7",
  "#E91E63",
  "#2196F3",
  "#42A5F5"
];

export const DummyActivities = [
  {
    id: "1",
    start: new Date().setHours(9, 30),
    end: new Date().setHours(9, 50),
    color: colors[0],
    title: "Task 1"
  },
  {
    id: "2",
    start: new Date().setHours(10, 30),
    end: new Date().setHours(11, 50),
    color: colors[9],
    title: "Task 2"
  },
  {
    id: "3",
    start: new Date().setHours(11, 55),
    end: new Date().setHours(12, 50),
    color: colors[8],
    title: "Task 3"
  }
];

export const ActivityIndicator = ({
  activities,
  startTime,
  endTime,
  height,
  detailed,
  hideTimeLine,
  onTimeLineCalculated
}: {
  activities: {
    id: string;
    start: number;
    end: number;
    color?: string;
    title: string;
  }[];
  startTime?: number;
  endTime?: number;
  height?: number;
  detailed?: boolean;
  hideTimeLine?: boolean;
  onTimeLineCalculated?: (opt: { startTime: number; endTime: number }) => any;
}) => {
  const orderedActivities = orderBy(activities, ["start"]);
  if (!startTime) {
    startTime = roundTime(
      Math.min(
        orderedActivities[0]?.start,
        new Date(orderedActivities[0]?.start).setHours(9)
      ),
      "floor"
    );
  }
  if (!endTime) {
    const lastLog = last(orderedActivities);
    endTime = roundTime(
      Math.max(
        lastLog?.end!,
        new Date(lastLog?.start!).setHours(21),
        lastLog?.end! < CurrentTime() && isToday(lastLog?.end!)
          ? CurrentTime()
          : 0
      ),
      "ceil"
    );
  }

  useEffect(() => {
    onTimeLineCalculated &&
      onTimeLineCalculated({ startTime: startTime!, endTime: endTime! });
  }, [startTime, endTime, onTimeLineCalculated]);

  if (activities && activities.length === 0) {
    return <div></div>;
  }

  const totalTimeSpan = endTime - startTime;
  let slots: JSX.Element[] = [];
  if (activities && activities.length !== 0 && endTime && startTime) {
    //   const interval = 15 * 60 * 1000;
    //   const slotsCount = totalTimeSpan / interval;
    slots = activities.map((activity, index) => {
      const width = ((activity.end - activity.start) * 100) / totalTimeSpan;
      const offset = ((activity.start - startTime!) * 100) / totalTimeSpan;
      const bar = (
        <div
          className="activity-slot"
          style={{
            backgroundColor: activity.color || colors[index % colors.length],
            width: `${width}%`,
            left: `${offset}%`
          }}
        ></div>
      );
      if (!detailed) {
        return (
          <Tooltip title={activity.title} key={activity.id}>
            {bar}
          </Tooltip>
        );
      }
      return (
        <Popover
          content={
            <>
              <span>Duration:</span>
              <span>{activity.title}</span>
            </>
          }
          title={false}
          key={activity.id}
        >
          {bar}
        </Popover>
      );
    });
  }

  const currentTimeIndicatorOffset =
    ((CurrentTime() - startTime!) * 100) / totalTimeSpan;
  const currentTimeIndicator = (
    <div
      className="current-time-indicator absolute bg-gray-700"
      style={{
        left: `${currentTimeIndicatorOffset}%`
      }}
    ></div>
  );

  const customStyle: React.CSSProperties = {};
  if (height) {
    customStyle.height = height;
  }

  return (
    <div className="activity-indicator-out-cont px-4 w-full">
      <div className="activity-indicator-cont" style={customStyle}>
        {slots}
        {currentTimeIndicator}
      </div>
      {!hideTimeLine && (
        <div className="time-cont flex flex-row justify-between w-full relative">
          <div className="start-time text-gray-500 text-xs">
            {moment(startTime).format("HH:mm")}
          </div>
          {currentTimeIndicatorOffset > 16 && currentTimeIndicatorOffset < 84 && (
            <div
              className="current-time absolute text-gray-500 text-xs"
              style={{
                left: `${currentTimeIndicatorOffset}%`
              }}
            >
              {moment().format("HH:mm")}
            </div>
          )}

          <div className="end-time text-gray-500 text-xs">
            {moment(endTime).format("HH:mm")}
          </div>
        </div>
      )}
    </div>
  );
};
