import {
  iPreset,
  iPresetFolder,
  iPresetState,
} from "@sdk/user-management/preset-state-model";
import { Collapse, Tooltip } from "antd";
import classnames from "classnames";
import _ from "lodash";
import { useMemo } from "react";
import { convertHtmlToPlainText } from "utils/html-to-plain-text";
import "./preset-folder-viewer.scss";

export const PresetItem = ({
  preset,
  onSelected,
  isActive,
  onMouseOver,
}: {
  preset: iPreset;
  onSelected: (preset: iPreset) => any;
  isActive?: boolean;
  onMouseOver?: (e) => any;
}) => {
  return (
    <div
      className={classnames(
        "flex flex-row items-center w-full rounded-md hover:bg-gray-200 dark:hover:bg-gray-800 cursor-pointer p-2 overflow-hidden",
        {
          "bg-gray-200 dark:bg-gray-800": isActive,
        },
      )}
      onClick={() => onSelected(preset)}
      onMouseOver={onMouseOver}
    >
      <div className="template-type">
        {preset.type === "RICH_TEXT" ? (
          <Tooltip title="Rich Text Preset">
            <i className="ri-font-size-2"></i>
          </Tooltip>
        ) : (
          <Tooltip title="Simple Text Preset">
            <i className="ri-text"></i>
          </Tooltip>
        )}
      </div>
      <div className="flex flex-col ml-3 overflow-hidden">
        <div className="template-label font-normal truncate">
          {preset.label}
        </div>
        <div className="template-label flex-1 text-sm font-normal text-gray-600 truncate">
          {convertHtmlToPlainText(preset.value)}
        </div>
      </div>
    </div>
  );
};

export const PresetFolderViewer = ({
  showRichText,
  folder,
  state,
  onSelected,
  presetUnderCursor,
  setPresetUnderCursor,
  ...collapsiblePanelProps
}: {
  showRichText?: boolean;
  folder: iPresetFolder;
  state: iPresetState;
  onSelected: (preset: iPreset) => any;
  presetUnderCursor: string;
  setPresetUnderCursor: (presetId: string) => any;
}) => {
  const presets = useMemo(() => {
    return _.filter(
      state.presets.filter((preset) => {
        if (!showRichText) {
          return preset.type !== "RICH_TEXT";
        }
        return true;
      }),
      { folder: folder.id },
    );
  }, [folder.id, showRichText, state.presets]);

  return (
    <Collapse.Panel
      key={folder.id}
      {...collapsiblePanelProps}
      // showArrow={false}
      header={
        <div className="flex flex-row justify-center items-center flex-1 text-gray-900 dark:text-gray-100">
          <div className="icon">
            <i className="ri-folder-2-line"></i>
          </div>
          <div className="texts flex flex-row pl-2 flex-grow flex-1">
            <div className="font-semibold truncate">{folder.label}</div>
          </div>
        </div>
      }
      extra={
        <div
          className="text-sm font-normal text-gray-900 dark:text-gray-100 whitespace-nowrap"
          style={{ width: "5em" }}
        >
          {presets.length} Preset(s)
        </div>
      }
      className="hover:bg-gray-100 dark:hover:bg-gray-900 rounded-md preset-folder-viewer"
    >
      <div>
        {presets.map((preset) => (
          <PresetItem
            preset={preset}
            key={preset.id}
            onSelected={onSelected}
            isActive={presetUnderCursor === preset.id}
            onMouseOver={() => setPresetUnderCursor(preset.id)}
          />
        ))}
      </div>
    </Collapse.Panel>
  );
};
