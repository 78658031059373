import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import { iCustomField } from "@sdk/dynamic-forms/dynamic-forms-model";
import { Button, Form, Input, message, Modal, Select, Space } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { SimpleCollapsePane } from "components/common/simple-collapse-pane/simple-collapse-pane";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect, useState } from "react";
import { uuidv4 } from "utils/generate-uuid";

const { Option } = Select;

export const CustomFieldEditorModal = ({
  visible,
  initialValue,
  onSave,
  onCancel,
  mode,
}: {
  visible: boolean;
  initialValue?: iCustomField;
  onSave: (formItem: iCustomField) => any;
  onCancel: () => any;
  mode: "ADD" | "EDIT";
}) => {
  const [form] = Form.useForm();

  const [configVisibility, setConfigVisibility] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [form, visible]);

  const getFieldConfigData = useCallback(() => {
    const formValue = form.getFieldsValue() as iCustomField;
    if (formValue?.inputConfig?.options) {
      formValue.inputConfig.options = formValue.inputConfig.options.map(
        (option) => ({
          value: option.label,
          label: option.label,
        })
      );
    }
    formValue.id = initialValue?.id! || uuidv4();
    formValue.key = formValue.id;
    return formValue;
  }, [form, initialValue]);

  return (
    <Modal
      open={visible}
      title={
        mode === "ADD" ? (
          <ModalTitle
            title={"Add Field"}
            icon={<i className="ri-add-line"></i>}
          />
        ) : (
          <ModalTitle
            title={"Edit Field"}
            icon={<i className="ri-pencil-line"></i>}
          />
        )
      }
      okText={mode === "ADD" ? "Add" : "Save"}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const fieldConfig = getFieldConfigData();
            onSave(fieldConfig);
            form.resetFields();
          })
          .catch((info) => {
            message.warning("Please check your input");
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{
        icon: <i className="ri-check-line"></i>,
        className: "font-bold",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={initialValue}
      >
        <Form.Item
          name="label"
          label={<div className="font-bold">Field Label</div>}
          help={
            <div className="-mt-2 mb-2">
              A label to identify your custom field
            </div>
          }
          rules={[
            {
              required: true,
              message: "Please input the label for the field!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label={<div className="font-bold">Field Description</div>}
          rules={[]}
          help={
            <div className="-mt-2 mb-2">
              An optional text to describe your custom field
            </div>
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="inputType"
          label={<div className="font-bold">Input Widget Type</div>}
          rules={[
            {
              required: true,
              message: "Please input the type for the field!",
            },
          ]}
        >
          <Select>
            <Select.OptGroup label="Simple">
              <Option value="TEXT">Text Field (Short Text)</Option>
              <Option value="TEXTAREA">Text Field (Long Text)</Option>
              <Option value="DATE">Date Field</Option>
              <Option value="NUMBER">Number Field</Option>
              <Option value="SWITCH">Toggle</Option>
              <Option value="CHECK_BOX">Simple Check Box</Option>
            </Select.OptGroup>

            <Select.OptGroup label="Multiple Options">
              <Option value="SELECT">Selectable Dropdown (Single)</Option>
              <Option value="MULTI_SELECT">
                Selectable Dropdown (Multiple)
              </Option>
              <Option value="RADIO">Simple Option List</Option>
            </Select.OptGroup>

            <Select.OptGroup label="Special">
              <Option value="EMAIL">Email Address</Option>
              <Option value="PHONE">Phone Number</Option>
            </Select.OptGroup>
          </Select>
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const fieldType = getFieldValue(["inputType"]);
            if (["SELECT", "MULTI_SELECT", "RADIO"].includes(fieldType)) {
              return (
                <div className="ml-4">
                  <Form.List name={["inputConfig", "options"]}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Space
                            key={field.key}
                            style={{ display: "flex" }}
                            align="baseline"
                          >
                            <Form.Item
                              {...field}
                              name={[field.name, "label"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Enter an option",
                                },
                              ]}
                              hasFeedback={false}
                              style={{ marginBottom: 4 }}
                            >
                              <Input placeholder="Option Label" />
                            </Form.Item>
                            <MinusCircleOutlined
                              onClick={() => remove(field.name)}
                            />
                          </Space>
                        ))}
                        <div
                          onClick={() =>
                            add({
                              label: "",
                              /*type: "work", phone: ""*/
                            })
                          }
                          className="cursor-pointer"
                        >
                          + add an option
                        </div>
                      </>
                    )}
                  </Form.List>
                </div>
              );
            }
          }}
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const fieldType = getFieldValue(["inputType"]);
            if (["TEXT", "TEXTAREA"].includes(fieldType)) {
              return (
                <>
                  <div className="mb-8">
                    <Button
                      type="link"
                      onClick={() => setConfigVisibility(!configVisibility)}
                      icon={
                        configVisibility ? (
                          <i className="ri-arrow-up-s-line text-2xl"></i>
                        ) : (
                          <i className="ri-arrow-down-s-line text-2xl"></i>
                        )
                      }
                      block
                    >
                      Input Validation
                    </Button>
                  </div>

                  <SimpleCollapsePane isCollapsed={configVisibility}>
                    <Form.Item
                      name={["inputConfig", "regex"]}
                      label={
                        <div className="font-bold">
                          Validate Input Using Regular Expression
                        </div>
                      }
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.resolve();
                            }
                            try {
                              new RegExp(value);
                              return Promise.resolve();
                            } catch (e) {
                              return Promise.reject(
                                new Error("Please enter a valid regex")
                              );
                            }
                          },
                        }),
                      ]}
                      help="Leave it blank to disable regex validation"
                    >
                      <Input placeholder="Regex Expression" />
                    </Form.Item>
                  </SimpleCollapsePane>
                </>
              );
            }
            return <></>;
          }}
        </Form.Item>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
