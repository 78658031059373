import { BuiltInControlType } from "braft-editor";

export const GenerateBraftChatExcludedControls = (
  additionalExcludedControls?: string[]
) =>
  [
    "blockquote",
    // "bold",
    "code",
    // "clear",
    "emoji",
    "font-family",
    "font-size",
    // "fullscreen",
    "headings",
    "hr",
    // "italic",
    "letter-spacing",
    "line-height",
    // "link",
    // "list-ol",
    // "list-ul",
    // "media",
    "redo",
    "remove-styles",
    "separator",
    // "strike-through",
    "superscript",
    "subscript",
    "text-align",
    "text-color",
    // "text-indent",
    // "underline",
    "undo",
    "table",
    ...(additionalExcludedControls || []),
  ] as BuiltInControlType[];
