import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Tooltip, message } from "antd";
import { useCallback } from "react";
import { useSelector, useStore } from "react-redux";
import { loadAllKnowledgeDocuments } from "store/modules/knowledge-documents/knowledge-documents.helpers";
import { selectOrganizationId } from "store/modules/workspace/workspace.selectors";

const uploadFileWithValidation = (
  entityId: string,
  file: File,
  uploadFileFunction: (file: File, entityId: string) => any,
) => {
  if (!file) {
    return;
  }
  const isSupportedDocumentType = file.type === "application/pdf";
  if (!isSupportedDocumentType) {
    message.error("You can only upload PDF file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("File must smaller than 2MB!");
  }
  if (isSupportedDocumentType && isLt2M) {
    uploadFileFunction(file, entityId);
  }
};

export const KnowledgeKitFileUploader = () => {
  const organizationId = useSelector(selectOrganizationId);

  const store = useStore();

  const { doAction: uploadImage, isProcessing: isUploading } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (file, entityId) =>
          SDK.uploadFile(file, {
            type: "KNOWLEDGE_KIT_FILE",
            entityId: entityId,
          })
            .then((fileRecord) => {
              message.success("File Uploaded");
              message.loading(
                "Starting to process document. This may take a while",
              );
              return SDK.knowledgeDocuments.createWithFile(fileRecord);
            })
            .then((d) => {
              return loadAllKnowledgeDocuments()(store, true);
            }),
      }),
      [store],
    );

  const onChange = useCallback(
    (file) => {
      uploadFileWithValidation(organizationId!, file, uploadImage);
    },
    [organizationId, uploadImage],
  );

  return (
    <Tooltip title="Upload PDF">
      <div className="relative cursor-pointer">
        <Button
          icon={<i className="ri-upload-2-line"></i>}
          type="dashed"
          loading={isUploading}
          className="cursor-pointer"
        >
          Upload PDF (Beta)
        </Button>

        <input
          type="file"
          onChange={(event) => {
            if (event.target.files !== null) {
              onChange(event.target.files[0]);
            }
          }}
          className="absolute left-0 w-full h-full z-10 opacity-0 cursor-pointer"
        />
      </div>
    </Tooltip>
  );
};
