import { useSDK } from "@sdk";
import { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { MessagesListItem } from "./message-list-item";

export const SingleMessageItem = memo(
  ({
    messageId,
    conversationId,
    contactId,
    ignoreTasks,
    ignoreAttachments
  }: {
    messageId: string;
    conversationId: string;
    contactId: string;
    ignoreTasks?: boolean;
    ignoreAttachments?: boolean;
  }) => {
    const conversation = useSelector(selectConversationById(conversationId));
    const contact = useSelector(selectContactById(contactId));
    const { data: message, isLoading, error, reload } = useSDK(
      SDK => SDK.getMessagesById(messageId),
      [messageId],
      !messageId
    );

    const forwardMessage = useCallback(() => {}, []);

    if (error) {
      return <>Error</>;
    }
    if (isLoading) {
      return <>Loading</>;
    }
    return (
      <div className="chat-messages">
        <MessagesListItem
          message={message}
          index={0}
          key={messageId}
          forwardMessage={forwardMessage}
          ignoreTasks={ignoreTasks}
          ignoreAttachments={ignoreAttachments}
          conversationId={conversationId}
          shouldShowContactAvatar={false}
          isFirstMessageOfGroup={false}
          isLastMessageOfGroup={false}
        />
      </div>
    );
  }
);
