import { UserAvatar } from "components/common/avatar/avatar";
import { ImageInputWithPreview } from "components/common/image-input-with-preview/image-input-with-preview";

export const UploadAvatar = ({
  selectedFile,
  onChange,
  isUploading = false,
  userId = " ",
}: {
  selectedFile: any;
  onChange: any;
  isUploading?: any;
  userId?: string;
}) => {
  return (
    <div className="user-header flex flex-row justify-center items-center mb-8 mt-8">
      <ImageInputWithPreview
        selectedFile={selectedFile}
        onChange={onChange}
        isUploading={isUploading}
        image={<UserAvatar userId={userId} size={80} />}
      />
    </div>
  );
};

// export const UploadAvatar = ({
//   selectedFile,
//   onChange,
//   isUploading = false,
//   userId = " "
// }: {
//   selectedFile: any;
//   onChange: any;
//   isUploading?: any;
//   userId?: string;
// }) => {
//   return (
//     <div className="user-header flex flex-row justify-center items-center mb-12 mt-5">
//       <div className="edit-user-avatar">
//         {selectedFile && (
//           <Spin
//             spinning={isUploading}
//             indicator={<LoadingIndicator />}
//           >
//             <Avatar size={80} src={URL.createObjectURL(selectedFile)} />
//           </Spin>
//         )}
//         {!selectedFile && <UserAvatar userId={userId} size={80} />}
//         <input
//           type="file"
//           onChange={event => {
//             if (event.target.files !== null) {
//               onChange(event.target.files[0]);
//             }
//           }}
//         />
//         <div className="upload-icon">
//           <i className="ri-upload-2-line"></i>
//         </div>
//       </div>
//     </div>
//   );
// };
