import { mdiAirplane } from "@mdi/js";
import { iPNRConverterResponse } from "@sdk/app-service/pnr-cerverter-response-model";
import { useSDK, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Segmented,
  Space,
  message
} from "antd";
import { useForm } from "antd/lib/form/Form";
import * as clipboard from "clipboard-polyfill";
import { MDIIcon } from "components/common/mdi-icon";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import dayjs from "dayjs";
import * as htmlToImage from "html-to-image";
import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { copyElementToClipboardWithStyles } from "utils/copy-element-to-clipboard-with-styles";
import { cleanPNRString } from "./helpers/clean-pnr-input";

export const FlightItineraryTemplateTypes = [
  {
    value: "Elegant",
    label: "Elegant"
  },
  {
    value: "Simple",
    label: "Simple"
  },
  {
    value: "Table",
    label: "Table"
  }
];

export const PNRConverterModal = ({
  visible,
  onChangeVisibility,
  onAddAsAttachment
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onAddAsAttachment?: (content: string) => any;
}) => {
  const [form] = useForm();
  const currentUser = useSelector(selectCurrentUser);

  const {
    doAction: _process,
    isProcessing,
    response,
    hasError
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (req: { pnr: string }) =>
        SDK.pnrConverter(req).then(d => {
          if (d.flightData.flights.length === 0) {
            throw {
              response: {
                data: { message: "Invalid PNR" }
              }
            };
          }
          return d;
        }),
      failureMessage: "Something went wrong"
    }),
    []
  );

  const process = useCallback(async () => {
    try {
      await form.validateFields();
      const { input } = form.getFieldsValue();
      if (input) {
        _process({ pnr: cleanPNRString(input) });
      }
    } catch (e) {
      message.error("Please check your input");
    }
  }, [_process, form]);

  const renderedPNRRef = useRef<any>();

  const organization = useSelector(selectOrganization);

  const { data: logoUrl, isLoading, error, reload: reloadDebug } = useSDK(
    SDK =>
      SDK.convertToBase64({
        url: organization?.data.logo!
      }),
    [],
    // !organization?.data.logo
    true
  );

  const copyToClipBoardAsText = useCallback(
    async (type: "TEXT" | "RICH_TEXT" | "IMAGE") => {
      const targetElement = document.getElementById("test-123");
      if (type === "RICH_TEXT") {
        copyElementToClipboardWithStyles(targetElement!);
        message.success("text copied to clipboard");
      } else if (type === "IMAGE") {
        message.open({
          type: "loading",
          content: "Processing",
          key: "copy-to-clipboard"
        });
        const blobData = await htmlToImage.toBlob(targetElement!);
        const item = new clipboard.ClipboardItem({
          "image/png": blobData!
        });
        await clipboard.write([item]);
        message.destroy("copy-to-clipboard");
        message.success("Image copied to clipboard");
      } else {
        const str = targetElement?.innerHTML;
        const listener = e => {
          e.clipboardData.setData("text/html", str);
          e.clipboardData.setData("text/plain", str);
          e.preventDefault();
        };
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
        message.success("text copied to clipboard");
      }
    },
    []
  );

  const [templateType, setTemplateType] = useState("Elegant");

  return (
    <Modal
      title={
        <ModalTitle
          title="PNR Converter"
          icon={<i className="ri-flight-takeoff-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={templateType === "Table" ? 1200 : 700}
      data-click-context="PNR Converter  Modal"
    >
      <Form
        layout="vertical"
        form={form}
        className="bold-form-labels"
        requiredMark={false}
      >
        <Form.Item
          name="input"
          label={"PNR"}
          rules={[
            {
              required: true,
              message: "Please enter a PNR"
            }
          ]}
        >
          <Input.TextArea placeholder="Your Input PNR" rows={6} />
        </Form.Item>

        <Button
          type="primary"
          icon={<i className="ri-magic-line"></i>}
          loading={isProcessing}
          onClick={process}
          size={"large"}
          block
          className="font-bold"
        >
          Format
        </Button>
      </Form>

      {!hasError &&
        response &&
        (response?.flightData?.flights || []).length > 0 && (
          <>
            <Divider>Results</Divider>
            <div className="flex flex-row justify-center items-center">
              <Segmented
                options={FlightItineraryTemplateTypes}
                value={templateType}
                onChange={val => setTemplateType(val.toString())}
              />
            </div>
            <div className="my-4 flex flex-row justify-end items-center">
              <Space>
                <Button
                  type="text"
                  icon={<i className="ri-clipboard-line"></i>}
                  onClick={() => copyToClipBoardAsText("TEXT")}
                  size={"large"}
                >
                  Copy as Text
                </Button>
                <Button
                  type="primary"
                  icon={<i className="ri-attachment-line"></i>}
                  loading={isProcessing}
                  size={"large"}
                  onClick={async () => {
                    const dataUrl = await htmlToImage.toPng(
                      renderedPNRRef.current
                    );
                    onAddAsAttachment && onAddAsAttachment(dataUrl);
                    onChangeVisibility(false);
                  }}
                >
                  Attach as Image
                </Button>
              </Space>
            </div>
            <div
              className="p-4 border border-gray-200 rounded-lg"
              ref={renderedPNRRef}
            >
              <div className="header w-full flex flex-col justify-center items-center">
                {organization?.data.logo && (
                  <div className="logo">
                    <img
                      src={
                        logoUrl.base44
                          ? `data:image/png;base64,${logoUrl.base44}`
                          : organization?.data.logo
                      }
                      alt="Logo"
                      style={{ maxWidth: 200 }}
                    />
                  </div>
                )}

                <div className="header-text text-gray-600 font-bold">
                  Quote Prepared By : {currentUser.data.firstName}{" "}
                  {currentUser.data.lastName} ({currentUser.credentials.email})
                </div>
                <div className="additional-text text-gray-600">
                  {/* Additional Text */}
                </div>
              </div>
              <div className="converted-value mt-8 bg-white text-black rounded-lg border border-gray-200">
                {templateType === "Elegant" && (
                  //  eslint-disable-next-line react/jsx-pascal-case
                  <FlightItineraryBlock_GoogleStyle
                    flightData={response?.flightData}
                  />
                )}
                {templateType === "Simple" && (
                  //  eslint-disable-next-line react/jsx-pascal-case
                  <FlightItineraryBlock_DefaultStyle
                    flightData={response?.flightData}
                  />
                )}
                {templateType === "Table" && (
                  //  eslint-disable-next-line react/jsx-pascal-case
                  <FlightItineraryBlock_TableStyle
                    flightData={response?.flightData}
                  />
                )}
              </div>
              <div className="footer">
                <div className="additional-text text-gray-600">
                  {/* Additional Text */}
                </div>
              </div>
            </div>
          </>
        )}

      <DarkModeBg />
    </Modal>
  );
};

export const FlightItineraryBlock_GoogleStyle = ({
  flightData
}: {
  flightData: iPNRConverterResponse["flightData"];
}) => {
  if (!flightData) {
    return <></>;
  }
  return (
    <>
      <div className="converted-value mt-8 bg-white text-black rounded-lg border border-gray-200">
        {flightData.flights.map((item, index) => (
          <div
            key={index}
            className="flex flex-col border-b border-gray-200 p-6 mb-8"
          >
            <div className="flex flex-row justify-center items-center">
              <div className="airline-image px-2">
                <div className="p-6 m-2 h-20 w-20 border border-solid rounded-full border-gray-200 hover:border-purple-600 flex items-center justify-center">
                  <img
                    src={`/assets/airline-logos/${item.flt.iatacode.toLowerCase()}.png`}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="summary flex flex-row justify-center items-center">
              <div className="departure text-2xl font-bold">
                {item.dep.airportcode}
              </div>
              <div className="flex-1 flex flex-col justify-center items-center px-16">
                <div className="flex-1 flex flex-row w-full justify-center items-center">
                  <div className="icon transform rotate-45">
                    <MDIIcon icon={mdiAirplane} size="2.25rem" />
                  </div>
                  <Divider className="m-0 flex-1">
                    <div className="duration text-gray-600">
                      {item.flt.name} - {item.flt.flightNo} / {item.flt.cabin}(
                      {item.flt.duration.hours}h {item.flt.duration.minutes}m)
                    </div>
                  </Divider>
                  <div className="icon transform rotate-45 opacity-0">
                    <MDIIcon icon={mdiAirplane} size="2.25rem" />
                  </div>
                </div>
              </div>
              <div className="arrival text-2xl font-bold">
                {item.arr.airportcode}
              </div>
            </div>
            <div className="detailed-info flex flex-row justify-items-stretch items-center">
              <div className="departure flex-1 flex flex-col">
                <div className="line-one">
                  {item.dep.airportname}{" "}
                  {dayjs(item.flt.departure.string).format("DD MMM, YYYY")}
                </div>
                <div className="line-2 text-xl font-bold">
                  {item.flt.departure.hr12}
                </div>
              </div>
              <div className="border-r border-gray-300 mx-4 h-12"></div>
              <div className="arrival flex-1 flex flex-col">
                <div className="line-one">
                  {item.arr.airportname}{" "}
                  {dayjs(item.flt.departure.string).format("DD MMM, YYYY")}
                </div>
                <div className="line-2 text-xl font-bold">
                  {item.flt.arrival.hr12}
                </div>
              </div>
            </div>
            <div className="transit flex flex-row justify-center items-center">
              {((item?.flt?.transit_time?.days || 0) > 0 ||
                (item?.flt?.transit_time?.hours || 0) > 3) && (
                <div className="mt-4 text-gray-600">
                  Long Connection: Transit Time:{" "}
                  {item?.flt?.transit_time?.days
                    ? `${item?.flt?.transit_time?.days} d`
                    : ""}{" "}
                  {item?.flt?.transit_time?.hours}h{" "}
                  {item?.flt?.transit_time?.minutes}m
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export const FlightItineraryBlock_DefaultStyle = ({
  flightData
}: {
  flightData: iPNRConverterResponse["flightData"];
}) => {
  if (!flightData) {
    return <></>;
  }
  return (
    <>
      <div className="converted-value mt-8 bg-white text-black rounded-lg border border-gray-200">
        {(flightData?.flights || []).map((item, index) => (
          <div
            key={index}
            className="flex flex-row items-center border-b border-gray-200 p-4"
          >
            <div className="airline-image px-2">
              <div className="p-6 m-2 h-20 w-20 border border-solid rounded-full border-gray-200 hover:border-purple-600 flex items-center justify-center">
                <img
                  src={`/assets/airline-logos/${item.flt.iatacode.toLowerCase()}.png`}
                  alt=""
                />
              </div>
            </div>
            <div className="flex flex-col px-2">
              <div className="title font-bold">
                {item.flt.departure.day},{" "}
                {dayjs(item.flt.departure.string).format("DD MMM, YYYY")}{" "}
                {item.flt.name} {item.flt.flightNo} {item.flt.cabin} -{" "}
                {item.flt.duration.hours}h {item.flt.duration.minutes}m
              </div>
              <div className="departing">
                Departing: {item.dep.airportname} ({item.dep.airportcode}) at{" "}
                {item.flt.departure.hr12}
              </div>
              <div className="Arriving">
                Arriving: {item.arr.airportname} ({item.arr.airportcode}) at{" "}
                {item.flt.arrival.hr12}
              </div>
              {((item?.flt?.transit_time?.days || 0) > 0 ||
                (item?.flt?.transit_time?.hours || 0) > 3) && (
                <div className="font-bold">
                  ----- Long Connection: Transit Time{" "}
                  {item?.flt?.transit_time?.days
                    ? `${item?.flt?.transit_time?.days} d`
                    : ""}{" "}
                  {item?.flt?.transit_time?.hours}h{" "}
                  {item?.flt?.transit_time?.minutes}m -----
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export const FlightItineraryBlock_TableStyle = ({
  flightData
}: {
  flightData: iPNRConverterResponse["flightData"];
}) => {
  if (!flightData) {
    return <></>;
  }
  return (
    <>
      <div
        className="converted-value mt-8 bg-white text-black rounded-lg border border-gray-200 w-full overflow-auto"
        id="test-123"
      >
        <table className="simple-table-style">
          <thead>
            <th></th>
            <th>Date</th>
            <th>Airline Name</th>
            <th>Flight Number</th>
            <th>Operated by</th>
            <th>Cabin</th>
            <th>Depart</th>
            <th>From</th>
            <th>Arrive</th>
            <th>At</th>
            <th>Duration</th>
            <th>Transit</th>
            <th>Aircraft</th>
          </thead>
          <tbody>
            {flightData.flights.map((item, index) => (
              <tr key={index}>
                <td>
                  {" "}
                  <div className="airline-image px-2">
                    <div className="p-6 m-2 h-20 w-20 border border-solid rounded-full border-gray-200 hover:border-purple-600 flex items-center justify-center">
                      <img
                        src={`/assets/airline-logos/${item.flt.iatacode.toLowerCase()}.png`}
                        alt=""
                      />
                    </div>
                  </div>
                </td>
                <td>
                  {item.flt.departure.day},{" "}
                  {dayjs(item.flt.departure.string).format("DD MMM, YYYY")}{" "}
                </td>
                <td>{item.flt.name}</td>
                <td>{item.flt.flightNo}</td>
                <td></td>
                <td> {item.flt.cabin}</td>
                <td>{item.flt.departure.hr12}</td>
                <td>
                  {item.dep.airportname} ({item.dep.airportcode})
                </td>
                <td>{item.flt.arrival.hr12}</td>
                <td>
                  {item.arr.airportname} ({item.arr.airportcode})
                </td>
                <td>
                  {item.flt.duration.hours}h {item.flt.duration.minutes}m
                </td>
                <td>
                  {((item?.flt?.transit_time?.days || 0) > 0 ||
                    (item?.flt?.transit_time?.hours || 0) > 3) && (
                    <>
                      {item?.flt?.transit_time?.days
                        ? `${item?.flt?.transit_time?.days} d`
                        : ""}{" "}
                      {item?.flt?.transit_time?.hours}h{" "}
                      {item?.flt?.transit_time?.minutes}m
                    </>
                  )}
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
