import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { PresetEditorModal } from "components/modules/message-presets/preset-editor-modal";
import { reducer } from "components/modules/message-presets/preset-manager-state-manager";
import { useReducer } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUserPresetsX } from "store/modules/users/users.selectors";
import { useEffectWhen } from "utils/hooks/use-effect-when";

export const AddMessagePresetModal = ({
  visible,
  onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const messagePresetsState = useSelector(selectCurrentUserPresetsX);

  const {
    doAction: updatePresets,
    isProcessing,
    response
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => data =>
        SDK.editCurrentUser({
          data: {
            presetsX: data
          }
        } as any),
      // successMessage: "Presets have been saved",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const [state, dispatch] = useReducer(reducer, messagePresetsState);

  useEffectWhen(
    () => {
      updatePresets(state);
    },
    [state, updatePresets],
    [state]
  );

  return (
    <>
      <PresetEditorModal
        visible={visible}
        mode={"ADD"}
        folders={state.folders}
        onChangeVisibility={onChangeVisibility}
        onSaved={preset => {
          dispatch({
            type: "ADD_PRESET",
            payload: {
              preset
            }
          });
          onChangeVisibility(false);
        }}
      />
    </>
  );
};
