export function GetCCFilename(url: string) {
  if (url) {
    var m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      const fileName = m[1];
      let ext = url.slice(((url.lastIndexOf(".") - 1) >>> 0) + 2).split("?")[0];
      if (url.indexOf("https://firebasestorage.googleapis.com") > -1) {
        return fileName.slice(fileName.indexOf("_") + 1) + `.${ext}`;
      }
      return `${fileName}.${ext}`;
    }
  }
  return "";
}

//   console.log(
//     GetCCFilename(
//       "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/b929e0_get_started_with_smallpdf.1623322947755.pdf?alt=media"
//     )
//   );
