import { ICall } from "@telnyx/webrtc";
import { IWebRTCCall } from "@telnyx/webrtc/lib/src/Modules/Verto/webrtc/interfaces";
import { Button, Popover, Tooltip } from "antd";
import classNames from "classnames";
import { TelnyxCallContext } from "components/modules/telnyx/components/telnyx-phone/telnyx-call-context";
import { TelnyxPhoneContainer } from "components/modules/telnyx/components/telnyx-phone/telnyx-phone";
import { SoftPhoneInterface } from "components/modules/telnyx/soft-phone";
import { useContext, useEffect, useMemo, useState } from "react";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";

const _PhoneButton = ({ mobileMode }: { mobileMode?: boolean }) => {
  const [isPhoneVisible, setPhoneVisibility] = useState(false);

  const { isClientReady, notification } = useContext(TelnyxCallContext);

  const activeCall = notification && (notification.call as ICall & IWebRTCCall);
  const notificationType = notification && notification.type;

  useEffect(() => {
    if (activeCall?.state) {
      setPhoneVisibility(true);
    }
  }, [activeCall?.state]);

  if (mobileMode) {
    return <></>;
  }

  return (
    <Popover
      content={<SoftPhoneInterface />}
      trigger="click"
      placement={mobileMode ? "bottom" : "rightBottom"}
      title={null}
      style={{ padding: 0 }}
      open={isPhoneVisible}
      onOpenChange={setPhoneVisibility}
      showArrow={false}
    >
      <Tooltip title="Phone" placement="right">
        <Button
          type={activeCall?.state === "ringing" ? "primary" : "text"}
          icon={
            <i
              className={classNames(
                "ri-phone-line cursor-pointer text-xl pr-0",
                {
                  "phone-ringing-state": activeCall?.state === "ringing"
                }
              )}
            ></i>
          }
        ></Button>
      </Tooltip>
    </Popover>
  );
};

export const PhoneButton = ({ mobileMode }: { mobileMode?: boolean }) => {
  const { state: connections, retry: reload } = useQueryWithStore(
    selectAllConnections,
    loadAllConnections
  );

  const telephoneConnection = useMemo(() => {
    return connections.find(item => item.type === "TELNYX");
  }, [connections]);

  if (mobileMode || !telephoneConnection) {
    return <></>;
  }

  return (
    <TelnyxPhoneContainer>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <_PhoneButton mobileMode={mobileMode} />
    </TelnyxPhoneContainer>
  );
};
