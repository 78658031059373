import { iConnectorContactSideBarData } from "@sdk/chat-connectors/chat-connectors.models";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { loadAllChatConnectors } from "store/modules/chat-connectors/chat-connectors.helpers";
import { selectAllChatConnectors } from "store/modules/chat-connectors/chat-connectors.selectors";
import {
  selectConversationCustomProperties,
  selectMailchimpIntegrationConfig,
  selectShopifyStoreConfig,
} from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { IconRender } from "../../manage-chat-connectors/icon-selector";

const _sectionHeadings = [
  {
    section: "PROFILE",
    title: "Customer Profile",
    icon: "ri-shield-user-line",
  },
  {
    section: "RECENT_CONVERSATIONS",
    title: "Recent Conversations",
    icon: "ri-chat-history-line",
  },
  {
    section: "RECENT_ACTIVITIES",
    title: "Recent Activities",
    icon: "ri-walk-fill",
  },
  {
    section: "LINKED_CARDS",
    title: "Linked Cards",
    icon: "ri-layout-top-2-line",
  },
  {
    section: "OPPORTUNITIES",
    title: "Recent Opportunities",
    icon: "ri-money-dollar-box-fill",
  },
  {
    section: "EVENTS",
    title: "Recent Events",
    icon: "ri-calendar-line",
  },
  {
    section: "SCHEDULED_MESSAGES",
    title: "Scheduled Messages",
    icon: "ri-calendar-todo-line",
  },
];

export const useChatBarAvailableSections = () => {
  const customProperties = useSelector(selectConversationCustomProperties);
  const shopifyStoreConfig = useSelector(selectShopifyStoreConfig);
  const mailchimpIntegrationConfig = useSelector(
    selectMailchimpIntegrationConfig,
  );

  const { state: chatConnectors, retry: reload } = useQueryWithStore(
    selectAllChatConnectors,
    loadAllChatConnectors(),
    [],
  );
  const chatConnectorSidebars = useMemo(
    () =>
      chatConnectors.filter(
        (connector) => connector.type === "CONTACT_SIDE_BAR",
      ),
    [chatConnectors],
  );
  const sectionHeadings = useMemo(
    () => [
      ..._sectionHeadings,
      ...(shopifyStoreConfig?.isActive
        ? [
            {
              section: "SHOPIFY_STORE",
              title: "Shopify Store",
              icon: "ri-store-line",
            },
          ]
        : []),
      ...(mailchimpIntegrationConfig?.isActive
        ? [
            {
              section: "MAIL_CHIMP",
              title: "Mailchimp",
              icon: () => (
                <img
                  src="/assets/integrations/mailchimp.png"
                  style={{ width: "2rem" }}
                  alt=""
                  // className="scale-150 transform"
                />
              ),
            },
          ]
        : []),
      ...(customProperties.length > 0
        ? [
            {
              section: "CUSTOM_PROPERTIES",
              title: "Custom Properties",
              icon: "ri-profile-line",
            },
          ]
        : []),
      // ...(window.location.href.includes("localhost") ||
      // window.location.href.includes("cc.clickconnector")
      //   ? [
      //       {
      //         section: "PIPELINE_STAGE_CHECK_LIST",
      //         title: "Check Lists",
      //         icon: "ri-checkbox-multiple-line"
      //       }
      //     ]
      //   : []),
      ...chatConnectorSidebars.map((item) => ({
        section: `CONNECTOR_${item.id}`,
        title: (item.data as iConnectorContactSideBarData).header.title,
        icon: ({ className }: { className?: string }) => (
          <IconRender
            icon={(item.data as iConnectorContactSideBarData).header.icon}
            className={className}
          />
        ),
      })),
    ],
    [
      chatConnectorSidebars,
      customProperties.length,
      mailchimpIntegrationConfig?.isActive,
      shopifyStoreConfig?.isActive,
    ],
  );

  return sectionHeadings;
};
