import { Badge } from "antd";
import { selectUnresolvedCountForFBPostQuery } from "store/modules/fb-posts/fb-posts.selectors";
import { useSelectorWithMemoize } from "store/utils/use-selector-with-memoize";

export const QueryUnresolvedFbCOmmentsCountBadge = ({
  query,
  dotOnly
}: {
  query?: string;
  dotOnly?: boolean;
}) => {
  const unread = useSelectorWithMemoize(
    () => selectUnresolvedCountForFBPostQuery(query!),
    [query],
    true
  );

  return (
    <>
      {Boolean(unread.count) && (
        <Badge
          count={
            dotOnly
              ? undefined
              : (unread.count ? unread.count : undefined) || undefined
          }
          dot={unread.count ? (dotOnly ? true : undefined) : undefined}
          className="un-read-badge blue"
        ></Badge>
      )}
    </>
  );
};
