export function convertMillisecondsToMinutesAndSeconds(
  milliseconds: number = 0
) {
  const minutes = Math.floor(milliseconds / 60000);
  const seconds = ((milliseconds % 60000) / 1000).toFixed(0);
  if (minutes === 0) {
    return seconds + " secs";
  }
  return minutes + ":" + (Number(seconds) < 10 ? "0" : "") + seconds + " mins";
}
