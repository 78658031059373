import HomeOutlined from "@ant-design/icons/HomeOutlined";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import MailOutlined from "@ant-design/icons/MailOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";

import { SDK } from "@sdk";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Validated_AccountCreateRequest } from "@sdk/user-management/user-management.controller-models";
import { Button, Divider, Form, Input, Modal, Space, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useMemo, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { isWorkEmail } from "utils/is-email";
import { ToAlphaNumeric } from "utils/to-alphanumeric";
import { toKebabCase } from "utils/to-kebab-case";
import { PasswordStrengthIndicators } from "../../../user-management/users/components/my-profile/components/password-strength-indicator";
const debounce = require("debounce-promise");

const debouncedDomainAvailability = debounce(
  async (domain) => {
    try {
      const status = await SDK.checkDomainAvailability(domain);
      if (!status.availability) {
        throw "Domain is already taken";
      }
      return;
    } catch (e) {
      console.log("e", e);
      throw "Domain is already taken";
    }
  },
  1000,
  { leading: false, trailing: true },
);

export const CreateSubAccountWorkspaceModal = ({
  visible,
  onChangeVisibility,
  onCreated,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated?: () => any;
}) => {
  const currentOrganization = useSelector(selectOrganization);
  const [registerForm] = useForm();
  const {
    doAction: _onFinish,
    isProcessing,
    response,
    dispatch,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values: Validated_AccountCreateRequest) => {
        return SDK.createAccount({
          ...values,
          domain: values.domain.toLowerCase(),
          email: values.email.toLowerCase(),
        }).then((res) => {
          const src = `https://${res.organization.domain}.clickconnector.io/login?accessToken=${res.accessToken}`;
          const win = window.open(src, "_blank");
          win && win.focus();
          onCreated && onCreated();
          onChangeVisibility(false);
          return res;
        });
      },
      successMessage: "Successfully registered",
      failureMessage: "Something went wrong",
    }),
    [onChangeVisibility, onCreated],
  );
  const [IsEmailReadOnly, setIsEmailReadOnly] = useState(true);

  const onFinish = useCallback(
    (data) => {
      return _onFinish({
        ...data,
        onboardingFlow: "SUB_ACCOUNT",
        organizationData: {
          parentOrganizationId: currentOrganization?.id,
          ...(data.organizationData || {}),
        },
      } as Validated_AccountCreateRequest);
    },
    [_onFinish, currentOrganization?.id],
  );

  const formInitialValues = useMemo(() => {
    return {};
  }, []);

  return (
    <>
      <Modal
        title={
          <ModalTitle
            icon={<i className="ri-add-line"></i>}
            title={"Create New Workspace"}
          />
        }
        open={visible}
        onCancel={() => {
          onChangeVisibility(false);
          registerForm.resetFields();
        }}
        footer={null}
        data-click-context="Add Workspace Modal"
      >
        <div className="">
          <>
            <Form
              name="register_form"
              className="register-form"
              initialValues={formInitialValues}
              onFinish={onFinish}
              layout="vertical"
              form={registerForm}
              requiredMark={false}
            >
              <Divider>
                <b>Admin User Details</b>
              </Divider>

              <Space>
                {/* First Name */}

                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="First name"
                  />
                </Form.Item>

                {/* Last Name */}

                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Last name"
                  />
                </Form.Item>
              </Space>

              {/* Email */}

              <Form.Item
                name="email"
                hasFeedback
                validateFirst={true}
                rules={[
                  { type: "email", required: true },
                  ({ getFieldValue }) => ({
                    validator: (rule, value) => {
                      return new Promise((resolve, reject) => {
                        if (isWorkEmail(value)) {
                          return resolve("");
                        }
                        throw reject("Please enter your work email");
                      });
                    },
                  }),
                ]}
                label="Work Email"
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  readOnly={IsEmailReadOnly}
                  onFocus={() => {
                    setIsEmailReadOnly(false);
                  }}
                />
              </Form.Item>

              {/* Password */}
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                // hasFeedback
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <PasswordStrengthIndicators />

              <Divider>
                <b>Workspace Details</b>
              </Divider>

              <Form.Item
                name="name"
                label="Workspace Name / Organization Name / Team Name"
                rules={[{ required: true, message: "Please enter a name!" }]}
              >
                <Input
                  prefix={<i className="ri-ancient-pavilion-line"></i>}
                  placeholder="Workspace Name / Organization name"
                  suffix={
                    <Tooltip title="A friendly name to identify your workspace">
                      <InfoCircleOutlined
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  }
                  onChange={(e) => {
                    console.log(e.target.value);
                    registerForm.setFieldValue(
                      "domain",
                      toKebabCase(ToAlphaNumeric(e.target.value)),
                    );
                  }}
                />
              </Form.Item>

              {/* Domain */}

              <Form.Item
                name="domain"
                hasFeedback
                validateFirst={true}
                rules={[
                  { required: true, message: "Please select a domain" },
                  ({ getFieldValue }) => ({
                    validator: async (rule, value) =>
                      await debouncedDomainAvailability(value),
                  }),
                ]}
                label="Your Unique Workspace URL"
                help="A unique website address for your workspace"
              >
                <Input
                  prefix={<HomeOutlined className="site-form-item-icon" />}
                  placeholder="Your Unique Workspace URL"
                  addonAfter=".clickconnector.io"
                  // suffix={
                  //   <Tooltip title="A unique website address for your workspace">
                  //     <InfoCircleOutlined
                  //       style={{ color: "rgba(0,0,0,.45)" }}
                  //     />
                  //   </Tooltip>
                  // }
                />
              </Form.Item>

              <Form.Item
                name="captcha"
                rules={[{ required: true, message: "Please verify" }]}
                label="Security Check"
              >
                <ReCAPTCHA sitekey="6LdJ_uMUAAAAALR5jyd-BByM9MaUfrJpRBtdNomY" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button mt-4 font-bold"
                  loading={isProcessing}
                  icon={<i className="ri-arrow-right-circle-fill"></i>}
                  size="large"
                >
                  Create Account
                </Button>
              </Form.Item>
            </Form>
          </>
        </div>

        <DarkModeBg />
      </Modal>
    </>
  );
};
