import { useSDK } from "@sdk";
import { useMemo } from "react";
import { ReadMoreContent } from "./text-with-read-more";

export const DestinationSnippet = ({
  destination
}: {
  destination: string;
}) => {
  const { data, isLoading, error, reload } = useSDK(
    SDK => SDK.getDestinationSnippet(destination),
    [destination],
    !destination
  );

  const extract = useMemo(() => {
    const pages = Object.keys(data?.query?.pages || {});
    if (pages.length === 0 || pages[0] === "-1") {
      return "No Data found";
    }
    return data.query.pages[pages[0]].extract;
  }, [data?.query?.pages]);
  return (
    <div className="my-4 border border-gray-200  dark:border-gray-800 rounded-lg p-4">
      <ReadMoreContent content={extract} />
    </div>
  );
};
