import { EntityServices } from "@sdk/utils/entity.service";
import { iConversionWidget } from "./conversion-widgets-model";

export class ConversionWidgetsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  conversionWidgets = Object.assign(
    new EntityServices<iConversionWidget>(this.config, "conversion-widgets"),
    {}
  );
}
