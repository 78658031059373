import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { Badge, Button, Divider, message, Select, Tooltip } from "antd";
import { push } from "connected-react-router";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectCurrentUserPresetsX,
} from "store/modules/users/users.selectors";
import {
  selectConversationTags,
  selectOrganizationWideMessagePresetsX,
} from "store/modules/workspace/workspace.selectors";
import { AddEllipsis } from "utils/add-ellipsis";

export const ConversationTagSelector = ({
  value,
  onChange,
  removeMinimumWidth,
  enableCustomTokens,
  ...props
}: {
  value?: string[];
  onChange?: (tags: string[]) => any;
  autoFocus?: boolean;
  enableCustomTokens?: boolean;
  removeMinimumWidth?: boolean;
}) => {
  const conversationTags = useSelector(selectConversationTags);
  const tagGroups = useMemo(
    () => _.groupBy(conversationTags, "tagGroup"),
    [conversationTags],
  );

  const messagePresets_currentUser = useSelector(selectCurrentUserPresetsX);
  const messagePresets_organization = useSelector(
    selectOrganizationWideMessagePresetsX,
  );

  const messagePresetsTags = useMemo(() => {
    const folders = [
      ...(messagePresets_currentUser.folders || []),
      ...(messagePresets_organization.folders || []),
    ];
    const allPresets = [
      ...(messagePresets_currentUser.presets || []),
      ...(messagePresets_organization.presets || []),
    ]
      .filter((e) => e)
      .map((e) => ({
        ...e,
        folderLabel: _.find(folders, { id: e.folder })?.label,
      }));

    return allPresets
      .filter((e) => e.autoTagConversation)
      .map((item) => item.label);
  }, [messagePresets_currentUser, messagePresets_organization]);

  const tagSelector = useMemo(
    () => ({
      mode: enableCustomTokens ? ("tags" as const) : ("multiple" as const),
      style: { width: "100%", minWidth: removeMinimumWidth ? undefined : 200 },
      value: value || [],
      onChange: onChange,
      placeholder: "Tag...",
      maxTagCount: "responsive" as const,
      optionLabelProp: "tag",
    }),
    [enableCustomTokens, onChange, removeMinimumWidth, value],
  );

  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const userRole = useMemo(() => {
    const currentUserRole = getRoleFromPermission(currentUser?.permissions);
    return currentUserRole;
  }, [currentUser?.permissions]);

  const onAddTagClick = useCallback(() => {
    if (userRole === "Owner") {
      dispatch(
        push(
          `/configurations/inbox-settings/general?activeConfigs=Conversation%20Tags`,
        ),
      );
    } else {
      message.warning(
        "Please contact your workspace administrator to configure tags",
      );
    }
  }, [dispatch, userRole]);

  const allOptions = useMemo(() => {
    return [
      ...Object.keys(tagGroups).map((tagGroup) => ({
        label: tagGroup === "undefined" ? "General" : tagGroup,
        options: tagGroups[tagGroup]
          .filter((e) => !e.isHidden)
          .sort((a, b) => (a.label || "").localeCompare(b.label))
          .map((tag) => ({
            label: (
              <span>
                <Badge color={tag.color} dot></Badge> {tag.label}
              </span>
            ),
            value: tag.label,
            tag: (
              <Tooltip title={tag.label}>
                <span>
                  <Badge color={tag.color} dot></Badge>{" "}
                  {AddEllipsis(tag.label, 15)}
                </span>
              </Tooltip>
            ),
          })),
      })),
      messagePresetsTags.length > 0
        ? [
            {
              label: "From Presets",
              options: messagePresetsTags
                .sort((a, b) => (a || "").localeCompare(b))
                .map((tag) => ({
                  label: (
                    <span>
                      <Badge color={tag} dot></Badge> {tag}
                    </span>
                  ),
                  value: tag,
                  tag: (
                    <Tooltip title={tag}>
                      <span>
                        <Badge color={tag} dot></Badge> {AddEllipsis(tag, 15)}
                      </span>
                    </Tooltip>
                  ),
                })),
            },
          ]
        : [],
    ];
  }, [messagePresetsTags, tagGroups]);

  return (
    <Select
      {...tagSelector}
      {...props}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: "8px 0" }} />
          <Button
            icon={<i className="ri-chat-settings-line"></i>}
            block
            type="text"
            onClick={onAddTagClick}
          >
            Configure Conversation Tags
          </Button>
        </div>
      )}
      options={allOptions}
    >
      {/* {Object.keys(tagGroups).map((tagGroup) => (
        <Select.OptGroup
          label={tagGroup === "undefined" ? "General" : tagGroup}
          key={tagGroup}
        >
          {tagGroups[tagGroup]
            .filter((e) => !e.isHidden)
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((tag) => (
              <Select.Option
                value={tag.label}
                key={tag.label}
                label={
                  <Tooltip title={tag.label}>
                    <span>
                      <Badge color={tag.color} dot></Badge>{" "}
                      {AddEllipsis(tag.label, 25)}
                    </span>
                  </Tooltip>
                }
              >
                <span>
                  <Badge color={tag.color} dot></Badge> {tag.label}
                </span>
              </Select.Option>
            ))}
        </Select.OptGroup>
      ))}
      {messagePresetsTags.length > 0 && (
        <Select.OptGroup label={"From Presets"} key={"from-presets"}>
          {messagePresetsTags
            .sort((a, b) => a.localeCompare(b))
            .map((tag) => (
              <Select.Option
                value={tag}
                key={tag}
                label={
                  <Tooltip title={tag}>
                    <span>
                      <Badge color={tag} dot></Badge> {AddEllipsis(tag, 25)}
                    </span>
                  </Tooltip>
                }
              >
                <span>
                  <Badge dot></Badge> {tag}
                </span>
              </Select.Option>
            ))}
        </Select.OptGroup>
      )} */}
    </Select>
  );
};
