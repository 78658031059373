import { Tooltip } from "antd";
import classNames from "classnames";
import { useState } from "react";
import "./pipeline-selector.scss";

interface iPipelineDefinition {
  stages: string[];
  successStage: string;
  failureStage: string;
}
export const PipelineStageSelector = ({
  value = "",
  onChange = (stage: string) => {},
  pipeline,
}: {
  value?: string;
  onChange?: (stage: string) => any;
  pipeline: iPipelineDefinition;
}) => {
  const stages = pipeline.stages;
  const currentStep = value
    ? stages.includes(value)
      ? stages.indexOf(value)
      : value === pipeline.successStage
      ? stages.length + 1
      : value === pipeline.failureStage
      ? stages.length
      : 0
    : -1;
  const isFailed = pipeline.failureStage === value;
  const [hoveredElement, setHoveredElement] = useState<number | undefined>(
    undefined,
  );
  const [isLostHovered, setIsLostHovered] = useState(false);
  return (
    <>
      <div className="flex-row flex w-full">
        <div
          className={classNames(
            "flex-1 bg-gray-100  dark:bg-gray-700 flex-row flex overflow-hidden pipe-line-boxes stages-6 mode_transition",
            {
              red: isFailed || isLostHovered,
            },
          )}
          style={{ height: 40 }}
        >
          {stages.map((stage, index) => (
            <Tooltip title={stage} key={index.toString()}>
              <div
                className={classNames("flex-1 cursor-pointer pipe-line-box", {
                  "selected-stage":
                    index <= currentStep || index <= (hoveredElement || -1),
                })}
                style={{ zIndex: stages.length + 2 - index }}
                onClick={() => onChange(stage)}
                onMouseEnter={() => setHoveredElement(index)}
                onMouseLeave={() => setHoveredElement(undefined)}
              >
                <div className="arrow-box"></div>
              </div>
            </Tooltip>
          ))}

          <Tooltip title={pipeline.successStage} key={stages.length}>
            <div
              className={classNames(
                "flex-1 cursor-pointer pipe-line-box relative",
                {
                  "selected-stage":
                    value === pipeline.successStage ||
                    stages.length <= (hoveredElement || -1),
                  "text-white":
                    value === pipeline.failureStage || isLostHovered,
                },
              )}
              style={{ zIndex: 0 }}
              onClick={() => onChange(pipeline.successStage)}
              onMouseEnter={() => setHoveredElement(stages.length)}
              onMouseLeave={() => setHoveredElement(undefined)}
            >
              <i className="ri-checkbox-circle-line"></i>
              <div className="arrow-box"></div>
            </div>
          </Tooltip>
        </div>
        <Tooltip title={pipeline.failureStage}>
          <div
            className={classNames(
              "flex justify-center items-center w-10 hover:text-white hover:bg-red-600",
              {
                "bg-red-600": value === pipeline.failureStage,
                "text-white": value === pipeline.failureStage,
              },
            )}
            onClick={() => onChange(pipeline.failureStage)}
            onMouseEnter={() => {
              setIsLostHovered(true);
            }}
            onMouseLeave={() => setIsLostHovered(false)}
          >
            <i className="ri-close-circle-line text-lg"></i>
          </div>
        </Tooltip>
      </div>
    </>
  );
};
