import { useSDK } from "@sdk";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Spin, Table } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { SectionHeader } from "components/common/section-header";
import { useSelector } from "react-redux";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const BlockedFacebookUsers = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const isDevMode = useSelector(selectIsAdvancedMode);
  const { state: connection } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId
  );

  const { data: blockedUsers, isLoading, reload } = useSDK(
    (SDK) => SDK.connections.getAllBlockedFbUsers(connectionId),
    [connectionId],
    !connectionId,
    []
  );

  const {
    doAction: unblockUser,
    isProcessing: isUnblockingUser,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (connectionId: string, userId: string) =>
        SDK.connections
          .unblockUserFromPage(connectionId, userId)
          .then(() => reload()),
      successMessage: "User has been unblocked",
      failureMessage: "Something went wrong",
    }),
    [reload]
  );

  console.log("blockedUsers", blockedUsers);

  const columns = [
    {
      title: "User",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "id",
      key: "action",
      render: (data, record) => (
        <Button
          type="link"
          onClick={() => unblockUser(connectionId, record.id)}
        >
          Unblock User
        </Button>
      ),
    },
  ];

  return (
    <Spin
      spinning={isUnblockingUser || isLoading}
      indicator={<LoadingIndicatorWithoutSpin />}
      wrapperClassName="flex-1 flex fill-spinner"
    >
      <div className="animated fadeInLeftMin  flex flex-col w-full">
        <SectionHeader
          title="Blocked Users"
          icon={<i className="ri-facebook-box-line"></i>}
          description="Manage blocked users of the page"
        />
        <Divider />

        <div className="table-container flex flex-col">
          {(blockedUsers || []).length === 0 && <EmptyData text="No Results" />}

          {(blockedUsers || []).length > 0 && (
            <Table
              columns={columns}
              dataSource={blockedUsers || []}
              className="w-full flex-col"
              style={{ width: "100%" }}
            />
          )}
        </div>
      </div>
    </Spin>
  );
};
