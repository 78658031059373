import { useMemo } from "react";
import {
  iReportVisualizationTypes,
  iReportWidget
} from "../components/report-canvas/widget-canvas-models";

export const useAvailableCharts = (reportQuery: iReportWidget) => {
  const availableChartTypes = useMemo(() => {
    const totalDimensions =
      reportQuery.groupBy.length + (reportQuery.aggregateByPeriod ? 1 : 0);

    // No Dimensions
    if (totalDimensions === 0) {
      return ["DIGIT"];
    }

    // One Dimension and it is by period
    if (reportQuery.aggregateByPeriod === "HEAT_MAP") {
      return ["HEAT_MAP"];
    }
    if (reportQuery.aggregateByPeriod && totalDimensions === 1) {
      return ["BAR_CHART", "TABLE"];
    }

    if (reportQuery.groupBy.includes("tagsCombination")) {
      return ["PIVOT_TABLE"];
    }

    // Two or more dimensions and it includes time
    if (reportQuery.aggregateByPeriod) {
      return ["BAR_CHART", "PIVOT_TABLE"];
    }

    // Todo: 3 dimensions with one of them is period could result in other widgets too

    // One Dimension and no time
    if (totalDimensions === 1) {
      const possible = ["PIE", "BAR_CHART", "TABLE"];
      // Special Dimension Charts
      // if (reportQuery.groupBy.includes("users_grouped")) {
      //   possible.push("VEN_DIAGRAM");
      // }
      return possible;
    }
    // Two Dimensions
    if (reportQuery.groupBy.length === 2) {
      return ["2D_BAR_CHART", "PIVOT_TABLE"];
    }

    // Three or More Dimensions
    return ["PIVOT_TABLE"];
  }, [reportQuery.aggregateByPeriod, reportQuery.groupBy]);
  return availableChartTypes as iReportVisualizationTypes[];
};
