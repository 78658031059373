import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iAppFeatureRequest } from "@sdk/app-feature-requests/app-feature-requests-model";
import {
  useSDKActionWithDeps,
  useSDKWithRemotePagination,
} from "@sdk/sdk.hooks";
import { Badge, Button, Input, Pagination, Spin, Switch, Tag } from "antd";
import classNames from "classnames";
import { EmptyData } from "components/common/empty-data/empty-data";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { escapeRegExp } from "lodash";
import { FeatureViewer, ReportBug } from "modal-registry";
import { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { useDebouncedValue } from "utils/hooks/use-debounced-value";

export const ActiveBugs = () => {
  const { triggerTempModal } = useModalPanels();

  const [_searchText, setSearchText] = useState("");
  const [searchText] = useDebouncedValue(_searchText, 600);

  const scrollableViewRef = useRef<HTMLDivElement>(null);

  const currentUserId = useSelector(selectCurrentUserId);

  const isAdvancedMode = useSelector(selectIsAdvancedMode);
  const [showInReview, setShowInReview] = useState(false);

  const {
    paginationState,
    setPaginationState,
    data: itemsInView,
    setData,
    isLoading,
    reload: resetAndReloadData,
  } = useSDKWithRemotePagination(
    (SDK) => (query, options) =>
      SDK.appFeatureRequests.query({ query, options }),
    {
      type: "BUG",
      isDeleted: { $ne: true },
      isArchived: { $ne: true },
      $and: [
        {
          $or: [
            showInReview
              ? {}
              : {
                  status: { $nin: ["In Review"] },
                },
            {
              "submittedBy.userId": currentUserId,
            },
          ].filter((e) => e),
        },
        searchText
          ? {
              $or: [
                {
                  title: {
                    $regex: `${escapeRegExp(searchText)}`,
                    $options: "i",
                  },
                },
                {
                  shortDescription: {
                    $regex: `${escapeRegExp(searchText)}`,
                    $options: "i",
                  },
                },
                {
                  body: {
                    $regex: `${escapeRegExp(searchText)}`,
                    $options: "i",
                  },
                },
              ],
            }
          : undefined,
      ].filter((e) => e),
    },
    [searchText, currentUserId, showInReview],
    {},
  );

  const onUpdateRequest = useCallback(
    (updatedItem: iAppFeatureRequest) => {
      setData((data) => {
        return data.map((item) => {
          if (item.id === updatedItem.id) {
            return updatedItem;
          }
          return item;
        });
      });
    },
    [setData],
  );

  return (
    <div className="w-full flex flex-col">
      <div className="search-box flex-1 flex flex-row gap-4">
        <Input.Search
          placeholder="Search bugs..."
          className="rounded-md flex-1"
          value={_searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className="w-full mt-2">
        <Button
          className="font-bold"
          type="dashed"
          icon={<i className="ri-bug-line"></i>}
          onClick={() => {
            triggerTempModal(ReportBug, {
              onAdded: () => {
                resetAndReloadData();
              },
            });
          }}
          block
        >
          Did you find a nasty bug? Report a Bug Now.
        </Button>
      </div>
      <div className="flex flex-row justify-end items-center">
        <Button
          type="link"
          onClick={() => resetAndReloadData()}
          loading={isLoading}
          icon={<i className="ri-refresh-line"></i>}
        >
          Reload
        </Button>
        {isAdvancedMode && (
          <>
            <Switch onChange={setShowInReview} checked={showInReview} />{" "}
            <>Show In Review</>
          </>
        )}
      </div>
      <div className="w-full flex flex-col gap-4 mt-8" ref={scrollableViewRef}>
        {itemsInView.map((item) => (
          <BugCard
            item={item}
            key={item.id}
            onUpdateRequest={onUpdateRequest}
          />
        ))}
      </div>
      {itemsInView.length === 0 && (
        <EmptyData
          text="No bugs to display"
          icon={<i className="ri-search-line text-3xl"></i>}
          className="text-gray-600"
        />
      )}
      {/* Pagination */}
      <div className="pagination-container p-4 flex flex-row justify-end">
        <Pagination
          pageSize={paginationState.pageSize}
          current={paginationState.currentPage}
          total={paginationState.totalItems}
          onChange={(currentPage, pageSize) => {
            setPaginationState({
              ...paginationState,
              pageSize: pageSize || 20,
              currentPage,
            });
            scrollableViewRef.current?.scrollIntoView({
              behavior: "smooth",
            });
          }}
        />
      </div>
    </div>
  );
};

export const BugCard = ({
  item,
  onUpdateRequest,
}: {
  item: iAppFeatureRequest;
  onUpdateRequest: (item: iAppFeatureRequest) => any;
}) => {
  const { triggerTempModal } = useModalPanels();
  const currentUserId = useSelector(selectCurrentUserId);

  const { doAction: voteFeature, isProcessing: isVoting } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (reqId: string) =>
          SDK.appFeatureRequests.vote(reqId).then((d) => {
            onUpdateRequest(d);
          }),
        failureMessage: "Something went wrong",
      }),
      [onUpdateRequest],
    );

  const { doAction: unVoteFeature, isProcessing: isUnVoting } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (reqId: string) =>
          SDK.appFeatureRequests.unVote(reqId).then((d) => {
            onUpdateRequest(d);
          }),
        failureMessage: "Something went wrong",
      }),
      [onUpdateRequest],
    );

  const hasVoted = useMemo(() => {
    const vote = item.votes.find((item) => item.userId === currentUserId);
    return Boolean(vote);
  }, [currentUserId, item.votes]);

  return (
    <div
      className="flex flex-row gap-4 hover:bg-gray-100 dark:hover:bg-gray-800 p-4 rounded-lg cursor-pointer"
      onClick={() => {
        triggerTempModal(FeatureViewer, {
          feature: item,
          onUpdateRequest,
        });
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <Spin
          spinning={isVoting || isUnVoting}
          indicator={<LoadingIndicatorWithSpin />}
        >
          <div
            className={classNames(
              "flex flex-col border border-gray-600 rounded-lg cursor-pointer hover:bg-gray-200 dark:bg-gray-800",
              {
                "border-blue-600": hasVoted,
                "text-blue-600": hasVoted,
              },
            )}
            style={{ width: 60 }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (!hasVoted) {
                voteFeature(item.id);
              } else {
                unVoteFeature(item.id);
              }
            }}
          >
            <div className="flex w-full justify-center items-center border-b border-gray-600">
              <i className="ri-arrow-up-s-line"></i>
            </div>
            <div className="font-bold flex justify-center items-center">
              {item.votes.length}
            </div>
          </div>
        </Spin>
      </div>
      <div className="flex-1 flex flex-col">
        <div className="font-bold text-xl">{item.title}</div>
        <div className="">
          <Tag>{item.status || "In Review"}</Tag>
        </div>
        <div className="text-gray-600">{item.shortDescription}</div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <Badge
          count={item.comments.length || 0}
          color="blue"
          showZero
          offset={[5, 0]}
        >
          <i className="ri-question-answer-line"></i>
        </Badge>
      </div>
    </div>
  );
};
