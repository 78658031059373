import { axios, AxiosResponse } from "@sdk/axios";
import {
  iCCMacro,
  iOrganization,
} from "@sdk/user-management/user-management.models";
import { UserTracker } from "user-tracker";

export class MacroManagementServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  macros = Object.assign(
    { config: this.config },
    {
      addMacro: async (data: iCCMacro) => {
        UserTracker.track("MACROS - Add Macro", {});
        const results: AxiosResponse<iOrganization> = await axios.post(
          this.config.basePath + `/organizations/macros`,
          data,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      editMacro: async (id: string, data: iCCMacro) => {
        UserTracker.track("MACROS - Edit Macro", {});
        const results: AxiosResponse<iOrganization> = await axios.patch(
          this.config.basePath + `/organizations/macros/${id}`,
          data,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      deleteMacro: async (id: string) => {
        UserTracker.track("MACROS - Delete Macro", {});
        const results: AxiosResponse<iOrganization> = await axios.delete(
          this.config.basePath + `/organizations/macros/${id}`,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
