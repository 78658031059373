import { processServerError } from "@sdk/sdk.hooks";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Result,
  Row,
  Select
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useState } from "react";
import { WorldCurrencies } from "./currency";

const { Option } = Select;

export const AddProductModal = ({
  visible,
  onChangeVisibility: _onChangeVisibility,
  onCreated
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated?: (product: any) => any;
}) => {
  const [addProductForm] = useForm();
  const [step, setStep] = useState<"CREATE" | "SUCCESS" | "ERROR">("CREATE");
  const [response, setResponse] = useState<any | undefined>(undefined);

  // Create Call
  const [isProcessing, setIsProcessing] = useState(false);
  const onAddProduct = async () => {
    try {
      await addProductForm.validateFields();
      const formValue = addProductForm.getFieldsValue();
      const contactData: any = {
        ...formValue,
        country: formValue.country
          ? formValue.country.split("|")[0]
          : undefined,
        mobile: `${formValue.countryCode} ${formValue.mobile}`
      };
      setIsProcessing(true);
      try {
        // const contact = await SDK.createContact(contactData);
        // message.success("Contact has been created");
        // setStep("SUCCESS");
        // setResponse(contact);
        // onCreated(contact);
        // setIsProcessing(false);
        addProductForm.resetFields();
      } catch (e) {
        console.log("Error while crating list", e);
        setStep("ERROR");
        if (e instanceof Error) {
          setResponse((e as any)?.response?.data);
        }
        message.error(processServerError(e, "Something went wrong"));
        setIsProcessing(false);
      }
    } catch (e) {
      // Validation Failed; Do nothing
      message.error("Please check your input");
    }
  };

  const tags: any[] = [];
  const contactLists: any[] = [];

  const onChangeVisibility = (status: boolean) => {
    _onChangeVisibility(status);
    if (!status) {
      setStep("CREATE");
    }
  };
  return (
    (<Modal
      title={
        <ModalTitle
          title="Add Product"
          icon={<i className="ri-store-fill"></i>}
        />
      }
      open={visible}
      onOk={async () => onAddProduct()}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      footer={step === "CREATE" ? undefined : null}
      okButtonProps={{ loading: isProcessing }}
      okText={"Save Record"}
      data-click-context="Add Product Modal"
    >
      {step === "CREATE" && (
        <Form
          layout="vertical"
          form={addProductForm}
          initialValues={{}}
          className="bold-form-labels"
        >
          {/* Firs Name */}
          <Form.Item label="Product Name" name="name">
            <Input placeholder="" />
          </Form.Item>
          {/* Unit Name */}
          <Form.Item label="Unit Label" name="unitLabel">
            <Input placeholder="" />
          </Form.Item>
          <Row justify="space-between">
            {/* Value */}
            <Col span={11}>
              <Form.Item label="Unit Price" name="unitPrice">
                <Input placeholder="" type="number" />
              </Form.Item>
            </Col>
            {/* Currency */}
            <Col span={11}>
              <Form.Item label="Currency" name="currency">
                <Select style={{ minWidth: 150 }}>
                  {WorldCurrencies.map((currency, index) => (
                    <Select.Option value={currency.cc} key={index.toString()}>
                      {currency.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
      {step === "SUCCESS" && (
        <>
          <Result
            status="success"
            title="Successfully Added a Product"
            subTitle={`${
              (response as any)?.data?.firstName
            } has been added as a product successfully`}
            extra={[
              <Button
                type="primary"
                key="close"
                onClick={() => {
                  addProductForm.resetFields();
                  onChangeVisibility(false);
                }}
              >
                Close
              </Button>,
              <Button
                key="another"
                onClick={() => {
                  setStep("CREATE");
                  addProductForm.resetFields();
                }}
              >
                Add Another
              </Button>
            ]}
          />
        </>
      )}
      {step === "ERROR" && (
        <>
          <Result
            status="warning"
            title="Something went wrong"
            subTitle={`${(response as any)?.message}`}
            extra={[
              <Button
                type="primary"
                key="back"
                onClick={() => setStep("CREATE")}
              >
                Go back
              </Button>
            ]}
          />
        </>
      )}
      <DarkModeBg />
    </Modal>)
  );
};
