import PaperClipOutlined from "@ant-design/icons/PaperClipOutlined";
import { useModalPanels } from "@libs/modal-panels/modal-panels";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Select,
  Tag,
  Tooltip,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import modal from "antd/lib/modal";
import {
  EditableText,
  EditableTextArea,
} from "components/common/editable-text/editable-text";
import { ModalTitle } from "components/common/modal-title";
import { SimpleCollapsePane } from "components/common/simple-collapse-pane/simple-collapse-pane";
import _, { cloneDeep } from "lodash";
import { MessageVariantEditor } from "modal-registry";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Node,
  useReactFlow,
  useUpdateNodeInternals,
} from "react-flow-renderer";
import { useSelector } from "react-redux";
import { selectOrganizationId } from "store/modules/workspace/workspace.selectors";
import { getTokens } from "utils/extract-tokens";
import { uuidv4 } from "utils/generate-uuid";
import { useViewRefresher } from "utils/hooks/use-view-refresher";
import { DragHandler } from "../../components/drag-handler/drag-handler";
import { DragPreventer } from "../../components/drag-preventer/drag-preventer";
import {
  AvailableNodes,
  DataCollectorLabel,
  iMessageNode,
} from "../../data-model";
import { useSetNode } from "../../utils/use-set-node";
import { ChatFlowHandle } from "../chat-flow-handle";
import "./message-node.scss";
import { WithAttachments } from "./with-attachments";

export const AvailableTokensForBots = [
  "CONTACT_FIRST_NAME",
  "CONTACT_LAST_NAME",
  "CONTACT_EMAIL",
  "CONTACT_PHONE",
  "WORKSPACE_NAME",
];

export const AvailableTokenTableForBots = [
  {
    token: "CONTACT_FIRST_NAME",
    label: "Customer's First Name",
  },
  {
    token: "CONTACT_LAST_NAME",
    label: "Customer's Last Name",
  },
  {
    token: "CONTACT_EMAIL",
    label: "Customer's Email",
  },
  {
    token: "CONTACT_PHONE",
    label: "Customer's Phone Number",
  },
  {
    token: "WORKSPACE_NAME",
    label: "Your Workspace Name",
  },
];

export const MessageNode = memo(
  ({ data, id }: { id: string; data: iMessageNode }) => {
    const organizationId = useSelector(selectOrganizationId);
    const setNode = useSetNode<iMessageNode>(id);
    const { getNode, setNodes, setEdges, getNodes, getEdges, getEdge } =
      useReactFlow<AvailableNodes, any>();

    const updateNodeInternals = useUpdateNodeInternals();
    const { visible: isAddQuickReplyVisible, reRender } = useViewRefresher();

    const [form] = useForm();

    const [configVisibility, setConfigVisibility] = useState(false);

    useEffect(() => {
      updateNodeInternals(id);
    }, [id, updateNodeInternals, configVisibility, data]);

    useEffect(() => {
      const timer = setTimeout(() => {
        updateNodeInternals(id);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }, [id, updateNodeInternals]);

    const cloneNode = useCallback(() => {
      const node = getNode(id) as Node<iMessageNode>;
      const currentNodePosition = node.position;
      const newNodeId = uuidv4();
      const order = 0;
      const newNode = {
        ..._.pick(_.cloneDeep(node), ["type", "data"]),
        id: newNodeId,
        position: {
          x: currentNodePosition.x + 320 + 100,
          y: currentNodePosition.y + (order || 0) * 100,
        },
      };
      setNodes([...getNodes(), newNode]);
    }, [getNode, getNodes, id, setNodes]);

    const deleteNode = useCallback(() => {
      setNodes(_.filter([...getNodes()], (node) => node.id !== id));
    }, [getNodes, id, setNodes]);

    useEffect(() => {
      if (
        ((data?.quickButtons || []).length > 0 ||
          (data.persistence &&
            data.persistence?.target &&
            data.persistence?.target !== "NONE") ||
          (data.validation &&
            data.validation.type &&
            data.validation.type !== "NONE")) &&
        data.processNextNodeImmediately
      ) {
        setNode({
          processNextNodeImmediately: false,
        });
      }
    }, [
      data?.persistence,
      data?.processNextNodeImmediately,
      data?.quickButtons,
      data?.quickButtons?.length,
      data.validation,
      setNode,
    ]);

    const capturedTokens = useMemo(() => {
      const tokens = getTokens(data?.text || "");
      // console.log("tokens", tokens);
      const validTokens = tokens.filter((token) =>
        AvailableTokensForBots.includes(token),
      );

      // console.log("validTokens", validTokens, AvailableTokensForBots);
      const invalidTokens = tokens.filter(
        (token) => !AvailableTokensForBots.includes(token),
      );

      // console.log("invalidTokens", invalidTokens, AvailableTokensForBots);
      return { validTokens, invalidTokens, all: tokens };
    }, [data?.text]);

    const { triggerTempModal } = useModalPanels();

    return (
      <div className="p-4 message-node-container node-container group border-2 dark:border-black hover:border-blue-600">
        {id !== "START" && (
          <div className="hidden group-hover:show">
            <div
              className="hover-action-box flex flex-row gap-4 justify-center items-center absolute w-full left-0 pb-4"
              style={{ top: -40 }}
            >
              <Tooltip title="Delete Block">
                <Button
                  type="link"
                  onClick={(e) => {
                    deleteNode();
                  }}
                  icon={<i className="ri-delete-bin-5-line"></i>}
                />
              </Tooltip>
              <Tooltip title="Clone Block">
                <Button
                  type="link"
                  onClick={(e) => {
                    cloneNode();
                  }}
                  icon={<i className="ri-file-copy-line"></i>}
                />
              </Tooltip>
            </div>
          </div>
        )}

        {data.persistence?.target && data.persistence?.target !== "NONE" && (
          <div className="w-full flex flex-col items-center justify-center -mt-6">
            <Tag className="font-bold dark:bg-black" color={"blue"}>
              Data Collector: {DataCollectorLabel[data.persistence?.target]}{" "}
              {data.persistence?.target === "CONTEXT" &&
                `[${data.persistence?.key}]`}
            </Tag>
          </div>
        )}
        {id === "START" && (
          <div className="w-full flex flex-row justify-center items-center">
            <div className="bg-gray-800 text-white w-16 h-16 rounded-full absolute flex justify-center items-center -mt-32">
              Begin
            </div>
          </div>
        )}

        <div className="drag-handler-container flex flex-row justify-center items-center">
          <DragHandler />
        </div>

        <ChatFlowHandle handleId="DEFAULT" nodeId={id} type="DEFAULT" />
        {id !== "START" && (
          <ChatFlowHandle handleId="INPUT" nodeId={id} type="INPUT" />
        )}

        <div>
          <DragPreventer>
            <WithAttachments
              initialAttachments={data.attachments}
              onAttachmentsChange={(attachments) => {
                setNode({
                  attachments,
                });
              }}
              id={id}
              entityType="CHAT_BOT"
              entityId={organizationId!}
            >
              <EditableTextArea
                value={data?.text || ""}
                placeholder="Your Message"
                onSave={(text) => {
                  setNode({
                    text,
                  });
                }}
              />
            </WithAttachments>
          </DragPreventer>
          <div className="flex flex-row justify-end items-center">
            <Button
              type="link"
              onClick={() => {
                triggerTempModal(MessageVariantEditor, {
                  existingVariants: data.textVariants || [],
                  onSave: (textVariants) => {
                    setNode({
                      textVariants,
                    });
                  },
                });
              }}
            >
              A/B Test ({data.textVariants?.length || "0"})
            </Button>
            <Button
              type="link"
              onClick={(e) =>
                document.getElementById(`uploadField_${id}`)?.click()
              }
              icon={<PaperClipOutlined />}
            />
            <Button
              type="link"
              onClick={() => {
                const modalRef = modal.info({
                  icon: null,
                  title: (
                    <ModalTitle
                      title="Message Personalization"
                      icon={<i className="ri-braces-line"></i>}
                    />
                  ),
                  content: (
                    <div>
                      <Divider />
                      <div className="mt-4">
                        You can use variable tokens in your messages
                        <div className="font-bold my-4">Available Tokens:</div>
                        <table className="simple-table-style w-full">
                          <thead>
                            <tr>
                              <th className="font-bold">Variable</th>
                              <th className="font-bold">Token</th>
                            </tr>
                          </thead>
                          <tbody>
                            {AvailableTokenTableForBots.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.label}</td>
                                  <td>
                                    <Tag
                                      onClick={() => {
                                        setNode({
                                          text: `${data.text} {${item.token}}`,
                                        });
                                        modalRef.destroy();
                                      }}
                                      className="cursor-pointer"
                                    >
                                      {item.token}
                                    </Tag>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ),
                });
              }}
            >
              Personalization{" "}
              {(capturedTokens?.validTokens || []).length > 0
                ? `(${capturedTokens.validTokens.length})`
                : ""}
            </Button>
          </div>
          <div>
            {(capturedTokens?.invalidTokens || []).length !== 0 && (
              <div className="token-container">
                <div className="font-bold my">Invalid Tokens:</div>
                {(capturedTokens?.invalidTokens || []).map((item, index) => (
                  <Tag key={index.toString()}>{item}</Tag>
                ))}
              </div>
            )}
          </div>

          {id !== "START" &&
            (data?.quickButtons || []).length === 0 &&
            (!data.persistence ||
              !data.persistence?.target ||
              data.persistence?.target === "NONE") &&
            (!data.validation ||
              !data.validation?.type ||
              data.validation?.type === "NONE") && (
              <Checkbox
                checked={data.processNextNodeImmediately}
                onChange={(e) => {
                  if (e.target.checked) {
                    setNode({
                      processNextNodeImmediately: e.target.checked,
                      quickButtons: [],
                      persistence: undefined,
                      validation: undefined,
                    });
                  } else {
                    setNode({
                      processNextNodeImmediately: e.target.checked,
                    });
                  }
                }}
              >
                Process Next Block Immediately
              </Checkbox>
            )}
          {data?.persistence?.target &&
            data?.persistence?.target !== "NONE" && (
              <Checkbox
                checked={data.skipIfDataAlreadyCollection}
                onChange={(e) => {
                  setNode({
                    skipIfDataAlreadyCollection: e.target.checked,
                  });
                }}
              >
                Skip if data is already collected
              </Checkbox>
            )}

          {!data.processNextNodeImmediately && (
            <>
              {(data?.quickButtons || []).map((quickAction, index) => (
                <div
                  key={index.toString()}
                  className="flex flex-row justify-between relative"
                >
                  <EditableText
                    value={quickAction.label}
                    placeholder={"Quick Reply"}
                    onSave={(value) => {
                      setNode({
                        quickButtons: (() => {
                          const quickReplies = cloneDeep([
                            ...data?.quickButtons,
                          ]);
                          quickReplies[index].label = (value || "").trim();
                          return quickReplies;
                        })(),
                      });
                    }}
                  />
                  <Button
                    icon={<i className="ri-delete-bin-4-line"></i>}
                    type="text"
                    onClick={() => {
                      setNode({
                        quickButtons: _.without(
                          data?.quickButtons,
                          quickAction,
                        ),
                      });
                    }}
                    className="mr-4"
                  />
                  <ChatFlowHandle
                    handleId={quickAction.id}
                    nodeId={id}
                    order={index + 1}
                  />
                </div>
              ))}
              {isAddQuickReplyVisible && (
                <EditableText
                  value={""}
                  placeholder={"+ Quick Reply"}
                  onSave={(value) => {
                    setNode({
                      quickButtons: (() => {
                        const quickReplies = [
                          ...(data?.quickButtons || []),
                          {
                            id: Date.now().toString(),
                            label: (value || "").trim(),
                          },
                        ];
                        return quickReplies;
                      })(),
                    });
                    reRender();
                  }}
                />
              )}
              {true && (
                <div>
                  <Button
                    type="link"
                    onClick={() => setConfigVisibility(!configVisibility)}
                    icon={
                      configVisibility ? (
                        <i className="ri-arrow-up-s-line text-2xl"></i>
                      ) : (
                        <i className="ri-arrow-down-s-line text-2xl"></i>
                      )
                    }
                    block
                  >
                    Advanced
                  </Button>
                </div>
              )}

              <SimpleCollapsePane isCollapsed={configVisibility}>
                <Form
                  layout="vertical"
                  form={form}
                  initialValues={data}
                  onValuesChange={() => {
                    setNode(form.getFieldsValue());
                  }}
                  className="mt-4"
                >
                  <div className="flex flex-col justify-center items-center">
                    <Tag className="font-bold">Validation</Tag>
                  </div>
                  <DragPreventer>
                    <Form.Item
                      label={<div className="font-bold">Select Validation</div>}
                      name={["validation", "type"]}
                    >
                      <Select style={{ minWidth: 150 }}>
                        <Select.Option value="NONE">NONE</Select.Option>
                        <Select.Option value="EMAIL">Email</Select.Option>
                        <Select.Option value="WORK_EMAIL">
                          Work Email
                        </Select.Option>
                        <Select.Option value="MOBILE_NUMBER">
                          Mobile Number
                        </Select.Option>
                        <Select.Option value="DATE">Date</Select.Option>
                        <Select.Option value="NUMBER">Number</Select.Option>
                        <Select.Option value="REGEX">Regex</Select.Option>
                      </Select>
                    </Form.Item>
                  </DragPreventer>

                  <DragPreventer>
                    <Form.Item shouldUpdate className="mb-0 empty-form">
                      {({ getFieldValue }) => {
                        const selectedOption = getFieldValue([
                          "validation",
                          "type",
                        ]);
                        const invalidInputComponent = (
                          <Form.Item
                            label={
                              <div className="font-bold">
                                Invalid Input Message
                              </div>
                            }
                            name={["validation", "invalidMessage"]}
                          >
                            <Input style={{ minWidth: 150 }} />
                          </Form.Item>
                        );
                        if (selectedOption && selectedOption !== "NONE") {
                          if (selectedOption === "REGEX") {
                            return (
                              <>
                                <Form.Item
                                  label={<div className="font-bold">Regex</div>}
                                  name={["validation", "regex"]}
                                >
                                  <Input style={{ minWidth: 150 }} />
                                </Form.Item>
                                {invalidInputComponent}
                                <div className="mb-8"></div>
                              </>
                            );
                          }
                          return (
                            <>
                              {invalidInputComponent}
                              <div className="mb-8"></div>
                            </>
                          );
                        }

                        return <div></div>;
                      }}
                    </Form.Item>
                  </DragPreventer>

                  <div className="flex flex-col justify-center items-center">
                    <Tag className="font-bold">Persistence</Tag>
                  </div>
                  <DragPreventer>
                    <Form.Item
                      label={<div className="font-bold">Save Target</div>}
                      name={["persistence", "target"]}
                    >
                      <Select style={{ minWidth: 150 }}>
                        <Select.Option value="NONE">None</Select.Option>
                        <Select.Option value="CONTACT_NAME">
                          Contact Name
                        </Select.Option>
                        <Select.Option value="CONTACT_EMAIL">
                          Contact Email
                        </Select.Option>
                        <Select.Option value="CONTACT_MOBILE_NUMBER">
                          Contact Mobile Number
                        </Select.Option>
                        <Select.Option value="CONTEXT">
                          Chat Context
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </DragPreventer>

                  <DragPreventer>
                    <Form.Item shouldUpdate className="mb-0 empty-form">
                      {({ getFieldValue }) => {
                        const selectedOption = getFieldValue([
                          "persistence",
                          "target",
                        ]);
                        if (selectedOption === "CONTEXT") {
                          return (
                            <>
                              <Form.Item
                                label={<div className="font-bold">Key</div>}
                                name={["persistence", "key"]}
                              >
                                <Input style={{ minWidth: 150 }} />
                              </Form.Item>
                              <div className="mb-8"></div>
                            </>
                          );
                        }
                        return <div></div>;
                      }}
                    </Form.Item>
                  </DragPreventer>

                  <div className="flex flex-col justify-center items-center">
                    <Tag className="font-bold">Follow up</Tag>
                  </div>
                  <DragPreventer>
                    <Form.Item
                      label={
                        <div className="font-bold">Select Followup Period</div>
                      }
                      name={["autoFollowUpConfig", "threshold"]}
                    >
                      <Select style={{ minWidth: 150 }}>
                        <Select.Option value={0}>NONE</Select.Option>
                        <Select.Option value={2 * 60 * 60 * 1000}>
                          2 Hours
                        </Select.Option>
                        <Select.Option value={6 * 60 * 60 * 1000}>
                          6 Hours
                        </Select.Option>
                        <Select.Option value={24 * 60 * 60 * 1000}>
                          24 Hours
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </DragPreventer>
                  <DragPreventer>
                    <Form.Item shouldUpdate className="mb-0 empty-form">
                      {({ getFieldValue }) => {
                        const selectedOption = getFieldValue([
                          "autoFollowUpConfig",
                          "threshold",
                        ]);
                        if (
                          selectedOption !== undefined &&
                          selectedOption !== 0
                        ) {
                          return (
                            <>
                              <Form.Item
                                label={
                                  <div className="font-bold">
                                    Message (Optional)
                                  </div>
                                }
                                name={["autoFollowUpConfig", "message"]}
                              >
                                <Input style={{ minWidth: 150 }} />
                              </Form.Item>
                              <div className="mb-8"></div>
                            </>
                          );
                        }
                        return <div></div>;
                      }}
                    </Form.Item>
                  </DragPreventer>
                </Form>
              </SimpleCollapsePane>
            </>
          )}
        </div>
      </div>
    );
  },
);
