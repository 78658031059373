import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iOrganization } from "@sdk/user-management/user-management.models";
import { Button, Divider, Form, Modal, Progress } from "antd";
import { useForm } from "antd/lib/form/Form";

import { PillSelector } from "components/common/pill-selector/pill-selector";
import { SectionHeader } from "components/common/section-header";
import {
  PlanCard,
  PricingPlans
} from "components/modules/organization-management/general/billing/pricing-plans";
import {
  TEAM_PRICING,
  iPricingPlan
} from "components/modules/organization-management/general/billing/pricing-plans-data";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect, useMemo, useState } from "react";

import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { useCalendlyEventListener } from "react-calendly";
import { useSelector } from "react-redux";
import { DeepPartial } from "redux";
import {
  selectAccountStatus,
  selectOrganizationOnboardingState,
  selectShopifyBilling
} from "store/modules/workspace/workspace.selectors";
import { BrandingReviewStep } from "../common/onboarding-branding-review";
import { ExpressAccountFinalSetup } from "../common/onboarding-final-step-demo";
import { useShopifyBillingStatusChecker } from "./helpers/shopify-billing-status-checker";
import "./shopify-onboarding-experience.scss";

const finalStepNumber = 8;
const stepPercentage = Math.floor(100 / finalStepNumber);

export const ShopifyCustomizeExperienceModal = ({
  visible,
  onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const [form] = useForm();

  const currentOnboardingState = useSelector(selectOrganizationOnboardingState);
  const accountStatus = useSelector(selectAccountStatus);
  const shopifyBillingStatus = useSelector(selectShopifyBilling);

  const lastCompletedStep = useMemo(() => {
    if (currentOnboardingState) {
      if (currentOnboardingState.preferenceCompleted) {
        // return 5;
        return finalStepNumber;
      } else if (currentOnboardingState.autoSetupCompleted) {
        return 6;
      } else if (
        currentOnboardingState.useCases &&
        currentOnboardingState.useCases.length > 0
      ) {
        return 5;
      } else if (
        currentOnboardingState.featuresInterested &&
        currentOnboardingState.featuresInterested.length > 0
      ) {
        return 4;
      } else if (currentOnboardingState.role) {
        return 3;
      } else if (currentOnboardingState.teamSize) {
        return 2;
      }
      return 1;
    } else {
      return 1;
    }
  }, [currentOnboardingState]);

  const [currentStep, setCurrentStep] = useState(lastCompletedStep);

  useEffect(() => {
    setCurrentStep(lastCompletedStep);
  }, [lastCompletedStep]);

  const [selectedPlan, setSelectedPlan] = useState<{
    plan: iPricingPlan;
    isYearly?: boolean;
  }>({
    plan: TEAM_PRICING[0],
    isYearly: false
  });

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: e => console.log(e.data.payload)
  });

  const { doAction: editCurrentOrganization } = useSDKActionWithDeps(
    () => ({
      action: SDK => edits => SDK.editCurrentOrganization(edits),
      // successMessage: "Done",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const onNextStep = useCallback(() => {
    const formValues = form.getFieldsValue();
    setCurrentStep(currentStep => currentStep + 1);
    editCurrentOrganization({
      appData: {
        ONBOARDING: formValues
      }
    } as DeepPartial<iOrganization>);
  }, [editCurrentOrganization, form]);

  const { changePanelState } = useModalPanels();

  useEffect(() => {
    if (
      currentStep === finalStepNumber &&
      !(accountStatus === "PAID" || accountStatus === "FREE_VERSION")
    ) {
      editCurrentOrganization({
        metaData: {
          status: "PAID"
        }
      } as DeepPartial<iOrganization>);
    }
  }, [accountStatus, currentStep, editCurrentOrganization]);

  const [
    isShopifyBillingCheckerActivated,
    setSHopifyBillingChecker,
    reloadBillingStatus,
    isReloadingStatus
  ] = useShopifyBillingStatusChecker();

  const {
    doAction: _activatePlan,
    isProcessing,
    response
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (req: {
        planId: string;
        planName: string;
        planPrice: number;
      }) =>
        SDK.shopify.activateSubscription(req).then(d => {
          window.open(d.confirmation_url, "cc-shopify-billing");
          setSHopifyBillingChecker(true);
          return d;
        }),
      // successMessage: "Done",
      failureMessage: "Something went wrong"
    }),
    [setSHopifyBillingChecker]
  );

  const activatePlan = useCallback(
    (plan: iPricingPlan) => {
      if (plan.id === "STARTUP") {
        editCurrentOrganization({
          metaData: {
            status: "FREE_VERSION"
          },
          appData: {
            ONBOARDING: {
              preferenceCompleted: true
            }
          }
        } as DeepPartial<iOrganization>).then(d => {
          // setCurrentStep((currentStep) => currentStep + 2);
        });
      } else {
        setCurrentStep(currentStep => currentStep + 1);
        _activatePlan({
          planId: plan.id,
          planName: plan.name,
          planPrice: Number(plan.price.replace("$", ""))
        });
      }
    },
    [_activatePlan, editCurrentOrganization]
  );

  useEffect(() => {
    if (shopifyBillingStatus) {
      editCurrentOrganization({
        metaData: {
          status: "PAID"
        },
        appData: {
          ONBOARDING: {
            preferenceCompleted: true
          }
        }
      } as DeepPartial<iOrganization>);
    }
  }, [editCurrentOrganization, shopifyBillingStatus]);

  return (
    <Modal
      title={
        null
        // <ModalTitle
        //   title="Getting Started"
        //   icon={<i className="ri-star-smile-line"></i>}
        // />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      footer={null}
      className="max-w-screen-md w-full customize-experience-modal"
      data-click-context="Customize Experience Modal"
      closable={currentStep === finalStepNumber}
      maskClosable={currentStep === finalStepNumber}
    >
      <div className="flex flex-col max-w-screen-xl w-full standard-onboarding-experience">
        {currentStep < 5 && (
          <>
            {currentStep === 1 && (
              <SectionHeader
                title="Welcome to Click Connector"
                description=" Let's quickly customize your experience and set up your
            workspace"
                icon={<span> 🎉</span>}
              />
            )}

            <Progress
              percent={
                currentStep === finalStepNumber
                  ? 100
                  : currentStep * stepPercentage
              }
              status="active"
              showInfo={false}
              className="opacity-25"
            />

            <Divider />

            <Form
              layout="vertical"
              onFinish={() => {
                const formValues = form.getFieldsValue();
              }}
              form={form}
              initialValues={currentOnboardingState}
              preserve
              requiredMark={false}
            >
              {currentStep === 1 && (
                <>
                  <Form.Item
                    label={
                      <div className="mb-8 text-center w-full">
                        <div className="font-semibold text-2xl">
                          How big is your team?
                        </div>
                        <div className="text-gray-600">
                          We'll use this to customize your team set up
                          experience
                        </div>
                      </div>
                    }
                    name="teamSize"
                    rules={[
                      {
                        required: true,
                        message: "Please select your team size"
                      }
                    ]}
                    className="mb-16 full-w-label"
                  >
                    <PillSelector options={TeamSizeOptions} className="mt-2" />
                  </Form.Item>
                </>
              )}

              {currentStep === 2 && (
                <>
                  <Form.Item
                    label={
                      <div className="mb-8 text-center w-full">
                        <div className="font-semibold text-2xl">
                          What is your role in your organization?
                        </div>
                      </div>
                    }
                    name="role"
                    rules={[
                      { required: true, message: "Please select your role" }
                    ]}
                    className="mb-16 full-w-label"
                  >
                    <PillSelector options={UserRoleOptions} />
                  </Form.Item>
                </>
              )}

              {currentStep === 3 && (
                <>
                  <Form.Item
                    label={
                      <div className="mb-8 text-center w-full">
                        <div className="font-semibold text-2xl">
                          What are the communication channels that you want to
                          use?
                        </div>

                        <div className="text-gray-600">
                          You can select as many as you want
                        </div>
                      </div>
                    }
                    name="featuresInterested"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one"
                      }
                    ]}
                    className="mb-16 full-w-label"
                  >
                    <PillSelector
                      options={InterestedFeaturesOptions}
                      multiple={true}
                      className="mt-2"
                    />
                  </Form.Item>
                </>
              )}
              {currentStep === 4 && (
                <>
                  <Form.Item
                    label={
                      <div className="mb-8 text-center w-full">
                        <div className="font-semibold text-2xl">
                          What will you be using Click Connector for?
                        </div>

                        <div className="text-gray-600">
                          You can select as many as you want
                        </div>
                      </div>
                    }
                    name="useCases"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one"
                      }
                    ]}
                    className="mb-16 full-w-label"
                  >
                    <PillSelector options={WhyUseCCOptions} multiple={true} />
                  </Form.Item>
                </>
              )}
            </Form>

            <Button
              type="primary"
              icon={<i className="ri-arrow-right-circle-line"></i>}
              className="font-bold"
              size="large"
              onClick={() => {
                form.validateFields().then(e => {
                  onNextStep();
                });
              }}
            >
              Continue
            </Button>
          </>
        )}

        {currentStep === 5 && (
          <>
            <BrandingReviewStep
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              stepPercentage={stepPercentage}
            />
          </>
        )}

        {currentStep === 6 && (
          <>
            <SectionHeader
              title="Choose a plan to continue"
              description="* All Plans have 14 day trial period"
              icon={<span>🚀</span>}
            />
            <Progress
              percent={currentStep * stepPercentage}
              status="active"
              showInfo={false}
              className="opacity-25"
            />
            <Divider />
            <div className="w-full flex flex-col justify-center items-center">
              <PricingPlans
                onPlanSelect={async (plan, isYearly) => {
                  setSelectedPlan({
                    plan: {
                      ...plan,
                      planId: `${plan.id}_${isYearly ? "YEARLY" : "MONTHLY"}`
                    },
                    isYearly
                  });
                  activatePlan(plan);
                }}
                selectedPlan={""}
                hideYearly={true}
              />
            </div>
          </>
        )}

        {currentStep === 7 && (
          <>
            <SectionHeader
              title="Payment Details"
              description="Fill in the below form to continue"
              icon={<span>🚀</span>}
            />
            <Progress
              percent={currentStep * stepPercentage}
              status="active"
              showInfo={false}
              className="opacity-25"
            />

            <Divider className="text-sm font-bold">Selected Plan</Divider>
            <div className="w-full">
              <Button
                className=""
                type="text"
                icon={<i className="ri-arrow-left-line"></i>}
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                Choose a different Plan
              </Button>
            </div>
            <PlanCard plan={selectedPlan.plan} yearly={selectedPlan.isYearly} />

            <div
              className="flex flex-col w-full justify-center items-center"
              style={{ height: 200 }}
            >
              <div className="text mb-8">
                Waiting for you to authorize billing request
              </div>
              <LoadingIndicatorWithSpin fontSize={20} />

              <div className="flex flex-row justify-center items-center my-4">
                <Button
                  type="text"
                  onClick={() => {
                    console.log(
                      "response.confirmation_url",
                      response.confirmation_url
                    );
                    window.open(
                      response.confirmation_url,
                      "cc-shopify-billing"
                    );
                  }}
                >
                  Re-Open Shopify Billing Page
                </Button>
              </div>

              <div className="flex flex-row justify-center items-center my-4">
                <Button
                  type="text"
                  onClick={() => reloadBillingStatus()}
                  loading={isReloadingStatus}
                >
                  Check Billing Status
                </Button>
              </div>
            </div>
          </>
        )}

        {currentStep === finalStepNumber && (
          <>
            <ExpressAccountFinalSetup />
          </>
        )}
      </div>
      <DarkModeBg />
    </Modal>
  );
};

const InterestedFeaturesOptions = [
  {
    value: "LIVE_CHAT",
    label: "Website Live Chat",
    icon: <i className="ri-message-3-line"></i>
  },
  {
    value: "EMAIL_INBOX",
    label: "Email",
    icon: <i className="ri-mail-line"></i>
  },
  {
    value: "FACEBOOK",
    label: "Facebook",
    icon: <i className="ri-facebook-box-line"></i>
  },
  {
    value: "INSTAGRAM",
    label: "Instagram",
    icon: <i className="ri-instagram-line"></i>
  },
  {
    value: "WHATSAPP",
    label: "Whatsapp",
    icon: <i className="ri-whatsapp-line"></i>
  }
];

const WhyUseCCOptions = [
  {
    value: "Customer Support",
    label: "Customer Support",
    icon: <i className="ri-customer-service-2-line"></i>
  },
  {
    value: "Sales",
    label: "Sales",
    icon: <i className="ri-file-user-line"></i>
  },
  {
    value: "Lead Generation",
    label: "Lead Generation",
    icon: <i className="ri-customer-service-2-line"></i>
  },
  {
    value: "Marketing Automation",
    label: "Marketing Automation",
    icon: <i className="ri-magic-line"></i>
  },
  {
    value: "CRM",
    label: "CRM",
    icon: <i className="ri-contacts-book-line"></i>
  }
];

const UserRoleOptions = [
  {
    value: "Founder / Owner",
    label: "Founder / Owner",
    icon: <i className="ri-user-star-line"></i>
  },
  {
    value: "Marketing",
    label: "Marketing",
    icon: <i className="ri-funds-box-line"></i>
  },
  {
    value: "Sales",
    label: "Sales",
    icon: <i className="ri-money-dollar-circle-line"></i>
  },
  {
    value: "IT Manager",
    label: "IT Manager",
    icon: <i className="ri-computer-line"></i>
  },
  {
    value: "Other",
    label: "Other",
    icon: <i className="ri-account-box-line"></i>
  }
];

const TeamSizeOptions = [
  {
    value: "1-3",
    label: "1-3"
  },
  {
    value: "3-10",
    label: "3-10"
  },
  {
    value: "10-20",
    label: "10-20"
  },
  {
    value: "20-50",
    label: "20-50"
  },
  {
    value: "50+",
    label: "50+"
  }
];
