import { iDevice } from "@sdk/sessions/sessions.models";

export const DeviceIcon = ({ device }: { device: iDevice }) => {
  return (
    <span>
      {device?.type === "MOBILE" && <i className="ri-smartphone-line"></i>}
      {device?.type === "TABLET" && <i className="ri-tablet-line"></i>}
      {device?.type === "DESKTOP" && <i className="ri-macbook-line"></i>}
      {(!device || device?.type === "UNKNOWN") && (
        <i className="ri-question-line"></i>
      )}
    </span>
  );
};
