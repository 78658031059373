import { DeepPartial } from "@reduxjs/toolkit";
import { axios, AxiosResponse } from "@sdk/axios";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { PaginatedOptions, PaginatedResults } from "../utils/paginated-results";
import {
  ContactQuery,
  OpportunitiesReq_BulkAction,
  Validated_CreateListRequest,
} from "./crm.controller-models";
import {
  iActivity,
  iCompany,
  iContact,
  iContactList,
  iEngagement,
  iOpportunity,
} from "./crm.models";
import {
  iCompaniesBulkActionReq,
  iContactBulkActionReq,
} from "./crm.service-models";

export class CRMService {
  constructor(protected config: { basePath: string; token: string }) {}

  /* ---------------------------------- Opportunities --------------------------------- */

  opportunities = Object.assign(
    new EntityServices<iOpportunity>(this.config, "opportunities", {
      onCreate: () => {
        UserTracker.track("OPPORTUNITIES - Create", {});
      },
      onDelete: () => {
        UserTracker.track("OPPORTUNITIES - Delete", {});
      },
    }),
    {
      assignUser: async (opportunityId: string, userId: string) => {
        UserTracker.track("OPPORTUNITIES - Assign User");
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/opportunities/${opportunityId}/assign`,
          {
            userId,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      unassignUser: async (opportunityId: string, userId: string) => {
        UserTracker.track("OPPORTUNITIES - UnAssign User");
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/opportunities/${opportunityId}/unassign`,
          {
            userId,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      addComment: async (
        opportunityId: string,
        text: string,
        attachments: string[],
      ) => {
        UserTracker.track("OPPORTUNITIES - Add Comment");
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/opportunities/${opportunityId}/comments`,
          {
            text,
            attachments,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      markAsCompleted: async (opportunitiesId: string, won: boolean) => {
        UserTracker.track("OPPORTUNITIES - Mark as Completed");
        const results: AxiosResponse<any> = await axios.patch(
          this.config.basePath + `/opportunities/${opportunitiesId}/complete`,
          {
            won,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      bulkEditOpportunities: async (req: OpportunitiesReq_BulkAction) => {
        UserTracker.track("OPPORTUNITIES - Bulk Edit Opportunities");
        const results: AxiosResponse<{ status: boolean }> = await axios.post(
          this.config.basePath + `/opportunities/bulk-edit`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );

  /* ---------------------------------- Products --------------------------------- */

  // products = Object.assign(
  //   new EntityServices<iOpportunityProduct>(this.config, "products"),
  //   {}
  // );
  /* ---------------------------------- Engagement --------------------------------- */

  engagements = Object.assign(
    new EntityServices<iEngagement>(this.config, "engagements", {
      onCreate: () => {
        UserTracker.track("ENGAGEMENTS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("ENGAGEMENTS - Delete", {});
      },
    }),
    {},
  );

  /* ---------------------------------- Companies --------------------------------- */

  companies = Object.assign(
    new EntityServices<iCompany>(this.config, "companies", {
      onCreate: () => {
        UserTracker.track("COMPANIES - Create", {});
      },
      onDelete: () => {
        UserTracker.track("COMPANIES - Delete", {});
      },
    }),
    {
      bulkEditCompanies: async (req: iCompaniesBulkActionReq) => {
        UserTracker.track("COMPANIES - Bulk Edit Companies");
        const results: AxiosResponse<{ status: boolean }> = await axios.post(
          this.config.basePath + `/companies/bulk-edit`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );

  /* ---------------------------------- Contact Lists --------------------------------- */

  contactLists = Object.assign(
    new EntityServices<iContactList>(this.config, "contact-lists", {
      onCreate: () => {
        UserTracker.track("CONTACT_LISTS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("CONTACT_LISTS - Delete", {});
      },
    }),
    {
      create: async (req: Validated_CreateListRequest) => {
        UserTracker.track("CONTACT_LISTS - Create");
        const results: AxiosResponse<iContactList> = await axios.post(
          this.config.basePath + "/contact-lists",
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );

  /* -------------------------------- Contacts -------------------------------- */

  async createContact(req: any) {
    UserTracker.track("CONTACT - Create");
    const results: AxiosResponse<iContact> = await axios.post(
      this.config.basePath + "/contacts",
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async bulkCreateContacts(req: any) {
    UserTracker.track("CONTACT - Bulk Create");
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + "/contacts/bulk-create",
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async queryContacts(req: { query: any; options: PaginatedOptions }) {
    const results: AxiosResponse<PaginatedResults<iContact>> = await axios.post(
      this.config.basePath + "/contacts/query",
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async exportContacts(req: { query: any }) {
    UserTracker.track("CONTACT - Export Contacts");
    const results: AxiosResponse<Partial<iContact>[]> = await axios.post(
      this.config.basePath + "/contacts/export",
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async getContactByIds(contactIds: string[]) {
    if (contactIds.length === 0) {
      return [];
    }
    const results: AxiosResponse<iContact[]> = await axios.post(
      this.config.basePath + `/contacts/get-by-ids`,
      { contactIds },
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async getContactById(id: string) {
    const results: AxiosResponse<iContact> = await axios.get(
      this.config.basePath + `/contacts/${id}`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async editContact(id: string, edits: DeepPartial<iContact>) {
    const results: AxiosResponse<iContact> = await axios.patch(
      this.config.basePath + `/contacts/${id}`,
      edits,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async resolveContact(req: ContactQuery) {
    const results: AxiosResponse<{
      contact?: iContact;
      resolved: boolean;
    }> = await axios.post(this.config.basePath + `/contacts/resolve`, req, {
      headers: {
        Authorization: this.config.token,
      },
    });
    return results.data;
  }

  async verifyContact(contactId: string) {
    UserTracker.track("CONTACT - Verify Contact");
    const results: AxiosResponse<iContact> = await axios.post(
      this.config.basePath + `/contacts/${contactId}/verify`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }
  async mergeContacts(req: {
    source: string;
    target: string;
    dataOverrides: iContact["data"];
  }) {
    UserTracker.track("CONTACT - Merge Contacts");
    const results: AxiosResponse<iContact> = await axios.post(
      this.config.basePath + `/contacts/merge-contacts`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async addContactToList(contactId: string, listId: string) {
    const results: AxiosResponse<iContact> = await axios.post(
      this.config.basePath + `/contacts/${contactId}/lists/${listId}/add`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async unsubscribeContactFromAll(contactId: string) {
    UserTracker.track("CONTACT - Unsubscribe Contacts");
    const results: AxiosResponse<iContact> = await axios.post(
      this.config.basePath + `/contacts/${contactId}/unsubscribe`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async unsubscribeContactFromList(contactId: string, listId: string) {
    const results: AxiosResponse<iContact> = await axios.post(
      this.config.basePath +
        `/contacts/${contactId}/lists/${listId}/unsubscribe`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async removeContactFromList(contactId: string, listId: string) {
    const results: AxiosResponse<iContact> = await axios.post(
      this.config.basePath + `/contacts/${contactId}/lists/${listId}/remove`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async bulkAction(req: iContactBulkActionReq) {
    UserTracker.track("CONTACT - Bulk Edit Contact");
    const results: AxiosResponse<iContact> = await axios.post(
      this.config.basePath + `/contacts/bulk-action`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async getContactTags() {
    const results: AxiosResponse<any[]> = await axios.get(
      this.config.basePath + `/accounts/me/tags/CONTACTS`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async deleteContact(id: string) {
    UserTracker.track("CONTACT - Delete Contact");
    const results: AxiosResponse<iContact> = await axios.delete(
      this.config.basePath + `/contacts/${id}`,
      {
        headers: {
          Authorization: this.config.token,
        },
        params: { confirmation: "DELETE" },
      },
    );
    return results.data;
  }

  activities = Object.assign(
    new EntityServices<iActivity>(this.config, "activities", {
      onCreate: () => {
        UserTracker.track("ACTIVITIES - Create", {});
      },
      onDelete: () => {
        UserTracker.track("ACTIVITIES - Delete", {});
      },
    }),
    {},
  );
}
