import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import { Button, Space, Table } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import {
  DraggableBodyRowGenerator,
  DraggableContainerGenerator,
  DragHandle,
} from "components/common/sortable-table/sortable-table-elements";
import { useSortableTable } from "components/common/sortable-table/use-sortable-table";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useStore } from "react-redux";
import { LoadAllGroups } from "store/modules/groups/groups.helpers";
import {
  selectAllGroupsQuery,
  selectGroupById,
} from "store/modules/groups/groups.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { stringArrayToSentence } from "utils/string-array-to-sentence";
import { iRule } from "../rule-editor/models";
import { RuleEditorModal } from "../rule-editor/rule-editor-modal";

const dummyRules: iRule[] = [
  {
    id: "1",
    field: "adName",
    operator: "contains",
    value: "Digital",
    routeTo: "ROUTE_TO_ANY_AVAILABLE_AGENT",
    routingConfig: {},
  },
  {
    id: "2",
    field: "language",
    operator: "is",
    value: "Spanish",
    routeTo: "ROUTE_TO_ANY_AVAILABLE_AGENT",
    routingConfig: {},
  },
];

export const RoutingRules = ({
  value,
  onChange,
}: {
  value?: iRule[];
  onChange?: (rules: iRule[]) => any;
}) => {
  const tableData = useMemo(() => value || [], [value]);

  const { state, setState, onSortEnd } = useSortableTable(tableData);

  useEffectWhen(
    () => {
      if (onChange) {
        onChange(state);
      }
    },
    [onChange, state],
    [state]
  );

  useEffect(() => {
    setState(tableData);
  }, [setState, tableData]);

  const store = useStore();

  const {
    state: { list: userGroups },
  } = useQueryWithStore(selectAllGroupsQuery, LoadAllGroups);

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "When",
      dataIndex: "when",
      render: (data, record: iRule, index) =>
        `${record.field} ${record.operator} ${record.value}`,
      className: "drag-visible",
    },
    {
      title: "Then",
      dataIndex: "routeTo",
      className: "drag-visible",
      render: (data, record: iRule, index) => {
        if (record.routeTo === "STOP_ROUTING") {
          return "Stop Routing";
        } else if (record.routeTo === "ROUTE_TO_ANY_AVAILABLE_AGENT") {
          return "Route to any available agent";
        } else if (record.routeTo === "STANDARD_ROUTING") {
          return "Follow standard routing rules";
        } else if (record.routeTo === "CUSTOM_ROUTING") {
          const groups = record.routingConfig?.groupsToRoute || [];
          const groupNames = groups.map(
            (groupId) => selectGroupById(groupId)(store.getState())?.label
          );
          return `Route to users in ${stringArrayToSentence(groupNames)}`;
        }
      },
    },
    {
      title: "Additional Actions",
      dataIndex: "actions",
      className: "drag-visible",
      render: (data, record: iRule, index) => {
        return `${(record.actions || []).length} Actions `;
      },
    },
    {
      title: "",
      dataIndex: "remove",
      width: 100,
      render: (data, record, index) => (
        <Space>
          <Button
            icon={<i className="ri-edit-line" />}
            type="link"
            onClick={() => {
              setRuleEditorState({
                visibility: true,
                rule: record,
              });
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            type="link"
            onClick={() => {
              setState(_.without(state, record));
            }}
          />
        </Space>
      ),
    },
  ];

  const [ruleEditorState, setRuleEditorState] = useSimpleState({
    visibility: false,
    rule: undefined as iRule | undefined,
  });

  return (
    <>
      <Table
        columns={columns}
        dataSource={state}
        pagination={false}
        rowKey="id"
        components={{
          body: {
            wrapper: DraggableContainerGenerator(onSortEnd),
            row: DraggableBodyRowGenerator(state),
          },
        }}
        locale={{
          emptyText: (
            <EmptyData text="No rules have been added" icon="ri-route-line" />
          ),
        }}
      />
      <div className="flex flex-row justify-center items-center my-8">
        <Button
          type="primary"
          icon={<i className="ri-add-line"></i>}
          onClick={() =>
            setRuleEditorState({ visibility: true, rule: null as any })
          }
        >
          Add New Rule
        </Button>
      </div>

      <RuleEditorModal
        visible={ruleEditorState.visibility}
        onChangeVisibility={(visibility) => setRuleEditorState({ visibility })}
        initialValue={ruleEditorState.rule}
        onSave={(rule) => {
          setState((rules) => {
            if (_.find(rules, { id: rule.id })) {
              return rules.map((item) => {
                if (item.id === rule.id) {
                  return rule;
                }
                return item;
              });
            }
            return [...rules, rule];
          });
        }}
      />
    </>
  );
};
