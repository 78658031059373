import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, message } from "antd";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectAccountBillingType,
  selectIsAppSumoUser,
  selectOrganization,
  selectSubscriptionDetails,
} from "store/modules/workspace/workspace.selectors";
import { ContactAccountManagerCard } from "../../contact-account-manager-card/contact-account-manager-card";
import { ActivateSubscriptionModal } from "./activate-subscription";
import { ActivePlanSummaryCard } from "./active-plan-summary-card";
import {
  AppSumoBillingPortal,
  AppSumoSubscriptionCard,
} from "./appsumo-subscription-card";
import { BillingAddons } from "./billing-addons";
import { ManagedByParentWorkspace } from "./manage-by-parent-workspace";
import { NextPaymentSummary } from "./next-payment-summary";
import { PaymentMethodSummaryCard } from "./payment-method-summary-card";
import { PricingPlansChanger } from "./pricing-plans-changer";
import { iPricingPlan } from "./pricing-plans-data";
import { RecentInvoices } from "./recent-invoices";
import { ShopifyBillingPortal } from "./shopify-billing-portal";
import { UpdateSubscriptionModal } from "./update-subscription-modal";
import { useSubscriptionDetails } from "./use-subscription-details";

// Note
//* If Invoice Payment failed, after first subscription or payment method is changed, it is not handled here. Ideally
// This needs to shown near Payment Method Section saying that payment failed and update card
// This indicator can be created using `activeSubscription` object

// Test Card Details: https://stripe.com/docs/testing
// No Auth - 4242 4242 4242 4242 // Any Future Date // Any CVC
// 3D Secure - 4000002500003155
// 3D Secure on all - 4000002760003184

export const BillingPortal = () => {
  const organization = useSelector(selectOrganization);
  const isCustomBilling = useSelector(selectAccountBillingType);
  const subscriptionDetails = useSelector(selectSubscriptionDetails);
  const isAppSumoUser = useSelector(selectIsAppSumoUser);

  if (!organization?.id) {
    return (
      <StyledScrollArea className="w-full h-full animated fadeInLeftMin">
        <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4 flex flex-col justify-center items-center">
          <LoadingIndicatorWithSpin />
        </SimpleCardSection>
      </StyledScrollArea>
    );
  }

  if (organization.data.parentOrganizationId) {
    return <ManagedByParentWorkspace />;
  }

  if (isAppSumoUser) {
    if (subscriptionDetails?.appSumoBilling) {
      return <AppSumoSubscriptionCard />;
    }
    return (
      <StyledScrollArea className="w-full h-full animated fadeInLeftMin">
        <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4 flex flex-col justify-center items-center">
          <img
            src="/assets/app-sumo/app-sumo.svg"
            style={{ width: 300 }}
            alt="Custom Billing"
          />
          <div className="font-bold text-2xl my-8">
            You are using a license purchased from AppSumo.
          </div>
          <div className="text-gray-600">
            Please visit AppSumo dashboard for details
          </div>
          <AppSumoBillingPortal />
          {/* <ManageSubAccounts /> */}
        </SimpleCardSection>
      </StyledScrollArea>
    );
  }

  if (isCustomBilling) {
    return (
      <StyledScrollArea className="w-full h-full animated fadeInLeftMin">
        <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4 flex flex-col justify-center items-center">
          <img
            src="/assets/illustrations/billing.svg"
            style={{ width: 300 }}
            alt="Custom Billing"
          />
          <div className="font-bold text-2xl my-8">
            Your Account is under a Customized Subscription Plan
          </div>
          <div className="text-gray-600">
            Contact your Account Manager for queries
          </div>

          <ContactAccountManagerCard type="CARD" />
        </SimpleCardSection>
      </StyledScrollArea>
    );
  }

  if (subscriptionDetails?.shopifyBilling) {
    return <ShopifyBillingPortal />;
  }
  // eslint-disable-next-line react/jsx-pascal-case
  return <_BillingPortal />;
};

export const _BillingPortal = () => {
  const recentInvoicesRef = useRef<any>();
  const nextPaymentSummaryRef = useRef<any>();

  // console.log("activeSubscription", activeSubscription);
  const reloadStripeData = () => {
    recentInvoicesRef?.current?.reloadInvoices?.();
    nextPaymentSummaryRef?.current?.reloadSubscriptions?.();
  };

  const { doAction: forceResetSubscription, isProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () => SDK.forceResetSubscription(),
        successMessage: "Done",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const [selectedPlan, setSelectedPlan] = useState<undefined | iPricingPlan>(
    undefined,
  );

  const [activatePaymentModalVisibility, setActivatePaymentModalVisibility] =
    useState(false);
  const [showChangeSubscription, setShowChangeSubscription] = useState(false);

  const { isSubscribed, subscriptionDetails, isYearly, plan } =
    useSubscriptionDetails();

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin">
      <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4">
        <SectionHeader
          title="Billing & Subscriptions"
          icon={<i className="ri-team-line"></i>}
          description="Manage your subscriptions. View and change your billing details."
        />
        <Divider />

        <div className="">
          {(window as any).ENABLE_UNSAFE_ACTIONS && (
            <div className="subtitle text-gray-600 dark:text-gray-400 mb-2 text-2xl mode_transition">
              <Button type="link" onClick={forceResetSubscription}>
                Reset Subscription
              </Button>
            </div>
          )}

          {isSubscribed && (
            <>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <ActivePlanSummaryCard
                  onChangePlan={() => setShowChangeSubscription(true)}
                />

                <PaymentMethodSummaryCard reloadStripeData={reloadStripeData} />
              </div>
            </>
          )}
          {!isSubscribed && (
            <div className="text-center mb-4 text-gray-600 text-sm">
              Go ahead and pick your subscription plan
            </div>
          )}

          <div className="flex flex-row justify-center">
            {!isSubscribed && (
              <PricingPlansChanger
                reloadStripeData={reloadStripeData}
                setActivatePaymentModalVisibility={(plan) => {
                  setSelectedPlan(plan);
                  setActivatePaymentModalVisibility(true);
                }}
              />
            )}
          </div>

          {isSubscribed && (
            <>
              <NextPaymentSummary ref={nextPaymentSummaryRef} />

              <ContactAccountManagerCard type="SMALL" />

              <BillingAddons reloadStripeData={reloadStripeData} />
            </>
          )}

          <RecentInvoices ref={recentInvoicesRef} />
        </div>

        {/* Invoices */}
        {/*  Once the plan is selected, used to update the payment plan */}
        <ActivateSubscriptionModal
          isActivated={isSubscribed}
          visible={activatePaymentModalVisibility}
          onChangeVisibility={setActivatePaymentModalVisibility}
          onActivated={() => {
            setActivatePaymentModalVisibility(false);
            message.success("Subscription has been activated");
            reloadStripeData();
          }}
          plan={selectedPlan}
        />

        {/* Used to Change Plans - Has a Plan Changer In It  */}
        <UpdateSubscriptionModal
          visible={showChangeSubscription}
          onChangeVisibility={setShowChangeSubscription}
          reloadStripeData={reloadStripeData}
          setActivatePaymentModalVisibility={(plan) => {
            setSelectedPlan(plan);
            setActivatePaymentModalVisibility(true);
          }}
        />
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
