import { useSDKWithRemotePagination } from "@sdk/sdk.hooks";
import { Tabs } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import { useMemo } from "react";
const { TabPane } = Tabs;

export const ReleaseNotes = () => {
  const {
    paginationState,
    setPaginationState,
    data: _itemsInView,
    setData: setItemsInView,
    isLoading,
    reload: resetAndReloadChangeLogs,
  } = useSDKWithRemotePagination((SDK) => SDK.HQ_getHQChangeLogs, {}, [], {
    pageSize: 15,
    currentPage: 1,
    paginationOptions: {
      sort: "-version",
    },
  });

  const itemsInView = useMemo(
    () => _itemsInView.filter((item) => item.version),
    [_itemsInView],
  );

  return (
    <>
      <Tabs tabPosition="left" className="border-b">
        {_.orderBy(itemsInView, "version", "desc").map((log) => {
          return (
            <TabPane tab={`Version ${log.version}`} key={log.version}>
              <div className=" ">
                <div className="mb-5 ">
                  <b> Date:</b> {dayjs(log.releasedTime).format("DD-MM-YYYY")}
                </div>
                <div className="mb-5 ">
                  <b>{log.releaseNoteTitle}</b>
                </div>
                <div className="mb-5 flex ">
                  <div
                    className="raw-html-rendered-block"
                    dangerouslySetInnerHTML={{
                      __html: log.releaseNoteBody,
                    }}
                  />
                </div>
              </div>
            </TabPane>
          );
        })}
      </Tabs>
    </>
  );
};
