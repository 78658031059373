import { iReportCanvas } from "../components/report-canvas/widget-canvas-models";

export const GeneralReportCanvas = {
  id: "2927284f",
  title: "General Report",
  description:
    "A Standard report by Click Connector that gives you an overall picture of what is happening in Click Connector",
  type: "CONVERSATION",
  layout: [
    { w: 1, h: 1, x: 0, y: 0, i: "6ea681eb", moved: false, static: false },
    { w: 2, h: 2, x: 0, y: 2, i: "437666d0", moved: false, static: false },
    { w: 2, h: 2, x: 1, y: 0, i: "fdae2ba5", moved: false, static: false },
    { w: 1, h: 2, x: 2, y: 2, i: "0e18dbb5", moved: false, static: false },
    { w: 1, h: 1, x: 0, y: 1, i: "c1a4e7a8", moved: false, static: false },
    { w: 2, h: 3, x: 0, y: 4, i: "25ffbf74", moved: false, static: false },
    { w: 1, h: 1, x: 2, y: 4, i: "36560d65", moved: false, static: false },
    { w: 1, h: 1, x: 2, y: 5, i: "3a927149", moved: false, static: false },
    { w: 1, h: 1, x: 2, y: 6, i: "d3e112b1", moved: false, static: false },
  ],
  widgets: [
    {
      id: "6ea681eb",
      type: "CONVERSATION",
      title: "Total Conversations",
      description: "Total conversations count in the selected period",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: [],
      reportViewConfig: { defaultVisualization: "DIGIT" },
    },
    {
      id: "437666d0",
      type: "CONVERSATION",
      title: "Conversations by Tags",
      description: "Conversations by Tags in the selected period",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: ["tags"],
      reportViewConfig: {
        defaultVisualization: "PIE",
        tagType: ["General Tags"],
      },
    },
    {
      id: "fdae2ba5",
      type: "CONVERSATION",
      title: "Conversations by day",
      description: "Conversations  in the selected period",
      reportType: "CONVERSATION",
      aggregateByPeriod: "DAY",
      filters: [],
      groupBy: ["status"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
    {
      id: "0e18dbb5",
      type: "CONVERSATION",
      title: "Conversations by User Group",
      description: "Total Conversations in the selected period",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: ["users"],
      reportViewConfig: { groupByUserGroup: true, defaultVisualization: "PIE" },
    },
    {
      id: "c1a4e7a8",
      type: "CONVERSATION",
      title: "Average Response Time",
      description: "",
      reportType: "CONVERSATION",
      aggregateByPeriod: "DAY",
      aggregateFunction: "AVERAGE",
      aggregateTargetField: "responseTime",
      filters: [],
      groupBy: [],
      reportViewConfig: { defaultVisualization: "BAR_CHART" },
    },
    {
      id: "25ffbf74",
      type: "CONVERSATION",
      title: "Conversations by Users and Tags",
      description: "Total Conversations in the selected period",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: ["users", "tags"],
      reportViewConfig: {
        defaultVisualization: "PIVOT_TABLE",
        tagType: ["General Tags"],
      },
    },
    {
      id: "36560d65",
      type: "CONVERSATION",
      title: "By message count",
      description: "",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: ["messageCount"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
    {
      id: "3a927149",
      type: "CONVERSATION",
      title: "Conversations by Connection",
      description: "",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: ["connections"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
    {
      id: "d3e112b1",
      type: "CONVERSATION",
      title: "Conversations by durations",
      description: "",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: ["categorizedDuration"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
  ],
  dateField: "",
  filters: [],
  createdAt: 1664294448691,
  createdBy: "4f8080-13925",
  isShared: false,
  isNonEditable: true,
} as iReportCanvas;

export const MarketingReportCanvas = {
  id: "141dbdd9",
  title: "Marketing Report",
  description:
    "A Standard report by Click Connector that gives you an overall picture of what is happening in Click Connector",
  type: "CONVERSATION",
  layout: [
    { w: 2, h: 2, x: 0, y: 0, i: "d27f8b90", moved: false, static: false },
    { w: 1, h: 2, x: 0, y: 2, i: "b25baba5", moved: false, static: false },
    { w: 1, h: 1, x: 2, y: 0, i: "2b62c9b2", moved: false, static: false },
    { w: 1, h: 2, x: 1, y: 2, i: "8a42c42e", moved: false, static: false },
    { w: 1, h: 1, x: 2, y: 1, i: "ea2ea40b", moved: false, static: false },
  ],
  widgets: [
    {
      id: "d27f8b90",
      type: "CONVERSATION",
      title: "Conversations by Marketing Campaigns",
      description:
        "Conversations by Marketing Campaigns in the selected period",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: ["tags"],
      reportViewConfig: {
        defaultVisualization: "PIE",
        tagType: ["Marketing Campaigns"],
      },
    },
    {
      id: "b25baba5",
      type: "CONVERSATION",
      title: "Conversations by Facebook Ad",
      description: "Total conversations in the selected period by Facebook ads",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: ["fbAdId"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
    {
      id: "2b62c9b2",
      type: "CONVERSATION",
      title: "By Source",
      description: "",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: ["source"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
    {
      id: "8a42c42e",
      type: "CONVERSATION",
      title: "By Facebook Post",
      description: "",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: ["fbPostId"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
    {
      id: "ea2ea40b",
      type: "CONVERSATION",
      title: "By Connection",
      description: "",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: ["connections"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
  ],
  dateField: "",
  filters: [],
  createdAt: 1664335950105,
  createdBy: "4f8080-13925",
  isShared: false,
  isNonEditable: true,
} as iReportCanvas;

export const UsersReportCanvas = {
  id: "41dfd570",
  title: "Users Report",
  description:
    "A Standard report by Click Connector that gives you an overall picture of what is happening in Click Connector",
  type: "CONVERSATION",
  layout: [
    { w: 3, h: 2, x: 0, y: 2, i: "11ea1a95", moved: false, static: false },
    { w: 1, h: 2, x: 2, y: 0, i: "67284386", moved: false, static: false },
    { w: 2, h: 2, x: 0, y: 0, i: "1a7d3ddf", moved: false, static: false },
  ],
  widgets: [
    {
      id: "11ea1a95",
      type: "CONVERSATION",
      title: "Conversations by Users and Tags",
      description: "Total Conversations in the selected period",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: ["users", "tags"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
    {
      id: "67284386",
      type: "CONVERSATION",
      title: "Conversations by User Group",
      description: "Total Conversations in the selected period",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: ["users"],
      reportViewConfig: { defaultVisualization: "PIE", groupByUserGroup: true },
    },
    {
      id: "1a7d3ddf",
      type: "CONVERSATION",
      title: "Conversations By Users",
      description: "Total Conversations in the selected period",
      reportType: "CONVERSATION",
      filters: [],
      groupBy: ["users"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
  ],
  dateField: "",
  filters: [],
  createdAt: 1664335978154,
  createdBy: "4f8080-13925",
  isShared: false,
  isNonEditable: true,
} as iReportCanvas;

export const ConversationFlowReportCanvas = {
  id: "9168e8b6",
  title: "Conversation Flow Report",
  description:
    "A Standard report by Click Connector that gives you an overall picture of what is happening in Click Connector",
  type: "CONVERSATION",
  layout: [
    { w: 3, h: 2, x: 0, y: 0, i: "ac44abd7", moved: false, static: false },
  ],
  widgets: [
    {
      id: "ac44abd7",
      type: "CONVERSATION",
      title: "Conversation Flow Heatmap",
      description: "Conversations  in the selected period",
      reportType: "CONVERSATION",
      aggregateByPeriod: "HEAT_MAP",
      filters: [],
      groupBy: [],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
  ],
  dateField: "",
  filters: [],
  createdAt: 1664336002869,
  createdBy: "4f8080-13925",
  isShared: false,
  isNonEditable: true,
} as iReportCanvas;

export const LeadReportCanvas = {
  id: "eb68e81a",
  title: "Lead Report",
  description: "A simple report on new leads that are captured in your CRM",
  type: "LEADS",
  layout: [
    {
      w: 1,
      h: 1,
      x: 0,
      y: 0,
      i: "d751129b",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 1,
      x: 1,
      y: 0,
      i: "e074a6cd",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 1,
      x: 0,
      y: 1,
      i: "b22ede5d",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 1,
      x: 1,
      y: 1,
      i: "5c3983df",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 1,
      x: 2,
      y: 1,
      i: "b63596e6",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 1,
      x: 0,
      y: 2,
      i: "1a6bb73c",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 1,
      x: 1,
      y: 2,
      i: "cf9b91da",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 1,
      x: 2,
      y: 2,
      i: "b58a99f5",
      moved: false,
      static: false,
    },
  ],
  widgets: [
    {
      id: "d751129b",
      type: "LEADS",
      title: "Total Leads",
      description: "Total Lead count in the selected period",
      reportType: "LEADS",
      filters: [],
      groupBy: [],
      reportViewConfig: { defaultVisualization: "DIGIT" },
    },
    {
      id: "e074a6cd",
      type: "LEADS",
      title: "Leads by day",
      description: "Leads  in the selected period",
      reportType: "LEADS",
      aggregateByPeriod: "DAY",
      filters: [],
      groupBy: [],
      reportViewConfig: { defaultVisualization: "BAR_CHART" },
    },
    {
      id: "b22ede5d",
      type: "LEADS",
      title: "Leads by Tags",
      description: "Leads by Tags in the selected period",
      reportType: "LEADS",
      filters: [],
      groupBy: ["tags"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
    {
      id: "5c3983df",
      type: "LEADS",
      title: "Leads by Channels",
      description: "Leads by Channels in the selected period",
      reportType: "LEADS",
      filters: [],
      groupBy: ["connections"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
    {
      id: "b63596e6",
      type: "LEADS",
      title: "Leads by Country",
      description: "Leads by Country in the selected period",
      reportType: "LEADS",
      filters: [],
      groupBy: ["country"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
    {
      id: "1a6bb73c",
      type: "LEADS",
      title: "Leads by Marketing Preference",
      description: "Leads by Marketing Preference in the selected period",
      reportType: "LEADS",
      filters: [],
      groupBy: ["marketingConsentObtained"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
    {
      id: "cf9b91da",
      type: "LEADS",
      title: "Leads by Mobile Availability",
      description: "Leads by Mobile Availability in the selected period",
      reportType: "LEADS",
      filters: [],
      groupBy: ["mobileExists"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
    {
      id: "b58a99f5",
      type: "LEADS",
      title: "Leads by Email Availability",
      description: "Leads by Email Availability in the selected period",
      reportType: "LEADS",
      filters: [],
      groupBy: ["emailExists"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
  ],
  dateField: "",
  filters: [],
  createdAt: 1676991382681,
  createdBy: "demo01-b21a6",
  isShared: false,
} as iReportCanvas;

export const ActivitiesReportCanvas = {
  id: "30c99ca5",
  title: "Activities Report",
  description: "A simple report on all activities recorded in your CRM",
  type: "ACTIVITIES",
  layout: [
    {
      w: 1,
      h: 1,
      x: 0,
      y: 0,
      i: "3e69e3da",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 1,
      x: 1,
      y: 0,
      i: "9175760a",
      moved: false,
      static: false,
    },
  ],
  widgets: [
    {
      id: "3e69e3da",
      type: "ACTIVITIES",
      title: "Total Activities",
      description: "Total Activities count in the selected period",
      reportType: "ACTIVITIES",
      filters: [],
      groupBy: [],
      reportViewConfig: { defaultVisualization: "DIGIT" },
    },
    {
      id: "9175760a",
      type: "ACTIVITIES",
      title: "Activities by Type",
      description: "Activities by Type in the selected period",
      reportType: "ACTIVITIES",
      filters: [],
      groupBy: ["activityType"],
      reportViewConfig: { defaultVisualization: "PIE" },
    },
  ],
  dateField: "",
  filters: [],
  createdAt: 1676993895047,
  createdBy: "demo01-b21a6",
  isShared: false,
} as iReportCanvas;
