import { mdiWebhook } from "@mdi/js";
import { iConnectedApp } from "@sdk/connected-apps/connected-apps.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Form, Input, message, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { MDIIcon } from "components/common/mdi-icon";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useMemo } from "react";

const { Option } = Select;

export const AddWebhookAppModel = ({
  visible,
  onChangeVisibility,
  onCreated,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated?: (user: iConnectedApp) => any;
}) => {
  const [form] = useForm();

  const {
    doAction: createWebhookSubscription,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.connectedApps.create(values).then((res) => {
          onCreated && onCreated(res);
          form.resetFields();
          onChangeVisibility(false);
          form.resetFields();
          return res;
        }),
      successMessage: "Webhook Subscription has been created",
      failureMessage: "Something went wrong",
    }),
    [form, onChangeVisibility, onCreated],
  );

  const defaultValue = useMemo(() => ({}), []);

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const { event, label, targetUrl } = form.getFieldsValue();
      const [entity, eventType] = event.split("_"); // Eg: CONTACT_UPDATE
      createWebhookSubscription({
        type: "Webhook",
        label,
        targetUrl: targetUrl,
        subscriptions: [
          {
            entity: entity,
            event: "OBJECT_SYNC",
            data: {
              type: eventType,
            },
          },
        ],
        createdAt: Date.now(),
        isActive: true,
      });
    } catch (e) {
      message.error("Please check your input");
    }
  };

  return (
    <Modal
      title={
        <ModalTitle
          title={"Add New Webhook Subscription"}
          icon={<MDIIcon icon={mdiWebhook} size={"2rem"} />}
        />
      }
      open={visible}
      onOk={async () => onSubmit()}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okButtonProps={{ loading: isProcessing }}
      okText={"Create"}
      data-click-context="Create Webhook Subscription Modal"
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={defaultValue}
        className="bold-form-labels"
        onFinish={onSubmit}
      >
        {/* Label */}
        <Form.Item
          label="Label"
          name="label"
          rules={[{ required: true, message: "Please input a label" }]}
        >
          <Input
            placeholder="Eg: My App Integration"
            prefix={<i className="ri-plug-line"></i>}
          />
        </Form.Item>
        {/* Name */}
        <Form.Item
          label="Webhook URL"
          name="targetUrl"
          rules={[
            { required: true, message: "Please enter a URL" },
            {
              type: "url",
              message: "Invalid URL",
            },
          ]}
        >
          <Input placeholder="https://myapp.com/api/v1/webhhk" />
        </Form.Item>
        {/* Subscribed Field */}
        <Form.Item
          label="Event to subscribe"
          name="event"
          rules={[{ required: true, message: "Please select a role" }]}
        >
          <Select>
            <Select.OptGroup label="Contact">
              <Select.Option value="CONTACT_NEW">
                New Contact Record
              </Select.Option>
              <Select.Option value="CONTACT_UPDATE">
                Contact Record Updated
              </Select.Option>
            </Select.OptGroup>
            <Select.OptGroup label="Conversation">
              <Select.Option value="CONVERSATION_NEW">
                New Conversation Record
              </Select.Option>
              <Select.Option value="CONVERSATION_UPDATE">
                Conversation Record Updated
              </Select.Option>
            </Select.OptGroup>
          </Select>
        </Form.Item>
        <Button htmlType="submit" type="primary" className="hidden">
          Save
        </Button>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
