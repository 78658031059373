import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Descriptions, Popconfirm, Tag, Tooltip } from "antd";
import { DeviceIcon } from "components/pages/live-view/components/device-icon";
import { LocationText } from "components/pages/live-view/components/location";
import dayjs from "dayjs";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.selectors";
import { loadContactSessions } from "store/modules/sessions/sessions.helpers";
import { selectSessionsWithContactId } from "store/modules/sessions/sessions.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { AddEllipsis } from "utils/add-ellipsis";
import JSONModal from "utils/json-modal/json-modal";

export const SessionData = ({
  contactId,
  title,
}: {
  contactId: string;
  title?: string;
}) => {
  const { state: contactSessions, retry: reloadSessions } = useQueryWithStore(
    selectSessionsWithContactId(contactId),
    loadContactSessions(contactId),
    [contactId],
    !contactId,
  );

  const lastWebSession = useMemo(() => {
    if (!contactSessions! || !contactSessions.list[0]) {
      return undefined;
    }
    return contactSessions.list[0];
  }, [contactSessions]);

  const { doAction: blockIpAddress, isProcessing: idDeletingPipeline } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (ip: string, status: boolean) =>
          SDK.blockIpAddress({ ip, status }),
        successMessage: "Ip Address has been blacklisted",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const { state: widgets } = useQueryWithStore(
    selectAllChatWidgets,
    loadAllChatWidgets(),
    [],
  );

  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  const isIPBlocked = useMemo(() => {
    if (lastWebSession) {
      const ip = lastWebSession.ip;
      const allBlockedIpAddresses = _.flatMap(
        widgets.map(
          (widget) => widget.configurations.blackListedClients?.ips || [],
        ),
      );
      if (allBlockedIpAddresses.includes(ip)) {
        return true;
      }
    }
    return false;
  }, [widgets, lastWebSession]);

  if (!lastWebSession) {
    return <></>;
  }

  return (
    <div className="session-data p-4">
      <Descriptions
        title={title || "Session Details"}
        bordered
        layout="horizontal"
        column={1}
        size="small"
      >
        <Descriptions.Item label="IP">
          {lastWebSession.ip}
          {!isIPBlocked && (
            <Popconfirm
              placement="leftTop"
              title="Are you sure?"
              onConfirm={() => {
                blockIpAddress(lastWebSession.ip, true);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Block this IP Address">
                <Button
                  type="link"
                  shape="circle"
                  icon={<i className="ri-forbid-line"></i>}
                ></Button>
              </Tooltip>
            </Popconfirm>
          )}
          {isIPBlocked && <Tag>Blacklisted</Tag>}
        </Descriptions.Item>
        <Descriptions.Item label="Device">
          <DeviceIcon device={lastWebSession.device} />{" "}
          <LocationText location={lastWebSession.location} />
        </Descriptions.Item>
        {lastWebSession.device.screenW && (
          <Descriptions.Item label="Screen">
            {lastWebSession.device.screenW} x {lastWebSession.device.screenH}
          </Descriptions.Item>
        )}
        {lastWebSession.device.timezone && (
          <Descriptions.Item label="Timezone">
            {lastWebSession.device.timezone} (
            {dayjs().tz(lastWebSession.device.timezone).format("hh:mm a")})
          </Descriptions.Item>
        )}

        {lastWebSession.metaData.landedPage && (
          <Descriptions.Item label="Landed Page">
            <a
              href={lastWebSession.metaData.landedPage.url}
              target="_blank"
              rel="noreferrer"
            >
              {AddEllipsis(lastWebSession.metaData.landedPage.title, 50)}
            </a>
          </Descriptions.Item>
        )}
        {lastWebSession.metaData.referer && (
          <Descriptions.Item label="Referer">
            <a
              href={lastWebSession.metaData.referer.url}
              target="_blank"
              rel="noreferrer"
            >
              {AddEllipsis(lastWebSession.metaData.referer.title, 50)}
            </a>
          </Descriptions.Item>
        )}
        {lastWebSession.metaData.createdTime && (
          <Descriptions.Item label="First Visit Time">
            {dayjs(lastWebSession.metaData.createdTime).format("LL")}
            {/* .format("dddd, MMMM Do YYYY, h:mm:ss a") */}
          </Descriptions.Item>
        )}
        {lastWebSession.metaData.customUserId && (
          <Descriptions.Item label="Customer ID">
            {lastWebSession.metaData.customUserId}
          </Descriptions.Item>
        )}
        {isAdvancedMode && (
          <Descriptions.Item label="Full Data">
            <i
              className="ri-code-box-line cursor-pointer"
              onClick={() => {
                JSONModal(lastWebSession);
              }}
            />
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  );
};
