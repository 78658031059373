import { ChatInitSource } from "@sdk/conversations/conversations.models";

export const ConversationSourceLabelsMap: Record<ChatInitSource, string> = {
  INBOUND_EMAIL: "Inbound Email",
  OUTBOUND_EMAIL: "Outbound Email",
  FACEBOOK_AD: "Facebook Ads",
  FB_PRIVATE_REPLY: "Facebook Private Replies",
  INVITE: "Website Chat Campaign",
  WEBSITE_CHAT: "Website Chat"
};
