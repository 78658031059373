import { iOrganization } from "@sdk/user-management/user-management.models";
import axios, { AxiosResponse } from "axios";
import { DeepPartial } from "redux";
import { PaginatedOptions, PaginatedResults } from "../utils/paginated-results";
import { iChangeLog } from "./hq.modal";

export class HQService {
  constructor(protected config: { basePath: string; HQToken: string }) {}

  HQConfigure(config: { basePath?: string; HQToken?: string }) {
    Object.assign(this.config, config);
  }

  async HQ_Login(req: { email: string; password: string }) {
    const results: AxiosResponse<{
      accessToken: string;
    }> = await axios.post(this.config.basePath + "/hq/login", req, {});
    return results.data;
  }

  async HQ_getOrganizations(query: any, PaginatedOptions: PaginatedOptions) {
    const results: AxiosResponse<PaginatedResults<any>> = await axios.post(
      this.config.basePath + "/hq/organizations/query",
      { query: query, options: PaginatedOptions },
      {
        headers: {
          Authorization: this.config.HQToken
        }
      }
    );
    return results.data;
  }

  async HQ_editOrganization(
    organizationId: string,
    updatedData: DeepPartial<iOrganization>
  ) {
    const results: AxiosResponse<iOrganization> = await axios.patch(
      this.config.basePath + `/hq/organizations/${organizationId}`,
      updatedData,
      {
        headers: {
          Authorization: this.config.HQToken
        }
      }
    );
    return results.data;
  }

  async HQ_createHQChangeLog(changeLogDetails: iChangeLog) {
    const results: AxiosResponse<PaginatedResults<any>> = await axios.post(
      this.config.basePath + "/change-logs/",
      changeLogDetails,
      {
        headers: {
          Authorization: this.config.HQToken
        }
      }
    );
    return results.data;
  }
  async HQ_getHQChangeLogs(query: any, PaginatedOptions: PaginatedOptions) {
    const results: AxiosResponse<PaginatedResults<
      iChangeLog
    >> = await axios.post(
      this.config.basePath + "/change-logs/query",
      { query: query, options: PaginatedOptions },
      {
        headers: {
          Authorization: this.config.HQToken
        }
      }
    );
    return results.data;
  }
  async HQ_deleteHQChangeLog(id: string) {
    const results: AxiosResponse<iChangeLog> = await axios.delete(
      this.config.basePath + `/change-logs/${id}`,
      {
        headers: {
          Authorization: this.config.HQToken
        },
        params: { confirmation: "DELETE" }
      }
    );
    return results.data;
  }
  async HQ_getHQChangeLogId(id: number) {
    const results: AxiosResponse<iChangeLog> = await axios.get(
      this.config.basePath + `/change-logs/${id}`,
      {
        headers: {
          Authorization: this.config.HQToken
        }
      }
    );
    return results.data;
  }

  async HQ_editHQChangeLog(
    id: number,
    updatedChangeLogDetails: Partial<iChangeLog>
  ) {
    const results: AxiosResponse<iChangeLog> = await axios.patch(
      this.config.basePath + `/change-logs/${id}`,
      updatedChangeLogDetails,
      {
        headers: {
          Authorization: this.config.HQToken
        }
      }
    );
    return results.data;
  }

  async HQ_getUsers(query: any, PaginatedOptions: PaginatedOptions) {
    const results: AxiosResponse<PaginatedResults<any>> = await axios.post(
      this.config.basePath + "/hq/users/query",
      { query: query, options: PaginatedOptions },
      {
        headers: {
          Authorization: this.config.HQToken
        }
      }
    );
    return results.data;
  }
  async HQ_getConnections(query: any, PaginatedOptions: PaginatedOptions) {
    const results: AxiosResponse<PaginatedResults<any>> = await axios.post(
      this.config.basePath + "/hq/connections/query",
      { query: query, options: PaginatedOptions },
      {
        headers: {
          Authorization: this.config.HQToken
        }
      }
    );
    return results.data;
  }

  async HQ_queryFbEventLogs(query: any, PaginatedOptions: PaginatedOptions) {
    const results: AxiosResponse<PaginatedResults<any>> = await axios.post(
      this.config.basePath + "/fb-events-logs/query",
      { query: query, options: PaginatedOptions },
      {
        headers: {
          Authorization: this.config.HQToken
        }
      }
    );
    return results.data;
  }
  async HQ_queryEmailEventLogs(query: any, PaginatedOptions: PaginatedOptions) {
    const results: AxiosResponse<PaginatedResults<any>> = await axios.post(
      this.config.basePath + "/email-events-logs/query",
      { query: query, options: PaginatedOptions },
      {
        headers: {
          Authorization: this.config.HQToken
        }
      }
    );
    return results.data;
  }
  async HQ_getAccessToken(userId: string) {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + "/hq/generate-access-token",
      { userId: userId },
      {
        headers: {
          Authorization: this.config.HQToken
        }
      }
    );
    return results.data.accessToken;
  }
}
