import EditOutlined from "@ant-design/icons/EditOutlined";
import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import { Button, Collapse, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import classnames from "classnames";
import { iFAQStoreState } from "components/modules/connections/channels/chat-widget/components/faq-editor/faq-model";
import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import AddFAQ from "./add-faq.component";
import "./single-editable-faq-collection.scss";
import SingleEditableFAQ from "./single-editable-faq.component";
const { Panel } = Collapse;

const SingleEditableFAQCollection = ({
  faqCollectionId,
  values,
  dispatch,
  index,
}: {
  faqCollectionId: string;
  values: iFAQStoreState;
  dispatch: React.Dispatch<any>;
  index: number;
}) => {
  const [EditFAQCollectionForm] = useForm();
  const [activeKey, setActiveKey] = useState([
    `Collection${faqCollectionId}`,
  ] as string[]);
  const [editFormVisible, setEditFormVisible] = useState(false);
  // const onDragEnd = (result) => {
  //   if (!result.destination) {
  //     return;
  //   }
  //   const items: any = reorder(
  //     values.collections.byIds[faqCollectionId].faqs,
  //     result.source.index,
  //     result.destination.index
  //   );
  //   dispatch({
  //     type: "REORDER_FAQ",
  //     payload: {
  //       collectionId: faqCollectionId,
  //       faqs: items,
  //     },
  //   });
  // };

  if (!values.collections.byIds[faqCollectionId]) {
    return <></>;
  }
  return (
    <Draggable
      key={faqCollectionId}
      draggableId={faqCollectionId}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            // background: snapshot.isDragging ? "lightgreen" : "grey",
            // styles we need to apply on draggables
            ...provided.draggableProps.style,
          }}
        >
          <Collapse
            activeKey={activeKey}
            onChange={() => {}}
            className="p-0 shadow-xl rounded single-editable-faq-collection mb-3"
          >
            <Panel
              header={
                <div className="flex-col justify-center items-center w-full">
                  <div
                    className="flex flex-row justify-center items-center cursor-move w-full"
                    onClick={(e) => e.stopPropagation()}
                    {...provided.dragHandleProps}
                  >
                    <div
                      className={classnames("w-6 flex items-center move-icon")}
                    >
                      <EllipsisOutlined className="text-2xl transform rotate-90  " />
                    </div>
                    <div className="flex flex-row flex-1 items-center h-16">
                      <div className="flex-1 flex flex-col justify-center">
                        <div
                          className={classnames(
                            editFormVisible
                              ? " hidden"
                              : "font-semibold flex flex-row"
                          )}
                        >
                          <i className="ri-folder-2-fill pr-2"></i>{" "}
                          {values.collections.byIds[faqCollectionId].label}
                        </div>

                        <Form
                          layout="vertical"
                          form={EditFAQCollectionForm}
                          initialValues={{
                            FAQCollectionLabel:
                              values.collections.byIds[faqCollectionId].label,
                          }}
                          onFinish={() => {
                            dispatch({
                              type: "EDIT_FAQ_COLLECTION",
                              payload: {
                                collection: {
                                  id: faqCollectionId,
                                  label: EditFAQCollectionForm.getFieldValue(
                                    "FAQCollectionLabel"
                                  ),
                                },
                              },
                            });
                            setEditFormVisible(false);
                          }}
                          className={classnames(
                            editFormVisible ? "" : "hidden"
                          )}
                        >
                          <div className="flex">
                            <Form.Item
                              label=""
                              name="FAQCollectionLabel"
                              rules={[{ required: true }]}
                              className="m-0"
                            >
                              <Input />
                            </Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="ml-2"
                            >
                              Save
                            </Button>
                            <Button
                              type="text"
                              onClick={() => {
                                setEditFormVisible(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                    <div className=" mr-3" onClick={() => {}}>
                      <EditOutlined
                        className="cursor-pointer flex items-center"
                        onClick={() => {
                          setEditFormVisible(!editFormVisible);
                        }}
                      />
                    </div>
                    <div
                      className=" pr-5"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch({
                          type: "REMOVE_FAQ_COLLECTION",
                          payload: {
                            collectionId: faqCollectionId,
                          },
                        });
                      }}
                    >
                      <i className="ri-delete-bin-line cursor-pointer"></i>
                    </div>
                  </div>

                  {false && (
                    <div className="show-faqs w-full flex-row flex justify-center items-center view-more-toggle-container p-2">
                      <div
                        className="cursor-pointer font-bold flex flex-row justify-center items-center"
                        onClick={() => {
                          setActiveKey(
                            activeKey.length === 0
                              ? [`Collection${faqCollectionId}`]
                              : []
                          );
                        }}
                      >
                        {activeKey.length === 0 ? (
                          <>
                            <i className="ri-eye-line pr-2"></i>
                            {`View FAQs (${values.collections.byIds[faqCollectionId].faqs.length})`}
                          </>
                        ) : (
                          <>
                            <i className="ri-eye-off-line pr-2"></i>
                            Hide FAQs
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              }
              key={`Collection${faqCollectionId}`}
              showArrow={false}
            >
              <Droppable
                droppableId={faqCollectionId}
                type={`droppableSubItem`}
              >
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      minHeight: 20,
                      // background: snapshot.isDraggingOver
                      //   ? "lightblue"
                      //   : "lightgrey",
                    }}
                  >
                    {values.collections.byIds[faqCollectionId].faqs.map(
                      (faqId, faqIndex) => (
                        <div>
                          <SingleEditableFAQ
                            faqCollectionId={faqCollectionId}
                            faqId={faqId}
                            values={values}
                            dispatch={dispatch}
                            key={faqId}
                            index={faqIndex}
                          />
                        </div>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              <AddFAQ dispatch={dispatch} collectionId={faqCollectionId} />
            </Panel>
          </Collapse>
        </div>
      )}
    </Draggable>
  );
};
export default SingleEditableFAQCollection;
