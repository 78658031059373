import { iConnection } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, message, Radio, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  AddConnectionLayout,
  AddConnectionModalContainer,
  AddConnectionStepsContainer,
  IntegrationSuccessfulScreen,
  StepContainer,
} from "components/modules/connections/add-connection-modal-templates";
import { generateDefaultConnectionConfig } from "components/modules/connections/common-connection-settings/default-connection-config-generator";
import { ContactAccountManagerCard } from "components/modules/organization-management/contact-account-manager-card/contact-account-manager-card";
import { GlobalConfig } from "config";
import { push } from "connected-react-router";
import { useCallback, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { TextLoop } from "react-text-loop-next";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { ConfigureInbuiltMailServer } from "../configure-inbuilt-mail-server/configure-inbuilt-mail-server";
import { ConfigureCustomSMTP } from "../configure-smtp-connection/configure-smtp-connection";
import { ConnectGmailToConnection } from "../connect-gmail-to-connection/connect-gmail-to-connection";
import { ConfigureEmailConnectionForm } from "../forms/setup-email-connection-form";

export const AddEmailConnectionModal = ({
  visible,
  onChangeVisibility,
  isPrivate,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  isPrivate?: boolean;
}) => {
  const [primaryEmailBoxForm] = useForm();

  const [setupType, setSelectedSetupType] = useState("CLICK_CONNECTOR_MAILBOX");
  const [currentStep, setCurrentStep] = useState(0);
  const [createdConnection, setCreatedConnection] = useState("");

  const currentUserId = useSelector(selectCurrentUserId);

  const onReconfigureConnection = (connectionId: string) => {};

  const { doAction: createPrimaryInbox, isProcessing: isProcessingCreation } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (val) =>
          SDK.connections.create(val).then((d) => {
            setCreatedConnection(d.id!);
            setCurrentStep(1);
          }),
        successMessage: "Primary Inbox has been created",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const resetModal = useCallback(() => {
    primaryEmailBoxForm.resetFields();
    setCurrentStep(0);
    setCreatedConnection("");
  }, [primaryEmailBoxForm]);

  useEffectWhen(
    () => {
      if (!visible) {
        resetModal();
      }
    },
    [resetModal, visible],
    [visible],
  );

  const dispatch = useDispatch();

  const store = useStore();

  return (
    <>
      <AddConnectionModalContainer
        title="Connect Email"
        icon={<i className="ri-mail-unread-line"></i>}
        isVisible={visible}
        onChangeVisibility={onChangeVisibility}
      >
        <AddConnectionLayout
          currentStep={currentStep}
          totalSteps={3}
          stepsWithSidebars={[0, 1, 2]}
          topBar={
            <AddConnectionStepsContainer
              currentStep={currentStep}
              steps={[
                {
                  label: "Primary Mailbox",
                  icon: <i className="ri-number-1"></i>,
                },
                {
                  label: "Next Steps",
                  icon: <i className="ri-number-2"></i>,
                },
                {
                  label: "Connect Your Email/Domain",
                  icon: <i className="ri-number-3"></i>,
                },
                {
                  label: "Done",
                  icon: <i className="ri-check-line"></i>,
                },
              ]}
            />
          }
          sidebarImage={
            <div className="text-10xl opacity-50">
              <svg
                viewBox="0 0 24 24"
                style={{ width: "10rem", height: "10rem" }}
              >
                <path
                  fill="currentColor"
                  d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z"
                />
              </svg>
            </div>
          }
        >
          <StepContainer stepNumber={0} currentStep={currentStep}>
            <div className="text-gray-600 dark:text-gray-400 mode_transition">
              Create a team inbox and manage enquiries and tickets with Click
              Connector's inbox
            </div>
            <div className="text-gray-600  mb-6">
              Example:{" "}
              <TextLoop interval={2000}>
                {["support", "sales", "reservations", "info"].map((item) => (
                  <div
                    className="text-gray-600"
                    key={item}
                    style={{ width: 300 }}
                  >
                    {item}@abc.com
                  </div>
                ))}
              </TextLoop>
            </div>
            <ConfigureEmailConnectionForm formInstance={primaryEmailBoxForm} />

            <div className="button-container my-4 flex flex-row justify-end items-center w-full">
              <ContactAccountManagerCard
                type="BUTTON"
                buttonType="dashed"
                buttonSize="large"
                text="Need Help?"
                buttonClassName="ask"
              />
              <Button
                type="primary"
                loading={isProcessingCreation}
                icon={<i className="ri-arrow-right-circle-fill"></i>}
                onClick={async () => {
                  const { pageId } = primaryEmailBoxForm.getFieldsValue();
                  try {
                    await primaryEmailBoxForm.validateFields();
                    const formValue = primaryEmailBoxForm.getFieldsValue();

                    const configData = generateDefaultConnectionConfig(
                      "EMAIL",
                      store,
                    );

                    const email = `${formValue.email}@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN}`;
                    const label = isPrivate ? `${email} - Personal` : email;
                    const userGroups = isPrivate ? [currentUserId] : [];
                    const additionalDataProps = isPrivate
                      ? {
                          isPersonal: true,
                          ownerId: currentUserId,
                          filterOnlyContactConversations: true,
                        }
                      : {};
                    // Todo - Handle Private
                    const connectionData: Partial<iConnection> = {
                      type: "EMAIL",
                      label: label,
                      userGroups,
                      data: {
                        senderName: formValue.senderName,
                        email: email,
                        ...configData,
                        ...additionalDataProps,
                      },
                    };

                    createPrimaryInbox(connectionData);
                  } catch (e) {
                    if (!pageId) {
                      message.warning("Please check your input");
                      return;
                    }
                  }
                }}
                size="large"
              >
                Continue
              </Button>
            </div>
          </StepContainer>

          <StepContainer stepNumber={1} currentStep={currentStep}>
            <div className="font-bold mb-4">
              How would you like to set up your Inbox?
            </div>
            <Radio.Group
              defaultValue={"CLICK_CONNECTOR_MAILBOX"}
              className="w-full"
              value={setupType}
              onChange={(e) => setSelectedSetupType(e.target.value)}
            >
              <Space direction="vertical" className="w-full">
                <Radio
                  value="CLICK_CONNECTOR_MAILBOX"
                  className="border border-gray-200 rounded-lg p-4 w-full font-bold"
                >
                  Use selected Click Connector Email
                  <div className="text-gray-600 font-normal">
                    You have configured -{" "}
                    {`${primaryEmailBoxForm.getFieldValue("email")}@${
                      GlobalConfig.HELP_DESK_EMAIL_DOMAIN
                    }`}
                  </div>
                </Radio>
                <Radio
                  value="INBUILT_SERVER"
                  className="border border-gray-200 rounded-lg p-4 w-full font-bold"
                >
                  Use your own domain with Click Connector Partner Email Servers
                  <div className="text-gray-600 font-normal">
                    Make sure you can modify DNS records for the domain you have
                    selected
                  </div>
                </Radio>
                <Radio
                  value="GMAIL"
                  className="border border-gray-200 rounded-lg p-4 w-full font-bold"
                >
                  Connect your Gmail (Beta)
                  <div className="text-gray-600 font-normal">
                    Simple Authentication (Recommended)
                  </div>
                </Radio>
                <Radio
                  value="SMTP_GMAIL"
                  className="border border-gray-200 rounded-lg p-4 w-full font-bold"
                >
                  Connect your Gmail Through SMTP
                  <div className="text-gray-600 font-normal">
                    You'll need to create gmail application password to connect
                    (Not Recommended)
                  </div>
                </Radio>

                <Radio
                  value="SMTP_AWS"
                  className="border border-gray-200 rounded-lg p-4 w-full font-bold"
                >
                  Connect AWS SES
                  <div className="text-gray-600 font-normal">
                    You'll need SMTP details from your AWS SES Account
                  </div>
                </Radio>
                <Radio
                  value="SMTP_CUSTOM"
                  className="border border-gray-200 rounded-lg p-4 w-full font-bold"
                >
                  Connect with custom SMTP Configurations
                  <div className="text-gray-600 font-normal">
                    You'll need SMTP Server address, port, SMTP username and
                    SMTP password to continue
                  </div>
                </Radio>
              </Space>
            </Radio.Group>

            <div className="button-container my-4 flex flex-row justify-end items-center w-full">
              <ContactAccountManagerCard
                type="BUTTON"
                buttonType="dashed"
                buttonSize="large"
                text="Need Help?"
                buttonClassName="ask"
              />
              <Button
                type="primary"
                icon={<i className="ri-arrow-right-circle-fill"></i>}
                onClick={() => {
                  if (setupType === "CLICK_CONNECTOR_MAILBOX") {
                    setCurrentStep(3);
                  } else {
                    setCurrentStep(2);
                  }
                }}
                size="large"
              >
                Continue
              </Button>
            </div>
          </StepContainer>

          <StepContainer stepNumber={2} currentStep={currentStep}>
            <div className="button-container mb-4 flex flex-row justify-start items-center w-full">
              <Button
                type="primary"
                icon={<i className="ri-arrow-left-circle-fill"></i>}
                onClick={() => setCurrentStep(1)}
              >
                Go back
              </Button>
            </div>
            {setupType === "INBUILT_SERVER" && (
              <ConfigureInbuiltMailServer
                connectionId={createdConnection}
                onMailboxRead={() => setCurrentStep(3)}
              />
            )}
            {setupType.includes("SMTP") && (
              <>
                {setupType.replace("SMTP_", "") !== "GMAIL" ? (
                  <div className="font-bold my-8 text-xl">
                    Configure SMTP Details
                  </div>
                ) : (
                  <div className="font-bold my-8 text-xl">
                    Connect Your Gmail
                  </div>
                )}

                <ConfigureCustomSMTP
                  connectionId={createdConnection}
                  onMailboxRead={() => setCurrentStep(3)}
                  smtpType={setupType.replace("SMTP_", "")}
                />
              </>
            )}
            {setupType === "GMAIL" && (
              <>
                <ConnectGmailToConnection
                  connectionId={createdConnection}
                  onMailboxRead={() => setCurrentStep(3)}
                />
              </>
            )}
          </StepContainer>

          <StepContainer stepNumber={3} currentStep={currentStep}>
            <IntegrationSuccessfulScreen
              onManageConnection={() => {
                // Todo: Support Private Connection
                dispatch(
                  push(`/configurations/connections/${createdConnection}/`),
                );
              }}
              onHideWizard={() => onChangeVisibility(false)}
              title="Your Mailbox is Ready!"
              subTitle="Now you will be able to reply to emails from Click Connector"
            ></IntegrationSuccessfulScreen>
          </StepContainer>
        </AddConnectionLayout>
      </AddConnectionModalContainer>
    </>
  );
};
