import { iConnectorConversationActionData } from "@sdk/chat-connectors/chat-connectors.models";
import { Menu } from "antd";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";

import { IconRender } from "components/modules/conversations/components/manage-chat-connectors/icon-selector";
import { useChatConnectorAPIAction } from "./user-chat-connector-api";

export const SimpleChatConnectorAction = ({
  id,
  actionData,
  conversationId,
}: {
  id;
  actionData: iConnectorConversationActionData;
  conversationId: string;
}) => {
  const { callAPI, loading } = useChatConnectorAPIAction(
    conversationId,
    actionData.apiCall,
    true,
  );

  return (
    <Menu.Item
      key={`CONNECTOR_${id}`}
      onClick={() => callAPI()}
      icon={<IconRender icon={actionData.actionIcon} />}
    >
      {actionData.actionLabel || "Action"}{" "}
      {loading && <LoadingIndicatorWithSpin fontSize={"inherit"} />}
    </Menu.Item>
  );
};
