import { Modal, Segmented, Tabs } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useLayoutEffect, useState } from "react";
import { ActiveBugs } from "./components/active-bugs/active-bugs";
import { FeatureRequests } from "./components/feature-requests/feature-requests";
import { ReleaseNotes } from "./components/release-notes";
import { RoadMap } from "./components/road-map/road-map";
import "./feature-requests.scss";
const { TabPane } = Tabs;

const segments = [
  {
    value: "FEATURE_REQUESTS",
    label: (
      <div>
        <i className="ri-lightbulb-flash-line"></i> Feature Requests
      </div>
    ),
  },
  {
    value: "ROAD_MAP",
    label: (
      <div>
        <i className="ri-map-2-line"></i> Road map
      </div>
    ),
  },
  {
    value: "BUG_BOARD",
    label: (
      <div>
        <i className="ri-bug-line"></i> Bug Board
      </div>
    ),
  },
  {
    value: "RELEASE_NOTES",
    label: (
      <div>
        <i className="ri-sticky-note-line"></i> Release Notes
      </div>
    ),
  },
];

const widthMap = {
  ROAD_MAP: 1200,
  FEATURE_REQUESTS: 800,
  RELEASE_NOTES: 800,
  BUG_BOARD: 800,
};

export const FeatureRequestsModal = ({
  visible,
  onChangeVisibility,
  initialTab,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  initialTab: "FEATURE_REQUESTS" | "ROAD_MAP" | "RELEASE_NOTES" | "BUG_BOARD";
}) => {
  const [activeTab, setActiveTab] = useState<
    "FEATURE_REQUESTS" | "ROAD_MAP" | "RELEASE_NOTES" | "BUG_BOARD"
  >(initialTab || "FEATURE_REQUESTS");

  useLayoutEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);

  return (
    <Modal
      title={
        <ModalTitle
          title="Product RoadMap and Feature Requests"
          icon={<i className="ri-guide-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={widthMap[activeTab]}
      className="feature-requests-modal mode_transition"
      data-click-context="Feature Requests Modal"
      destroyOnClose
    >
      <div>
        <div className="view-selector flex flex-row justify-center items-center w-full mb-8">
          <Segmented
            options={segments}
            value={activeTab}
            onChange={(e) => setActiveTab((e as any).toString())}
          />
        </div>
        {activeTab === "ROAD_MAP" && <RoadMap />}
        {activeTab === "FEATURE_REQUESTS" && <FeatureRequests />}
        {activeTab === "RELEASE_NOTES" && <ReleaseNotes />}
        {activeTab === "BUG_BOARD" && <ActiveBugs />}
      </div>
      <DarkModeBg />
    </Modal>
  );
};
