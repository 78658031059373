import React, { useMemo } from "react";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once
import { useCombinedRefs } from "utils/hooks/use-multiple-refs";

// https://github.com/bvaughn/react-virtualized/blob/master/docs/creatingAnInfiniteLoadingList.md

export const VirtualizedInfiniteList = <T extends unknown>({
  renderItem: _renderItem,
  data,
  emptyText = "No Data",
  hasMore = false,
  isLoading = false,
  onLoadMore,
  listRef,
  rowHeight,
  loadingIndicator
}: {
  renderItem: (item: T, index: number) => React.ReactNode;
  data: T[];
  emptyText?: string | React.ReactNode;
  hasMore?: boolean;
  isLoading?: boolean;
  onLoadMore: () => any;
  listRef?: any;
  rowHeight: number;
  loadingIndicator: React.ReactNode;
}) => {
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const rowCount = hasMore ? data.length + 1 : data.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreRows = isLoading ? () => {} : onLoadMore;

  const { rowRenderer, isRowLoaded } = useMemo(() => {
    // Every row is loaded except for our loading indicator row.
    const isRowLoaded = ({ index }) => !hasMore || index < data.length;

    // Render a list item or a loading indicator.
    const rowRenderer = ({ index, key, style }) => {
      if (!isRowLoaded({ index })) {
        return (
          <div
            style={{ height: rowHeight, ...(style || {}) }}
            key={key}
            className="flex flex-row justify-center items-center"
          >
            {loadingIndicator || "Loading..."}
          </div>
        );
      }

      const item = data[index];
      return (
        <div style={{ height: rowHeight, ...(style || {}) }} key={key}>
          {_renderItem(item, index)}
        </div>
      );
    };
    return { rowRenderer, isRowLoaded };
  }, [loadingIndicator, hasMore, rowHeight, _renderItem, data]);
  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={rowCount}
    >
      {({ onRowsRendered, registerChild }) => (
        <VirtualizedListWithRef
          onRowsRendered={onRowsRendered}
          registerChild={registerChild}
          rowRenderer={rowRenderer}
          listRef={listRef}
          rowHeight={rowHeight}
          rowCount={rowCount}
        />
      )}
    </InfiniteLoader>
  );
};

const VirtualizedListWithRef = ({
  onRowsRendered,
  registerChild,
  listRef,
  rowRenderer,
  rowHeight,
  rowCount
}) => {
  const ref = useCombinedRefs(registerChild, listRef);
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          ref={ref}
          width={width}
          height={height}
          onRowsRendered={onRowsRendered}
          rowRenderer={rowRenderer}
          // overscanRowCount={2}
          rowCount={rowCount}
          rowHeight={rowHeight}
        />
      )}
    </AutoSizer>
  );
};
