import { iOrganizationDPA } from "@sdk/user-management/user-management.models";
import dayjs from "dayjs";

export const DataProcessingAgreement = ({
  data,
}: {
  data: iOrganizationDPA;
}) => {
  return (
    <div className="">
      <h3>&nbsp;</h3>
      <h3>Data Processing Agreement — ClickConnector Inc</h3>
      <p>
        This Data Processing Agreement (“<strong>Agreement</strong>“) forms part
        of the Contract for
        <br />
        Services (“<strong>Principal Agreement</strong>“) between
        <br />
        {data.companyName}
        <br />
        {data.addressLine1}
        <br />
        {data.city}, {data.postalCode}
        <br />
        {data.country}
        <br />
        (the “<strong>Company</strong>”) and
        <br />
        Click Connector Inc,
        <br />
        651 N Broad St, Suite 206
        <br />
        Middletown. 19709. New Castle, USA
        <br />
        (the “Data Processor”)
        <br />
        (together as the “<strong>Parties</strong>”)
      </p>
      <p>WHEREAS</p>
      <p>(A) The Company acts as a Data Controller.</p>
      <p>
        (B) The Company wishes to subcontract certain Services, which imply the
        processing of personal data, to the Data Processor.
      </p>
      <p>
        (C) The Parties seek to implement a data processing agreement that
        complies with the requirements of the current legal framework in
        relation to data processing and with the Regulation (EU) 2016/679 of the
        European Parliament and of the Council of 27 April 2016 on the
        protection of natural persons with regard to the processing of personal
        data and on the free movement of such data, and repealing Directive
        95/46/EC (General Data Protection Regulation).
      </p>
      <p>(D) The Parties wish to lay down their rights and obligations.</p>
      <p>IT IS AGREED AS FOLLOWS:</p>
      <p>1. Definitions and Interpretation</p>
      <p>
        1.1 Unless otherwise defined herein, capitalized terms and expressions
        used in this Agreement shall have the following meaning:
      </p>
      <p>
        1.1.1 “Agreement” means this Data Processing Agreement and all
        Schedules;
      </p>
      <p>
        1.1.2 “Company Personal Data” means any Personal Data Processed by a
        Contracted Processor on behalf of Company pursuant to or in connection
        with the Principal Agreement;
      </p>
      <p>1.1.3 “Contracted Processor” means a Subprocessor;</p>
      <p>
        1.1.4 “Data Protection Laws” means EU Data Protection Laws and, to the
        extent applicable, the data protection or privacy laws of any other
        country;
      </p>
      <p>1.1.5 “EEA” means the European Economic Area;</p>
      <p>
        1.1.6 “EU Data Protection Laws” means EU Directive 95/46/EC, as
        transposed into domestic legislation of each Member State and as
        amended, replaced or superseded from time to time, including by the GDPR
        and laws implementing or supplementing the GDPR;
      </p>
      <p>1.1.7 “GDPR” means EU General Data Protection Regulation 2016/679;</p>
      <p>1.1.8 “Data Transfer” means:</p>
      <p>
        1.1.8.1 a transfer of Company Personal Data from the Company to a
        Contracted Processor; or
      </p>
      <p>
        1.1.8.2 an onward transfer of Company Personal Data from a Contracted
        Processor to a Subcontracted Processor, or between two establishments of
        a Contracted Processor, in each case, where such transfer would be
        prohibited by Data Protection Laws (or by the terms of data transfer
        agreements put in place to address the data transfer restrictions of
        Data Protection Laws);
      </p>
      <p>
        1.1.9 “Services” means the Customer Support Platform and associated
        services the Company provides.
      </p>
      <p>
        1.1.10 “Subprocessor” means any person appointed by or on behalf of
        Processor to process Personal Data on behalf of the Company in
        connection with the Agreement.
      </p>
      <p>
        1.2 The terms, “Commission”, “Controller”, “Data Subject”, “Member
        State”, “Personal Data”, “Personal Data Breach”, “Processing” and
        “Supervisory Authority” shall have the same meaning as in the GDPR, and
        their cognate terms shall be construed accordingly.
      </p>
      <p>2. Processing of Company Personal Data</p>
      <p>2.1 Processor shall:</p>
      <p>
        2.1.1 comply with all applicable Data Protection Laws in the Processing
        of Company Personal Data; and
      </p>
      <p>
        2.1.2 not Process Company Personal Data other than on the relevant
        Company’s documented instructions.
      </p>
      <p>
        2.2 The Company instructs Processor to process Company Personal Data.
      </p>
      <p>
        3. Processor Personnel
        <br />
        Processor shall take reasonable steps to ensure the reliability of any
        employee, agent or contractor of any Contracted Processor who may have
        access to the Company Personal Data, ensuring in each case that access
        is strictly limited to those individuals who need to know / access the
        relevant Company Personal Data, as strictly necessary for the purposes
        of the Principal Agreement, and to comply with Applicable Laws in the
        context of that individual’s duties to the Contracted Processor,
        ensuring that all such individuals are subject to confidentiality
        undertakings or professional or statutory obligations of
        confidentiality.
      </p>
      <p>4. Security</p>
      <p>
        4.1 Taking into account the state of the art, the costs of
        implementation and the nature, scope, context and purposes of Processing
        as well as the risk of varying likelihood and severity for the rights
        and freedoms of natural persons, Processor shall in relation to the
        Company Personal Data implement appropriate technical and organizational
        measures to ensure a level of security appropriate to that risk,
        including, as appropriate, the measures referred to in Article 32(1) of
        the GDPR.
      </p>
      <p>
        4.2 In assessing the appropriate level of security, Processor shall take
        account in particular of the risks that are presented by Processing, in
        particular from a Personal Data Breach.
      </p>
      <p>5. Subprocessing</p>
      <p>
        5.1 Processor shall not appoint (or disclose any Company Personal Data
        to) any Subprocessor unless required or authorized by the Company.
      </p>
      <p>6. Data Subject Rights</p>
      <p>
        6.1 Taking into account the nature of the Processing, Processor shall
        assist the Company by implementing appropriate technical and
        organisational measures, insofar as this is possible, for the fulfilment
        of the Company obligations, as reasonably understood by Company, to
        respond to requests to exercise Data Subject rights under the Data
        Protection Laws.
      </p>
      <p>6.2 Processor shall:</p>
      <p>
        6.2.1 promptly notify Company if it receives a request from a Data
        Subject under any Data Protection Law in respect of Company Personal
        Data; and
      </p>
      <p>
        6.2.2 ensure that it does not respond to that request except on the
        documented instructions of Company or as required by Applicable Laws to
        which the Processor is subject, in which case Processor shall to the
        extent permitted by Applicable Laws inform Company of that legal
        requirement before the Contracted Processor responds to the request.
      </p>
      <p>7. Personal Data Breach</p>
      <p>
        7.1 Processor shall notify Company without undue delay upon Processor
        becoming aware of a Personal Data Breach affecting Company Personal
        Data, providing Company with sufficient information to allow the Company
        to meet any obligations to report or inform Data Subjects of the
        Personal Data Breach under the Data Protection Laws.
      </p>
      <p>
        7.2 Processor shall co-operate with the Company and take reasonable
        commercial steps as are directed by Company to assist in the
        investigation, mitigation and remediation of each such Personal Data
        Breach.
      </p>
      <p>
        8. Data Protection Impact Assessment and Prior Consultation Processor
        shall provide reasonable assistance to the Company with any data
        protection impact assessments, and prior consultations with Supervising
        Authorities or other competent data privacy authorities, which Company
        reasonably considers to be required by article 35 or 36 of the GDPR or
        equivalent provisions of any other Data Protection Law, in each case
        solely in relation to Processing of Company Personal Data by, and taking
        into account the nature of the Processing and information available to,
        the Contracted Processors.
      </p>
      <p>9. Deletion or return of Company Personal Data</p>
      <p>
        9.1 Subject to this section 9 Processor shall promptly and in any event
        within
      </p>
      <p>
        10 business days of the date of cessation of any Services involving the
        Processing of Company Personal Data (the “Cessation Date”), delete and
        procure the deletion of all copies of those Company Personal Data.
      </p>
      <p>10. Audit rights</p>
      <p>
        10.1 Subject to this section 10, Processor shall make available to the
        Company on request all information necessary to demonstrate compliance
        with this Agreement, and shall allow for and contribute to audits,
        including inspections, by the Company or an auditor mandated by the
        Company in relation to the Processing of the Company Personal Data by
        the Contracted Processors.
      </p>
      <p>
        10.2 Information and audit rights of the Company only arise under
        section 10.1 to the extent that the Agreement does not otherwise give
        them information and audit rights meeting the relevant requirements of
        Data Protection Law.
      </p>
      <p>11. Data Transfer</p>
      <p>
        11.1 The Processor may not transfer or authorize the transfer of Data to
        countries outside the EU and/or the European Economic Area (EEA) without
        the prior written consent of the Company. If personal data processed
        under this Agreement is transferred from a country within the European
        Economic Area to a country outside the European Economic Area, the
        Parties shall ensure that the personal data are adequately protected. To
        achieve this, the Parties shall, unless agreed otherwise, rely on EU
        approved standard contractual clauses for the transfer of personal data.
      </p>
      <p>12. General Terms</p>
      <p>
        12.1 Confidentiality. Each Party must keep this Agreement and
        information it receives about the other Party and its business in
        connection with this Agreement (“Confidential Information”) confidential
        and must not use or disclose that Confidential Information without the
        prior written consent of the other Party except to the extent that:
        <br />
        (a) disclosure is required by law;
        <br />
        (b) the relevant information is already in the public domain.
      </p>
      <p>
        12.2 Notices. All notices and communications given under this Agreement
        must be in writing and will be delivered personally, sent by post or
        sent by email to the address or email address set out in the heading of
        this Agreement at such other address as notified from time to time by
        the Parties changing address.
      </p>
      <p>13. Governing Law and Jurisdiction</p>
      <p>
        13.1 This Agreement is governed by the laws of United States of America.
      </p>
      <p>
        13.2 Any dispute arising in connection with this Agreement, which the
        Parties will not be able to resolve amicably, will be submitted to the
        exclusive jurisdiction of the courts of Delaware, USA.
      </p>
      <p>
        IN WITNESS WHEREOF, this Agreement is entered into with effect from the
        date first set out below.
      </p>
      <p>
        {data.companyName}
        <br />
        Signature {data.signed ? `Sgd.` : `_____________________`}
        <br />
        Name: {data.signerName}
        <br />
        Title: {data.signerTitle}
        <br />
        Date Signed:{" "}
        {data.signedTimestamp
          ? `${dayjs(data.signedTimestamp).format("DD-MM-YYYY")}`
          : `_____________________`}
        <br />
        Processor Company
        <br />
        Signature Sgd.
        <br />
        Name Ruban Nanthagopal
        <br />
        Title CEO
        <br />
        Date Signed{" "}
        {data.signedTimestamp
          ? `${dayjs(data.signedTimestamp).format("DD-MM-YYYY")}`
          : `_____________________`}
      </p>
    </div>
  );
};
