import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import { BadgesController } from "badge-controller";
import dayjs from "dayjs";
import { ScheduleReminder } from "modal-registry";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { loadAllReminders } from "store/modules/reminders/reminders.helpers";
import { selectRemindersOfConversation } from "store/modules/reminders/reminders.selectors";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const ChatViewReminder = ({
  conversationId
}: {
  conversationId: string;
}) => {
  const { triggerTempModal } = useModalPanels();

  const reminderDates = useMemo(() => {
    const day1 = dayjs()
      .add(1, "day")
      .startOf("day")
      .hour(9);
    const day2 = dayjs()
      .add(2, "day")
      .startOf("day")
      .hour(9);
    const day3 = dayjs()
      .add(3, "day")
      .startOf("day")
      .hour(9);
    const dates = [
      // Tomorrow at 9am
      {
        timestamp: day1.valueOf(),
        label: "Tomorrow at 9am"
      },
      // Day After Tomorrow at 9am
      {
        timestamp: day2.valueOf(),
        label: day2.format("ddd [at] LT")
      },
      // Next Day at 9am
      {
        timestamp: day3.valueOf(),
        label: day3.format("ddd [at] LT")
      }
    ];
    return dates;
  }, []);

  const {
    doAction: createReminder,
    isProcessing: isActionProcessing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.reminders.create(values).then(d => {
          BadgesController.processBadgeEvent("use-reminders");
          return d;
        }),
      successMessage: "Reminder has been scheduled",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const currentUserId = useSelector(selectCurrentUserId);

  const { state: allReminders, retry } = useQueryWithStore(
    selectRemindersOfConversation(conversationId),
    loadAllReminders,
    []
  );

  const { doAction: removeReminder } = useSDKActionWithDeps(
    () => ({
      action: SDK => id => SDK.reminders.deleteById(id).then(() => retry()),
      successMessage: "Reminder has been removed",
      failureMessage: "Something went wrong"
    }),
    [retry]
  );

  return (
    <>
      {allReminders.length === 0 && (
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu data-click-context="Schedule Reminder Dropdown Menu">
              {reminderDates.map(item => (
                <Menu.Item
                  key={item.timestamp}
                  onClick={() => {
                    createReminder({
                      userId: currentUserId,
                      text: "Reminder",
                      type: "CONVERSATION",
                      entityId: conversationId,
                      entityType: "CONVERSATION",
                      data: {},
                      timestamp: item.timestamp
                    });
                  }}
                >
                  {item.label}
                </Menu.Item>
              ))}
              <Menu.Divider />
              <Menu.Item
                key={"CUSTOM"}
                icon={<i className="ri-calendar-event-line"></i>}
                onClick={() => {
                  triggerTempModal(ScheduleReminder, {
                    entityId: conversationId!,
                    entityType: "CONVERSATION"
                  });
                }}
              >
                Select Date and Time
              </Menu.Item>
            </Menu>
          }
        >
          <Tooltip title="Add Reminder">
            <Button
              type="text"
              icon={<i className="ri-calendar-check-line"></i>}
            ></Button>
          </Tooltip>
        </Dropdown>
      )}

      {allReminders.length > 0 && (
        <>
          <div className="">
            {allReminders.map(item => (
              <Tooltip key={item.id} title="Remove Reminder">
                <Button
                  icon={<i className="ri-calendar-check-line"></i>}
                  type="text"
                  onClick={() => removeReminder(item.id)}
                >
                  {dayjs(item.timestamp).format("ll LT")}
                </Button>
              </Tooltip>
            ))}
          </div>
        </>
      )}
    </>
  );
};
