import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Form, message, Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectChatBotById } from "store/modules/chat-bots/chat-bots.selectors";
import { AddEditChatBotForm } from "./add-edit-chat-bot-form";

export const EditChatBotModal = ({
  chatBotId,
  visible,
  onChangeVisibility,
  onEdited,
}: {
  chatBotId: string;
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onEdited?: () => any;
}) => {
  const [form] = Form.useForm();

  const chatBot = useSelector(selectChatBotById(chatBotId));

  useEffect(() => {
    form.setFieldsValue(chatBot);
  }, [chatBot, form]);

  const {
    doAction: onEditChatBot,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.chatBots
          .editById(chatBotId, {
            ...values,
          })
          .then((res) => {
            onEdited && onEdited();
            onChangeVisibility(false);
            form.resetFields();
          }),
      successMessage: "Chatbot has been edited",
      failureMessage: "Something went wrong",
    }),
    [chatBotId, form, onChangeVisibility, onEdited],
  );

  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      onEditChatBot(form.getFieldsValue());
    } catch (e) {
      message.error("Please check your input");
    }
  }, [form, onEditChatBot]);

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-folder-add-line"></i>}
          title="Edit Chatbot"
        />
      }
      open={visible}
      onOk={onSubmit}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save"
      data-click-context="Edit Chatbot Modal"
      destroyOnClose={true}
      className="w-screen max-w-3xl"
    >
      <AddEditChatBotForm form={form} onSubmit={onSubmit} />
      <DarkModeBg />
    </Modal>
  );
};
