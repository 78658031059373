import { Table } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import dayjs from "dayjs";
import { iPassengerDetails } from "../itineries-model";

export const PassengerDetailsBlock = ({
  passengers
}: {
  passengers: iPassengerDetails[];
}) => {
  const columns = [
    {
      title: "Title",
      dataIndex: "title"
    },
    {
      title: "Name",
      dataIndex: "firstName"
    },
    {
      title: "Surname",
      dataIndex: "surName"
    },
    {
      title: "Date Of Birth",
      dataIndex: "dateOfBirth",
      render: (dateOfBirth: number) => {
        return <>{dayjs(dateOfBirth).format("DD MMM, YYYY")}</>;
      }
    }
  ];

  return (
    <div className="w-full">
      {/* <table className="simple-table-style w-full">
        <thead>
          <th>Title</th>
          <th>First Name</th>
          <th>Surname</th>
          <th>Date of Birth</th>
        </thead>
        <tbody>
          {passengers.map((item, index) => (
            <tr key={index}>
              <td>{item.title}</td>
              <td>{item.firstName}</td>
              <td>{item.surName}</td>
              <td>{dayjs(item.dateOfBirth).format("DD MMM, YYYY")} </td>
            </tr>
          ))}
        </tbody>
      </table> */}
      <Table
        columns={columns}
        dataSource={passengers}
        pagination={false}
        rowKey="id"
        className="mb-16 w-full"
        locale={{
          emptyText: <EmptyData text="No data" icon="ri-file-history-line" />
        }}
      />
    </div>
  );
};
