import { ContactAvatar } from "components/common/avatar/client-avatar";
import dayjs from "dayjs";
import { loadContactById } from "store/modules/contacts/contacts.helpers";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { useQueryWithStore } from "store/store.hooks";
import "./contact-card.scss";

export const ContactCard = ({ contactId }: { contactId: string }) => {
  const { state: contact } = useQueryWithStore(
    selectContactById(contactId),
    loadContactById(contactId)(),
    [contactId],
    !contactId
  );

  if (!contact) {
    return <></>;
  }

  return (
    <>
      <div className="flex flex-col w-full contact-profile-card">
        <div className="avatar-section flex flex-row justify-center items-center mt-8 mb-8">
          <div className="flex flex-col justify-center items-center">
            <div className="edit-user-avatar">
              <ContactAvatar contactId={contactId} size={80} />
            </div>
            <div className="font-bold text-2xl">
              {contact?.data.firstName} {contact.data.lastName}
            </div>
          </div>
        </div>
        <div className="info-section">
          <div className="flex flex-row justify-center items-center px-5 mt-4">
            <div className="text text-gray-600 dark:text-gray-400 mode_transition">
              Record created on{" "}
              {dayjs(contact.metaData.createdTime).format("LL")}
              {/* "DD-MM-YYYY" */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
