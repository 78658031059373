import { useState } from "react";

export const usePagination = <T>(
  data: T[],
  {
    pageSize = 20,
    currentPage = 1
  }: {
    pageSize?: number;
    currentPage?: number;
  }
) => {
  const totalItems = data.length;
  const [paginationState, setPaginationState] = useState({
    pageSize: pageSize,
    currentPage: currentPage
  });
  const itemsInView = data.slice(
    (paginationState.currentPage - 1) * paginationState.pageSize,
    (paginationState.currentPage - 1) * paginationState.pageSize +
      paginationState.pageSize
  );
  return { paginationState, setPaginationState, itemsInView, totalItems };
};
