import { BuiltInControlType, ExtendControlType } from "braft-editor";
import "braft-editor/dist/index.css";

import { mdiCogPlay } from "@mdi/js";
import { iPreset } from "@sdk/user-management/preset-state-model";
import { iCCMacro } from "@sdk/user-management/user-management.models";
import { Tag } from "antd";
import { ActionIcons } from "components/modules/conversations/components/action-editor/actions";
import { find, last } from "lodash";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { Mention, MentionsInput } from "react-mentions";
import { checkText } from "smile2emoji";
import { AddEllipsis } from "utils/add-ellipsis";
import { useStateWithGetter } from "utils/hooks/use-state-with-getter";
import { convertHtmlToPlainTextWithLineBreaks } from "utils/html-to-plain-text";
import { MDIIcon } from "../mdi-icon";
import "./reply-input.scss";

const emptyArray = [];

function moveCursorToEnd(el) {
  setTimeout(() => {
    el.focus();
    if (typeof el.selectionStart == "number") {
      el.selectionStart = el.selectionEnd = el.value.length;
    } else if (typeof el.createTextRange != "undefined") {
      var range = el.createTextRange();
      range.collapse(false);
      range.select();
    }
  }, 50);
}

export const ReactMentionsInput = memo(
  forwardRef(
    (
      {
        initialValue,
        onChange,
        onBlur,
        onFocus,
        conversationId,
        // additionalExcludedControls,
        // extendControls,
        // onCommandActivated,
        mentions,
        actions,
        presets,
        // editorId,
        // noControls,
        onPressEnter,
        handlePastedFiles,
        placeholder,
        onActionSelected,
        onPresetSelected,
      }: {
        initialValue: string;
        onChange?: (html: string) => any;
        onBlur?: () => any;
        onFocus?: () => any;
        conversationId?: string;
        additionalExcludedControls?: BuiltInControlType[];
        extendControls?: ExtendControlType[];
        onCommandActivated?: (command: string) => any;
        extensions?: [];
        commandPallets?: [];
        mentions: any[];
        actions: any[];
        presets: any[];
        editorId?: string;
        noControls?: boolean;
        onPressEnter?: (e: any) => any;
        handlePastedFiles?: (filed: File[]) => any;
        placeholder?: string;
        onActionSelected?: (data: any) => any;
        onPresetSelected?: (data: any) => any;
      },
      ref,
    ) => {
      const inputRef = useRef<any>();
      const [sendMessageInputValue, setSendMessageInputValue, getInputValue] =
        useStateWithGetter(initialValue);

      const setHtmlContent = useCallback(
        (html: string) => {
          const textToAdd = convertHtmlToPlainTextWithLineBreaks(html);
          setSendMessageInputValue(textToAdd);
        },
        [setSendMessageInputValue],
      );

      const getHtmlContent = useCallback(() => {
        return getInputValue();
      }, [getInputValue]);

      const focusInput = useCallback(() => {
        inputRef.current?.focus();
      }, []);

      const insertHtmlToCursor = useCallback(
        (html: string) => {
          const textToAdd = convertHtmlToPlainTextWithLineBreaks(html);
          setSendMessageInputValue(`${getInputValue()}${textToAdd.trim()}`);
        },
        [getInputValue, setSendMessageInputValue],
      );

      useImperativeHandle(
        ref,
        () => ({
          setHtmlContent,
          getHtmlContent,
          focusInput,
          insertHtmlToCursor,
        }),
        [focusInput, getHtmlContent, insertHtmlToCursor, setHtmlContent],
      );

      const sendMessageSuggestionContainerRef = useRef<any>();

      const handleMessageInputChange = useCallback(
        (
          event,
          newValue,
          newPlainTextValue,
          mentions: {
            childIndex: number;
            display: string;
            id: string;
            index: number;
            plainTextIndex: number;
            type: "PRESET" | "MENTION" | "NOT_AVAILABLE";
          }[],
        ) => {
          if (newValue.indexOf("@[") > -1) {
            const lastMention = last(mentions);
            if (lastMention) {
              const preset = find(presets, { id: lastMention?.id });
              const action = find(actions, { id: lastMention?.id });
              const mention = find(mentions, { id: lastMention?.id });

              if (action || preset) {
                let textToAdd = "";
                if (action && onActionSelected) {
                  const result = onActionSelected(action);
                  newValue = newValue.replace(
                    `@[${lastMention.display}](${lastMention.id})`,
                    // macroCommand.label
                    "",
                  );
                }
                if (preset && onPresetSelected) {
                  const result = onPresetSelected(preset);
                  newValue = newValue.replace(
                    `@[${lastMention.display}](${lastMention.id})`,
                    result,
                  );
                }
                setTimeout(() => {
                  moveCursorToEnd(inputRef.current);
                }, 0);
              }
            }
          }
          setSendMessageInputValue(checkText(newValue));

          if (onChange) {
            onChange(newValue);
          }
        },
        [
          actions,
          onActionSelected,
          onChange,
          onPresetSelected,
          presets,
          setSendMessageInputValue,
        ],
      );

      const onKeyDown = useCallback(
        (e) => {
          if (e.keyCode === 13 && !e.shiftKey) {
            const status = onPressEnter && onPressEnter(e);
            if (status) {
              e.preventDefault();
            }
          }
        },
        [onPressEnter],
      );

      const mentionsSuggestions = useMemo(() => {
        return mentions.map((item) => ({
          ...item,
          display: item.name,
          suggestionType: "MENTION",
        }));
      }, [mentions]);

      const presetSuggestions = useMemo(() => {
        return presets.map((item) => ({
          ...item,
          display: `${item.label}: ${item.value}`,
          suggestionType: "PRESET",
        }));
      }, [presets]);

      const actionsSuggestions = useMemo(() => {
        return actions.map((item) => ({
          ...item,
          display: item.label,
          suggestionType: "ACTION",
        }));
      }, [actions]);

      const handleOnPaste = useCallback(
        (event: ClipboardEvent) => {
          const items = (
            event.clipboardData || (event as any).originalEvent.clipboardData
          ).items;
          let blob = null;

          for (const item of items) {
            if (item.type.indexOf("image") === 0) {
              blob = item.getAsFile();
            }
          }

          // load image if there is a pasted image
          if (blob !== null && handlePastedFiles) {
            handlePastedFiles([
              new File([blob], `clipboard-${new Date().getTime()}.png`, {
                type: "image/png",
              }),
            ]);
          }
        },
        [handlePastedFiles],
      );

      useEffect(() => {
        if (inputRef && inputRef.current) {
          inputRef.current.addEventListener("paste", handleOnPaste, false);
          inputRef.current.setAttribute("data-enable-grammarly", "true");
          inputRef.current.setAttribute("data-gramm", "true");
          const currentInputRef = inputRef.current;
          return function cleanup() {
            if (currentInputRef) {
              currentInputRef.removeEventListener(
                "paste",
                handleOnPaste,
                false,
              );
            }
          };
        }
      }, [handleOnPaste, inputRef]);

      return (
        <div className="p-4 relative react-mentions-container">
          <div
            className="suggestions-container w-full relative"
            ref={sendMessageSuggestionContainerRef}
          ></div>
          <MentionsInput
            value={sendMessageInputValue}
            inputRef={inputRef}
            onChange={handleMessageInputChange}
            placeholder={
              placeholder ||
              "Type in a message and hit enter to send a message. Press / for commands and message presets"
            }
            className="w-full send-input"
            // singleLine
            suggestionsPortalHost={sendMessageSuggestionContainerRef.current}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
          >
            <Mention
              trigger="/"
              data={presetSuggestions}
              renderSuggestion={PresetRenderer}
            />

            <Mention
              trigger="@"
              data={mentionsSuggestions}
              // renderSuggestion={renderPreset}
            />

            <Mention
              trigger="\"
              data={actionsSuggestions}
              renderSuggestion={MacroCommandRenderer}
            />
          </MentionsInput>
        </div>
      );
    },
  ),
);

const PresetRenderer = (preset: iPreset & { folderLabel?: string }) => {
  return (
    <div className="flex flex-row">
      {preset && (
        <>
          {preset.id === "NOT_AVAILABLE" ? (
            <div className="flex flex-row">
              <div className="icon px-4">
                <i className="ri-error-warning-fill"></i>
              </div>
              <div className="label font-bold  px-2">
                Message Presets have not been set
              </div>
              <div className="description text-gray-600 flex-1  px-2">
                Click here to add message presets
              </div>
            </div>
          ) : (
            <>
              <div className="flex flex-row">
                <div className="icon px-4">
                  {preset.id !== "NOT_AVAILABLE" && (
                    <i className="ri-message-2-line"></i>
                  )}
                </div>
                <div className="label font-bold  px-2">
                  {AddEllipsis(preset.label, 30)}
                </div>
                <div className="description text-gray-600 flex-1  px-2">
                  {AddEllipsis(preset.value, 30)}
                </div>
                <div className="right">
                  {preset.folderLabel && <Tag>{preset.folderLabel}</Tag>}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const MacroCommandRenderer = (
  action: { id: string; label: string } | iCCMacro,
) => {
  return (
    <div className="flex flex-row">
      {action && (
        <>
          {action.id === "NOT_AVAILABLE" ? (
            <div className="flex flex-row">
              <div className="icon px-4">
                <i className="ri-error-warning-fill"></i>
              </div>
              <div className="label font-bold  px-2">
                No Macros have been setup
              </div>
              <div className="description text-gray-600 flex-1  px-2"></div>
            </div>
          ) : (
            <>
              <div className="flex flex-row">
                <div className="icon px-4">
                  {ActionIcons[action.id] || (
                    <MDIIcon icon={mdiCogPlay} size="1rem" />
                  )}
                </div>
                <div className="label font-bold  px-2">{action.label}</div>
                <div className="description text-gray-600 flex-1  px-2">
                  {action.label}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
