import { axios, AxiosResponse } from "@sdk/axios";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import {
  iCardComment,
  iPipelineCard,
  iPipelineCardTask,
} from "./pipeline-cards-model";

export class PipelineCardsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  pipelineCards = Object.assign(
    new EntityServices<iPipelineCard>(this.config, "pipeline-cards", {
      onCreate: () => {
        UserTracker.track("PIPELINE_CARDS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("PIPELINE_CARDS - Delete", {});
      },
    }),
    {
      assignUser: async (pipelineCardId: string, userId: string) => {
        UserTracker.track("PIPELINE_CARDS - Assign User", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/pipeline-cards/${pipelineCardId}/assign`,
          {
            userId,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      unassignUser: async (pipelineCardId: string, userId: string) => {
        UserTracker.track("PIPELINE_CARDS - UnAssign User", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/pipeline-cards/${pipelineCardId}/unassign`,
          {
            userId,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      addComment: async (
        pipelineCardId: string,
        text: string,
        attachments: string[],
      ) => {
        UserTracker.track("PIPELINE_CARDS - Add Comment", {});
        const results: AxiosResponse<iPipelineCard> = await axios.post(
          this.config.basePath + `/pipeline-cards/${pipelineCardId}/comments`,
          {
            text,
            attachments,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      editComment: async (
        pipelineCardId: string,
        commentId: string,
        edits: Partial<iCardComment>,
      ) => {
        UserTracker.track("PIPELINE_CARDS - Edit Comment", {});
        const results: AxiosResponse<any> = await axios.patch(
          this.config.basePath +
            `/pipeline-cards/${pipelineCardId}/comments/${commentId}`,
          edits,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      removeComment: async (pipelineCardId: string, commentId: string) => {
        UserTracker.track("PIPELINE_CARDS - Remove Comment", {});
        const results: AxiosResponse<any> = await axios.delete(
          this.config.basePath +
            `/pipeline-cards/${pipelineCardId}/comments/${commentId}`,

          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      addTask: async (
        pipelineCardId: string,
        task: Partial<iPipelineCardTask>,
      ) => {
        UserTracker.track("PIPELINE_CARDS - Add Task", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/pipeline-cards/${pipelineCardId}/tasks`,
          task,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      editTask: async (
        pipelineCardId: string,
        taskId: string,
        edits: Partial<iPipelineCardTask>,
      ) => {
        UserTracker.track("PIPELINE_CARDS - Edit Task", {});
        const results: AxiosResponse<any> = await axios.patch(
          this.config.basePath +
            `/pipeline-cards/${pipelineCardId}/tasks/${taskId}`,
          edits,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      removeTask: async (pipelineCardId: string, taskId: string) => {
        UserTracker.track("PIPELINE_CARDS - Remove Task", {});
        const results: AxiosResponse<any> = await axios.delete(
          this.config.basePath +
            `/pipeline-cards/${pipelineCardId}/tasks/${taskId}`,

          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
