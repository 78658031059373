import { iArticle } from "@sdk/knowledge-base/knowledge-base.models";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import { articleDefaultQueryConfig } from "./article-default-query";

export const {
  selectQueryMap: selectArticleQueryMap,
  selectEntityMap: selectArticlesMap,
  selectQueryMapAll: selectArticlesMapAll,
  selectQueryAllList: selectArticlesAllList,
  selectAllEntities: selectAllArticles,
  selectAllEntitiesQuery: selectAllArticlesQuery,
  selectEntityById: selectArticleById,
  selectEntityQuery: selectArticlesQuery
} = CreateSelectorForEntities<iStore, iArticle>({
  sliceName: "articles"
});

export const selectArticleByCollectionId = (collectionId: string) => (
  state: iStore
) =>
  selectAllArticles(state).filter(item =>
    item.collections.includes(collectionId)
  );

export const selectArticleByCollectionIdFromQuery = (collectionId: string) => (
  state: iStore
) =>
  selectArticlesQuery(
    JSON.stringify({
      query: { collections: collectionId },
      options: articleDefaultQueryConfig.options
    })
  )(state);
