import _, { escapeRegExp } from "lodash";

export function ArticlesSearchQueryBuilder(term: string) {
  const query = {
    $or: _.flattenDeep(
      [
        {
          "data.primaryEmail": {
            $regex: escapeRegExp(term),
            $options: "i",
          },
        },
      ].filter((e) => e),
    ),
  };
  return query;
}
