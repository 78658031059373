import InboxOutlined from "@ant-design/icons/InboxOutlined";
import UserAddOutlined from "@ant-design/icons/UserAddOutlined";
import { SDK } from "@sdk";
import {
  getPermissionFromRole,
  Roles,
} from "@sdk/roles-and-permissions/roles-and-permissions";
import { processServerError } from "@sdk/sdk.hooks";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Result,
  Select,
  Space,
  Steps,
  Switch,
  Table,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { DarkModeBg } from "dark-mode-bg";
import Papa from "papaparse";
import { useState } from "react";
import "./bulk-add-users.scss";

const { Dragger } = Upload;
const { Option } = Select;

const bulkUpdateHeaderMapping = [
  {
    key: "email",
    label: "Email",
  },
  {
    key: "firstName",
    label: "First name",
  },
  {
    key: "lastName",
    label: "Last Name",
  },
  {
    key: "password",
    label: "Password",
  },
  {
    key: "tags",
    label: "Tags",
  },
];

const steps = [
  {
    title: "Upload",
    description: "upload data via file",
  },
  {
    title: "Map",
    description: "define column headings",
  },
  {
    title: "Configure",
    description: "set up reason",
  },
];

export const BulkAddUsersModal = ({
  visible,
  onChangeVisibility,
  onCreated,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated?: () => any;
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [uploadedRecords, setUploadedRecords] = useState<any[]>([]);
  const [bulkOptOutColumnMapForm] = useForm();
  const [columnMappings, setColumnMappings] = useState({});
  const [bulkAddConfigForm] = useForm();

  // Bulk Update
  const [isProcessingBulkUpload, setIsProcessingBulkUpload] = useState(false);
  const [isFirstRecordHeader, setIsFirstRecordHeader] = useState(false);

  const onBulkAdd = async () => {
    // setIsProcessingBulkUpload(true);
    try {
      const _uploadedRecords = [...uploadedRecords];
      if (isFirstRecordHeader) {
        _uploadedRecords.shift();
      }

      const columnMappingForm = columnMappings;
      const columnMapping: any = {};
      for (let key in columnMappingForm) {
        if (columnMappingForm[key] !== "NA") {
          // column-$index
          const index = key.replace("column-", "");
          columnMapping[columnMappingForm[key]] = index;
        }
      }

      const getValue = (key: string, record: any) =>
        typeof columnMapping[key] === "undefined"
          ? undefined
          : record[columnMapping[key]];

      const records = _uploadedRecords.map((record) => ({
        email: getValue("email", record),
        firstName: getValue("firstName", record),
        lastName: getValue("lastName", record),
        password: getValue("password", record),
        tags: getValue("tags", record),
      }));
      // console.log("records", records);

      const { sendWelcomeEmail, sendPasswordSetEmail, defaultPassword, role } =
        bulkAddConfigForm.getFieldsValue();

      const res = await SDK.bulkCreateUser({
        records: records,
        sendWelcomeEmail,
        sendPasswordSetEmail,
        defaultPassword,
        defaultPermissions: getPermissionFromRole(role)!,
      });
      message.success(`Records have been added`);
      onCreated && onCreated();
      setCurrentStep(4);
      setIsProcessingBulkUpload(false);
    } catch (e) {
      console.log("Error while crating list", e);
      message.error(processServerError(e, "Something went wrong"));
      setIsProcessingBulkUpload(false);
    }
  };

  return (
    <Modal
      title={
        <div className="font-bold text-xl">
          <Space>
            <UserAddOutlined className="icon-fix" /> Import Users
          </Space>
        </div>
      }
      open={visible}
      footer={currentStep !== 3 ? null : undefined}
      onOk={() => {
        onBulkAdd();
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Import"
      width="90%"
      okButtonProps={{ icon: <i className="ri-check-line"></i> }}
      confirmLoading={isProcessingBulkUpload}
      data-click-context="Bulk Add User Modal"
      className="bulk-add-users-modal"
    >
      <div className="flex flex-row justify-center items-center">
        <div
          className="bulk-add-steps-container w-full px-12"
          style={{ maxWidth: 1200 }}
        >
          <Steps current={currentStep - 1}>
            {steps.map((item) => (
              <Steps.Step
                key={item.title}
                title={item.title}
                description={item.description}
              />
            ))}
          </Steps>
        </div>
      </div>
      <Divider />
      {currentStep === 1 && (
        <div className="drag-and-drop-container">
          <Dragger
            name="file"
            accept=".csv"
            customRequest={() => {}}
            onChange={(data) => {
              const file = data.fileList[0].originFileObj;
              // console.log("File Selected", file, Papa);
              const parsedData = Papa.parse(file, {
                error: function (err, file, inputElem, reason) {
                  // executed if an error occurs while loading the file,
                  // or if before callback aborted for some reason
                  console.log("Error while parsing file", err, reason);
                },
                complete: function (results, file) {
                  // executed after all files are complete
                  console.log("Parsing Complte", results, file);
                  setUploadedRecords(results.data);
                  setCurrentStep(2);
                },
              });
              console.log("parsedData", parsedData);
            }}
            style={{ minHeight: 400 }}
            className="flex-row flex items-center justify-center"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Upload contact data in CSV format</p>
            <a
              className="ant-upload-hint"
              href="https://www.youtube.com/watch?v=RW0BTP-XyeY"
              target="_blank"
              onClick={(e) => e.stopPropagation()}
              rel="noreferrer"
            >
              Got an excel file? (.xlxs). Click here to find out how to convert
              excel files to csv
            </a>
          </Dragger>
        </div>
      )}
      {currentStep === 2 && (
        <div>
          <div className="first-row-header flex flex-row justify-between items-center p-4">
            <Space>
              <div className="label">Is first row header</div>
              <Switch
                onChange={(value) => setIsFirstRecordHeader(value)}
                checked={isFirstRecordHeader}
              />
            </Space>
            <div className="record-coun">
              {isFirstRecordHeader
                ? uploadedRecords.length - 1
                : uploadedRecords.length}
              Record(s)
            </div>
          </div>
          <Form
            form={bulkOptOutColumnMapForm}
            onValuesChange={(change) =>
              setColumnMappings(bulkOptOutColumnMapForm.getFieldsValue())
            }
            preserve={true}
          >
            <Table
              columns={(uploadedRecords[0] as any).map((item, index) => ({
                title: (
                  <Form.Item
                    label=""
                    name={`column-${index}`}
                    key={`column-${index}`}
                  >
                    <Select placeholder="Please select">
                      <Option value={"NA"}>Not Mapped</Option>
                      {bulkUpdateHeaderMapping.map((header) => (
                        <Option key={header.key} value={header.key}>
                          {header.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ),
                dataIndex: index,
              }))}
              dataSource={(isFirstRecordHeader
                ? uploadedRecords.slice(1)
                : uploadedRecords
              ).map((row) =>
                (row as string[]).reduce(
                  (acc, curr, index) => ({ ...acc, [index]: curr }),
                  {},
                ),
              )}
              pagination={{ defaultPageSize: 5 }}
              scroll={{ x: "100vw" }}
            />
          </Form>
          <div className="flex flex-row justify-end">
            <Space>
              <Button onClick={() => setCurrentStep(1)}>Back</Button>
              <Button type="primary" onClick={() => setCurrentStep(3)}>
                Next
              </Button>
            </Space>
          </div>
        </div>
      )}
      {currentStep === 3 && (
        <div style={{ maxWidth: 450 }}>
          <div className="back-button-container my-10">
            <Button onClick={() => setCurrentStep(2)}>Back</Button>
          </div>
          <Form
            layout="vertical"
            form={bulkAddConfigForm}
            className="bold-form-labels"
          >
            {/* <Form.Item label="Send Welcome Email" name="sendWelcomeEmail">
              <Switch />
            </Form.Item>
            <Form.Item
              label="Send Password Set Email"
              name="sendPasswordSetEmail"
              shouldUpdate
            >
              <Switch />
            </Form.Item> */}
            <Form.Item dependencies={["sendPasswordSetEmail"]} shouldUpdate>
              {() =>
                !bulkAddConfigForm.getFieldValue("sendPasswordSetEmail") && (
                  <Form.Item
                    label="Default Password"
                    name="defaultPassword"
                    help="
                    If the password is not mapped, the default password will be used to set up the account."
                  >
                    <Input required type="password" />
                  </Form.Item>
                )
              }
            </Form.Item>
            {/* Role */}
            <Form.Item
              label="Role"
              name="role"
              rules={[{ required: true, message: "Please select a role" }]}
            >
              <Select size="large">
                {Roles.map((role) => (
                  <Select.Option key={role.label} value={role.label}>
                    {role.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
      )}
      {currentStep === 4 && (
        <Result
          status="success"
          icon={<i className="ri-checkbox-circle-line text-10xl"></i>}
          title="Users have been imported"
          subTitle={`Successful`}
          extra={[
            <Button
              key="another"
              onClick={() => {
                setCurrentStep(1);
              }}
              icon={<i className="ri-file-upload-line"></i>}
            >
              Upload another file
            </Button>,
          ]}
        />
      )}
      <DarkModeBg />
    </Modal>
  );
};
