import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { Button } from "antd";

import { ExpressAccountSetUp } from "modal-registry";

import { PopupButton, useCalendlyEventListener } from "react-calendly";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";

export const ExpressAccountFinalSetup = () => {
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: e => console.log(e.data.payload)
  });

  const { changePanelState } = useModalPanels();

  const organization = useSelector(selectOrganization);
  return (
    <div
      className="help flex-col justify-center items-center ml-4 relative overflow-hidden"
      style={{
        minHeight: 300
      }}
    >
      <img
        src="/assets/account-managers/ruban.png"
        alt="Speak to Support"
        style={{
          width: 308,
          right: -69,
          top: 40,
          position: "absolute"
        }}
      />
      <div className="font-bold text-2xl my-4 z-10" style={{ width: "60%" }}>
        Welcome to Click Connector Club!
      </div>
      <div className="font-bold my-4 z-10" style={{ width: "60%" }}>
        We want you to succeed!
      </div>
      <div className="text-gray-600 mb-8" style={{ width: "60%" }}>
        We will help you step by step to configure Click Connector for your
        organization's success
      </div>

      <div className="button-container" style={{ width: "60%" }}>
        <PopupButton
          {...{
            url: "https://calendly.com/clickconnector/meet",
            prefill: {},
            pageSettings: {
              backgroundColor: "ffffff",
              hideEventTypeDetails: true,
              hideLandingPageDetails: false,
              primaryColor: "00a2ff",
              textColor: "4d5055",
              hideGdprBanner: true
            },
            utm: {}
          }}
          text={
            (
              <Button
                type="primary"
                className="font-bold"
                icon={<i className="ri-calendar-2-line"></i>}
              >
                Schedule an account setup call
              </Button>
            ) as any
          }
          rootElement={document.getElementById("root")!}
        />
        <Button
          type="dashed"
          className="font-bold mt-4"
          icon={<i className="ri-rocket-2-line"></i>}
          onClick={() => {
            changePanelState(ExpressAccountSetUp, true, {});
          }}
        >
          Set my account up myself
        </Button>
      </div>
    </div>
  );
};
