import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iContact } from "@sdk/crm/crm.models";
import { Button } from "antd";
import { Avatar } from "components/common/avatar/avatar";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import { ContactProfile } from "modal-registry";

import { getContactName } from "../../crm/contacts/helpers/get-contact-name";

export const SoftPhoneContactCard = ({
  contact,
  phoneNumber,
  size = 80,
  callerName
}: {
  contact?: iContact;
  phoneNumber?: string;
  size?: number;
  callerName?: string;
}) => {
  const { changePanelState } = useModalPanels();
  return (
    <div className="header flex flex-col justify-center items-center">
      {contact ? (
        <ContactAvatar contactId={contact.id!} size={size} />
      ) : (
        <Avatar name="Unknown" size={size} />
      )}

      {contact ? (
        <>
          <div className="name text-2xl">{getContactName(contact)}</div>
          <div className="number text-gray-600">{phoneNumber}</div>
        </>
      ) : (
        <>
          <div className="name text-2xl">{phoneNumber}</div>
          <div className="number text-gray-600">
            {callerName || "Unknown Contact"}
          </div>
        </>
      )}

      {contact && (
        <Button
          icon={<i className="ri-folder-user-line"></i>}
          type="text"
          onClick={() => {
            changePanelState(ContactProfile, true, { contactId: contact.id! });
          }}
        >
          View Contact
        </Button>
      )}
    </div>
  );
};
