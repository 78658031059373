import { Button, Divider, Form, Select } from "antd";
import { useForm } from "antd/lib/form/Form";

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import "./article-editor.scss";

import { LexicalEditorApp } from "@libs/@lexical-playground/Async-App";
import { iArticle } from "@sdk/knowledge-base/knowledge-base.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import TextArea from "antd/es/input/TextArea";
import { EditableText } from "components/common/editable-text/editable-text";

import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { LazyEditorJS } from "components/common/editor-js/lazy-editor-js";
import { cloneDeep } from "lodash";
import { OpenAiAssistant } from "modal-registry";
import { loadAllArticleCollections } from "store/modules/article-collection/article-collection.helpers";
import { selectAllArticleCollections } from "store/modules/article-collection/article-collection.selectors";
import { loadArticleById } from "store/modules/articles/articles.helpers";
import { selectArticleById } from "store/modules/articles/articles.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { uuidv4 } from "utils/generate-uuid";
import { justWait } from "utils/just-wait";
import { EmptyArticleBody } from "./empty-article.data";
const DraftJSInput = React.lazy(
  () => import("components/common/draft-js-input/draft-js-input"),
);

// Sample Article - e1a520-95d3e
// Back to HTML - https://github.com/pavittarx/editorjs-html
// Option 2 https://www.npmjs.com/package/editorjs-viewer

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const ArticleEditorNew = forwardRef(
  (
    {
      articleId,
      hidePageTitle,
      onArticleCreated,
      removePadding,
      collectionId,
    }: {
      articleId?: string;
      hidePageTitle?: boolean;
      onArticleCreated?: (articleId: string) => any;
      removePadding?: boolean;
      collectionId?: string;
    },
    ref,
  ) => {
    const [form] = useForm();
    const editorRef = useRef<any>(null);
    const editorJsRef = useRef<any>(null);
    const isEditMode = Boolean(articleId);

    const { state: collections, retry: reload } = useQueryWithStore(
      selectAllArticleCollections,
      loadAllArticleCollections,
    );

    const { state: article, isLoading } = useQueryWithStore(
      selectArticleById(articleId!),
      loadArticleById(articleId!)(),
      [articleId],
      !articleId,
    );

    const isNewEditor = !articleId || typeof article?.body === "string";

    const {
      doAction: saveArticle,
      isProcessing,
      response,
      dispatch,
    } = useSDKActionWithDeps(
      () => ({
        action: (SDK) => async () => {
          let body;
          if (isNewEditor) {
            body = await editorRef.current.getEditorStateHtml();
          } else {
            body = editorJsRef && (await editorJsRef.current.save());
          }

          const data = {
            ...form.getFieldsValue(),
            body,
            type: "ARTICLE",
          } as Partial<iArticle>;
          if (
            !data.title ||
            !data.metaData?.status ||
            !data.type ||
            (data?.collections?.length || 0) < 1
          ) {
            let message = "Please fill in all required field";
            if ((data?.collections?.length || 0) < 1) {
              message = "Select at least one collection";
            }
            if (!data.title) {
              message = "Enter a title";
            }
            throw {
              response: {
                data: {
                  message: message,
                },
              },
            };
          }
          if (isEditMode) {
            return SDK.articles.editById(articleId!, data);
          } else {
            return SDK.articles.create(data);
          }
        },
        successMessage: `Article has been ${isEditMode ? "edited" : "created"}`,
        failureMessage: "Something went wrong",
      }),
      [articleId, form, isEditMode, isNewEditor],
    );

    const initialValues = useMemo(() => {
      return {
        collections: collectionId ? [collectionId] : [],
        metaData: {
          status: "PUBLISHED",
        },
      };
    }, [collectionId]);

    useEffect(() => {
      if (collectionId) {
        form.setFieldsValue({
          collections: [collectionId],
        });
      }
    }, [collectionId, form]);

    const setEditorStateHtml = useCallback(
      (html: string, retries: number = 0) => {
        if (editorRef.current) {
          editorRef.current.setEditorStateHtml(html || "");
          // 2 Seconds
        } else if (retries < 10) {
          // Retry
          setTimeout(() => {
            setEditorStateHtml(html, retries + 1);
          }, 200);
        }
      },
      [],
    );

    const renderEditorJSArticle = useCallback(
      (content: any, retries: number = 0) => {
        const isEditorAvailable =
          editorJsRef.current && editorJsRef.current._editorJS;
        if (isEditorAvailable) {
          editorJsRef.current._editorJS.isReady?.then((d) => {
            editorJsRef.current._editorJS.render(content);
          });
        } else if (retries < 5) {
          setTimeout(() => {
            renderEditorJSArticle(content, retries + 1);
          }, 400);
        }
      },
      [],
    );

    useEffect(() => {
      if (article) {
        form.resetFields();
        const clonedArticle = cloneDeep(article);
        form.setFieldsValue(clonedArticle);
      } else {
        form.resetFields();
        setEditorStateHtml(EmptyArticleBody);
      }
    }, [article, form, setEditorStateHtml]);

    const resetForm = useCallback(() => {
      form.resetFields();
      setEditorStateHtml(EmptyArticleBody);
    }, [form, setEditorStateHtml]);

    useImperativeHandle(
      ref,
      () => ({
        saveArticle: saveArticle,
        resetForm: resetForm,
      }),
      [resetForm, saveArticle],
    );

    useEffect(() => {
      if (article?.body) {
        if (isNewEditor) {
          setEditorStateHtml(article?.body || "");
        } else {
          renderEditorJSArticle(article.body);
        }
      }
    }, [article?.body, isNewEditor, renderEditorJSArticle, setEditorStateHtml]);

    useEffect(() => {
      if (!isEditMode) {
        form.resetFields();
        setEditorStateHtml(EmptyArticleBody);
        // No need to reset for old Editor
        // editorJsRef.current._editorJS?.isReady?.then((d) => {
        //   editorJsRef.current._editorJS?.render(defaultArticle);
        // });
      }
    }, [form, isEditMode, setEditorStateHtml]);

    const { triggerTempModal } = useModalPanels();

    const { doAction: uploadImage, isProcessing: isUploading } =
      useSDKActionWithDeps(
        () => ({
          action: (SDK) => (file) =>
            SDK.uploadFile(file, {
              type: "KNOWLEDGE_BASE_ARTICLE",
              entityId: articleId || `NEW_${uuidv4()}`,
            }),
          throwError: true,
        }),
        [articleId],
      );

    const imageUploader = useCallback(
      async (file: File) => {
        await justWait(2000);
        const fileDoc = await uploadImage(file);
        return fileDoc?.url!;
      },
      [uploadImage],
    );

    return (
      <div className="article-editor px-8">
        <Form
          layout="inline"
          form={form}
          requiredMark={false}
          className=""
          labelAlign="left"
          initialValues={initialValues}
        >
          <div className="flex flex-col p-4 w-full">
            <div className="title my-4">
              <Form.Item
                name="title"
                label=""
                rules={[{ required: true, message: "Please enter a title" }]}
                trigger="onSave"
                noStyle
              >
                <EditableText
                  placeholder="Title of the document"
                  className="text-2xl font-bold"
                />
              </Form.Item>
            </div>
            <div className="other-props">
              <Form.Item
                colon={false}
                name="collections"
                label={
                  <div className="text-gray-600">
                    <i className="ri-folder-5-line"></i> Collection
                  </div>
                }
                rules={[]}
                {...layout}
                className="mb-2"
              >
                <Select
                  mode="multiple"
                  placeholder={"Select Collections"}
                  bordered={false}
                  showSearch
                  options={collections.map((collection) => ({
                    value: collection.id,
                    label: collection.label,
                  }))}
                  optionFilterProp="label"
                >
                  {/* {collections.map((collection) => (
                    <Select.Option key={collection.id} value={collection.id!}>
                      {collection.label}
                    </Select.Option>
                  ))} */}
                </Select>
              </Form.Item>

              <Form.Item
                colon={false}
                name={["metaData", "status"]}
                label={
                  <div className="text-gray-600">
                    <i className="ri-eye-line"></i> Status
                  </div>
                }
                rules={[{ required: true, message: "Please enter a title" }]}
                {...layout}
                className="mb-2"
              >
                <Select placeholder={"Select Status"} bordered={false}>
                  <Select.Option value={"PUBLISHED"}>Published</Select.Option>
                  <Select.Option value={"DRAFT"}>Draft</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                colon={false}
                name="description"
                label={
                  <div className="text-gray-600">
                    <i className="ri-text"></i> Description
                  </div>
                }
                rules={[]}
                {...layout}
                className="mb-2"
              >
                <TextArea
                  placeholder="Short description about the article"
                  bordered={false}
                  rows={1}
                  autoSize={true}
                />
              </Form.Item>
            </div>
            <Divider className="mb-2" />

            {isNewEditor && (
              <>
                <LexicalEditorApp
                  editorId={"editor 1"}
                  editorRef={editorRef}
                  onChange={(e) => {
                    // console.log("Change", e);
                  }}
                  imageUploader={imageUploader}
                  toolBarExtra={
                    <Button
                      type="text"
                      icon={
                        <img
                          src="/assets/integrations/openai.png"
                          alt="Chat GPT"
                          style={{ width: "1rem" }}
                          className="dark:invert"
                        />
                      }
                      onClick={() => triggerTempModal(OpenAiAssistant, {})}
                    />
                  }
                />
              </>
            )}
            {!isNewEditor && article?.body && (
              <>
                <LazyEditorJS
                  editorJSRef={editorJsRef}
                  instanceId="article-editor-modal"
                />
              </>
            )}
          </div>
        </Form>
      </div>
    );
  },
);

export default ArticleEditorNew;
