import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { BadgeRegistry } from "@sdk/badges/badge-registry";
import { Button, Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { find } from "lodash";
import { AllBadges } from "modal-registry";
import { useMemo } from "react";
import { loadAllBadges } from "store/modules/badges/badges.helpers";
import { selectAllBadges } from "store/modules/badges/badges.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { toKebabCase } from "utils/to-kebab-case";

export const NewBadgeEarnedModal = ({
  visible,
  onChangeVisibility,
  badgeId
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  badgeId: string;
}) => {
  const badge = useMemo(() => find(BadgeRegistry, { id: badgeId }), [badgeId]);

  const { state: badges, retry: reload, isLoading } = useQueryWithStore(
    selectAllBadges,
    loadAllBadges,
    []
  );

  const { changePanelState } = useModalPanels();

  return (
    <Modal
      title={
        <ModalTitle
          title={"You Earned a New Badge!"}
          icon={<i className="ri-medal-2-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="New Badge Earned Modal"
      width={400}
    >
      <div className="p-4 flex flex-col justify-center items-center">
        <div className="badge flex flex-col justify-center items-center">
          <div
            style={{ width: 120, height: 160 }}
            className="flex flex-row justify-center items-center"
          >
            <img
              src={`/assets/badges/${toKebabCase(badge?.category || "")}/${
                badge?.id
              }.svg`}
              style={{ width: 300 }}
              alt=""
            />
          </div>
          <div className="font-bold text-xl">{badge?.name}</div>
          <div className="">"{badge?.description}"</div>
        </div>

        <div className="">
          <Button
            type="link"
            onClick={() => {
              changePanelState(AllBadges, true, {});
            }}
            size="small"
          >
            Show all my badges
          </Button>
        </div>
        <div className="mt-6">
          <Button
            onClick={() => {
              onChangeVisibility(false);
            }}
          >
            Okay Great! 🙌
          </Button>
        </div>
      </div>
      <DarkModeBg />
    </Modal>
  );
};
