import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iConnection } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iOrganization } from "@sdk/user-management/user-management.models";
import { Button, Divider, Modal, Result, Steps } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { ChatWidgetInstallInstructions } from "components/modules/connections/channels/chat-widget/components/chat-widget-install-instructions/chat-widget-install-instructions";
import { push } from "connected-react-router";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import {
  AddChatWidget,
  AddEmailConnection,
  AddFacebookConnection,
  AddInstagramConnection,
  AddWhatsappConnection,
} from "modal-registry";

import { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { DeepPartial } from "redux";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import {
  selectOrganization,
  selectOrganizationOnboardingState,
} from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { BulkInviteUsers } from "./bulk-invite-users";

import "./express-account-setup-modal.scss";
import "./home.page.scss";
import { useOnboardingSteps } from "./use-onboarding-steps";

const _steps: {
  id: string;
  stepId: string;
  icon: JSX.Element;
  label: string;
  description: string;
  status?: boolean;
}[] = [
  {
    id: "WEB_CHAT",
    stepId: "LIVE_CHAT",
    icon: <i className="ri-chat-1-line"></i>,
    label: "Configure Live Chat",
    description: "Customize your live chat and install it in your website",
  },
  {
    id: "EMAIL",
    stepId: "EMAIL_INBOX",
    icon: <i className="ri-mail-line"></i>,
    label: "Setup Team Email Inbox",
    description: "Select your email address and connect it with your domain",
  },
  {
    id: "FACEBOOK",
    stepId: "FACEBOOK",
    icon: <i className="ri-facebook-box-line"></i>,
    label: "Connect Facebook Page",
    description: "Authenticate Facebook and connect your Facebook page",
  },
  {
    id: "INSTAGRAM",
    stepId: "INSTAGRAM",
    icon: <i className="ri-instagram-line"></i>,
    label: "Connect Instagram Account",
    description: "Authenticate Facebook and connect your Instagram Account",
  },
  {
    id: "WHATS_APP_PRIVATE",
    stepId: "WHATSAPP",
    icon: <i className="ri-whatsapp-line"></i>,
    label: "Connect Whatsapp Account",
    description: "Scan your WhatsApp QR code and activate Whatsapp",
  },
  {
    id: "INVITE_USERS",
    stepId: "INVITE_USERS",
    icon: <i className="ri-team-line"></i>,
    label: "Invite Members",
    description: "Invite your team to Click Connector",
  },
  {
    id: "SHOPIFY",
    stepId: "SHOPIFY",
    icon: <i className="ri-shopping-cart-line"></i>,
    label: "Connect Shopify",
    description: "Install Shopify App and connect your shopify inventory",
  },
];

export const ExpressAccountSetUpModal = ({
  visible,
  onChangeVisibility,
  step,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  step?: number;
}) => {
  const currentOrganization = useSelector(selectOrganization);
  const currentOnboardingState = useSelector(selectOrganizationOnboardingState);

  const [currentStep, _setCurrentStep] = useState(
    step || currentOnboardingState?.expressAccountSetupLastStep || 0,
  );

  useEffect(() => {
    if (step) {
      _setCurrentStep(step);
    }
  }, [step]);

  const { state: connections } = useQueryWithStore(
    selectAllConnections,
    loadAllConnections,
  );

  const onboardingSteps = useOnboardingSteps();

  const steps = useMemo(() => {
    return onboardingSteps.map((step) => _.find(_steps, { stepId: step.id })!);
  }, [onboardingSteps]);

  const currentStepItem = steps[currentStep - 1];

  const { isCurrentStepCompleted, connection: currentStepConnection } =
    useMemo(() => {
      const existingConnection = _.find(connections, {
        type: currentStepItem?.id,
      });
      if (existingConnection) {
        return {
          isCurrentStepCompleted: true,
          connection: existingConnection as iConnection,
        };
      }
      return { isCurrentStepCompleted: false };
    }, [connections, currentStepItem?.id]);

  const { changePanelState } = useModalPanels();

  useEffect(() => {
    if (currentOnboardingState?.expressAccountSetupLastStep) {
      _setCurrentStep(currentOnboardingState.expressAccountSetupLastStep);
    }
  }, [currentOnboardingState?.expressAccountSetupLastStep]);

  const { doAction: editCurrentOrganization } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (edits) => SDK.editCurrentOrganization(edits),
      // successMessage: "Done",
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const onStepChange = useCallback(
    (step: number) => {
      editCurrentOrganization({
        appData: {
          ONBOARDING: {
            expressAccountSetupLastStep: step,
          },
        },
      } as DeepPartial<iOrganization>);
    },
    [editCurrentOrganization],
  );

  const setCurrentStep = useCallback(
    (step: number) => {
      _setCurrentStep(step);
      onStepChange(step);
    },
    [onStepChange],
  );

  useEffectWhen(
    () => {
      // When new connection is added
      onStepChange(currentStep + 1);
    },
    [connections.length, currentStep, onStepChange],
    [connections.length],
  );

  const dispatch = useDispatch();

  return (
    <Modal
      title={
        <ModalTitle
          title="Express Account Setup"
          icon={<i className="ri-rocket-2-line"></i>}
        />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      footer={null}
      width={960}
      data-click-context="Express Account Setup Modal"
      bodyStyle={{ padding: 0 }}
      className="express-account-setup"
    >
      <div className="flex flex-row max-w-screen-xl w-full">
        <Steps
          direction="vertical"
          current={currentStep}
          onChange={setCurrentStep}
          style={{ maxWidth: 320 }}
          className="p-4 hidden md:block"
        >
          <Steps.Step
            icon={<i className="ri-check-line"></i>}
            title={"Start"}
            description={"Start Express Account Setup"}
          />
          {steps.map((item) => (
            <Steps.Step
              icon={item.icon}
              title={item.label}
              key={item.label}
              // description={item.description}
            />
          ))}
          <Steps.Step
            icon={<i className="ri-check-line"></i>}
            title={"Complete"}
            description={"Complete Express Setup"}
          />
        </Steps>
        <Divider type="vertical" className="h-full hidden md:block" />

        <div
          className="flex-1 flex flex-col border-l border-gray-200 dark:border-gray-800"
          style={{ boxShadow: "0 0 20px #00000011" }}
        >
          {currentStep === 0 && (
            <div className="p-4 h-full">
              <div className="font-bold text-4xl leading-9">
                Let's get your <br /> account setup finished
              </div>
              <div className="description text-gray-600 mt-2">
                This guide is designed to quickly set up your account based on
                your preference so that you can get productive with Click
                Connector
              </div>
              <div className="text-center">
                <i className="ri-rocket-2-line text-10xl"></i>
              </div>

              <div className="flex flex-row justify-end items-center">
                <Button
                  type="primary"
                  icon={<i className="ri-arrow-right-line"></i>}
                  className="font-bold"
                  onClick={() => setCurrentStep(1)}
                >
                  Start Express Set up
                </Button>
              </div>
            </div>
          )}

          {/* Live Chat */}
          {currentStepItem?.id === "WEB_CHAT" && (
            <>
              <div className="h-full flex flex-col">
                <div className="flex flex-row bg-blue-100 dark:bg-black rounded-lg p-4 m-4">
                  <div className="icon-container font-bold text-2xl mr-2">
                    {currentStepItem.icon}
                  </div>
                  <div className="flex-1">
                    <div className="font-bold text-2xl">
                      {currentStepItem.label}
                    </div>
                    <div className="text-gray-600">
                      {currentStepItem.description}
                    </div>
                  </div>
                </div>
                {!isCurrentStepCompleted && (
                  <>
                    <div className="p-8 flex flex-col justify-center items-center">
                      <Button
                        className="font-bold"
                        type="primary"
                        icon={<i className="ri-play-fill"></i>}
                        size="large"
                        onClick={() =>
                          changePanelState(AddChatWidget, true, {})
                        }
                      >
                        Begin Set up
                      </Button>
                    </div>
                    <div className="flex flex-row justify-end items-center gap-2">
                      <Button
                        // className="font-bold"
                        type="text"
                        icon={<i className="ri-arrow-right-fill"></i>}
                        onClick={() => setCurrentStep(currentStep + 1)}
                      >
                        Skip
                      </Button>
                      <Button
                        // className="font-bold"
                        type="text"
                        icon={<i className="ri-check-line"></i>}
                        onClick={() => setCurrentStep(currentStep + 1)}
                      >
                        I have already configured
                      </Button>
                    </div>
                  </>
                )}

                {isCurrentStepCompleted && (
                  <>
                    <div className="flex flex-col justify-center items-center gap-4">
                      <i className="ri-checkbox-circle-line text-4xl"></i>
                      <div className="">
                        You have successfully created a live chat connection
                      </div>
                      <Button
                        onClick={() => {
                          if (currentStepConnection) {
                            dispatch(
                              push(
                                `/configurations/connections/${currentStepConnection.id}/live-chat-widget`,
                              ),
                            );
                          }
                        }}
                        type="dashed"
                        // icon={<i className="ri-file-upload-line"></i>}
                      >
                        Reconfigure Widget
                      </Button>
                    </div>
                    <div className="flex flex-row justify-end items-center gap-2">
                      <Button
                        // className="font-bold"
                        type="text"
                        icon={<i className="ri-arrow-right-fill"></i>}
                        onClick={() => setCurrentStep(currentStep + 1)}
                      >
                        Go to next step
                      </Button>
                    </div>
                    <div className="p-8">
                      <ChatWidgetInstallInstructions
                        connectionId={currentStepConnection?.id!}
                      />
                    </div>
                  </>
                )}

                <div className="flex-1 relative overflow-hidden">
                  <div>
                    <img
                      src="https://clickconnector.com/images/cro/widget.png"
                      alt="Live Chat Widget Preview"
                      className="absolute opacity-50"
                      // style={{ maxHeight: "20vh" }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Email */}
          {currentStepItem?.id === "EMAIL" && (
            <>
              <div className="flex flex-row h-full">
                <div className="p-4 flex flex-col h-full">
                  <div className="flex flex-row bg-blue-100 dark:bg-black rounded-lg p-4 m-4">
                    <div className="icon-container font-bold text-2xl mr-2">
                      {currentStepItem.icon}
                    </div>
                    <div className="flex-1">
                      <div className="font-bold text-2xl">
                        {currentStepItem.label}
                      </div>
                      <div className="text-gray-600">
                        {currentStepItem.description}
                      </div>
                    </div>
                  </div>
                  {!isCurrentStepCompleted && (
                    <>
                      <div className="p-8 flex flex-col justify-center items-center">
                        <Button
                          className="font-bold"
                          type="primary"
                          icon={<i className="ri-play-fill"></i>}
                          size="large"
                          onClick={() =>
                            changePanelState(AddEmailConnection, true, {})
                          }
                        >
                          Begin Set up
                        </Button>
                      </div>
                      <div className="flex-1"></div>
                      <div className="flex flex-row justify-end items-center gap-2">
                        <Button
                          // className="font-bold"
                          type="text"
                          icon={<i className="ri-arrow-right-fill"></i>}
                          onClick={() => setCurrentStep(currentStep + 1)}
                        >
                          Skip
                        </Button>
                        <Button
                          // className="font-bold"
                          type="text"
                          icon={<i className="ri-check-line"></i>}
                          onClick={() => setCurrentStep(currentStep + 1)}
                        >
                          I have already configured
                        </Button>
                      </div>
                    </>
                  )}
                  {isCurrentStepCompleted && (
                    <>
                      <div className="flex flex-col justify-center items-center gap-4">
                        <i className="ri-checkbox-circle-line text-4xl"></i>
                        <div className="">
                          You have successfully created an email connection
                        </div>
                        <Button
                          onClick={() => {
                            if (currentStepConnection) {
                              dispatch(
                                push(
                                  `/configurations/connections/${currentStepConnection.id}/live-chat-widget`,
                                ),
                              );
                            }
                          }}
                          type="dashed"
                          // icon={<i className="ri-file-upload-line"></i>}
                        >
                          Reconfigure Connection
                        </Button>
                      </div>
                      <div className="flex flex-row justify-end items-center gap-2 mt-2">
                        <Button
                          // className="font-bold"
                          type="text"
                          icon={<i className="ri-arrow-right-fill"></i>}
                          onClick={() => setCurrentStep(currentStep + 1)}
                        >
                          Go to next step
                        </Button>
                      </div>
                    </>
                  )}
                </div>

                <div className="flex flex-col justify-center items-center relative overflow-hidden h-full">
                  <div className=""></div>
                  <div style={{ width: 200 }}>
                    <img
                      src="https://clickconnector.com/images/features/shared-email.png"
                      alt="Live Chat Widget Preview"
                      style={{
                        width: 400,
                        position: "absolute",
                        top: 0,
                        maxWidth: 400,
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Facebook */}
          {currentStepItem?.id === "FACEBOOK" && (
            <>
              <div className="h-full flex flex-col">
                <div className="flex flex-row bg-blue-100 dark:bg-black rounded-lg p-4 m-4">
                  <div className="icon-container font-bold text-2xl mr-2">
                    {currentStepItem.icon}
                  </div>
                  <div className="flex-1">
                    <div className="font-bold text-2xl">
                      {currentStepItem.label}
                    </div>
                    <div className="text-gray-600">
                      {currentStepItem.description}
                    </div>
                  </div>
                </div>
                {!isCurrentStepCompleted && (
                  <>
                    <div className="p-8 flex flex-col justify-center items-center">
                      <Button
                        className="font-bold"
                        type="primary"
                        icon={<i className="ri-play-fill"></i>}
                        size="large"
                        onClick={() =>
                          changePanelState(AddFacebookConnection, true, {})
                        }
                      >
                        Begin Set up
                      </Button>
                    </div>
                    <div className="flex flex-row justify-end items-center gap-2">
                      <Button
                        // className="font-bold"
                        type="text"
                        icon={<i className="ri-arrow-right-fill"></i>}
                        onClick={() => setCurrentStep(currentStep + 1)}
                      >
                        Skip
                      </Button>
                      <Button
                        // className="font-bold"
                        type="text"
                        icon={<i className="ri-check-line"></i>}
                        onClick={() => setCurrentStep(currentStep + 1)}
                      >
                        I have already configured
                      </Button>
                    </div>
                  </>
                )}

                {isCurrentStepCompleted && (
                  <>
                    <div className="flex flex-col justify-center items-center gap-4">
                      <i className="ri-checkbox-circle-line text-4xl"></i>
                      <div className="">
                        You have successfully created a Facebook connection
                      </div>
                      <Button
                        onClick={() => {
                          if (currentStepConnection) {
                            dispatch(
                              push(
                                `/configurations/connections/${currentStepConnection.id}/live-chat-widget`,
                              ),
                            );
                          }
                        }}
                        type="dashed"
                        // icon={<i className="ri-file-upload-line"></i>}
                      >
                        Reconfigure Connection
                      </Button>
                    </div>
                    <div className="flex flex-row justify-end items-center gap-2 mt-2">
                      <Button
                        // className="font-bold"
                        type="text"
                        icon={<i className="ri-arrow-right-fill"></i>}
                        onClick={() => setCurrentStep(currentStep + 1)}
                      >
                        Go to next step
                      </Button>
                    </div>
                  </>
                )}

                <div className="flex-1 relative overflow-hidden">
                  <div className="flex flex-row- justify-center items-center">
                    <i className="ri-facebook-box-line text-10xl text-gray-200"></i>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Instagram */}
          {currentStepItem?.id === "INSTAGRAM" && (
            <>
              <div className="h-full flex flex-col">
                <div className="flex flex-row bg-blue-100 dark:bg-black rounded-lg p-4 m-4">
                  <div className="icon-container font-bold text-2xl mr-2">
                    {currentStepItem.icon}
                  </div>
                  <div className="flex-1">
                    <div className="font-bold text-2xl">
                      {currentStepItem.label}
                    </div>
                    <div className="text-gray-600">
                      {currentStepItem.description}
                    </div>
                  </div>
                </div>
                {!isCurrentStepCompleted && (
                  <>
                    <div className="p-8 flex flex-col justify-center items-center">
                      <Button
                        className="font-bold"
                        type="primary"
                        icon={<i className="ri-play-fill"></i>}
                        size="large"
                        onClick={() =>
                          changePanelState(AddInstagramConnection, true, {})
                        }
                      >
                        Begin Set up
                      </Button>
                    </div>
                    <div className="flex flex-row justify-end items-center gap-2">
                      <Button
                        // className="font-bold"
                        type="text"
                        icon={<i className="ri-arrow-right-fill"></i>}
                        onClick={() => setCurrentStep(currentStep + 1)}
                      >
                        Skip
                      </Button>
                      <Button
                        // className="font-bold"
                        type="text"
                        icon={<i className="ri-check-line"></i>}
                        onClick={() => setCurrentStep(currentStep + 1)}
                      >
                        I have already configured
                      </Button>
                    </div>
                  </>
                )}

                {isCurrentStepCompleted && (
                  <>
                    <div className="flex flex-col justify-center items-center gap-4">
                      <i className="ri-checkbox-circle-line text-4xl"></i>
                      <div className="">
                        You have successfully created an instagram connection
                      </div>
                      <Button
                        onClick={() => {
                          if (currentStepConnection) {
                            dispatch(
                              push(
                                `/configurations/connections/${currentStepConnection.id}/live-chat-widget`,
                              ),
                            );
                          }
                        }}
                        type="dashed"
                        // icon={<i className="ri-file-upload-line"></i>}
                      >
                        Reconfigure Connection
                      </Button>
                    </div>
                    <div className="flex flex-row justify-end items-center gap-2 mt-2">
                      <Button
                        // className="font-bold"
                        type="text"
                        icon={<i className="ri-arrow-right-fill"></i>}
                        onClick={() => setCurrentStep(currentStep + 1)}
                      >
                        Go to next step
                      </Button>
                    </div>
                  </>
                )}
                <div className="flex-1 relative overflow-hidden">
                  <div className="flex flex-row- justify-center items-center">
                    <i className="ri-instagram-line text-10xl text-gray-200"></i>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Whatsapp */}
          {currentStepItem?.id === "WHATS_APP_PRIVATE" && (
            <>
              <div className="h-full flex flex-col">
                <div className="flex flex-row bg-blue-100 dark:bg-black rounded-lg p-4 m-4">
                  <div className="icon-container font-bold text-2xl mr-2">
                    {currentStepItem.icon}
                  </div>
                  <div className="flex-1">
                    <div className="font-bold text-2xl">
                      {currentStepItem.label}
                    </div>
                    <div className="text-gray-600">
                      {currentStepItem.description}
                    </div>
                  </div>
                </div>

                {!isCurrentStepCompleted && (
                  <>
                    <div className="p-8 flex flex-col justify-center items-center">
                      <Button
                        className="font-bold"
                        type="primary"
                        icon={<i className="ri-play-fill"></i>}
                        size="large"
                        onClick={() =>
                          changePanelState(AddWhatsappConnection, true, {})
                        }
                      >
                        Begin Set up
                      </Button>
                    </div>
                    <div className="flex flex-row justify-end items-center gap-2">
                      <Button
                        // className="font-bold"
                        type="text"
                        icon={<i className="ri-arrow-right-fill"></i>}
                        onClick={() => setCurrentStep(currentStep + 1)}
                      >
                        Skip
                      </Button>
                      <Button
                        // className="font-bold"
                        type="text"
                        icon={<i className="ri-check-line"></i>}
                        onClick={() => setCurrentStep(currentStep + 1)}
                      >
                        I have already configured
                      </Button>
                    </div>
                  </>
                )}
                {isCurrentStepCompleted && (
                  <>
                    <div className="flex flex-col justify-center items-center gap-4">
                      <i className="ri-checkbox-circle-line text-4xl"></i>
                      <div className="">
                        You have successfully created an Whatsapp connection
                      </div>
                      <Button
                        onClick={() => {
                          if (currentStepConnection) {
                            dispatch(
                              push(
                                `/configurations/connections/${currentStepConnection.id}/live-chat-widget`,
                              ),
                            );
                          }
                        }}
                        type="dashed"
                        // icon={<i className="ri-file-upload-line"></i>}
                      >
                        Reconfigure Connection
                      </Button>
                    </div>
                    <div className="flex flex-row justify-end items-center gap-2 mt-2">
                      <Button
                        // className="font-bold"
                        type="text"
                        icon={<i className="ri-arrow-right-fill"></i>}
                        onClick={() => setCurrentStep(currentStep + 1)}
                      >
                        Go to next step
                      </Button>
                    </div>
                  </>
                )}
                <div className="flex-1 relative overflow-hidden">
                  <div className="flex flex-row- justify-center items-center">
                    <i className="ri-whatsapp-line text-10xl text-gray-200"></i>
                  </div>
                </div>
              </div>
            </>
          )}

          {currentStepItem?.id === "INVITE_USERS" && (
            <div className="h-full flex flex-col">
              <div className="flex flex-row bg-blue-100 dark:bg-black rounded-lg p-4 m-4">
                <div className="icon-container font-bold text-2xl mr-2">
                  {currentStepItem.icon}
                </div>
                <div className="flex-1">
                  <div className="font-bold text-2xl">
                    {currentStepItem.label}
                  </div>
                  <div className="text-gray-600">
                    {currentStepItem.description}
                  </div>
                </div>
              </div>

              <div className="p-4">
                <BulkInviteUsers
                  onComplete={() => setCurrentStep(currentStep + 1)}
                />
                <div className="flex flex-row justify-end items-center gap-2 mt-2">
                  <Button
                    // className="font-bold"
                    type="text"
                    icon={<i className="ri-arrow-right-fill"></i>}
                    onClick={() => setCurrentStep(currentStep + 1)}
                  >
                    Skip
                  </Button>
                  <Button
                    // className="font-bold"
                    type="text"
                    icon={<i className="ri-check-line"></i>}
                    onClick={() => setCurrentStep(currentStep + 1)}
                  >
                    I have already invited
                  </Button>
                </div>
              </div>
            </div>
          )}

          {currentStep === steps.length + 1 && (
            <div>
              <Result
                status="success"
                // <i className="ri-checkbox-circle-line text-8xl"></i>
                icon={<span className="text-8xl">🎉</span>}
                title="Congratulations"
                subTitle={`Your account set up is completed!`}
                extra={[
                  <Button
                    onClick={() => {
                      onChangeVisibility(false);
                    }}
                    type="dashed"
                    // icon={<i className="ri-file-upload-line"></i>}
                  >
                    Take me to home
                  </Button>,
                ]}
              />
            </div>
          )}
        </div>
      </div>
      <DarkModeBg />
    </Modal>
  );
};
