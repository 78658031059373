import { SDK } from "@sdk";
import { iPipelineCard } from "@sdk/pipeline-cards/pipeline-cards-model";

import { Store } from "redux";
import { QueryConfig } from "store/utils/query-config";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { pipelineCardsDefaultQueryConfig } from "./pipeline-cards-default-query";
import { PipelineCardsSlice } from "./pipeline-cards.slice";

export const loadQueryPipelineCards = (
  queryConfig: QueryConfig,
  queryAlias?: string
) => async (store: Store<iStore>, forceReload?: boolean) => {
  queryAlias = queryAlias || JSON.stringify(queryConfig);
  await loadPipelineCardsQuery(queryConfig, queryAlias)(store, forceReload);
};

export const {
  loadEntityQueries: loadPipelineCardsQuery,
  loadEntityById: loadPipelineCardById,
  loadMoreEntityQueries: loadMorePipelineCardsQueries
} = CreateHelpersForEntity<iStore, iPipelineCard>({
  sliceName: "pipelineCards",
  slice: PipelineCardsSlice,
  isPaginatedQueries: true,
  fetchPageSize: 50,
  loadEntityQueryProvider: query => SDK.pipelineCards.query(query),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.pipelineCards.getById(entityId)
});

export const loadCardsByContactId = (contactId: string) => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  const queryConfig = {
    ...pipelineCardsDefaultQueryConfig,
    query: {
      "references.contactId": contactId
    }
  };
  const queryAlias = JSON.stringify(queryConfig);
  await loadPipelineCardsQuery(queryConfig, queryAlias)(store, forceReload);
};
