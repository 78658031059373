import React from "react";
import { useSelector } from "react-redux";
import { selectCompanyById } from "store/modules/companies/companies.selectors";
import { Avatar } from "./avatar";

export const CompanyAvatar = ({
  companyId,
  ...props
}: {
  companyId: string;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}) => {
  const company = useSelector(selectCompanyById(companyId));
  return (
    <Avatar
      name={company?.data?.name || "Unknown Company"}
      avatarUrl={company?.data?.logo}
      {...props}
    />
  );
};
