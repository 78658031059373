import classnames from "classnames";
import { SimpleCardSection } from "../simple-carded-section";
import { PerfectScrollbar } from "../styled-scroll-area";

export const SimpleScrollableLeftSidePanel = ({
  children,
  className
}: {
  children;
  className?: string;
}) => {
  return (
    <PerfectScrollbar
      className={classnames(className, "flex flex-col justify-between")}
      style={{ width: "22rem" }}
    >
      <SimpleCardSection>{children}</SimpleCardSection>
    </PerfectScrollbar>
  );
};
