import { Modal } from "antd";
import { DarkModeBg } from "dark-mode-bg";
import { useRef, useState } from "react";
import ArticleEditorNew from "./article-editor-new";
import "./article-editor.modal.scss";

export const ArticleEditorModal = ({
  visible,
  onChangeVisibility,
  articleId,
  collectionId,
  onArticleCreated,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  articleId: string;
  collectionId: string;
  onArticleCreated: (articleId: string) => any;
}) => {
  const ArticleEditorRef = useRef<any>();
  const [isSaving, setSaving] = useState(false);

  return (
    <Modal
      // title={
      //   <div className="flex flex-row justify-end items-center">
      //     <Button
      //       icon={<i className="ri-save-line"></i>}
      //       type="primary"
      //       className="font-bold"
      //       onClick={async () => {
      //         try {
      //           setSaving(true);
      //           await ArticleEditorRef.current.saveArticle();
      //           setSaving(false);
      //         } catch (e) {
      //           setSaving(false);
      //         }
      //       }}
      //     >
      //       Save
      //     </Button>
      //   </div>
      // }
      title={null}
      open={visible}
      onCancel={() => onChangeVisibility(false)}
      okText="Save"
      okButtonProps={{
        loading: isSaving,
        icon: <i className="ri-save-line"></i>,
        className: "font-bold",
      }}
      onOk={async () => {
        try {
          setSaving(true);
          const saveStatus = await ArticleEditorRef.current.saveArticle();
          setSaving(false);
          if (saveStatus) {
            onChangeVisibility(false);
            if (!articleId) {
              setTimeout(() => {
                ArticleEditorRef.current?.resetForm?.();
              }, 800);
            }
          }
        } catch (e) {
          setSaving(false);
        }
      }}
      width={730}
      className="article-editor-modal"
      data-click-context="Article Editor Modal"
      bodyStyle={{ padding: 0 }}
    >
      <ArticleEditorNew
        articleId={articleId}
        onArticleCreated={onArticleCreated}
        ref={ArticleEditorRef}
        collectionId={collectionId}
      />
      <DarkModeBg />
    </Modal>
  );
};
