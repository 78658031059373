import MailOutlined from "@ant-design/icons/MailOutlined";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Form, Input, Modal, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectCurrentUserId
} from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { debouncedEmailAvailabilityWithCurrentEmail } from "../../../helpers/email-availability";

export const ChangeMyEmailModal = ({
  visible,
  onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const [form] = useForm();

  const currentUserId = useSelector(selectCurrentUserId);
  const user = useSelector(selectCurrentUser);

  const currentOrganization = useSelector(selectOrganization);

  const { doAction: onSubmit, isProcessing, response } = useSDKActionWithDeps(
    () => ({
      action: SDK => data =>
        SDK.editUser(currentUserId, data).then(d => {
          onChangeVisibility(false);
        }),
      successMessage: "Email has been changed",
      failureMessage: "Something went wrong"
    }),
    [currentUserId, onChangeVisibility]
  );

  return (
    <Modal
      title={
        <ModalTitle
          title="Change Email"
          icon={<i className="ri-shield-user-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={400}
      data-click-context="Change Email Modal"
    >
      <div className="content flex-1">
        <Form layout="vertical" form={form} style={{ padding: 24 }}>
          <Form.Item
            name="email"
            hasFeedback
            validateFirst={true}
            rules={[
              { type: "email" },
              ({ getFieldValue }) => ({
                validator: async (rule, value) =>
                  await debouncedEmailAvailabilityWithCurrentEmail(
                    user.credentials.email,
                    value,
                    currentOrganization?.id
                  )
              })
            ]}
            label="Email"
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Email"
              size="large"
            />
          </Form.Item>
        </Form>
        <div className="flex flex-row justify-end items-center p-4">
          <Button
            type="primary"
            loading={isProcessing}
            onClick={async () => {
              try {
                await form.validateFields();
                const values = form.getFieldsValue();
                onSubmit({
                  credentials: {
                    email: values.email
                  }
                });
              } catch (e) {
                message.error("Please check your input");
              }
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
