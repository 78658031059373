export const AdditionalDetailsBlock = ({ html }: { html: string }) => {
  console.log("html", html);
  if (!html) {
    return (
      <div className="flex flex-row justify-center items-center">
        No Content Added
      </div>
    );
  }
  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
};
