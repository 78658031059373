import { useSDK } from "@sdk";
import { Button, Divider, message } from "antd";
import { BuildViewQuery } from "components/common/filter-schema/build-view-config";
import { FilterBar } from "components/common/filter-schema/filter-bar";
import { iFilterOption } from "components/common/filter-schema/filter-config-schema";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { getContactName } from "components/modules/crm/contacts/helpers/get-contact-name";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import dayjs from "dayjs";
import _ from "lodash";

import { Suspense, useCallback, useMemo } from "react";
import { useStore } from "react-redux";
import { selectConnectionsMap } from "store/modules/connections/connections.selectors";
import { DefaultConversationQueryConfig } from "store/modules/ui-state/ui-state.slice";
import { selectUsersMap } from "store/modules/users/users.selectors";
import { exportCSVFile } from "utils/export-as-csv";
import { useURLData } from "utils/hooks/use-url-filter";
import { GenerateQueryFromReportFilter } from "../../helpers/generate-query-from-report-filter";
import { ConversationsReportsBackendSchema } from "../../schemas/backend-report-schemas/conversation-report-backend-schema";

import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React from "react";
import { ReportsFilterSchema } from "../../schemas/report-filter-schemas";

const PivotTable = React.lazy(() => import("../pivot-tables/pivot-table"));

const filterSchema = ReportsFilterSchema;

export const RawReports = () => {
  const [_selectedFilters, _setSelectedFilters] = useURLData(
    "report-filter",
    // reportCanvas.filters as iFilterOption[],
    // Removing the saved filters
    [] as iFilterOption[]
  );

  const selectedFilters = useMemo(() => {
    const dateFilter = _selectedFilters.find(item => item.key === "period");
    if (!dateFilter) {
      return [..._selectedFilters, filterSchema.generateDefaultFilter!()];
    }
    return _selectedFilters;
  }, [_selectedFilters]);

  const setSelectedFilters = useCallback(
    (_filters: iFilterOption[]) => {
      const filters = [..._filters];
      const dateFilter = filters.find(item => item.key === "period");
      if (!dateFilter) {
        message.warning("Reports require date filter");
        return;
      }
      _setSelectedFilters(filters);
    },
    [_setSelectedFilters]
  );

  const store = useStore();

  const query = useMemo(() => {
    const filterSpec = (selectedFilters || []).filter(
      item => item.key !== "period"
    );

    const dateFilter = (() => {
      let dateField = "createdTime";
      const periodFilterSpec = (selectedFilters || []).find(
        item => item.key === "period"
      );

      const dateSpec = (periodFilterSpec?.operatorConfig.value as number[]) || [
        dayjs()
          .subtract(7, "day")
          .valueOf(),
        dayjs().valueOf()
      ];
      if (periodFilterSpec) {
        const periodFilterSchema = _.find(filterSchema.filters, {
          key: periodFilterSpec.key
        });
        const operatorConfig = _.find(
          periodFilterSchema?.fieldOptions?.operators,
          {
            id: periodFilterSpec.operator
          }
        );
        const periodQueryObj = operatorConfig?.queryGenerator?.(
          dateSpec,
          store.getState()
        );
        if (periodQueryObj) {
          // There will be only one field
          dateField = Object.keys(periodQueryObj)[0];
        }
      }

      return {
        dateField: dateField,
        startDate:
          dateSpec && typeof dateSpec[0] === "string"
            ? dayjs(dateSpec[0]).valueOf()
            : dateSpec[0].valueOf(),
        endDate:
          dateSpec && typeof dateSpec[1] === "string"
            ? dayjs(dateSpec[1]).valueOf()
            : dateSpec[1].valueOf()
      };
    })();

    const reportFilter = BuildViewQuery({
      selectedFilters: [...filterSpec],
      defaultQuery: {} as any,
      schema: filterSchema,
      store: store
    }).query;

    const filter = GenerateQueryFromReportFilter(
      reportFilter,
      ConversationsReportsBackendSchema,
      true
    );

    filter[
      ConversationsReportsBackendSchema.dateFilterTypes[dateFilter.dateField]
    ] = {
      $gte: dayjs(dateFilter.startDate).valueOf(),
      $lt: dayjs(dateFilter.endDate).valueOf()
    };

    return {
      ...DefaultConversationQueryConfig,
      query: {
        ...filter
      },
      options: {
        ...DefaultConversationQueryConfig.options,
        limit: 1000
        // sortBy: "-metaData.lastMessage.timestamp",
      }
    };
  }, [selectedFilters, store]);

  const { data: conversationsRes } = useSDK(
    SDK => SDK.queryConversations(query),
    [query],
    false,
    {}
  );

  const tableData = useMemo(() => {
    const connectionsMap = selectConnectionsMap(store.getState());
    const usersMap = selectUsersMap(store.getState());
    return (conversationsRes?.docs || []).map(conversation => ({
      id: conversation.id,
      Connection:
        connectionsMap[conversation.connectionId]?.label ||
        "Unknown Connection",
      "Contact Name": getContactName(conversation.contact as any),
      "Created At": dayjs(conversation.metaData.createdAt).format("DD-MM-YYYY"),
      Status: conversation.status,
      Tags: conversation.tags.join(", "),
      "Assigned Users": conversation.members
        .map(item => item.userId)
        .map(userId => getUserName(usersMap[userId]))
        .join(", ")
    }));
  }, [conversationsRes.docs, store]);

  const pivotTableData = useMemo(() => {
    return {
      rows: ["Assigned Users"],
      cols: ["Connection", "Tags", "Status"],
      hiddenAttributes: ["id"]
    };
  }, []);

  return (
    <StyledScrollArea className="w-full h-full flex-1 animated fadeInLeftMin">
      <SimpleCardSection className="m-auto p-8 lg:p-12 my-4">
        <div className="p-10">
          <SectionHeader
            title={"Raw Report Generator"}
            icon={<i className="ri-archive-drawer-line"></i>}
            addons={
              <>
                {tableData.length > 0 && (
                  <Button
                    type="text"
                    onClick={() => {
                      exportCSVFile(tableData, "raw-report-export");
                    }}
                    icon={<i className="ri-download-2-line"></i>}
                  >
                    Export as CSV
                  </Button>
                )}
              </>
            }
          />
          <Divider className="m-0" />
          <div className="filters">
            <FilterBar
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              schema={filterSchema}
            ></FilterBar>
          </div>
          <Divider />
          <div className="body">
            <Suspense fallback={<SuspenseLoadingIndicator />}>
              <PivotTable data={tableData} {...pivotTableData} />
            </Suspense>

            {/* <SimplePivotTable data={tableData} /> */}
          </div>
        </div>
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
