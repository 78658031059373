import { SDK } from "@sdk";

import { iCampaign } from "@sdk/campaigns/campaigns-model";
import { Store } from "redux";
import { QueryConfig } from "store/utils/query-config";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { campaignsDefaultQueryConfig } from "./campaigns-default-query";
import { CampaignsSlice } from "./campaigns.slice";

export const loadAllCampaigns = () => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  await loadCampaignsQuery(campaignsDefaultQueryConfig, "all")(
    store,
    forceReload
  );
};

export const loadQueryCampaigns = (
  queryConfig: QueryConfig,
  queryAlias?: string
) => async (store: Store<iStore>, forceReload?: boolean) => {
  queryAlias = queryAlias || JSON.stringify(queryConfig);
  await loadCampaignsQuery(queryConfig, queryAlias)(store, forceReload);
};

export const {
  loadEntityQueries: loadCampaignsQuery,
  loadEntityById: loadCampaignById,
  loadMoreEntityQueries: loadMoreCampaignsQueries
} = CreateHelpersForEntity<iStore, iCampaign>({
  sliceName: "campaigns",
  slice: CampaignsSlice,
  isPaginatedQueries: true,
  fetchPageSize: 50,
  loadEntityQueryProvider: query => SDK.campaigns.query(query),
  loadEntityByIdProvider: (entityId: string) => SDK.campaigns.getById(entityId)
});
