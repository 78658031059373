import { axios, AxiosResponse } from "@sdk/axios";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { iWidget } from "./chat-widgets.models";

export class ChatWidgetServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  chatWidgets = Object.assign(
    new EntityServices<iWidget>(this.config, "widgets"),
    {
      sendScriptToDeveloper: async (widgetId: string, email: string) => {
        UserTracker.track("WIDGETS - Send Script to Developer", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/widgets/${widgetId}/email-to-developer`,
          {
            email,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      getPageScreenshot: async (widgetId: string, url: string) => {
        const results: AxiosResponse<{
          base64: string;
        }> = await axios.post(
          this.config.basePath + `/widgets/${widgetId}/get-page-screenshot`,
          {
            url,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      getPageContent: async (widgetId: string, url: string) => {
        const results: AxiosResponse<{
          content: string;
        }> = await axios.post(
          this.config.basePath + `/widgets/${widgetId}/get-page-content`,
          {
            url,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
