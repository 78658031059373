import { SDK } from "@sdk";
import { GooglePlaceDetailRes } from "@sdk/app-service/google-places-api-model";
import { Input, Spin } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { useEffect, useRef, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { GoogleMapPlacesObj } from "../itineries-model";

export const PlaceSelector = ({
  value,
  onChange,
  type,
  size,
  placeholder
}: {
  value?: GooglePlaceDetailRes["result"];
  onChange?: (val: GooglePlaceDetailRes["result"]) => any;
  type: "lodging" | "establishment" | "cities";
  size?: SizeType;
  placeholder?: string;
}) => {
  const [inputValue, setInputValue] = useState("");
  const antInputRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { ref: antRef } = usePlacesWidget({
    apiKey: "AIzaSyDgKBIsjuhnfxjV-7Vfv8q7P59AWTVopLo",
    onPlaceSelected: (place: GoogleMapPlacesObj) => {
      const process = async () => {
        setIsLoading(true);
        const placeData = await SDK.getPlaceDetails(place.place_id);
        onChange &&
          onChange({
            // ...place,
            // geometry: {
            //   location: {
            //     lat: (place.geometry.location.lat as any)(),
            //     lng: (place.geometry.location.lng as any)(),
            //   },
            // },
            ...placeData.result
          });
        setIsLoading(false);
      };
      process();
    },
    options:
      type === "cities"
        ? { type: "cities" }
        : type === "establishment"
        ? {
            types: ["establishment"]
          }
        : {
            types: ["lodging"]
          }
  });

  useEffect(() => {
    if (value) {
      setInputValue(value?.formatted_address);
    }
  }, [value]);

  return (
    <Spin spinning={isLoading} indicator={<LoadingIndicatorWithSpin />}>
      <Input
        ref={c => {
          antInputRef.current = c;
          if (c) (antRef as any).current = c.input;
        }}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        size={size}
        placeholder={placeholder}
      />
    </Spin>
  );
};
