// export function LightenDarkenColor(colorCode: string, amount: number) {
//   var usePound = false;

//   if (colorCode[0] === "#") {
//     colorCode = colorCode.slice(1);
//     usePound = true;
//   }

//   var num = parseInt(colorCode, 16);

//   var r = (num >> 16) + amount;

//   if (r > 255) {
//     r = 255;
//   } else if (r < 0) {
//     r = 0;
//   }

//   var b = ((num >> 8) & 0x00ff) + amount;

//   if (b > 255) {
//     b = 255;
//   } else if (b < 0) {
//     b = 0;
//   }

//   var g = (num & 0x0000ff) + amount;

//   if (g > 255) {
//     g = 255;
//   } else if (g < 0) {
//     g = 0;
//   }

//   return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
// }

export function LightenDarkenColor(color: string, amount: number) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2),
      )
  );
}
