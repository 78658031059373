import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Form, Input, Modal, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { ModalTitle } from "components/common/modal-title";

import { useSelector } from "react-redux";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { PasswordStrengthIndicators } from "./password-strength-indicator";

export const ChangeMyPasswordModal = ({
  visible,
  onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const currentUserId = useSelector(selectCurrentUserId);

  const [form] = useForm();

  const {
    doAction: resetPassword,
    isProcessing: isResetPasswordProcessing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (password: string) =>
        SDK.editUser(currentUserId, {
          credentials: {
            password: password
          }
        }).then(d => {
          onChangeVisibility(false);
        }),
      successMessage: "Password has been reset",
      failureMessage: "Something went wrong"
    }),
    [currentUserId, onChangeVisibility]
  );

  return (
    <Modal
      title={
        <ModalTitle
          title="Change Password"
          icon={<i className="ri-shield-user-line"></i>}
        />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      onOk={async () => {
        try {
          await form.validateFields();
          resetPassword(form.getFieldValue("password"));
        } catch (e) {
          message.error("Please check your input");
        }
      }}
      okButtonProps={{
        icon: <i className="ri-check-line"></i>,
        className: "font-bold"
      }}
      okText="Update Password"
      width={400}
      data-click-context="Change Password Modal"
    >
      <div className="content flex-1 overflow-auto">
        <Form layout="vertical" form={form}>
          <div>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!"
                }
              ]}
              className="flex-1"
            >
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
            <PasswordStrengthIndicators />
          </div>
        </Form>
      </div>
    </Modal>
  );
};
