export function roundTime(
  time: number,
  method: "round" | "ceil" | "floor" = "round",
  minutes: number = 30
) {
  var timeToReturn = new Date(time);
  timeToReturn.setMilliseconds(
    Math.round(timeToReturn.getMilliseconds() / 1000) * 1000
  );
  timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(
    Math[method](timeToReturn.getMinutes() / minutes) * minutes
  );
  return timeToReturn.getTime();
}
