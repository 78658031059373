import { Tag } from "antd";
import { AvatarGroup, UserAvatar } from "components/common/avatar/avatar";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { loadContactById } from "store/modules/contacts/contacts.helpers";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectPipelineCardById } from "store/modules/pipeline-cards/pipeline-cards.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { AddEllipsis } from "utils/add-ellipsis";
import { useUrlState } from "utils/hooks/use-url-state";
import { convertHtmlToPlainText } from "utils/html-to-plain-text";

export const PipelineCard = ({
  pipelineCardId,
  onClick,
}: {
  pipelineCardId: string;
  onClick?: (opportunityId: string) => any;
}) => {
  const [selectedCard, _setSelectedCard] = useUrlState("card");

  const onSelect = useCallback(
    (pipelineCardId: string) => {
      if (onClick) {
        onClick(pipelineCardId);
      } else {
        _setSelectedCard(pipelineCardId);
      }
    },
    [_setSelectedCard, onClick]
  );

  const pipelineCard = useSelector(selectPipelineCardById(pipelineCardId));

  const isDueDatePassed = useMemo(() => {
    const currentTime = Date.now();
    if (pipelineCard?.dueDate) {
      if (currentTime > pipelineCard?.dueDate) {
        return true;
      }
    }
    return false;
  }, [pipelineCard?.dueDate]);

  const { state: contact } = useQueryWithStore(
    selectContactById(pipelineCard?.references?.contactId!),
    loadContactById(pipelineCard?.references?.contactId!)(),
    [pipelineCard?.references?.contactId!],
    !pipelineCard?.references?.contactId!
  );

  if (!pipelineCard) {
    return <div>Loading</div>;
  }
  return (
    <div className="p-2 flex flex-row" onClick={() => onSelect(pipelineCardId)}>
      <div className="mb-8 mt-1">
        <ContactAvatar contactId={contact?.id!} size={40} />
      </div>
      <div className="flex flex-col pl-4 flex-1">
        <div className="font-bold text-xs">{contact?.data.firstName}</div>
        <div className="title font-bold">{pipelineCard?.label}</div>
        <div className="text-gray-600">
          {AddEllipsis(convertHtmlToPlainText(pipelineCard?.body || " "), 20)}
        </div>
        <div className="footer flex flex-row justify-between items-center">
          <div className="left flex flex-row items-center">
            <div className="owners">
              <AvatarGroup>
                {(pipelineCard.assignedTo || []).map((member) => (
                  <div className="avatar-container" key={member.userId}>
                    <UserAvatar
                      userId={member.userId}
                      key={member.userId}
                      enableToolTip
                      size={20}
                    />
                  </div>
                ))}
              </AvatarGroup>
            </div>
          </div>
          <div className="left flex flex-row items-center">
            <div className="tags-container">
              {(pipelineCard.tags || []).map((tag) => (
                <Tag color={"blue"}>{tag}</Tag>
              ))}
            </div>
            <div className="due-date">
              <Tag color={isDueDatePassed ? "red" : "blue"}>
                {dayjs(pipelineCard.dueDate).format("DD-MM-YYYY")}
              </Tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
