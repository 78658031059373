export const ChatBotUseCases = [
  {
    icon: "💰",
    label: "Boost Sales",
    description:
      "Chatbot Templates to increase your sales. Offers, promos, prompts and more..."
  },
  {
    icon: "🧲",
    label: "Generate Leads",
    description:
      "Chatbot Templates to generate leads for your products and services"
  },
  {
    icon: "📂",
    label: "Collect Feedback",
    description:
      "Chatbot Templates to collect feedback about your product and services"
  },
  {
    icon: "📝",
    label: "Surveys",
    description: "Chatbot Templates to collect data from your audience"
  },
  {
    icon: "ℹ️",
    label: "Customer Support",
    description:
      "Chatbot Templates to answer questions and queries that your customers may have"
  }
];
