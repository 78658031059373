import { Edge, Node } from "react-flow-renderer";
import { AvailableNodes } from "./data-model";

export const initialNodes: Node<AvailableNodes>[] = [
  {
    id: "START",
    type: "MESSAGE_NODE",
    // dragHandle: ".cc-drag-handle",
    data: {
      text:
        "Hey 👋, Welcome to Click Connector. I am delighted that I got a chance to help you. What would you like to know today?",
      quickButtons: [
        {
          id: "1",
          label: "Book Demo"
        },
        {
          id: "2",
          label: "Speak to Sales"
        }
      ],
      persistence: {},
      validation: {}
    },
    position: { x: 0, y: 0 }
  }
  // {
  //   id: "sfdf",
  //   type: "MESSAGE_NODE",
  //   data: {
  //     text: "fsdafdsf",
  //     quickButtons: [],
  //     configurations: {},
  //   },
  //   position: { x: 0, y: 0 },
  // },
  // {
  //   id: "sf32323df",
  //   type: "MESSAGE_NODE",
  //   data: {
  //     text: "fsdafsdffdsf",
  //     quickButtons: [],
  //     configurations: {},
  //   },
  //   position: { x: 0, y: 0 },
  // },
];

export const initialEdges: Edge[] = [];
