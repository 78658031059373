import { SDK } from "@sdk";
const debounce = require("debounce-promise");

export const debouncedEmailAvailability = debounce(
  async (email: string, organizationId?: string) => {
    try {
      // No need to check Email Availability
      const status = await SDK.checkEmailAvailability(email, organizationId);
      if (!status.availability) {
        throw "Email is already taken";
      }
      return;
    } catch (e) {
      throw "Email is already taken";
    }
  },
  1000,
  { leading: false, trailing: true }
);

export const debouncedEmailAvailabilityWithCurrentEmail = debounce(
  async (currentEmail, email, organizationId?: string) => {
    try {
      if (currentEmail === email) {
        return;
      }
      // No need to check Email Availability
      const status = await SDK.checkEmailAvailability(email, organizationId);
      if (!status.availability) {
        throw "Email is already taken";
      }
      return;
    } catch (e) {
      throw "Email is already taken";
    }
  },
  1000,
  { leading: false, trailing: true }
);
