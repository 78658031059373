import { Input, Modal } from "antd";
import { UserAvatar } from "components/common/avatar/avatar";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useMemo, useState } from "react";
import { loadAllUsers } from "store/modules/users/users.helpers";
import { selectAllActiveUsersQuery } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSearch } from "utils/hooks/use-search";
import "./user-picker-modal.scss";

export const UserPickerModal = ({
  visible,
  onChangeVisibility,
  onUserSelected,
  title
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onUserSelected: (userId: string) => any;
  title?: JSX.Element;
}) => {
  const [selectedUser, setSelectedUser] = useState("");
  const {
    state: { list: allUsers, isLoading },
    retry: reload
  } = useQueryWithStore(selectAllActiveUsersQuery, loadAllUsers());

  const userList = useMemo(
    () =>
      allUsers.map(user => ({
        id: user.id,
        name: `${user.data.firstName} ${user.data.lastName}`,
        email: user.credentials.email
      })),
    [allUsers]
  );

  const { setSearchTerm, searchTerm, filteredData } = useSearch(userList);
  return (<>
    <Modal
      title={
        title || (
          <ModalTitle
            title="Select a user"
            icon={<i className="ri-group-line"></i>}
          />
        )
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save"
      data-click-context="User Picker Modal"
    >
      <div className="flex flex-col w-full user-picker">
        <div className="search-container">
          <Input.Search
            placeholder="Search..."
            size="large"
            className="rounded-md mb-8"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="users-container">
          {filteredData.map(user => (
            <div
              className="flex flex-row items-center p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-900 border-b border-gray-200 dark:border-gray-800 mode_transition"
              onClick={() => {
                onUserSelected(user.id);
                onChangeVisibility(false);
              }}
              key={user.id}
            >
              <div className="avatar-container">
                <UserAvatar userId={user.id} />
              </div>
              <div className="texts flex flex-col flex-1 ml-2">
                <div className="name font-bold">{user.name}</div>
                <div className="email">{user.email}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <DarkModeBg />
    </Modal>
  </>);
};
