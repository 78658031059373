export const FinePrintBlock = ({ items }: { items: string[] }) => {
  return (
    <div className="border border-gray-200 dark:border-gray-800 rounded-lg w-full p-8 m-4 flex flex-col justify-center">
      {(items || []).map(item => (
        <div key={item} className="">
          👉 {item}
        </div>
      ))}
    </div>
  );
};
