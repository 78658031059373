import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { Button, Card } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { PerfectScrollbar } from "components/common/perfect-scroll-bar";
import { CCBadge } from "components/pages/home/all-badges-modal";
import { groupBy } from "lodash";
import { AllBadges } from "modal-registry";
import { useMemo } from "react";
import { loadAllBadges } from "store/modules/badges/badges.helpers";
import { selectAllBadges } from "store/modules/badges/badges.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const MyBadges = () => {
  const { changePanelState } = useModalPanels();

  const { state: badges } = useQueryWithStore(
    selectAllBadges,
    loadAllBadges,
    []
  );
  const badgeMap = useMemo(() => {
    return groupBy(badges, "badgeId");
  }, [badges]);

  return (
    <Card
      title={
        <div className="flex flex-row justify-between items-center">
          <ModalTitle
            title="Badges"
            icon={<i className="ri-medal-2-line"></i>}
          />
          <Button
            type="text"
            icon={<i className="ri-eye-line"></i>}
            onClick={() => {
              changePanelState(AllBadges, true, {});
            }}
          >
            View All
          </Button>
        </div>
      }
      bodyStyle={{ padding: 0 }}
      className="dark:bg-gray-950"
    >
      <PerfectScrollbar style={{ height: 300 }}>
        <div className="flex flex-row flex-wrap gap-4 p-8">
          {badges.map(item => (
            <CCBadge
              badgeId={item.badgeId}
              key={item.id}
              width={120}
              hideLabels={true}
            />
          ))}
        </div>
      </PerfectScrollbar>
    </Card>
  );
};
