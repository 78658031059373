import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { setContact } from "store/modules/contacts/contacts.slice";
import { CustomDataContainer } from "../custom-data-container/custom-data-container";

const emptyData = {};

export const CustomContactData = memo(
  ({ contactId }: { contactId: string }) => {
    const contact = useSelector(selectContactById(contactId));

    const {
      doAction: onEditContact,
      isProcessing,
      response,
      dispatch
    } = useSDKActionWithDeps(
      () => ({
        action: SDK => (customProperties: Record<string, string>) =>
          SDK.editContact(contactId, {
            data: {
              customProperties
            }
          }).then(d => {
            dispatch(setContact(d));
          }),
        successMessage: "Done",
        failureMessage: "Something went wrong"
      }),
      [contactId]
    );

    return (
      <CustomDataContainer
        data={contact?.data.customProperties || emptyData}
        onSave={onEditContact}
      />
    );
  }
);
