import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
import { AutoComplete, Button, Card, Input } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { Avatar } from "components/common/avatar/avatar";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { loadAllUsers } from "store/modules/users/users.helpers";
import {
  selectAllActiveUsersQuery,
  selectUser,
} from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSearch } from "utils/hooks/use-search";
import { UserPill } from "./user-selector-pill";

const { Meta } = Card;

export const SingleUserSelector = ({
  value: selectedUserId,
  onChange: setSelectedUserId = () => {},
  placeholder = "Search Users...",
  size = "middle",
  autoFocus,
}: {
  value?: string;
  onChange?: (contact: string) => any;
  placeholder?: string;
  size?: SizeType;
  autoFocus?: boolean;
}) => {
  const {
    state: { list: allUsers, isLoading },
    retry: reload,
  } = useQueryWithStore(selectAllActiveUsersQuery, loadAllUsers());

  const userList = useMemo(
    () =>
      allUsers.map((user) => ({
        id: user.id,
        name: `${user.data.firstName} ${user.data.lastName}`,
        email: user.credentials.email,
      })),
    [allUsers]
  );

  const { setSearchTerm, searchTerm, filteredData } = useSearch(userList);

  const selectedUser = useSelector(selectUser(selectedUserId));

  return (
    <>
      {selectedUserId && (
        <div className="hover:bg-gray-100 dark:hover:bg-gray-900 rounded-lg my-2 p-2 flex flex-row items-center justify-between mode_transition">
          <Meta
            avatar={<Avatar name={selectedUser.data.firstName!} size={30} />}
            title={`${selectedUser.data.firstName} ${selectedUser.data.lastName}`}
            description={`${selectedUser.credentials.email}`}
            className="m-0 flex flex-row gap-4"
          />
          <Button
            type="link"
            // size="large"
            icon={<CloseCircleOutlined size={20} />}
            shape="circle"
            onClick={() => setSelectedUserId(null as any)}
          />
        </div>
      )}
      {!selectedUserId && (
        <div className="flex flex-row items-center">
          <AutoComplete
            popupClassName="certain-category-search-dropdown"
            // dropdownMatchSelectWidth={500}
            // style={{ width: 200 }}
            onSelect={(selectedUserId, option) => {
              setSelectedUserId(selectedUserId);
            }}
            // open={true}
            options={(filteredData || []).map((user) => ({
              value: user.id!,
              data: user,
              label: <UserPill name={user.name} email={user.email} />,
            }))}
            autoFocus
            defaultOpen={autoFocus}
          >
            <Input.Search
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={placeholder}
              size={size}
            />
          </AutoComplete>
        </div>
      )}
    </>
  );
};
