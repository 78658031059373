export const ExtensionsFileMap = {
  ai: "graphics_editor",
  psd: "graphics_editor",
  asf: "video",
  mov: "video",
  qt: "video",
  movie: "video",
  au: "audio",
  aif: "audio",
  aiff: "audio",
  avi: "video",
  bmp: "image",
  dib: "image",
  cdr: "graphics_editor",
  aifc: "audio",
  csv: "spreadsheet",
  eps: "graphics_editor",
  epsf: "graphics_editor",
  gan: "project_management",
  xcf: "graphics_editor",
  gif: "image",
  ico: "image",
  jfif: "image",
  jpe: "image",
  jpeg: "image",
  jpg: "image",
  key: "presentation",
  "key-tef": "presentation",
  kth: "presentation",
  apxl: "presentation",
  mp3: "audio",
  mpv2: "video",
  mp4: "video",
  mpe: "video",
  mpeg: "video",
  mpg: "video",
  rmi: "audio",
  xls: "spreadsheet",
  xlsx: "spreadsheet",
  xlt: "spreadsheet",
  xltx: "spreadsheet",
  xlw: "spreadsheet",
  xla: "spreadsheet",
  xlsm: "spreadsheet",
  xlsb: "spreadsheet",
  xltm: "spreadsheet",
  xlam: "spreadsheet",
  ppt: "presentation",
  pptx: "presentation",
  pps: "presentation",
  ppsx: "presentation",
  ppa: "presentation",
  ppam: "presentation",
  pptm: "presentation",
  ppsm: "presentation",
  potm: "presentation",
  mpp: "project_management",
  mpt: "project_management",
  vsd: "diagramming",
  vst: "diagramming",
  vsw: "diagramming",
  vss: "diagramming",
  doc: "word_processing",
  docx: "word_processing",
  dot: "word_processing",
  dotx: "word_processing",
  dotm: "word_processing",
  docm: "word_processing",
  word: "word_processing",
  w6w: "word_processing",
  mid: "audio",
  numbers: "spreadsheet",
  ods: "spreadsheet",
  ots: "spreadsheet",
  odp: "presentation",
  odt: "word_processing",
  ott: "word_processing",
  oth: "word_processing",
  odm: "word_processing",
  page: "word_processing",
  pbm: "image",
  pdf: "pdf",
  pgm: "image",
  png: "image",
  ppm: "image",
  ps: "graphics_editor",
  rgb: "image",
  ra: "audio",
  ram: "audio",
  rtf: "word_processing",
  svg: "image",
  snd: "audio",
  sketch: "graphics_editor",
  tsv: "spreadsheet",
  tab: "spreadsheet",
  txt: "word_processing",
  text: "word_processing",
  tif: "image",
  tiff: "image",
  wav: "audio",
  wpd: "word_processing",
  webm: "video",
  weba: "audio",
  webp: "image",
  xbm: "image",
  xpm: "image",
  oga: "audio",
};
