import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iEmailConnectionData } from "@sdk/conversations/conversations.models";
import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Avatar,
  DatePicker,
  Divider,
  Input,
  message,
  Modal,
  Spin,
  Switch
} from "antd";
import { UserAvatar } from "components/common/avatar/avatar";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { iMenuItem } from "components/common/side-bar-menu/menu-item-mode";
import { SideBarMenu } from "components/common/side-bar-menu/side-bar-menu";
import { SimpleMenuSectionTitle } from "components/common/side-bar-menu/simple-menu-section-title";
import { DarkModeBg } from "dark-mode-bg";
import dayjs from "dayjs";
import {
  AddEmailConnection,
  ChangeMyEmail,
  ChangeMyPassword,
  ConfigurePersonalMailbox
} from "modal-registry";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import {
  selectCurrentUserId,
  selectUser
} from "store/modules/users/users.selectors";
import { selectFeatureRestrictionConfig } from "store/modules/workspace/workspace.selectors";
import { useSelectorWithMemoize } from "store/utils/use-selector-with-memoize";

import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { EditableTextWithForm } from "components/common/editable-text-with-form/editable-text-with-form";
import { StyledSwitch } from "components/common/styled-swtich";
import { BetaFeatures } from "components/pages/configurations/sections/general-settings/general-settings-home";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { MyActivityReport } from "./components/my-activity-report";
import { MyActivityToday } from "./components/my-activity-today";
import { MyBadges } from "./components/my-badges";
import { MyOverallStats } from "./components/my-overall-stats";
import { MyPerformanceReport } from "./components/my-performance-report";
import "./my-profile-modal.scss";

export const MyProfileModalX = ({
  visible,
  onChangeVisibility,
  onEdited
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onEdited?: () => any;
}) => {
  const userId = useSelector(selectCurrentUserId);
  const user = useSelectorWithMemoize(() => selectUser(userId), [userId]);
  const userRole = useMemo(() => {
    return getRoleFromPermission(user?.permissions || []);
  }, [user?.permissions]);

  const connections = useSelector(selectAllConnections);
  const personalEmailConnection = useMemo(() => {
    return connections.find(
      item =>
        item.type === "EMAIL" &&
        (item.data as iEmailConnectionData).isPersonal &&
        (item.data as iEmailConnectionData).ownerId === userId
    );
  }, [connections, userId]);

  // Avatar Change - Start
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);

  const {
    doAction: uploadAvatar,
    isProcessing: isUploading
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => file =>
        SDK.uploadFile(file, {
          type: "USER",
          entityId: user.id
        })
          .then(fileRecord => {
            setSelectedFile(undefined);
            return SDK.editUser(userId, {
              data: {
                avatar: fileRecord.url
              }
            });
          })
          .catch(e => {
            setSelectedFile(undefined);
            throw e;
          }),
      successMessage: "Avatar has been updated",
      failureMessage: "Something went wrong"
    }),
    [user.id, userId]
  );

  const changeAvatar = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files![0]!;
      if (file) {
        const isJpgOrPng =
          file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
          message.error("You can only upload JPG/PNG file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error("Image must smaller than 2MB!");
        }
        if (isJpgOrPng && isLt2M) {
          setSelectedFile(file);
          uploadAvatar(file);
        }
      }
    },
    [uploadAvatar]
  );

  // Avatar Change - End

  const { changePanelState } = useModalPanels();

  const featureRestrictionConfig = useSelector(selectFeatureRestrictionConfig);
  const [activityDate, setActivityDate] = useState(dayjs());
  const [activityDateRange, setActivityDateRange] = useState([
    dayjs()
      .subtract(7, "day")
      .startOf("day"),
    dayjs().endOf("day")
  ]);

  const MyProfileMenu: iMenuItem[] = useMemo(() => {
    const menu: iMenuItem[] = [
      {
        type: "LINK_ITEM",
        key: "CHANGE_EMAIL",
        icon: <i className="ri-mail-settings-line"></i>,
        disabled: featureRestrictionConfig?.["CHANGE_SELF_EMAIL"]?.[userRole],
        label: "Change Email",
        onClick: () => {
          changePanelState(ChangeMyEmail, true, {});
        }
      },
      {
        type: "LINK_ITEM",
        key: "CHANGE_PASSWORD",
        icon: <i className="ri-lock-line"></i>,
        label: "Change Password",
        onClick: () => {
          changePanelState(ChangeMyPassword, true, {});
        }
      }
    ];

    if (personalEmailConnection) {
      menu.push({
        type: "LINK_ITEM",
        key: "PERSONAL_EMAIL",
        icon: <i className="ri-mail-line"></i>,
        label: "Configure Personal Email",
        onClick: () => {
          changePanelState(ConfigurePersonalMailbox, true, {});
        }
      });
    } else {
      menu.push({
        type: "LINK_ITEM",
        key: "PERSONAL_EMAIL",
        icon: <i className="ri-mail-line"></i>,
        label: "Connect Personal Email",
        onClick: () => {
          changePanelState(AddEmailConnection, true, {
            isPrivate: true
          });
        }
      });
    }

    return menu;
  }, [
    changePanelState,
    featureRestrictionConfig,
    personalEmailConnection,
    userRole
  ]);

  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  return (
    <>
      <Modal
        title={
          <ModalTitle
            title="My Profile"
            icon={<i className="ri-shield-user-line"></i>}
          />
        }
        open={visible}
        footer={null}
        onCancel={() => {
          onChangeVisibility(false);
        }}
        bodyStyle={{ padding: 0 }}
        width={960}
        data-click-context="My Profile Modal"
      >
        <div className="flex flex-col w-full contact-profile bg-gray p-4">
          <div className="body flex flex-row flex-wrap justify-start">
            <div className="left-col">
              <div
                className="bg-white dark:bg-gray-900  rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex py-8"
                style={{ width: 300 }}
              >
                <div className="flex flex-col justify-center items-center">
                  <div className="flex flex-row justify-center items-center my-8 mb-16">
                    <div className="edit-user-avatar">
                      {selectedFile && (
                        <Spin
                          spinning={isUploading}
                          indicator={<LoadingIndicatorWithSpin />}
                        >
                          <Avatar
                            size={80}
                            src={URL.createObjectURL(selectedFile)}
                          />
                        </Spin>
                      )}
                      {!selectedFile && (
                        <UserAvatar userId={userId} size={80} />
                      )}
                      <input type="file" onChange={changeAvatar} />
                      <div className="upload-icon">
                        <i className="ri-upload-2-line"></i>
                      </div>
                    </div>
                  </div>
                  <div className="px-8">
                    <div className="mb-4">
                      <SimpleMenuSectionTitle>
                        My Profile
                      </SimpleMenuSectionTitle>
                      <EditableTextWithForm
                        label={"First Name"}
                        fieldPath={"data.firstName"}
                        entityType="USER"
                        entityId={userId}
                        formLayout="vertical"
                      >
                        <Input
                          placeholder="Enter First Name"
                          className="font-semibold"
                        />
                      </EditableTextWithForm>
                      <EditableTextWithForm
                        label={"Last Name"}
                        fieldPath={"data.lastName"}
                        entityType="USER"
                        entityId={userId}
                        formLayout="vertical"
                      >
                        <Input
                          placeholder="Enter Last Name"
                          className="font-semibold"
                        />
                      </EditableTextWithForm>
                      <EditableTextWithForm
                        label={"Description / Title"}
                        fieldPath={"data.description"}
                        entityType="USER"
                        entityId={userId}
                        formLayout="vertical"
                      >
                        <Input
                          placeholder="Enter a description or a title"
                          className="font-semibold"
                        />
                      </EditableTextWithForm>

                      <EditableTextWithForm
                        label={"Bio"}
                        fieldPath={"data.bio"}
                        entityType="USER"
                        entityId={userId}
                        formLayout="vertical"
                      >
                        <Input.TextArea
                          placeholder="Enter Bio"
                          className="font-semibold"
                          rows={6}
                        />
                      </EditableTextWithForm>
                      <div className="flex flex-row justify-between items-center w-full mt-2">
                        Hide Profile in Chat Widget
                        <EditableTextWithForm
                          fieldPath={"data.isInternalUser"}
                          entityType="USER"
                          entityId={userId}
                          formLayout="vertical"
                          valuePropName="checked"
                        >
                          <Switch />
                        </EditableTextWithForm>
                      </div>
                    </div>
                    <Divider />

                    <SideBarMenu
                      menuItems={MyProfileMenu}
                      onMenuClick={item => {
                        (item as any).onClick();
                      }}
                      selectedKeys={[]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="right-section flex-1">
              {/* Top Bar | Action Bar */}
              <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-row flex px-10 py-2 max-w-screen-xl flex-1 ml-4 justify-end items-center">
                <div className="left">
                  <div className="text text-gray-600 dark:text-gray-400 mode_transition">
                    Using Click Connector since{" "}
                    {dayjs(user.metaData.joined).format("LL")}
                    {/* "DD-MM-YYYY" */}
                  </div>
                </div>
              </div>

              {isAdvancedMode && (
                <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex  px-10 py-2 max-w-screen-xl flex-1 ml-4 mt-4">
                  <ConfigurationEditorComponent
                    icon="ri-gift-line"
                    title="Enable Beta Features"
                    description="Enable access to Insider features"
                    entityType="USER"
                    entityId={userId}
                  >
                    * By default below features are not available to you
                    {BetaFeatures.map((item, index) => {
                      return (
                        <StyledSwitch
                          label={item.label}
                          key={item.key}
                          name={["data", "betaFeatures", item.key]}
                        />
                      );
                    })}
                  </ConfigurationEditorComponent>
                </div>
              )}

              {/*  Summary Card */}
              <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-row flex justify-between items-center px-10 py-2 max-w-screen-xl flex-1 ml-4 mt-4">
                <MyOverallStats />
              </div>

              {/* Today */}
              <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4">
                <div className="card-header flex flex-row justify-between items-center">
                  <div className="text-lg font-bold py-4">
                    Your activity today
                  </div>
                  <div className="">
                    <DatePicker
                      value={activityDate}
                      onChange={setActivityDate as any}
                      format={"DD/MM/YYYY"}
                      bordered={false}
                      allowClear={false}
                    />
                  </div>
                </div>
                <div className="w-full my-4">
                  <MyActivityToday date={activityDate} />
                </div>
              </div>

              <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4">
                <div className="card-header flex flex-row justify-between items-center">
                  <div className="text-lg font-bold py-4">
                    Last 7 Day Activity
                  </div>
                  <div className="">
                    <DatePicker.RangePicker
                      value={activityDateRange as any}
                      onChange={setActivityDateRange as any}
                      format={"DD/MM/YYYY"}
                      bordered={false}
                      allowClear={false}
                    />
                  </div>
                </div>
                <div className="w-full my-4">
                  <MyActivityReport
                    dateRange={activityDateRange as any}
                    userId={userId}
                    hideUserColumns={true}
                  />
                </div>
              </div>

              {/* Achievements */}
              <div className="ml-4  mt-4">
                <MyBadges />
              </div>

              {/* Statistics */}
              <div
                className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex px-10 py-2 max-w-screen-xl flex-1 ml-4  mt-4"
                style={{ minHeight: 200 }}
              >
                <div className="card-header flex flex-row justify-between items-center">
                  <div className="text-lg font-bold py-4">
                    Your Trace in Workspace
                  </div>
                </div>

                <div className="w-full">
                  <MyPerformanceReport />
                </div>
              </div>
            </div>
          </div>
        </div>

        <DarkModeBg />
      </Modal>
    </>
  );
};
