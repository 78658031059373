import JsonURL from "@jsonurl/jsonurl";
import qs from "query-string";
import { useCallback, useMemo } from "react";
import { useUrlState } from "utils/hooks/use-url-state";

export const useURLData = <T extends unknown>(
  dataPath: string,
  defaultData: T[]
) => {
  const [filterUrlString, setFilterUrlString] = useUrlState(dataPath);

  const selectedFilters = useMemo(() => {
    if (!filterUrlString) {
      return defaultData;
    }

    // Patch for JSON objects not getting processed correctly
    const parsedString = (() => {
      const newQueryStr = qs.stringify(
        {
          str: filterUrlString
        },
        { encode: true }
      );
      const parsedQueryStr = newQueryStr.replace("str=", "");
      return String(parsedQueryStr);
    })();

    let value = JsonURL.parse(parsedString || "(())", {
      AQF: true
    });
    if (Array.isArray(value)) {
      return value as T[];
    }
    // Todo: Empty Filters also returns default Data
    return defaultData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterUrlString]);

  const setSelectedFilters = useCallback(
    (options: T[]) => {
      let string = JsonURL.stringify(options, {
        AQF: true
      });

      if (string) {
        return setFilterUrlString(string);
      }
      return setFilterUrlString("");
    },
    [setFilterUrlString]
  );
  return [selectedFilters, setSelectedFilters] as [
    typeof selectedFilters,
    typeof setSelectedFilters
  ];
};
