import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";

import { Button, message, Modal, Space, Tag, Tooltip } from "antd";
import { ExtendControlType } from "braft-editor";
import { defaultAdditionalExcludedBraftControllers } from "components/common/draft-js-advanced/default-excluded-braft-controllers";
import { BraftJSInput } from "components/common/draft-js-advanced/draft-js-advanced";
import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { AvailableCampaignTokenTable } from "components/modules/conversations/components/chat-input/available-message-tokens";
import { DarkModeBg } from "dark-mode-bg";
import { ConfigureCampaign } from "modal-registry";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { selectCampaignById } from "store/modules/campaigns/campaigns.selectors";
import { selectAllContactLists } from "store/modules/contact-lists/contact-lists.selectors";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import { cleanHtmlForSimpleEmail } from "utils/clean-html-for-plain-email";

const EasyEmail = React.lazy(() => import("./easy-email/easy-email"));

const CodeEditor = React.lazy(() => import("@uiw/react-textarea-code-editor"));

const SimpleEmailCreator = React.lazy(
  () => import("./simple-email-creator/simple-email-creator"),
);

const defaultHtmlBody = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    
</body>
</html>`;

export const EditEmailCampaignModal = ({
  visible,
  onChangeVisibility,
  campaignId,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  campaignId: string;
}) => {
  const campaign = useSelector(selectCampaignById(campaignId));

  const { changePanelState, triggerTempModal } = useModalPanels();

  const onContinueToSend = useCallback(() => {
    setTimeout(() => {
      changePanelState(ConfigureCampaign, true, {
        campaignId: campaignId!,
        mode: "SEND",
      });
    }, 100);
  }, [campaignId, changePanelState]);

  const {
    doAction: onEditCampaign,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (edits) =>
        SDK.campaigns.editById(campaignId, {
          ...edits,
        }),
      successMessage: "Saved",
      failureMessage: "Something went wrong",
    }),
    [campaignId],
  );

  const marketingLists = useSelector(selectAllContactLists);

  const [code, setCode] = useState(defaultHtmlBody);
  const [textEmail, SetTextEmail] = useState("");

  const isDarkMode = useSelector(selectIsDarkMode);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.setAttribute("data-color-mode", "dark");
    } else {
      document.documentElement.setAttribute("data-color-mode", "light");
    }
  }, [isDarkMode]);

  const easyEmailRef = useRef<any>();
  const simpleEmailRef = useRef<any>();

  useEffect(() => {
    if (!campaign) {
      return;
    }
    // Without Set timeout, for some odd reason old value is shown
    setTimeout(() => {
      switch (campaign.data.emailEditorType) {
        case "HTML": {
          setCode(campaign.data.body || defaultHtmlBody);
          return;
        }
        case "SIMPLE_TEXT_EMAIL": {
          SetTextEmail(campaign.data.body);
          return;
        }
        case "SIMPLE_EMAIL": {
          return;
        }
        case "ADVANCED_EMAIL_EDITOR": {
          return;
        }
      }
    }, 100);
  }, [campaign]);

  const saveEmail = useCallback(async () => {
    try {
      switch (campaign.data.emailEditorType) {
        case "SIMPLE_TEXT_EMAIL": {
          await onEditCampaign({
            data: {
              body: textEmail,
            },
          });
          return true;
        }
        case "HTML": {
          await onEditCampaign({
            data: {
              body: code,
            },
          });
          return true;
        }
        case "SIMPLE_EMAIL": {
          const blocks = simpleEmailRef.current.blocks;
          const html = simpleEmailRef.current.getHtml();
          await onEditCampaign({
            data: {
              body: html,
              simpleMailBlocks: blocks,
            },
          });
          return true;
        }
        case "ADVANCED_EMAIL_EDITOR": {
          const pageData = easyEmailRef.current.getValues();
          const html = easyEmailRef.current.getHtml();
          await onEditCampaign({
            data: {
              body: html,
              advancedEmailPageData: pageData,
            },
          });
          return true;
        }
      }
      //
    } catch (e) {
      message.error("Please check your input");
    }
  }, [campaign?.data?.emailEditorType, code, onEditCampaign, textEmail]);

  const draftJsRef = useRef<any>();

  const extendControls = useMemo(() => {
    const extendControls: ExtendControlType[] = [
      {
        key: "message-presets",
        type: "modal",
        text: (
          <Tooltip title="Message Personalization">
            <i className="ri-braces-line"></i>
          </Tooltip>
        ),
        modal: {
          id: "message-personalization-modal",
          title: (
            <span>
              <ModalTitle
                title="Message Personalization"
                icon={<i className="ri-braces-line"></i>}
              />
            </span>
          ) as any,
          showFooter: false,
          closeOnBlur: true,
          className: "message-personalization-modal",
          children: (
            <div style={{ width: 400, padding: "0 10px" }}>
              <div className="my-4">
                You can use variable tokens in your messages
                <div className="font-bold my-4">Available Tokens:</div>
                <table className="simple-table-style w-full">
                  <thead>
                    <tr>
                      <th className="font-bold">Variable</th>
                      <th className="font-bold">Token</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AvailableCampaignTokenTable.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.label}</td>
                          <td>
                            <Tag
                              onClick={() => {
                                draftJsRef.current.insertHtmlToCursor(
                                  `<span class="variable-token-item">{${item.token}}</span>`,
                                );
                                const cb =
                                  draftJsRef.current.getControlBarInstance();
                                cb?.extendedModals?.[
                                  "message-personalization-modal"
                                ].close();
                              }}
                              className="cursor-pointer"
                            >
                              {item.token}
                            </Tag>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ),
        },
      },
    ];
    return extendControls;
  }, []);

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-mail-send-fill"></i>}
          title="Setup Email"
        />
      }
      open={visible}
      footer={
        <div className="flex flex-row justify-end items-center mr-16">
          <Space>
            <Button
              type="text"
              onClick={() => {
                onChangeVisibility(false);
              }}
              className="font-bold"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              icon={<i className="ri-check-line"></i>}
              onClick={async () => {
                const status = await saveEmail();
                if (status) {
                  // onChangeVisibility(false);
                }
              }}
              className="font-bold"
              loading={isProcessing}
            >
              Save Progress
            </Button>
            <Button
              type="primary"
              icon={<i className="ri-mail-send-line"></i>}
              onClick={async () => {
                const status = await saveEmail();
                if (status) {
                  onChangeVisibility(false);
                  onContinueToSend();
                }
              }}
              className="font-bold"
              loading={isProcessing}
            >
              Review and Send
            </Button>
          </Space>
        </div>
      }
      onOk={async () => {}}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Create New Campaign"
      width={
        campaign?.data?.emailEditorType === "SIMPLE_TEXT_EMAIL"
          ? undefined
          : "100%"
      }
      className={
        campaign?.data?.emailEditorType === "ADVANCED_EMAIL_EDITOR"
          ? "advanced-email-editor-modal"
          : ""
      }
      bodyStyle={{
        padding:
          campaign?.data?.emailEditorType === "SIMPLE_EMAIL" ? 0 : undefined,
      }}
      destroyOnClose={true}
    >
      {campaign && (
        <div className="editor">
          {campaign.data.emailEditorType === "ADVANCED_EMAIL_EDITOR" && (
            <Suspense fallback={<SuspenseLoadingIndicator />}>
              <EasyEmail
                editorRef={easyEmailRef}
                initialValues={campaign.data.advancedEmailPageData}
              />
            </Suspense>
          )}
          {campaign.data.emailEditorType === "SIMPLE_EMAIL" && (
            <Suspense fallback={<SuspenseLoadingIndicator />}>
              <SimpleEmailCreator
                ref={simpleEmailRef}
                initialValue={campaign.data.simpleMailBlocks}
              />
            </Suspense>
          )}
          {campaign.data.emailEditorType === "HTML" && (
            <div
              style={{ height: "calc(100vh - 240px)" }}
              className="overflow-auto"
            >
              <Suspense fallback={<SuspenseLoadingIndicator />}>
                <CodeEditor
                  value={code}
                  language="html"
                  placeholder="Enter HTML for your email"
                  onChange={(evn) => setCode(evn.target.value)}
                  padding={15}
                  style={{
                    fontSize: 12,
                    fontFamily:
                      "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                    height: "calc(100vh - 240px)",
                    overflow: "auto",
                  }}
                />
              </Suspense>
            </div>
          )}
          {campaign.data.emailEditorType === "SIMPLE_TEXT_EMAIL" && (
            <div>
              <div className="font-bold mb-4">Type in your text email</div>

              <div className="draft-js-container border border-gray-200 dark:border-gray-700 rounded-lg">
                <BraftJSInput
                  initialValue={campaign?.data?.body}
                  ref={draftJsRef}
                  additionalExcludedControls={
                    defaultAdditionalExcludedBraftControllers
                  }
                  extendControls={extendControls}
                  onChange={(html) => {
                    const text = cleanHtmlForSimpleEmail(html);
                    SetTextEmail(text.trim());
                  }}
                  showControlsOnTop={true}
                />
              </div>

              {/* <Input.TextArea
                rows={10}
                value={textEmail}
                onChange={(e) => SetTextEmail(e.target.value)}
              /> */}
              {/* <div className="footer p-4 text-gray-600">
                {textEmail.length} Characters
              </div> */}
            </div>
          )}
        </div>
      )}
      <DarkModeBg />
    </Modal>
  );
};
