import {
  iHolidayQuote,
  iWhatsIncludedItem,
  iWhatsIncludedType
} from "@sdk/holiday-quotes/holiday-quotes-model";
import { useMemo } from "react";
import { GenerateWhatsIncluded } from "../helpers/generate-whats-included";
import { iWhatsIncludedBlock } from "../itineries-model";

export const WhatsIncludedBlock = ({
  data,
  holidayQuote
}: {
  data: iWhatsIncludedBlock["data"];
  holidayQuote: iHolidayQuote;
}) => {
  const WhatsIncluded = useMemo(() => {
    return [
      ...GenerateWhatsIncluded(data, holidayQuote),
      ...(data?.whatsIncluded || [])
    ];
  }, [data, holidayQuote]);
  return (
    <div className="flex flex-col justify-center mt-8">
      {WhatsIncluded.filter(item => !item.isHidden).map((item, index) => (
        <div key={index} className="flex flex-row mb-4 items-center">
          <div className="icon-container mr-4 text-4xl">
            {WhatsIncludedIconMap[item.type]}
          </div>
          <div className="flex flex-col">
            <div className="">{item.title}</div>
            <div className="text-gray-600">{item.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const WhatsIncludedIconMap: Record<iWhatsIncludedType, JSX.Element> = {
  FLIGHT: <i className="ri-flight-takeoff-line"></i>,
  HOTEL: <i className="ri-hotel-bed-line"></i>,
  INSURANCE: <i className="ri-shield-cross-line"></i>,
  VISA: <i className="ri-article-line"></i>,
  AGENT: <i className="ri-user-smile-line"></i>,
  MISCELLANEOUS: <i className="ri-coupon-line"></i>,
  EXCURSION: <i className="ri-flag-line"></i>,
  ACTIVITY: <i className="ri-empathize-line"></i>
};

export const WhatsIncludedDummyData: iWhatsIncludedItem[] = [
  {
    type: "FLIGHT",
    title: "LHR to HKG | HKG | CMB",
    description: "12:20pm Take-off  | 7:55am - Touchdown | 1 ticket | Layover"
  },
  {
    type: "HOTEL",
    title: "Bali Mandira Beach Resort",
    description: "Bali Mandira Beach Resort | 7 days | 1 double room | B&B"
  },
  {
    type: "FLIGHT",
    title: "HKG to LHR",
    description: "10:00am Take-off  | 3:oopm - Touchdown | 1 ticket"
  },
  {
    type: "INSURANCE",
    title: "Travel Insurance",
    description: "Full Coverage | 1 person"
  },
  {
    type: "VISA",
    title: "Visa",
    description: "On-arrival visa"
  },
  {
    type: "AGENT",
    title: "Agent",
    description: "On-call agent"
  }
];
