export type iBadgeCategory =
  | "Reports"
  | "Profile Customization"
  | "Get Started"
  | "Click Connector"
  | "Unibox Discovery"
  | "Unibox Mastery"
  | "Chat Master"
  | "CRM"
  | "Workspace Setup"
  | "Automation"
  | "Organized Workspace"
  | "Advanced Workspace Setup"
  | "Sales Team"
  | "Task Master";

export const allBadgeCategories: iBadgeCategory[] = [
  "Get Started",
  "Profile Customization",
  "Reports",
  "Click Connector",
  "Unibox Discovery",
  "Unibox Mastery",
  "Chat Master",
  "CRM",
  "Workspace Setup",
  "Automation",
  "Organized Workspace",
  "Advanced Workspace Setup",
  "Sales Team",
  "Task Master"
];

export type iBadgeScope = "User Specific" | "Organization Wide";

export type iBadgeType = "Multiple Event" | "Event";

export const BadgeRegistry: {
  id: string;
  description: string;
  name: string;
  category?: iBadgeCategory;
  scope: iBadgeScope;
  type: iBadgeType;
  isImplemented?: boolean;
}[] = [
  {
    id: "implement-a-live-chat-connection",
    description: "Implement a Live Chat Connection",
    name: "On the interwebs",
    category: "Workspace Setup",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "create-your-first-task",
    description: "Create Your First Task",
    name: "Might be motivated",
    category: "Unibox Discovery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "setup-message-presets",
    description: "Setup Message Presets",
    name: "Saving Time",
    category: "Workspace Setup",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "send-a-testimonial",
    description: "Send a testimonial",
    name: "This one gets it",
    category: "Click Connector",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "send-500-messages",
    description: "Send 500 Messges",
    name: "Experienced Converser",
    category: "Chat Master",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "use-availability",
    description: "Use Availability",
    name: "Now you see me",
    category: "Get Started",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "send-1000-messages",
    description: "Send 1000 Messages",
    name: "Veteran Converser",
    category: "Chat Master",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "join-a-conversation",
    description: "Join a Conversation",
    name: "Chip in",
    category: "Unibox Discovery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "send-25-message",
    description: "Send 25 Message",
    name: "Novice Converser",
    category: "Chat Master",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "set-up-a-chat-bot",
    description: "Set up a Chat Bot",
    name: "Making Friends",
    category: "Automation",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "add-crm-activity",
    description: "Add CRM Activity",
    name: "Tracker",
    category: "CRM",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "make-your-first-comment",
    description: "Make your first comment",
    name: "Constructive Input",
    category: "Unibox Discovery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "send-50-messages",
    description: "Send 50 Messages",
    name: "Chatty Converser",
    category: "Chat Master",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "finish-2x-tours",
    description: "Finish 2x Tours",
    name: "On Tour",
    category: "Get Started",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "create-3-custom-reports",
    description: "Create 3 Custom Reports",
    name: "More data is better data",
    category: "Reports",
    scope: "Organization Wide",
    type: "Multiple Event"
  },
  {
    id: "create-10x-task",
    description: "Create 10x Task",
    name: "Definitely Motivated",
    category: "Task Master",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "use-dark-mode",
    description: "Use Dark Mode",
    name: "Children of the Night",
    category: "Get Started",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "explore-2-report-types",
    description: "Explore 2 Report Types",
    name: "Dr Watson",
    category: "Reports",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "use-ip-white-listing",
    description: "Use IP White Listing",
    name: "Protected Organization",
    category: "Advanced Workspace Setup",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "watch-an-academy-video",
    description: "Watch an academy Video",
    name: "Studious",
    category: "Get Started",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "set-up-first-integration",
    description: "Set up First Integration",
    name: "Integrated",
    category: "Advanced Workspace Setup",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "make-your-first-private-reply",
    description: "Make your first private reply",
    name: "Its our little secret",
    category: "Unibox Discovery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "setup-profile-bio",
    description: "Setup Profile Bio",
    name: "Personal Poet",
    category: "Profile Customization",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "use-mentions",
    description: "Use Mentions",
    name: "Collaborator",
    category: "Unibox Discovery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "use-10x-mentions",
    description: "Use 10x Mentions",
    name: "Who’s who?",
    category: "Task Master",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "use-bulk-actions",
    description: "Use Bulk Actions",
    name: "Casting the net",
    category: "Unibox Discovery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "use-presets",
    description: "Use Presets",
    name: "Always Prepared",
    category: "Unibox Discovery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "create-first-custom-report",
    description: "Create First Custom Report",
    name: "Keeping an eye out",
    category: "Reports",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "setup-personal-presets",
    description: "Setup Personal Presets",
    name: "I know what I want",
    category: "Get Started",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "use-report-inspector",
    description: "Use Report Inspector",
    name: "Looking for insight",
    category: "Reports",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "transfer-a-conversation",
    description: "Transfer a conversation",
    name: "The Handover",
    category: "Unibox Discovery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "create-5x-task",
    description: "Create 5x Task",
    name: "Pretty Motivated",
    category: "Task Master",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "bring-in-your-first-10-leads",
    description: "Bring in your First 10 Leads",
    name: "The Newbie",
    category: "Sales Team",
    scope: "Organization Wide",
    type: "Multiple Event"
  },
  {
    id: "explore-1-report-type",
    description: "Explore 1 Report Type",
    name: "Investigator",
    category: "Reports",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "send-5x-message",
    description: "Send 5x Message",
    name: "Rookie Converser",
    category: "Unibox Mastery",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "setup-first-chat-action",
    description: "Setup First Chat Action",
    name: "The Future is here",
    category: "Advanced Workspace Setup",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "setup-your-email-signature",
    description: "Setup your email signature",
    name: "Sign-off",
    category: "Get Started",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "use-reminder-x5",
    description: "Use Reminder x5",
    name: "Second Brain",
    category: "Unibox Mastery",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "use-knowledge-base",
    description: "Use Knowledge Base",
    name: "Socrates",
    category: "Workspace Setup",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "create-forms",
    description: "Create Forms",
    name: "Gatherer",
    category: "Workspace Setup",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "change-avatar",
    description: "Change Avatar",
    name: "Fashionista",
    category: "Profile Customization",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "use-reminders",
    description: "Use Reminders",
    name: "Forget me not",
    category: "Unibox Discovery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "setup-custom-inboxes",
    description: "Setup Custom Inboxes",
    name: "On a mission",
    category: "Unibox Mastery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "use-cross-channel-communication",
    description: "Use Cross Channel Communication",
    name: "Omni-present",
    category: "Unibox Mastery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "suggest-a-feature",
    description: "Suggest a Feature",
    name: "Critique",
    category: "Click Connector",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "implement-a-wa-connection",
    description: "Implment a WA Connection",
    name: "This is Personal",
    category: "Workspace Setup",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "use-scheduled-message",
    description: "Use Scheduled Message",
    name: "Fire & Forget",
    category: "Unibox Discovery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "create-your-first-marking-list",
    description: "Create your first marking list",
    name: "Neat & Organized",
    category: "Organized Workspace",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "update-crm-record",
    description: "Update CRM Record",
    name: "In the know",
    category: "CRM",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "set-up-teams",
    description: "Set up Teams",
    name: "Hivemind",
    category: "Advanced Workspace Setup",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "customize-chat-tags",
    description: "Customize Chat Tags",
    name: "Master Tagger",
    category: "Advanced Workspace Setup",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "send-your-first-message",
    description: "Send your first message",
    name: "First Timer",
    category: "Unibox Discovery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "tag-a-conversation",
    description: "Tag a conversation",
    name: "Organizer",
    category: "Unibox Discovery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "finish-5x-tours",
    description: "Finish 5x Tours",
    name: "Regular Tourist",
    category: "Get Started",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "user-role-usage",
    description: "User Role Usage",
    name: "Overlord",
    category: "Organized Workspace",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "use-scheduled-message-x5",
    description: "Use Scheduled Message x5",
    name: "According to Schedule",
    category: "Unibox Mastery",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "send-100-messages",
    description: "Send 100 Messages",
    name: "Persistent Converser",
    category: "Chat Master",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "invite-a-user",
    description: "Invite a User",
    name: "Life of the Party",
    category: "Unibox Discovery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "use-chat-filters",
    description: "Use Chat Filters",
    name: "Seeking Precision",
    category: "Unibox Discovery",
    scope: "User Specific",
    type: "Event"
  },
  {
    id: "implement-a-meta-connection",
    description: "Implement a Meta Connection",
    name: "Zuckerberg sends his Regards",
    category: "Workspace Setup",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "implemented-connection-x-6",
    description: "Implemented Connection x 6",
    name: "Well Connected",
    category: "Workspace Setup",
    scope: "Organization Wide",
    type: "Event"
  },
  {
    id: "explore-all-reports",
    description: "Explore All Reports",
    name: "Sherlock Holmes",
    category: "Reports",
    scope: "User Specific",
    type: "Multiple Event"
  },
  {
    id: "bring-in-your-first-1000-leads",
    description: "Bring in your First 1000 Leads",
    name: "The Closer",
    category: "Sales Team",
    scope: "Organization Wide",
    type: "Multiple Event"
  },
  {
    id: "bring-in-your-first-100-leads",
    description: "Bring in your First 100 Leads",
    name: "The Collector",
    category: "Sales Team",
    scope: "Organization Wide",
    type: "Multiple Event"
  }
];
