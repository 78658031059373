import {
  iCustomField,
  iDynamicForm,
} from "@sdk/dynamic-forms/dynamic-forms-model";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { MobileNumberInput } from "components/common/mobile-number-input/mobile-number-input";
import { useDynamicFormItemProps } from "./helpers/use-dynamic-form-item-props";

const { Title } = Typography;

export const DynamicFormItemInput = ({
  config,
  ...otherProps
}: {
  config: iCustomField;
}) => {
  if (config.inputType === "EMAIL" || config.inputType === "TEXT") {
    return <Input placeholder="" {...otherProps} />;
  }

  if (config.inputType === "CHECK_BOX") {
    return <Checkbox {...otherProps}>{config.label}</Checkbox>;
  }

  if (config.inputType === "DATE") {
    return <DatePicker {...otherProps} className="w-full" />;
  }
  if (config.inputType === "NUMBER") {
    return <InputNumber {...otherProps} className="w-full" />;
  }

  if (config.inputType === "TEXTAREA") {
    return <Input.TextArea {...otherProps} />;
  }

  if (config.inputType === "SWITCH") {
    return <Switch {...otherProps} />;
  }

  if (config.inputType === "PHONE") {
    return <MobileNumberInput {...otherProps} />;
  }

  if (config.inputType === "RADIO") {
    return (
      <Radio.Group {...otherProps}>
        <Space direction="vertical">
          {config.inputConfig?.options?.map((item) => (
            <Radio key={item.value} value={item.value}>
              {item.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    );
  }

  if (config.inputType === "SELECT") {
    return (
      <Select {...otherProps}>
        {config.inputConfig?.options?.map((item) => (
          <Select.Option key={item.value} value={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    );
  }

  if (config.inputType === "MULTI_SELECT") {
    return (
      <Select mode="multiple" {...otherProps}>
        {config.inputConfig?.options?.map((item) => (
          <Select.Option key={item.value} value={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    );
  }

  return <>Unidentified Input</>;
};

export const DynamicFormItem = ({ config }: { config: iCustomField }) => {
  const { rules, label, additionProps } = useDynamicFormItemProps(config);

  return (
    <div>
      <Form.Item
        label={label}
        name={config.key}
        rules={rules}
        {...additionProps}
        help={<div className="-mt-2 mb-2">{config.description}</div>}
      >
        <DynamicFormItemInput config={config} />
      </Form.Item>
    </div>
  );
};

export const DynamicForm = ({ config }: { config: iDynamicForm }) => {
  const [form] = useForm();

  if (!config) {
    return <></>;
  }

  return (
    <div className="form-container bg-white dark:bg-gray-800 dark:border-gray-700 rounded-lg border border-gray-200 shadow-lg overflow-auto">
      <div className="header">
        <div
          className="banner"
          style={{
            backgroundColor: config?.layout?.body?.themePrimaryColor,
            opacity: 0.1,
            backgroundImage: `repeating-radial-gradient( circle at 0 0, transparent 0, ${config?.layout?.body?.themePrimaryColor}ff 10px ), repeating-linear-gradient( #ffffff, #ffffff )`,
            height: 100,
          }}
        ></div>
        {config.layout.header.logo && (
          <div className="logo-container flex flex-row items-center justify-center my-4">
            <img
              src={config.layout.header.logo}
              style={{ width: 200 }}
              alt=""
            />
          </div>
        )}

        <div className="title text-4xl font-bold px-6 mt-4">
          {config.layout.header.title}
        </div>
        <div className="text-gray-600 px-6">
          {config.layout.header.description}
        </div>
      </div>
      <div className="body p-6">
        <Form form={form} layout={"vertical"} hideRequiredMark={true}>
          {config.layout.body.items.map((formGroup) => (
            <div key={formGroup.id} className="my-4 border-b border-gray-200">
              <div className="title text-xl font-bold">{formGroup.title}</div>
              <div className="text-gray-600">{formGroup.description}</div>

              <div className="form-items my-6">
                {formGroup.fields.map((field) => (
                  <DynamicFormItem key={field.id} config={field} />
                ))}
              </div>
            </div>
          ))}
          <div className="button-container flex flex-row justify-end items-center">
            <Button
              type="primary"
              htmlType="submit"
              loading={false}
              block
              size="large"
              style={{
                background: config.layout.body.themePrimaryColor,
                border: config.layout.body.themePrimaryColor,
              }}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
      <div className="footer"></div>
    </div>
  );
};
