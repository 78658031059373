import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { DatePicker, Form, message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "store/modules/users/users.selectors";

const getRange = (start: number, end: number) =>
  [...(Array(end - start + 1) as any).keys()].map((i) => i + start);

export const ScheduleReminderModal = ({
  visible,
  onChangeVisibility,
  entityType,
  entityId,
  onScheduled,
  hideOnScheduled,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  entityType: string;
  entityId: string;
  onScheduled?: (company: any) => any;
  hideOnScheduled?: boolean;
}) => {
  const [form] = useForm();

  // Create Call
  const {
    doAction: createReminder,
    isProcessing: isActionProcessing,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.reminders.create(values).then((d) => {
          onChangeVisibility(false);
          onScheduled && onScheduled(d.id);
        }),
      successMessage: "Reminder has been scheduled",
      failureMessage: "Something went wrong",
    }),
    [onChangeVisibility, onScheduled]
  );

  const currentUserId = useSelector(selectCurrentUserId);

  const onAddReminder = useCallback(async () => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const timestamp = formValues.timestamp.valueOf();
      createReminder({
        userId: currentUserId,
        text: "Reminder",
        type: "CONVERSATION",
        entityType: entityType,
        entityId: entityId,
        data: {},
        timestamp: timestamp,
      });
    } catch (e) {
      // Validation Failed; Do nothing
      message.error("Please check your input");
    }
  }, [createReminder, currentUserId, entityId, entityType, form]);

  const { minDate, maxDate } = useMemo(() => {
    return {
      minDate: dayjs(),
      maxDate: dayjs().add(31, "day"),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const disabledDate = useCallback(
    (d) => {
      if (d == null) {
        return null;
      }

      return (
        (minDate != null && d.isBefore(minDate) && !d.isSame(minDate, "day")) ||
        (maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, "day"))
      );
    },
    [minDate, maxDate]
  );

  const disabledTime = useCallback(
    (d) => {
      if (d == null) {
        return null;
      }

      if (d.isSame(minDate, "day")) {
        return {
          disabledHours: () =>
            minDate.hour() > 0 ? getRange(0, minDate.hour() - 1) : [],
          disabledMinutes: (hour) =>
            hour === minDate.hour() && minDate.minute() > 0
              ? getRange(0, minDate.minute() - 1)
              : [],
        };
      }

      if (d.isSame(maxDate, "day")) {
        return {
          disabledHours: () => getRange(maxDate.hour() + 1, 24),
          disabledMinutes: (hour) =>
            hour === maxDate.hour() ? getRange(maxDate.minute() + 1, 60) : [],
        };
      }

      return null;
    },
    [minDate, maxDate]
  );

  const [isPanelOpen, setPanelOpen] = useState(true);

  const options = useMemo(() => {
    const day1 = dayjs()
      .add(1, "day")
      .startOf("day")
      .hour(9);
    const day2 = dayjs()
      .add(2, "day")
      .startOf("day")
      .hour(9);
    const day3 = dayjs()
      .add(3, "day")
      .startOf("day")
      .hour(9);
    const day4 = dayjs()
      .add(3, "day")
      .startOf("day")
      .hour(9);
    const day5 = dayjs()
      .add(3, "day")
      .startOf("day")
      .hour(9);
    const dates = [
      // Tomorrow at 9am
      {
        value: day1,
        label: "Tomorrow at 9 AM",
      },
      // Day After Tomorrow at 9am
      {
        value: day2,
        label: day2.format("ddd [at] LT"),
      },
      // Next Day at 9am
      {
        value: day3,
        label: day3.format("ddd [at] LT"),
      },
      // More Options
      {
        value: day4,
        label: day4.format("ddd [at] LT"),
      },
      {
        value: day5,
        label: day5.format("ddd [at] LT"),
      },
    ];
    return dates;
  }, []);

  return (
    <Modal
      title={
        <ModalTitle
          title="Schedule Reminder"
          icon={<i className="ri-time-line"></i>}
        />
      }
      open={visible}
      onOk={async () => {
        onAddReminder();
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okButtonProps={{ loading: isActionProcessing }}
      okText={"Set Reminder"}
      data-click-context="Schedule Reminder Modal"
    >
      <Form layout="vertical" form={form} className="bold-form-labels">
        {/* Name */}
        <Form.Item
          label="Date & Time"
          name={["timestamp"]}
          rules={[
            {
              type: "object",
              required: true,
              message: "Please select time!",
            },
          ]}
        >
          <DatePicker
            showTime
            showSecond={false}
            autoFocus
            placeholder="Select Date & Time"
            // format="ddd [at] LT"
            format="YYYY-MM-DD HH:mm"
            className="w-full"
            disabledDate={disabledDate}
            disabledTime={disabledTime as any}
            open={isPanelOpen}
            onOpenChange={setPanelOpen}
            presets={options}
          />
        </Form.Item>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
