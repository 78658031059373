import MailOutlined from "@ant-design/icons/MailOutlined";
import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import { SDK } from "@sdk";
import { processServerError } from "@sdk/sdk.hooks";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  message,
  Modal,
  Result,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import modal from "antd/lib/modal";
import { MobileNumberInput } from "components/common/mobile-number-input/mobile-number-input";
import { ModalTitle } from "components/common/modal-title";
import { RouterPrompt } from "components/common/router-prompt/router-prompt";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useBus } from "react-bus";
import { useStore } from "react-redux";
import { loadAllContactLists } from "store/modules/contact-lists/contact-lists.helpers";
import { selectAllContactLists } from "store/modules/contact-lists/contact-lists.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { cleanMobileNumber } from "utils/clean-mobile-number";
import { CountryCodes } from "utils/country-codes";
import { isEmail } from "utils/is-email";
import { debouncedContactAvailabilityValidator } from "../../helpers/contact-identifier-validator";
import { ContactTagSelector } from "../contact-tag-selector/contact-tag-selector";
import "./add-contact.scss";

const { Option } = Select;

export const AddContactModal = ({
  visible,
  onChangeVisibility,
  onCreated,
  isMinimal,
  eventReceiver,
  hideOnCreate,
  prefill,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated?: (contact: any) => any;
  isMinimal?: boolean;
  eventReceiver?: string;
  hideOnCreate?: boolean;
  prefill?: any;
}) => {
  const store = useStore();
  const defaultFormValue = useMemo(() => {
    const organization = selectOrganization(store.getState());
    const defaultCountry = CountryCodes.find(
      (item) => item.code === organization?.data?.country
    );
    return {
      firstName: "",
      lastName: "",
      primaryEmail: "",
      primaryMobile: "",
      countryCode: defaultCountry?.dialCode || "+44",
      country: defaultCountry
        ? `${defaultCountry.code}|${defaultCountry.name}`
        : "GB|United Kingdom",
      emails: [],
      phones: [],
      ...(prefill || {}),
    };
  }, [prefill, store]);
  const [addContactForm] = useForm();
  const [step, setStep] = useState<"CREATE" | "SUCCESS" | "ERROR">("CREATE");
  const [response, setResponse] = useState<any | undefined>(undefined);

  const bus = useBus();

  // Create Call
  const [isProcessing, setIsProcessing] = useState(false);

  const onCreateContact = useCallback(async () => {
    try {
      await addContactForm.validateFields();
      const formValue = addContactForm.getFieldsValue();
      const contactData: any = {
        ...formValue,
        country: formValue.country
          ? formValue.country.split("|")[0]
          : undefined,
      };
      setIsProcessing(true);
      try {
        const contact = await SDK.createContact(contactData);
        message.success("Contact has been created");
        setStep("SUCCESS");
        setResponse(contact);
        onCreated && onCreated(contact);
        if (hideOnCreate) {
          onChangeVisibility(false);
        }
        bus.emit(
          eventReceiver ? `${eventReceiver}-add-contact` : "add-contact",
          contact
        );
        setIsProcessing(false);
        addContactForm.resetFields();
      } catch (e) {
        console.log("Error while crating list", e);
        setStep("ERROR");
        if (e instanceof Error) {
          setResponse((e as any)?.response?.data);
        }

        message.error(processServerError(e, "Something went wrong"));
        setIsProcessing(false);
      }
    } catch (e) {
      // Validation Failed; Do nothing
      message.error("Please check your input");
    }
  }, [
    addContactForm,
    bus,
    eventReceiver,
    hideOnCreate,
    onChangeVisibility,
    onCreated,
  ]);

  const tags: any[] = [];

  const { state: allContactLists, retry: reload } = useQueryWithStore(
    selectAllContactLists,
    loadAllContactLists
  );

  const resetView = () => {
    setStep("CREATE");
  };

  useEffect(() => {
    if (!visible) {
      resetView();
      setIsProcessing(false);
      addContactForm.resetFields();
    }
    if (visible) {
      setTimeout(() => {
        if (firstInput.current) {
          firstInput.current.focus();
        }
      }, 400);
    }
  }, [addContactForm, visible]);

  const [isFormTouched, setFormTouched] = useState(false);
  const firstInput = useRef<any>();

  useEffect(() => {
    setTimeout(() => {
      firstInput.current?.focus();
    }, 100);
  }, []);

  return (
    <Modal
      title={
        <ModalTitle
          title="Add Customer Record"
          icon={<i className="ri-folder-user-line"></i>}
        />
      }
      open={visible}
      onOk={onCreateContact}
      onCancel={() => {
        if (addContactForm.isFieldsTouched()) {
          modal.confirm({
            icon: null,
            content: "You have unsaved data. Are you sure you want to close?",
            okText: "Yes",
            onOk() {
              onChangeVisibility(false);
            },
          });
        } else {
          onChangeVisibility(false);
        }
      }}
      footer={step === "CREATE" ? undefined : null}
      okButtonProps={{ loading: isProcessing }}
      okText={
        <>
          <i className="ri-save-line"></i> Save Contact
        </>
      }
      width={isMinimal ? 450 : 900}
      data-click-context="Add Contact Modal"
      destroyOnClose={true}
    >
      <RouterPrompt when={addContactForm.isFieldsTouched()} />
      {step === "CREATE" && (
        <Form
          layout="vertical"
          form={addContactForm}
          initialValues={defaultFormValue}
          className="bold-form-labels add-contact-modal"
          onChange={() => {
            if (addContactForm.isFieldsTouched() && !isFormTouched) {
              setFormTouched(true);
            }
          }}
          onFinish={onCreateContact}
        >
          <div className="flex flex-row">
            <div className="main-contact-details flex-1 mr-2">
              {/* First Name */}
              <Row justify="space-between">
                <Col span={11}>
                  {/* First Name*/}

                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="First name"
                      size="large"
                      autoFocus
                      ref={firstInput}
                    />
                  </Form.Item>
                </Col>

                {/* Last Name */}
                <Col span={11}>
                  <Form.Item name="lastName" label="Last Name">
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Last name"
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Mobile Number */}
              <Form.Item
                label="Primary Mobile"
                name="primaryMobile"
                rules={[
                  ({ getFieldValue }) => ({
                    async validator(rule, value) {
                      if (value) {
                        const mobile = await cleanMobileNumber(value);
                        if (!mobile.isValid || !mobile.isValidMobileNumber) {
                          return Promise.reject(
                            "Invalid Mobile Number. Please enter complete mobile number with country and area codes"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      }
                      // Phone is not mandatory
                      // return Promise.reject("Invalid Phone Number");
                      return Promise.resolve();
                    },
                  }),
                  ({ getFieldValue }) => ({
                    validator: async (rule, value) => {
                      if (value) {
                        const mobile = await cleanMobileNumber(value);
                        if (!mobile.isValidMobileNumber) {
                          throw "Invalid Mobile Number";
                        }
                        return await debouncedContactAvailabilityValidator({
                          mobile: mobile.mobileId,
                        });
                      }
                      return true;
                    },
                  }),
                ]}
              >
                {/* <Input placeholder="" /> */}
                <MobileNumberInput />
              </Form.Item>
              {/* Email */}
              <Form.Item
                label="Primary Email"
                name="primaryEmail"
                normalize={(value) => ((value || "") as string).toLowerCase()}
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                  ({ getFieldValue }) => ({
                    validator: async (rule, value) => {
                      if (value) {
                        if (!isEmail(value)) {
                          throw "Invalid Email Address";
                        }
                        return await debouncedContactAvailabilityValidator({
                          email: value,
                        });
                      }
                      return true;
                    },
                  }),
                ]}
              >
                <Input
                  placeholder=""
                  prefix={<MailOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
              {/* Tags */}
              <Form.Item label="Tags" name="tags">
                <ContactTagSelector />
              </Form.Item>
            </div>
            {!isMinimal && (
              <div className="additional-details flex-1 ml-2">
                <Collapse className="border-0" defaultActiveKey={["MARKETING"]}>
                  <Collapse.Panel
                    header="Additional Contact Details"
                    key="CONTACT_DETAILS"
                  >
                    <div className="label font-bold mb-2">Phone</div>
                    <Form.List name="phones">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field) => (
                            <Space
                              key={field.key}
                              style={{ display: "flex", marginBottom: 8 }}
                              align="baseline"
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, "phone"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing phone number",
                                  },
                                ]}
                                hasFeedback={false}
                                style={{ marginBottom: 0 }}
                              >
                                <Input placeholder="Phone" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, "label"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing phone number type",
                                  },
                                ]}
                                hasFeedback={false}
                                style={{ marginBottom: 0 }}
                              >
                                <Select style={{ minWidth: 150 }}>
                                  <Select.Option value="work">
                                    Work
                                  </Select.Option>
                                  <Select.Option value="personal">
                                    Personal
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </Space>
                          ))}
                          <div
                            onClick={() => add({ type: "work", phone: "" })}
                            className="cursor-pointer"
                          >
                            + add one more
                          </div>
                        </>
                      )}
                    </Form.List>
                    <div className="label font-bold mb-2 mt-4">Email</div>
                    <Form.List name="emails">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field) => (
                            <Space
                              key={field.key}
                              style={{ display: "flex", marginBottom: 8 }}
                              align="baseline"
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, "email"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                                hasFeedback={false}
                                style={{ marginBottom: 0 }}
                              >
                                <Input placeholder="Email" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, "label"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing last name",
                                  },
                                ]}
                                hasFeedback={false}
                                style={{ marginBottom: 0 }}
                              >
                                <Select style={{ minWidth: 150 }}>
                                  <Select.Option value="work">
                                    Work
                                  </Select.Option>
                                  <Select.Option value="personal">
                                    Personal
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </Space>
                          ))}
                          <div
                            onClick={() => add({ type: "work", email: "" })}
                            className="cursor-pointer"
                          >
                            + add one more
                          </div>
                        </>
                      )}
                    </Form.List>
                  </Collapse.Panel>
                  <Collapse.Panel header="Address" key="ADDRESS">
                    <Form.Item label="Country" name="country">
                      <Select showSearch>
                        {CountryCodes.map((country) => (
                          <Select.Option
                            value={`${country.code}|${country.name}`}
                            key={country.code}
                          >
                            {country.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Collapse.Panel>
                  <Collapse.Panel header="Marketing" key="MARKETING">
                    <Form.Item label="Lists" name="lists">
                      <Select showSearch mode="multiple">
                        {allContactLists.map((list) => (
                          <Select.Option value={list.id!} key={list.id}>
                            {list.data?.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Marketing Preference"
                      name={["preference", "marketingConsentObtained"]}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Collapse.Panel>
                </Collapse>
              </div>
            )}
          </div>
          <Button htmlType="submit" className="hidden">
            Submit
          </Button>
        </Form>
      )}
      {step === "SUCCESS" && (
        <>
          <Result
            status="success"
            title="Successfully Added a Contact"
            subTitle={`${
              (response as any)?.data?.firstName
            } has been added as a contact successfully`}
            extra={[
              <Button
                type="primary"
                key="close"
                onClick={() => {
                  addContactForm.resetFields();
                  onChangeVisibility(false);
                }}
              >
                Close
              </Button>,
              <Button
                key="another"
                onClick={() => {
                  setStep("CREATE");
                  addContactForm.resetFields();
                }}
              >
                Add Another
              </Button>,
            ]}
          />
        </>
      )}
      {step === "ERROR" && (
        <>
          <Result
            status="warning"
            title="Something went wrong"
            subTitle={`${(response as any)?.message}`}
            extra={[
              <Button
                type="primary"
                key="back"
                onClick={() => setStep("CREATE")}
              >
                Go back
              </Button>,
            ]}
          />
        </>
      )}
      <DarkModeBg />
    </Modal>
  );
};
