import {
  iMessage,
  iMessageSenderType,
} from "@sdk/conversations/conversations.models";
import { Image } from "antd";
import { memo, useRef } from "react";
import { animated } from "react-spring";
import {
  isFirstMessageOfGroup,
  isLastMessageOfGroup,
  shouldShowContactAvatar,
} from "./message-list-helpers";
import { MessagesListItem } from "./message-list-item";

export const AiAssistMessageList = memo(
  ({
    conversationId,
    messages,
  }: {
    conversationId: string;
    messages: iMessage[];
  }) => {
    const ref = useRef<HTMLDivElement>();

    return (
      <Image.PreviewGroup>
        <div className="flex-1 flex flex-col overflow-hidden relative">
          <animated.div
            ref={ref as any}
            className="chat-messages flex-1 overflow-x-hidden overflow-y-auto  pb-24"
          >
            {(messages || []).map((message, index) => (
              <MessagesListItem
                message={message}
                index={index}
                shouldShowContactAvatar={shouldShowContactAvatar(
                  message,
                  index,
                  messages,
                )}
                isLastMessageOfGroup={isLastMessageOfGroup(
                  message,
                  index,
                  messages,
                )}
                isFirstMessageOfGroup={isFirstMessageOfGroup(
                  message,
                  index,
                  messages,
                )}
                key={message.id}
                conversationId={conversationId}
                hideActions={true}
                useMarkdownRender={
                  message.from.senderType === iMessageSenderType.USER
                }
              />
            ))}
          </animated.div>
        </div>
      </Image.PreviewGroup>
    );
  },
);
