import dayjs from "dayjs";
import _ from "lodash";
import { ReportSchema } from "../schemas/backend-report-schemas/backend-report-schema-model";

export const GenerateQueryFromReportFilter = (
  reportFilter: any,
  schema: ReportSchema,
  ignoreDateField?: boolean
) => {
  const startDate: number =
    reportFilter?.period?.[0]?.valueOf() || reportFilter.startDate;
  const endDate: number =
    reportFilter?.period?.[1]?.valueOf() || reportFilter.endDate;

  const filter = {
    dateRange: {
      dateField: reportFilter.dateField || "createdTime",
      start:
        startDate ||
        dayjs()
          .startOf("d")
          .subtract(6, "day")
          .valueOf(),
      end:
        endDate ||
        dayjs()
          .endOf("d")
          .valueOf(),
    },
    query: {
      ...reportFilter.filters,
      ..._.omit(reportFilter, ["filters", "endDate", "startDate", "groupBy"]),
    },
  };

  const query: {
    [field: string]: any;
  } = ignoreDateField
    ? {}
    : {
        [schema.dateFilterTypes[filter.dateRange.dateField]]: {
          $gte: dayjs(filter.dateRange.start).valueOf(),
          $lt: dayjs(filter.dateRange.end).valueOf(),
        },
      };
  for (const condition in filter.query) {
    const filterSchema = schema.filters[condition];
    if (filterSchema) {
      if (filterSchema.type === "EQUALITY") {
        query[filterSchema.path] = filter.query[condition];
      } else if (
        filterSchema.type === "BETWEEN" ||
        filterSchema.type === "CUSTOM"
      ) {
        const conditionValue = filter.query[condition];
        try {
          query[filterSchema.path] =
            typeof conditionValue === "string"
              ? JSON.parse(conditionValue)
              : conditionValue;
        } catch (e) {
          throw `${JSON.stringify(
            conditionValue
          )} is not a valid input for a CUSTOM filter`;
        }
      } else if (filterSchema.type === "IN") {
        if (filter.query[condition] === "Empty") {
          //* Patch. Ideally it should be read from the config
          if (filterSchema.path.indexOf("members.") > -1) {
            query[filterSchema.path] = { $exists: false };
          } else {
            query[filterSchema.path] = { $size: 0 };
          }
        } else if (filterSchema.path.indexOf("members.") > -1) {
          //* Patch. for Members
          query["members"] = {
            $elemMatch: {
              userId: {
                $in: Array.isArray(filter.query[condition])
                  ? filter.query[condition]
                  : [filter.query[condition]],
              },
            },
          };
        } else {
          // Todo: Instead of assigning freshly, we may hve to merge the query Eg: UserGroup x User could collide if present in a wrong order
          query[filterSchema.path] = {
            $in: Array.isArray(filter.query[condition])
              ? filter.query[condition]
              : [filter.query[condition]],
          };
        }
      } else if (filterSchema.type === "ALL") {
        if (filter.query[condition] === "Empty") {
          //* Patch. Ideally it should be read from the config
          if (filterSchema.path.indexOf("members.") > -1) {
            query[filterSchema.path] = { $exists: false };
          } else {
            query[filterSchema.path] = { $size: 0 };
          }
        } else {
          // Todo: Instead of assigning freshly, we may hve to merge the query Eg: UserGroup x User could collide if present in a wrong order
          if (filterSchema.path.indexOf("members.") > -1) {
            query["members"] = {
              $elemMatch: {
                userId: {
                  $all: Array.isArray(filter.query[condition])
                    ? filter.query[condition]
                    : [filter.query[condition]],
                },
              },
            };
          } else {
            query[filterSchema.path] = {
              $all: Array.isArray(filter.query[condition])
                ? filter.query[condition]
                : [filter.query[condition]],
            };
          }
        }
      }
    }
  }
  return query;
};
