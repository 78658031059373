import MailOutlined from "@ant-design/icons/MailOutlined";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Form, Input, message, Popover, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { CollapsibleConfigurationSection } from "components/common/collapsible-configuration-panel/collapsible-configuration-container";

import { GlobalConfig } from "config";
import copyToClipboard from "copy-to-clipboard";
import { useEffect, useMemo, useRef } from "react";
import "./callback-widget-install-instructions.scss";

export const CallbackWidgetInstallInstructions = ({
  connectionId,
  openPanelOnShow,
}: {
  connectionId: string;
  openPanelOnShow?: boolean;
}) => {
  const [appearanceForm] = useForm();

  const widgetSnippet = useMemo(() => {
    return `<script src="${GlobalConfig.COMMON_WIDGET_BASE}/cc-callback-widget.js" id="cc-callback-widget-script" data-widget-id="${connectionId}" defer async onload="loadCCCallbackWidget()"></script>
<script>
  function loadCCCallbackWidget(){
    const widget = new window["cc-callback-widget"]();
    widget.mount({
      type:"CALL_BACK_WIDGET",
      widgetId:"${connectionId}"
    });
  }
</script>`;
  }, [connectionId]);

  const {
    doAction: sendToDeveloper,
    isProcessing,
    response,
    dispatch,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (email) =>
        SDK.callBackRequests.sendScriptToDeveloper(connectionId!, email),
      successMessage: "Email has been sent",
      failureMessage: "Something went wrong",
    }),
    [connectionId]
  );

  const [sendToDeveloperForm] = useForm();

  useEffect(() => {
    if (openPanelOnShow) {
      const timer = setTimeout(() => {
        if (panelRef.current) {
          panelRef.current.openPanel();
        }
      }, 400);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [openPanelOnShow]);

  const panelRef = useRef<any>(null);

  return (
    <CollapsibleConfigurationSection
      icon={"ri-code-box-line"}
      title={"Install Widget"}
      description={
        "A simple way to place the widget on any website or platform"
      }
      ref={panelRef}
    >
      <>
        <ol>
          <li>
            <span>
              1. Copy and paste this code before the closing of{" "}
              <span className="text-green-600">{`</body>`}</span> tag on every
              page of your website
            </span>
            <Input.TextArea
              value={widgetSnippet}
              readOnly={true}
              className="mt-4"
              style={{ height: 200 }}
            ></Input.TextArea>
            <div className="flex flex-row justify-end items-center mb-4">
              <Button
                type="text"
                onClick={() => {
                  copyToClipboard(widgetSnippet);
                  message.success("Snippet has been copied to clipboard");
                }}
              >
                Click here to copy
              </Button>
            </div>
          </li>
          <li>
            2. Reload your website. LiveChat should appear in the bottom right
            corner.
          </li>
        </ol>
        <span>
          Need help?{" "}
          <Popover
            placement="bottom"
            trigger="click"
            content={
              <>
                <Form layout="vertical" form={sendToDeveloperForm}>
                  <div className="flex flex-col" style={{ minWidth: 400 }}>
                    <Form.Item
                      name="email"
                      hasFeedback
                      validateFirst={true}
                      rules={[{ type: "email", required: true }]}
                      label="Email"
                    >
                      <Input
                        prefix={
                          <MailOutlined className="site-form-item-icon" />
                        }
                        placeholder="Email"
                        size="large"
                      />
                    </Form.Item>
                    <div className="flex flex-row justify-end items-center">
                      <Button
                        type="primary"
                        size="large"
                        loading={isProcessing}
                        onClick={async () => {
                          try {
                            await sendToDeveloperForm.validateFields();
                          } catch (e) {
                            message.warning("Invalid Email");
                            return;
                          }
                          const {
                            email,
                          } = sendToDeveloperForm.getFieldsValue();
                          await sendToDeveloper(email).catch((e) => {});
                          sendToDeveloperForm.resetFields();
                        }}
                      >
                        Send Snippet
                      </Button>
                    </div>
                  </div>
                </Form>
              </>
            }
            title={
              <div className="font-bold">
                <Space>
                  <i className="ri-mail-send-line"></i>
                  Send to Developer
                </Space>
              </div>
            }
          >
            <Button type="link">Send this task to your developer</Button>
          </Popover>
        </span>
        {/* <Collapse.Panel
          header={
            <CollapsibleHeader
              icon={<i className="ri-plug-line text-3xl"></i>}
              title={"Plugins"}
              description={"One click installation to existing platforms"}
            />
          }
          key="Plugins"
        >
          <div className="px-6">
            <div className="description mb-4">
              Please select the platform to continue
            </div>
            <Tooltip title="Plugin is not yet available">
              <Space>
                <img
                  src="assets/live-chat-plugin-platforms/wordpress.png"
                  className="plugin-platform-logo"
                  alt="Wordpress"
                />
                <img
                  src="assets/live-chat-plugin-platforms/joomla.png"
                  className="plugin-platform-logo"
                  alt="Joomla"
                />
                <img
                  src="assets/live-chat-plugin-platforms/shopify.png"
                  className="plugin-platform-logo"
                  alt="Shopify"
                />
                <img
                  src="assets/live-chat-plugin-platforms/woocommerce.png"
                  className="plugin-platform-logo"
                  alt="Woo Commerce"
                />
              </Space>
            </Tooltip>
          </div>
        </Collapse.Panel> */}
        {/* </Collapse> */}
      </>
    </CollapsibleConfigurationSection>
  );
};
