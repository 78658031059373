import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tag
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import dayjs from "dayjs";

import { DarkModeBg } from "dark-mode-bg";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { loadAllScheduledReports } from "store/modules/scheduled-reports/scheduled-reports.helpers";
import { selectAllScheduledReports } from "store/modules/scheduled-reports/scheduled-reports.selectors";
import { selectCurrentUserEmail } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { LegacyReportFilter } from "../legacy-report-filter/legacy-report-filter";
import { EmailReportWidgets } from "./email-report-widgets";
import { Timezones } from "./timezones";

export const ScheduledReports = () => {
  const [query, setQuery] = useState({
    period: [dayjs().subtract(7, "day"), dayjs()] as any,
    groupBy: []
  } as any);

  const [form] = useForm();

  const { state: data, retry: reload } = useQueryWithStore(
    selectAllScheduledReports,
    loadAllScheduledReports
  );

  const [
    addEditScheduleModalState,
    setAddEditScheduleModalState
  ] = useSimpleState({
    mode: "ADD" as "ADD" | "EDIT",
    scheduleId: "",
    isModalVisible: false
  });

  const {
    doAction: addScheduledReport,
    isProcessing: isAdding
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => async values =>
        SDK.scheduledReports.create(values).then(d => {
          setAddEditScheduleModalState({ isModalVisible: false });
        }),
      successMessage: "Report has been scheduled",
      failureMessage: "Something went wrong"
    }),
    [setAddEditScheduleModalState]
  );

  const {
    doAction: editScheduledReport,
    isProcessing: isEditing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => async (id: string, edits) =>
        SDK.scheduledReports
          .editById(id, edits)
          .then(e => {
            return reload();
          })
          .then(d => {
            setAddEditScheduleModalState({ isModalVisible: false });
          }),
      successMessage: "Report has been modified",
      failureMessage: "Something went wrong"
    }),
    [reload, setAddEditScheduleModalState]
  );

  const {
    doAction: removeScheduledReport,
    isProcessing: isRemoving
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => async id =>
        SDK.scheduledReports.deleteById(id).then(d => {
          return reload();
        }),
      successMessage: "Schedule has been removed",
      failureMessage: "Something went wrong"
    }),
    [reload]
  );

  const columns = [
    {
      title: "Label",
      dataIndex: "label"
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      render: frequency => (
        <span>
          <Tag>{frequency}</Tag>
        </span>
      ),
      filters: ["MONTHLY", "DAILY"].map(frequency => ({
        text: frequency,
        value: frequency
      })),
      onFilter: (value, record) => record.frequency.indexOf(value) === 0
    },
    {
      title: "Widgets",
      dataIndex: "reportWidgets",
      render: reportWidgets => (
        <span>
          {reportWidgets.length > 3 ? (
            <>
              {reportWidgets.slice(0, 3).map(item => (
                <Tag key={item}>{item}</Tag>
              ))}
              <Tag>+ {reportWidgets.length - 3} more</Tag>
            </>
          ) : (
            <>
              {reportWidgets.map(item => (
                <Tag key={item}>{item}</Tag>
              ))}
            </>
          )}
        </span>
      )
    },
    {
      title: "",
      key: "action",
      align: "right" as "right",
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => removeScheduledReport(record.id)}>
            Remove
          </Button>
          <Button
            type="link"
            onClick={() => {
              form.setFieldsValue(record);
              setQuery(record.filter);
              setAddEditScheduleModalState({
                mode: "EDIT",
                scheduleId: record.id,
                isModalVisible: true
              });
            }}
          >
            Edit
          </Button>
        </Space>
      )
    }
  ];

  const currentUser = useSelector(selectCurrentUserEmail);

  const defaultFormValue = useMemo(() => {
    return {
      frequency: "DAILY",
      timezone: moment.tz.guess(),
      reportEmails: [currentUser],
      reportWidgets: [
        "TOTAL_CONVERSATIONS",
        "CONVERSATIONS_BY_TAGS",
        "CONVERSATIONS_BY_STATUS"
      ]
    };
  }, [currentUser]);

  return (
    <StyledScrollArea className="w-full h-full flex-1 animated fadeInLeftMin">
      <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4">
        <SectionHeader
          title={"Scheduled Reports"}
          icon={<i className="ri-calendar-line"></i>}
          addons={
            <Button
              icon={<i className="ri-add-line"></i>}
              type="primary"
              onClick={() => {
                form.resetFields();
                setAddEditScheduleModalState({
                  mode: "ADD",
                  isModalVisible: true
                });
              }}
            >
              Schedule Report
            </Button>
          }
        />
        <Divider />

        <Table
          columns={columns}
          dataSource={data}
          pagination={data.length < 10 ? false : undefined}
          rowKey="id"
          loading={isRemoving}
        />
        <Modal
          title={
            <ModalTitle
              title={
                addEditScheduleModalState.mode === "ADD"
                  ? "Add Scheduled Report"
                  : "Edit Scheduled Report"
              }
              icon={<i className="ri-folder-chart-line"></i>}
            />
          }
          open={addEditScheduleModalState.isModalVisible}
          footer={null}
          onCancel={() => {
            setAddEditScheduleModalState({ isModalVisible: false });
          }}
          destroyOnClose={true}
          data-click-context="Add/Edit Scheduled Reports Modal"
        >
          <Form
            layout="vertical"
            form={form}
            initialValues={defaultFormValue}
            onFinish={() => {
              const formValues = form.getFieldsValue();
              if (addEditScheduleModalState.mode === "ADD") {
                form.resetFields();
                addScheduledReport({ ...formValues, filter: query });
              } else {
                editScheduledReport(addEditScheduleModalState.scheduleId, {
                  ...formValues,
                  filter: query
                });
              }
            }}
          >
            <Form.Item
              name="label"
              label="Report Label"
              rules={[{ required: true, message: "Please input a value" }]}
            >
              <Input placeholder="Eg: Sales Report" size="large" />
            </Form.Item>

            <Form.Item
              name="frequency"
              label="Frequency"
              rules={[{ required: true, message: "Please select a frequency" }]}
            >
              <Select style={{ minWidth: 150 }}>
                <Select.Option value="DAILY">Daily</Select.Option>
                <Select.Option value="MONTHLY">Monthly</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="timezone" label="Timezone" rules={[]}>
              <Select style={{ minWidth: 150 }} showSearch>
                {Timezones.map(item => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="reportEmails" label="Emails" rules={[]}>
              <Select
                mode="tags"
                style={{ width: "100%" }}
                tokenSeparators={[","]}
                dropdownStyle={{ display: "none" }}
              >
                {" "}
              </Select>
            </Form.Item>

            <Form.Item name="reportWidgets" label="Widgets" rules={[]}>
              <Select style={{ minWidth: 150 }} mode="multiple">
                {EmailReportWidgets.map(item => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div className="filter-container  px-10 py-2 my-10 hover:bg-gray-200 flex flex-row justify-between">
              <LegacyReportFilter
                onFilterChange={query => setQuery(query)}
                hideDateFilter={true}
                customLabel="Filter Conversations"
                hideGroupBy={true}
                disableAdvancedQuery={true}
                initialFilter={query}
              />
            </div>

            <div className="flex flex-row justify-end items-center">
              <Button
                icon={<i className="ri-save-2-line"></i>}
                htmlType="submit"
                type="primary"
                loading={isEditing || isAdding}
              >
                Save
              </Button>
            </div>
          </Form>
          <DarkModeBg />
        </Modal>
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
