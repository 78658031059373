import { iFormGroupItem } from "@sdk/dynamic-forms/dynamic-forms-model";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Form, Input } from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { ColorPickerFormItem } from "components/modules/connections/channels/email/components/forms/email-template";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { loadDynamicFormById } from "store/modules/dynamic-forms/dynamic-forms.helpers";
import { selectDynamicFormById } from "store/modules/dynamic-forms/dynamic-forms.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { DynamicForm } from "./components/dynamic-form";
import { DynamicFormFieldEditor } from "./dynamic-form-field-editor";

import { GlobalConfig } from "config";
import copyToClipboard from "copy-to-clipboard";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import "./dynamic-form-editor.style.scss";
import { DynamicFormEditorTemplateCreatorForm } from "./dynamic-form-template-creator-form";

export const DynamicFormEditor = ({
  dynamicFormId,
}: {
  dynamicFormId: string;
}) => {
  const { state: dynamicForm, retry: reload } = useQueryWithStore(
    selectDynamicFormById(dynamicFormId),
    loadDynamicFormById(dynamicFormId)(),
  );

  const {
    doAction: onEditDynamicForm,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.dynamicForms.editById(dynamicFormId, {
          ...values,
        }),
      successMessage: "Form has been saved",
      failureMessage: "Something went wrong",
    }),
    [dynamicFormId],
  );

  // Form State
  const [formData, setFormData] = useState<iFormGroupItem[]>(
    dynamicForm?.layout?.body?.items || [],
  );

  useEffect(() => {
    if (dynamicForm) {
      setFormData(dynamicForm?.layout?.body?.items || []);
    }
  }, [dynamicForm]);

  const isFieldsChanged = useMemo(() => {
    return formData !== dynamicForm?.layout?.body?.items;
  }, [dynamicForm?.layout?.body?.items, formData]);

  const formConfig = useMemo(() => {
    const config = _.cloneDeep(dynamicForm);
    if (config?.layout?.body?.items) {
      config.layout.body.items = formData;
    }

    return config;
  }, [dynamicForm, formData]);

  const organization = useSelector(selectOrganization);

  const formURL = useMemo(() => {
    if (GlobalConfig.IS_PROD && formConfig) {
      const domainConfig =
        organization?.appData.HELP_DESK?.customPagesDomainConfig;
      const url =
        domainConfig?.domain &&
        domainConfig?.isVerified &&
        domainConfig?.isConfigured
          ? `https://${domainConfig?.domain}/forms/${formConfig.id}`
          : `http://www.ccpages.xyz/forms/${formConfig.id}`;
      return url;
    }
    return `http://localhost:3000/forms/${formConfig?.id}`;
  }, [formConfig, organization?.appData.HELP_DESK?.customPagesDomainConfig]);

  const isTemplateCreator =
    window.location.href.includes("localhost") ||
    window.location.href.includes("reach-it-right") ||
    window.location.href.includes("cc.clickconnector.io");

  if (!dynamicForm) {
    return (
      <div
        className="flex flex-row w-full justify-center items-center"
        style={{ height: "calc(100vh - 10rem)" }}
      >
        <i className="ri-loader-5-line animate-spin text-4xl"></i>
      </div>
    );
  }

  return (
    <div
      className="flex flex-row w-full"
      style={{ height: "calc(100vh - 10rem)" }}
    >
      <div className="fields-editor px-4 h-full overflow-auto">
        <div className="flex flex-row justify-end items-center mt-2">
          <Button
            type="text"
            icon={<i className="ri-clipboard-line"></i>}
            onClick={() => {
              copyToClipboard(formURL);
            }}
          >
            Copy Form URL
          </Button>
        </div>
        {isTemplateCreator && (
          <DynamicFormEditorTemplateCreatorForm dynamicFormId={dynamicFormId} />
        )}
        <ConfigurationEditorComponent
          icon={"ri-layout-top-2-line"}
          title={"Form Header"}
          description={"Configure form header section"}
          entityType={"DYNAMIC_FORM"}
          entityId={dynamicFormId}
        >
          <Form.Item
            name={["label"]}
            label={"Form Label (Internal Reference)"}
            rules={[{ required: true, message: "Please enter a form label" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["layout", "header", "title"]}
            label={"Form Title"}
            rules={[{ required: true, message: "Please enter a form title" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["layout", "header", "description"]}
            label={"Form Description"}
          >
            <Input.TextArea />
          </Form.Item>
          <ColorPickerFormItem
            fieldName={["layout", "body", "themePrimaryColor"]}
            label="Form Theme Color"
          />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={"ri-layout-bottom-line"}
          title={"Form Footer"}
          description={"Configure form footer section"}
          entityType={"DYNAMIC_FORM"}
          entityId={dynamicFormId}
        >
          <Form.Item
            name={["layout", "footer", "text"]}
            label={"Footer Text"}
            rules={[]}
          >
            <Input />
          </Form.Item>
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={"ri-emotion-happy-line"}
          title={"Post Submission"}
          description={"Configure texts after form is submitted"}
          entityType={"DYNAMIC_FORM"}
          entityId={dynamicFormId}
        >
          <Form.Item
            name={["layout", "submission", "message"]}
            label={"Form Submission Message"}
            rules={[]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["layout", "submission", "additionalMessage"]}
            label={"Additional Message"}
          >
            <Input.TextArea />
          </Form.Item>
        </ConfigurationEditorComponent>

        <div className="">
          <div className="flex flex-row justify-between items-center px-4">
            <div className="text-lg font-bold">Setup Questions</div>
            <Button
              icon={<i className="ri-save-2-line"></i>}
              type="primary"
              onClick={() =>
                onEditDynamicForm({
                  layout: {
                    body: {
                      items: formData || [],
                    },
                  },
                })
              }
              loading={isProcessing}
              disabled={!isFieldsChanged}
            >
              Save Questions
            </Button>
          </div>
          <DynamicFormFieldEditor data={formData} onDataChange={setFormData} />
        </div>
      </div>

      <div
        className="flex-grow flex flex-col justify-center items-center h-full"
        style={{
          background: `${formConfig?.layout?.body?.themePrimaryColor}11`,
        }}
      >
        <DynamicForm config={formConfig} />
      </div>
    </div>
  );
};
export default DynamicFormEditor;
