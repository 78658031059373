import { useSDK } from "@sdk";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Alert, Button, Modal } from "antd";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { SimpleContactCard } from "../similar-contacts-explorer/simple-contact-card";

export const QuickContactMergeModal = ({
  visible,
  onChangeVisibility,
  contactId,
  email,
  phone,
  onMerged,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  contactId: string;
  email: string;
  phone: string;
  onMerged?: () => any;
}) => {
  const { data: similarContacts, isLoading, error, reload } = useSDK(
    (SDK) => {
      const query = {
        $or: _.flattenDeep(
          [
            email
              ? {
                  "data.primaryEmail": email,
                }
              : undefined,
            phone
              ? {
                  "data.primaryMobile": phone,
                }
              : undefined,
          ].filter((e) => e)
        ),
        id: { $ne: contactId },
        "metaData.isVerified": true,
      };
      return SDK.queryContacts({ query, options: {} });
    },
    [email, phone, visible, contactId],
    !(contactId && (email || phone) && visible)
  );

  const {
    doAction: mergeContact,
    isProcessing: isMergingContact,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (target: string) =>
        SDK.mergeContacts({
          target,
          source: contactId,
          dataOverrides: {},
        }).then((d) => {
          if (onMerged) {
            onMerged();
          } else {
            onChangeVisibility(false);
          }
        }),
      successMessage: "Done",
      failureMessage: "Something went wrong",
    }),
    [contactId, onChangeVisibility, onMerged]
  );

  return (<>
    <Modal
      title={
        <ModalTitle
          title="Contact Merger"
          icon={<i className="ri-shield-user-fill"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save"
      width={500}
      data-click-context="Contact Merger Modal"
    >
      {contactId && (
        <div
          className="flex flex-row w-full justify-center items-center"
          style={{ minHeight: 320 }}
        >
          <>
            {isLoading && (
              <div className="flex flex-col text-center">
                <LoadingIndicatorWithSpin />
                <div className="font-bold"> Processing</div>
              </div>
            )}
            {error && (
              <div className="flex flex-col text-center">
                <div className="font-bold">
                  {" "}
                  Something went wrong{" "}
                  <Button onClick={() => reload}>Retry</Button>
                </div>
              </div>
            )}
            {!error &&
              !isLoading &&
              similarContacts &&
              similarContacts.docs &&
              similarContacts.docs.length > 0 && (
                <div className="flex flex-col text-center">
                  <Alert
                    message={<b>Existing Contact Record Found</b>}
                    type="info"
                    description={
                      "Contact's primary email or primary mobile number matches with the following contact."
                    }
                    className="mt-3"
                  />
                  <SimpleContactCard
                    contactId={similarContacts.docs[0].id!}
                  />
                  <Button
                    type="primary"
                    onClick={() => mergeContact(similarContacts.docs[0].id!)}
                    loading={isMergingContact}
                    size="large"
                  >
                    Merge Contact
                  </Button>
                </div>
              )}
            {!error &&
              !isLoading &&
              similarContacts &&
              similarContacts.docs &&
              similarContacts.docs.length === 0 && (
                <div className="flex flex-col text-center font-bold">
                  No Matching Contact Found
                </div>
              )}
          </>
        </div>
      )}
      <DarkModeBg />
    </Modal>
  </>);
};
