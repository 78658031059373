import Icon from "@mdi/react";
import { Button, DatePicker, Divider, Form, Input, Radio, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import classNames from "classnames";
import { ConnectionSelector } from "components/modules/conversations/components/connection-selector/connection-selector";
import { ConversationTagSelector } from "components/modules/conversations/components/converation-tag-selector";
import { SingleUserSelector } from "components/modules/user-management/users/components/single-user-selector/single-user-selector";
import _ from "lodash";
import { memo, useMemo, useState } from "react";
import { useDateRangeFilterPresets } from "utils/hooks/use-date-range-filter-presets";

import { iFilterConfigSchema, iFilterOption } from "./filter-config-schema";
import { SimpleFilterSelect } from "./simple-filter-selector";

import { SingleContactSelector } from "components/modules/crm/contacts/components/single-contact-selector/single-contact-selector-new";
import dayjs from "dayjs";
import { CCPopover } from "../popover/popover";

export const FilterBar = memo(
  ({
    selectedFilters,
    setSelectedFilters,
    schema,
    children,
    label,
    className,
    prioritizeFilters,
    hideAddFilters,
    hideLabel
  }: {
    selectedFilters: iFilterOption[];
    setSelectedFilters: (options: iFilterOption[]) => any;
    schema: iFilterConfigSchema;
    children?;
    label?: string | JSX.Element;
    className?: string;
    prioritizeFilters?: boolean;
    hideAddFilters?: boolean;
    hideLabel?: boolean;
  }) => {
    return (
      <div className={className || "p-4 z-10 relative"}>
        {!hideLabel && (
          <div className="font-bold my-2">{label || "Applied Filters:"}</div>
        )}

        <Space wrap={true}>
          {(selectedFilters || []).map((item, index) => (
            <div key={index.toString()} className="">
              <FilterOptionPill
                filterOption={item}
                onDeleteOption={() => {
                  setSelectedFilters(_.without(selectedFilters, item));
                }}
                onChangeOption={option => {
                  const newFilters = [...selectedFilters];
                  newFilters[index] = option;
                  setSelectedFilters(newFilters);
                }}
                schema={schema}
              />
            </div>
          ))}
          {children}
          {!hideAddFilters && (
            <CCPopover
              content={
                <AddFilterButton
                  onAddFilter={filter => {
                    const existingFilters = selectedFilters;
                    setSelectedFilters([...existingFilters, filter]);
                  }}
                  existingFilters={(selectedFilters || []).map(
                    item => item.key
                  )}
                  schema={schema}
                  breakByPriority={prioritizeFilters}
                />
              }
              trigger="click"
              // overlayClassName="styled-popover"
              placement="bottom"
              destroyTooltipOnHide={true}
            >
              <div
                className={classNames(
                  "flex flex-row justify-between items-center flex-1 text-base font-semibold leading-6  rounded-lg cursor-pointer py-1 px-4 hover:bg-gray-100 dark:hover:bg-gray-800 border border-dashed border-gray-500 text-gray-700 dark:text-gray-300"
                )}
                // onClick={() => setSelectedFilter(item.key)}
              >
                <div className="left flex flex-row items-center">
                  <i className="ri-add-line"></i>
                  Add Filter
                </div>
                <div className="right flex flex-row items-center">
                  <i className="ri-arrow-down-s-fill"></i>
                </div>
              </div>
            </CCPopover>
          )}
        </Space>
      </div>
    );
  }
);

export const FilterOptionPill = memo(
  ({
    filterOption,
    onChangeOption,
    onDeleteOption,
    mode = "EDIT",
    schema
  }: {
    filterOption: iFilterOption;
    onChangeOption: (option: iFilterOption) => any;
    onDeleteOption?: () => any;
    mode?: "ADD" | "EDIT";
    schema: iFilterConfigSchema;
  }) => {
    const { filterConfig, operator } = useMemo(() => {
      const filterConfig = _.find(schema.filters, {
        key: filterOption.key
      });
      const operator = _.find(filterConfig?.fieldOptions?.operators, {
        id: filterOption.operator
      });
      return {
        filterConfig,
        operator
      };
    }, [filterOption?.key, filterOption?.operator, schema]);

    const [popOverVisible, setPopoverVisibility] = useState(false);

    if (!filterConfig) {
      return <>Unidentified Filter</>;
    }

    // * CC Popover is used because of a bug. When auto focus is enabled on a select and you try to use the popeover with it, the select popover never hides
    return (
      <CCPopover
        open={popOverVisible}
        onOpenChange={setPopoverVisibility}
        content={({ closePopover }) => (
          <FilterConfig
            selectedFilter={filterOption.key}
            clearFilterSelection={() => {}}
            onApply={filterOption => {
              onChangeOption(filterOption);
              closePopover();
              setPopoverVisibility(false);
            }}
            hideHeader={true}
            initialValue={{
              operator: operator?.id,
              operatorConfig: filterOption.operatorConfig
            }}
            schema={schema}
          />
        )}
        trigger="click"
        placement="bottom"
        // overlayClassName="styled-popover2"
        destroyTooltipOnHide={true}
      >
        <div
          className={classNames(
            "flex flex-row justify-between items-center flex-1 text-base font-semibold leading-6  rounded-lg cursor-pointer py-1 px-4",
            {
              "hover:bg-gray-200 dark:hover:bg-gray-800 bg-gray dark:bg-gray-950 border  border-gray-200 dark:border-gray-800 text-gray-700 dark:text-gray-300":
                mode === "EDIT",
              "hover:bg-gray-100 dark:hover:bg-gray-900 border border-dashed border-gray-500 text-gray-700 dark:text-gray-300":
                mode === "ADD"
            }
          )}
          // onClick={() => setSelectedFilter(item.key)}
        >
          <div className="left flex flex-row items-center flex-wrap">
            {mode === "ADD" && <i className="ri-add-line"></i>}
            {typeof filterConfig.icon === "string" ? (
              filterConfig.icon.includes("ri-") ? (
                <i className={`${filterConfig.icon} pr-2 text-base`}></i>
              ) : (
                <span className="pr-2 text-base">
                  <Icon path={filterConfig.icon} size={"1rem"} />
                </span>
              )
            ) : (
              filterConfig.icon
            )}

            <div className="label">{filterConfig.label}</div>
            {mode === "EDIT" && (
              <>
                <div className="label"> &nbsp; {operator?.label}</div>
                {filterOption.operatorConfig?.value && (
                  <div className="label flex flex-row items-center">
                    {operator?.viewRenderer ? (
                      <>
                        &nbsp;
                        {operator.viewRenderer(
                          filterOption.operatorConfig?.value
                        )}
                      </>
                    ) : (
                      <> &nbsp; {filterOption.operatorConfig?.value}</>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="right flex flex-row items-center">
            <i className="ri-arrow-down-s-fill"></i>
          </div>
          {onDeleteOption && (
            <div className="remove-button">
              <Button
                type="text"
                size="small"
                icon={<i className="ri-delete-bin-line"></i>}
                className="p-0 h-auto w-auto"
                onClick={e => {
                  e.stopPropagation();
                  onDeleteOption();
                }}
              />
            </div>
          )}
        </div>
      </CCPopover>
    );
  }
);

export const FilterConfig = memo(
  ({
    selectedFilter,
    clearFilterSelection,
    onApply,
    hideHeader,
    initialValue,
    schema
  }: {
    selectedFilter: string;
    clearFilterSelection: () => any;
    onApply: (filter: iFilterOption) => any;
    hideHeader?: boolean;
    initialValue?: any;
    schema: iFilterConfigSchema;
  }) => {
    const filterConfig = useMemo(() => {
      return _.find(schema.filters, {
        key: selectedFilter
      });
    }, [selectedFilter, schema]);

    const [form] = useForm();
    const defaultFormValue = useMemo(() => {
      if (initialValue) {
        const sanitizedValue = _.cloneDeep(initialValue);
        const operator = sanitizedValue.operator;
        if (!operator) {
          return sanitizedValue;
        }
        const operatorConfig = _.find(filterConfig?.fieldOptions?.operators, {
          id: operator
        });
        if (
          operatorConfig &&
          operatorConfig?.valueField?.type === "DATE_RANGE"
        ) {
          const [startDate, endDate] = sanitizedValue.operatorConfig.value;
          sanitizedValue.operatorConfig.value = [
            dayjs(startDate),
            dayjs(endDate)
          ];
        }
        return sanitizedValue;
      }
      return {
        operator: filterConfig?.fieldOptions?.defaultOperator
      };
    }, [
      filterConfig?.fieldOptions?.defaultOperator,
      filterConfig?.fieldOptions?.operators,
      initialValue
    ]);

    const [selectedOperator, setSelectedOperator] = useState(
      defaultFormValue.operator
    );

    const dateRangeFilterPresets = useDateRangeFilterPresets();

    return (
      <div
        onClick={e => {
          // Patch file is created to resolve modal outside click
          e.stopPropagation();
        }}
      >
        {!hideHeader && (
          <div className="header flex flex-row items-center">
            <Button
              type="text"
              icon={<i className="ri-arrow-left-s-line"></i>}
              onClick={clearFilterSelection}
            ></Button>
            <div className="filter-name font-bold">{filterConfig?.label}</div>
          </div>
        )}

        <div className="filter-option p-4" style={{ width: "24rem" }}>
          <Form
            layout="horizontal"
            form={form}
            initialValues={defaultFormValue}
            onChange={e => {
              const { operator } = form.getFieldsValue();
              setSelectedOperator(operator);
            }}
          >
            <div className="w-full">
              <Form.Item
                label={""}
                name={["operator"]}
                hasFeedback={false}
                style={{ marginBottom: 0, marginLeft: 5 }}
              >
                <Radio.Group className="w-full">
                  <Space direction="vertical" className="w-full">
                    {filterConfig?.fieldOptions?.operators.map(item => (
                      <div key={item.id}>
                        <Radio
                          value={item.id}
                          className="w-full hover:bg-gray-100 dark:hover:bg-gray-900 p-2"
                        >
                          {item.label}
                        </Radio>
                        {item.valueField && selectedOperator === item.id && (
                          <div className="ml-6">
                            {item.valueField.type === "INPUT" && (
                              <Form.Item
                                style={{ margin: 0 }}
                                name={["operatorConfig", "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter a value"
                                  }
                                ]}
                              >
                                <Input autoFocus />
                              </Form.Item>
                            )}
                            {item.valueField.type === "SELECT" && (
                              <Form.Item
                                style={{ margin: 0 }}
                                name={["operatorConfig", "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select an option"
                                  }
                                ]}
                              >
                                <SimpleFilterSelect
                                  spec={item.valueField}
                                  // {...item.valueField.config}
                                  // https://github.com/ant-design/ant-design/issues/41239
                                  autoFocus={true}
                                />
                              </Form.Item>
                            )}
                            {item.valueField.type === "USER_SELECTOR" && (
                              <Form.Item
                                style={{ margin: 0 }}
                                name={["operatorConfig", "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter a user"
                                  }
                                ]}
                              >
                                <SingleUserSelector autoFocus />
                              </Form.Item>
                            )}
                            {item.valueField.type === "CONTACT_SELECTOR" && (
                              <Form.Item
                                style={{ margin: 0 }}
                                name={["operatorConfig", "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter a customer"
                                  }
                                ]}
                              >
                                <SingleContactSelector hideAdd={true} />
                              </Form.Item>
                            )}
                            {item.valueField.type ===
                              "CONVERSATION_TAG_SELECTOR" && (
                              <Form.Item
                                style={{ margin: 0 }}
                                name={["operatorConfig", "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select a tag"
                                  }
                                ]}
                              >
                                <ConversationTagSelector
                                  autoFocus
                                  enableCustomTokens
                                />
                              </Form.Item>
                            )}
                            {item.valueField.type === "CONNECTION_SELECTOR" && (
                              <Form.Item
                                style={{ margin: 0 }}
                                name={["operatorConfig", "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select a connection"
                                  }
                                ]}
                              >
                                <ConnectionSelector autoFocus={true} />
                              </Form.Item>
                            )}
                            {item.valueField.type === "DATE_RANGE" && (
                              <Form.Item
                                style={{ margin: 0 }}
                                name={["operatorConfig", "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter a date range"
                                  }
                                ]}
                              >
                                <DatePicker.RangePicker
                                  className="w-full"
                                  bordered={true}
                                  autoFocus
                                  defaultOpen
                                  presets={dateRangeFilterPresets}
                                />
                              </Form.Item>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="footer flex flex-row items-center justify-end p-2">
          <Button
            type="primary"
            icon={<i className="ri-checkbox-circle-line"></i>}
            className="font-bold"
            onClick={async () => {
              try {
                await form.validateFields();
                const fieldValues = form.getFieldsValue();
                console.log("fieldValues", fieldValues);
                const operatorConfig = _.find(
                  filterConfig?.fieldOptions?.operators,
                  { id: selectedOperator }
                );
                if (
                  operatorConfig &&
                  operatorConfig?.valueField?.type === "DATE_RANGE"
                ) {
                  const [startDate, endDate] = fieldValues.operatorConfig.value;
                  fieldValues.operatorConfig.value = [
                    dayjs(startDate)
                      .startOf("day")
                      .valueOf(),
                    dayjs(endDate)
                      .endOf("day")
                      .valueOf()
                  ];
                }
                onApply({
                  ...fieldValues,
                  key: selectedFilter
                });
              } catch (e) {
                // message.warning("Please check your filter field");
              }
            }}
          >
            Apply Filter
          </Button>
        </div>
      </div>
    );
  }
);

export const AddFilterButton = memo(
  ({
    onAddFilter,
    existingFilters,
    schema,
    breakByPriority
  }: {
    onAddFilter: (filter: iFilterOption) => any;
    existingFilters: string[];
    schema: iFilterConfigSchema;
    breakByPriority?: boolean;
  }) => {
    const [selectedFilter, setSelectedFilter] = useState("");

    const [showOnlyPriority, setShowOnlyPriority] = useState(true);

    const filterOptionsToShow = useMemo(() => {
      const available = schema.filters.filter(
        item => !existingFilters.includes(item.key)
      );
      if (breakByPriority) {
        if (showOnlyPriority) {
          return available.slice(0, 7);
        }
      }
      return available;
    }, [breakByPriority, existingFilters, schema.filters, showOnlyPriority]);

    return (
      <div
        style={{ minWidth: "16rem", maxHeight: "30rem" }}
        onClick={e => e.stopPropagation()}
        className="overflow-auto"
      >
        {!selectedFilter && (
          <div>
            <Divider className="my-0 text-xs text-gray-600">Filter by</Divider>
            {filterOptionsToShow.map(item => {
              return (
                <div
                  className="flex flex-row justify-between items-center flex-1 text-base leading-6 text-gray-800 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900 rounded-sm cursor-pointer p-2"
                  key={item.key}
                  onClick={() => setSelectedFilter(item.key)}
                >
                  <div className="left flex flex-row items-center">
                    {typeof item.icon === "string" ? (
                      item.icon.includes("ri-") ? (
                        <i className={`${item.icon} pr-2 text-base`}></i>
                      ) : (
                        <span className="pr-2 text-base">
                          <Icon path={item.icon} size={"1rem"} />
                        </span>
                      )
                    ) : (
                      item.icon
                    )}
                    <div className="label">{item.label}</div>
                  </div>
                  <div className="right flex flex-row items-center">
                    <i className="ri-arrow-drop-right-line"></i>
                  </div>
                </div>
              );
            })}
            {breakByPriority && showOnlyPriority && (
              <div className="footer flex flex-row justify-center items-center">
                <Button
                  type="text"
                  onClick={() => setShowOnlyPriority(false)}
                  icon={<i className="ri-arrow-down-s-line"></i>}
                >
                  Show all Filters
                </Button>
              </div>
            )}
          </div>
        )}
        {selectedFilter && (
          <div>
            <FilterConfig
              selectedFilter={selectedFilter}
              clearFilterSelection={() => setSelectedFilter("")}
              onApply={filter => {
                onAddFilter(filter);
              }}
              schema={schema}
            />
          </div>
        )}
      </div>
    );
  }
);
