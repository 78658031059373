import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import {
  MessageAttachmentTypes,
  iMessageSenderType,
} from "@sdk/conversations/conversations.models";
import { iSendMessageReq } from "@sdk/help-desk/help-desk.models";
import { processServerError, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iPreset } from "@sdk/user-management/preset-state-model";
import { iCCMacro } from "@sdk/user-management/user-management.models";
import { Button, Space, Spin, Tabs, Tag, Tooltip, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ExtendControlType } from "braft-editor";
import { BraftMessageInput } from "components/common/draft-js-advanced/draft-js-advanced-playground-2";
import { ModalTitle } from "components/common/modal-title";
import { ActionsBar } from "components/common/reply-input/actions-bar";
import { EmailModifier } from "components/common/reply-input/email-modifer";
import { EmojiPicker } from "components/common/reply-input/emoji-picker";
import { iAction } from "components/modules/conversations/components/action-editor/models";
import {
  AvailableTokens,
  MessageTokenReplacerMap,
} from "components/modules/conversations/components/chat-input/available-message-tokens";
import { extractMentions } from "components/modules/conversations/components/chat-input/extract-mentions";
import { AddSignatureButton } from "components/modules/email-signatures/email-signature-selector/add-email-signature-button";
import { AddPresetButton } from "components/modules/message-presets/preset-selector/add-preset-button";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import { EditorState, SelectionState } from "draft-js";
import clearFormatting from "draft-js-clear-formatting";
import _, { find } from "lodash";
import {
  HolidayQuoteCreator,
  MyManagePresets,
  OpenAiAssistant,
  PNRConverter,
  ShopifyProductBrowser,
} from "modal-registry";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { selectContactById } from "store/modules/contacts/contacts.selectors";

import { mdiAutoFix } from "@mdi/js";
import { iShopifyCustomer, iShopifyOrder } from "@sdk/shopify/shopify.service";
import { BadgesController } from "badge-controller";
import { ReactMentionsInput } from "components/common/draft-js-advanced/react-mentions-playground-3";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { MDIIcon } from "components/common/mdi-icon";
import { useMediaQuery } from "react-responsive";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import {
  addNewMessage,
  removeTemporaryMessages,
} from "store/modules/messages/message.actions";
import { GenerateTemporaryMessuage } from "store/modules/messages/messages.helpers";
import { selectMessageByConversationId } from "store/modules/messages/messages.selectors";
import { setEnteredMessages } from "store/modules/ui-state/ui-state.actions";
import {
  selectEnteredMessage,
  selectIsAdvancedMode,
} from "store/modules/ui-state/ui-state.selectors";
import {
  selectAllUsers,
  selectCurrentUser,
  selectCurrentUserId,
  selectCurrentUserPresetsX,
  selectUser,
} from "store/modules/users/users.selectors";
import {
  selectIndustry,
  selectMacros,
  selectOrganizationWideMessagePresetsX,
} from "store/modules/workspace/workspace.selectors";
import { useDoubleSelectorWithMemoize } from "store/utils/use-double-selector-with-memoize";
import { UserTracker } from "user-tracker";
import { getTokens } from "utils/extract-tokens";
import { GlobalEventEmitter } from "utils/global-event-emitter";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { useStateWithGetter } from "utils/hooks/use-state-with-getter";
import { useThrottle } from "utils/hooks/use-throttle";
import {
  convertHtmlToPlainText,
  convertHtmlToPlainTextWithLineBreaks,
} from "utils/html-to-plain-text";
import { isHTML } from "utils/is-html";
import { SplitMessage } from "utils/message-splitter";
import { stringArrayToSentence } from "utils/string-array-to-sentence";
import { textToHtml } from "utils/text-to-html";
import { AvailableTokenTableForBots } from "../bot-builder/node-types/message-node/message-node";
import { WithAttachments } from "../bot-builder/node-types/message-node/with-attachments";
import { AddArticleButton } from "../knowledge-base/article-selector/add-article-button";
import { replaceAll } from "../playground/offline-chat-bot/utils/replace-all";
import "./chat-input-2.scss";
import { MessageScheduler } from "./components/message-scheduler/message-scheduler";
import {
  AvailableShopifyTokens,
  ShopifyMessageTokenResolver,
  resolveOrder,
  resolveShopifyCustomer,
} from "./shopify/shopify-token-resolver";
import { useBetaFeatures } from "./use-beta-features";

const emptyArray = [];

const renderTabBar = (props, TabBar) => (
  <div className="flex flex-row w-full items-center">
    <TabBar {...(props as any)} tabIndex={-1} />
  </div>
);

const tabBarStyle = {
  paddingLeft: 20,
  paddingRight: 20,
  flex: 1,
  margin: 0,
};

export const ChatInputPlayground = memo(
  ({
    conversationId,
    onReplyInputFocus,
  }: {
    conversationId: string;
    onReplyInputFocus?: () => any;
  }) => {
    const store = useStore();
    const currentUserId = useSelector(selectCurrentUserId);
    const isAdvancedMode = useSelector(selectIsAdvancedMode);
    const betaFeatures = useBetaFeatures();
    const conversationConnectionType = useDoubleSelectorWithMemoize(
      () => selectConversationById(conversationId),
      [conversationId],
      (conversation) => conversation?.connectionType,
      false,
    );

    const contactId = useDoubleSelectorWithMemoize(
      () => selectConversationById(conversationId),
      [conversationId],
      (conversation) => conversation?.contactId,
      false,
    );
    const messagePresets_currentUser = useSelector(selectCurrentUserPresetsX);
    const messagePresets_organization = useSelector(
      selectOrganizationWideMessagePresetsX,
    );
    const _macroCommands = useSelector(selectMacros);
    const allUsers = useSelector(selectAllUsers);

    // Refs
    const replyInputRef = useRef<any>();
    const notesInputRef = useRef<any>();
    const emailDateModifierRef = useRef<any>();
    const attachmentsHandlerRef = useRef<any>();

    // Input States - Start
    const [actionsForm] = useForm();
    const [
      isReplyInputContentEmpty,
      setReplyInputContentEmpty,
      getReplyInputContentState,
    ] = useStateWithGetter(false);

    const [notesInputContent, onNotesInputContentChange] = useState("");
    const notesInputTextContent = useMemo(() => {
      const text = convertHtmlToPlainText(notesInputContent);
      return text;
    }, [notesInputContent]);

    // Input States - End

    const [scheduleMessageModalState, setScheduleMessageModalState] =
      useSimpleState({
        isModalVisible: false,
        data: {
          contactId: "",
          connectionId: "",
          conversationId: "",
          message: "",
          attachments: [] as string[],
          subject: "" as string | undefined,
          cc: [] as string[] | undefined,
          bcc: [] as string[] | undefined,
          to: [] as string[] | undefined,
          actions: [] as iAction[] | undefined,
        },
      });

    // Input For Input Components - START
    const users = useMemo(
      () =>
        allUsers.map((user) => ({
          id: user.id,
          name: getUserName(user),
        })),
      [allUsers],
    );

    const messagePresets = useMemo(() => {
      const folders = [
        ...(messagePresets_currentUser.folders || []),
        ...(messagePresets_organization.folders || []),
      ];
      const allPresets = [
        ...(messagePresets_currentUser.presets || []),
        ...(messagePresets_organization.presets || []),
      ]
        .filter((e) => e)
        .map((e) => ({
          ...e,
          folderLabel: _.find(folders, { id: e.folder })?.label,
        }));

      if (allPresets.length > 0) {
        return allPresets;
      }
      return [{ id: "NOT_AVAILABLE" }];
    }, [messagePresets_currentUser, messagePresets_organization]);

    const macroCommands = useMemo(() => {
      if (_macroCommands.length > 0) {
        return _macroCommands;
      }
      return [{ id: "NOT_AVAILABLE" }];
    }, [_macroCommands]);

    const extendControls = useMemo(() => {
      const extendControls: ExtendControlType[] = [
        {
          key: "message-presets",
          type: "modal",
          text: (
            <Tooltip title="Message Personalization">
              <i className="ri-braces-line"></i>
            </Tooltip>
          ),
          modal: {
            id: "message-personalization-modal",
            title: (
              <span>
                <ModalTitle
                  title="Message Personalization"
                  icon={<i className="ri-braces-line"></i>}
                />
              </span>
            ) as any,
            showFooter: false,
            closeOnBlur: true,
            className: "message-personalization-modal",
            children: (
              <div style={{ width: 400, padding: "0 10px" }}>
                <div className="my-4">
                  You can use variable tokens in your messages
                  <div className="font-bold my-4">Available Tokens:</div>
                  <table className="simple-table-style w-full">
                    <thead>
                      <tr>
                        <th className="font-bold">Variable</th>
                        <th className="font-bold">Token</th>
                      </tr>
                    </thead>
                    <tbody>
                      {AvailableTokenTableForBots.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.label}</td>
                            <td>
                              <Tag
                                onClick={() => {
                                  replyInputRef.current.insertHtmlToCursor(
                                    `<span class="variable-token-item">{${item.token}}</span>`,
                                  );
                                  const cb =
                                    replyInputRef.current.getControlBarInstance();
                                  cb?.extendedModals?.[
                                    "message-personalization-modal"
                                  ].close();
                                }}
                                className="cursor-pointer"
                              >
                                {item.token}
                              </Tag>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ),
          },
        },
      ];
      return extendControls;
    }, []);

    // Input For Input Components - END

    const [inputMode, setInputMode] = useState("REPLY" as "REPLY" | "NOTES");
    const [editorId, setEditorId] = useState("message-chat-input");
    const [attachments, setAttachments] = useState([] as string[]);
    const [isUploading, setIsUploading] = useState(false);
    const [replyToMessage, setReplyToMessage] = useState("");

    // Helper Methods - START

    const setInputState = useCallback(
      ({
        content,
        attachments,
        actions,
      }: {
        content?: string;
        attachments?: string[];
        actions?: iAction[];
      }) => {
        if (content && replyInputRef.current) {
          replyInputRef.current.setHtmlContent(content);
        }
        if (attachments) {
          setAttachments(attachments);
        }
        if (actions) {
          actionsForm.setFieldsValue({
            actions,
          });
        }
      },
      [actionsForm],
    );
    const clearStyling = useCallback(() => {
      let currentState = replyInputRef.current.getCurrentState();
      let currentContent = currentState.getCurrentContent();
      const selectionState = SelectionState.createEmpty("blockkey");
      let selection = selectionState.merge({
        anchorKey: currentContent.getFirstBlock().getKey(),
        anchorOffset: 0,
        focusOffset: currentContent.getLastBlock().getText().length,
        focusKey: currentContent.getLastBlock().getKey(),
      });

      replyInputRef.current.setEditorState(
        EditorState.acceptSelection(currentState, selection),
      );

      currentState = replyInputRef.current.getCurrentState();

      const newEditorState = clearFormatting(
        EditorState.acceptSelection(currentState, selection),
        {
          inline: true,
          entities: true,
          lists: true,
        },
      );
      replyInputRef.current.setEditorState(newEditorState);
    }, []);

    const openScheduleMessageModal = useCallback(
      (req: iSendMessageReq & {}) => {
        const conversation = selectConversationById(conversationId)(
          store.getState(),
        );
        setScheduleMessageModalState({
          isModalVisible: true,
          data: {
            conversationId,
            ...req,
            contactId: conversation?.contactId,
            connectionId: conversation?.connectionType,
            message: req.message,
            attachments: req.attachments || [],
            subject: req.subject,
            cc: req.cc || [],
            bcc: req.bcc || [],
            to: req.to || [],
            actions: req.actions || [],
          },
        });
      },
      [conversationId, setScheduleMessageModalState, store],
    );

    const __tokenReplacer = useCallback(
      (message: string) => {
        const tokens = getTokens(message);
        for (const token of tokens) {
          if (AvailableTokens.includes(token)) {
            const tokenParts = token.split("_");
            const nameSpace = tokenParts.shift();
            const tokenVariable = tokenParts.join("_");
            if (nameSpace === "CONTACT") {
              const replaceFunction =
                MessageTokenReplacerMap.CONTACT[tokenVariable];
              const conversation = selectConversationById(conversationId)(
                store.getState(),
              );
              const contact = selectContactById(conversation?.contactId)(
                store.getState(),
              );
              if (replaceFunction) {
                message = replaceAll(
                  message,
                  `<span class="variable-token-item">{${token}}</span>`,
                  replaceFunction(contact),
                );
                message = replaceAll(
                  message,
                  `{${token}}`,
                  replaceFunction(contact),
                );
              }
            } else if (nameSpace === "USER") {
              const replaceFunction =
                MessageTokenReplacerMap.USER[tokenVariable];
              const user = selectCurrentUser(store.getState());
              if (replaceFunction) {
                message = replaceAll(
                  message,
                  `<span class="variable-token-item">{${token}}</span>`,
                  replaceFunction(user),
                );

                message = replaceAll(
                  message,
                  `{${token}}`,
                  replaceFunction(user),
                );
              }
            }
          }
        }
        return message;
      },
      [conversationId, store],
    );

    const tokenReplacerNew = useCallback(
      async (message: string) => {
        try {
          setTokenReplacing(true);
          const tokens = getTokens(message);
          let shopifyCustomer: iShopifyCustomer | null = null;
          let shopifyLastOrder: iShopifyOrder | null = null;
          const contact = selectContactById(contactId)(store.getState());
          const customerEmail = contact?.data?.primaryEmail;
          const customerMobile = contact?.data?.primaryMobile;
          if (tokens.some((v) => AvailableShopifyTokens.includes(v))) {
            if (customerEmail || customerMobile) {
              shopifyCustomer = await resolveShopifyCustomer(
                customerEmail,
                customerMobile,
              );
              const lastOrderId = shopifyCustomer.last_order_id;
              if (lastOrderId) {
                shopifyLastOrder = await resolveOrder(String(lastOrderId));
              }
            }
          }

          for (const token of tokens) {
            if (
              [...AvailableTokens, ...AvailableShopifyTokens].includes(token)
            ) {
              const tokenParts = token.split("_");
              const nameSpace = tokenParts.shift();
              const tokenVariable = tokenParts.join("_");
              if (nameSpace === "CONTACT") {
                const replaceFunction =
                  MessageTokenReplacerMap.CONTACT[tokenVariable];
                const conversation = selectConversationById(conversationId)(
                  store.getState(),
                );
                const contact = selectContactById(conversation?.contactId)(
                  store.getState(),
                );
                if (replaceFunction) {
                  message = replaceAll(
                    message,
                    `<span class="variable-token-item">{${token}}</span>`,
                    replaceFunction(contact),
                  );
                  message = replaceAll(
                    message,
                    `{${token}}`,
                    replaceFunction(contact),
                  );
                }
              } else if (nameSpace === "USER") {
                const replaceFunction =
                  MessageTokenReplacerMap.USER[tokenVariable];
                const user = selectCurrentUser(store.getState());
                if (replaceFunction) {
                  message = replaceAll(
                    message,
                    `<span class="variable-token-item">{${token}}</span>`,
                    replaceFunction(user),
                  );

                  message = replaceAll(
                    message,
                    `{${token}}`,
                    replaceFunction(user),
                  );
                }
              } else if (nameSpace === "SHOPIFY") {
                const replacedValue = await ShopifyMessageTokenResolver(
                  token as any,
                  customerEmail,
                  customerMobile,
                  shopifyCustomer,
                  shopifyLastOrder,
                );
                message = replaceAll(
                  message,
                  `<span class="variable-token-item">{${token}}</span>`,
                  replacedValue,
                );
                message = replaceAll(message, `{${token}}`, replacedValue);
              }
            }
          }
          setTokenReplacing(false);
        } catch (e) {
          setTokenReplacing(false);
        }
        return message;
      },
      [contactId, conversationId, store],
    );
    // Helper Methods - END

    // Main Methods - START

    const [isTokenReplacing, setTokenReplacing] = useState(false);

    const {
      doAction: onSendMessage,
      isProcessing,
      response,
      dispatch,
      hasError,
    } = useSDKActionWithDeps(
      () => ({
        action: (SDK) => (data) => {
          return new Promise((resolve, reject) => {
            const conversationIdX = conversationId;
            const conversation = selectConversationById(conversationId)(
              store.getState(),
            );
            let inputValue = data.message as string;
            const mentions = extractMentions(inputValue);

            if (mentions.length > 0) {
              for (const mention of mentions) {
                inputValue = inputValue.replace(
                  mention.mention,
                  mention.label!,
                );
              }
            }

            const sendMessage = (
              textMessage: string,
              includeAttachments: boolean,
            ) => {
              // Add Temporary Message
              dispatch(
                addNewMessage(
                  GenerateTemporaryMessuage({
                    conversationId: conversationIdX,
                    message: textMessage,
                    attachments: includeAttachments
                      ? data.actions
                        ? [
                            ...(data.attachments || []),
                            {
                              type: MessageAttachmentTypes.ACTION_LIST,
                              payload: {
                                actions: data.actions,
                              },
                            },
                          ]
                        : data.attachments
                      : [],
                    connectionType: conversation?.connectionType as any,
                    isPrivate: data.isPrivate,
                    references: data.references,
                  }),
                ),
              );

              SDK.sendMessage({
                conversationId: conversationIdX,
                ...data,
                message: textMessage,
                attachments: includeAttachments ? data.attachments : [],
              })
                .then((message) => {
                  if (includeAttachments && mentions.length > 0) {
                    const mentionedUserNames = mentions.map((mention) =>
                      getUserName(selectUser(mention.id)(store.getState())),
                    );
                    let label =
                      message.message ||
                      `${getUserName(
                        selectUser(currentUserId)(store.getState()),
                      )} mentioned ${stringArrayToSentence(
                        mentions.map((mention) =>
                          getUserName(selectUser(mention.id)(store.getState())),
                        ),
                      )} in a message`;
                    for (const mentionedUser of mentionedUserNames) {
                      label = label.replace(mentionedUser, "");
                    }

                    SDK.tasks
                      .create({
                        type: "MENTION",
                        label,
                        body: "",
                        // body: message.message,
                        conversationId,
                        priority: 55,
                        contactId: conversation?.contactId,
                        messageId: message.id,
                        assignedTo: mentions.map((mention) => ({
                          userId: mention.id,
                        })),
                      })
                      .then((task) => {
                        BadgesController.processBadgeEvent("use-mentions");
                        SDK.editMessage(message.id!, {
                          attachments: [
                            ...(message.attachments || []),
                            {
                              type: MessageAttachmentTypes.TASK,
                              payload: {
                                taskId: task.id,
                              },
                            },
                          ],
                        });
                      });
                  }
                })
                .catch((e) => {
                  dispatch(removeTemporaryMessages(conversationIdX));
                  message.error(processServerError(e, "Something went wrong"));
                });
            };
            if (
              conversation?.connectionType === "FACEBOOK" ||
              conversation?.connectionType === "INSTAGRAM"
            ) {
              const parts = SplitMessage(inputValue, 2000);
              for (let i = 0; i < parts.length; i++) {
                sendMessage(parts[i], i === parts.length - 1);
              }
            } else {
              sendMessage(inputValue, true);
            }
            resolve({});
          }).then((d) => {
            BadgesController.processBadgeEvent("send-your-first-message");
            return d;
          });
        },
        throwError: true,
        // successMessage: "Message has been sent",
        failureMessage: "Something went wrong",
      }),
      [conversationId, currentUserId, store],
    );

    const sendMessage = useCallback(async () => {
      const conversation = selectConversationById(conversationId)(
        store.getState(),
      );
      const content = (() => {
        const html = replyInputRef.current.getHtmlContent() || "";
        if (isHTML(html)) {
          if (conversation?.connectionType === "EMAIL") {
            return html;
          }
          const text = convertHtmlToPlainText(html);
          const textWithLineBreak = convertHtmlToPlainTextWithLineBreaks(html);
          return text ? textWithLineBreak : "";
        }
        return html.trim();
      })();
      const { actions } = actionsForm.getFieldsValue();
      const additionData = await (async () => {
        if (
          conversation?.connectionType === "EMAIL" &&
          emailDateModifierRef?.current
        ) {
          try {
            await emailDateModifierRef.current.validateForm();
          } catch (e) {
            message.error("Please check your input");
            throw e;
          }
          const additionData = emailDateModifierRef.current.getMessageData();
          emailDateModifierRef.current.resetFields();
        }
        return {};
      })();
      const _attachments = [...attachments];
      const replyToMessageId = replyToMessage;

      try {
        replyInputRef.current.setHtmlContent("");
        setAttachments([]);
        actionsForm.setFieldsValue({ actions: [] });
        setReplyToMessage("");

        await onSendMessage({
          conversationId: conversationId,
          // from: req.fromId,
          message: content || "Attachments",
          attachments: attachments,
          ...additionData,
          actions: actions || [],
          references: replyToMessageId ? [replyToMessageId] : undefined,
        });

        conversationId &&
          dispatch(
            setEnteredMessages({
              conversationId: conversationId,
              data: {
                files: [],
                message: "",
                actions: [],
              },
            }),
          );
      } catch (e) {
        replyInputRef.current.setHtmlContent(content);
        setAttachments(_attachments);
        actionsForm.setFieldsValue({ actions });
      }
    }, [
      actionsForm,
      attachments,
      conversationId,
      dispatch,
      onSendMessage,
      replyToMessage,
      store,
    ]);

    const scheduleMessage = useCallback(async () => {
      const conversation = selectConversationById(conversationId)(
        store.getState(),
      );
      if (openScheduleMessageModal) {
        const content = (() => {
          const html = replyInputRef.current.getHtmlContent();
          if (conversation?.connectionType === "EMAIL") {
            return html;
          }
          return convertHtmlToPlainText(html);
        })();
        const { actions } = actionsForm.getFieldsValue();
        const additionData = await (async () => {
          if (
            conversation?.connectionType === "EMAIL" &&
            emailDateModifierRef?.current
          ) {
            try {
              await emailDateModifierRef.current.validateForm();
            } catch (e) {
              message.error("Please check your input");
              throw e;
            }
            const additionData = emailDateModifierRef.current.getMessageData();
            emailDateModifierRef.current.resetFields();
          }
          return {};
        })();
        replyInputRef.current.setHtmlContent("");
        await openScheduleMessageModal({
          // from: req.fromId,
          message: content || "Attachments",
          attachments: attachments,
          ...additionData,
          actions: actions || [],
        });
        conversationId &&
          dispatch(
            setEnteredMessages({
              conversationId: conversationId,
              data: {
                files: [],
                message: "",
                actions: [],
              },
            }),
          );
      }
    }, [
      conversationId,
      store,
      openScheduleMessageModal,
      actionsForm,
      attachments,
      dispatch,
    ]);

    const saveNotes = useCallback(async () => {
      const content = (() => {
        const html = notesInputRef.current.getHtmlContent();
        return convertHtmlToPlainText(html);
      })();

      try {
        notesInputRef.current.setHtmlContent("");
        await onSendMessage({
          conversationId: conversationId,
          // from: req.fromId,
          message: content || "Attachments",
          attachments: attachments,
          isPrivate: true,
        });
      } catch (e) {
        notesInputRef.current.setHtmlContent(content);
      }
    }, [attachments, conversationId, onSendMessage]);

    const sendTypingIndicator = useThrottle(
      (inputValue) => {
        // Emit for other channels as well. The co-users can see that someone is typing a message
        // if (conversation.connectionType === "WEB_CHAT") {
        // console.log("Send Typing Indicator");
        if (SocketConnector.socket) {
          SocketConnector.socket.emit("SEND_TO_CHANNEL", {
            channel: conversationId,
            data: {
              event: "TYPING_INDICATOR",
              data: {
                userType: "USER",
                userId: currentUserId,
                conversationId,
              },
            },
          });
        }
      },
      1500,
      { leading: true, trailing: false },
    );

    const onReplyInputContentChange = useCallback(
      (content) => {
        const isEmpty = (() => {
          if (content.length > 8) {
            return false;
          }
          const text = convertHtmlToPlainText(content);
          if (text.length > 0) {
            return false;
          }
          return true;
        })();

        const currentState = getReplyInputContentState();
        if (currentState !== isEmpty) {
          setReplyInputContentEmpty(isEmpty);
        }
        sendTypingIndicator();
      },
      [
        getReplyInputContentState,
        sendTypingIndicator,
        setReplyInputContentEmpty,
      ],
    );

    const { doAction: editTags } = useSDKActionWithDeps(
      () => ({
        action: (SDK) => (tags: string[]) =>
          SDK.updateConversationTags(conversationId!, _.uniq(tags)),
        failureMessage: "Something went wrong",
      }),
      [conversationId],
    );
    // Main Methods - END

    // Message Persistence and Restore - START

    useEffect(() => {
      if (replyInputRef.current) {
        const previouslyEnteredMessage = selectEnteredMessage(conversationId)(
          store.getState(),
        );
        replyInputRef.current.setHtmlContent(
          previouslyEnteredMessage?.message || "",
        );
        const previouslyEnteredFiles = _.cloneDeep(
          previouslyEnteredMessage?.files || [],
        );
        setAttachments(previouslyEnteredFiles);
        if (actionsForm) {
          actionsForm.setFieldsValue({
            actions: previouslyEnteredMessage?.actions,
          });
        }
      }
    }, [conversationId, actionsForm, store]);

    // Message Persistence and Restore - END

    // Reply To Message - START
    useEffect(() => {
      setReplyToMessage("");
    }, [conversationId]);

    useEffect(() => {
      const registerEvents = (data: {
        conversationId: string;
        contactId: string;
        messageId: string;
      }) => {
        if (conversationId === data.conversationId) {
          setReplyToMessage(data.messageId);
          replyInputRef.current.focusInput();
        }
      };
      GlobalEventEmitter.addListener("REPLY_MESSAGE", registerEvents);
      return () => {
        GlobalEventEmitter.removeListener("REPLY_MESSAGE", registerEvents);
      };
    }, [conversationId]);

    // Reply To Message - END

    const { triggerTempModal, changePanelState } = useModalPanels();

    const onPresetSelected = useCallback(
      (preset: iPreset) => {
        const conversation = selectConversationById(conversationId)(
          store.getState(),
        );
        if (conversationId) {
          if (preset.autoTagConversation) {
            const existingTags = conversation?.tags || [];
            editTags([...existingTags, preset.label]);
          }
          if (preset.id === "NOT_AVAILABLE") {
            changePanelState(MyManagePresets, true, {});
          }
          if (preset.attachments) {
            setAttachments(preset.attachments.map((item) => item.url));
          }
          if (preset.id !== "NOT_AVAILABLE") {
            BadgesController.processBadgeEvent("use-presets");
            UserTracker.track("MESSAGE_PRESETS - Used", {});
          }
        }

        const content =
          preset.type === "SIMPLE_TEXT"
            ? textToHtml(preset.value, { ignoreSpaces: true })
            : preset.value;

        tokenReplacerNew(content)
          .then((resolvedMessage) => {
            setTimeout(async () => {
              replyInputRef.current.insertHtmlToCursor(
                await tokenReplacerNew(resolvedMessage),
              );
              onReplyInputContentChange(resolvedMessage);
            }, 50);
          })
          .catch((e) => {
            // Ignore
          });
        // return __tokenReplacer(preset.value);
        return "";
      },
      [
        conversationId,
        store,
        tokenReplacerNew,
        editTags,
        changePanelState,
        onReplyInputContentChange,
      ],
    );

    const onReplyInputPressEnter = useCallback(
      (e) => {
        const conversation = selectConversationById(conversationId)(
          store.getState(),
        );
        if (conversation.connectionType !== "EMAIL") {
          sendMessage();
          return true;
        }
      },
      [conversationId, sendMessage, store],
    );

    const handleReplyInputPastedFiles = useCallback(
      (files) => {
        if (inputMode === "REPLY") {
          attachmentsHandlerRef.current.onDrop(files);
          return "handled";
        }
      },
      [inputMode],
    );

    const onReplyInputBlur = useCallback(() => {
      conversationId &&
        dispatch(
          setEnteredMessages({
            conversationId: conversationId,
            data: {
              files: attachments,
              message: replyInputRef.current.getHtmlContent(),
              actions: actionsForm ? actionsForm.getFieldValue("actions") : [],
            },
          }),
        );
    }, [actionsForm, attachments, conversationId, dispatch]);

    const onReplyInputActionSelected = useCallback(
      (macro: iCCMacro) => {
        actionsForm.setFieldsValue({ actions: macro.actions });
        if (macro.attachments) {
          setAttachments(macro.attachments);
        }
        setTimeout(async () => {
          if (inputMode === "REPLY" && macro.message) {
            const content = await tokenReplacerNew(macro.message);
            replyInputRef.current.insertHtmlToCursor(
              await tokenReplacerNew(macro.message),
            );
            onReplyInputContentChange(content);
          }
        }, 50);
        return true;
      },
      [actionsForm, inputMode, onReplyInputContentChange, tokenReplacerNew],
    );

    const onNotesInputEnter = useCallback(
      (e) => {
        saveNotes();
        return true;
      },
      [saveNotes],
    );

    const onEmojiPicked = useCallback(
      (emoji) => {
        if (inputMode === "REPLY") {
          replyInputRef.current.insertHtmlToCursor(emoji);
        } else {
          notesInputRef.current.insertHtmlToCursor(emoji);
        }
        // setNotesInputValue(notesInputValue + emoji);
      },
      [inputMode],
    );

    const onAttachmentIconClicked = useCallback(() => {
      document.getElementById("uploadField_MessageConfigInput")?.click?.();
    }, []);

    const onAttachmentsChange = useCallback(
      (attachments) => {
        setAttachments(attachments);
        dispatch(
          setEnteredMessages({
            conversationId: conversationId,
            data: {
              files: attachments,
              message: replyInputRef.current.getHtmlContent(),
              actions: actionsForm ? actionsForm.getFieldValue("actions") : [],
            },
          }),
        );
      },
      [actionsForm, conversationId, dispatch],
    );

    const onPresetSelect = useCallback(
      async (preset) => {
        const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
        const content =
          preset.type === "SIMPLE_TEXT"
            ? textToHtml(preset.value, { ignoreSpaces: true })
            : preset.value;

        replyInputRef.current.insertHtmlToCursor(
          await tokenReplacerNew(content),
        );
        BadgesController.processBadgeEvent("use-presets");
        UserTracker.track("MESSAGE_PRESETS - Used", {});
        if (preset.attachments) {
          setAttachments(preset.attachments.map((item) => item.url));
        }
        if (preset.autoTagConversation) {
          const existingTags =
            selectConversationById(conversationId)(store.getState())?.tags ||
            [];
          editTags([...existingTags, preset.label]);
        }
      },
      [conversationId, editTags, store, tokenReplacerNew],
    );

    const onSignatureSelect = useCallback(
      async (signature) => {
        replyInputRef.current.insertHtmlToCursor(
          `<br />` + (await tokenReplacerNew(signature.content)),
        );
      },
      [tokenReplacerNew],
    );

    const { doAction: processWithAI, isProcessing: isAIProcessing } =
      useSDKActionWithDeps(
        () => ({
          action: (SDK) => (req) => SDK.processOpenAiPrompts(req),
          failureMessage: "Something went wrong",
        }),
        [],
      );

    const industry = useSelector(selectIndustry);
    const isMobileView = useMediaQuery({ query: "(max-width: 500px)" });

    return (
      <Spin
        spinning={isTokenReplacing || isAIProcessing}
        indicator={<LoadingIndicatorWithSpin />}
      >
        <div className="chat-footer dark-gradient-bg-on-focus">
          {conversationConnectionType === "EMAIL" && (
            <EmailModifier
              conversationId={conversationId!}
              ref={emailDateModifierRef}
            />
          )}
          {replyToMessage && (
            <MessageReferenceBar
              messageId={replyToMessage}
              conversationId={conversationId}
              clear={() => setReplyToMessage("")}
            />
          )}

          <WithAttachments
            initialAttachments={attachments}
            onAttachmentsChange={onAttachmentsChange}
            id={"MessageConfigInput"}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            ref={attachmentsHandlerRef}
            entityType="CONVERSATION"
            entityId={conversationId!}
          >
            <div className="input-container">
              <Tabs
                activeKey={inputMode}
                onChange={setInputMode as any}
                renderTabBar={renderTabBar}
                tabBarExtraContent={
                  <Space>
                    {inputMode === "REPLY" && (
                      <>
                        <AddPresetButton
                          showRichText={conversationConnectionType === "EMAIL"}
                          onPresetSelect={onPresetSelect}
                        />
                        {conversationConnectionType === "EMAIL" && (
                          <AddSignatureButton
                            onSignatureSelect={onSignatureSelect}
                          />
                        )}

                        <AddArticleButton
                          showRichText={conversationConnectionType === "EMAIL"}
                          onPresetSelect={onPresetSelect}
                        />

                        {/* <Button
                  icon={<i className="ri-format-clear"></i>}
                  onClick={clearStyling}
                  type="text"
                ></Button> */}

                        {/* <Button
                  onClick={() => setType(type === "rich" ? "not-rich" : "rich")}
                  icon={<i className="ri-toggle-line"></i>}
                  type="text"
                ></Button> */}
                      </>
                    )}
                  </Space>
                }
                tabBarStyle={tabBarStyle}
                className={"w-full overflow-visible"}
                tabIndex={-1}
                // destroyInactiveTabPane={true}
              >
                <Tabs.TabPane
                  tab={
                    <div
                      className=" text-gray-700 dark:text-gray-300"
                      tabIndex={-1}
                    >
                      Reply
                    </div>
                  }
                  key="REPLY"
                >
                  {conversationConnectionType !== "EMAIL" && (
                    <ReactMentionsInput
                      placeholder="Press '/' for message presets and '\' for Macros"
                      initialValue=""
                      ref={replyInputRef}
                      mentions={users}
                      actions={macroCommands}
                      presets={messagePresets}
                      extendControls={extendControls}
                      editorId={editorId}
                      noControls={conversationConnectionType !== "EMAIL"}
                      onPressEnter={onReplyInputPressEnter}
                      handlePastedFiles={handleReplyInputPastedFiles}
                      onChange={onReplyInputContentChange}
                      onFocus={onReplyInputFocus}
                      onBlur={onReplyInputBlur}
                      onActionSelected={onReplyInputActionSelected}
                      onPresetSelected={onPresetSelected}
                    />
                  )}

                  {conversationConnectionType === "EMAIL" && (
                    <BraftMessageInput
                      placeholder="Press '/' for message presets and '\' for Macros"
                      initialValue=""
                      ref={replyInputRef}
                      mentions={users}
                      actions={macroCommands}
                      presets={messagePresets}
                      extendControls={extendControls}
                      editorId={editorId}
                      noControls={conversationConnectionType !== "EMAIL"}
                      onPressEnter={onReplyInputPressEnter}
                      handlePastedFiles={handleReplyInputPastedFiles}
                      onChange={onReplyInputContentChange}
                      onFocus={onReplyInputFocus}
                      onBlur={onReplyInputBlur}
                      onActionSelected={onReplyInputActionSelected}
                      onPresetSelected={onPresetSelected}
                    />
                  )}
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className=" text-gray-700  dark:text-gray-300"
                      tabIndex={-1}
                    >
                      Notes
                    </div>
                  }
                  key="NOTES"
                >
                  {/* <Input.TextArea
                className="border-0"
                placeholder="Type in your notes and press enter"
              /> */}
                  <BraftMessageInput
                    placeholder="Type in your notes and press enter"
                    initialValue=""
                    ref={notesInputRef}
                    mentions={users}
                    actions={emptyArray}
                    presets={emptyArray}
                    noControls={true}
                    extendControls={extendControls}
                    onPressEnter={onNotesInputEnter}
                    // editorId={"NOTES_INPUT"}
                    onChange={onNotesInputContentChange}
                  />
                </Tabs.TabPane>
              </Tabs>
              {/* Actions Bar */}
            </div>
            <div className="chat-footer-bottom-bar mb-2">
              <div className="helper-bar flex flex-row justify-between items-center w-full">
                <div className="left-icons px-4">
                  <Space>
                    {!isMobileView && (
                      <EmojiPicker onEmojiPicked={onEmojiPicked} type="text" />
                    )}

                    <Button
                      type="text"
                      icon={<i className="ri-attachment-2"></i>}
                      onClick={onAttachmentIconClicked}
                    />
                    <Tooltip title="AI Writing Assistant">
                      <Button
                        type="text"
                        icon={
                          <img
                            src="/assets/integrations/openai.png"
                            alt="Chat GPT"
                            style={{ width: "1rem" }}
                            className="dark:invert"
                          />
                        }
                        onClick={() => {
                          const text = convertHtmlToPlainText(
                            replyInputRef.current.getHtmlContent(),
                          );
                          const textWithLineBreaks =
                            convertHtmlToPlainTextWithLineBreaks(
                              replyInputRef.current.getHtmlContent(),
                            );

                          triggerTempModal(OpenAiAssistant, {
                            input: text ? textWithLineBreaks : "",
                            onReplaceText: (content) => {
                              if (inputMode === "REPLY") {
                                replyInputRef.current.setHtmlContent(content);
                              } else {
                                notesInputRef.current.setHtmlContent(content);
                              }
                            },
                          });
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Write For Me (Beta)">
                      <Button
                        type="text"
                        icon={
                          <i className="ri-edit-line"></i>
                          // <MDIIcon icon={mdiCommentEditOutline} size="1.3rem" />
                        }
                        onClick={async () => {
                          const messagesState = selectMessageByConversationId(
                            conversationId,
                          )(store.getState());
                          const allMessages = messagesState.data
                            .map((message) => ({
                              from: (() => {
                                switch (message.from.senderType) {
                                  case iMessageSenderType.CLIENT: {
                                    return "Customer";
                                  }
                                  case iMessageSenderType.USER: {
                                    return "Agent";
                                  }
                                }
                                return message.from.senderType;
                              })(),
                              message: message.message,
                              hasAttachments: Boolean(
                                message.attachments.length,
                              ),
                            }))
                            .filter(
                              (e) => e.from !== iMessageSenderType.SYSTEM,
                            );
                          const messageText = allMessages
                            .map((item) => `${item.from}: "${item.message}"\n`)
                            .join("");

                          const messageJson = JSON.stringify(
                            allMessages,
                            null,
                            2,
                          );

                          // Todo:
                          // Get Last 2 Messages, First two Messages. Query Documents and get 2 matched article and give context.

                          const nextSentencePrompt = `Write the next reply on behalf of the Agent in the conversation.  If you are not sure of what to say next explicitly say "NO_MESSAGE". Explicitly give the exact next message - No Parenthesis or introduction.`;

                          const response = await processWithAI({
                            type: "CHAT",
                            instruction: nextSentencePrompt,
                            input: `The conversation is as follows: \`\`\`\n ${messageText} \n\`\`\`\``,
                          });
                          const nextMessage: string =
                            response?.choices?.[0]?.message?.content || "";

                          const trimmedVersion = (() => {
                            const text = nextMessage
                              .replace("Agent: ", "")
                              .trim();

                            if (
                              text.charAt(0) === `"` &&
                              text.charAt(text.length - 1) === `"`
                            ) {
                              return text.substring(1, text.length - 1);
                            }
                            return text;
                          })();

                          if (inputMode === "REPLY") {
                            if (replyInputRef.current) {
                              replyInputRef.current.setHtmlContent(
                                trimmedVersion,
                              );
                            }
                          } else {
                            if (notesInputRef.current) {
                              notesInputRef.current.setHtmlContent(
                                trimmedVersion,
                              );
                            }
                          }
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Improve Reply">
                      <Button
                        type="text"
                        icon={<MDIIcon icon={mdiAutoFix} size="1.3rem" />}
                        onClick={async () => {
                          const text = convertHtmlToPlainText(
                            replyInputRef.current.getHtmlContent(),
                          );
                          const textWithLineBreaks =
                            convertHtmlToPlainTextWithLineBreaks(
                              replyInputRef.current.getHtmlContent(),
                            );

                          if (!textWithLineBreaks) {
                            message.warning(`There's Nothing to improve`);
                            return;
                          }

                          const response = await processWithAI({
                            type: "CHAT",
                            instruction: "IMPROVE_SENTENCE",
                            input: textWithLineBreaks,
                          });

                          const nextMessage: string =
                            response?.choices?.[0]?.message?.content || "";

                          const trimmedVersion = (() => {
                            const text = nextMessage.trim();
                            if (
                              text.charAt(0) === `"` &&
                              text.charAt(text.length - 1) === `"`
                            ) {
                              return text.substring(1, text.length - 1);
                            }
                            return text;
                          })();
                          if (inputMode === "REPLY") {
                            if (replyInputRef.current) {
                              replyInputRef.current.setHtmlContent(
                                trimmedVersion,
                              );
                            }
                          } else {
                            if (notesInputRef.current) {
                              notesInputRef.current.setHtmlContent(
                                trimmedVersion,
                              );
                            }
                          }
                        }}
                      />
                    </Tooltip>
                    {!isMobileView && industry === "Travel and Tourism" && (
                      <>
                        <Tooltip title="PNR Converter">
                          <Button
                            type="text"
                            icon={<i className="ri-flight-takeoff-line"></i>}
                            onClick={() => {
                              triggerTempModal(PNRConverter, {
                                onAddAsAttachment: (base64) => {
                                  const url = base64;
                                  console.log("url", url);
                                  fetch(url)
                                    .then((res) => res.blob())
                                    .then((blob) => {
                                      const file = new File(
                                        [blob],
                                        `flight-itenary-${Date.now()}.png`,
                                        {
                                          type: "image/png",
                                        },
                                      );

                                      console.log("file", file);
                                      handleReplyInputPastedFiles([file]);
                                    });
                                },
                              });
                            }}
                          />
                        </Tooltip>
                        {/* (isAdvancedMode || betaFeatures?.QUOTE_CREATOR) && (
                      
                      ) */}
                        <Tooltip title="Quote Generator">
                          <Button
                            type="text"
                            icon={<i className="ri-bill-line"></i>}
                            onClick={() => {
                              triggerTempModal(HolidayQuoteCreator, {
                                conversationId,
                                contactId,
                                onAddAsAttachment: (base64) => {
                                  const url = base64;
                                  console.log("url", url);
                                  fetch(url)
                                    .then((res) => res.blob())
                                    .then((blob) => {
                                      const file = new File(
                                        [blob],
                                        `flight-itenary-${Date.now()}.png`,
                                        {
                                          type: "image/png",
                                        },
                                      );

                                      console.log("file", file);
                                      handleReplyInputPastedFiles([file]);
                                    });
                                },
                              });
                            }}
                          />
                        </Tooltip>
                      </>
                    )}

                    {!isMobileView && isAdvancedMode && (
                      <Tooltip title="Shopify Products">
                        <Button
                          type="text"
                          icon={<i className="ri-store-2-line"></i>}
                          onClick={() => {
                            triggerTempModal(ShopifyProductBrowser, {
                              onSelected: (product) => {
                                console.log("product", product);
                                const content = `${product.title} (${product.url})`;
                                if (inputMode === "REPLY") {
                                  replyInputRef.current.insertHtmlToCursor(
                                    content,
                                  );
                                } else {
                                  notesInputRef.current.insertHtmlToCursor(
                                    content,
                                  );
                                }
                              },
                            });
                          }}
                        />
                      </Tooltip>
                    )}
                  </Space>
                </div>

                <div className="right-side-content flex flex-row items-center px-2">
                  {inputMode === "REPLY" && (
                    <>
                      <Button
                        icon={<i className="ri-calendar-line"></i>}
                        type="text"
                        onClick={scheduleMessage}
                        disabled={
                          (attachments.length === 0 &&
                            isReplyInputContentEmpty) ||
                          hasError
                        }
                        loading={isProcessing || isUploading}
                        // className="mr-2"
                      ></Button>

                      <Button
                        icon={<i className="ri-send-plane-2-line"></i>}
                        type={
                          (attachments.length === 0 &&
                            isReplyInputContentEmpty) ||
                          hasError
                            ? "text"
                            : "primary"
                        }
                        onClick={sendMessage}
                        disabled={
                          (attachments.length === 0 &&
                            isReplyInputContentEmpty) ||
                          hasError
                        }
                        loading={isProcessing || isUploading}
                      >
                        Send{" "}
                        {conversationConnectionType !== "EMAIL" && (
                          <span className="text-sm mt-2 ml-2">↵</span>
                        )}
                      </Button>
                    </>
                  )}
                  {inputMode === "NOTES" && (
                    <Button
                      icon={<i className="ri-save-2-line"></i>}
                      type={
                        (attachments.length === 0 && !notesInputTextContent) ||
                        hasError
                          ? "text"
                          : "primary"
                      }
                      disabled={
                        (attachments.length === 0 && !notesInputTextContent) ||
                        hasError
                      }
                      loading={isProcessing || isUploading}
                      onClick={saveNotes}
                    >
                      Save Note <span className="text-sm mt-2 ml-2">↵</span>
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <ActionsBar form={actionsForm} />
          </WithAttachments>

          <MessageScheduler
            visible={scheduleMessageModalState.isModalVisible}
            onChangeVisibility={(visibility) =>
              setScheduleMessageModalState({ isModalVisible: visibility })
            }
            data={scheduleMessageModalState.data}
            // onScheduled={}
          />
        </div>
      </Spin>
    );
  },
);

export const MessageReferenceBar = ({
  conversationId,
  messageId,
  clear,
}: {
  conversationId: string;
  messageId: string;
  clear: () => any;
}) => {
  const { contactId } = useDoubleSelectorWithMemoize(
    () => selectConversationById(conversationId),
    [conversationId],
    (conversation) => ({
      contactId: conversation?.contactId,
    }),
  );

  const message = useDoubleSelectorWithMemoize(
    () => selectMessageByConversationId(conversationId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conversationId, messageId],
    (messages) => find(messages?.data || [], { id: messageId }),
    false,
  );

  if (!message) {
    return <></>;
  }

  return (
    <div className="border mb-2 border-gray-200 dark:border-gray-800 flex flex-row items-center rounded-lg">
      <i className="ri-reply-fill p-2"></i>
      <div className="label flex-1">
        Replying to: {message.message}{" "}
        {message.attachments.length > 0
          ? `(${message.attachments.length} attachments)`
          : ""}
      </div>
      <Button
        type="text"
        onClick={clear}
        icon={<i className="ri-close-circle-line"></i>}
        shape="circle"
      />
    </div>
  );
};
