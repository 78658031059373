import {
  iConnection,
  iEmailConnectionData,
  iFbConnectionData,
  iWAPrivateConnectionData,
} from "@sdk/conversations/conversations.models";
import { default as classNames, default as classnames } from "classnames";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { ConnectionIcons } from "../helpers/connection-icons";

export const ConnectionPill = ({
  connectionId,
  withoutBg,
}: {
  connectionId: string;
  withoutBg?: boolean;
}) => {
  const connection = useSelector(selectConnectionById(connectionId));

  const getConnectionData = useMemo(() => {
    return (connection: iConnection) => {
      switch (connection.type) {
        case "EMAIL": {
          const emailAddress = (connection.data as iEmailConnectionData)
            .customDomain
            ? (connection.data as iEmailConnectionData).customDomain?.email
            : (connection.data as iEmailConnectionData).customSmtp
            ? ((connection.data as iEmailConnectionData).customSmtp.data as any)
                .email ||
              ((connection.data as iEmailConnectionData).customSmtp.data as any)
                .username
            : (connection.data as iEmailConnectionData).email;

          return emailAddress;
        }
        case "FACEBOOK": {
          return (connection.data as iFbConnectionData).page.name;
        }
        case "WEB_CHAT": {
          return "Chat Widget";
        }
        case "WHATS_APP_PRIVATE": {
          if (
            (connection.data as iWAPrivateConnectionData).waConnectionX
              ?.state === "CONNECTED"
          ) {
            return `Private WA Connection`;
          }
          return `Private WA Connection - NOT ACTIVE`;
        }
        default: {
          return "Todo";
        }
      }
    };
  }, []);
  if (!connection) {
    return <div>Unknown Connection</div>;
  }

  return (
    <div
      className="inline-flex flex-row justify-start items-center overflow-hidden"
      style={{ maxWidth: "15rem" }}
    >
      <div
        className={classnames(
          "flex-row flex justify-start items-center  rounded-lg px-4",
          {
            "bg-gray-100 dark:bg-gray-800": !withoutBg,
          }
        )}
      >
        <div className="icon-container mr-2">
          <i className={classNames(ConnectionIcons[connection.type])} />
        </div>
        <div className="label">{connection.label}</div>
        <div className="text-gray-600 dark:text-gray-400 text-sm pl-2 mode_transition overflow-ellipsis">
          {getConnectionData(connection)}
        </div>
      </div>
    </div>
  );
};
