import {
  GetWindowFocus,
  GetWindowVisibility,
} from "@libs/window-visibility-detector/window-visibility-detector.service";
import { SDK } from "@sdk";
import { processServerError } from "@sdk/sdk.hooks";
import { message } from "antd";
import { push } from "connected-react-router";
import _ from "lodash";
import { store } from "store/store";
import { convertMillisecondsToReadableString } from "utils/convert-milliseconds-to-readable-string";
import { AvailableAudios } from "./audio-notifications-controller";
import { Notifications } from "./notications";
import {
  iNotification,
  iNotificationChannel,
} from "./on-screen-notifications-controller";

const uuidv1 = require("uuid/v1");

export enum NotificationType {
  CONVERSATION_ACTIVATED = "CONVERSATION_ACTIVATED",
  CONVERSATION_JOIN_REQUEST = "CONVERSATION_JOIN_REQUEST",
  JOIN_REQUEST_ACCEPTED = "JOIN_REQUEST_ACCEPTED",
  JOIN_REQUEST_REJECTED = "JOIN_REQUEST_REJECTED",
  TRANSFER_ACCEPTED = "TRANSFER_ACCEPTED",
  INVITED_TO_CONVERSATION = "INVITED_TO_CONVERSATION",
  AWAY_CHECKER = "AWAY_CHECKER",
  MADE_AWAY = "MADE_AWAY",
  NEW_MESSAGE = "NEW_MESSAGE",
  CONVERSATION_ALERT = "CONVERSATION_ALERT",
  QUEUED_CONVERSATION = "QUEUED_VISITOR",
}

const withPermission = (notification: iNotification): iNotification => {
  // Todo:
  return notification;
};

const navigateToConversationPage = (conversationId: string) => {
  store.dispatch(push(`/conversations/auto/${conversationId}`));
};

const navigateToUnassigned = () => {
  store.dispatch(push(`/conversations/DEFAULT_UNASSIGNED/`));
};

const navigateToLiveViewPage = () => {
  store.dispatch(push(`/live-view`));
};

const dismissNotificationInboxItem = (notificationId: string) => {
  SDK.dismissNotification(notificationId);
};

export class NotificationHelper {
  static fire = {
    // Conversations Notifications - START
    simpleConversationNotification: ({
      conversationId,
      title,
      text,
      notificationId,
    }: {
      conversationId: string;
      title: string;
      text: string;
      notificationId?: string;
    }) => {
      // Todo: Based on preferences modify the notification data
      const notification: iNotification = {
        id: notificationId || uuidv1(),
        title: title,
        body: text,
        icon: "new-message",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: ["ONSCREEN", "BROWSER"],
        data: {
          type: NotificationType.CONVERSATION_ALERT,
          conversationId: conversationId,
        },
        duration: 0,
        type: "PROMPT",
        tag: `${conversationId}`,
        onClick: () => {
          Notifications.dismiss(notification);
          navigateToConversationPage(conversationId);
          if (notificationId) {
            dismissNotificationInboxItem(notificationId);
          }
        },
        buttons: [
          {
            text: "View",
            isPrimary: true,
            action: () => {
              Notifications.dismiss(notification);
              navigateToConversationPage(conversationId);
              if (notificationId) {
                dismissNotificationInboxItem(notificationId);
              }
            },
          },
          {
            text: "Dismiss",
            action: () => {
              Notifications.dismiss(notification);
              if (notificationId) {
                dismissNotificationInboxItem(notificationId);
              }
            },
          },
        ],
        refs: {},
      };
      Notifications.fire(notification);
    },
    onNewMessage: ({
      connectionName,
      senderName,
      message,
    }: {
      connectionName?: string;
      senderName: string;
      message: { message: string; conversationId: string };
    }) => {
      const notificationId = uuidv1();
      // Todo: Based on preferences modify the notification data

      const isWindowVisible = GetWindowVisibility(); //
      const windowHasFocus = GetWindowFocus();

      const isUserOnConversationView = window.location.href.includes(
        message.conversationId,
      );

      const notificationChannels: iNotificationChannel[] =
        windowHasFocus && isUserOnConversationView
          ? []
          : ["ONSCREEN", "BROWSER"];

      const notification: iNotification = {
        id: notificationId,
        title: `${connectionName ? `${connectionName}:` : ""}New Message`,
        body: `${senderName} says "${message.message}"`,
        icon: "new-message",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: notificationChannels,
        data: {
          type: NotificationType.NEW_MESSAGE,
          conversationId: message.conversationId,
        },
        type: "PROMPT",
        tag: `${message.conversationId}`,
        onClick: () => {
          Notifications.dismiss(notification);
          navigateToConversationPage(message.conversationId);
        },
        buttons: [
          {
            text: "View",
            isPrimary: true,
            action: () => {
              Notifications.dismiss(notification);
              navigateToConversationPage(message.conversationId);
            },
          },
          {
            text: "Dismiss",
            action: () => {
              Notifications.dismiss(notification);
            },
          },
        ],
        refs: {},
      };
      Notifications.fire(notification);
    },
    onQueuedConversation: ({
      connectionName,
      clientName,
      conversationId,
      showJoinChatOption,
    }: {
      connectionName?: string;
      clientName: string;
      conversationId: string;
      showJoinChatOption?: boolean;
    }) => {
      const notificationId = uuidv1();
      // Todo: Based on preferences modify the notification data
      const notification: iNotification = {
        id: notificationId,
        title: `${
          connectionName ? `${connectionName}:` : ""
        }Customer is in Chat Queue`,
        body: `${clientName} is awaiting reply`,
        icon: "new-message",
        audio: AvailableAudios.QUEUED_VISITOR,
        channels: ["ONSCREEN", "BROWSER"],
        data: {
          type: NotificationType.QUEUED_CONVERSATION,
          conversationId: conversationId,
        },
        type: "PROMPT",
        tag: `QUEUE_${conversationId}`,
        onClick: () => {
          Notifications.dismiss(notification);
          navigateToUnassigned();
          // navigateToLiveViewPage();
        },
        buttons: [
          ...(showJoinChatOption
            ? [
                {
                  text: "Join",
                  action: () => {
                    Notifications.dismiss(notification);
                    SDK.joinConversation(conversationId)
                      .then((d) => {
                        navigateToConversationPage(conversationId);
                        return d;
                      })
                      .then((d) => {
                        return SDK.dismissNotification(notificationId);
                      });
                  },
                },
              ]
            : []),
          {
            text: "View",
            action: () => {
              Notifications.dismiss(notification);
              navigateToConversationPage(conversationId);
            },
          },
          {
            text: "Dismiss",
            action: () => {
              Notifications.dismiss(notification);
            },
          },
        ],
        refs: {},
      };
      Notifications.fire(notification);
    },
    onNewConversationActivated: ({
      connectionName,
      conversationId,
    }: {
      connectionName?: string;
      conversationId: string;
    }) => {
      const notificationId = uuidv1();
      // Todo: Based on preferences modify the notification data
      const notification: iNotification = {
        id: notificationId,
        title: `${
          connectionName ? `${connectionName}:` : ""
        }Conversation Activated`,
        body: `Click here to view`,
        icon: "conversation-activated",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: ["ONSCREEN"],
        data: {
          type: NotificationType.CONVERSATION_ACTIVATED,
          conversationId: conversationId,
        },
        type: "PROMPT",
        tag: `${conversationId}`,
        onClick: () => {
          Notifications.dismiss(notification);
          navigateToConversationPage(conversationId);
        },
        buttons: [
          {
            text: "View",
            action: () => {
              Notifications.dismiss(notification);
              navigateToConversationPage(conversationId);
            },
          },
          {
            text: "Dismiss",
            action: () => {
              Notifications.dismiss(notification);
            },
          },
        ],
        refs: {},
      };
      Notifications.fire(notification);
    },
    onConversationJoinRequest: ({
      conversationId,
      userId,
      username,
    }: {
      conversationId: string;
      userId: string;
      username: string;
    }) => {
      const notificationId = uuidv1();
      // Todo: Based on preferences modify the notification data
      const notification: iNotification = {
        id: notificationId,
        title: "Conversation Join Request",
        body: `${username} would like to join join the conversation`,
        icon: "join-request",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: ["ONSCREEN", "BROWSER"],
        data: {
          type: NotificationType.CONVERSATION_JOIN_REQUEST,
          conversationId: conversationId,
          userId: userId,
        },
        type: "PROMPT",
        tag: `${conversationId}-${userId}`,
        buttons: [
          {
            text: "Accept",
            action: () => {
              console.log("Accept");
              Notifications.dismiss(notification);
              navigateToConversationPage(conversationId);
              // Todo:
              //   GetController(ConversationsController)
              //     .acceptJoinRequest({
              //       conversationId,
              //       userId,
              //     })
              //     .then((d) => {
              //       setTimeout(() => {
              //         this.router.navigate(["/chat"], {
              //           queryParams: { conversationId: conversationId },
              //         });
              //       }, 400);
              //     })
              //     .catch((e) => {
              //       console.log("Error while accepting", e);
              //     });
            },
          },
          {
            text: "Reject",
            action: async () => {
              Notifications.dismiss(notification);
              // Todo:
              //   await GetController(ConversationsController).rejectJoinRequest({
              //     conversationId,
              //     userId,
              //   });
            },
          },
          {
            text: "Dismiss",
            action: () => {
              Notifications.dismiss(notification);
            },
          },
        ],
        onClick: () => {
          Notifications.dismiss(notification);
          notification.refs = {};
          notification.channels = ["ONSCREEN"];
          Notifications.fire(notification);
        },
        refs: {},
      };
      Notifications.fire(notification);
    },
    onConversationJoinRequestAccepted: ({
      username,
      conversationId,
      userId,
    }: {
      username: string;
      conversationId: string;
      userId: string;
    }) => {
      const notificationId = uuidv1();
      // Todo: Based on preferences modify the notification data
      const notification: iNotification = {
        id: notificationId,
        title: "Conversation Join Request Accepted",
        body: `${username} has accepted your request`,
        icon: "check-mark",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: ["ONSCREEN", "BROWSER"],
        data: {
          type: NotificationType.JOIN_REQUEST_ACCEPTED,
          conversationId: conversationId,
          userId: userId,
        },
        type: "PROMPT",
        tag: `${conversationId}-${userId}`,
        onClick: () => {
          Notifications.dismiss(notification);
          // Todo:
          //   this.router.navigate(["/chat"], {
          //     queryParams: { conversationId: conversationId },
          //   });
        },
        buttons: [
          {
            text: "View",
            action: () => {
              Notifications.dismiss(notification);
              // Todo:
              //   this.router.navigate(["/chat"], {
              //     queryParams: { conversationId: conversationId },
              //   });
            },
          },
          {
            text: "Dismiss",
            action: () => {
              Notifications.dismiss(notification);
            },
          },
        ],
        refs: {},
      };
      Notifications.fire(notification);
    },
    onConversationJoinRequestRejected: ({
      username,
      conversationId,
      userId,
    }: {
      username: string;
      conversationId: string;
      userId: string;
    }) => {
      const notificationId = uuidv1();
      // Todo: Based on preferences modify the notification data
      const notification: iNotification = {
        id: notificationId,
        title: "Conversation Join Request Rejected",
        body: `${username} has rejected your request`,
        icon: "cancel",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: ["ONSCREEN", "BROWSER"],
        data: {
          type: NotificationType.JOIN_REQUEST_REJECTED,
          conversationId: conversationId,
          userId: userId,
        },
        type: "SIMPLE",
        tag: `${conversationId}-${userId}`,
        onClick: () => {
          Notifications.dismiss(notification);
        },
        refs: {},
      };
      Notifications.fire(notification);
    },
    onTransferAccepted: ({
      username,
      conversationId,
      userId,
    }: {
      username: string;
      conversationId: string;
      userId: string;
    }) => {
      const notificationId = uuidv1();
      // Todo: Based on preferences modify the notification data
      const notification: iNotification = {
        id: notificationId,
        title: "Transfer Request Accepted",
        body: `${username} has accepted your request`,
        icon: "cancel",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: ["ONSCREEN", "BROWSER"],
        data: {
          type: NotificationType.TRANSFER_ACCEPTED,
          conversationId: conversationId,
          userId: userId,
        },
        type: "SIMPLE",
        tag: `${conversationId}-${userId}`,
        onClick: () => {
          Notifications.dismiss(notification);
        },
        refs: {},
      };
      Notifications.fire(notification);
    },
    onTransferRejected: ({
      username,
      conversationId,
      userId,
    }: {
      username: string;
      conversationId: string;
      userId: string;
    }) => {
      const notificationId = uuidv1();
      const notification: iNotification = {
        id: notificationId,
        title: "Transfer Request Rejected",
        body: `${username} has rejected your request`,
        icon: "cancel",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: ["ONSCREEN", "BROWSER"],
        data: {
          type: NotificationType.TRANSFER_ACCEPTED,
          conversationId: conversationId,
          userId: userId,
        },
        type: "SIMPLE",
        tag: `${conversationId}-${userId}`,
        onClick: () => {
          Notifications.dismiss(notification);
          navigateToConversationPage(conversationId);
        },
        refs: {},
      };
      Notifications.fire(notification);
    },
    onTransferRequest: ({
      username,
      conversationId,
      userId,
    }: {
      username: string;
      conversationId: string;
      userId: string;
    }) => {
      const notificationId = uuidv1();
      const notification: iNotification = {
        id: notificationId,
        title: "Conversation Invitation",
        body: `${username} has invited you to a conversation`,
        icon: "cancel",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: ["ONSCREEN", "BROWSER"],
        data: {
          type: NotificationType.INVITED_TO_CONVERSATION,
          conversationId: conversationId,
          userId: userId,
        },
        type: "PROMPT",
        tag: `${conversationId}-${userId}`,
        duration: 0,
        buttons: [
          {
            text: "Accept",
            isPrimary: true,
            action: () => {
              console.log("Accept");
              Notifications.dismiss(notification);
              SDK.acceptConversationTransferRequest(conversationId).catch(
                (e) => {
                  message.error(processServerError(e, "Something went wrong"));
                },
              );
            },
          },
          {
            text: "Reject",
            action: () => {
              Notifications.dismiss(notification);
              SDK.declineConversationTransferRequest(conversationId).catch(
                (e) => {
                  message.error(processServerError(e, "Something went wrong"));
                },
              );
            },
          },
          {
            text: "Dismiss",
            action: () => {
              Notifications.dismiss(notification);
            },
          },
        ],
        onClick: () => {
          Notifications.dismiss(notification);
          notification.refs = {};
          notification.channels = ["ONSCREEN"];
          Notifications.fire(notification);
        },
        refs: {},
      };
      Notifications.fire(notification);
    },
    onUserInvitedToConversation: ({
      connectionName,
      conversationId,
      notificationId,
      expireAt,
    }: {
      connectionName?: string;
      conversationId: string;
      notificationId: string;
      expireAt?: number;
    }) => {
      const notification: iNotification = {
        id: notificationId,
        title: `${connectionName ? `${connectionName}:` : ""}Chat Invitation`,
        body: `You have been invited to a conversation`,
        icon: "cancel",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: ["ONSCREEN", "BROWSER"],
        data: {
          type: NotificationType.INVITED_TO_CONVERSATION,
          conversationId: conversationId,
        },
        type: "PROMPT",
        tag: `${conversationId}-INVITE`,
        isUnDismissible: true,
        duration: 0,
        buttons: [
          {
            text: "Accept",
            isPrimary: true,
            action: () => {
              console.log("Accept");
              Notifications.dismiss(notification);
              SDK.joinConversation(conversationId)
                .then((d) => {
                  navigateToConversationPage(conversationId);
                  return d;
                })
                .then((d) => {
                  return SDK.dismissNotification(notificationId);
                });
            },
          },
          {
            text: "Reject",
            action: () => {
              Notifications.dismiss(notification);
              SDK.dismissNotification(notificationId);
            },
          },
          {
            text: "Dismiss",
            action: () => {
              Notifications.dismiss(notification);
              SDK.dismissNotification(notificationId);
            },
          },
        ],
        onClick: () => {
          Notifications.dismiss(notification);
          notification.refs = {};
          notification.channels = ["ONSCREEN"];
          Notifications.fire(notification);
        },
        refs: {},
      };

      Notifications.fire(notification);
      if (expireAt) {
        setTimeout(() => {
          console.log("Expire Invite");
          Notifications.dismiss(notification);
        }, expireAt - Date.now());
      }
    },
    // Conversations Notifications - END
    onAwayDetected: (timeout: number) => {
      NotificationHelper.dismiss.onAwayDetected();
      const notificationId = uuidv1();
      const notification: iNotification = {
        id: notificationId,
        title: "Knock Knock 🔔",
        body: `You've been away for ${convertMillisecondsToReadableString(
          timeout,
        )}`,
        icon: "cancel",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: ["ONSCREEN", "BROWSER"],
        data: {
          type: NotificationType.AWAY_CHECKER,
        },
        type: "PROMPT",
        tag: `AWAY_CHECKER`,
        buttons: [
          {
            text: "I'm Here",
            action: async () => {
              Notifications.dismiss(notification);
            },
          },
          {
            text: "Dismiss",
            action: () => {
              Notifications.dismiss(notification);
            },
          },
        ],
        onClick: () => {
          Notifications.dismiss(notification);
        },
        refs: {},
      };
      Notifications.fire(notification);
    },
    onMadeAway: (timeout: number) => {
      NotificationHelper.dismiss.onAwayDetected();
      NotificationHelper.dismiss.onMadeAway();
      const notificationId = uuidv1();
      const notification: iNotification = {
        id: notificationId,
        title: "💤 Your Status Have Been Changed",
        body: `You've been away for ${convertMillisecondsToReadableString(
          timeout,
        )}`,
        icon: "cancel",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: ["ONSCREEN", "BROWSER"],
        data: {
          type: NotificationType.MADE_AWAY,
        },
        type: "PROMPT",
        tag: `AWAY_CHECKER`,
        duration: 0,
        buttons: [
          {
            text: "I'm Online Again",
            action: async () => {
              Notifications.dismiss(notification);
              // Make Online
              SDK.editCurrentUser({
                metaData: {
                  availabilityStatus: "AVAILABLE",
                },
              });
            },
          },
          {
            text: "Dismiss",
            action: () => {
              Notifications.dismiss(notification);
            },
          },
        ],
        onClick: () => {
          Notifications.dismiss(notification);
        },
        refs: {},
      };
      Notifications.fire(notification);
    },
    // Help Center Notifications - START
    helpCenterNotifications: ({
      title,
      body,
    }: {
      title: string;
      body: string;
    }) => {
      const notificationId = uuidv1();
      const notification: iNotification = {
        id: notificationId,
        title: title,
        body: body,
        icon: "cancel",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: ["ONSCREEN"],
        data: {
          type: NotificationType.MADE_AWAY,
        },
        type: "PROMPT",
        tag: `HELP_CENTER`,
        duration: 0,
        buttons: [
          {
            text: "Learn More",
            isPrimary: true,
            action: async () => {
              Notifications.dismiss(notification);
              // Todo
            },
          },
          {
            text: "Dismiss",
            action: () => {
              Notifications.dismiss(notification);
            },
          },
        ],
        onClick: () => {
          Notifications.dismiss(notification);
        },
        refs: {},
      };
      Notifications.fire(notification);
    },
    // Help Center Notifications - END
    testNotification: ({ title, body }: { title: string; body: string }) => {
      const notificationId = uuidv1();
      const notification: iNotification = {
        id: notificationId,
        title: title,
        body: body,
        icon: "cancel",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: ["ONSCREEN", "BROWSER"],
        data: {
          type: NotificationType.AWAY_CHECKER,
        },
        type: "PROMPT",
        tag: `TEST_NOTIFICATION`,
        duration: 0,
        buttons: [
          {
            text: "Dismiss",
            action: () => {
              Notifications.dismiss(notification);
            },
          },
        ],
        onClick: () => {
          Notifications.dismiss(notification);
          console.log("Notification Clicked");
        },
        refs: {},
      };
      Notifications.fire(notification);
    },
    // Help Center Notifications - END
    testBrowserNotification: ({
      title,
      body,
    }: {
      title: string;
      body: string;
    }) => {
      const notificationId = uuidv1();
      const notification: iNotification = {
        id: notificationId,
        title: title,
        body: body,
        icon: "cancel",
        audio: AvailableAudios.NEW_MESSAGE,
        channels: ["BROWSER"],
        data: {
          type: NotificationType.AWAY_CHECKER,
        },
        type: "PROMPT",
        tag: `TEST_NOTIFICATION`,
        duration: 0,
        buttons: [
          {
            text: "Dismiss",
            action: () => {
              Notifications.dismiss(notification);
            },
          },
        ],
        onClick: () => {
          Notifications.dismiss(notification);
          console.log("Notification Clicked");
        },
        refs: {},
      };
      Notifications.fire(notification);
    },
  };

  static dismiss = {
    clientIsInQueue: (sessionId: string) => {
      // Todo: Probably have to add the type as well
      const notification = _.find(Notifications.notifications, {
        data: { sessionId },
      });
      if (notification) {
        Notifications.dismiss(notification);
      }
    },
    clientIsInQueueByConversationId: (conversationId: string) => {
      const notification = _.find(
        Notifications.notifications,
        (item) =>
          item.data.type === NotificationType.QUEUED_CONVERSATION &&
          item.data.conversationId === conversationId,
      );
      if (notification) {
        Notifications.dismiss(notification);
      }
    },
    conversationJoinRequest: ({ userId }: { userId: string }) => {
      const notification = _.find(Notifications.notifications, {
        data: { userId },
      });
      if (notification) {
        Notifications.dismiss(notification);
      }
    },
    onUserInvitedToConversation: ({
      conversationId,
    }: {
      conversationId: string;
    }) => {
      const notification = _.find(Notifications.notifications, {
        data: { conversationId },
      });
      if (notification) {
        Notifications.dismiss(notification);
      }
    },
    onAwayDetected: () => {
      const notification = _.find(Notifications.notifications, {
        data: { type: NotificationType.AWAY_CHECKER },
      });
      if (notification) {
        Notifications.dismiss(notification);
      }
    },
    onMadeAway: () => {
      const notification = _.find(Notifications.notifications, {
        data: { type: NotificationType.MADE_AWAY },
      });
      if (notification) {
        Notifications.dismiss(notification);
      }
    },
    newMessageNotification: ({
      conversationId,
    }: {
      conversationId: string;
    }) => {
      const notification = _.find(Notifications.notifications, {
        data: { conversationId },
      });
      if (notification) {
        Notifications.dismiss(notification);
      }
    },
  };
}
