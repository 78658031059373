import {
  getPermissionFromRole,
  Roles
} from "@sdk/roles-and-permissions/roles-and-permissions";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { ApiUserType } from "@sdk/user-management/user-management.controller-models";
import { iUser } from "@sdk/user-management/user-management.models";
import { Button, Form, Input, message, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useMemo } from "react";

const { Option } = Select;

export const AddAPIUserModal = ({
  visible,
  onChangeVisibility,
  onCreated,
  type
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated?: (user: iUser) => any;
  type: ApiUserType;
}) => {
  const defaultFormValue = {};
  const [addAPIUserForm] = useForm();

  const {
    doAction: createAPIUser,
    isProcessing,
    response
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.createAPIUser(values).then(res => {
          onCreated && onCreated(res);
          addAPIUserForm.resetFields();
          onChangeVisibility(false);
          addAPIUserForm.resetFields();
        }),
      successMessage: "API User has been created",
      failureMessage: "Something went wrong"
    }),
    [addAPIUserForm, onChangeVisibility, onCreated]
  );

  const onSubmit = async () => {
    try {
      await addAPIUserForm.validateFields();
      const { role, label } = addAPIUserForm.getFieldsValue();
      createAPIUser({
        label,
        permissions: getPermissionFromRole(role),
        type: type
      });
    } catch (e) {
      message.error("Please check your input");
    }
  };

  const title = useMemo(() => {
    if (type === "Zapier App") {
      return "Create Zapier Token";
    }
    return "Create API Token";
  }, [type]);

  return (
    <Modal
      title={
        <ModalTitle title={title} icon={<i className="ri-plug-line"></i>} />
      }
      open={visible}
      onOk={async () => onSubmit()}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okButtonProps={{ loading: isProcessing }}
      okText={"Create"}
      data-click-context="Create API User Modal"
    >
      <Form
        layout="vertical"
        form={addAPIUserForm}
        initialValues={defaultFormValue}
        className="bold-form-labels"
        onFinish={onSubmit}
      >
        {/* Name */}
        <Form.Item
          label="Label"
          name="label"
          rules={[{ required: true, message: "Please input a label" }]}
        >
          <Input
            placeholder="Eg: My App Integration"
            size="large"
            prefix={<i className="ri-plug-line"></i>}
          />
        </Form.Item>
        {/* Role */}
        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: "Please select a role" }]}
        >
          <Select size="large">
            {Roles.map(role => (
              <Select.Option key={role.label} value={role.label}>
                {role.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button htmlType="submit" type="primary" className="hidden">
          Save
        </Button>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
