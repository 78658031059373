import { List, Tag, Tooltip } from "antd";
import classNames from "classnames";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import dayjs from "dayjs";

import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { loadAllContactLists } from "store/modules/contact-lists/contact-lists.helpers";
import { selectAllContactLists } from "store/modules/contact-lists/contact-lists.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { selectContactById } from "../../../../../store/modules/contacts/contacts.selectors";
import "./contact-list-item.scss";

export const ContactListItem = ({
  contactId,
  onContactSelected,
  isSelected,
}: {
  contactId: string;
  onContactSelected: (contactId: string) => any;
  isSelected?: boolean;
}) => {
  const contact = useSelector(selectContactById(contactId));

  const { state: allContactLists, retry: reload } = useQueryWithStore(
    selectAllContactLists,
    loadAllContactLists
  );

  const contactListMap = useMemo(() => {
    return _.keyBy(allContactLists, "id");
  }, [allContactLists]);

  const subjectLine = contact?.data
    ? `${contact?.data.firstName || ""} ${contact?.data.lastName || ""}`
    : "---";

  const previewLine = [
    contact?.data?.primaryEmail,
    contact?.data?.primaryMobile,
  ]
    .filter((e) => e)
    .join(" ");

  const createdDate = dayjs(contact?.metaData?.createdTime);
  const isCurrentDate = createdDate.isSame(new Date(), "day");
  const timeString = createdDate.format(isCurrentDate ? "LT" : "MMM Do");

  const tagText = useMemo(() => {
    const marketingLists = (contact?.marketingLists || [])
      .map((list) => ({
        ...list,
        label: contactListMap[list.id]?.data.label,
      }))
      .map((item) => item.label);

    if (!marketingLists || marketingLists.length === 0) {
      return "";
    }
    if (marketingLists.length === 1) {
      return marketingLists[0];
    }
    return `${marketingLists[0]}+${marketingLists.length - 1}`;
  }, [contact, contactListMap]);

  return (
    <List.Item
      className={classNames(
        "px-4 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer contact-list-item mode_transition",
        {
          active: isSelected,
        }
      )}
      onClick={() => onContactSelected(contactId)}
    >
      <div className="flex flex-row justify-start items-center w-full">
        <div className="avatar-container flex flex-row justify-center items-center">
          <ContactAvatar contactId={contact?.id!} size={40} />
        </div>
        <Tooltip title={subjectLine} placement="right">
          <div className="text-container flex flex-col flex-1 pl-2 w-full">
            <div className="first-line  flex flex-row justify-between items-center">
              {tagText && (
                <div className="tags-container">
                  <Tag color={"blue"}>{tagText}</Tag>
                </div>
              )}
            </div>
            <div className="second-line flex flex-row justify-between items-center">
              <div className="subject-line">{subjectLine}</div>
              <div className="date text-gray-600 dark:text-gray-400 text-xs mode_transition">
                {timeString}
              </div>
            </div>
            <div className="preview-line text-gray-600 dark:text-gray-400 text-sm mode_transition">
              {previewLine}
            </div>
          </div>
        </Tooltip>
      </div>
    </List.Item>
  );
};
