import { iScheduledReport } from "@sdk/scheduled-reports/scheduled-reports";
import { createSelector } from "reselect";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const getAllScheduledReports = () =>
  createSelector(selectAllScheduledReports, allScheduledReports => {
    return allScheduledReports;
  });

export const {
  selectQueryMap: selectScheduledReportQueryMap,
  selectEntityMap: selectScheduledReportsMap,
  selectQueryMapAll: selectScheduledReportsMapAll,
  selectQueryAllList: selectScheduledReportsAllList,
  selectAllEntities: selectAllScheduledReports,
  selectAllEntitiesQuery: selectAllScheduledReportsQuery,
  selectEntityById: selectScheduledReportById,
  selectEntityQuery: selectScheduledReportsQuery
} = CreateSelectorForEntities<iStore, iScheduledReport>({
  sliceName: "scheduledReports"
});
