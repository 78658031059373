import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { reducer } from "components/modules/message-presets/preset-manager-state-manager";
import { PresetManager } from "components/modules/message-presets/presets-manager";
import { DarkModeBg } from "dark-mode-bg";
import { useReducer } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUserPresetsX } from "store/modules/users/users.selectors";
import { useEffectWhen } from "utils/hooks/use-effect-when";

export const ManagePresetsModalX = ({
  visible,
  onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const messagePresetsState = useSelector(selectCurrentUserPresetsX);

  const {
    doAction: updatePresets,
    isProcessing,
    response
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => data =>
        SDK.editCurrentUser({
          data: {
            presetsX: data
          }
        } as any),
      // successMessage: "Presets have been saved",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const [state, dispatch] = useReducer(reducer, messagePresetsState);

  useEffectWhen(
    () => {
      updatePresets(state);
    },
    [state, updatePresets],
    [state]
  );

  return (<>
    <Modal
      title={
        <ModalTitle
          title="My Message Presets"
          icon={<i className="ri-message-3-line"></i>}
        />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      footer={null}
      width={660}
      data-click-context="Manage Presets Modal"
    >
      <div
        className="content flex-1 overflow-auto"
        style={{ minHeight: 550 }}
      >
        <PresetManager state={state} dispatch={dispatch} />
      </div>
      <DarkModeBg />
    </Modal>
  </>);
};
