import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Form, message, Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import {
  initialEdges,
  initialNodes,
} from "components/pages/bot-builder/initial-elements";
import { DarkModeBg } from "dark-mode-bg";
import { BotBuilder } from "modal-registry";
import { useCallback } from "react";
import { AddEditChatBotForm } from "./add-edit-chat-bot-form";

export const AddChatBotModal = ({
  visible,
  onChangeVisibility,
  onCreated,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated?: () => any;
}) => {
  const [form] = Form.useForm();

  const { changePanelState } = useModalPanels();

  const {
    doAction: onAddChatBot,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.chatBots
          .create({
            ...values,
            flow: {
              nodes: initialNodes,
              edges: initialEdges,
              viewPort: { zoom: 1, x: 5, y: 5 },
            },
          })
          .then((res) => {
            onCreated && onCreated();
            onChangeVisibility(false);
            setTimeout(() => {
              changePanelState(BotBuilder, true, { chatBotId: res.id });
            }, 400);
          }),
      successMessage: "Chatbot has been created",
      failureMessage: "Something went wrong",
    }),
    [changePanelState, onChangeVisibility, onCreated],
  );

  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      await onAddChatBot(form.getFieldsValue());
      setTimeout(() => {
        form.resetFields();
      }, 400);
    } catch (e) {
      message.error("Please check your input");
    }
  }, [form, onAddChatBot]);

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-folder-add-line"></i>}
          title="Create New Chatbot"
        />
      }
      open={visible}
      onOk={onSubmit}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Create"
      data-click-context="Create New Bot Modal"
      className="w-screen max-w-3xl"
      destroyOnClose={true}
    >
      <AddEditChatBotForm form={form} onSubmit={onSubmit} />
      <DarkModeBg />
    </Modal>
  );
};
