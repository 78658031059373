import { SocketConnector } from "@sdk/@libs/socket-connector";
import { Button } from "antd";
import { GlobalConfig } from "config";
import _ from "lodash";
import { useEffect, useState } from "react";

export interface ExternalAuthCallbackData {
  accessToken?: string;
  code?: string;
  oauth_token?: string;
  oauth_verifier?: string;
  d360?: {
    channels: string[];
    client: string;
  };
}

export const ExternalAuthConnector = ({
  type,
  label,
  insider,
  onAuthenticated
}: {
  type:
    | "FACEBOOK"
    | "WHATSAPP"
    | "TWITTER_V2"
    | "TWITTER_V1"
    | "GMAIL"
    | "D360";
  label?: string;
  insider?: boolean;
  onAuthenticated: (data: ExternalAuthCallbackData) => any;
}) => {
  const [isProcessStarted, setProcessStarted] = useState(false);
  const openExternAuthConnector = () => {
    // let url = `http://localhost:3000/external-auth-connector?target=${type}`;
    let url = `${GlobalConfig.AUTH_APP_URL}?target=${type}`;
    if (insider) {
      url += `&isInsider=true`;
    }
    window.open(url, "cc-auth-connector");
    setProcessStarted(true);
  };

  useEffect(() => {
    if (isProcessStarted) {
      const receiveMessage = event => {
        if (event?.data?.externalAuthConnector) {
          // console.log("Received External Window Event", event);
          onAuthenticated(event?.data?.externalAuthConnector);
          setProcessStarted(false);
        }
      };
      window.addEventListener("message", receiveMessage, false);
      return () => {
        window.removeEventListener("message", receiveMessage);
      };
    }
  }, [isProcessStarted, onAuthenticated]);

  useEffect(() => {
    if (isProcessStarted && type === "TWITTER_V2") {
      const listener = (
        data: ExternalAuthCallbackData & { userId: string }
      ) => {
        // console.log("Received Socket Data - Twitter");
        onAuthenticated(_.omit(data, "userId"));
        setProcessStarted(false);
      };
      if (SocketConnector.socket) {
        SocketConnector.socket.on("TWITTER_OAUTH", listener);
      }
      return () => {
        if (SocketConnector.socket) {
          SocketConnector.socket.off("TWITTER_OAUTH", listener);
        }
      };
    }
  }, [isProcessStarted, onAuthenticated, type]);

  return (
    <Button
      size="large"
      icon={<i className="ri-fingerprint-line"></i>}
      onClick={openExternAuthConnector}
    >
      {label || "Connect"}
    </Button>
  );
};
