import { TotalConversationStatCard } from "components/pages/home/your-achievements";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "store/modules/users/users.selectors";

export const MyOverallStats = () => {
  const currentUserId = useSelector(selectCurrentUserId);

  const queries = useMemo(() => {
    return {
      totalAllTime: {
        members: {
          $elemMatch: {
            userId: currentUserId
          }
        }
      },
      salesAllTime: {
        members: {
          $elemMatch: {
            userId: currentUserId
          }
        },
        tags: { $in: ["Sale", "sale"] }
      },
      totalLast90Days: {
        startDate: dayjs()
          .subtract(90, "day")
          .startOf("day")
          .valueOf(),
        endDate: dayjs()
          .endOf("day")
          .valueOf(),
        filters: {
          users: currentUserId
        }
      },
      salesLast90Days: {
        startDate: dayjs()
          .subtract(90, "day")
          .startOf("day")
          .valueOf(),
        endDate: dayjs()
          .endOf("day")
          .valueOf(),
        filters: {
          users: currentUserId,
          tagsIn: ["Sale", "sale"]
        }
      }
    };
  }, [currentUserId]);

  return (
    <div className="flex flex-row justify-around items-center w-full">
      {/* <SimpleReportStatCard
        title="Total Chats (90 Days)"
        icon={<i className="ri-message-2-line"></i>}
        reportType="CONVERSATION"
        query={queries.totalLast90Days}
      /> */}

      <TotalConversationStatCard
        title="Total Chats (All Time)"
        icon={<i className="ri-message-2-line"></i>}
        query={queries.totalAllTime}
      />

      {/* <SimpleReportStatCard
        title="Total Sales (90 Days)"
        icon={<span>💰</span>}
        reportType="CONVERSATION"
        query={queries.salesLast90Days}
      /> */}

      <TotalConversationStatCard
        title="Total Sales (All Time)"
        icon={<span>💰</span>}
        query={queries.salesAllTime}
      />
    </div>
  );
};
