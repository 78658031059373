import ImagePicker from "@libs/@unsplash-picker/component/picker";
import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { GlobalConfig } from "config";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback } from "react";

export const UnsplashImageSelectorModal = ({
  visible,
  onChangeVisibility,
  initialQuery,
  onPicked
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  initialQuery?: string;
  onPicked: (url: string) => any;
}) => {
  const onPhotoSelect = useCallback(
    photo => {
      onChangeVisibility(false);
      onPicked(photo?.urls?.regular);
    },
    [onChangeVisibility, onPicked]
  );

  return (
    <Modal
      title={
        <ModalTitle
          title="Select an Image"
          icon={<i className="ri-gallery-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={600}
      data-click-context="Unsplash Image Picker  Modal"
    >
      <ImagePicker
        unsplashAccessKey={GlobalConfig.UNSPLASH_API_KEY}
        initialPhotoSearchQuery={initialQuery}
        onPhotoSelect={onPhotoSelect}
      />
      <DarkModeBg />
    </Modal>
  );
};
