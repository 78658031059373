import { iWidget } from "@sdk/chat-widgets/chat-widgets.models";
import {
  iConnectionConfigurationData,
  iFbConnectionData
} from "@sdk/conversations/conversations.models";
import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { ConnectionIcons } from "components/modules/connections/helpers/connection-icons";
import { userHasConnectionGroupPermission } from "components/modules/connections/helpers/user-has-connection-permission";
import { buildChatViewQueryConfigs } from "components/modules/conversations/helpers/build-chat-view-queries";
import dayjs from "dayjs";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.selectors";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";

import { selectCurrentUser } from "store/modules/users/users.selectors";
import {
  selectChannels,
  selectConversationTags,
  selectInboxConfig,
  selectTeams
} from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { QueryConfig } from "store/utils/query-config";

// Inbox Config

type QueryConfigAlias = {
  alias: string;
};

export type InboxType =
  | "CHAT"
  | "FB_POSTS"
  | "IG_MEDIAS"
  | "TASKS"
  | "SPAM_EMAILS";

export interface iInboxItem {
  id: string;
  type: InboxType;
  label: string;
  icon: JSX.Element | string;
  keyboardShortcut?: string;
  queryConfig: QueryConfigAlias | QueryConfig;
  viewConfig: {
    breakByChatStatus?: boolean;
    isBeta?: boolean;
  };
}

// Inbox Menu Items

export interface InboxViewMenuItem extends iInboxItem {
  menuItemType: "ITEM";
}

export type InboxMenuItem =
  | {
      menuItemType: "TITLE";
      label: string;
    }
  | {
      menuItemType: "CUSTOM_COMPONENT";
      component: string;
    }
  | {
      menuItemType: "DIVIDER";
    }
  | InboxViewMenuItem
  | {
      menuItemType: "GROUP";
      label: string;
      icon: JSX.Element;
      children: InboxMenuItem[];
    };

const DividerMenu: InboxMenuItem = {
  menuItemType: "DIVIDER"
};

export const defaultInboxViewTemplate: iInboxItem[] = [
  {
    id: "DEFAULT_UNASSIGNED",
    type: "CHAT",
    label: "Unassigned",
    keyboardShortcut: "$mod+u",
    icon: <i className="ri-feedback-fill"></i>,
    queryConfig: {
      alias: "Unassigned"
    },
    viewConfig: {
      breakByChatStatus: true
    }
  },
  {
    id: "DEFAULT_MY",
    type: "CHAT",
    label: "My Inbox",
    keyboardShortcut: "$mod+m",
    icon: <i className="ri-inbox-fill"></i>,
    queryConfig: {
      alias: "My Inbox"
    },
    viewConfig: {
      breakByChatStatus: true
    }
  },
  {
    id: "DEFAULT_TEAM",
    type: "CHAT",
    label: "All Assigned",
    keyboardShortcut: "$mod+t",
    icon: <i className="ri-group-fill"></i>,
    queryConfig: {
      alias: "All Assigned"
    },
    viewConfig: {
      breakByChatStatus: true
    }
  },
  {
    id: "DEFAULT_ATTENTION",
    type: "CHAT",
    label: "All Awaiting Reply",
    icon: <i className="ri-flag-line"></i>,
    queryConfig: {
      alias: "Requires Attention"
    },
    viewConfig: {
      breakByChatStatus: false
    }
  }
];

export const useInboxes = (options?: {
  omitTitle?: boolean;
  omitTasks?: boolean;
}) => {
  const currentUser = useSelector(selectCurrentUser);

  const { state: connections, retry: reload, isLoading } = useQueryWithStore(
    selectAllConnections,
    loadAllConnections,
    []
  );
  const teams = useSelector(selectTeams);
  const conversationTags = useSelector(selectConversationTags);
  const workspaceInboxConfig = useSelector(selectInboxConfig);
  const channels = useSelector(selectChannels);
  const userRole = useMemo(() => {
    const currentUserRole = getRoleFromPermission(currentUser?.permissions);
    return currentUserRole;
  }, [currentUser?.permissions]);

  const { state: widgets } = useQueryWithStore(
    selectAllChatWidgets,
    loadAllChatWidgets(),
    []
  );

  const hasChatBotEnabled = useMemo(() => {
    for (const widget of widgets) {
      if (
        (widget as iWidget).configurations?.conversationFlow?.greetingsMessage
          ?.chatBotId
      ) {
        return true;
      }
    }
    return false;
  }, [widgets]);

  const hasFollowupsEnabled = useMemo(() => {
    for (const connection of connections) {
      if (
        (connection?.data as iConnectionConfigurationData)?.autoFollowupConfig
          ?.active
      ) {
        return true;
      }
    }
    return false;
  }, [connections]);

  const menu = useMemo(() => {
    const views = buildChatViewQueryConfigs(currentUser.id);
    // Generate Default Inboxes
    let data: InboxMenuItem[] = options?.omitTitle
      ? [
          ..._.cloneDeep(defaultInboxViewTemplate).map(item => ({
            menuItemType: "ITEM" as "ITEM",
            ...item
          }))
        ]
      : [
          {
            menuItemType: "TITLE",
            label: "Inboxes"
          },
          ..._.cloneDeep(defaultInboxViewTemplate).map(item => ({
            menuItemType: "ITEM" as "ITEM",
            ...item
          }))
        ];

    const inboxByDates: InboxMenuItem = {
      menuItemType: "GROUP",
      label: `By Started Date`,
      icon: <i className="ri-calendar-2-line"></i>,
      children: []
    };
    if (workspaceInboxConfig.showMyInboxByDates) {
      inboxByDates.children = buildMyInboxesByDate(views["My Inbox"]);
      const myInboxIndex = data.findIndex(
        item => (item as any)?.id === "DEFAULT_MY"
      );
      if (workspaceInboxConfig.groupMyInboxByDates) {
        data = [
          ...data.slice(0, myInboxIndex + 1),
          inboxByDates,
          ...data.slice(myInboxIndex + 1)
        ];
      } else {
        data = [
          ...data.slice(0, myInboxIndex + 1),
          ...inboxByDates.children,
          ...data.slice(myInboxIndex + 1)
        ];
      }
    }

    if (hasFollowupsEnabled) {
      const myInboxIndex = data.findIndex(
        item => (item as any)?.id === "DEFAULT_MY"
      );

      data.splice(myInboxIndex + 1, 0, {
        menuItemType: "ITEM" as "ITEM",
        id: "DEFAULT_FOLLOW_UP_INBOX",
        type: "CHAT",
        label: "My Followups",
        icon: <i className="ri-flag-line"></i>,
        queryConfig: {
          query: {
            ...views["My Inbox"].query,
            "metaData.flags.requiresFollowup.isActive": true
          },
          options: {
            ...views["My Inbox"].options
          }
        },
        viewConfig: {
          breakByChatStatus: true
        }
      });

      const allAwaitingReply = data.findIndex(
        item => (item as any)?.id === "DEFAULT_ATTENTION"
      );

      data.splice(allAwaitingReply + 1, 0, {
        menuItemType: "ITEM" as "ITEM",
        id: "DEFAULT_ATTENTION_ALL",
        type: "CHAT",
        label: "All Followups",
        icon: <i className="ri-flag-line"></i>,
        queryConfig: {
          query: {
            ...views["All Assigned"].query,
            "metaData.flags.requiresFollowup.isActive": true
          },
          options: {
            ...views["All Assigned"].options
          }
        },
        viewConfig: {
          breakByChatStatus: true
        }
      });
    }

    if (hasChatBotEnabled) {
      data.push({
        menuItemType: "ITEM" as "ITEM",
        id: "DEFAULT_BOT_INBOX",
        type: "CHAT",
        label: "Bot Inbox",
        icon: <i className="ri-robot-line"></i>,
        queryConfig: {
          alias: "Active Qualifications"
        },
        viewConfig: {
          breakByChatStatus: true
        }
      });
    }

    if (workspaceInboxConfig.showQuickInboxAddition) {
      if (userRole === "Owner" || userRole === "Manager") {
        data.push({
          menuItemType: "CUSTOM_COMPONENT",
          component: "QUICK_INBOX_ADD"
        });
      }
    }

    data.push(DividerMenu);

    const customInboxes = currentUser?.data.customInboxes || [];
    const pinnedConversation = currentUser?.data.pinnedConversations || [];
    const emailConnections = _.filter(connections, {
      type: "EMAIL"
    });

    let enableSpamBox = false;
    if (emailConnections.length > 0 && enableSpamBox) {
      const teamInboxIndex = data.findIndex(
        item => (item as any)?.id === "DEFAULT_TEAM"
      );

      data.splice(teamInboxIndex + 1, 0, {
        id: "SPAM_EMAILS",
        menuItemType: "ITEM",
        label: "Spam Emails",
        type: "SPAM_EMAILS",
        icon: <i className="ri-spam-line"></i>,
        queryConfig: {
          query: {},
          options: {
            sortBy: ["-metaData.lastMessage.timestamp"]
          }
        },
        viewConfig: {
          breakByChatStatus: false
        }
      });
    }

    if (customInboxes.length > 0) {
      const myInboxIndex = data.findIndex(
        item => (item as any)?.id === "DEFAULT_MY"
      );

      const customInboxMenu = customInboxes.map(item => ({
        id: `custom-${item.label}`,
        type: "CHAT" as "CHAT",
        menuItemType: "ITEM" as "ITEM",
        label: item.label,
        icon: <i className="ri-star-line"></i>,
        queryConfig: JSON.parse(item.query),
        viewConfig: {
          breakByChatStatus: false
        }
      }));
      data = [
        ...data.slice(0, myInboxIndex + 1),
        ...customInboxMenu,
        ...data.slice(myInboxIndex + 1)
      ];
    }

    if (pinnedConversation.length > 0) {
      data = [
        data[0],
        {
          id: "PINNED",
          menuItemType: "ITEM",
          type: "CHAT",
          label: "Pinned",
          icon: <i className="ri-pushpin-line"></i>,
          queryConfig: {
            query: {
              id: { $in: pinnedConversation }
            },
            options: {
              sortBy: ["-metaData.lastMessage.timestamp"]
            }
          },
          viewConfig: {
            breakByChatStatus: false
          }
        },
        ...data.slice(1)
      ];
    }

    // Include Tasks
    if (!options?.omitTasks) {
      data = [
        {
          id: "MY_TASKS",
          type: "TASKS",
          menuItemType: "ITEM",
          label: "Mentions & Tasks",
          icon: <i className="ri-task-line"></i>,
          queryConfig: {
            query: {
              $or: [
                {
                  assignedTo: {
                    $elemMatch: {
                      userId: currentUser?.id
                    }
                  }
                },
                {
                  createdBy: currentUser?.id
                }
              ],
              isResolved: false
            },
            options: {
              sortBy: ["createdAt"]
            }
          },
          viewConfig: {
            isBeta: false
          }
        },
        data[0],
        ...data.slice(1)
      ];
    }

    let tagsMenus: InboxMenuItem[] = [];
    if (workspaceInboxConfig.showInboxesByTags) {
      const groupedTags = _.groupBy(conversationTags, "tagGroup");

      const getStringId = (value: string) =>
        (value || "").toLowerCase().replace(/[^a-z0-9 _-]+/gi, "-");

      tagsMenus = Object.keys(groupedTags).map(group => {
        // Populate Tags
        const tagsMenu: InboxMenuItem = {
          menuItemType: "GROUP",
          label: group === "undefined" ? "By Tags" : `By ${group}`,
          icon: <i className="ri-price-tag-3-line"></i>,
          children: []
        };
        tagsMenu.children = (groupedTags[group] || [])
          .filter(e => !e.isHidden)
          .map(tag => ({
            id: `TAG_${getStringId(group)}_${getStringId(tag.label)}`,
            menuItemType: "ITEM" as "ITEM",
            type: "CHAT" as "CHAT",
            label: tag.label,
            icon: (
              <i
                className="ri-checkbox-blank-circle-fill"
                style={{ color: tag.color }}
              ></i>
            ),
            queryConfig: {
              query: {
                tags: tag.label,
                status: "OPEN"
              },
              options: {
                sortBy: ["-metaData.lastMessage.timestamp"]
              }
            },
            viewConfig: {
              breakByChatStatus: true
            }
          }));
        return tagsMenu;
      });
    }

    // Add Pages
    let facebookConnections = _.filter(connections, {
      type: "FACEBOOK"
    });

    const facebookPageItems: InboxMenuItem[] = facebookConnections
      .filter(item => item?.metaData?.isActive)
      .filter(e => e?.data?.page)
      .filter(item =>
        userHasConnectionGroupPermission(item?.userGroups, [
          currentUser?.id,
          ...(currentUser?.userGroups || [])
        ])
      )
      .map(connection => ({
        id: `Posts_Fb_${connection.id}`,
        menuItemType: "ITEM" as "ITEM",
        type: "FB_POSTS",
        label: `Posts: FB: ${connection.data?.page?.name}`,
        icon: <i className="ri-facebook-box-line"></i>,
        target: "FB_POSTS",
        queryConfig: {
          query: {
            pageId: (connection.data as iFbConnectionData).page.id
          },
          options: {
            sortBy: ["-updated_time"]
          }
        },
        viewConfig: {}
      }));

    // Add Instagram Accounts

    let instagramConnections = _.filter(connections, {
      type: "INSTAGRAM"
    });

    const instagramPageItems: InboxMenuItem[] = instagramConnections
      .filter(item => item?.metaData?.isActive)
      .filter(e => e?.data?.page)
      .filter(item =>
        userHasConnectionGroupPermission(item?.userGroups, [
          currentUser?.id,
          ...(currentUser?.userGroups || [])
        ])
      )
      .map(connection => ({
        id: `Posts_Ig_${connection.id}`,
        menuItemType: "ITEM" as "ITEM",
        type: "IG_MEDIAS",
        label: `Posts: IG: ${connection.data.page?.name}`,
        icon: <i className="ri-instagram-fill"></i>,
        target: "IG_MEDIAS",
        queryConfig: {
          query: {
            connectionId: connection.id!
          },
          options: {
            sortBy: ["-metaData.createdTime"]
          }
        },
        viewConfig: {}
      }));

    // Add Connections

    let connectionItems: InboxMenuItem[] = [];
    if (workspaceInboxConfig.showInboxesByConnections) {
      connectionItems = connections
        .filter(item => item?.metaData?.isActive)
        .filter(item =>
          userHasConnectionGroupPermission(item?.userGroups, [
            currentUser?.id,
            ...(currentUser?.userGroups || [])
          ])
        )
        .map(connection => ({
          id: `Connections_${connection.id}`,
          menuItemType: "ITEM" as "ITEM",
          type: "CHAT",
          label: connection.label,
          icon: <i className={ConnectionIcons[connection.type]}></i>,
          queryConfig: {
            query: {
              connectionId: connection.id!,
              status: "OPEN"
            },
            options: {
              sortBy: ["-metaData.lastMessage.timestamp"]
            }
          },
          viewConfig: { breakByChatStatus: true }
        }));
    }

    const connectionMenu: InboxMenuItem = {
      menuItemType: "GROUP",
      label: `By Connections`,
      icon: <i className="ri-arrow-left-right-line"></i>,
      children: connectionItems
    };

    data = [
      ...data,
      // {
      //   menuItemType: "TITLE",
      //   label: "Posts & Comments",
      // },
      // ...(workspaceInboxConfig.groupMyInboxByDates
      //   ? [inboxByDates]
      //   : inboxByDates.children),
      ...facebookPageItems,
      ...instagramPageItems,
      DividerMenu,
      ...(workspaceInboxConfig.groupInboxesByTags
        ? tagsMenus
        : _.flatten(tagsMenus.map(item => (item as any).children))),
      DividerMenu,
      ...[
        ...(workspaceInboxConfig.showInboxesByConnections
          ? [
              ...(workspaceInboxConfig.groupInboxesByConnections
                ? [connectionMenu]
                : connectionItems)
            ]
          : [])
      ]
    ];
    return data;
  }, [
    connections,
    conversationTags,
    currentUser?.data.customInboxes,
    currentUser?.data.pinnedConversations,
    currentUser.id,
    currentUser?.userGroups,
    hasChatBotEnabled,
    hasFollowupsEnabled,
    options?.omitTasks,
    options?.omitTitle,
    userRole,
    workspaceInboxConfig?.groupInboxesByConnections,
    workspaceInboxConfig?.groupInboxesByTags,
    workspaceInboxConfig?.groupMyInboxByDates,
    workspaceInboxConfig?.showInboxesByConnections,
    workspaceInboxConfig?.showInboxesByTags,
    workspaceInboxConfig?.showMyInboxByDates,
    workspaceInboxConfig?.showQuickInboxAddition
  ]);

  return menu;
};

export const buildMyInboxesByDate = (defaultConfig: QueryConfig) => {
  const dates = new Array(7).fill(null).map((item, index) => {
    return dayjs()
      .subtract(index, "day")
      .startOf("day");
  });

  return dates.map(
    date =>
      ({
        id: `INBOX_BY_DATE_${date.format("Do")}`,
        menuItemType: "ITEM",
        type: "CHAT",
        label: date.format("ddd, Do MMM"),
        icon: <i className="ri-calendar-2-line"></i>,
        queryConfig: {
          query: {
            ...defaultConfig.query,
            "metaData.createdAt": {
              $gt: date.toDate().getTime(),
              $lt: date
                .clone()
                .add(1, "d")
                .toDate()
                .getTime()
            }
          },
          options: {
            ...defaultConfig.options,
            sortBy: ["-metaData.lastMessage.timestamp"]
          }
        },
        viewConfig: {
          breakByChatStatus: true
        }
      } as InboxMenuItem)
  );
};
