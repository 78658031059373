import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import React from "react";

export const LoadingIndicatorWithSpin = ({ fontSize }: { fontSize? }) => {
  return <LoadingOutlined style={{ fontSize: fontSize || 24 }} spin />;
};

export const LoadingIndicatorWithoutSpin = () => {
  return <LoadingOutlined style={{ fontSize: 24 }} />;
};

export const SuspenseLoadingIndicator = ({
  style,
}: {
  style?: React.CSSProperties;
}) => (
  <div
    style={style}
    className="flex flex-col justify-center items-center w-full h-full"
  >
    <LoadingIndicatorWithSpin />
  </div>
);
