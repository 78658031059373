import { useSDK, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Form, Progress, Select } from "antd";

import { useCallback, useEffect, useState } from "react";

import {
  iOrganization,
  iWorkspaceIndustry,
} from "@sdk/user-management/user-management.models";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { CountrySelector } from "components/common/country-selector/country-selector";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import {
  ColorPickerFormItem,
  ImageUploaderFormItem,
} from "components/modules/connections/channels/email/components/forms/email-template";
import { Timezones } from "components/pages/reports/components/scheduled-reports/timezones";
import { useSelector, useStore } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { getCurrentTimezone } from "utils/get-current-timezone";

export const BrandingReviewStep = ({
  currentStep,
  stepPercentage,
  setCurrentStep,
}: {
  currentStep: number;
  stepPercentage: number;
  setCurrentStep: (currentStep: number) => any;
}) => {
  const organization = useSelector(selectOrganization);
  const store = useStore();

  const { doAction: _triggerAutoSetup, isProcessing: isAutoSetupProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (edits) => SDK.autoSetupAccount(),
        // successMessage: "Done",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const onTriggerAutoSetup = useCallback(async () => {
    await _triggerAutoSetup();
    setCurrentStep(currentStep + 1);
  }, [_triggerAutoSetup, currentStep, setCurrentStep]);

  const { data, error, isLoading } = useSDK(
    (SDK) => SDK.callBackRequests.resolveIP(),
    [],
  );

  const { doAction: deduceLogo, isProcessing: isDeducingLogo } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (edits) => SDK.deduceLogo(),
        // successMessage: "Done",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const { doAction: editCurrentOrganization } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (edits) => SDK.editCurrentOrganization(edits),
      // successMessage: "Done",
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const [hasProcessed, setProcessed] = useState(false);

  useEffect(() => {
    const process = async () => {
      const currentTimezone = getCurrentTimezone() || data.data.timezone;
      const currentCountry = data.data.country;

      const currentOrganizationState = selectOrganization(store.getState());

      const editObject = {} as Partial<iOrganization["data"]>;

      if (!currentOrganizationState?.data.country && currentCountry) {
        editObject.country = currentCountry;
      }
      if (!currentOrganizationState?.data.timezone && currentTimezone) {
        editObject.timezone = currentTimezone;
      }
      if (!currentOrganizationState?.data.logo) {
        const logo = (await deduceLogo())?.logo;
        if (logo) {
          editObject.logo = logo;
        }
      }
      if (
        !currentOrganizationState?.data.industry &&
        currentOrganizationState?.appData?.ONBOARDING?.industry
      ) {
        editObject.industry = currentOrganizationState?.appData.ONBOARDING
          .industry as iWorkspaceIndustry;
      }

      if (Object.keys(editObject).length > 0) {
        await editCurrentOrganization({
          data: editObject,
        });
      }
      setProcessed(true);
    };
    if (data.data) {
      process();
    }
  }, [data.data, deduceLogo, editCurrentOrganization, store]);

  return (
    <>
      <Progress
        percent={currentStep * stepPercentage}
        status="active"
        showInfo={false}
        className="opacity-25"
      />

      <Divider />

      <div className="mb-8 text-center w-full">
        <div className="font-semibold text-2xl">
          Review Branding, Country and Timezone
        </div>

        <div className="text-gray-600">
          This would allow us to set up your account with less friction
        </div>
      </div>

      {!hasProcessed && (
        <>
          <div
            className="flex flex-row justify-center items-center"
            style={{ height: 300 }}
          >
            <LoadingIndicatorWithSpin />
          </div>
        </>
      )}

      {hasProcessed && (
        <>
          <ConfigurationEditorComponent
            title="Branding"
            description="Set Logo and Brand Colour"
            icon={"ri-drop-fill"}
            entityType="WORKSPACE"
            ignoreNavigationWarning={true}
          >
            <ColorPickerFormItem
              label="Brand Color"
              fieldName={["data", "primaryColor"]}
            />
            <ImageUploaderFormItem
              label="Logo"
              fieldName={["data", "logo"]}
              entityType="WORKSPACE"
              entityId={organization?.id!}
            />
          </ConfigurationEditorComponent>

          <ConfigurationEditorComponent
            title="Country"
            description="Country to be used as default country when handling phone numbers"
            icon={"ri-flag-line"}
            entityType="WORKSPACE"
            ignoreNavigationWarning={true}
          >
            <Form.Item
              label="Select a country"
              name={["data", "country"]}
              hasFeedback
            >
              <CountrySelector />
            </Form.Item>
          </ConfigurationEditorComponent>

          <ConfigurationEditorComponent
            title="Timezone"
            description="Timezone to be used as default timezone for scheduled reports"
            icon={"ri-global-line"}
            entityType="WORKSPACE"
            ignoreNavigationWarning={true}
          >
            <Form.Item name={["data", "timezone"]} label="Timezone" rules={[]}>
              <Select style={{ minWidth: 150 }} showSearch>
                {Timezones.map((item) => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </ConfigurationEditorComponent>

          <div className="py-2"> </div>
          <Button
            type="primary"
            icon={<i className="ri-arrow-right-circle-line"></i>}
            className="font-bold"
            size="large"
            loading={isAutoSetupProcessing}
            onClick={() => {
              onTriggerAutoSetup();
            }}
          >
            Continue
          </Button>
        </>
      )}
    </>
  );
};
