import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { BgPatternPicker } from "modal-registry";
import { useEffect, useMemo, useState } from "react";

export const BgPatternSelector = ({
  value,
  onChange,
  primaryColor,
}: {
  value?: string;
  onChange?: (val: string) => any;
  primaryColor: string;
}) => {
  const { triggerTempModal } = useModalPanels();

  const [bgPatterns, setBgPatterns] = useState(
    [] as { name: string; backgroud: string }[],
  );
  useEffect(() => {
    import("./patterns.json").then((d) => {
      setBgPatterns(d.default);
    });
  }, []);

  const selectedPattern = useMemo(() => {
    return bgPatterns.find((item) => item.backgroud === value);
  }, [bgPatterns, value]);

  return (
    <>
      <div
        className="text-white flex flex-col justify-end items-center rounded-lg cursor-pointer p-4 font-semibold"
        style={{
          width: 200,
          height: 120,
          backgroundColor: primaryColor,
          backgroundImage: value,
        }}
        onClick={() => {
          triggerTempModal(BgPatternPicker, {
            primaryColor,
            onSelected: (pattern) => {
              onChange && onChange(pattern);
            },
          });
        }}
      >
        {!value && "None"}
        {value && selectedPattern && selectedPattern.name}
        {value && !selectedPattern && "Custom"}
      </div>
    </>
  );
};

export const BgPatternPickerModal = ({
  visible,
  onChangeVisibility,
  onSelected,
  primaryColor,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;

  primaryColor: string;
  onSelected: (pattern: string) => any;
}) => {
  const [bgPatterns, setBgPatterns] = useState(
    [] as { name: string; backgroud: string }[],
  );
  useEffect(() => {
    import("./patterns.json").then((d) => {
      setBgPatterns(d.default);
    });
  }, []);
  return (
    <>
      <Modal
        title={
          <ModalTitle
            icon={<i className="ri-pantone-line"></i>}
            title={"Pick a pattern"}
          />
        }
        open={visible}
        footer={null}
        onCancel={() => {
          onChangeVisibility(false);
        }}
        data-click-context="Bg Pattern Picker Modal"
        width={600}
        destroyOnClose
      >
        <div className="flex flex-row flex-wrap gap-4 justify-center items-center">
          <div
            className="text-white flex flex-col justify-end items-center rounded-lg cursor-pointer p-4 font-semibold"
            style={{
              width: 120,
              height: 120,
              backgroundColor: primaryColor,
            }}
            onClick={() => {
              onSelected && onSelected("");
              onChangeVisibility(false);
            }}
          >
            None
          </div>
          {bgPatterns.map((item) => (
            <div
              className="text-white flex flex-col justify-end items-center rounded-lg cursor-pointer p-4 font-semibold"
              style={{
                width: 120,
                height: 120,
                backgroundColor: primaryColor,
                backgroundImage: item.backgroud,
              }}
              onClick={() => {
                onSelected && onSelected(item.backgroud);
                onChangeVisibility(false);
              }}
            >
              {item.name}
            </div>
          ))}
        </div>

        <DarkModeBg />
      </Modal>
    </>
  );
};
