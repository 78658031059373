import { axios, AxiosResponse } from "@sdk/axios";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { iTask } from "./task-model";

export class TasksServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  tasks = Object.assign(
    new EntityServices<iTask>(this.config, "tasks", {
      onCreate: () => {
        UserTracker.track("TASKS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("TASKS - Delete", {});
      },
    }),
    {
      markAsDone: async (taskId: string, status: boolean) => {
        UserTracker.track("TASKS - Mark as Done", {});
        const results: AxiosResponse<any> = await axios.patch(
          this.config.basePath + `/tasks/${taskId}/status`,
          {
            status,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      assignUser: async (taskId: string, userId: string) => {
        UserTracker.track("TASKS - Assign User", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/tasks/${taskId}/assign`,
          {
            userId,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      unassignUser: async (taskId: string, userId: string) => {
        UserTracker.track("TASKS - Unassign User", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/tasks/${taskId}/unassign`,
          {
            userId,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      addComment: async (
        taskId: string,
        text: string,
        attachments: string[],
      ) => {
        UserTracker.track("TASKS - Add Comment", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/tasks/${taskId}/comments`,
          {
            text,
            attachments,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
