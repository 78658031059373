import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";
import InfoCircleFilled from "@ant-design/icons/InfoCircleFilled";
import { SDK } from "@sdk";
import { Button, Divider, Form, Input, message, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { CollapsibleConfigurationSection } from "components/common/collapsible-configuration-panel/collapsible-configuration-container";
import { useState } from "react";
import { ContactAccountManagerCard } from "../../contact-account-manager-card/contact-account-manager-card";
export const MigrateData = () => {
  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);
  const [isOtherFieldVisible, setIsOtherFieldVisible] = useState(false);
  const [form] = useForm();
  const { Option } = Select;

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const formData = form.getFieldsValue();

      try {
        const connection = await SDK.sendRequest({
          subject: "Migrate Data",
          data: {
            provider:
              formData.provider === "other"
                ? formData.other_provider
                : formData.provider,
            how_long: formData.how_long,
            how_many_users: formData.how_many_users,
          },
        }).then((d) => {
          setIsRequestSubmitted(true);
        });

        message.success("Data has been edited");
      } catch (e) {
        message.error("Something went wrong");
      }
    } catch (e) {
      // Validation Failed; Do nothing
      message.error("Please check your input");
    }
  };

  return (
    <CollapsibleConfigurationSection
      icon={"ri-database-2-line"}
      title={"Migrate Data"}
      description={"Bring in data from other platforms automatically"}
    >
      <>
        <div className="flex justify-center items-center mt-10">
          <div
            className="illustration-container "
            style={{ width: "40%", minWidth: 250 }}
          >
            <img
              src="/assets/illustrations/connect-whatsapp.svg"
              alt="Migrate Data"
            />
          </div>
        </div>
        {isRequestSubmitted ? (
          <div className="flex flex-col items-center ">
            <div className=" font-bold text-lg mt-10">
              Request successfully submitted
            </div>
            <CheckCircleOutlined className=" text-5xl text-green-700 my-10" />
            <div className=" bg-blue-100 border border-blue-300 rounded-lg p-5 flex max-w-3xl">
              <InfoCircleFilled className="text-blue-600 mt-1 mr-2" />
              <div>
                Our team received your request. One of our support team members
                will reach out to you via email in the next 48 hours.
              </div>
            </div>
          </div>
        ) : (
          <>
            <Divider className="text-center font-semibold mb-10">
              Send us a request
            </Divider>
            <Form
              layout="vertical"
              form={form}
              initialValues={{}}
              // onChange={() => {
              //   console.log("form.getFieldsValue();", form.getFieldsValue());
              // }}
            >
              <Form.Item
                label="Provider"
                name="provider"
                rules={[
                  {
                    required: true,
                    message: "Please enter a page",
                  },
                ]}
                className="font-semibold"
              >
                <Select
                  placeholder="Ex: Zoho"
                  onChange={() => {
                    setIsOtherFieldVisible(
                      form.getFieldValue("provider") === "other"
                    );
                  }}
                  allowClear
                >
                  <Option value="live_chat">Live Chat</Option>
                  <Option value="zoho">Zoho</Option>
                  <Option value="help_desk">Help Des</Option>
                  <Option value="intercom">Intercom</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="other_provider"
                label=""
                rules={[isOtherFieldVisible ? { required: true } : {}]}
                className={isOtherFieldVisible ? "" : "hidden"}
              >
                <Input placeholder="Type in the provider you have used" />
              </Form.Item>
              <Form.Item
                name="how_long"
                label="How long have you been using that software"
                rules={[{ required: true }]}
                className="font-semibold"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="how_many_users"
                label="How many users were using the software"
                rules={[{ required: true }]}
                className="font-semibold"
              >
                <Input />
              </Form.Item>
              <div className="flex flex-row justify-end mt-3">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  icon={<i className="ri-save-line"></i>}
                  onClick={() => onSubmit()}
                  // onClick={async () => {
                  //   onSubmit({
                  //     subject: "Migrate Data",
                  //     data: {
                  //       ["provider"]:
                  //         form.getFieldValue("provider") === "other"
                  //           ? form.getFieldValue("other_provider")
                  //           : form.getFieldValue("provider"),
                  //       ["how_long"]: form.getFieldValue("how_long"),
                  //       ["how_many_users"]: form.getFieldValue(
                  //         "how_many_users"
                  //       ),
                  //     },
                  //   });
                  // }}
                >
                  Send Request
                </Button>
              </div>
            </Form>
            <ContactAccountManagerCard type="SMALL" />
          </>
        )}
      </>
    </CollapsibleConfigurationSection>
  );
};
