import { Button } from "antd";
import { EditableText } from "components/common/editable-text/editable-text";

import * as _ from "lodash";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useSimpleState } from "utils/hooks/use-simple-state";
import "./custom-data-container.scss";

// Todo: Data types is not implemented

const fieldsArr = [
  {
    label: "First Name",
    type: "STRING",
    value: "Thomas",
  },
  {
    label: "Last Name",
    type: "STRING",
    value: "Cook",
  },
  {
    label: "Date Of Birth",
    type: "DATE",
    value: "12/12/2012",
  },
  {
    label: "Booking Id",
    type: "NUMBER",
    value: "1312143r434",
  },
];

const fieldsObj = {
  "First Name": "Thomas",
  "Last Name": "Cook",
  "Date Of Birth": "12/12/2012",
  "Booking Id": "1312143r434",
};

export const CustomDataContainer = memo(
  ({
    data,
    onSave,
  }: {
    data: Record<string, any>;
    onSave?: (change: Record<string, any>) => any;
  }) => {
    const fields = useMemo(() => {
      return [
        ...Object.keys(data).map((key) => ({
          label: key,
          value: data[key],
        })),
        {
          label: "",
          value: "",
        },
      ];
    }, [data]);

    const onFieldChange = useCallback(
      (fieldState) =>
        onSave && onSave({ ...data, [fieldState.label]: fieldState.value }),
      [onSave, data]
    );

    const onClearField = useCallback(
      (key) => {
        onSave && onSave(_.omit(data, [key]));
      },
      [data, onSave]
    );

    return (
      <div className="custom-data-container">
        <table className="w-full">
          <tbody>
            {fields.map((field, index) => (
              <CustomFieldRow
                key={index.toString()}
                label={field.label}
                value={field.value}
                onChange={onFieldChange}
                clearField={onClearField}
              />
            ))}
            {/* <tr>
          <td colSpan={2}>
            <Button
              icon={<i className="ri-add-circle-line"></i>}
              type="text"
              className="text-gray-600 dark:text-gray-400 mode_transition"
              onClick={}
            >
              Add Data
            </Button>
          </td>
        </tr> */}
          </tbody>
        </table>
      </div>
    );
  }
);

const CustomFieldRow = memo(
  ({
    label,
    value,
    onChange,
    clearField,
  }: {
    label: string;
    value: string;
    onChange: (value: { label: string; value: string }) => any;
    clearField: (key: string) => any;
  }) => {
    const [state, setState] = useSimpleState({ label, value });
    useEffect(() => {
      setState({ label, value });
    }, [label, setState, value]);

    const onKeyChange = useCallback(
      (label: string) => {
        setState({
          label: label,
        });
        if (label && state.value) {
          onChange({
            label: label,
            value: state.value,
          });
        }
      },
      [setState, onChange, state.value]
    );

    const onValueChange = useCallback(
      (value: string) => {
        setState({
          value: value,
        });
        if (state.label && value) {
          onChange({
            value: value,
            label: state.label,
          });
        }
      },
      [state.label, setState, onChange]
    );

    const onClearField = useCallback(() => clearField(label), [
      label,
      clearField,
    ]);

    return (
      <tr>
        <td style={{ width: "50%" }}>
          <div className="label">
            <EditableText
              value={state.label}
              placeholder="+ New Field"
              onSave={onKeyChange}
            />
          </div>
        </td>
        <td>
          <div className="value">
            <EditableText
              value={state.value}
              placeholder="+ Data"
              onSave={onValueChange}
            />
          </div>
        </td>
        <td>
          <div className="clear">
            {label && (
              <Button onClick={onClearField} type="link">
                <i className="ri-close-line"></i>
              </Button>
            )}
          </div>
        </td>
      </tr>
    );
  }
);
