import { Divider, Form, Input, Select } from "antd";
import { emailValidatorRuleForSelect } from "components/common/reply-input/email-modifer";
import { SectionHeader } from "components/common/section-header";
import { StyledSwitch } from "components/common/styled-swtich";
import { AutoCloseConfigurationForm } from "components/modules/connections/common-connection-settings/auto-close-configuration/auto-close-configuration-form";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { ConnectionDataEditor } from "../common-connection-settings/connection-data-editor";

const tokenSeparators = [",", ";"];

export const ConnectionAutomationConfig = ({
  connectionId,
}: {
  connectionId?: string;
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const connection = useSelector(selectConnectionById(connectionId!));

  return (
    <>
      <div className="animated fadeInLeftMin">
        <SectionHeader
          title="Configure Connection Automatons"
          icon={<i className="ri-magic-line"></i>}
        />
        <Divider />
        <AutoCloseConfigurationForm connectionId={connectionId} />

        <ConnectionDataEditor
          connectionId={connectionId!}
          icon={"ri-magic-line"}
          title="Auto Extract Contact Information"
          description="Scan all incoming message and automatically extract emails and mobile numbers and save it in the CRM"
        >
          <StyledSwitch
            name={["data", "autoExtractContactData", "active"]}
            label="Enabled"
          />
        </ConnectionDataEditor>

        <ConnectionDataEditor
          connectionId={connectionId!}
          icon={"ri-flag-line"}
          title="Auto Flag for Followups"
          description="Automatically flag conversations that requires followups"
        >
          <StyledSwitch
            name={["data", "autoFollowupConfig", "active"]}
            label="Enabled"
          />
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const isActive = getFieldValue([
                "data",
                "autoFollowupConfig",
                "active",
              ]);
              if (isActive) {
                return (
                  <Form.Item
                    label="Followup Reminder Pattern"
                    name={["data", "autoFollowupConfig", "template"]}
                    rules={[
                      { required: true, message: "Please select a template" },
                    ]}
                  >
                    <Select>
                      {window.location.href.includes("localhost") && (
                        <Select.Option value={"SUPER_SHORT_TEST"}>
                          Short Testing
                        </Select.Option>
                      )}

                      <Select.Option value={"DAILY_FOR_3_DAYS"}>
                        Followup Everyday for 3 days
                      </Select.Option>
                      <Select.Option value={"3_FOLLOWUPS_7_DAYS"}>
                        3 Followups within a week
                      </Select.Option>
                      <Select.Option value={"2_WEEK_FOLLOWUP"}>
                        3 Followups within 2 weeks
                      </Select.Option>
                    </Select>
                  </Form.Item>
                );
              }
              return <></>;
            }}
          </Form.Item>
        </ConnectionDataEditor>

        <ConnectionDataEditor
          connectionId={connectionId!}
          icon={"ri-chat-new-line"}
          title="Automatic Message When Conversation Re-opens"
          description="Automatically send a message when customer re-opens a conversation"
        >
          <Form.Item
            label="Message to send"
            name={["data", "autoMessageOnReOpened", "message"]}
            rules={[]}
            help="Keep it empty to disable this automation."
          >
            <Input />
          </Form.Item>
        </ConnectionDataEditor>

        {connection && connection.type !== "EMAIL" && (
          <ConnectionDataEditor
            connectionId={connectionId!}
            icon={"ri-mail-send-line"}
            title="Auto Email Chat Transcripts"
            description="Email the transcript to your emails when a conversation is marked as closed"
          >
            <Form.Item
              label="Emails"
              name={["data", "autoEmailTranscripts"]}
              rules={[emailValidatorRuleForSelect]}
            >
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Add Emails"
                dropdownStyle={{ display: "none" }}
                allowClear
                tokenSeparators={tokenSeparators}
                // onChange={handleChange}
              ></Select>
            </Form.Item>
          </ConnectionDataEditor>
        )}
      </div>
    </>
  );
};
