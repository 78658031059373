import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { allBadgeCategories, BadgeRegistry } from "@sdk/badges/badge-registry";
import { Button, Divider, Modal, Progress, Tooltip } from "antd";
import classnames from "classnames";
import { ModalTitle } from "components/common/modal-title";
import { PerfectScrollbar } from "components/common/styled-scroll-area";
import { DarkModeBg } from "dark-mode-bg";
import { groupBy } from "lodash";
import { BadgeViewer } from "modal-registry";
import { useMemo } from "react";
import { loadAllBadges } from "store/modules/badges/badges.helpers";
import { selectAllBadges } from "store/modules/badges/badges.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { toKebabCase } from "utils/to-kebab-case";

export const AllBadgesModal = ({
  visible,
  onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const badgedByBadgeCategory = useMemo(
    () => groupBy(BadgeRegistry, "category"),
    []
  );
  const { state: badges, retry: reload, isLoading } = useQueryWithStore(
    selectAllBadges,
    loadAllBadges,
    []
  );

  const progress = useMemo(
    () => Math.round(((badges?.length || 0) / BadgeRegistry.length) * 100),
    [badges?.length]
  );

  return (
    <Modal
      title={
        <ModalTitle title="Badges" icon={<i className="ri-medal-2-line"></i>} />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Badges Modal"
      width={900}
    >
      <PerfectScrollbar style={{ height: "100%" }}>
        <div className="header p-8 flex flex-col justify-center items-center mb-12">
          <i className="ri-medal-2-line text-6xl"></i>
          <div className="text-lg">
            {badges?.length}/{BadgeRegistry.length} Badges Earned{" "}
            <Button
              type="text"
              icon={<i className="ri-refresh-line"></i>}
              onClick={reload}
            ></Button>
          </div>
          <div className="flex flex-row justify-center items-center">
            <Progress percent={progress} size={[300, 5]} />
          </div>
          <div className="line">
            You are making progress! We are happy that you are making the best
            of Click Connector
          </div>
        </div>
        {allBadgeCategories.map(category => (
          <div className="badge-section mb-8" key={category}>
            <div className="text-lg">
              {" "}
              <i className="ri-medal-2-line text-xl"></i> {category}
            </div>
            <Divider className="my-2" />
            <div className="grid grid-cols-2 xl:grid-cols-4 gap-4">
              {(badgedByBadgeCategory[category] || []).map(item => (
                <CCBadge badgeId={item.id} key={item.id} />
              ))}
            </div>
          </div>
        ))}
      </PerfectScrollbar>
      <DarkModeBg />
    </Modal>
  );
};

export const CCBadge = ({
  badgeId,
  width,
  hideLabels
}: {
  badgeId: string;
  width?: number;
  hideLabels?: boolean;
}) => {
  const { changePanelState } = useModalPanels();

  const { state: badges, retry: reload, isLoading } = useQueryWithStore(
    selectAllBadges,
    loadAllBadges,
    []
  );

  const badgeMap = useMemo(() => {
    return groupBy(badges, "badgeId");
  }, [badges]);

  const badgeMeta = useMemo(
    () => BadgeRegistry.find(item => item.id === badgeId),
    [badgeId]
  );

  if (!badgeMeta) {
    <div
      className="cursor-pointer hover:bg-gray-200 dark:bg-gray-800 rounded-lg flex flex-col justify-center items-center p-4"
      style={{ width: width || 180 }}
    >
      Invalid Badge
    </div>;
  }

  return (
    <Tooltip title={badgeMeta?.name}>
      <div
        onClick={() =>
          changePanelState(BadgeViewer, true, { badgeId: badgeId })
        }
        className={classnames(
          "cursor-pointer hover:bg-gray-200 dark:bg-gray-800 rounded-lg flex flex-col justify-center items-center",
          {
            "p-4": (width || 180) >= 160,
            "p-3": (width || 180) >= 140 && (width || 180) < 160,
            "p-2": (width || 180) >= 100 && (width || 180) < 140,
            "p-1": (width || 180) <= 100
          }
        )}
        style={{ width: width || 180 }}
      >
        {badgeMap[badgeId] ? (
          <div
            style={{
              width: width ? width - 60 : 120,
              height: width ? width - 20 : 160
            }}
            className="flex flex-row justify-center items-center"
          >
            <img
              src={`/assets/badges/${toKebabCase(
                badgeMeta?.category || ""
              )}/${badgeId}.svg`}
              style={{ width: 100 }}
              alt=""
            />
          </div>
        ) : (
          <div
            style={{
              width: width ? width - 60 : 120,
              height: width ? width - 20 : 160
            }}
            className="flex flex-row justify-center items-center"
          >
            <i className="ri-lock-2-line text-6xl"></i>
          </div>
        )}
        {!hideLabels && <div className="font-semibold">{badgeMeta?.name}</div>}
      </div>
    </Tooltip>
  );
};
