import { useSelector } from "react-redux";
import { selectConversationsCountForQuery } from "store/modules/conversations/conversations.selectors";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { selectAlertEveryoneOnUnassignedChats } from "store/modules/workspace/workspace.selectors";

export const UnassignedChatsBanner = () => {
  const currentUserId = useSelector(selectCurrentUserId);
  const unassignedCount = useSelector(
    selectConversationsCountForQuery("Unassigned")
  );

  const alertEveryoneOnUnassignedChats = useSelector(
    selectAlertEveryoneOnUnassignedChats
  );

  return (
    <>
      {currentUserId &&
        Boolean(unassignedCount) &&
        alertEveryoneOnUnassignedChats && (
          <div className="bg-red-700 font-bold text-white flex justify-center items-center p-2">
            You have unassigned chats!
          </div>
        )}
    </>
  );
};
