import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { Modal } from "antd";
import { useThrottle } from "utils/hooks/use-throttle";
import { DarkModeBg } from "dark-mode-bg";

export const RouterPrompt = ({
  when,
  title = "",
  okText = "Yes",
  cancelText = "Cancel"
}: {
  when: boolean;
  onOK?: () => any;
  onCancel?: () => any;
  title?: string;
  okText?: string;
  cancelText?: string;
}) => {
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, _setCurrentPath] = useState("");

  const unblockRef = useRef<any>();

  function handleShowModal() {
    setShowPrompt(true);
  }

  function onCancel() {
    setShowPrompt(false);
  }

  const setCurrentPath = useThrottle(
    value => {
      _setCurrentPath(value);
    },
    10,
    { leading: true, trailing: false }
  );

  useEffect(() => {
    unblockRef.current = history.block(location => {
      if (when) {
        setCurrentPath(location.pathname);
        handleShowModal();
        return false;
      }
      return;
    });
    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [history, setCurrentPath, when]);

  function handleConfirm() {
    if (unblockRef) {
      unblockRef.current();
    }
    setShowPrompt(false);
    history.push(currentPath);
  }

  return showPrompt ? (
    <Modal
      title={title}
      open={showPrompt}
      onOk={handleConfirm}
      okText={okText}
      onCancel={onCancel}
      cancelText={cancelText}
      closable={true}
    >
      There are unsaved changes. Are you sure want to leave this page ?
      <DarkModeBg />
    </Modal>
  ) : null;
};
