import { Store } from "redux";
import { selectAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { iSelfServicePortalData } from "./website/services/models";

export const generateDefaultKBConfig = async (store: Store) => {
  const organization = selectOrganization(store.getState());
  const widgets = selectAllChatWidgets(store.getState());
  return {
    siteName: `Knowledge Base - ${organization?.data.name}`,
    domain: {
      subdomain: organization?.domain,
    },
    seo: {
      description: "",
      tags: "",
    },
    appearance: {
      primaryColor: organization?.data.primaryColor || "#2b66ff",
    },
    customHTML: {
      head: "",
      body: "",
    },
    navBar: {
      isEnabled: true,
      logo: organization?.data.logo || "",
      links: [],
      buttons: [],
    },
    hero: {
      pattern: `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23000000' fill-opacity='0.07' fill-rule='evenodd'/%3E%3C/svg%3E")`,
      title: "Knowledge Base",
      searchPlaceholder: "",
    },
    footer: {
      logo: organization?.data.logo || "",
      description: "",
      socialLinks: [
        // {
        //   label: "Facebook",
        //   link: "#"
        // },
        // {
        //   label: "Twitter",
        //   link: "#"
        // },
        // {
        //   label: "Instagram",
        //   link: "#"
        // }
      ],
      linkSections: [],
      copyRightText: `© 2022 ${organization?.data.name}. All rights reserved.`,
    },
    liveChatIntegration: {
      widgetId: widgets?.[0]?.id || "",
    },
  } as iSelfServicePortalData;
};
