import { Tag } from "antd";
import { renderToStaticMarkup } from "react-dom/server";

export const pieChartConfig: Highcharts.Options = {
  colors: ["#2b9eff", "#1A73E8", "#74BBFB", "#9CACBF", "#072F5F", "#072F5F"],
  title: {
    text: ""
  },
  series: [
    {
      type: "pie",
      data: [1, 2, 3, 4, 5, 6, 70],
      cursor: "pointer",
      point: {
        events: {
          click: function(e) {
            // const container = menuContainerRef.current;
            // if (container) {
            //   setMenuVisibility(true);
            //   container.setAttribute(
            //     "style",
            //     "top: " + e.pageY + "px; left:" + e.pageX + "px;"
            //   );
            // }
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function() {
          return renderToStaticMarkup(<Tag>{this.point.name}</Tag>);
        }
        // formatter: function() {
        //   return "<b>" + this.point.name + "</b>: " + this.percentage + " %";
        // },
      }
    }
  ],
  accessibility: {
    point: {
      valueSuffix: "Hits"
    }
  },
  credits: {
    enabled: false
  },
  yAxis: {
    title: {
      text: ""
    },
    gridLineWidth: 0
  },
  legend: {
    enabled: true
  },
  tooltip: {
    enabled: true
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      // cursor: "pointer",
      // dataLabels: {
      //   enabled: false,
      // },
      showInLegend: true
    }
  },
  chart: { backgroundColor: "#00000000" }
};
