import { Badge } from "antd";
import { useSelector } from "react-redux";
import {
  selectAllUnreadMessages,
  selectUnreadMessagesFoQuery
} from "store/modules/conversations/conversations.selectors";
import { useSelectorWithMemoize } from "store/utils/use-selector-with-memoize";

export const QueryUnreadCountBadge = ({ query }: { query?: string }) => {
  const unread = useSelectorWithMemoize(
    () => selectUnreadMessagesFoQuery(query!),
    [query],
    true
  );

  return (
    <>
      {unread.hasUnread && (
        <Badge
          count={unread.count || undefined}
          dot={!unread.count ? unread.hasUnread : undefined}
          className="un-read-badge blue"
        ></Badge>
      )}
    </>
  );
};

export const AllUnreadCountBadge = () => {
  const unread = useSelector(selectAllUnreadMessages);

  return (
    <>
      {unread.hasUnread && (
        <Badge
          count={unread.count || undefined}
          dot={!unread.count ? unread.hasUnread : undefined}
          className="un-read-badge blue"
        ></Badge>
      )}
    </>
  );
};
