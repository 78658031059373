import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Space,
  Tooltip,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import copyToClipboard from "copy-to-clipboard";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect, useMemo, useState } from "react";

export const MagicAssistantModal = ({
  visible,
  onChangeVisibility,
  query,
  onReplaceText,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  query?: string;
  onReplaceText?: (content: string) => any;
}) => {
  const [form] = useForm();

  const {
    doAction: _process,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (query: string) =>
        SDK.knowledgeDocuments.queryKnowledge(query),
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const [responseContent, setResponseContent] = useState("");

  useEffect(() => {
    setResponseContent(response?.response || "");
  }, [response]);

  const process = useCallback(async () => {
    try {
      await form.validateFields();
      const { query } = form.getFieldsValue();
      if (query) {
        _process(query);
      }
    } catch (e) {
      message.error("Please check your input");
    }
  }, [_process, form]);

  useEffect(() => {
    if (query) {
      process();
    }
  }, [query, process]);

  const initialValue = useMemo(() => {
    return { query };
  }, [query]);

  return (
    <Modal
      title={
        <ModalTitle
          title="Magic Assistant"
          icon={<i className="ri-magic-line"></i>}
        />
      }
      open={visible}
      okButtonProps={{
        loading: isProcessing,
        icon: <i className="ri-save-line"></i>,
        className: "font-bold",
      }}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={500}
      data-click-context="Magic Assistant  Modal"
    >
      <div className="mb-4">
        The Magic Assistant is equipped to respond to your queries based on the
        knowledge you have set up in your KnowledgeKit. It will make every
        effort to provide answers exclusively from that knowledge. For general
        inquiries, please utilize the AI Assistant.
      </div>
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValue}
        className="bold-form-labels"
        requiredMark={false}
      >
        <Form.Item
          name="query"
          label={"Query"}
          rules={[
            {
              required: true,
              message: "Please write your query",
            },
          ]}
        >
          <Input.TextArea placeholder="Your query" />
        </Form.Item>

        <Button
          type="primary"
          icon={<i className="ri-magic-line"></i>}
          loading={isProcessing}
          onClick={process}
          size={"large"}
          block
          className="font-bold"
        >
          Let's Go
        </Button>
      </Form>
      {responseContent && (
        <>
          <Divider className="font-semibold text-gray-600">Response</Divider>

          <Input.TextArea
            value={responseContent}
            onChange={(e) => setResponseContent(e.target.value)}
            placeholder=""
            rows={10}
          />

          <div className="my-4 flex flex-row justify-end items-center">
            <Space>
              <Tooltip title="Copy AI response to Clipboard">
                <Button
                  onClick={(e) => {
                    copyToClipboard(responseContent);
                    e.stopPropagation();
                  }}
                  type="link"
                  icon={<i className="ri-clipboard-line"></i>}
                ></Button>
              </Tooltip>

              {onReplaceText && (
                <Button
                  type="text"
                  icon={<i className="ri-check-line"></i>}
                  loading={isProcessing}
                  onClick={() => {
                    onReplaceText(responseContent);
                    onChangeVisibility(false);
                  }}
                >
                  Add to Chat Input
                </Button>
              )}
            </Space>
          </div>
        </>
      )}
      <DarkModeBg />
    </Modal>
  );
};
