import { iTelnyxConnectionData } from "@sdk/conversations/conversations.models";
import { Alert, Divider, Form, Select } from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { SectionHeader } from "components/common/section-header";
import { StyledSwitch } from "components/common/styled-swtich";
import { ConnectionDataEditor } from "components/modules/connections/common-connection-settings/connection-data-editor";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { CountryCodes } from "utils/country-codes";
import { isIpAddress } from "utils/is-ip-address";
import { stringArrayToSentence } from "utils/string-array-to-sentence";
import {
  ColorPickerFormItem,
  ImageUploaderFormItem
} from "../../email/components/forms/email-template";
import { CallbackWidgetInstallInstructions } from "./callback-widget-install-instructions/callback-widget-install-instructions";
import { CustomCallbackWidgetTexts } from "./custom-callback-widget-texts";
import { ConfigureCallbackWidgetOfflineHours } from "./manage-offline-hours";

export const ManageTelnyxCallbackWidget = ({
  connectionId
}: {
  connectionId: string;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId
  );

  const connectionData = connection?.data as iTelnyxConnectionData | undefined;

  return (
    <div className="animated fadeInLeftMin  flex flex-col w-full">
      <SectionHeader
        title="Manage Callback Widget"
        icon={<i className="ri-phone-line"></i>}
      />
      <Divider />
      <ConnectionDataEditor
        icon={"ri-palette-line"}
        title="Widget Colour and Logo"
        description="Set a custom colour or use brand colours"
        connectionId={connectionId}
      >
        <ImageUploaderFormItem
          label="Logo"
          fieldName={["data", "callBackWidgetConfig", "logo"]}
          entityType="CALL_BACK_FORM"
          entityId={connectionId}
        />
        <ColorPickerFormItem
          fieldName={["data", "callBackWidgetConfig", "colorPallet", "primary"]}
          label="Widget Color"
        />
      </ConnectionDataEditor>
      <ConfigureCallbackWidgetOfflineHours connectionId={connectionId} />
      <CustomCallbackWidgetTexts connectionId={connectionId} />
      <ConfigurationEditorComponent
        icon="ri-shield-check-line"
        title="Blacklisted Clients"
        description="disable the widget if a person tries to access chat through specific IPs"
        entityId={connectionId}
        entityType="CONNECTION"
      >
        <Form.Item
          label="Blacklisted IPs"
          name={["data", "callBackWidgetConfig", "blackListedClients", "ips"]}
          hasFeedback
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, ips) {
                if (!ips || ips.length === 0) {
                  return Promise.resolve();
                }
                const invalidIps = ips.filter(ip => !isIpAddress(ip));
                if (invalidIps.length > 0) {
                  return Promise.reject(
                    `The following Ips are Invalid: ${stringArrayToSentence(
                      invalidIps
                    )}`
                  );
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Blacklisted IPs"
            dropdownStyle={{ display: "none" }}
            allowClear
          ></Select>
        </Form.Item>
      </ConfigurationEditorComponent>
      <ConfigurationEditorComponent
        icon="ri-eye-line"
        title="Customize Widget Visibility"
        description="Control to whom the call back widget will be visible"
        entityId={connectionId}
        entityType="CONNECTION"
      >
        <Form.Item
          label="Show only in the following countries"
          name={["data", "callBackWidgetConfig", "triggersOn", "countries"]}
          hasFeedback
          help="Leave it blank to show the widget in all countries"
        >
          <Select showSearch mode="tags">
            {CountryCodes.map(country => (
              <Select.Option
                value={`${country.code}|${country.name}`}
                key={country.code}
              >
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Show only in the following pages"
          name={["data", "callBackWidgetConfig", "triggersOn", "pages"]}
          hasFeedback
          help="Leave it blank to show the widget in all pages"
        >
          <Select mode="tags" popupClassName="hidden"></Select>
        </Form.Item>
      </ConfigurationEditorComponent>
      <ConnectionDataEditor
        icon={"ri-route-line"}
        title="Call Initiation Flow"
        description="Configure the sequence in which calls should be originated"
        connectionId={connectionId}
      >
        <Alert
          message={
            " By Default, both customer call and agent call are processed simultaneously"
          }
          type="info"
          showIcon
          className="mb-4"
        />
        <StyledSwitch
          name={[
            "data",
            "callBackWidgetConfig",
            "processCallbackRequestAfterClientAnswers"
          ]}
          label="Initiate agent call after  the customer answers the callback request"
        />
      </ConnectionDataEditor>
      <ConfigurationEditorComponent
        icon="ri-shield-check-line"
        title="Customize Phone Number Eligibility for Callback"
        description="Control callback request eligibility based on phone number"
        entityId={connectionId}
        entityType="CONNECTION"
      >
        <Form.Item
          label="Allow only callback requests for the following countries"
          name={[
            "data",
            "callBackWidgetConfig",
            "validatePhone",
            "whitelistedCountries"
          ]}
          hasFeedback
          help="Leave it blank to allow all countries"
        >
          <Select showSearch mode="tags">
            {CountryCodes.map(country => (
              <Select.Option
                value={`${country.code}|${country.name}`}
                key={country.code}
              >
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Blacklisted Numbers"
          name={[
            "data",
            "callBackWidgetConfig",
            "validatePhone",
            "blockedNumbers"
          ]}
          hasFeedback
          help="Enter full number with country code"
        >
          <Select mode="tags" popupClassName="hidden"></Select>
        </Form.Item>
      </ConfigurationEditorComponent>
      <CallbackWidgetInstallInstructions connectionId={connectionId} />
    </div>
  );
};
