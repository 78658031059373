import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Checkbox, Divider, Modal, Result, Steps } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { ResetInnerCSS } from "components/common/rendered-html/rendered-html";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { DataProcessingAgreement } from "./data-processing-agreement";
import { DPAInput } from "./dpa-input";
import { LazyDPADownloader } from "./lazy-dpa-downloader";

const description = "";
const items = [
  {
    title: "Legal Data",
    description,
  },
  {
    title: "Agreement",
    description,
  },
  {
    title: "Done",
    description,
  },
];

const widthMap = [600, 800, 800];

export const DPASignerModal = ({
  visible,
  onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const organization = useSelector(selectOrganization);

  const isSigned = organization?.data.dpa?.signed;

  const { doAction: editOrganization, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (edits) =>
        SDK.editCurrentOrganization(edits).then((d) => {
          return d;
        }),
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const [currentStep, setCurrentStep] = useState(isSigned ? 2 : 0);

  const [dpaInputData, setDpaInputData] = useState({} as any);

  const setSignerInputData = useCallback(
    (data) => {
      editOrganization({
        data: {
          dpa: {
            ...data,
          },
        },
      });
    },
    [editOrganization],
  );

  const [checked, setChecked] = useState(false);

  return (
    <Modal
      title={
        <ModalTitle
          title="Data Processing Agreement"
          icon={<i className="ri-file-paper-line"></i>}
        />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={widthMap[currentStep]}
      className="dpa-signer-modal mode_transition"
      footer={null}
      data-click-context="DPA Signer Modal"
    >
      <div className="">
        <Steps current={currentStep} labelPlacement="vertical" items={items} />
      </div>
      <Divider />
      {currentStep === 0 && (
        <>
          <DPAInput
            onContinue={(data) => {
              setSignerInputData(data);
              setDpaInputData(data);
              setCurrentStep(1);
            }}
          />
          <div className=""></div>
        </>
      )}
      {currentStep === 1 && (
        <>
          <div className="border border-gray-200 dark:border-gray-800 rounded-lg p-4">
            <ResetInnerCSS>
              <div className="text-left">
                <DataProcessingAgreement data={dpaInputData} />
              </div>
            </ResetInnerCSS>
          </div>

          <div className="flex flex-row mt-4 px-4">
            <Checkbox
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            >
              I have read and understood the Data Processing Agreement and I
              agree to the terms and conditions of the Data Processing Agreement
            </Checkbox>
          </div>

          <div className="flex flex-row justify-between items-center my-4">
            <Button
              type="link"
              icon={<i className="ri-arrow-left-line"></i>}
              onClick={() => {
                setCurrentStep(0);
              }}
            >
              Back
            </Button>
            <Button
              type="primary"
              icon={<i className="ri-ball-pen-line"></i>}
              onClick={() => {
                setSignerInputData({
                  signed: true,
                  signedTimestamp: Date.now(),
                });
                setCurrentStep(2);
              }}
              className="font-bold"
              disabled={!checked}
            >
              Sign
            </Button>
          </div>
        </>
      )}
      {currentStep === 2 && (
        <>
          <Result
            status="success"
            title="Completed"
            subTitle={`You have signed the data processing agreement on behalf of your company`}
            extra={[]}
          />

          <div className="flex flex-col justify-center items-center">
            <Button
              type="primary"
              onClick={() => onChangeVisibility(false)}
              className="mb-4"
            >
              Continue Workspace Setup
            </Button>
            <div className="flex flex-col justify-center items-center">
              <LazyDPADownloader />
            </div>
          </div>
        </>
      )}
      <DarkModeBg />
    </Modal>
  );
};
