import { Modal } from "antd";
import { DarkModeBg } from "dark-mode-bg";
import { KnowledgeBaseManageCollectionsInner } from "./knowledge-base-manage-collection";

export const KnowledgeBaseManageCollectionsModal = ({
  visible,
  onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  return (
    <Modal
      title={null}
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Manage Collections Modal"
      width={720}
    >
      <KnowledgeBaseManageCollectionsInner />
      <DarkModeBg />
    </Modal>
  );
};
