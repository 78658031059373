import { iEmailConnectionData } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Alert,
  Button,
  Collapse,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import { CollapsibleHeader } from "components/common/collapsible-configuration-panel/collapsible-header";
import { ModalTitle } from "components/common/modal-title";
import { ConnectionSelector } from "components/modules/conversations/components/connection-selector/connection-selector";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { selectCampaignById } from "store/modules/campaigns/campaigns.selectors";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { loadAllContactLists } from "store/modules/contact-lists/contact-lists.helpers";
import {
  selectAllContactLists,
  selectContactListById,
} from "store/modules/contact-lists/contact-lists.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { SendTestCampaignModal } from "./send-test-campaign/send-test-campaign-modal";

import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { getContactName } from "components/modules/crm/contacts/helpers/get-contact-name";
import { push } from "connected-react-router";
import { loadContactsQuery } from "store/modules/contacts/contacts.helpers";
import { selectContactsQuery } from "store/modules/contacts/contacts.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";

export const ConfigureCampaignModal = ({
  visible,
  onChangeVisibility,
  campaignId,
  mode,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  campaignId: string;

  mode?: "SEND" | "EDIT";
}) => {
  const [form] = Form.useForm();

  const campaign = useSelector(selectCampaignById(campaignId));

  const initialValues = useMemo(() => {
    if (!campaign) {
      return {};
    }
    return {
      label: campaign.label,
      type: campaign.type,
      list: campaign.to.listId,
      connectionId: campaign.sender?.connectionId,
      senderName: campaign.sender?.name,
      sendingEmail: campaign.sender?.email,
      emailEditorType: campaign.data?.emailEditorType,
      subjectLine: campaign.data?.subjectLine,
      previewLine: campaign.data?.previewLine,
    };
  }, [campaign]);

  useEffect(() => {
    // Without Set timeout, for some odd reason old value is shown
    setTimeout(() => {
      form.resetFields();
      setFormTouched(false);
    }, 100);
  }, [form, initialValues]);

  const {
    doAction: onEditCampaign,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (edits) =>
        SDK.campaigns
          .editById(campaignId, {
            ...edits,
          })
          .then((res) => {
            // onChangeVisibility(false);
            form.resetFields();
          }),
      successMessage: "Saved",
      failureMessage: "Something went wrong",
    }),
    [campaignId, form],
  );

  const { state: contactLists, retry: reload } = useQueryWithStore(
    selectAllContactLists,
    loadAllContactLists,
  );

  const [selectedConnectionId, setSelectedConnectionId] = useState(
    campaign?.sender?.connectionId,
  );

  const selectedConnection = useSelector(
    selectConnectionById(campaign?.sender?.connectionId),
  );

  const selectedContactList = useSelector(
    selectContactListById(campaign?.to?.listId!),
  );

  const [selectedListId, setSelectedListId] = useState(campaign?.to?.listId!);

  const query = useMemo(() => {
    return {
      query: {
        "marketingLists.id": { $in: selectedListId },
        "data.primaryEmail": { $exists: true, $nin: [null, ""] },
      },
      options: {
        sortBy: ["metaData.createdTime"],
        page: 1,
        limit: 20,
        sort: "metaData.createdTime",
      },
    };
  }, [selectedListId]);

  const { state: contactsQuery, isLoading: loadingListData } =
    useQueryWithStore(
      selectContactsQuery(JSON.stringify(query)),
      loadContactsQuery(query, JSON.stringify(query)),
      [query],
      !selectedListId,
    );

  const store = useStore();

  const goToSelectedList = useCallback(() => {
    const page = `/customers?contact-filter=((key:marketingList,operator:IS_IN,operatorConfig:(value:${selectedListId})))`;
    store.dispatch(push(page));
  }, [selectedListId, store]);

  const [isFormTouched, setFormTouched] = useState(false);

  const [sendTestModalState, setSendTestModalState] = useSimpleState({
    visibility: false,
    campaignId: "",
  });

  const { doAction: sendCampaign, isProcessing: isProcessingCampaign } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (campaignId: string) =>
          SDK.campaigns.sendCampaign(campaignId),
        successMessage: "Campaign has been queued",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const {
    doAction: enableEmailTracking,
    isProcessing: isEnablingEmailTracking,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (connectionId: string) =>
        SDK.connections.activateTracking(connectionId),
      // successMessage: "Campaign has been queued",
      failureMessage: "Something went wrong",
      actionDependencies: [],
    }),
    [],
  );

  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  const dispatch = useDispatch();

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-mail-send-fill"></i>}
          title={mode === "SEND" ? "Review and Send" : "Configure Campaign"}
        />
      }
      open={visible}
      footer={isFormTouched ? undefined : null}
      onOk={async () => {
        try {
          await form.validateFields();
          const {
            label,
            type,
            list,
            connectionId,
            senderName,
            sendingEmail,
            emailEditorType,
            subjectLine,
            previewLine,
          } = form.getFieldsValue();
          onEditCampaign({
            label,
            to: {
              type: "LIST",
              listId: list,
            },
            sender: {
              connectionId,
              name: senderName,
              email: sendingEmail,
            },
            data: {
              emailEditorType,
              subjectLine,
              previewLine,
            },
          });
        } catch (e) {
          message.error("Please check your input");
        }
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okButtonProps={{ icon: <i className="ri-save-line"></i> }}
      okText="Save Changes"
      data-click-context="Configure Campaign"
      destroyOnClose={true}
    >
      <div className="side-bar">
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          hideRequiredMark={true}
          className="bold-form-labels"
          preserve
          onValuesChange={() => {
            const isFieldsTouched = form.isFieldsTouched();
            // console.log("isFieldsTouched", isFieldsTouched);
            setFormTouched(isFieldsTouched);
            const marketingListId = form.getFieldValue(["list"]);
            // console.log("marketingListId", marketingListId);
            if (selectedListId !== marketingListId) {
              setSelectedListId(marketingListId);
            }
            const connectionId = form.getFieldValue(["connectionId"]);
            if (selectedConnectionId !== connectionId) {
              setSelectedConnectionId(connectionId);
            }
          }}
        >
          <Collapse className="w-full" accordion>
            <Collapse.Panel
              header={
                <CollapsibleHeader
                  icon={<i className={`ri-tools-line text-3xl`}></i>}
                  title={"Campaign Label"}
                  description={campaign?.label}
                />
              }
              key={"campaign-label"}
            >
              <Form.Item
                name="label"
                label="Name Your Campaign"
                rules={[
                  {
                    required: true,
                    message: "Please enter a campaign name",
                  },
                ]}
              >
                <Input placeholder="Label" />
              </Form.Item>
            </Collapse.Panel>

            <Collapse.Panel
              header={
                <div>
                  <CollapsibleHeader
                    icon={<i className={`ri-mail-send-line text-3xl`}></i>}
                    title={"Audience and Sender Name"}
                    description={`Sending to : ${selectedContactList?.data.label}`}
                  />
                  <div className="ml-12">
                    <div className="text-gray-600">
                      Sender Through : {selectedConnection?.label}
                    </div>
                    <div className="text-gray-600">
                      Sender Name : {campaign?.sender?.name}
                    </div>
                    <div className="text-gray-600">
                      Sender's Email : {campaign?.sender?.email}
                    </div>
                  </div>
                </div>
              }
              key={"audience"}
            >
              <Form.Item
                name="list"
                label="Marketing List"
                rules={[
                  {
                    required: true,
                    message: "Please select a marketing list",
                  },
                ]}
                help={
                  <div className="text-gray-600 text-sm">
                    Select the list you want to send this campaign to
                  </div>
                }
              >
                <Select>
                  {contactLists.map((list) => (
                    <Select.Option key={list.id} value={list.id!}>
                      {list.data.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {selectedListId && (
                <Spin
                  spinning={loadingListData}
                  indicator={<LoadingIndicatorWithSpin />}
                >
                  <div className="text-gray-600">
                    {contactsQuery.totalItems || 0} Customer(s) found{" "}
                    {(contactsQuery?.totalItems || 0) > 0 && (
                      <>
                        (Eg:{" "}
                        {contactsQuery.list
                          .slice(0, 5)
                          .map(
                            (contact) =>
                              `${getContactName(contact)} - ${
                                contact.data.primaryEmail
                              }`,
                          )
                          .join(", ")}
                        )
                      </>
                    )}
                    <span
                      className="cursor-pointer"
                      onClick={() => goToSelectedList()}
                    >
                      - View All
                    </span>
                  </div>
                </Spin>
              )}

              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const selectedOption = getFieldValue(["type"]);

                  if (selectedOption === "EMAIL") {
                    return (
                      <>
                        <Form.Item
                          name="connectionId"
                          label="Connection to Use"
                          rules={[
                            {
                              required: true,
                              message: "Please select a connection",
                            },
                          ]}
                          help={
                            <div className="text-gray-600 text-sm">
                              Select the email connection that will be used to
                              deliver the email.
                            </div>
                          }
                        >
                          <ConnectionSelector
                            connectionFilter={(connection) =>
                              connection.type === "EMAIL"
                            }
                            autoSelectFirstOption
                          />
                        </Form.Item>

                        {/* <Form.Item shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            const connectionId = getFieldValue([
                              "connectionId",
                            ]);
                            const connection = selectConnectionById(
                              connectionId
                            )(store.getState());
                            if (
                              (connection.data as iEmailConnectionData)
                            ) {
                              // Todo
                            }
                          }}
                        </Form.Item> */}

                        <Form.Item
                          name="senderName"
                          label="Sender Name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter a sender name",
                            },
                          ]}
                          help={
                            <div className="text-gray-600 text-sm">
                              Choose a name this list will recognize instantly
                              like the company’s name or the account manager’s
                              name.
                            </div>
                          }
                        >
                          <Input type="eg: Click Connector" />
                        </Form.Item>

                        <Form.Item
                          name="sendingEmail"
                          label="Sender's Email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter a sender email",
                            },
                          ]}
                          help={
                            <div className="text-gray-600 text-sm">
                              This Email will be visible to the receiver while
                              the email in “Connection to Use” is on the server
                              side and will not be seen by the receiver.
                            </div>
                          }
                        >
                          <Input type="eg: info@clickconnector.com" />
                        </Form.Item>
                      </>
                    );
                  }
                  return <></>;
                }}
              </Form.Item>
            </Collapse.Panel>

            <Collapse.Panel
              header={
                <div>
                  <CollapsibleHeader
                    icon={<i className={`ri-text text-3xl`}></i>}
                    title={"Subject & Preview Text"}
                    description={`Subject: ${
                      campaign?.data?.subjectLine || "Not Set"
                    }`}
                  />
                  <div className="ml-12">
                    <div className="text-gray-600">
                      {" "}
                      Preview Line : {campaign?.data?.previewLine || "Not Set"}
                    </div>
                  </div>
                </div>
              }
              key={"subject-line-label"}
            >
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const selectedOption = getFieldValue(["type"]);
                  const subjectLine = getFieldValue(["subjectLine"]) || "";
                  const previewLine = getFieldValue(["previewLine"]) || "";
                  if (selectedOption === "EMAIL") {
                    return (
                      <>
                        <Form.Item
                          name="subjectLine"
                          className="full-w-label"
                          label={
                            <div className="flex flex-row justify-between items-center w-full">
                              <div className="">Subject</div>
                              <div className="text-gray-600 flex flex-row justify-end items-center font-normal text-xs">
                                {subjectLine.length} Characters
                              </div>
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message:
                                "Please enter a subject line to continue. (You can edit this later)",
                            },
                          ]}
                          help={
                            <>
                              <div className="text-gray-600 flex flex-row justify-start items-center text-sm">
                                A great subject line gives your audience a
                                reason to open your email!
                              </div>
                              <div className=" text-gray-600 text-sm font-semibold">
                                Recommended Best Practices:
                              </div>
                              <ul className="list-disc ml-8 text-gray-600 mb-8 text-sm">
                                <li>
                                  Avoid using more than 60 characters or 9 words
                                </li>
                                <li>
                                  Avoid using more than 3 punctuation marks
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <Input
                            placeholder="Enter a subject line"
                            spellCheck={"true"}
                          />
                        </Form.Item>

                        <Form.Item
                          name="previewLine"
                          className="full-w-label"
                          label={
                            <div className="flex flex-row justify-between items-center w-full">
                              <div className="">Preview Text</div>
                              <div className="text-gray-600 flex flex-row justify-end items-center font-normal text-xs">
                                {previewLine.length} Characters
                              </div>
                            </div>
                          }
                          rules={[]}
                          help={
                            <div className="text-gray-600 text-sm">
                              Preview Text appears in the inbox, underneath the
                              Subject Line and provides a sneak peak into the
                              email.
                            </div>
                          }
                        >
                          <Input spellCheck={"true"} />
                        </Form.Item>
                        <div className="mb-12"></div>
                      </>
                    );
                  }
                  return <></>;
                }}
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Form>

        <div className="flex flex-row justify-center items-center p-4">
          <Space direction="vertical" align="center">
            <Tooltip
              title={isFormTouched ? "Save changes before previewing" : false}
            >
              <Button
                type="text"
                icon={<i className="ri-eye-line"></i>}
                disabled={isFormTouched}
                onClick={() => {
                  const win = window.open("", "Email Preview", "location=no");
                  if (win) {
                    win.document.documentElement.innerHTML = campaign.data.body;
                  }
                  // Modal.info({
                  //   title: (
                  //     <ModalTitle
                  //       title={message?.data?.subject || "Original Email"}
                  //       icon={<i className="ri-bill-line"></i>}
                  //     />
                  //   ),
                  //   icon: false,
                  //   content: (
                  //     <div style={{ width: 750, height: 600, overflow: "auto" }}>
                  //       <HtmlEmailRenderer message={message} />
                  //     </div>
                  //   ),
                  //   okText: "Close",
                  //   width: 800,
                  // });
                }}
              >
                Preview Email
              </Button>
            </Tooltip>
            <Tooltip
              title={isFormTouched ? "Save changes before sending test" : false}
            >
              <Button
                type="dashed"
                icon={<i className="ri-flask-line"></i>}
                disabled={isFormTouched}
                onClick={() => setSendTestModalState({ visibility: true })}
              >
                Send Test Email
              </Button>
            </Tooltip>

            {!(selectedConnection?.data as iEmailConnectionData)?.customDomain
              ?.data?.isMailBoxReady && (
              <div>
                <Alert
                  message="Setup your domain before sending campaigns"
                  description={
                    <div>
                      You have not set up custom domain yet
                      <Button
                        onClick={() => {
                          dispatch(
                            push(
                              `/configurations/connections/${selectedConnection.id}/mailbox?activeConfigs=Setup%20Custom%20Email%20Domain`,
                            ),
                          );
                        }}
                      >
                        Setup your domain
                      </Button>
                    </div>
                  }
                  type="warning"
                  showIcon
                />
              </div>
            )}

            {isAdvancedMode && (
              <Button
                onClick={() => {
                  enableEmailTracking(selectedConnection.id);
                }}
                loading={isEnablingEmailTracking}
                type="dashed"
              >
                Re-Enable Email Tracking
              </Button>
            )}

            {mode === "SEND" &&
              (selectedConnection?.data as iEmailConnectionData)?.customDomain
                ?.data?.isMailBoxReady && (
                <>
                  {!(selectedConnection?.data as iEmailConnectionData)
                    ?.customDomain?.trackingActivated && (
                    <Alert
                      message="Email Tracking is not activated yet"
                      description={
                        <div>
                          Email Tracking is required to produce campaign reports
                          <Button
                            onClick={() => {
                              enableEmailTracking(selectedConnection.id);
                            }}
                            loading={isEnablingEmailTracking}
                          >
                            Enable Email Tracking
                          </Button>
                        </div>
                      }
                      type="warning"
                      showIcon
                    />
                  )}

                  <Tooltip
                    title={
                      isFormTouched
                        ? "Save changes before sending campaign"
                        : false
                    }
                  >
                    <Button
                      type="primary"
                      size="large"
                      icon={<i className="ri-mail-send-line"></i>}
                      disabled={isFormTouched}
                      onClick={() => sendCampaign(campaignId)}
                      loading={isProcessingCampaign}
                    >
                      Send Campaign
                    </Button>
                  </Tooltip>
                </>
              )}
          </Space>

          <SendTestCampaignModal
            visible={sendTestModalState.visibility}
            onChangeVisibility={() =>
              setSendTestModalState({ visibility: false })
            }
            campaignId={campaignId}
          />
        </div>
      </div>
      <DarkModeBg />
    </Modal>
  );
};
