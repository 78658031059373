import { useCallback } from "react";
import Editor from "react-simple-wysiwyg";
import "./simple-wysiwyg-editor.scss";

export const SimpleWysiwyg = ({
  value,
  onChange: _onChange,
}: {
  value?: string;
  onChange?: (val: string) => any;
}) => {
  const onChange = useCallback(
    (e) => {
      _onChange && _onChange(e.target.value);
    },
    [_onChange],
  );
  return (
    <Editor
      value={value}
      onChange={onChange}
      containerProps={{
        className:
          "simple-wysiwyg-editor border border-gray-200 dark:border-gray-800 rounded-lg",
        style: { fontSize: "14px" },
      }}
    />
  );
};
