import React, { forwardRef, useImperativeHandle, useMemo } from "react";
import Frame, { FrameContextConsumer } from "react-frame-component";
import { MessageIFrame } from "utils/message-iframe";

// https://github.com/ryanseddon/react-frame-component
// https://stackoverflow.com/questions/1679507/getting-all-css-used-in-html-file
export const FrameWithCSS = ({ children }) => {
  const allCSS = [...(document.styleSheets as any)]
    .map(styleSheet => {
      try {
        return [...styleSheet.cssRules].map(rule => rule.cssText).join("");
      } catch (e) {
        console.log(
          "Access to stylesheet %s is denied. Ignoring...",
          styleSheet.href
        );
        return null;
      }
    })
    .filter(Boolean)
    .join("\n");

  return (
    <Frame
      initialContent={`<!DOCTYPE html><html><head><style>${allCSS}</style></head><body><div id="root"></div></body></html>`}
      mountTarget="#root"
      style={{ width: "100%", height: "100%" }}
    >
      <FrameContextConsumer>
        {// Callback is invoked with iframe's window and document instances
        ({ document, window }) => {
          // Render Children
        }}
      </FrameContextConsumer>
      {children}
    </Frame>
  );
};

export const FrameWithControls = forwardRef(({ url }: { url: string }, ref) => {
  const frameId = useMemo(() => new Date().getTime().toString(), []);
  const Controls = {
    sendMessage: (data: { type: string; data: any }) => {
      MessageIFrame(document.getElementById(frameId))(data);
    }
  };
  useImperativeHandle(ref, () => Controls);
  return (
    <iframe
      title={frameId}
      src={url}
      id={frameId}
      allowTransparency={true}
      style={{ width: "100%", height: "100%" }}
    ></iframe>
  );
});
