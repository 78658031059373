import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { iScheduledMessage } from "./scheduled-messages";

export class ScheduledMessagesService {
  constructor(protected config: { basePath: string; token: string }) {}

  /* ---------------------------------- Scheduled Messages --------------------------------- */

  scheduledMessages = Object.assign(
    new EntityServices<iScheduledMessage>(this.config, "scheduled-messages", {
      onCreate: () => {
        UserTracker.track("SCHEDULED_MESSAGES - Create", {});
      },
      onDelete: () => {
        UserTracker.track("SCHEDULED_MESSAGES - Delete", {});
      },
    }),
    {},
  );
}
