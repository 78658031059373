import FacebookOutlined from "@ant-design/icons/FacebookOutlined";
import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
import PoweroffOutlined from "@ant-design/icons/PoweroffOutlined";
import ProfileOutlined from "@ant-design/icons/ProfileOutlined";

import MailOutlined from "@ant-design/icons/MailOutlined";
import { Layout, Menu } from "antd";
import { push } from "connected-react-router";
import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import { logoutHQ } from "store/modules/hq/hq.actions";
import "./hq-layout.style.scss";
const { Sider } = Layout;

const mapDispatch = (dispatch) => ({});

export const HQLayout = (
  props: ReturnType<typeof mapDispatch> & {
    children: JSX.Element;
    banner?: JSX.Element;
  }
) => {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const onCollapse = (collapsed) => {
    console.log(collapsed);
    setIsCollapsed(collapsed);
  };

  const logout = () => {
    dispatch(logoutHQ());
  };
  return (
    <>
      <Layout className="w-full h-full fixed" data-click-context="HQ">
        <Sider
          collapsible
          collapsed={isCollapsed}
          onCollapse={onCollapse}
          className="h-full"
        >
          <div className="h-full flex flex-col">
            <div className="my-3 flex flex-col items-center">
              <img src="/logo.png" className="w-16" alt="" />
            </div>

            <div className=" flex-grow flex flex-col justify-between  pb-5">
              <div>
                <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
                  <Menu.Item
                    key="1"
                    icon={<ProfileOutlined />}
                    onClick={() => {
                      dispatch(push("/hq/organizations"));
                    }}
                  >
                    Organizations
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    icon={<FileTextOutlined />}
                    onClick={() => {
                      dispatch(push("/hq/change-logs"));
                    }}
                  >
                    Change Logs
                  </Menu.Item>
                  <Menu.Item
                    key="3"
                    icon={<FacebookOutlined />}
                    onClick={() => {
                      dispatch(push("/hq/fb-event-log-browser"));
                    }}
                  >
                    FB Event Log Browser
                  </Menu.Item>
                  <Menu.Item
                    key="4"
                    icon={<MailOutlined />}
                    onClick={() => {
                      dispatch(push("/hq/email-event-log-browser"));
                    }}
                  >
                    Email Event Log Browser
                  </Menu.Item>
                </Menu>
              </div>
              <div className="flex flex-col items-center text-gray-300">
                <PoweroffOutlined className="text-2xl my-4" onClick={logout} />
                {/* <Switch defaultChecked className=" my-4 bg-gray-400" /> */}
              </div>
            </div>
          </div>
        </Sider>
        <PerfectScrollbar className="h-full w-full">
          <Layout className="site-layout">{props.children}</Layout>
        </PerfectScrollbar>
      </Layout>
    </>
  );
};
