import MailOutlined from "@ant-design/icons/MailOutlined";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Form, Input, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "store/modules/users/users.selectors";

export const SendTestCampaignModal = ({
  visible,
  onChangeVisibility,
  campaignId,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  campaignId: string;
}) => {
  const [form] = useForm();

  const user = useSelector(selectCurrentUser);

  const {
    doAction: sendTestCampaign,
    isProcessing: isEmailProcessing,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (email: string) =>
        SDK.campaigns.sendTest(campaignId, email),
      successMessage: "Test has been sent",
      failureMessage: "Something went wrong",
    }),
    [campaignId]
  );

  return (<>
    <Modal
      title={
        <ModalTitle
          title="Send Test"
          icon={<i className="ri-mail-send-fill"></i>}
        />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      footer={null}
      okText="Send"
      data-click-context="Send Test Campaign Modal"
    >
      <div className="email-me flex flex-col justify-center items-center">
        <Button
          type="primary"
          size="large"
          onClick={() => {
            sendTestCampaign(user.credentials.email);
          }}
          loading={isEmailProcessing}
        >
          Send test to my email
        </Button>
        <div className="text-sm text-gray-600 dark:text-gray-400 mode_transition">
          ({user.credentials.email})
        </div>
      </div>
      <Divider type="horizontal">or</Divider>
      <Form layout="vertical" form={form}>
        {/* Email */}
        <Form.Item
          name="email"
          hasFeedback
          validateFirst={true}
          rules={[{ type: "email" }]}
          label="Email"
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
            size="large"
            style={{ height: "2rem" }}
            addonAfter={
              <Button
                type="text"
                onClick={async () => {
                  try {
                    await form.validateFields();
                    sendTestCampaign(form.getFieldsValue().email);
                    // eslint-disable-next-line no-empty
                  } catch (e) {}
                }}
                loading={isEmailProcessing}
                style={{ height: "2rem" }}
              >
                Send Test
              </Button>
            }
          />
        </Form.Item>
      </Form>
      <DarkModeBg />
    </Modal>
  </>);
};
