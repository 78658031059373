import {
  iConnectorActionButton,
  iConnectorAPICallConfig,
  iConnectorArrayTable,
  iConnectorBlock,
  iConnectorContactSideBarData,
  iConnectorFrameBlock,
  iConnectorKeyValueTable,
  iConnectorSimpleTextData,
} from "@sdk/chat-connectors/chat-connectors.models";
import { Button } from "antd";
import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import {
  useChatConnectorAPIAction,
  useChatConnectorAPIConfig,
  useChatConnectorAPIData,
} from "components/pages/conversations/user-chat-connector-api";
import React, { Suspense, useMemo } from "react";

import { useSelector } from "react-redux";
import { selectChatConnectorById } from "store/modules/chat-connectors/chat-connectors.selectors";
import { toTitleCase } from "utils/to-title-case";
import { ReplaceTokens } from "utils/token-replacer";

const ReactMarkdown = React.lazy(() => import("react-markdown"));

export const ConnectorSideBar = ({
  conversationId,
  connectorId,
}: {
  conversationId: string;
  connectorId: string;
}) => {
  const connector = useSelector(selectChatConnectorById(connectorId));
  const connectorData = connector?.data as iConnectorContactSideBarData;
  if (!connector) {
    return <div>Connector not loaded</div>;
  }
  return (
    <div className="h-full flex flex-col">
      <div className="header font-bold p-4 border-b border-gray-200">
        {connectorData.header.title}
      </div>
      <div className="body my-4 flex-1">
        {connectorData.body.map((block) => (
          <ChatConnectorBlockRenderer
            block={block}
            key={block.id}
            conversationId={conversationId}
          />
        ))}
      </div>
    </div>
  );
};

export const ChatConnectorBlockRenderer = ({
  block,
  conversationId,
}: {
  block: iConnectorBlock;
  conversationId: string;
}) => {
  if (block.type === "HEADING") {
    return <div className="text-xl px-4">{block.label}</div>;
  }

  if (block.type === "TEXT") {
    return <div className="px-4">{block.label}</div>;
  }

  if (block.type === "MARKDOWN") {
    return (
      <div className="p-4">
        <Suspense fallback={<SuspenseLoadingIndicator />}>
          <ReactMarkdown>{block.label}</ReactMarkdown>
        </Suspense>
      </div>
    );
  }

  if (block.type === "IFRAME") {
    return <SimpleIframeBlock block={block} conversationId={conversationId} />;
  }

  if (block.type === "SIMPLE_TEXT_DATA") {
    return (
      <SimpleTextDataBlock block={block} conversationId={conversationId} />
    );
  }

  if (block.type === "SIMPLE_ARRAY_TABLE") {
    return <SimpleArrayTable block={block} conversationId={conversationId} />;
  }

  if (block.type === "SIMPLE_KEY_VALUE") {
    return (
      <SimpleKeyValueTableBlock block={block} conversationId={conversationId} />
    );
  }

  if (block.type === "SIMPLE_ACTION_BUTTON") {
    return (
      <SimpleActionButtonBlock block={block} conversationId={conversationId} />
    );
  }

  return <div>Un Identified Block</div>;
};

export const SimpleTable = ({
  columns,
  rows,
}: {
  columns: string[];
  rows: any[];
}) => {
  return (
    <table className="simple-table-style w-full">
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column} className="font-bold">
              {toTitleCase(column)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((item, index) => {
          return (
            <tr key={index}>
              {columns.map((column) => (
                <td key={column}>{item[column]}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const SimpleTextDataBlock = ({
  block,
  conversationId,
}: {
  block: iConnectorSimpleTextData;
  conversationId: string;
}) => {
  const { data, loading, hasError } = useChatConnectorAPIData(
    conversationId,
    block.url,
  );

  const text = useMemo(
    () => ReplaceTokens(block.textTemplate, data),
    [block.textTemplate, data],
  );

  // Todo: Handle has Error and Loading
  return <div className="p-4">{text}</div>;
};

export const SimpleArrayTable = ({
  block,
  conversationId,
}: {
  block: iConnectorArrayTable;
  conversationId: string;
}) => {
  const { data, loading, hasError } = useChatConnectorAPIData(
    conversationId,
    block.url,
  );
  const { columns, rows } = useMemo(() => {
    const sample = data?.[0] || {};
    return {
      columns: Object.keys(sample),
      rows: Array.isArray(data) ? data : [],
    };
  }, [data]);
  // Todo: Handle has Error and Loading
  return (
    <div className="p-4">
      <SimpleTable rows={rows} columns={columns} />
    </div>
  );
};

export const SimpleKeyValueTableBlock = ({
  block,
  conversationId,
}: {
  block: iConnectorKeyValueTable;
  conversationId: string;
}) => {
  const { data, loading, hasError } = useChatConnectorAPIData(
    conversationId,
    block.url,
  );
  const { columns, rows } = useMemo(() => {
    return {
      columns: ["Label", "Value"],
      rows: Object.keys(data || {}).map((key) => ({
        Label: key,
        Value: data[key],
      })),
    };
  }, [data]);
  // Todo: Handle has Error and Loading
  return (
    <div className="p-4">
      <SimpleTable rows={rows} columns={columns} />
    </div>
  );
};

export const SimpleActionButtonBlock = ({
  block,
  conversationId,
}: {
  block: iConnectorActionButton;
  conversationId: string;
}) => {
  const { data, loading, hasError, callAPI } = useChatConnectorAPIAction(
    conversationId,
    block.url,
    true,
  );

  return (
    <div className="p-4">
      <Button loading={loading} onClick={() => callAPI()}>
        {block.buttonLabel || "Action"}
      </Button>
    </div>
  );
};

export const SimpleIframeBlock = ({
  block,
  conversationId,
}: {
  block: iConnectorFrameBlock;
  conversationId: string;
}) => {
  const config = useChatConnectorAPIConfig(conversationId, block.url);

  if (!config.ready) {
    return <></>;
  }

  return (
    <div className="h-full w-full">
      <iframe
        title="connector-iframe"
        src={`${config.url}`}
        style={{
          width: "100%",
          height: block.height || "40vh",
          border: 0,
          background: "transparent",
        }}
      ></iframe>
    </div>
  );
};

export const GenerateUrlConfig = (
  apiConfig: iConnectorAPICallConfig,
  context: Record<string, any>,
) => {
  const urlParameters = {} as Record<string, string>;
  for (const key in apiConfig.urlParameters) {
    urlParameters[key] = ReplaceTokens(
      apiConfig.urlParameters[key].value,
      context,
    );
  }
  const urlString = ReplaceTokens(apiConfig.url, urlParameters);
  try {
    const url = new URL(
      urlString.includes("https://") ? urlString : `https://${urlString}`,
    );

    for (const key in apiConfig.queryParaMeters) {
      url.searchParams.append(
        key,
        ReplaceTokens(apiConfig.queryParaMeters[key].value, context),
      );
    }
    const body = {} as any;
    for (const key in apiConfig.body) {
      body[key] = ReplaceTokens(apiConfig.body[key].value, context);
    }
    const headers = {} as any;
    for (const key in apiConfig.headers) {
      headers[key] = ReplaceTokens(apiConfig.headers[key].value, context);
    }
    return {
      url: url.toString(),
      body: body,
      headers,
    };
  } catch (e) {
    return {
      url: "INVALID_URL",
      body: {},
      headers: {},
    };
  }
};
