import { LightenDarkenColor } from "utils/color-utils";

export const GenerateColorTheme = (themeColor: string, textColor: string) => {
  return /*css*/ `
      .primary-button {
        background: ${themeColor};
        color:#fff;
      }
      .primary-button:hover{
        background: ${LightenDarkenColor(themeColor, 10)};
      }

      .secondary-button {
        background: ${LightenDarkenColor(themeColor, 210)};
        color: ${themeColor};
      }
      .secondary-button:hover{
        background: ${LightenDarkenColor(themeColor, 200)};
      }

      .quick-reply-pill {
        border: 2px solid ${themeColor};
        color: ${themeColor}
      }

      .quick-reply-pill:hover {
        background: ${LightenDarkenColor(themeColor, 200)};
      }

      .primary-bg {
        background: ${themeColor};
      }

      .article-collection-heading {
        background: ${LightenDarkenColor(themeColor, 200)};
        color: ${themeColor}
      }
    

      .chat-messages .message-row.contact .bubble{
        color: rgba(0, 0, 0, 0.8);
        background-color: ${
          LightenDarkenColor(themeColor, 210) === "#ffffff"
            ? "#f6f6f6"
            : LightenDarkenColor(themeColor, 210)
        };
      }

      .chat-messages .message-row.me .bubble{
        color:#fff;
        background-color: ${themeColor};
      }


      .primary-gradient-bg {
        background: ${themeColor};
        background: radial-gradient(${themeColor}, ${LightenDarkenColor(
    themeColor,
    -20
  )});
      }

      .primary-gradient-bg-2 {
        background: ${themeColor};
        background: radial-gradient(${themeColor}, ${LightenDarkenColor(
    themeColor,
    -40
  )});
      }

      .switch {
        --thumb-color-off:${LightenDarkenColor(themeColor, 150)};
        --thumb-color-on:${themeColor};
        --color-bg-on: ${LightenDarkenColor(themeColor, 215)};
      }
      .switch__gfx{
        width:35px;
      }
      
  `;
};
