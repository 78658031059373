import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Space, Spin } from "antd";
import { HelpCenterPromptButton } from "components/common/help-center/embeddable-components/help-center-prompt-button";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { useCallback, useEffect } from "react";
import { useStore } from "react-redux";
import { loadAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.helpers";
import { selectAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { InsiderFeedbackCollector } from "../home/insider-feedback-collector/insider-feedback-collector";
import { AddSelfServicePortalModal } from "./add-self-service-portals-modal";
import { ConfigureSelfServicePortalModal } from "./configure-self-service-portals-modal";
import { generateDefaultKBConfig } from "./genereate-default-kb-config";
import { KnowledgeBaseManageCollectionsInner } from "./knowledge-base-manage-collection";

const KnowledgeBasePage = () => {
  // Self Service Portals
  const { state: selfServicePortals } = useQueryWithStore(
    selectAllSelfServicePortals,
    loadAllSelfServicePortals,
  );

  const defaultPortal = selfServicePortals[0];

  const [addSelfServicePortalModalState, setAddSelfServicePortalState] =
    useSimpleState({
      visibility: false,
    });

  const [
    configureSelfServicePortalModalState,
    setConfigureSelfServicePortalState,
  ] = useSimpleState({
    visibility: false,
    portalId: "",
  });

  const { changePanelState } = useModalPanels();

  const {
    doAction: onAddSelfServicePortal,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.selfServicedPortals
          .create({
            ...values,
          })
          .then((res) => {
            setConfigureSelfServicePortalState({
              visibility: true,
              portalId: res.id,
            });
            return res;
          }),
      successMessage: "Portal has been created",
      failureMessage: "Something went wrong",
    }),
    [setConfigureSelfServicePortalState],
  );

  const { doAction: deleteSelfServicePortal } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (id) => SDK.selfServicedPortals.deleteById(id),
      // successMessage: "Portal has been created",
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const store = useStore();

  const createSelfServicePortal = useCallback(
    async (req) => {
      const config = await generateDefaultKBConfig(store);
      onAddSelfServicePortal({
        ...req,
        data: config,
      });
    },
    [onAddSelfServicePortal, store],
  );

  useEffect(() => {
    if (defaultPortal) {
      if (defaultPortal.id && !defaultPortal.data) {
        // Auto Delete the portal
        deleteSelfServicePortal(defaultPortal.id).then((d) => {
          window.location.reload();
        });
      }
    }
  }, [defaultPortal, deleteSelfServicePortal]);

  return (
    // <SpacedPage
    //   data-click-context="Knowledge Base Page"
    //   className="flex-col home-page h-auto min-h-full animate fadeInLeftMin"
    // >
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin manage-knowledge-base">
      <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg mx-auto p-8 lg:p-12 my-4">
        <SectionHeader
          title={
            <div>
              Knowledge Base Portals{" "}
              <HelpCenterPromptButton
                type="ICON"
                text="Introduction to Knowledge Base Portals"
                description="Watch a quick video to learn about knowledge base portals."
                icon="QUESTION"
                articleId="fc89e0-bb8e5"
                className="my-4"
                disableAutoShow={true}
              />
            </div>
          }
          description="Self Service Portals are public pages that are hosted for you"
          icon={<i className="ri-window-line"></i>}
        />

        {defaultPortal && defaultPortal.data && (
          <div
            style={{
              backgroundColor: defaultPortal.data.appearance.primaryColor,
              height: 300,
              backgroundImage: defaultPortal.data.hero.pattern || undefined,
            }}
            className="flex flex-col justify-center items-center rounded-lg my-4 shadow-lg cursor-pointer"
            onClick={() => {
              setConfigureSelfServicePortalState({
                visibility: true,
                portalId: defaultPortal.id,
              });
            }}
          >
            <div className="flex-1 m-4 p-4 flex flex-row justify-end items-start w-full">
              <Space>
                <Button
                  type="dashed"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const domainConfig = defaultPortal.data.domain;
                    const url =
                      domainConfig.customDomainConfig?.domain &&
                      domainConfig.customDomainConfig?.isVerified &&
                      domainConfig.customDomainConfig?.isConfigured
                        ? `https://${domainConfig.customDomainConfig?.domain}`
                        : `https://${domainConfig.subdomain}.helpcenter.guide`;
                    const win = window.open(url, "_blank");
                    win && win.focus();
                  }}
                  icon={<i className="ri-eye-line"></i>}
                >
                  Preview
                </Button>

                <Button
                  type="dashed"
                  onClick={() => {
                    setConfigureSelfServicePortalState({
                      visibility: true,
                      portalId: defaultPortal.id,
                    });
                  }}
                  icon={<i className="ri-settings-2-line"></i>}
                >
                  Configure
                </Button>
              </Space>
            </div>
            <div className="text-6xl text-white font-bold mb-4">
              {defaultPortal.data.hero.title || "Knowledge Base"}
            </div>
            <div className="flex flex-row max-w-xl w-full mb-4">
              <div className="bg-white flex flex-row justify-center items-center pl-3 rounded-lg w-full">
                <i className="ri-search-line"></i>
                <div className="text-gray-600 flex-1 px-2">
                  {defaultPortal.data.hero.searchPlaceholder ||
                    "Search Knowledge Base..."}
                </div>
                <div
                  className="p-3 text-white rounded-lg font-bold m-2"
                  style={{
                    background: defaultPortal.data.appearance.primaryColor,
                  }}
                >
                  Search
                </div>
              </div>
            </div>
            <div
              className="bg-white rounded-t-lg max-w-3xl w-full"
              style={{ height: 30 }}
            ></div>
          </div>
        )}

        {!defaultPortal && (
          <Spin
            spinning={isProcessing}
            indicator={<LoadingIndicatorWithSpin />}
          >
            <>
              <div
                className="w-full my-4 p-4 border-dashed border border-gray-200 dark:border-gray-800 rounded-md hover:bg-gray-200 dark:hover:bg-gray-800 cursor-pointer flex flex-col justify-center items-center"
                style={{ height: 200 }}
                onClick={() => {
                  createSelfServicePortal({
                    label: "Default Public KB Portal",
                  });
                }}
              >
                <i className="ri-settings-2-line text-4xl"></i>
                <div className="text-2xl">
                  {" "}
                  Setup Your Knowledge Base Portal
                </div>
                <div className="text-gray-600">
                  Your Knowledge Portal is not configured yet
                </div>
              </div>

              <AddSelfServicePortalModal
                visible={addSelfServicePortalModalState.visibility}
                onChangeVisibility={(state) =>
                  setAddSelfServicePortalState({
                    visibility: state,
                  })
                }
                onCreated={() => {}}
              />
            </>
          </Spin>
        )}
        <Divider />
        <ConfigureSelfServicePortalModal
          visible={configureSelfServicePortalModalState.visibility}
          onChangeVisibility={(state) =>
            setConfigureSelfServicePortalState({
              visibility: state,
            })
          }
          portalId={configureSelfServicePortalModalState.portalId}
          onCreated={() => {}}
        />
        <KnowledgeBaseManageCollectionsInner />

        <InsiderFeedbackCollector
          text="How can we improve our Knowledge Base feature?"
          className="mt-4"
        />
      </SimpleCardSection>
      {/* </SpacedPage> */}
    </StyledScrollArea>
  );
};
export default KnowledgeBasePage;
