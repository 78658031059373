import { ContactAvatar } from "components/common/avatar/client-avatar";
import React from "react";
import { loadContactById } from "store/modules/contacts/contacts.helpers";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const SimpleContactCard = ({ contactId }: { contactId: string }) => {
  const { state: contact } = useQueryWithStore(
    selectContactById(contactId),
    loadContactById(contactId)(),
    [contactId],
    !contactId
  );
  console.log("contact", contact);

  return (
    <div className="flex flex-row w-full contact-profile h-full">
      <div className="section flex-1 overflow-y-auto">
        <div className="avatar-section flex flex-row justify-center items-center mt-12 mb-20">
          <div className="flex flex-col justify-center items-center">
            <div className="text-gray">{contactId}</div>
            <div className="edit-user-avatar">
              <ContactAvatar contactId={contactId} size={80} />
            </div>
            <div className="font-bold text-2xl">
              {contact?.data.firstName} {contact?.data.lastName}
            </div>
            <div className="font-bold">{contact?.data.primaryEmail}</div>
            <div className="font-bold">{contact?.data.primaryMobile}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
