import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, List, Space, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import { useState } from "react";

import classNames from "classnames";
import { useStore } from "react-redux";
import { loadTaskById } from "store/modules/tasks/tasks.helpers";
import { selectTaskById } from "store/modules/tasks/tasks.selectors";
import { selectUser } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { getUserName } from "../user-management/helpers/get-user-name";
import "./task-card.scss";
import { CommentReplyInput, TaskComment } from "./task-viewer/task-viewer";

export const TaskTypeIcon = ({ type }: { type?: string }) => {
  if (type === "MENTION") {
    return <i className="ri-at-line"></i>;
  }
  if (type === "MENTION_2") {
    return <i className="ri-chat-quote-line"></i>;
  }
  if (type === "ATTENTION_REQUIRED") {
    return <i className="ri-feedback-fill"></i>;
  }
  if (type === "NEW_CHAT") {
    return <i className="ri-chat-new-line"></i>;
  }
  return <i className="ri-task-line"></i>;
};

const comments: {
  author: string;
  avatar: string;
  content: any;
  datetime: string;
}[] = [
  {
    author: "Ruban",
    avatar: "https://joeschmoe.io/api/v1/random",
    content: "Hello World How are you?",
    datetime: "11/06/2022",
  },
];

export const TaskCard = ({ taskId }: { taskId: string }) => {
  const [isCollapsed, setCollapsed] = useState(true);
  const { state: task, hasError, isLoading } = useQueryWithStore(
    selectTaskById(taskId),
    loadTaskById(taskId)(),
    [taskId],
    !taskId
  );

  const { doAction: markAsDone, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (status: boolean) =>
        SDK.tasks.markAsDone(task?.id, status),
      successMessage: "Done",
      failureMessage: "Something went wrong",
      actionDependencies: [task?.id],
    }),
    [task?.id]
  );

  const store = useStore();

  if (isLoading || !task) {
    return <>Loading Task</>;
  }

  return (
    <div
      style={{ maxWidth: 700 }}
      className="task-card w-full border border-1 border-gray-200 rounded-md p-4 flex flex-col hover:bg-gray-100 dark:hover:bg-gray-900 mode_transition"
    >
      <div className="first-row flex flex-row w-full">
        <div className="description flex-1 flex flex-row items-center">
          <div className="icon  text-xl">
            <TaskTypeIcon />
          </div>
          <div
            className={classNames("label flex-1 font-bold px-2", {
              "line-through": task.isResolved,
            })}
          >
            {task.label}
          </div>
          <div className="status">
            {task.tags.map((item) => (
              <Tag key={item}>{item}</Tag>
            ))}
          </div>
        </div>
        {!task.isResolved ? (
          <Tooltip title="Mark as done">
            <div
              className="check-box cursor-pointer text-2xl"
              onClick={() => markAsDone(true)}
            >
              <i className="ri-checkbox-blank-circle-line icon"></i>
              <i className="ri-checkbox-circle-fill on-hover-icon"></i>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="Reopen Task">
            <div
              className="check-box cursor-pointer text-2xl"
              onClick={() => markAsDone(false)}
            >
              <i className="ri-arrow-up-circle-line icon"></i>
              <i className="ri-arrow-up-circle-fill  on-hover-icon"></i>
            </div>
          </Tooltip>
        )}
      </div>
      <div className="status-data text-gray-600">
        <Space>
          <div className="item">
            Created By:{" "}
            {getUserName(selectUser(task.createdBy)(store.getState()))}
          </div>
          <div className="item">
            Created At: {dayjs(task.createdAt).format("LL")}
          </div>
          <div className="item">{task.comments.length} Comments</div>
        </Space>
      </div>
      <div className="sub-comments">
        <div className="flex flex-row justify-center items-center">
          <Button type="link" onClick={() => setCollapsed(!isCollapsed)}>
            {isCollapsed ? (
              <i className="ri-arrow-drop-down-line text-3xl"></i>
            ) : (
              <i className="ri-arrow-drop-up-line text-3xl"></i>
            )}
          </Button>
        </div>
        {!isCollapsed && (
          <div>
            <List
              dataSource={task.comments}
              header={`${task.comments.length} ${
                task.comments.length > 1 ? "replies" : "reply"
              }`}
              itemLayout="horizontal"
              renderItem={(comment) => <TaskComment comment={comment} />}
            />
            <CommentReplyInput taskId={taskId} />
          </div>
        )}
      </div>
    </div>
  );
};
