import { iConnectionRoutingStrategy } from "@sdk/conversations/conversations.models";
import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { usePermissionToJoinChats } from "components/modules/conversations/helpers/use-permission-to-join-chat";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { loadAllChatBots } from "store/modules/chat-bots/chat-bots.helpers";
import { selectAllChatBots } from "store/modules/chat-bots/chat-bots.selectors";
import { loadAllChatConnectors } from "store/modules/chat-connectors/chat-connectors.helpers";
import { selectAllChatConnectors } from "store/modules/chat-connectors/chat-connectors.selectors";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { loadContactById } from "store/modules/contacts/contacts.helpers";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { loadConversationById } from "store/modules/conversations/conversations.helpers";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { LoadAllGroups } from "store/modules/groups/groups.helpers";
import { selectAllGroupsQuery } from "store/modules/groups/groups.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import {
  selectConnectionContextVisibility,
  selectConversationTags,
} from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const useChatContext = (conversationId) => {
  const isAdvancedMode = useSelector(selectIsAdvancedMode);
  const {
    state: conversation,
    retry: reloadConversation,
    hasError: conversationNotFound,
  } = useQueryWithStore(
    selectConversationById(conversationId),
    loadConversationById(conversationId)(),
  );

  const connection = useSelector(
    selectConnectionById(conversation?.connectionId),
  );

  const showConnectionContext = useSelector(selectConnectionContextVisibility);
  const currentUser = useSelector(selectCurrentUser);
  const isMember = _.find(conversation?.members || [], {
    userId: currentUser.id,
  });
  // const contact = useSelector(selectContactById(conversation?.contactId));
  const { state: contact } = useQueryWithStore(
    selectContactById(conversation?.contactId),
    loadContactById(conversation?.contactId)(),
    [conversation?.contactId],
    !conversation?.contactId,
  );
  const conversationTags = useSelector(selectConversationTags);

  const {
    state: { list: userGroups },
  } = useQueryWithStore(selectAllGroupsQuery, LoadAllGroups);

  const { hasPermissionToJoin } = usePermissionToJoinChats(
    connection,
    currentUser,
  );

  const { forceUserToTag, tagGroupToForce } = useMemo(() => {
    if (!connection?.data?.forceUserToTag) {
      return {
        forceUserToTag: false,
        tagGroupToForce: [],
      };
    }

    if (connection?.data?.forceUserToTagByTagGroup?.length > 0) {
      const existingTagGroups = conversation?.tags.map(
        (tag) =>
          _.find(conversationTags, { label: tag })?.tagGroup || "General Tags",
      );
      const missingTagGroup = _.without(
        connection?.data?.forceUserToTagByTagGroup,
        ...existingTagGroups,
      );
      return {
        forceUserToTag: missingTagGroup.length > 0,
        tagGroupToForce: missingTagGroup,
      };
    }

    if (conversation?.tags && conversation?.tags?.length > 0) {
      return {
        forceUserToTag: false,
        tagGroupToForce: [],
      };
    }

    return {
      forceUserToTag: true,
      tagGroupToForce: [],
    };
  }, [connection?.data, conversation?.tags, conversationTags]);

  const messagesCount = conversation?.metaData.totalMessages || 0;

  const isMessagingWindowExpired = (() => {
    if (
      conversation?.connectionType === "FACEBOOK" ||
      conversation?.connectionType === "INSTAGRAM"
    ) {
      return (
        conversation?.metaData.lastMessage.client + 7 * 24 * 60 * 60 * 1000 <
        Date.now()
      );
    }
    if (
      conversation?.connectionType === "WHATS_APP_CLOUD" ||
      conversation?.connectionType === "WHATS_APP_CLOUD_NATIVE"
    ) {
      return (
        conversation?.metaData.lastMessage.client + 24 * 60 * 60 * 1000 <
        Date.now()
      );
    }
    return false;
  })();

  const connectionRoutingStrategy = connection?.data
    ?.routingStrategy as iConnectionRoutingStrategy;

  const isBlockedForBidding = (() => {
    if (!conversation) {
      return false;
    }
    if (conversation.members.length > 0) {
      return false;
    }
    const isRoutingEnabled =
      connectionRoutingStrategy && connectionRoutingStrategy !== "NO_ROUTE";
    // Routing has not started
    if (
      isRoutingEnabled &&
      !conversation.metaData.routerMetaData &&
      conversation.metaData.createdAt + 2000 > Date.now()
    ) {
      return true;
    }
    if (conversation.metaData.routerMetaData) {
      if (
        conversation.metaData.routerMetaData.currentInvitedUser ===
        currentUser?.id
      ) {
        return false;
      }
      // if bid is active then block
      if (
        conversation.metaData.routerMetaData.isActive &&
        (conversation.metaData.routerMetaData.lastRoutingExpiry || 0) >
          Date.now()
      ) {
        return true;
      }
    } else {
      // Bidding is not there,
      if (
        isRoutingEnabled &&
        conversation.metaData.createdAt + 4000 > Date.now()
      ) {
        return true;
      }
    }
    return false;
  })();

  const userRole = useMemo(() => {
    const currentUserRole = getRoleFromPermission(currentUser?.permissions);
    return currentUserRole;
  }, [currentUser?.permissions]);

  const alreadyBided = isBlockedForBidding
    ? (conversation?.metaData.routerMetaData?.bidQueue || []).includes(
        currentUser?.id,
      )
    : false;

  const { state: chatConnectors, retry: reload } = useQueryWithStore(
    selectAllChatConnectors,
    loadAllChatConnectors(),
    [],
  );

  const { state: chatBots, retry: reloadChatBots } = useQueryWithStore(
    selectAllChatBots,
    loadAllChatBots(),
    [],
  );

  const chatConnectorActions = useMemo(
    () =>
      chatConnectors.filter(
        (connector) => connector.type === "CONVERSATION_ACTION",
      ),
    [chatConnectors],
  );

  return {
    isAdvancedMode,
    conversation,
    reloadConversation,
    conversationNotFound,
    connection,
    showConnectionContext,
    currentUser,
    isMember,
    contact,
    conversationTags,
    userGroups,
    hasPermissionToJoin,
    forceUserToTag,
    tagGroupToForce,
    messagesCount,
    isMessagingWindowExpired,
    connectionRoutingStrategy,
    isBlockedForBidding,
    userRole,
    alreadyBided,
    chatConnectors,
    chatBots,
    reloadChatBots,
    chatConnectorActions,
  };
};
