import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iConversation } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Space, Table, Tag } from "antd";
import { ConnectionTypeToLabelMap } from "banners/connection-issue-banner";
import { EditableCustomFieldWithModal } from "components/modules/conversations/components/chat-view-side-bar/components/custom-properties";
import { getContactName } from "components/modules/crm/contacts/helpers/get-contact-name";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import dayjs from "dayjs";
import _ from "lodash";
import { ConversationViewer } from "modal-registry";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectConnectionsMap } from "store/modules/connections/connections.selectors";
import { selectUsersMap } from "store/modules/users/users.selectors";
import {
  selectConversationCustomProperties,
  selectConversationTags,
} from "store/modules/workspace/workspace.selectors";
import "./conversation-table-view.scss";

export const ConversationsTableView = forwardRef(
  (
    {
      data,
      onEdited,
    }: {
      data: iConversation[];
      onEdited: (conversation: iConversation) => any;
    },
    ref,
  ) => {
    const connectionsMap = useSelector(selectConnectionsMap);
    const usersMap = useSelector(selectUsersMap);
    const conversationTags = useSelector(selectConversationTags);
    const conversationCustomProperties = useSelector(
      selectConversationCustomProperties,
    );
    const tagsByTagGroups = useMemo(
      () => _.groupBy(conversationTags, "tagGroup"),
      [conversationTags],
    );

    const { doAction: editCustomProperty } = useSDKActionWithDeps(
      () => ({
        action:
          (SDK) => (conversationId: string, property: string, value: any) =>
            SDK.editConversation(conversationId!, {
              customProperties: {
                [property]: value,
              },
            }).then((d) => {
              onEdited(d);
              return d;
            }),
        failureMessage: "Something went wrong",
      }),
      [],
    );

    const { changePanelState } = useModalPanels();

    const tableData = useMemo(() => {
      return data.map((item) => ({
        id: item.id,
        startedDay: dayjs(item.metaData.createdAt).format("YYYY-MM-DD"),
        lastContact: dayjs(item.metaData.lastMessage.timestamp).format(
          "YYYY-MM-DD",
        ),
        customerName: getContactName(item.contact as any),
        connectionLabel: connectionsMap?.[item.connectionId]?.label,
        medium: ConnectionTypeToLabelMap[item.connectionType],
        tags: item.tags,
        user: item.members.map((user) => getUserName(usersMap[user.userId])),
        // Tag Groups - Start
        ...(() => {
          const tagGroups = {};
          for (const tagGroup in tagsByTagGroups) {
            const matchedTags = item.tags.filter((tag) => {
              return _.find(tagsByTagGroups[tagGroup], { label: tag });
            });
            tagGroups[tagGroup === "undefined" ? "General" : tagGroup] =
              matchedTags;
          }
          return tagGroups;
        })(),
        // Tag Groups - End
        // Customer Properties - Start
        ...(() => {
          const customProperties = {};
          for (const customProperty of conversationCustomProperties) {
            customProperties[customProperty.label] =
              item.customProperties?.[customProperty.id];
          }
          return customProperties;
        })(),
        // Customer Properties - End
      }));
    }, [
      connectionsMap,
      conversationCustomProperties,
      data,
      tagsByTagGroups,
      usersMap,
    ]);

    const columns = useMemo(
      () => [
        {
          title: "Started Day",
          dataIndex: "startedDay",
          width: 120,
        },
        {
          title: "Last Contact",
          dataIndex: "lastContact",
          width: 120,
        },
        {
          title: "Customer Name",
          dataIndex: "customerName",
          width: 200,
        },
        {
          title: "Handling User",
          dataIndex: "user",
          width: 200,
        },
        {
          title: "Connection Label",
          dataIndex: "connectionLabel",
          width: 200,
        },
        {
          title: "Medium",
          dataIndex: "medium",
          width: 120,
        },
        {
          title: "Tags",
          dataIndex: "tags",
          width: 200,
          render: (tags: string[]) => (
            <span>
              <Space size="middle">
                {tags &&
                  tags.map((tag, index) => (
                    <Tag key={index.toString()}>{tag}</Tag>
                  ))}
              </Space>
            </span>
          ),
        },
        // Tag Groups - Start
        ...(() => {
          const tagGroupColumns: any[] = [];
          for (const tagGroup in tagsByTagGroups) {
            tagGroupColumns.push({
              title: `Tag Type - ${
                tagGroup === "undefined" ? "General" : tagGroup
              }`,
              dataIndex: tagGroup === "undefined" ? "General" : tagGroup,
              width: 200,
              render: (tags: string[]) => (
                <span>
                  <Space size="middle">
                    {tags &&
                      tags.map((tag, index) => (
                        <Tag key={index.toString()}>{tag}</Tag>
                      ))}
                  </Space>
                </span>
              ),
            });
          }
          return tagGroupColumns;
        })(),
        // Tag Groups - End
        ...(() => {
          const customPropertiesColumns: any[] = [];
          for (const customProperty of conversationCustomProperties) {
            customPropertiesColumns.push({
              title: customProperty.label,
              dataIndex: customProperty.label,
              width: 200,
              render: (value: any, record) => (
                <span>
                  <EditableCustomFieldWithModal
                    schema={customProperty}
                    value={value}
                    editCustomProperty={(property, value) =>
                      editCustomProperty(record.id, property, value)
                    }
                  />
                </span>
              ),
            });
          }
          return customPropertiesColumns;
        })(),
        {
          title: "",
          key: "action",
          width: 200,
          render: (text, record) => (
            <Space size="middle">
              <Button
                type="text"
                onClick={() => {
                  changePanelState(ConversationViewer, true, {
                    conversationId: record.id,
                  });
                }}
              >
                View Conversation
              </Button>
            </Space>
          ),
        },
      ],
      [
        changePanelState,
        conversationCustomProperties,
        editCustomProperty,
        tagsByTagGroups,
      ],
    );

    const tableWidth = useMemo(() => {
      return _.sumBy(columns, (item) => item.width || 100);
    }, [columns]);

    useImperativeHandle(
      ref,
      () => ({
        tableData,
      }),
      [tableData],
    );

    return (
      <>
        <div className="conversations-table-container">
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={tableData.length < 10 ? false : undefined}
            loading={false}
            scroll={{ x: tableWidth }}
            bordered={true}
            size="small"
            style={{ minHeight: 400 }}
          />
        </div>
      </>
    );
  },
);
