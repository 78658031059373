import { Card } from "antd";
import React, { useMemo } from "react";
import { vCardParser } from "utils/parse-v-card";

export const VCard = ({ data }: { data: string }) => {
  const vCardData = useMemo(() => {
    return vCardParser.parse(data);
  }, [data]);

  return (
    <>
      {vCardData.map((contact, index) => (
        <Card
          style={{ width: 300 }}
          cover={
            contact.photo ? (
              <img alt="Profile" src={contact.photo} />
            ) : (
              undefined
            )
          }
          key={index.toString()}
        >
          <Card.Meta
            title={contact.displayName}
            description={`
${contact.organization ? `${contact.organization}\n` : ""}${
              contact.email
                ? contact.email.map(email => email.value).join("\n") + "\n"
                : ""
            }${
              contact.telephone
                ? contact.telephone
                    .map(telephone => telephone.value)
                    .join("\n") + "\n"
                : ""
            }${
              contact.address
                ? contact.address
                    .map(address => address.valueInfo.LABEL)
                    .join("\n") + "\n"
                : ""
            }
`}
          />
        </Card>
      ))}
    </>
  );
};
