import { SDK } from "@sdk";
import dayjs from "dayjs";

import _ from "lodash";
import { useMemo } from "react";
import { useStore } from "react-redux";
import {
  selectAllUsers,
  selectUsersByUserGroup,
} from "store/modules/users/users.selectors";
import { selectConversationTags } from "store/modules/workspace/workspace.selectors";
import { useAsyncMemo } from "use-async-memo";
import { iReportWidget } from "../components/report-canvas/widget-canvas-models";
import { normalizeDataForGraph } from "../components/report-widget/utils/normalize-graph-data";
import {
  AggregateFunctionLabels,
  AggregateTargetLabels,
  DimensionProcessors,
  ReportLabelRenderers,
} from "./widget-label-mappers";

export const UseReportDataProcessor = (
  _rawData: any[],
  reportConfig: iReportWidget
) => {
  const store = useStore();

  const rawData = useMemo(() => {
    if (reportConfig.aggregateByPeriod) {
      return normalizeDataForGraph(
        _.orderBy(_rawData, ["startOfDay"], ["asc"]).map((e) => ({
          timestamp: e.startOfDay,
          ...e,
        })),
        // Todo: Got to test for  heatmap
        reportConfig.aggregateByPeriod as any,
        reportConfig.period![0].valueOf(),
        reportConfig.period![1].valueOf(),
        (timestamp, momentFormat) => ({
          date: dayjs(timestamp).format(momentFormat),
          count: 0,
        })
      ).map((item) => _.omit(item, "timestamp", "startOfDay", "startOfPeriod"));
    }
    // Todo:  Move this to schema
    if (reportConfig.reportViewConfig.groupByUserGroup) {
      const byUserGroup = selectUsersByUserGroup(store.getState());
      const allUsers = selectAllUsers(store.getState());
      const noGroupUsers = allUsers.filter(
        (item) => !item.userGroups || item.userGroups.length === 0
      );
      const reportByUser = _.keyBy(_rawData, "users");

      const userGroupReport = [
        {
          userGroup: "Empty",
          count: (() => {
            return _.sum(
              noGroupUsers.map((user) => reportByUser?.[user.id]?.count || 0)
            );
          })(),
        },
        ...Object.keys(byUserGroup).map((userGroupId) => ({
          userGroup: userGroupId,
          count: (() => {
            return _.sum(
              byUserGroup[userGroupId].map(
                (user) => reportByUser?.[user.id]?.count || 0
              )
            );
          })(),
        })),
      ].filter((item) => item.count);
      return userGroupReport;
    }
    return _rawData;
  }, [_rawData, reportConfig, store]);

  const preDimensions = useMemo(() => {
    const allDimensions = (() => {
      let dimensionList: string[] = [];
      for (const data of rawData) {
        dimensionList = [...dimensionList, ...Object.keys(data || {})];
      }
      return _.uniq(dimensionList);
    })();
    const groupingDimensions = _.without(allDimensions, "count");
    return groupingDimensions;
  }, [rawData]);

  const preProcessedData = useAsyncMemo(
    async () => {
      let processedData = rawData;

      for (const dimension of preDimensions) {
        if (DimensionProcessors[dimension]) {
          if (DimensionProcessors[dimension].preProcessor) {
            processedData = await DimensionProcessors[dimension].preProcessor!(
              processedData,
              SDK,
              store
            );
          }
        }
      }
      return processedData;
    },
    [preDimensions, rawData, store],
    []
  );

  const dimensions = useMemo(() => {
    const allDimensions = (() => {
      let dimensionList: string[] = [];
      for (const data of preProcessedData) {
        dimensionList = [...dimensionList, ...Object.keys(data || {})];
      }
      return _.uniq(dimensionList);
    })();
    const groupingDimensions = _.without(allDimensions, "count");
    return groupingDimensions;
  }, [preProcessedData]);

  const { processedData, hiddenDimension, idAttributes } = useMemo(() => {
    let hiddenDimension: string[] = [];
    let idAttributes: string[] = [];
    const processedData = preProcessedData.map((item) => {
      const recordItem = { count: item.count };
      for (const dimension of dimensions) {
        const propsToAdd = {};
        const rendererRecord = ReportLabelRenderers[dimension];
        if (rendererRecord && rendererRecord.inputTransformers) {
          const transformedValue = rendererRecord.inputTransformers(
            item[dimension],
            store
          );
          Object.assign(propsToAdd, transformedValue);
        } else {
          propsToAdd[dimension] = item[dimension];
        }
        if (rendererRecord && rendererRecord.hiddenAttributes) {
          hiddenDimension = [
            ...hiddenDimension,
            ...rendererRecord.hiddenAttributes!,
          ];
        }
        if (rendererRecord?.idAttributes) {
          idAttributes = [...idAttributes, ...rendererRecord.idAttributes];
        }
        Object.assign(recordItem, propsToAdd);
      }
      return recordItem;
    });
    return {
      processedData,
      hiddenDimension,
      idAttributes,
    };
  }, [preProcessedData, dimensions, store]);

  const postDimensions = useMemo(() => {
    const allDimensions = (() => {
      let dimensionList: string[] = [];
      for (const data of processedData) {
        dimensionList = [...dimensionList, ...Object.keys(data || {})];
      }
      return _.uniq(dimensionList);
    })();
    const groupingDimensions = _.without(allDimensions, "count");
    return groupingDimensions;
  }, [processedData]);

  // Todo: Move this to schema
  const filteredData = useMemo(() => {
    if (reportConfig.reportViewConfig.tagType && dimensions.includes("tags")) {
      const availableTags = selectConversationTags(store.getState());
      const tagTypeToFilter = reportConfig.reportViewConfig.tagType;
      return processedData.filter((item) => {
        const tag = item["tags"];
        const tagConfig = _.find(availableTags, { label: tag });
        if (tagTypeToFilter.includes(tagConfig?.tagGroup || "General Tags")) {
          return true;
        }
        return false;
      });
    }

    return processedData;
  }, [dimensions, processedData, reportConfig.reportViewConfig.tagType, store]);

  const { countKey, countLabel, countTemplate } = useMemo(() => {
    if (reportConfig.aggregateFunction && reportConfig.aggregateTargetField) {
      const label = `${
        AggregateFunctionLabels[reportConfig.aggregateFunction!]
      } ${AggregateTargetLabels[reportConfig.aggregateTargetField!].label}`;
      return {
        countKey: "count",
        countLabel: label,
        countTemplate:
          AggregateTargetLabels[reportConfig.aggregateTargetField!].template,
      };
    }
    if (reportConfig.aggregateFunction) {
      const label = `${
        AggregateFunctionLabels[reportConfig.aggregateFunction!]
      } no of conversation`;
      return { countKey: "count", countLabel: label };
    }
    return { countKey: "count", countLabel: "No of conversations" };
  }, [reportConfig.aggregateFunction, reportConfig.aggregateTargetField]);

  return {
    processedData: filteredData,
    preDimensions,
    dimensions,
    postDimensions,
    countKey,
    countLabel,
    countTemplate,
    hiddenDimension,
    idAttributes,
  };
};
