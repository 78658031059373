import { iConnection } from "@sdk/conversations/conversations.models";
import { processServerError, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Form, Select, message } from "antd";
import { useForm } from "antd/es/form/Form";
import {
  AddConnectionLayout,
  AddConnectionModalContainer,
  AddConnectionStepsContainer,
  IntegrationSuccessfulScreen,
  StepContainer,
} from "components/modules/connections/add-connection-modal-templates";
import { generateDefaultConnectionConfig } from "components/modules/connections/common-connection-settings/default-connection-config-generator";
import { ConnectionIcons } from "components/modules/connections/helpers/connection-icons";
import { push } from "connected-react-router";
import { useCallback, useState } from "react";

import { SDK } from "@sdk";
import {
  ExternalAuthCallbackData,
  ExternalAuthConnector,
} from "components/common/external-auth-connector-button/external-auth-connector-button";
import { useDispatch, useStore } from "react-redux";

export const AddWhatsappOfficialConnection = ({
  visible,
  onChangeVisibility: _onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const dispatch = useDispatch();
  const store = useStore();

  const [currentStep, setCurrentStep] = useState(0);
  const [createdConnection, setCreatedConnection] = useState("");

  const [pageSelectionForm] = useForm();

  const onChangeVisibility = useCallback(
    (visibility: boolean) => {
      _onChangeVisibility(visibility);
      setCurrentStep(0);
    },
    [_onChangeVisibility],
  );

  // Create Call

  const { doAction: completeIntegration, isProcessing: isActionProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (val) =>
          SDK.connections.create(val).then((d) => {
            setCreatedConnection(d.id!);
            setCurrentStep(2);
          }),
        successMessage: "Integration has been created",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const [form] = useForm();

  const [availableNumbers, setAvailableNumbers] = useState<
    { id: string; label: string }[]
  >([]);

  const onAddConnection = useCallback(async () => {
    try {
      await form.validateFields();
      const { instanceUrl, apiKey } = form.getFieldsValue();
      const configData = generateDefaultConnectionConfig(
        "WHATS_APP_OFFICIAL",
        store,
      );
      const connectionData: Partial<iConnection> = {
        type: "WHATS_APP_OFFICIAL",
        label: "Whatsapp",
        userGroups: [],
        data: {
          instanceUrl,
          apiKey,
          ...configData,
        },
      };
      completeIntegration(connectionData);
    } catch (e) {
      // Validation Failed; Do nothing
      message.error("Please check your input");
    }
  }, [completeIntegration, form, store]);

  const onAuthenticated = useCallback(({ d360 }: ExternalAuthCallbackData) => {
    console.log("d360", d360);
    SDK.whatsAppOfficial
      .getConnectedAccounts(d360?.channels || [])
      .then((d) => {
        // Todo
        setAvailableNumbers(
          d.map((page) => ({
            id: page.id,
            label: page.name,
          })),
        );
        setCurrentStep(1);
      })
      .catch((e) => {
        message.error(processServerError(e, "Something went wrong"));
      });
  }, []);

  return (
    <>
      <AddConnectionModalContainer
        title="Connect Whatsapp"
        icon={<i className="ri-whatsapp-line"></i>}
        isVisible={visible}
        onChangeVisibility={onChangeVisibility}
      >
        <AddConnectionLayout
          currentStep={currentStep}
          totalSteps={2}
          stepsWithSidebars={[0, 1]}
          topBar={
            <AddConnectionStepsContainer
              currentStep={currentStep}
              steps={[
                {
                  label: "Begin",
                  icon: <i className="ri-flag-line"></i>,
                },
                {
                  label: "Setup Account",
                  icon: <i className="ri-stack-line"></i>,
                },
                {
                  label: "Done",
                  icon: <i className="ri-check-line"></i>,
                },
              ]}
            />
          }
          sidebarImage={
            <div className="text-10xl opacity-50">
              <i className={ConnectionIcons.WHATS_APP_PRIVATE}></i>
            </div>
          }
        >
          <StepContainer stepNumber={0} currentStep={currentStep}>
            <div className="description text-gray-600 dark:text-gray-400 mb-4 mode_transition">
              We have partnered with 360Dialog to bring you Whatsapp Business
              Access. You'll be onboard with 360 Dialog to setup your Whatsapp
              Business Account.
            </div>

            <div className="flex flex-row justify-center items-center gap-4 mt-4">
              <img
                src={
                  "https://www.360dialog.com/wp-content/uploads/2021/03/Company_logo.png"
                }
                alt=""
                style={{ width: 120 }}
              />
              <img
                src={
                  "https://www.360dialog.com/wp-content/uploads/2022/02/mbp-badge.png"
                }
                alt=""
                style={{ width: 120 }}
              />
            </div>

            <div className="mt-4 flex justify-center items-center">
              <ExternalAuthConnector
                type="D360"
                onAuthenticated={onAuthenticated}
                label="Begin Authentication"
              />
            </div>
          </StepContainer>

          <StepContainer stepNumber={1} currentStep={currentStep}>
            <div className="description text-gray-600 dark:text-gray-400 mb-4 mode_transition text-center">
              Please select the whatsapp number that you want to integrate
            </div>

            <Form layout="vertical" form={pageSelectionForm} initialValues={{}}>
              {/* Welcome Message */}
              <Form.Item
                label="Whatsapp Number to Connect"
                name="channelId"
                rules={[
                  {
                    required: true,
                    message: "Please enter an account",
                  },
                ]}
              >
                <Select size="large">
                  {availableNumbers.map((page) => (
                    <Select.Option key={page.id} value={page.id}>
                      {page.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>

            <Button
              type="text"
              icon={<i className="ri-arrow-right-line"></i>}
              size="large"
              onClick={() => onAddConnection}
            >
              Continue
            </Button>
          </StepContainer>

          <StepContainer stepNumber={2} currentStep={currentStep}>
            <IntegrationSuccessfulScreen
              onManageConnection={() => {
                dispatch(
                  push(`/configurations/connections/${createdConnection}/`),
                );
              }}
              onHideWizard={() => onChangeVisibility(false)}
              subTitle="Now you will be able to reply to Whatsapp messages from Click Connector"
            ></IntegrationSuccessfulScreen>
          </StepContainer>
        </AddConnectionLayout>
      </AddConnectionModalContainer>
    </>
  );
};
