import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { SDK } from "@sdk";
import { iHolidayQuote } from "@sdk/holiday-quotes/holiday-quotes-model";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Segmented,
  Select,
  Space,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { LazyQuillEditor } from "components/common/quill-editor/lazy-quill-editor";
import { WithAttachments } from "components/pages/bot-builder/node-types/message-node/with-attachments";
import { DarkModeBg } from "dark-mode-bg";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { CountryCodes } from "utils/country-codes";
import { cleanPNRString } from "../pnr-converter/helpers/clean-pnr-input";
import { FlightItineraryTemplateTypes } from "../pnr-converter/pnr-converter";
import { PlaceSelector } from "./components/google-places-auto-complete";
import { WhatsIncludedIconMap } from "./components/whats-included-block";
import { roomOptions } from "./helpers/hotel-room-options";
import { flightDetailsInputMethod } from "./holiday-quote-creator";
import {
  iDestinationSummaryBlock,
  iHotelItineraryItem,
  iItineraryBlock,
} from "./itineries-model";
import { NameTitleOptions } from "./name-title-options";

export const DefaultHotelInclusionOptions = [
  {
    value: "ALL",
    label: "Includes all of the mentioned Hotels",
  },
  {
    value: "ANY",
    label: "Includes any one of the mentioned Hotels",
  },
];

export const QuoteBlockEditorModal = ({
  visible,
  onChangeVisibility,
  onSave,
  data,
  holidayQuote,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onSave: (content: iItineraryBlock) => any;
  data: iItineraryBlock;
  holidayQuote: iHolidayQuote;
}) => {
  const [form] = useForm();
  const [isProcessing, setProcessing] = useState(false);

  const initialValues = useMemo(() => {
    if (data?.type === "HOTEL_ITEM") {
      return {
        ...data.data,
        checkIn: dayjs(data.data.checkIn),
      };
    }
    if (data?.type === "PASSENGER_DETAILS") {
      if (!data.data.passengers) {
        const dataToReturn = {
          ...data.data,
          passengers: [
            {
              dateOfBirth: undefined,
              surName: "",
              firstName: "",
              title: "",
            },
          ],
        };
        console.log("dataToReturn", dataToReturn);
        return dataToReturn;
      }
      const dataToReturn = {
        ...data.data,
        passengers: (data.data.passengers || []).map((item) => ({
          ...item,
          dateOfBirth: dayjs(item.dateOfBirth),
        })),
      };
      console.log("dataToReturn", dataToReturn);
      return dataToReturn;
    }
    if (data?.type === "DESTINATION_SUMMARY_BLOCK") {
      return {
        ...data.data,
        // destination: data?.data?.destination?.name,
      };
    }
    return data?.data;
  }, [data?.data, data?.type]);

  const title = useMemo(() => {
    switch (data.type) {
      case "HOTEL_ITEM": {
        return "Edit Hotel Details";
      }
      case "FINE_PRINT_ITEM": {
        return "Edit Fine Prints";
      }
      case "FLIGHT_ITINERARY": {
        return "Edit Flight Details";
      }
      case "PASSENGER_DETAILS": {
        return "Edit Passenger Details";
      }
      case "PRICE_SUMMARY": {
        return "Edit Price";
      }
      case "SIMPLE_DETAILS": {
        return "Edit Details";
      }
    }
    return "Edit Quote";
  }, [data?.type]);

  const organization = useSelector(selectOrganization);
  const attachmentRefs = useRef<any>();

  const handleOnPaste = useCallback((event: ClipboardEvent) => {
    const items = (
      event.clipboardData || (event as any).originalEvent.clipboardData
    ).items;
    let blob = null;

    for (const item of items) {
      if (item.type.indexOf("image") === 0) {
        blob = item.getAsFile();
      }
    }

    // load image if there is a pasted image
    if (blob !== null) {
      attachmentRefs.current.onDrop([
        new File([blob], `clipboard-${new Date().getTime()}.png`, {
          type: "image/png",
        }),
      ]);
    }
  }, []);

  useEffect(() => {
    if (data.type === "FLIGHT_ITINERARY") {
      window.addEventListener("paste", handleOnPaste, false);
      return function cleanup() {
        window.removeEventListener("paste", handleOnPaste, false);
      };
    }
  }, [data?.type, handleOnPaste]);

  const { currency, currencySymbol } = useMemo(() => {
    const country = organization?.data.country || "GB";
    const countryData = CountryCodes.find((item) => item.code === country);
    if (!countryData?.currencyCode) {
      return {
        currency: "GBP",
        currencySymbol: "£",
      };
    }
    return {
      currency: countryData?.currencyCode || "GBP",
      currencySymbol: countryData?.currencySymbol,
    };
  }, [organization?.data?.country]);

  const hotelInclusionOptions = useMemo(() => {
    const hotelBlocks = holidayQuote.data.blocks.filter(
      (item) => item.type === "HOTEL_ITEM",
    );
    return [
      ...DefaultHotelInclusionOptions,
      ...hotelBlocks.map((hotelBlock: iHotelItineraryItem) => ({
        value: hotelBlock.id,
        label: hotelBlock.data.hotel.name,
      })),
    ];
  }, [holidayQuote.data.blocks]);

  return (
    <Modal
      title={
        <ModalTitle title={title} icon={<i className="ri-bill-line"></i>} />
      }
      open={visible}
      okText={"Save"}
      onOk={async () => {
        try {
          await form.validateFields();
          const formValues = form.getFieldsValue();
          console.log("formValues", formValues);
          setProcessing(true);
          switch (data.type) {
            case "FLIGHT_ITINERARY": {
              const {
                pnr,
                itineraryStyle,
                flightDetailsInputMethod,
                flightDetailsScreenshots,
              } = formValues;
              if (flightDetailsInputMethod === "MANUAL") {
                await onSave({
                  ...data,
                  data: {
                    flightDetailsInputMethod,
                    flightDetailsScreenshots,
                  },
                });
              } else {
                const pnrResponse = await SDK.pnrConverter({
                  pnr: cleanPNRString(pnr),
                });
                await onSave({
                  ...data,
                  data: {
                    flightDetailsInputMethod,
                    flightData: pnrResponse.flightData,
                    pnr,
                    itineraryStyle,
                  },
                });
              }

              break;
            }
            case "HOTEL_ITEM": {
              const hotelData = formValues as iHotelItineraryItem["data"];
              console.log("hotelData", hotelData);
              await onSave({
                ...data,
                data: {
                  ...hotelData,
                  placeId: hotelData.hotel.place_id,
                },
              });
              break;
            }
            case "DESTINATION_SUMMARY_BLOCK": {
              const destinationData =
                formValues.destination as iDestinationSummaryBlock["data"]["destination"];
              console.log("destinationData", destinationData);
              if (!destinationData) {
                message.error("Please select a destination");
              } else {
                const res = await SDK.getWeatherUrl(destinationData.place_id);
                console.log("res", res);
                await onSave({
                  ...data,
                  data: {
                    destination: destinationData,
                    placeId: destinationData.place_id,
                    name: destinationData.name,
                    label: destinationData.formatted_address,
                    weatherUrl: res.path,
                  },
                });
              }

              break;
            }
            default: {
              await onSave({
                ...data,
                data: formValues,
              });
              break;
            }
          }

          onChangeVisibility(false);
          setProcessing(false);
        } catch (e) {
          setProcessing(false);
          message.error("Please check your input");
        }
        // Todo
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okButtonProps={{ loading: isProcessing }}
      width={600}
      data-click-context="Quote Block Editor  Modal"
    >
      {data && (
        <div className="bold-form-labels">
          <Form
            layout="vertical"
            form={form}
            initialValues={initialValues}
            className="bold-form-titles"
          >
            {data.type === "FLIGHT_ITINERARY" && (
              <>
                <Form.Item name="flightDetailsInputMethod" label={""}>
                  <Segmented
                    options={flightDetailsInputMethod}
                    block
                    className="w-full"
                    defaultValue="PNR"
                  />
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const type = getFieldValue(["flightDetailsInputMethod"]);
                    if (type === "MANUAL") {
                      return (
                        <>
                          <Form.Item
                            name={["flightDetailsScreenshots"]}
                            valuePropName="initialAttachments"
                            trigger={"onAttachmentsChange"}
                          >
                            <WithAttachments
                              id={`HOLIDAY_QUOTE_CREATOR`}
                              withoutBorder={true}
                              entityType="HOLIDAY_QUOTE"
                              entityId={organization?.id!}
                              ref={attachmentRefs}
                            >
                              <div className="text-gray-600 text-center border-2 rounded-lg p-4 border-dashed border-gray-600">
                                Drag your images here to upload attachments here
                                <br />
                                or just paste your image
                              </div>
                            </WithAttachments>
                          </Form.Item>
                        </>
                      );
                    }
                    return (
                      <>
                        <Form.Item
                          name="pnr"
                          label={"Flight PNR"}
                          rules={[
                            {
                              required: true,
                              message: "Please enter a PNR",
                            },
                          ]}
                        >
                          <Input.TextArea
                            placeholder="Your Input PNR"
                            rows={6}
                          />
                        </Form.Item>
                        <Form.Item
                          name={["itineraryStyle"]}
                          label={"Flight Itinerary Style"}
                          rules={[
                            {
                              required: true,
                              message: "Please enter a PNR",
                            },
                          ]}
                        >
                          <Select
                            style={{ minWidth: 150 }}
                            defaultValue={"Elegant"}
                          >
                            {FlightItineraryTemplateTypes.map((item) => (
                              <Select.Option
                                value={item.value}
                                key={item.value}
                              >
                                {item.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.Item>
              </>
            )}

            {data.type === "HOTEL_ITEM" && (
              <>
                <div className="flex flex-col">
                  <Form.Item
                    name={["hotel"]}
                    label="Hotel"
                    rules={[{ required: true, message: "Missing Hotel" }]}
                  >
                    <PlaceSelector type="lodging" placeholder="Select Hotel" />
                  </Form.Item>
                  <Form.Item
                    name={["checkIn"]}
                    label="Check In"
                    rules={[
                      {
                        type: "object" as const,
                        required: true,
                        message: "Please select a date!",
                      },
                    ]}
                  >
                    <DatePicker placeholder="Check in" className="w-full" />
                  </Form.Item>
                  <Form.Item
                    name={["noOfNights"]}
                    rules={[{ required: true, message: "Fill this" }]}
                    label="Stay Duration"
                  >
                    <InputNumber
                      min={1}
                      max={10}
                      className="w-full"
                      placeholder="Nights"
                      addonAfter="Nights"
                    />
                  </Form.Item>

                  <Form.Item
                    name={["roomType"]}
                    rules={[{ required: true, message: "Room Type" }]}
                    label="Stay Type"
                  >
                    <Select placeholder="Room Type" options={roomOptions} />
                  </Form.Item>

                  <Form.Item
                    name={["notes"]}
                    className="flex-1"
                    label="Additional Notes"
                  >
                    <Input placeholder="Additional Notes" className="flex-1" />
                  </Form.Item>
                </div>
              </>
            )}

            {data.type === "FINE_PRINT_ITEM" && (
              <>
                <Form.List
                  name="finePrints"
                  rules={[
                    {
                      validator: async (_, names) => {
                        if (!names || names.length < 1) {
                          return Promise.reject(new Error("At least 1 item"));
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      <div className="font-bold my-4">Fine Prints</div>
                      {fields.map((field, index) => (
                        <Form.Item
                          required={false}
                          key={field.key}
                          className="my-2"
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message:
                                  "Please input something or delete this item.",
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              placeholder="Fine print ..."
                              style={{ width: "90%" }}
                            />
                          </Form.Item>
                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button px-2"
                              onClick={() => remove(field.name)}
                            />
                          ) : null}
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                          block
                        >
                          Add a new item
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </>
            )}
            {data.type === "DESTINATION_SUMMARY_BLOCK" && (
              <>
                <Form.Item
                  name={["destination"]}
                  label={"Destination"}
                  rules={[
                    {
                      required: true,
                      message: "Please pick a destination",
                    },
                  ]}
                >
                  <PlaceSelector type="cities" placeholder="Select a City" />
                </Form.Item>
              </>
            )}
            {data.type === "PASSENGER_DETAILS" && (
              <>
                <Form.List
                  name="passengers"
                  rules={
                    [
                      // {
                      //   validator: async (_, names) => {
                      //     if (!names || names.length < 2) {
                      //       return Promise.reject(new Error("At least 2 passengers"));
                      //     }
                      //   },
                      // },
                    ]
                  }
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "title"]}
                            rules={[
                              { required: true, message: "Missing Title" },
                            ]}
                          >
                            <Select
                              placeholder="Title"
                              options={NameTitleOptions}
                              style={{ minWidth: 50 }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "firstName"]}
                            rules={[
                              { required: true, message: "Missing first name" },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "surName"]}
                            rules={[
                              { required: true, message: "Missing Surname" },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                          <Form.Item
                            name={[name, "dateOfBirth"]}
                            rules={[
                              {
                                type: "object" as const,
                                required: true,
                                message: "Please select DOB!",
                              },
                            ]}
                          >
                            <DatePicker placeholder="D.O.B" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{ width: "60%" }}
                          icon={<PlusOutlined />}
                          className="w-full"
                        >
                          Add Passenger Details
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </>
            )}
            {data.type === "MAP_BLOCK" && (
              <>
                <Form.Item
                  name={["placeObj"]}
                  label={"Location"}
                  rules={[
                    {
                      required: true,
                      message: "Please pick a location",
                    },
                  ]}
                >
                  <PlaceSelector
                    type="establishment"
                    placeholder="Select a City"
                  />
                </Form.Item>
              </>
            )}
            {data.type === "PRICE_SUMMARY" && (
              <>
                <Form.Item
                  name="price"
                  label={"Total Price"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a value",
                    },
                  ]}
                >
                  <InputNumber
                    prefix={currencySymbol || currency}
                    min={1}
                    className="w-full"
                  />
                </Form.Item>
                <Form.Item
                  name="discountText"
                  label={"Additional Text"}
                  rules={[]}
                >
                  <Input className="w-full" />
                </Form.Item>

                {/* Hotels */}
                <Form.Item
                  name="includedHotel"
                  label={"Included Hotel"}
                  rules={[]}
                >
                  <Select defaultValue={"ALL"}>
                    {hotelInclusionOptions.map((item) => (
                      <Select.Option value={item.value} key={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <div className="font-bold mt-8 mb-4">Extras</div>
                <Form.List
                  name="whatsIncluded"
                  rules={
                    [
                      // {
                      //   validator: async (_, names) => {
                      //     if (!names || names.length < 2) {
                      //       return Promise.reject(new Error("At least 2 passengers"));
                      //     }
                      //   },
                      // },
                    ]
                  }
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex" }}
                          align="baseline"
                        >
                          <Form.Item {...restField} noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                              const type = getFieldValue([
                                "whatsIncluded",
                                name,
                                "type",
                              ]);
                              if (type) {
                                return (
                                  <div className="text-xl flex flex-col justify-center items-center">
                                    {WhatsIncludedIconMap[type]}
                                  </div>
                                );
                              }
                            }}
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "title"]}
                            className="flex-1"
                            rules={[
                              { required: true, message: "Missing title" },
                            ]}
                          >
                            <Input placeholder="Title" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "description"]}
                            className="flex-1"
                            rules={[]}
                          >
                            <Input placeholder="Description" />
                          </Form.Item>

                          <Button
                            type="text"
                            icon={<i className="ri-delete-bin-line"></i>}
                            onClick={() => remove(name)}
                          />
                        </Space>
                      ))}
                      <Form.Item>
                        <div className="flex flex-row gap-4">
                          <Button
                            type="dashed"
                            onClick={() =>
                              add({
                                type: "VISA",
                                title: "Visa Included",
                                description: "Visa fee included",
                              })
                            }
                            style={{ width: "60%" }}
                            icon={<PlusOutlined />}
                            className="w-full flex-1"
                            block
                          >
                            Add Visa
                          </Button>
                          <Button
                            type="dashed"
                            onClick={() =>
                              add({
                                type: "EXCURSION",
                                title: "Excursion Name",
                                description: "",
                              })
                            }
                            style={{ width: "60%" }}
                            icon={<PlusOutlined />}
                            className="w-full flex-1"
                            block
                          >
                            Add Excursion
                          </Button>
                        </div>
                        <div className="flex flex-row gap-4 mt-4">
                          <Button
                            type="dashed"
                            onClick={() =>
                              add({
                                type: "INSURANCE",
                                title: "Travel Insurance",
                                description: "Full Coverage",
                              })
                            }
                            style={{ width: "60%" }}
                            icon={<PlusOutlined />}
                            className="w-full flex-1"
                            block
                          >
                            Add Insurance
                          </Button>
                          <Button
                            type="dashed"
                            onClick={() =>
                              add({
                                type: "MISCELLANEOUS",
                                title: "Item Title",
                                description: "Item Description",
                              })
                            }
                            style={{ width: "60%" }}
                            icon={<PlusOutlined />}
                            className="w-full flex-1"
                            block
                          >
                            Add Extra
                          </Button>
                        </div>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </>
            )}
            {data.type === "CONFIRM_AND_PAY" && (
              <>
                <Form.Item
                  name="paymentLink"
                  label={"Payment Link"}
                  rules={[
                    {
                      type: "url",
                      message: "Please enter a valid payment URL",
                    },
                  ]}
                >
                  <Input className="w-full" />
                </Form.Item>
              </>
            )}

            {data.type === "SHARE_ITINERARY_BLOCK" && (
              <>
                <div className="flex flex-row justify-center items-center">
                  There is nothing to edit in this block
                </div>
              </>
            )}
            {data.type === "SIMPLE_DETAILS" && (
              <>
                <Form.Item name="content">
                  <LazyQuillEditor />
                </Form.Item>
              </>
            )}
          </Form>
        </div>
      )}

      <DarkModeBg />
    </Modal>
  );
};
