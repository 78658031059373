const stopPropagationAndPreventDefault = e => {
  e.stopPropagation();
  // e.preventDefault();
};

export const DragPreventer = ({ children }: { children: JSX.Element }) => {
  return (
    <div
      className="nodrag nowheel"
      // onClick={stopPropagationAndPreventDefault}
      // draggable={false}
      // onDrag={stopPropagationAndPreventDefault}
      // onDragStart={stopPropagationAndPreventDefault}
      // onMouseDown={stopPropagationAndPreventDefault}
      // onDragCapture={stopPropagationAndPreventDefault}
      // onMouseDownCapture={stopPropagationAndPreventDefault}
    >
      {children}
    </div>
  );
};
