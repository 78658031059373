import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDK } from "@sdk";
import { iAppFeatureRequest } from "@sdk/app-feature-requests/app-feature-requests-model";
import { Alert, Button, Tag } from "antd";
import { dummyFeatureRequests } from "components/feature-requests/data/dummy-feature-requests";
import _ from "lodash";
import { EditFeatureRequest, FeatureViewer } from "modal-registry";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { selectOrganizationId } from "store/modules/workspace/workspace.selectors";
import "./road-map.scss";

const _columnMap: {
  title: string;
  tickets: iAppFeatureRequest[];
}[] = [
  {
    title: "In Backlog",
    tickets: dummyFeatureRequests.filter(
      (item) => item.status === "In Backlog",
    ),
  },
  {
    title: "Selected for Build",
    tickets: dummyFeatureRequests.filter(
      (item) => item.status === "Selected for Build",
    ),
  },
  {
    title: "In Build",
    tickets: dummyFeatureRequests.filter((item) => item.status === "In Build"),
  },
  {
    title: "Released",
    tickets: dummyFeatureRequests.filter((item) => item.status === "Released"),
  },
];

export const RoadMap = () => {
  const { data: roadMapFeatures, reload } = useSDK(
    (SDK) =>
      SDK.appFeatureRequests.query({
        query: {
          // type: "FEATURE_REQUEST",
          isArchived: { $ne: true },
          isDeleted: { $ne: true },
          status: {
            $in: ["In Backlog", "Selected for Build", "In Build", "Released"],
          },
        },
        options: {
          limit: 100,
        },
      }),
    [],
  );

  const columnMap = useMemo(() => {
    const groupedFeatures = _.groupBy(roadMapFeatures?.docs || [], "status");
    return ["In Backlog", "Selected for Build", "In Build", "Released"].map(
      (status) => ({
        title: status,
        tickets: groupedFeatures[status] || [],
      }),
    );
  }, [roadMapFeatures?.docs]);

  const { triggerTempModal } = useModalPanels();

  const _isAdvancedMode = useSelector(selectIsAdvancedMode);
  const organizationId = useSelector(selectOrganizationId);
  const isAdvancedMode =
    _isAdvancedMode &&
    (organizationId === "fc89e0" || window.location.href.includes("localhost"));

  return (
    <div>
      <Alert
        showIcon
        message="The roadmap below is based on user requests. Additionally, the product team maintains a separate product board for user-provided comments, suggestions for improvements,bugs , and other R&D tasks."
        className="mb-4 max-w-5xl m-auto"
      />
      <div className="road-map-container flex flex-row gap-4 mt-8">
        {columnMap.map((column, i) => (
          <div
            className="bg-gray-100 dark:bg-gray-900 rounded-lg p-4 flex flex-col"
            key={`${column.title}-${i}`}
            style={{ width: 300, minHeight: 300 }}
          >
            <div className="font-bold p-2 flex flex-col justify-center items-center">
              {column.title}
            </div>
            <div className="flex flex-col gap-4">
              {column.tickets.map((item, i) => (
                <div
                  key={`exploring-${i}`}
                  className="bg-white dark:bg-black p-4 rounded-lg cursor-pointer shadow-xs hover:shadow-lg"
                  onClick={() => {
                    triggerTempModal(FeatureViewer, {
                      feature: item,
                      onUpdateRequest: () => {
                        reload();
                      },
                    });
                  }}
                >
                  <div className="font-bold">{item.title}</div>
                  <div
                    className="text-gray-600"
                    dangerouslySetInnerHTML={{ __html: item.shortDescription }}
                  />
                  <div className="text-gray-600" />
                  <div className="">
                    <Tag className="font-bold">
                      Category: {item.category || "Other"}
                    </Tag>{" "}
                    {item.tags.map((tag, index) => (
                      <Tag key={index.toString()}>{tag}</Tag>
                    ))}
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    {/* {Boolean(item.comments.length) && (
                      <Badge
                        count={item.comments.length || 0}
                        color="blue"
                        showZero
                        offset={[5, 0]}
                      >
                        <i className="ri-question-answer-line"></i>
                      </Badge>
                    )} */}
                    {isAdvancedMode && (
                      <Button
                        type="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          triggerTempModal(EditFeatureRequest, {
                            feature: item,
                            onEdited: () => reload(),
                          });
                        }}
                        icon={<i className="ri-pencil-line"></i>}
                      >
                        Edit Card
                      </Button>
                    )}
                  </div>
                  {/* <div className="flex flex-row gap-4">
                  <div className="flex flex-row justify-center items-center">
                    <i className="ri-arrow-up-s-line"></i> {t.votes.length}
                  </div>
                  <div className="flex flex-row justify-center items-center">
                    <i className="ri-question-answer-line"></i>{" "}
                    {t.comments.length}
                  </div>
                </div> */}
                </div>
              ))}
              {column.tickets.length === 0 && (
                <div className="text-center text-gray-600">
                  No Active Items in This Column
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-col justify-center items-center my-16">
        <div className="text-gray-600">
          Not able to find what you are looking for?
        </div>
        <Button
          type="primary"
          className="font-bold mt-4"
          icon={<i className="ri-chat-smile-3-line"></i>}
          onClick={() => {
            (window as any).ccWidget.setWidgetVisibility(true);
          }}
        >
          Chat with us
        </Button>
      </div>
    </div>
  );
};
