import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iDynamicForm } from "@sdk/dynamic-forms/dynamic-forms-model";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Form, Input, message, Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { ManageForm } from "modal-registry";
import { useCallback } from "react";
import { antInputModalAutoFocusWithRef } from "utils/ant-input-modal-auto-focus";

const FormTemplateOptions = [
  {
    value: "EMAIL",
    label: "Email",
    icon: <i className="ri-mail-line"></i>,
  },
  {
    value: "SMS",
    label: "SMS",
    icon: <i className="ri-message-3-line"></i>,
  },
];

export const AddDynamicFormModal = ({
  visible,
  onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const [form] = Form.useForm();

  const { changePanelState } = useModalPanels();

  const {
    doAction: onCreateDynamicForm,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.dynamicForms
          .create({
            ...values,
          })
          .then((res) => {
            onChangeVisibility(false);
            form.resetFields();
            changePanelState(ManageForm, true, { dynamicFormId: res.id! });
          }),
      successMessage: "Form has been created",
      failureMessage: "Something went wrong",
    }),
    [changePanelState, form, onChangeVisibility]
  );

  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();
      onCreateDynamicForm({
        ...formValues,
        layout: {
          header: {
            title: formValues.label,
            description: "",
            banner: "",
          },
          body: {
            layout: "SINGLE_PAGE",
            items: [
              {
                id: "1",
                type: "GROUP",
                title: "Please fill in the below form",
                description: "",
                fields: [
                  {
                    id: "y1",
                    key: "name",
                    label: "Your name",
                    inputType: "TEXT",
                    inputConfig: {
                      isRequired: true,
                    },
                  },
                ],
              },
            ],
            themePrimaryColor: "#2b66ff",
          },
          footer: {
            text: "",
          },
          submission: {
            message: "Thank you for taking the time to fill in this form",
            additionalMessage: "",
          },
        },
      } as iDynamicForm);
    } catch (e) {
      message.error("Please check your input");
    }
  }, [form, onCreateDynamicForm]);

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-file-text-line"></i>}
          title="Create New Form"
        />
      }
      open={visible}
      onOk={onSubmit}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okButtonProps={{ icon: <i className="ri-arrow-right-circle-line"></i> }}
      okText="Create Form"
      data-click-context="Create New Form Modal"
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{}}
        onFinish={onSubmit}
      >
        <Form.Item
          name="label"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please enter a form name",
            },
          ]}
        >
          <Input
            placeholder="Label"
            autoFocus
            ref={antInputModalAutoFocusWithRef}
          />
        </Form.Item>
        {/* Todo: Add Templates */}
        {/* <Form.Item name="type" label="Template" rules={[]}>
          <PillSelector options={FormTemplateOptions} />
        </Form.Item> */}
        <Button htmlType="submit" className="hidden">
          Submit
        </Button>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
