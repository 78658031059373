import DownOutlined from "@ant-design/icons/DownOutlined";
import UserAddOutlined from "@ant-design/icons/UserAddOutlined";
import UsergroupAddOutlined from "@ant-design/icons/UsergroupAddOutlined";
import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { SDK, useSDK } from "@sdk";
import {
  Roles,
  getRoleFromPermission,
} from "@sdk/roles-and-permissions/roles-and-permissions";
import { processServerError, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iUser } from "@sdk/user-management/user-management.models";
import {
  Button,
  Divider,
  Dropdown,
  Input,
  Menu,
  Popconfirm,
  Popover,
  Progress,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  message,
} from "antd";
import { UserAvatar } from "components/common/avatar/avatar";
import { FireHelpCenterTip } from "components/common/help-center/embeddable-components/help-center-notifications";
import { ModalTitle } from "components/common/modal-title";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import dayjs from "dayjs";
import {
  AddUser,
  BulkAddUsers,
  BulkEditUsers,
  EditUser,
  UserPicker,
} from "modal-registry";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { selectOrganizationDomain } from "store/modules/workspace/workspace.selectors";
import { useSearch } from "utils/hooks/use-search";
import { useUrlState } from "utils/hooks/use-url-state";
import JSONModal from "utils/json-modal/json-modal";
import { loadAllUsers } from "../../../../../../store/modules/users/users.helpers";
import {
  selectAllUsersQuery,
  selectCurrentUser,
} from "../../../../../../store/modules/users/users.selectors";
import { useQueryWithStore } from "../../../../../../store/store.hooks";
import { InviteUserForm } from "./invite-user-form";

const emptyArray = [];

const getUserCompletionStatus = (user: iUser) => {
  let missingFields: string[] = [];
  // Avatar
  if (!user.data.avatar) {
    missingFields.push("avatar");
  }

  // Description
  if (!user.data.description) {
    missingFields.push("description");
  }
  // Bio
  if (!user.data.bio) {
    missingFields.push("bio");
  }
  return {
    missingFields,
  };
};

export const ManageUsers = () => {
  const {
    state: { list: __users, isLoading },
    retry: reload,
  } = useQueryWithStore(selectAllUsersQuery, loadAllUsers());
  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  const _users = __users || emptyArray;

  const users = useMemo(
    () =>
      (_users || [])
        .filter((d) => !d.metaData.isAPIUser)
        .map((user) => ({
          id: user.id,
          name: `${user.data.firstName} ${user.data.lastName}`,
          email: user.credentials.email,
          role: getRoleFromPermission(user.permissions),
          isActive: user.metaData.isActive,
          isDeleted: user.metaData.isDeleted,
          lastOnline: user.metaData.isAvailable
            ? user.metaData.availabilityStatus
            : user.metaData.lastOnline,
          isChatAccessRestricted: user.metaData.disableChatJoining,
          missingFields: getUserCompletionStatus(user).missingFields,
          isAdmin: user.metaData.isAdmin,
        })),
    [_users],
  );

  const currentUser = useSelector(selectCurrentUser);

  const { data: _activeInvites, setData: setActiveInvites } = useSDK(
    (SDK) => SDK.getAllActiveInvitation(),
    [],
    false,
    [],
  );

  const activeInvites = useMemo(
    () =>
      _activeInvites.map((invite) => ({
        email: invite.email,
        role: getRoleFromPermission(invite.permissions),
      })),
    [_activeInvites],
  );

  const { filteredData, searchTerm, setSearchTerm } = useSearch(
    users.filter((d) => d.isActive),
  );

  const inactiveUser = useSearch(
    users.filter((d) => !d.isActive || d.isDeleted),
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (change) => setSelectedRowKeys(change),
  };

  const { doAction: transferAllConversations } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (toUserId: string, fromUserId: string) =>
        SDK.bulkEditConversations({
          action: "TRANSFER",
          user: toUserId,
          query: {
            members: {
              $elemMatch: {
                userId: fromUserId,
              },
            },
          },
        }),
      failureMessage: "Something went wrong",
      successMessage: "Transfer is successful",
    }),
    [],
  );

  const { doAction: closeAllConversations } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (fromUserId: string) =>
        SDK.bulkEditConversations({
          action: "CLOSE_CONVERSATION",
          query: {
            members: {
              $elemMatch: {
                userId: fromUserId,
              },
            },
            "members.1": { $exists: false },
          },
        }),
      failureMessage: "Something went wrong",
      successMessage: "Transfer is successful",
    }),
    [],
  );

  const { doAction: unAssignAllConversations } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (fromUserId: string) =>
        SDK.bulkEditConversations({
          action: "UNASSIGN_USER",
          user: fromUserId,
          query: {
            members: {
              $elemMatch: {
                userId: fromUserId,
              },
            },
          },
        }),
      failureMessage: "Something went wrong",
      successMessage: "User has been unassigned from the conversation",
    }),
    [],
  );

  const { doAction: restrictChatAccess } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (userId: string, status: boolean) =>
        SDK.editUser(userId, {
          metaData: {
            disableChatJoining: status,
          },
        }),
      successMessage: "Chat Restriction has been modified",
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const domain = useSelector(selectOrganizationDomain);

  const resetPassword = useCallback(
    (email: string) => {
      const indicator = message.loading("Processing");
      SDK.emailResetPasswordLink({
        email: email,
        domain: domain!,
      })
        .then((d) => {
          indicator();
          message.info(`Password Reset Link has been mailed to ${email}`);
        })
        .catch((e) => {
          message.error(processServerError(e, "Something went wrong"));
        });
    },
    [domain],
  );

  const { doAction: deActivateUser, isProcessing: isDeactivateProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (userId: string) =>
          SDK.deactivateUser(userId).then((d) => {
            FireHelpCenterTip({
              title: "Consider transferring deactivated user's chats 🤞",
              description:
                "When you delete/deactivate a user, the chats are not automatically unassigned",
              articleId: "fc89e0-e5ffc",
              // User Management
              collectionId: "fc89e0-a99c5",
            });
          }),
        successMessage: "User has been deactivated",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const columns = [
    {
      title: "",
      dataIndex: "id",
      width: 50,
      render: (id) => (
        <span>
          <UserAvatar userId={id} />
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) => (
        <span>
          <Tag>{role}</Tag>
        </span>
      ),
      filters: Roles.map((role) => ({
        text: role.label,
        value: role.label,
      })),
      onFilter: (value, record) => record.role.indexOf(value) === 0,
    },
    {
      title: "Availability",
      dataIndex: "lastOnline",
      render: (lastOnline) =>
        lastOnline && (
          <div className="flex">
            {lastOnline === "AVAILABLE" ? (
              <>
                <i className="ri-checkbox-blank-circle-fill text-green-500 mr-1"></i>
                Online
              </>
            ) : lastOnline === "BUSY" ? (
              <>
                <i className="ri-checkbox-blank-circle-fill  text-red-500 mr-1"></i>
                Busy
              </>
            ) : lastOnline === "AWAY" ? (
              <>
                <i className="ri-checkbox-blank-circle-fill text-yellow-500 mr-1"></i>
                Away
              </>
            ) : (
              dayjs(lastOnline).format("dddd, MMMM Do YYYY, h:mm:ss a")
            )}
          </div>
        ),
    },
    {
      title: "",
      key: "action",
      align: "right" as "right",
      render: (
        text,
        record: {
          isChatAccessRestricted?: boolean;
          email: string;
          id: string;
          missingFields: string[];
          isAdmin?: boolean;
        },
      ) => (
        <Space size="middle" onClick={(e) => e.stopPropagation()}>
          <Tooltip
            title={
              record.missingFields.length > 0
                ? `Profile Not completed - Missing ${record.missingFields.join(
                    ", ",
                  )}`
                : "Profile is completed"
            }
          >
            <Progress
              steps={4}
              percent={((4 - record.missingFields.length) / 4) * 100}
              size="small"
            />
          </Tooltip>
          <Button
            type="text"
            onClick={() => {
              changePanelState(EditUser, true, { userId: record.id });
            }}
            icon={<i className="ri-pencil-line"></i>}
          ></Button>
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu
                onClick={({ key: action }) => {
                  if (action === "DEACTIVATE") {
                    deActivateUser(record.id);
                  } else if (action === "TOGGLE_RESTRICT_CHAT_ACCESS") {
                    restrictChatAccess(
                      record.id,
                      !record.isChatAccessRestricted,
                    );
                  } else if (action === "RESET_PASSWORD") {
                    resetPassword(record.email);
                  }
                }}
                data-click-context="Bulk Edit User Menu"
              >
                <Menu.Item
                  key="DEACTIVATE"
                  icon={<i className="ri-lock-line"></i>}
                  disabled={record.isAdmin || currentUser.id === record.id}
                >
                  Deactivate User
                </Menu.Item>
                {record.isChatAccessRestricted ? (
                  <Menu.Item
                    key="TOGGLE_RESTRICT_CHAT_ACCESS"
                    icon={<i className="ri-check-line"></i>}
                  >
                    Allow Chat Access
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    key="TOGGLE_RESTRICT_CHAT_ACCESS"
                    icon={<i className="ri-subtract-line"></i>}
                  >
                    Restrict Chat Access
                  </Menu.Item>
                )}

                <Menu.Item
                  key="RESET_PASSWORD"
                  icon={<i className="ri-restart-line"></i>}
                >
                  Reset Password and Send Email
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="text"
              icon={<i className="ri-more-2-line"></i>}
            ></Button>
          </Dropdown>

          {/* <a>Remove</a> */}
        </Space>
      ),
    },
  ];
  if (isAdvancedMode) {
    columns.push({
      title: "",
      dataIndex: "id",
      render: (id) => {
        return (
          <i
            className="ri-code-box-line cursor-pointer"
            onClick={() => {
              JSONModal(_users.find((user) => user.id == id) || {});
            }}
          />
        );
      },
    });
  }
  const { doAction: activateUser, isProcessing: isActivateProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (userId) =>
          SDK.activateUser(userId).then((res) => {
            reload();
          }),
        successMessage: "User has been activated",
        failureMessage: "Something went wrong",
      }),
      [reload],
    );

  const columnsDeactivatedUsers = [
    {
      title: "",
      dataIndex: "id",
      width: 50,
      render: (id) => (
        <span>
          <UserAvatar userId={id} />
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "left" as "left",
    },
    {
      title: "",
      key: "action",
      align: "right" as "right",
      render: (text, record: (typeof users)[0]) =>
        !record.isDeleted ? (
          <Popconfirm
            title="Are you sure to activate this user?"
            onConfirm={() => {
              activateUser(record.id);
            }}
            okButtonProps={{ loading: isActivateProcessing }}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Activate User</Button>
          </Popconfirm>
        ) : (
          "USER REMOVED"
        ),
    },
    {
      title: "",
      key: "action2",
      align: "right" as "right",
      render: (text, record: (typeof users)[0]) => (
        <Space>
          <Button
            type="link"
            onClick={() => {
              triggerTempModal(UserPicker, {
                onUserSelected: async (toUser) => {
                  const hide = message.loading("Processing", 0);
                  try {
                    await transferAllConversations(toUser, record.id);
                    hide();
                  } catch (e) {
                    hide();
                  }
                },
                title: (
                  <ModalTitle
                    title="Pick a user to transfer"
                    icon={<i className="ri-mail-send-fill"></i>}
                  />
                ),
              });
            }}
          >
            Transfer all conversations
          </Button>

          <Button
            type="link"
            onClick={async () => {
              const destroyIndicator = message.loading("Processing");
              await unAssignAllConversations(record.id);
              destroyIndicator();
            }}
          >
            Unassign all conversations
          </Button>
          <Button
            type="link"
            onClick={async () => {
              const destroyIndicator = message.loading("Processing");
              await closeAllConversations(record.id);
              await unAssignAllConversations(record.id);
              destroyIndicator();
            }}
          >
            Close and unassign conversations
          </Button>
        </Space>
      ),
    },
  ];

  const {
    doAction: onDeleteInvitation,
    isProcessing: isDeleteInviteProcessing,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (email: string) =>
        SDK.deleteInvitation(email).then((status) => {
          setActiveInvites(
            _activeInvites.filter((invite) => invite.email !== email),
          );
        }),
      successMessage: "Invitation has been removed",
      failureMessage: "Something went wrong",
    }),
    [_activeInvites, setActiveInvites],
  );

  const inviteDataColumns = useMemo(() => {
    return [
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Role",
        dataIndex: "role",
        render: (role) => (
          <span>
            <Tag>{role}</Tag>
          </span>
        ),
        filters: Roles.map((role) => ({
          text: role.label,
          value: role.label,
        })),
        onFilter: (value, record) => record.name.indexOf(value) === 0,
      },
      {
        title: "",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="text"
              onClick={() => onDeleteInvitation(record.email)}
            >
              Retract Invitation
            </Button>
            {/* <a>Remove</a> */}
          </Space>
        ),
      },
    ];
  }, [onDeleteInvitation]);

  const [activeTab, setActiveTab] = useUrlState(
    "user-list",
    "active-users" as
      | "active-users"
      | "deactivated-users"
      | "active-invitations",
  );

  const history = useHistory();

  const inviteUserRef = useRef<any>(null);

  useEffect(() => {
    // Run Once

    if (
      ((history?.location?.state as any)?.showInvite ||
        history?.location.search.includes("show-invite")) &&
      inviteUserRef &&
      inviteUserRef.current
    ) {
      setTimeout(() => {
        inviteUserRef.current.click();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { triggerTempModal, changePanelState } = useModalPanels();

  const inviteUserButton = (
    <Popover
      placement="bottom"
      trigger="click"
      destroyTooltipOnHide
      content={
        <>
          <InviteUserForm
            onInviteSent={(invite) => {
              setActiveInvites([..._activeInvites, invite]);
            }}
          />
        </>
      }
      title={
        <div className="font-bold">
          <Space>
            <i className="ri-mail-send-line"></i>
            Invite User
          </Space>
        </div>
      }
    >
      <Button
        type="primary"
        // size="large"
        icon={<UserAddOutlined />}
        ref={inviteUserRef}
      >
        Invite User
      </Button>
    </Popover>
  );

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin">
      <SimpleCardSection className="m-auto my-4 p-8 lg:p-24">
        <SectionHeader
          title="Manage Users"
          icon={<i className="ri-group-line pr-2"></i>}
          description="Add, remove and keep track of everyone who has access to your Click Connector."
          addons={
            <>
              {activeTab === "active-users" && (
                <div className="right-buttons">
                  {selectedRowKeys.length === 0 && (
                    <Space>
                      {inviteUserButton}
                      <Button
                        // type="primary"
                        // size="large"
                        icon={<UserAddOutlined />}
                        onClick={() => changePanelState(AddUser, true, {})}
                      >
                        Add User
                      </Button>
                      <Button
                        // type="dashed"
                        // size="large"
                        icon={<UsergroupAddOutlined />}
                        onClick={() => changePanelState(BulkAddUsers, true, {})}
                      >
                        Import Users
                      </Button>
                    </Space>
                  )}
                  {selectedRowKeys.length > 0 && (
                    <div className="right-hand-side-buttons">
                      <Space>
                        <div className="records-count text-gray-500">
                          {`${selectedRowKeys.length} selected item(s)`}
                        </div>
                        <Dropdown
                          trigger={["click"]}
                          overlay={
                            <Menu
                              onClick={({ key: action }) => {
                                triggerTempModal(BulkEditUsers, {
                                  action: action as any,
                                  query:
                                    selectedRowKeys.length === 0
                                      ? {} // All Users
                                      : {
                                          id: { $in: selectedRowKeys },
                                        },
                                  onActionCompleted: () => reload(),
                                });
                              }}
                              data-click-context="Bulk Edit User Menu"
                            >
                              <Menu.Item
                                key="DEACTIVATE"
                                icon={<i className="ri-user-unfollow-line"></i>}
                              >
                                Deactivate Users
                              </Menu.Item>
                              <Menu.Item
                                key="ADD_TO_USER_GROUP"
                                icon={<i className="ri-user-shared-2-line"></i>}
                              >
                                Add to User groups
                              </Menu.Item>
                              <Menu.Item
                                key="REMOVE_FROM_USER_GROUP"
                                icon={
                                  <i className="ri-user-received-2-line"></i>
                                }
                              >
                                Remove from User groups
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <Button>
                            Actions <DownOutlined />
                          </Button>
                        </Dropdown>
                      </Space>
                    </div>
                  )}
                </div>
              )}

              {activeTab === "active-invitations" && (
                <div className="right-buttons">{inviteUserButton}</div>
              )}
            </>
          }
        />
        <Divider />

        <Tabs
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key as any)}
          tabBarStyle={{ paddingLeft: 16 }}
        >
          <Tabs.TabPane
            tab={<span className="font-bold text-sm">Users</span>}
            key="active-users"
          >
            <Input.Search
              placeholder="Search..."
              className="rounded-md mb-8"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Table
              columns={columns}
              dataSource={filteredData}
              pagination={filteredData.length < 10 ? false : undefined}
              rowKey="id"
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              onRow={(row) => ({
                onClick: () => {
                  changePanelState(EditUser, true, { userId: row.id });
                },
                className: "cursor-pointer",
              })}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<span className="font-bold text-sm">Deactivated Users</span>}
            key="deactivated-users"
          >
            <Input.Search
              placeholder="Search..."
              className="rounded-md mb-8"
              value={inactiveUser.searchTerm}
              onChange={(e) => inactiveUser.setSearchTerm(e.target.value)}
            />
            <Table
              columns={columnsDeactivatedUsers}
              dataSource={inactiveUser.filteredData}
              pagination={
                inactiveUser.filteredData.length < 10 ? false : undefined
              }
              rowKey="id"
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            disabled={selectedRowKeys.length > 0}
            tab={<span className="font-bold text-sm">Pending Invitations</span>}
            key="active-invitations"
          >
            <Table
              columns={inviteDataColumns}
              dataSource={activeInvites}
              pagination={activeInvites.length < 10 ? false : undefined}
              rowKey="email"
              loading={isDeleteInviteProcessing}
            />
          </Tabs.TabPane>
        </Tabs>
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
