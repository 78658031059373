import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDK } from "@sdk";
import { Alert, Divider } from "antd";
import { SectionHeader } from "components/common/section-header";
import { CreateSubAccountWorkspace } from "modal-registry";
import { useSelector } from "react-redux";
import { selectSubscriptionDetails } from "store/modules/workspace/workspace.selectors";

const _subAccounts = [
  { id: "daa", domain: "xxx1", data: { logo: "fdsfd", name: "dsfdfdsf" } },
];

const limit = 3;

export const ManageSubAccounts = () => {
  const subscriptionDetails = useSelector(selectSubscriptionDetails);
  const appSumoSubscription = subscriptionDetails?.appSumoBilling;

  const activatedPlan = subscriptionDetails?.appSumoBilling?.plan_id;

  const { triggerTempModal } = useModalPanels();

  const {
    data: subAccounts,
    isLoading,
    reload,
  } = useSDK((SDK) => SDK.fetchSubAccounts(), []);

  const remainingLicenses = limit - (subAccounts || []).length;

  return (
    <div className="flex flex-col w-full my-12">
      <SectionHeader
        title="Manage Sub Accounts"
        icon={<i className="ri-group-2-line"></i>}
        description="Register and manage your sub accounts under your agency account"
      />
      <Divider />
      <Alert
        showIcon
        type="info"
        message={`You can create 3 sub accounts under your plan and you have ${remainingLicenses} licenses left`}
      />
      {subAccounts.length > 0 && (
        <>
          <div className="subtitle text-gray-600 dark:text-gray-400 mb-2 mt-8 font-bold text-lg mode_transition">
            Managed Sub-Accounts
          </div>
          <div className="sub-accounts w-full flex flex-col my-4">
            {subAccounts.map((account) => (
              <div key={account.id} className="mb-4">
                <div className="font-bold">{account.data.name}</div>
                <div className="text-gray-600">
                  {account.domain}.clickconnector.io
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {Boolean(remainingLicenses) && (
        <div
          className="border border-gray-300 dark:border-gray-400 border-dashed rounded-lg p-4 flex flex-col cursor-pointer hover:bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-500 justify-start items-center mt-4"
          onClick={() => {
            triggerTempModal(CreateSubAccountWorkspace, {
              onCreated: () => {
                reload();
              },
            });
          }}
        >
          <div className="text-4xl">
            <i className="ri-add-line pr-2"></i>
          </div>
          <div className="flex flex-col text-lg justify-center items-center">
            <div className="text-2xl">Create New Workspace</div>
            <div className="">
              You'll be using one license and this action is not reversible
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
