import { useSDKWithRemotePagination } from "@sdk/sdk.hooks";
import { Modal, Pagination, Space, Table, Tabs } from "antd";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import { ModalTitle } from "components/common/modal-title";
import { getContactName } from "components/modules/crm/contacts/helpers/get-contact-name";
import { DarkModeBg } from "dark-mode-bg";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { selectAutomationWorkflowById } from "store/modules/automation-workflows/automation-workflows.selectors";
import { bulkLoadContactsByIds } from "store/modules/contacts/contacts.helpers";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { iWorkflowStepIconMap } from "../workflow-step-icons";

const ContactPill = ({ contactId }: { contactId }) => {
  const contact = useSelector(selectContactById(contactId));
  if (!contact) {
    return <div></div>;
  }
  return (
    <div
      className="flex flex-row items-center p-2 cursor-pointer  mode_transition"
      key={contact.id}
    >
      <div className="avatar-container">
        <ContactAvatar contactId={contact.id!} />
      </div>
      <div className="texts flex flex-col flex-1 ml-2">
        <div className="name font-bold">{getContactName(contact)}</div>
        <div className="email">{contact.data.primaryEmail}</div>
      </div>
    </div>
  );
};

export const SequenceStatusModal = ({
  visible,
  onChangeVisibility,
  sequenceId,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  sequenceId?: string;
}) => {
  const store = useStore();
  const sequenceConfig = useSelector(selectAutomationWorkflowById(sequenceId!));

  const [currentStep, setCurrentStep] = useState("");

  const query = useMemo(() => {
    return {
      automationWorkflowId: sequenceId,
      currentStep: currentStep === "ALL" ? undefined : currentStep,
    };
  }, [currentStep, sequenceId]);

  const {
    paginationState,
    setPaginationState,
    data: itemsInView,
    setData: setItemsInView,
    isLoading,
    reload: resetAndReload,
  } = useSDKWithRemotePagination(
    (SDK) => SDK.automationWorkflows.queryAutomationWorkflowContacts,
    query,
    [query],
    {
      pageSize: 15,
      currentPage: 1,
    },
  );

  // Load Contacts
  useEffect(() => {
    const associatedContacts = itemsInView.map(
      (chatBotInstance) => chatBotInstance.contactId,
    );
    if (associatedContacts.length > 0) {
      bulkLoadContactsByIds(associatedContacts)(store);
    }
  }, [itemsInView, store]);

  const columns = useMemo(() => {
    return [
      {
        title: "Contact",
        dataIndex: "contactId",
        key: "contactId",
        render: (contactId) => {
          return <ContactPill contactId={contactId} />;
        },
      },
      {
        title: "Added Time",
        dataIndex: "addedTime",
        key: "addedTime",
        render: (addedTime) => {
          return dayjs(addedTime).format("DD MMM YYYY hh:mm a");
        },
      },
      {
        title: "Step",
        dataIndex: "currentStep",
        key: "currentStep",
        render: (currentStep) => {
          return `Step ${
            (sequenceConfig?.steps || []).findIndex(
              (item) => item.id === currentStep,
            ) + 1
          }`;
        },
      },
      {
        title: "Action",
        dataIndex: "version",
        key: "action",
        render: (data, record) => <Space size="middle">{/* Todo */}</Space>,
      },
    ];
  }, [sequenceConfig?.steps]);

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-mail-send-line"></i>}
          title="View Sequence Status"
        />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      footer={null}
      data-click-context="View Sequence Status Modal"
      width={1100}
    >
      <div className="flex flex-col">
        <Tabs
          tabPosition="top"
          className="border-b"
          activeKey={currentStep}
          onChange={(e) => setCurrentStep(e)}
        >
          {sequenceConfig && (
            <>
              <Tabs.TabPane
                tab={
                  <div>
                    <div className="flex flex-row">
                      <div className="mr-2">
                        <i className="ri-star-line"></i>
                      </div>
                      <div className="label">All</div>
                    </div>
                  </div>
                }
                tabKey={"ALL"}
                key={"ALL"}
              >
                <></>
              </Tabs.TabPane>
              {sequenceConfig.steps.map((step, index) => {
                return (
                  <Tabs.TabPane
                    tab={
                      <div>
                        <div className="flex flex-row">
                          <div className="mr-2">
                            {iWorkflowStepIconMap[step.type]}
                          </div>
                          <div className="label">Step {index + 1}</div>
                        </div>
                      </div>
                    }
                    key={step.id}
                  >
                    <></>
                  </Tabs.TabPane>
                );
              })}
            </>
          )}
        </Tabs>
        <div className="body">
          <Table
            columns={columns}
            dataSource={itemsInView}
            pagination={false}
            loading={isLoading}
          />
          <div className="flex items-center justify-between mt-3">
            <Pagination
              pageSize={paginationState.pageSize}
              current={paginationState.currentPage}
              total={paginationState.totalItems}
              onChange={(currentPage, pageSize) => {
                setPaginationState({
                  ...paginationState,
                  pageSize: pageSize || 15,
                  currentPage,
                });
                // console.log("currentPage", currentPage);
              }}
            />
            <div>Total items: {paginationState.totalItems}</div>
          </div>
        </div>
      </div>
      <DarkModeBg />
    </Modal>
  );
};
