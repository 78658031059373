import { AnonymousAvatarsService } from "@libs/anonymous-avatars/anonymous-avatars.service";
import { iFbConnectionData } from "@sdk/conversations/conversations.models";
import { iContactConnectionTypes } from "@sdk/crm/crm.models";
import classNames from "classnames";
import React, { memo, useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { selectConnectionsMap } from "store/modules/connections/connections.selectors";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectSessionById } from "store/modules/sessions/sessions.selectors";
import {
  selectIsSessionOnline,
  selectIsUserOnline,
} from "store/modules/user-presence/user-presence.selectors";
import { useDoubleSelectorWithMemoize } from "store/utils/use-double-selector-with-memoize";
import { getFbAvatar } from "utils/get-fb-avatar";
import { parseEmailHeader } from "utils/parse-email-header";
import { Avatar } from "./avatar";
import "./avatar.scss";
const gravatarUrl = require("gravatar-url");

export const EmailAvatar = ({
  emailString,
  size = 32,
  style,
  className,
}: {
  emailString: string;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}) => {
  const { avatarUrl, name } = useMemo(() => {
    const firstEmail = parseEmailHeader(emailString)[0];
    return {
      name: firstEmail.name,
      avatarUrl: gravatarUrl(firstEmail.email, {
        size: 64,
        d: "identicon", // https://en.gravatar.com/site/implement/images/
        // default:
        //   "https://cc.clickconnector.com/assets/img/avatars/missing-profile-picture.jpg",
      }),
    };
  }, [emailString]);

  return (
    <Avatar
      name={name}
      avatarUrl={avatarUrl}
      size={size}
      style={style}
      className={className}
    />
  );
};

export const useContactAvatar = (contactId: string, connectionId?: string) => {
  const store = useStore();

  const { contactExists, connections, primaryEmail, firstName, avatar } =
    useDoubleSelectorWithMemoize(
      () => selectContactById(contactId!),
      [contactId],
      (contact) => ({
        contactExists: Boolean(contact),
        connections: contact?.metaData.connections,
        primaryEmail: contact?.data.primaryEmail,
        firstName: contact?.data.firstName,
        avatar: contact?.data.avatar,
      }),
    );

  const avatarData = useMemo(() => {
    const data: {
      sessionIds: string[];
      primaryEmail?: string;
      fbProfile?: {
        fbUserId: string;
        connectionId: string;
        accessToken: string;
      };
      connectionTypes: iContactConnectionTypes[];
      connectionTypeInContext?: string;
    } = {
      sessionIds: [],
      connectionTypes: [],
    };
    if (!contactExists) {
      return data;
    }
    const connectionsMap = selectConnectionsMap(store.getState());
    if (connectionId) {
      data.connectionTypeInContext = connectionsMap[connectionId]?.type;
    }

    for (const connectionData of connections || []) {
      if (connectionId && connectionData.connectionId !== connectionId) {
        continue;
      }
      data.connectionTypes.push(connectionData.type);
      if (connectionData.type === "FACEBOOK") {
        data.fbProfile = {
          fbUserId: connectionData.data.fbUserId!,
          connectionId: connectionData.connectionId,
          accessToken: connectionsMap[connectionData.connectionId!]
            ? (
                connectionsMap[connectionData.connectionId!]
                  .data as iFbConnectionData
              )?.page?.access_token
            : "",
        };
      }
      if (connectionData.type === "WEB_CHAT") {
        data.sessionIds = [
          ...data.sessionIds,
          ...(connectionData.data.associatedSessions || []),
        ];
      }
    }
    data.primaryEmail = primaryEmail;
    return data;
  }, [connectionId, connections, contactExists, primaryEmail, store]);

  const isOnline = useSelector(selectIsSessionOnline(avatarData.sessionIds));

  return {
    name: firstName,
    avatar:
      avatarData.fbProfile && avatarData.fbProfile.accessToken
        ? getFbAvatar(
            avatarData.fbProfile.fbUserId,
            avatarData.fbProfile.accessToken,
          )
        : avatar ||
          (avatarData.primaryEmail
            ? gravatarUrl(avatarData.primaryEmail, {
                size: 64,
                d: "identicon", // https://en.gravatar.com/site/implement/images/
                // default:
                //   "https://cc.clickconnector.com/assets/img/avatars/missing-profile-picture.jpg",
              })
            : undefined),
    connectionType:
      avatarData.connectionTypeInContext || avatarData.connectionTypes[0],
    isOnline,
  };
};

export const useSessionAvatar = (sessionId: string) => {
  const { contactId, isAvailable } = useDoubleSelectorWithMemoize(
    () => selectSessionById(sessionId),
    [sessionId],
    (session) => ({
      contactId: session?.contactId,
      isAvailable: session?.metaData?.active,
    }),
  );

  const { contactExists, firstName, avatar } = useDoubleSelectorWithMemoize(
    () => selectContactById(contactId!),
    [contactId],
    (contact) => ({
      contactExists: Boolean(contact),
      connections: contact?.metaData.connections,
      primaryEmail: contact?.data.primaryEmail,
      firstName: contact?.data.firstName,
      avatar: contact?.data.avatar,
    }),
  );

  const isOnline = useSelector(selectIsUserOnline(sessionId));
  return {
    name: contactExists
      ? firstName
      : AnonymousAvatarsService.getAnonymousName(sessionId),
    avatar: contactExists
      ? avatar
      : AnonymousAvatarsService.getAnonymousAvatar(sessionId),
    avatarColor: AnonymousAvatarsService.getAnonymousAvatarColor(sessionId),
    isOnline,
    isAvailable,
  };
};

export const SessionAvatar = memo(
  ({
    sessionId,
    size = 32,
    style,
    className,
  }: {
    sessionId: string;
    size?: number;
    style?: React.CSSProperties;
    className?: string;
  }) => {
    const { name, isOnline, isAvailable, avatar, avatarColor } =
      useSessionAvatar(sessionId);

    return (
      <div
        className={classNames("user-avatar", isAvailable ? "online" : "away")}
        style={{ width: size }}
      >
        <Avatar
          name={name}
          avatarUrl={avatar}
          avatarColor={avatarColor}
          size={size}
          style={style}
          className={className}
        />
      </div>
    );
  },
);

export const ContactAvatar = memo(
  ({
    contactId,
    connectionId,
    size = 32,
    style,
    className,
    hideMedium,
  }: {
    contactId: string;
    connectionId?: string;
    size?: number;
    style?: React.CSSProperties;
    className?: string;
    hideMedium?: boolean;
  }) => {
    const { name, isOnline, avatar, connectionType } = useContactAvatar(
      contactId,
      connectionId,
    );

    return (
      <div
        className={classNames(
          "user-avatar",
          connectionType === "WEB_CHAT" ? (isOnline ? "online" : "away") : "",
        )}
        style={{ width: size }}
      >
        {!hideMedium && (
          <>
            {connectionType === "FACEBOOK" && (
              <div className="medium-icon facebook">
                <i className="ri-facebook-box-fill"></i>
              </div>
            )}
            {(connectionType === "WHATS_APP_PRIVATE" ||
              connectionType === "WHATS_APP_OFFICIAL" ||
              connectionType === "WHATS_APP_OFFICIAL" ||
              connectionType === "WHATS_APP_CLOUD_NATIVE") && (
              <div className="medium-icon whatsapp">
                <i className="ri-whatsapp-fill"></i>
              </div>
            )}

            {connectionType === "EMAIL" && (
              <div className="medium-icon email">
                <i className="ri-mail-fill"></i>
              </div>
            )}
            {connectionType === "INSTAGRAM" && (
              <div className="medium-icon instagram">
                <i className="ri-instagram-fill"></i>
              </div>
            )}
            {connectionType === "TELNYX" && (
              <div className="medium-icon telnyx">
                <i className="ri-smartphone-fill"></i>
              </div>
            )}
            {connectionType === "TWITTER" && (
              <div className="medium-icon twitter">
                <i className="ri-twitter-fill"></i>
              </div>
            )}
            {connectionType === "TELEGRAM" && (
              <div className="medium-icon telegram">
                <i className="ri-telegram-fill"></i>
              </div>
            )}
          </>
        )}

        <Avatar
          name={name}
          avatarUrl={avatar}
          size={size}
          style={style}
          className={className}
        />
      </div>
    );
  },
);
