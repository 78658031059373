import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { SDK } from "@sdk";
import { iKnowledgeDocument } from "@sdk/knowledge-documents/knowledge-documents-model";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Alert,
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Pagination,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { HelpCenterPromptButton } from "components/common/help-center/embeddable-components/help-center-prompt-button";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { StyledSwitch } from "components/common/styled-swtich";
import dayjs from "dayjs";
import {
  AddEditKnowledgeDocument,
  KnowledgeQueryLogsViewer,
} from "modal-registry";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { loadAllIndexedWebPages } from "store/modules/indexed-web-pages/indexed-web-pages.helpers";
import { selectIndexedWebPageQuery } from "store/modules/indexed-web-pages/indexed-web-pages.selectors";
import { loadAllKnowledgeDocuments } from "store/modules/knowledge-documents/knowledge-documents.helpers";
import {
  selectAllKnowledgeDocuments,
  selectKnowledgeDocumentsMap,
} from "store/modules/knowledge-documents/knowledge-documents.selectors";
import { setKnowledgeDocuments } from "store/modules/knowledge-documents/knowledge-documents.slice";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { selectWebsitesToCrawl } from "store/modules/workspace/workspace.selectors";
import {
  usePaginatedQueryWithStore,
  useQueryWithStore,
} from "store/store.hooks";
import { AddEllipsis } from "utils/add-ellipsis";
import { isURL } from "utils/is-url";
import JSONModal from "utils/json-modal/json-modal";
import { stringArrayToSentence } from "utils/string-array-to-sentence";
import { KnowledgeKitFileUploader } from "../components/knowledge-kit-file-uploader";
import { MagicAssistantPromptWriter } from "../components/magic-assistant-prompt-writer";
import { QueryKnowledgePlayground } from "../query-knowledge-playground/query-knowledge-playground";
import "./manage-knowledge-documents.scss";

const KnowledgeDocumentTypeLabels = {
  TEXT: "Internal Document",
  KB_ARTICLE: "Knowledge Base Article",
  WEB_PAGE: "Web page",
  FILE: "File",
};

const allDocumentsQuery = {};

const tokenSeparators = [",", ";"];

const availableOpenAIModels = [
  // GPT 4
  {
    name: "gpt-4",
    description:
      "More capable than any GPT-3.5 model, able to do more complex tasks, and optimized for chat. Will be updated with our latest model iteration 2 weeks after it is released",
    tokens: 8192,
    pricing: {
      input: "$0.03 / 1K tokens",
      output: "$0.06 / 1K tokens",
    },
  },
  {
    name: "gpt-4-32k",
    description:
      "Same capabilities as the standard gpt-4 mode but with 4x the context length. Will be updated with our latest model iteration.",
    tokens: 32768,
    pricing: {
      input: "$0.06 / 1K tokens",
      output: "$0.12 / 1K tokens",
    },
  },
  // GPT 3.5
  {
    name: "gpt-3.5-turbo",
    description:
      "Most capable GPT-3.5 model and optimized for chat at 1/10th the cost of text-davinci-003. Will be updated with our latest model iteration 2 weeks after it is released.",
    tokens: 4097,
    pricing: {
      input: "$0.0015 / 1K tokens",
      output: "$0.002 / 1K tokens",
    },
  },
  {
    name: "gpt-3.5-turbo-16k",
    description:
      "Same capabilities as the standard gpt-3.5-turbo model but with 4 times the context.",
    tokens: 16385,
    pricing: {
      input: "$0.003 / 1K tokens",
      output: "$0.004 / 1K tokens",
    },
  },
];

export const ManageKnowledgeDocuments = () => {
  const isAdvancedMode = useSelector(selectIsAdvancedMode);
  const history = useHistory();
  const { changePanelState } = useModalPanels();

  const { state, retry: reload } = useQueryWithStore(
    selectAllKnowledgeDocuments,
    loadAllKnowledgeDocuments(),
  );

  const {
    paginationState,
    setPaginationState,
    results: documents,
  } = usePaginatedQueryWithStore(
    selectKnowledgeDocumentsMap,
    setKnowledgeDocuments,
    allDocumentsQuery,
    (req) => SDK.knowledgeDocuments.query(req),
    [state?.length],
    { pageSize: 50 },
  );

  const { doAction: onDeleteDocument, isProcessing: isDeleting } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (documentId: string) =>
          SDK.knowledgeDocuments.deleteById(documentId).then((res) => {
            reload();
          }),
        successMessage: "Document has been removed",
        failureMessage: "Something went wrong",
      }),
      [reload],
    );

  const { doAction: clearAllDocuments, isProcessing: isClearingDocuments } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (documentId: string) =>
          SDK.knowledgeDocuments.reprocessDocument(documentId).then((res) => {
            reload();
          }),
        successMessage: "Documents have been cleared",
        failureMessage: "Something went wrong",
      }),
      [reload],
    );

  const { doAction: onReprocessDocument, isProcessing: isReprocessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (documentId: string) =>
          SDK.knowledgeDocuments.reprocessDocument(documentId).then((res) => {
            reload();
          }),
        successMessage: "Competed",
        failureMessage: "Something went wrong",
      }),
      [reload],
    );

  const { doAction: reCrawlWebsites, isProcessing: isRecrawlingWebsites } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.indexedWebPages.reCrawlWebsites().then((res) => {
            reload();
          }),
        successMessage: "Crawling has been finished",
        failureMessage: "Something went wrong",
      }),
      [reload],
    );

  const { doAction: reIndexKB, isProcessing: isReIndexingKB } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.knowledgeDocuments.reProcessKb().then((res) => {
            reload();
          }),
        successMessage: "Re-Indexing has been finished",
        failureMessage: "Something went wrong",
      }),
      [reload],
    );

  const dispatch = useDispatch();

  const websitesToCrawl = useSelector(selectWebsitesToCrawl);

  const {
    state: indexState,
    retry: reloadIndexes,
    isLoading,
  } = useQueryWithStore(
    selectIndexedWebPageQuery("all"),
    loadAllIndexedWebPages(),
    [],
  );

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      render: (title: string, record: iKnowledgeDocument) => {
        return (
          <div>
            {/* <Tooltip title={title}>{AddEllipsis(title, 100)}</Tooltip> */}
            {AddEllipsis(title, 100)}
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 200,
      render: (title: string, record: iKnowledgeDocument) => {
        return (
          <div>
            <Tag>{KnowledgeDocumentTypeLabels[record.type]}</Tag>
          </div>
        );
      },
    },
    // {
    //   title: "Created",
    //   dataIndex: "createdAt",
    //   width: 120,
    //   render: (createdAt: number, record: iKnowledgeDocument) => {
    //     return (
    //       <div className="text-gray-600">
    //         {dayjs(record.createdAt).format("DD-MM-YYYY")}
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Last Update",
      dataIndex: "updatedAt",
      width: 140,
      render: (createdAt: number, record: iKnowledgeDocument) => {
        return (
          <div className="text-gray-600">
            {dayjs(record.updatedAt).format("DD-MM-YYYY")}
          </div>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: 180,
      render: (text, record: iKnowledgeDocument) => (
        <Space size="middle" onClick={(e) => e.stopPropagation()}>
          <Tag>
            {record.failedAt > record.lastEmbedStartedAt && "Failed"}
            {(!record.failedAt ||
              record.failedAt < record.lastEmbedStartedAt) && (
              <>
                {record.lastEmbedStartedAt > record.lastEmbedCreatedAt &&
                  "Processing"}
                {record.lastEmbedStartedAt === record.lastEmbedCreatedAt &&
                  "Ready"}
              </>
            )}
          </Tag>
          <Dropdown
            overlay={
              <Menu data-click-context="Manage Knowledge Document More Button">
                <Menu.Item
                  icon={<i className="ri-delete-bin-line"></i>}
                  onClick={() => onDeleteDocument(record.id)}
                  disabled={isDeleting}
                >
                  Delete Document
                </Menu.Item>

                <Menu.Item
                  icon={<i className="ri-refresh-line"></i>}
                  onClick={() => onReprocessDocument(record.id)}
                  disabled={isReprocessing}
                >
                  Re-Process Document
                </Menu.Item>
              </Menu>
            }
            placement="topCenter"
            trigger={["click"]}
            arrow
          >
            <Button type="text" icon={<i className="ri-more-line"></i>} />
          </Dropdown>
        </Space>
      ),
    },
  ];
  if (isAdvancedMode) {
    columns.push({
      title: "",
      dataIndex: "id",
      width: 20,
      render: (id: any) => {
        return (
          <i
            className="ri-code-box-line cursor-pointer"
            onClick={() => {
              JSONModal(documents.find((document) => document.id === id) || {});
            }}
          />
        );
      },
    });
  }

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin manage-chat-bots">
      <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4">
        <SectionHeader
          title="Manage Knowledge Documents"
          icon={<i className="ri-folder-5-line"></i>}
          description="Empower AI Assistants with Knowledge Documents for Informed and Accurate Response"
          addons={
            <>
              <Space>
                <Button
                  type="primary"
                  icon={<i className="ri-add-box-line"></i>}
                  onClick={() =>
                    changePanelState(AddEditKnowledgeDocument, true, {})
                  }
                  className="font-bold"
                >
                  New Document
                </Button>
                <KnowledgeKitFileUploader />
                <Tooltip title="View Past Query Logs">
                  <Button
                    type="text"
                    icon={<i className="ri-folder-history-line"></i>}
                    onClick={() =>
                      changePanelState(KnowledgeQueryLogsViewer, true, {})
                    }
                    className="font-bold"
                  ></Button>
                </Tooltip>
              </Space>
            </>
          }
        />

        <Divider />
        <QueryKnowledgePlayground />
        <Divider />

        <HelpCenterPromptButton
          type="BANNER"
          text={
            <div className="text-lg p-3">
              Video Guide: Introduction to Knowledge Kit 📺
            </div>
          }
          description=""
          icon="LEARN"
          articleId="fc89e0-00ce7"
          className="my-4"
        />

        <ConfigurationEditorComponent
          icon="ri-global-line"
          title={
            websitesToCrawl.length > 0
              ? `Websites to Study: Indexed ${websitesToCrawl.length} website(s). (${indexState?.totalItems} Pages)`
              : `Websites to Study`
          }
          description="Configure the websites to gather knowledge from automatically"
          entityType="WORKSPACE"
          onSaved={() => reCrawlWebsites()}
        >
          <div className="flex flex-row justify-end items-center">
            {websitesToCrawl.length > 0 && (
              <div>
                {/* <Tooltip title="Crawling website is temporarily disabled for function upgrades. Stay Tuned!"> */}
                <Button
                  type="dashed"
                  icon={<i className="ri-refresh-line"></i>}
                  onClick={() => reCrawlWebsites()}
                  loading={isRecrawlingWebsites}
                  // disabled={true}
                >
                  Re-crawl Websites and Index
                </Button>
                {/* </Tooltip> */}
              </div>
            )}
          </div>
          <Form.Item
            label="Websites to crawl"
            name={["appData", "KNOWLEDGE_BASE", "indexWebpages", "websites"]}
            hasFeedback
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, urls) {
                  if (!urls || urls.length === 0) {
                    return Promise.resolve();
                  }
                  // Only support https
                  const invalidUrls = urls.filter((url) => !isURL(url));
                  if (invalidUrls.length > 0) {
                    return Promise.reject(
                      `The following URLs are Invalid: ${stringArrayToSentence(
                        invalidUrls,
                      )}`,
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Websites to crawl"
              dropdownStyle={{ display: "none" }}
              allowClear
            ></Select>
          </Form.Item>

          <Alert
            showIcon
            type="info"
            message="Tip: To improve content scraping, select only the main content on your web pages. This will also reduce token usage."
            className="mb-4"
          />

          <Form.Item
            label="Blacklisted Page Elements (CSS selectors)"
            name={[
              "appData",
              "KNOWLEDGE_BASE",
              "indexWebpages",
              "blackListedHtmlElement",
            ]}
            hasFeedback
            help="Blacklisted elements will be removed from all pages"
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Add CSS selectors"
              dropdownStyle={{ display: "none" }}
              allowClear
              tokenSeparators={tokenSeparators}
              // onChange={handleChange}
            ></Select>
          </Form.Item>

          <Form.Item
            label="Whitelisted Page Elements (CSS selectors)"
            name={[
              "appData",
              "KNOWLEDGE_BASE",
              "indexWebpages",
              "whiteListedHtmlElement",
            ]}
            hasFeedback
            help="Only whitelisted elements will be picked up for training"
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Add CSS selectors"
              dropdownStyle={{ display: "none" }}
              allowClear
              tokenSeparators={tokenSeparators}
              // onChange={handleChange}
            ></Select>
          </Form.Item>
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon="ri-folder-5-line"
          title={`Study Public Knowledge Pages`}
          description="enable/disable study of knowledge base articles to knowledge repository"
          entityType="WORKSPACE"
          onSaved={() => reIndexKB()}
        >
          <div className="flex flex-row justify-end items-center mb-2">
            {websitesToCrawl.length > 0 && (
              <div>
                <Button
                  type="dashed"
                  icon={<i className="ri-refresh-line"></i>}
                  onClick={() => reIndexKB()}
                  loading={isReIndexingKB}
                >
                  Re-Index Knowledge Base
                </Button>
              </div>
            )}
          </div>
          <StyledSwitch
            label="Enable Knowledge Base Indexing"
            name={["appData", "KNOWLEDGE_BASE", "includeInKnowledgeDocuments"]}
          />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon="ri-tools-line"
          title={`Customize Magic Assistant (Beta)`}
          description="Set custom OPEN AI Keys, change models and edit Prompts of the Magic Assistant"
          entityType="WORKSPACE"
        >
          <StyledSwitch
            label="Enable Custom Prompts"
            name={[
              "appData",
              "KNOWLEDGE_BASE",
              "magicAssistantConfig",
              "isCustomPromptEnabled",
            ]}
          />

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const isCustomPromptEnabled = getFieldValue([
                "appData",
                "KNOWLEDGE_BASE",
                "magicAssistantConfig",
                "isCustomPromptEnabled",
              ]);
              if (isCustomPromptEnabled) {
                return (
                  <>
                    <MagicAssistantPromptWriter />
                  </>
                );
              }
              return <></>;
            }}
          </Form.Item>

          <StyledSwitch
            label="Enable Custom OPEN AI Keys"
            name={[
              "appData",
              "KNOWLEDGE_BASE",
              "magicAssistantConfig",
              "isCustomTokenEnabled",
            ]}
          />

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const isCustomTokenEnabled = getFieldValue([
                "appData",
                "KNOWLEDGE_BASE",
                "magicAssistantConfig",
                "isCustomTokenEnabled",
              ]);
              if (isCustomTokenEnabled) {
                return (
                  <>
                    <Form.Item
                      name={[
                        "appData",
                        "KNOWLEDGE_BASE",
                        "magicAssistantConfig",
                        "openAIToken",
                      ]}
                      label="OpenAI Token"
                      rules={[
                        {
                          required: true,
                          message: "Please input your API Token!",
                        },
                      ]}
                    >
                      <Input.Password placeholder="OpenAI API Token" />
                    </Form.Item>
                    <Form.Item
                      name={[
                        "appData",
                        "KNOWLEDGE_BASE",
                        "magicAssistantConfig",
                        "modelToUse",
                      ]}
                      label="Model to use"
                      rules={[
                        {
                          required: true,
                          message: "Select a model",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Model"
                        style={{ minWidth: 140 }}
                      >
                        {availableOpenAIModels.map((item) => (
                          <Select.Option value={item.name} key={item.name}>
                            <div className="flex flex-col">
                              <div className="font-bold">{item.name}</div>
                              <div className="text-gray-600">
                                {item.description}
                              </div>
                              <div className="font-bold">
                                {item.tokens} Token Limit | Pricing - Input:{" "}
                                {item.pricing.input}, Output:{" "}
                                {item.pricing.output}
                              </div>
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                );
              }
              return <></>;
            }}
          </Form.Item>
        </ConfigurationEditorComponent>

        <Divider />
        <div className="text-bold text-2xl mb-2 flex flex-row justify-between items-center">
          <div className="title">
            Knowledge Documents ({paginationState?.totalItems})
          </div>
          <Popconfirm
            placement="leftTop"
            title="Are you sure?"
            onConfirm={() => {
              clearAllDocuments();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="text"
              icon={<i className="ri-folder-reduce-line"></i>}
              loading={isClearingDocuments}
            >
              Clear All
            </Button>
          </Popconfirm>
        </div>
        <Table
          columns={columns}
          dataSource={documents}
          pagination={false}
          rowKey="id"
          className="mb-16"
          onRow={(record) => ({
            onClick: () => {
              changePanelState(AddEditKnowledgeDocument, true, {
                documentId: record.id!,
              });
            },
            className: "cursor-pointer",
          })}
        />

        <div className="flex flex-row justify-end items-center py-4">
          <Pagination
            pageSize={paginationState.pageSize}
            current={paginationState.currentPage}
            total={paginationState.totalItems}
            onChange={(currentPage, pageSize) =>
              setPaginationState({
                ...paginationState,
                pageSize: pageSize || 20,
                currentPage,
              })
            }
          />
        </div>
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
