import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";

export const AcademyViewerModal = ({
  visible,
  onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  return (
    (<Modal
      title={
        <ModalTitle
          title="Click Connector Academy"
          icon={<i className="ri-vip-crown-2-line"></i>}
        />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      footer={null}
      width={800}
      data-click-context="Click Connector Academy Modal"
    >
      <div className="flex flex-col justify-center items-center max-w-screen-xl w-full">
        <img
          src="/assets/illustrations/webinar-animate.svg"
          alt="Academy"
          style={{ maxHeight: 300 }}
        />
        <div className={"flex flex-col justify-center items-center my-8"}>
          <i className={"ri-time-line text-4xl"}></i>
          We are still in the process of making this video
          <div className="text-gray-600">We'll notify you when it's ready</div>
        </div>
      </div>
      <DarkModeBg />
    </Modal>)
  );
};
