import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Form, Input, Rate, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { iFeedbackRatingBlock } from "../itineries-model";

export const FeedbackAndRatingsBlock = ({
  holidayQuoteId,
  data
}: {
  holidayQuoteId: string;
  data: iFeedbackRatingBlock["data"];
}) => {
  const [form] = useForm();

  const { doAction: rateQuote, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: SDK => (req: { ratings: number; comment: string }) =>
        SDK.holidayQuotes.rateAndComment(holidayQuoteId!, req),
      failureMessage: "Something went wrong"
    }),
    [holidayQuoteId]
  );

  return (
    <div className="flex flex-col justify-center">
      Rate the quote out of 5 stars and give us feedback
      <Form
        layout="vertical"
        onFinish={async () => {
          try {
            await form.validateFields();
            const formValues = form.getFieldsValue();
            rateQuote(formValues);
          } catch (e) {
            message.error("Please check your input");
          }
        }}
        form={form}
      >
        <div className="flex flex-col justify-center items-center">
          <Form.Item label="" name={["ratings"]} rules={[]}>
            <Rate allowHalf className="text-4xl" />
          </Form.Item>
        </div>

        <Form.Item label="Feedback" name={["comment"]} rules={[]}>
          <Input.TextArea
            showCount
            maxLength={140}
            rows={2}
            placeholder="Type your feedback here"
          />
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const ratings = getFieldValue(["ratings"]);
            const comment = getFieldValue(["comment"]);
            if (ratings || comment) {
              return (
                <div className="flex flex-row justify-center items-center">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={isProcessing}
                    size="large"
                  >
                    Submit Feedback
                  </Button>
                </div>
              );
            }
            return <></>;
          }}
        </Form.Item>
      </Form>
    </div>
  );
};
