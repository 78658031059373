import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { Button, Modal, Tooltip } from "antd";
import classNames from "classnames";
import { ModalTitle } from "components/common/modal-title";
import { useAvailableAddOns } from "components/modules/organization-management/general/billing/use-available-addons";
import { push } from "connected-react-router";
import { DarkModeBg } from "dark-mode-bg";
import {
  AddChatWidget,
  AddEmailConnection,
  AddFacebookConnection,
  AddInstagramConnection,
  AddTelegramConnection,
  AddTelnyxConnection,
  AddTwitterConnection,
  AddWACloudConnection,
  AddWACloudNativeConnection,
  AddWhatsappConnection,
  AddWhatsappD360Connection,
} from "modal-registry";
import { useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { AvailableConnectionListVersion3 } from "../modules/connections/connection-list";

export const AddNewConnectionModal = ({
  visible,
  onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const { changePanelState } = useModalPanels();

  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  const availableAddons = useAvailableAddOns();

  const _availableConnectionList = useMemo(() => {
    if (isAdvancedMode) {
      return AvailableConnectionListVersion3;
    }
    const isFbTest = window.location.href.includes("fbtest");

    if (isFbTest) {
      return AvailableConnectionListVersion3.filter((item) => {
        return ![
          "TWITTER",
          "WHATS_APP_OFFICIAL",
          "WHATS_APP_CLOUD",
          // "WHATS_APP_CLOUD_NATIVE",
          "WHATS_APP_PRIVATE",
          // "TELNYX",
          "TELEGRAM",
        ].includes(item.key);
      });
    }
    return AvailableConnectionListVersion3.filter((item) => {
      return ![
        "TWITTER",
        "WHATS_APP_OFFICIAL",
        // "WHATS_APP_CLOUD",
        "WHATS_APP_CLOUD_NATIVE",
        "WHATS_APP_PRIVATE",
        // "TELNYX",
        "TELEGRAM",
      ].includes(item.key);
    });
  }, [isAdvancedMode]);

  const availableConnectionList = useMemo(() => {
    return _availableConnectionList.map((item) => {
      if (
        // item.key === "WHATS_APP_OFFICIAL" ||
        item.key === "WHATS_APP_PRIVATE"
        // item.key === "WHATS_APP_CLOUD"
      ) {
        if (availableAddons.availableWAAddons < 1) {
          return {
            ...item,
            disabledReason: "Purchase the Add-on before adding connection",
          };
        }
      }
      if (item.key === "TELNYX") {
        if (availableAddons.availableMobileAddons < 1) {
          return {
            ...item,
            disabledReason: "Purchase the Add-on before adding connection",
          };
        }
      }
      return { ...item, disabledReason: undefined };
    });
  }, [
    _availableConnectionList,
    availableAddons?.availableMobileAddons,
    availableAddons?.availableWAAddons,
  ]);

  const hasAtLeastOneDisabled = useMemo(
    () => availableConnectionList.find((item) => item.disabledReason),
    [availableConnectionList],
  );

  const store = useStore();

  return (
    <Modal
      title={
        <ModalTitle
          title={"Add New Connection"}
          icon={<i className="ri-add-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={360}
      data-click-context="Add new Connection Modal"
      bodyStyle={{ padding: 0, overflow: "hidden" }}
    >
      {availableConnectionList.map((item) => (
        <Tooltip
          key={item.key}
          title={
            item.disabledReason ||
            (item.requireManualPermission
              ? "Speak to Support to Activate"
              : undefined)
          }
        >
          <Button
            key={item.key}
            type="text"
            icon={item.icon}
            className={classNames(
              "font-bold p-8 text-xl border-0 border-b border-gray-200 dark:border-gray-800 justify-start ant-btn",
              {
                "disabled-section":
                  item.requireManualPermission || item.disabledReason,
              },
            )}
            block
            onClick={() => {
              if (item.requireManualPermission || item.disabledReason) {
                return;
              }
              if (item.key === "WEB_CHAT") {
                changePanelState(AddChatWidget, true, {});
              } else if (item.key === "EMAIL") {
                changePanelState(AddEmailConnection, true, {});
              } else if (item.key === "FACEBOOK") {
                changePanelState(AddFacebookConnection, true, {});
              } else if (item.key === "INSTAGRAM") {
                changePanelState(AddInstagramConnection, true, {});
              } else if (item.key === "WHATS_APP_PRIVATE") {
                changePanelState(AddWhatsappConnection, true, {});
              } else if (item.key === "WHATS_APP_OFFICIAL") {
                changePanelState(AddWhatsappD360Connection, true, {});
              } else if (item.key === "WHATS_APP_CLOUD") {
                changePanelState(AddWACloudConnection, true, {});
              } else if (item.key === "WHATS_APP_CLOUD_NATIVE") {
                changePanelState(AddWACloudNativeConnection, true, {});
              } else if (item.key === "TWITTER") {
                changePanelState(AddTwitterConnection, true, {});
              } else if (item.key === "TELNYX") {
                changePanelState(AddTelnyxConnection, true, {});
              } else if (item.key === "TELEGRAM") {
                changePanelState(AddTelegramConnection, true, {});
              }
              // setTimeout(() => {
              //   changePanelState(AddNewConnection, false, {});
              // }, 2000);
            }}
          >
            <div className="ml-2">{item.label}</div>
          </Button>
        </Tooltip>
      ))}

      {hasAtLeastOneDisabled && (
        <div className="w-full flex flex-row justify-center items-center p-4">
          <Button
            icon={<i className="ri-shopping-cart-line"></i>}
            type="dashed"
            onClick={() => {
              store.dispatch(push("/configurations/billing"));
            }}
          >
            Buy Add-ons
          </Button>
        </div>
      )}
      <DarkModeBg />
    </Modal>
  );
};
