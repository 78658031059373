import { Divider, Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { EditableTextArea } from "components/common/editable-text/editable-text";
import {
  iActionTypes,
  iMessageConfig,
} from "components/pages/bot-builder/data-model";
import { WithAttachments } from "components/pages/bot-builder/node-types/message-node/with-attachments";
import { useSelector } from "react-redux";
import { LoadAllGroups } from "store/modules/groups/groups.helpers";
import { selectAllGroupsQuery } from "store/modules/groups/groups.selectors";
import { selectOrganizationId } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { ActionSelector } from "../action-selector/action-selector";

import "./action-editor.scss";
import { ActionConfig, ActionConfigLabels } from "./actions";
import { iAction } from "./models";

const EmptyMessageConfig: iMessageConfig = {
  text: "",
  attachments: [],
};

export const MessageConfigInput = ({
  value,
  onChange,
}: {
  value?: iMessageConfig;
  onChange?: (val: iMessageConfig) => any;
}) => {
  const [state, setState] = useSimpleState(value || EmptyMessageConfig);
  const organizationId = useSelector(selectOrganizationId);

  useEffectWhen(
    () => {
      if (onChange) {
        onChange(state);
      }
    },
    [onChange, state],
    [state]
  );

  return (
    <WithAttachments
      initialAttachments={state.attachments}
      onAttachmentsChange={(attachments) => {
        setState({
          attachments,
        });
      }}
      id={"MessageConfigInput"}
      entityType="ORG_PRESETS"
      entityId={organizationId!}
    >
      <EditableTextArea
        value={state?.text || ""}
        placeholder="Your Message"
        onSave={(text) => {
          setState({
            text,
          });
        }}
      />
    </WithAttachments>
  );
};

export const ActionEditor = ({
  action,
  _setAction,
}: {
  action: iAction;
  _setAction: (action: iAction) => any;
}) => {
  const {
    state: { list: userGroups },
  } = useQueryWithStore(selectAllGroupsQuery, LoadAllGroups);

  const [form] = useForm();

  return (
    <div className="flex flex-col justify-center items-center">
      <Form
        layout="vertical"
        form={form}
        initialValues={action}
        onValuesChange={() => {
          _setAction({
            ...action,
            ...form.getFieldsValue(),
          });
        }}
        className="w-full"
      >
        <Form.Item
          label={<div className="font-bold">Select Action</div>}
          name={["action"]}
        >
          <ActionSelector />
        </Form.Item>

        <Form.Item shouldUpdate className="mb-0 empty-form">
          {({ getFieldValue }) => {
            const selectedOption: iActionTypes = getFieldValue(["action"]);
            return (
              <>
                <ActionConfig
                  actionType={selectedOption}
                  actionConfigHeader={
                    <Divider type="horizontal" className="w-full">
                      <div className="font-bold">
                        {ActionConfigLabels[selectedOption]}
                      </div>
                    </Divider>
                  }
                />
              </>
            );
          }}
        </Form.Item>
      </Form>
    </div>
  );
};
