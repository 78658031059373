import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Dropdown, Menu, Tag } from "antd";
import _ from "lodash";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { loadAllContactLists } from "store/modules/contact-lists/contact-lists.helpers";
import { selectAllContactLists } from "store/modules/contact-lists/contact-lists.selectors";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const MiniContactMarketingListEditor = ({
  contactId
}: {
  contactId: string;
}) => {
  const contact = useSelector(selectContactById(contactId));

  const { state: allContactLists, retry: reload } = useQueryWithStore(
    selectAllContactLists,
    loadAllContactLists
  );

  const contactListMap = useMemo(() => {
    return _.keyBy(allContactLists, "id");
  }, [allContactLists]);

  const { doAction: addToList } = useSDKActionWithDeps(
    () => ({
      action: SDK => listId => SDK.addContactToList(contactId, listId),
      failureMessage: "Something went wrong"
    }),
    [contactId]
  );
  const { doAction: removeFromList } = useSDKActionWithDeps(
    () => ({
      action: SDK => listId => SDK.removeContactFromList(contactId, listId),
      failureMessage: "Something went wrong"
    }),
    [contactId]
  );
  const { doAction: unsubscribeFromList } = useSDKActionWithDeps(
    () => ({
      action: SDK => listId =>
        SDK.unsubscribeContactFromList(contactId, listId),
      failureMessage: "Something went wrong"
    }),
    [contactId]
  );

  const listsThatCanBeAddedTo = useMemo(() => {
    const existingListId =
      contact?.marketingLists.map(record => record.id) || [];
    return allContactLists.filter(list => !existingListId.includes(list.id!));
  }, [contact?.marketingLists, allContactLists]);

  return (
    <div>
      {(contact?.marketingLists || []).map((list, index) => {
        return (
          <Dropdown
            key={index.toString()}
            overlay={
              <Menu data-click-context="Contact Marketing List Menu">
                <Menu.Item
                  key={"REMOVE"}
                  icon={<i className="ri-delete-back-line"></i>}
                  onClick={() => removeFromList(list.id!)}
                  disabled={list.isUnsubscribed}
                >
                  Remove
                </Menu.Item>
                <Menu.Item
                  key={"UNSUBSCRIBE"}
                  icon={<i className="ri-subtract-line"></i>}
                  onClick={() => unsubscribeFromList(list.id!)}
                  disabled={list.isUnsubscribed}
                >
                  Unsubscribe
                </Menu.Item>
              </Menu>
            }
            placement="bottomLeft"
            trigger={["click"]}
            arrow
            className="cursor-pointer"
          >
            <Tag
              color={list.isUnsubscribed ? "red" : undefined}
              key={index.toString()}
              className="cursor-pointer"
            >
              {contactListMap?.[list.id]?.data?.label}
            </Tag>
          </Dropdown>
        );
      })}
      {listsThatCanBeAddedTo.length > 0 && (
        <Dropdown
          overlay={
            <Menu data-click-context="Contact Marketing List Menu">
              {listsThatCanBeAddedTo.map(record => (
                <Menu.Item
                  key={record.id!}
                  icon={<i className="ri-add-line"></i>}
                  onClick={() => addToList(record.id!)}
                >
                  {contactListMap?.[record.id!]?.data?.label}
                </Menu.Item>
              ))}
            </Menu>
          }
          placement="bottomLeft"
          trigger={["click"]}
        >
          <Button
            type="dashed"
            size="small"
            icon={<i className="ri-add-line"></i>}
          >
            Add
          </Button>
        </Dropdown>
      )}
    </div>
  );
};
