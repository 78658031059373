import { iMessage } from "@sdk/conversations/conversations.models";
import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { MessageForwarder } from "./components/message-forwarder";

export const MessageForwarderModal = ({
  visible,
  onChangeVisibility,
  message
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  message: iMessage;
}) => {
  return (<>
    <Modal
      title={
        <ModalTitle
          title={"Forward Message"}
          icon={<i className="ri-chat-forward-line"></i>}
        />
      }
      open={visible}
      data-click-context="Message Forwarder Modal"
      onCancel={() => onChangeVisibility(false)}
      footer={null}
      width={800}
    >
      <MessageForwarder
        message={message}
        onSent={() => onChangeVisibility(false)}
      />
      <DarkModeBg />
    </Modal>
  </>);
};
