import { Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "react-redux";
import { iFilterOptionOperatorSchema } from "./filter-config-schema";

export const SimpleFilterSelect = ({
  spec,
  autoFocus,
  ...props
}: {
  autoFocus?: boolean;
  spec: iFilterOptionOperatorSchema["valueField"];
}) => {
  const store = useStore();
  const options = useMemo(() => {
    if (spec?.config?.options) {
      return spec?.config?.options;
    }
    if (spec?.config?.optionGenerator) {
      return spec?.config?.optionGenerator(store.getState());
    }
    return [];
  }, [spec?.config, store]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 0);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Select
      placeholder="Select an option"
      options={options}
      mode={spec?.config?.selectionMode}
      // defaultOpen={autoFocus}
      open={open}
      onDropdownVisibleChange={setOpen}
      autoFocus
      showSearch={Boolean(spec?.config?.showSearch)}
      filterOption={true}
      optionFilterProp={spec?.config?.optionFilterProp}
      dropdownStyle={spec?.config?.dropdownStyle}
      {...props}
    />
  );
};
