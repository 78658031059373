import { useSDK } from "@sdk";
import { List } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { ChatListItem } from "components/modules/conversations/components/chat-list/chat-list-item";
import { CustomContactData } from "components/modules/conversations/components/custom-contact-data-editor/custom-contact-data-editor";
import { EditContactSection } from "components/modules/crm/contacts/components/edit-contact-mini/edit-contact-mini";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { setConversations } from "store/modules/conversations/conversations.slice";

export const SimilarContactsExplorer_ContactProfile = ({
  contactId,
  onEditCustomer,
}: {
  contactId: string;
  onEditCustomer: (customerId: string) => any;
}) => {
  const contact = useSelector(selectContactById(contactId));
  const dispatch = useDispatch();

  const conversationSearchResult = useSDK(
    async (SDK) => {
      const results = await SDK.queryConversations({
        query: { contactId },
        options: {},
      });
      dispatch(setConversations(results.docs));
      return results;
    },
    [contactId],
    !contactId,
    {
      data: {
        docs: [],
      },
      isLoading: false,
      error: false,
    }
  );

  const onSelectConversation = (conversationId: string) => {
    dispatch(push(`/conversations/auto/${conversationId}`));
  };

  const onEditContact = () => {
    onEditCustomer(contactId);
  };

  return (
    <div className="flex flex-row w-full contact-profile h-full">
      <div className="section flex-1 overflow-y-auto">
        {/* <div className="avatar-section flex flex-row justify-center items-center mt-12 mb-20">
          <div className="flex flex-col justify-center items-center">
            <div className="edit-user-avatar">
              <ContactAvatar contactId={contactId} size={80} />
            </div>
            <div className="font-bold text-2xl">
              {contact?.data.firstName} {contact.data.lastName}
            </div>
          </div>
        </div> */}

        <div className="contact-details">
          <EditContactSection contactId={contactId} />
        </div>
        <div className="test p-4">
          <div className="font-bold pl-2">Additional Data</div>
          <CustomContactData contactId={contactId} />
        </div>
      </div>

      <div
        className="data-preview overflow-y-auto pl-4 h-full"
        style={{ minHeight: 400, minWidth: 320 }}
      >
        <div className="text-lg font-bold mb-4">Recent Conversations</div>
        <div className="">
          <List
            itemLayout="horizontal"
            dataSource={conversationSearchResult.data.docs || []}
            loading={
              conversationSearchResult.isLoading
                ? {
                    indicator: <LoadingIndicatorWithoutSpin />,
                  }
                : false
            }
            renderItem={(item) => (
              <ChatListItem
                onConversationSelected={onSelectConversation}
                conversationId={item.id!}
              />
            )}
            locale={{
              emptyText: (
                <EmptyData
                  text="No conversations to show"
                  icon="ri-chat-4-line"
                />
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};
