import { EmailBlock_CTA } from "@libs/@email-body-generator/blocks/cta-block";
import { EmailBlock_EmptySpace } from "@libs/@email-body-generator/blocks/empty-space";
import { EmailBlock_Header } from "@libs/@email-body-generator/blocks/header.block";
import { EmailBlock_Logo } from "@libs/@email-body-generator/blocks/logo-block";
import { EmailBlock_Text } from "@libs/@email-body-generator/blocks/text-block";
import { EmailBlock_MessageThread } from "@libs/@email-body-generator/blocks/text-message-thread";
import { GenerateBaseEmailTemplate } from "@libs/@email-body-generator/generate-base-email-template";
import { iLiveChatConnectionData } from "@sdk/conversations/conversations.models";
import { Button } from "antd";
import { cloneDeep } from "lodash";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectChatWidgetByConnectionId } from "store/modules/chat-widgets/chat-widgets.selectors";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { DeepAssign } from "utils/deep-assign";

export const EmailFollowupTemplate = ({
  connectionId,
  tempConnectionData,
}: {
  connectionId: string;
  tempConnectionData: any;
}) => {
  const { state: connection, retry: reloadConnection } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const organization = useSelector(selectOrganization);

  const { state: widget, retry: reload } = useQueryWithStore(
    selectChatWidgetByConnectionId(connectionId),
    loadAllChatWidgets(),
    [connectionId],
  );

  const generateHtmlEmail = useCallback(() => {
    const { logo: widgetLogo } = widget?.data || {};

    const connectionData = cloneDeep(connection.data || {});
    DeepAssign(connectionData, tempConnectionData);

    const {
      notifyClientViaEmail: {
        senderName = "Click Connector",
        customLocale = {},
      } = {},
      emailConfiguration,
    } = (connectionData as iLiveChatConnectionData) || {
      notifyClientViaEmail: {
        customLocale: {},
      },
      emailConfiguration: {},
    };

    const widgetColor =
      widget?.configurations?.appearance?.colorPallet?.primary;

    const logo =
      widgetLogo ||
      emailConfiguration?.logo ||
      organization?.data?.logo ||
      "https://cc.clickconnector.io/assets/email/logo.png";

    const companyName = organization?.data?.name || "Click Connector";

    const colorScheme =
      widgetColor ||
      emailConfiguration?.primaryColor ||
      organization?.data?.primaryColor ||
      "#2b66ff";

    const ctaLink = emailConfiguration?.homepageAddress || "";

    const HtmlContent = GenerateBaseEmailTemplate(
      [
        // Modify the logo
        EmailBlock_Logo({
          url: logo,
          companyName: companyName,
        }),
        EmailBlock_Header({
          image:
            customLocale?.bannerImage ||
            `https://cc.clickconnector.io/assets/email/email-update.png`,
          title: customLocale?.title || "New Reply from the Team",
        }),
        EmailBlock_MessageThread({
          messages: [
            {
              text: "Test Response",
              timestamp: Date.now(),
              sentBy: "Tom Brown",
            },
          ],
        }),
        EmailBlock_EmptySpace(),
        EmailBlock_Text({
          text: `Continue this conversation by replying to this email`,
        }),
        EmailBlock_CTA({
          label: "View Conversation",
          url: ctaLink,
          buttonColor: colorScheme,
        }),
        EmailBlock_EmptySpace(),
      ],
      // Modify this
      companyName,
      {
        // Modify the primary COlor
        primaryColor: colorScheme,
        preBodyContent: `<p style="text-align:center">// Please enter your reply above this line.
                  </br>Replies below this line will not be received. \\\\\\</p>`,
      },
    );

    return HtmlContent;
  }, [
    connection.data,
    organization?.data?.logo,
    organization?.data?.name,
    organization?.data?.primaryColor,
    tempConnectionData,
    widget?.configurations?.appearance?.colorPallet?.primary,
    widget?.data,
  ]);

  return (
    <div className="flex flex-row justify-center items-center">
      <Button
        type="dashed"
        icon={<i className="ri-eye-line"></i>}
        onClick={(e) => {
          const win = window.open("", "Email Preview", "location=no");
          if (win) {
            win.document.documentElement.innerHTML = generateHtmlEmail();
          }
        }}
      >
        Preview Email
      </Button>
    </div>
  );

  //   return <div dangerouslySetInnerHTML={{ __html: HtmlContent }}></div>;
};
