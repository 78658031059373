import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iChatBot } from "@sdk/chat-bots/chat-bots.models";
import { iConnectionType } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Dropdown, Menu, message, Modal, Space, Spin } from "antd";
import {
  LoadingIndicatorWithoutSpin,
  LoadingIndicatorWithSpin,
} from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { ConnectionIcons } from "components/modules/connections/helpers/connection-icons";
import { BotBuilderPlayground } from "components/pages/bot-builder/bot-builder.playground";
import { iChatBotFlowConfig } from "components/pages/playground/offline-chat-bot/chat-bot-state";
import { GlobalConfig } from "config";
import { DarkModeBg } from "dark-mode-bg";
import { cloneDeep } from "lodash";
import { ConversationsPicker, EditChatBot } from "modal-registry";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { selectChatBotById } from "store/modules/chat-bots/chat-bots.selectors";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useThrottle } from "utils/hooks/use-throttle";

const testFlow = {
  nodes: [
    {
      width: 320,
      height: 375,
      id: "START",
      type: "MESSAGE_NODE",
      data: {
        text: "Hey  {CONTACT_FIRST_NAME},\nHow would you like to help you today?",
        quickButtons: [
          {
            id: "1",
            label: "Ask a Question",
          },
          {
            id: "2",
            label: "Report a Bug",
          },
          {
            id: "1654339871851",
            label: "Request a Feature",
          },
        ],
      },
      position: {
        x: -240,
        y: 30,
      },
      positionAbsolute: {
        x: -240,
        y: 30,
      },
      z: 0,
      handleBounds: {
        source: [
          {
            id: "DEFAULT",
            position: "bottom",
            x: 94.852294921875,
            y: 163.02740478515625,
            width: 20,
            height: 20,
          },
          {
            id: "1",
            position: "right",
            x: 179.58697509765625,
            y: 75.8818359375,
            width: 20,
            height: 20,
          },
          {
            id: "2",
            position: "right",
            x: 179.58697509765625,
            y: 99.91110229492188,
            width: 20,
            height: 20,
          },
        ],
        target: null,
      },
      selected: false,
      dragging: false,
    },
    {
      width: 320,
      height: 283,
      id: "9d9d02b0",
      type: "MESSAGE_NODE",
      data: {
        text: "Oops! That's embarrassing! Can you explain a little more about this bug? Try to attach a screenshot if you can. We'll look into this as soon as possible",
        quickButtons: [],
      },
      position: {
        x: 300,
        y: 390,
      },
      positionAbsolute: {
        x: 300,
        y: 390,
      },
      selected: false,
      dragging: false,
    },
    {
      width: 320,
      height: 436,
      id: "123facf8",
      type: "ACTION_NODE",
      data: {
        action: "ROUTE_TO_HUMAN",
        data: {
          routingMethod: "INVITE",
          routeOnlyToOnlineUsers: true,
        },
      },
      position: {
        x: 1170,
        y: 255,
      },
      positionAbsolute: {
        x: 1170,
        y: 255,
      },
      selected: false,
      dragging: false,
    },
    {
      width: 320,
      height: 283,
      id: "434fda2c",
      type: "MESSAGE_NODE",
      data: {
        text: "We are excited to hear your request! Please explain your request in detail, including the use cases, so that we can forward it to our product team and build it for you.",
        quickButtons: [],
      },
      position: {
        x: 285,
        y: 825,
      },
      selected: false,
      positionAbsolute: {
        x: 285,
        y: 825,
      },
      dragging: false,
    },
    {
      width: 320,
      height: 189,
      id: "b0f1003d",
      type: "GPT_ASSISTANT_NODE",
      data: {
        inputType: "GPT_ASSISTANT",
        text: "Shoot Your Question. Our Magic Assistant is here to you help!",
        quickButtons: [],
      },
      position: {
        x: 285,
        y: 60,
      },
      positionAbsolute: {
        x: 285,
        y: 60,
      },
      selected: false,
      dragging: false,
    },
    {
      width: 320,
      height: 213,
      id: "3389bd27",
      type: "MESSAGE_NODE",
      data: {
        text: "You will now be redirected to our support team. Please note that response times from the support team may be delayed, but rest assured that your query will be answered.",
        quickButtons: [],
        processNextNodeImmediately: true,
      },
      position: {
        x: 720,
        y: 45,
      },
      selected: false,
      positionAbsolute: {
        x: 720,
        y: 45,
      },
      dragging: false,
    },
  ],
  edges: [
    {
      source: "START",
      sourceHandle: "1654339871851",
      target: "94c3d27a",
      targetHandle: "INPUT",
      id: "reactflow__edge-START1654339871851-94c3d27aINPUT",
      selected: false,
    },
    {
      source: "a3be6ff9",
      sourceHandle: "1659578445586",
      target: "f4cc34c9",
      targetHandle: "INPUT",
      id: "reactflow__edge-a3be6ff91659578445586-f4cc34c9INPUT",
    },
    {
      source: "a3be6ff9",
      sourceHandle: "1659578436994",
      target: "a8533632",
      targetHandle: "INPUT",
      id: "reactflow__edge-a3be6ff91659578436994-a8533632INPUT",
    },
    {
      source: "94c3d27a",
      sourceHandle: "1659578118655",
      target: "0c049062",
      targetHandle: "INPUT",
      id: "reactflow__edge-94c3d27a1659578118655-0c049062INPUT",
    },
    {
      source: "0c049062",
      sourceHandle: "DEFAULT",
      target: "a3be6ff9",
      targetHandle: "INPUT",
      id: "reactflow__edge-0c049062DEFAULT-a3be6ff9INPUT",
    },
    {
      source: "0416d0cb",
      sourceHandle: "DEFAULT",
      target: "690503a1",
      targetHandle: "INPUT",
      id: "reactflow__edge-0416d0cbDEFAULT-690503a1INPUT",
    },
    {
      source: "START",
      sourceHandle: "1",
      target: "c4a68243",
      targetHandle: "INPUT",
      id: "reactflow__edge-START1-c4a68243INPUT",
    },
    {
      source: "fd7895ed",
      sourceHandle: "DEFAULT",
      target: "e94095b3",
      targetHandle: "INPUT",
      id: "reactflow__edge-fd7895edDEFAULT-e94095b3INPUT",
    },
    {
      source: "e94095b3",
      sourceHandle: "1659578786497",
      target: "517599da",
      targetHandle: "INPUT",
      id: "reactflow__edge-e94095b31659578786497-517599daINPUT",
    },
    {
      source: "517599da",
      sourceHandle: "DEFAULT",
      target: "f4cc34c9",
      targetHandle: "INPUT",
      id: "reactflow__edge-517599daDEFAULT-f4cc34c9INPUT",
    },
    {
      source: "c4a68243",
      sourceHandle: "DEFAULT",
      target: "fd7895ed",
      targetHandle: "INPUT",
      id: "reactflow__edge-c4a68243DEFAULT-fd7895edINPUT",
    },
    {
      source: "START",
      sourceHandle: "2",
      target: "1bf2210f",
      targetHandle: "INPUT",
      id: "reactflow__edge-START2-1bf2210fINPUT",
    },
    {
      source: "START",
      sourceHandle: "1659578072454",
      target: "1bf2210f",
      targetHandle: "INPUT",
      id: "reactflow__edge-START1659578072454-1bf2210fINPUT",
    },
    {
      source: "1bf2210f",
      sourceHandle: "DEFAULT",
      target: "0b8061ff",
      targetHandle: "INPUT",
      id: "reactflow__edge-1bf2210fDEFAULT-0b8061ffINPUT",
    },
    {
      source: "0b8061ff",
      sourceHandle: "DEFAULT",
      target: "0416d0cb",
      targetHandle: "INPUT",
      id: "reactflow__edge-0b8061ffDEFAULT-0416d0cbINPUT",
    },
    {
      source: "690503a1",
      sourceHandle: "DEFAULT",
      target: "f4cc34c9",
      targetHandle: "INPUT",
      id: "reactflow__edge-690503a1DEFAULT-f4cc34c9INPUT",
    },
    {
      source: "e94095b3",
      sourceHandle: "1659578796291",
      target: "1bf2210f",
      targetHandle: "INPUT",
      id: "reactflow__edge-e94095b31659578796291-1bf2210fINPUT",
    },
    {
      source: "a8533632",
      sourceHandle: "DEFAULT",
      target: "1bf2210f",
      targetHandle: "INPUT",
      id: "reactflow__edge-a8533632DEFAULT-1bf2210fINPUT",
    },
    {
      source: "94c3d27a",
      sourceHandle: "1659578121408",
      target: "1bf2210f",
      targetHandle: "INPUT",
      id: "reactflow__edge-94c3d27a1659578121408-1bf2210fINPUT",
    },
    {
      source: "9d9d02b0",
      sourceHandle: "DEFAULT",
      target: "123facf8",
      targetHandle: "INPUT",
      id: "reactflow__edge-9d9d02b0DEFAULT-123facf8INPUT",
    },
    {
      source: "434fda2c",
      sourceHandle: "DEFAULT",
      target: "123facf8",
      targetHandle: "INPUT",
      id: "reactflow__edge-434fda2cDEFAULT-123facf8INPUT",
    },
    {
      source: "b0f1003d",
      sourceHandle: "DEFAULT",
      target: "3389bd27",
      targetHandle: "INPUT",
      id: "reactflow__edge-b0f1003dDEFAULT-3389bd27INPUT",
    },
    {
      source: "3389bd27",
      sourceHandle: "DEFAULT",
      target: "123facf8",
      targetHandle: "INPUT",
      id: "reactflow__edge-3389bd27DEFAULT-123facf8INPUT",
    },
  ],
  viewport: {
    x: 214.36700122985076,
    y: 94.15239053364536,
    zoom: 0.7205123843157095,
  },
};

const cleanFlowData = (data: iChatBotFlowConfig) => {
  data = cloneDeep(data);
  data.edges = data.edges.filter((edge) => {
    const targetNodeId = edge.target;
    const sourceNodeId = edge.source;
    const targeNode = data.nodes.find((item) => item.id === targetNodeId);
    const sourceNode = data.nodes.find((item) => item.id === sourceNodeId);
    if (targetNodeId && !targeNode) {
      return false;
    }
    if (sourceNodeId && !sourceNode) {
      return false;
    }
    return true;
  });
  return data;
};

export const BotBuilderModal = ({
  chatBotId,
  visible,
  onChangeVisibility,
  onEdited,
}: {
  chatBotId: string;
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onEdited?: () => any;
}) => {
  const chatBot = useSelector(selectChatBotById(chatBotId));

  const [isInitialDataLoaded, setInitialDataLoaded] = useState(false);
  const [isDataChanged, setDataChanged] = useState(false);

  const autoSave = useThrottle(
    () => {
      if (!botBuilderRef.current || !setInitialDataLoaded) {
        return;
      }
      const flowData = botBuilderRef.current.getState();
      if (chatBotId) {
        onEditChatBot({
          flow: flowData,
        }).then((d) => {
          setDataChanged(false);
        });
      }
    },
    2000,
    { leading: false, trailing: true },
  );

  const loadChatBotFlow = useCallback(
    (chatBot: iChatBot<any>) => {
      if (botBuilderRef.current) {
        if (chatBot.flow.nodes) {
          botBuilderRef.current.setNodes(chatBot.flow.nodes);
        }
        if (chatBot.flow.edges) {
          botBuilderRef.current.setEdges(chatBot.flow.edges);
        }
        if (chatBot.flow.viewport) {
          botBuilderRef.current.setViewport(chatBot.flow.viewport);
        }
        setDataChanged(false);
        const onDataChanged = () => {
          setDataChanged(true);
          autoSave();
        };
        const timer = setTimeout(() => {
          botBuilderRef?.current?.refreshView();
        }, 500);

        const timer2 = setTimeout(() => {
          if (botBuilderRef.current) {
            botBuilderRef.current.onChangeCallback.current = onDataChanged;
          }
        }, 1200);
      }
    },
    [autoSave],
  );

  useEffect(() => {
    if (chatBot && !isInitialDataLoaded) {
      if (botBuilderRef.current) {
        loadChatBotFlow(chatBot);
        setInitialDataLoaded(true);
      } else {
        let retries = 0;
        const timer = setInterval(() => {
          if (botBuilderRef.current) {
            loadChatBotFlow(chatBot);
            setInitialDataLoaded(true);
            clearInterval(timer);
          } else if (retries < 5) {
            retries++;
          } else {
            clearInterval(timer);
          }
        }, 500);
      }
      return () => {
        // clearTimeout(timer);
        // clearTimeout(timer2);
        // currentBotBuilderRef.onChangeCallback.current = null;
      };
    }
  }, [autoSave, chatBot, isInitialDataLoaded, loadChatBotFlow]);

  const {
    doAction: onEditChatBot,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) => {
        const cleanedFlowData = cleanFlowData(values.flow);

        return SDK.chatBots
          .editById(chatBotId, {
            ...values,
            ...(values.flow
              ? {
                  flow: cleanedFlowData,
                }
              : {}),
          })
          .then((res) => {
            onEdited && onEdited();
          });
      },
      // successMessage: "Chatbot has been edited",
      failureMessage: "Something went wrong",
    }),
    [chatBotId, onEdited],
  );

  const botBuilderRef = useRef<any>();

  // useEffect(() => {
  //   if (visible) {
  //     const $style = document.createElement("style");
  //     document.head.appendChild($style);
  //     $style.innerHTML = `body { zoom:1 !important }`;
  //     return () => {
  //       document.head.removeChild($style);
  //     };
  //   }
  // }, [visible]);

  const store = useStore();

  const { state: allWidgets } = useQueryWithStore(
    selectAllChatWidgets,
    loadAllChatWidgets(),
    [],
  );
  const testSandboxChatBot = useCallback(async () => {
    if (!botBuilderRef.current) {
      return;
    }
    const flowData = botBuilderRef.current.getState();
    await onEditChatBot({
      flow: flowData,
    });
    const chatWidget = selectAllChatWidgets(store.getState())[0];
    if (chatWidget) {
      const path = window.location.href.includes("localhost")
        ? "sandbox-local"
        : "sandbox";
      const url = `${GlobalConfig.CHAT_WIDGET_BASE}/${path}?widgetId=${chatWidget.id}&chatBotId=${chatBotId}`;
      window.open(url, "chat-widget-sandbox");
    }
  }, [chatBotId, onEditChatBot, store]);

  const { triggerTempModal, changePanelState } = useModalPanels();

  const { doAction: activateChatBot, isProcessing: isActivatingChatbot } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (conversationId: string, chatBotId: string) =>
          SDK.activateChatBot(conversationId, { chatBotId }),
        successMessage: "Bot has been activated on the conversation",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const testChatBotOnRecentConversation = useCallback(
    async (connectionType: iConnectionType) => {
      if (!botBuilderRef.current) {
        return;
      }
      const flowData = botBuilderRef.current.getState();
      await onEditChatBot({
        flow: flowData,
      });
      triggerTempModal(ConversationsPicker, {
        query: {
          connectionType,
        },
        onConversationSelected: (conversationId, changeModalVisibility) => {
          activateChatBot(conversationId, chatBotId);
          changeModalVisibility(false);
        },
      });
    },
    [activateChatBot, chatBotId, onEditChatBot, triggerTempModal],
  );

  useEffect(() => {
    if (!visible) {
      setInitialDataLoaded(false);
    }
  }, [visible]);

  return (
    <Modal
      title={
        <div className="flex flex-row justify-between items-center">
          <ModalTitle
            icon={<i className="ri-robot-line"></i>}
            title={
              <div
                className="flex flex-row items-center justify-center cursor-pointer"
                onClick={() => triggerTempModal(EditChatBot, { chatBotId })}
              >
                {chatBot?.label || "unlabelled Chatbot"}{" "}
                <i className="ri-edit-line ml-2"></i>
              </div>
            }
          />
          {isDataChanged && (
            <div className="flex flex-row justify-center items-center mr-8">
              <div className="icon mr-2">
                <LoadingIndicatorWithSpin fontSize={16} />
              </div>
              Saving
            </div>
          )}
        </div>
      }
      open={visible}
      footer={
        <div>
          <Space>
            <Button onClick={() => onChangeVisibility(false)}>Cancel</Button>
            <Dropdown
              overlay={
                <Menu data-click-context="Save Bot and Test Menu">
                  <Menu.Item
                    icon={<i className={ConnectionIcons.WEB_CHAT}></i>}
                    onClick={() => testSandboxChatBot()}
                  >
                    New Live Chat Session
                  </Menu.Item>
                  <Menu.Item
                    icon={<i className={ConnectionIcons.FACEBOOK}></i>}
                    onClick={() => testChatBotOnRecentConversation("FACEBOOK")}
                  >
                    Recent Facebook Chat
                  </Menu.Item>
                  <Menu.Item
                    icon={<i className={ConnectionIcons.INSTAGRAM}></i>}
                    onClick={() => testChatBotOnRecentConversation("INSTAGRAM")}
                  >
                    Recent Instagram Chat
                  </Menu.Item>
                  <Menu.Item
                    icon={<i className={ConnectionIcons.WHATS_APP_PRIVATE}></i>}
                    onClick={() =>
                      testChatBotOnRecentConversation("WHATS_APP_PRIVATE")
                    }
                  >
                    Recent Whatsapp Chat
                  </Menu.Item>
                </Menu>
              }
              placement="topCenter"
              trigger={["click"]}
              arrow
            >
              <Button
                type="primary"
                icon={<i className="ri-flask-line"></i>}
                className="font-bold"
              >
                Save and Test <i className="ri-arrow-drop-down-line"></i>
              </Button>
            </Dropdown>

            <Button
              type="primary"
              icon={<i className="ri-save-line"></i>}
              className="font-bold"
              onClick={async () => {
                try {
                  if (!botBuilderRef.current) {
                    return;
                  }
                  const flowData = botBuilderRef.current.getState();

                  const nodes = flowData.nodes || [];

                  // console.log("flowData", nodes);
                  flowData.nodes = nodes.map((item) => ({
                    ...item,
                    data: {
                      ...(item.data || {}),
                      quickButtons: item?.data?.quickButtons
                        ? item.data.quickButtons.map((button) => ({
                            ...button,
                            label: (button.label || "").trim(),
                          }))
                        : [],
                    },
                  }));
                  await onEditChatBot({
                    flow: flowData,
                  });
                  onChangeVisibility(false);
                } catch (e) {
                  console.log("Error while saving bot", e);
                  message.error("Please check your input");
                }
              }}
            >
              Save and Exit
            </Button>
          </Space>
        </div>
      }
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Edit Chatbot Modal"
      className="very-big-modal"
      destroyOnClose={true}
    >
      {chatBot && chatBotId && visible && chatBot?.id === chatBotId && (
        <Spin
          spinning={!isInitialDataLoaded}
          indicator={<LoadingIndicatorWithoutSpin />}
          wrapperClassName="flex-1 flex fill-spinner"
        >
          <BotBuilderPlayground botBuilderRef={botBuilderRef} />
        </Spin>
      )}
      <DarkModeBg />
    </Modal>
  );
};
