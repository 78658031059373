import { Alert, Button, Form, Input, message, Modal, Tag } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getTokens } from "utils/extract-tokens";
import { uuidv4 } from "utils/generate-uuid";

import { SimpleWysiwyg } from "components/common/simple-wysiwyg/simple-wysiwyg";
import { AvailableTokens } from "../conversations/components/chat-input/available-message-tokens";
import "./email-signature-editor-modal.scss";
import { iEmailSignature } from "./signature-manager-state-manager";

export const EmailSignatureEditorModal = ({
  visible,
  mode,
  onChangeVisibility,
  onSaved,
  initialValue,
}: {
  visible: boolean;
  mode: "ADD" | "EDIT";
  onChangeVisibility: (state: boolean) => any;
  onSaved: (value: iEmailSignature) => any;
  initialValue?: iEmailSignature;
}) => {
  const [form] = Form.useForm();

  // const draftJsRef = useRef<any>();

  useEffect(() => {
    form.setFieldsValue({
      label: initialValue?.label,
      content: initialValue?.content,
    });
    // if (draftJsRef.current) {
    //   draftJsRef.current.setHtmlContent(initialValue?.content || "");
    // }
  }, [visible, mode, initialValue, form]);

  const [value, setValue] = useState("");

  const capturedTokens = useMemo(() => {
    const tokens = getTokens(value);
    const validTokens = tokens.filter((token) =>
      AvailableTokens.includes(token),
    );
    const invalidTokens = tokens.filter(
      (token) => !AvailableTokens.includes(token),
    );
    return { validTokens, invalidTokens, all: tokens };
  }, [value]);

  const onFinish = useCallback(async () => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();
      // const htmlValue = draftJsRef.current?.getHtmlContent() || "";
      onSaved({
        id: uuidv4(),
        ...((initialValue || {}) as any),
        ...formValues,
        // content: htmlValue,
      });
    } catch (e) {
      message.error("Please check your input");
    }
  }, [form, initialValue, onSaved]);

  return (
    <Modal
      title={
        mode === "ADD" ? (
          <ModalTitle
            icon={<i className="ri-ball-pen-line"></i>}
            title="Add Signature"
          />
        ) : (
          <ModalTitle
            icon={<i className="ri-ball-pen-line"></i>}
            title="Edit Signature"
          />
        )
      }
      open={visible}
      onOk={onFinish}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save"
      okButtonProps={{ className: "font-bold" }}
      data-click-context="Signature Modal"
      width={720}
      className="signature-editor-modal"
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          type: "SIMPLE_TEXT",
        }}
        onFinish={onFinish}
        onValuesChange={(e) => {
          const content = form.getFieldValue("content");
          setValue(content);
        }}
      >
        <Form.Item
          name="label"
          label={<div className="font-bold">Label</div>}
          rules={[
            {
              required: true,
              message: "Please enter a label",
            },
          ]}
        >
          <Input placeholder="Label" size="large" />
        </Form.Item>

        <Form.Item
          name="content"
          label={<div className="font-bold">Signature</div>}
          rules={[
            {
              required: true,
              message: "Signature is missing",
            },
          ]}
        >
          <SimpleWysiwyg />
        </Form.Item>

        {/* <div className="draft-js-container border border-gray-200 dark:border-gray-700 rounded-lg">
          <BraftJSInput
            initialValue={initialValue?.content || ""}
            additionalExcludedControls={
              defaultAdditionalExcludedBraftControllers
            }
            ref={draftJsRef}
            onChange={setValue}
            showControlsOnTop={true}
          />
        </div> */}

        {capturedTokens.all.length === 0 && (
          <Alert
            message={<b>Did you know?</b>}
            type="info"
            description={
              <div>
                You can use variable tokens in your signature
                <div className="font-bold my">Available Tokens:</div>
                {AvailableTokens.map((item, index) => (
                  <Tag key={index.toString()}>{item}</Tag>
                ))}
              </div>
            }
            className="mt-3"
          />
        )}

        {capturedTokens.all.length !== 0 && (
          <div className="token-container">
            <div className="font-bold my">Valid Tokens:</div>
            {capturedTokens.validTokens.map((item, index) => (
              <Tag key={index.toString()}>{item}</Tag>
            ))}
            <div className="font-bold my">Invalid Tokens:</div>
            {capturedTokens.invalidTokens.map((item, index) => (
              <Tag key={index.toString()}>{item}</Tag>
            ))}
            <div className="font-bold my">Available Tokens:</div>
            {AvailableTokens.map((item, index) => (
              <Tag key={index.toString()}>{item}</Tag>
            ))}
          </div>
        )}
        <Button htmlType="submit" type="primary" className="hidden">
          Save
        </Button>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
