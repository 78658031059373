import { Form, Select, Switch } from "antd";
import { StyledSwitch } from "components/common/styled-swtich";
import { RoutingRules } from "components/modules/conversations/components/routing-rules/routing-rules";
import { ChatBotSelector } from "components/pages/chat-bots/chat-bot-selector/chat-bot-selector";
import { ConnectionAutomationConfig } from "../../common-automation-configs/connection-automations-config";
import { ConnectionDataEditor } from "../../common-connection-settings/connection-data-editor";

export const FacebookAutomationConfig = ({
  connectionId,
}: {
  connectionId?: string;
}) => {
  return (
    <>
      <ConnectionAutomationConfig connectionId={connectionId} />
      <div className="animated fadeInLeftMin">
        <ConnectionDataEditor
          connectionId={connectionId!}
          icon={"ri-route-line"}
          title="Auto Campaign Routing"
          description="Route Conversations based on campaigns"
        >
          <div className="auto-trigger flex flex-row items-center justify-between">
            <div className="font-bold">Enable 'Auto Campaign Routing'</div>
            <Form.Item
              name={["data", "autoRouteConfig", "active"]}
              hasFeedback={false}
              valuePropName="checked"
              style={{ marginBottom: 0, marginLeft: 5 }}
            >
              <Switch />
            </Form.Item>
          </div>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => {
              const configEnabled = getFieldValue([
                "data",
                "autoRouteConfig",
                "active",
              ]);
              if (configEnabled) {
                return (
                  <div className="mt-4">
                    <Form.Item
                      label=""
                      name={["data", "autoRouteConfig", "rules"]}
                    >
                      <RoutingRules />
                    </Form.Item>
                  </div>
                );
              }
              return <></>;
            }}
          </Form.Item>
        </ConnectionDataEditor>
        <ConnectionDataEditor
          connectionId={connectionId!}
          icon={"ri-inbox-archive-line"}
          title="Auto Archive Unsubscribes"
          description="Unclutter your inboxes by archiving conversations with 'Unsubscribe' or 'Pause Question' Signals"
        >
          <StyledSwitch
            name={["data", "autoArchiveUnsubscribes", "isEnabled"]}
            label="Enabled"
          />
        </ConnectionDataEditor>

        <ConnectionDataEditor
          connectionId={connectionId!}
          icon={"ri-robot-line"}
          title="Connect Chat Bot"
          description="Connect a Chat Bot when a conversation starts"
        >
          <StyledSwitch
            name={["data", "startWithChatBot", "isEnabled"]}
            label="Enabled"
          />
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const configEnabled = getFieldValue([
                "data",
                "startWithChatBot",
                "isEnabled",
              ]);
              if (configEnabled) {
                return (
                  <div>
                    <Form.Item
                      name={["data", "startWithChatBot", "chatBotId"]}
                      label="Select Chatbot Flow"
                      rules={[
                        {
                          required: true,
                          message: "Please select a Chatbot flow",
                        },
                      ]}
                    >
                      <ChatBotSelector />
                    </Form.Item>
                    <Form.Item
                      label="Activate Bot For"
                      name={["data", "startWithChatBot", "activateOn"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select a an option",
                        },
                      ]}
                    >
                      <Select placeholder="Select an option">
                        <Select.Option
                          key={"ALL_NEW_CONVERSATION"}
                          value={"ALL_NEW_CONVERSATION"}
                        >
                          All New Conversation
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                );
              }
              return <></>;
            }}
          </Form.Item>
        </ConnectionDataEditor>
      </div>
    </>
  );
};
