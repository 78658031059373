import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iTask } from "@sdk/tasks/task-model";
import { Button, Form, Input, message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { BadgesController } from "badge-controller";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { DeepPartial } from "redux";
import { selectCurrentUserId } from "store/modules/users/users.selectors";

export const AddTaskModal = ({
  visible,
  onChangeVisibility,
  onCreated,
  conversationId,
  messageId
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated?: () => any;
  conversationId?: string;
  messageId?: string;
}) => {
  const [form] = useForm();
  const currentUser = useSelector(selectCurrentUserId);

  const { doAction: onSubmit, isProcessing, response } = useSDKActionWithDeps(
    () => ({
      action: SDK => values => {
        console.log("values", values);
        return SDK.tasks.create(values).then(res => {
          onCreated && onCreated();
          BadgesController.processBadgeEvent("create-your-first-task");
          form.resetFields();
          onChangeVisibility(false);
        });
      },
      successMessage: "Task has been created",
      failureMessage: "Something went wrong"
    }),
    [form, onChangeVisibility, onCreated]
  );

  const onFinish = useCallback(async () => {
    try {
      await form.validateFields();
      const { label } = form.getFieldsValue();
      onSubmit({
        label,
        type: "TASK",
        assignedTo: [{ userId: currentUser }],
        conversationId,
        messageId
      } as DeepPartial<iTask>);
    } catch (e) {
      message.error("Please check your input");
    }
  }, [currentUser, form, onSubmit]);

  return (
    <>
      <Modal
        title={
          <ModalTitle
            icon={<i className="ri-folder-add-line"></i>}
            title="Add Task"
          />
        }
        open={visible}
        onOk={onFinish}
        onCancel={() => {
          onChangeVisibility(false);
        }}
        okText="Save"
        okButtonProps={{ className: "font-bold" }}
        data-click-context="Add Task Modal"
        width={400}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{}}
          onFinish={onFinish}
        >
          <Form.Item
            name="label"
            label=""
            rules={[
              {
                required: true,
                message: "Please enter a label"
              }
            ]}
          >
            <Input placeholder="Label" size="large" />
          </Form.Item>
          <Button htmlType="submit" type="primary" className="hidden">
            Save
          </Button>
        </Form>
        <DarkModeBg />
      </Modal>
    </>
  );
};
