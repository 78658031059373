import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { Button } from "antd";

import { ExpressAccountSetUp } from "modal-registry";

import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { AppSumoUserGreetings } from "./app-sumo-greeting";

export const AppSumoExpressAccountFinalSetup = () => {
  const { changePanelState } = useModalPanels();

  const organization = useSelector(selectOrganization);
  return (
    <div
      className="help flex-col justify-center items-center ml-4 relative overflow-hidden"
      style={{
        minHeight: 300,
      }}
    >
      <img
        src="/assets/account-managers/ruban.png"
        alt="Speak to Support"
        style={{
          width: 308,
          right: -69,
          top: 40,
          position: "absolute",
        }}
      />
      <div style={{ width: "60%" }}>
        <AppSumoUserGreetings />
      </div>

      <div className="button-container mb-4" style={{ width: "60%" }}>
        <Button
          type="primary"
          className="font-bold mt-4"
          icon={<i className="ri-rocket-2-line"></i>}
          onClick={() => {
            changePanelState(ExpressAccountSetUp, true, {});
          }}
        >
          Proceed to Express Account Setup
        </Button>
      </div>
    </div>
  );
};
