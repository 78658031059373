import { axios, AxiosResponse } from "@sdk/axios";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { iSession } from "./sessions.models";

export class SessionsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  sessions = Object.assign(
    new EntityServices<iSession>(this.config, "sessions"),
    {
      getActiveGuestSessions: async (organizationId: string) => {
        const results: AxiosResponse<Record<string, boolean>> = await axios.get(
          this.config.basePath +
            `/sockets/status-by-namespace/${organizationId}_GUESTS`,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      softInviteSession: async (sessionId: string, message: string) => {
        UserTracker.track("SESSIONS - Soft Invite", {});
        const results: AxiosResponse<Record<string, boolean>> =
          await axios.post(
            this.config.basePath + `/sessions/${sessionId}/soft-invite`,
            {
              message,
            },
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
      sendInviteSubsequentMessage: async (
        sessionId: string,
        message: string,
      ) => {
        UserTracker.track("SESSIONS - Send Invite Subsequent Message", {});
        const results: AxiosResponse<Record<string, boolean>> =
          await axios.post(
            this.config.basePath +
              `/sessions/${sessionId}/soft-invite-subsequent-messages`,
            {
              message,
            },
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
    },
  );
}
