import MailOutlined from "@ant-design/icons/MailOutlined";
import { Divider, Modal, Result } from "antd";
import { HelpCenterPromptButton } from "components/common/help-center/embeddable-components/help-center-prompt-button";
import { ModalTitle } from "components/common/modal-title";
import { ChatList } from "components/modules/conversations/components/chat-list/chat-list";
import { ChatView } from "components/pages/conversations/chat-view";
import "components/pages/conversations/conversations.page.style.scss";
import { DarkModeBg } from "dark-mode-bg";
import { memo, useMemo } from "react";
import { DefaultConversationQueryConfig } from "store/modules/ui-state/ui-state.slice";
import { QueryConfig } from "store/utils/query-config";
import { useUrlState } from "utils/hooks/use-url-state";

export const ConversationsViewerNew = memo(
  ({
    visible,
    onChangeVisibility,
    filter,
  }: {
    visible: boolean;
    onChangeVisibility: (state: boolean) => any;
    filter: any;
  }) => {
    const [selectedConversation, setSelectedConversation] = useUrlState(
      "conversationId"
    );

    const queryObj: {
      queryConfig: QueryConfig;
      alias: string;
    } = useMemo(() => {
      const queryConfig = {
        query: filter,
        options: DefaultConversationQueryConfig.options,
      };
      return {
        queryConfig: queryConfig,
        alias: JSON.stringify(queryConfig),
      };
    }, [filter]);

    return (
      <>
        <Modal
          title={
            <ModalTitle
              title={
                <>
                  Conversation Explorer{" "}
                  <HelpCenterPromptButton
                    type="ICON"
                    text="Chat Restriction is Active"
                    description="Since your account is using Chat Restrictions, you might not be able to see all conversations"
                    articleId="fc89e0-58191"
                    collectionId="fc89e0-d943b"
                  />
                </>
              }
              icon={<i className="ri-filter-line"></i>}
            />
          }
          open={visible}
          footer={null}
          onCancel={() => {
            onChangeVisibility(false);
          }}
          className="responsive-modal very-big-modal"
          bodyStyle={{ padding: 0 }}
          data-click-context="Conversation Viewer Modal"
        >
          {visible && (
            <div className="content flex flex-row" style={{ height: "80vh" }}>
              <div
                className="chat-list h-full overflow-y-auto overflow-x-hidden"
                style={{ width: 320 }}
              >
                <ChatList
                  selectedConversation={selectedConversation}
                  onSelectConversation={setSelectedConversation}
                  defaultQueryConfig={queryObj.queryConfig}
                  defaultQueryAlias={queryObj.alias}
                  title="Conversations"
                  breakByChatStatus={false}
                />
              </div>
              <Divider type="vertical" className="h-full m-0" />
              {selectedConversation && (
                <ChatView
                  conversationId={selectedConversation}
                  showContactInfo={true}
                />
              )}
              {!selectedConversation && (
                <Result
                  title={"Select a conversation"}
                  icon={<MailOutlined />}
                  className="flex-1 h-full flex flex-col items-center justify-center"
                />
              )}
            </div>
          )}
          <DarkModeBg />
        </Modal>
      </>
    );
  }
);
