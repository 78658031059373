import { GoogleMapPlacesObj } from "../itineries-model";
import { GoogleMapImage } from "./google-map-static";

export const MapImageBlock = ({ data }: { data: GoogleMapPlacesObj }) => {
  if (!data) {
    return (
      <div className="flex flex-row justify-center items-center mt-8">
        Map not configured
      </div>
    );
  }
  return (
    <div className="flex flex-row justify-center items-center mt-8">
      <GoogleMapImage
        center={data.formatted_address}
        marker={`${data.geometry.location.lat},${data.geometry.location.lng}`}
      />
    </div>
  );
};
