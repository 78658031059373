import { Avatar as _Avatar, Tooltip } from "antd";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectIsUserOnline } from "store/modules/user-presence/user-presence.selectors";
import { selectUser } from "store/modules/users/users.selectors";
import { LightenDarkenColor } from "../../../utils/color-utils";
import { getHashedColor } from "../../../utils/get-hashed-color";
import "./avatar.scss";

export const Avatar = ({
  name,
  avatarUrl,
  avatarColor,
  size,
  style,
  className
}: {
  name?: string;
  avatarUrl?: string;
  avatarColor?: string;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}) => {
  const firstLetter = name?.charAt(0).toUpperCase() || "U";
  const avatarStyle = useMemo(() => {
    return {
      backgroundColor:
        avatarColor ||
        LightenDarkenColor(getHashedColor(firstLetter || ""), -160),
      ...(style || {})
    };
  }, [style, avatarColor, firstLetter]);
  if (avatarUrl) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <_Avatar
        style={avatarStyle}
        size={size}
        className={className}
        src={avatarUrl}
      ></_Avatar>
    );
  }
  return <NamedAvatar name={name} size={size} className={className} />;
};

export const NamedAvatar = ({
  name,
  size,
  style,
  className
}: {
  name?: string;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}) => {
  const firstLetter = name?.charAt(0).toUpperCase() || "U";

  const avatarStyle = useMemo(() => {
    return {
      color: LightenDarkenColor(getHashedColor(firstLetter || ""), 0),
      backgroundColor: LightenDarkenColor(
        getHashedColor(firstLetter || ""),
        160
      ),
      ...(style || {})
    };
  }, [style, firstLetter]);

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_Avatar style={avatarStyle} size={size} className={className}>
      {firstLetter}
    </_Avatar>
  );
};

export const AvatarGroup = _Avatar.Group;

export const useUserAvatar = (userId: string) => {
  const user = useSelector(selectUser(userId));
  const isOnline = useSelector(selectIsUserOnline(userId));
  return {
    name: user?.data.firstName,
    avatar: user?.data.avatar,
    isOnline,
    isAvailable: user?.metaData?.isAvailable,
    availabilityStatus: user?.metaData.availabilityStatus
  };
};

export const UserAvatar = ({
  userId,
  size = 32,
  style,
  className,
  enableToolTip,
  tooltipTemplate
}: {
  userId: string;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
  enableToolTip?: boolean;
  tooltipTemplate?: (name: string) => string;
}) => {
  const {
    name,
    isOnline,
    isAvailable,
    availabilityStatus,
    avatar: avatarUrl
  } = useUserAvatar(userId);

  const avatar = (
    <div
      className={classNames(
        "user-avatar",
        isAvailable
          ? availabilityStatus === "AVAILABLE"
            ? "online"
            : availabilityStatus === "BUSY"
            ? "busy"
            : "away"
          : ""
      )}
      style={{ width: size }}
    >
      <Avatar
        name={name}
        avatarUrl={avatarUrl}
        size={size}
        style={style}
        className={className}
      />
    </div>
  );

  if (enableToolTip) {
    return (
      <Tooltip title={tooltipTemplate ? tooltipTemplate(name) : name}>
        {avatar}
      </Tooltip>
    );
  } else {
    return avatar;
  }
};
