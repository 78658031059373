import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { SelfServicePortalPage } from "./configure-self-service-portal/configure-self-service-portal.page";

export const ConfigureSelfServicePortalModal = ({
  visible,
  onChangeVisibility,
  onCreated,
  portalId
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated: () => any;
  portalId: string;
}) => {
  return (
    (<Modal
      title={
        <ModalTitle
          icon={<i className="ri-tools-line"></i>}
          title="Configure Self Service Portal"
        />
      }
      open={visible}
      footer={null}
      onCancel={() => onChangeVisibility(false)}
      data-click-context="Configure Self Service Portal Modal"
      width={"100%"}
    >
      <SelfServicePortalPage portalId={portalId} />
      <DarkModeBg />
    </Modal>)
  );
};
