import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  iOrganizationHelpDeskPipeline,
  iOrganizationHelpDeskPipelineStage,
} from "@sdk/user-management/user-management.models";
import { Button, Form, Input, Modal, Space, Table, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import {
  DragHandle,
  DraggableBodyRowGenerator,
  DraggableContainerGenerator,
} from "components/common/sortable-table/sortable-table-elements";
import { useSortableTable } from "components/common/sortable-table/use-sortable-table";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectPipelineById } from "store/modules/workspace/workspace.selectors";
import { AddEditPipelineStage } from "./add-edit-pipeline-stage";

export const AddEditPipeline = ({
  visible,
  onChangeVisibility,
  setSelectedPipeline,
  onCreated,
  pipelineId,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  setSelectedPipeline: (string) => any;
  onCreated: () => any;
  pipelineId: string;
}) => {
  console.log(
    "pipelineIdpipelineIdpipelineIdpipelineIdpipelineIdpipelineIdpipelineIdpipelineIdpipelineId",
    pipelineId,
  );
  const [form] = useForm();

  const pipelineData = useSelector(selectPipelineById(pipelineId));
  const {
    state: stages,
    setState: setStages,
    onSortEnd,
  } = useSortableTable([] as iOrganizationHelpDeskPipelineStage[]);
  useEffect(() => {
    setStages(pipelineData?.stages || []);
    form.setFieldsValue({
      ...pipelineData,
    });
  }, [form, pipelineData, setStages]);

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Label",
      dataIndex: "label",
      className: "drag-visible",
    },
    {
      title: "",
      dataIndex: "actions",
      width: 180,
      render: (data, record, index) => (
        <Space>
          <Button
            icon={<i className="ri-pencil-line"></i>}
            type="link"
            onClick={() => {
              setPipelineStageInContext(record);
            }}
          >
            Edit
          </Button>
          <Button
            icon={<i className="ri-delete-bin-line"></i>}
            type="link"
            onClick={() => {
              setStages(_.without(stages, record));
            }}
          />
        </Space>
      ),
    },
  ];

  const { doAction: createPipeline, isProcessing: isAddingPipeline } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (data: iOrganizationHelpDeskPipeline) =>
          SDK.pipelines.addPipeline(data).then((res) => {
            onCreated();
            setSelectedPipeline("");
            onChangeVisibility(false);
          }),
        successMessage: "Pipeline has been created",
        failureMessage: "Something went wrong",
      }),
      [onChangeVisibility, onCreated, setSelectedPipeline],
    );

  const { doAction: editPipeline, isProcessing: isEditingPipeline } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (id: string, data: iOrganizationHelpDeskPipeline) =>
          SDK.pipelines.editPipeline(id, data).then((res) => {
            onCreated();
            setSelectedPipeline("");
            onChangeVisibility(false);
          }),
        successMessage: "Pipeline has been edited",
        failureMessage: "Something went wrong",
      }),
      [onChangeVisibility, onCreated, setSelectedPipeline],
    );

  const onSubmit = () => {
    const formValues = form.getFieldsValue();
    console.log("formValues", formValues);
    const pipelineData: iOrganizationHelpDeskPipeline = {
      id: String(new Date().getTime()),
      label: formValues.label,
      startingStageLabel: formValues.startingStageLabel,
      successfulExitStageLabel: formValues.successfulExitStageLabel,
      failureExistStageLabel: formValues.failureExistStageLabel,
      stages: stages,
    };
    if (pipelineId) {
      editPipeline(pipelineId, pipelineData);
    } else {
      createPipeline(pipelineData);
    }
    form.resetFields();
  };

  const [pipelineStageInContext, setPipelineStageInContext] = useState<
    undefined | iOrganizationHelpDeskPipelineStage
  >();

  return (
    <>
      <Modal
        title={
          <ModalTitle
            icon={<i className="ri-inbox-fill"></i>}
            title={pipelineId === "" ? `Create Pipeline` : `Edit Pipeline`}
          />
        }
        open={visible}
        okButtonProps={{ loading: isAddingPipeline || isEditingPipeline }}
        onOk={async () => {
          try {
            await form.validateFields();
            onSubmit();
          } catch (e) {
            message.error("Please check your input");
          }
        }}
        onCancel={() => {
          form.resetFields();
          setSelectedPipeline("");
          onChangeVisibility(false);
        }}
        okText={pipelineId === "" ? `Add` : `Save`}
        data-click-context="Add Edit Pipeline Modal"
      >
        <Form layout="vertical" form={form}>
          {/* Label */}

          <Form.Item
            name="label"
            label="Label"
            rules={[{ required: true, message: "Please enter a name" }]}
          >
            <Input
              prefix={<i className="ri-inbox-fill"></i>}
              placeholder="Eg: Sales Funnel, Support Funnel"
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="startingStageLabel"
            label="Starting Stage"
            rules={[
              { required: true, message: "Please enter a starting stage" },
            ]}
          >
            <Input
              prefix={<i className="ri-flag-line"></i>}
              placeholder="Eg: Lead, Open"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="successfulExitStageLabel"
            label="Successful Exit Stage"
            rules={[{ required: true, message: "Please enter a stage name" }]}
          >
            <Input
              prefix={<i className="ri-flag-fill"></i>}
              placeholder="Eg: Sale Completed"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="failureExistStageLabel"
            label="Failure Exit Stage"
            rules={[{ required: true, message: "Please enter a stage name" }]}
          >
            <Input
              prefix={<i className="ri-flag-fill"></i>}
              placeholder="Eg: Failed Sale"
              size="large"
            />
          </Form.Item>
          {/* Other Pipeline stages */}
          <div className="text-lg font-bold">Intermediate Stages</div>
          <Table
            columns={columns}
            dataSource={stages}
            pagination={false}
            rowKey="id"
            components={{
              body: {
                wrapper: DraggableContainerGenerator(onSortEnd),
                row: DraggableBodyRowGenerator(stages),
              },
            }}
            footer={() => (
              <div className="flex-row flex justify-end items-center">
                <Button
                  onClick={() => {
                    setPipelineStageInContext({
                      id: String(new Date().getTime()),
                      label: "",
                      checkLists: [],
                      automation: {},
                      rules: {},
                    });
                  }}
                >
                  Add Stage
                </Button>
              </div>
            )}
          />
          <AddEditPipelineStage
            visible={!!pipelineStageInContext}
            onChangeVisibility={() => setPipelineStageInContext(undefined)}
            onSubmit={(data) => {
              console.log("data", data, pipelineStageInContext);
              const existingStage = _.find(stages, {
                id: pipelineStageInContext?.id,
              });
              if (existingStage) {
                // Edit
                setStages(
                  stages.map((stage) => {
                    if (stage === existingStage) {
                      return {
                        ...stage,
                        ...data,
                      };
                    }
                    return stage;
                  }),
                );
              } else {
                // Add
                setStages([
                  ...stages,
                  {
                    ...data,
                    id: pipelineStageInContext?.id!,
                  },
                ]);
              }
            }}
            data={pipelineStageInContext}
          />
        </Form>
        <DarkModeBg />
      </Modal>
    </>
  );
};
