import { Input } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import _ from "lodash";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import ReactPivot from "react-pivot-webpack";
import { useSearch } from "utils/hooks/use-search";
import { useViewRefresher } from "utils/hooks/use-view-refresher";
import { UseReportDataProcessor } from "../../helpers/use-report-data-processor";
import { ReportLabelRenderers } from "../../helpers/widget-label-mappers";
import { iReportWidget } from "../report-canvas/widget-canvas-models";

import "./simple-pivot-table.scss";

export const SimplePivotTable = ({
  data,
  reportConfig,
  onInspectSegment,
}: {
  data: any[];
  reportConfig: iReportWidget;
  onInspectSegment?: (point) => any;
  isInspectAvailable?: boolean;
  reportNoun: string;
}) => {
  const {
    dimensions,
    postDimensions,
    processedData: initialData,
    countKey,
    countLabel,
    countTemplate,
  } = UseReportDataProcessor(data, reportConfig);

  const reduce = useCallback(
    function(row, memo) {
      memo.countTotal = (memo.countTotal || 0) + parseFloat(row[countKey]);
      return memo;
    },
    [countKey]
  );

  const calculations = useMemo(
    () => [
      {
        title: countLabel,
        value: "countTotal",
        template: countTemplate,
        sortBy: function(row) {
          return isNaN(row.countTotal) ? 0 : row.countTotal;
        },
      },
    ],
    [countLabel, countTemplate]
  );

  const {
    dimensionDefinitions,
    hiddenAttributes,
    activeDimensions,
  } = useMemo(() => {
    const dimensionDefinitions: {
      value: string;
      title: string;
      template: (value) => JSX.Element;
    }[] = [];
    let hiddenAttributes: string[] = [];
    let activeDimensions: string[] = [];

    for (const dimension of postDimensions) {
      const record = {
        value: dimension,
        title: dimension,
        template: (value) => value,
      };

      const rendererRecord = ReportLabelRenderers[dimension];
      if (rendererRecord) {
        if (rendererRecord.label) {
          record.title = rendererRecord.label;
        }
        if (rendererRecord.template) {
          record.template = rendererRecord.template;
        }
        if (rendererRecord.hiddenAttributes) {
          hiddenAttributes = [
            ...hiddenAttributes,
            ...rendererRecord.hiddenAttributes,
          ];
        }
      }
      dimensionDefinitions.push(record);
      activeDimensions = [...activeDimensions, record.title];
    }
    activeDimensions = _.without(activeDimensions, ...hiddenAttributes);
    return { dimensionDefinitions, hiddenAttributes, activeDimensions };
  }, [postDimensions]);

  const { filteredData, searchTerm, setSearchTerm } = useSearch(initialData);

  const containerRef = useRef<any>();
  const [pageSize, setPageSize] = useState(7);
  useLayoutEffect(() => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const paginationHeight = 21;
      const headerHeight = 53;
      const rowHeight = 45.5;
      const additionalHeight = 40;

      const pageSize = Math.floor(
        (containerHeight -
          (paginationHeight + headerHeight + additionalHeight)) /
          rowHeight
      );

      setPageSize(pageSize > 1 ? pageSize : 1);
    }
  }, []);

  const { visible } = useViewRefresher(dimensions);

  return (
    <div className="simple-pivot-table-container h-full w-full flex flex-col">
      <div className="search-container bg-white dark:bg-gray-900 px-4">
        <Input.Search
          placeholder="Search Report..."
          // size="small"
          className="rounded-md py-4"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
      </div>
      <div className="report-container overflow-auto flex-1" ref={containerRef}>
        {initialData.length === 0 && (
          <div className="h-full w-full flex flex-row justify-center items-center">
            <EmptyData
              icon={<i className="ri-table-line"></i>}
              text="Not enough data"
            />
          </div>
        )}
        {initialData.length > 0 && (
          <>
            {visible && (
              <ReactPivot
                rows={filteredData}
                dimensions={dimensionDefinitions}
                reduce={reduce}
                calculations={calculations}
                nPaginateRows={pageSize}
                activeDimensions={activeDimensions}
                hiddenColumns={hiddenAttributes}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
