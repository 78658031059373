import { useModalPanels } from "@libs/modal-panels/modal-panels";
import {
  iAutomationWorkflow,
  iAutomationWorkflowStep,
  iWorkflowStepData,
} from "@sdk/automation-workflows/automation-workflows-model";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Popover,
  Space,
  Tag,
} from "antd";
import { ModalTitle } from "components/common/modal-title";
import { SimpleDNDList } from "components/common/simple-dnd-list/dnd-list";
import { DarkModeBg } from "dark-mode-bg";
import _, { last } from "lodash";
import { SequenceDelayEditor, SequenceStepEditor } from "modal-registry";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAutomationWorkflowById } from "store/modules/automation-workflows/automation-workflows.selectors";
import { AddEllipsis } from "utils/add-ellipsis";
import { convertMillisecondsToReadableString } from "utils/convert-milliseconds-to-readable-string";
import { uuidv4 } from "utils/generate-uuid";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { useStateWithGetter } from "utils/hooks/use-state-with-getter";
import { convertHtmlToPlainText } from "utils/html-to-plain-text";
import { toTitleCase } from "utils/to-title-case";
import { iWorkflowStepIconMap } from "../workflow-step-icons";
import { iWorkflowStepLabelMap } from "../workflow-step-labels";

export const SequenceEditorModal = ({
  visible,
  onChangeVisibility,
  sequenceId,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  sequenceId: string;
}) => {
  const sequenceConfig = useSelector(selectAutomationWorkflowById(sequenceId!));

  // console.log("sequenceConfig", sequenceConfig);

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-mail-send-line"></i>}
          title="Edit Automation Sequence"
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Sequence Editor Modal"
      width={800}
      destroyOnClose={true}
    >
      {sequenceConfig && <SequenceEditorInner sequence={sequenceConfig} />}
      <DarkModeBg />
    </Modal>
  );
};

const SequenceEditorInner = ({
  sequence,
}: {
  sequence: iAutomationWorkflow;
}) => {
  const { doAction: editSequence, isProcessing: isSavingSequence } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (edits: Partial<iAutomationWorkflow>) =>
          SDK.automationWorkflows.editById(sequence.id!, edits),
        successMessage: "Sequence has been modified",
        failureMessage: "Something went wrong",
      }),
      [sequence.id],
    );

  const [steps, setSteps, getSteps] = useStateWithGetter(sequence?.steps || []);

  const [stepEditorState, setStepEditorState] = useSimpleState({
    isVisible: false,
    stepInContext: null as any as iAutomationWorkflowStep | null,
  });

  useEffectWhen(
    () => {
      editSequence({ steps });
    },
    [editSequence, steps],
    [steps],
  );

  const [linkedInSessionCookie, setLinkedInSessionCookie] = useState("");
  const [linkedInSetupVisibility, setLinkedInSetupVisibility] = useState(false);

  useEffect(() => {
    setLinkedInSessionCookie(
      sequence?.configurations?.linkedInSessionCookie || "",
    );
  }, [sequence?.configurations?.linkedInSessionCookie]);

  const { triggerTempModal } = useModalPanels();

  return (
    <div className="flex flex-col p-8 pt-4">
      <div className="border-gray-600 bg-gray-200 dark:bg-gray-800 rounded-lg p-4 flex flex-row mb-4">
        <div className="flex flex-col justify-center items-center mr-4">
          <i className="ri-linkedin-box-fill text-2xl"></i>
          <Tag className="m-0">Beta</Tag>
        </div>
        <div className="text flex-1">
          {sequence?.configurations?.linkedInSessionCookie
            ? `LinkedIn Automation has been setup. Now you can use LinkedIn Actions in your sequence`
            : `LinkedIn Automation is now available for Insiders. Configure the
            automation to use them`}
        </div>
        <div className="flex flex-col justify-center items-center">
          <Popover
            placement="bottom"
            trigger="click"
            open={linkedInSetupVisibility}
            onOpenChange={setLinkedInSetupVisibility}
            content={
              <>
                <div className="flex flex-col" style={{ minWidth: "20rem" }}>
                  <Input
                    placeholder="LinkedIn Session Cookie"
                    value={linkedInSessionCookie}
                    onChange={(e) => setLinkedInSessionCookie(e.target.value)}
                  />
                  <div className="flex flex-row justify-end items-center mt-4">
                    <Button
                      type="primary"
                      onClick={() => {
                        editSequence({
                          configurations: {
                            linkedInSessionCookie,
                          },
                        });
                        setLinkedInSetupVisibility(false);
                      }}
                      className="font-bold"
                      icon={<i className="ri-save-line"></i>}
                    >
                      {sequence?.configurations?.linkedInSessionCookie
                        ? `Save Configurations`
                        : ` Finish Setup`}
                    </Button>
                  </div>
                </div>
              </>
            }
            title={
              <div className="font-bold">
                <Space>
                  <i className="ri-settings-2-line"></i>
                  Setup Linked In Automation
                </Space>
              </div>
            }
          >
            <Button
              type="dashed"
              // size="large"
              icon={<i className="ri-settings-2-line"></i>}
            >
              {sequence?.configurations?.linkedInSessionCookie
                ? `Configure`
                : ` Set up`}
            </Button>
          </Popover>
        </div>
      </div>

      <SimpleDNDList
        list={steps}
        onListChange={setSteps}
        dragHandleContainerStyle={{ top: 10, padding: 10 }}
        renderItem={(step, index) => (
          <SequenceStepItem
            step={step}
            onEdit={() => {
              triggerTempModal(SequenceStepEditor, {
                step: step,
                onSave: (step) => {
                  setSteps((steps) => {
                    return steps.map((stepX) => {
                      if (step.id === stepX.id) {
                        return step;
                      }
                      return stepX;
                    });
                  });
                },
              });
            }}
            onEditDelay={() => {
              triggerTempModal(SequenceDelayEditor, {
                step: step,
                onSave: (step) => {
                  setSteps((steps) => {
                    return steps.map((stepX) => {
                      if (step.id === stepX.id) {
                        return step;
                      }
                      return stepX;
                    });
                  });
                },
              });
            }}
            onDelete={() => {
              setSteps((steps) => _.without(steps, step));
            }}
            key={step.id!}
            stepNumber={index + 1}
          />
        )}
      />
      <div className="flex flex-row justify-center items-center p-12">
        <Dropdown
          overlay={
            <Menu data-click-context="Add Sequence Step Menu">
              <Menu.Item
                icon={iWorkflowStepIconMap.EMAIL}
                onClick={() => {
                  setSteps((steps) => {
                    const emailSteps = steps.filter(
                      (step) => step.type === "EMAIL",
                    );
                    const lastEmailStep = last(emailSteps);
                    const data = (() => {
                      if (lastEmailStep) {
                        return {
                          email: {
                            senderName: lastEmailStep.data.email?.senderName,
                            connectionId:
                              lastEmailStep.data.email?.connectionId,
                            sendingEmail:
                              lastEmailStep.data.email?.sendingEmail,
                          },
                        } as iWorkflowStepData;
                      }
                      return {
                        email: {},
                      } as iWorkflowStepData;
                    })();
                    return [
                      ...steps,
                      {
                        id: uuidv4(),
                        type: "EMAIL",
                        delay: 24 * 60 * 60 * 1000,
                        data: data,
                      },
                    ];
                  });
                  setTimeout(() => {
                    const lastAddedStep = last(getSteps());
                    triggerTempModal(SequenceStepEditor, {
                      step: lastAddedStep,
                      onSave: (step) => {
                        setSteps((steps) => {
                          return steps.map((stepX) => {
                            if (step.id === stepX.id) {
                              return step;
                            }
                            return stepX;
                          });
                        });
                      },
                    });
                  }, 400);
                }}
              >
                Email
              </Menu.Item>
              <Menu.Item
                icon={iWorkflowStepIconMap.LINKEDIN}
                disabled={!linkedInSessionCookie}
                onClick={() => {
                  setSteps((steps) => {
                    return [
                      ...steps,
                      {
                        id: uuidv4(),
                        type: "LINKEDIN",
                        delay: 24 * 60 * 60 * 1000,
                        data: {
                          linkedIn: {
                            task: "VIEW_PROFILE",
                            body: "",
                          },
                        },
                      },
                    ];
                  });
                  setTimeout(() => {
                    const lastAddedStep = last(getSteps());
                    triggerTempModal(SequenceStepEditor, {
                      step: lastAddedStep,
                      onSave: (step) => {
                        setSteps((steps) => {
                          return steps.map((stepX) => {
                            if (step.id === stepX.id) {
                              return step;
                            }
                            return stepX;
                          });
                        });
                      },
                    });
                  }, 400);
                }}
              >
                Linked In
              </Menu.Item>
              <Menu.Item icon={iWorkflowStepIconMap.PHONE_CALL} disabled>
                Phone Call
              </Menu.Item>
              <Menu.Item icon={iWorkflowStepIconMap.SMS} disabled>
                SMS
              </Menu.Item>
              <Menu.Item icon={iWorkflowStepIconMap.TASK} disabled>
                Task
              </Menu.Item>
              <Menu.Item icon={iWorkflowStepIconMap.WHATSAPP} disabled>
                Whatsapp Message
              </Menu.Item>
              <Menu.Item icon={iWorkflowStepIconMap.ZAPIER} disabled>
                Zapier Task
              </Menu.Item>
            </Menu>
          }
          placement="topCenter"
          trigger={["click"]}
          arrow
        >
          <Button type="text" icon={<i className="ri-add-box-line"></i>}>
            Add Step
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export const SequenceStepItem = ({
  step,
  onEdit,
  onDelete,
  onEditDelay,
  stepNumber,
}: {
  step: iAutomationWorkflowStep;
  onEdit: () => any;
  onDelete: () => any;
  stepNumber: number;
  onEditDelay: () => any;
}) => {
  return (
    <div className="flex flex-col">
      <div
        className="flex flex-col hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer border border-gray-300 dark:border-gray-700 hover:border-blue-600 p-4 rounded-lg"
        onClick={() => {
          onEdit();
        }}
      >
        <div className="second-bar  flex flex-row justify-center items-center">
          <div className="text-gray-600 text-xs font-bold absolute">
            Step {stepNumber}
          </div>
        </div>
        <div className="flex flex-row">
          <div className="icon mr-2 text-3xl flex justify-center items-center">
            {iWorkflowStepIconMap[step.type]}
          </div>
          <div className="description flex-1">
            <div className="fist-line">{iWorkflowStepLabelMap[step.type]}</div>
            <div className="second-line text-gray-600">
              {step.type === "EMAIL" && (
                <>
                  {step?.data?.email?.subjectLine || "Use Last Subject Line"} :{" "}
                  {AddEllipsis(
                    convertHtmlToPlainText(step?.data?.email?.body! || ""),
                    20,
                  ) || "No Email Body"}
                </>
              )}
              {step.type === "LINKEDIN" && (
                <>
                  {toTitleCase(
                    (step.data.linkedIn?.task || "Interact with").replace(
                      "_",
                      " ",
                    ),
                  )}{" "}
                </>
              )}
            </div>
          </div>
          <div className="action-buttons">
            <Space>
              <Button
                type="text"
                icon={<i className="ri-pencil-line"></i>}
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit();
                }}
              />
              <Button
                type="text"
                icon={<i className="ri-delete-bin-line"></i>}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
              />
            </Space>
          </div>
        </div>
      </div>
      <div className="second-bar  flex flex-col justify-center items-center">
        <a
          className="text-xs"
          onClick={() => {
            onEditDelay();
          }}
        >
          after {convertMillisecondsToReadableString(step.delay)}
        </a>
        <div className="text-gray-600">
          <i className="ri-arrow-down-line"></i>
        </div>
      </div>
    </div>
  );
};
