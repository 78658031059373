import { useSDK } from "@sdk";
import {
  iTelnyxPhoneSearchFilter,
  iTelnyxPhoneSearchResultItem,
} from "@sdk/telnyx/telnyx-service-models";
import { Button, Form, Input, Select, Space, Table, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import { CountrySelector } from "components/common/country-selector/country-selector";
import { EmptyData } from "components/common/empty-data/empty-data";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { SimpleCollapse } from "components/common/simple-collapse/simple-collapse";
import { useState } from "react";

export const TelnyxPhoneSearcher = ({
  onBuyNumber,
}: {
  onBuyNumber: (record: iTelnyxPhoneSearchResultItem) => any;
}) => {
  const [form] = useForm();
  const [searchQuery, SetSearchQuery] = useState(
    {} as iTelnyxPhoneSearchFilter,
  );

  const {
    data: searchResults,
    isLoading,
    error,
    reload,
  } = useSDK((SDK) => SDK.telnyx.queryPhoneNumber(searchQuery), [searchQuery]);

  const columns = [
    {
      title: "Number",
      dataIndex: "number",
      width: 50,
      render: (label: string, record: iTelnyxPhoneSearchResultItem) => {
        return <>{record.phone_number}</>;
      },
    },
    // {
    //   title: "Rate Center",
    //   dataIndex: "rateCenter",
    //   render: (label: string, record: TelnyxPhoneNumberSearchResultItem) => {
    //     return <>{label}</>;
    //   },
    // },
    {
      title: "Features",
      dataIndex: "features",
      width: 120,
      render: (label: string, record: iTelnyxPhoneSearchResultItem) => {
        const features = record.features;
        return (
          <div className="">
            <Space>
              {features
                .filter((item) =>
                  ["voice", "sms", "emergency"].includes(item.name),
                )
                .map((item) => {
                  if (item.name === "voice") {
                    return (
                      <Tooltip title="Voice" key={item.name}>
                        <i className="ri-phone-line"></i>
                      </Tooltip>
                    );
                  } else if (item.name === "sms") {
                    return (
                      <Tooltip title="SMS" key={item.name}>
                        <i className="ri-chat-4-line"></i>
                      </Tooltip>
                    );
                  } else if (item.name === "emergency") {
                    return (
                      <Tooltip title="Emergency" key={item.name}>
                        <i className="ri-alarm-warning-line"></i>
                      </Tooltip>
                    );
                  }
                  return <></>;
                })}
            </Space>
          </div>
        );
      },
    },
    {
      title: "Instant",
      dataIndex: "quickShip",
      width: 70,
      render: (label: string, record: iTelnyxPhoneSearchResultItem) => {
        return <>{record.quickship ? "YES" : "No"}</>;
      },
    },
    {
      title: "Region",
      dataIndex: "region",
      width: 140,
      render: (label: string, record: iTelnyxPhoneSearchResultItem) => {
        return (
          <>
            {record.region_information
              .map((item) => `${item.region_name}`)
              .join(" | ")}
          </>
        );
      },
    },
    // {
    //   title: "Upfront Price",
    //   dataIndex: "upfrontPrice",
    //   width: 150,
    //   render: (label: string, record: iTelnyxPhoneSearchResultItem) => {
    //     return (
    //       <>
    //         {Number(record.cost_information.upfront_cost) + 11}{" "}
    //         {record.cost_information.currency}
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Monthly Price",
    //   dataIndex: "monthlyPrice",
    //   width: 150,
    //   render: (label: string, record: iTelnyxPhoneSearchResultItem) => {
    //     return (
    //       <>
    //         {Number(record.cost_information.monthly_cost) + 11}{" "}
    //         {record.cost_information.currency}
    //       </>
    //     );
    //   },
    // },
    {
      title: "",
      key: "action",
      width: 120,
      render: (label: string, record: iTelnyxPhoneSearchResultItem) => (
        <Space>
          <Button
            type="text"
            icon={<i className="ri-shopping-cart-line"></i>}
            className="font-bold"
            onClick={() => onBuyNumber(record)}
            disabled={
              Number(record.cost_information.monthly_cost) > 10 ||
              Number(record.cost_information.upfront_cost) > 10
            }
          >
            Buy and Configure
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="">
      <div className="phone-search-form-container border border-1 border-gray-200 dark:border-gray-700 p-8">
        <div className="text-2xl mb-2">Search Phone Numbers</div>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            filterType: "starts_with",
          }}
        >
          <div className="w-full flex fle-row">
            <Space>
              <Form.Item
                label={<div className="font-bold">Type</div>}
                name={["phone_number_type"]}
              >
                <Select
                  style={{ width: 250 }}
                  placeholder="Select Phone Number Type"
                  allowClear
                >
                  <Select.Option value={"mobile"}>Mobile Number</Select.Option>
                  <Select.Option value={"landline"}>Land Line</Select.Option>
                  <Select.Option value={"toll_free"}>Toll Free</Select.Option>
                </Select>
              </Form.Item>

              {/*  Country */}
              <Form.Item
                style={{ width: 250 }}
                name={"country_code"}
                label={<div className="font-bold">Country</div>}
              >
                <CountrySelector />
              </Form.Item>

              <Form.Item
                style={{ width: 250 }}
                label={<div className="font-bold">Phone Features</div>}
                name={["features"]}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Features"
                  allowClear
                >
                  <Select.Option value={"voice"}>Voice</Select.Option>
                  <Select.Option value={"sms"}>SMS</Select.Option>
                </Select>
              </Form.Item>
            </Space>
          </div>
          <div className="">
            <SimpleCollapse title="Advanced Search">
              <div className="my-4 mx-4 flex flex-row justify-start items-center">
                <Space>
                  {/* Filter Type */}
                  <Form.Item
                    label=""
                    name={["filterType"]}
                    style={{ width: 250 }}
                  >
                    <Select>
                      <Select.Option value={"starts_with"}>
                        Starts With
                      </Select.Option>
                      <Select.Option value={"ends_with"}>
                        Ends With
                      </Select.Option>
                      <Select.Option value={"contains"}>Contains</Select.Option>
                    </Select>
                  </Form.Item>
                  {/* Filter Value */}
                  <Form.Item
                    label=""
                    name={["filterValue"]}
                    style={{ width: 250 }}
                  >
                    <Input placeholder="..." />
                  </Form.Item>
                </Space>
              </div>
            </SimpleCollapse>
          </div>

          <div className="flex flex-row justify-end items-center">
            <Button
              type="primary"
              icon={<i className="ri-search-line"></i>}
              className="font-bold"
              onClick={() => {
                const searchConfig = form.getFieldsValue();
                if (searchConfig.filterValue) {
                  searchConfig.phone_number = {
                    [searchConfig.filterType]: searchConfig.filterValue,
                  };
                }
                delete searchConfig.filterType;
                delete searchConfig.filterValue;
                SetSearchQuery(searchConfig);
              }}
            >
              Search
            </Button>
          </div>
        </Form>
      </div>

      <div className="search-results  border border-1 border-gray-200 dark:border-gray-700 p-8 mt-20">
        <div className="text-3xl mb-4">Search Results</div>
        <Table
          columns={columns}
          dataSource={searchResults.data}
          pagination={false}
          rowKey="id"
          className="mb-16"
          locale={{
            emptyText: (
              <EmptyData
                text="No Available Phone Numbers Found"
                icon="ri-phone-line"
              />
            ),
          }}
          loading={
            isLoading
              ? {
                  indicator: <LoadingIndicatorWithSpin />,
                }
              : false
          }
        />
      </div>
    </div>
  );
};
