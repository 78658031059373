import { iAutomationWorkflow } from "@sdk/automation-workflows/automation-workflows-model";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import { generateAutomationWorkflowOfContactQuery } from "./automation-workflows.helpers";

export const {
  selectQueryMap: selectAutomationWorkflowQueryMap,
  selectEntityMap: selectAutomationWorkflowsMap,
  selectQueryMapAll: selectAutomationWorkflowsMapAll,
  selectQueryAllList: selectAutomationWorkflowsAllList,
  selectAllEntities: selectAllAutomationWorkflows,
  selectAllEntitiesQuery: selectAllAutomationWorkflowsQuery,
  selectEntityById: selectAutomationWorkflowById,
  selectEntityQuery: selectAutomationWorkflowQuery,
  selectEntityQueryList: selectAutomationWorkflowQueryList
} = CreateSelectorForEntities<iStore, iAutomationWorkflow>({
  sliceName: "automationWorkflows"
});

export const selectAutomationWorkflowByConnectionId = (
  connectionId: string
) => (state: iStore) => {
  return _.find(selectAllAutomationWorkflows(state), {
    connectionId
  });
};

export const selectAutomationWorkflowsByContactId = (contactId: string) => {
  return selectAutomationWorkflowQuery(
    JSON.stringify(generateAutomationWorkflowOfContactQuery(contactId))
  );
};
