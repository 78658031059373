import { iMarketingReference } from "@sdk/marketing-references/marketing-references.models";
import _ from "lodash";
import { createSelector } from "reselect";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectMarketingReferenceQueryMap,
  selectEntityMap: selectMarketingReferencesMap,
  selectQueryMapAll: selectMarketingReferencesMapAll,
  selectQueryAllList: selectMarketingReferencesAllList,
  selectAllEntities: selectAllMarketingReferences,
  selectAllEntitiesQuery: selectAllMarketingReferencesQuery,
  selectEntityById: selectMarketingReferenceById,
  selectEntityQuery: selectMarketingReferenceQuery,
  selectEntityQueryList: selectMarketingReferenceQueryList
} = CreateSelectorForEntities<iStore, iMarketingReference>({
  sliceName: "marketingReferences"
});

export const selectMarketingReferenceByFacebookAdIdId = (fbAdId: string) => (
  state: iStore
) =>
  createSelector([selectMarketingReferencesMap], allMarketingReferencesMap => {
    const marketingRef = _.find(
      Object.values(allMarketingReferencesMap),
      item => item.data.fbAdId === fbAdId
    );
    return marketingRef;
  })(state);
