import { Button, Divider, Form, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  ActionConfig,
  ActionConfigLabels,
} from "components/modules/conversations/components/action-editor/actions";
import { ActionSelector } from "components/modules/conversations/components/action-selector/action-selector";
import _ from "lodash";
import { memo, useCallback } from "react";
import { Node, useReactFlow } from "react-flow-renderer";
import { useSelector } from "react-redux";
import { selectAllGroups } from "store/modules/groups/groups.selectors";
import { uuidv4 } from "utils/generate-uuid";
import { useViewRefresher } from "utils/hooks/use-view-refresher";
import { DragHandler } from "../../components/drag-handler/drag-handler";
import { DragPreventer } from "../../components/drag-preventer/drag-preventer";
import { AvailableNodes, iActionNode, iActionTypes } from "../../data-model";
import { useSetNode } from "../../utils/use-set-node";
import { ChatFlowHandle } from "../chat-flow-handle";
import "./action-node.scss";

export const ActionNode = memo(
  ({ data, id }: { id: string; data: iActionNode }) => {
    const setNode = useSetNode<iActionNode>(id);

    const {
      getNode,
      setNodes,
      setEdges,
      getNodes,
      getEdges,
      getEdge,
    } = useReactFlow<AvailableNodes, any>();

    const cloneNode = useCallback(() => {
      const node = getNode(id) as Node<iActionNode>;
      const currentNodePosition = node.position;
      const newNodeId = uuidv4();
      const order = 0;
      const newNode = {
        ..._.pick(_.cloneDeep(node), ["type", "data"]),
        id: newNodeId,
        position: {
          x: currentNodePosition.x + 320 + 100,
          y: currentNodePosition.y + (order || 0) * 100,
        },
      };
      setNodes([...getNodes(), newNode]);
    }, [getNode, getNodes, id, setNodes]);

    const deleteNode = useCallback(() => {
      setNodes(_.filter([...getNodes()], (node) => node.id !== id));
    }, [getNodes, id, setNodes]);

    const { visible: isAddQuickReplyVisible, reRender } = useViewRefresher();

    const [form] = useForm();

    const userGroups = useSelector(selectAllGroups);

    return (
      <div className="p-4 action-node-container node-container group border-2 dark:border-black hover:border-blue-600">
        <div className="hidden group-hover:show">
          <div
            className="hover-action-box flex flex-row gap-4 justify-center items-center absolute w-full left-0 pb-4"
            style={{ top: -40 }}
          >
            <Tooltip title="Delete Block">
              <Button
                type="link"
                onClick={(e) => {
                  deleteNode();
                }}
                icon={<i className="ri-delete-bin-5-line"></i>}
              />
            </Tooltip>
            <Tooltip title="Clone Block">
              <Button
                type="link"
                onClick={(e) => {
                  cloneNode();
                }}
                icon={<i className="ri-file-copy-line"></i>}
              />
            </Tooltip>
          </div>
        </div>

        <ChatFlowHandle handleId="DEFAULT" nodeId={id} type="DEFAULT" />
        {id !== "START" && (
          <ChatFlowHandle handleId="INPUT" nodeId={id} type="INPUT" />
        )}
        <div className="drag-handler-container flex flex-row justify-center items-center">
          <DragHandler />
        </div>
        <div>
          <Form
            layout="vertical"
            form={form}
            initialValues={data}
            onValuesChange={() => {
              setNode(form.getFieldsValue());
            }}
          >
            <DragPreventer>
              <Form.Item
                label={<div className="font-bold">Select an Action</div>}
                name={["action"]}
              >
                <ActionSelector excludeActions={["SEND_MESSAGE"]} />
              </Form.Item>
            </DragPreventer>

            <Form.Item shouldUpdate className="mb-0 empty-form">
              {({ getFieldValue }) => {
                const selectedOption: iActionTypes = getFieldValue(["action"]);

                return (
                  <ActionConfig
                    actionType={selectedOption}
                    actionConfigHeader={
                      <Divider type="horizontal" className="w-full">
                        <div className="font-bold">
                          {ActionConfigLabels[selectedOption]}
                        </div>
                      </Divider>
                    }
                    fieldItemContainer={DragPreventer}
                  />
                );
              }}
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
);
