import { Alert, Button, Descriptions, Form, Select, Space } from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { CustomTimePicker } from "components/common/custom-time-picker/custom-time-picker";
import { Timezones } from "components/pages/reports/components/scheduled-reports/timezones";
import { cloneDeep } from "lodash";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectChatWidgetByConnectionId } from "store/modules/chat-widgets/chat-widgets.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const ConfigureChatWidgetOfflineHours = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const { state: widget, retry: reload } = useQueryWithStore(
    selectChatWidgetByConnectionId(connectionId),
    loadAllChatWidgets(),
    [connectionId],
  );
  return (
    <ConfigurationEditorComponent
      icon={"ri-rest-time-line"}
      title="Setup Offline Hours"
      description="Let customers know when they can expect to connect with your team"
      entityId={widget?.id!}
      entityType="WIDGET"
      // onSaved={() => {
      //   reload();
      // }}
    >
      <Form.Item
        name={["configurations", "offlineHours", "timezone"]}
        label="Timezone"
        rules={[]}
      >
        <Select style={{ minWidth: 150 }} showSearch>
          {Timezones.map((item) => (
            <Select.Option value={item} key={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Descriptions bordered layout="horizontal" column={1}>
        {[
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ]
          // Object.entries(
          //   widget?.configurations.workingHours?.offlineHours ||
          //     {}
          // )
          .map((day) => (
            <Descriptions.Item label={day}>
              <Space>
                <Form.Item
                  shouldUpdate
                  label="Start Time"
                  name={[
                    "configurations",
                    "offlineHours",
                    "dayWiseConfig",
                    day,
                    "startTime",
                  ]}
                  rules={
                    [
                      // {
                      //   required: true,
                      //   message: "Please select a start time",
                      // },
                    ]
                  }
                  // initialValue={day[1].startTime}
                >
                  <CustomTimePicker style={{ minWidth: 250 }} />
                </Form.Item>
                <Form.Item
                  shouldUpdate
                  label="End Time"
                  name={[
                    "configurations",
                    "offlineHours",
                    "dayWiseConfig",
                    day,
                    "endTime",
                  ]}
                  rules={
                    [
                      // {
                      //   required: true,
                      //   message: "Please select a start time",
                      // },
                    ]
                  }
                  //initialValue={day[1].endTime}
                >
                  <CustomTimePicker style={{ minWidth: 250 }} />
                </Form.Item>
                <CopyToOtherDate day={day} />
              </Space>
            </Descriptions.Item>
          ))}
      </Descriptions>

      <Alert
        type="info"
        showIcon
        message={
          "If your operations are closed on a specific day, you can simply set the start time and end time to be the same."
        }
      />
    </ConfigurationEditorComponent>
  );
};

const CopyToOtherDate = ({ day }: { day: string }) => {
  const form = Form.useFormInstance();

  return (
    <Button
      icon={<i className="ri-file-copy-line"></i>}
      type="text"
      onClick={() => {
        const formValue = form.getFieldsValue();
        console.log("formValue", formValue);
        const offlineHours =
          formValue?.configurations?.offlineHours?.dayWiseConfig;
        const dayConfig = offlineHours[day] || {};

        const newDayWiseConfig = {
          Monday: cloneDeep(dayConfig),
          Tuesday: cloneDeep(dayConfig),
          Wednesday: cloneDeep(dayConfig),
          Thursday: cloneDeep(dayConfig),
          Friday: cloneDeep(dayConfig),
          Saturday: cloneDeep(dayConfig),
          Sunday: cloneDeep(dayConfig),
        };

        form.setFieldValue(
          ["configurations", "offlineHours", "dayWiseConfig"],
          newDayWiseConfig,
        );
      }}
    >
      Copy to all
    </Button>
  );
};
