import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Popconfirm } from "antd";
import { iNavigationMenuItem } from "components/common/side-bar-menu/menu-item-mode";
import { SimpleMenuSectionTitle } from "components/common/side-bar-menu/simple-menu-section-title";
import { ConfigureTicketWorkflows } from "components/modules/connections/channels/email/components/configure-ticket-workflows/configure-ticket-workflows";
import { BlockedFacebookUsers } from "components/modules/connections/channels/facebook/blocked-facebook-users/blocked-facebook-users";
import { FacebookAutomationConfig } from "components/modules/connections/channels/facebook/facebook-automations-config";
import { InstagramAutomationConfig } from "components/modules/connections/channels/instagram/instagram-automations-config";
import { ManageInstagramConnection } from "components/modules/connections/channels/instagram/manage-instagram-connection/manage-instagram-connection";
import { ManageTelegramConnection } from "components/modules/connections/channels/telegram/manage-telegram-connection/manage-telegram-connection";
import { ManageTelnyxCallbackWidget } from "components/modules/connections/channels/telnyx/manage-telnyx-callback-widget/manage-telnyx-callback-widget";
import { ManageTelnyxConnection } from "components/modules/connections/channels/telnyx/manage-telnyx-connection/manage-telnyx-connection";
import { ConfigureTwitterConnection } from "components/modules/connections/channels/twitter/configure-twitter-connection/configure-twitter-connection";
import { ManageWACloudNativeConnection } from "components/modules/connections/channels/whatsapp-cloud-native/manage-whatsapp-cloud-native-connection/manage-whatsapp-cloud-native-connection";
import { ManageWACloudConnection } from "components/modules/connections/channels/whatsapp-cloud/manage-whatsapp-cloud-connection/manage-whatsapp-cloud-connection";
import { ConfigureWhatsappPrivateConnection } from "components/modules/connections/channels/whatsapp-private/configure-whatsapp-private-connection/configure-whatsapp-private-connection";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import JSONModal from "utils/json-modal/json-modal";
import { SectionWithSideMenu } from "../../../common/section-with-side-menu";
import { ConfigureChatWidgetConnection } from "../../../modules/connections/channels/chat-widget/components/configure-chat-widget/configure-chat-widget-connection";
import { LiveChatAutomationConfig } from "../../../modules/connections/channels/chat-widget/components/live-chat-automations-config";
import { ConfigureMailbox } from "../../../modules/connections/channels/email/components/configure-mailbox/configure-mailbox";
import { EmailTemplate } from "../../../modules/connections/channels/email/components/forms/email-template";
import { ManageFacebookConnection } from "../../../modules/connections/channels/facebook/manage-facebook-connection/manage-facebook-connection";
import { ConnectionAutomationConfig } from "../../../modules/connections/common-automation-configs/connection-automations-config";
import { GeneralConnectionConfig } from "../../../modules/connections/common-connection-settings/general-connection-config";

const connectionGeneralConfigNav = {
  deepLink: "general",
  children: [
    {
      label: "Change Name",
      deepLink: "general?activeConfigs=Connection%20Name",
    },
    {
      label: "Restrict Access by User Groups",
      deepLink:
        "general?activeConfigs=Restrict%20Access%20by%20Teams%2C%20Departments%20or%20Custom%20Groups",
    },
    {
      label: "Routing Preferences",
      deepLink: "general?activeConfigs=Chat%20Routing%20Rules",
    },
    {
      label: "Make Conversation Tags Mandatory",
      deepLink: "general?activeConfigs=Make%20Conversation%20Tags%20Mandatory",
    },
    {
      label: "User Limits",
      deepLink: "general?activeConfigs=User%20Limits",
    },
  ],
};

const connectionAutomationNav = {
  deepLink: "automation",
  children: [
    {
      label: "Auto Close Conversations",
      deepLink: "automation?activeConfigs=Auto-Close%20Conversations",
    },
  ],
};

export const EmailConfigurationMenu: iNavigationMenuItem[] = [
  {
    type: "CUSTOM_SECTION",
    key: "title",
    element: (
      <SimpleMenuSectionTitle>Configure Connection</SimpleMenuSectionTitle>
    ),
  },
  {
    type: "LINK_ITEM",
    key: "general",
    icon: <i className="ri-tools-line"></i>,
    label: "General",
    component: GeneralConnectionConfig,
    ...connectionGeneralConfigNav,
  },
  {
    type: "LINK_ITEM",
    key: "mailbox",
    icon: <i className="ri-mail-settings-line"></i>,
    label: "Mailbox",
    component: ConfigureMailbox,
    deepLink: "mailbox",
    children: [
      {
        label: "Change Sender Name",
        deepLink: "mailbox?activeConfigs=Change%20Sender%20Name",
      },
      {
        label: "Change Click Connector Email Address",
        deepLink:
          "mailbox?activeConfigs=Change%20Click%20Connector%20Email%20Address",
      },
      {
        label: "Setup Custom Email Domain",
        deepLink: "mailbox?activeConfigs=Setup%20Custom%20Email%20Domain",
      },
      {
        label: "Connect Gmail/AWS/Custom SMTP",
        deepLink: "mailbox?activeConfigs=Connect%20Gmail%2FAWS%2FCustom%20SMTP",
      },
    ],
  },
  {
    type: "LINK_ITEM",
    key: "automation",
    icon: <i className="ri-magic-line"></i>,
    label: "Automations",
    component: ConnectionAutomationConfig,
    ...connectionAutomationNav,
  },
  {
    type: "LINK_ITEM",
    key: "ticket-workflows",
    icon: <i className="ri-shuffle-line"></i>,
    label: "Ticket Workflows",
    component: ConfigureTicketWorkflows,
    deepLink: "ticket-workflows",
    children: [
      {
        label: "Change Sender Name",
        deepLink: "mailbox?activeConfigs=Change%20Sender%20Name",
      },
      {
        label: "Change Click Connector Email Address",
        deepLink:
          "mailbox?activeConfigs=Change%20Click%20Connector%20Email%20Address",
      },
      {
        label: "Setup Custom Email Domain",
        deepLink: "mailbox?activeConfigs=Setup%20Custom%20Email%20Domain",
      },
      {
        label: "Connect Gmail/AWS/Custom SMTP",
        deepLink: "mailbox?activeConfigs=Connect%20Gmail%2FAWS%2FCustom%20SMTP",
      },
    ],
  },
  {
    type: "CUSTOM_SECTION",
    key: "illustration",
    element: (
      <img
        src="/assets/illustrations/emails-animate.svg"
        width={250}
        alt="Email"
      />
    ),
  },
];

export const LiveChatConfigurationMenu: iNavigationMenuItem[] = [
  {
    type: "CUSTOM_SECTION",
    key: "title",
    element: (
      <SimpleMenuSectionTitle>Configure Connection</SimpleMenuSectionTitle>
    ),
  },
  {
    type: "LINK_ITEM",
    key: "general",
    icon: <i className="ri-tools-line"></i>,
    label: "General",
    component: GeneralConnectionConfig,
    ...connectionGeneralConfigNav,
  },
  {
    type: "LINK_ITEM",
    key: "live-chat-widget",
    icon: <i className="ri-mail-star-line"></i>,
    label: "Chat Widget",
    component: ConfigureChatWidgetConnection,
    children: [
      {
        label: "Widget Colour",
        deepLink: "live-chat-widget?activeConfigs=Widget%20Colour",
      },
      {
        label: "Setup Greetings",
        deepLink: "live-chat-widget?activeConfigs=Setup%20Greetings",
      },
      {
        label: "Enable Chat Ratings",
        deepLink: "live-chat-widget?activeConfigs=Enable%20Chat%20Ratings",
      },
      {
        label: "Pre-chat Form",
        deepLink: "live-chat-widget?activeConfigs=Pre-chat%20Form",
      },
      // {
      //   label: "Setup FAQs",
      //   deepLink:
      //     "mailbox?activeConfigs=Change%20Click%20Connector%20Email%20Address",
      // },
      {
        label: "Customize Widget Content",
        deepLink: "live-chat-widget?activeConfigs=Customize%20Widget%20Content",
      },
      {
        label: "Setup Offline Hours",
        deepLink: "live-chat-widget?activeConfigs=Setup%20Offline%20Hours",
      },
      {
        label: "Allow Multiple Active Chats",
        deepLink:
          "live-chat-widget?activeConfigs=Allow%20Multiple%20Active%20Chats",
      },
      {
        label: "Auto Widget Popup",
        deepLink: "live-chat-widget?activeConfigs=Auto%20Widget%20Popup",
      },
      {
        label: "Blacklisted Clients",
        deepLink: "live-chat-widget?activeConfigs=Blacklisted%20Clients",
      },
      {
        label: "Install Widget",
        deepLink: "live-chat-widget?activeConfigs=Install%20Widget",
      },
      {
        label: "Disable Widget",
        deepLink: "live-chat-widget?activeConfigs=Disable%20Widget",
      },
    ],
  },
  {
    type: "LINK_ITEM",
    key: "automation",
    icon: <i className="ri-magic-line"></i>,
    label: "Automations",
    component: LiveChatAutomationConfig,
    ...connectionAutomationNav,
  },
  {
    type: "CUSTOM_SECTION",
    key: "extend",
    element: (
      <div
        className="flex flex-row justify-center items-center"
        style={{ height: 200 }}
      >
        <i className="ri-wechat-line text-10xl text-gray-200 dark:text-gray-700"></i>
      </div>
    ),
  },
];

export const FacebookConfigurationMenu: iNavigationMenuItem[] = [
  {
    type: "CUSTOM_SECTION",
    key: "title",
    element: (
      <SimpleMenuSectionTitle>Configure Connection</SimpleMenuSectionTitle>
    ),
  },
  {
    type: "LINK_ITEM",
    key: "general",
    icon: <i className="ri-tools-line"></i>,
    label: "General",
    component: GeneralConnectionConfig,
    ...connectionGeneralConfigNav,
  },
  {
    type: "LINK_ITEM",
    key: "connected-page",
    icon: <i className="ri-mail-star-line"></i>,
    label: "Connected Page",
    component: ManageFacebookConnection,
    deepLink: "connected-page",
  },
  {
    type: "LINK_ITEM",
    key: "automation",
    icon: <i className="ri-magic-line"></i>,
    label: "Automations",
    component: FacebookAutomationConfig,
    ...connectionAutomationNav,
  },
  {
    type: "LINK_ITEM",
    key: "blocked-users",
    icon: <i className="ri-forbid-2-line"></i>,
    label: "Blocked Users",
    component: BlockedFacebookUsers,
    deepLink: "blocked-users",
    children: [
      {
        label: "Manage BlockedU sers",
        deepLink: "blocked-users",
      },
    ],
  },
  {
    type: "CUSTOM_SECTION",
    key: "extend",
    element: (
      <div
        className="flex flex-row justify-center items-center"
        style={{ height: 200 }}
      >
        <i className="ri-facebook-line text-10xl text-gray-200 dark:text-gray-700"></i>
      </div>
    ),
  },
];

export const InstagramConfigurationMenu: iNavigationMenuItem[] = [
  {
    type: "CUSTOM_SECTION",
    key: "title",
    element: (
      <SimpleMenuSectionTitle>Configure Connection</SimpleMenuSectionTitle>
    ),
  },
  {
    type: "LINK_ITEM",
    key: "general",
    icon: <i className="ri-tools-line"></i>,
    label: "General",
    component: GeneralConnectionConfig,
    ...connectionGeneralConfigNav,
  },
  {
    type: "LINK_ITEM",
    key: "connected-page",
    icon: <i className="ri-instagram-line"></i>,
    label: "Connected Page",
    component: ManageInstagramConnection,
    deepLink: "connected-page",
  },
  {
    type: "LINK_ITEM",
    key: "automation",
    icon: <i className="ri-magic-line"></i>,
    label: "Automations",
    component: InstagramAutomationConfig,
    ...connectionAutomationNav,
  },
  {
    type: "CUSTOM_SECTION",
    key: "extend",
    element: (
      <div
        className="flex flex-row justify-center items-center"
        style={{ height: 200 }}
      >
        <i className="ri-instagram-line text-10xl text-gray-200 dark:text-gray-700"></i>
      </div>
    ),
  },
];

export const WhatsappCloudConfigurationMenu: iNavigationMenuItem[] = [
  {
    type: "CUSTOM_SECTION",
    key: "title",
    element: (
      <SimpleMenuSectionTitle>Configure Connection</SimpleMenuSectionTitle>
    ),
  },
  {
    type: "LINK_ITEM",
    key: "general",
    icon: <i className="ri-tools-line"></i>,
    label: "General",
    component: GeneralConnectionConfig,
    ...connectionGeneralConfigNav,
  },
  {
    type: "LINK_ITEM",
    key: "connected-account",
    icon: <i className="ri-mail-star-line"></i>,
    label: "Connected Account",
    component: ManageWACloudConnection,
    deepLink: "connected-account",
  },
  {
    type: "LINK_ITEM",
    key: "automation",
    icon: <i className="ri-magic-line"></i>,
    label: "Automations",
    component: ConnectionAutomationConfig,
    ...connectionAutomationNav,
  },
  {
    type: "CUSTOM_SECTION",
    key: "extend",
    element: (
      <div
        className="flex flex-row justify-center items-center"
        style={{ height: 200 }}
      >
        <i className="ri-whatsapp-line text-10xl text-gray-200 dark:text-gray-700"></i>
      </div>
    ),
  },
];

export const WhatsappCloudNativeConfigurationMenu: iNavigationMenuItem[] = [
  {
    type: "CUSTOM_SECTION",
    key: "title",
    element: (
      <SimpleMenuSectionTitle>Configure Connection</SimpleMenuSectionTitle>
    ),
  },
  {
    type: "LINK_ITEM",
    key: "general",
    icon: <i className="ri-tools-line"></i>,
    label: "General",
    component: GeneralConnectionConfig,
    ...connectionGeneralConfigNav,
  },
  {
    type: "LINK_ITEM",
    key: "connected-account",
    icon: <i className="ri-mail-star-line"></i>,
    label: "Connected Account",
    component: ManageWACloudNativeConnection,
    deepLink: "connected-account",
  },
  {
    type: "LINK_ITEM",
    key: "automation",
    icon: <i className="ri-magic-line"></i>,
    label: "Automations",
    component: ConnectionAutomationConfig,
    ...connectionAutomationNav,
  },
  {
    type: "CUSTOM_SECTION",
    key: "extend",
    element: (
      <div
        className="flex flex-row justify-center items-center"
        style={{ height: 200 }}
      >
        <i className="ri-whatsapp-line text-10xl text-gray-200 dark:text-gray-700"></i>
      </div>
    ),
  },
];

export const WhatsappConfigurationMenu: iNavigationMenuItem[] = [
  {
    type: "CUSTOM_SECTION",
    key: "title",
    element: (
      <SimpleMenuSectionTitle>Configure Connection</SimpleMenuSectionTitle>
    ),
  },
  {
    type: "LINK_ITEM",
    key: "general",
    icon: <i className="ri-tools-line"></i>,
    label: "General",
    component: GeneralConnectionConfig,
    ...connectionGeneralConfigNav,
  },
  {
    type: "LINK_ITEM",
    key: "connected-account",
    icon: <i className="ri-mail-star-line"></i>,
    label: "Connected Account",
    component: ConfigureWhatsappPrivateConnection,
    deepLink: "connected-account",
  },
  {
    type: "LINK_ITEM",
    key: "automation",
    icon: <i className="ri-magic-line"></i>,
    label: "Automations",
    component: ConnectionAutomationConfig,
    ...connectionAutomationNav,
  },
  {
    type: "CUSTOM_SECTION",
    key: "extend",
    element: (
      <div
        className="flex flex-row justify-center items-center"
        style={{ height: 200 }}
      >
        <i className="ri-whatsapp-line text-10xl text-gray-200 dark:text-gray-700"></i>
      </div>
    ),
  },
];

export const TelnyxConfigurationMenu: iNavigationMenuItem[] = [
  {
    type: "CUSTOM_SECTION",
    key: "title",
    element: (
      <SimpleMenuSectionTitle>Configure Connection</SimpleMenuSectionTitle>
    ),
  },
  {
    type: "LINK_ITEM",
    key: "general",
    icon: <i className="ri-tools-line"></i>,
    label: "General",
    component: GeneralConnectionConfig,
    ...connectionGeneralConfigNav,
  },
  {
    type: "LINK_ITEM",
    key: "connection",
    icon: <i className="ri-mail-star-line"></i>,
    label: "Connection",
    component: ManageTelnyxConnection,
    deepLink: "connection",
  },
  {
    type: "LINK_ITEM",
    key: "automation",
    icon: <i className="ri-magic-line"></i>,
    label: "Automations",
    component: ConnectionAutomationConfig,
    ...connectionAutomationNav,
  },
  {
    type: "LINK_ITEM",
    key: "callbackWidget",
    icon: <i className="ri-phone-line"></i>,
    label: "Callback Widget",
    component: ManageTelnyxCallbackWidget,
    deepLink: "callback-widget",
  },
  {
    type: "CUSTOM_SECTION",
    key: "extend",
    element: (
      <div
        className="flex flex-row justify-center items-center"
        style={{ height: 200 }}
      >
        <i className="ri-phone-line text-10xl text-gray-200 dark:text-gray-700"></i>
      </div>
    ),
  },
];

export const TwitterConfigurationMenu: iNavigationMenuItem[] = [
  {
    type: "CUSTOM_SECTION",
    key: "title",
    element: (
      <SimpleMenuSectionTitle>Configure Connection</SimpleMenuSectionTitle>
    ),
  },
  {
    type: "LINK_ITEM",
    key: "general",
    icon: <i className="ri-tools-line"></i>,
    label: "General",
    component: GeneralConnectionConfig,
    ...connectionGeneralConfigNav,
  },
  {
    type: "LINK_ITEM",
    key: "connected-account",
    icon: <i className="ri-mail-star-line"></i>,
    label: "Connected Account",
    component: ConfigureTwitterConnection,
    deepLink: "connected-account",
  },
  {
    type: "LINK_ITEM",
    key: "automation",
    icon: <i className="ri-magic-line"></i>,
    label: "Automations",
    component: EmailTemplate,
    ...connectionAutomationNav,
  },
  {
    type: "CUSTOM_SECTION",
    key: "extend",
    element: (
      <div
        className="flex flex-row justify-center items-center"
        style={{ height: 200 }}
      >
        <i className="ri-twitter-line text-10xl text-gray-200 dark:text-gray-700"></i>
      </div>
    ),
  },
];

export const TelegramConfigurationMenu: iNavigationMenuItem[] = [
  {
    type: "CUSTOM_SECTION",
    key: "title",
    element: (
      <SimpleMenuSectionTitle>Configure Connection</SimpleMenuSectionTitle>
    ),
  },
  {
    type: "LINK_ITEM",
    key: "general",
    icon: <i className="ri-tools-line"></i>,
    label: "General",
    component: GeneralConnectionConfig,
    ...connectionGeneralConfigNav,
  },
  {
    type: "LINK_ITEM",
    key: "connected-account",
    icon: <i className="ri-mail-star-line"></i>,
    label: "Connected Account",
    component: ManageTelegramConnection,
    deepLink: "connected-account",
  },
  {
    type: "LINK_ITEM",
    key: "automation",
    icon: <i className="ri-magic-line"></i>,
    label: "Automations",
    component: ConnectionAutomationConfig,
    ...connectionAutomationNav,
  },
  {
    type: "CUSTOM_SECTION",
    key: "extend",
    element: (
      <div
        className="flex flex-row justify-center items-center"
        style={{ height: 200 }}
      >
        <i className="ri-telegram-line text-10xl text-gray-200 dark:text-gray-700"></i>
      </div>
    ),
  },
];

export const ConnectionSettingsContainer = () => {
  const location = useLocation();
  const connectionId = useMemo(() => {
    // console.log('location.pathname.split("/")', location.pathname.split("/"));
    return location.pathname.split("/")[3]!;
  }, [location]);

  const connection = useSelector(selectConnectionById(connectionId));
  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  const { doAction: deleteConnection, isProcessing: isDeletingConnection } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (confirmation: string) =>
          SDK.dangerousDeletion.deleteConnection(connectionId, confirmation),
        successMessage: "Connection and associated data is deleted",
        failureMessage: "Something went wrong",
      }),
      [connectionId],
    );

  const {
    doAction: deActivateConnection,
    isProcessing: isDeactivatingConnection,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () => SDK.connections.deleteById(connectionId),
      successMessage: "Connection has been deactivated",
      failureMessage: "Something went wrong",
    }),
    [connectionId],
  );

  const {
    doAction: dismissConnectionError,
    isProcessing: isDismissingConnectionError,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () =>
        SDK.connections.editById(connectionId, {
          metaData: {
            hasError: false,
          },
        }),
      successMessage: "Done",
      failureMessage: "Something went wrong",
    }),
    [connectionId],
  );

  const { routes: _connectionMenu, defaultRoute } = useMemo(() => {
    if (!connection?.type) {
      return {
        routes: [],
        defaultRoute: "",
      };
    }
    if (connection.type === "EMAIL") {
      return {
        routes: EmailConfigurationMenu,
        defaultRoute: "mailbox",
      };
    } else if (connection.type === "FACEBOOK") {
      return {
        routes: FacebookConfigurationMenu,
        defaultRoute: "connected-page",
      };
    } else if (connection.type === "INSTAGRAM") {
      return {
        routes: InstagramConfigurationMenu,
        defaultRoute: "connected-page",
      };
    } else if (connection.type === "WEB_CHAT") {
      return {
        routes: LiveChatConfigurationMenu,
        defaultRoute: "live-chat-widget",
      };
    } else if (connection.type === "WHATS_APP_PRIVATE") {
      return {
        routes: WhatsappConfigurationMenu,
        defaultRoute: "connected-account",
      };
    } else if (connection.type === "WHATS_APP_CLOUD") {
      return {
        routes: WhatsappCloudConfigurationMenu,
        defaultRoute: "connected-account",
      };
    } else if (connection.type === "WHATS_APP_CLOUD_NATIVE") {
      return {
        routes: WhatsappCloudNativeConfigurationMenu,
        defaultRoute: "connected-account",
      };
    } else if (connection.type === "TELNYX") {
      return {
        routes: TelnyxConfigurationMenu,
        defaultRoute: "connection",
      };
    } else if (connection.type === "TWITTER") {
      return {
        routes: TwitterConfigurationMenu,
        defaultRoute: "connected-account",
      };
    } else if (connection.type === "TELEGRAM") {
      return {
        routes: TelegramConfigurationMenu,
        defaultRoute: "connected-account",
      };
    }
    return {
      routes: [],
    };
  }, [connection?.type]);

  const connectionMenu = useMemo(() => {
    if (isAdvancedMode) {
      return [
        ..._connectionMenu,
        {
          type: "CUSTOM_SECTION",
          key: "advanced-mode",
          element: (
            <div className="flex flex-col gap-4">
              {window.location.href.includes("localhost") && (
                <Popconfirm
                  placement="leftTop"
                  title="Are you sure? All conversation and contact records will be permanently removed"
                  onConfirm={() => {
                    deleteConnection("CONFIRM");
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="text"
                    icon={<i className="ri-delete-bin-3-line"></i>}
                    loading={isDeletingConnection}
                  >
                    Delete Connection
                  </Button>
                </Popconfirm>
              )}

              <Popconfirm
                placement="leftTop"
                title="Are you sure? This cannot be undone"
                onConfirm={() => {
                  deActivateConnection();
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="text"
                  icon={<i className="ri-delete-back-line"></i>}
                  loading={isDeactivatingConnection}
                >
                  De-activate Connection
                </Button>
              </Popconfirm>
              <Button
                type="text"
                icon={<i className="ri-code-box-line" />}
                onClick={() => {
                  JSONModal(connection);
                }}
              >
                View Data
              </Button>
              <Button
                type="text"
                icon={<i className="ri-eraser-line"></i>}
                loading={isDismissingConnectionError}
                onClick={() => {
                  dismissConnectionError();
                }}
              >
                Dismiss Connection Error Warning
              </Button>
            </div>
          ),
        } as iNavigationMenuItem,
      ];
    }
    return _connectionMenu;
  }, [
    _connectionMenu,
    connection,
    deActivateConnection,
    deleteConnection,
    dismissConnectionError,
    isAdvancedMode,
    isDeactivatingConnection,
    isDeletingConnection,
    isDismissingConnectionError,
  ]);

  const additionalProps = useMemo(
    () => ({ connectionId: connection?.id }),
    [connection?.id],
  );

  return (
    <SectionWithSideMenu
      menu={connectionMenu}
      baseUrl={`/configurations/connections/${connectionId}`}
      additionalProps={additionalProps}
      defaultRoute={
        defaultRoute
          ? `/configurations/connections/${connectionId}/${defaultRoute}`
          : undefined
      }
    />
  );
};
