import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React, { Suspense } from "react";

const DPADownloader = React.lazy(() => import("./dpa-downloader"));

export const LazyDPADownloader = () => {
  return (
    <Suspense fallback={<SuspenseLoadingIndicator />}>
      <DPADownloader />
    </Suspense>
  );
};
