import { escapeRegExp } from "utils/escape-regex-search-string";

export function buildNameSearchQuery(words: string[]) {
  if (words.length === 1) {
    const firstWord = words[0];
    return [
      {
        "data.firstName": {
          $regex: `${escapeRegExp(firstWord)}`,
          $options: "i"
        }
      },
      {
        "data.lastName": {
          $regex: `${escapeRegExp(firstWord)}`,
          $options: "i"
        }
      }
    ];
  }

  if (words.length === 2) {
    const firstWord = words[0];
    const rest = words
      .slice(1)
      .join(",")
      .replace(/,/g, " ");
    const all = words.join(",").replace(/,/g, " ");
    return [
      {
        $and: [
          {
            "data.firstName": {
              $regex: `${escapeRegExp(firstWord)}`,
              $options: "i"
            }
          },
          {
            "data.lastName": {
              $regex: `${escapeRegExp(rest)}`,
              $options: "i"
            }
          }
        ]
      },
      {
        "data.firstName": {
          $regex: `${escapeRegExp(all)}`,
          $options: "i"
        }
      }
    ];
  }
  const firstTwoWords = words
    .slice(0, 2)
    .join(",")
    .replace(/,/g, " ");
  const restAfterFirstTwoWords = words
    .slice(2)
    .join(",")
    .replace(/,/g, " ");

  const firstWord = words
    .slice(0, 1)
    .join(",")
    .replace(/,/g, " ");
  const restAfterFirstWord = words
    .slice(1)
    .join(",")
    .replace(/,/g, " ");

  return [
    {
      $and: [
        {
          "data.firstName": {
            $regex: `${escapeRegExp(firstTwoWords)}`,
            $options: "i"
          }
        },
        {
          "data.lastName": {
            $regex: `${escapeRegExp(restAfterFirstTwoWords)}`,
            $options: "i"
          }
        }
      ]
    },
    {
      $and: [
        {
          "data.firstName": {
            $regex: `${escapeRegExp(firstWord)}`,
            $options: "i"
          }
        },
        {
          "data.lastName": {
            $regex: `${escapeRegExp(restAfterFirstWord)}`,
            $options: "i"
          }
        }
      ]
    }
  ];
}
