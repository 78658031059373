import { Form, InputNumber, Select } from "antd";
import { StyledSwitch } from "components/common/styled-swtich";
import { useSelector } from "react-redux";
import { selectAllGroups } from "store/modules/groups/groups.selectors";
import { ConnectionDataEditor } from "../connection-data-editor";

export const RoutingPreferencesForm = ({ connectionId }: { connectionId }) => {
  const userGroups = useSelector(selectAllGroups);

  return (
    <ConnectionDataEditor
      connectionId={connectionId}
      icon={"ri-arrow-go-forward-line"}
      title="Chat Routing Rules"
      description="Choose how chats are routed to users"
    >
      {/* Routing Strategy */}
      <Form.Item
        label="Routing Strategy"
        name={["data", "routingStrategy"]}
        help="Strategy used to pick the user while routing"
      >
        <Select>
          <Select.Option value={"ROUND_ROBIN"}>Round Robin</Select.Option>
          <Select.Option value={"RANDOM"}>Random</Select.Option>
          <Select.Option value={"NO_ROUTE"}>Don't Route</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const routingStrategy = getFieldValue(["data", "routingStrategy"]);
          if (
            routingStrategy === "ROUND_ROBIN" ||
            routingStrategy === "RANDOM"
          ) {
            return (
              <>
                <Form.Item
                  label="Restrict Routing to specific user groups"
                  name={["data", "groupsToRoute"]}
                  help="Select the user groups that can receive conversation invites"
                >
                  <Select allowClear mode="multiple">
                    {(userGroups || []).map((userGroup) => (
                      <Select.Option key={userGroup.label} value={userGroup.id}>
                        {userGroup.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* Routing Method  */}

                <Form.Item
                  label="Routing Method"
                  name={["data", "routingMethod"]}
                  help="How routed user gets the invite"
                >
                  <Select defaultValue="ASSIGN">
                    <Select.Option value={"INVITE"}>Invite User</Select.Option>
                    <Select.Option value={"ASSIGN"}>Assign User</Select.Option>
                  </Select>
                </Form.Item>

                <StyledSwitch
                  label="Route only to online users"
                  name={["data", "routeOnlyToOnlineUsers"]}
                />

                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const routingMethod = getFieldValue([
                      "data",
                      "routingMethod",
                    ]);
                    if (routingMethod === "INVITE") {
                      return (
                        <>
                          <Form.Item
                            label="Expire Routing invitations"
                            name={["data", "inviteExpiry"]}
                            help="Invitation expiry in seconds. Only applicable if 'Invite User' method is selected"
                          >
                            <InputNumber
                              defaultValue={45}
                              min={30}
                              max={120}
                              step={1}
                              className="w-full"
                              {...{ addonAfter: "Seconds" }}
                            />
                          </Form.Item>
                        </>
                      );
                    }
                    return <></>;
                  }}
                </Form.Item>
              </>
            );
          }
          return <></>;
        }}
      </Form.Item>

      <Form.Item
        label="Automatically Assign Last Chatted User"
        name={["data", "autoAssignLastAgent"]}
      >
        <Select style={{ minWidth: 300 }} placeholder="Select an option">
          <Select.Option value={"ALWAYS"}>Always</Select.Option>
          <Select.Option value={"NEVER"}>Never</Select.Option>
          <Select.Option value={"IF_AVAILABLE"}>
            If user is available
          </Select.Option>
        </Select>
      </Form.Item>
    </ConnectionDataEditor>
  );
};
