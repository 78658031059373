import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iPreset } from "@sdk/user-management/preset-state-model";
import { Button, Collapse, Divider, Input, Popover } from "antd";
import classnames from "classnames";
import { filter, findIndex } from "lodash";
import { MyManagePresets } from "modal-registry";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { selectCurrentUserPresetsX } from "store/modules/users/users.selectors";
import { selectOrganizationWideMessagePresetsX } from "store/modules/workspace/workspace.selectors";
import { useSearch } from "utils/hooks/use-search";
import { useStateWithGetter } from "utils/hooks/use-state-with-getter";
import { useKeyboardListNavigation } from "../../../../utils/hooks/use-keyboard-list-navigation";
import { PresetFolderViewer, PresetItem } from "./preset-folder-viewer";
import "./preset-selector.scss";

export const AddPresetButton = forwardRef(
  (
    {
      onPresetSelect,
      showRichText
    }: {
      onPresetSelect: (preset: iPreset) => any;
      showRichText?: boolean;
    },
    ref
  ) => {
    const [popOverVisible, setPopoverVisibility] = useState(false);

    const isMobileView = useMediaQuery({ query: "(max-width: 500px)" });

    useImperativeHandle(
      ref,
      () => ({
        setPopoverVisibility: setPopoverVisibility
      }),
      []
    );

    return (
      <Popover
        open={popOverVisible}
        onOpenChange={setPopoverVisibility}
        destroyTooltipOnHide={true}
        content={
          <PresetExplorer
            onPresetSelect={onPresetSelect}
            showRichText={showRichText}
            onExit={() => setPopoverVisibility(false)}
          />
        }
        trigger="click"
        overlayClassName="color-input-popover"
      >
        <Button
          // className="font-semibold"
          type="text"
          icon={<i className="ri-message-2-fill"></i>}
          size="small"
        >
          {!isMobileView && <> Message Presets</>}
        </Button>
      </Popover>
    );
  }
);

const PresetExplorer = ({
  onPresetSelect: _onPresetSelect,
  showRichText,
  onExit
}: {
  onPresetSelect: (preset: iPreset) => any;
  showRichText?: boolean;
  onExit: () => any;
}) => {
  const onPresetSelect = useCallback(
    (preset: iPreset) => {
      _onPresetSelect(preset);
      onExit();
    },
    [_onPresetSelect, onExit]
  );
  const presetMessages = useSelector(selectOrganizationWideMessagePresetsX);
  const messagePresetsState = useSelector(selectCurrentUserPresetsX);
  const presetState = useMemo(() => {
    return {
      folders: [...presetMessages.folders, ...messagePresetsState.folders],
      presets: [...presetMessages.presets, ...messagePresetsState.presets]
    };
  }, [
    messagePresetsState.folders,
    messagePresetsState.presets,
    presetMessages.folders,
    presetMessages.presets
  ]);

  const {
    filteredData: filteredPresets,
    searchTerm,
    setSearchTerm
  } = useSearch(presetState.presets);

  const presetsWithoutFolders = useMemo(() => {
    return presetState.presets
      .filter(preset => !preset.folder)
      .filter(preset => {
        if (!showRichText) {
          return preset.type !== "RICH_TEXT";
        }
        return true;
      });
  }, [presetState.presets, showRichText]);

  const { changePanelState } = useModalPanels();

  const directList = useMemo(() => {
    const list: iPreset[] = [];
    for (const folder of presetState.folders) {
      const presetsInFolder = filter(
        presetState.presets.filter(preset => {
          if (!showRichText) {
            return preset.type !== "RICH_TEXT";
          }
          return true;
        }),
        { folder: folder.id }
      );
      for (const item of presetsInFolder) {
        list.push(item);
      }
    }
    for (const item of presetsWithoutFolders) {
      list.push(item);
    }
    return list;
  }, [
    presetState.folders,
    presetState.presets,
    presetsWithoutFolders,
    showRichText
  ]);

  const currentList = searchTerm.length > 0 ? filteredPresets : directList;

  const containerRef = useRef<any>();

  const { index: selectedIndex, selected, set } = useKeyboardListNavigation({
    list: currentList,
    onEnter: ({ element }) => element && onPresetSelect(element),
    ref: containerRef
  });
  const onSelectItemUnderCursor = useCallback(() => {
    if (selected) {
      onPresetSelect(selected);
    }
  }, [onPresetSelect, selected]);

  // Auto Focus Search Input
  const searchInputRef = useRef<any>();
  useEffect(() => {
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 200);
  }, []);

  // When Moved through Cursor, automatically expand folders
  const [
    openedFolders,
    setOpenedFolders,
    getOpenedFolders
  ] = useStateWithGetter([] as string[]);
  useEffect(() => {
    if (searchTerm.length === 0 && selected) {
      if (selected.folder) {
        if (!getOpenedFolders().includes(selected.folder)) {
          setOpenedFolders([selected.folder]);
        }
      }
    }
  }, [getOpenedFolders, searchTerm.length, selected, setOpenedFolders]);

  return (
    <div
      className="flex flex-col preset-selector p-4"
      style={{ width: 375, height: 450 }}
      ref={containerRef}
    >
      <div className="panel-title flex flex-row justify-between items-center">
        <div
          className={classnames(
            "flex flex-row items-center flex-1 font-bold text-xl"
          )}
        >
          <i className="ri-message-2-fill"></i>
          <div className="title ml-2">Message Presets</div>
        </div>

        <Button
          icon={<i className="ri-settings-2-line"></i>}
          type="text"
          shape="circle"
          onClick={e => {
            changePanelState(MyManagePresets, true, {});
            onExit();
          }}
        />
      </div>

      <div className="body h-full flex-1 overflow-auto border-t border-gray-200 dark:border-gray-700 mt-2 pt-6">
        <div className="search-bar mb-6">
          <Input.Search
            placeholder="Search Presets..."
            className="rounded-md"
            value={searchTerm}
            ref={searchInputRef}
            onChange={e => setSearchTerm(e.target.value)}
            autoFocus={true}
            onSearch={onSelectItemUnderCursor}
          />
        </div>
        <div className="body">
          {searchTerm.length > 0 ? (
            <div className="search-results">
              <Divider className="text-sm">
                {filteredPresets.length} Presets
              </Divider>
              {filteredPresets.map((preset, index) => (
                <PresetItem
                  key={preset.id}
                  preset={preset}
                  onSelected={onPresetSelect}
                  isActive={selected?.id === preset.id}
                  onMouseOver={() => set({ cursor: index })}
                />
              ))}
              {filteredPresets.length === 0 && (
                <div className="text-xl my-6 mb-16 flex flex-col items-center">
                  <div>
                    <i className="ri-emotion-sad-line text-8xl text-blue-400"></i>
                  </div>
                  <div className="text-gray-500 leading-none">
                    Search yielded no results
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              <Divider className="text-sm">
                {presetState.presets.length} Presets
              </Divider>
              <div className="folders">
                <Collapse
                  className="border-0 w-full"
                  activeKey={openedFolders}
                  onChange={key =>
                    setOpenedFolders(Array.isArray(key) ? key : [key])
                  }
                  accordion
                  ghost
                >
                  {presetState.folders.map(folder => (
                    <PresetFolderViewer
                      showRichText={showRichText}
                      key={folder.id}
                      folder={folder}
                      state={presetState}
                      onSelected={onPresetSelect}
                      presetUnderCursor={selected?.id}
                      setPresetUnderCursor={presetId => {
                        const index = findIndex(directList, { id: presetId });
                        set({ cursor: index });
                      }}
                    />
                  ))}
                </Collapse>
              </div>
              <div className="non-folder">
                {presetsWithoutFolders.map(preset => (
                  <PresetItem
                    key={preset.id}
                    preset={preset}
                    onSelected={onPresetSelect}
                    isActive={selected?.id === preset.id}
                    onMouseOver={() => {
                      const index = findIndex(directList, { id: preset.id });
                      set({ cursor: index });
                    }}
                  />
                ))}
              </div>
              {presetState.presets.length === 0 && (
                <div className="text-xl my-6 mb-16 flex flex-col items-center">
                  <div>
                    <i className="ri-emotion-sad-line text-8xl text-blue-400"></i>
                  </div>
                  <div className="text-gray-500 leading-none">
                    There are no presets configured
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
