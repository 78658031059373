import { iMessageAttachment } from "@sdk/conversations/conversations.models";
import { Button } from "antd";

export const LinkAttachment = ({
  attachment
}: {
  attachment: iMessageAttachment;
}) => {
  const title =
    attachment?.payload?.link?.title ||
    (attachment?.payload?.link?.metaData?.postData ? "Post Reference" : "");
  const description =
    attachment.payload.link?.description ||
    attachment?.payload?.link?.metaData?.postData?.message;
  const url = attachment?.payload?.link?.url;
  const imagePreview =
    attachment?.payload?.link?.imagePreview ||
    attachment?.payload?.link?.metaData?.postData?.image;

  return (
    <a
      className="attachment-inner-cont link cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 no-underline p-4 rounded-md max-w-md"
      target="_blank"
      rel="noreferrer"
      href={url}
    >
      <div className="font-bold">{title}</div>
      <div className="text-gray-600 text-sm">{description}</div>
      <div className="text-gray-600 text-xs">{url}</div>

      {imagePreview && (
        <div className="image mt-2">
          <img src={imagePreview} alt="Preview" />
        </div>
      )}
      {/* Todo */}
      {attachment?.payload?.link?.metaData?.postId && (
        <div className="more-links mt-4">
          <Button className="clickable">Open Post</Button>
        </div>
      )}
    </a>
  );
};
