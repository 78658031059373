import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "react-redux";
import {
  selectAccessToken,
  selectCurrentUser,
} from "store/modules/users/users.selectors";
import { getSubscription, subscribeUser } from "subscription";

export const usePushNotificationStatus = () => {
  const store = useStore();
  const [isOfflinePushEnabled, setOfflinePushNotificationStatus] =
    useState(false);
  const user = selectCurrentUser(store.getState());

  useEffect(() => {
    if (user) {
      const accessToken = selectAccessToken(store.getState());
      getSubscription(accessToken, user)
        .then((d) => {
          console.log("d", d);
          if (d && d.isRegistered) {
            setOfflinePushNotificationStatus(true);
          } else {
            setOfflinePushNotificationStatus(false);
          }
        })
        .catch((d) => {
          // Ignore Error
          setOfflinePushNotificationStatus(false);
        });
    }
  }, [store, user]);

  const subscribeToPush = useCallback(() => {
    const accessToken = selectAccessToken(store.getState());
    const user = selectCurrentUser(store.getState());

    subscribeUser(accessToken, user);
  }, [store]);

  const returnVal = useMemo(
    () => ({
      isOfflinePushEnabled,
      subscribeToPush,
    }),
    [isOfflinePushEnabled, subscribeToPush],
  );

  return returnVal;
};
