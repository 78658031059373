import { SDK } from "@sdk";
import { iConnection } from "@sdk/conversations/conversations.models";
import { processServerError } from "@sdk/sdk.hooks";
import { Button, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  AddConnectionLayout,
  AddConnectionModalContainer,
  AddConnectionStepsContainer,
  IntegrationSuccessfulScreen,
  StepContainer
} from "components/modules/connections/add-connection-modal-templates";
import { generateDefaultConnectionConfig } from "components/modules/connections/common-connection-settings/default-connection-config-generator";
import { ConnectionIcons } from "components/modules/connections/helpers/connection-icons";
import { push } from "connected-react-router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { ChatWidgetInstallInstructions } from "../chat-widget-install-instructions/chat-widget-install-instructions";
import { ConfigureChatWidgetConnection } from "../configure-chat-widget/configure-chat-widget-connection";

export const AddChatWidgetConnectionModal = ({
  visible,
  onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const [form] = useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [createdConnection, setCreatedConnection] = useState("");

  // Create Call
  const [isProcessing, setIsProcessing] = useState(false);

  const store = useStore();

  const onAddConnection = useCallback(async () => {
    try {
      await form.validateFields();
      const {
        label,
        userGroupsGivenAccess,
        ...otherData
      } = form.getFieldsValue();
      const configData = generateDefaultConnectionConfig("WEB_CHAT", store);

      Object.assign(configData, otherData);

      const connectionData: Partial<iConnection> = {
        type: "WEB_CHAT",
        label: label,
        userGroups: userGroupsGivenAccess,
        data: configData
      };

      setIsProcessing(true);
      try {
        const connection = await SDK.connections.create(connectionData);
        message.success("Connection has been created");
        setIsProcessing(false);
        form.resetFields();
        setCreatedConnection(connection.id!);
        setCurrentStep(1);
      } catch (e) {
        console.log("Error while creating connection", e);
        message.error(processServerError(e, "Something went wrong"));
        setIsProcessing(false);
      }
    } catch (e) {
      // Validation Failed; Do nothing
      message.error("Please check your input");
    }
  }, [form, store]);

  const resetModal = useCallback(() => {
    form.resetFields();
    setCurrentStep(0);
    setCreatedConnection("");
  }, [form]);

  useEffect(() => {
    if (!visible) {
      resetModal();
    }
  }, [resetModal, visible]);

  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      label: "Live Chat"
    }),
    []
  );

  return (
    <>
      <AddConnectionModalContainer
        title="Configure Chat Widget"
        icon={<i className={ConnectionIcons.WEB_CHAT}></i>}
        isVisible={visible}
        onChangeVisibility={onChangeVisibility}
      >
        <AddConnectionLayout
          currentStep={currentStep}
          totalSteps={2}
          stepsWithSidebars={[0]}
          topBar={
            <AddConnectionStepsContainer
              currentStep={currentStep}
              steps={[
                {
                  label: "Name Widget",
                  icon: <i className="ri-number-1"></i>
                },
                {
                  label: "Configure Widget",
                  icon: <i className="ri-number-2"></i>
                },
                {
                  label: "Install Widget",
                  icon: <i className="ri-number-3"></i>
                }
              ]}
            />
          }
          sidebarImage={
            <div className="text-10xl opacity-50">
              <i className={ConnectionIcons.WEB_CHAT}></i>
            </div>
          }
        >
          <StepContainer stepNumber={0} currentStep={currentStep}>
            <Form
              layout="vertical"
              form={form}
              initialValues={initialValues}
              requiredMark={false}
              onFinish={onAddConnection}
            >
              <div className="text-gray-600 dark:text-gray-400 mb-6 mode_transition">
                Let's name your chat widget so that we can identify it later
              </div>

              {/* Name */}
              <Form.Item
                label={<div className="text-2xl">Chat Widget Name</div>}
                name="label"
                rules={[
                  { required: true, message: "Please enter a friendly name" }
                ]}
                help="A friendly name to identify channel Eg: Live Chat"
              >
                <Input placeholder="" size="large" />
              </Form.Item>

              <div className="button-container my-4 flex flex-row justify-end items-center w-full mt-8">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isProcessing}
                  icon={<i className="ri-arrow-right-circle-fill"></i>}
                  size="large"
                >
                  Continue
                </Button>
              </div>
            </Form>
          </StepContainer>

          <StepContainer stepNumber={1} currentStep={currentStep}>
            <div className="flex flex-col h-full">
              <div className="my-4 mb-8 flex flex-row justify-end items-center w-full">
                <Button
                  type="primary"
                  loading={isProcessing}
                  icon={<i className="ri-arrow-right-circle-fill"></i>}
                  onClick={() => setCurrentStep(2)}
                  size="large"
                >
                  Continue to Installation
                </Button>
              </div>
              <div className="flex-1">
                <ConfigureChatWidgetConnection
                  connectionId={createdConnection}
                  hideHeader={true}
                />
              </div>
              <div className="my-4 flex flex-row justify-end items-center w-full">
                <Button
                  type="primary"
                  loading={isProcessing}
                  icon={<i className="ri-arrow-right-circle-fill"></i>}
                  onClick={() => setCurrentStep(2)}
                  size="large"
                >
                  Continue to Installation
                </Button>
              </div>
            </div>
          </StepContainer>

          <StepContainer stepNumber={2} currentStep={currentStep}>
            <IntegrationSuccessfulScreen
              onManageConnection={() => {
                dispatch(
                  push(`/configurations/connections/${createdConnection}/`)
                );
              }}
              onHideWizard={() => onChangeVisibility(false)}
              subTitle="One more step...."
              title="Your chat widget is ready!"
            >
              <div className="w-full max-w-4xl text-left">
                <ChatWidgetInstallInstructions
                  connectionId={createdConnection}
                  openPanelOnShow={true}
                />
              </div>
            </IntegrationSuccessfulScreen>
          </StepContainer>
        </AddConnectionLayout>
      </AddConnectionModalContainer>
    </>
  );
};
