import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  iCustomReport,
  iOrganizationAppData
} from "@sdk/user-management/user-management.models";
import { Button, Form, Input, Modal, Select, message } from "antd";
import classnames from "classnames";
import { ModalTitle } from "components/common/modal-title";
import { push } from "connected-react-router";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { DeepPartial } from "redux";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { setOrganization } from "store/modules/workspace/workspace.actions";
import { selectCustomReports } from "store/modules/workspace/workspace.selectors";
import { uuidv4 } from "utils/generate-uuid";
import { iReportCanvas } from "../report-canvas/widget-canvas-models";
import { ReportTypeIcons } from "../reports-page-menu/reports-menu";

const availableReport = [
  // {
  //   id: "GENERAL",
  //   label: "Common Report",
  // },
  {
    id: "CONVERSATION",
    label: "Conversation Report"
  },
  {
    id: "LEADS",
    label: "Lead Report"
    // disabled: true
  },
  {
    id: "ACTIVITIES",
    label: "Activities Report",
    disabled: false
  }
  // {
  //   id: "USER_REPORT",
  //   label: "User Report",
  // },
  // {
  //   id: "COMPANIES",
  //   label: "Companies Report",
  // },
  // {
  //   id: "OPPORTUNITIES",
  //   label: "Opportunities Report",
  // },
  // {
  //   id: "USER_PERFORMANCE_REPORT",
  //   label: "User Performance Report",
  // },
];

export const AddCustomReportModal = ({
  visible,
  onChangeVisibility,
  onCreated,
  currentCanvas
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated?: () => any;
  currentCanvas?: iReportCanvas;
}) => {
  const [form] = Form.useForm();

  const currentUserId = useSelector(selectCurrentUserId);

  const customReports = useSelector(selectCustomReports);

  const { doAction: onUpdate, isProcessing, dispatch } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.editCurrentOrganization(values).then(res => {
          dispatch(setOrganization(res));
          onChangeVisibility(false);
        }),
      successMessage: "Report Canvas has been created",
      failureMessage: "Something went wrong"
    }),
    [onChangeVisibility]
  );

  const addNewReport = useCallback(
    (newReport: iCustomReport) => {
      onUpdate({
        appData: {
          REPORTS: {
            customReportViews: [...customReports, newReport]
          }
        }
      } as DeepPartial<iOrganizationAppData>).then(d => {
        dispatch(push(`/reports/custom-reports/${newReport.id}`));
      });
    },
    [customReports, dispatch, onUpdate]
  );

  useEffect(() => {
    if (currentCanvas) {
      form.setFieldsValue({
        type: currentCanvas.type,
        title: `Copy of ${currentCanvas.title}`,
        description: currentCanvas.description
      });
    }
  }, [currentCanvas, form]);

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-file-chart-line"></i>}
          title="Create New Report"
        />
      }
      open={visible}
      onOk={() => form.submit()}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Create"
      okButtonProps={{
        loading: isProcessing
      }}
      data-click-context="Create New Report Modal"
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          type: "CONVERSATION"
        }}
        requiredMark={false}
        className="bold-form-labels"
        onFinish={async () => {
          try {
            await form.validateFields();
            const { title, description, type } = form.getFieldsValue();
            const reportCanvas = {
              id: uuidv4(),
              title: title,
              description: description,
              type: type || currentCanvas?.type,
              layout: currentCanvas?.layout || [],
              widgets: currentCanvas?.widgets || [],
              filters: [],
              dateField: "",
              createdAt: Date.now(),
              createdBy: currentUserId,
              isShared: false
            };
            addNewReport(reportCanvas);
          } catch (e) {
            message.error("Please check your input");
          }
        }}
      >
        {!currentCanvas && (
          <Form.Item
            name="type"
            label="Report Type"
            rules={[
              {
                required: true,
                message: "Please select a report type"
              }
            ]}
          >
            <Select style={{ minWidth: 150 }}>
              {availableReport.map(item => (
                <Select.Option value={item.id} disabled={item.disabled}>
                  <i
                    className={classnames(ReportTypeIcons[item.id], "mr-2")}
                  ></i>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="title"
          label="Report Title"
          rules={[
            {
              required: true,
              message: "Please enter a title"
            }
          ]}
        >
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item name="description" label="Report Description" rules={[]}>
          <Input.TextArea placeholder="Describe what is this report about (optional)" />
        </Form.Item>
        <Button htmlType="submit" type="primary" className="hidden">
          Save
        </Button>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
