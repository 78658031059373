import { Button, Card, Input, Menu, Space, Table, Tooltip } from "antd";
import classNames from "classnames";
import { EmptyData } from "components/common/empty-data/empty-data";
import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";

import _ from "lodash";
import React, {
  CSSProperties,
  memo,
  Suspense,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { AddEllipsis } from "utils/add-ellipsis";
import { exportCSVFile } from "utils/export-as-csv";
import { iLegacyWidgetTypes } from "../../schemas/legacy-report-widgets-schema";
import { columnChartConfig } from "./components/configs/column-chart";
import { generateHeatMapConfig } from "./components/configs/heat-map";
import { pieChartConfig } from "./components/configs/pie-chart";
import "./report-widget-legacy.scss";
import { getDayNameFromDayNumber } from "./utils/hour-day-heatmap-generator";

const HighchartsReact = React.lazy(
  () => import("@libs/high-charts-lazy/high-charts-lazy"),
);

// Example Charts
// http://jsfiddle.net/sivaecekrr/qpg7a9en/
// https://jsfiddle.net/chrisvecchio/f2r7jqhz/
// https://codesandbox.io/s/highcharts-heatmap-0tsng?file=/src/index.js:391-421

const tableLocale = {
  emptyText: (
    <div className="text-gray-600 text-center">
      There is not enough data to display this report
    </div>
  ),
};

export type ChartType = "COLUMN" | "PIE" | "TABLE" | "DIGIT" | "HEAT_MAP";

const GetContainerProps = _.memoize(
  (chartWidth: number, chartHeight: number) => ({
    style: {
      width: chartWidth - 50,
      height: chartHeight - 100,
    },
  }),
  (...args) => JSON.stringify(args),
);

const emptyArray = [];

export interface iReportWidgetConfig {
  filter?: any;
  advancedFilter?: any;
  isAdvancedMode?: boolean;
  title: string;
  description?: string;
  chartType: ChartType;
  chartConfig?: any;
  size?: "small" | "medium" | "large";
  chartWidth?: number;
  chartHeight?: number;
  cardStyle?: CSSProperties;
}

export const ReportWidgetLegacy = memo(
  ({
    widgetType,
    widgetConfig: {
      filter = {},
      advancedFilter = {},
      isAdvancedMode = false,
      title,
      description,
      chartType,
      chartConfig,
      size = "medium",
      chartHeight,
      chartWidth,
      cardStyle,
    },
    data: _data,
    onAction,
    extra,
  }: {
    widgetType?: iLegacyWidgetTypes;
    widgetConfig: {
      filter?: any;
      advancedFilter?: any;
      isAdvancedMode?: boolean;
      title: string;
      description?: string;
      chartType: ChartType;
      chartConfig?: any;
      size?: "small" | "medium" | "large";
      chartWidth?: number;
      chartHeight?: number;
      cardStyle?: CSSProperties;
    };
    data: any;
    onAction?: (
      data: { action: "VIEW" | "INSPECT"; filter: any },
      widgetType?: iLegacyWidgetTypes,
    ) => any;
    extra?: JSX.Element;
  }) => {
    const data = _.cloneDeep(_data);
    const menuContainerRef = useRef<HTMLDivElement>(null);

    const [menuVisibility, setMenuVisibility] = useState(false);
    const [clickContext, setClickContext] = useState({} as any);

    // Todo: Validate data against chartTypes and throw error

    const menu = useMemo(
      () => (
        <Menu>
          <Menu.Item
            onClick={() => {
              onAction!({ action: "VIEW", filter: clickContext }, widgetType);
              setMenuVisibility(false);
            }}
          >
            View Conversations
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              onAction!(
                { action: "INSPECT", filter: clickContext },
                widgetType,
              );
              setMenuVisibility(false);
            }}
          >
            Inspect Selected Segment
          </Menu.Item>
        </Menu>
      ),
      [clickContext, onAction, widgetType],
    );

    const chartOptions = useMemo(() => {
      return ((chartType: ChartType, data: any) => {
        let config: Highcharts.Options;
        if (chartType === "COLUMN") {
          config = _.cloneDeep(columnChartConfig);
        } else if (chartType === "PIE") {
          config = _.cloneDeep(pieChartConfig);
          // config.
        } else if (chartType === "HEAT_MAP") {
          console.log("HEAT_MAP HEAT_MAP data", data);
          config = generateHeatMapConfig({
            title: "Conversations by hour of the day",
            xAxisLabels: new Array(24)
              .fill(0)
              .map((v, index) => index.toString()),
            yAxisLabels: new Array(7)
              .fill(0)
              .map((v, i) => getDayNameFromDayNumber(i + 1)),
            // data: heatMapDummyData.map((record) => ({
            //   x: record.hour,
            //   y: record.dayOfTheWeek,
            //   count: record.count,
            // })),
            data: Array.isArray(data)
              ? data.map((record) => ({
                  x: Number(record.date?.split("-")[1]),
                  y: Number(record.date?.split("-")[0]) - 1,
                  count: record.count,
                }))
              : [],
            toolTipTemplate: (x, y, count) => `${count} conversations`,
          }) as any;
        } else {
          return undefined;
        }
        if (chartType !== "HEAT_MAP" && Array.isArray(data)) {
          if (
            chartType === "COLUMN" &&
            (chartConfig?.series || []).length > 0
          ) {
            const groupedData = _.groupBy(data, chartConfig?.series[0]);
            const categories = Object.keys(groupedData)[0]
              ? groupedData[Object.keys(groupedData)[0]].map((d) => d.name)
              : [];

            config.series = Object.keys(groupedData).map((group) => ({
              name: group,
              data: groupedData[group].map((item) => item.y),
              animation: false,
              type: "column",
              cursor: "crosshair",
            })) as any;

            config.xAxis = {
              categories: categories,
              crosshair: true,
            };
          } else {
            config!.series?.forEach((series) => {
              (series as any).data = data;
              series.name = title;
              config.xAxis = {
                categories: data.map((d) => d.name),
                crosshair: true,
              };

              if (chartType !== "COLUMN") {
                series.point!.events!.click = function (e) {
                  const container = menuContainerRef.current;
                  console.log("Series Clicked", e);
                  if (container) {
                    setMenuVisibility(true);
                    container.setAttribute(
                      "style",
                      "top: " + e.offsetY + "px; left:" + e.offsetX + "px;",
                    );
                    setClickContext({
                      ...filter,
                      innerQuery: e.point.options?.id || e.point.name,
                    });
                  }
                };
              }
            });
          }
        }

        if (chartConfig?.highChartsOptions) {
          Object.assign(config, chartConfig.highChartsOptions);
        }

        return config;
      })(chartType, data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartType, data]);

    //
    if (!chartWidth) {
      chartWidth = size === "large" ? 1125 : size === "medium" ? 550 : 355;
    }

    if (!chartHeight) {
      chartHeight = size === "large" ? 550 : size === "medium" ? 480 : 350;
    }

    const transformedData = useMemo(() => {
      if (!chartConfig || !chartConfig.transformData) {
        return data;
      }
      return transformToDataTable(
        data,
        chartConfig.primaryField,
        chartConfig.secondaryField,
        chartConfig.valueField,
      );
    }, [chartConfig, data]);

    const tableColumnDef = useMemo(
      () =>
        GenerateColumnDefinitions(
          transformedData,
          chartConfig?.customColumnWidths || {},
          chartConfig?.fixedLeft,
          chartConfig?.fixedRight,
        ),
      [
        chartConfig?.customColumnWidths,
        chartConfig?.fixedLeft,
        chartConfig?.fixedRight,
        transformedData,
      ],
    );

    const [tableSearchTerm, setTableSearchTerm] = useState("");

    const tableData = useMemo(() => {
      if (!tableSearchTerm) {
        return transformedData;
      }
      if (!Array.isArray(transformedData)) {
        return [];
      }
      return transformedData.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(tableSearchTerm.toLowerCase()),
        ),
      );
    }, [transformedData, tableSearchTerm]);

    const isTable = chartType === "TABLE";

    const onTableRowClicked = useCallback(
      (record, rowIndex) => {
        return {
          onClick: (event) => {
            console.log("event", event, record);
            const rect = event.target
              .closest(".report-widget")
              .getBoundingClientRect();
            console.log("rect", rect);
            const x = event.clientX - rect.left; //x position within the element.
            const y = event.clientY - rect.top; //y position within the element.
            console.log("Left? : " + x + " ; Top? : " + y + ".");

            const container = menuContainerRef.current;
            setMenuVisibility(true);
            if (container) {
              container.setAttribute(
                "style",
                "top: " + y + "px; left:" + x + "px;",
              );
            }
            console.log("Row CLicked", record);
            // innerQuery: e.point.name
            setClickContext({
              ...filter,
              innerQuery: record.id || record.name,
            });
          },
        };
      },
      [filter],
    );

    const onMenuClicked = useCallback((e) => e.stopPropagation(), []);
    const onMenuBgClicked = useCallback(() => setMenuVisibility(false), []);

    const onDigitReportClick = useCallback(
      (e) => {
        console.log("e", e);
        const container = menuContainerRef.current;
        setMenuVisibility(true);
        if (container) {
          // container.setAttribute(
          //   "style",
          //   "top: " + e.screenY + "px; left:" + e.screenX + "px;"
          // );
          container.setAttribute("style", "top: 50%; left: 50%");
        }
        setClickContext(filter);
      },
      [filter],
    );

    const onExport = useCallback(() => {
      // if (chartType === "TABLE") {
      //   exportCSVFile(data, "cc-report");
      // } else {
      //   exportCSVFile(tableData, "cc-report");
      // }
      exportCSVFile(tableData, "cc-report");
    }, [tableData]);

    if (!_data || _data.length === 0) {
      return (
        <Card
          style={{
            width: chartWidth,
            height: isTable ? chartHeight + 20 : chartHeight + 20,
            ...cardStyle,
          }}
          bodyStyle={{
            padding: isTable ? 0 : undefined,
          }}
          title={
            <div className="font-bold widget-title">
              <i className="ri-focus-3-line text-lg"></i>
              {title}
            </div>
          }
        >
          <div
            className="widget relative"
            style={{
              width: isTable ? chartWidth - 2 : chartWidth - 50,
              height: isTable ? chartHeight - 120 : chartHeight - 100,
            }}
          >
            <div className="h-full flex justify-center items-center">
              <EmptyData
                text=" There is not enough data to populate report"
                icon={"ri-line-chart-line"}
              />
            </div>
          </div>
        </Card>
      );
    }

    return (
      <Card
        style={{
          width: chartWidth,
          height: isTable ? chartHeight + 20 : chartHeight + 20,
          ...cardStyle,
        }}
        bodyStyle={{
          padding: isTable ? 0 : undefined,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
        title={
          <div className="font-bold widget-title">
            <i className="ri-focus-3-line text-lg"></i>
            {title}
          </div>
        }
        extra={
          <Space>
            {extra}
            {chartType !== "DIGIT" && (
              <Button type="link" onClick={onExport}>
                <i className="ri-download-2-line"></i>
              </Button>
            )}
          </Space>
        }
        className="report-widget dark:bg-gray-900 mode_transition"
      >
        <div
          className="widget relative"
          style={{
            width: isTable ? chartWidth - 2 : chartWidth - 50,
            height: isTable ? chartHeight - 120 : chartHeight - 100,
          }}
        >
          {(chartType === "COLUMN" ||
            chartType === "PIE" ||
            chartType === "HEAT_MAP") && (
            <Suspense fallback={<SuspenseLoadingIndicator />}>
              <HighchartsReact
                options={chartOptions}
                containerProps={GetContainerProps(chartWidth, chartHeight)}
              />
            </Suspense>
          )}
          {chartType === "DIGIT" && (
            <div
              className={classNames(
                "flex flex-row justify-center items-center  font-bold cursor-pointer",
                {
                  "text-10xl": size === "large",
                  "text-8xl": size !== "large",
                },
              )}
              style={{
                width: chartWidth - 50,
                height: chartHeight - 100,
              }}
              onClick={onDigitReportClick}
            >
              {typeof data === "number" ? data : "..."}
            </div>
          )}
          {chartType === "TABLE" && (
            <>
              <Table
                dataSource={Array.isArray(tableData) ? tableData : emptyArray}
                columns={tableColumnDef as any}
                size="small"
                title={() => (
                  <Input.Search
                    placeholder="Search..."
                    value={tableSearchTerm}
                    onChange={(e) => setTableSearchTerm(e.target.value)}
                    size="middle"
                    bordered={false}
                    style={{ paddingLeft: 17, paddingRight: 10 }}
                  />
                )}
                pagination={{ pageSize: Math.round((chartHeight - 210) / 40) }}
                scroll={{ y: chartHeight + 20, x: chartWidth - 20 }}
                bordered={false}
                rowKey={"id"}
                locale={tableLocale}
                onRow={onTableRowClicked}
              />
            </>
          )}

          <div
            className="menu-background"
            style={{ display: menuVisibility ? "block" : "none" }}
            onClick={onMenuBgClicked}
          >
            <div
              className="menu-container sticky"
              ref={menuContainerRef}
              onClick={onMenuClicked}
            >
              {menu}
            </div>
          </div>
        </div>
      </Card>
    );
  },
);

export const GenerateColumnDefinitions = (
  data: any[],
  customColumnWidths: Record<string, number>,
  fixedLeft?: string[],
  fixedRight?: string[],
) => {
  const dataSample = data[0];
  return Object.keys(dataSample || {})
    .filter((key) => key !== "key" && key !== "id")
    .map((key) => ({
      title: (
        <>
          <Tooltip title={`${key.toUpperCase()} -> Click to sort`}>
            {AddEllipsis(key.toUpperCase(), 5)}
          </Tooltip>
        </>
      ),
      dataIndex: key,
      key: key,
      fixed: (() => {
        if (fixedLeft && fixedLeft.includes(key)) {
          return "left";
        }
        if (fixedRight && fixedRight.includes(key)) {
          return "right";
        }
        if (!fixedRight) {
          return typeof dataSample[key] === "number" ? "right" : undefined;
        }
      })(),
      // ellipsis: {
      //   showTitle: false,
      // },
      showSorterTooltip: false,
      width:
        customColumnWidths[key] ||
        (typeof dataSample[key] === "number" ? 100 : undefined),
      sorter:
        typeof dataSample[key] === "number"
          ? (a, b) => a[key] - b[key]
          : (a, b) => {
              return ("" + (a[key] || "")).localeCompare(b[key]);
            },
    }));
};

export const transformToDataTable = (
  data: any[],
  primaryField: string,
  secondaryField: string,
  valueField: string,
) => {
  if (!Array.isArray(data)) {
    return data;
  }

  const additionalColumns: any[] = [];
  for (let record of data) {
    additionalColumns.push(record[secondaryField]);
  }
  const groupedData = _.groupBy(data, primaryField);

  const finalResults: any[] = [];

  for (const group in groupedData) {
    finalResults.push({
      id: groupedData[group][0].id || group,
      [primaryField]: group,
      ...additionalColumns.reduce((collector, field) => {
        const dataRecord = _.find(groupedData[group], {
          [secondaryField]: field,
        });
        collector[field] = dataRecord?.count || 0;
        return collector;
      }, {}),
      // Commented on 13/10/21 to remove the total count as it could mislead
      // Total: _.sumBy(groupedData[group], valueField)
    });
  }
  return finalResults;
};
