import { iHolidayQuote } from "@sdk/holiday-quotes/holiday-quotes-model";
import { Form, Input, InputNumber, Modal, Space, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useMemo, useState } from "react";

export const QuoteHeaderEditorModal = ({
  visible,
  onChangeVisibility,
  onSave,
  data
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onSave: (content: iHolidayQuote) => any;
  data: iHolidayQuote;
}) => {
  const [form] = useForm();
  const [isProcessing, setProcessing] = useState(false);

  const initialValues = useMemo(() => {
    return data;
  }, [data]);

  return (
    <Modal
      title={
        <ModalTitle
          title="Edit Quote"
          icon={<i className="ri-bill-line"></i>}
        />
      }
      open={visible}
      okText={"Save"}
      onOk={async () => {
        try {
          await form.validateFields();
          const formValues = form.getFieldsValue();
          console.log("formValues", formValues);
          setProcessing(true);
          await onSave({
            ...data,
            data: {
              ...data.data,
              ...formValues.data
            }
          });

          onChangeVisibility(false);
          setProcessing(false);
        } catch (e) {
          setProcessing(false);
          message.error("Please check your input");
        }
        // Todo
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okButtonProps={{ loading: isProcessing }}
      width={600}
      data-click-context="Quote Editor  Modal"
    >
      {data && (
        <div className="bold-form-labels">
          <Form
            layout="vertical"
            form={form}
            initialValues={initialValues}
            className="bold-form-titles"
          >
            <Form.Item
              name={["data", "quoteTitle"]}
              label={"Holiday Name"}
              rules={[]}
            >
              <Input placeholder="Holiday Name" />
            </Form.Item>
            <Form.Item
              name={["data", "greetings"]}
              label={"Greeting Message"}
              rules={[]}
            >
              <Input.TextArea
                placeholder="Greeting Message"
                autoSize={{ minRows: 6 }}
              />
            </Form.Item>

            <Form.Item
              name={["data", "numberOfNights"]}
              label="Holiday Duration"
              rules={[]}
            >
              <InputNumber
                min={1}
                max={10}
                className="w-full"
                addonAfter="Nights"
              />
            </Form.Item>

            <div className="flex flex-col">
              <div className="font-bold my-3">Passengers</div>
              <Space style={{ display: "flex" }}>
                <Form.Item name={["data", "adults"]} rules={[]}>
                  <InputNumber
                    min={0}
                    max={10}
                    className="w-full"
                    addonAfter="Adults"
                    defaultValue={0}
                  />
                </Form.Item>
                <Form.Item name={["data", "children"]} rules={[]}>
                  <InputNumber
                    min={0}
                    max={10}
                    className="w-full"
                    addonAfter="Children"
                    defaultValue={0}
                  />
                </Form.Item>
                <Form.Item name={["data", "infants"]} rules={[]}>
                  <InputNumber
                    min={0}
                    max={10}
                    className="w-full"
                    addonAfter="Infants"
                    defaultValue={0}
                  />
                </Form.Item>
              </Space>
            </div>
            {/* <Form.Item
              name={["data", "user"]}
              rules={[]}
              label="Quote Created By"
            >
              <SingleUserSelector />
            </Form.Item> */}
          </Form>
        </div>
      )}

      <DarkModeBg />
    </Modal>
  );
};
