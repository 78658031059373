import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Form, Input, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import axios, { AxiosResponse } from "axios";

import { iFacebookGetListResponse } from "components/modules/connections/channels/whatsapp-cloud-native/add-whatsapp-cloud-native-connection-modal/wa-cloud-native-helpers";
import { FileInputWithUploader } from "components/modules/file-management/file-input-with-uploader";
import { ImageInputWithUploader } from "components/modules/file-management/image-input-with-uploader";
import { cloneDeep, find } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { replaceAll } from "utils/replace-all";
import { ReplaceTokens } from "utils/token-replacer";

interface iWabaTemplate {
  status: string;
  name: string;
  id: string;
  language: string;
  category: string;
  components: iWabaTemplateComponent[];
}

type iWabaTemplateComponent =
  | iWabaTemplateComponent_Header
  | iWabaTemplateComponent_Body
  | iWabaTemplateComponent_Footer
  | iWabaTemplateComponent_Buttons;

interface iWabaTemplateComponent_Header {
  type: "HEADER";
  format: "TEXT";
  text: string;
  example: {
    header_text: string[];
  };
}

interface iWabaTemplateComponent_Body {
  type: "BODY";
  text: string;
  example: {
    body_text: string[];
  };
}

interface iWabaTemplateComponent_Footer {
  type: "FOOTER";
  text: string;
}

interface iWabaTemplateComponent_Buttons {
  type: "BUTTONS";
  buttons: {
    type: "URL" | "QUICK_REPLY";
    text: string;
    url?: string; // "https://clickconnector.com/{{1}}";
    example?: string[];
  }[];
}

interface iWabaMessageTemplateComponentPayload {
  type: "header" | "body" | "footer" | "button";
  sub_type?: string;
  index?: string;
  parameters: {
    type: "text" | "image" | "video" | "document" | "payload";

    text?: string;
    image?: {
      link: string;
    };
    document?: {
      link: string;
    };
    video?: {
      link: string;
    };
    payload?: string;
  }[];
}

const TemplateStatusIcons = {
  APPROVED: <i className="ri-shield-check-line"></i>,
  PENDING: <i className="ri-error-warning-line"></i>,
  REJECTED: <i className="ri-spam-2-line"></i>,
};

function findVariableTokens(str: string) {
  const regex = /{{(.*?)}}/g;
  const tokens = [] as string[];
  let match;

  while ((match = regex.exec(str))) {
    tokens.push(match[1].trim());
  }

  return tokens;
}

export const getWAAccountTemplates = async (
  accessToken: string,
  WABAId: string,
): Promise<iWabaTemplate[]> => {
  const baseUrl = "https://graph.facebook.com/v17.0";
  const fields = "id,name,status,components,language,category";
  let templates: iWabaTemplate[] = [];
  const uri = `${baseUrl}/${WABAId}/message_templates?access_token=${accessToken}&fields=${fields}`;

  const loadTemplates = async (url: string) => {
    const results: AxiosResponse<iFacebookGetListResponse<iWabaTemplate>> =
      await axios.get(url);
    const data = results.data;
    if (!data) {
      throw new Error("Something went wrong");
    } else {
      templates = templates.concat(data.data);
      if (data && data.paging && data.paging.next) {
        await loadTemplates(data.paging.next);
      }
    }
  };

  await loadTemplates(uri);

  return templates;
};

export const WATemplateMessage = ({
  connectionId,
  selectedContacts,
  onMessageSent,
}: {
  connectionId: string;
  selectedContacts: string[];
  onMessageSent?: () => any;
}) => {
  const connection = useSelector(selectConnectionById(connectionId));
  const [templates, setTemplates] = useState([] as iWabaTemplate[]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(
    undefined as undefined | string,
  );
  const [selectedTemplate, setSelectedTemplate] = useState(
    undefined as undefined | iWabaTemplate,
  );

  const [selectedTemplateMetaData, setSelectedTemplateMetaData] = useState({
    header: undefined as undefined | iWabaTemplateComponent_Header,
    headerVariables: [] as string[],
    body: undefined as undefined | iWabaTemplateComponent_Body,
    bodyVariables: [] as string[],
    footer: undefined as undefined | iWabaTemplateComponent_Footer,
    footerVariables: [] as string[],
    buttons: undefined as undefined | iWabaTemplateComponent_Buttons,
    buttonVariables: [] as string[],
    payload: [] as iWabaMessageTemplateComponentPayload[],
  });

  useEffect(() => {
    if (connectionId) {
      const accessToken = connection.data.accessToken;
      const WABAId = connection.data.wabaId;
      const mobileNumber = connection.data.mobileNumber;
      getWAAccountTemplates(accessToken, WABAId)
        .then((templates) => {
          setTemplates(templates);
          console.log("templates", templates);
        })
        .catch((e) => {
          console.log("Error while fetching templates", e);
        });
    }
  }, [
    connection?.data?.accessToken,
    connection?.data?.mobileNumber,
    connection?.data?.wabaId,
    connectionId,
  ]);

  const [form] = useForm();

  useEffect(() => {
    if (selectedTemplateId) {
      const selectedTemplate = find(templates, { id: selectedTemplateId });
      setSelectedTemplate(selectedTemplate);
      const header = find(selectedTemplate?.components, {
        type: "HEADER",
      }) as iWabaTemplateComponent_Header | undefined;
      const body = find(selectedTemplate?.components, {
        type: "BODY",
      }) as iWabaTemplateComponent_Body | undefined;
      const footer = find(selectedTemplate?.components, {
        type: "FOOTER",
      }) as iWabaTemplateComponent_Footer | undefined;
      const buttons = find(selectedTemplate?.components, {
        type: "BUTTONS",
      }) as iWabaTemplateComponent_Buttons | undefined;

      const buttonVariables = [] as string[];
      for (const button of buttons?.buttons || []) {
        buttonVariables.push(...findVariableTokens(button.text));
      }

      const payload = [] as iWabaMessageTemplateComponentPayload[];

      for (const component of selectedTemplate?.components || []) {
        if (component.type === "HEADER") {
          if (component.format === "TEXT") {
            const totalVariables = findVariableTokens(header?.text || "");
            payload.push({
              type: "header",
              parameters: totalVariables.map((variable) => ({
                type: "text",
                text: "",
              })),
            });
          } else if (component.format === "IMAGE") {
            // eg: sample_purchase_feedback, sample_movie_ticket_confirmation
            payload.push({
              type: "header",
              parameters: [
                {
                  type: "image",
                  image: {
                    link: "",
                  },
                },
              ],
            });
          } else if (component.format === "VIDEO") {
            // eg: sample_happy_hour_announcement
            payload.push({
              type: "header",
              parameters: [
                {
                  type: "video",
                  video: {
                    link: "",
                  },
                },
              ],
            });
          } else if (component.format === "DOCUMENT") {
            // eg: sample_flight_confirmation
            payload.push({
              type: "header",
              parameters: [
                {
                  type: "document",
                  document: {
                    link: "",
                  },
                },
              ],
            });
          }
        } else if (component.type === "BODY") {
          const totalVariables = findVariableTokens(body?.text || "");
          payload.push({
            type: "body",
            parameters: totalVariables.map((variable) => ({
              type: "text",
              text: "",
            })),
          });
        } else if (component.type === "FOOTER") {
          payload.push({
            type: "footer",
            parameters: [],
          });
        } else if (component.type === "BUTTONS") {
          (component.buttons || []).forEach((button, index) => {
            if (button.type === "URL") {
              const totalVariables = findVariableTokens(button?.url || "");
              payload.push({
                type: "button",
                sub_type: button.type.toLowerCase(),
                index: index.toString(),
                parameters: totalVariables.map((item) => ({
                  type: "payload",
                  payload: "",
                })),
              });
            } else {
              payload.push({
                type: "button",
                index: index.toString(),
                sub_type: button.type.toLowerCase(),
                parameters: [],
              });
            }
          });
        }
      }

      setSelectedTemplateMetaData({
        header,
        body,
        footer,
        buttons,
        headerVariables: findVariableTokens(header?.text || ""),
        bodyVariables: findVariableTokens(body?.text || ""),
        footerVariables: findVariableTokens(footer?.text || ""),
        buttonVariables: buttonVariables,
        payload,
      });

      form.setFieldsValue({
        components: payload,
      });
      setTimeout(() => {
        form.resetFields();
      }, 100);
    }
  }, [form, selectedTemplateId, templates]);

  const { doAction: onSendMessage, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (data) =>
        SDK.startNewConversationWithMessage(data)
          .then((d) => {
            onMessageSent && onMessageSent();
          })
          .catch((e) => {
            console.log("Error while starting a new conversation", e);
            throw e;
          }),
      successMessage: "Message has been sent",
      throwError: true,
      failureMessage: "Something went wrong",
    }),
    [onMessageSent],
  );

  return (
    <>
      <div className="mt-4 font-bold">Message Template</div>
      <Select
        showSearch
        value={selectedTemplateId}
        placeholder="Select Template"
        filterOption={false}
        onChange={setSelectedTemplateId}
        style={{ width: "100%" }}
        size="large"
      >
        {(templates || []).map((template) => (
          <Select.Option
            key={template.id}
            value={template.id!}
            disabled={template.status !== "APPROVED"}
          >
            <div className="flex flex-row items-center">
              <div className="icon-container mr-2">
                {TemplateStatusIcons[template.status]}
              </div>
              <div className="label">
                {template.name} ({template.language})
              </div>
              <div className="text-gray-600 dark:text-gray-400 text-sm pl-2 mode_transition">
                {template.category}
              </div>
            </div>
          </Select.Option>
        ))}
      </Select>

      {selectedTemplate && selectedTemplateMetaData.body && (
        <>
          {(selectedTemplateMetaData.headerVariables.length > 0 ||
            selectedTemplateMetaData.bodyVariables.length > 0 ||
            selectedTemplateMetaData.buttonVariables.length > 0) && (
            <>
              <div className="mt-4 font-bold mb-4">Template Variables</div>

              <Form
                layout="vertical"
                form={form}
                initialValues={{
                  components: selectedTemplateMetaData.payload,
                }}
                preserve={true}
              >
                {selectedTemplateMetaData.payload.map((comp, index) => {
                  if (comp.type === "header") {
                    if (comp.parameters.length > 0) {
                      if (comp.parameters[0].type === "text") {
                        return (
                          <>
                            <Form.Item
                              label={"Header Variable"}
                              name={[
                                "components",
                                index,
                                "parameters",
                                0,
                                "text",
                              ]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <Input
                                placeholder={`Enter Header Variable - {{1}}`}
                                required={true}
                              />
                            </Form.Item>
                          </>
                        );
                      } else if (comp.parameters[0].type === "image") {
                        return (
                          <>
                            <Form.Item
                              label={"Upload Header Image"}
                              name={[
                                "components",
                                index,
                                "parameters",
                                0,
                                "image",
                                "link",
                              ]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <ImageInputWithUploader
                                type="CONVERSATION"
                                entityId={`NEW-${connectionId}`}
                                width={320}
                                height={180}
                              />
                            </Form.Item>
                          </>
                        );
                      } else if (comp.parameters[0].type === "video") {
                        return (
                          <>
                            <Form.Item
                              label={"Upload Video"}
                              name={[
                                "components",
                                index,
                                "parameters",
                                0,
                                "video",
                                "link",
                              ]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <FileInputWithUploader
                                type="CONVERSATION"
                                entityId={`NEW-${connectionId}`}
                                accept="video/mp4"
                              />
                            </Form.Item>
                          </>
                        );
                      } else if (comp.parameters[0].type === "document") {
                        return (
                          <>
                            <Form.Item
                              label={"Upload PDF"}
                              name={[
                                "components",
                                index,
                                "parameters",
                                0,
                                "document",
                                "link",
                              ]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <FileInputWithUploader
                                type="CONVERSATION"
                                entityId={`NEW-${connectionId}`}
                                accept="application/pdf"
                              />
                            </Form.Item>
                          </>
                        );
                      }
                      return <>Un Supported Parameter</>;
                    }
                  } else if (comp.type === "body") {
                    if (comp.parameters.length > 0) {
                      return (
                        <>
                          <div className="">Body Variables</div>
                          {comp.parameters.map((param, paramIndex) => {
                            return (
                              <>
                                <Form.Item
                                  label={`Parameter ${paramIndex + 1}`}
                                  name={[
                                    "components",
                                    index,
                                    "parameters",
                                    paramIndex,
                                    "text",
                                  ]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder={`Enter Body Variable - {{${
                                      paramIndex + 1
                                    }}}`}
                                    required={true}
                                  />
                                </Form.Item>
                              </>
                            );
                          })}
                        </>
                      );
                    }
                  } else if (comp.type === "footer") {
                    return <></>;
                  } else if (comp.type === "button") {
                    if (comp.parameters.length > 0) {
                      return (
                        <>
                          <Form.Item
                            label={`Button Variable - ${comp.sub_type}`}
                            name={[
                              "components",
                              index,
                              "parameters",
                              0,
                              "payload",
                            ]}
                            rules={[
                              {
                                required: true,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter Button Variable"
                              required={true}
                            />
                          </Form.Item>
                        </>
                      );
                    }
                  }
                  return <></>;
                })}
                <div className="w-full p-4"></div>
              </Form>
            </>
          )}

          <div className="mt-4 font-bold">Preview</div>
          <div
            className="mt-4 rounded-lg border border-gray-200 dark:border-gray-700 p-4 m-auto"
            style={{ maxWidth: 340 }}
          >
            {selectedTemplateMetaData.header && (
              <>
                <div className="font-bold">
                  {selectedTemplateMetaData.header.text}
                </div>
                <Divider />
              </>
            )}
            {selectedTemplateMetaData.body.text}
            {selectedTemplateMetaData.footer && (
              <>
                <div className="text-sm text-gray-600">
                  {selectedTemplateMetaData.footer.text}
                </div>
              </>
            )}
            {selectedTemplateMetaData.buttons && (
              <>
                <Divider />
                {selectedTemplateMetaData.buttons.buttons.map(
                  (button, index) => {
                    return (
                      <Button
                        type="link"
                        icon={
                          button.type === "URL" ? (
                            <i className="ri-external-link-line"></i>
                          ) : (
                            <i className="ri-reply-fill"></i>
                          )
                        }
                        block
                        key={button.text + index}
                      >
                        {button.text}
                      </Button>
                    );
                  },
                )}
              </>
            )}
          </div>
        </>
      )}

      <div className="flex flex-row justify-end items-center mt-4">
        <Button
          type="primary"
          icon={<i className="ri-send-plane-line"></i>}
          disabled={!selectedTemplate || selectedContacts?.length === 0}
          loading={isProcessing}
          onClick={() => {
            const formValue = form.getFieldsValue() as {
              components: iWabaMessageTemplateComponentPayload[];
            };
            console.log("formValue", formValue);
            const componentsPayload = cloneDeep(
              selectedTemplateMetaData.payload,
            );
            console.log("componentsPayload", selectedTemplateMetaData.payload);

            componentsPayload.forEach((component, compIndex) => {
              if (formValue?.components?.[compIndex]) {
                if (component.type === "header") {
                  if (component.parameters[0]) {
                    if (component.parameters[0].type === "text") {
                      component.parameters[0].text =
                        formValue.components[compIndex].parameters[0].text;
                    } else if (component.parameters[0].type === "image") {
                      component.parameters[0].image =
                        formValue.components[compIndex].parameters[0].image;
                    } else if (component.parameters[0].type === "video") {
                      component.parameters[0].video =
                        formValue.components[compIndex].parameters[0].video;
                    } else if (component.parameters[0].type === "document") {
                      component.parameters[0].document =
                        formValue.components[compIndex].parameters[0].document;
                    }
                  }
                } else if (component.type === "body") {
                  component.parameters.forEach((parameter, paramIndex) => {
                    parameter.text =
                      formValue.components[compIndex].parameters[
                        paramIndex
                      ].text;
                  });
                } else if (component.type === "footer") {
                  // Nothing
                } else if (component.type === "button") {
                  if (component.parameters[0]) {
                    component.parameters[0].payload =
                      formValue.components[compIndex].parameters[0].payload;
                  }
                }
              }
            });

            console.log("componentsPayload", componentsPayload);

            const bodyValues = (
              componentsPayload.find((item) => item.type === "body")
                ?.parameters || []
            ).map((item) => item.text || "");
            console.log("bodyValues", bodyValues);
            const tokenValues = selectedTemplateMetaData.bodyVariables.reduce(
              (acc, curr, currIndex) => {
                acc[curr] = bodyValues[currIndex];
                return acc;
              },
              {},
            );

            console.log("tokenValues", tokenValues);

            const body = ReplaceTokens(
              replaceAll(
                replaceAll(
                  selectedTemplateMetaData.body?.text || "",
                  "{{",
                  "{",
                ),
                "}}",
                "}",
              ),
              tokenValues,
            );

            console.log("body", body);

            onSendMessage({
              connectionId,
              contactIds: selectedContacts,
              message: body,
              attachments: [],
              messageData: {
                type: "template",
                template: {
                  name: selectedTemplate?.name,
                  language: { code: selectedTemplate?.language },
                  components: componentsPayload.filter(
                    (item) =>
                      item.type !== "footer" && item.parameters.length > 0,
                  ),
                },
              },
            });
          }}
        >
          Send Message
        </Button>
      </div>
    </>
  );
};
