import { useState } from "react";
import { Collapse } from "react-collapse";

export const SimpleCollapseWithHeader = ({
  headerRow,
  children
}: {
  headerRow: JSX.Element;
  children;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <div className="">
      <div
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="bg-gray-100 dark:bg-gray-900 border-t border-gray-200 dark:border-gray-800 p-2 flex flex-row items-center justify-between cursor-pointer"
      >
        {headerRow}
        <div>
          {isCollapsed ? (
            <i className="ri-arrow-up-s-line text-xl"></i>
          ) : (
            <i className="ri-arrow-down-s-line text-xl"></i>
          )}
        </div>
      </div>

      <Collapse isOpened={isCollapsed}>{children}</Collapse>
    </div>
  );
};
