import { iChatConnectorType } from "@sdk/chat-connectors/chat-connectors.models";
import { useMemo } from "react";

export const useChatConnectorEntity = (type?: iChatConnectorType) => {
  const data = useMemo(() => {
    if (type === "CONVERSATION_ACTION") {
      return {
        pageTitle: "Manage Chat Actions",
        description:
          "Chat Actions helps you to send signals to external systems",
        entityName: "Chat Action",
        icon: <i className="ri-send-plane-2-line"></i>
      };
    }

    if (type === "CONTACT_SIDE_BAR") {
      return {
        pageTitle: "Manage Chat SidePanel",
        description:
          "Chat Side Panels helps you to retrieve and show information from external systems",
        entityName: "Chat Side Panel",
        icon: <i className="ri-side-bar-line rotate-180"></i>
      };
    }
    return {
      pageTitle: "Manage Chat Connectors",
      description:
        "With Chat Connectors, you can integrate external systems seamlessly with Click Connector",
      entityName: "Chat Connector",
      icon: <i className="ri-plug-line"></i>
    };
  }, [type]);
  return data;
};
