import { Button, Space, Tooltip } from "antd";
import copyToClipboard from "copy-to-clipboard";
import { AddEllipsis } from "utils/add-ellipsis";
import { convertHtmlToPlainText } from "utils/html-to-plain-text";
import { iEmailSignature } from "./signature-manager-state-manager";

export const SignatureItemEditorView = ({
  preset,
  onDelete,
  onEdit
}: {
  preset: iEmailSignature;
  onDelete: () => any;
  onEdit: () => any;
}) => {
  return (
    <div
      className="flex flex-row items-center w-full hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer p-4"
      onClick={onEdit}
    >
      <div className="template-type">
        <i className="ri-ball-pen-line"></i>
      </div>
      <div className="template-label font-bold ml-3">
        {AddEllipsis(preset.label, 30)}
      </div>
      <div className="template-label flex-1 text-gray-600 ml-4">
        {AddEllipsis(convertHtmlToPlainText(preset.content), 20)}
      </div>
      <Space>
        <Tooltip title="Delete Preset">
          <Button
            icon={<i className="ri-delete-bin-line"></i>}
            type="text"
            shape="circle"
            onClick={e => {
              e.stopPropagation();
              onDelete();
            }}
          />
        </Tooltip>
        <Tooltip title="Copy Preset">
          <Button
            icon={<i className="ri-file-copy-2-line"></i>}
            type="text"
            shape="circle"
            onClick={e => {
              e.stopPropagation();
              copyToClipboard(preset.content);
            }}
          />
        </Tooltip>
      </Space>
    </div>
  );
};
