import { Button, Input, Tag } from "antd";
import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import "components/pages/knowledge-base/article-preview/article-preview.modal.scss";
import { edjsParser } from "components/pages/knowledge-base/article-preview/edj-parser";
import dayjs from "dayjs";
import React, { Suspense, useEffect } from "react";
import { loadAllArticleCollections } from "store/modules/article-collection/article-collection.helpers";
import { selectArticleCollectionById } from "store/modules/article-collection/article-collection.selectors";
import { loadAllArticles } from "store/modules/articles/articles.helpers";
import { selectArticleByCollectionId } from "store/modules/articles/articles.selectors";
import { loadCCArticleById } from "store/modules/cc-articles/cc-articles.helpers";
import { selectCCArticleById } from "store/modules/cc-articles/cc-articles.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useUrlState } from "utils/hooks/use-url-state";

const VideoPlayer = React.lazy(() => import("@libs/video-player/video-plaer"));

export const HelpCenterArticle = ({ articleId }: { articleId: string }) => {
  // Todo: Replace
  const {
    state: article,
    retry: reload,
    isLoading,
  } = useQueryWithStore(
    selectCCArticleById(articleId),
    loadCCArticleById(articleId)(),
    [articleId],
  );

  const [selectedArticle, onSelectArticle] = useUrlState("help-center-article");

  const articlePrimaryCollectionId = article?.collections?.[0];

  // Todo: Replace
  const { state: collection, isLoading: isCollectionLoading } =
    useQueryWithStore(
      selectArticleCollectionById(articlePrimaryCollectionId),
      loadAllArticleCollections,
      [articlePrimaryCollectionId],
      !articlePrimaryCollectionId,
    );

  const { state: articles, isLoading: isArticlesLoading } = useQueryWithStore(
    selectArticleByCollectionId(articlePrimaryCollectionId),
    loadAllArticles,
  );

  useEffect(() => {
    const title = document.getElementById("article-title-cc-kb");
    if (title) {
      title.scrollIntoView();
    }
  }, [articleId]);

  return (
    <div className="article-preview-modal">
      {isLoading && (
        <div className="w-full flex flex-col justify-center items-center p-20">
          <i className="ri-loader-5-line animate-spin text-2xl"></i>
        </div>
      )}
      {!isLoading && article && (
        <div className="flex flex-col">
          <div className="header">
            <div className=" bg-gray-100 dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 px-8 flex flex-row py-4">
              <Button
                type="text"
                className="h-full font-bold"
                size="large"
                onClick={() => {
                  onSelectArticle("");
                }}
              >
                <i className="ri-arrow-left-line"></i>
              </Button>
              <Input.Search
                placeholder="Search Help Center..."
                size="large"
                className="rounded-md"
                onSearch={(value) => {
                  onSelectArticle("", {
                    searchTerm: value,
                    "help-center-collection": "",
                  });
                }}
              />
            </div>
          </div>
          <div className="p-8 px-12 flex flex-col">
            <div className="flex flex-row justify-center items-center">
              <div className="flex flex-row flex-1 items-center">
                <div className="flex-1 flex flex-col justify-center">
                  <div className="text-2xl font-bold" id="article-title-cc-kb">
                    {article.title}
                  </div>
                  <div className=" text-gray-600 dark:text-gray-400 mode_transition">
                    {article.description}
                  </div>
                  <div className="">
                    {article.tags?.map((tag) => (
                      <Tag key={tag}>
                        {(tag.toUpperCase && tag.toUpperCase()) || tag}
                      </Tag>
                    ))}
                  </div>
                  <div className=" text-gray-600 dark:text-gray-400 mode_transition">
                    Created At:{" "}
                    {dayjs(article.metaData.createdAt).format("LL h:mm:ss a ")}
                  </div>
                </div>
              </div>
            </div>
            {article.type === "GUIDE" && (
              <Suspense fallback={<SuspenseLoadingIndicator />}>
                <VideoPlayer url={article.videoUrl} />
              </Suspense>
            )}
            <div
              className="mt-8 editor-js-preview"
              dangerouslySetInnerHTML={{
                __html:
                  typeof article.body === "string"
                    ? article.body
                    : edjsParser.parse(article.body).join(""),
              }}
              // onCopy={(event) => {
              //   const range = window?.getSelection()?.getRangeAt(0)!,
              //     rangeContents = range.cloneContents(),
              //     helper = document.createElement("div");

              //   const appendedText = (() => {
              //     const baseUrl =
              //       "https://web.clickconnector.app/conversations";
              //     const url = new URL(baseUrl);
              //     url.searchParams.append("activate-help-center", "YES");
              //     if (selectedArticle) {
              //       url.searchParams.append(
              //         "help-center-article",
              //         selectedArticle
              //       );
              //     }
              //     if (articlePrimaryCollectionId) {
              //       url.searchParams.append(
              //         "help-center-collection",
              //         articlePrimaryCollectionId
              //       );
              //     }
              //     return `-- Read More at ${url.toString()}`;
              //   })();

              //   helper.appendChild(rangeContents);

              //   event.clipboardData.setData(
              //     "text/plain",
              //     `${helper.innerText}\n${appendedText}`
              //   );
              //   event.clipboardData.setData(
              //     "text/html",
              //     `${helper.innerHTML}<br>${appendedText}`
              //   );
              //   event.preventDefault();
              // }}
            ></div>
          </div>
          {collection && articles && (
            <div className="flex flex-col mt-8 px-8 py-4 bg-gray-200 border-t border-b border-gray-400 dark:bg-gray-800 dark:border-gray-700">
              <div className="text-2xl">Want to know more?</div>
              <div className="text-gray-600">{collection?.label}</div>

              <div className="questions-link-container">
                <ul style={{ listStyleType: "disc" }}>
                  {articles
                    .filter((article) => article.id !== articleId)
                    .map((article) => (
                      <li
                        key={article.id}
                        className="cursor-pointer ml-8 my-2 hover:underline"
                        onClick={() => onSelectArticle(article.id!)}
                      >
                        {article.title} <Tag>{article.type}</Tag>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}

          <div className="flex flex-col justify-center items-center my-16">
            <div className="text-gray-600">
              Not able to find what you are looking for?
            </div>
            <Button
              type="primary"
              className="font-bold mt-4"
              icon={<i className="ri-chat-smile-3-line"></i>}
              onClick={() => {
                (window as any).ccWidget.setWidgetVisibility(true);
              }}
            >
              Chat with us
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
