import classnames from "classnames";
import _ from "lodash";

export const PillSelector = ({
  value,
  onChange,
  options,
  multiple,
  className
}: {
  value?: string | string[];
  onChange?: (value?: string | string[]) => any;
  options: {
    value: string;
    icon?: JSX.Element;
    label: string;
    disabled?: boolean;
  }[];
  multiple?: boolean;
  className?: string;
}) => {
  return (
    <div
      className={classnames(
        "flex flex-row flex-wrap gap-4 justify-center",
        className
      )}
    >
      {options.map(item => {
        const isOptionSelected = multiple
          ? (value || ([] as string[])).includes(item.value)
          : item.value === value;
        return (
          <div
            key={item.value}
            className={classnames(
              "cursor-pointer rounded-md border px-4 py-2",
              {
                "border-blue-600 shadow-lg": isOptionSelected,
                "border-gray-200 dark:border-gray-700": !isOptionSelected,
                "cursor-not-allowed": item.disabled
              }
            )}
            onClick={() => {
              if (item.disabled) {
                return;
              }
              if (onChange) {
                if (multiple) {
                  if (isOptionSelected) {
                    onChange(_.without((value as any) || [], item.value));
                  } else {
                    onChange([...((value as any) || []), item.value]);
                  }
                } else {
                  if (isOptionSelected) {
                    onChange(undefined);
                  } else {
                    onChange(item.value);
                  }
                }
              }
            }}
            style={{ minWidth: 200 }}
          >
            <div className="flex flex-col justify-start items-center">
              {item.icon && (
                <div className="icon-container text-2xl my-2">{item.icon}</div>
              )}
              <div className="font-bold"> {item.label}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
