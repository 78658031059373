import { useSDK } from "@sdk";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { UserAvatar } from "components/common/avatar/avatar";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useStore } from "react-redux";
import { selectUser } from "store/modules/users/users.selectors";
import { convertMillisecondsToHoursAndMinutes } from "utils/convert-milliseconds-to-hours-and-minutes";

export const MyActivityReport = ({
  dateRange,
  userId,
  hideUserColumns
}: {
  dateRange: [any, any];
  userId?: string;
  hideUserColumns?: boolean;
}) => {
  const { data: dailyLogs, reload: reloadDailyLog } = useSDK(
    SDK =>
      SDK.queryUserPresenceDailySummaryLogs({
        query: {
          date: {
            $gt: dayjs(dateRange[0]).valueOf(),
            $lt: dayjs(dateRange[1]).valueOf()
          },
          userId: userId ? userId : undefined
        },
        options: { limit: 500 }
      }),
    [dateRange, userId],
    false,
    {}
  );

  const store = useStore();

  const logsWithData = useMemo(() => {
    return (dailyLogs.docs || []).map(item => ({
      ...item,
      user: getUserName(selectUser(item.userId)(store.getState()))
    }));
  }, [dailyLogs.docs, store]);

  const columns = useMemo(() => {
    return [
      ...(!hideUserColumns
        ? [
            {
              title: "",
              dataIndex: "id",
              width: 50,
              fixed: "left",
              render: (id, record) => (
                <span>
                  <UserAvatar userId={record.userId} />
                </span>
              )
            },
            {
              title: "User",
              dataIndex: "user",
              fixed: "left",
              width: 170
            }
          ]
        : []),

      {
        title: "Date",
        dataIndex: "date",
        width: 120,
        render: date => {
          return <span>{dayjs(date).format("DD-MM-YYYY")}</span>;
        }
      },
      {
        title: "Online",
        dataIndex: "totalOnline",
        width: 120,
        render: milliseconds => {
          return (
            <span>
              {convertMillisecondsToHoursAndMinutes(milliseconds)} Hours
            </span>
          );
        }
      },
      {
        title: "Available",
        dataIndex: "totalAvailable",
        width: 120,
        render: milliseconds => {
          return (
            <span>
              {convertMillisecondsToHoursAndMinutes(milliseconds)} Hours
            </span>
          );
        }
      },
      {
        title: "Screen Time",
        dataIndex: "totalScreenActiveTime",
        width: 120,
        render: milliseconds => {
          return (
            <span>
              {convertMillisecondsToHoursAndMinutes(milliseconds)} Hours
            </span>
          );
        }
      }
    ] as ColumnsType<any>;
  }, [hideUserColumns]);

  return (
    <div className="flex flew-row items-center">
      <div style={{ width: "100%" }}>
        <Table
          columns={columns}
          dataSource={logsWithData}
          rowKey="id"
          scroll={{
            x: !hideUserColumns ? 900 : 400,
            scrollToFirstRowOnChange: true
          }}
          pagination={{ hideOnSinglePage: true }}
          sticky
        />
      </div>
    </div>
  );
};
