import { SDK } from "@sdk";
import { BrowserWakeUpHandler } from "@sdk/@libs/browser-wake-up-handler";
import { SDKConfig } from "@sdk/sdk";
import { AccountsAndUserManagementServices } from "@sdk/user-management/user-management.service";
import {
  ConfigProvider,
  Empty,
  Spin,
  Tour,
  TourStepProps,
  message,
  notification,
  theme,
} from "antd";
import { AccountStatusBannerAndModal } from "banners/account-status-banner";
import { AwayDetectorBanner } from "banners/away-detector-banner";
import { ConnectionIssuesBanner } from "banners/connection-issue-banner";
import { JoiningChatsRestrictedBanner } from "banners/joining-chats-restricted-banner";
import { SocketDisconnectedBanner } from "banners/socket-disconnected-banner";
import { UnassignedChatsBanner } from "banners/un-assigned-chats-banner";
import { Notifications } from "components/common/notifications/notications";
import { KBar } from "components/k-bar/k-bar";
import { ConnectedRouter, push } from "connected-react-router";
import { GlobalErrorBoundary } from "error-boundary";
import { Action, KBarProvider, Priority, useRegisterActions } from "kbar";
import { useEffect, useMemo, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { MainRouter } from "router";

import { BrowserHistory } from "store/browser-history";
import {
  addRecentLoginData,
  setSwitchingProfile,
} from "store/modules/app-state/app-state.actions";
import { selectIsSwitchingProfile } from "store/modules/app-state/app-state.selectors";
import {
  selectIsDarkMode,
  selectIsElectronMode,
  selectWindowState,
} from "store/modules/ui-state/ui-state.selectors";
import { logout, setAccessToken } from "store/modules/users/users.actions";
import { switchAppTheme } from "switch-app-theme";

import { UserTracker } from "user-tracker";
import { useUrlQuery } from "utils/hooks/use-url-query";
import { justWait } from "utils/just-wait";
import "./App.scss";

import { useResponsive } from "@libs/@react-hooks-responsive";
import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { WithModalState } from "@libs/modal-panels/modal-state-context";
import { iBadgeRecord } from "@sdk/badges/badge-record-model";
import { ThemeConfig } from "antd/lib/config-provider";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { iRouteItem } from "components/common/side-bar-menu/menu-item-mode";
import { TitleBar } from "components/electron-app/title-bar/title-bar.component";
import { QuickActions } from "components/k-bar/quick-actions-registry";
import {
  AdministrationMenu,
  OtherAppsMenu,
  WorkspaceManagement,
} from "components/pages/configurations/configurations-navigation-spec";
import {
  EmailConfigurationMenu,
  FacebookConfigurationMenu,
  InstagramConfigurationMenu,
  LiveChatConfigurationMenu,
  TelnyxConfigurationMenu,
  TwitterConfigurationMenu,
  WhatsappConfigurationMenu,
} from "components/pages/configurations/sections/connection-configurations-container";
import { DarkModeBg } from "dark-mode-bg";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import { isElectronEnv } from "electron-utils";
import i18n from "i18next";
import { KBNavGenerator } from "kb-nav-generator";
import { flatten } from "lodash";
import {
  AddEditKnowledgeDocument,
  KnowledgeBaseManageArticles,
  MagicAssistant,
  NewBadgeEarned,
  OpenAiAssistant,
} from "modal-registry";
import { Provider as EventBusProvider } from "react-bus";
import { ReactI18NextConfig } from "react-i18config";
import { initReactI18next } from "react-i18next";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { selectConversationsMap } from "store/modules/conversations/conversations.selectors";
import {
  setDarkMode,
  setIsPinned,
} from "store/modules/ui-state/ui-state.actions";
import { selectAccessToken } from "store/modules/users/users.selectors";
import { GenerateInboxTour } from "tours/inbox-tour";
import { GenerateNavigationTour } from "tours/navigation-tour";
import { GlobalEventEmitter } from "utils/global-event-emitter";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { mergeDeep } from "utils/merge-deep";
import { getAntLocal } from "./locales/ant.lang-new";
import { persistor, store } from "./store/store";
import { iStore } from "./store/store.model";
import { useForceUpdate } from "./utils/hooks/force-update";

// dayjs.locale(en);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);

i18n.use(initReactI18next).init(ReactI18NextConfig);

const breakpoints = { xl: 1600, lg: 1200, md: 996, sm: 768, xs: 576, xxs: 0 };

const LoadingView = (
  <div className="flex items-center justify-center h-screen w-screen">
    {/* <i className="ri-loader-5-line animate-spin text-4xl"></i> */}
    <div className="loader animate-spin text-4xl">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="48"
        height="48"
        style={{ fill: "currentColor" }}
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z" />
      </svg>
    </div>
  </div>
);

function App() {
  const language = useSelector((state: iStore) => state.preferences.language);
  const [notificationAPI, notificationContextHolder] =
    notification.useNotification();
  const forceUpdate = useForceUpdate();
  const AntLocale = useMemo(() => getAntLocal(language), [language]);

  //const isDarkMode = true;
  const isDarkMode = useSelector(selectIsDarkMode);
  const isSwitchingProfile = useSelector(selectIsSwitchingProfile);
  useEffect(() => {
    switchAppTheme(isDarkMode);
  }, [isDarkMode]);

  const { changePanelState } = useModalPanels();

  useEffect(() => {
    BrowserWakeUpHandler.init(async (sleepTime) => {
      if (sleepTime > 10 * 10000) {
        message.warning("Reloading. Browser restarted from sleep");
        await justWait(2000);
        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    Spin.setDefaultIndicator(<LoadingIndicatorWithSpin />);
  }, []);

  useEffect(() => {
    const emptyImage = (
      <div className="w-full h-full flex justify-center items-center text-6xl">
        <i className="ri-folder-5-line"></i>
      </div>
    );
    Empty.PRESENTED_IMAGE_DEFAULT = emptyImage;
    Empty.PRESENTED_IMAGE_SIMPLE = emptyImage;
  }, []);

  useEffect(() => {
    if (!(window as any).appStartedTime) {
      (window as any).appStartedTime = Date.now();
    }
  }, []);

  useEffect(() => {
    Notifications.inAppNotificationAPI = notificationAPI;
  }, [notificationAPI]);

  // useEffect(() => {
  //   const clickEventListener = function (event) {
  //     const target = event.target;
  //     const closestLabel = target.closest("label");
  //     const closestButton = target.closest("button");
  //     const closestAnchor = target.closest("a");
  //     const closestClickable = target.closest("[data-click-label]");
  //     const closestContext = target.closest("[data-click-context]");
  //     const targetElement = closestButton || closestAnchor || closestLabel;

  //     // Ignore Events
  //     if (!targetElement && target.innerText?.length > 50) {
  //       return;
  //     }
  //     // Ignore modal outside click
  //     if (
  //       target.className &&
  //       typeof target.className === "string" &&
  //       target.className?.indexOf("ant-modal-wrap") > -1
  //     ) {
  //       return;
  //     }

  //     // Ignore drop down clicks
  //     if (target.closest(".ant-select-dropdown")) {
  //       return;
  //     }

  //     // Modal Header Clicks
  //     if (target.closest(".ant-modal-header")) {
  //       return;
  //     }

  //     const elementName =
  //       closestClickable?.dataset.clickLabel ||
  //       targetElement?.innerText ||
  //       `${
  //         target.innerText && target.innerText?.split(" ").length < 4
  //           ? target.innerText
  //           : target.tagName
  //       }`;
  //     const contextName = closestContext?.dataset.clickContext;

  //     const additionalData = {
  //       text:
  //         targetElement?.innerText ||
  //         target.innerText ||
  //         (() => {
  //           // console.log(target.tagName);
  //           if (target.tagName === "I") {
  //             return target.className;
  //           }
  //         })(),
  //       ...(JSON.parse(event.target.dataset.clickData || "{}") || {}),
  //     };

  //     const trackEvent = `Clicked ${
  //       contextName ? `${contextName} -> ` : ""
  //     }${elementName}`;
  //     // console.log(trackEvent, additionalData);
  //     UserTracker.track(trackEvent, {
  //       contextName: contextName,
  //       elementName: elementName,
  //       ...additionalData,
  //     });
  //   };
  //   document.addEventListener("click", clickEventListener, false);
  //   return () => {
  //     document.removeEventListener("click", clickEventListener, false);
  //   };
  // }, []);

  useEffect(() => {
    UserTracker.track("App Loaded", {
      isReload:
        performance?.navigation?.type === performance?.navigation?.TYPE_RELOAD,
    });
  }, []);

  useEffect(() => {
    const errorHandler = (event: ErrorEvent) => {
      if (
        event.message.includes("Failed to execute 'insertBefore' on 'Node'")
      ) {
        message.error(
          "It looks like one your plugin is interfering with Click Connector",
        );
        SDK.sendErrorLog({
          subject: "Plugin Interference Log",
          data: {
            title: "Plugin Interference Log",
            url: window.location.href,
            event: event.toString(),
            error: event.message,
          },
        });
        UserTracker.track("Plugin Interference Logged", {
          url: window.location.href,
          error: event.message,
        });
      }
    };
    window.addEventListener("error", errorHandler);
    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  const urlQuery = useUrlQuery();
  const dispatch = useDispatch();

  const accessTokenInUrl = urlQuery.get("accessToken")!;
  useEffect(() => {
    if (accessTokenInUrl) {
      const newUserSDK = new AccountsAndUserManagementServices({
        ...SDKConfig,
        token: accessTokenInUrl,
      });
      dispatch(setSwitchingProfile(true));
      Promise.all([
        newUserSDK.getCurrentUser(),
        newUserSDK.getCurrentOrganization(),
      ]).then(([user, organization]) => {
        dispatch(
          addRecentLoginData({
            // Org Data
            domain: organization.domain.toLowerCase(),
            // organizationLogo: string,
            // User Data
            email: user.credentials.email.toLowerCase(),
            firstName: user.data.firstName,
            lastName: user.data.lastName,
            avatar: user.data.avatar,
            // Access Data
            accessToken: accessTokenInUrl,
          }),
        );
        dispatch(logout());
        urlQuery.delete("accessToken");
        BrowserHistory.replace({ search: urlQuery.toString() });
        setTimeout(() => {
          dispatch(setAccessToken(accessTokenInUrl));
          dispatch(setSwitchingProfile(false));
        }, 600);
      });
    }
  }, [accessTokenInUrl, dispatch, urlQuery]);

  const allConnections = useSelector(selectAllConnections);

  const kbBindings = useMemo(() => {
    const themeActions = [
      {
        id: "theme",
        name: "Change theme…",
        keywords: "interface color dark light",
        section: "Preferences",
      },
      {
        id: "darkTheme",
        name: "Dark",
        keywords: "dark theme",
        section: "",
        perform: (actionImpl) => {
          dispatch(setDarkMode(true));
        },
        parent: "theme",
      },
      {
        id: "lightTheme",
        name: "Light",
        keywords: "light theme",
        section: "",
        perform: (actionImpl) => {
          dispatch(setDarkMode(false));
        },
        parent: "theme",
      },
    ] as Action[];

    const configurationMenuShortCuts = [
      ...[...AdministrationMenu, ...WorkspaceManagement, ...OtherAppsMenu].map(
        (item) => KBNavGenerator(item, dispatch, "Configurations > "),
      ),
    ];

    const connectionConfigMenu = flatten(
      allConnections
        .map((connection) => {
          if (connection.type === "EMAIL") {
            return EmailConfigurationMenu.filter(
              (item) => item.type === "LINK_ITEM",
            ).map((item) =>
              KBNavGenerator(
                item as iRouteItem,
                dispatch,
                `${connection.label} > `,
                `/configurations/connections/${connection.id}/`,
              ),
            );
          } else if (connection.type === "FACEBOOK") {
            return FacebookConfigurationMenu.map((item) =>
              KBNavGenerator(
                item as iRouteItem,
                dispatch,
                `${connection.label} > `,
                `/configurations/connections/${connection.id}/`,
              ),
            );
          } else if (connection.type === "INSTAGRAM") {
            return InstagramConfigurationMenu.map((item) =>
              KBNavGenerator(
                item as iRouteItem,
                dispatch,
                `${connection.label} > `,
                `/configurations/connections/${connection.id}/`,
              ),
            );
          } else if (connection.type === "TELNYX") {
            return TelnyxConfigurationMenu.map((item) =>
              KBNavGenerator(
                item as iRouteItem,
                dispatch,
                `${connection.label} > `,
                `/configurations/connections/${connection.id}/`,
              ),
            );
          } else if (connection.type === "TWITTER") {
            return TwitterConfigurationMenu.map((item) =>
              KBNavGenerator(
                item as iRouteItem,
                dispatch,
                `${connection.label} > `,
                `/configurations/connections/${connection.id}/`,
              ),
            );
          } else if (connection.type === "WEB_CHAT") {
            return LiveChatConfigurationMenu.map((item) =>
              KBNavGenerator(
                item as iRouteItem,
                dispatch,
                `${connection.label} > `,
                `/configurations/connections/${connection.id}/`,
              ),
            );
          } else if (connection.type === "WHATS_APP_PRIVATE") {
            return WhatsappConfigurationMenu.map((item) =>
              KBNavGenerator(
                item as iRouteItem,
                dispatch,
                `${connection.label} > `,
                `/configurations/connections/${connection.id}/`,
              ),
            );
          }
          return undefined;
        })
        .filter((e) => e),
    );

    const quickActions = QuickActions.map((quickAction) => ({
      id: quickAction.label!,
      section: "Quick Actions",
      icon: quickAction.icon,
      // subtitle: quickAction.data?.subjectLine,
      name: quickAction.label,
      keywords: `${quickAction.label} ${quickAction.keywords}`,
      priority: Priority.HIGH,
      perform: () => {
        changePanelState(quickAction.modal, true, quickAction.additionalInput);
      },
    }));

    const aiAssistant = {
      id: "OPEN_AI_ASSISTANT",
      section: "AI",
      icon: <i className="ri-magic-line"></i>,
      name: "AI Assistant",
      keywords: "AI Assistant",
      priority: Priority.HIGH,
      perform: () => {
        changePanelState(OpenAiAssistant, true, {});
      },
    };

    const magicAssistant = {
      id: "MAGIC_ASSISTANT",
      section: "AI",
      icon: <i className="ri-magic-line"></i>,
      name: "Magic Assistant",
      keywords: "Magic Assistant",
      priority: Priority.HIGH,
      perform: () => {
        changePanelState(MagicAssistant, true, {});
      },
    };

    const knowledgeDocument = {
      id: "ADD_KNOWLEDGE_DOCUMENT",
      section: "AI",
      icon: <i className="ri-add-line"></i>,
      name: "Add New Document to Knowledge Kit",
      keywords: "AI Knowledge Document Kit",
      priority: Priority.LOW,
      perform: () => {
        changePanelState(AddEditKnowledgeDocument, true, {});
      },
    };

    const manageArticles = {
      id: "MANAGE_KB_ARTICLES",
      section: "Knowledge Base",
      icon: <i className="ri-folder-5-line"></i>,
      name: "Manage Knowledge Base Articles",
      keywords: "Manage Knowledge Base Articles",
      priority: Priority.LOW,
      perform: () => {
        changePanelState(KnowledgeBaseManageArticles, true, {});
      },
    };

    return flatten([
      aiAssistant,
      magicAssistant,
      manageArticles,
      knowledgeDocument,
      ...quickActions,
      ...themeActions,
      ...configurationMenuShortCuts,
      ...connectionConfigMenu,
    ]) as Action[];
  }, [allConnections, changePanelState, dispatch]);

  useRegisterActions(kbBindings, [kbBindings]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      const receiveMessage = (event) => {
        if (event?.data?.ccServiceWorker) {
          const data = event?.data?.ccServiceWorker;
          if (data.conversationId) {
            dispatch(push(`/conversations/auto/${data.conversationId}`));
          }
        }
      };
      navigator.serviceWorker.addEventListener(
        "message",
        receiveMessage,
        false,
      );
      return () => {
        navigator.serviceWorker.removeEventListener("message", receiveMessage);
      };
    }
  }, [dispatch]);

  const { size, screenIsAtLeast, screenIsAtMost } = useResponsive(breakpoints);

  const themeConfig = useMemo(() => {
    // @component-background: #ffffff0a;
    // @select-border-color: #434343;
    // @table-border-color: rgba(141, 178, 247, 8%);
    // @checkbox-size: 20px;
    // @switch-heigh: 25px;
    // @switch-min-width: 50px;
    const config = {
      token: {
        // colorPrimary: "#000000",
        colorPrimary: "#1A73E8",
        fontFamily: `-apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
      },
      components: {
        Checkbox: {
          controlInteractiveSize: 20,
        },
        // Table: {
        //   colorBgContainer: "#ffffff",
        //   colorBgElevated: "#ffffff",
        //   colorBgLayout: "#000000",
        // },
      },
      algorithm: [
        theme.defaultAlgorithm,
        (token) => {
          return {
            ...theme.defaultAlgorithm(token),
            fontFamily: `-apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
          };
        },
      ],
    } as ThemeConfig;

    if (isDarkMode) {
      mergeDeep(config, {
        token: {
          colorPrimary: "#1a73e8",
          colorPrimaryBg: "#19202d",
          colorBgBase: "#0e1118",
          colorTextBase: "#ffffff",
          colorBorder: "#242e40",
          colorBgElevated: "#101010",
        },
        components: {
          Divider: {
            colorSplit: `rgba(141, 178, 247, 8%)`,
          },
          Menu: {
            colorItemBg: "transparent",
          },
        },
        algorithm: [
          theme.darkAlgorithm,
          (token) => {
            return {
              ...theme.darkAlgorithm(token),
              fontFamily: `-apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
            };
          },
        ],
      } as ThemeConfig);
    }

    if (screenIsAtMost("xl")) {
      mergeDeep(config, {
        token: {
          fontSize: 13,
          controlHeight: 28,
        },
      } as ThemeConfig);
    }
    return config;
  }, [isDarkMode, screenIsAtMost]);

  const isElectron = useSelector(selectIsElectronMode);
  const windowState = useSelector(selectWindowState);

  useEffect(() => {
    if (isElectronEnv()) {
      // Load Electron Env
      console.log("Load Electron Env");
      setTimeout(() => {
        import("./platform.electron")
          .then((d) => {
            console.log("Electron Loaded");
          })
          .catch((e) => {
            // console.log("Error while loading electron env", e);
          });
      }, 2000);
    }
  }, []);

  useEffect(() => {
    const registerEvents = (data: iBadgeRecord) => {
      if (data.userId) {
        changePanelState(NewBadgeEarned, true, { badgeId: data.badgeId! });
      }
    };
    GlobalEventEmitter.addListener("BADGE_EARNED", registerEvents);
    return () => {
      GlobalEventEmitter.removeListener("BADGE_EARNED", registerEvents);
    };
  }, [changePanelState]);

  const [tourState, setTourState] = useSimpleState({
    isTourOpen: false,
    tourSteps: [] as TourStepProps[],
  });

  useEffect(() => {
    const registerEvents = (tour: string) => {
      UserTracker.track("TOUR - Activated", { tour });
      if (tour === "NAVIGATION") {
        setTourState({
          isTourOpen: true,
          tourSteps: GenerateNavigationTour(store),
        });
      } else if (tour === "INBOX") {
        const conversationMap = selectConversationsMap(store.getState());
        const sampleConversation = Object.keys(conversationMap)[1];
        if (sampleConversation) {
          dispatch(push(`/conversations/auto/${sampleConversation}`));
        } else {
          dispatch(push(`/conversations`));
        }

        setTourState({
          isTourOpen: true,
          tourSteps: GenerateInboxTour(store),
        });
      }
    };
    GlobalEventEmitter.addListener("OPEN_TOUR", registerEvents);
    return () => {
      GlobalEventEmitter.removeListener("OPEN_TOUR", registerEvents);
    };
  }, [changePanelState, dispatch, setTourState]);

  const isAuthenticated = useSelector(selectAccessToken);

  useEffect(() => {
    if (isAuthenticated) {
      document.title =
        "ClickConnector - Your New Favorite Customer Support Platform";
    }
  }, [isAuthenticated]);

  if (isSwitchingProfile || accessTokenInUrl) {
    return LoadingView;
  }

  return (
    <ConfigProvider locale={AntLocale} theme={themeConfig}>
      <GlobalErrorBoundary>
        {isElectron && (
          <TitleBar
            isFullScreen={windowState?.isFullScreen}
            isPinned={windowState?.isPinned}
            onClose={() =>
              GlobalEventEmitter.emit("WINDOW_EVENT", "CLOSE_WINDOW")
            }
            onFullScreen={() =>
              GlobalEventEmitter.emit("WINDOW_EVENT", "SET_FULL_SCREEN")
            }
            onMaximize={() =>
              GlobalEventEmitter.emit("WINDOW_EVENT", "MAXIMIZE")
            }
            onMinimize={() =>
              GlobalEventEmitter.emit("WINDOW_EVENT", "MINIMIZE")
            }
            onPin={(status) => {
              store.dispatch(setIsPinned(status));
            }}
            onSwitchToCompactMode={() => {}}
          />
        )}
        {isAuthenticated && (
          <>
            <SocketDisconnectedBanner />
            <UnassignedChatsBanner />
            <JoiningChatsRestrictedBanner />
            <AccountStatusBannerAndModal />
            <AwayDetectorBanner />
            <ConnectionIssuesBanner />
            <KBar />
          </>
        )}
        {notificationContextHolder}
        <DarkModeBg />
        {/* Router */}
        <MainRouter />
        <Tour
          open={tourState.isTourOpen}
          onClose={() => setTourState({ isTourOpen: false })}
          steps={tourState.tourSteps}
          arrow={false}
        />
      </GlobalErrorBoundary>
    </ConfigProvider>
  );
}

function WrappedApp() {
  const [waitForSVGToLoad, setSVGLoadingStatus] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setSVGLoadingStatus(false);
    }, 1500);
  }, []);

  useEffect(() => {
    const loadOnLocalhost = false;
    if (!window.location.href.includes("localhost") || loadOnLocalhost) {
      const timer = setInterval(() => {
        if (window["click-connector-widget"]) {
          const widget = new window["click-connector-widget"]();
          widget.mount({
            corneredChatWidget: true,
            loadIframeDemand: true,
          });
          clearInterval(timer);
        }
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={LoadingView} persistor={persistor}>
        <EventBusProvider>
          <WithModalState>
            <ConnectedRouter history={BrowserHistory}>
              {/* Todo: Could remove the waitForSVGToLoad after testing */}
              <KBarProvider
                options={{
                  enableHistory: true,
                  callbacks: {
                    onSelectAction: (action) => message.info(`${action.name}`),
                  },
                }}
              >
                {waitForSVGToLoad ? LoadingView : <App />}
              </KBarProvider>
            </ConnectedRouter>{" "}
          </WithModalState>
        </EventBusProvider>
      </PersistGate>
    </Provider>
  );
}

export default WrappedApp;
