import { Comment } from "@ant-design/compatible";
import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  DatePicker,
  Divider,
  Input,
  List,
  message,
  Result,
  Select,
  Space,
  Timeline,
  Tooltip
} from "antd";
import { AvatarGroup, UserAvatar } from "components/common/avatar/avatar";
import { EditableText } from "components/common/editable-text/editable-text";
import { ModalTitle } from "components/common/modal-title";
import { ChatListItem } from "components/modules/conversations/components/chat-list/chat-list-item";
import { ConversationsPickerModal } from "components/modules/conversations/components/conversation-picker-modal/conversation-picker-modal";
import { SingleMessageItem } from "components/modules/conversations/components/messages-list/single-message-item";
import { ContactPickerModal } from "components/modules/crm/contacts/components/contact-picker-modal/contact-picker-modal";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import { UserPickerModal } from "components/modules/user-management/users/components/user-picker-modal/user-picker-modal";
import { ContactCard } from "components/pages/contacts/components/contact-card/contact-card";
import { push } from "connected-react-router";
import copyToClipboard from "copy-to-clipboard";
import dayjs from "dayjs";
import _ from "lodash";
import { ConversationViewer } from "modal-registry";
import { useCallback, useMemo, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { loadConversationById } from "store/modules/conversations/conversations.helpers";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { loadTaskById } from "store/modules/tasks/tasks.helpers";
import { selectTaskById } from "store/modules/tasks/tasks.selectors";
import {
  selectCurrentUser,
  selectUser
} from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { iTaskActivity, iTaskComment } from "../../../../@sdk/tasks/task-model";
import { InlineBraftEditor } from "../inline-braft-editor/inline-braft-editor";
import { TaskTypeIcon } from "../task-card";
import { PriorityTag } from "../task-list/task-list.item";

import "./task-viewer.scss";

export const PrioryLabels = [
  {
    label: "Very High",
    value: 100
  },
  {
    label: "High",
    value: 80
  },
  {
    label: "Medium",
    value: 55
  },
  {
    label: "Low",
    value: 20
  },
  {
    label: "Very Low",
    value: 0
  }
];

export const TaskViewer = ({ taskId }: { taskId?: string }) => {
  const { state: task, isLoading: isTaskLoading, hasError } = useQueryWithStore(
    selectTaskById(taskId!),
    loadTaskById(taskId!)(),
    [taskId],
    !taskId
  );

  const currentUser = useSelector(selectCurrentUser);
  const isAssigned = useMemo(
    () => _.find(task?.assignedTo || [], { userId: currentUser!.id }),
    [currentUser, task?.assignedTo]
  );
  const { doAction: updateTask } = useSDKActionWithDeps(
    () => ({
      action: SDK => edits => SDK.tasks.editById(taskId!, edits),
      // successMessage: "Joined Conversation",
      failureMessage: "Something went wrong"
    }),
    [taskId]
  );

  const {
    doAction: assignUser,
    isProcessing: isAssignProcessing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => userId => SDK.tasks.assignUser(taskId!, userId),
      // successMessage: "Joined Conversation",
      failureMessage: "Something went wrong"
    }),
    [taskId]
  );
  const {
    doAction: unassignUser,
    isProcessing: isUnassignProcessing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => userId => SDK.tasks.unassignUser(taskId!, userId),
      // successMessage: "Joined Conversation",
      failureMessage: "Something went wrong"
    }),
    [taskId]
  );

  const {
    doAction: markAsDone,
    isProcessing: isProcessingState
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (status: boolean) =>
        SDK.tasks.markAsDone(task?.id, status),
      successMessage: "Done",
      failureMessage: "Something went wrong",
      actionDependencies: [task?.id]
    }),
    [task?.id]
  );

  const store = useStore();

  const [userPickerModalState, setUserPickerModalState] = useState(false);
  const [contactPickerModalState, setContactPickerModalState] = useState(false);
  const [
    conversationPickerModalState,
    setConversationPickerModalState
  ] = useState(false);

  const {
    state: associatedConversation,
    retry: reloadConversation,
    hasError: conversationNotFound
  } = useQueryWithStore(
    selectConversationById(task?.conversationId!),
    loadConversationById(task?.conversationId!)(),
    [task?.conversationId],
    !task?.conversationId
  );

  const dueDateMoment = useMemo(() => dayjs(task?.dueDate), [task?.dueDate]);

  const navigateToConversationPage = useCallback(
    (conversationId: string) => {
      store.dispatch(push(`/conversations/auto/${conversationId}`));
    },
    [store]
  );

  const { changePanelState } = useModalPanels();

  if (!taskId) {
    return (
      <div className="task-view-container flex-1 flex flex-col items-stretch">
        <Result
          title={"Select a task"}
          icon={<i className="ri-info-line text-5xl"></i>}
          className="flex-1 h-full flex flex-col items-center justify-center"
        />
      </div>
    );
  }

  if (!task && isTaskLoading) {
    return (
      <div className="task-view-container flex-1 flex flex-col items-stretch">
        <div className="w-full flex flex-col justify-center items-center p-20">
          <i className="ri-loader-5-line animate-spin text-2xl"></i>
        </div>
      </div>
    );
  }

  if (!task) {
    return (
      <div className="task-view-container flex-1 flex flex-col items-stretch">
        <Result
          title={"Task Not Found"}
          icon={<i className="ri-error-warning-line text-5xl"></i>}
          className="flex-1 h-full flex flex-col items-center justify-center"
        />
      </div>
    );
  }

  return (
    <div
      className="task-view-container flex-1 flex flex-col items-stretch0"
      data-click-context="Task View"
    >
      <div className="task-header flex flex-row justify-between items-center px-6 py-2">
        <div className="task-card flex flex-row items-center flex-1">
          <div className="icon-container text-xl">
            <TaskTypeIcon type={task.type} />
          </div>
          <div className="profile flex flex-col ml-2 flex-1">
            <EditableText
              value={task?.label}
              onSave={label => updateTask({ label })}
              className="font-bold text-lg p-0 m-0"
              placeholder="Untitled Task"
              //   isProcessing={isSubjectChangeProcessing}
            />
            <div className="flex justify-between">
              <div className="text-xs" style={{ paddingLeft: 1 }}>
                Created at: {dayjs(task?.createdAt).format("LL h:mm:ss a ")}
              </div>
            </div>
          </div>
        </div>
        <div className="right-side flex flex-row items-center">
          <Tooltip title="Copy Conversation Id">
            <Button
              icon={<i className="ri-clipboard-line"></i>}
              type="text"
              shape="circle"
              onClick={() => {
                copyToClipboard(taskId!);
              }}
            ></Button>
          </Tooltip>
        </div>
      </div>
      <Divider className="w-full my-0" />

      <div className="flex flex-row justify-start items-center py-2 px-4 action-bar">
        <div className="assigned-users flex flex-row items-center">
          <div className="font-bold text-sm text-gray-600 mr-2">Assigned:</div>
          <AvatarGroup>
            {task?.assignedTo.map(userObj => (
              <Tooltip
                title={getUserName(
                  selectUser(userObj.userId)(store.getState())
                )}
                key={userObj.userId}
              >
                <div className="avatar-container" key={userObj.userId}>
                  <div
                    className="overlay"
                    onClick={() => {
                      if (task.assignedTo.length > 1) {
                        unassignUser(userObj.userId);
                      } else {
                        message.warning(
                          "Task requires at least one assigned user"
                        );
                      }
                    }}
                  >
                    <i className="ri-subtract-line"></i>
                  </div>

                  <UserAvatar userId={userObj.userId} key={userObj.userId} />
                </div>
              </Tooltip>
            ))}
            <Tooltip title={"Assign User"}>
              <div
                className="rounded-full bg-gray-100 flex items-center justify-center w-8 h-8 cursor-pointer hover:bg-gray-200"
                onClick={() => setUserPickerModalState(true)}
              >
                <i className="ri-add-line"></i>
              </div>
            </Tooltip>
          </AvatarGroup>
        </div>

        <Divider type="vertical" className="h-full"></Divider>
        <DatePicker
          showTime={false}
          value={dueDateMoment}
          format="YYYY-MM-DD"
          placeholder="Select Due Date"
          className="font-bold"
          style={{ width: 200 }}
          onChange={d => {
            updateTask({ dueDate: d?.valueOf() });
          }}
        />
        <Divider type="vertical" className="h-full"></Divider>
        <Select
          mode="tags"
          value={task?.tags}
          className="flex-1"
          maxTagCount="responsive"
          dropdownStyle={{ display: "none" }}
          placeholder="Tag..."
          onChange={(tags: string[]) => {
            updateTask({ tags: tags });
          }}
        ></Select>
        <Divider type="vertical" className="h-full"></Divider>
        <Select
          className="flex-1"
          value={task?.priority as any}
          maxTagCount="responsive"
          placeholder="Select Priority"
          onChange={(priority: string[]) => {
            updateTask({ priority });
          }}
        >
          {(PrioryLabels || []).map(option => (
            <Select.Option value={option.value} key={option.value}>
              <PriorityTag priority={option.value} />
            </Select.Option>
          ))}
        </Select>

        <Divider type="vertical" className="h-full"></Divider>
        {!task?.isResolved && (
          <Button
            type="dashed"
            onClick={() => markAsDone(true)}
            loading={isProcessingState}
          >
            Mark as done
          </Button>
        )}
        {task?.isResolved && (
          <Button
            type="dashed"
            onClick={() => markAsDone(false)}
            loading={isProcessingState}
          >
            Re-Open Task
          </Button>
        )}

        <Divider type="vertical" className="h-full"></Divider>

        {/* More Menu */}
        {/* <div className="mr-2 flex flex-row items-center">
          <Dropdown
            trigger={["click"]}
            placement="bottomRight"
            overlay={
              <Menu
                className="task-actions-drop-down"
                data-click-context="Task Menu"
              >
                <Menu.Item key="ARCHIVE_TASK">
                  <Button
                    type="text"
                    onClick={() => {
                      // changeConversationStatus("CLOSED");
                    }}
                    className="action-button"
                    icon={<i className="ri-inbox-archive-line"></i>}
                    //   loading={isStatusChangeProcessing}
                    block
                  >
                    Archive Task
                  </Button>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="text"
              icon={<i className="ri-more-2-fill"></i>}
              className="px-2 mx-1"
            ></Button>
          </Dropdown>
        </div> */}
      </div>

      <Divider className="my-0 w-full"></Divider>

      <div className="flex-1 flex flex-row overflow-hidden relative">
        <div className="w-1/2 overflow-auto h-full">
          <div className="body-container p-10">
            <InlineBraftEditor
              value={task?.body}
              onChange={value => {
                if (task?.body !== value) {
                  updateTask({ body: value });
                }
              }}
            />

            <div className="mt-8 p-4 border border-1 border-gray-200 dark:border-gray-700">
              <div className="font-bold text-gray-600">
                Referenced Conversation:
                {task?.type === "TASK" && (
                  <Space>
                    <Button
                      onClick={() => setConversationPickerModalState(true)}
                      type="link"
                    >
                      {!task?.conversationId
                        ? "Pick Conversation"
                        : "Pick Different Conversation"}
                    </Button>

                    {task?.conversationId && (
                      <Button
                        icon={<i className="ri-delete-bin-line"></i>}
                        onClick={() => {
                          updateTask({ conversationId: null });
                        }}
                        type="link"
                      ></Button>
                    )}
                  </Space>
                )}
              </div>
              {task?.conversationId && associatedConversation && (
                <div className="mt-4">
                  <ChatListItem
                    conversationId={task?.conversationId}
                    // onSelected={e => {}}
                    onConversationSelected={conversationId => {
                      console.log("conversationId", conversationId);
                      changePanelState(ConversationViewer, true, {
                        conversationId
                      });
                      // navigateToConversationPage(conversationId);
                    }}
                  />
                </div>
              )}
            </div>

            <div className="mt-8 p-4 border border-1 border-gray-200 dark:border-gray-700">
              <div className="font-bold text-gray-600">
                Referenced Contact:
                {!task?.conversationId && (
                  <Space>
                    <Button
                      onClick={() => setContactPickerModalState(true)}
                      type="link"
                    >
                      {!task?.conversationId
                        ? "Pick Contact"
                        : "Pick Different Contact"}
                    </Button>
                    {task?.contactId && (
                      <Button
                        icon={<i className="ri-delete-bin-line"></i>}
                        onClick={() => {
                          updateTask({ contactId: null });
                        }}
                        type="link"
                      ></Button>
                    )}
                  </Space>
                )}
              </div>
              {task?.contactId && (
                <div className="mt-4">
                  <ContactCard contactId={task?.contactId} />
                </div>
              )}
            </div>

            {task?.messageId && task?.conversationId && task?.contactId && (
              <div className="mt-8 p-4 border border-1 border-gray-200 dark:border-gray-700">
                <div className="font-bold text-gray-600 mb-4">
                  Referenced Message:
                </div>
                <SingleMessageItem
                  conversationId={task?.conversationId}
                  contactId={task?.contactId}
                  messageId={task?.messageId}
                  ignoreTasks={true}
                />
              </div>
            )}
          </div>
          <div className="activities-container p-10">
            <Divider className="font-bold text-gray-600 mb-8">
              Activities
            </Divider>
            <TaskTimeLine activities={task?.activities} />
          </div>
        </div>
        <div className="comments-container w-1/2 bg-gray-100 dark:bg-gray-800 flex flex-col overflow-auto h-full">
          <div className="list overflow-auto flex-1 p-10">
            <List
              dataSource={task?.comments}
              header={
                <div className="text-gray-600">
                  {task?.comments.length}
                  {task?.comments.length > 1 ? " comments" : " comment"}
                </div>
              }
              itemLayout="horizontal"
              renderItem={comment => <TaskComment comment={comment} />}
            />
          </div>

          <CommentReplyInput taskId={taskId} />
        </div>
      </div>

      {/* <Divider className="w-full" /> */}

      {/* User Assignment */}
      <UserPickerModal
        visible={userPickerModalState}
        onChangeVisibility={setUserPickerModalState}
        onUserSelected={async userId => {
          console.log("user", userId);
          const hide = message.loading("Processing", 0);
          try {
            await assignUser(userId);
            setUserPickerModalState(false);
            hide();
          } catch (e) {
            hide();
          }
        }}
        title={
          <ModalTitle
            title="Pick a user to assign"
            icon={<i className="ri-mail-send-fill"></i>}
          />
        }
      />

      {/* Associate Contact */}
      <ContactPickerModal
        visible={contactPickerModalState}
        onChangeVisibility={setContactPickerModalState}
        onContactSelected={async contactId => {
          const hide = message.loading("Processing", 0);
          try {
            await updateTask({ contactId });
            setContactPickerModalState(false);
            hide();
          } catch (e) {
            hide();
          }
        }}
        title={
          <ModalTitle
            title="Pick a contact to assign"
            icon={<i className="ri-mail-send-fill"></i>}
          />
        }
      />

      {/* Associate Conversation */}
      <ConversationsPickerModal
        visible={conversationPickerModalState}
        onChangeVisibility={setConversationPickerModalState}
        onConversationSelected={async conversationId => {
          console.log("conversation", conversationId);
          const conversation = selectConversationById(conversationId)(
            store.getState()
          );
          const hide = message.loading("Processing", 0);
          try {
            await updateTask({
              conversationId,
              contactId: conversation.contactId
            });
            setConversationPickerModalState(false);
            hide();
          } catch (e) {
            hide();
          }
        }}
        title={
          <ModalTitle
            title="Pick a Conversation to assign"
            icon={<i className="ri-mail-send-fill"></i>}
          />
        }
      />
    </div>
  );
};

export const TaskComment = ({ comment }: { comment: iTaskComment }) => {
  const user = useSelector(selectUser(comment.userId));
  return (
    <Comment
      avatar={<UserAvatar userId={comment.userId} />}
      datetime={dayjs(comment.timestamp).format("LL")}
      content={comment.text}
      author={getUserName(user)}
    />
  );
};

export const TaskTimeLine = ({
  activities: _activities
}: {
  activities: iTaskActivity[];
}) => {
  const activities = useMemo(() => _.reverse(_.clone(_activities) || []), [
    _activities
  ]);
  return (
    <Timeline>
      {activities.map((activity, index) => (
        <Timeline.Item
          key={index.toString()}
          dot={<i className="ri-walk-line"></i>}
        >
          <div className="flex flex-col">
            <div className="title">{activity.label}</div>
            <div className="date text-gray-600 dark:text-gray-400 text-sm mode_transition">
              {dayjs(activity.timestamp).format("LL")}
            </div>
          </div>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export const CommentReplyInput = ({ taskId }: { taskId }) => {
  const [value, setValue] = useState("");

  const { doAction: addComment, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: SDK => (text: string, attachment: string[]) =>
        SDK.tasks.addComment(taskId!, text, attachment).then(d => {
          setValue("");
        }),
      // successMessage: "Joined Conversation",
      failureMessage: "Something went wrong"
    }),
    [taskId]
  );

  return (
    <div className=" bg-white dark:bg-black p-4 border-t border-1 border-gray-200 dark:border-gray-700">
      <div className="text-bold text-gray-600 mb-2">Reply</div>
      <Input.TextArea
        rows={2}
        onChange={e => setValue(e.target.value)}
        value={value}
        className=" border border-1  rounded-lg"
      />
      <div className="flex flex-row justify-end items-center mt-2">
        <Button
          type="dashed"
          className="font-bold"
          icon={<i className="ri-add-line"></i>}
          loading={isProcessing}
          onClick={() => addComment(value)}
        >
          Add Reply
        </Button>
      </div>
    </div>
  );
};
