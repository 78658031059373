import { buildNameSearchQuery } from "components/modules/crm/contacts/helpers/build-name-search";
import _, { escapeRegExp } from "lodash";
import { getMobileId } from "utils/clean-mobile-number";
import { ExtractPhoneNumbers } from "utils/extract-phone-numbers";

const use$textSearch = false;

export function ContactSearchQueryBuilder(term: string) {
  if (use$textSearch) {
    return ContactSearchQueryBuilderWithTextSearch(term);
  }
  return ContactSearchQueryBuilderWithRegexSearch(term);
}

export const ContactSearchQueryBuilderWithRegexSearch = (
  searchQuery: string,
) => {
  searchQuery = searchQuery.trim();

  const mobileId = getMobileId(
    searchQuery?.charAt(0) === "0" ? searchQuery?.slice(1) : searchQuery,
  )
    .replace("+", "")
    .trim();
  const searchByMobileQuery = mobileId
    ? {
        "data.primaryMobile": {
          $regex: escapeRegExp(mobileId),
          $options: "i",
        },
      }
    : undefined;

  const query = {
    $or: _.flattenDeep(
      [
        ...buildNameSearchQuery(searchQuery.split(" ")),
        {
          "data.primaryEmail": {
            $regex: escapeRegExp(searchQuery.split(" ").join("")),
            $options: "i",
          },
        },
        searchByMobileQuery,
      ].filter((e) => e),
    ),
  };
  return query;
};

export const ContactSearchQueryBuilderWithTextSearch = (
  searchQuery: string,
) => {
  searchQuery = searchQuery.trim();
  const phoneNumbers = ExtractPhoneNumbers(searchQuery);

  if (phoneNumbers.length > 0) {
    for (const mobile of phoneNumbers) {
      searchQuery += ` ${getMobileId(mobile)}`;
    }
  }

  return {
    $text: {
      $search: searchQuery,
    },
  };
};
