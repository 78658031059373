import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Form, Input, message, Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback } from "react";

export const AddPSequenceModal = ({
  visible,
  onChangeVisibility,
  onCreated
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated?: () => any;
}) => {
  const [form] = Form.useForm();

  const {
    doAction: onAddSequence,
    isProcessing,
    response
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.automationWorkflows
          .create({
            ...values
          })
          .then(res => {
            onCreated && onCreated();
            onChangeVisibility(false);
            form.resetFields();
          }),
      successMessage: "Sequence has been created",
      failureMessage: "Something went wrong"
    }),
    [form, onChangeVisibility, onCreated]
  );

  const onFinish = useCallback(async () => {
    try {
      await form.validateFields();
      const formValue = form.getFieldsValue();
      onAddSequence({
        ...formValue,
        steps: []
      });
    } catch (e) {
      message.error("Please check your input");
    }
  }, [form, onAddSequence]);

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-mail-send-line"></i>}
          title="Add New Sequence"
        />
      }
      open={visible}
      onOk={onFinish}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okButtonProps={{ icon: <i className="ri-check-line"></i> }}
      okText="Save"
      data-click-context="Add New Product"
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{}}
        onFinish={onFinish}
      >
        <Form.Item
          name="label"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please enter a sequence name"
            }
          ]}
        >
          <Input placeholder="Label" />
        </Form.Item>
        <Button htmlType="submit" type="primary" className="hidden">
          Save
        </Button>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
