import { ConversationSearchQueryBuilder } from "components/modules/conversations/helpers/build-chat-search";
import { GenerateMessageTextFilterQuery } from "components/modules/conversations/helpers/chat-filter-schema";
import { ContactSearchQueryBuilder } from "components/modules/crm/contacts/helpers/contact-search-query-builder";
import { buildOpportunitySearchQuery } from "components/pages/opportunities/helpers/build-opportunities-search";
import _ from "lodash";
import { useMemo, useState } from "react";
import { loadAllArticleCollections } from "store/modules/article-collection/article-collection.helpers";
import { selectAllArticleCollections } from "store/modules/article-collection/article-collection.selectors";
import { loadAllAutomationWorkflows } from "store/modules/automation-workflows/automation-workflows.helpers";
import { selectAllAutomationWorkflows } from "store/modules/automation-workflows/automation-workflows.selectors";
import { loadAllCampaigns } from "store/modules/campaigns/campaigns.helpers";
import { selectAllCampaigns } from "store/modules/campaigns/campaigns.selectors";
import { loadAllCCArticles } from "store/modules/cc-articles/cc-articles.helpers";
import { selectAllCCArticles } from "store/modules/cc-articles/cc-articles.selectors";
import { loadAllChatBots } from "store/modules/chat-bots/chat-bots.helpers";
import { selectAllChatBots } from "store/modules/chat-bots/chat-bots.selectors";
import { loadAllContactLists } from "store/modules/contact-lists/contact-lists.helpers";
import { selectAllContactLists } from "store/modules/contact-lists/contact-lists.selectors";
import { contactsDefaultQueryConfig } from "store/modules/contacts/contacts-default-query";
import { loadContactsQuery } from "store/modules/contacts/contacts.helpers";
import { selectContactsQuery } from "store/modules/contacts/contacts.selectors";
import { loadQueryConversations } from "store/modules/conversations/conversations.helpers";
import { selectConversationQuery } from "store/modules/conversations/conversations.selectors";
import { loadAllDynamicForms } from "store/modules/dynamic-forms/dynamic-forms.helpers";
import { selectAllDynamicForms } from "store/modules/dynamic-forms/dynamic-forms.selectors";
import { opportunitiesDefaultQueryConfig } from "store/modules/opportunities/opportunities-default-query";
import { loadOpportunitiesQuery } from "store/modules/opportunities/opportunities.helpers";
import { selectOpportunitiesQuery } from "store/modules/opportunities/opportunities.selectors";
import { loadAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.helpers";
import { selectAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.selectors";
import { DefaultConversationQueryConfig } from "store/modules/ui-state/ui-state.slice";
import { useQueryWithStore } from "store/store.hooks";
import { QueryConfig } from "store/utils/query-config";
import { useDebouncedValue } from "utils/hooks/use-debounced-value";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { QuickActions } from "./quick-actions-registry";

export const useConversationSearch = (_searchText: string) => {
  const [searchText] = useDebouncedValue(_searchText, 1000);

  // Conversation Search
  const [shouldSearchMessages, setShouldSearchMessages] = useState(false);
  // Toggle Back Text Message searches
  useEffectWhen(
    () => {
      if (shouldSearchMessages) {
        setShouldSearchMessages(false);
      }
    },
    [searchText, shouldSearchMessages],
    [searchText],
  );
  const [sortBy, setSortBy] = useState<"UPDATED_TIME" | "CREATED_TIME">(
    "UPDATED_TIME",
  );

  const viewQuery: QueryConfig & { alias: string } = useMemo(() => {
    const query = {
      $and: [
        !shouldSearchMessages
          ? searchText
            ? ConversationSearchQueryBuilder(searchText)
            : undefined
          : undefined,
      ].filter((e) => e),
    };
    if (shouldSearchMessages) {
      query["messagesQuery"] = GenerateMessageTextFilterQuery(searchText);
    }
    const queryConfig = {
      query: query,
      options: DefaultConversationQueryConfig.options,
    };
    return {
      ...queryConfig,
      alias: JSON.stringify(queryConfig),
      options: {
        sortBy:
          sortBy === "UPDATED_TIME"
            ? ["-metaData.lastMessage.timestamp"]
            : ["-metaData.createdAt"],
      },
    };
  }, [searchText, shouldSearchMessages, sortBy]);

  const {
    state: { list: conversations, isLoading, totalItems, lastFetchedPage },

    retry: reload,
  } = useQueryWithStore(
    selectConversationQuery(viewQuery.alias),
    loadQueryConversations(_.omit(viewQuery, "alias"), viewQuery.alias),
    [viewQuery],
    !searchText,
  );

  return {
    conversations,
    isLoading,
    totalItems,
    lastFetchedPage,
  };
};

export const useContactsSearch = (_searchText: string) => {
  const [searchText] = useDebouncedValue(_searchText, 1000);

  // Contact Search Search
  const viewQuery: QueryConfig & { alias: string } = useMemo(() => {
    const query = ContactSearchQueryBuilder(searchText);

    const queryConfig = {
      query: query,
      options: contactsDefaultQueryConfig.options,
    };
    return {
      ...queryConfig,
      alias: JSON.stringify(queryConfig),
    };
  }, [searchText]);

  const {
    state: { list: contacts, isLoading, totalItems, lastFetchedPage },
    retry: reloadContacts,
  } = useQueryWithStore(
    selectContactsQuery(viewQuery.alias),
    loadContactsQuery(_.omit(viewQuery, "alias"), viewQuery.alias),
    [viewQuery],
    !searchText,
  );
  return {
    contacts,
    isLoading,
    totalItems,
    lastFetchedPage,
  };
};

export const useOpportunitiesSearch = (_searchText: string) => {
  const [searchText] = useDebouncedValue(_searchText, 1000);

  // Contact Search Search
  const viewQuery: QueryConfig & { alias: string } = useMemo(() => {
    const query = {
      $and: [
        searchText
          ? buildOpportunitySearchQuery(searchText).opportunities
          : undefined,
        //   filterQuery?.opportunities,
        //   _.omit(opportunitiesDefaultQueryConfig.query, [])
      ].filter((e) => e),
    };

    const queryConfig = {
      query: query,
      options: opportunitiesDefaultQueryConfig.options,
    };
    return {
      ...queryConfig,
      alias: JSON.stringify(queryConfig),
    };
  }, [searchText]);

  const {
    state: { list: opportunities, isLoading, totalItems, lastFetchedPage },
    retry: reloadOpportunities,
  } = useQueryWithStore(
    selectOpportunitiesQuery(viewQuery.alias),
    loadOpportunitiesQuery(_.omit(viewQuery, "alias"), viewQuery.alias),
    [viewQuery],
    !searchText,
  );

  return {
    opportunities,
    isLoading,
    totalItems,
    lastFetchedPage,
  };
};

export const useCCHelpDeskSearch = (_searchText: string) => {
  const [searchText] = useDebouncedValue(_searchText, 1000);

  // Contact Search Search
  const { state: _articles, isLoading: isLoadingArticles } = useQueryWithStore(
    selectAllCCArticles,
    loadAllCCArticles,
    [],
    !searchText,
  );

  const availableArticles = useMemo(() => {
    return _.clone(
      _articles.filter((item) => item.metaData.status === "PUBLISHED"),
    ).reverse();
  }, [_articles]);

  const filteredData = useMemo(() => {
    if (!searchText) {
      return [];
    }
    return availableArticles.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          String(o[k]).toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [availableArticles, searchText]);

  return {
    articles: filteredData,
  };
};

export const useChatBotSearch = (_searchText: string) => {
  const [searchText] = useDebouncedValue(_searchText, 1000);

  // Contact Search Search
  const { state: _chatBots } = useQueryWithStore(
    selectAllChatBots,
    loadAllChatBots(),
    [],
    !searchText,
  );

  const availableBots = useMemo(() => {
    return _.clone(_chatBots.filter((item) => item)).reverse();
  }, [_chatBots]);

  const filteredData = useMemo(() => {
    if (!searchText) {
      return [];
    }
    return availableBots.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          String(o[k]).toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [availableBots, searchText]);

  return {
    chatBots: filteredData,
  };
};

export const useFormsSearch = (_searchText: string) => {
  const [searchText] = useDebouncedValue(_searchText, 1000);

  const { state: _forms } = useQueryWithStore(
    selectAllDynamicForms,
    loadAllDynamicForms(),
    [],
    !searchText,
  );

  const availableForms = useMemo(() => {
    return _.clone(_forms.filter((item) => item));
  }, [_forms]);

  const filteredData = useMemo(() => {
    if (!searchText) {
      return [];
    }
    return availableForms.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          String(o[k]).toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [availableForms, searchText]);

  return {
    dynamicForms: filteredData,
  };
};

export const useCampaignsSearch = (_searchText: string) => {
  const [searchText] = useDebouncedValue(_searchText, 1000);

  // Contact Search Search
  const { state: _campaigns } = useQueryWithStore(
    selectAllCampaigns,
    loadAllCampaigns(),
    [],
    !searchText,
  );

  const availableCampaigns = useMemo(() => {
    return _.clone(_campaigns.filter((item) => item)).reverse();
  }, [_campaigns]);

  const filteredData = useMemo(() => {
    if (!searchText) {
      return [];
    }
    return availableCampaigns.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          String(o[k]).toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [availableCampaigns, searchText]);

  return {
    campaigns: filteredData,
  };
};

export const useSequenceSearch = (_searchText: string) => {
  const [searchText] = useDebouncedValue(_searchText, 1000);

  // Contact Search Search
  const { state: _sequences } = useQueryWithStore(
    selectAllAutomationWorkflows,
    loadAllAutomationWorkflows(),
    [],
    !searchText,
  );

  const availableWorkflow = useMemo(() => {
    return _.clone(_sequences.filter((item) => item)).reverse();
  }, [_sequences]);

  const filteredData = useMemo(() => {
    if (!searchText) {
      return [];
    }
    return availableWorkflow.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          String(o[k]).toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [availableWorkflow, searchText]);

  return {
    sequences: filteredData,
  };
};

export const useContactListsSearch = (_searchText: string) => {
  const [searchText] = useDebouncedValue(_searchText, 1000);

  // Contact Search Search
  const { state: _contactLists } = useQueryWithStore(
    selectAllContactLists,
    loadAllContactLists,
    [],
    !searchText,
  );

  const availableContactLists = useMemo(() => {
    return _.clone(_contactLists.filter((item) => item)).reverse();
  }, [_contactLists]);

  const filteredData = useMemo(() => {
    if (!searchText) {
      return [];
    }
    return availableContactLists.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          String(o[k]).toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [availableContactLists, searchText]);

  return {
    contactLists: filteredData,
  };
};

export const useArticlesSearch = (_searchText: string) => {
  const [searchText] = useDebouncedValue(_searchText, 1000);

  // Contact Search Search
  const { state: _collections } = useQueryWithStore(
    selectAllArticleCollections,
    loadAllArticleCollections,
    [],
    !searchText,
  );

  const availableCollections = useMemo(() => {
    return _.clone(_collections.filter((item) => item)).reverse();
  }, [_collections]);

  const filteredData = useMemo(() => {
    if (!searchText) {
      return [];
    }
    return availableCollections.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          String(o[k]).toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [availableCollections, searchText]);

  return {
    collections: filteredData,
  };
};

export const useSelfServicePortalSearch = (_searchText: string) => {
  const [searchText] = useDebouncedValue(_searchText, 1000);

  // Contact Search Search
  const { state: _selfServicePortals } = useQueryWithStore(
    selectAllSelfServicePortals,
    loadAllSelfServicePortals,
    [],
    !searchText,
  );

  const availablePortals = useMemo(() => {
    return _.clone(_selfServicePortals.filter((item) => item)).reverse();
  }, [_selfServicePortals]);

  const filteredData = useMemo(() => {
    if (!searchText) {
      return [];
    }
    return availablePortals.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          String(o[k]).toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [availablePortals, searchText]);

  return {
    selfServicePortals: filteredData,
  };
};

export const useQuickActionsSearch = (_searchText: string) => {
  const [searchText] = useDebouncedValue(_searchText, 1000);

  // Contact Search Search
  const quickActions = useMemo(() => {
    return QuickActions;
  }, []);

  const filteredData = useMemo(() => {
    if (!searchText) {
      return [];
    }
    return quickActions.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === "string" &&
          String(o[k]).toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [quickActions, searchText]);

  return {
    quickActions: filteredData,
  };
};

export const useGlobalSearch = (_searchText: string) => {
  const { conversations, isLoading: isConversationLoading } =
    useConversationSearch(_searchText);

  const { contacts, isLoading: isContactsLoading } =
    useContactsSearch(_searchText);

  const { opportunities, isLoading: isOpportunitiesLoading } =
    useOpportunitiesSearch(_searchText);

  const { articles } = useCCHelpDeskSearch(_searchText);
  const { chatBots } = useChatBotSearch(_searchText);
  const { dynamicForms } = useFormsSearch(_searchText);
  const { campaigns } = useCampaignsSearch(_searchText);
  const { sequences } = useSequenceSearch(_searchText);
  const { contactLists } = useContactListsSearch(_searchText);
  const { collections } = useArticlesSearch(_searchText);
  const { selfServicePortals } = useSelfServicePortalSearch(_searchText);
  const { quickActions } = useQuickActionsSearch(_searchText);

  const results = useMemo(() => {
    return {
      conversations,
      isConversationLoading,
      contacts,
      isContactsLoading,
      opportunities,
      isOpportunitiesLoading,
      articles,
      chatBots,
      dynamicForms,
      campaigns,
      sequences,
      contactLists,
      collections,
      selfServicePortals,
      quickActions,
    };
  }, [
    conversations,
    isConversationLoading,
    contacts,
    isContactsLoading,
    opportunities,
    isOpportunitiesLoading,
    articles,
    chatBots,
    dynamicForms,
    campaigns,
    sequences,
    contactLists,
    collections,
    selfServicePortals,
    quickActions,
  ]);

  return results;
};
