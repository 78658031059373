import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { selectSubscriptionDetails } from "store/modules/workspace/workspace.selectors";

export const useAvailableAddOns = () => {
  const subscriptionDetails = useSelector(selectSubscriptionDetails);
  const allConnections = useSelector(selectAllConnections);

  const returnValue = useMemo(() => {
    const allAddOns = subscriptionDetails?.billing?.addOns || [];
    const waAddOns = allAddOns.filter((item) => item.addOnId === "WHATSAPP");
    const mobileAddOns = allAddOns.filter(
      (item) => item.addOnId === "MOBILE_NUMBER",
    );

    const WAConnections = allConnections.filter(
      (connection) =>
        connection.type === "WHATS_APP_OFFICIAL" ||
        connection.type === "WHATS_APP_PRIVATE",
    );
    const MobileConnections = allConnections.filter(
      (connection) => connection.type === "TELNYX",
    );

    return {
      allAddOns,
      availableWAAddons: waAddOns.length - WAConnections.length,
      availableMobileAddons: mobileAddOns.length - MobileConnections.length,
    };
  }, [allConnections, subscriptionDetails?.billing?.addOns]);

  return returnValue;
};
