import MailOutlined from "@ant-design/icons/MailOutlined";
import UserAddOutlined from "@ant-design/icons/UserAddOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import { SDK } from "@sdk";
import {
  getPermissionFromRole,
  getRoleFromPermission,
  Roles,
} from "@sdk/roles-and-permissions/roles-and-permissions";
import { processServerError, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Col,
  Collapse,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { FireHelpCenterTip } from "components/common/help-center/embeddable-components/help-center-notifications";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { StyledSwitch } from "components/common/styled-swtich";
import { BetaFeatures } from "components/pages/configurations/sections/general-settings/general-settings-home";
import { DarkModeBg } from "dark-mode-bg";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { LoadAllGroups } from "store/modules/groups/groups.helpers";
import { selectAllGroupsQuery } from "store/modules/groups/groups.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import {
  selectCurrentUser,
  selectUser,
} from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { uploadImageWithValidation } from "../../../../file-management/upload-image-with-validation";
import { debouncedEmailAvailabilityWithCurrentEmail } from "../../helpers/email-availability";
import { UploadAvatar } from "../upload-avatar/upload-avatar";
import "./edit-user-modal.scss";
const Panel = Collapse.Panel;

export const EditUserModal = ({
  visible,
  onChangeVisibility,
  userId,
  onEdited,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onEdited?: () => any;
  userId: string;
}) => {
  const [form] = useForm();

  const user = useSelector(selectUser(userId));
  const currentUser = useSelector(selectCurrentUser);

  const currentEmail = user?.credentials.email;

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        email: user.credentials.email,
        role: getRoleFromPermission(user.permissions),
        description: user.data.description,
        bio: user.data.bio,
        userGroups: user?.userGroups,
        isInternalUser: user?.data.isInternalUser,
        restrictNewChats: user?.metaData.disableChatJoining,
      });
    }
  }, [form, user]);

  // Edit Function
  const {
    doAction: onSubmit,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.editUser(userId, {
          data: {
            firstName: values.firstName,
            lastName: values.lastName,
            isInternalUser: values.isInternalUser,
            description: values.description,
            bio: values.bio,
          },
          credentials: {
            email: values.email,
          },
          userGroups: values?.userGroups,
          permissions: getPermissionFromRole(values.role),
          metaData: {
            disableChatJoining: values.restrictNewChats as boolean,
          },
        }).then((res) => {
          onEdited && onEdited();
          onChangeVisibility(false);
        }),
      successMessage: "User has been Edited",
      failureMessage: "Something went wrong",
    }),
    [onChangeVisibility, onEdited, userId],
  );

  const {
    state: { list: groups, isLoading },
    retry: reload,
  } = useQueryWithStore(selectAllGroupsQuery, LoadAllGroups);

  const [showResetPasswordInput, setShowResetPasswordInput] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  // const changeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files![0]!;
  //   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  //   if (!isJpgOrPng) {
  //     message.error("You can only upload JPG/PNG file!");
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isLt2M) {
  //     message.error("Image must smaller than 2MB!");
  //   }
  //   if (isJpgOrPng && isLt2M) {
  //     setSelectedFile(file);
  //     uploadAvatar(file);
  //   }
  // };
  const { doAction: uploadAvatar, isProcessing: isUploading } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (file) =>
          SDK.uploadFile(file, {
            type: "USER",
            entityId: user.id,
          })
            .then((fileRecord) => {
              setSelectedFile(undefined);
              return SDK.editUser(userId, {
                data: {
                  avatar: fileRecord.url,
                },
              });
            })
            .catch((e) => {
              setSelectedFile(undefined);
              throw e;
            }),
        successMessage: "Avatar has been updated",
        failureMessage: "Something went wrong",
      }),
      [user?.id, userId],
    );

  const { doAction: deActivateUser, isProcessing: isDeactivateProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.deactivateUser(user.id)
            .then((res) => {
              onEdited && onEdited();
              onChangeVisibility(false);
            })
            .then((d) => {
              FireHelpCenterTip({
                title: "Consider transferring deactivated user's chats 🤞",
                description:
                  "When you delete/deactivate a user, the chats are not automatically unassigned",
                articleId: "fc89e0-e5ffc",
                // User Management
                collectionId: "fc89e0-a99c5",
              });
            }),
        successMessage: "User has been deactivated",
        failureMessage: "Something went wrong",
      }),
      [onChangeVisibility, onEdited, user?.id],
    );

  const { doAction: deleteUser, isProcessing: isDeleteProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.deleteUser(user.id).then((res) => {
            onEdited && onEdited();
            onChangeVisibility(false);
          }),
        successMessage: "User has been deleted",
        failureMessage: "Something went wrong",
      }),
      [onChangeVisibility, onEdited, user?.id],
    );

  const organization = useSelector(selectOrganization);

  const { doAction: resetPassword, isProcessing: isResetPasswordProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (password: string) =>
          SDK.editUser(userId, {
            credentials: {
              password: password,
            },
          }).then((res) => {
            setShowResetPasswordInput(false);
          }),
        successMessage: "Password has been reset",
        failureMessage: "Something went wrong",
      }),
      [userId],
    );

  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  return (
    <>
      <Modal
        title={
          <div className="font-bold text-xl">
            <Space>
              <UserAddOutlined className="icon-fix" /> Edit User profile
            </Space>
          </div>
        }
        open={visible}
        onOk={async () => {
          try {
            await form.validateFields();
            onSubmit(form.getFieldsValue());
          } catch (e) {
            message.error("Please check your input");
          }
        }}
        onCancel={() => {
          onChangeVisibility(false);
        }}
        okText={
          <>
            <i className="ri-save-line"></i> Save
          </>
        }
        bodyStyle={{ padding: 0 }}
        data-click-context="Edit Users Modal"
      >
        {/* Todo */}

        {/* <div className="user-header flex flex-row justify-center items-center mb-12 mt-5">
        <div className="edit-user-avatar">
          {selectedFile && (
            <Spin
              spinning={isUploading}
              indicator={<LoadingIndicator />}
            >
              <Avatar size={80} src={URL.createObjectURL(selectedFile)} />
            </Spin>
          )}
          {!selectedFile && <UserAvatar userId={userId} size={80} />}
          <input
            type="file"
            onChange={(event) => {
              if (event.target.files !== null) {
                setSelectedFile(event.target.files![0]);
                changeAvatar(
                  userId,
                  event.target.files[0],
                  uploadAvatar,
                  setSelectedFile
                );
              }
            }}
          />
          <div className="upload-icon">
            <i className="ri-upload-2-line"></i>
          </div>
        </div>
      </div> */}
        <div style={{ height: "calc(80vh)" }}>
          <PerfectScrollbar>
            <UploadAvatar
              selectedFile={selectedFile}
              onChange={(file) => {
                setSelectedFile(file);
                uploadImageWithValidation(
                  userId,
                  file,
                  uploadAvatar,
                  setSelectedFile,
                );
              }}
              isUploading={isUploading}
              userId={userId}
            />

            <Form layout="vertical" form={form} style={{ padding: 24 }}>
              {/* Firs Name */}

              <Row justify="space-between">
                <Col span={11}>
                  {/* First Name */}

                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="First name"
                      size="large"
                    />
                  </Form.Item>
                </Col>

                {/* Last Name */}
                <Col span={11}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Last name"
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Email */}
              <Form.Item
                name="email"
                hasFeedback
                validateFirst={true}
                normalize={(value) => ((value || "") as string).toLowerCase()}
                rules={[
                  { type: "email", required: true },
                  ({ getFieldValue }) => ({
                    validator: async (rule, value) =>
                      await debouncedEmailAvailabilityWithCurrentEmail(
                        currentEmail,
                        value,
                        organization?.id,
                      ),
                  }),
                ]}
                label="Email"
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  size="large"
                />
              </Form.Item>

              {/* Role */}
              <Form.Item
                label="Role"
                name="role"
                rules={[{ required: true, message: "Please select a role" }]}
              >
                <Select size="large">
                  {Roles.map((role) => (
                    <Select.Option key={role.label} value={role.label}>
                      {role.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {/* Description */}
              <Form.Item name="description" label="Description" rules={[]}>
                <Input placeholder="Description " size="large" />
              </Form.Item>
              {/* Bio */}
              <Form.Item name="bio" label="Bio" rules={[]}>
                <Input.TextArea placeholder="Bio " size="large" />
              </Form.Item>
              {/* User Groups */}
              <Form.Item label="User Groups" name="userGroups" rules={[]}>
                <Select size="large" mode="tags">
                  {groups.map((group) => (
                    <Select.Option key={group.id} value={group.id}>
                      {group.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Collapse bordered={false} className="mt-5">
                <Panel header="More Options" key="1" className="border-none">
                  <div className="ml-4 mt-4 mb-2">
                    <StyledSwitch
                      name="isInternalUser"
                      label="Hide Profile in Chat Widget"
                    />
                  </div>
                  <div className="ml-4 mt-4 mb-2">
                    <StyledSwitch
                      name="restrictNewChats"
                      label="Temporarily Restrict New Chats"
                    />
                  </div>
                  <Space>
                    <Dropdown
                      trigger={["click"]}
                      overlay={
                        <Menu data-click-context="Edit User - Password Reset Menu">
                          <Menu.Item
                            onClick={() => {
                              const indicator = message.loading("Processing");
                              SDK.emailResetPasswordLink({
                                email: user.credentials.email,
                                domain: organization?.domain!,
                              })
                                .then((d) => {
                                  indicator();
                                  message.info(
                                    `Password Reset Link has been mailed to ${user.credentials.email}`,
                                  );
                                })
                                .catch((e) => {
                                  message.error(
                                    processServerError(
                                      e,
                                      "Something went wrong",
                                    ),
                                  );
                                });
                            }}
                          >
                            Email Reset Password Link
                          </Menu.Item>
                          {
                            <Menu.Item
                              onClick={() => {
                                setShowResetPasswordInput(true);
                              }}
                            >
                              Change Password Manually
                            </Menu.Item>
                          }
                        </Menu>
                      }
                    >
                      <Button type="link">
                        <i className="ri-restart-line"></i>Reset Password
                      </Button>
                    </Dropdown>
                    {user ? (
                      user.metaData.isAdmin || currentUser.id === user.id ? (
                        <Tooltip
                          placement="top"
                          title="You cannot deactivate your account"
                        >
                          <Button type="link" disabled>
                            <i className="ri-lock-line"></i>Deactivate User
                          </Button>
                        </Tooltip>
                      ) : (
                        <Popconfirm
                          title="Are you sure to deactivate this user?"
                          onConfirm={() => {
                            deActivateUser();
                          }}
                          okButtonProps={{ loading: isDeactivateProcessing }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            <i className="ri-lock-line"></i>Deactivate User
                          </Button>
                        </Popconfirm>
                      )
                    ) : (
                      ""
                    )}

                    {user ? (
                      user.metaData.isAdmin || currentUser.id === user.id ? (
                        <Tooltip
                          placement="top"
                          title="You cannot Delete your account"
                        >
                          <Button type="link" disabled>
                            <i className="ri-close-line"></i>
                            Delete User
                          </Button>
                        </Tooltip>
                      ) : (
                        <Popconfirm
                          title="Are you sure to delete this user permanently?"
                          onConfirm={() => {
                            deleteUser();
                          }}
                          okButtonProps={{ loading: isDeleteProcessing }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            <i className="ri-close-line"></i> Delete User
                          </Button>
                        </Popconfirm>
                      )
                    ) : (
                      ""
                    )}
                  </Space>
                </Panel>
              </Collapse>
              {showResetPasswordInput && (
                <Spin
                  spinning={isResetPasswordProcessing}
                  indicator={<LoadingIndicatorWithSpin />}
                >
                  <div
                    className="password-reset-box flex flex-row p-2 bg-gray-100  dark:bg-gray-700  rounded-md mt-4 pt-6 mode_transition"
                    style={{ border: "1px solid #e4e4e4" }}
                  >
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      hasFeedback
                      className="flex-1"
                    >
                      <Input.Password placeholder="Password" size="large" />
                    </Form.Item>

                    <Button
                      size="large"
                      style={{ borderLeft: 0, borderRadius: "0 5px 5px 0" }}
                      onClick={() => {
                        resetPassword(form.getFieldValue("password"));
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      size="large"
                      type="text"
                      onClick={() => setShowResetPasswordInput(false)}
                    >
                      <i className="ri-close-circle-line"></i>
                    </Button>
                  </div>
                </Spin>
              )}
            </Form>

            {isAdvancedMode && (
              <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-800 flex-col flex  px-10 py-2 max-w-screen-xl flex-1 ml-4 mt-4">
                <ConfigurationEditorComponent
                  icon="ri-gift-line"
                  title="Enable Beta Features"
                  description="Enable access to Insider features"
                  entityType="USER"
                  entityId={userId}
                >
                  * By default below features are not available to the user
                  {BetaFeatures.map((item, index) => {
                    return (
                      <StyledSwitch
                        label={item.label}
                        key={item.key}
                        name={["data", "betaFeatures", item.key]}
                      />
                    );
                  })}
                </ConfigurationEditorComponent>
              </div>
            )}
          </PerfectScrollbar>
        </div>
        <DarkModeBg />
      </Modal>
    </>
  );
};
