import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDK } from "@sdk";
import { Button, Card, Select } from "antd";
import { AddContact } from "modal-registry";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setContacts } from "store/modules/contacts/contacts.slice";
import { ContactSearchQueryBuilder } from "../../helpers/contact-search-query-builder";
import { ContactSelectorPill } from "../contact-selector-pill/contact-selector-pill";
import "./multi-contact-selector.scss";

const { Meta } = Card;

export const MultiContactSelector = ({
  value: selectedContacts,
  onChange: setSelectedContacts,
  placeholder = "Select Contacts",
}: {
  value?: string[];
  onChange?: (ids: string[]) => any;
  placeholder?: string;
}) => {
  const [showAddContactForm, setShowAddContactForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const { data: originalData, isLoading } = useSDK(
    (SDK) =>
      SDK.queryContacts({
        query: ContactSearchQueryBuilder(searchQuery),
        options: {
          offset: 0,
          page: 1,
          limit: 20,
        },
      }).then((results) => {
        dispatch(setContacts(results.docs));
        return results;
      }),
    [searchQuery],
    false,
    {}
  );

  const transformIdToNode = (ids?: string[]) => {
    return (ids || []).map((contactId) => {
      return {
        key: contactId,
        value: contactId,
        label: <ContactSelectorPill contactId={contactId} />,
      };
    });
  };

  const { triggerTempModal } = useModalPanels();

  return (
    <>
      <Select
        mode="multiple"
        labelInValue
        value={transformIdToNode(selectedContacts)}
        placeholder={placeholder}
        filterOption={false}
        onSearch={(val) => setSearchQuery(val)}
        onChange={(contactNodes, option) => {
          setSelectedContacts &&
            setSelectedContacts(contactNodes.map((item) => item.value));
        }}
        style={{ width: "100%" }}
        size="large"
      >
        {(originalData?.docs || []).map((contact) => (
          <Select.Option key={contact.id} value={contact.id!}>
            <ContactSelectorPill contactId={contact.id!} />
          </Select.Option>
        ))}
      </Select>
      <div className="flex-row flex justify-end items-center">
        <Button
          onClick={() => {
            triggerTempModal(AddContact, {
              isMinimal: true,
              hideOnCreate: true,
              onCreated: (contact) => {
                setTimeout(() => {
                  setSelectedContacts &&
                    setSelectedContacts([
                      ...(selectedContacts || []),
                      contact.id!,
                    ]);
                }, 200);
              },
            });
          }}
          icon={<PlusOutlined />}
          type="link"
        >
          New Contact
        </Button>
      </div>
    </>
  );
};
