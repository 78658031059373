import { Button, Popover } from "antd";

import "emoji-mart/css/emoji-mart.css";
import React, { memo, Suspense, useState } from "react";
import { SuspenseLoadingIndicator } from "../loading-indicator/loading-indicator";

const Picker = React.lazy(() => import("./emoji-picker-lib"));

export const EmojiPicker = memo(
  ({
    onEmojiPicked,
    type
  }: {
    onEmojiPicked: (text: string) => any;
    type?:
      | "default"
      | "link"
      | "text"
      | "dashed"
      | "ghost"
      | "primary"
      | undefined;
  }) => {
    const [popoverVisibility, setPopoverVisibility] = useState(false);
    return (
      <Popover
        content={
          <Suspense fallback={<SuspenseLoadingIndicator />}>
            <Picker
              onSelect={emoji => {
                onEmojiPicked(emoji.native);
                setPopoverVisibility(false);
              }}
            />
          </Suspense>
        }
        trigger="click"
        open={popoverVisibility}
        onOpenChange={setPopoverVisibility}
      >
        <Button
          type={type || "link"}
          icon={<i className="ri-emotion-happy-line"></i>}
        />
      </Popover>
    );
  }
);
