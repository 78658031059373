import { SDK } from "@sdk";

import { iHolidayQuote } from "@sdk/holiday-quotes/holiday-quotes-model";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { HolidayQuotesSlice } from "./holiday-quotes.slice";

export const {
  loadEntityQueries: loadHolidayQuotesQuery,
  loadEntityById: loadHolidayQuoteById,
  loadMoreEntityQueries: loadMoreHolidayQuotesQueries
} = CreateHelpersForEntity<iStore, iHolidayQuote>({
  sliceName: "holidayQuotes",
  slice: HolidayQuotesSlice,
  isPaginatedQueries: true,
  fetchPageSize: 50,
  loadEntityQueryProvider: query => SDK.holidayQuotes.query(query),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.holidayQuotes.getById(entityId)
});
