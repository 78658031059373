import { iFilterConfigSchema } from "components/common/filter-schema/filter-config-schema";
import { ConnectionPill } from "components/modules/connections/connection-pill/connection-pill";
import { ConnectionTypeLabelMap } from "components/modules/connections/helpers/connection-type-labels";
import { ContactPill } from "components/modules/crm/contacts/components/contact-pill/contact-pill";
import { UserPill } from "components/modules/user-management/users/components/user-pill/user-pill";
import { durationOptions } from "components/pages/reports/helpers/chat-duration-options";
import { messageCountOptions } from "components/pages/reports/helpers/chat-message-count-options";
import { chatRatingOptions } from "components/pages/reports/helpers/chat-rating-options";
import { generateIntervalOptions } from "components/pages/reports/helpers/generate-interval-options";
import dayjs from "dayjs";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import {
  selectAllGroups,
  selectGroupById,
} from "store/modules/groups/groups.selectors";
import { selectUsersByUserGroup } from "store/modules/users/users.selectors";
import {
  selectFirstResponseIntervalConfig,
  selectPipelines,
} from "store/modules/workspace/workspace.selectors";

export const ChatFilterSchemaNew: iFilterConfigSchema = {
  filters: [
    // Date Filter
    {
      label: "Filter by Period",
      icon: "ri-calendar-line",
      key: "period",
      fieldOptions: {
        operators: [
          {
            id: "STARTED_IS_BETWEEN",
            label: "is started between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              "metaData.createdAt": {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
          {
            id: "CREATED_IS_BETWEEN",
            label: "is created between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              "metaData.objectCreatedAt": {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
          {
            id: "CLOSED_BETWEEN",
            label: "is closed between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              "metaData.closedAt": {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
          {
            id: "LAST_MESSAGE",
            label: "has last message between",
            queryGenerator: ({
              value: [startDate, endDate] = [
                dayjs()
                  .subtract(7, "d")
                  .valueOf(),
                dayjs().valueOf(),
              ],
            }: {
              value?: [number, number];
            }) => ({
              "metaData.lastMessage.timestamp": {
                $gte: startDate,
                $lt: endDate,
              },
            }),
            valueField: {
              type: "DATE_RANGE",
              config: {},
            },
            viewRenderer: ([startDate, endDate]: [number, number]) => (
              <span>
                {dayjs(startDate).format("DD-MM-YYYY")} and{" "}
                {dayjs(endDate).format("DD-MM-YYYY")}
              </span>
            ),
          },
        ],
        defaultOperator: "STARTED_IS_BETWEEN",
      },
    },
    // User
    {
      label: "Member",
      icon: "ri-user-fill",
      key: "users",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value: userId }) => ({
              members: {
                $elemMatch: {
                  userId: { $in: [userId] },
                },
              },
            }),
            valueField: {
              type: "USER_SELECTOR",
            },
            viewRenderer: (userId: string) => (
              <UserPill userId={userId} withoutBg={true} />
            ),
          },
        ],
        defaultOperator: "IS",
      },
    },
    // Tags
    {
      label: "Tags",
      icon: "ri-price-tag-3-fill",
      key: "tags",
      fieldOptions: {
        operators: [
          {
            id: "IS_ONE_OF",
            label: "includes one of :",
            queryGenerator: ({ value: tags }) => ({
              tags: { $in: tags },
            }),
            valueField: {
              type: "CONVERSATION_TAG_SELECTOR",
            },
          },
          {
            id: "IS_ALL_OF",
            label: "includes all of",
            queryGenerator: ({ value: tags }) => ({
              tags: tags,
            }),
            valueField: {
              type: "CONVERSATION_TAG_SELECTOR",
            },
          },
          {
            id: "IS_EMPTY",
            label: "is Empty",
            queryGenerator: () => ({
              tags: { $size: 0 },
            }),
          },
        ],
        defaultOperator: "IS_ONE_OF",
      },
    },
    // Connection
    {
      label: "Connection",
      icon: "ri-wireless-charging-line",
      key: "connectionId",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            valueField: {
              type: "CONNECTION_SELECTOR",
            },
            viewRenderer: (connectionId: string) => (
              <ConnectionPill connectionId={connectionId} withoutBg={true} />
            ),
          },
        ],
        defaultOperator: "IS",
      },
    },
    // Requires Attention
    {
      label: "Requires Attention",
      icon: "ri-flag-line",
      key: "requiresAttention",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "Requires Attention",
            queryGenerator: ({ value }) => ({
              "metaData.flags.requiresAttention.isActive":
                value === "YES" ? true : { $ne: true },
            }),
            valueField: {
              type: "SELECT",
              config: {
                placeholder: "Select an option",
                options: [
                  {
                    value: "YES",
                    label: "is on",
                  },
                  {
                    value: "NO",
                    label: "is off",
                  },
                ],
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    // Requires Followup
    {
      label: "Requires Followup",
      icon: "ri-flag-line",
      key: "requiresFollowup",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "Requires Followup",
            queryGenerator: ({ value }) => ({
              "metaData.flags.requiresFollowup.isActive":
                value === "YES" ? true : { $ne: true },
            }),
            valueField: {
              type: "SELECT",
              config: {
                placeholder: "Select an option",
                options: [
                  {
                    value: "YES",
                    label: "is on",
                  },
                  {
                    value: "NO",
                    label: "is off",
                  },
                ],
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    // User Groups
    {
      label: "User Groups",
      icon: "ri-group-line",
      key: "userGroup",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            valueField: {
              type: "SELECT",
              config: {
                optionGenerator: (state) => {
                  const userGroups = selectAllGroups(state);
                  return _.uniqBy(
                    userGroups.map((userGroup) => ({
                      value: userGroup.id,
                      label: userGroup.label,
                    })),
                    "value"
                  );
                },
              },
            },
            queryGenerator: ({ value: userGroupId }, state) => {
              const users = selectUsersByUserGroup(state)[userGroupId];
              return {
                "members.userId": users.map((user) => user.id),
              };
            },
            viewRenderer: (userGroupId: string) => {
              const UserGroupRenderer = ({ userGroupId }) => {
                const userGroup = useSelector(selectGroupById(userGroupId));
                return <>{userGroup?.label}</>;
              };
              return <UserGroupRenderer userGroupId={userGroupId} />;
            },
          },
        ],

        defaultOperator: "IS",
      },
    },
    // Contact
    {
      label: "Customer",
      icon: "ri-user-fill",
      key: "contact",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value }) => ({
              contactId: value,
            }),
            valueField: {
              type: "CONTACT_SELECTOR",
            },
            viewRenderer: (contactId: string) => (
              <ContactPill contactId={contactId} withoutBg={true} />
            ),
          },
        ],
        defaultOperator: "IS",
      },
    },
    // Connection Type
    {
      label: "Connection Type",
      icon: "ri-sim-card-fill",
      key: "connectionType",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            valueField: {
              type: "SELECT",
              config: {
                optionGenerator: (state) => {
                  const connections = selectAllConnections(state);
                  return _.uniqBy(
                    connections.map((connection) => ({
                      value: connection.type,
                      label: ConnectionTypeLabelMap[connection.type],
                    })),
                    "value"
                  );
                },
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    // Status
    {
      label: "Status",
      icon: "ri-archive-line",
      key: "status",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            valueField: {
              type: "SELECT",
              config: {
                options: [
                  { value: "OPEN", label: "Open" },
                  { value: "CLOSED", label: "Closed" },
                ],
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    // Pipelines
    {
      // Pipelines
      label: "Pipeline",
      icon: "ri-flag-line",
      key: "pipeline",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is part of",
            queryGenerator: ({ value }) => ({
              "pipeline.id": value,
            }),
            valueField: {
              type: "SELECT",
              config: {
                optionGenerator: (state) => {
                  const pipelines = selectPipelines(state);
                  return _.uniqBy(
                    pipelines.map((pipeline) => ({
                      value: pipeline.id,
                      label: pipeline.label,
                    })),
                    "value"
                  );
                },
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    // Subject Line
    {
      label: "Subject",
      icon: "ri-message-2-line",
      key: "subject",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            valueField: {
              type: "INPUT",
              config: {
                placeholder: "Filter by subject line",
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    // Notes
    // {
    //     label: "Notes",
    //     icon: "ri-sticky-note-2-line",
    //     key: "notes",
    //     fieldOptions: {
    //       operators: [
    //         {
    //           id: "IS",
    //           label: "is",
    //           valueField: {
    //             type: "INPUT",
    //             config: {
    //               placeholder: "Enter Notes",
    //             },
    //           },
    //         },
    //       ],
    //       defaultOperator: "IS",
    //     },
    //   },
    // Facebook Ad ID
    {
      label: "Facebook Ad ID",
      icon: "ri-facebook-box-line",
      key: "fbAdId",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value }) => ({
              "references.value": value,
            }),
            valueField: {
              type: "INPUT",
              config: {
                placeholder: "Enter Facebook Ad Id",
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    // Facebook Post Id
    {
      label: "Facebook Post Id",
      icon: "ri-facebook-box-line",
      key: "fbPostId",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value }) => ({
              "references.value": value,
            }),
            valueField: {
              type: "INPUT",
              config: {
                placeholder: "Enter Facebook Post Id",
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    // Source
    {
      label: "Source",
      icon: "ri-arrow-right-line",
      key: "source",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value }) => ({
              "metaData.initSource": value,
            }),
            valueField: {
              type: "INPUT",
              config: {
                placeholder: "Select a conversation Source",
              },
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    // First Response Time
    {
      label: "First Response Time",
      icon: "ri-timer-flash-line",
      key: "responseTime",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value }) => ({
              "metaData.firstResponseTime": JSON.parse(value),
            }),
            valueField: {
              type: "SELECT",
              config: {
                placeholder: "Select a First Response Time Period",
                optionGenerator: (state) => {
                  return generateIntervalOptions(
                    selectFirstResponseIntervalConfig(state)
                  ).map((item) => ({
                    label: item.title,
                    value: item.value,
                  }));
                },
              },
            },
            viewRenderer: (value: string) => {
              const ResponseOptionLabelRenderer = ({ value }) => {
                const store = useStore();

                const optionLabel = useMemo(() => {
                  const options = generateIntervalOptions(
                    selectFirstResponseIntervalConfig(store.getState())
                  );
                  const selectedOption = _.find(options, { value });
                  return selectedOption?.title;
                }, [store, value]);
                return <>{optionLabel}</>;
              };
              return <ResponseOptionLabelRenderer value={value} />;
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    // Duration
    {
      label: "Duration",
      icon: "ri-timer-line",
      key: "duration",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value }) => ({
              "metaData.duration": JSON.parse(value),
            }),
            valueField: {
              type: "SELECT",
              config: {
                placeholder: "Select a conversation Duration",
                optionGenerator: (state) => {
                  return durationOptions.map((item) => ({
                    label: item.title,
                    value: item.value,
                  }));
                },
              },
            },
            viewRenderer: (value: string) => {
              return <>{_.find(durationOptions, { value })?.title}</>;
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    // Message Count
    {
      label: "Message Count",
      icon: "ri-list-ordered",
      key: "messageCount",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value }) => ({
              "metaData.totalMessages": JSON.parse(value),
            }),
            valueField: {
              type: "SELECT",
              config: {
                placeholder: "Select a conversation Message Count range",
                optionGenerator: (state) => {
                  return messageCountOptions.map((item) => ({
                    label: item.title,
                    value: item.value,
                  }));
                },
              },
            },
            viewRenderer: (value: string) => {
              return <>{_.find(messageCountOptions, { value })?.title}</>;
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    // Conversation Rating
    {
      label: "Conversation Rating",
      icon: "ri-emotion-happy-line",
      key: "chatRating",
      fieldOptions: {
        operators: [
          {
            id: "IS",
            label: "is",
            queryGenerator: ({ value }) => ({
              "chatRating.rating": Number(value),
            }),
            valueField: {
              type: "SELECT",
              config: {
                placeholder: "Select a conversation Rating",
                optionGenerator: (state) => {
                  return chatRatingOptions.map((item) => ({
                    label: item.title,
                    // Todo
                    value: item.value as any,
                  }));
                },
              },
            },
            viewRenderer: (value: string) => {
              return (
                <>{_.find(chatRatingOptions, { value: value as any })?.title}</>
              );
            },
          },
        ],
        defaultOperator: "IS",
      },
    },
    // Includes Text
    // {
    //   label: "Includes Text",
    //   icon: "ri-chat-quote-line",
    //   key: "messageText",
    //   fieldOptions: {
    //     operators: [
    //       {
    //         id: "IS",
    //         label: "has text",
    //         valueField: {
    //           type: "INPUT",
    //           config: {
    //             placeholder: "Input in any text",
    //           },
    //         },
    //       },
    //     ],
    //     defaultOperator: "IS",
    //   },
    // },
  ],
};
