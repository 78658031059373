import SearchOutlined from "@ant-design/icons/SearchOutlined";
import _ from "lodash";
import { useMemo } from "react";
import { defaultWidgetPreviewData } from "../default-widget-data";
import { ChatWidgetFAQsPAGE } from "./chat-widget-faqs-page";

export const ChatWidgetFAQSection = ({
  data,
}: {
  data: typeof defaultWidgetPreviewData.FAQ;
}) => {
  const { faqs } = useMemo(() => {
    const faqCollections = data?.COLLECTIONS;

    const faqs = _.flatten(
      (faqCollections || []).map((collection) => collection?.faqList || [])
    );
    return { faqs };
  }, [data.COLLECTIONS]);

  return (
    <div>
      <div className="py-6 animate fade-in-right">
        <div className="title-section flex flex-row justify-between items-center px-4 mb-4">
          <div className="font-semibold text-lg text-gray-800">
            {data.FAQ_TITLE}
          </div>
          <div className="text-gray-600 text-xs  cursor-pointer">
            {data.SEE_ALL_TEXT}
          </div>
        </div>
        <div className="search-bar flex flex-row justify-between cursor-pointer px-6">
          <input
            placeholder="Search FAQs"
            className="flex-1 border-b border-solid border-gray-200 pb-2 cursor-pointer bg-white outline-none"
            disabled
          />
          <SearchOutlined style={{ fontSize: "2rem" }} className="mr-2" />
        </div>
        <div className="faq-list-container mt-4">
          <ChatWidgetFAQsPAGE categoryTitle="" questions={faqs} />
        </div>
        <div className="browse-all-button p-4">
          <div className="secondary-button flex items-center justify-center font-semibold rounded-lg cursor-pointer p-4 primary-font">
            <div className="flex flex-row items-center">
              <div className="icon-container mr-2">
                <SearchOutlined style={{ fontSize: "2rem" }} />
              </div>
              {data.BROWSE_ALL_BUTTON}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
