import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { Input, Tooltip } from "antd";
import classNames from "classnames";
import { AddFilterButton } from "components/common/filter-schema/filter-bar";
import { iFilterOption } from "components/common/filter-schema/filter-config-schema";
import { CCPopover } from "components/common/popover/popover";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { selectFeatureRestrictionConfig } from "store/modules/workspace/workspace.selectors";
import { useURLData } from "utils/hooks/use-url-filter";
import { ChatFilterSchemaNew } from "../chat-list/chat-filter-schema-new";
import "./chat-search-input.scss";

export const ChatSearchInput = ({
  selectedInbox,
  searchText,
  onSearchTextChange,
  // onChangeFilter,
  // filter,
  loading
}: {
  selectedInbox: string;
  searchText: string;
  onSearchTextChange: (text: string) => any;
  // onChangeFilter: (filter: any) => any;
  // filter: any;
  loading?: boolean;
}) => {
  const [filterVisibility, setFilterVisibility] = useState(false);

  const [selectedFilters, setSelectedFilters] = useURLData(
    "chat-filter",
    [] as iFilterOption[]
  );

  const existingFilters = useMemo(() => selectedFilters.map(item => item.key), [
    selectedFilters
  ]);

  const currentUser = useSelector(selectCurrentUser);
  const userRole = useMemo(() => {
    const currentUserRole = getRoleFromPermission(
      currentUser?.permissions || []
    );
    return currentUserRole;
  }, [currentUser?.permissions]);

  const featureRestrictionConfig = useSelector(selectFeatureRestrictionConfig);

  return (
    <div className="flex flex-row justify-between items-center chat-search-input search-with-filter z-20 relative">
      <Input.Search
        placeholder={`Search ${selectedInbox}`}
        onSearch={value => console.log(value)}
        // style={{ width: 290 }}
        value={searchText}
        onChange={e => onSearchTextChange(e.target.value)}
        // disabled
        // addonAfter={}
        loading={loading}
      />
      {!featureRestrictionConfig?.["CHAT_FILTERING"]?.[userRole] && (
        <>
          <CCPopover
            content={({ closePopover }) => (
              <AddFilterButton
                onAddFilter={filter => {
                  const existingFilters = selectedFilters;
                  console.log("existingFilters", existingFilters);
                  setSelectedFilters([...existingFilters, filter]);
                  closePopover();
                }}
                existingFilters={existingFilters}
                schema={ChatFilterSchemaNew}
                breakByPriority={true}
              />
            )}
            trigger="click"
            open={filterVisibility}
            onOpenChange={setFilterVisibility}
            // overlayClassName="styled-popover"
          >
            <div
              className={classNames(
                "cursor-pointer filter-button hover:text-blue-600 flex flex-row justify-center items-center",
                {
                  "text-blue-500": selectedFilters.length > 0
                }
              )}
            >
              <i className="ri-filter-fill"></i>
            </div>
          </CCPopover>
        </>
      )}
      {featureRestrictionConfig?.["CHAT_FILTERING"]?.[userRole] && (
        <Tooltip title="Chat Filtering has been disabled by account owner">
          <div
            className={classNames(
              "filter-button hover:text-blue-600 flex flex-row justify-center items-center cursor-not-allowed",
              {
                "text-blue-500": selectedFilters.length > 0
              }
            )}
          >
            <i className="ri-filter-fill"></i>
          </div>
        </Tooltip>
      )}

      {/* <Popover
        content={
          <ChatFilterForm
            onFilter={(filter) => {
              onChangeFilter(filter);
              setFilterVisibility(false);
              // On Filter
            }}
          />
        }
        trigger="click"
        open={filterVisibility}
        onOpenChange={setFilterVisibility}
      >
        <div
          className={classNames(
            "cursor-pointer filter-button hover:text-blue-600 flex flex-row justify-center items-center",
            {
              "text-blue-500": !!filter,
            }
          )}
        >
          <i className="ri-filter-fill"></i>
        </div>
      </Popover> */}
    </div>
  );
};
