import { useSDK } from "@sdk";
import { Button, Divider, Space, message } from "antd";
import { BuildViewQuery } from "components/common/filter-schema/build-view-config";
import { FilterBar } from "components/common/filter-schema/filter-bar";
import { iFilterOption } from "components/common/filter-schema/filter-config-schema";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import dayjs from "dayjs";
import _ from "lodash";

import { useCallback, useMemo, useRef } from "react";
import { useStore } from "react-redux";
import { DefaultConversationQueryConfig } from "store/modules/ui-state/ui-state.slice";
import { exportCSVFile } from "utils/export-as-csv";
import { useURLData } from "utils/hooks/use-url-filter";
import { GenerateQueryFromReportFilter } from "../../helpers/generate-query-from-report-filter";
import { ConversationsReportsBackendSchema } from "../../schemas/backend-report-schemas/conversation-report-backend-schema";

import { iConversation } from "@sdk/conversations/conversations.models";
import { ReportsFilterSchema } from "../../schemas/report-filter-schemas";
import { ConversationsTableView } from "./conversations-table-view";

const filterSchema = ReportsFilterSchema;

export const TableReport = () => {
  const [_selectedFilters, _setSelectedFilters] = useURLData(
    "report-filter",
    // reportCanvas.filters as iFilterOption[],
    // Removing the saved filters
    [] as iFilterOption[]
  );

  const selectedFilters = useMemo(() => {
    const dateFilter = _selectedFilters.find(item => item.key === "period");
    if (!dateFilter) {
      return [..._selectedFilters, filterSchema.generateDefaultFilter!()];
    }
    return _selectedFilters;
  }, [_selectedFilters]);

  const setSelectedFilters = useCallback(
    (_filters: iFilterOption[]) => {
      const filters = [..._filters];
      const dateFilter = filters.find(item => item.key === "period");
      if (!dateFilter) {
        message.warning("Reports require date filter");
        return;
      }
      _setSelectedFilters(filters);
    },
    [_setSelectedFilters]
  );

  const store = useStore();

  const query = useMemo(() => {
    const filterSpec = (selectedFilters || []).filter(
      item => item.key !== "period"
    );

    const dateFilter = (() => {
      let dateField = "createdTime";
      const periodFilterSpec = (selectedFilters || []).find(
        item => item.key === "period"
      );

      const dateSpec = (periodFilterSpec?.operatorConfig.value as number[]) || [
        dayjs()
          .subtract(7, "day")
          .valueOf(),
        dayjs().valueOf()
      ];
      if (periodFilterSpec) {
        const periodFilterSchema = _.find(filterSchema.filters, {
          key: periodFilterSpec.key
        });
        const operatorConfig = _.find(
          periodFilterSchema?.fieldOptions?.operators,
          {
            id: periodFilterSpec.operator
          }
        );
        const periodQueryObj = operatorConfig?.queryGenerator?.(
          dateSpec,
          store.getState()
        );
        if (periodQueryObj) {
          // There will be only one field
          dateField = Object.keys(periodQueryObj)[0];
        }
      }

      return {
        dateField: dateField,
        startDate:
          dateSpec && typeof dateSpec[0] === "string"
            ? dayjs(dateSpec[0]).valueOf()
            : dateSpec[0].valueOf(),
        endDate:
          dateSpec && typeof dateSpec[1] === "string"
            ? dayjs(dateSpec[1]).valueOf()
            : dateSpec[1].valueOf()
      };
    })();

    const reportFilter = BuildViewQuery({
      selectedFilters: [...filterSpec],
      defaultQuery: {} as any,
      schema: filterSchema,
      store: store
    }).query;

    const filter = GenerateQueryFromReportFilter(
      reportFilter,
      ConversationsReportsBackendSchema,
      true
    );

    filter[
      ConversationsReportsBackendSchema.dateFilterTypes[dateFilter.dateField]
    ] = {
      $gte: dayjs(dateFilter.startDate).valueOf(),
      $lt: dayjs(dateFilter.endDate).valueOf()
    };

    return {
      ...DefaultConversationQueryConfig,
      query: {
        ...filter
      },
      options: {
        ...DefaultConversationQueryConfig.options,
        limit: 1000
        // sortBy: "-metaData.lastMessage.timestamp",
      }
    };
  }, [selectedFilters, store]);

  const { data: conversationsRes, setData, reload, isLoading } = useSDK(
    SDK => SDK.queryConversations(query),
    [query],
    false,
    {}
  );

  const onEdited = useCallback(
    (conversation: iConversation) => {
      setData(res => ({
        ...res,
        docs: res.docs.map(item => {
          if (item.id === conversation.id) {
            return conversation;
          }
          return item;
        })
      }));
    },
    [setData]
  );

  // Sync Event is only Emitted if conversation is watched
  // useEffect(() => {
  //   const listener = (data: iSyncEvent) => {
  //     console.log("data", data);
  //     if (data.type === "UPDATE" && data.entityType === "CONVERSATION") {
  //       const edits = { ...data.data, id: data.entityId };
  //       console.log("edits", edits);
  //       let edited = false;
  //       const newList = conversationsRes.docs.filter((item) => {
  //         if (item.id === edits.id) {
  //           const editedItem = { ...item };
  //           DeepAssign(editedItem, edits);
  //           edited = true;
  //           return editedItem;
  //         }
  //         return item;
  //       });
  //       if (edited) {
  //         setData({ ...conversationsRes, docs: newList });
  //       }
  //     }
  //   };
  //   SocketConnector.socket?.on("SYNC", listener);
  //   const timer = setTimeout(() => {
  //     SocketConnector.socket.off("SYNC", listener);
  //   }, 10000);
  //   return () => {
  //     SocketConnector.socket?.off("SYNC", listener);
  //     clearTimeout(timer);
  //   };
  // }, [conversationsRes, setData]);

  const tableViewRef = useRef<any>();

  return (
    <StyledScrollArea className="w-full h-full flex-1 animated fadeInLeftMin">
      <SimpleCardSection className="m-auto p-8 lg:p-12 my-4">
        <div className="p-10">
          <SectionHeader
            title={"Table Report Generator"}
            icon={<i className="ri-table-line"></i>}
            addons={
              <>
                <Space>
                  {(conversationsRes?.docs || []).length > 0 && (
                    <Button
                      type="text"
                      onClick={() => {
                        const tableData = tableViewRef.current?.tableData;
                        exportCSVFile(tableData, "table-report-export");
                      }}
                      icon={<i className="ri-download-2-line"></i>}
                    >
                      Export as CSV
                    </Button>
                  )}
                  <Button
                    type="text"
                    onClick={() => {
                      reload();
                    }}
                    icon={<i className="ri-refresh-line"></i>}
                    loading={isLoading}
                  >
                    Reload Report
                  </Button>
                </Space>
              </>
            }
          />
          <Divider className="m-0" />
          <div className="filters">
            <FilterBar
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              schema={filterSchema}
            ></FilterBar>
          </div>
          <Divider />

          <div className="table-input">
            <ConversationsTableView
              data={conversationsRes?.docs || []}
              ref={tableViewRef}
              onEdited={onEdited}
            />
          </div>
        </div>
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
