import { LazyJSONViewer } from "@libs/lazy-json-viewer/lazy-json-viewer";
import { iMessage } from "@sdk/conversations/conversations.models";
import { Button, Collapse, Dropdown, Menu, Modal, Space } from "antd";
import { UserAvatar } from "components/common/avatar/avatar";
import { EmailAvatar } from "components/common/avatar/client-avatar";
import { ModalTitle } from "components/common/modal-title";
import { getContactName } from "components/modules/crm/contacts/helpers/get-contact-name";
import { PrintUserNameById } from "components/modules/user-management/helpers/get-user-name-by-id";
import dayjs from "dayjs";
import { sanitize } from "lettersanitizer";
import linkifyHtml from "linkify-html";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import ReactIframeResizer from "react-iframe-resizer-super";
import { useStore } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectConversationProperties } from "store/modules/conversations/conversations.selectors";
import { useDoubleSelectorWithMemoize } from "store/utils/use-double-selector-with-memoize";
import { useSelectorWithMemoize } from "store/utils/use-selector-with-memoize";
import { parseEmailHeader } from "utils/parse-email-header";
import { textToHtml } from "utils/text-to-html";
import { PDFDrawer } from "./components/pdf-drawer";
import { IFrameResizerScriptString } from "./iframe-resizer-script-str";
import { MessageAttachments } from "./message-attachment";
import "./message-list.scss";

export const HtmlEmailRenderer = ({
  message,
  ignoreDarkMode,
  showFullEmail,
}: {
  message: iMessage;
  ignoreDarkMode?: boolean;
  showFullEmail?: boolean;
}) => {
  const [readFullEmail, setReadFullEmail] = useState(false);
  const html = useMemo(() => {
    const _html =
      readFullEmail || showFullEmail
        ? message?.data?.["body-html"] ||
          message?.data?.["stripped-html"] ||
          message?.data?.original ||
          message?.message
        : message?.data?.["stripped-html"] ||
          message.data?.original ||
          textToHtml(message?.message || "");

    return linkifyHtml(_html || "", {});
  }, [message.data, message?.message, readFullEmail, showFullEmail]);

  useEffect(() => {
    if (showFullEmail) {
      setTimeout(() => {
        setReadFullEmail(true);
      }, 100);
    }
  }, [showFullEmail]);

  const sanitizedHtml = sanitize(html, undefined);

  const isDarkMode =
    !ignoreDarkMode &&
    document.getElementsByTagName("html")[0].classList.contains("mode-dark");

  const isLargeScreen = useMediaQuery({ query: "(min-width: 1400px)" });

  const additionalStyle = useMemo(() => {
    if (isDarkMode) {
      return `
<style>
  body{
    margin: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: ${isLargeScreen ? 13 : 12}px;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: 'tnum', "tnum";
  }

  p{
    word-break: break-word;
    white-space: pre-line;
  }
</style>`;
    }

    return `
<style>
  body{
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: ${isLargeScreen ? 13 : 12}px;
    font-family: 'Open Sans', sans-serif;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: 'tnum', "tnum";
  }
  p{
    word-break: break-word;
    white-space: pre-line;
  }
</style>`;
  }, [isDarkMode, isLargeScreen]);
  return (
    <>
      <ReactIframeResizer
        iframeResizerEnable={true}
        content={`${IFrameResizerScriptString}${sanitizedHtml}${additionalStyle}`}
        id={message.id}
        iframeResizerUrl={false}
        iframeResizerOptions={{
          // log: true,
          autoResize: true,
          checkOrigin: false,
          heightCalculationMethod: "max",
          resizeFrom: "parent",
          // scrolling: "omit",
          // sizeHeight: true,
          // resizeFrom: "child",
        }}
      ></ReactIframeResizer>
      {/* <div
        className="break-all whitespace-normal"
        dangerouslySetInnerHTML={{
          __html: readFullEmail
            ? message?.data?.["body-html"] ||
              message?.data?.["stripped-html"] ||
              message?.data?.original ||
              message?.message
            : message?.data?.["stripped-html"] ||
              message.data?.original ||
              message?.message,
        }}
      /> */}
      {!readFullEmail && !showFullEmail && (
        <Button
          icon={<i className="ri-more-line"></i>}
          type="text"
          shape="round"
          onClick={() => setReadFullEmail(true)}
        />
      )}
    </>
  );
};

export const RichMessageList = memo(
  ({
    message,
    index,
    isLastMessage,
    forwardMessage,
    conversationId,
  }: {
    message: iMessage;
    index: number;
    isLastMessage: boolean;
    forwardMessage: (message: iMessage) => any;
    conversationId: string;
  }) => {
    const store = useStore();

    const { contactId, clientReadWaterMark } = useSelectorWithMemoize(
      () =>
        selectConversationProperties(conversationId, (conversation) => ({
          contactId: conversation?.contactId,
          clientReadWaterMark: conversation?.metaData?.readWatermarks?.client,
        })),
      [conversationId],
      true,
    );

    const contactNameLine = useDoubleSelectorWithMemoize(
      () => selectContactById(contactId!),
      [contactId],
      (contact) =>
        `${getContactName(contact)} (${contact?.data?.primaryEmail})`,
      false,
    );

    const viewOriginalEmail = useCallback((message: iMessage) => {
      // const win = window.open("", data.subject, "location=no");
      // if (win) {
      //   win.document.documentElement.innerHTML = data["stripped-html"];
      // }
      Modal.info({
        title: (
          <ModalTitle
            title={message?.data?.subject || "Original Email"}
            icon={<i className="ri-bill-line"></i>}
          />
        ),
        icon: false,
        content: (
          <div
            style={{ width: 750, height: 600, overflow: "auto" }}
            className=""
          >
            <HtmlEmailRenderer
              message={message}
              ignoreDarkMode={true}
              showFullEmail={true}
            />
          </div>
        ),
        okText: "Close",
        width: 800,
        className: "ant-temp-modal",
      });
    }, []);

    const viewEmailReceipt = useCallback((data) => {
      // console.log("data", data);
      // const win = window.open("", data.subject, "location=no");
      // if (win) {
      //   win.document.documentElement.innerHTML = JSON.stringify(data, null, 2);
      // }
      Modal.info({
        title: (
          <ModalTitle
            title={data?.subject || "Receipt"}
            icon={<i className="ri-bill-line"></i>}
          />
        ),
        icon: false,
        content: (
          <div
            style={{
              width: 750,
              height: 600,
              overflow: "auto",
              lineBreak: "anywhere",
            }}
          >
            <LazyJSONViewer src={data || {}} collapsed={false} />
          </div>
        ),
        okText: "Close",
        width: 800,
      });
    }, []);

    const attachments = useMemo(() => {
      return (message.attachments || []).filter(
        (attachment) =>
          attachment.type !== "QUICK_REPLIES" &&
          attachment.type !== "SELECTED_QUICK_REPLY",
      );
    }, [message]);

    const [PDFDrawerVisible, setPDFDrawerVisible] = useState(false);
    const [PDFFileUrl, setPDFFileUrl] = useState("");
    const showPDFDrawer = (url) => {
      setPDFFileUrl(url);
      setPDFDrawerVisible(true);
    };
    const onPDFDrawerClose = () => {
      setPDFFileUrl("");
      setPDFDrawerVisible(false);
    };

    const recipients = useMemo(() => {
      const body = message.data;
      if (!body) {
        return {
          ToMembers: [],
          CcMembers: [],
          BccMembers: [],
          ForwardedToMember: [],
          ReceivedUser: [],
        };
      }
      const ToMembers = body.to || (body.To ? parseEmailHeader(body.To) : []);
      const CcMembers = body.cc || (body.Cc ? parseEmailHeader(body.Cc) : []);
      const BccMembers =
        body.bcc || (body.Bcc ? parseEmailHeader(body.Bcc) : []);
      const ForwardedToMembers = body["X-Forwarded-To"]
        ? parseEmailHeader(body["X-Forwarded-To"])
        : [];
      const ReceivedUser = body.recipient
        ? parseEmailHeader(body.recipient)
        : [];
      return {
        ToMembers,
        CcMembers,
        BccMembers,
        ForwardedToMembers,
        ReceivedUser,
      };
    }, [message]);

    return (
      <div style={{ marginLeft: -25 }} className="rich-message-item">
        <PDFDrawer
          PDFDrawerVisible={PDFDrawerVisible}
          onPDFDrawerClose={onPDFDrawerClose}
          PDFFileUrl={PDFFileUrl}
        />
        <Collapse
          className="border-0 w-full"
          defaultActiveKey={isLastMessage ? ["1stMessage"] : []}
          accordion
        >
          <Collapse.Panel
            header={
              <div className="flex flex-row w-full items-center">
                <div className="icon">
                  {message.from.senderType === "CLIENT" &&
                    message.data?.from && (
                      <EmailAvatar emailString={message.data.from!} />
                    )}
                  {message.from.senderType === "USER" && (
                    <UserAvatar userId={message.from.id} />
                  )}
                </div>
                <div className="texts flex-col pl-4 flex-grow flex-1">
                  <div className="header font-bold">
                    {message.from.senderType === "CLIENT" &&
                      (message.data?.from || contactNameLine)}

                    {message.from.senderType === "USER" && (
                      <PrintUserNameById userId={message.from.id} />
                    )}
                  </div>
                  <div className="header text-gray-600 dark:text-gray-400 mode_transition">
                    To:{" "}
                    {recipients.ToMembers.map((item) => item.email).join(", ")}
                  </div>
                  {recipients.CcMembers.length > 0 && (
                    <div className="header text-gray-600 dark:text-gray-400 mode_transition">
                      CC:{" "}
                      {recipients.CcMembers.map(
                        (item) => `${item.name} (${item.email})`,
                      )}
                    </div>
                  )}
                  {recipients.ReceivedUser.length > 0 && (
                    <div className="header text-gray-600 dark:text-gray-400 mode_transition">
                      Through:{" "}
                      {recipients.ReceivedUser.map((item) => `${item.email}`)}
                    </div>
                  )}
                </div>
                <div className="date text-gray-500 text-sm my-4">
                  {dayjs(message.metaData.sentAt).format("LL h:mm:ss a ")}
                </div>
                <Space>
                  <div className="indicators pl-4">
                    <div className="time secondary-text mat-caption">
                      {message.from.senderType === "USER" &&
                        (clientReadWaterMark || 0) >=
                          message.metaData.sentAt && (
                          <i className="ri-check-double-line mr-2 text-lg read-indicator"></i>
                        )}
                      {message.from.senderType === "USER" &&
                        (clientReadWaterMark || 0) <
                          message.metaData.sentAt && (
                          <i className="ri-check-line mr-2 text-lg read-indicator"></i>
                        )}
                    </div>
                  </div>
                  <div
                    className="more icons"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Dropdown
                      trigger={["click"]}
                      overlay={
                        <Menu data-click-context="Bulk Chat Action Menu">
                          <Menu.Item
                            key="FORWARD"
                            icon={<i className="ri-chat-forward-line"></i>}
                            onClick={() => forwardMessage(message)}
                          >
                            Forward Message
                          </Menu.Item>
                          <Menu.Item
                            key="VIEW_ORIGINAL"
                            icon={<i className="ri-chat-forward-line"></i>}
                            onClick={() => viewOriginalEmail(message)}
                          >
                            View Original
                          </Menu.Item>
                          <Menu.Item
                            key="VIEW_RECEIPT"
                            icon={<i className="ri-user-add-line"></i>}
                            onClick={() =>
                              viewEmailReceipt((message as any)?.data)
                            }
                          >
                            View Receipt
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Button
                        shape="circle"
                        type="text"
                        icon={<i className="ri-more-2-line"></i>}
                      ></Button>
                    </Dropdown>
                  </div>
                </Space>
              </div>
            }
            key="1stMessage"
            showArrow={false}
          >
            <div className="message-content">
              <HtmlEmailRenderer message={message} />
            </div>

            {attachments && attachments.length > 0 && (
              <div className="attachments-cont">
                <MessageAttachments
                  attachments={message.attachments}
                  showPDFDrawer={showPDFDrawer}
                />
              </div>
            )}
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  },
);
