import { processServerError, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Alert,
  Button,
  Collapse,
  Descriptions,
  Form,
  Select,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  ExternalAuthCallbackData,
  ExternalAuthConnector,
} from "components/common/external-auth-connector-button/external-auth-connector-button";
import {
  AddConnectionLayout,
  AddConnectionModalContainer,
  AddConnectionStepsContainer,
  IntegrationSuccessfulScreen,
  StepContainer,
} from "components/modules/connections/add-connection-modal-templates";
import { generateDefaultConnectionConfig } from "components/modules/connections/common-connection-settings/default-connection-config-generator";
import { ConnectionIcons } from "components/modules/connections/helpers/connection-icons";
import { push } from "connected-react-router";
import _ from "lodash";
import { useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { UserTracker } from "user-tracker";
import { getIgAccounts } from "./ig-helpers";

export const AddInstagramConnectionModal = ({
  visible,
  onChangeVisibility: _onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const [connectionForm] = useForm();

  const [currentStep, setCurrentStep] = useState(0);

  const [accessToken, setAccessToken] = useState("");
  const [pageSelectionForm] = useForm();
  const [createdConnection, setCreatedConnection] = useState("");

  const onManageConnection = (connectionId: string) => {};

  const [availablePages, setAvailablePages] = useState<
    { id: string; label: string }[]
  >([]);

  const onAuthenticated = ({ accessToken }: ExternalAuthCallbackData) => {
    setAccessToken(accessToken!);
    getIgAccounts(accessToken!)
      .then((d) => {
        setAvailablePages(
          d
            .filter((page) => page.instagram_business_account?.id)
            .map((page) => ({
              id: page.id,
              label: page.name,
            })),
        );
        setCurrentStep(1);
      })
      .catch((e) => {
        message.error(processServerError(e, "Something went wrong"));
      });
  };

  const { doAction: completeIntegration, isProcessing: isActionProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (val) =>
          SDK.connections.create(val).then((d) => {
            setCreatedConnection(d.id!);
            setCurrentStep(2);
          }),
        successMessage: "Integration has been created",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const onChangeVisibility = (visibility: boolean) => {
    _onChangeVisibility(visibility);
    setCurrentStep(0);
  };

  const dispatch = useDispatch();
  const store = useStore();

  return (
    <>
      <AddConnectionModalContainer
        title="Connect Instagram"
        icon={<i className="ri-instagram-fill"></i>}
        isVisible={visible}
        onChangeVisibility={onChangeVisibility}
      >
        <AddConnectionLayout
          currentStep={currentStep}
          totalSteps={2}
          stepsWithSidebars={[0, 1]}
          topBar={
            <AddConnectionStepsContainer
              currentStep={currentStep}
              steps={[
                {
                  label: "Authenticate",
                  icon: <i className="ri-lock-line"></i>,
                },
                {
                  label: "Select Page",
                  icon: <i className="ri-file-shield-line"></i>,
                },
                {
                  label: "Done",
                  icon: <i className="ri-check-line"></i>,
                },
              ]}
            />
          }
          sidebarImage={
            <div className="text-10xl opacity-50">
              <i className={ConnectionIcons.INSTAGRAM}></i>
            </div>
          }
        >
          <StepContainer stepNumber={0} currentStep={currentStep}>
            <div className="description text-gray-600 dark:text-gray-400 mb-4 mode_transition">
              Now click on 'Connect through Facebook' button and authenticate to
              allow Click Connector to access your Instagram Account
            </div>

            <Descriptions
              title="Required Permissions"
              bordered
              layout="horizontal"
              column={1}
            >
              <Descriptions.Item label="pages_messaging">
                This will allow us to connect to your Instagram Account.
              </Descriptions.Item>
              <Descriptions.Item label="instagram_manage_messages">
                This will allow Click Connector to send and receive messages
                from your Instagram Account.
              </Descriptions.Item>
              <Descriptions.Item label="instagram_manage_comments">
                This will allow Click Connector to reply to comments on behalf
                of your Instagram Account.
              </Descriptions.Item>
            </Descriptions>
            <div className="button-container my-4 flex flex-row justify-center items-center">
              <ExternalAuthConnector
                type="FACEBOOK"
                onAuthenticated={onAuthenticated}
                label="Connect Through Facebook"
              />
            </div>
          </StepContainer>

          <StepContainer stepNumber={1} currentStep={currentStep}>
            <div className="styled-accordion-container mb-4">
              <Collapse className="border-0" defaultActiveKey={[]}>
                <Collapse.Panel
                  header="💡 I am not able to select pages while authenticating anymore"
                  key="PROVIDER"
                >
                  <div className="Box-row">
                    <img
                      src="/assets/help-center/re-authenticating-facebook.png"
                      width={400}
                      alt="Authenticate"
                    />
                  </div>
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="description text-gray-600 dark:text-gray-400 mb-4 mode_transition">
              Select a page that you wish to connect to the website
            </div>
            {availablePages.length === 0 && (
              <Alert
                message="It looks like you do not have active pages or you have missed to select the pages while authenticating"
                type="warning"
                showIcon
                className="p-4 rounded-lg mb-4"
              />
            )}

            <Form layout="vertical" form={pageSelectionForm} initialValues={{}}>
              {/* Welcome Message */}
              <Form.Item
                label="Page to Connect"
                name="pageId"
                rules={[
                  {
                    required: true,
                    message: "Please enter a page",
                  },
                ]}
              >
                <Select size="large">
                  {availablePages.map((page) => (
                    <Select.Option key={page.id} value={page.id}>
                      {page.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>

            <div className="flex flex-row justify-between items-center mt-4">
              <Button
                onClick={() => {
                  setCurrentStep(0);
                }}
                size="large"
                icon={<i className="ri-arrow-left-circle-fill"></i>}
              >
                Back
              </Button>
              <Button
                type="primary"
                loading={isActionProcessing}
                size="large"
                onClick={async () => {
                  const { pageId } = pageSelectionForm.getFieldsValue();
                  try {
                    await pageSelectionForm.validateFields();

                    const selectedOption = _.find(availablePages, {
                      id: pageId,
                    });

                    const label = `IG: ${selectedOption!.label}`;

                    const configData = generateDefaultConnectionConfig(
                      "INSTAGRAM",
                      store,
                    );
                    completeIntegration({
                      type: "INSTAGRAM",
                      label,
                      pageId,
                      accessToken,
                      data: configData,
                    });
                    UserTracker.track("Authenticate Page", {
                      label,
                      pageId,
                      accessToken,
                    });
                  } catch (e) {
                    if (!pageId) {
                      message.warning("Please select a page");
                      return;
                    }
                  }
                }}
                icon={<i className="ri-arrow-right-circle-fill"></i>}
              >
                Continue
              </Button>
            </div>
          </StepContainer>

          <StepContainer stepNumber={2} currentStep={currentStep}>
            <IntegrationSuccessfulScreen
              onManageConnection={() => {
                dispatch(
                  push(`/configurations/connections/${createdConnection}/`),
                );
              }}
              onHideWizard={() => onChangeVisibility(false)}
              subTitle="Now you will be able to reply to Instagram messages from Click Connector"
              title="Your Integration is ready!"
            ></IntegrationSuccessfulScreen>
          </StepContainer>
        </AddConnectionLayout>
      </AddConnectionModalContainer>
    </>
  );
};
