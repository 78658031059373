import { SDK } from "@sdk";
import {
  MessageAttachmentTypes,
  iConnectionType,
  iMessage,
  iMessageAttachment,
  iMessageSenderType
} from "@sdk/conversations/conversations.models";
import { Store } from "redux";
import { store } from "store/store";
import { iStore } from "../../store.model";
import { selectCurrentUser } from "../users/users.selectors";
import {
  addNewMessage,
  setMessagesLoadingErrorStatus,
  setMessagesLoadingStatus,
  setMessagesRes
} from "./message.actions";
import { selectMessageByConversationId } from "./messages.selectors";
const textVersion = require("textversionjs");

export const addDummyMessage = (
  conversationId: string,
  store: Store<iStore>
) => {
  store.dispatch(
    addNewMessage({
      from: { senderType: iMessageSenderType.USER, id: "043dd0-c115d" },
      metaData: { sentAt: 1603215972238, deliveredAt: 1603215972238 },
      connectionId: "xxxx",
      connectionType: "EMAIL",
      organizationId: "043dd0",
      attachments: [],
      references: [],
      contactId: conversationId,
      conversationId: conversationId,
      message: `Test Message - ${new Date().toString()}`,
      id: `043dd0-${new Date().valueOf()}`
    })
  );
};

export const GenerateTemporaryMessuage = ({
  conversationId,
  message,
  attachments = [],
  connectionType,
  isPrivate,
  references = []
}: {
  conversationId: string;
  message: string;
  attachments: iMessageAttachment[];
  connectionType: iConnectionType;
  isPrivate?: boolean;
  references?: string[];
}): iMessage => {
  const currentUser = selectCurrentUser(store.getState());
  const organizationId = conversationId.split("-")[0];
  const messageReference = (() => {
    if (!references || references.length === 0) {
      return;
    }
    const messages = selectMessageByConversationId(conversationId)(
      store.getState()
    ).data;
    const message = (messages || []).find(
      message => message.id === references[0]
    );
    return message;
  })();

  return {
    from: { senderType: iMessageSenderType.USER, id: currentUser.id },
    metaData: { sentAt: Date.now(), deliveredAt: 0 },
    connectionId: "xxxx",
    connectionType: "xxxx",
    organizationId: organizationId,
    references,
    contactId: "xxxx",
    conversationId: conversationId,
    isNotes: isPrivate,
    isPrivate: isPrivate,
    data: {
      original: message
    },
    message: connectionType === "EMAIL" ? textVersion(message) : message,
    id: `${organizationId}-${new Date().valueOf()}`,
    attachments: [
      ...attachments,
      ...(messageReference
        ? [
            {
              type: MessageAttachmentTypes.MESSAGE_REFERENCE,
              payload: {
                message: messageReference
              }
            }
          ]
        : [])
    ],
    isTemporary: true
  };
};

export const loadMessages = (conversationId: string) => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  const state = store.getState().messages.byConversations[conversationId];
  if (!state || forceReload || state.hasError) {
    store.dispatch(
      setMessagesLoadingStatus({ conversationId, isLoading: true })
    );
    try {
      const res = await SDK.getMessagesByConversationId(conversationId);
      store.dispatch(
        setMessagesRes({
          conversationId,
          messagesResults: res,
          reset: forceReload
        })
      );
    } catch (e) {
      store.dispatch(
        setMessagesLoadingErrorStatus({ conversationId, hasError: true })
      );
      throw e;
    }
  }
};

export const loadMoreMessages = (conversationId: string) => async (
  store: Store<iStore>
) => {
  const state = store.getState().messages.byConversations[conversationId];
  if (
    state &&
    !state.isFetching &&
    state.fetchedPages > 0 &&
    !state.isFullyFetched
  ) {
    store.dispatch(
      setMessagesLoadingStatus({ conversationId, isLoading: true })
    );
    try {
      const res = await SDK.getMessagesByConversationId(
        conversationId,
        50,
        state.fetchedPages * 50
      );
      store.dispatch(setMessagesRes({ conversationId, messagesResults: res }));
    } catch (e) {
      store.dispatch(
        setMessagesLoadingErrorStatus({ conversationId, hasError: true })
      );
      throw e;
    }
  }
};
