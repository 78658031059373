import {
  iConnectionConfigurationData,
  iConnectionRoutingConfigurationData,
  iConnectionType,
  iEmailConnectionData,
  iFbConnectionData,
  iLiveChatConnectionData,
  iTelnyxConnectionData
} from "@sdk/conversations/conversations.models";
import { Store } from "redux";
import {
  selectAllUsers,
  selectUser
} from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";

export const generateDefaultConnectionConfig = (
  connectionType: iConnectionType,
  store: Store
) => {
  const organization = selectOrganization(store.getState());

  const allUsers = selectAllUsers(store.getState());
  const adminUser = selectUser(organization?.metaData.adminUserId)(
    store.getState()
  );

  const autoCloseConversationDay = (() => {
    switch (connectionType) {
      case "EMAIL": {
        return 14;
      }
      case "FACEBOOK": {
        return 10;
      }
      case "INSTAGRAM": {
        return 10;
      }
      case "TELNYX": {
        return 14;
      }
      case "WEB_CHAT": {
        return 7;
      }
      case "WHATS_APP_PRIVATE": {
        return 14;
      }
    }
  })();
  const autoFollowupConfig = (() => {
    switch (connectionType) {
      case "EMAIL": {
        return "2_WEEK_FOLLOWUP";
      }
      case "FACEBOOK": {
        return "3_FOLLOWUPS_7_DAYS";
      }
      case "INSTAGRAM": {
        return "3_FOLLOWUPS_7_DAYS";
      }
      case "TELNYX": {
        return "2_WEEK_FOLLOWUP";
      }
      case "WEB_CHAT": {
        return "3_FOLLOWUPS_7_DAYS";
      }
      case "WHATS_APP_PRIVATE": {
        return "2_WEEK_FOLLOWUP";
      }
    }
  })();

  const connectionConfig = {
    connectionBreakAlertEmails: [adminUser.credentials.email],
    notifications:
      allUsers.length < 7
        ? {
            onNewChat: {
              isActive: true,
              for: "NO_ONE_IS_ONLINE"
            },
            onNewMessage: {
              isActive: true
            }
          }
        : {},
    // *  The following is still  in the solution designing stage
    autoClose: {
      active: autoCloseConversationDay ? true : false,
      timeout: (autoCloseConversationDay || 7) * 24
    },
    autoFollowupConfig: {
      active: autoFollowupConfig ? true : false,
      template: autoFollowupConfig
    }
  } as iConnectionConfigurationData;
  const routingConfig = {
    routingStrategy: "ROUND_ROBIN",
    routingMethod: "INVITE",
    routeOnlyToOnlineUsers: true,
    maximumHops: 3
  } as iConnectionRoutingConfigurationData;

  const connectionBasedConfig = (() => {
    switch (connectionType) {
      case "EMAIL": {
        return {
          emailConfiguration: {
            logo: organization!.data.logo,
            primaryColor: organization!.data.primaryColor
          }
        } as Partial<iEmailConnectionData>;
      }
      case "WEB_CHAT": {
        return {
          defaultInviteMessage: "Hi there 👋 How can we help you?",
          emailConfiguration: {
            logo: organization!.data.logo,
            primaryColor: organization!.data.primaryColor
          },
          notifyClientViaEmail: {
            active: true,
            senderName: organization!.data.name
          }
        } as Partial<iLiveChatConnectionData>;
      }
      case "FACEBOOK": {
        return {
          autoArchiveUnsubscribes: {
            isEnabled: true
          }
        } as Partial<iFbConnectionData>;
      }
      case "TELNYX": {
        // Handled in Backend - createDefaultConnectionData()
        return {} as Partial<iTelnyxConnectionData>;
      }
      default: {
        return {};
      }
    }
  })();

  return { ...connectionConfig, ...routingConfig };
};
