import dayjs from "dayjs";
import { iHotelItineraryItem } from "../itineries-model";
import { GoogleMapsPlaceCard } from "./google-place-card";
export const HotelDetailsBlock = ({
  data
}: {
  data: iHotelItineraryItem["data"];
}) => {
  return (
    <div className="mt-4">
      <div className="details-block flex flex-row flex-wrap gap-4">
        <div className="p-2 border border-gray-200 bg-gray-200 dark:bg-gray-700 dark:border-gray-600 rounded-lg font-semibold">
          <i className="ri-calendar-2-line"></i> Check-in:{" "}
          {dayjs(data.checkIn).format("DD-MM-YYYY")}
        </div>
        <div className="p-2 border border-gray-200 bg-gray-200 dark:bg-gray-700 dark:border-gray-600 rounded-lg font-semibold">
          <i className="ri-moon-line"></i> {data.noOfNights} Nights
        </div>
        <div className="p-2 border border-gray-200 bg-gray-200 dark:bg-gray-700 dark:border-gray-600 rounded-lg font-semibold">
          <i className="ri-star-line"></i>
          {data.roomType}
        </div>
      </div>
      {data.notes && (
        <div className="notes text-gray-600 my-4">{data.notes}</div>
      )}

      <GoogleMapsPlaceCard placeId={data.placeId} />
    </div>
  );
};
