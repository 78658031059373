import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React, { Suspense, memo } from "react";
import { iLexicalEditorProps } from "./Editor";

const _LexicalEditorApp = React.lazy(() => import("./App"));

export const LexicalEditorApp = memo(
  (
    props: iLexicalEditorProps & {
      editorRef: any;
    },
  ) => {
    return (
      <Suspense fallback={<SuspenseLoadingIndicator />}>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <_LexicalEditorApp {...props} />
      </Suspense>
    );
  },
);
