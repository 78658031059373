import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Alert, Button, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { SimpleCollapse } from "components/common/simple-collapse/simple-collapse";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import { LazyMarkdownPreview } from "./lazy-mark-down-preview";

const KnowledgeDocumentTypeLabels = {
  TEXT: "Internal Document",
  KB_ARTICLE: "Knowledge Base Article",
  WEB_PAGE: "Web page",
  FILE: "File",
};

export const QueryKnowledgePlayground = () => {
  const {
    doAction: queryKnowledge,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (query: string) =>
        SDK.knowledgeDocuments.queryKnowledge(query, { debug: true }),
      //   successMessage: "Document has been removed",
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const [query, setQuery] = useState("");
  const isDarkMode = useSelector(selectIsDarkMode);

  return (
    <SimpleCollapse title="🧪 AI Response Testing Playground">
      <div className="flex flex-col gap-4 mt-4 ml-6">
        <TextArea
          placeholder="Enter your query/question here..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13 && !e.shiftKey) {
              e.preventDefault();
              if (query) {
                queryKnowledge(query);
              }
            }
          }}
        />
        <Button
          type="primary"
          icon={<i className="ri-magic-line"></i>}
          // icon={<i className="ri-search-line"></i>}
          loading={isProcessing}
          onClick={() => {
            if (query) {
              queryKnowledge(query);
            }
          }}
        >
          Process Query and Visualize behind the scene :-)
        </Button>
        {response.response && (
          <div>
            <div className="answer border border-gray-200 rounded-lg dark:border-gray-800 p-4 flex flex-col">
              <div className="font-bold">Input: </div>
              <div className="">{response.originalQuery}</div>
            </div>
            <div className="flex flex-row justify-center items-center my-4">
              <i className="ri-arrow-down-line text-2xl"></i>
            </div>
            <div className="answer border border-gray-200 rounded-lg dark:border-gray-800 p-4 flex flex-col">
              <div className="font-bold">Matched Documents: </div>
              <div className="flex flex-row items-center">
                <div className="">
                  {response.matchedDocuments.length} Documents
                  {/* {sampleResponse.matchedDocuments?.[0] && (
                    <span className="ml-2">
                      (Match Score -{" "}
                      {Math.round(
                        sampleResponse.matchedDocuments[0].similarity * 10000
                      ) / 100}
                      %)
                    </span>
                  )} */}
                </div>
                {response.matchedDocuments?.[0] && (
                  <div className="">
                    <Button
                      type="link"
                      onClick={() => {
                        Modal.info({
                          title: "Matched Documents",
                          type: "info",
                          width: "80%",
                          content: (
                            <div>
                              {response.matchedDocuments.map((doc) => (
                                <SimpleCollapse
                                  title={` ${doc.title} | ${
                                    KnowledgeDocumentTypeLabels[doc.type]
                                  } | (Match Score - 
                                ${Math.round(doc.similarity * 10000) / 100}
                                %)`}
                                >
                                  <LazyMarkdownPreview
                                    source={doc.content}
                                    wrapperElement={{
                                      "data-color-mode": isDarkMode
                                        ? "dark"
                                        : "light",
                                    }}
                                  />
                                </SimpleCollapse>
                              ))}
                            </div>
                          ),
                        });
                      }}
                    >
                      View Documents
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row justify-center items-center my-4">
              <i className="ri-arrow-down-line text-2xl"></i>
            </div>
            <div className="answer border border-gray-200 rounded-lg dark:border-gray-800 p-4 flex flex-col">
              <div className="font-bold">Prompt for ChatGPT: </div>
              <div className="whitespace-pre-wrap">{response.prompt}</div>
            </div>
            <div className="flex flex-row justify-center items-center my-4">
              <i className="ri-arrow-down-line text-2xl"></i>
            </div>
            <div className="answer border border-gray-200 rounded-lg dark:border-gray-800 p-4 flex flex-col">
              <div className="font-bold">Response: </div>
              <div className="">
                <LazyMarkdownPreview
                  source={response.response}
                  wrapperElement={{
                    "data-color-mode": isDarkMode ? "dark" : "light",
                  }}
                />
              </div>
            </div>

            <Alert
              message={"Not happy with the answer?"}
              description={
                <div className="">
                  1. Check if the matched documents are picked up by
                  ClickConnector above. If this is not correct, you may have to
                  improve the knowledge document that you have provided <br />
                  2. Check the final prompt that has been sent to ChatGPT. You
                  may improve the prompt to get the expected response <br />
                  Heppy Prompt Engineering!
                </div>
              }
              type="info"
              showIcon
              className="mt-2"
            />
          </div>
        )}
      </div>
    </SimpleCollapse>
  );
};
