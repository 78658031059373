import { message } from "antd";
import { MediaType } from "braft-editor";

export const GenerateDraftMediaUploader = (
  fileUploader: (file: File) => any
) => {
  return {
    uploadFn: ({ file, progress, success, error }) => {
      progress(20);
      const uploadingMessage = message.loading("Uploading");
      fileUploader(file)
        .then((file) => {
          uploadingMessage();
          success({
            url: file?.url!,
            meta: {
              id: file?.id!,
              title: "",
              alt: "",
              loop: false,
              autoPlay: false,
              controls: true,
              poster: "",
            },
          });
        })
        .catch((e) => {
          uploadingMessage();
          error({ msg: "Unable to upload the file" });
        });
    },
    accepts: {
      video: false,
      audio: false,
    },
    externals: {
      video: false,
      audio: false,
      embed: false,
    },
  } as MediaType;
};
