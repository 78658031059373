import {
  iLegacyReportWidgetConfig,
  iLegacyWidgetTypes,
  LegacyReportWidgets
} from "./legacy-report-widgets-schema";

export type iLegacyReportTypes =
  | "OVERVIEW_REPORT"
  | "CONVERSATION_FLOW_REPORT"
  | "TEAM_REPORT"
  | "SALES_REPORT"
  | "SUPPORT_REPORT"
  | "MARKETING_REPORT"
  | "RESPONSE_TIME_REPORT"
  | "REPORT_360"
  | "TAGS_BY_TAGS_EXPLORER"
  | "REPORTS_PLAYGROUND";

export const LegacyReportTypeSchemes: {
  [key in iLegacyReportTypes]: {
    reports: (keyof typeof LegacyReportWidgets)[];
    layout: number[];
    overides?: {
      [key in iLegacyWidgetTypes]?: Partial<iLegacyReportWidgetConfig>;
    };
  };
} = {
  OVERVIEW_REPORT: {
    reports: [
      "TOTAL_CONVERSATIONS",
      "CONVERSATIONS_BY_TAGS_GROUP",
      "CONVERSATIONS_BY_PERIOD",
      "CONVERSATIONS_BY_RESPONSE_TIME_BY_PERIOD",
      "CONVERSATIONS_BY_DURATION_BY_PERIOD",
      "CONVERSATIONS_BY_CONNECTIONS",
      "CONVERSATIONS_BY_MESSAGE_COUNT",
      "CONVERSATIONS_BY_STATUS",
      "CONVERSATIONS_BY_RESPONSE_TIME",
      "CONVERSATIONS_BY_DURATION",
      "CONVERSATIONS_BY_CHAT_RATING"
    ],
    layout: [12, 12, 24, 24, 24, 8, 8, 8, 8, 8],
    overides: {
      TOTAL_CONVERSATIONS: {
        size: "medium"
      },
      CONVERSATIONS_BY_TAGS_GROUP: {
        size: "medium"
      },
      CONVERSATIONS_BY_CONNECTIONS: {
        size: "small"
      },
      CONVERSATIONS_BY_MESSAGE_COUNT: {
        size: "small"
      },
      CONVERSATIONS_BY_STATUS: {
        size: "small"
      },
      CONVERSATIONS_BY_PERIOD: {
        size: "large"
      },
      CONVERSATIONS_BY_RESPONSE_TIME: {
        size: "small"
      },
      CONVERSATIONS_BY_DURATION: {
        size: "small"
      },
      CONVERSATIONS_BY_CHAT_RATING: {
        size: "small"
      },
      CONVERSATIONS_BY_RESPONSE_TIME_BY_PERIOD: {
        size: "large"
      },
      CONVERSATIONS_BY_DURATION_BY_PERIOD: {
        size: "large"
      }
    }
  },
  CONVERSATION_FLOW_REPORT: {
    reports: ["CONVERSATIONS_HEAT_MAP"],
    layout: [24]
  },
  TEAM_REPORT: {
    reports: [
      "CONVERSATIONS_BY_USERS",
      "CONVERSATIONS_BY_USERS_TAGS",
      "USER_PERFORMANCE_REPORT"
    ],
    layout: [24, 24, 24],
    overides: {
      CONVERSATIONS_BY_USERS: {
        size: "large"
      },
      CONVERSATIONS_BY_USERS_TAGS: {
        size: "large"
      },
      USER_PERFORMANCE_REPORT: {
        size: "large"
      }
    }
  },
  SALES_REPORT: {
    reports: ["CONVERSATIONS_BY_TEAMS", "CONVERSATIONS_BY_USERS"],
    layout: [24, 24],
    overides: {
      CONVERSATIONS_BY_TEAMS: {
        size: "large"
      }
    }
  },
  SUPPORT_REPORT: {
    reports: ["CONVERSATIONS_BY_TEAMS", "CONVERSATIONS_BY_USERS"],
    layout: [24, 24],
    overides: {
      CONVERSATIONS_BY_TEAMS: {
        size: "large"
      }
    }
  },
  MARKETING_REPORT: {
    reports: [
      "CONVERSATIONS_BY_CONNECTIONS",
      "CONVERSATIONS_BY_SOURCE",
      "CONVERSATIONS_BY_FACEBOOK_AD",
      "CONVERSATIONS_BY_FACEBOOK_POST"
    ],
    layout: [12, 12, 12, 12]
  },
  RESPONSE_TIME_REPORT: {
    reports: ["CONVERSATIONS_BY_RESPONSE_TIME_BY_PERIOD"],
    layout: [24]
  },
  REPORT_360: {
    reports: [
      "TOTAL_CONVERSATIONS",
      "CONVERSATIONS_BY_STATUS",
      "CONVERSATIONS_BY_TAGS_GROUP",
      // "CONVERSATIONS_BY_TEAMS",
      "CONVERSATIONS_BY_MESSAGE_COUNT",
      "CONVERSATIONS_HEAT_MAP",
      "CONVERSATIONS_BY_USERS",
      "CONVERSATIONS_BY_USERS_GROUPS",
      "USER_PERFORMANCE_REPORT",
      "CONVERSATIONS_BY_USERS_TAGS",
      "CONVERSATIONS_BY_CONNECTIONS",
      "CONVERSATIONS_BY_SOURCE",
      "CONVERSATIONS_BY_FACEBOOK_AD",
      "CONVERSATIONS_BY_FACEBOOK_POST",
      "CONVERSATIONS_TAGS_BY_TAGS_WIDGET",
      "CONVERSATIONS_BY_RESPONSE_TIME",
      "CONVERSATIONS_BY_DURATION",
      "CONVERSATIONS_BY_CHAT_RATING"
    ],
    layout: [12, 12, 12, 12, 24, 12, 12, 24, 24, 12, 12, 12, 12, 24, 8, 8, 8],
    overides: {
      CONVERSATIONS_BY_USERS_TAGS: {
        size: "large"
      },
      CONVERSATIONS_BY_USERS: {
        size: "medium"
      },
      TOTAL_CONVERSATIONS: {
        size: "medium"
      },
      CONVERSATIONS_BY_STATUS: {
        size: "medium"
      },
      CONVERSATIONS_BY_TAGS_GROUP: {
        size: "medium"
      },
      CONVERSATIONS_BY_TAGS: {
        size: "small"
      },
      CONVERSATIONS_BY_MESSAGE_COUNT: {
        size: "medium"
      },
      CONVERSATIONS_TAGS_BY_TAGS_WIDGET: {
        size: "large"
      },
      CONVERSATIONS_BY_RESPONSE_TIME: {
        size: "small"
      },
      CONVERSATIONS_BY_DURATION: {
        size: "small"
      },
      CONVERSATIONS_BY_CHAT_RATING: {
        size: "small"
      }
    }
  },
  TAGS_BY_TAGS_EXPLORER: {
    reports: ["CONVERSATIONS_TAGS_BY_TAGS_WIDGET"],
    layout: [12],
    overides: {
      CONVERSATIONS_TAGS_BY_TAGS_WIDGET: {
        size: "large"
      }
    }
  },
  REPORTS_PLAYGROUND: {
    reports: [
      "CONVERSATIONS_BY_RESPONSE_TIME_BY_PERIOD",
      "CONVERSATIONS_BY_DURATION_BY_PERIOD",
      "CONVERSATIONS_BY_RESPONSE_TIME",
      "CONVERSATIONS_BY_DURATION"
    ],
    layout: [24, 24, 12, 12],
    overides: {
      CONVERSATIONS_BY_RESPONSE_TIME_BY_PERIOD: {
        size: "large"
      },
      CONVERSATIONS_BY_DURATION_BY_PERIOD: {
        size: "large"
      },
      CONVERSATIONS_BY_RESPONSE_TIME: {
        size: "medium"
      },
      CONVERSATIONS_BY_DURATION: {
        size: "medium"
      }
    }
  }
};
