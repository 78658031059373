import { Select } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useSelector } from "react-redux";
import { selectAllGroups } from "store/modules/groups/groups.selectors";

export const UserGroupSelector = ({
  value,
  onChange,
  placeholder = "Search User Groups...",
  size = "large",
  mode = "default",
}: {
  value?: string;
  onChange?: (contact: string) => any;
  placeholder?: string;
  size?: SizeType;
  mode?: "default" | "multiple" | "tags";
}) => {
  const userGroups = useSelector(selectAllGroups);

  return (
    <Select
      size={size}
      allowClear
      mode={mode as any}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    >
      {(userGroups || []).map((userGroup) => (
        <Select.Option key={userGroup.label} value={userGroup.id}>
          {userGroup.label}
        </Select.Option>
      ))}
    </Select>
  );
};
