import { iChatConnectorType } from "@sdk/chat-connectors/chat-connectors.models";
import { Button, Divider, Space, Table, Tooltip } from "antd";
import { SectionHeader } from "components/common/section-header";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import copyToClipboard from "copy-to-clipboard";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { loadAllChatConnectors } from "store/modules/chat-connectors/chat-connectors.helpers";
import { selectAllChatConnectors } from "store/modules/chat-connectors/chat-connectors.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSimpleState } from "utils/hooks/use-simple-state";
import JSONModal from "utils/json-modal/json-modal";
import { AddChatConnectorModal } from "./add-chat-connector-modal";
import { ConfigureChatConnectorModal } from "./configure-chat-connector-modal";
import { EditChatConnectorModal } from "./edit-chat-connector-modal";
import { useChatConnectorEntity } from "./use-chat-connector-entity";

export const ManageChatConnectors = ({
  type,
}: {
  type?: iChatConnectorType;
}) => {
  const isAdvancedMode = useSelector(selectIsAdvancedMode);
  const columns = [
    {
      title: "Label",
      dataIndex: "label",
      render: (label: string, record) => {
        return (
          <>
            {label}{" "}
            <Tooltip title="Copy Connector Id">
              <Button
                onClick={(e) => {
                  copyToClipboard(record.id);
                  e.stopPropagation();
                }}
                type="link"
                icon={<i className="ri-clipboard-line"></i>}
              ></Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Created on",
      dataIndex: "createdOn",
      width: 120,
      render: (timestamp: string) => {
        return (
          <div className="text-gray-700 dark:text-gray-200 mode_transition">
            {dayjs(timestamp).format("DD-MM-YYYY")}
          </div>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setEditChatConnectorModalState({
                visibility: true,
                chatConnectorId: record.id,
              });
            }}
            icon={<i className="ri-settings-2-line"></i>}
          >
            Edit Label
          </Button>
          <Button
            onClick={() => {
              setConfigureChatConnectorModalState({
                visibility: true,
                chatConnectorId: record.id,
              });
            }}
            icon={<i className="ri-settings-2-line"></i>}
          >
            Configure
          </Button>
        </Space>
      ),
    },
  ];
  if (isAdvancedMode) {
    columns.push({
      title: "",
      dataIndex: "id",
      width: 20,
      render: (id) => {
        return (
          <i
            className="ri-code-box-line cursor-pointer"
            onClick={() => {
              JSONModal(
                _chatConnectors.find((connection) => connection.id === id) ||
                  {},
              );
            }}
          />
        );
      },
    });
  }

  const { state: _chatConnectors, retry: reload } = useQueryWithStore(
    selectAllChatConnectors,
    loadAllChatConnectors(),
  );

  const chatConnectors = useMemo(
    () =>
      _chatConnectors
        .filter((chatConnector) => chatConnector.isActive)
        .filter((chatConnector) => {
          if (type) {
            return chatConnector.type === type;
          }
          return true;
        })
        .map((chatConnector) => ({
          id: chatConnector.id,
          label: chatConnector.label,
          createdAt: chatConnector.createdAt,
        })),
    [_chatConnectors, type],
  );

  const [addChatConnectorModalState, setAddChatConnectorModalState] =
    useSimpleState({
      visibility: false,
    });

  const [editChatConnectorModalState, setEditChatConnectorModalState] =
    useSimpleState({
      visibility: false,
      chatConnectorId: "",
    });

  const [
    configureChatConnectorModalState,
    setConfigureChatConnectorModalState,
  ] = useSimpleState({
    visibility: false,
    chatConnectorId: "",
  });

  const { pageTitle, icon, entityName, description } =
    useChatConnectorEntity(type);

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin">
      <SectionHeader
        title={pageTitle}
        icon={icon}
        description={description}
        addons={
          <Button
            type="primary"
            icon={<i className="ri-add-box-line"></i>}
            onClick={() =>
              setAddChatConnectorModalState({
                visibility: true,
              })
            }
            className="font-bold"
          >
            New {entityName}
          </Button>
        }
      />
      <Divider />

      <Table
        columns={columns}
        dataSource={chatConnectors}
        pagination={false}
        rowKey="id"
        className="mb-16"
      />

      <AddChatConnectorModal
        visible={addChatConnectorModalState.visibility}
        onChangeVisibility={(state) =>
          setAddChatConnectorModalState({
            visibility: state,
          })
        }
        onCreated={() => reload()}
        type={type}
      />

      <EditChatConnectorModal
        visible={editChatConnectorModalState.visibility}
        chatConnectorId={editChatConnectorModalState.chatConnectorId}
        onChangeVisibility={(state) =>
          setEditChatConnectorModalState({
            visibility: state,
          })
        }
        type={type}
      />

      <ConfigureChatConnectorModal
        visible={configureChatConnectorModalState.visibility}
        chatConnectorId={configureChatConnectorModalState.chatConnectorId}
        onChangeVisibility={(state) =>
          setConfigureChatConnectorModalState({
            visibility: state,
          })
        }
      />
    </StyledScrollArea>
  );
};
