export const ProtobiQueryUnDot = function(obj, key) {
  try {
    var keys = key.split("."),
      sub = obj;
    for (var i = 0; i < keys.length; i++) {
      sub = sub[keys[i]];
    }
    return sub;
  } catch (e) {
    return undefined;
  }
};
