import { ConfigurationEditorComponent } from "../../../common/configuration-editor";

export const ConnectionDataEditor = ({
  connectionId,
  children,
  icon,
  title,
  description
}: {
  connectionId: string;
  icon: string;
  title: string;
  description: string;
  children;
}) => {
  return (
    <ConfigurationEditorComponent
      icon={icon}
      title={title}
      description={description}
      entityType="CONNECTION"
      entityId={connectionId}
    >
      {children}
    </ConfigurationEditorComponent>
  );
};
