import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import { iReportWidget } from "components/pages/reports/components/report-canvas/widget-canvas-models";

import _ from "lodash";
import React, { memo, Suspense, useMemo } from "react";
import { useMeasure } from "react-use";
import { getDayNameFromDayNumber } from "../utils/hour-day-heatmap-generator";
import { generateHeatMapConfig } from "./configs/heat-map";

import "./high-chart-menu.scss";

const HighchartsReact = React.lazy(() =>
  import("@libs/high-charts-lazy/high-charts-lazy")
);

export const Heatmap = memo(
  ({
    data: _data,
    reportConfig,
  }: {
    data: any[];
    reportConfig: iReportWidget;
  }) => {
    const [setRef, { width, height }] = useMeasure();

    const data = _.cloneDeep(_data);

    const chartOptions = useMemo(() => {
      return ((data: any) => {
        let config: Highcharts.Options;
        config = generateHeatMapConfig({
          title: "",
          xAxisLabels: new Array(24)
            .fill(0)
            .map((v, index) => index.toString()),
          yAxisLabels: new Array(7)
            .fill(0)
            .map((v, i) => getDayNameFromDayNumber(i + 1)),
          // data: heatMapDummyData.map((record) => ({
          //   x: record.hour,
          //   y: record.dayOfTheWeek,
          //   count: record.count,
          // })),
          data: Array.isArray(data)
            ? data.map((record) => ({
                x: Number(record.date?.split("-")[1]),
                y: Number(record.date?.split("-")[0]) - 1,
                count: record.count,
              }))
            : [],
          toolTipTemplate: (x, y, count) => `${count} conversations`,
        }) as any;

        (config.chart as any).height = height - 5;
        if (height < 250) {
          (config.legend as any).enabled = false;
          // (config.xAxis as any).labels = {
          //   enabled: false,
          // };
        }

        return config;
      })(data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
      <div className="h-full w-full overflow-hidden" ref={setRef}>
        <div className="high-chart-container h-full w-full overflow-auto">
          <Suspense fallback={<SuspenseLoadingIndicator />}>
            <HighchartsReact options={chartOptions} />
          </Suspense>
        </div>
      </div>
    );
  }
);
