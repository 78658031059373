import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import {
  iConnectorAPICallConfig,
  iConnectorDataMapper,
} from "@sdk/chat-connectors/chat-connectors.models";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Space,
  Tag,
  message,
} from "antd";
import { ModalTitle } from "components/common/modal-title";
import { SimpleCollapse } from "components/common/simple-collapse/simple-collapse";
import { AvailableAPITokens } from "components/pages/conversations/user-chat-connector-api";
import copyToClipboard from "copy-to-clipboard";
import { useEffect } from "react";

export const UrlDataBuilder = ({ name }: { name: string | string[] }) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <Space key={field.key} style={{ display: "flex" }} align="baseline">
              <Form.Item
                {...field}
                name={[field.name, "key"]}
                rules={[
                  {
                    required: true,
                    message: "Enter a text",
                  },
                ]}
                hasFeedback={false}
                style={{ marginBottom: 4 }}
              >
                <Input placeholder="Key" />
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, "value"]}
                hasFeedback={false}
                style={{ marginBottom: 4 }}
              >
                <Input placeholder="Value" />
              </Form.Item>
              {/* User Group */}

              <MinusCircleOutlined onClick={() => remove(field.name)} />
            </Space>
          ))}
          <div
            onClick={() =>
              add({
                key: "New Key",
                value: "",
              })
            }
            className="cursor-pointer pl-4"
          >
            + add a data
          </div>
        </>
      )}
    </Form.List>
  );
};

const convertUrlDatObjectMapperToArray = (data: iConnectorDataMapper = {}) => {
  return Object.keys(data).map((key) => ({
    key,
    value: data[key].value,
  }));
};

const convertUrlDatArrayToObject = (
  data: { key: string; value: string }[] = [],
) => {
  const obj: iConnectorDataMapper = {};
  for (const record of data) {
    obj[record.key] = {
      type: "STATIC",
      value: record.value,
    };
  }
  return obj;
};

export const URLBuilder = ({
  value,
  onChange,
}: {
  value?: iConnectorAPICallConfig;
  onChange?: (value: iConnectorAPICallConfig) => any;
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    // console.log("Setting Value", value);
    const formValue = { ...value } as any;
    formValue.body = convertUrlDatObjectMapperToArray(value?.body);
    formValue.queryParaMeters = convertUrlDatObjectMapperToArray(
      value?.queryParaMeters,
    );
    formValue.urlParameters = convertUrlDatObjectMapperToArray(
      value?.urlParameters,
    );
    form.setFieldsValue(formValue);
  }, [form, value]);

  return (
    <div className="border rounded-lg p-4" style={{}}>
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={value}
        onChange={() => {
          const formValue = form.getFieldsValue();
          formValue.body = convertUrlDatArrayToObject(formValue?.body);
          formValue.queryParaMeters = convertUrlDatArrayToObject(
            formValue?.queryParaMeters,
          );
          formValue.urlParameters = convertUrlDatArrayToObject(
            formValue?.urlParameters,
          );
          onChange && onChange(formValue);
        }}
      >
        <Form.Item
          name="method"
          label=""
          rules={[
            {
              required: true,
              message: "Please enter a Method",
            },
          ]}
        >
          <Radio.Group buttonStyle="solid" style={{ width: "100%" }}>
            <Radio.Button
              value="POST"
              style={{ width: "25%", textAlign: "center" }}
            >
              Post
            </Radio.Button>
            <Radio.Button
              value="GET"
              style={{ width: "25%", textAlign: "center" }}
            >
              Get
            </Radio.Button>
            <Radio.Button
              value="PATCH"
              style={{ width: "25%", textAlign: "center" }}
            >
              Patch
            </Radio.Button>
            <Radio.Button
              value="DELETE"
              style={{ width: "25%", textAlign: "center" }}
            >
              Delete
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="url"
          label={<div className="font-bold">URL / Endpoint</div>}
          rules={[
            {
              required: true,
              message: "Please enter a URL",
            },
          ]}
        >
          <Input placeholder="URL" addonBefore="https://" />
        </Form.Item>
        <SimpleCollapse title="Query Parameters">
          <div className="mb-4">
            <UrlDataBuilder name={["queryParaMeters"]} />
          </div>
        </SimpleCollapse>
        <SimpleCollapse title="Headers">
          <div className="mb-4">
            <UrlDataBuilder name={["headers"]} />
          </div>
        </SimpleCollapse>
        <SimpleCollapse title="Body">
          <div className="mb-4">
            <UrlDataBuilder name={["body"]} />
          </div>
        </SimpleCollapse>
        <div className="w-full flex flex-row flex-end items-center">
          <Button
            type="link"
            onClick={() => {
              const modalRef = Modal.info({
                icon: null,
                title: (
                  <ModalTitle
                    title="Available Tokens"
                    icon={<i className="ri-braces-line"></i>}
                  />
                ),
                content: (
                  <div>
                    <Divider />
                    <div className="mt-4">
                      You can use variable tokens in your API Calls
                      <div className="font-bold my-4">Available Tokens:</div>
                      <table className="simple-table-style w-full">
                        <thead>
                          <tr>
                            <th className="font-bold">Variable</th>
                            <th className="font-bold">Token</th>
                          </tr>
                        </thead>
                        <tbody>
                          {AvailableAPITokens.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.label}</td>
                                <td>
                                  <Tag
                                    onClick={() => {
                                      copyToClipboard(item.token);
                                      message.success("Copied to Clipboard");
                                      modalRef.destroy();
                                    }}
                                    className="cursor-pointer"
                                  >
                                    {item.token}
                                  </Tag>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ),
              });
            }}
          >
            Available Variable Tokens
          </Button>
        </div>
      </Form>
      {/* <div>{JSON.stringify(form.getFieldsValue(), null, 2)}</div> */}
    </div>
  );
};
