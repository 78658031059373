import {
  iContactConnection,
  iContactMetaData_lastActivity,
  iContactMetaData_lastContact,
  iMarketingListRecord,
} from "@sdk/crm/crm.models";
import { Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import dayjs from "dayjs";

export interface iContactListItem {
  id: string;
  key: string;
  name: string;
  phone: string;
  email: string;
  lists: (iMarketingListRecord & { label: string })[];
  tags: string[];
  connections: iContactConnection[];
  lastContact: iContactMetaData_lastContact;
  lastActivity: iContactMetaData_lastActivity;
}

export const GenerateContactsTableColumns = () => {
  // Todo
  // Connected Connections
  // Total Conversations
  // Total Opportunities
  return [
    // {
    //   title: "Name",
    //   dataIndex: "id",
    //   // responsive: ["lg"],
    //   render: (id: string, data: iContactListItem) => (
    //     <div className="flex flex-row items-center" style={{ minWidth: 250 }}>
    //       <ContactAvatar contactId={id} size={32} />
    //       <div className="flex flex-col  ml-2">
    //         <div className="font-bold">{data.name}</div>
    //         <div className="text-gray-600 dark:text-gray-400 mode_transition">
    //           Last Contact:{" "}
    //           {data.lastContact?.byUser &&
    //             dayjs(data.lastContact.byUser).format("MMM Do, h:mm:ss a")}
    //         </div>
    //         <div className="text-gray-600 dark:text-gray-400 mode_transition">
    //           Last Activity:{" "}
    //           {data.lastActivity?.timestamp &&
    //             dayjs(data.lastActivity.timestamp).format("MMM Do, h:mm:ss a")}
    //         </div>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: "Name",
      dataIndex: "id",
      // responsive: ["maxLg"],
      render: (id: string, data: iContactListItem) => (
        <div className="flex flex-row items-center" style={{ minWidth: 250 }}>
          <ContactAvatar contactId={id} size={32} />
          <div className="flex flex-col  ml-2">
            <div className="font-bold">{data.name}</div>
            <div className="text-gray-600 dark:text-gray-400 mode_transition">
              <i className="ri-mail-send-line mr-1"></i>
              {data.email || "No Record"}
            </div>
            <div className="text-gray-600 dark:text-gray-400 mode_transition">
              <i className="ri-phone-line mr-1"></i>
              {data.phone || "No Record"}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Lists & Tags",
      dataIndex: "lists",
      responsive: ["xxl"],
      render: (
        lists: {
          label: string;
          id: string;
          dateAdded: number;
          isUnsubscribed: boolean;
        }[],
        data: iContactListItem
      ) => (
        <>
          {lists.map((list, index) => {
            return (
              <Tag
                color={list.isUnsubscribed ? "red" : undefined}
                key={index.toString()}
              >
                {list?.label}
              </Tag>
            );
          })}
          {data.tags.map((tag) => {
            return (
              <Tag key={tag}>
                {(tag.toUpperCase && tag.toUpperCase()) || tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Activity",
      dataIndex: "meta",
      responsive: ["xl"],
      render: (id: string, data: iContactListItem) => (
        <div className="flex flex-row items-center" style={{ minWidth: 250 }}>
          <div className="flex flex-col">
            <div className="text-gray-600 dark:text-gray-400 mode_transition text-sm">
              Last Contact By User:{" "}
              {data.lastContact?.byUser
                ? dayjs(data.lastContact.byUser).format("MMM Do, h:mm:ss a")
                : "Not available"}
            </div>
            <div className="text-gray-600 dark:text-gray-400 mode_transition text-sm">
              Last Contact By Customer:{" "}
              {data.lastContact?.byContact
                ? dayjs(data.lastContact.byContact).format("MMM Do, h:mm:ss a")
                : "Not available"}
            </div>
            <div className="text-gray-600 dark:text-gray-400 mode_transition text-sm">
              Last Activity:{" "}
              {data.lastActivity?.timestamp
                ? dayjs(data.lastActivity.timestamp).format("MMM Do, h:mm:ss a")
                : "Not available"}
            </div>
          </div>
        </div>
      ),
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   responsive: ["lg"],
    //   render: (email: string) => (
    //     <div className="text-gray-600 dark:text-gray-400 mode_transition">
    //       <i className="ri-mail-send-line mr-1"></i>
    //       {email || "no email"}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Phone Number",
    //   dataIndex: "phone",
    //   responsive: ["lg"],
    //   render: (phone: string) => (
    //     <div className="text-gray-600 dark:text-gray-400 mode_transition">
    //       <i className="ri-phone-line mr-1"></i>
    //       {phone || "no phone"}
    //     </div>
    //   ),
    // },

    // {
    //   title: "Tags",
    //   dataIndex: "tags",
    //   responsive: ["xxl"],
    //   render: (tags: string[]) => (
    //     <>
    //       {tags.map((tag) => {
    //         return (
    //           <Tag key={tag}>
    //             {(tag.toUpperCase && tag.toUpperCase()) || tag}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    // {
    //   title: "Last Contacted on",
    //   dataIndex: "lastContact",
    //   responsive: ["xxl"],
    //   render: (lastContact: iContactMetaData_lastContact) => (
    //     <>
    //       <div className="text-gray-600 dark:text-gray-400 mode_transition">
    //         {lastContact?.byUser &&
    //           dayjs(lastContact.byUser).format("MMM Do, h:mm:ss a")}
    //       </div>
    //     </>
    //   ),
    // },
    // {
    //   title: "Last Activity",
    //   dataIndex: "lastActivity",
    //   responsive: ["xxl"],
    //   render: (lastActivity: iContactMetaData_lastActivity) => (
    //     <>
    //       <div className="text-gray-600 dark:text-gray-400 mode_transition">
    //         {lastActivity?.timestamp &&
    //           dayjs(lastActivity.timestamp).format("MMM Do, h:mm:ss a")}
    //       </div>
    //     </>
    //   ),
    // },
  ].filter((e) => e) as ColumnsType<any>;
};
