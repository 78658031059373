import { Divider, Form, Select } from "antd";
import { emailValidatorRuleForSelect } from "components/common/reply-input/email-modifer";
import { StyledSwitch } from "components/common/styled-swtich";
import { ConnectionDataEditor } from "../connection-data-editor";

const tokenSeparators = [",", ";"];

export const EmailNotificationsForm = ({ connectionId }: { connectionId }) => {
  return (
    <ConnectionDataEditor
      connectionId={connectionId}
      icon={"ri-notification-3-fill"}
      title="Email Notifications"
      description="Choose to receive email notifications on new chats and new messages"
    >
      <StyledSwitch
        label="Enable Notification on new Chats"
        name={["data", "notifications", "onNewChat", "isActive"]}
      />
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const isActive = getFieldValue([
            "data",
            "notifications",
            "onNewChat",
            "isActive",
          ]);
          if (isActive) {
            return (
              <div>
                <Form.Item
                  label="Send Notifications for"
                  name={["data", "notifications", "onNewChat", "for"]}
                >
                  <Select>
                    <Select.Option value={"ALL"}>All new chats</Select.Option>
                    <Select.Option value={"NO_ONE_IS_ONLINE"}>
                      When no agents are online
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            );
          }
          return <></>;
        }}
      </Form.Item>
      {/* <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const isActive = getFieldValue([
            "data",
            "notifications",
            "onNewChat",
            "isActive",
          ]);
          const forConfig = getFieldValue([
            "data",
            "notifications",
            "onNewChat",
            "for",
          ]);
          if (isActive && forConfig === "NOT_ASSIGNED") {
            return (
              <div>
                <Form.Item
                  label="Time to wait"
                  name={["data", "notifications", "onNewChat", "waitTime"]}
                >
                  <Select>
                    <Select.Option value={2 * 60 * 1000}>
                      2 Minutes
                    </Select.Option>
                    <Select.Option value={5 * 60 * 1000}>
                      5 Minutes
                    </Select.Option>
                    <Select.Option value={10 * 60 * 1000}>
                      10 Minutes
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            );
          }
          return <></>;
        }}
      </Form.Item> */}
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const isActive = getFieldValue([
            "data",
            "notifications",
            "onNewChat",
            "isActive",
          ]);
          if (isActive) {
            return (
              <div>
                <Form.Item
                  label={"Emails to send the notifications"}
                  name={["data", "notifications", "onNewChat", "emails"]}
                  hasFeedback={false}
                  rules={[emailValidatorRuleForSelect]}
                >
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Add Emails"
                    dropdownStyle={{ display: "none" }}
                    allowClear
                    tokenSeparators={tokenSeparators}
                    // onChange={handleChange}
                  ></Select>
                </Form.Item>
              </div>
            );
          }
          return <></>;
        }}
      </Form.Item>
      {/* Spacer */}
      <div className="mt-4"></div>
      <Divider />

      <StyledSwitch
        label="Enable Notification on new Messages"
        name={["data", "notifications", "onNewMessage", "isActive"]}
        description="Notifications will be sent to assigned users"
      />

      {/* <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const isActive = getFieldValue([
            "data",
            "notifications",
            "onNewMessage",
            "isActive",
          ]);
          if (isActive) {
            return (
              <div>
                <Form.Item
                  label="Time to wait"
                  name={["data", "notifications", "onNewMessage", "waitTime"]}
                >
                  <Select>
                    <Select.Option value={2 * 60 * 1000}>
                      2 Minutes
                    </Select.Option>
                    <Select.Option value={5 * 60 * 1000}>
                      5 Minutes
                    </Select.Option>
                    <Select.Option value={10 * 60 * 1000}>
                      10 Minutes
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            );
          }
          return <></>;
        }}
      </Form.Item> */}
    </ConnectionDataEditor>
  );
};
