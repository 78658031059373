import { iGenerateQuoteInput } from "../helpers/generate-quote-from-base-input";
import { DummyHotelPlaceData } from "./dummy-hotel-place-data";

export const DummyQuoteRequest: iGenerateQuoteInput = {
  flightsPNR: `2  NZ 456 H 26OCT 5 WLGAKL HK1  1945 2050  26OCT  E  NZ/W268NH 
  3  NZ 002 W 26OCT 5 AKLLAX HK1  2250 1500  26OCT  E  NZ/W268NH 
  4  CM 362 T 26OCT 5 LAXPTY HK1  2200 0637  27OCT  E  CM/BIDQUI 
  5  CM 472 T 03NOV 6 PTYLAX HK1  1229 1735  03NOV  E  CM/BIDQUI 
  6  NZ 005 W 03NOV 6 LAXAKL HK1  2240 0730  05NOV  E  NZ/W268NH 
  7  NZ 413 H 05NOV 1 AKLWLG HK1  0900 1005  05NOV  E  NZ/W268NH`,
  totalPrice: 100,
  finePrint: "Line 1\nLine 2",
  whatsIncluded: [
    {
      type: "VISA",
      title: "Visa Included",
      description: "Visa is included in the price"
    }
  ],
  adults: 2,
  children: 0,
  infants: 0,
  passengerDetails: [
    {
      first: "RUban",
      last: "Nanthagopal",
      dob: "2023-04-10T17:23:45.058Z"
    }
  ],
  hotelDetails: [
    {
      hotel: DummyHotelPlaceData,
      checkIn: "2023-04-04T17:23:12.829Z",
      noOfNights: 3,
      roomType: "Room Only",
      notes: "efdsf"
    }
  ]
};
