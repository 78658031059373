import { useSDK } from "@sdk";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Form, Input, Modal, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import dayjs from "dayjs";

import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useState } from "react";
import { useStore } from "react-redux";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectUser } from "store/modules/users/users.selectors";
import { exportCSVFile } from "utils/export-as-csv";
import { LegacyReportFilter } from "../legacy-report-filter/legacy-report-filter";
import { ReportWidgetLegacy } from "../report-widget/report-widget-legacy";

export const CustomReport = () => {
  const [query, setQuery] = useState({
    period: [
      dayjs()
        .subtract(7, "day")
        .startOf("day"),
      dayjs().endOf("day")
    ] as any,
    groupBy: [],
    dateField: "createdTime"
  } as any);

  const store = useStore();
  const { data: analytics, isLoading } = useSDK(
    sdk =>
      sdk
        .getConversationReports({
          startDate: query.period && query.period[0].valueOf(),
          endDate: query.period && query.period[1].valueOf(),
          filters: _.omit(query, "groupBy", "period", "dateField"),
          groupBy: (query.groupBy || []).join(","),
          period: query.aggregateByPeriod,
          dateField: query.dateField
        })
        .then(report => {
          const groupByOptions = query.groupBy || [];
          if (groupByOptions.length > 0) {
            return report.map(record => {
              for (const groupByOption of groupByOptions) {
                if (groupByOption === "connections") {
                  record.connections = selectConnectionById(record.connections)(
                    store.getState()
                  ).label;
                } else if (groupByOption === "users") {
                  record.users = getUserName(
                    selectUser(record.users)(store.getState())
                  );
                }
              }

              return record;
            });
          }
          return report;
        }),
    [query],
    false,
    []
  );
  const chartType =
    !query.groupBy || query.groupBy.length === 0
      ? "DIGIT"
      : query.groupBy.length === 1
      ? "PIE"
      : "TABLE";

  const [
    saveAsPresetModalVisibility,
    setSaveAsPresetModalVisibility
  ] = useState(false);

  const [form] = useForm();

  const { doAction: onSubmit, isProcessing, response } = useSDKActionWithDeps(
    () => ({
      action: SDK => async values => {
        return {};
      },
      successMessage: "Preset has been saved",
      failureMessage: "Something went wrong"
    }),
    []
  );
  return (
    <>
      <StyledScrollArea className="w-full h-full flex-1 animated fadeInLeftMin">
        <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4">
          <SectionHeader
            title={"CSV Report Generator"}
            icon={<i className="ri-grid-line"></i>}
          />
          <Divider />

          <div className="filter-container  px-10 py-2 my-10 hover:bg-gray-200 flex flex-col">
            <div className="font-bold">Filter:</div>
            <LegacyReportFilter
              onFilterChange={query => setQuery(query)}
              disableAdvancedQuery={true}
              showPeriodAggregationOption={true}
            />
            {/* <Button
        icon={<i className="ri-save-2-line"></i>}
        onClick={() => setSaveAsPresetModalVisibility(true)}
      >
        Save as Report Preset
      </Button> */}
          </div>
          <Spin
            spinning={isLoading}
            indicator={<LoadingIndicatorWithoutSpin />}
          >
            <div className="flex flex-row justify-center items-center mx-4">
              <Button
                type="dashed"
                onClick={() => {
                  exportCSVFile(
                    Array.isArray(analytics) ? analytics : [analytics],
                    "cc-report"
                  );
                }}
                icon={<i className="ri-download-2-line"></i>}
              >
                Download CSV Report
              </Button>
            </div>

            {false && (
              <div className="flex-row justify-center items-center mx-4">
                <ReportWidgetLegacy
                  widgetConfig={{
                    filter: {},
                    advancedFilter: {},
                    isAdvancedMode: false,
                    title: "Conversations",
                    description: "Total Conversations in the selected period",
                    chartType: chartType,
                    size: "large"
                  }}
                  data={(() => {})()}
                />
              </div>
            )}
          </Spin>
          <Modal
            title={
              <ModalTitle
                title="Save as Report Preset"
                icon={<i className="ri-save-2-line"></i>}
              />
            }
            open={saveAsPresetModalVisibility}
            footer={null}
            onCancel={() => {
              setSaveAsPresetModalVisibility(false);
            }}
            destroyOnClose={true}
            data-click-context="Save as Preset Modal"
          >
            <Form layout="vertical" form={form} onFinish={() => {}}>
              <Form.Item
                name="label"
                label="Preset Label"
                rules={[{ required: true, message: "Please input a value" }]}
              >
                <Input placeholder="Eg: Sales Report" size="large" />
              </Form.Item>

              <div className="flex flex-row justify-end items-center">
                <Button
                  icon={<i className="ri-save-2-line"></i>}
                  htmlType="submit"
                  type="primary"
                >
                  Save
                </Button>
              </div>
            </Form>
            <DarkModeBg />
          </Modal>
        </SimpleCardSection>
      </StyledScrollArea>
    </>
  );
};
