import { useModalPanels } from "@libs/modal-panels/modal-panels";
import {
  GetWindowFocus,
  GetWindowVisibility,
} from "@libs/window-visibility-detector/window-visibility-detector.service";
import { iConnectorConversationActionData } from "@sdk/chat-connectors/chat-connectors.models";
import {
  iConversationFacebookData,
  iEmailConnectionData,
  iMessageSenderType,
} from "@sdk/conversations/conversations.models";
import {
  Button,
  Divider,
  Dropdown,
  Menu,
  Modal,
  Popconfirm,
  Rate,
  Result,
  Space,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import { AvatarGroup, UserAvatar } from "components/common/avatar/avatar";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import { KeyboardShortcut } from "components/common/keyboard-shortcut/keyboard-shortcut";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { ConversationTagSelector } from "components/modules/conversations/components/converation-tag-selector";
import { MessagesList } from "components/modules/conversations/components/messages-list/message-list";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import copyToClipboard from "copy-to-clipboard";
import { useRegisterActions } from "kbar";
import {
  ContactProfile,
  ConversationSummary,
  NewMessageCreator,
} from "modal-registry";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { resetUnreadCount } from "store/modules/messages/message.actions";
import { selectUser } from "store/modules/users/users.selectors";
import JSONModal from "utils/json-modal/json-modal";
import {
  loadConversationById,
  updateReadWatermark,
} from "../../../store/modules/conversations/conversations.helpers";
import { selectConversationById } from "../../../store/modules/conversations/conversations.selectors";
import { useQueryWithStore } from "../../../store/store.hooks";
import { EditableText } from "../../common/editable-text/editable-text";
import { PipelineSelector } from "../../modules/conversations/components/pipeline-selector/pipeline-selector";
import { UserPickerModal } from "../../modules/user-management/users/components/user-picker-modal/user-picker-modal";

import { mdiCommentEyeOutline } from "@mdi/js";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { MDIIcon } from "components/common/mdi-icon";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { selectMessageByConversationId } from "store/modules/messages/messages.selectors";
import { selectAutoTranslationDefaultLanguage } from "store/modules/workspace/workspace.selectors";
import { SimpleChatConnectorAction } from "./chat-connector-action";
import { ChatInputPlayground } from "./chat-input-2-playground";
import { ChatViewReminder } from "./chat-view-reminder";
import "./chat-view.scss";
import { AssignUsersModal } from "./components/assign-users/assign-users-modal";
import { EmailTranscriptModal } from "./components/email-transcript-modal/email-transcript-modal";
import { useChatActions } from "./use-chat-actions";
import { useChatContext } from "./use-chat-context";
dayjs.extend(relativeTime);
const { confirm } = Modal;
// https://www.npmjs.com/package/react-horizontal-scrolling-menu

function showSyncConfirm(syncFbConversation: () => any) {
  confirm({
    title: "Are you sure you want to resync it?",
    content: "Conversation action traces might be lost",
    onOk() {
      syncFbConversation();
    },
  });
}

export const ChatView = ({
  conversationId,
  showContactInfo,
  showDeleteConversation,
}: {
  conversationId: string;
  showContactInfo?: boolean;
  showDeleteConversation?: boolean;
}) => {
  const {
    state: conversation,
    retry: reloadConversation,
    hasError: conversationNotFound,
  } = useQueryWithStore(
    selectConversationById(conversationId),
    loadConversationById(conversationId)(),
  );

  if (!conversation && conversationNotFound) {
    return (
      <div className="chat-view-container flex-1 flex flex-col items-stretch">
        <Result
          title={"Conversation Not Found"}
          icon={<i className="ri-error-warning-line text-5xl"></i>}
          className="flex-1 h-full flex flex-col items-center justify-center"
        />
      </div>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_ChatView
      conversationId={conversationId}
      showContactInfo={showContactInfo}
      showDeleteConversation={showDeleteConversation}
    />
  );
};

const _ChatView = ({
  conversationId,
  showContactInfo,
  showDeleteConversation,
}: {
  conversationId: string;
  showContactInfo?: boolean;
  showDeleteConversation?: boolean;
}) => {
  const store = useStore();
  const dispatch = useDispatch();

  const {
    joinConversation,
    leaveConversation,
    removeMembers,
    editTags,
    transferChat,
    changeSubject,
    isSubjectChangeProcessing,
    changeConversationStatus,
    isStatusChangeProcessing,
    syncFbConversation,
    isConversationSyncing,
    syncIgConversation,
    isIgConversationSyncing,
    syncWAPrivateConversationOld,
    isWAPrivateConversationSyncingOld,
    blockUser,
    isBlockingUser,
    syncWAPrivateConversationX,
    isWAPrivateConversationSyncingX,
    cancelTransfer,
    isCancelTransferInProgress,
    refreshMessagesList,
    isMessagesRefreshing,
    dismissAttentionFlag,
    isDismissingAttentionFlag,
    activateChatBot,
    isActivatingChatbot,
    deactivateChatBot,
    isDeactivatingChatbot,
    bidToJoin,
    isBidding,
    routeToUserGroup,
    isRouteToUserGroupProcessing,
    isWAPrivateConversationSyncing,
    syncWAPrivateConversation,
    permanentlyRemoveConversation,
    activateTranslation,
    isActivatingTranslation,
  } = useChatActions(conversationId);

  const {
    isAdvancedMode,
    conversation,
    reloadConversation,
    conversationNotFound,
    connection,
    showConnectionContext,
    currentUser,
    isMember,
    contact,
    conversationTags,
    userGroups,
    hasPermissionToJoin,
    forceUserToTag,
    tagGroupToForce,
    messagesCount,
    isMessagingWindowExpired,
    connectionRoutingStrategy,
    isBlockedForBidding,
    userRole,
    alreadyBided,
    chatConnectors,
    chatBots,
    reloadChatBots,
    chatConnectorActions,
  } = useChatContext(conversationId);

  const updateWatermark = useCallback(
    (timestamp: number) => {
      if (conversation) {
        const currentReadWatermark = conversation.metaData.readWatermarks.byUser
          ? conversation.metaData.readWatermarks.byUser[currentUser.id]
          : 0 || 0;

        if (
          timestamp < currentReadWatermark ||
          conversation.metaData.lastMessage.timestamp > currentReadWatermark
        ) {
          const newWatermark =
            timestamp < currentReadWatermark
              ? timestamp
              : conversation.metaData.lastMessage.timestamp;
          updateReadWatermark(conversation.id!, newWatermark).catch((e) => {
            console.log("Error while updating watermark", e);
          });
        }
        // Clear Local Unread
        dispatch(resetUnreadCount(conversation?.id!));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      currentUser?.id,
      conversation?.metaData.readWatermarks.byUser,
      conversation?.metaData.lastMessage,
      conversation?.id,
    ],
  );

  // const replyInputRef = useRef<HTMLInputElement>();

  const onReplyInputFocus = useCallback(() => {
    updateWatermark(conversation?.metaData.lastMessage.timestamp);
  }, [conversation?.metaData?.lastMessage?.timestamp, updateWatermark]);

  // On Input Focus, Update Watermark - Start
  // useEffect(() => {
  //   const { current } = replyInputRef;
  //   const handleFocus = () => {
  //     updateWatermark(conversation?.metaData.lastMessage.timestamp);
  //   };
  //   if (current && conversation) {
  //     current.addEventListener("focus", handleFocus);
  //   }
  //   return () => {
  //     if (current && conversation) {
  //       current.removeEventListener("focus", handleFocus);
  //     }
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   conversation?.metaData.lastMessage.timestamp,
  //   conversation?.metaData.readWatermarks,
  // ]);

  // On Input Focus, Update Watermark - End

  // On New Message, update Watermark - Start
  const onNewMessage = (timestamp: number) => {
    if (
      GetWindowVisibility() &&
      GetWindowFocus() &&
      document?.activeElement?.classList
        ?.toString?.()
        ?.includes?.("DraftEditor-content")
    ) {
      updateWatermark(timestamp);
    }
    // if (
    //   replyInputRef.current &&
    //   replyInputRef.current === document.activeElement
    // ) {

    // }
  };

  useEffect(() => {
    conversation && onNewMessage(conversation.metaData.lastMessage.timestamp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation?.metaData.lastMessage, onNewMessage]);
  // On New Message, update Watermark - End

  const [userPickerModalState, setUserPickerModalState] = useState(false);
  const [emailTranscriptModalVisibility, setEmailTranscriptModalVisibility] =
    useState(false);

  const [assignUsersModalVisibility, setAssignUsersModalVisibility] =
    useState(false);

  // If shorter conversation, unread indicator should be sent whey they select the conversation
  useEffect(() => {
    if (conversation?.metaData.totalMessages < 10) {
      updateWatermark(conversation?.metaData.lastMessage.timestamp);
    }
  }, [conversationId, conversation, updateWatermark]);

  const kbBindings = useMemo(() => {
    const chatActions: any[] = [];
    // Transfer Chat
    if (
      conversation?.status === "OPEN" &&
      conversation?.metaData.transferRequestLogs.length === 0
    ) {
      chatActions.push({
        id: "Transfer Chat",
        name: "Transfer Chat",
        shortcut: ["t"],
        keywords: `Transfer Chat`,
        perform: () => {
          setUserPickerModalState(true);
        },
      });
    }

    // Join Conversation
    if (!isMember && !isBlockedForBidding && hasPermissionToJoin) {
      chatActions.push({
        id: "Join Conversation",
        name: "Join Conversation",
        shortcut: ["j"],
        keywords: `Join Conversation`,
        perform: () => {
          joinConversation();
        },
      });
    }

    // Leave Conversation
    if (isMember) {
      chatActions.push({
        id: "Leave Conversation",
        name: "Leave Conversation",
        shortcut: ["l"],
        keywords: `Leave Conversation`,
        perform: () => {
          leaveConversation();
        },
      });
    }

    if (conversation?.status === "OPEN") {
      chatActions.push({
        id: "Close Conversation",
        name: "Close Conversation",
        shortcut: ["$mod+Backspace"],
        keywords: `Close Conversation`,
        perform: () => {
          changeConversationStatus("CLOSED");
        },
      });
    } else {
      chatActions.push({
        id: "Open Conversation",
        name: "Open Conversation",
        shortcut: ["$mod+o"],
        keywords: `Open Conversation`,
        perform: () => {
          changeConversationStatus("OPEN");
        },
      });
    }

    const otherShortcuts = [
      {
        id: "Focus Message Input",
        name: "Focus Message Input",
        shortcut: ["Space"],
        keywords: `Focus Message Input`,
        perform: () => {
          const inputElement =
            document.getElementsByClassName("w-full__input")[0] ||
            document.querySelector(".DraftEditor-editorContainer > div");
          if (inputElement) {
            (inputElement as any).focus();
          }
        },
      },
    ];
    return [...chatActions, ...otherShortcuts];
  }, [
    changeConversationStatus,
    conversation?.metaData.transferRequestLogs.length,
    conversation?.status,
    hasPermissionToJoin,
    isBlockedForBidding,
    isMember,
    joinConversation,
    leaveConversation,
  ]);

  useRegisterActions(kbBindings, [kbBindings]);

  const { changePanelState } = useModalPanels();

  const isMobileView = useMediaQuery({ query: "(max-width: 500px)" });

  const oldConversationTag = useMemo(() => {
    if (
      conversation?.metaData?.createdAt &&
      conversation?.metaData?.createdAt + 7 * 24 * 60 * 60 * 1000 < Date.now()
    ) {
      return dayjs(conversation.metaData.createdAt).fromNow();
    }
  }, [conversation?.metaData?.createdAt]);

  const {
    doAction: generateSubjectLineWithAI,
    isProcessing: isGeneratingSubjectLine,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (req) => SDK.processOpenAiPrompts(req),
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const autoGenerateSubjectLine = useCallback(async () => {
    const messagesState = selectMessageByConversationId(conversationId)(
      store.getState(),
    );
    const allMessages = messagesState.data
      .map((message) => ({
        from: (() => {
          switch (message.from.senderType) {
            case iMessageSenderType.CLIENT: {
              return "Customer";
            }
            case iMessageSenderType.USER: {
              return "Agent";
            }
          }
          return message.from.senderType;
        })(),
        message: message.message,
        hasAttachments: Boolean(message.attachments.length),
      }))
      .filter((e) => e.from !== iMessageSenderType.SYSTEM);
    const messageText = allMessages
      .map((item) => `${item.from}: "${item.message}"\n`)
      .join("");

    const subjectLinePrompt = `Write a subject line for the conversation between customer support agent and the customer. It needs to be single sentence under 12 words.`;

    const chatRating = await generateSubjectLineWithAI({
      type: "CHAT",
      instruction: subjectLinePrompt,
      input: messageText,
    });
    const trimmedVersion = (() => {
      const text = chatRating?.choices?.[0]?.message?.content || "".trim();

      if (text.charAt(0) === `"` && text.charAt(text.length - 1) === `"`) {
        return text.substring(1, text.length - 1);
      }
      return text;
    })();
    if (trimmedVersion) {
      changeSubject(trimmedVersion);
    }
  }, [generateSubjectLineWithAI, changeSubject, conversationId, store]);

  const autoTranslationDefaultLanguage = useSelector(
    selectAutoTranslationDefaultLanguage,
  );

  return (
    <div
      className="chat-view-container flex-1 flex flex-col items-stretch"
      data-click-context="Chat View"
      id={"chat-view-container"}
    >
      <div className="chat-header flex flex-row justify-between items-center px-6 py-2">
        <div className="contact-card flex flex-row items-center flex-1">
          <div
            className="avatar-container cursor-pointer"
            onClick={() => {
              changePanelState(ContactProfile, true, {
                contactId: contact?.id!,
              });
            }}
          >
            <ContactAvatar
              contactId={contact?.id!}
              connectionId={conversation?.connectionId}
              size={40}
            />
          </div>
          <div
            className="profile flex flex-col ml-2 flex-1"
            style={{ maxWidth: showContactInfo ? 620 : 420 }}
          >
            <div
              className="font-bold cursor-pointer flex flex-row"
              style={{ paddingLeft: 1 }}
              onClick={() => {
                changePanelState(ContactProfile, true, {
                  contactId: contact?.id!,
                });
              }}
            >
              {contact?.data.firstName}{" "}
              {oldConversationTag && (
                <Tag className="ml-2">{oldConversationTag}</Tag>
              )}
              {showContactInfo && (
                <div className="font-normal text-gray-600 ml-2">
                  <Space>
                    {contact?.data?.primaryEmail && (
                      <span className="flex flex-row">
                        | {contact.data.primaryEmail}
                      </span>
                    )}
                    {contact?.data?.primaryMobile && (
                      <span className="flex flex-row">
                        | {contact.data.primaryMobile}
                      </span>
                    )}
                  </Space>
                </div>
              )}
            </div>
            {false && (
              <Typography.Paragraph
                editable={
                  {
                    onChange: (text) => {
                      // Todo
                    },
                    // icon: processingFields.indexOf("data.firstName") > -1 && (
                    //   <LoadingOutlined />
                    // ),
                  } as any
                }
                className="font-bold text-lg"
              >
                {conversation.subject}
              </Typography.Paragraph>
            )}
            <div className="flex flex-row items-center w-full">
              <EditableText
                value={conversation?.subject}
                onSave={changeSubject}
                className="font-bold text-lg p-0 m-0 flex-1"
                placeholder="Untitled Conversation"
                isProcessing={isSubjectChangeProcessing}
              />
              {conversation?.connectionType !== "EMAIL" && (
                <Tooltip title="Auto Generate Chat Subject">
                  <Button
                    icon={<i className="ri-magic-line"></i>}
                    type="text"
                    // shape="circle"
                    onClick={() => {
                      autoGenerateSubjectLine().catch((e) => {});
                    }}
                    size="small"
                    loading={isGeneratingSubjectLine}
                  ></Button>
                </Tooltip>
              )}
            </div>
            <div className="flex justify-between">
              <div className="text-xs" style={{ paddingLeft: 1 }}>
                Last message:{" "}
                {dayjs(conversation?.metaData.lastMessage.timestamp).fromNow()}
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="right-side flex flex-row items-center">
          {/* Todo: Maybe you can add to pipeline from conversation view and modify the pipeline associated with the conversation */}
          {false && <PipelineSelector />}
          {showConnectionContext && (
            <div>
              <Tag>{connection?.label}</Tag>
            </div>
          )}
          {isAdvancedMode && (
            <>
              <Button
                icon={<i className="ri-code-box-line cursor-pointer" />}
                type="text"
                shape="circle"
                onClick={() => {
                  JSONModal(conversation);
                }}
              ></Button>
              {window.location.href.includes("localhost") && (
                <Popconfirm
                  placement="leftTop"
                  title="Are you sure?"
                  description="This cannot be undone"
                  onConfirm={() => {
                    permanentlyRemoveConversation("CONFIRM");
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Delete Conversation Permanently">
                    <Button
                      icon={<i className="ri-delete-bin-2-line"></i>}
                      type="text"
                      shape="circle"
                    ></Button>
                  </Tooltip>
                </Popconfirm>
              )}
            </>
          )}

          <ChatViewReminder conversationId={conversationId} />
          {(connection?.data as iEmailConnectionData)?.ticketWorkflowConfig
            ?.simpleTicketIds?.isEnabled ? (
            <>
              <Tooltip title="Copy TicketId Id">
                <Button
                  icon={<i className="ri-clipboard-line"></i>}
                  type="text"
                  onClick={() => {
                    copyToClipboard(
                      `#${conversation?.data?.simpleId}` || conversation?.id!,
                    );
                  }}
                >
                  #{conversation?.data?.simpleId}
                </Button>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Copy Conversation Id">
                <Button
                  icon={<i className="ri-clipboard-line"></i>}
                  type="text"
                  shape="circle"
                  onClick={() => {
                    copyToClipboard(conversation?.id!);
                  }}
                ></Button>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <Divider className="w-full my-0" />
      <div
        className="flex flex-row justify-start items-center py-2 px-4 action-bar"
        id="chat-actions-bar"
      >
        <ConversationTagSelector
          value={conversation?.tags}
          onChange={editTags}
          removeMinimumWidth={isMobileView}
        />

        {conversation?.members?.length > 0 && (
          <Divider type="vertical" className="h-full"></Divider>
        )}
        <div className="members mr-2 flex flex-row items-center">
          {conversation?.members?.length > 0 && (
            <AvatarGroup>
              {conversation.members.map((member) => (
                <Tooltip
                  title={getUserName(
                    selectUser(member.userId)(store.getState()),
                  )}
                  key={member.userId}
                >
                  <div className="avatar-container" key={member.userId}>
                    {conversation?.status === "OPEN" && (
                      <div
                        className="overlay"
                        onClick={() => removeMembers([member.userId])}
                      >
                        <i className="ri-subtract-line"></i>
                      </div>
                    )}

                    <UserAvatar userId={member.userId} key={member.userId} />
                  </div>
                </Tooltip>
              ))}
            </AvatarGroup>
          )}
        </div>

        {!isMobileView && (
          <>
            {conversation?.status !== "OPEN" && !isMessagingWindowExpired && (
              <Button
                type="primary"
                onClick={() => {
                  changeConversationStatus("OPEN");
                }}
                icon={<i className="ri-inbox-unarchive-line"></i>}
                loading={isStatusChangeProcessing}
              >
                Open Conversation
              </Button>
            )}
            {conversation?.status === "OPEN" && (
              <>
                {conversation?.metaData.transferRequestLogs.length > 0 &&
                  conversation?.metaData.transferRequestLogs[0].userId !==
                    currentUser.id && (
                    <div className="transfer-progress-container">
                      <Button
                        type="primary"
                        onClick={() =>
                          cancelTransfer(
                            conversation.metaData.transferRequestLogs[0].userId,
                          )
                        }
                      >
                        Transfer in Progress <i className="ri-close-line"></i>
                      </Button>
                    </div>
                  )}
                {conversation?.metaData.transferRequestLogs.length > 0 &&
                  conversation?.metaData.transferRequestLogs[0].userId ===
                    currentUser.id && (
                    <div className="transfer-progress-container">
                      <Button
                        type="primary"
                        onClick={() =>
                          cancelTransfer(
                            conversation.metaData.transferRequestLogs[0].userId,
                          )
                        }
                        icon={<i className="ri-check-line"></i>}
                      >
                        Accept Transfer
                      </Button>
                    </div>
                  )}

                <Divider type="vertical" className="h-full"></Divider>

                {!isMember && isBlockedForBidding && (
                  <Button
                    type="text"
                    onClick={bidToJoin}
                    className="action-button"
                    icon={<i className="ri-user-add-line"></i>}
                    loading={isBidding}
                    disabled={alreadyBided}
                  >
                    {alreadyBided ? "Awaiting bid results" : `Bid to join`}
                  </Button>
                )}
                {!isMember && !isBlockedForBidding && hasPermissionToJoin && (
                  <Button
                    type="text"
                    onClick={joinConversation}
                    className="action-button"
                    icon={<i className="ri-user-add-line"></i>}
                  >
                    Join Conversation{" "}
                    <KeyboardShortcut label="j" disableToolTip={true} />
                  </Button>
                )}
                {isMember && (
                  <Button
                    type="text"
                    onClick={leaveConversation}
                    className="action-button"
                    icon={<i className="ri-user-shared-2-line"></i>}
                  >
                    Leave Conversation{" "}
                    <KeyboardShortcut label="l" disableToolTip={true} />
                  </Button>
                )}
              </>
            )}
          </>
        )}

        <Dropdown
          trigger={["click"]}
          placement="bottomRight"
          overlay={
            <Menu
              className="chat-actions-drop-down"
              data-click-context="Chat Menu"
            >
              {conversation?.status !== "OPEN" && (
                <Menu.Item
                  key="OPEN_CHAT"
                  onClick={() => {
                    changeConversationStatus("OPEN");
                  }}
                  icon={<i className="ri-inbox-unarchive-line"></i>}
                  disabled={isStatusChangeProcessing}
                >
                  Open Conversation <KeyboardShortcut label="$mod+o" />
                  {isStatusChangeProcessing && (
                    <LoadingIndicatorWithSpin fontSize={"inherit"} />
                  )}
                </Menu.Item>
              )}
              {conversation?.status === "OPEN" && (
                <Menu.Item
                  key="CLOSE_CHAT"
                  onClick={() => {
                    changeConversationStatus("CLOSED");
                  }}
                  icon={<i className="ri-inbox-archive-line"></i>}
                  disabled={isStatusChangeProcessing}
                >
                  Close Conversation <KeyboardShortcut label="$mod+Backspace" />
                  {isStatusChangeProcessing && (
                    <LoadingIndicatorWithSpin fontSize={"inherit"} />
                  )}
                </Menu.Item>
              )}

              {isMobileView && (
                <>
                  {conversation?.status === "OPEN" && (
                    <>
                      {conversation?.metaData.transferRequestLogs.length > 0 &&
                        conversation?.metaData.transferRequestLogs[0].userId !==
                          currentUser.id && (
                          <Menu.Item
                            key="TRANSFER_IN_PROGRESS"
                            onClick={() =>
                              cancelTransfer(
                                conversation.metaData.transferRequestLogs[0]
                                  .userId,
                              )
                            }
                            icon={<i className="ri-close-line"></i>}
                          >
                            Transfer in Progress{" "}
                          </Menu.Item>
                        )}
                      {conversation?.metaData.transferRequestLogs.length > 0 &&
                        conversation?.metaData.transferRequestLogs[0].userId ===
                          currentUser.id && (
                          <Menu.Item
                            key="ACCEPT_TRANSFER"
                            onClick={() =>
                              cancelTransfer(
                                conversation.metaData.transferRequestLogs[0]
                                  .userId,
                              )
                            }
                            icon={<i className="ri-check-line"></i>}
                          >
                            Accept Transfer
                          </Menu.Item>
                        )}

                      {!isMember && isBlockedForBidding && (
                        <Menu.Item
                          key="BIDDING_PROGRESS"
                          onClick={() => bidToJoin()}
                          icon={<i className="ri-user-add-line"></i>}
                          disabled={isBidding || alreadyBided}
                        >
                          {alreadyBided
                            ? "Awaiting bid results"
                            : `Bid to join`}
                        </Menu.Item>
                      )}
                      {!isMember &&
                        !isBlockedForBidding &&
                        hasPermissionToJoin && (
                          <Menu.Item
                            key="JOIN_CONVERSATION"
                            onClick={() => joinConversation()}
                            icon={<i className="ri-user-add-line"></i>}
                          >
                            Join Conversation{" "}
                          </Menu.Item>
                        )}
                      {isMember && (
                        <Menu.Item
                          key="LEAVE_CONVERSATION"
                          onClick={() => leaveConversation()}
                          icon={<i className="ri-user-shared-2-line"></i>}
                        >
                          Leave Conversation{" "}
                        </Menu.Item>
                      )}
                    </>
                  )}
                </>
              )}

              {conversation?.status === "OPEN" && (
                <Menu.Item
                  key="ASSIGN_USER"
                  onClick={() => setAssignUsersModalVisibility(true)}
                  icon={<i className="ri-user-add-line"></i>}
                  disabled={isBlockedForBidding}
                >
                  Assign User{" "}
                  <KeyboardShortcut label="a" disableToolTip={true} />
                </Menu.Item>
              )}

              {conversation?.status === "OPEN" &&
                conversation?.metaData.transferRequestLogs.length === 0 && (
                  <Menu.Item
                    key="TRANSFER_CHAT"
                    onClick={() => setUserPickerModalState(true)}
                    icon={<i className="ri-chat-forward-line"></i>}
                    disabled={isBlockedForBidding}
                  >
                    Transfer Chat{" "}
                    <KeyboardShortcut label="t" disableToolTip={true} />
                  </Menu.Item>
                )}

              {/* Todo: */}

              {conversation?.status === "OPEN" &&
                conversation?.metaData.transferRequestLogs.length === 0 &&
                userGroups.length > 0 && (
                  <Menu.SubMenu
                    key="ROUTE_TO"
                    icon={<i className="ri-chat-forward-line"></i>}
                    disabled={isBlockedForBidding}
                    title={
                      <span className="inline-flex flex-row ml-1">
                        Route To{" "}
                        {isRouteToUserGroupProcessing && (
                          <LoadingIndicatorWithSpin fontSize={"inherit"} />
                        )}
                      </span>
                    }
                  >
                    {userGroups.map((userGroup) => (
                      <Menu.Item
                        key={userGroup.id}
                        onClick={() => routeToUserGroup(userGroup.id)}
                        disabled={isBlockedForBidding}
                        // loading={isRouteToUserGroupProcessing}
                      >
                        {userGroup.label}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                )}

              <Menu.Item
                key="EMAIL_TRANSCRIPT"
                onClick={() => setEmailTranscriptModalVisibility(true)}
                icon={<i className="ri-mail-send-fill"></i>}
              >
                Email Transcript
              </Menu.Item>

              {conversation?.connectionType === "FACEBOOK" && (
                <Menu.Item
                  key="OPEN_ORIGINAL"
                  icon={<i className="ri-share-forward-line"></i>}
                  onClick={() => {
                    const url = `https://facebook.com/${
                      (conversation.data as iConversationFacebookData)
                        .fbConversationRef.link
                    }`;
                    const newWindow = window.open(url, "_blank");
                  }}
                >
                  Open Original
                </Menu.Item>
              )}

              {userRole === "Owner" &&
                conversation?.connectionType === "FACEBOOK" && (
                  <Menu.Item
                    key="FB_BLOCK_USER"
                    onClick={() =>
                      blockUser(
                        conversation.connectionId,
                        (conversation.data as iConversationFacebookData)
                          .fbUserId,
                      )
                    }
                    icon={<i className="ri-forbid-2-line"></i>}
                  >
                    Block User
                    {isBlockingUser && (
                      <LoadingIndicatorWithSpin fontSize={"inherit"} />
                    )}
                  </Menu.Item>
                )}
              {conversation?.connectionType === "FACEBOOK" && (
                <Menu.Item
                  key="SYNC_MESSAGES_FB"
                  onClick={() => showSyncConfirm(syncFbConversation)}
                  icon={<i className="ri-refresh-line"></i>}
                >
                  Sync Messages{" "}
                  {isConversationSyncing && (
                    <LoadingIndicatorWithSpin fontSize={"inherit"} />
                  )}
                </Menu.Item>
              )}
              {conversation?.connectionType === "INSTAGRAM" && (
                <Menu.Item
                  key="SYNC_MESSAGES_IG"
                  icon={<i className="ri-refresh-line"></i>}
                  onClick={() => showSyncConfirm(syncIgConversation)}
                >
                  Sync Messages
                  {isIgConversationSyncing && (
                    <LoadingIndicatorWithSpin fontSize={"inherit"} />
                  )}
                </Menu.Item>
              )}

              {conversation?.connectionType === "WHATS_APP_PRIVATE" && (
                <Menu.Item
                  key="SYNC_MESSAGES_WA_PRIVATE"
                  onClick={() => showSyncConfirm(syncWAPrivateConversation)}
                  icon={<i className="ri-refresh-line"></i>}
                >
                  Sync Messages
                  {isWAPrivateConversationSyncing && (
                    <LoadingIndicatorWithSpin fontSize={"inherit"} />
                  )}
                </Menu.Item>
              )}

              <Menu.Item
                key="REFRESH_MESSAGES"
                onClick={() => refreshMessagesList()}
                icon={<i className="ri-refresh-line"></i>}
              >
                Refresh Messages
                {isMessagesRefreshing && (
                  <LoadingIndicatorWithSpin fontSize={"inherit"} />
                )}
              </Menu.Item>
              {conversation?.activeBot && (
                <Menu.Item
                  key="DEACTIVATE_CHAT_BOT"
                  onClick={() => deactivateChatBot()}
                  icon={<i className="ri-robot-line"></i>}
                >
                  Deactivate Chatbot{" "}
                  {isDeactivatingChatbot && (
                    <LoadingIndicatorWithSpin fontSize={"inherit"} />
                  )}
                </Menu.Item>
              )}
              {!conversation?.activeBot && chatBots.length > 0 && (
                <Menu.SubMenu
                  icon={<i className="ri-robot-line"></i>}
                  title={<span className="ml-1">Handover to Bot</span>}
                >
                  {chatBots.map((item) => (
                    <Menu.Item
                      key={item.id}
                      onClick={() => activateChatBot(item.id)}
                      icon={<i className="ri-robot-line"></i>}
                    >
                      {item.label}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              )}

              <Menu.Item
                key="ACTIVATE_TRANSLATION"
                onClick={() =>
                  activateTranslation(
                    !conversation?.metaData.autoTranslation?.isEnabled,
                    autoTranslationDefaultLanguage,
                  )
                }
                icon={<i className="ri-translate"></i>}
              >
                {!conversation?.metaData?.autoTranslation?.isEnabled
                  ? "Activate"
                  : "Deactivate"}{" "}
                Translation
                {isActivatingTranslation && (
                  <LoadingIndicatorWithSpin fontSize={"inherit"} />
                )}
              </Menu.Item>

              <Menu.Item
                key="VIEW_CHAT_SUMMARY"
                onClick={() =>
                  changePanelState(ConversationSummary, true, {
                    conversationId,
                  })
                }
                icon={<MDIIcon icon={mdiCommentEyeOutline} />}
              >
                View Chat Summary
              </Menu.Item>

              {/* 
              <Menu.Item
                key="PERMANENTLY_DELETE_CONVERSATION"
                onClick={() =>
                  changePanelState(ConversationSummary, true, {
                    conversationId,
                  })
                }
                icon={<i className="ri-delete-bin-line"></i>}
              >
                Permanently Delete Chat
              </Menu.Item> */}

              {chatConnectorActions.length > 0 && (
                <>
                  <Divider className="my-0 w-full"></Divider>
                  <Menu.Item
                    key="CONNECTORS"
                    className="font-bold text-sm px-4"
                  >
                    Connectors
                  </Menu.Item>
                  {chatConnectorActions.map((item) => (
                    <SimpleChatConnectorAction
                      key={`CONNECTOR_${item.id}`}
                      id={item.id}
                      actionData={item.data as iConnectorConversationActionData}
                      conversationId={conversationId}
                    />
                  ))}
                </>
              )}

              {userRole === "Owner" && showDeleteConversation && (
                <>
                  <Divider className="my-0 w-full mt-2"></Divider>
                  <Popconfirm
                    placement="leftTop"
                    title="Are you sure?"
                    description="This cannot be undone"
                    onConfirm={() => {
                      permanentlyRemoveConversation("CONFIRM");
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title="Delete Conversation Permanently">
                      <Button
                        icon={<i className="ri-delete-bin-2-line"></i>}
                        type="text"
                        block
                        className="justify-start pl-4"
                      >
                        {" "}
                        Permanently Delete Chat
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </>
              )}
            </Menu>
          }
        >
          <Button
            type="text"
            icon={<i className="ri-more-2-fill"></i>}
            className="px-2 mx-1"
          ></Button>
        </Dropdown>
      </div>

      <Divider className="my-0 w-full"></Divider>
      {conversation?.metaData?.flags?.requiresAttention?.isActive && (
        <div className="bg-yellow-200 dark:bg-yellow-400 text-yellow-800 font-bold text-center w-full flex flex-row justify-between items-center px-4">
          <div className="left">
            <i className="ri-alarm-warning-line mr-2"></i>
            Conversation requires Attention
          </div>
          <div className="right">
            <Button
              icon={<i className="ri-indeterminate-circle-line"></i>}
              type="text"
              onClick={() => dismissAttentionFlag("requiresAttention")}
              loading={isDismissingAttentionFlag}
              className="dark:text-yellow-800"
            >
              Dismiss
            </Button>
          </div>
        </div>
      )}
      {conversation?.metaData?.flags?.requiresFollowup?.isActive && (
        <div className="bg-yellow-200 dark:bg-yellow-400 text-yellow-800 font-bold text-center w-full flex flex-row justify-between items-center px-4">
          <div className="left">
            <i className="ri-flag-line mr-2"></i>
            Conversation requires a Followup
          </div>
          <div className="right">
            <Button
              icon={<i className="ri-indeterminate-circle-line"></i>}
              type="text"
              onClick={() => dismissAttentionFlag("requiresFollowup")}
              loading={isDismissingAttentionFlag}
              className="dark:text-yellow-800"
            >
              Dismiss
            </Button>
          </div>
        </div>
      )}
      {conversation?.status === "CLOSED" && (
        <div className="archived-indicator  bg-yellow-20 dark:bg-yellow-400 text-yellow-800 font-bold text-center w-full">
          Conversation has been closed
        </div>
      )}

      <MessagesList
        conversationId={conversationId}
        onUpdateWatermark={updateWatermark}
      />

      {/* Chat Footer */}

      {true && (
        <>
          {/* <Divider className="w-full" /> */}
          {conversation?.status === "OPEN" && !isMessagingWindowExpired && (
            <>
              {!isMember && !isBlockedForBidding && hasPermissionToJoin && (
                <div className="chat-footer flex-row flex justify-center items-center py-4">
                  <Button onClick={joinConversation}>
                    Join conversation to send message{" "}
                    <KeyboardShortcut label="j" />
                  </Button>
                </div>
              )}
              {!isMember && isBlockedForBidding && (
                <div className="chat-footer flex-row flex justify-center items-center py-4">
                  Chat routing is in progress
                </div>
              )}

              {isMember &&
                (!forceUserToTag || (forceUserToTag && messagesCount < 7)) && (
                  <>
                    {conversation?.activeBot && (
                      <div className="chat-footer flex-row flex justify-center items-center py-4">
                        <Button
                          onClick={deactivateChatBot}
                          icon={<i className="ri-robot-line"></i>}
                        >
                          Deactivate Chatbot
                        </Button>
                      </div>
                    )}
                    {!conversation?.activeBot && (
                      <div className="chat-view-input">
                        <ChatInputPlayground
                          conversationId={conversationId}
                          onReplyInputFocus={onReplyInputFocus}
                        />
                        {/* <ChatInput
                          conversationId={conversationId}
                          inputRef={replyInputRef}
                        /> */}
                      </div>
                    )}
                  </>
                )}
              {isMember && forceUserToTag && messagesCount >= 7 && (
                <div className="chat-view-input p-4 font-bold flex justify-center items-center">
                  Please tag the conversation to continue chatting{" "}
                  {tagGroupToForce.length > 0 && "- "}
                  {tagGroupToForce.join(", ")}
                </div>
              )}
            </>
          )}

          {conversation?.status === "OPEN" && isMessagingWindowExpired && (
            <>
              {(conversation?.connectionType === "FACEBOOK" ||
                conversation?.connectionType === "INSTAGRAM") && (
                <>
                  <div className="chat-view-input p-4 text-gray-600 text-sm flex flex-col justify-center items-center">
                    Messaging window has been expired. You cannot send a message
                    unless otherwise the customer sends a message
                    <Button
                      type="text"
                      href={`https://facebook.com/${
                        (conversation.data as iConversationFacebookData)
                          .fbConversationRef.link
                      }`}
                      target="_blank"
                      className="action-button mt-2"
                      icon={<i className="ri-share-forward-line"></i>}
                    >
                      Open Original
                    </Button>
                  </div>
                </>
              )}

              {(conversation?.connectionType === "WHATS_APP_CLOUD" ||
                conversation?.connectionType === "WHATS_APP_CLOUD_NATIVE") && (
                <>
                  <div className="chat-view-input p-4 text-gray-600 text-sm flex flex-col justify-center items-center">
                    Messaging window has been expired. You cannot send a message
                    unless otherwise the customer sends a message
                    <Button
                      type="text"
                      onClick={() => {
                        changePanelState(NewMessageCreator, true, {
                          contactId: conversation?.contactId,
                          connectionId: conversation?.connectionId,
                        });
                      }}
                      className="action-button mt-2"
                      icon={<i className="ri-share-forward-line"></i>}
                    >
                      Send Templated Message
                    </Button>
                  </div>
                </>
              )}
            </>
          )}

          {conversation?.status === "CLOSED" && conversation?.chatRating && (
            <div className="chat-view-input p-4 font-bold flex justify-center items-center">
              <div className="text-gray-600 mr-4">
                Customer has rated the conversation
              </div>
              <Rate value={conversation?.chatRating?.rating} disabled={true} />
            </div>
          )}
        </>
      )}

      <UserPickerModal
        visible={userPickerModalState}
        onChangeVisibility={setUserPickerModalState}
        onUserSelected={async (user) => {
          const hide = message.loading("Processing", 0);
          try {
            await transferChat(user);
            setUserPickerModalState(false);
            hide();
          } catch (e) {
            hide();
          }
        }}
        title={
          <ModalTitle
            title="Pick a user to transfer"
            icon={<i className="ri-mail-send-fill"></i>}
          />
        }
      />

      <EmailTranscriptModal
        visible={emailTranscriptModalVisibility}
        onChangeVisibility={setEmailTranscriptModalVisibility}
        conversationId={conversationId}
      />

      <AssignUsersModal
        visible={assignUsersModalVisibility}
        onChangeVisibility={setAssignUsersModalVisibility}
        conversationId={conversationId}
      />
    </div>
  );
};
