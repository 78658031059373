import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { useSDK } from "@sdk";
import { iContact } from "@sdk/crm/crm.models";
import { Button, Card, Divider, Select } from "antd";
import { Avatar } from "components/common/avatar/avatar";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ContactSearchQueryBuilder } from "../../helpers/contact-search-query-builder";
import { getContactName } from "../../helpers/get-contact-name";
import "./multi-contact-selector.scss";

import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { AddContact } from "modal-registry";
import { useStore } from "react-redux";
import { setContacts } from "store/modules/contacts/contacts.slice";
import { ExtractEmailsAddresses } from "utils/extract-email-addresses";
import { ExtractNameFromString } from "utils/extract-first-and-last-name";
import { ExtractPhoneNumbers } from "utils/extract-phone-numbers";
import { justWait } from "utils/just-wait";

const { Meta } = Card;

export const MultiContactSelector = ({
  selectedContacts,
  setSelectedContacts,
  requiredData,
  autoFocus,
}: {
  selectedContacts: string[];
  setSelectedContacts: (ids: string[]) => any;
  requiredData?: "EMAIL" | "MOBILE";
  autoFocus?: boolean;
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { data: originalData, isLoading } = useSDK(
    (SDK) =>
      SDK.queryContacts({
        query: (() => {
          const query = ContactSearchQueryBuilder(searchQuery);
          if (requiredData === "EMAIL") {
            (query as any)["data.primaryEmail"] = { $exists: true, $ne: "" };
          }
          if (requiredData === "MOBILE") {
            (query as any)["data.primaryMobile"] = { $exists: true, $ne: "" };
          }
          return query;
        })(),
        options: {
          offset: 0,
          page: 1,
          limit: 20,
        },
      }),
    [searchQuery, requiredData],
    false,
    {},
  );

  const store = useStore();

  useEffect(() => {
    if (originalData.docs) {
      store.dispatch(setContacts(originalData.docs));
    }
  }, [originalData, store]);

  const getContactExtraDetail = useMemo(() => {
    return (contact: iContact) => {
      if (requiredData === "EMAIL") {
        return contact.data.primaryEmail;
      } else if (requiredData === "MOBILE") {
        return contact.data.primaryMobile;
      } else {
        return contact.data.primaryEmail || contact.data.primaryMobile || "";
      }
    };
  }, [requiredData]);

  const { triggerTempModal } = useModalPanels();

  const showAddContactModal = useCallback(
    async (delay?: number) => {
      const emailAddresses = ExtractEmailsAddresses(searchQuery);
      const phoneNumbers = ExtractPhoneNumbers(searchQuery);
      const { firstName, lastName } = ExtractNameFromString(
        [...emailAddresses, ...phoneNumbers].reduce((acc, curr) => {
          return acc.replace(curr, "");
        }, searchQuery),
      );

      setSelectOpenState(false);
      await justWait(delay || 0);
      triggerTempModal(AddContact, {
        onCreated: (contact) => {
          setTimeout(() => {
            setSelectedContacts([
              ...selectedContacts,
              {
                key: contact.id!,
                value: contact.id!,
                label: (
                  <div className="user-pill leading-normal">
                    <div className="flex-row flex justify-start items-center">
                      <Avatar name={contact.data.firstName} />
                      <div className="flex-col pl-3">
                        <div className="font-bold">
                          {getContactName(contact)}
                        </div>
                        <div className="description text-gray-600 dark:text-gray-400 text-sm mode_transition">
                          {getContactExtraDetail(contact)}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              } as any,
            ]);
          }, 200);
        },
        isMinimal: true,
        hideOnCreate: true,
        prefill: {
          firstName: firstName,
          lastName: lastName,
          primaryEmail: emailAddresses[0],
          primaryMobile: phoneNumbers[0],
        },
      });
    },
    [
      getContactExtraDetail,
      searchQuery,
      selectedContacts,
      setSelectedContacts,
      triggerTempModal,
    ],
  );

  const [isSelectOpen, setSelectOpenState] = useState(false);

  return (
    <>
      <Select
        mode="multiple"
        showSearch
        labelInValue
        value={selectedContacts}
        placeholder="Select Contacts"
        filterOption={false}
        onSearch={(val) => setSearchQuery(val)}
        onChange={(contactIds, option) => {
          console.log("contactIds", contactIds);
          setSelectedContacts(contactIds);
        }}
        style={{ width: "100%" }}
        size="large"
        autoFocus={autoFocus}
        open={isSelectOpen}
        onDropdownVisibleChange={setSelectOpenState}
        // defaultOpen={autoFocus}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <div
              className="user-pill leading-normal cursor-pointer p-2 hover:bg-gray-200 dark:bg-gray-800"
              onClick={() => showAddContactModal(200)}
            >
              <div className="flex-row flex justify-start items-center">
                <i className="ri-add-line" />
                <div className="flex-col pl-3">
                  <div className="font-bold">Create New Contact</div>
                  <div className="description text-gray-600 dark:text-gray-400 text-sm mode_transition">
                    {searchQuery}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      >
        {(originalData?.docs || []).map((contact) => (
          <Select.Option key={contact.id} value={contact.id!}>
            <div className="user-pill leading-normal">
              <div className="flex-row flex justify-start items-center">
                <Avatar name={contact.data.firstName} />
                <div className="flex-col pl-3">
                  <div className="font-bold">{getContactName(contact)}</div>
                  <div className="description text-gray-600 dark:text-gray-400 text-sm mode_transition">
                    {getContactExtraDetail(contact)}
                  </div>
                </div>
              </div>
            </div>
          </Select.Option>
        ))}
      </Select>
      <div className="flex-row flex justify-end items-center">
        <Button
          onClick={() => showAddContactModal()}
          icon={<PlusOutlined />}
          type="link"
          tabIndex={-1}
        >
          New Contact
        </Button>
      </div>
    </>
  );
};
