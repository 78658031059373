import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import { iCustomField } from "@sdk/dynamic-forms/dynamic-forms-model";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Tag,
} from "antd";
import { ModalTitle } from "components/common/modal-title";
import { SimpleCollapsePane } from "components/common/simple-collapse-pane/simple-collapse-pane";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect, useState } from "react";
import { uuidv4 } from "utils/generate-uuid";

const { Option } = Select;

export const FormFieldEditorModal = ({
  visible,
  initialValue,
  onSave,
  onCancel,
  mode,
}: {
  visible: boolean;
  initialValue?: iCustomField;
  onSave: (formItem: iCustomField) => any;
  onCancel: () => any;
  mode: "ADD" | "EDIT";
}) => {
  const [form] = Form.useForm();

  const [configVisibility, setConfigVisibility] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [form, visible]);

  const getFieldConfigData = useCallback(() => {
    const formValue = form.getFieldsValue() as iCustomField;
    if (formValue.inputConfig.options) {
      formValue.inputConfig.options = formValue.inputConfig.options.map(
        (option) => ({
          value: option.label,
          label: option.label,
        })
      );
    }
    formValue.id = initialValue?.id! || uuidv4();
    formValue.key = formValue.id;
    return formValue;
  }, [form, initialValue]);

  return (
    <Modal
      open={visible}
      title={
        <ModalTitle
          title={mode === "ADD" ? "Add Question" : "Edit Question"}
          icon={<i className="ri-pencil-line"></i>}
        />
      }
      okText="Save"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const fieldConfig = getFieldConfigData();
            onSave(fieldConfig);
            form.resetFields();
          })
          .catch((info) => {
            message.warning("Please check your input");
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{
        icon: <i className="ri-check-line"></i>,
        className: "font-bold",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={initialValue}
      >
        <Form.Item
          name="label"
          label={<div className="font-bold">Question</div>}
          rules={[
            {
              required: true,
              message: "Please enter a question",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label={<div className="font-bold">Additional Text</div>}
          rules={[]}
        >
          <Input />
        </Form.Item>

        <div className="flex items-center mb-3">
          <Form.Item
            label=""
            name={["inputConfig", "isRequired"]}
            // valuePropName="checked"
            className="m-0"
          >
            <Checkbox />
          </Form.Item>
          <div className="pl-2">Answer is required</div>
        </div>
        <Form.Item
          name="inputType"
          label={<div className="font-bold">User Input Type</div>}
          rules={[
            {
              required: true,
              message: "Please input the type for the field!",
            },
          ]}
        >
          <Select>
            <Select.OptGroup label="Simple">
              <Option value="TEXT">Text Field (Short Text)</Option>
              <Option value="TEXTAREA">Text Field (Long Text)</Option>
              <Option value="DATE">Date Field</Option>
              <Option value="NUMBER">Number Field</Option>
              <Option value="SWITCH">Toggle</Option>
              <Option value="CHECK_BOX">Simple Check Box</Option>
            </Select.OptGroup>

            <Select.OptGroup label="Multiple Options">
              <Option value="SELECT">Selectable Dropdown (Single)</Option>
              <Option value="MULTI_SELECT">
                Selectable Dropdown (Multiple)
              </Option>
              <Option value="RADIO">Simple Option List</Option>
            </Select.OptGroup>

            <Select.OptGroup label="Special">
              <Option value="EMAIL">Email Address</Option>
              <Option value="PHONE">Phone Number</Option>
            </Select.OptGroup>
          </Select>
        </Form.Item>

        <Form.Item
          shouldUpdate
          noStyle
          style={{ marginBottom: 4, marginLeft: 12 }}
        >
          {({ getFieldValue }) => {
            const fieldType = getFieldValue(["inputType"]);
            if (["SELECT", "MULTI_SELECT", "RADIO"].includes(fieldType)) {
              return (
                <Form.List
                  name={["inputConfig", "options"]}
                  rules={[
                    {
                      validator: async (rule, value) => {
                        if (!value?.[0]?.label) {
                          throw "At least one option is required";
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field) => (
                        <Space
                          key={field.key}
                          style={{ display: "flex" }}
                          align="baseline"
                        >
                          <Form.Item
                            {...field}
                            name={[field.name, "label"]}
                            rules={[
                              {
                                required: true,
                                message: "Enter an option",
                              },
                            ]}
                            style={{ marginBottom: 4 }}
                          >
                            <Input placeholder="Option Label" />
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </Space>
                      ))}
                      <div
                        onClick={() =>
                          add({
                            label: "",
                            /*type: "work", phone: ""*/
                          })
                        }
                        className="cursor-pointer"
                      >
                        + add an option
                      </div>
                      <Form.ErrorList errors={errors} />
                    </>
                  )}
                </Form.List>
              );
            }
          }}
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const fieldType = getFieldValue(["inputType"]);
            const shouldShowAdvanced = [
              "TEXT",
              "TEXTAREA",
              "EMAIL",
              "PHONE",
            ].includes(fieldType);
            if (shouldShowAdvanced) {
              return (
                <>
                  <div className="mb-8">
                    <Button
                      type="link"
                      onClick={() => setConfigVisibility(!configVisibility)}
                      icon={
                        configVisibility ? (
                          <i className="ri-arrow-up-s-line text-2xl"></i>
                        ) : (
                          <i className="ri-arrow-down-s-line text-2xl"></i>
                        )
                      }
                      block
                    >
                      Advanced
                    </Button>
                  </div>

                  <SimpleCollapsePane isCollapsed={configVisibility}>
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const fieldType = getFieldValue(["inputType"]);
                        if (["TEXT", "TEXTAREA"].includes(fieldType)) {
                          return (
                            <>
                              <div className="flex flex-col justify-center items-center mb-8">
                                <Tag className="font-bold">
                                  Input Validation
                                </Tag>
                              </div>

                              <Form.Item
                                name={["inputConfig", "regex"]}
                                label={
                                  <div className="font-bold">
                                    Validate Input Using Regular Expression
                                  </div>
                                }
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value) {
                                        return Promise.resolve();
                                      }
                                      try {
                                        new RegExp(value);
                                        return Promise.resolve();
                                      } catch (e) {
                                        return Promise.reject(
                                          new Error(
                                            "Please enter a valid regex"
                                          )
                                        );
                                      }
                                    },
                                  }),
                                ]}
                                help="Leave it blank to disable regex validation"
                              >
                                <Input placeholder="Regex Expression" />
                              </Form.Item>
                            </>
                          );
                        }
                        return <></>;
                      }}
                    </Form.Item>

                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const fieldType = getFieldValue(["inputType"]);

                        let options: { value: string; label: string }[] = [];
                        // Text Field
                        if (["TEXT"].includes(fieldType)) {
                          options.push({
                            value: "CONTACT_NAME",
                            label: "Contact Name",
                          });
                        }
                        // Email Field
                        if (["EMAIL"].includes(fieldType)) {
                          options.push({
                            value: "CONTACT_EMAIL",
                            label: "Contact Email",
                          });
                        }
                        // Phone Field
                        if (["PHONE"].includes(fieldType)) {
                          options.push({
                            value: "CONTACT_MOBILE_NUMBER",
                            label: "Contact Mobile Number",
                          });
                        }
                        if (options.length > 0) {
                          return (
                            <>
                              <div className="flex flex-col justify-center items-center mt-8">
                                <Tag className="font-bold">
                                  Connect Input Field
                                </Tag>
                              </div>
                              <Form.Item
                                label={
                                  <div className="font-bold">
                                    Persistence Target
                                  </div>
                                }
                                name={["inputConfig", "mappedField"]}
                                help="Automatically save the input data to a system field when submitted"
                              >
                                <Select style={{ minWidth: 150 }}>
                                  <Select.Option value="NONE">
                                    None
                                  </Select.Option>
                                  {options.map((option) => (
                                    <Select.Option
                                      value={option.value}
                                      key={option.value}
                                    >
                                      {option.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </>
                          );
                        }

                        return <></>;
                      }}
                    </Form.Item>
                  </SimpleCollapsePane>
                </>
              );
            }
          }}
        </Form.Item>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
