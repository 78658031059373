import { EntityServices } from "@sdk/utils/entity.service";
import axios, { AxiosResponse } from "axios";
import { UserTracker } from "user-tracker";
import { iAppFeatureRequest } from "./app-feature-requests-model";

export class AppFeatureRequestsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  appFeatureRequests = Object.assign(
    new EntityServices<iAppFeatureRequest>(
      this.config,
      "app-feature-requests",
      {
        onCreate: () => {
          UserTracker.track("APP_FEATURE_REQUESTS - Create", {});
        },
        onDelete: () => {
          UserTracker.track("APP_FEATURE_REQUESTS - Delete", {});
        },
      },
    ),
    {
      vote: async (requestId: string) => {
        const results: AxiosResponse<iAppFeatureRequest> = await axios.post(
          this.config.basePath + `/app-feature-requests/${requestId}/vote`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      unVote: async (requestId: string) => {
        const results: AxiosResponse<iAppFeatureRequest> = await axios.post(
          this.config.basePath + `/app-feature-requests/${requestId}/un-vote`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      addComment: async (requestId: string, comment: string) => {
        const results: AxiosResponse<iAppFeatureRequest> = await axios.post(
          this.config.basePath + `/app-feature-requests/${requestId}/comments`,
          { comment },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      removeComment: async (requestId: string, commentId: string) => {
        const results: AxiosResponse<iAppFeatureRequest> = await axios.delete(
          this.config.basePath +
            `/app-feature-requests/${requestId}/comments/${commentId}`,

          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      // Team Actions
      publishTeamUpdates: async (requestId: string) => {
        const results: AxiosResponse<iAppFeatureRequest> = await axios.post(
          this.config.basePath +
            `/app-feature-requests/${requestId}/publish-team-update`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      queryUsers: async (searchText: string) => {
        const results: AxiosResponse<
          {
            id: string;
            organizationId: string;
            firstName: string;
            lastName: string;
            email: string;
            avatar: string;
            workspaceDomain: string;
            workspaceName: string;
          }[]
        > = await axios.post(
          this.config.basePath + `/app-feature-requests/query-users`,
          {
            searchText,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      addVote: async (
        requestId: string,
        user: {
          userId: string;
          organizationId: string;
          name: string;
          avatar: string;
        },
      ) => {
        const results: AxiosResponse<iAppFeatureRequest> = await axios.post(
          this.config.basePath + `/app-feature-requests/${requestId}/add-vote`,
          user,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      removeVote: async (requestId: string, userId: string) => {
        const results: AxiosResponse<iAppFeatureRequest> = await axios.post(
          this.config.basePath +
            `/app-feature-requests/${requestId}/remove-vote`,
          {
            userId,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
