import { useEffect, useMemo, useState } from "react";

export const useLocalStorageStore = <T extends unknown>(
  key: string,
  initialValue: T
) => {
  const type = useMemo(() => {
    switch (typeof initialValue) {
      case "string": {
        return "STRING";
      }
      case "number": {
        return "NUMBER";
      }
      case "object": {
        return "JSON";
      }
      case "boolean": {
        return "BOOLEAN";
      }
      default: {
        return "STRING";
      }
    }
  }, [initialValue]);

  const localStorageAccessAvailable = useMemo(() => {
    try {
      if (localStorage.getItem(key)) {
        //
      }
      return true;
    } catch (e) {
      console.log("Third Party Storage is blocked");
      return false;
    }
  }, [key]);

  const initialLocalStateValue = useMemo(() => {
    const value = localStorageAccessAvailable
      ? window.localStorage.getItem(key) || ""
      : "";
    if (type === "JSON") {
      try {
        return JSON.parse(value) || initialValue;
      } catch (e) {
        return initialValue;
      }
    } else if (type === "NUMBER") {
      return Number(value) || initialValue;
    } else if (type === "BOOLEAN") {
      return Boolean(value === "TRUE");
    }
    return String(value || initialValue);
  }, [initialValue, key, localStorageAccessAvailable, type]);

  const [value, setValue] = useState<T>(initialLocalStateValue);

  useEffect(() => {
    if (localStorageAccessAvailable) {
      if (type === "JSON") {
        try {
          SetLocalStorageItem(key, JSON.stringify(value));
          return;
        } catch (e) {
          SetLocalStorageItem(key, JSON.stringify(initialValue));
        }
      } else if (type === "NUMBER") {
        SetLocalStorageItem(key, String(value));
      } else if (type === "BOOLEAN") {
        SetLocalStorageItem(key, value ? "TRUE" : "FALSE");
      } else if (type === "STRING") {
        SetLocalStorageItem(key, value as string);
      } else {
        SetLocalStorageItem(key, String(value));
      }
    }
  }, [initialValue, key, localStorageAccessAvailable, type, value]);
  return [value, setValue] as [typeof value, typeof setValue];
};

export const SetLocalStorageItem = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.log("Local Storage - Probably QuotaExceededError", e);
    // Todo: Probably got to have fallback or send analytics on quota exceeded
    // Ignore setting it
  }
};

export const GetLocalStorageItem = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.log("Get Local Storage - Error", e);
    return "";
  }
};
