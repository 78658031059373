import { SDK } from "@sdk";
const debounce = require("debounce-promise");

export const debouncedContactAvailabilityValidator = debounce(
  async (
    { email, mobile }: { email?: string; mobile?: string },
    contactId?: string
  ) => {
    try {
      const status = await SDK.resolveContact({ email, mobile });
      if (status.contact && status.contact.id === contactId) {
        return;
      }
      if (status.resolved) {
        throw "Duplicate Primary Identifier";
      }
      return;
    } catch (e) {
      throw typeof e === "string" ? e : "Something went wrong";
    }
  },
  1000,
  { leading: false, trailing: true }
);
