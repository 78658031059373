import unsplashApi from "@libs/@unsplash-picker/api/unsplash-api";
import { SDK } from "@sdk";
import {
  iHolidayQuote,
  iWhatsIncludedItem
} from "@sdk/holiday-quotes/holiday-quotes-model";
import { iDefaultHolidayQuotesConfig } from "@sdk/user-management/user-management.models";
import { GlobalConfig } from "config";
import dayjs from "dayjs";
import _, { sample, uniqBy } from "lodash";
import last from "lodash/last";
import { Store } from "redux";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { CountryCodes } from "utils/country-codes";
import { justWait } from "utils/just-wait";
import { cleanPNRString } from "../../pnr-converter/helpers/clean-pnr-input";
import { DummyQuoteRequest } from "../dummy-data/dummy-quote-request";
import {
  iConfirmAndBlock,
  iDestinationSummaryBlock,
  iFeedbackRatingBlock,
  iHotelItineraryItem,
  iItineraryBlock,
  iItineraryPassengerDetails,
  iItineraryPriceSummary,
  iItineraryShareBlock
} from "../itineries-model";
import { iPlaceData_Hotel } from "../place-data-hotel-model";

export interface iGenerateQuoteInput {
  flightsPNR: string;
  totalPrice: number;
  finePrint: string;
  whatsIncluded: {
    type: string;
    title: string;
    description: string;
  }[];
  adults: number;
  children: number;
  infants: number;
  passengerDetails: {
    first: string;
    last: string;
    dob: string;
  }[];
  hotelDetails: {
    hotel: iPlaceData_Hotel;
    checkIn: string;
    noOfNights: number;
    roomType: string;
    notes: string;
  }[];
  flightDetailsInputMethod?: "PNR" | "MANUAL";
  flightDetailsScreenshots?: string[];
  lengthOfTrip?: number;
  cities?: iPlaceData_Hotel[];
}

export const defaultHolidayQuoteConfig: iDefaultHolidayQuotesConfig = {
  enableSharingBlockByDefault: true,
  enableFeedbackBlockByDefault: true,
  enablePaymentLinksByDefault: true,
  defaultPaymentLink: "#",
  defaultFlightItineraryStyle: "Elegant"
};

export const GenerateQuoteFromBaseInput = async (
  input = DummyQuoteRequest,
  store: Store
) => {
  const organization = selectOrganization(store.getState());

  const quoteConfigurations =
    organization?.appData.HELP_DESK.holidayQuoteConfig ||
    defaultHolidayQuoteConfig;

  const { currency, currencySymbol } = (() => {
    const organization = selectOrganization(store.getState());
    const country = organization?.data.country || "GB";
    const countryData = CountryCodes.find(item => item.code === country);
    if (!countryData?.currencyCode) {
      return {
        currency: "GBP",
        currencySymbol: "£"
      };
    }
    return {
      currency: countryData?.currencyCode || "GBP",
      currencySymbol: countryData?.currencySymbol
    };
  })();

  const flightDetailsInputMethod = input.flightDetailsInputMethod;

  const { pnrResponse, destinations, lengthOfTrip } = await (async () => {
    if (flightDetailsInputMethod === "MANUAL") {
      const destinations = (() => {
        const records: {
          label: string;
          city: string;
          country: string;
          noOfDays: number;
        }[] = [];
        for (const city of input.cities || []) {
          const country = city.address_components.find(item =>
            item.types.includes("country")
          )?.short_name;
          records.push({
            label: `${city.name}, ${country}`,
            city: city.name,
            country: country!,
            noOfDays: input.lengthOfTrip!
          });
        }
        return records;
      })();
      return {
        destinations,
        lengthOfTrip: input.lengthOfTrip!
      };
    }
    const pnrResponse = await SDK.pnrConverter({
      pnr: cleanPNRString(input.flightsPNR)
    });

    const destinations = (() => {
      const records: {
        label: string;
        city: string;
        country: string;
        noOfDays: number;
      }[] = [];
      for (const flight of pnrResponse.flightData.flights) {
        if (flight.flt?.transit_time?.days || 0 > 1) {
          records.push({
            label: `${flight.arr.cityname}, ${flight.arr.countryname}`,
            city: flight.arr.cityname,
            country: flight.arr.countryname,
            noOfDays: flight.flt?.transit_time?.days!
          });
        }
      }
      return records;
    })();

    const lengthOfTrip = (() => {
      const firstFlight = pnrResponse.flightData.flights[0];
      const lastFlight = last(pnrResponse.flightData.flights);
      const start = firstFlight.flt.departure.UTC;
      const end = lastFlight?.flt.arrival.UTC;
      const startDate = dayjs(start);
      const endDate = dayjs(end);
      const days = endDate.diff(startDate, "days");
      console.log("days", days);
      return days;
    })();
    return {
      pnrResponse,
      destinations,
      lengthOfTrip
    };
  })();

  // Header Image
  const unsplash = unsplashApi(GlobalConfig.UNSPLASH_API_KEY);

  const firstCity = destinations[0].label;

  const unsplashResponse = await unsplash.search.getPhotos({
    page: 0,
    perPage: 10,
    query: firstCity,
    orientation: "landscape"
  });
  const unsplashResults = unsplashResponse?.response?.results || [];

  const defaultImages = [
    "https://images.unsplash.com/photo-1530521954074-e64f6810b32d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    "https://images.unsplash.com/photo-1452421822248-d4c2b47f0c81?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
  ];
  const headerImage =
    unsplashResults[0]?.urls?.regular || sample(defaultImages);

  let destinationWidgets: {
    country: string;
    city: string;
    placeData: iPlaceData_Hotel;
    weatherUrl: string;
  }[] = [];

  for (const destination of destinations) {
    // Todo; Get Destination URL if no
    // Todo: Get Place ID,
    // const res = await SDK.getWeatherUrl()
  }
  for (const hotelRecord of input.hotelDetails) {
    const country = hotelRecord.hotel.address_components.find(item =>
      item.types.includes("country")
    )?.short_name;
    const city = hotelRecord.hotel.address_components.find(item =>
      item.types.includes("locality")
    )?.short_name;

    const placeIdRes = await SDK.getPlaceId(`${city}, ${country}`);
    const placeId = placeIdRes?.candidates?.[0]?.place_id;
    if (placeId) {
      const res = await SDK.getWeatherUrl(placeId);
      const placeObj = await SDK.getPlaceDetails(placeId);
      console.log("res", res);

      destinationWidgets.push({
        country: country!,
        city: city!,
        placeData: placeObj.result,
        weatherUrl: res.path
      });
    }
  }

  destinationWidgets = uniqBy(
    destinationWidgets,
    item => `${item.country}-${item.city}`
  );
  console.log("destinationWidgets", destinationWidgets);

  const openAiRes = await SDK.processOpenAiPrompts({
    type: "CHAT",
    instruction: `Write a trip summary about a holiday proposal as an overperforming travel agent & destination specialist. Keep it to one paragraph. You can include what to try that's popular in the area and provide any other insights that would be helpful for travel.`,
    input: `\n ${destinations
      .map(item => `${item.noOfDays} Nights in ${item.city}, ${item.country}`)
      .join("; ")}`
  });

  let greetings = `${openAiRes?.choices?.[0]?.message?.content}`;
  if (greetings.charAt(0) === ":") {
    greetings = greetings.substring(1);
    greetings.trim();
  }

  const currentUser = selectCurrentUser(store.getState());

  const itineraryBlocks: iItineraryBlock[] = [
    flightDetailsInputMethod === "MANUAL"
      ? {
          id: "FLIGHT_ITINERARY",
          type: "FLIGHT_ITINERARY",
          data: {
            flightDetailsInputMethod: input.flightDetailsInputMethod,
            flightDetailsScreenshots: input.flightDetailsScreenshots,
            cities: input.cities
          }
        }
      : {
          id: "FLIGHT_ITINERARY",
          type: "FLIGHT_ITINERARY",
          data: {
            flightDetailsInputMethod: input.flightDetailsInputMethod,
            flightData: pnrResponse!.flightData,
            pnr: input.flightsPNR,
            itineraryStyle: quoteConfigurations.defaultFlightItineraryStyle
          }
        },
    ...input.hotelDetails.map(
      (item, index) =>
        ({
          id: `HOTEL_${index}`,
          type: "HOTEL_ITEM",
          data: {
            placeId: item.hotel.place_id,
            ...item
          }
        } as iHotelItineraryItem)
    ),
    {
      id: "FINE_PRINT_ITEM",
      type: "FINE_PRINT_ITEM",
      data: {
        finePrints: [
          ...(input.finePrint ? input.finePrint.split("\n") : []),
          "Terms and Conditions apply.",
          "ABTA & ATOL Protected."
        ]
      }
    },
    ...(input.passengerDetails
      ? ([
          {
            id: "PASSENGER_DETAILS",
            type: "PASSENGER_DETAILS",
            data: {
              passengers: input.passengerDetails.map(item => ({
                title: "Mr",
                firstName: item.first,
                surName: item.last,
                dateOfBirth: new Date(item.dob).getTime()
              }))
            }
          }
        ] as iItineraryPassengerDetails[])
      : []),
    ...destinationWidgets.map(
      (item, index) =>
        ({
          id: `DESTINATION_SUMMARY_BLOCK_${index}`,
          type: "DESTINATION_SUMMARY_BLOCK",
          data: {
            placeId: item.placeData.place_id,
            name: item.placeData.name,
            label: item.placeData.formatted_address,
            destination: item.placeData,
            weatherUrl: item.weatherUrl
          }
        } as iDestinationSummaryBlock)
    ),
    {
      id: "PRICE_SUMMARY",
      type: "PRICE_SUMMARY",
      data: {
        price: `${input.totalPrice}`,
        currency,
        currencySymbol,
        includedHotel: "ALL",
        discountText: `For ${lengthOfTrip + 1} days / 
  ${lengthOfTrip} Nights, ${input.adults ? `${input.adults} adult(s) ` : ""}${
          input.children ? `${input.children} child(ren) ` : ""
        }${input.infants ? `${input.infants} infant(s) ` : ""}`,
        whatsIncluded: input.whatsIncluded as iWhatsIncludedItem[]
      }
    } as iItineraryPriceSummary,
    ...(quoteConfigurations.enablePaymentLinksByDefault
      ? [
          {
            id: "CONFIRM_AND_PAY",
            type: "CONFIRM_AND_PAY",
            data: {
              paymentLink: quoteConfigurations.defaultPaymentLink || "#",
              contact: {
                data: _.pick(
                  currentUser.data,
                  "firstName",
                  "lastName",
                  "bio",
                  "description",
                  "avatar"
                ),
                credentials: {
                  email: currentUser.credentials.email
                }
              }
            }
          } as iConfirmAndBlock
        ]
      : []),
    ...(quoteConfigurations.enableFeedbackBlockByDefault
      ? [
          {
            id: "FEEDBACK_RATING",
            type: "FEEDBACK_RATING",
            data: {}
          } as iFeedbackRatingBlock
        ]
      : []),
    ...(quoteConfigurations.enableSharingBlockByDefault
      ? [
          {
            id: "SHARE_ITINERARY_BLOCK",
            type: "SHARE_ITINERARY_BLOCK",
            data: {}
          } as iItineraryShareBlock
        ]
      : [])
  ];

  console.log("itineraryBlocks", itineraryBlocks);

  let holidayTitle = ``;

  for (const destination of destinations) {
    const openAiRes = await SDK.processOpenAiPrompts({
      type: "CHAT",
      instruction: `Be creative writer, When I tell a city name, create holiday name with the city name
        Example:
        Bali - Beautiful Bali
        Sri Lanka - Relaxing Lanka`,
      input: `Now write a holiday name for ${destination.city},${destination.country}`
    });
    if (!holidayTitle) {
      holidayTitle += openAiRes?.choices?.[0]?.message?.content;
    } else {
      holidayTitle += "; " + openAiRes?.choices?.[0]?.message?.content;
    }
    if (last(destinations) !== destination) {
      await justWait(5000);
    }
  }

  const quote: Partial<iHolidayQuote> = {
    // reference data
    contactId: undefined,
    conversationId: undefined,
    sendAsUserId: undefined,
    // For Templates
    isTemplate: undefined,
    label: holidayTitle,
    // quote data
    status: "DRAFT",
    value: input.totalPrice,
    data: {
      cover: headerImage!,
      adults: input.adults,
      children: input.children,
      infants: input.infants,
      numberOfNights: lengthOfTrip,
      quoteTitle: holidayTitle,
      greetings: greetings,
      blocks: itineraryBlocks,
      finePrint: input.finePrint,
      whatsIncluded: input.whatsIncluded as iWhatsIncludedItem[]
      // Default Order of block - Price, Passenger Details, Fine Print, Flights, Hotel,  Destination, Share
    },
    quoteExpiry: undefined
  };

  return quote;
};
