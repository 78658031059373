import { useSDK } from "@sdk/sdk.hooks";
import {
  iShopifyProduct,
  iShopifyVariant,
} from "@sdk/shopify/shopify-product-model";
import { Input, Modal } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectShopifyStoreConfig } from "store/modules/workspace/workspace.selectors";
import { useSearch } from "utils/hooks/use-search";

export interface iShopifyProductVariant {
  id: string;
  title: string;
  product: iShopifyProduct;
  variant: iShopifyVariant;
  label: JSX.Element;
  image: string;
  price: string;
  url: string;
}

export const ShopifyProductBrowserModal = ({
  visible,
  onChangeVisibility,
  onSelected,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onSelected?: (product: iShopifyProductVariant) => any;
}) => {
  const {
    data: allProducts,
    reload: reloadProducts,
    isLoading: isProductsLoading,
  } = useSDK((SDK) => SDK.shopify.getAllProducts(), [], false, []);

  const shopifyConfig = useSelector(selectShopifyStoreConfig);

  const productOptions = useMemo(
    () =>
      _.flatten(
        allProducts.map((product) =>
          product.variants.map((variant) => ({
            id: `${product.id}-${variant.id}`,
            variant,
            product,
          })),
        ),
      ).map(
        (item) =>
          ({
            id: item.id,
            title: `${item.product.title} - ${item.variant.title}`,
            product: item.product,
            variant: item.variant,
            url: `https://${shopifyConfig?.storeDomain}/products/${item.product.handle}?variant=${item.variant.id}`,
            image: item.product.image.src,
            price: item.variant.price,
            label: (
              <div
                className="flex flex-row items-center justify-between border-b border-gray-200 dark:border-gray-800"
                key={item.id}
              >
                <div className="flex flex-row items-center">
                  <div className="image">
                    <img
                      src={item.product.image.src}
                      alt=""
                      style={{ width: 40, height: 40 }}
                      className="rounded-lg"
                    />
                  </div>
                  <div className="font-bold ml-2">
                    {item.product.title} - {item.variant.title}
                  </div>
                </div>
                <div className="right">{item.variant.price}</div>
              </div>
            ),
          }) as iShopifyProductVariant,
      ),
    [allProducts, shopifyConfig?.storeDomain],
  );

  const {
    filteredData: filteredItems,
    searchTerm,
    setSearchTerm,
  } = useSearch(productOptions);

  return (
    <>
      <Modal
        title={
          <ModalTitle
            icon={<i className="ri-store-2-line"></i>}
            title={"Product Catalogue"}
          />
        }
        open={visible}
        footer={null}
        onCancel={() => {
          onChangeVisibility(false);
        }}
        okText={
          <>
            <i className="ri-check-line"></i> Create
          </>
        }
        data-click-context="Shopify Product Catalogue Modal"
        width={600}
        destroyOnClose
      >
        <div className="search-bar mb-6">
          <Input.Search
            placeholder="Search Products..."
            className="rounded-md"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoFocus={true}
          />
        </div>

        <div className="">
          {filteredItems.map((item) => (
            <div
              className="hover:bg-gray-100 dark:bg-gray-800 cursor-pointer"
              onClick={() => {
                onSelected && onSelected(item);
                onChangeVisibility(false);
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
        {filteredItems.length === 0 && (
          <EmptyData
            icon={<i className="ri-search-line text-4xl"></i>}
            text={
              <div className="flex flex-col justify-center items-center">
                <div className="text-2xl">No products to show</div>
                <div className="">
                  Make sure that you have created products records in your
                  Shopify Store
                </div>
              </div>
            }
          />
        )}

        <DarkModeBg />
      </Modal>
    </>
  );
};
