import { useSDK } from "@sdk";
import { Col, Row, Spin } from "antd";
import classNames from "classnames";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import dayjs from "dayjs";

import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useStore } from "react-redux";
import { LoadAllGroups } from "store/modules/groups/groups.helpers";
import { selectAllGroupsQuery } from "store/modules/groups/groups.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { useSimpleState } from "utils/hooks/use-simple-state";

import {
  iLegacyReportTypes,
  LegacyReportTypeSchemes,
} from "../../schemas/legacy-report-type-schema";
import { LegacyReportWidgets } from "../../schemas/legacy-report-widgets-schema";
import { LegacyReportAnalyzer } from "../legacy-report-analyzer/legacy-report-analyzer";
import { LegacyReportFilter } from "../legacy-report-filter/legacy-report-filter";
import { ReportsByTagXTags } from "../legecy-custom-widgets/reports-by-tag-combination/reports-by-tag-combination";
import { ReportsByTagGroup } from "../legecy-custom-widgets/reports-by-tag-group";
import { UserPerformanceReportWidget } from "../legecy-custom-widgets/user-performance-report/user-performance-report";
import { ReportWidgetLegacy } from "../report-widget/report-widget-legacy";
import { ConversationsViewer } from "../reports-conversations-explorer-legacy/conversations-viewer";

const getWidgetConfig = _.memoize(
  (widget: string, type: string) => {
    return {
      ...LegacyReportWidgets[widget],
      ...(LegacyReportTypeSchemes[type].overides?.[widget] || {}),
      // filter: ReportWidgets[widget].queryFunction(query),
    };
  },
  (...args) => JSON.stringify(args),
);

export const LegacyReportViewer = ({
  type,
  onChangeReport,
  filter,
  hideFilter,
}: {
  type: iLegacyReportTypes;
  onChangeReport?: (type: iLegacyReportTypes) => any;
  filter?: any;
  hideFilter?: boolean;
}) => {
  const [query, setQuery] = useState({
    period: [dayjs().subtract(7, "day"), dayjs()] as any,
    groupBy: [],
    dateField: "createdTime",
    ...(filter || {}),
  } as any);

  const {
    state: { list: _groups },
  } = useQueryWithStore(selectAllGroupsQuery, LoadAllGroups);

  const [isReportsVisible, setReportsVisibility] = useState(true);

  const widgets: (keyof typeof LegacyReportWidgets)[] =
    LegacyReportTypeSchemes[type].reports;

  const store = useStore();

  const { data: reportData, isLoading } = useSDK(
    (sdk) =>
      Promise.all(
        widgets.map((widget) =>
          sdk
            .getConversationReports(
              LegacyReportWidgets[widget].queryFunction(
                query,
                store.getState(),
              ),
            )
            .then((d) =>
              LegacyReportWidgets[widget].transformFunction(
                d,
                sdk,
                store,
                query,
              ),
            ),
        ),
      ),
    [query, widgets],
    !query.period,
    [],
  );

  const [conversationViewerState, setConversationViewerState] = useSimpleState({
    visible: false,
    filter: {},
  });

  const [reportAnalyzerState, setReportAnalyzerState] = useSimpleState({
    visible: false,
    filter: {},
  });

  useEffect(() => {
    setReportsVisibility(false);
    setTimeout(() => {
      setReportsVisibility(true);
    }, 1000);
  }, [type]);

  useEffectWhen(
    () => {
      if (type !== "REPORT_360") {
        const newQuery = _.pick(query, ["period", "groupBy", "dateField"]);
        setQuery(newQuery);
      }
    },
    [query, type],
    [type],
  );

  const onWidgetAction = useCallback(
    (action, widget) => {
      // console.log("action", action);

      const filter = LegacyReportWidgets[widget].inspectorFunction
        ? LegacyReportWidgets[widget].inspectorFunction!(
            {
              ..._.omit(query, "groupBy"),
              ...action.filter,
            },
            store.getState(),
          )
        : {
            ..._.omit(query, "groupBy"),
            ...action.filter,
          };

      console.log("filter", filter);
      if (action.action === "VIEW") {
        setConversationViewerState({
          filter: filter,
          visible: true,
        });
      }
      if (action.action === "INSPECT") {
        console.log("Inspect Conversations", filter);
        setReportAnalyzerState({
          filter: filter,
          visible: true,
        });
      }
    },
    [query, setConversationViewerState, setReportAnalyzerState, store],
  );

  const onFilterChange = useCallback(
    (_query) => {
      if (
        type === "REPORT_360" ||
        _.isEqual(
          _.without(Object.keys(_query), "groupBy"),
          _.without(Object.keys(query), "groupBy"),
        )
      ) {
        // Same Keys
        console.log("Same Keys");
        setQuery(_query);
      } else {
        console.log("Different Keys");
        setQuery(_query);
        // setTimeout(() => {
        //   onChangeReport && onChangeReport("REPORT_360");
        //   message.info("Report type automatically changed to 360 Report");
        // }, 0);
      }
    },
    [query, type],
  );

  const onConversationViewerVisibility = useCallback(
    (visible) =>
      setConversationViewerState({
        visible,
      }),
    [setConversationViewerState],
  );

  const onReportAnalyzerVisibilityChange = useCallback(
    (visible) =>
      setReportAnalyzerState({
        visible,
      }),
    [setReportAnalyzerState],
  );

  return (
    <>
      {!hideFilter && (
        <div
          className={classNames(
            "filter-container  px-10 py-2 my-10 hover:bg-gray-200",
            {
              "opacity-0": !isReportsVisible,
            },
          )}
        >
          <LegacyReportFilter
            onFilterChange={onFilterChange}
            initialFilter={query}
            hideGroupBy={true}
          />
        </div>
      )}

      <Spin
        spinning={isLoading || !isReportsVisible}
        indicator={<LoadingIndicatorWithoutSpin />}
        style={{ minHeight: 400 }}
        // delay={200}
        wrapperClassName="flex-1 flex fill-spinner"
      >
        {isReportsVisible && (
          <Row className="px-10 pb-20" gutter={[32, 32]}>
            {widgets.map((widget, index) => {
              const WidgetComponent = (() => {
                if (widget === "CONVERSATIONS_BY_TAGS_GROUP") {
                  return ReportsByTagGroup;
                }
                if (widget === "CONVERSATIONS_TAGS_BY_TAGS_WIDGET") {
                  return ReportsByTagXTags;
                }
                if (widget === "USER_PERFORMANCE_REPORT") {
                  return UserPerformanceReportWidget;
                }
                return ReportWidgetLegacy;
              })() as typeof ReportWidgetLegacy;

              return (
                <Col
                  span={LegacyReportTypeSchemes[type].layout[index]}
                  key={`${widget}-${index.toString()}`}
                >
                  <WidgetComponent
                    widgetConfig={getWidgetConfig(widget, type)}
                    data={reportData[index] || []}
                    onAction={onWidgetAction}
                    widgetType={widget}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      </Spin>

      <ConversationsViewer
        filter={conversationViewerState.filter}
        visible={conversationViewerState.visible}
        onChangeVisibility={onConversationViewerVisibility}
      />

      <LegacyReportAnalyzer
        filter={reportAnalyzerState.filter}
        visible={reportAnalyzerState.visible}
        onChangeVisibility={onReportAnalyzerVisibilityChange}
      />
    </>
  );
};
