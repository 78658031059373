import { iPresetState } from "@sdk/user-management/preset-state-model";
import {
  iAutoKickUsersConfig,
  iCustomCRMSegment,
  iWorkspaceInboxConfig,
} from "@sdk/user-management/user-management.models";
import { iReportCanvas } from "components/pages/reports/components/report-canvas/widget-canvas-models";
import _ from "lodash";
import { iStore } from "../../store.model";

const emptyArray = [];

export const selectOrganizationLogo = (state: iStore) =>
  state.organizations.organization?.data.logo;

export const selectOrganization = (state: iStore) =>
  state.organizations.organization;

export const selectOrganizationId = (state: iStore) =>
  state.organizations.organization?.id;

export const selectOrganizationDomain = (state: iStore) =>
  state.organizations.organization?.domain;

export const selectWhitelistedIPs = (state: iStore) =>
  state.organizations.organization?.data.whiteListedAccessIPs || emptyArray;

export const selectTeams = (state: iStore) =>
  state.organizations.organization?.appData.HELP_DESK.teams;

export const selectShouldEnforceStrongPassword = (state: iStore) =>
  state.organizations.organization?.appData?.HELP_DESK
    ?.shouldEnforceStrongerPassword;

export const selectConversationTags = (state: iStore) =>
  state.organizations.organization?.appData.HELP_DESK.conversationTags ||
  emptyArray;

export const selectContactTags = (state: iStore) =>
  state.organizations.organization?.appData?.CRM?.contactTags || emptyArray;

export const selectPresetMessages = (state: iStore) =>
  state.organizations.organization?.appData.HELP_DESK.messagePresets;

export const selectOrganizationWideMessagePresets = (state: iStore) =>
  state.organizations.organization?.appData.HELP_DESK.messagePresets;

export const defaultPresetXModel = {
  folders: [],
  presets: [],
} as iPresetState;

export const selectOrganizationWideMessagePresetsX = (state: iStore) =>
  state.organizations.organization?.appData.HELP_DESK.messagePresetsX ||
  defaultPresetXModel;

export const selectPipelines = (state: iStore) =>
  state.organizations.organization?.appData.HELP_DESK.pipelines || emptyArray;

export const selectPipelineById = (id: string) => (state: iStore) => {
  return _.find(
    state.organizations.organization?.appData.HELP_DESK.pipelines || emptyArray,
    {
      id,
    },
  );
};

export const selectMacros = (state: iStore) =>
  state.organizations.organization?.appData.HELP_DESK.macros || emptyArray;

export const selectMacroById = (id: string) => (state: iStore) => {
  return _.find(
    state.organizations.organization?.appData.HELP_DESK.macros || emptyArray,
    {
      id,
    },
  );
};

export const selectChannels = (state: iStore) =>
  state.organizations.organization?.appData.HELP_DESK.channels;

export const selectSubscriptionDetails = (state: iStore) =>
  state.organizations.organization?.subscriptionDetails;

export const selectCredits = (state: iStore) => state.organizations.credits;

export const defaultAutoKickUserConfig: iAutoKickUsersConfig = {
  active: false,
  timeout: 0,
};

export const selectAutoKickUserConfig = (state: iStore) =>
  state.organizations.organization?.appData.HELP_DESK.autoKickUsers ||
  defaultAutoKickUserConfig;

export const selectPreventBurstJoinChat = (state: iStore) =>
  state.organizations.organization?.appData.HELP_DESK.preventBurstJoins;

export const selectConnectionContextVisibility = (state: iStore) =>
  state.organizations.organization?.appData?.HELP_DESK?.showConnectionContext;

export const selectConversationCustomProperties = (state: iStore) =>
  state.organizations.organization?.appData?.HELP_DESK
    ?.customConversationProperties || emptyArray;

export const selectCRMCustomProperties = (state: iStore) =>
  state.organizations.organization?.appData?.CRM?.customCRMProperties ||
  emptyArray;

export const selectAlertAllOnNewIncomingChat = (state: iStore) =>
  state.organizations.organization?.appData?.HELP_DESK
    ?.alertAllOnNewIncomingChat;

export const selectAlertEveryoneOnUnassignedChats = (state: iStore) =>
  state.organizations.organization?.appData?.HELP_DESK
    ?.alertEveryoneOnUnassignedChats;

export const selectAccountStatus = (state: iStore) =>
  state.organizations.organization?.metaData.status;

export const selectShopifyBilling = (state: iStore) =>
  state.organizations.organization?.subscriptionDetails?.shopifyBilling
    ?.recurringApplicationChargeId;

export const selectAccountLimits = (state: iStore) =>
  state.organizations.organization?.metaData.limits;

export const selectAccountManager = (state: iStore) =>
  state.organizations.organization?.metaData.accountManager;

export const selectAccountBillingType = (state: iStore) =>
  state.organizations.organization?.metaData.customBilling;

export const selectAccountTrialEndDate = (state: iStore) =>
  state.organizations.organization?.metaData.trialEndDate;

export const selectOrganizationOnboardingState = (state: iStore) =>
  state.organizations.organization?.appData?.ONBOARDING;

const defaultFirstResponseConfig = {
  intervals: [30, 120, 300],
  labels: ["Fast", "Average", "Slow", "Super Slow"],
};

const defaultFirstResponseConfig2 = {
  intervals: [5 * 60, 9 * 60, 12 * 60],
  labels: ["Fast", "Average", "Slow", "Super Slow"],
};

export const selectFirstResponseIntervalConfig = (state: iStore) => {
  const currentOrganization = selectOrganizationId(state);
  if (currentOrganization === "0dd740") {
    return defaultFirstResponseConfig2;
  }
  return defaultFirstResponseConfig;
};

export const selectCustomReports = (state: iStore) =>
  (state.organizations.organization?.appData?.REPORTS
    ?.customReportViews as iReportCanvas[]) || emptyArray;

export const selectCustomCRMViews = (state: iStore) =>
  (state.organizations.organization?.appData?.CRM
    ?.customCRMSegments as iCustomCRMSegment[]) || emptyArray;

export const selectShopifyStoreConfig = (state: iStore) =>
  state.organizations.organization?.appData?.HELP_DESK?.shopifyStore;

export const selectMailchimpIntegrationConfig = (state: iStore) =>
  state.organizations.organization?.appData?.CRM?.mailChimpIntegration;

const defaultInboxConfig: iWorkspaceInboxConfig = {
  showInboxesByTags: true,
  showInboxesByConnections: true,
  showQuickInboxAddition: true,
};

export const selectInboxConfig = (state: iStore) =>
  state.organizations.organization?.appData.HELP_DESK.defaultInboxConfig ||
  defaultInboxConfig;

export const selectChatSideBarConfig = (state: iStore) =>
  state.organizations.organization?.appData.HELP_DESK.chatSideBarConfig;

export const selectFeatureRestrictionConfig = (state: iStore) =>
  state.organizations.organization?.appData.HELP_DESK.featureRestrictionConfigs;

export const selectOrganizationBetaFeatures = (state: iStore) =>
  state.organizations.organization?.appData.HELP_DESK.betaFeaturesConfig;

export const selectWebsitesToCrawl = (state: iStore) =>
  state.organizations.organization?.appData?.KNOWLEDGE_BASE?.indexWebpages
    ?.websites || emptyArray;

export const selectIsAppSumoUser = (state: iStore) =>
  state.organizations.organization?.appData?.ONBOARDING?.onboardingFlow ===
  "APP_SUMO";

export const selectIsInsiderUser = (state: iStore) =>
  state.organizations.organization?.appData?.HELP_DESK?.isInsiderAccount;

export const selectIndustry = (state: iStore) =>
  state.organizations.organization?.data?.industry;

export const selectDPAState = (state: iStore) =>
  state.organizations.organization?.data?.dpa;

export const selectAutoTranslationDefaultLanguage = (state: iStore) =>
  state.organizations.organization?.appData?.HELP_DESK?.autoTranslator
    ?.defaultLanguage || "en";
