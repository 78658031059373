import { iCompany } from "@sdk/crm/crm.models";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectCompanyQueryMap,
  selectEntityMap: selectCompaniesMap,
  selectQueryMapAll: selectCompaniesMapAll,
  selectQueryAllList: selectCompaniesAllList,
  selectAllEntities: selectAllCompanies,
  selectAllEntitiesQuery: selectAllCompaniesQuery,
  selectEntityById: selectCompanyById,
  selectEntityQuery: selectCompaniesQuery
} = CreateSelectorForEntities<iStore, iCompany>({
  sliceName: "companies"
});
