import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Alert, Button, Input, Table, Typography } from "antd";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelfServicePortalById } from "store/modules/self-service-portals/self-service-portals.selectors";

export const CustomDomainConfigForPortal = ({
  portalId,
}: {
  portalId: string;
}) => {
  const selfServicePortal = useSelector(selectSelfServicePortalById(portalId));

  const customDomainConfig =
    selfServicePortal?.data?.domain?.customDomainConfig;

  const [customDomainInput, setCustomDomainInput] = useState("");

  const { doAction: setCustomDomain, isProcessing: isSettingCustomDomain } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (domain) =>
          SDK.selfServicedPortals.setCustomDomain(portalId, domain),
        successMessage: "Done",
        failureMessage: "Something went wrong",
        actionDependencies: [portalId],
      }),
      [portalId],
    );

  const { doAction: removeCustomDomain, isProcessing: idRemoveProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.selfServicedPortals.removeCustomDomain(portalId),
        successMessage: "Done",
        failureMessage: "Something went wrong",
        actionDependencies: [portalId],
      }),
      [portalId],
    );

  const { doAction: refreshValidityCustomDomain, isProcessing: isRefreshing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.selfServicedPortals.refreshValidityCustomDomain(portalId),
        //   successMessage: "Done",
        failureMessage: "Something went wrong",
        actionDependencies: [portalId],
      }),
      [portalId],
    );

  const columns = useMemo(() => {
    return [
      {
        title: "Type",
        dataIndex: "type",
      },
      {
        title: "Hostname",
        dataIndex: "domain",
      },
      {
        title: "Enter This Value",
        dataIndex: "value",
        render: (value) => (
          <div
            className="p-4 bg-gray-100  dark:bg-gray-700 rounded-lg mode_transition"
            style={{ maxWidth: 200 }}
          >
            <Typography.Paragraph
              ellipsis={{ rows: 3, expandable: false, symbol: "more" }}
              copyable={{ text: value }}
            >
              {value}
            </Typography.Paragraph>
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "reason",
      },
    ];
  }, []);

  const customDomainDNSConfig = useMemo(() => {
    return [
      {
        type: "CNAME",
        domain: customDomainConfig?.domain,
        value: "cname.vercel-dns.com",
      },
    ];
  }, [customDomainConfig?.domain]);

  if (customDomainConfig?.domain) {
    if (customDomainConfig?.isVerified) {
      if (customDomainConfig.isConfigured) {
        return (
          <>
            <Alert
              message="You have successfully setup your custom domain"
              type="success"
              showIcon
              className="p-4 rounded-lg flex-1"
            />
            <div className="my-4">
              Custom Domain : {customDomainConfig.domain}
            </div>
            <Button
              onClick={() => removeCustomDomain()}
              loading={idRemoveProcessing}
            >
              Remove Domain
            </Button>
          </>
        );
      }
      return (
        <>
          <Alert
            message="Domain has been verified. However it is not configured yet"
            type="warning"
            showIcon
            className="p-4 rounded-lg flex-1"
          />
          <div className="my-4">
            Custom Domain : {customDomainConfig.domain}
          </div>

          <div className="mt-4">
            Please set the following CNAME record on domain DNS settings
          </div>
          <Table
            columns={columns}
            dataSource={customDomainDNSConfig}
            pagination={false}
            rowKey="value"
            scroll={{ x: "100%" }}
          />
          <div className="mt-4 flex flex-row gap-4 items-center">
            <Button
              onClick={() => removeCustomDomain()}
              loading={idRemoveProcessing}
            >
              Remove Domain
            </Button>
            <Button
              onClick={() => refreshValidityCustomDomain(customDomainInput)}
              loading={isRefreshing}
            >
              Validate
            </Button>
          </div>
        </>
      );
    }
    return (
      <div className="flex flex-col">
        <Alert
          message="Please Complete the domain setup"
          type="warning"
          showIcon
          className="p-4 rounded-lg flex-1"
        />
        <div className="mt-4">
          Please set the following TXT record on domain DNS settings
        </div>
        <Table
          columns={columns}
          dataSource={customDomainConfig.verification || []}
          pagination={false}
          rowKey="value"
          scroll={{ x: "100%" }}
        />
        <div className="flex flex-row mt-4 gap-4">
          <Button
            onClick={() => refreshValidityCustomDomain(customDomainInput)}
            loading={isRefreshing}
          >
            Validate
          </Button>
          <Button
            onClick={() => removeCustomDomain()}
            loading={idRemoveProcessing}
          >
            Remove Domain
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <Alert
        message="You have not setup a custom domain yet"
        type="info"
        showIcon
        className="p-4 rounded-lg flex-1"
      />

      <div className="flex flex-row mt-4">
        <Input
          value={customDomainInput}
          onChange={(e) => setCustomDomainInput(e.target.value)}
          className="flex-1"
          placeholder="Your Domain"
        />
        <Button
          onClick={() => setCustomDomain(customDomainInput)}
          loading={isSettingCustomDomain}
        >
          Setup
        </Button>
      </div>
    </div>
  );
};
