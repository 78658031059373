import { iConnectionType } from "@sdk/conversations/conversations.models";

export const AvailableConnectionList: {
  title: string;
  icon: string;
  description: JSX.Element;
  connectionType: iConnectionType;
  isDisabled?: boolean;
}[] = [
  {
    title: "Configure a new Live Chat Widget",
    icon: "/assets/channels/website-v2.png",
    description: (
      <span>
        Add a <b>live chat</b> to your website and chat with your visitors
        directly. You can also connect <b>enquiry forms and contacts forms</b>{" "}
        to click connector and continue the conversations via email
      </span>
    ),
    connectionType: "WEB_CHAT",
  },
  {
    title: "Connect a new Email Inbox",
    icon: "/assets/channels/email.png",
    description: (
      <span>
        Connect your support and sales emails to click connector to provide
        seamless experience to your customers.
      </span>
    ),
    connectionType: "EMAIL",
  },
  {
    title: "Connect a new Facebook Page",
    icon: "/assets/channels/facebook.png",
    description: (
      <span>
        When you connect Facebook, you will be able to mange{" "}
        <b>facebook conversations,manage leads </b> through <b>lead forms</b>.
        You can also reply to comments and engage with all the users who is
        commenting on your posts. Set up does not take more than 5 minutes!
      </span>
    ),
    connectionType: "FACEBOOK",
  },
  // {
  //   title: "SMS",
  //   icon: "/assets/channels/sms.png",
  //   description: (
  //     <span>
  //       You can also get your own mobile number to send and receive sms via
  //       click connector. This would serve as an additional notification
  //       channel that works seamlessly with other communication channels
  //     </span>
  //   ),
  //   connectionType: "SMS",
  //   isDisabled: true
  // },
  {
    title: "Connect a new Instagram Account",
    icon: "/assets/channels/instagram.webp",
    description: (
      <span>
        You can engage with all your followers who engage with your posts. You
        can also reply to comments and continue the conversations via direct
        messages through Click Connector
      </span>
    ),
    connectionType: "INSTAGRAM",
    isDisabled: true,
  },
  {
    title: "Connect a new Whatsapp Account",
    icon: "/assets/channels/whatsapp.webp",
    description: (
      <span>
        Multiple agents can use Whatsapp ub parallel and manage and continue all
        your chats through Click Connector. This will require additional set up
        and require your mobile phone to be connected all the time. Reach out to
        us to learn more.
      </span>
    ),
    connectionType: "WHATS_APP_PRIVATE",
  },
];

export const AvailableConnectionListVersion2 = [
  {
    label: "Add Chat Widget",
    icon: <i className="ri-message-3-line"></i>,
    description: (
      <span>
        Add a <b>live chat</b> to your website and chat with your visitors
        directly. You can also connect <b>enquiry forms and contacts forms</b>{" "}
        to click connector and continue the conversations via email
      </span>
    ),
    key: "WEB_CHAT",
  },
  {
    label: "Add Email Inbox",
    icon: <i className="ri-mail-line"></i>,
    description: (
      <span>
        Connect your support and sales emails to click connector to provide
        seamless experience to your customers.
      </span>
    ),
    key: "EMAIL",
  },
  {
    label: "Add Facebook Page",
    icon: <i className="ri-facebook-box-line"></i>,
    description: (
      <span>
        When you connect Facebook, you will be able to mange{" "}
        <b>facebook conversations,manage leads </b> through <b>lead forms</b>.
        You can also reply to comments and engage with all the users who is
        commenting on your posts. Set up does not take more than 5 minutes!
      </span>
    ),
    key: "FACEBOOK",
  },
  {
    label: "Add Instagram Account",
    icon: <i className="ri-instagram-line"></i>,
    description: (
      <span>
        You can engage with all your followers who engage with your posts. You
        can also reply to comments and continue the conversations via direct
        messages through Click Connector
      </span>
    ),
    key: "INSTAGRAM",
    isDisabled: true,
  },
  // {
  //   label: "Add Whatsapp Account",
  //   icon: <i className="ri-whatsapp-line"></i>,
  //   description: (
  //     <span>
  //       Multiple agents can use Whatsapp ub parallel and manage and continue all
  //       your chats through Click Connector. This will require additional set up
  //       and require your mobile phone to be connected all the time. Reach out to
  //       us to learn more.
  //     </span>
  //   ),
  //   key: "WHATS_APP_PRIVATE",
  // },
];

export const AvailableConnectionListVersion3 = [
  {
    label: "Live Chat Widget",
    icon: <i className="ri-message-3-line"></i>,
    key: "WEB_CHAT",
  },
  {
    label: "Email Inbox",
    icon: <i className="ri-mail-line"></i>,
    key: "EMAIL",
  },
  {
    label: "Facebook Page",
    icon: <i className="ri-facebook-box-line"></i>,
    key: "FACEBOOK",
  },
  {
    label: "Instagram Account",
    icon: <i className="ri-instagram-line"></i>,
    key: "INSTAGRAM",
  },
  {
    label: "WhatsApp Cloud API",
    icon: <i className="ri-whatsapp-line"></i>,
    key: "WHATS_APP_CLOUD",
  },
  {
    label: "Whatsapp (WA Web)",
    icon: <i className="ri-whatsapp-line"></i>,
    key: "WHATS_APP_PRIVATE",
    requireManualPermission: true,
  },
  {
    label: "WhatsApp Cloud",
    icon: <i className="ri-whatsapp-line"></i>,
    key: "WHATS_APP_CLOUD_NATIVE",
  },
  {
    label: "Whatsapp (Dialog360)",
    icon: <i className="ri-whatsapp-line"></i>,
    key: "WHATS_APP_OFFICIAL",
    requireManualPermission: true,
  },
  {
    label: "Twitter Account",
    icon: <i className="ri-twitter-line"></i>,
    key: "TWITTER",
  },
  {
    label: "Telephony & SMS",
    icon: <i className="ri-phone-line"></i>,
    key: "TELNYX",
  },
  {
    label: "Telegram (Beta)",
    icon: <i className="ri-telegram-line"></i>,
    key: "TELEGRAM",
  },
];
