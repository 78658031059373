import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDK } from "@sdk";
import { AutoComplete, Button, Card, Input } from "antd";
import { Avatar } from "components/common/avatar/avatar";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { AddContact } from "modal-registry";
import { useState } from "react";
import { loadContactById } from "store/modules/contacts/contacts.helpers";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { ContactSearchQueryBuilder } from "../../helpers/contact-search-query-builder";
import { GetContactIdentifier } from "../../helpers/get-contact-identifier";
import { getContactName } from "../../helpers/get-contact-name";
import { ContactPill } from "./contact-selector-pill";

const { Meta } = Card;

export const SingleContactSelector = ({
  value: selectedContactId,
  onChange: setSelectedContact = () => {},
  hideAdd,
}: {
  value?: string;
  onChange?: (contact: string) => any;
  hideAdd?: boolean;
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { data: originalData, isLoading } = useSDK(
    async (SDK) =>
      SDK.queryContacts({
        query: ContactSearchQueryBuilder(searchQuery),
        options: {
          offset: 0,
          page: 1,
          limit: 20,
        },
      }),
    [searchQuery],
    false,
    {}
  );
  const { triggerTempModal } = useModalPanels();

  const {
    state: selectedContact,
    isLoading: isLoadingSelected,
    retry: reloadContacts,
  } = useQueryWithStore(
    selectContactById(selectedContactId!),
    loadContactById(selectedContactId!)(),
    [selectedContactId],
    !selectedContactId
  );

  return (
    <>
      {selectedContactId && (
        <div className="hover:bg-gray-100 dark:hover:bg-gray-900 rounded-lg my-2 p-2 flex flex-row items-center justify-between mode_transition">
          {isLoadingSelected ? (
            <LoadingIndicatorWithSpin />
          ) : (
            <Meta
              avatar={
                <Avatar name={selectedContact?.data?.firstName!} size={50} />
              }
              title={getContactName(selectedContact)}
              description={GetContactIdentifier(selectedContact)}
              className="m-0 flex flex-row gap-4"
            />
          )}

          <Button
            type="link"
            size="large"
            icon={<CloseCircleOutlined size={20} />}
            shape="circle"
            onClick={() => setSelectedContact(null as any)}
          />
        </div>
      )}
      {!selectedContactId && (
        <div className="flex flex-row items-center">
          <AutoComplete
            popupClassName="certain-category-search-dropdown"
            // dropdownMatchSelectWidth={500}
            // style={{ width: 200 }}
            onSelect={(selectedContactId, option) => {
              setSelectedContact(selectedContactId);
            }}
            options={(originalData?.docs || []).map((contact) => ({
              value: contact.id!,
              data: contact,
              label: (
                <ContactPill
                  name={getContactName(contact)}
                  email={GetContactIdentifier(contact) || ""}
                  // avatar={<ContactAvatar contactId={contact.id!} />}
                />
              ),
            }))}
          >
            <Input.Search
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search for Contact"
            />
          </AutoComplete>
          {!hideAdd && (
            <>
              <div className="px-4">or</div>
              <Button
                onClick={() =>
                  triggerTempModal(AddContact, {
                    hideOnCreate: true,
                    onCreated: setSelectedContact,
                  })
                }
              >
                Add Customer
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
};
