import { iArticleCollection } from "@sdk/knowledge-base/knowledge-base.models";
import classNames from "classnames";
import { ImageX } from "components/common/image";

export const HelpCenterCollectionItem = ({
  collection,
  onSelect
}: {
  collection: iArticleCollection;
  onSelect?: () => any;
}) => {
  return (
    <div
      className="my-4 border-gray-200 dark:border-gray-800 border rounded-md flex collection-item draggable-collection mode_transition"
      onClick={onSelect}
    >
      <div
        className={classNames(
          "w-full border-0 bg-white dark:bg-gray-900 rounded-lg  mode_transition",
          {
            "hover:bg-blue-100 cursor-pointer dark:hover:bg-gray-800": Boolean(
              onSelect
            )
          }
        )}
      >
        <div className="flex flex-row justify-center items-center">
          <div className="flex flex-row flex-1 items-center p-8">
            <div className="flex items-center w-20">
              <ImageX
                src={collection.icon || "/knowledge-base/icons/article.png"}
                width={85}
                preview={false}
                className="rounded-full"
              />
            </div>
            <div className="flex-1 flex flex-col justify-center pl-4">
              <div className=" text-blue-600 text-xl">{collection.label}</div>
              <div className=" text-gray-600 dark:text-gray-400 mode_transition">
                {collection.description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
