import { useRef } from "react";
import "./playground.scss";

export const Playground = () => {
  const editorRef = useRef<any>(null);

  return (
    <div className="w-full h-full m-8">
      {/* <OfflineChatBot /> */}

      {/* <div className="">
        <ProductCard />
        <ProductLandingPage />
        <FlightBookingCard />
        <HotelBookingCard />
        <HolidayPackageCard />
      </div> */}

      <div className="mt-20 w-full h-full ">
        <div className="flex m-auto w-3/6 border-gray-300 border">
          {/* <LexicalEditorApp
            editorId={"editor 1"}
            editorRef={editorRef}
            onChange={(e) => {
              // console.log("Change", e);
            }}
            onBlur={() => {
              console.log("on Blur");
            }}
          /> */}
          {/* <AppSumoProductReviews /> */}
          {/* <DataProcessingAgreement /> */}
        </div>
      </div>

      {/* <Button
        onClick={async () => {
          const html = await editorRef.current.getEditorState();
          console.log("html", html);
        }}
      >
        Test
      </Button> */}

      {/* <Button onClick={() => {}}>Test 2</Button> */}

      <div className="div">
        {/* <TwitterPostCard /> */}
        {/* <TwitterPost /> */}
      </div>
    </div>
  );
};
