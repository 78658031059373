import { iRouteItem } from "components/common/side-bar-menu/menu-item-mode";
import { push } from "connected-react-router";
import { Action } from "kbar";
import { flatten } from "lodash";

import { Dispatch } from "redux";

export const KBNavGenerator = (
  item: iRouteItem,
  dispatch: Dispatch,
  prefixLabel: string = "",
  prefixPath: string = ""
) => {
  return [
    item.deepLink
      ? {
          id: `${prefixLabel}${item.label}`,
          name: `${prefixLabel}${item.label}`,
          icon: <i className="ri-arrow-right-line"></i>,
          keywords: `Navigation Shortcut`,
          section: "Navigation",
          perform: () => dispatch(push(`${prefixPath}${item.deepLink!}`)),
        }
      : undefined,
    // Unfurl Children
    ...(item.children || [])
      .map((child) =>
        child.deepLink || child.action
          ? {
              id: `${prefixLabel}${item.label} > ${child.label}`,
              name: `${prefixLabel}${item.label} > ${child.label}`,
              icon: <i className="ri-arrow-right-line"></i>,
              section: "Navigation",
              keywords: `Navigation Shortcut`,
              perform: () => {
                if (child.deepLink) {
                  dispatch(push(`${prefixPath}${child.deepLink!}`));
                } else if (child.action) {
                  child.action();
                }
              },
            }
          : undefined
      )
      .filter((item) => item),
    // Unfurl Children > Sections
    ...flatten(
      (item.children || [])
        .map((child) =>
          child.sections || child.actions
            ? [...(child.sections || []), ...(child.actions || [])]
                .map((section) =>
                  section.deepLink || section.action
                    ? {
                        id: `${prefixLabel}${item.label} > ${section.label}`,
                        name: `${prefixLabel}${item.label} > ${section.label}`,
                        icon: <i className="ri-arrow-right-line"></i>,
                        section: "Navigation",
                        keywords: `Navigation Shortcut`,
                        perform: () => {
                          if (section.deepLink) {
                            dispatch(push(`${prefixPath}${section.deepLink!}`));
                          } else if (section.action) {
                            section.action();
                          }
                        },
                      }
                    : undefined
                )
                .filter((item) => item)
            : undefined
        )
        .filter((item) => item)
    ),
  ].filter((item) => item) as Action[];
};
