import { SocketConnector } from "@sdk/@libs/socket-connector";
import { useSDK, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { WAState } from "@sdk/whatsapp-private/whatsapp-private.service";
import { Button, Modal } from "antd";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { QRCodeUpdate } from "../configure-whatsapp-private-connection/whatsapp-qr-code-status";

export const TroubleshootWhatsappConnectionModal = ({
  visible,
  onChangeVisibility: _onChangeVisibility,
  connectionId,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  connectionId: string;
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const {
    data: status,
    reload: reloadStatus,
    isLoading: isStatusLoading,
    error: statusHasError,
  } = useSDK(
    (SDK) => SDK.whatsAppPrivateX.getConnectionStatus(connectionId),
    [connectionId],
    !connectionId
  );

  const statusRef = useRef({ status, statusHasError });

  useEffect(() => {
    statusRef.current = { status, statusHasError };
  }, [status, statusHasError]);

  const {
    doAction: rebootInstance,
    isProcessing: isRebooting,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () => SDK.whatsAppPrivateX.rebootInstance(connectionId),
      successMessage: "Instance is rebooting",
      failureMessage: "Something went wrong",
    }),
    [connectionId]
  );

  const {
    doAction: resetInstance,
    isProcessing: isResetting,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () => SDK.whatsAppPrivateX.resetInstance(connectionId),
      successMessage: "Instance is resetting",
      failureMessage: "Something went wrong",
    }),
    [connectionId]
  );

  const { doAction: logout, isProcessing: isLoggingOut } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () =>
        SDK.whatsAppPrivateX.logout(connectionId).then(() => {
          return reloadStatus();
        }),
      successMessage: "Successfully logged out",
      failureMessage: "Something went wrong",
    }),
    [connectionId, reloadStatus]
  );

  useEffect(() => {
    const listener = (data: {
      name: string;
      data: { connectionId: string } & QRCodeUpdate;
    }) => {
      if (
        data.name === "WHATSAPP_CONNECTION_STATUS" &&
        data.data.connectionId === connectionId
      ) {
        reloadStatus();
      }
    };
    if (SocketConnector.socket) {
      SocketConnector.socket.on("SPECIAL_EVENTS", listener);
      return () => {
        SocketConnector.socket.off("SPECIAL_EVENTS", listener);
      };
    }
  }, [connectionId, reloadStatus]);

  const [showScreenshot, setShowScreenshot] = useState(true);

  const { data: screenshot, isLoading, error, reload: reloadDebug } = useSDK(
    (SDK) => SDK.whatsAppPrivateX.getScreenshot(connectionId),
    [connectionId, showScreenshot, status?.lastQrCode],
    !showScreenshot
  );

  const screenshotUrl = useMemo(() => {
    try {
      let srcValue = "data:image/png;base64," + screenshot.base64;
      return srcValue;
    } catch (e) {
      return "";
    }
  }, [screenshot]);

  const onChangeVisibility = (visibility: boolean) => {
    _onChangeVisibility(visibility);
    setCurrentStep(0);
  };

  const moveToConnectedState = useCallback(() => {
    setCurrentStep(6);
  }, []);

  const moveToTroubleshootFailedState = useCallback(() => {
    setCurrentStep(7);
  }, []);

  useEffect(() => {
    if (status.state === WAState.CONNECTED) {
      moveToConnectedState();
    }
  }, [moveToConnectedState, status.state]);

  const startThirdStage = useCallback(async () => {
    setCurrentStep(4);
    await resetInstance();
    const startTime = Date.now();
    const timer = setInterval(() => {
      const currenTime = Date.now();
      if (startTime + 2 * 60 * 1000 < currenTime) {
        clearInterval(timer);
        moveToTroubleshootFailedState();
      } else {
        if (
          !statusRef.current.statusHasError &&
          statusRef.current.status.lastQrCode
        ) {
          clearInterval(timer);
          setCurrentStep(5);
        }
      }
      reloadStatus();
    }, 5000);
  }, [moveToTroubleshootFailedState, reloadStatus, resetInstance]);

  const startSecondStage = useCallback(async () => {
    setCurrentStep(2);
    await rebootInstance();
    const startTime = Date.now();
    const timer = setInterval(() => {
      const currenTime = Date.now();
      if (startTime + 2 * 60 * 1000 < currenTime) {
        clearInterval(timer);
        startThirdStage();
      } else {
        if (statusRef.current?.status?.state === WAState.CONNECTED) {
          clearInterval(timer);
          moveToConnectedState();
        } else if (
          !statusRef.current.statusHasError &&
          statusRef.current.status.lastQrCode
        ) {
          clearInterval(timer);
          setTimeout(() => {
            setCurrentStep(3);
          }, 8000);
        }
      }
      reloadStatus();
    }, 5000);
  }, [moveToConnectedState, rebootInstance, reloadStatus, startThirdStage]);

  const startTroubleshooting = useCallback(() => {
    if (
      statusRef.current.statusHasError ||
      !statusRef?.current?.status?.lastQrCode
    ) {
      startSecondStage();
    } else {
      setCurrentStep(1);
    }
  }, [startSecondStage]);

  const viewScreenshot = useCallback(() => {
    Modal.info({
      title: (
        <ModalTitle
          title={"WA Instance"}
          icon={<i className="ri-eye-line"></i>}
        />
      ),
      icon: false,
      content: (
        <div
          style={{
            width: 750,
            height: 600,
            overflow: "auto",
          }}
        >
          <img src={screenshotUrl} alt="" />
        </div>
      ),
      okText: "Close",
      width: 800,
    });
  }, [screenshotUrl]);

  // Step 0 - Start Screen
  // Step 1 - Looks to be connected; Got to scan QR code
  // Step 2 - Restart Instance
  // Step 3 - Show QR code after Restart
  // Step 4 - Reset Instance
  // Step 5 - Show QR code after Reset
  // Step 6 - Troubleshoot Successful; // Received Connected State
  // Step 7 - Troubleshoot Failed

  return (<>
    <Modal
      title={
        <ModalTitle
          title={"Troubleshoot Whatsapp Connection"}
          icon={<i className="ri-whatsapp-line"></i>}
        />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      footer={null}
      className="responsive-modal w-full max-w-2xl"
      data-click-context={`Troubleshoot Whatsapp Connection Modal`}
    >
      <div className="steps-container">
        {/* Step 0 - Start Screen */}
        {currentStep === 0 && (
          <>
            <div className="">
              You are about to troubleshoot WA Connection. WA connection can
              be broken due to multiple reasons.
              <ul>
                <li>
                  👉 Your WA mobile is not connected to internet for a long
                  time
                </li>
                <li>👉 Your WA mobile app is outdated</li>
                <li>👉 WhatsApp Web had a recent update</li>
                <li>
                  👉 WhatsApp Web servers are down.
                  <a
                    target={"_blank"}
                    href="https://www.isitdownrightnow.com/web.whatsapp.com.html"
                    rel="noreferrer"
                  >
                    Click Here to check the status
                  </a>
                </li>
              </ul>
              <div className="mt-4 flex flex-row justify-center items-center">
                {!isStatusLoading && !statusHasError && (
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex flex-row justify-center items-center">
                      Current Connection Status:
                      {status?.state || "Not Connected"}
                    </div>
                  </div>
                )}
                {!isStatusLoading && statusHasError && (
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex flex-row justify-center items-center">
                      Current Connection Status: Not Responding
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-4 flex flex-row justify-center items-center">
                <Button
                  type="primary"
                  onClick={startTroubleshooting}
                  icon={<i className="ri-stethoscope-line"></i>}
                >
                  Start Troubleshooting
                </Button>
              </div>
            </div>
          </>
        )}
        {/* Step 1 - Looks to be connected; Got to scan QR code */}
        {currentStep === 1 && (
          <>
            <div className="">
              <div className="flex flex-col justify-center items-center border border-gray-200 rounded-lg p-4 dark:border-gray-800">
                <div className="">Scan the QR Code to Authenticate</div>
                <div
                  className="cursor-pointer"
                  style={{
                    width: 300,
                    height: 300,
                    backgroundPosition: "84% 45%",
                    backgroundSize: "294%",
                    backgroundImage: `url(${screenshotUrl})`,
                  }}
                  onClick={viewScreenshot}
                ></div>
              </div>
              <div className="mt-4 flex flex-row justify-center items-center">
                <Button
                  type="primary"
                  onClick={startSecondStage}
                  icon={<i className="ri-stethoscope-line"></i>}
                >
                  Still Facing Trouble?
                </Button>
              </div>
            </div>
          </>
        )}
        {/* Step 2 - Restart Instance */}
        {currentStep === 2 && (
          <>
            <div className="flex flex-col justify-center items-center">
              <div className="font-bold text-2xl">
                Restarting Your WA Instance.
              </div>
              <div className="text-gray-600 mb-8">
                This may take upto 2 minutes
              </div>
              <LoadingIndicatorWithSpin />
            </div>
          </>
        )}

        {/*  Show QR code after Restart */}
        {currentStep === 3 && (
          <>
            <div className="">
              <div className="flex flex-col justify-center items-center border border-gray-200 rounded-lg p-4 dark:border-gray-800">
                <div className="">Scan the QR Code to Authenticate</div>
                <div
                  className="cursor-pointer"
                  style={{
                    width: 300,
                    height: 300,
                    backgroundPosition: "84% 45%",
                    backgroundSize: "294%",
                    backgroundImage: `url(${screenshotUrl})`,
                  }}
                  onClick={viewScreenshot}
                ></div>
              </div>
              <div className="mt-4 flex flex-row justify-center items-center">
                <Button
                  type="primary"
                  onClick={startThirdStage}
                  icon={<i className="ri-stethoscope-line"></i>}
                >
                  Still Facing Trouble?
                </Button>
              </div>
            </div>
          </>
        )}

        {/* Step 4 - Reset Instance */}
        {currentStep === 4 && (
          <>
            <div className="flex flex-col justify-center items-center">
              <div className="font-bold text-2xl">
                Trying to Reset Your WA Instance.
              </div>
              <div className="text-gray-600 mb-8">
                This may take upto 2 minutes
              </div>
              <LoadingIndicatorWithSpin />
            </div>
          </>
        )}

        {/* Step 5 - Show QR code after Reset */}
        {currentStep === 5 && (
          <>
            <div className="">
              <div className="flex flex-col justify-center items-center border border-gray-200 rounded-lg p-4 dark:border-gray-800">
                <div className="">Scan the QR Code to Authenticate</div>
                <div
                  className="cursor-pointer"
                  style={{
                    width: 300,
                    height: 300,
                    backgroundPosition: "84% 45%",
                    backgroundSize: "294%",
                    backgroundImage: `url(${screenshotUrl})`,
                  }}
                  onClick={viewScreenshot}
                ></div>
              </div>
              <div className="mt-4 flex flex-row justify-center items-center">
                <Button
                  type="primary"
                  onClick={moveToTroubleshootFailedState}
                  icon={<i className="ri-stethoscope-line"></i>}
                >
                  Still Facing Trouble?
                </Button>
              </div>
            </div>
          </>
        )}

        {/* Step 6 - Troubleshoot Successful; // Received Connected State */}
        {currentStep === 6 && (
          <>
            <div className="flex flex-col justify-center items-center">
              <div className="font-bold text-2xl">
                Troubleshoot has been completed
              </div>
              <div className="mt-4 flex flex-row justify-center items-center">
                {!isStatusLoading && !statusHasError && (
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex flex-row justify-center items-center">
                      Current Connection Status:
                      {status?.state || "Not Connected"}
                    </div>
                  </div>
                )}
                {!isStatusLoading && statusHasError && (
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex flex-row justify-center items-center">
                      Current Connection Status: Not Responding
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {/* Step 7 - Troubleshoot Failed */}
        {currentStep === 7 && (
          <>
            <div className="flex flex-col justify-center items-center">
              <div className="font-bold text-2xl">
                Troubleshoot was not successful
              </div>
              <div className="">
                Please check the following and contact support
              </div>
              <ul>
                <li>
                  👉 Your WA mobile is not connected to internet for a long
                  time
                </li>
                <li>👉 Your WA mobile app is outdated</li>
                <li>👉 WhatsApp Web had a recent update</li>
                <li>
                  👉 WhatsApp Web servers are down.
                  <a
                    target={"_blank"}
                    href="https://www.isitdownrightnow.com/web.whatsapp.com.html"
                    rel="noreferrer"
                  >
                    Click Here to check the status
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
      <DarkModeBg />
    </Modal>
  </>);
};
