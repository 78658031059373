import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectSubscriptionDetails } from "store/modules/workspace/workspace.selectors";
import {
  APPSUMO_PLANS,
  BUSINESS_PRICING,
  STARTUP_PRICING,
  TEAM_PRICING,
} from "./pricing-plans-data";

export const useSubscriptionDetails = () => {
  const subscriptionDetails = useSelector(selectSubscriptionDetails);

  const planId = subscriptionDetails?.planId || "STARTUP_MONTHLY";

  const isSubscribed = !(
    !subscriptionDetails ||
    !subscriptionDetails.planId ||
    subscriptionDetails.planId === "STARTUP_MONTHLY" ||
    subscriptionDetails.planId === "STARTUP_YEARLY" ||
    subscriptionDetails.planId === "FREE"
  );

  const plan = useMemo(() => {
    const id = planId.replace(`_YEARLY`, "").replace(`_MONTHLY`, "");
    const plan = _.find(
      [
        ...APPSUMO_PLANS,
        ...STARTUP_PRICING,
        ...TEAM_PRICING,
        ...BUSINESS_PRICING,
      ],
      {
        id,
      },
    );
    return plan;
  }, [planId]);

  return {
    isSubscribed,
    subscriptionDetails,
    isYearly: planId?.includes("_YEARLY"),
    plan: plan,
  };
};
