import { Form, Input, Modal, Tag, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import "components/modules/connections/channels/chat-widget/components/chat-widget-theme/screenshot-frame.scss";
import {
  ColorPickerFormItem,
  ImageUploaderFormItem,
} from "components/modules/connections/channels/email/components/forms/email-template";
import { DarkModeBg } from "dark-mode-bg";
import { useMemo, useRef } from "react";

import { ExtendControlType } from "braft-editor";
import { defaultAdditionalExcludedBraftControllers } from "components/common/draft-js-advanced/default-excluded-braft-controllers";
import { BraftJSInput } from "components/common/draft-js-advanced/draft-js-advanced";
import { AvailableCampaignTokenTable } from "components/modules/conversations/components/chat-input/available-message-tokens";

export interface iEmailBlock {
  id: string;
  type: "LOGO_BLOCK" | "HERO_BLOCK" | "BUTTON_BLOCK" | "SINGLE_COLUMN_TEXT";
  config: any;
}

export const SimpleEmailBlockEditorModal = ({
  visible,
  onChangeVisibility,
  blockInContext,
  onSave,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  blockInContext: iEmailBlock;
  onSave: (data: iEmailBlock) => any;
}) => {
  const [form] = useForm();

  const draftJsRef = useRef<any>();

  const extendControls = useMemo(() => {
    const extendControls: ExtendControlType[] = [
      {
        key: "message-presets",
        type: "modal",
        text: (
          <Tooltip title="Message Personalization">
            <i className="ri-braces-line"></i>
          </Tooltip>
        ),
        modal: {
          id: "message-personalization-modal",
          title: (
            <span>
              <ModalTitle
                title="Message Personalization"
                icon={<i className="ri-braces-line"></i>}
              />
            </span>
          ) as any,
          showFooter: false,
          closeOnBlur: true,
          className: "message-personalization-modal",
          children: (
            <div style={{ width: 400, padding: "0 10px" }}>
              <div className="my-4">
                You can use variable tokens in your messages
                <div className="font-bold my-4">Available Tokens:</div>
                <table className="simple-table-style w-full">
                  <thead>
                    <tr>
                      <th className="font-bold">Variable</th>
                      <th className="font-bold">Token</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AvailableCampaignTokenTable.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.label}</td>
                          <td>
                            <Tag
                              onClick={() => {
                                draftJsRef.current.insertHtmlToCursor(
                                  `<span class="variable-token-item">{${item.token}}</span>`,
                                );
                                const cb =
                                  draftJsRef.current.getControlBarInstance();
                                cb?.extendedModals?.[
                                  "message-personalization-modal"
                                ].close();
                              }}
                              className="cursor-pointer"
                            >
                              {item.token}
                            </Tag>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ),
        },
      },
    ];
    return extendControls;
  }, []);

  return (
    <Modal
      title={
        <ModalTitle
          title="Edit Email Block"
          icon={<i className="ri-edit-line"></i>}
        />
      }
      open={visible}
      className="simple-email-creator-block-editor"
      okText={"Continue"}
      onOk={() => {
        const formValues = form.getFieldsValue();
        const config = (() => {
          if (blockInContext?.type === "SINGLE_COLUMN_TEXT") {
            if (draftJsRef.current.getHtmlContent) {
              const html = draftJsRef.current.getHtmlContent();
              return { ...formValues, html };
            }
          }
          return formValues;
        })();
        onSave({
          ...blockInContext,
          config,
        });
        onChangeVisibility(false);
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Email Block Editor Modal"
      width={blockInContext?.type === "SINGLE_COLUMN_TEXT" ? 680 : undefined}
    >
      {blockInContext && (
        <div className="bold-form-labels">
          <Form
            layout="vertical"
            form={form}
            initialValues={blockInContext.config}
            className="bold-form-titles"
          >
            {blockInContext.type === "LOGO_BLOCK" && (
              <>
                <ImageUploaderFormItem
                  label="Logo"
                  fieldName={["url"]}
                  entityType="CAMPAIGN"
                  entityId={"campaignId"}
                />
                <Form.Item
                  label="Image Width (in px)"
                  name="width"
                  rules={[]}
                  help="Leave it blank to make it auto"
                >
                  <Input
                    placeholder=""
                    size="large"
                    type={"number"}
                    min={50}
                    max={600}
                  />
                </Form.Item>
                <Form.Item
                  label="Image Height (in px)"
                  name="height"
                  rules={[]}
                  help="Leave it blank to make it auto"
                >
                  <Input
                    placeholder=""
                    size="large"
                    type={"number"}
                    min={50}
                    max={600}
                  />
                </Form.Item>
              </>
            )}

            {blockInContext.type === "HERO_BLOCK" && (
              <>
                <ImageUploaderFormItem
                  label="Hero Image"
                  fieldName={["url"]}
                  entityType="CAMPAIGN"
                  entityId={"campaignId"}
                />
                <Form.Item
                  label="Image Width (in px)"
                  name="width"
                  rules={[]}
                  help="Leave it blank to make it auto"
                >
                  <Input
                    placeholder=""
                    size="large"
                    type={"number"}
                    min={50}
                    max={600}
                  />
                </Form.Item>
                <Form.Item
                  label="Image Height (in px)"
                  name="height"
                  rules={[]}
                  help="Leave it blank to make it auto"
                >
                  <Input
                    placeholder=""
                    size="large"
                    type={"number"}
                    min={50}
                    max={600}
                  />
                </Form.Item>
              </>
            )}

            {blockInContext.type === "SINGLE_COLUMN_TEXT" && (
              <>
                <div className="draft-js-container border border-gray-200 dark:border-gray-700 rounded-lg">
                  <BraftJSInput
                    initialValue={blockInContext?.config?.html}
                    ref={draftJsRef}
                    additionalExcludedControls={
                      defaultAdditionalExcludedBraftControllers
                    }
                    extendControls={extendControls}
                    showControlsOnTop={true}
                  />
                </div>
              </>
            )}

            {blockInContext.type === "BUTTON_BLOCK" && (
              <>
                <Form.Item
                  label="Button Label"
                  name="label"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a friendly name",
                    },
                  ]}
                  help="A friendly name to identify channel Eg: Live Chat"
                >
                  <Input placeholder="" size="large" />
                </Form.Item>

                <Form.Item
                  label="Button URL"
                  name="buttonURL"
                  rules={[
                    { required: true, message: "Please enter a url" },
                    { type: "url", message: "Please enter a valid URL" },
                  ]}
                >
                  <Input placeholder="" size="large" />
                </Form.Item>

                <ColorPickerFormItem
                  fieldName={"buttonColor"}
                  label="Button Color"
                />
              </>
            )}
          </Form>
        </div>
      )}
      <DarkModeBg />
    </Modal>
  );
};
