import { replaceAll } from "./replace-all";

export const ExtractFirstEmailFromString = (str: string) => {
  const matches = str.match(
    /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
  );
  return matches![0];
};

export function parseEmailHeader(
  emailString: string
): {
  name: string;
  email: string;
}[] {
  emailString = replaceAll(emailString, "+", " ");
  const parts = emailString.split(",");
  const members: { name: string; email: string }[] = [];
  for (const part of parts) {
    const fromString = part.trim();
    const extract = { name: "", email: "" };

    const emails = fromString.match(/[^@<\s]+@+([\w-]+\.)+[\w-]{2,4}/g);

    if (emails) {
      extract.email = ExtractFirstEmailFromString(emails[0]).toLowerCase();
    }

    const names = fromString.split(/\s+/);

    if (names.length > 1) {
      names.pop();
      extract.name = names.join(" ").replace(/"/g, "");
    }
    if (extract.email) {
      members.push(extract);
    }
  }
  return members;
}
