import LineOutlined from "@ant-design/icons/LineOutlined";
import RightOutlined from "@ant-design/icons/RightOutlined";
import { iChatFAQ } from "@sdk/chat-widgets/chat-widgets.models";

export const ChatWidgetFAQsPAGE = ({
  categoryTitle,
  questions,
}: {
  categoryTitle?: string;
  questions: iChatFAQ[];
}) => {
  return (
    <div>
      <div>
        {categoryTitle && (
          <div className="text-white  items-center pt-6 pb-6 mb-2 flex justify-between rounded-lg cursor-pointer font-semibold primary-gradient-bg-2">
            <div className="ml-4 ">{categoryTitle}</div>
            <div className="mr-3 ">
              <LineOutlined />
            </div>
          </div>
        )}

        <div className="animate fade-in-up">
          {questions.map((item) => {
            return (
              <div className="flex flex-row pointer hover:bg-gray-100 p-4 px-8 cursor-pointer justify-between items-center">
                <div className="title">{item.question}</div>
                <div className="icon-container">
                  <RightOutlined
                    style={{ fontSize: "1.5rem", fontWeight: "900" }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
