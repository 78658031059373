import { useEffect, useRef } from "react";

// https://github.com/facebook/react/issues/13029
// Usage - <div ref={useCombinedRefs(ref1, ref2)} />
export function useCombinedRefs(...refs) {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return;

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        if (ref) {
          ref.current = targetRef.current;
        }
      }
    });
  }, [refs]);

  return targetRef;
}
