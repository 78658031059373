import { iReportWidget } from "components/pages/reports/components/report-canvas/widget-canvas-models";
import { UseReportDataProcessor } from "components/pages/reports/helpers/use-report-data-processor";

import _ from "lodash";
import { memo, Suspense, useCallback, useMemo, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useMeasure } from "react-use";
import { columnChartConfig } from "./configs/column-chart";

import { EmptyData } from "components/common/empty-data/empty-data";
import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React from "react";
import "./../report-widget-legacy.scss";

const HighchartsReact = React.lazy(
  () => import("@libs/high-charts-lazy/high-charts-lazy"),
);

const GetContainerProps = _.memoize(
  (chartWidth: number, chartHeight: number) => ({
    style: {
      width: chartWidth - 50,
      height: chartHeight - 100,
    },
  }),
  (...args) => JSON.stringify(args),
);

export const ReportWidgetBarChart = memo(
  ({
    data,
    reportConfig,
    onInspectSegment,
    isInspectAvailable,
    reportNoun,
  }: {
    data: any[];

    reportConfig: iReportWidget;
    onInspectSegment?: (point) => any;
    isInspectAvailable?: boolean;
    reportNoun: string;
  }) => {
    const menuContainerRef = useRef<HTMLDivElement>(null);

    const [menuVisibility, setMenuVisibility] = useState(false);
    const [clickContext, setClickContext] = useState({} as any);

    const {
      postDimensions: dimensions,
      processedData: initialData,
      countKey,
      countTemplate,
      idAttributes,
    } = UseReportDataProcessor(data, reportConfig);

    const { isMultiDimensional, primaryDimension } = useMemo(() => {
      const isMultiDimensional = dimensions.length > 1;
      const visibleDimensions = _.without(dimensions, ...idAttributes);
      return {
        isMultiDimensional: isMultiDimensional,
        // Todo: This needs to be cleaned
        primaryDimension: isMultiDimensional
          ? visibleDimensions[1]
          : visibleDimensions[0],
      };
    }, [dimensions, idAttributes]);

    const chartDataX = _.cloneDeep(initialData);

    const [setRef, { width, height }] = useMeasure();

    const chartOptions = useMemo(() => {
      return ((data: any) => {
        let config: Highcharts.Options;
        config = _.cloneDeep(columnChartConfig);
        //* without -5 it auto expands forever
        (config.chart as any).height = height - 5;
        if (height < 250) {
          (config.legend as any).enabled = false;
          config.xAxis = {
            labels: {
              enabled: false,
            },
          };
        }

        if (data.length === 0) {
          return config;
        }

        if (isMultiDimensional) {
          const visibleDimensions = _.without(dimensions, ...idAttributes);
          const secondaryDimension = _.without(
            visibleDimensions,
            primaryDimension,
          )[0];
          const groupedData = _.groupBy(data, primaryDimension);

          const primaryDimensionCategories = Object.keys(groupedData);
          const secondaryDimensionCategories = Object.keys(
            _.groupBy(data, secondaryDimension),
          );

          const seriesData = primaryDimensionCategories.map((group) => ({
            name: group,
            data: secondaryDimensionCategories.map((item) => {
              const record = _.find(data, {
                [primaryDimension]: group,
                [secondaryDimension]: item,
              });
              return {
                y: record ? record[countKey] : 0,
                name: item,
                record,
              };
            }),
            animation: false,
            type: "column",
            cursor: "crosshair",
            point: {
              events: {
                click: function (e) {
                  const container = menuContainerRef.current;
                  // console.log("Series Clicked", e);
                  if (container && onInspectSegment && isInspectAvailable) {
                    setMenuVisibility(true);
                    container.setAttribute(
                      "style",
                      "top: " + e.offsetY + "px; left:" + e.offsetX + "px;",
                    );
                    setClickContext({
                      record: (e.point.options as any)?.record,
                    });
                  }
                },
              },
            },
          })) as any;

          config.series = seriesData;

          config.xAxis = {
            categories: secondaryDimensionCategories,
            crosshair: true,
          };
        } else {
          config!.series?.forEach((series) => {
            (series as any).data = data.map((item) => ({
              name: item[primaryDimension],
              y: item[countKey],
              record: item,
            }));
            series.name = reportConfig.title;
            config.xAxis = {
              categories: data.map((d) => d[primaryDimension]),
              crosshair: true,
            };

            series.point!.events!.click = function (e) {
              const container = menuContainerRef.current;
              console.log("Series Clicked", e);
              if (container && isInspectAvailable) {
                setMenuVisibility(true);
                container.setAttribute(
                  "style",
                  "top: " + e.offsetY + "px; left:" + e.offsetX + "px;",
                );
                setClickContext({
                  record: (e.point.options as any)?.record,
                });
              }
            };
          });
        }

        if (countTemplate) {
          config.tooltip = {
            formatter: function () {
              return countTemplate((this as any).y);
            },
          };
          // (config.yAxis as any).type = "datetime";
          (config.yAxis as any).labels = {
            formatter: function ({ value }) {
              return countTemplate(value);
            },
          };
        }

        return config;
      })(chartDataX);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      chartDataX,
      countKey,
      countTemplate,
      dimensions,
      height,
      isMultiDimensional,
      primaryDimension,
      reportConfig.title,
    ]);

    const menu = useMemo(
      () => (
        <div className="container">
          <div
            className="flex flex-row justify-between items-center flex-1 text-base leading-6 text-gray-800 hover:bg-gray-100 rounded-sm cursor-pointer p-2 border-b border-gray-200"
            onClick={() => {
              onInspectSegment!({
                action: "VIEW",
                record: clickContext?.record || { count: 0 },
                reportConfig: reportConfig,
              });
              setMenuVisibility(false);
            }}
          >
            <div className="left flex flex-row items-center">
              <i className="ri-eye-line pr-2"></i>
              <div className="label font-bold">
                {" "}
                View {reportNoun || "Records"}
              </div>
            </div>
          </div>
          {/* <div
            className="flex flex-row justify-between items-center flex-1 text-base leading-6 text-gray-800 hover:bg-gray-100 rounded-sm cursor-pointer p-2"
            onClick={() => {
              onInspectSegment!({ action: "INSPECT", record: clickContext,  reportConfig: reportConfig, });
              setMenuVisibility(false);
            }}
          >
            <div className="left flex flex-row items-center">
              <i className="ri-zoom-in-line pr-2"></i>
              <div className="label  font-bold"> Inspect Selected Segment</div>
            </div>
          </div> */}
        </div>
      ),
      [clickContext?.record, onInspectSegment, reportConfig, reportNoun],
    );

    const onMenuClicked = useCallback((e) => e.stopPropagation(), []);
    const onMenuBgClicked = useCallback(() => setMenuVisibility(false), []);

    return (
      <div className="h-full w-full overflow-hidden" ref={setRef}>
        <div className="high-chart-container h-full w-full overflow-auto">
          {initialData.length > 0 && (
            <>
              <Suspense fallback={<SuspenseLoadingIndicator />}>
                <HighchartsReact options={chartOptions} />
              </Suspense>

              <OutsideClickHandler onOutsideClick={onMenuBgClicked}>
                <div
                  className="menu-background"
                  style={{ display: menuVisibility ? "block" : "none" }}
                  onClick={onMenuBgClicked}
                >
                  <div
                    className="menu-container sticky"
                    ref={menuContainerRef}
                    onClick={onMenuClicked}
                  >
                    {menu}
                  </div>
                </div>
              </OutsideClickHandler>
            </>
          )}

          {initialData.length === 0 && (
            <div className="h-full w-full flex flex-row justify-center items-center">
              <EmptyData
                icon={<i className="ri-bar-chart-line"></i>}
                text="Not enough data"
              />
            </div>
          )}
        </div>
      </div>
    );
  },
);
