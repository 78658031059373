import { SDK } from "@sdk";
import { iReminder } from "@sdk/reminders/reminder-model";

import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { remindersDefaultQueryConfig } from "./reminders-default-query";
import { RemindersSlice } from "./reminders.slice";

export const loadAllReminders = (store: Store<iStore>, forceReload?: boolean) =>
  loadRemindersQuery(remindersDefaultQueryConfig, "all")(store, forceReload);

export const {
  loadEntityQueries: loadRemindersQuery,
  loadEntityById: loadReminderById,
  loadEntitiesByIds: bulkLoadRemindersByIds
} = CreateHelpersForEntity<iStore, iReminder>({
  sliceName: "reminders",
  slice: RemindersSlice,
  isPaginatedQueries: false,
  loadEntityQueryProvider: query => SDK.reminders.getAll(),
  loadEntityByIdProvider: (entityId: string) => SDK.reminders.getById(entityId)
});
