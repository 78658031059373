import DragOutlined from "@ant-design/icons/DragOutlined";
import PlusCircleFilled from "@ant-design/icons/PlusCircleFilled";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import {
  iCustomField,
  iFormGroupItem,
} from "@sdk/dynamic-forms/dynamic-forms-model";
import { Button, Typography } from "antd";
import _ from "lodash";
import { Dispatch, SetStateAction, useCallback } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import { uuidv4 } from "utils/generate-uuid";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { FieldGroupEditorModal } from "./components/field-group-editor-modal";
import { FormFieldEditorModal } from "./components/form-field-editor-modal";
import { FormFieldIcon } from "./components/form-icon";

import "./dynamic-form-editor.style.scss";

const { Title } = Typography;

const getListStyle = (isDraggingOver, isDarkMode: boolean) => ({
  background: isDraggingOver
    ? isDarkMode
      ? "#2f3d56"
      : "#f4f4f4"
    : "transparent",
  minHeight: "50px",
});

const getItemStyle = (draggableStyle, isDragging, isDarkMode: boolean) => ({
  // change background colour if dragging
  background: isDragging ? (isDarkMode ? "#2f3d56" : "#f4f4f4") : "transparent",

  // styles we need to apply on draggables
  ...draggableStyle,
});

// a little function to help us with reordering the result
function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

function move<T>(
  source: T[],
  destination: T[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: { [droppableId: string]: T[] } = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
}

export const DynamicFormFieldEditor = ({
  data: formData = [],
  onDataChange: setFormData,
}: {
  data: iFormGroupItem[];
  onDataChange: Dispatch<SetStateAction<iFormGroupItem[]>>;
}) => {
  // Modals
  const [fieldEditorState, setFieldEditorState] = useSimpleState({
    visibility: false,
    mode: "ADD" as "ADD" | "EDIT",
    field: null as iCustomField | null,
  });

  const [fieldGroupEditorState, setFieldGroupEditorState] = useSimpleState({
    visibility: false,
    mode: "ADD" as "ADD" | "EDIT",
    group: null as iFormGroupItem | null,
  });

  const isDarkMode = useSelector(selectIsDarkMode);

  const onDragEnd = ({ type, destination, source }: DropResult) => {
    // Notes
    // On Drag End Event has to be handled for 3 different type of movement
    // 1) Form Group Order is changed
    // 2) Inside a Form Group, Form Field order is changed
    // 3) Form Field is moved from on Form Group to another
    // Ref
    // https://codesandbox.io/s/zqwz5n5p9x?file=/src/index.js:1332-1695
    // https://codesandbox.io/s/ql08j35j3q?file=/index.js:3504-3516
    if (!destination) {
      return;
    }
    // 1) Form Group Order is changed
    if (destination.droppableId === "FORM_GROUP_DROPPABLE") {
      if (destination.index === source.index) {
        return;
      }
      const reOrderedFormData = reorder(
        formData,
        source.index,
        destination.index
      );
      setFormData(reOrderedFormData);
    } else {
      // 2) Inside a Form Group, Form Field order is changed
      if (destination.droppableId === source.droppableId) {
        if (destination.index === source.index) {
          return;
        }

        const sourceListIndex = _.findIndex(
          formData,
          (group) => group.id === source.droppableId
        )!;
        const reGroupedFields = reorder(
          formData[sourceListIndex].fields,
          source.index,
          destination.index
        );

        setFormData(
          formData.map((formGroup, index) => {
            if (index === sourceListIndex) {
              return {
                ...formGroup,
                fields: reGroupedFields,
              };
            }
            return formGroup;
          })
        );
      } else {
        // 3) Form Field is moved from on Form Group to another
        const sourceListIndex = _.findIndex(
          formData,
          (group) => group.id === source.droppableId
        )!;

        const DestinationListIndex = _.findIndex(
          formData,
          (group) => group.id === destination.droppableId
        )!;

        const result = move(
          formData[sourceListIndex].fields,
          formData[DestinationListIndex].fields,
          source,
          destination
        );
        setFormData(
          formData.map((formGroup, index) => {
            if (index === sourceListIndex) {
              return {
                ...formGroup,
                fields: result[source.droppableId],
              };
            } else if (index === DestinationListIndex) {
              return {
                ...formGroup,
                fields: result[destination.droppableId],
              };
            }
            return formGroup;
          })
        );
      }
    }
  };

  const onRemoveGroup = useCallback(
    (groupId: string) => {
      setFormData(formData.filter((group) => group.id !== groupId));
    },
    [formData, setFormData]
  );

  const onRemoveField = useCallback(
    (groupId: string, fieldId: string) => {
      setFormData(
        formData.map((group) => {
          if (group.id === groupId) {
            return {
              ...group,
              fields: group.fields.filter((field) => field.id !== fieldId),
            };
          }
          return group;
        })
      );
    },
    [formData, setFormData]
  );

  return (
    <div className="flex flex-col form-config-container">
      {/* Header Bar */}

      <div className="w-full mt-4 p-4 overflow-auto">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="FORM_GROUP_DROPPABLE" type="FORM_GROUP">
            {(outerDroppableProvider) => (
              <div
                ref={outerDroppableProvider.innerRef}
                {...outerDroppableProvider.droppableProps}
              >
                {formData.map((formGroup, index) => (
                  <div key={formGroup.id}>
                    <Draggable
                      key={formGroup.id}
                      draggableId={formGroup.id}
                      index={index}
                    >
                      {(outerDraggableProvided) => (
                        <div
                          ref={outerDraggableProvided.innerRef}
                          {...outerDraggableProvided.draggableProps}
                          className="my-4"
                        >
                          <div
                            {...outerDraggableProvided.dragHandleProps}
                            className="h-10 px-2 flex text-lg font-bold border border-b-0 rounded-t-md border-gray-200 dark:border-gray-800"
                          >
                            <div className="w-10 flex  justify-center items-center">
                              <DragOutlined />
                            </div>
                            <div
                              className="p-2 flex-1 truncate"
                              onClick={(e) => {
                                setFieldGroupEditorState({
                                  visibility: true,
                                  mode: "EDIT",
                                  group: formGroup,
                                });
                                e.stopPropagation();
                              }}
                            >
                              {formGroup.title}
                            </div>
                            <div
                              className="w-10 flex justify-center items-center cursor-pointer"
                              onClick={(e) => {
                                setFieldGroupEditorState({
                                  visibility: true,
                                  mode: "EDIT",
                                  group: formGroup,
                                });
                                e.stopPropagation();
                              }}
                            >
                              <SettingOutlined className="text-lg" />
                            </div>
                            <div
                              className="w-10 flex justify-center items-center cursor-pointer"
                              onClick={(e) => {
                                onRemoveGroup(formGroup.id);
                                e.stopPropagation();
                              }}
                            >
                              <i className="ri-delete-bin-line text-lg font-normal"></i>
                            </div>
                          </div>
                          <Droppable
                            droppableId={formGroup.id}
                            key={formGroup.id}
                            type="FIELDS"
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                style={getListStyle(
                                  snapshot.isDraggingOver,
                                  isDarkMode
                                )}
                                {...provided.droppableProps}
                                className="py-1 border border-gray-200 rounded-b-md bg-gray-100 dark:bg-gray-900 dark:border-gray-800"
                              >
                                {formGroup.fields.map(
                                  (fieldItem, fieldIndex) => (
                                    <div key={fieldItem.id}>
                                      <Draggable
                                        key={fieldItem.id}
                                        draggableId={fieldItem.id}
                                        index={fieldIndex}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            style={getItemStyle(
                                              provided.draggableProps.style,
                                              snapshot.isDragging,
                                              isDarkMode
                                            )}
                                            className="my-3 mx-1 h-10 flex items-center border border-gray-200 dark:border-gray-800"
                                          >
                                            <div
                                              {...provided.dragHandleProps}
                                              className="w-10 flex justify-center items-center h-10"
                                            >
                                              <DragOutlined />
                                            </div>
                                            <div
                                              className="flex-1 pl-2 cursor-pointer flex flex-row items-center overflow-hidden"
                                              onClick={() =>
                                                setFieldEditorState({
                                                  visibility: true,
                                                  mode: "EDIT",
                                                  field: fieldItem,
                                                })
                                              }
                                            >
                                              <div className="mr-2">
                                                <FormFieldIcon
                                                  type={fieldItem.inputType}
                                                />
                                              </div>
                                              <div className="flex-1 truncate">
                                                {fieldItem.label}
                                              </div>
                                            </div>
                                            <div
                                              className="w-10 flex justify-center items-center cursor-pointer"
                                              onClick={() =>
                                                setFieldEditorState({
                                                  visibility: true,
                                                  mode: "EDIT",
                                                  field: fieldItem,
                                                })
                                              }
                                            >
                                              <SettingOutlined className="text-lg" />
                                            </div>
                                            <div
                                              className="w-10 flex justify-center items-center cursor-pointer"
                                              onClick={(e) => {
                                                onRemoveField(
                                                  formGroup.id,
                                                  fieldItem.id
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              <i className="ri-delete-bin-line text-lg"></i>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    </div>
                                  )
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}
                    </Draggable>
                  </div>
                ))}
                {outerDroppableProvider.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="w-full mt-12 p-4 flex flex-col">
        <div className="flex flex-col justify-center gap-4 items-center">
          {formData.length > 0 && (
            <div className="">
              <Button
                type="dashed"
                onClick={() => {
                  setFieldEditorState({
                    visibility: true,
                    mode: "ADD",
                    field: null,
                  });
                }}
                icon={<PlusCircleFilled />}
                className="flex items-center"
              >
                Add Question
              </Button>
              <FormFieldEditorModal
                visible={fieldEditorState.visibility}
                initialValue={fieldEditorState.field!}
                onCancel={() => setFieldEditorState({ visibility: false })}
                onSave={(newFieldValue) => {
                  if (fieldEditorState.mode === "ADD") {
                    newFieldValue.id = uuidv4();
                    newFieldValue.key = newFieldValue.id;
                    const lastFormGroup = _.last(formData);
                    if (lastFormGroup) {
                      setFormData([
                        ..._.without(formData, lastFormGroup),
                        {
                          ...lastFormGroup,
                          fields: [...lastFormGroup?.fields, newFieldValue],
                        },
                      ]);
                    }
                  } else if (fieldEditorState.mode === "EDIT") {
                    setFormData((groups) => {
                      return groups.map((group) => {
                        return {
                          ...group,
                          fields: group.fields.map((field) => {
                            if (field.id === newFieldValue.id) {
                              return newFieldValue;
                            }
                            return field;
                          }),
                        };
                      });
                    });
                  }
                  setFieldEditorState({ visibility: false });
                }}
                mode={fieldEditorState.mode}
              />
            </div>
          )}

          <div className="">
            <Button
              type="dashed"
              onClick={() => {
                setFieldGroupEditorState({
                  visibility: true,
                  mode: "ADD",
                  group: null,
                });
              }}
              icon={<PlusCircleFilled />}
              className="flex items-center"
            >
              Add Questions Block
            </Button>
            <FieldGroupEditorModal
              visible={fieldGroupEditorState.visibility}
              initialValue={fieldGroupEditorState.group!}
              onCancel={() => setFieldGroupEditorState({ visibility: false })}
              onSave={(newGroupValue) => {
                if (fieldGroupEditorState.mode === "ADD") {
                  newGroupValue.id = uuidv4();
                  setFormData([...formData, newGroupValue]);
                } else if (fieldGroupEditorState.mode === "EDIT") {
                  setFormData((groups) => {
                    return groups.map((group) => {
                      if (group.id === newGroupValue.id) {
                        return {
                          ...newGroupValue,
                        };
                      }
                      return group;
                    });
                  });
                }
                setFieldGroupEditorState({ visibility: false });
              }}
              mode={fieldGroupEditorState.mode}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
