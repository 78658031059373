import DownOutlined from "@ant-design/icons/DownOutlined";
import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iContact } from "@sdk/crm/crm.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Divider,
  Dropdown,
  Input,
  Menu,
  message,
  Popconfirm,
  Tooltip,
  Typography,
} from "antd";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import { EditableTextWithForm } from "components/common/editable-text-with-form/editable-text-with-form";
import { MobileNumberInput } from "components/common/mobile-number-input/mobile-number-input";
import { ModalTitle } from "components/common/modal-title";
import { NewMessage } from "components/modules/conversations/components/new-message/new-message";
import { UserPickerModal } from "components/modules/user-management/users/components/user-picker-modal/user-picker-modal";
import { UserPill } from "components/modules/user-management/users/components/user-pill/user-pill";
import { QuickContactMergeModal } from "components/pages/contacts/components/quick-contact-merge-modal/quick-contact-merge-modal";
import { SimilarContactsExplorer } from "components/pages/contacts/components/similar-contacts-explorer/similar-contacts-explorer";
import { VerifyContactModal } from "components/pages/contacts/components/verify-contact-modal/verify-contact-modal";

import { useUserRole } from "components/modules/user-management/helpers/use-user-role";
import { ContactProfile } from "modal-registry";
import { memo, useCallback, useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { loadAllContactLists } from "store/modules/contact-lists/contact-lists.helpers";
import { selectAllContactLists } from "store/modules/contact-lists/contact-lists.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useDoubleSelectorWithMemoize } from "store/utils/use-double-selector-with-memoize";
import { cleanMobileNumber } from "utils/clean-mobile-number";
import { useSimpleState } from "utils/hooks/use-simple-state";
import JSONModal from "utils/json-modal/json-modal";
import { toTitleCase } from "utils/to-title-case";
import { selectContactById } from "../../../../../../store/modules/contacts/contacts.selectors";
import { ContactTagSelector } from "../contact-tag-selector/contact-tag-selector";
import { CustomContactProperties } from "../custom-contact-properties/custom-contact-properties";
import "./edit-contact-mini.scss";
import { MiniContactMarketingListEditor } from "./mini-contact-marketing-list-editor/mini-contact-marketing-list-editor";

const { Paragraph } = Typography;

const socialMediaChannels = [
  {
    medium: "facebook",
    icon: <i className="ri-facebook-circle-fill"></i>,
  },
  {
    medium: "twitter",
    icon: <i className="ri-twitter-fill"></i>,
  },
  {
    medium: "linkedIn",
    icon: <i className="ri-linkedin-box-fill"></i>,
  },
  {
    medium: "instagram",
    icon: <i className="ri-instagram-fill"></i>,
  },
];

export const EditContactSection = memo(
  ({
    contactId,
    hideHeader,
    disableContactViewer,
    hiddenElements,
    onDeleteContact,
  }: {
    contactId: string;
    hideHeader?: boolean;
    disableContactViewer?: boolean;
    hiddenElements?: string[];
    onDeleteContact?: () => {};
  }) => {
    const isAdvancedMode = useSelector(selectIsAdvancedMode);

    const store = useStore();

    const { owner, isVerified, isUnsubscribed, primaryEmail, primaryMobile } =
      useDoubleSelectorWithMemoize(
        () => selectContactById(contactId),
        [contactId],
        (contact) => ({
          owner: contact?.owner,
          isVerified: contact?.metaData.isVerified,
          isUnsubscribed: contact?.preference?.isUnsubscribed,
          primaryEmail: contact?.data.primaryEmail,
          primaryMobile: contact?.data.primaryMobile,
        }),
      );

    const {
      doAction: editContact,
      isProcessing,
      dispatch,
    } = useSDKActionWithDeps(
      () => ({
        action: (SDK) => (edits: Partial<iContact>) =>
          SDK.editContact(contactId, edits),
        // successMessage: "Saved",
        failureMessage: "Something went wrong",
      }),
      [contactId],
    );

    const [formErrors, setFormErrors] = useSimpleState(
      {} as {
        email?: {
          duplicatePrimaryIdentifier: boolean;
          identifier: string;
        };
        mobile?: {
          duplicatePrimaryIdentifier: boolean;
          identifier: string;
        };
      },
    );

    useEffect(() => {
      setFormErrors({
        email: undefined,
        mobile: undefined,
      });
    }, [contactId, setFormErrors]);

    const [verificationModalVisible, setVerificationModalVisible] =
      useState(false);

    const [similarContactModalVisible, setSimilarContactModalVisible] =
      useState(false);

    const [quickMergeModal, setQuickMergeModal] = useSimpleState({
      isVisible: false,
      email: "",
      phone: "",
    });

    const [newMessageModalState, setNewMessageModalState] = useSimpleState({
      isVisible: false,
      medium: "EMAIL" as "EMAIL" | "MOBILE",
    });

    const onShowNewTextMessageModal = useCallback(
      () =>
        setNewMessageModalState({
          isVisible: true,
          medium: "MOBILE",
        }),
      [setNewMessageModalState],
    );

    const showQuickMergeModal = useCallback(
      () =>
        setQuickMergeModal({
          isVisible: true,
          email: formErrors?.email?.identifier,
        }),
      [formErrors?.email?.identifier, setQuickMergeModal],
    );

    const onShowNewEmailModal = useCallback(
      () =>
        setNewMessageModalState({
          isVisible: true,
          medium: "EMAIL",
        }),
      [setNewMessageModalState],
    );

    const [contactOwnerPickerModalState, setContactOwnerPickerModalState] =
      useState(false);

    const { doAction: unsubscribeContact } = useSDKActionWithDeps(
      () => ({
        action: (SDK) => () => SDK.unsubscribeContactFromAll(contactId),
        successMessage: "Contact has been unsubscribed",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [contactId],
    );

    const { changePanelState } = useModalPanels();

    const { state: allContactLists, retry: reload } = useQueryWithStore(
      selectAllContactLists,
      loadAllContactLists,
    );

    const {
      doAction: permanentlyRemoveContact,
      isProcessing: isRemovingContact,
    } = useSDKActionWithDeps(
      () => ({
        action: (SDK) => (confirmation: string) =>
          SDK.dangerousDeletion
            .deleteContact(contactId, confirmation)
            .then((d) => {
              message.info(
                "Contact Record has been removed. You may have to refresh the page to see it removed 😅",
              );
              onDeleteContact && onDeleteContact();
            }),
        failureMessage: "Something went wrong",
      }),
      [contactId, onDeleteContact],
    );

    const userRole = useUserRole();

    return (
      <div className="contact-mini-profile relative">
        {!hiddenElements?.includes("AVATAR") && (
          <>
            {!hideHeader && (
              <>
                {!disableContactViewer && (
                  <div
                    className="contact-header flex flex-row justify-center items-center mb-12 cursor-pointer"
                    onClick={() => {
                      changePanelState(ContactProfile, true, {
                        contactId: contactId!,
                      });
                    }}
                  >
                    <ContactAvatar contactId={contactId} size={80} />
                  </div>
                )}
                {disableContactViewer && (
                  <div className="contact-header flex flex-row justify-center items-center mb-12">
                    <ContactAvatar contactId={contactId} size={80} />
                  </div>
                )}
              </>
            )}
          </>
        )}

        {!hiddenElements?.includes("VERIFIED_CONTACT") && (
          <>
            {isVerified && (
              <div className="verified flex flex-row justify-center items-center mb-4">
                <i className="ri-shield-user-fill mr-2"></i> Verified Contact
              </div>
            )}
          </>
        )}

        {isUnsubscribed && (
          <div className="verified flex flex-row justify-center items-center mb-4 text-red-700 bg-red-200 dark:bg-red-900 dark:text-red-300">
            <i className="ri-close-line mr-2"></i> Unsubscribed
          </div>
        )}

        {!isVerified && (
          <div className="verified flex flex-row justify-center items-center mb-4">
            <Dropdown
              trigger={["click"]}
              overlay={
                <Menu data-click-context="Contact Verified Menu">
                  <Menu.Item
                    key="VERIFY_CONTACT"
                    icon={<i className="ri-shield-check-line"></i>}
                    onClick={() => setVerificationModalVisible(true)}
                  >
                    Verify Contact
                  </Menu.Item>
                  <Menu.Item
                    key="FIND_SIMILAR_CONTACT"
                    icon={<i className="ri-user-search-line"></i>}
                    onClick={() => setSimilarContactModalVisible(true)}
                  >
                    Find Similar Contact
                  </Menu.Item>
                </Menu>
              }
            >
              <Button className="font-bold" type="text">
                Not Verified <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        )}
        {owner && (
          <div className="flex flex-col justify-center items-center">
            <div className="text-xs">Contact Owner</div>
            <UserPill userId={owner} />
          </div>
        )}
        {!owner && (
          <div className="flex flex-row justify-center items-center">
            <Button
              type="text"
              onClick={() => setContactOwnerPickerModalState(true)}
              size="small"
            >
              Select Contact Owner
            </Button>
          </div>
        )}
        <div className="flex justify-center">
          {" "}
          <div>
            {isAdvancedMode && (
              <>
                <i
                  className="ri-code-box-line cursor-pointer"
                  onClick={() => {
                    const contact = selectContactById(contactId)(
                      store.getState(),
                    );
                    JSONModal(contact);
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className="data-table w-full p-4">
          <table className="simple-data-table w-full">
            <tbody>
              <tr>
                <td colSpan={2}>
                  <div
                    className="text-gray-700 dark:text-gray-200 mode_transition"
                    style={{ paddingLeft: 4 }}
                  >
                    First Name
                  </div>
                  <EditableTextWithForm
                    fieldPath={"data.firstName"}
                    entityType="CONTACT"
                    entityId={contactId}
                  >
                    <Input placeholder="..." className="font-semibold" />
                  </EditableTextWithForm>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div
                    className=" text-gray-700 dark:text-gray-200 mode_transition"
                    style={{ paddingLeft: 4 }}
                  >
                    Last Name
                  </div>
                  <EditableTextWithForm
                    fieldPath={"data.lastName"}
                    entityType="CONTACT"
                    entityId={contactId}
                  >
                    <Input
                      placeholder="Enter Last Name"
                      className="font-semibold"
                    />
                  </EditableTextWithForm>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className="-mb-1 flex flex-row justify-between items-center">
                    <div
                      className="text-gray-700 dark:text-gray-200 mode_transition"
                      style={{ paddingLeft: 4 }}
                    >
                      Email
                    </div>
                    {primaryEmail && (
                      <div className="action-icons">
                        <Tooltip title="Send Email">
                          <Button
                            type="link"
                            icon={<i className="ri-mail-line"></i>}
                            onClick={onShowNewEmailModal}
                          >
                            Send Email
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                  </div>

                  <EditableTextWithForm
                    fieldPath={"data.primaryEmail"}
                    entityType="CONTACT"
                    entityId={contactId}
                    rules={[
                      {
                        type: "email",
                        message: "Invalid Email",
                      },
                    ]}
                    onSaveFailed={(e, value) => {
                      setFormErrors({
                        email: {
                          duplicatePrimaryIdentifier: true,
                          identifier: value!,
                        },
                      });
                    }}
                    onClearError={() => {
                      setFormErrors({ email: undefined });
                    }}
                  >
                    <Input
                      placeholder="✉️ Click to add email..."
                      className="font-semibold"
                    />
                  </EditableTextWithForm>

                  {formErrors?.email?.duplicatePrimaryIdentifier && (
                    <div className="text-red-600 text-center">
                      Similar Contact Exists.{" "}
                      <Button
                        type="dashed"
                        icon={<i className="ri-user-search-line"></i>}
                        onClick={showQuickMergeModal}
                      >
                        Find and Merge Contact
                      </Button>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className=" flex flex-row justify-between items-center">
                    <div
                      className=" text-gray-700 dark:text-gray-200"
                      style={{ paddingLeft: 4 }}
                    >
                      Mobile
                    </div>
                    {primaryMobile && (
                      <div className="action-icons">
                        <Tooltip title="Send SMS">
                          <Button
                            type="link"
                            icon={<i className="ri-message-2-line"></i>}
                            onClick={onShowNewTextMessageModal}
                          >
                            Send Text
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                  </div>

                  <EditableTextWithForm
                    fieldPath={"data.primaryMobile"}
                    entityType="CONTACT"
                    entityId={contactId}
                    onSaveFailed={(e, value) => {
                      setFormErrors({
                        mobile: {
                          duplicatePrimaryIdentifier: true,
                          identifier: value!,
                        },
                      });
                    }}
                    onClearError={() => {
                      setFormErrors({ mobile: undefined });
                    }}
                    rules={[
                      ({ getFieldValue }) => ({
                        async validator(rule, value) {
                          if (value) {
                            const mobile = await cleanMobileNumber(value);
                            if (
                              !mobile.isValid ||
                              !mobile.isValidMobileNumber
                            ) {
                              return Promise.reject(
                                "Invalid Mobile Number. Please enter complete mobile number with country and area codes",
                              );
                            } else {
                              return Promise.resolve();
                            }
                          }
                          // Phone is not mandatory
                          // return Promise.reject("Invalid Phone Number");
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <MobileNumberInput className="font-semibold" />
                  </EditableTextWithForm>

                  {formErrors?.mobile?.duplicatePrimaryIdentifier && (
                    <div className="text-red-600 text-center">
                      Similar Contact Exists. (Not Saved)
                      <Button
                        type="dashed"
                        icon={<i className="ri-user-search-line"></i>}
                        onClick={() =>
                          setQuickMergeModal({
                            isVisible: true,
                            phone: formErrors?.mobile?.identifier,
                          })
                        }
                      >
                        Find and Merge Contact
                      </Button>
                    </div>
                  )}
                </td>
              </tr>
              {!hiddenElements?.includes("NOTES") && (
                <>
                  <tr>
                    <td colSpan={2}>
                      <div
                        className=" -mb-1 text-gray-700 dark:text-gray-200  mode_transition"
                        style={{ paddingLeft: 4 }}
                      >
                        Notes
                      </div>
                      <EditableTextWithForm
                        fieldPath={"data.notes"}
                        entityType="CONTACT"
                        entityId={contactId}
                      >
                        <Input.TextArea
                          placeholder="Click to add notes"
                          // rows={4}
                          autoSize={{ minRows: 4 }}
                        />
                      </EditableTextWithForm>
                    </td>
                  </tr>
                </>
              )}

              {!hiddenElements?.includes("CONTACT_TAGS") && (
                <>
                  <tr>
                    <td colSpan={2}>
                      <div
                        className=" text-gray-700 dark:text-gray-200  mode_transition"
                        style={{ paddingLeft: 4 }}
                      >
                        Contact Tags
                      </div>
                      <div className="tags-editor mb-4">
                        <EditableTextWithForm
                          fieldPath={"data.tags"}
                          entityType="CONTACT"
                          entityId={contactId}
                          saveOnChange
                        >
                          <ContactTagSelector />
                        </EditableTextWithForm>
                      </div>
                    </td>
                  </tr>
                </>
              )}
              {!hiddenElements?.includes("MARKETING_LIST") && (
                <>
                  {allContactLists.length > 0 && (
                    <tr>
                      <td colSpan={2}>
                        <div
                          className="text-gray-700 dark:text-gray-200  mode_transition"
                          style={{ paddingLeft: 4 }}
                        >
                          Marketing List
                        </div>
                        <MiniContactMarketingListEditor contactId={contactId} />
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>

          {/* Todo */}
          {!hiddenElements?.includes("SOCIAL_MEDIA_PROFILES") && (
            <>
              <div className="social-media-profiles my-8 w-full">
                <div className="text-gray-700 font-semibold dark:text-gray-200 mb-2">
                  Social Media Profiles
                </div>
                {socialMediaChannels.map((item) => (
                  <div className="flex flex-row w-full" key={item.medium}>
                    <div className="flex flex-col justify-center items-center"></div>
                    <div className="flex-1">
                      <EditableTextWithForm
                        fieldPath={`data.socialMediaProfiles.${item.medium}`}
                        entityType="CONTACT"
                        label={item.icon}
                        entityId={contactId}
                      >
                        <Input
                          placeholder={`Add ${toTitleCase(
                            item.medium,
                          )} profile`}
                        />
                      </EditableTextWithForm>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {/* Todo */}
          {!hiddenElements?.includes("CUSTOM_CONTACT_PROPERTIES") && (
            <>
              <CustomContactProperties contactId={contactId} />
            </>
          )}

          <Divider />

          {onDeleteContact && userRole === "Owner" && (
            <div className="flex flex-row justify-center items-center">
              <Popconfirm
                title="Are you sure?"
                description="All Assocaited data including conversation and messages will be deleted permanently. This cannot be undone."
                onConfirm={() => {
                  permanentlyRemoveContact("CONFIRM");
                }}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Delete Contact Permanently">
                  <Button
                    type="link"
                    icon={<i className="ri-delete-bin-line"></i>}
                  >
                    Delete Contact
                  </Button>
                </Tooltip>
              </Popconfirm>
            </div>
          )}

          {isUnsubscribed && (
            <div className="">
              <div className="font-bold">Actions</div>
              <div className="my-2">
                <Button
                  type="text"
                  icon={<i className="ri-close-line"></i>}
                  onClick={unsubscribeContact}
                  block
                  className="font-bold"
                >
                  Unsubscribe from Marketing
                </Button>
              </div>
            </div>
          )}
        </div>
        <VerifyContactModal
          visible={verificationModalVisible}
          contactId={contactId}
          onChangeVisibility={setVerificationModalVisible}
        />

        {true && (
          <SimilarContactsExplorer
            visible={similarContactModalVisible}
            contactId={contactId}
            onChangeVisibility={setSimilarContactModalVisible}
          />
        )}

        <QuickContactMergeModal
          visible={quickMergeModal.isVisible}
          contactId={contactId}
          email={quickMergeModal.email}
          phone={quickMergeModal.phone}
          onChangeVisibility={(visibility) =>
            setQuickMergeModal({ isVisible: visibility })
          }
          onMerged={() => {
            changePanelState(ContactProfile, false, { contactId: "" });
          }}
        />

        <NewMessage
          visible={newMessageModalState.isVisible}
          onChangeVisibility={(visibility) =>
            setNewMessageModalState({ isVisible: visibility })
          }
          // onSent={() => {}}
          contactId={contactId}
          mediumType={newMessageModalState.medium}
        />

        <UserPickerModal
          visible={contactOwnerPickerModalState}
          onChangeVisibility={setContactOwnerPickerModalState}
          onUserSelected={async (userId) => {
            console.log("user", userId);
            const hide = message.loading("Processing", 0);
            try {
              await editContact({
                owner: userId,
              });
              setContactOwnerPickerModalState(false);
              hide();
            } catch (e) {
              hide();
            }
          }}
          title={
            <ModalTitle
              title="Pick a user to assign"
              icon={<i className="ri-account-circle-line"></i>}
            />
          }
        />
      </div>
    );
  },
);
