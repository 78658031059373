import { iReportQuery } from "@sdk/reports/reports-controller-models";
import { Select } from "antd";
import { SimpleReportStatCard } from "components/pages/home/your-achievements";
import { iReportWidget } from "components/pages/reports/components/report-canvas/widget-canvas-models";
import { DraggableReportWidget } from "components/pages/reports/components/report-widget/report-widget";
import dayjs from "dayjs";
import { groupBy } from "lodash";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { selectConversationTags } from "store/modules/workspace/workspace.selectors";

const periodOptions = [
  {
    value: JSON.stringify([
      dayjs()
        .startOf("day")
        .valueOf(),
      dayjs()
        .endOf("day")
        .valueOf()
    ]),
    label: "Today"
  },
  {
    value: JSON.stringify([
      dayjs()
        .subtract(1, "day")
        .startOf("day")
        .valueOf(),
      dayjs()
        .subtract(1, "day")
        .endOf("day")
        .valueOf()
    ]),
    label: "Yesterday"
  },
  {
    value: JSON.stringify([
      dayjs()
        .startOf("week")
        .valueOf(),
      dayjs()
        .endOf("week")
        .valueOf()
    ]),
    label: "This Week"
  },
  {
    value: JSON.stringify([
      dayjs()
        .subtract(6, "day")
        .startOf("day")
        .valueOf(),
      dayjs()
        .endOf("day")
        .valueOf()
    ]),
    label: "Last 7 Days"
  },
  {
    value: JSON.stringify([
      dayjs()
        .subtract(1, "week")
        .startOf("week")
        .valueOf(),
      dayjs()
        .subtract(1, "week")
        .endOf("week")
        .valueOf()
    ]),
    label: "Last Week"
  },
  {
    value: JSON.stringify([
      dayjs()
        .startOf("month")
        .valueOf(),
      dayjs()
        .endOf("month")
        .valueOf()
    ]),
    label: "This Month"
  },
  {
    value: JSON.stringify([
      dayjs()
        .subtract(1, "month")
        .startOf("month")
        .valueOf(),
      dayjs()
        .subtract(1, "month")
        .endOf("month")
        .valueOf()
    ]),
    label: "Last Month"
  }
];

export const MyPerformanceReport = () => {
  const currentUserId = useSelector(selectCurrentUserId);

  const [selectedPeriodOption, setSelectedPeriodOption] = useState(
    periodOptions[3].value
  );

  const conversationTags = useSelector(selectConversationTags);
  const tagGroups = useMemo(() => groupBy(conversationTags, "tagGroup"), [
    conversationTags
  ]);
  const {
    chatsOverPeriod,
    chatByTags,
    totalChatsQuery,
    averageResponseTimeQuery,
    closedConversationsQuery,
    followupsDoneQuery,
    messagesSentQuery
  } = useMemo(() => {
    // All these should be compared against last period
    const chatsOverPeriod: iReportWidget = {
      id: "CHATS_BY_DAY",
      type: "CONVERSATION",
      title: "Conversations by Day",
      description: "Total conversations count in the selected period by day",
      reportType: "CONVERSATION",
      // Query
      filters: [
        {
          key: "period",
          operator: "STARTED_IS_BETWEEN",
          operatorConfig: {
            value: JSON.parse(selectedPeriodOption)
          }
        },
        {
          key: "users",
          operator: "IS_ONE_OF",
          operatorConfig: {
            value: currentUserId
          }
        }
      ],
      groupBy: [],
      aggregateByPeriod: "DAY",
      // Other View Data
      reportViewConfig: {
        defaultVisualization: "DIGIT"
      }
    };
    console.log("tagGroups", tagGroups);

    const tagGroup = tagGroups["General Tags"] ? "General Tags" : "";

    const chatByTags: iReportWidget = {
      id: "CONVERSATIONS_BY_TAGS",
      type: "CONVERSATION",
      title: `Conversations by Tags`,
      description: `Total conversations count broken down by Tags`,
      reportType: "CONVERSATION",
      // Query
      filters: [
        {
          key: "period",
          operator: "STARTED_IS_BETWEEN",
          operatorConfig: {
            value: JSON.parse(selectedPeriodOption)
          }
        },
        {
          key: "users",
          operator: "IS_ONE_OF",
          operatorConfig: {
            value: currentUserId
          }
        }
      ],
      groupBy: ["tags"],
      // Other View Data
      reportViewConfig: {
        defaultVisualization: "DIGIT",
        tagType: tagGroup
      }
    };

    const [startDate, endDate] = JSON.parse(selectedPeriodOption);

    const totalChatsQuery: iReportQuery = {
      startDate,
      endDate,
      filters: {
        users: currentUserId
      }
    };

    const averageResponseTimeQuery: iReportQuery = {
      startDate,
      endDate,
      filters: {
        users: currentUserId
      },
      aggregateFunction: "AVERAGE",
      aggregateTargetField: "responseTime"
    };

    const closedConversationsQuery: iReportQuery = {
      startDate,
      endDate,
      filters: {
        users: currentUserId
      }
    };

    const followupsDoneQuery: iReportQuery = {
      startDate,
      endDate,
      filters: {
        users: currentUserId
      }
    };

    const messagesSentQuery: iReportQuery = {
      startDate,
      endDate,
      filters: {
        users: currentUserId
      }
    };

    return {
      chatsOverPeriod,
      chatByTags,
      totalChatsQuery,
      averageResponseTimeQuery,
      closedConversationsQuery,
      followupsDoneQuery,
      messagesSentQuery
    };
  }, [currentUserId, selectedPeriodOption, tagGroups]);

  return (
    <>
      <div className="header flex flex-row justify-center items-center border rounded-lg border-gray-200 dark:border-gray-700 p-4 mb-4">
        <div className="pr-4">Select Report Period:</div>
        <Select
          options={periodOptions}
          value={selectedPeriodOption}
          onChange={setSelectedPeriodOption}
          className="flex-1"
          bordered={false}
        />
      </div>
      <div className="flex flex-row items-center">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <SimpleReportStatCard
            title="Conversations"
            icon={<i className="ri-message-2-line"></i>}
            reportType="CONVERSATION"
            query={totalChatsQuery}
          />

          <SimpleReportStatCard
            title="First Response Time"
            icon={<i className="ri-flashlight-fill"></i>}
            reportType="CONVERSATION"
            query={averageResponseTimeQuery}
            label={"seconds"}
          />

          <SimpleReportStatCard
            title="Closed Conversations"
            icon={<i className="ri-chat-check-line"></i>}
            reportType="ANALYTICS"
            query={closedConversationsQuery}
          />

          <SimpleReportStatCard
            title="Followups"
            icon={<i className="ri-chat-follow-up-line"></i>}
            reportType="ANALYTICS"
            query={followupsDoneQuery}
          />

          <SimpleReportStatCard
            title="Messages"
            icon={<i className="ri-chat-1-line"></i>}
            reportType="MESSAGES"
            query={messagesSentQuery}
          />
        </div>
      </div>
      <div className="Stats my-12">
        <DraggableReportWidget
          data={chatsOverPeriod}
          isEditMode={false}
          style={{ height: "33rem" }}
        />

        <DraggableReportWidget
          data={chatByTags}
          isEditMode={false}
          style={{ height: "33rem", marginTop: "2rem" }}
        />
      </div>
    </>
  );
};
