import { iReportWidget } from "../components/report-canvas/widget-canvas-models";

export const ReportWidgetPresets: iReportWidget[] = [
  // Total Conversations in this month
  {
    id: "TOTAL_CONVERSATIONS",
    type: "CONVERSATION",
    title: "Total Conversations",
    description: "Total conversations count in the selected period",
    reportType: "CONVERSATION",
    // Query

    filters: [],
    groupBy: [],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "DIGIT"
    }
  },
  // Conversations by Tags
  {
    id: "CONVERSATIONS_BY_TAGS",
    type: "CONVERSATION",
    title: "Conversations by Tags",
    description: "Conversations by Tags in the selected period",
    reportType: "CONVERSATION",
    // Query

    filters: [],
    groupBy: ["tags"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  // Conversations over period
  {
    id: "CONVERSATIONS_BY_PERIOD",
    type: "CONVERSATION",
    title: "Conversations by day",
    description: "Conversations  in the selected period",
    reportType: "CONVERSATION",
    // Query
    aggregateByPeriod: "DAY",
    filters: [],
    groupBy: ["status"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  // By Status
  {
    id: "CONVERSATIONS_BY_STATUS",
    type: "CONVERSATION",
    title: "Conversations by Status",
    description: "Total Conversations in the selected period by status",
    reportType: "CONVERSATION",
    // Query

    filters: [],
    groupBy: ["status"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  // Todo: Tag Groups
  {
    id: "CONVERSATIONS_BY_TAGS_GROUP",
    type: "CONVERSATION",
    title: "By Tags",
    description: "Total Conversations in the selected period by tags",
    reportType: "CONVERSATION",
    // Query

    filters: [],
    groupBy: ["tags"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  // Todo: By Teams
  // {
  //   id: "CONVERSATIONS_BY_TEAMS",
  //   type: "CONVERSATION",
  //   title: "Conversations by Team",
  //   description: "Total Conversations in the selected period by tags",
  //   reportType: "CONVERSATION",
  //   // Query

  //   filters: [],
  //   groupBy: ["users"],
  //   // Other View Data
  //   reportViewConfig: {
  //     defaultVisualization: "PIE"
  //   }
  // },
  {
    id: "CONVERSATIONS_BY_USERS_GROUPS",
    type: "CONVERSATION",
    title: "Conversations by User Group",
    description: "Total Conversations in the selected period",
    reportType: "CONVERSATION",
    // Query
    filters: [],
    groupBy: ["users"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE",
      groupByUserGroup: true
    }
  },
  // Heatmap
  {
    id: "CONVERSATIONS_HEAT_MAP",
    type: "CONVERSATION",
    title: "Conversation Flow Heat Map",
    description: "Total Conversations in the selected period by tags",
    reportType: "CONVERSATION",
    // Query
    aggregateByPeriod: "HEAT_MAP",
    filters: [],
    groupBy: [],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  // By users
  {
    id: "CONVERSATIONS_BY_USERS",
    type: "CONVERSATION",
    title: "Conversations By Users",
    description: "Total Conversations in the selected period",
    reportType: "CONVERSATION",
    // Query
    filters: [],
    groupBy: ["users"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  // By users, By Tags
  {
    id: "CONVERSATIONS_BY_USERS_TAGS",
    type: "CONVERSATION",
    title: "Conversations by Users and Tags",
    description: "Total Conversations in the selected period",
    reportType: "CONVERSATION",
    // Query
    filters: [],
    groupBy: ["users", "tags"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  {
    id: "CONVERSATIONS_BY_CONNECTIONS",
    type: "CONVERSATION",
    title: "Conversations by Connection",
    description: "Total conversations in the selected period by connections",
    reportType: "CONVERSATION",
    // Query
    filters: [],
    groupBy: ["connections"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  {
    id: "CONVERSATIONS_BY_MESSAGE_COUNT",
    type: "CONVERSATION",
    title: "By message count",
    description: "Total conversations in the selected period by message count",
    reportType: "CONVERSATION",
    // Query
    filters: [],
    groupBy: ["messageCount"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  {
    id: "CONVERSATIONS_BY_CHAT_RATING",
    type: "CONVERSATION",
    title: "Conversations by Chat Ratings",
    description: "Conversation in the selected period by ratings",
    reportType: "CONVERSATION",
    // Query
    filters: [],
    groupBy: ["chatRating"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  {
    id: "CONVERSATIONS_BY_DURATION",
    type: "CONVERSATION",
    title: "Conversations by durations",
    description: "Total conversations in the selected period by message count",
    reportType: "CONVERSATION",
    // Query
    filters: [],
    groupBy: ["categorizedDuration"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  {
    id: "CONVERSATIONS_BY_RESPONSE_TIME",
    type: "CONVERSATION",
    title: "Conversations by First Response Speed",
    description: "First Response Speed in the selected period by status",
    reportType: "CONVERSATION",
    // Query
    filters: [],
    groupBy: ["categorizedResponseTime"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  {
    id: "CONVERSATIONS_BY_RESPONSE_TIME_BY_PERIOD",
    type: "CONVERSATION",
    title: "Average Response Times of Conversations",
    description: "Average Response Times for selected period",
    reportType: "CONVERSATION",
    // Query
    aggregateByPeriod: "DAY",
    aggregateFunction: "AVERAGE",
    aggregateTargetField: "responseTime",
    filters: [],
    groupBy: [],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "BAR_CHART"
    }
  },
  {
    id: "CONVERSATIONS_BY_SOURCE",
    type: "CONVERSATION",
    title: "Conversations by Source",
    description: "Total conversations in the selected period by source",
    reportType: "CONVERSATION",
    // Query
    filters: [],
    groupBy: ["source"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  {
    id: "CONVERSATIONS_BY_FACEBOOK_AD",
    type: "CONVERSATION",
    title: "Conversations by Facebook Ad",
    description: "Total conversations in the selected period by Facebook ads",
    reportType: "CONVERSATION",
    // Query
    filters: [],
    groupBy: ["fbAdId"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  {
    id: "CONVERSATIONS_BY_FACEBOOK_POST",
    type: "CONVERSATION",
    title: "Conversations by Facebook Post",
    description: "Total conversations in the selected period by Facebook post",
    reportType: "CONVERSATION",
    // Query
    filters: [],
    groupBy: ["fbPostId"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  {
    id: "CONVERSATIONS_BY_DURATION_BY_PERIOD",
    type: "CONVERSATION",
    title: "Average Durations of Conversations",
    description: "Average durations for selected period",
    reportType: "CONVERSATION",
    // Query
    filters: [],
    groupBy: ["categorizedDuration"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  {
    id: "USER_PERFORMANCE_REPORT",
    type: "USER_PERFORMANCE_REPORT",
    title: "User Performance Report - Beta",
    description: "User Performance Report",
    reportType: "USER_PERFORMANCE_REPORT",
    // Query
    filters: [],
    groupBy: [],
    // Other View Data
    reportViewConfig: {}
  },
  {
    id: "CONVERSATIONS_TAGS_BY_TAGS_WIDGET",
    type: "CONVERSATION",
    title: "Conversation Tag Relation Explorer",
    description: "Total Conversations in the selected period",
    reportType: "CONVERSATION",
    // Query
    filters: [],
    groupBy: ["tagsCombination"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIVOT_TABLE"
    }
  }
];

export const ContactsReportWidgetPresets: iReportWidget[] = [
  // TOTAL_CONTACTS
  {
    id: "TOTAL_CONTACTS",
    type: "LEADS",
    title: "Total Leads",
    description: "Total Lead count in the selected period",
    reportType: "LEADS",
    // Query
    filters: [],
    groupBy: [],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "DIGIT"
    }
  },
  // CONTACTS_BY_TAGS
  {
    id: "CONTACTS_BY_TAGS",
    type: "LEADS",
    title: "Leads by Tags",
    description: "Leads by Tags in the selected period",
    reportType: "LEADS",
    // Query
    filters: [],
    groupBy: ["tags"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  // LEADS_BY_PERIOD
  {
    id: "LEADS_BY_PERIOD",
    type: "LEADS",
    title: "Leads by day",
    description: "Leads  in the selected period",
    reportType: "LEADS",
    // Query
    aggregateByPeriod: "DAY",
    filters: [],
    groupBy: [],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "BAR_CHART"
    }
  },
  // CONTACTS_BY_CHANNELS,
  {
    id: "CONTACTS_BY_CHANNELS",
    type: "LEADS",
    title: "Leads by Channels",
    description: "Leads by Channels in the selected period",
    reportType: "LEADS",
    // Query
    filters: [],
    groupBy: ["connections"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  // CONTACTS_BY_COUNTRY
  {
    id: "CONTACTS_BY_COUNTRY",
    type: "LEADS",
    title: "Leads by Country",
    description: "Leads by Country in the selected period",
    reportType: "LEADS",
    // Query
    filters: [],
    groupBy: ["country"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  // CONTACTS_BY_MARKETING_PREFERENCE
  {
    id: "CONTACTS_BY_MARKETING_PREFERENCE",
    type: "LEADS",
    title: "Leads by Marketing Preference",
    description: "Leads by Marketing Preference in the selected period",
    reportType: "LEADS",
    // Query
    filters: [],
    groupBy: ["marketingConsentObtained"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  // CONTACTS_BY_MOBILE_AVAILABILITY
  {
    id: "CONTACTS_BY_MOBILE_AVAILABILITY",
    type: "LEADS",
    title: "Leads by Mobile Availability",
    description: "Leads by Mobile Availability in the selected period",
    reportType: "LEADS",
    // Query
    filters: [],
    groupBy: ["mobileExists"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  // CONTACTS_BY_EMAIL_AVAILABILITY
  {
    id: "CONTACTS_BY_EMAIL_AVAILABILITY",
    type: "LEADS",
    title: "Leads by Email Availability",
    description: "Leads by Email Availability in the selected period",
    reportType: "LEADS",
    // Query
    filters: [],
    groupBy: ["emailExists"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  }
];
export const ActivitiesReportWidgetPresets: iReportWidget[] = [
  // TOTAL_ACTIVITIES
  {
    id: "TOTAL_ACTIVITIES",
    type: "ACTIVITIES",
    title: "Total Activities",
    description: "Total Activities count in the selected period",
    reportType: "ACTIVITIES",
    // Query
    filters: [],
    groupBy: [],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "DIGIT"
    }
  },
  // CONTACTS_BY_TYPE
  {
    id: "CONTACTS_BY_TYPE",
    type: "ACTIVITIES",
    title: "Activities by Type",
    description: "Activities by Type in the selected period",
    reportType: "ACTIVITIES",
    // Query
    filters: [],
    groupBy: ["activityType"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  },
  // CONTACTS_BY_ACCOUNT_OWNER
  {
    id: "CONTACTS_BY_ACCOUNT_OWNER",
    type: "ACTIVITIES",
    title: "Activities by Account Owner",
    description: "Activities by Account Owner in the selected period",
    reportType: "ACTIVITIES",
    // Query
    filters: [],
    groupBy: ["owner"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  }
];
export const CompaniesReportWidgetPresets: iReportWidget[] = [
  // TOTAL_COMPANIES
  {
    id: "TOTAL_COMPANIES",
    type: "COMPANIES",
    title: "Total Companies",
    description: "Total Company count in the selected period",
    reportType: "COMPANIES",
    // Query
    filters: [],
    groupBy: [],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "DIGIT"
    }
  }
];
export const OpportunitiesReportWidgetPresets: iReportWidget[] = [
  // TOTAL_OPPORTUNITIES
  // OPPORTUNITIES_BY_PIPELINE_STAGE
  // TODO: TOTAL_VALUE
  // TOTAL_OPPORTUNITIES
  {
    id: "TOTAL_OPPORTUNITIES",
    type: "OPPORTUNITIES",
    title: "Total Opportunities",
    description: "Total Opportunities count in the selected period",
    reportType: "LEADS",
    // Query
    filters: [],
    groupBy: [],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "DIGIT"
    }
  },
  // OPPORTUNITIES_BY_PIPELINE_STAGE
  {
    id: "OPPORTUNITIES_BY_PIPELINE_STAGE",
    type: "OPPORTUNITIES",
    title: "Opportunities by Pipeline Stage",
    description: "Opportunities by Pipeline Stage in the selected period",
    reportType: "LEADS",
    // Query
    filters: [],
    groupBy: ["tags"],
    // Other View Data
    reportViewConfig: {
      defaultVisualization: "PIE"
    }
  }
];
