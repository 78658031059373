import {
  iCampaign,
  TriggerConditionPresets
} from "@sdk/chat-widgets/chat-widgets.models";

export const ChatCampaignPresets_Old = [
  {
    id: TriggerConditionPresets.FIVE_SECONDS_AFTER_VISIT,
    label: "Customer lands on any Page"
  },
  {
    id: TriggerConditionPresets.NONE,
    label: "Customer lands on an ad/newsletter campaign"
  },
  {
    id: TriggerConditionPresets.ON_NAVIGATION_TO_SECOND_PAGE,
    label: "Customer visits second page"
  }
];

export type iChatCampaignPresetTypes =
  | "Customer lands on any Page"
  | "Customer lands on a specific page"
  | "Customer lands on a campaign"
  | "Unknown Preset";

export const ChatCampaignPresets: {
  label: iChatCampaignPresetTypes;
}[] = [
  {
    label: "Customer lands on any Page"
  },
  {
    label: "Customer lands on a specific page"
  },
  {
    label: "Customer lands on a campaign"
  }
];

export const GetCampaignPresetType = (
  campaign: iCampaign
): iChatCampaignPresetTypes => {
  if (campaign.data.triggerCondition.preset === TriggerConditionPresets.NONE) {
    if ((campaign?.data?.triggerCondition?.data?.forUtmTags?.length || 0) > 0) {
      return "Customer lands on a campaign";
    } else if (
      (campaign.data.triggerCondition.data?.pageContains?.length || 0) > 0
    ) {
      return "Customer lands on a specific page";
    } else {
      return "Customer lands on any Page";
    }
  } else {
    return "Unknown Preset";
  }
};
