import { useSDK } from "@sdk";

export const EmailCampaignStatCard = ({
  title,
  query,
  campaignId,
  onClick,
}: {
  title: string;
  query: any;
  campaignId: string;
  onClick: () => any;
}) => {
  const { data } = useSDK(
    (SDK) => SDK.campaigns.queryReport(campaignId, { query: query }),
    [query, campaignId],
  );

  return (
    <div
      className="p-8 border border-gray-200 rounded-lg w-full dark:border-gray-800 flex-1 bg-white dark:bg-black hover:bg-gray-200 cursor-pointer"
      // style={{ width: 500 }}
      onClick={onClick}
    >
      <div className="header flex flex-row justify-between items-center mb-4">
        <div className="font-bold text-2xl mr-4">{title}</div>
      </div>
      <div className="body text-2xl">{data?.count || 0}</div>
    </div>
  );
};
