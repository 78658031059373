import axios, { AxiosResponse } from "axios";

export const getFBPages = async (
  accessToken: string
): Promise<FacebookAccountRecord[]> => {
  const baseUrl = "https://graph.facebook.com/v7.0";
  let accounts: FacebookAccountRecord[] = [];
  const uri = `${baseUrl}/me/accounts?access_token=${accessToken}`;

  const loadPages = async (url: string) => {
    const results: AxiosResponse<FacebookGetAccountResponse> = await axios.get(
      url
    );
    const data = results.data;
    if (!data) {
      throw new Error("Something went wrong");
    } else {
      accounts = accounts.concat(data.data);
      if (data && data.paging && data.paging.next) {
        await loadPages(data.paging.next);
      }
    }
  };

  await loadPages(uri);
  return accounts;
};

interface FacebookGetAccountResponse {
  data: FacebookAccountRecord[];
  paging: FacebookResponsePaging;
}

interface FacebookResponsePaging {
  cursors: FacebookResponseCursors;
  next?: string;
}

interface FacebookResponseCursors {
  before: string;
  after: string;
}

interface FacebookAccountRecord {
  access_token: string;
  category: string;
  category_list: FacebookCategoryList[];
  name: string;
  id: string;
  tasks: string[];
  instagram_business_account?: {
    id: string;
  };
}

interface FacebookCategoryList {
  id: string;
  name: string;
}
