import { SDK } from "@sdk";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import { iNewConversationRequest } from "@sdk/conversations/conversations.controller-models";
import {
  iConnection,
  iConversation,
} from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iSyncEvent } from "@socket-engine/handle-socket-events";
import { Alert, Input, message as Message, Modal, Spin, message } from "antd";
import { RcFile } from "antd/lib/upload";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import {
  ReplyComponent,
  ValidationMessageType,
} from "components/common/reply-input/reply-input";
import { iSlashCommand } from "components/common/reply-input/slash-command";
import { SimpleCollapse } from "components/common/simple-collapse/simple-collapse";
import { userHasConnectionGroupPermission } from "components/modules/connections/helpers/user-has-connection-permission";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import { MessageScheduler } from "components/pages/conversations/components/message-scheduler/message-scheduler";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import {
  selectAllUsers,
  selectCurrentUser,
  selectCurrentUserId,
  selectCurrentUserPresetsX,
} from "store/modules/users/users.selectors";
import { selectOrganizationWideMessagePresetsX } from "store/modules/workspace/workspace.selectors";
import { useLocalStorageStore } from "utils/hooks/use-local-storage-store";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { justWait } from "utils/just-wait";
import { MultiContactSelector } from "../../../crm/contacts/components/multi-contact-selector-v2/multi-contact-selector";
import { GenSMSMetaData } from "../../helpers/gen-sms-message-meta-data";
import { ConnectionSelector } from "../connection-selector/connection-selector";
import { WATemplateMessage } from "./components/wa-template-message";
import "./new-message.scss";

export const NewMessage = ({
  visible,
  onChangeVisibility,
  contactId,
  connectionId,
  mediumType,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  contactId?: string;
  connectionId?: string;
  mediumType?: "EMAIL" | "MOBILE";
}) => {
  const store = useStore();

  const currentUserId = useSelector(selectCurrentUserId);

  const [selectedContacts, setSelectedContacts] = useState<any[]>([]);
  const [lastSentMessage, setLastSentMessage] = useState({
    contactIds: [] as string[],
    timestamp: 0,
  });

  const [selectedConnectionId, setSelectedConnection] = useLocalStorageStore(
    "last-selected-connection",
    connectionId || (undefined as string | undefined),
  );

  const selectedConnection = useSelector(
    selectConnectionById(selectedConnectionId!),
  );

  const [subjectLine, setSubjectLine] = useState("");

  const onSent = () => {};

  const [additionalTo, setAdditionalTo] = useState<any[]>([]);
  const [additionalCC, setAdditionalCC] = useState<any[]>([]);

  const clearFieldsAndCloseModal = useCallback(() => {
    setSelectedContacts([]);
    setAdditionalCC([]);
    setAdditionalTo([]);
    setSubjectLine("");
    onChangeVisibility(false);
  }, [onChangeVisibility]);

  const { doAction: onSendMessage, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (data: iNewConversationRequest) =>
        // SDK.sendMessage(data).then((res) => {
        //   onChangeVisibility(false);
        //   if (Array.isArray(res)) {
        //     const conversations = res.map((message) => message.conversationId);
        //     const conversationsToLoad = conversations.filter(
        //       (conversationId) =>
        //         selectMessageByConversationId(conversationId)(store.getState())
        //           .fetchedPages === 0
        //     );
        //     for (const message of res) {
        //       dispatch(addNewMessage(message));
        //     }
        //     return Promise.all(
        //       conversationsToLoad.map((conversationId) =>
        //         loadMessages(conversationId)(store)
        //       )
        //     ) as Promise<any>;
        //   } else {
        //     if (
        //       selectMessageByConversationId(res.conversationId)(store.getState())
        //         .fetchedPages === 0
        //     ) {
        //       return loadMessages(res.conversationId)(store);
        //     }
        //     dispatch(addNewMessage(res));
        //   }
        // }),
        SDK.startNewConversationWithMessage(data)
          .then((d) => {
            clearFieldsAndCloseModal();
          })
          .catch((e) => {
            console.log("Error while starting a new conversation", e);
            throw e;
          }),
      successMessage: "Message has been sent",
      throwError: true,
      failureMessage: "Something went wrong",
    }),
    [clearFieldsAndCloseModal],
  );

  // Patch for Whatsapp Conversation - To automatically assign contact - START
  // This could be in the backend too. on New Message, save a global local variable, and do the process
  useEffect(() => {
    if (
      lastSentMessage.contactIds &&
      lastSentMessage.contactIds.length > 0 &&
      SocketConnector.socket
    ) {
      const listener = (data: iSyncEvent) => {
        if (data.type === "NEW" && data.entityType === "CONVERSATION") {
          const newConversation = data.data as iConversation;
          if (lastSentMessage.contactIds.includes(newConversation.contactId)) {
            // Conversation needs to be fetched before assigning, otherwise when fetched conversation without members will be coming to front end
            justWait(1000).then((d) => {
              return SDK.addMembersToConversation(data.data.id, [currentUserId])
                .then((d) => {
                  // Todo: Maybe navigate to the conversation
                })
                .catch((e) => {
                  console.log("Error while adding user to conversation");
                });
            });
          }
        }
      };
      SocketConnector.socket.on("SYNC", listener);
      const timer = setTimeout(() => {
        SocketConnector.socket.off("SYNC", listener);
      }, 10000);
      return () => {
        SocketConnector.socket.off("SYNC", listener);
        clearTimeout(timer);
      };
    }
  }, [currentUserId, lastSentMessage]);
  // Patch for Whatsapp Conversation - To automatically assign contact - END

  const messagePresets_currentUser = useSelector(selectCurrentUserPresetsX);
  const messagePresets_organization = useSelector(
    selectOrganizationWideMessagePresetsX,
  );

  const slashCommands: iSlashCommand[] = useMemo(() => {
    const folders = [
      ...(messagePresets_currentUser.folders || []),
      ...(messagePresets_organization.folders || []),
    ];
    return [
      ...(messagePresets_currentUser.presets || []),
      ...(messagePresets_organization.presets || []),
    ]
      .filter((e) => e)
      .map((e) => ({
        id: `MESSAGE_PRESET_${e.id}`,
        type: "MESSAGE_PRESET",
        label: e.label,
        description: e.value,
        metaData: {
          id: e.id,
          value: e.value,
          isRichText: e.type === "RICH_TEXT",
          folder: e.folder,
          folderLabel: _.find(folders, { id: e.folder })?.label,
          attachments: e.attachments,
        },
      }));
  }, [messagePresets_currentUser, messagePresets_organization]);

  const allUsers = useSelector(selectAllUsers);
  const userList = useMemo(
    () =>
      allUsers.map((user) => ({
        id: user.id,
        display: getUserName(user),
      })),
    [allUsers],
  );
  const currentUser = useSelector(selectCurrentUser);

  const mobileNumbers: any = useMemo(() => [], []);

  const {
    messageValidator,
    messageCharLimitCalculator,
    enableEmojis,
    enableNotes,
    enableAttachments,
    fromIds,
    defaultSelectedFromId,
    disableSenderSelection,
    maximumContactLimit,
    maximumContactLimitAlertText,
    mediumOfSend,
    enableRichText,
  } = useMemo(() => {
    const connectionType = selectedConnection?.type;
    switch (connectionType) {
      case "SMS":
      case "TELNYX": {
        // Message Validator
        const messageValidator = (message: string) => {
          const messageMetaData = GenSMSMetaData(message);
          const validationMessages: {
            message: string;
            type: ValidationMessageType;
          }[] = [];
          if (messageMetaData.hasExceededMaximumMessageSize) {
            validationMessages.push({
              message: `Message length: You have exceeded a single message length. Message will be sent as multi-part messages(each part could have up to ${messageMetaData.messagePartLength} characters) and hence the above message will be sent as ${messageMetaData.numberOfSMS} parts and it will cost x${messageMetaData.numberOfSMS} for each delivery.`,
              type: "warning",
            });
          }

          if (messageMetaData.hasUnicode) {
            validationMessages.push({
              message: `Special Characters: Your message consists of unicode characters(Special character. A message with special characters can be only of 70`,
              type: "warning",
            });
          }

          return validationMessages;
        };
        // Message Char Limit Calculater
        const messageCharLimitCalculator = (message: string) => {
          const messageMetaData = GenSMSMetaData(message);
          return {
            current: messageMetaData.messageLength,
            limit: messageMetaData.maximumMessageSize,
          };
        };

        // From Ids
        const fromIds = (mobileNumbers || []).map((mobileNumber) => ({
          id: mobileNumber.mobileNumber,
          label: `${mobileNumber.label} - ${mobileNumber.mobileNumber}`,
        }));
        const defaultSelectedFromId = (mobileNumbers || 0)[0]?.mobileNumber;
        return {
          messageValidator,
          messageCharLimitCalculator,
          enableEmojis: false,
          enableNotes: false,
          enableAttachments: false,
          fromIds,
          defaultSelectedFromId,
          disableSenderSelection: true,
          maximumContactLimit: 5,
          maximumContactLimitAlertText:
            "Your account is limited to have only a maximum of 5 recipients",
          mediumOfSend: "MOBILE",
          enableRichText: false,
        };
      }
      case "WHATS_APP_OFFICIAL":
      case "WHATS_APP_CLOUD":
      case "WHATS_APP_CLOUD_NATIVE":
      case "WHATS_APP_PRIVATE": {
        const messageValidator = (message: string) => {
          const validationMessages: {
            message: string;
            type: ValidationMessageType;
          }[] = [];
          return validationMessages;
        };
        const messageCharLimitCalculator = (message: string) => {
          return {
            current: message.length,
            limit: 0,
          };
        };
        const fromIds = [];
        const defaultSelectedFromId = "";
        return {
          messageValidator,
          messageCharLimitCalculator,
          enableEmojis: true,
          enableNotes: false,
          enableAttachments: true,
          fromIds,
          defaultSelectedFromId,
          disableSenderSelection: true,
          maximumContactLimit: 10,
          maximumContactLimitAlertText:
            "Whatsapp messages be delivered upto only 10 contacts",
          mediumOfSend: "MOBILE",
          enableRichText: false,
        };
      }
      case "EMAIL":
      case "FACEBOOK":
      case "WEB_CHAT":
      default: {
        const messageValidator = (message: string) => {
          const validationMessages: {
            message: string;
            type: ValidationMessageType;
          }[] = [];
          return validationMessages;
        };
        const messageCharLimitCalculator = (message: string) => {
          return {
            current: message.length,
            limit: 0,
          };
        };
        const fromIds = [];
        const defaultSelectedFromId = "";
        return {
          messageValidator,
          messageCharLimitCalculator,
          enableEmojis: true,
          enableNotes: false,
          enableAttachments: true,
          fromIds,
          defaultSelectedFromId,
          disableSenderSelection: true,
          maximumContactLimit: 1,
          maximumContactLimitAlertText:
            "Emails can only have only one primary contact",
          mediumOfSend: "EMAIL",
          enableRichText: connectionType === "EMAIL",
        };
      }
    }
  }, [mobileNumbers, selectedConnection?.type]);

  const uploadFile = async (file: RcFile) => {
    await justWait(3000);
    const fileRecord = await SDK.uploadFile(file, {
      type: "NEW_MESSAGE",
      entityId: currentUser?.id!,
    });
    return fileRecord.url;
  };

  const [scheduleMessageModalState, setScheduleMessageModalState] =
    useSimpleState({
      isModalVisible: false,
      data: {
        contactId: "",
        connectionId: "",
        conversationId: "",
        subject: "",
        message: "",
        attachments: [] as string[],
        to: [] as string[],
        cc: [] as string[],
      },
    });

  const replyComponentRef = useRef<any>();

  const sendMessage = useCallback(
    async (text: string, attachments, isSchedule?: boolean) => {
      if (!selectedConnection) {
        message.warning("Please select a connection to send the message");
        return;
      }
      if (!contactId && (!selectedContacts || !selectedContacts?.length)) {
        Message.error("Please select a contact");
        return;
      }
      if (selectedContacts?.length > maximumContactLimit) {
        Message.error("Maximum recipients count exceeded");
        return;
      }

      if (
        selectedConnection?.type === "SMS" ||
        selectedConnection?.type === "TELNYX"
      ) {
        const messageMetaData = GenSMSMetaData(text);
        if (messageMetaData.hasExceededMaximumMessageSize) {
          Message.error("Maximum message length exceeded");
          return;
        }
      }

      if (selectedConnection?.type === "EMAIL" && !subjectLine) {
        message.warning("Subject line is required");
        return;
      }

      const rootState = store.getState();
      const messageData = {
        connectionId: selectedConnectionId,
        contactIds: contactId
          ? [contactId]
          : selectedContacts.map((option) => option.value),
        subject: subjectLine,
        message: text,
        attachments: attachments,
        to: additionalTo
          .map((option) => option.value)
          .map((contactId) => {
            const contact = selectContactById(contactId)(rootState);
            return contact?.data?.primaryEmail;
          })
          .filter((e) => e) as string[],
        cc: additionalCC
          .map((option) => option.value)
          .map((contactId) => {
            const contact = selectContactById(contactId)(rootState);
            return contact?.data?.primaryEmail;
          })
          .filter((e) => e) as string[],
      };

      if (!isSchedule) {
        // Patch for Whatsapp Conversation - To automatically assign contact - START
        if (selectedConnection?.type === "WHATS_APP_PRIVATE") {
          setLastSentMessage({
            contactIds: messageData.contactIds,
            timestamp: Date.now(),
          });
        }
        // Patch for Whatsapp Conversation - To automatically assign contact - END
        await onSendMessage(messageData);
      } else {
        // Todo: WAPA private messages scheduled will not be auto assigned
        setScheduleMessageModalState({
          isModalVisible: true,
          data: {
            conversationId: "",
            contactId: messageData.contactIds[0] as string,
            connectionId: selectedConnectionId!,
            subject: subjectLine,
            message: text,
            attachments: attachments || [],
            to: messageData.to,
            cc: messageData.cc,
          },
        });
      }
    },
    [
      additionalCC,
      additionalTo,
      contactId,
      maximumContactLimit,
      onSendMessage,
      selectedConnection,
      selectedConnectionId,
      selectedContacts,
      setScheduleMessageModalState,
      store,
      subjectLine,
    ],
  );

  useEffect(() => {
    setSelectedConnection(connectionId);
  }, [connectionId, setSelectedConnection]);

  const connectionFilter = useCallback(
    (connection: iConnection) => {
      if (
        !userHasConnectionGroupPermission(connection?.userGroups, [
          currentUser?.id,
          ...(currentUser?.userGroups || []),
        ])
      ) {
        return false;
      }
      if (mediumType === "EMAIL") {
        return connection.type === "EMAIL";
      }
      if (mediumType === "MOBILE") {
        return (
          connection.type === "SMS" ||
          connection.type === "WHATS_APP_PRIVATE" ||
          connection.type === "WHATS_APP_OFFICIAL" ||
          connection.type === "WHATS_APP_CLOUD" ||
          connection.type === "WHATS_APP_CLOUD_NATIVE" ||
          connection.type === "TELNYX"
        );
      }
      return (
        connection.type === "EMAIL" ||
        connection.type === "SMS" ||
        connection.type === "WHATS_APP_PRIVATE" ||
        // connection.type === "WHATS_APP_OFFICIAL" ||
        connection.type === "WHATS_APP_CLOUD_NATIVE" ||
        // connection.type === "WHATS_APP_CLOUD" ||
        connection.type === "TELNYX"
      );
    },
    [currentUser?.id, currentUser?.userGroups, mediumType],
  );

  return (
    <Modal
      title={
        <ModalTitle
          title="New Message"
          icon={<i className="ri-chat-new-fill"></i>}
        />
      }
      open={visible}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      footer={null}
      okText={"Send"}
      width={750}
      className="new-message-modal"
      data-click-context="New Message Modal"
      destroyOnClose={true}
    >
      <Spin spinning={isProcessing} indicator={<LoadingIndicatorWithSpin />}>
        <div style={{ maxWidth: 750 }} className="w-full mb-8">
          <div className="mb-1 font-bold">From (Connection):</div>
          <ConnectionSelector
            value={selectedConnectionId}
            onChange={setSelectedConnection}
            connectionFilter={connectionFilter}
            autoSelectFirstOption={true}
          />
          {!contactId ? (
            <>
              <div className="recipient mt-4 font-bold">
                {selectedConnection?.type === "EMAIL"
                  ? "Primary Recipient:"
                  : "To:"}
              </div>
              <MultiContactSelector
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
                requiredData={mediumOfSend as any}
                autoFocus={true}
              />
            </>
          ) : (
            <div className="mt-4"></div>
          )}

          {selectedContacts.length > maximumContactLimit && (
            <Alert
              message="Maximum recipients exceeded"
              description={maximumContactLimitAlertText}
              type="error"
              showIcon
              className="my-4"
            />
          )}

          {selectedConnection?.type === "EMAIL" && (
            <>
              <div className="mb-4">
                <SimpleCollapse title="Additional Recipients">
                  <>
                    <div className="recipient mt-4 font-bold">Email To</div>
                    <MultiContactSelector
                      selectedContacts={additionalTo}
                      setSelectedContacts={setAdditionalTo}
                      requiredData={"EMAIL"}
                    />
                    <div className="recipient mt-4 font-bold">Email CC: </div>
                    <MultiContactSelector
                      selectedContacts={additionalCC}
                      setSelectedContacts={setAdditionalCC}
                      requiredData={"EMAIL"}
                    />
                  </>
                </SimpleCollapse>
              </div>
              <div className="mb-1 font-bold">Subject line:</div>
              <Input
                placeholder="Subject line"
                value={subjectLine}
                onChange={(e) => setSubjectLine(e.target.value)}
                allowClear
                size="large"
                className="mb-6"
              />
            </>
          )}

          {selectedConnection?.type === "WHATS_APP_CLOUD_NATIVE" && (
            <>
              <WATemplateMessage
                connectionId={selectedConnection.id!}
                selectedContacts={
                  contactId
                    ? [contactId]
                    : selectedContacts.map((option) => option.value)
                }
                onMessageSent={clearFieldsAndCloseModal}
              />
            </>
          )}

          {selectedConnection?.type !== "WHATS_APP_CLOUD_NATIVE" && (
            <>
              <ReplyComponent
                enableEmojis={enableEmojis}
                enableNotes={false}
                enableAttachments={enableAttachments}
                enableRichText={enableRichText}
                defaultSelectedFromId={defaultSelectedFromId}
                ref={replyComponentRef}
                onSend={async (req) => {
                  await sendMessage(req.text, req.attachments);
                }}
                attachmentUploader={uploadFile}
                fromIds={fromIds}
                messageValidator={messageValidator}
                messageCharLimitCalculator={messageCharLimitCalculator}
                slashCommands={slashCommands}
                userList={userList}
                disableSenderSelection={disableSenderSelection}
                sendMessageOnEnter={false}
                replyHeaderText="Message"
                onScheduleMessage={(req) => {
                  sendMessage(req.text, req.attachments, true);
                }}
                // additionalTabBarContent={(mode) => {
                //   if (mode === "RICH_TEXT") {
                //     return <AddPresetButton />;
                //   }
                //   if (mode === "REPLY") {
                //     return <AddPresetButton />;
                //   }
                //   return <></>;
                // }}
              />
            </>
          )}

          <MessageScheduler
            visible={scheduleMessageModalState.isModalVisible}
            onChangeVisibility={(visibility) =>
              setScheduleMessageModalState({ isModalVisible: visibility })
            }
            data={scheduleMessageModalState.data}
            onScheduled={() => {
              if (replyComponentRef?.current) {
                replyComponentRef.current.ReplyInputFirstTabRef.current.setSendMessageInputValue(
                  "",
                );
              }
            }}
          />
        </div>
      </Spin>
      <DarkModeBg />
    </Modal>
  );
};
