import dayjs from "dayjs";
import _ from "lodash";
import moment from "moment";

type PeriodTypes = "HOUR" | "DAY" | "MONTH" | "WEEK";

export const getMomentDurationFromPeriod = (period: PeriodTypes) => {
  let momentPeriodType: moment.unitOfTime.DurationConstructor = "day";
  if (period === "HOUR") {
    momentPeriodType = "hour";
  } else if (period === "MONTH") {
    momentPeriodType = "month";
  } else if (period === "WEEK") {
    momentPeriodType = "week";
  }
  return momentPeriodType;
};

export const normalizeDataForGraph = (
  data: { timestamp: number }[],
  period: PeriodTypes,

  startTime: number,
  endTime: number,
  emptyRecordFunction: (timestamp: number, momentFormat: string) => any
) => {
  let periodFormatString = "YYYY-MM-DD";
  if (period === "HOUR") {
    periodFormatString = "YYYY-MM-DD-HH";
  } else if (period === "MONTH") {
    periodFormatString = "YYYY-MM";
  } else if (period === "WEEK") {
    periodFormatString = "YYYY-ww";
  }

  let periodRoller = dayjs(startTime).subtract(
    1,
    getMomentDurationFromPeriod(period)
  );
  const endPeriod = dayjs(endTime).add(1, getMomentDurationFromPeriod(period));

  const periods: any[] = [];

  while (!periodRoller.isAfter(endPeriod)) {
    periods.push(periodRoller.format(periodFormatString));
    periodRoller = periodRoller.add(1, getMomentDurationFromPeriod(period));
  }

  return _.flatten(
    periods.map((periodString: string) => {
      const existingRecords = _.filter(
        data,
        (ele) =>
          dayjs(ele.timestamp).format(periodFormatString) === periodString
      );
      if (existingRecords && existingRecords.length > 0) {
        return existingRecords;
      }
      // Record not found
      return emptyRecordFunction(
        dayjs(periodString, periodFormatString).valueOf(),
        periodFormatString
      );
    })
  );
};
