import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { iMessageSenderType } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, message, Modal, Space, Spin } from "antd";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import {
  AddEditKnowledgeDocument,
  KnowledgeBaseManageArticles,
} from "modal-registry";
import { useCallback, useEffect, useState } from "react";
import { useStore } from "react-redux";
import { selectMessageByConversationId } from "store/modules/messages/messages.selectors";
import { UserTracker } from "user-tracker";

export const ConversationSummaryModal = ({
  visible,
  onChangeVisibility,
  conversationId,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  conversationId: string;
}) => {
  const {
    doAction: _process,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (req) => SDK.processOpenAiPrompts(req),
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const store = useStore();

  useEffect(() => {
    // Todo: Load All messages
  }, []);

  const [chatRatingContent, setChatRatingContent] = useState("");
  // const [extractFAQsContent, setExtractFAQsContent] = useState(
  //   [] as { question: string; answer: string }[]
  // );
  const [extractFAQsContent, setExtractFAQsContent] = useState("");

  const process = useCallback(async () => {
    try {
      const messagesState = selectMessageByConversationId(conversationId)(
        store.getState(),
      );
      const allMessages = messagesState.data
        .map((message) => ({
          from: (() => {
            switch (message.from.senderType) {
              case iMessageSenderType.CLIENT: {
                return "Customer";
              }
              case iMessageSenderType.USER: {
                return "Agent";
              }
            }
            return message.from.senderType;
          })(),
          message: message.message,
          hasAttachments: Boolean(message.attachments.length),
        }))
        .filter((e) => e.from !== iMessageSenderType.SYSTEM);
      const messageText = allMessages
        .map((item) => `${item.from}: "${item.message}"\n`)
        .join("");

      const messageJson = JSON.stringify(allMessages, null, 2);

      const summaryPrompt = `Write a summary of the conversation between customer support agent and the customer`;
      const subjectLinePrompt = `Write a subject line for the conversation between customer support agent and the customer. It needs to be single sentence under 12 words.`;

      const requireFollowup = `Does the following conversation require a followup?`;

      const writeFollowup = `Write a followup message on behalf of the agent.  Keep it short (under 2 sentence)`;

      const chatRatingPrompt = `Study the below conversations explain the following points:
1) 1 or 2 sentence subject line (under 12 words) as "Subject"
2) Conversation Summary (be descriptive) as "Summary"
3) Customers's Tone as  "Customers's Tone"
4) Agent's Tone as "Agent's Tone"
5) Sentiment in the conversation as "Sentiment"
6) Tags in the conversation as "Keywords"
7) Agent's Conversing style as "Agent's Conversing style"
8) Was the agent handling the customer well? as "Was the agent handling the customer well?"
9) Extract FAQs (both questions and answers; Extract all) in the conversation as "Possible FAQs that can be extracted out"

Keep line breaks betweens the above points. Format the label in bold. Output as HTML.
`;

      const sentimentPrompt = `Find out the Sentiments in the conversation.`;

      const extractFAQsPromptJso = `Extract FAQs from the below conversation and present in a typescript array with the following interface:
      \`\`\`
      interface iFAQ{
      question:string;
      answer:string;
      }
      \`\`\``;

      const extractFAQsPrompt = `Extract FAQs from the below conversation and present in the below format
      \`\`\`
     Q: Question
     A: Answer
      \`\`\``;

      if (allMessages.length > 0) {
        const chatRating = await _process({
          type: "CHAT",
          instruction: chatRatingPrompt,
          input: messageText,
        });
        setChatRatingContent(chatRating?.choices?.[0]?.message?.content || "");

        // const extractFAQs = await _process({
        //   type: "CHAT",
        //   instruction: extractFAQsPrompt,
        //   input: messageText,
        // });
        // setExtractFAQsContent(
        //   textToHtml(extractFAQs?.choices?.[0]?.message?.content || "", true)
        // );

        try {
          // const faqs = JSON.parse(
          //   extractFAQs?.choices?.[0]?.message?.content || ""
          // );
          // setExtractFAQsContent(faqs);
        } catch (e) {
          // Ignore
        }
      }
    } catch (e) {
      console.log("Error while creating summary", e);
      message.error("Something went wrong");
    }
  }, [_process, conversationId, store]);

  // useEffectWhen(
  //   () => {
  //     if (conversationId) {
  //       process();
  //     }
  //   },
  //   [conversationId, process],
  //   [conversationId]
  // );

  useEffect(() => {
    if (conversationId) {
      process();
      UserTracker.track("CONVERSATION SUMMARY - Tried", {});
    }
  }, [conversationId, process]);

  const { triggerTempModal } = useModalPanels();

  return (
    <Modal
      title={
        <ModalTitle
          title="Conversation Analyzer"
          icon={<i className="ri-sparkling-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      width={700}
      data-click-context="Conversation Summary  Modal"
    >
      <Spin
        spinning={isProcessing}
        indicator={<LoadingIndicatorWithoutSpin />}
        wrapperClassName="flex-1 flex fill-spinner"
      >
        <div style={{ minHeight: 300 }}>
          {!isProcessing && (
            <>
              <div
                dangerouslySetInnerHTML={{ __html: chatRatingContent }}
              ></div>
              <Divider />
              <div className="flex flex-row justify-end items-center">
                <Space>
                  <Button
                    type="text"
                    icon={<i className="ri-folder-5-line"></i>}
                    onClick={() => {
                      triggerTempModal(KnowledgeBaseManageArticles, {});
                    }}
                  >
                    Manage Knowledge base Articles
                  </Button>
                  <Button
                    type="text"
                    icon={<i className="ri-add-line"></i>}
                    onClick={() => {
                      triggerTempModal(AddEditKnowledgeDocument, {
                        initialValue: chatRatingContent,
                      });
                    }}
                  >
                    Add New Document to Knowledge Kit
                  </Button>
                </Space>
              </div>
            </>
          )}
        </div>
      </Spin>
      <DarkModeBg />
    </Modal>
  );
};
