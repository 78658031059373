import { AwayDetectorService } from "@libs/away-detector/away-detector.service";
import { UserPresenceLoggerService } from "@libs/user-presence-logger/user-presence-logger.service";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import {
  AvailabilityStatus,
  iAutoKickUsersConfig,
  iUser,
} from "@sdk/user-management/user-management.models";
import * as Sentry from "@sentry/browser";
import { HandleSocketEvents } from "@socket-engine/handle-socket-events";
import { message } from "antd";
import { BadgesController } from "badge-controller";
import _, { debounce } from "lodash";
import { Store } from "redux";
import watch from "redux-watch";
import { UserTracker } from "user-tracker";
import { justWait } from "utils/just-wait";
import { SDK } from "../@sdk/sdk";
import { updateRecentLoginData } from "./modules/app-state/app-state.actions";
import { selectAllLoadedConversations } from "./modules/conversations/conversations.selectors";
import { selectHQAccessToken } from "./modules/hq/hq.selectors";
import { persistorSelector } from "./modules/ui-state/ui-state.selectors";
import { logout } from "./modules/users/users.actions";
import { loadAllUsers, reloadCurrentUser } from "./modules/users/users.helpers";
import {
  selectAccessToken,
  selectCurrentUser,
  selectCurrentUserAvailabilityStatus,
  selectCurrentUserData,
} from "./modules/users/users.selectors";
import { loadOrganization } from "./modules/workspace/workspace.helpers";
import {
  defaultAutoKickUserConfig,
  selectAutoKickUserConfig,
  selectOrganization,
  selectOrganizationLogo,
  selectWhitelistedIPs,
} from "./modules/workspace/workspace.selectors";
import { iStore } from "./store.model";

type SelectorFunction<T> = (state: iStore) => T;
type WatchCallback<T> = (newVal?: T, oldVal?: T, objectPath?: string) => void;

export const RegisterWatchers = (store: Store) => {
  function TypedWatch<T>(
    selector: SelectorFunction<T>,
  ): (callback: WatchCallback<T>) => () => any {
    return watch(() => selector(store.getState()));
  }

  /**
   *
   * Initiate
   *
   */

  const onStateRehydrate = debounce((newVal, oldVal, objectPath) => {
    // Write functions that needs to be firing when store initiated
  }, 200);

  const onIdentifyChange = debounce(
    (newVal?: any, oldVal?: any, objectPath?) => {
      // console.log("Identify Changed");
      const user = selectCurrentUser(store.getState());
      const organization = selectOrganization(store.getState());
      const accessToken = selectAccessToken(store.getState());

      if (organization && user) {
        store.dispatch(
          updateRecentLoginData({
            // Org Data
            domain: organization.domain,
            organizationLogo: organization.data.logo,
            // User Data
            email: user.credentials.email,
            firstName: user.data.firstName,
            lastName: user.data.lastName,
            avatar: user.data.avatar,
            // Access Data
            accessToken: accessToken,
          }),
        );
      }
    },
    200,
  );

  const onAccessTokenChangeInstant = (token) => {
    SDK.configure({
      token: token,
    });
  };
  const onHQAccessTokenChangeInstant = (token) => {
    SDK.HQConfigure({
      HQToken: token,
    });
  };

  const addWatcherStateListeners = () => {
    SocketConnector.socket.on("connect", () => {
      reWatchEntities();
    });

    SocketConnector.socket.on("reconnect", () => {
      reWatchEntities();
    });

    SocketConnector.socket.on("disconnect", () => {
      watchedEntities[SocketConnector.socket.id] = [];
    });
  };

  const reWatchEntities = () => {
    const list = selectAllLoadedConversations(store.getState());
    const newEntitiesToWatch = _.without(list || []);
    watchedEntities[SocketConnector.socket.id] = [...newEntitiesToWatch];
    if (newEntitiesToWatch.length > 0) {
      SocketConnector.socket.emit("WATCH_ENTITIES", newEntitiesToWatch || []);
    }
  };

  const onAccessTokenChange = debounce((token, oldVal, objectPath) => {
    // Write functions that needs to be firing when store initiated
    SDK.configure({
      token: token,
    });
    // On Logout, no need to load the account
    if (token) {
      loadOrganization(store).catch((e) => {
        console.log("Error while loading organization", e);
        // Failed. Ideally Logout
        message.info("Loading Workspace Failed");
        store.dispatch(logout());
      });
      loadAllUsers()(store, true).catch((e) => {
        console.log("Error while loading all users");
        // Failed. Ideally Logout
        message.info("Loading Users Failed");
        store.dispatch(logout());
      });
      if (!selectCurrentUser(store.getState())) {
        reloadCurrentUser(store).catch((e) => {
          console.log("Error while loading user");
          // Failed. Ideally Logout
          message.info("Loading User Failed");
          store.dispatch(logout());
        });
      }
      BadgesController.init();
      onIdentifyChange();
    }

    // Connect and Disconnect Socket Server // Probably have to move this to main component
    if (token) {
      SocketConnector.connect(token);
      HandleSocketEvents(SocketConnector.socket, store);
      // Init Away Detector
      AwayDetectorService.init(store);
      // Init User Presence Logger
      // Todo: Uncomment
      // UserPresenceLoggerService.init(store);
      addWatcherStateListeners();
    } else {
      SocketConnector.disconnect();
      UserPresenceLoggerService.destroy(store);
    }
  }, 200);

  const onCurrentUserChange = debounce(
    (newVal?: iUser, oldVal?: iUser, objectPath?) => {
      if (newVal && newVal.id) {
        window["CC_CURRENT_USER_ID"] = newVal.id;
      }
      // Auto Logout Deactivated Users
      if (newVal && newVal.metaData.isActive === false) {
        message.info("User is not active");
        store.dispatch(logout());
      }
      if (newVal?.credentials?.email) {
        Sentry.setUser({
          email: newVal?.credentials.email,
        });
        Sentry.setContext("data", {
          name: `${newVal.data.firstName}`,
          organizationId: newVal.organizationId,
          email: newVal?.credentials.email,
        });
        const organization = selectOrganization(store.getState());
        // Mixpanel User Identification
        UserTracker.identify(newVal.id);
        const mixPanelProfile = {
          $name: [newVal.data.firstName, newVal.data.lastName]
            .filter((e) => e)
            .join(" "),
          $first_name: newVal.data.firstName,
          $last_name: newVal.data.lastName,
          $email: newVal?.credentials.email,
          "User Role": getRoleFromPermission(newVal.permissions),
          organizationId: newVal.organizationId,
          workspace: organization?.domain,
        };
        UserTracker.setUserProperties(mixPanelProfile);
      }
      // Patch to initiate the Away Detector Config
      onAutoKickUserConfigChange(defaultAutoKickUserConfig);
    },
    200,
  );

  let watchedEntities: { [socketId: string]: string[] } = {};
  const onLoadedConversationsChange = debounce(
    (list?: string[], oldVal?: string[], objectPath?) => {
      if (SocketConnector.socket) {
        const existingList = watchedEntities[SocketConnector.socket.id] || [];
        const newEntitiesToWatch = _.without(list || [], ...existingList);
        watchedEntities[SocketConnector.socket.id] = [
          ...existingList,
          ...newEntitiesToWatch,
        ];
        if (newEntitiesToWatch.length > 0) {
          SocketConnector.socket.emit(
            "WATCH_ENTITIES",
            newEntitiesToWatch || [],
          );
        }
      }
    },
    200,
  );

  const onAllowedIPChange = debounce((allowedIps, oldVal, objectPath) => {
    if (allowedIps && allowedIps.length > 0) {
      let user = selectCurrentUser(store.getState());
      // Todo: Could Improve this
      (async () => {
        if (!user) {
          await justWait(2000);
        }
        user = selectCurrentUser(store.getState());
        if (!user) {
          return;
        }
        const role = getRoleFromPermission(user.permissions);
        if (role !== "Owner") {
          SDK.validateAccess()
            .then((data) => {
              if (!data.valid) {
                message.warning("Your IP address is blocked");
                store.dispatch(logout());
              }
            })
            .catch((e) => {
              // Ignore
            });
        }
      })();
    }
  }, 200);

  const onUserAvailabilityChange = debounce(
    (newVal?: AvailabilityStatus, oldVal?: AvailabilityStatus, objectPath?) => {
      // Enable and Disable Away Detectors
      if (newVal === "AVAILABLE") {
        AwayDetectorService.IS_AWAY_DETECTOR_ACTIVE = true;
      } else {
        AwayDetectorService.IS_AWAY_DETECTOR_ACTIVE = false;
        AwayDetectorService.LAST_ONLINE_TIME = undefined;
        AwayDetectorService.IS_REMINDER_ACTION_TAKEN = false;
        AwayDetectorService.IS_AWAY_ACTION_TAKEN = false;
      }
    },
    200,
  );

  const onAutoKickUserConfigChange = debounce(
    (
      newVal?: iAutoKickUsersConfig,
      oldVal?: iAutoKickUsersConfig,
      objectPath?,
    ) => {
      // AwayDetectorService.IS_AWAY_DETECTOR_ACTIVE_FOR_ORGANIZATION = true;
      // AwayDetectorService.AWAY_TIMEOUT = 60 * 1000;
      // AwayDetectorService.REMINDER_THRESHOLD = 30 * 1000;
      // // Todo:
      AwayDetectorService.IS_AWAY_DETECTOR_ACTIVE_FOR_ORGANIZATION = Boolean(
        newVal?.active,
      );
      AwayDetectorService.AWAY_TIMEOUT = newVal?.timeout || 30 * 60 * 1000;
      AwayDetectorService.REMINDER_THRESHOLD =
        newVal?.timeout || 30 * 60 * 1000 - 1 * 60 * 1000;
    },
    200,
  );

  /**
   *
   * Watchers
   *
   */

  const persistStateWatcher = TypedWatch(persistorSelector)(onStateRehydrate);

  const accessTokenWatcher = TypedWatch(selectAccessToken)(onAccessTokenChange);

  const accessTokenWatcher2 = TypedWatch(selectAccessToken)(
    onAccessTokenChangeInstant,
  );
  const HQAccessTokenWatcher = TypedWatch(selectHQAccessToken)(
    onHQAccessTokenChangeInstant,
  );
  const CurrentUserWatcher = TypedWatch(selectCurrentUser)(onCurrentUserChange);

  const AllowedIPChange = TypedWatch(selectWhitelistedIPs)(onAllowedIPChange);

  const loadedConversationsWatcher = TypedWatch(selectAllLoadedConversations)(
    onLoadedConversationsChange,
  );

  const userAvailabilityWatcher = TypedWatch(
    selectCurrentUserAvailabilityStatus,
  )(onUserAvailabilityChange);

  const autoKickUserConfigWatcher = TypedWatch(selectAutoKickUserConfig)(
    onAutoKickUserConfigChange,
  );

  const userOrganizationLogoChangeWatcher = TypedWatch(selectOrganizationLogo)(
    onIdentifyChange,
  );

  const userDataWatcher = TypedWatch(selectCurrentUserData)(onIdentifyChange);

  store.subscribe(() => {
    // Add Watchers Here
    persistStateWatcher();
    accessTokenWatcher();
    accessTokenWatcher2();
    HQAccessTokenWatcher();
    CurrentUserWatcher();
    loadedConversationsWatcher();
    AllowedIPChange();
    userAvailabilityWatcher();
    autoKickUserConfigWatcher();
    userOrganizationLogoChangeWatcher();
    userDataWatcher();
  });
};
