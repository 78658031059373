import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import DynamicFormEditor from "./dynamic-form-editor";

const FormTemplateOptions = [
  {
    value: "EMAIL",
    label: "Email",
    icon: <i className="ri-mail-line"></i>
  },
  {
    value: "SMS",
    label: "SMS",
    icon: <i className="ri-message-3-line"></i>
  }
];

export const ConfigureDynamicFormModal = ({
  visible,
  onChangeVisibility,
  dynamicFormId
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  dynamicFormId: string;
}) => {
  return (
    (<Modal
      title={
        <ModalTitle
          icon={<i className="ri-file-text-line"></i>}
          title="Form Editor"
        />
      }
      open={visible}
      footer={null}
      onCancel={() => onChangeVisibility(false)}
      data-click-context="Editor Dynamic Form Modal"
      width={"100%"}
      destroyOnClose={true}
      bodyStyle={{ padding: 0 }}
    >
      <DynamicFormEditor dynamicFormId={dynamicFormId} />
      <DarkModeBg />
    </Modal>)
  );
};
