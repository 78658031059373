import {
  getPermissionFromRole,
  Roles
} from "@sdk/roles-and-permissions/roles-and-permissions";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";

import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";

import MailOutlined from "@ant-design/icons/MailOutlined";
import { Button, Form, Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { BadgesController } from "badge-controller";
import { debouncedEmailAvailability } from "components/modules/user-management/users/helpers/email-availability";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";

const emptyInviteList = {
  users: [
    {
      email: "",
      level: ""
    },
    {
      email: "",
      level: ""
    },
    {
      email: "",
      level: ""
    }
  ]
};

export const BulkInviteUsers = ({ onComplete }: { onComplete: () => any }) => {
  const currentOrganization = useSelector(selectOrganization);
  const [userInviteForm] = useForm();

  const {
    doAction: onInviteUser,
    isProcessing: isInviteProcessing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.inviteUser({
          email: values.email,
          permissions: getPermissionFromRole(values.role)!
        }).then(d => {
          BadgesController.processBadgeEvent("invite-a-user");
          return d;
        }),
      // successMessage: "User has been invited",
      failureMessage: "Something went wrong",
      throwError: true
    }),
    []
  );
  return (
    <Form
      layout="vertical"
      form={userInviteForm}
      requiredMark={false}
      initialValues={emptyInviteList}
    >
      <div
        className="flex flex-col m-auto border border-gray-200 dark:border-gray-800 rounded-lg p-8"
        style={{ minWidth: "30rem" }}
      >
        <Form.List name={["users"]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(field => (
                <div
                  key={field.key}
                  className="flex flex-row justify-center items-center gap-4"
                  style={{ marginBottom: 8 }}
                >
                  <Form.Item
                    name={[field.name, "email"]}
                    hasFeedback
                    validateFirst={true}
                    rules={[
                      { type: "email", required: true },
                      ({ getFieldValue }) => ({
                        validator: async (rule, value) =>
                          await debouncedEmailAvailability(
                            value,
                            currentOrganization?.id
                          )
                      })
                    ]}
                    label=""
                    style={{ marginBottom: 0 }}
                    className="flex-1"
                  >
                    <Input
                      prefix={<MailOutlined className="site-form-item-icon" />}
                      placeholder="Email"
                    />
                  </Form.Item>

                  <Form.Item
                    label=""
                    name={[field.name, "role"]}
                    rules={[
                      {
                        required: true,
                        message: "Please select a Level of access"
                      }
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Select
                      placeholder="Level of access"
                      style={{ width: 150 }}
                    >
                      {Roles.map(role => (
                        <Select.Option key={role.label} value={role.label}>
                          {role.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </div>
              ))}
              <div
                onClick={() => add({ type: "work", phone: "" })}
                className="cursor-pointer"
              >
                + add one more
              </div>
            </>
          )}
        </Form.List>

        <div className="flex flex-row justify-end items-center">
          <Button
            type="primary"
            loading={isInviteProcessing}
            onClick={async () => {
              const { users } = userInviteForm.getFieldsValue();
              for (const user of users) {
                if (user.email && user.role) {
                  await onInviteUser(user);
                }
              }
              onComplete();
            }}
            className="font-bold"
            icon={<i className="ri-mail-send-line"></i>}
          >
            Send Invite(s)
          </Button>
        </div>
      </div>
    </Form>
  );
};
