import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  AddConnectionLayout,
  AddConnectionModalContainer,
  AddConnectionStepsContainer,
  IntegrationSuccessfulScreen,
  StepContainer,
} from "components/modules/connections/add-connection-modal-templates";
import { ConnectionIcons } from "components/modules/connections/helpers/connection-icons";
import { push } from "connected-react-router";
import { useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { UserTracker } from "user-tracker";

export const AddTelegramConnectionModal = ({
  visible,
  onChangeVisibility: _onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = useForm();
  const [createdConnection, setCreatedConnection] = useState("");

  const { doAction: completeIntegration, isProcessing: isActionProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (val) =>
          SDK.connections.create(val).then((d) => {
            setCreatedConnection(d.id!);
            setCurrentStep(2);
          }),
        successMessage: "Integration has been created",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const onChangeVisibility = (visibility: boolean) => {
    _onChangeVisibility(visibility);
    setCurrentStep(0);
  };

  const dispatch = useDispatch();
  const store = useStore();

  return (
    <>
      <AddConnectionModalContainer
        title="Connect Telegram"
        icon={<i className="ri-telegram-line"></i>}
        isVisible={visible}
        onChangeVisibility={onChangeVisibility}
      >
        <AddConnectionLayout
          currentStep={currentStep}
          totalSteps={2}
          stepsWithSidebars={[0, 1]}
          topBar={
            <AddConnectionStepsContainer
              currentStep={currentStep}
              steps={[
                {
                  label: "Begin",
                  icon: <i className="ri-info-line"></i>,
                },
                {
                  label: "Authentication",
                  icon: <i className="ri-lock-line"></i>,
                },
                {
                  label: "Done",
                  icon: <i className="ri-check-line"></i>,
                },
              ]}
            />
          }
          sidebarImage={
            <div className="text-10xl opacity-50">
              <i className={ConnectionIcons.TELEGRAM}></i>
            </div>
          }
        >
          <StepContainer stepNumber={0} currentStep={currentStep}>
            <div className="description text-gray-600 dark:text-gray-400 mb-4 mode_transition">
              Connect Telegram Bot with ClickConnector
            </div>

            <div className="flex flex-row justify-center items-center">
              <img
                src={
                  "https://core.telegram.org/file/811140934/1/tbDSLHSaijc/fdcc7b6d5fb3354adf"
                }
                style={{ width: 150 }}
                alt="The BotFather"
              />
            </div>

            <div className="description text-gray-600 dark:text-gray-400 mb-4 mode_transition">
              Creating a bot is streamlined by Telegram’s Bot API. To get
              started, message @BotFather on Telegram to register your bot and
              receive its authentication token.
            </div>

            <div className="button-container my-4 flex flex-row justify-center items-center">
              <Button
                type="primary"
                loading={isActionProcessing}
                size="large"
                onClick={() => {
                  setCurrentStep(1);
                }}
                icon={<i className="ri-arrow-right-circle-fill"></i>}
              >
                Continue (Beta)
              </Button>
            </div>
          </StepContainer>

          <StepContainer stepNumber={1} currentStep={currentStep}>
            <Form layout="vertical" form={form} initialValues={{}}>
              <div className="mb-2">
                To get started, message @BotFather on Telegram to register your
                bot and receive its authentication token.
              </div>
              <Form.Item
                label="Access Token"
                name="accessToken"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid Access Token",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>

            <div className="flex flex-row justify-between items-center mt-4">
              <Button
                onClick={() => {
                  setCurrentStep(0);
                }}
                size="large"
                icon={<i className="ri-arrow-left-circle-fill"></i>}
              >
                Back
              </Button>
              <Button
                type="primary"
                loading={isActionProcessing}
                size="large"
                onClick={async () => {
                  try {
                    await form.validateFields();
                    const formValues = form.getFieldsValue();

                    completeIntegration({
                      type: "TELEGRAM",
                      label: `TG - ${formValues.phoneNumber}`,
                      accessToken: formValues.accessToken,
                      data: {
                        ...formValues,
                      },
                    });
                    UserTracker.track("Add Telegram Connection", {
                      ...formValues,
                    });
                  } catch (e) {
                    message.warning("Please check your input");
                  }
                }}
                icon={<i className="ri-arrow-right-circle-fill"></i>}
              >
                Continue
              </Button>
            </div>
          </StepContainer>

          <StepContainer stepNumber={2} currentStep={currentStep}>
            <IntegrationSuccessfulScreen
              onManageConnection={() => {
                dispatch(
                  push(`/configurations/connections/${createdConnection}/`),
                );
              }}
              onHideWizard={() => onChangeVisibility(false)}
              subTitle="Now you will be able to reply to Telegram messages from Click Connector"
              title="Your Integration is ready!"
            ></IntegrationSuccessfulScreen>
          </StepContainer>
        </AddConnectionLayout>
      </AddConnectionModalContainer>
    </>
  );
};
