import { Button, Drawer, Form, Input, Segmented, Select, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import { FilterBar } from "components/common/filter-schema/filter-bar";
import { iFilterOption } from "components/common/filter-schema/filter-config-schema";
import { ModalTitle } from "components/common/modal-title";

import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectConversationTags } from "store/modules/workspace/workspace.selectors";
import { toTitleCase } from "utils/to-title-case";
import { useAvailableCharts } from "../../helpers/use-available-charts";
import { ConversationsReportSchema } from "../../schemas/legacy-reports-schema";
import { ReportsFilterSchema } from "../../schemas/report-filter-schemas";
import { iReportWidget } from "../report-canvas/widget-canvas-models";
import { ChartTypeIcons } from "../report-widget/report-widget";
import "./edit-report-widget-drawer.scss";

const periodAggregateOptions = [
  { label: "None", value: "" },
  { label: "Hourly", value: "HOUR" },
  { label: "Daily", value: "DAY" },
  { label: "Monthly", value: "MONTH" },
  { label: "Weekly", value: "WEEK" }
];

const aggregationMethods = [
  { label: "Count Records", value: "" },
  { label: "Average ", value: "AVERAGE" },
  { label: "BUCKET_AUTO", value: "BUCKET_AUTO" }
];

export const EditReportWidgetDrawer = ({
  isVisible,
  onChangeVisibility,
  initialState,
  onEditWidget
}: {
  isVisible;
  onChangeVisibility: (state: boolean) => any;
  initialState?: iReportWidget;
  onEditWidget: (widget: iReportWidget) => any;
}) => {
  const [editForm] = useForm();

  useEffect(() => {
    setTimeout(() => {
      editForm.resetFields();
    }, 100);
  }, [isVisible, editForm]);

  const [selectedFilters, setSelectedFilters] = useState([] as iFilterOption[]);

  const [formValue, setFormValue] = useState({ ...initialState } as any);

  console.log("selectedFilters", selectedFilters);

  useEffect(() => {
    if (initialState) {
      setFormValue(initialState);
      setSelectedFilters(initialState.filters || []);
    }
  }, [initialState]);

  const reportConfig = useMemo(() => {
    return { groupBy: [], ...(initialState || {}), ...formValue } as any;
  }, [formValue, initialState]);

  // Todo: Could Extract this as function and use instead of using hook
  const availableChartTypes = useAvailableCharts(reportConfig!);

  // eslint-disable-next-line no-undef
  const conversationTags = useSelector(selectConversationTags);
  const availableTagGroups = useMemo(() => {
    const tagGroups = _.groupBy(conversationTags, "tagGroup");
    return Object.keys(tagGroups);
  }, [conversationTags]);

  return (
    <Drawer
      title={
        <ModalTitle
          title={"Edit Widget"}
          icon={<i className="ri-edit-line"></i>}
        />
      }
      placement="right"
      onClose={e => onChangeVisibility(false)}
      open={isVisible}
      width={400}
      bodyStyle={{ padding: 0 }}
      className="edit-report-widget-drawer"
    >
      <div className="bg-white dark:bg-gray-900 p-8 flex flex-col flex-wrap gap-8 min-h-full bold-form-labels">
        {isVisible && (
          <>
            <Form
              layout="vertical"
              form={editForm}
              initialValues={initialState}
              onValuesChange={() => {
                setFormValue(editForm.getFieldsValue());
              }}
            >
              <Form.Item label={"Widget Title"} name={["title"]}>
                <Input placeholder="Enter a title" />
              </Form.Item>
              <Form.Item
                label="Widget Description"
                name={["description"]}
                rules={[]}
              >
                <Input.TextArea placeholder="Description" />
              </Form.Item>

              <Form.Item label="Group Report Results By" name="groupBy">
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Please select"
                >
                  {ConversationsReportSchema.groupByOptions.map(option => (
                    <Select.Option key={option.key} value={option.key}>
                      {option.icon && <i className={option.icon}></i>}
                      {option.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const groupByOptions = getFieldValue("groupBy");
                  if (groupByOptions.includes("tags")) {
                    return (
                      <Form.Item
                        label="Filter Tag Types"
                        name={["reportViewConfig", "tagType"]}
                        rules={[]}
                      >
                        <Select
                          mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Please select"
                        >
                          {availableTagGroups.map(tagGroup => (
                            <Select.Option key={tagGroup} value={tagGroup}>
                              {toTitleCase(tagGroup)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    );
                  }
                }}
              </Form.Item>

              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const groupByOptions = getFieldValue("groupBy");
                  if (
                    groupByOptions.length === 1 &&
                    groupByOptions.includes("users")
                  ) {
                    return (
                      <Form.Item
                        label="Group by User Groups"
                        name={["reportViewConfig", "groupByUserGroup"]}
                        rules={[]}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    );
                  }
                }}
              </Form.Item>

              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const groupByOptions = getFieldValue("groupBy");
                  if (groupByOptions.includes("responseTime__")) {
                    return (
                      <Form.Item
                        label="Aggregation Method"
                        name={["aggregateFunction"]}
                        rules={[]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Please select"
                        >
                          {aggregationMethods.map(option => (
                            <Select.Option
                              key={option.label}
                              value={option.value}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    );
                  }
                }}
              </Form.Item>

              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const groupByOptions = getFieldValue("groupBy");
                  return (
                    <Form.Item
                      label="Aggregate Results by Period"
                      name="aggregateByPeriod"
                    >
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Please select"
                      >
                        {periodAggregateOptions.map(option => (
                          <Select.Option
                            key={option.label}
                            value={option.value}
                          >
                            {option.label}
                          </Select.Option>
                        ))}
                        {groupByOptions.length === 0 && (
                          <Select.Option key={"HEAT_MAP"} value={"HEAT_MAP"}>
                            Heat Map
                          </Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                  );
                }}
              </Form.Item>

              <FilterBar
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                schema={ReportsFilterSchema}
                label="Add Additional Filters"
                className="p-0 my-8"
              ></FilterBar>

              {availableChartTypes.length > 1 && (
                <Form.Item
                  label="Preferred Widget"
                  name={["reportViewConfig", "defaultVisualization"]}
                  rules={[]}
                >
                  <Segmented
                    options={availableChartTypes.map(chartType => ({
                      value: chartType,
                      label: "",
                      icon: ChartTypeIcons[chartType] ? (
                        ChartTypeIcons[chartType]
                      ) : (
                        <i className="ri-question-line"></i>
                      )
                    }))}
                    block
                    className="w-full"
                  />
                </Form.Item>
              )}
            </Form>
            <Button
              type="primary"
              icon={<i className="ri-save-line"></i>}
              onClick={async () => {
                try {
                  await editForm.validateFields();
                  const formValues = editForm.getFieldsValue();
                  if (initialState) {
                    // Todo
                    onEditWidget({
                      ...initialState,
                      ...formValues,
                      filters: selectedFilters
                    });
                  }
                } catch (e) {
                  // Show Error message
                }
              }}
            >
              Save
            </Button>
          </>
        )}
      </div>
    </Drawer>
  );
};
