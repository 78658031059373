import { useSDK } from "@sdk";
import { iFbConnectionData } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Alert, Button, Descriptions, Divider, Space, Spin } from "antd";
import { ExternalAuthConnector } from "components/common/external-auth-connector-button/external-auth-connector-button";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { SectionHeader } from "components/common/section-header";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { stringArrayToSentence } from "utils/string-array-to-sentence";

export const ManageInstagramConnection = ({
  connectionId
}: {
  connectionId: string;
}) => {
  const isDevMode = useSelector(selectIsAdvancedMode);

  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId
  );

  const {
    doAction: syncConversations,
    isProcessing: isConversationSyncing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (connectionId: string) =>
        SDK.connections.loadAllRecentIgConversation(connectionId),
      successMessage: "Conversations have been synced",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const {
    doAction: syncPosts,
    isProcessing: isPostsSyncing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (connectionId: string) =>
        SDK.instagram.syncAllMedia(connectionId),
      successMessage: "Posts have been synced",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const fbConnectionData = connection?.data as iFbConnectionData;

  const {
    data: connectionStatus,
    isLoading,
    error,
    reload: reloadDebug
  } = useSDK(
    SDK => SDK.connections.checkFacebookAuthenticationStatus(connectionId),
    [connectionId],
    !connectionId
  );

  const {
    doAction: reAuthenticateInstagramConnection,
    isProcessing: isAuthenticating
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (connectionId: string, accessToken: string) =>
        SDK.connections
          .reAuthenticateInstagramConnection(connectionId, accessToken)
          .then(d => {
            return reloadDebug();
          }),
      successMessage: "Re-authentication has been successful",
      failureMessage: "Something went wrong"
    }),
    [reloadDebug]
  );

  const instagramPage = useMemo(() => {
    const pages = connectionStatus?.pages || [];
    const page = pages.find(item => item.id === fbConnectionData?.page?.id);
    if (page?.instagram_business_account?.id) {
      return page;
    }
  }, [connectionStatus?.pages, fbConnectionData?.page?.id]);

  return (
    <Spin
      spinning={!error && isLoading}
      indicator={<LoadingIndicatorWithoutSpin />}
      wrapperClassName="flex-1 flex fill-spinner"
    >
      <div className="animated fadeInLeftMin  flex flex-col w-full">
        <SectionHeader
          title="Connected Page"
          icon={<i className="ri-instagram-line"></i>}
          description="Channel, Conversation & Posts Integration"
        />
        <Divider />
        <div className="flex flex-row w-full justify-end items-center mb-4">
          <Space>
            <Button
              type="link"
              loading={isConversationSyncing}
              onClick={() => {
                syncConversations(connectionId);
              }}
              icon={<i className="ri-refresh-line"></i>}
            >
              (Re)Sync Conversations
            </Button>
            <Button
              type="link"
              loading={isPostsSyncing}
              onClick={() => {
                syncPosts(connectionId);
              }}
              icon={<i className="ri-refresh-line"></i>}
            >
              (Re)Sync Posts
            </Button>
          </Space>
        </div>

        {error ||
        (!isLoading && (!connectionStatus?.debugResponse || !instagramPage)) ? (
          <>
            <Alert
              message={
                <div className="font-bold">
                  It looks like your Instagram Integration is broken
                </div>
              }
              description={
                <div>
                  It could happen for the following reasons <br />
                  <ul>
                    <li>👉 You disconnected Click Connector Manually</li>
                    <li>
                      👉 The user who gave permission is no longer part of the
                      page
                    </li>
                    <li>
                      👉 The user who gave permission changed their password
                    </li>
                  </ul>
                  <div
                    className="flex flex-row justify-end items-center"
                    style={{ width: 202 }}
                  >
                    <Spin
                      spinning={isAuthenticating || (!error && isLoading)}
                      indicator={<LoadingIndicatorWithoutSpin />}
                      wrapperClassName="flex-1 flex fill-spinner"
                    >
                      <ExternalAuthConnector
                        type="FACEBOOK"
                        onAuthenticated={({ accessToken }) => {
                          reAuthenticateInstagramConnection(
                            connection.id,
                            accessToken
                          );
                        }}
                        label="Re-Connect Instagram"
                      />
                    </Spin>
                  </div>
                </div>
              }
              type="error"
              showIcon
              className="p-4 rounded-lg my-2 mb-4"
            />
          </>
        ) : (
          <></>
        )}

        {isDevMode &&
          !(
            error ||
            (!isLoading && (!connectionStatus?.debugResponse || !instagramPage))
          ) && (
            <div>
              <Spin
                spinning={isAuthenticating || (!error && isLoading)}
                indicator={<LoadingIndicatorWithoutSpin />}
                wrapperClassName="flex-1 flex fill-spinner"
              >
                <ExternalAuthConnector
                  type="FACEBOOK"
                  onAuthenticated={({ accessToken }) => {
                    reAuthenticateInstagramConnection(
                      connection.id,
                      accessToken
                    );
                  }}
                  label="Re-Authenticate Instagram"
                  insider={true}
                />
              </Spin>
            </div>
          )}

        <Descriptions
          title="Integration Details"
          bordered
          layout="horizontal"
          column={1}
          style={{ maxWidth: 600 }}
        >
          <Descriptions.Item label="Connected Page">
            {fbConnectionData?.page?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Page Category">
            {fbConnectionData?.page?.category}
          </Descriptions.Item>
          {!error && (
            <>
              <Descriptions.Item label="Application">
                {connectionStatus?.debugResponse?.data.application}
              </Descriptions.Item>
              <Descriptions.Item label="Application">
                {connectionStatus?.debugResponse?.data.application}
              </Descriptions.Item>
              <Descriptions.Item label="Application">
                {connectionStatus?.debugResponse?.data.application} (
                {connectionStatus?.debugResponse?.data.app_id})
              </Descriptions.Item>
              <Descriptions.Item label="Data Access Expiry">
                {dayjs(
                  connectionStatus?.debugResponse?.data.data_access_expires_at *
                    1000
                ).toString()}
              </Descriptions.Item>
              <Descriptions.Item label="Access Issued at">
                {dayjs(
                  connectionStatus?.debugResponse?.data.issued_at * 1000
                ).toString()}
              </Descriptions.Item>
              <Descriptions.Item label="Profile ID">
                {connectionStatus?.debugResponse?.data.profile_id}
              </Descriptions.Item>
              <Descriptions.Item label="Granted Permissions">
                {stringArrayToSentence(
                  connectionStatus?.debugResponse?.data.scopes || []
                )}
              </Descriptions.Item>
              <Descriptions.Item label="UserId">
                {connectionStatus?.debugResponse?.data.user_id}
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
      </div>
    </Spin>
  );
};
