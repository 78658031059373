import { iConversationReferenceType } from "@sdk/conversations/conversations.models";
import { ReportSchema } from "./backend-report-schema-model";

export const ConversationsReportsBackendSchema: ReportSchema = {
  filters: {
    organizationId: {
      type: "EQUALITY",
      path: "organizationId"
    },
    users: {
      type: "ALL",
      path: "members.userId"
    },
    usersIn: {
      type: "IN",
      path: "members.userId"
    },
    tagsIn: {
      type: "IN",
      path: "tags"
    },
    tags: {
      type: "ALL",
      path: "tags"
    },
    connectionId: {
      type: "EQUALITY",
      path: "connectionId"
    },
    connectionType: {
      type: "EQUALITY",
      path: "connectionType"
    },
    messageCount: {
      type: "BETWEEN",
      path: "metaData.totalMessages"
    },
    fbAdId: {
      type: "EQUALITY",
      path: "references.value"
    },
    fbPostId: {
      type: "EQUALITY",
      path: "references.value"
    },
    team: {
      type: "EQUALITY",
      path: "members.userId"
    },
    source: {
      type: "EQUALITY",
      path: "metaData.initSource"
    },
    status: {
      type: "EQUALITY",
      path: "status"
    },
    responseTime: {
      type: "BETWEEN",
      path: "metaData.firstResponseTime"
    },
    duration: {
      type: "BETWEEN",
      path: "metaData.duration"
    },
    chatRating: {
      type: "EQUALITY",
      path: "chatRating.rating"
    }
  },
  dateFilterTypes: {
    createdTime: "metaData.createdAt",
    objectCreated: "metaData.objectCreatedAt",
    closedAt: "metaData.closedAt",
    lastMessageTimestamp: "metaData.lastMessage.timestamp"
  },
  // Todo: Wherever there is an array, unwind is required
  groupByOptions: {
    users: {
      path: "members.userId",
      unwinds: ["members"]
    },
    status: {
      path: "status"
    },
    tags: {
      path: "tags",
      unwinds: ["tags"]
    },
    tagsCombination: {
      path: "tags"
    },
    connections: {
      path: "connectionId"
    },
    messageCount: {
      path: "metaData.totalMessages"
    },
    source: {
      path: "metaData.initSource"
    },
    chatRating: {
      path: "chatRating.rating"
    },
    fbAdId: {
      path: "references.value",
      preTasks: [
        // Todo: Probably an unwind is required, Got to check this
        {
          $match: {
            "references.type": iConversationReferenceType.FB_AD_REFERENCE
          }
        }
      ]
    },
    fbPostId: {
      path: "references.value",
      preTasks: [
        // Todo: Probably an unwind is required, Got to check this
        {
          $match: {
            "references.type": iConversationReferenceType.FB_POST_REFERENCE
          }
        }
      ]
    },
    responseTime: {
      path: "metaData.firstResponseTime",
      preTasks: [
        {
          $match: {
            "metaData.firstResponseTime": { $exists: true }
          }
        }
      ]
    },
    duration: {
      path: { $round: ["$metaData.duration", -4] },
      preTasks: [
        {
          $match: {
            "metaData.duration": { $exists: true }
          }
        }
      ]
    },
    categorizedResponseTime: {
      path: {
        $switch: {
          branches: [
            {
              case: { $lt: ["$metaData.firstResponseTime", 30] },
              then: "Fast"
            },
            {
              case: {
                $and: [
                  { $gte: ["$metaData.firstResponseTime", 30] },
                  { $lt: ["$metaData.firstResponseTime", 120] }
                ]
              },
              then: "Average"
            },
            {
              case: {
                $and: [
                  { $gte: ["$metaData.firstResponseTime", 120] },
                  { $lt: ["$metaData.firstResponseTime", 300] }
                ]
              },
              then: "Slow"
            },
            {
              case: { $gte: ["$metaData.firstResponseTime", 300] },
              then: "Super Slow"
            }
          ],
          default: "NAN"
        }
      },
      preTasks: [
        {
          $match: {
            "metaData.firstResponseTime": { $exists: true }
          }
        }
      ]
    },
    categorizedDuration: {
      path: {
        $switch: {
          branches: [
            {
              case: { $lt: ["$metaData.duration", 5 * 60 * 1000] },
              then: "Less than 5 minutes"
            },
            {
              case: {
                $and: [
                  { $gte: ["$metaData.duration", 5 * 60 * 1000] },
                  { $lt: ["$metaData.duration", 60 * 60 * 1000] }
                ]
              },
              then: "Between 5 and 60 minutes"
            },
            {
              case: {
                $and: [
                  { $gte: ["$metaData.duration", 1 * 60 * 60 * 1000] },
                  { $lt: ["$metaData.duration", 4 * 60 * 60 * 1000] }
                ]
              },
              then: "Between 1 and 4 hours"
            },
            {
              case: {
                $and: [
                  { $gte: ["$metaData.duration", 4 * 60 * 60 * 1000] },
                  { $lt: ["$metaData.duration", 24 * 60 * 60 * 1000] }
                ]
              },
              then: "Between 4 and 24 hours"
            },
            {
              case: { $gte: ["$metaData.duration", 24 * 60 * 60 * 1000] },
              then: "More than 24 hours"
            }
          ],
          default: "NAN"
        }
      },
      preTasks: [
        {
          $match: {
            "metaData.duration": { $exists: true }
          }
        }
      ]
    },
    categorizedMessageCount: {
      path: {
        $switch: {
          branches: [
            {
              case: { $lt: ["$metaData.totalMessages", 20] },
              then: "0-20"
            },
            {
              case: {
                $and: [
                  { $gte: ["$metaData.totalMessages", 20] },
                  { $lt: ["$metaData.totalMessages", 50] }
                ]
              },
              then: "20-50"
            },
            {
              case: { $gte: ["$metaData.totalMessages", 50] },
              then: "50+"
            }
          ],
          default: "NAN"
        }
      },
      preTasks: [
        {
          $match: {
            "metaData.totalMessages": { $exists: true }
          }
        }
      ]
    }
  },
  unwinds: {
    tags: {
      path: "tags",
      postTasks: []
    },
    members: {
      path: "members",
      postTasks: []
    }
  }
};
