import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import BraftEditor, {
  BuiltInControlType,
  ExtendControlType,
} from "braft-editor";
import "braft-editor/dist/index.css";
import { EditorState, KeyBindingUtil, getDefaultKeyBinding } from "draft-js";

import Markdown from "braft-extensions/dist/markdown";
import { defaultSuggestionsFilter } from "braft-extensions/dist/mention";
import { ContentUtils } from "braft-utils";

import { iPreset } from "@sdk/user-management/preset-state-model";
import { iCCMacro } from "@sdk/user-management/user-management.models";
import { Tag } from "antd";
import classnames from "classnames";
import { ActionIcons } from "components/modules/conversations/components/action-editor/actions";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { selectUser } from "store/modules/users/users.selectors";
import { AddEllipsis } from "utils/add-ellipsis";
import { uuidv4 } from "utils/generate-uuid";
import { useStateWithGetter } from "utils/hooks/use-state-with-getter";
import { UserAvatar } from "../avatar/avatar";
import "./draft-js-advanced.scss";
import { GenerateDraftMediaUploader } from "./helpers/braft-media-uploaded";
import {
  ActionShortcutsSuggestions,
  MessagePresetsSuggestions,
  UserMentionSuggestions,
  actionShortcuts,
  messagePresetsShortcuts,
  userMentions,
} from "./helpers/braft-mentions";
import { GenerateBraftChatExcludedControls } from "./helpers/chat-input-excluded-controls";
import { BraftMentionEntryComponentProps } from "./helpers/mention-default-entry-component";
import { BraftMessageVariableExtension } from "./helpers/message-variables";
import { replaceTrigger } from "./helpers/replace-trigger";
const { hasCommandModifier } = KeyBindingUtil;

BraftEditor.use(
  Markdown({
    includeEditors: ["rich"],
  }),
);
BraftEditor.use(userMentions);
BraftEditor.use(messagePresetsShortcuts);
BraftEditor.use(actionShortcuts);
BraftEditor.use(BraftMessageVariableExtension);

const emptyArray = [];

export const BraftMessageInput = memo(
  forwardRef(
    (
      {
        initialValue,
        onChange,
        onBlur,
        onFocus,
        conversationId,
        additionalExcludedControls,
        extendControls,
        onCommandActivated,
        mentions,
        actions,
        presets,
        editorId,
        noControls,
        onPressEnter,
        handlePastedFiles,
        placeholder,
        onActionSelected,
        onPresetSelected,
      }: {
        initialValue: string;
        onChange?: (html: string) => any;
        onBlur?: () => any;
        onFocus?: () => any;
        conversationId?: string;
        additionalExcludedControls?: BuiltInControlType[];
        extendControls?: ExtendControlType[];
        onCommandActivated?: (command: string) => any;
        extensions?: [];
        commandPallets?: [];
        mentions: any[];
        actions: any[];
        presets: any[];
        editorId?: string;
        noControls?: boolean;
        onPressEnter?: (e: any) => any;
        handlePastedFiles?: (filed: File[]) => any;
        placeholder?: string;
        onActionSelected?: (data: any) => any;
        onPresetSelected?: (data: any) => any;
      },
      ref,
    ) => {
      const initialEditorState = useMemo(() => {
        return BraftEditor.createEditorState(initialValue || "");
      }, [initialValue]);

      const draftEditorRef = useRef<any>();

      const [__editorState, setEditorState, getCurrentState] =
        useStateWithGetter(initialEditorState);

      useEffect(() => {
        if (onChange) {
          const htmlContent = __editorState.toHTML();
          onChange(htmlContent);
        }
      }, [__editorState, onChange]);

      const setHtmlContent = useCallback(
        (html: string) => {
          const editorState = BraftEditor.createEditorState(
            html || initialValue || "",
          );
          setEditorState(EditorState.moveFocusToEnd(editorState) as any);
        },
        [initialValue, setEditorState],
      );

      const getHtmlContent = useCallback(() => {
        const htmlContent = getCurrentState().toHTML();
        return htmlContent;
      }, [getCurrentState]);

      const focusInput = useCallback(() => {
        draftEditorRef.current?.getDraftInstance()?.focus();
      }, []);

      const getControlBarInstance = useCallback(() => {
        return draftEditorRef.current?.controlBarInstance;
      }, []);

      const insertHtmlToCursor = useCallback(
        (html: string) => {
          setEditorState(
            ContentUtils.insertHTML(getCurrentState(), html || ""),
          );
        },
        [getCurrentState, setEditorState],
      );

      useImperativeHandle(
        ref,
        () => ({
          setHtmlContent,
          getHtmlContent,
          setEditorState,
          getCurrentState,
          focusInput,
          insertHtmlToCursor,
          getControlBarInstance,
        }),
        [
          focusInput,
          getControlBarInstance,
          getCurrentState,
          getHtmlContent,
          insertHtmlToCursor,
          setEditorState,
          setHtmlContent,
        ],
      );

      const { doAction: uploadImage, isProcessing: isUploading } =
        useSDKActionWithDeps(
          () => ({
            action: (SDK) => (file) =>
              SDK.uploadFile(file, {
                type: conversationId ? "CONVERSATIONS" : "NEW_MESSAGE",
                entityId: conversationId || `BRAFT_${uuidv4()}`,
              })
                // .then(fileRecord => {
                //   // fileRecord.url
                // })
                .catch((e) => {
                  throw e;
                }),
            throwError: true,
          }),
          [conversationId],
        );

      const handleKeyCommand = useCallback(
        (command: string) => {
          if (command === "open-presets") {
            onCommandActivated && onCommandActivated(command);
            return "handled";
          }
          return "not-handled";
        },
        [onCommandActivated],
      );

      const braftKeyBindings = useCallback(
        function (e): string | null {
          if (e.keyCode === 191 /* `?/` key */ && hasCommandModifier(e)) {
            return "open-presets";
          }
          if (e.keyCode === 13) {
            const status = onPressEnter ? onPressEnter(e) : false;
            if (status) {
              return "handled";
            }
          }
          const defaultBinding = getDefaultKeyBinding(e);
          if (
            noControls &&
            ["bold", "code", "italic", "underline", "strikethrough"].includes(
              defaultBinding || "",
            )
          ) {
            return "handled";
          }

          switch (e.key) {
            case "Esc":
            case "Escape":
              return "escape";
            case "Tab":
              return "tab";
            case "ArrowUp":
            case "ArrowDown":
              return null;
            default:
              return defaultBinding;
          }
        },
        [noControls, onPressEnter],
      );

      const [userMentionKeyword, setUserMentionKeyword] = useState("");
      const [messagePresetKeyword, setMessagePresetKeyword] = useState("");
      const [actionKeyword, setActionKeyword] = useState("");

      const handleUserMentionFilter = useCallback(
        ({ value: mentionKeyword }) => {
          setUserMentionKeyword(mentionKeyword);
        },
        [],
      );

      const handleMessagePresetFilter = useCallback(
        ({ value: mentionKeyword }) => {
          setMessagePresetKeyword(mentionKeyword);
        },
        [],
      );
      const handleActionFilter = useCallback(({ value: mentionKeyword }) => {
        setActionKeyword(mentionKeyword);
      }, []);

      const mentionsSuggestions = useMemo(() => {
        return defaultSuggestionsFilter(userMentionKeyword, mentions);
      }, [mentions, userMentionKeyword]);

      const actionSuggestions = useMemo(() => {
        return allKeyFilter(actionKeyword, actions);
      }, [actionKeyword, actions]);

      const presetsSuggestions = useMemo(() => {
        return allKeyFilter(messagePresetKeyword, presets);
      }, [messagePresetKeyword, presets]);

      const draftProps = useMemo(() => ({ spellCheck: true }) as any, []);

      const onAddActin = useCallback(
        (d) => {
          onActionSelected && onActionSelected(d);
          const newEditorState = replaceTrigger(getCurrentState(), "\\", "");
          setEditorState(newEditorState as any);
        },
        [getCurrentState, onActionSelected, setEditorState],
      );

      const onAddPreset = useCallback(
        (d) => {
          const formattedValue = onPresetSelected && onPresetSelected(d);
          const newEditorState = replaceTrigger(
            getCurrentState(),
            "/",
            formattedValue || "",
          );
          setEditorState(newEditorState as any);
        },
        [getCurrentState, onPresetSelected, setEditorState],
      );

      const excludedControls = useMemo(
        () => GenerateBraftChatExcludedControls(additionalExcludedControls),
        [additionalExcludedControls],
      );

      return (
        <div
          className={classnames("draft-js-advanced top-controls", {
            "hide-controls": noControls,
          })}
        >
          <BraftEditor
            placeholder={placeholder || "Enter your message"}
            editorId={editorId}
            ref={draftEditorRef}
            value={__editorState}
            onChange={setEditorState}
            // onSave={this.submitContent}
            language={"en"}
            draftProps={draftProps}
            onBlur={onBlur}
            onFocus={onFocus}
            handleKeyCommand={handleKeyCommand}
            stripPastedStyles={noControls}
            {...{
              keyBindingFn: braftKeyBindings,
            }}
            controls={noControls ? emptyArray : undefined}
            excludeControls={excludedControls}
            extendControls={noControls ? emptyArray : extendControls}
            media={GenerateDraftMediaUploader(uploadImage)}
            handlePastedFiles={handlePastedFiles}
            // handleDroppedFiles={(file, file2) => {
            //   console.log("handleDroppedFiles", file, file2);
            //   return "handled";
            // }}
          />
          {editorId && (
            <MentionPlugins
              handleUserMentionFilter={handleUserMentionFilter}
              mentionsSuggestions={mentionsSuggestions}
              handleActionFilter={handleActionFilter}
              actionSuggestions={actionSuggestions}
              onAddActin={onAddActin}
              handleMessagePresetFilter={handleMessagePresetFilter}
              presetsSuggestions={presetsSuggestions}
              onAddPreset={onAddPreset}
            />
          )}

          {/* Mentions */}
        </div>
      );
    },
  ),
);

const MentionPlugins = memo(
  ({
    handleUserMentionFilter,
    mentionsSuggestions,
    handleActionFilter,
    actionSuggestions,
    onAddActin,
    handleMessagePresetFilter,
    presetsSuggestions,
    onAddPreset,
  }: any) => {
    return (
      <>
        {/* Mentions */}
        <UserMentionSuggestions
          onSearchChange={handleUserMentionFilter}
          suggestions={mentionsSuggestions}
          entryComponent={UserMentionEntryComponent}
          mentionPrefix={"CC_MENTION"}
        />
        {/* Actions */}
        <ActionShortcutsSuggestions
          onSearchChange={handleActionFilter}
          suggestions={actionSuggestions}
          entryComponent={ActionEntryComponent}
          mentionPrefix={"CC_ACTION"}
          onAddMention={onAddActin}
        />
        {/* Message Presets */}
        <MessagePresetsSuggestions
          onSearchChange={handleMessagePresetFilter}
          suggestions={presetsSuggestions}
          entryComponent={MessagePresetsEntryComponent}
          mentionPrefix={"CC_MESSAGE_PRESETS"}
          onAddMention={onAddPreset}
        />
      </>
    );
  },
);

const allKeyFilter = function (searchValue: string, items: any[]) {
  const value = searchValue.toLowerCase();
  const filteredSuggestions = items.filter(function (item) {
    return (
      !value ||
      Object.keys(item).some(
        (k) =>
          typeof item[k] === "string" &&
          String(item[k]).toLowerCase().includes(searchValue.toLowerCase()),
      )
    );
  });
  const length =
    filteredSuggestions.length < 5 ? filteredSuggestions.length : 5;
  return filteredSuggestions.slice(0, length);
};

const UserMentionEntryComponent = (props: BraftMentionEntryComponentProps) => {
  const {
    mention: item,
    theme,
    searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
    isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...parentProps
  } = props;

  const user = useSelector(selectUser(item.id));

  return (
    <div {...(parentProps as any)}>
      {user && (
        <div className="w-full flex flex-row">
          <UserAvatar userId={user.id} size={24} />
          <div className="label">{getUserName(user)}</div>
        </div>
      )}
    </div>
  );
};

const MessagePresetsEntryComponent = (
  props: BraftMentionEntryComponentProps,
) => {
  const {
    mention: item,
    theme,
    searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
    isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...parentProps
  } = props;

  const preset = item as iPreset & { folderLabel?: string };

  return (
    <div {...(parentProps as any)}>
      {preset && (
        <>
          {preset.id === "NOT_AVAILABLE" ? (
            <div className="flex flex-row">
              <div className="icon px-4">
                <i className="ri-error-warning-fill"></i>
              </div>
              <div className="label font-bold  px-2">
                Message Presets have not been set
              </div>
              <div className="description text-gray-600 flex-1  px-2">
                Click here to add message presets
              </div>
            </div>
          ) : (
            <>
              <div className="flex flex-row">
                <div className="icon px-4">
                  {preset.id !== "NOT_AVAILABLE" && (
                    <i className="ri-message-2-line"></i>
                  )}
                </div>
                <div className="label font-bold  px-2">
                  {AddEllipsis(preset.label, 30)}
                </div>
                <div className="description text-gray-600 flex-1  px-2">
                  {AddEllipsis(preset.value, 30)}
                </div>
                <div className="right">
                  {preset.folderLabel && <Tag>{preset.folderLabel}</Tag>}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const ActionEntryComponent = (props: BraftMentionEntryComponentProps) => {
  const {
    mention: item,
    theme,
    searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
    isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...parentProps
  } = props;

  const action = item as { id: string; label: string } | iCCMacro;

  return (
    <div {...(parentProps as any)}>
      {action && (
        <>
          {action.id === "NOT_AVAILABLE" ? (
            <div className="flex flex-row">
              <div className="icon px-4">
                <i className="ri-error-warning-fill"></i>
              </div>
              <div className="label font-bold  px-2">
                No Macros have been setup
              </div>
              <div className="description text-gray-600 flex-1  px-2"></div>
            </div>
          ) : (
            <>
              <div className="flex flex-row">
                <div className="icon px-4">
                  {ActionIcons[action.id] || <i className="ri-magic-line"></i>}
                </div>
                <div className="label font-bold  px-2">{action.label}</div>
                <div className="description text-gray-600 flex-1  px-2">
                  {action.label}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
