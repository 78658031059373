import { Divider } from "antd";
import { CollapsibleConfigurationSection } from "components/common/collapsible-configuration-panel/collapsible-configuration-container";
import { SectionHeader } from "components/common/section-header";
import { CRMTagsConfiguration } from "components/modules/organization-management/crm/crm-tags/crm-tags";

export const GeneralCRMSettings = () => {
  return (
    <div className="animated fadeInLeftMin">
      <SectionHeader
        title="General Settings"
        icon={<i className="ri-tools-line"></i>}
        description="Fine-tune your CRM"
      />
      <Divider />

      <CollapsibleConfigurationSection
        icon={"ri-price-tag-3-line"}
        title={"Customer Tags"}
        description={"Create & Manage available customer tags"}
      >
        <CRMTagsConfiguration />
      </CollapsibleConfigurationSection>
    </div>
  );
};
