import { Divider } from "antd";
import { CollapsibleConfigurationSection } from "components/common/collapsible-configuration-panel/collapsible-configuration-container";
import { SectionHeader } from "components/common/section-header";
import { ChannelNameForm } from "components/modules/connections/common-connection-settings/channel-name/channel-name-form";

import { ConnectionTaggingPreferencesEditor } from "components/modules/connections/common-connection-settings/connection-tagging-preference-editor/connection-tagging-preferences-editor";
import { RestrictAccessForm } from "components/modules/connections/common-connection-settings/restrict-access-form/restrict-access-form";
import { RoutingPreferencesForm } from "components/modules/connections/common-connection-settings/routing-preferences-form/routing-preferences-form";

import { useState } from "react";
import { ConnectionLimits } from "./connection-limits/connection-limits";
import { DeleteConnectionSettings } from "./delete-connection-settings/delete-connection-settings";
import { EmailNotificationsForm } from "./email-notifications-form/email-notifications-form";

export const GeneralConnectionConfig = ({
  connectionId,
}: {
  connectionId?: string;
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div className="animated fadeInLeftMin">
      <SectionHeader
        title="General Connection Settings"
        icon={<i className="ri-tools-line"></i>}
        description="Fine-tune this connection"
      />
      <Divider />

      {/* Name */}
      <ChannelNameForm connectionId={connectionId} />

      <EmailNotificationsForm connectionId={connectionId} />

      <RestrictAccessForm connectionId={connectionId} />
      {/* Routing Settings */}
      <RoutingPreferencesForm connectionId={connectionId} />
      <ConnectionTaggingPreferencesEditor connectionId={connectionId} />
      <CollapsibleConfigurationSection
        icon={"ri-git-commit-fill"}
        title={"User Limits"}
        description={"Prevent users from being assigned too many chats"}
      >
        <ConnectionLimits connectionId={connectionId!} />
      </CollapsibleConfigurationSection>

      <Divider />
      <DeleteConnectionSettings connectionId={connectionId!} />
    </div>
  );
};
