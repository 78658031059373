export class BrowserWakeUpHandler {
  static lastActiveTime = new Date().getTime();
  static oldTimer: any;
  static init(callbackFunction: (sleepTime: number) => any) {
    if (BrowserWakeUpHandler.oldTimer) {
      clearInterval(BrowserWakeUpHandler.oldTimer);
    }
    BrowserWakeUpHandler.oldTimer = setInterval(function() {
      var currentTime = new Date().getTime();
      if (currentTime > BrowserWakeUpHandler.lastActiveTime + 2000 * 2) {
        // ignore small delays
        // Probably just woke up!
        callbackFunction(
          currentTime - BrowserWakeUpHandler.lastActiveTime + 2000 * 2
        );
      }
      BrowserWakeUpHandler.lastActiveTime = currentTime;
    }, 2000);
  }
}
