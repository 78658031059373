export function textToHtml(
  text: string,
  options?: {
    omitPreTag?: boolean;
    ignoreSpaces?: boolean;
    ignoreTabs?: boolean;
  },
) {
  // Replace special characters with their corresponding HTML entities
  text = text
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");

  // Convert newlines to <br> tags
  text = text.replace(/\n/g, "<br>");

  // Convert spaces to &nbsp;
  if (!options?.ignoreSpaces) {
    text = text.replace(/ /g, "&nbsp;");
  }

  // Convert tabs to &nbsp;&nbsp;&nbsp;&nbsp;
  if (!options?.ignoreTabs) {
    text = text.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
  }

  // Wrap the text in a <pre> tag to preserve whitespace
  if (!options?.omitPreTag) {
    text = "<pre>" + text + "</pre>";
  }

  return text;
}
