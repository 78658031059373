import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iOrganizationAppData } from "@sdk/user-management/user-management.models";
import { Button, Divider, message, Space, Tag, Tooltip } from "antd";
import {
  EditableText,
  EditableTextArea
} from "components/common/editable-text/editable-text";
import { FilterBar } from "components/common/filter-schema/filter-bar";
import { iFilterOption } from "components/common/filter-schema/filter-config-schema";
import { RouterPrompt } from "components/common/router-prompt/router-prompt";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { ContactsExplorerModal } from "components/modules/crm/contacts/components/contacts-explorer-modal/contacts-explorer-modal";
import dayjs from "dayjs";

import copyToClipboard from "copy-to-clipboard";

import _ from "lodash";
import { AddCustomReport } from "modal-registry";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { useLocation } from "react-router";
import { DeepPartial } from "redux";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import { setOrganization } from "store/modules/workspace/workspace.actions";
import { selectCustomReports } from "store/modules/workspace/workspace.selectors";
import { uuidv4 } from "utils/generate-uuid";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { useURLData } from "utils/hooks/use-url-filter";
import { useViewRefresher } from "utils/hooks/use-view-refresher";
import { DashboardCardsContainer } from "../../../../common/draggable-cards-container/dashboard-cards-container";
import { ConversationsViewerNew } from "../../../../modules/conversations/components/conversations-explorer-modal/conversations-explorer-modal";
import { useReportFilterSchema } from "../../helpers/use-report-filter-schema";
import { ReportLabelRenderers } from "../../helpers/widget-label-mappers";
import { ConversationsReportsBackendSchema } from "../../schemas/backend-report-schemas/conversation-report-backend-schema";

import { GenerateQueryFromReportFilter } from "../../helpers/generate-query-from-report-filter";
import {
  ActivitiesReportCanvas,
  ConversationFlowReportCanvas,
  GeneralReportCanvas,
  LeadReportCanvas,
  MarketingReportCanvas,
  UsersReportCanvas
} from "../../schemas/standard-reports";
import { AddReportWidgetDrawer } from "../add-report-widget-drawer/add-report-widget-drawer";
import { EditReportWidgetDrawer } from "../edit-report-widget-drawer/edit-report-widget-drawer";
import { HighChartDarkTheme } from "../report-widget/components/high-charts-dark-theme";
import { DraggableReportWidget } from "../report-widget/report-widget";

import { BadgesController } from "badge-controller";
import { ActivitiesReportsBackendSchema } from "../../schemas/backend-report-schemas/activities-report-backend-schema";
import { ContactsReportsBackendSchema } from "../../schemas/backend-report-schemas/contacts-report-backend-schema";
import { ReportCanvasStateContext } from "./report-canvas-state-context";
import { iReportCanvas, iReportWidget } from "./widget-canvas-models";

export const ReportTypeLabels = {
  GENERAL: "General Report",
  CONVERSATION: "Conversation Report",
  LEADS: "Lead Report",
  ACTIVITIES: "Activities Report"
};

const defaultReportCanvas = {
  id: "default",
  title: "Empty Report",
  description: "Some Descriptions here",
  type: "CONVERSATION",
  layout: [],
  widgets: [],
  filters: [],
  dateField: "",
  createdAt: 0,
  createdBy: "",
  isShared: false
} as iReportCanvas;

const StandardReports = {
  overview: GeneralReportCanvas,
  "conversation-flow-report": ConversationFlowReportCanvas,
  "user-report": UsersReportCanvas,
  "marketing-report": MarketingReportCanvas,
  "lead-report": LeadReportCanvas,
  "activities-report": ActivitiesReportCanvas
};

export const ReportCanvas = () => {
  const location = useLocation();

  const reportId = useMemo(() => {
    if (location.pathname.includes("custom-reports")) {
      return location.pathname.split("/")[3]!;
    } else {
      return location.pathname.split("/")[2];
    }
  }, [location]);

  const customReports = useSelector(selectCustomReports);

  const reportCanvas = useMemo(() => {
    if (StandardReports[reportId]) {
      return StandardReports[reportId];
    }
    const report = _.find(customReports, { id: reportId });
    if (report) {
      return report;
    }
    return undefined;
  }, [reportId, customReports]);

  const { visible } = useViewRefresher(reportId);

  if (!visible || !reportCanvas) {
    return <></>;
  }

  // eslint-disable-next-line react/jsx-pascal-case
  return <_ReportCanvas originalReportCanvas={reportCanvas} />;
};

export const _ReportCanvas = ({
  originalReportCanvas
}: {
  originalReportCanvas: iReportCanvas;
}) => {
  const customReports = useSelector(selectCustomReports);

  const [reportCanvas, setReportCanvas] = useSimpleState(originalReportCanvas);

  useEffect(() => {
    setReportCanvas(originalReportCanvas);
  }, [originalReportCanvas, setReportCanvas]);

  const [_selectedFilters, _setSelectedFilters] = useURLData(
    "report-filter",
    // reportCanvas.filters as iFilterOption[],
    // Removing the saved filters
    [] as iFilterOption[]
  );

  const filterSchema = useReportFilterSchema(reportCanvas?.type);

  const selectedFilters = useMemo(() => {
    const dateFilter = _selectedFilters.find(item => item.key === "period");
    if (!dateFilter) {
      return [..._selectedFilters, filterSchema.generateDefaultFilter!()];
    }
    return _selectedFilters;
  }, [_selectedFilters, filterSchema?.generateDefaultFilter]);

  const setSelectedFilters = useCallback(
    (_filters: iFilterOption[]) => {
      const filters = [..._filters];
      const dateFilter = filters.find(item => item.key === "period");
      if (!dateFilter) {
        message.warning("Reports require date filter");
        return;
      }
      _setSelectedFilters(filters);
    },
    [_setSelectedFilters]
  );

  useEffect(() => {
    setReportCanvas({
      filters: selectedFilters
    });
  }, [selectedFilters, setReportCanvas]);

  const [isEditMode, setEditMode] = useState(reportCanvas.widgets.length === 0);

  const [isAddReportDrawerVisible, setAddReportDrawerVisibility] = useState(
    isEditMode
  );

  const [editReportWidgetState, setEditReportWidgetState] = useSimpleState({
    visible: false,
    initialState: (undefined as any) as iReportWidget
  });

  const isDarkModes = useSelector(selectIsDarkMode);

  useEffect(() => {
    if (isDarkModes) {
      const Highcharts = (window as any).Highcharts;
      if (Highcharts) {
        Highcharts.setOptions(HighChartDarkTheme as any);
      }
    } else {
      // Highcharts.setOptions(Highcharts.theme);
    }
  }, [isDarkModes]);

  const [conversationViewerState, setConversationViewerState] = useSimpleState({
    visible: false,
    filter: {}
  });

  const [contactsViewerState, setContactsViewerState] = useSimpleState({
    visible: false,
    filter: {}
  });

  const onConversationViewerVisibility = useCallback(
    visible =>
      setConversationViewerState({
        visible
      }),
    [setConversationViewerState]
  );

  const onContactsViewerVisibility = useCallback(
    visible =>
      setContactsViewerState({
        visible
      }),
    [setContactsViewerState]
  );

  const store = useStore();

  const onInspectSegment = useCallback(
    ({
      action,
      record,
      originalFilterConfig,
      reportConfig
    }: {
      action: "VIEW" | "INSPECT";
      record: any;
      originalFilterConfig: {
        filter: any;
        dateField: string;
        startDate: number;
        endDate: number;
      };
      reportConfig: iReportWidget;
    }) => {
      const recordFilter = (() => {
        let filterObj = { ..._.omit(record, "count") };
        for (const key in filterObj) {
          if (
            ReportLabelRenderers[key] &&
            ReportLabelRenderers[key].inspectorTransformer
          ) {
            filterObj = ReportLabelRenderers[key].inspectorTransformer!(
              filterObj,
              store,
              originalFilterConfig,
              reportConfig
            );
          }
        }
        return filterObj;
      })();
      if (action === "VIEW") {
        console.log("action", action, reportCanvas.type);
        // const filter = { ...originalFilterConfig.filter) };

        const ReportSchema = (() => {
          if (reportCanvas.type === "ACTIVITIES") {
            return ActivitiesReportsBackendSchema;
          } else if (reportCanvas.type === "LEADS") {
            return ContactsReportsBackendSchema;
          }
          return ConversationsReportsBackendSchema;
        })();
        const filter = GenerateQueryFromReportFilter(
          originalFilterConfig.filter,
          ReportSchema,
          true
        );

        filter[ReportSchema.dateFilterTypes[originalFilterConfig.dateField]] = {
          $gte: dayjs(originalFilterConfig.startDate).valueOf(),
          $lt: dayjs(originalFilterConfig.endDate).valueOf()
        };
        Object.assign(filter, recordFilter);

        if (reportCanvas.type === "LEADS") {
          setContactsViewerState({
            visible: true,
            filter: filter
          });
        } else if (reportCanvas.type === "CONVERSATION") {
          console.log("filter", filter, recordFilter);
          setConversationViewerState({
            visible: true,
            filter: filter
          });
        }
        BadgesController.processBadgeEvent("use-report-inspector");
      }
    },
    [
      reportCanvas?.type,
      setContactsViewerState,
      setConversationViewerState,
      store
    ]
  );

  const {
    doAction: onUpdateOrg,
    isProcessing,
    dispatch
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.editCurrentOrganization(values).then(res => {
          dispatch(setOrganization(res));
        }),
      successMessage: "Report has been saved",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const onSave = useCallback(async () => {
    setEditMode(false);
    await onUpdateOrg({
      appData: {
        REPORTS: {
          customReportViews: customReports.map(report => {
            if (report.id === reportCanvas.id) {
              return reportCanvas;
            }
            return report;
          })
        }
      }
    } as DeepPartial<iOrganizationAppData>);
  }, [customReports, onUpdateOrg, reportCanvas]);

  const discardChanges = useCallback(() => {
    setReportCanvas(originalReportCanvas);
  }, [originalReportCanvas, setReportCanvas]);

  const { triggerTempModal } = useModalPanels();

  return (
    <StyledScrollArea className="w-full h-full flex-1 animated fadeInLeftMin">
      <SimpleCardSection className="m-auto p-8 lg:p-12 my-4">
        <RouterPrompt when={isEditMode} />
        <div className="flex flex-row justify-center items-center">
          <Tag>
            {ReportTypeLabels[reportCanvas.type] || "Unknown Report Type"}
          </Tag>
        </div>
        <ReportCanvasStateContext.Provider value={{ state: reportCanvas }}>
          <div className="p-10">
            <div className="header flex flex-row items-center justify-between">
              {isEditMode && (
                <>
                  <div className="left flex flex-col flex-1">
                    <div className="text-2xl">
                      <EditableText
                        placeholder="General Report"
                        value={reportCanvas.title}
                        onSave={title => setReportCanvas({ title })}
                      />
                    </div>
                    <div className="text-gray-600">
                      <EditableTextArea
                        placeholder="Some Description of the project"
                        value={reportCanvas.description}
                        onSave={description => setReportCanvas({ description })}
                        rows={1}
                      />
                    </div>
                  </div>
                  <div className="actions px-4">
                    <Space>
                      <Tooltip title="Save Report">
                        <Button
                          icon={<i className="ri-save-2-line"></i>}
                          type="text"
                          onClick={() => onSave()}
                        ></Button>
                      </Tooltip>
                      <Tooltip title="Discard Changes">
                        <Button
                          icon={<i className="ri-eraser-line"></i>}
                          type="text"
                          onClick={() => discardChanges()}
                        ></Button>
                      </Tooltip>
                    </Space>
                  </div>
                </>
              )}
              {!isEditMode && (
                <>
                  <div className="left flex flex-col flex-1">
                    <div className="text-2xl">{reportCanvas.title}</div>
                    <div className="text-gray-600">
                      {reportCanvas.description}
                    </div>
                  </div>
                  <div className="actions px-4">
                    <Space>
                      {!reportCanvas.isNonEditable && (
                        <Tooltip title="Edit Report">
                          <Button
                            icon={<i className="ri-edit-line"></i>}
                            type="text"
                            onClick={() => setEditMode(true)}
                          ></Button>
                        </Tooltip>
                      )}

                      <Tooltip title="Share Report">
                        <Button
                          icon={<i className="ri-share-forward-line"></i>}
                          type="text"
                          onClick={() => {
                            copyToClipboard(window.location.href);
                            message.success(
                              "Sharable URL has been copied to clipboard"
                            );
                          }}
                        ></Button>
                      </Tooltip>
                      <Tooltip title="Clone Report">
                        <Button
                          icon={<i className="ri-file-copy-line"></i>}
                          type="text"
                          onClick={() => {
                            triggerTempModal(AddCustomReport, {
                              currentCanvas: reportCanvas
                            });
                          }}
                        ></Button>
                      </Tooltip>
                      {/* <Tooltip title="Schedule Report">
                        <Button
                          icon={<i className="ri-calendar-check-line"></i>}
                          type="text"
                        ></Button>
                      </Tooltip> */}
                    </Space>
                  </div>
                </>
              )}
            </div>
            <Divider className="m-0" />
            <div className="filters">
              <FilterBar
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                schema={filterSchema}
              ></FilterBar>
            </div>
            <Divider className="m-0" />

            <div className="body">
              <DashboardCardsContainer
                layouts={reportCanvas.layout}
                onLayoutChange={layout => setReportCanvas({ layout: layout })}
                layoutModifiable={isEditMode}
              >
                {reportCanvas.widgets.map(item => (
                  <DraggableReportWidget
                    data={item}
                    key={item.id}
                    onEditWidget={widgetId => {
                      const currentWidget = _.find(reportCanvas.widgets, {
                        id: widgetId
                      });
                      setEditReportWidgetState({
                        visible: true,
                        initialState: currentWidget
                      });
                    }}
                    onRemoveWidget={() => {
                      setReportCanvas({
                        widgets: _.without(reportCanvas.widgets, item)
                      });
                    }}
                    onInspectSegment={onInspectSegment}
                    isEditMode={isEditMode}
                  />
                ))}
              </DashboardCardsContainer>

              {isEditMode && (
                <div className="flex flex-row justify-center items-center my-16">
                  <Button
                    type="dashed"
                    size="large"
                    icon={<i className="ri-add-line"></i>}
                    onClick={() => {
                      setEditMode(true);
                      setAddReportDrawerVisibility(true);
                    }}
                  >
                    Add Report Widget
                  </Button>
                </div>
              )}
            </div>
            <AddReportWidgetDrawer
              isVisible={isAddReportDrawerVisible}
              onChangeVisibility={setAddReportDrawerVisibility}
              onAddWidget={widget => {
                setReportCanvas({
                  widgets: [
                    ...reportCanvas.widgets,
                    {
                      ...widget,
                      id: uuidv4()
                    }
                  ]
                });
                setAddReportDrawerVisibility(false);
              }}
              canvasType={reportCanvas.type}
            />

            <EditReportWidgetDrawer
              isVisible={editReportWidgetState.visible}
              onChangeVisibility={visibility =>
                setEditReportWidgetState({ visible: visibility })
              }
              initialState={editReportWidgetState.initialState}
              onEditWidget={widget => {
                setReportCanvas({
                  widgets: reportCanvas.widgets.map(item => {
                    if (item.id === widget.id) {
                      return widget;
                    }
                    return item;
                  })
                });
                setEditReportWidgetState({ visible: false });
              }}
            />

            <ConversationsViewerNew
              filter={conversationViewerState.filter}
              visible={conversationViewerState.visible}
              onChangeVisibility={onConversationViewerVisibility}
            />

            <ContactsExplorerModal
              filter={contactsViewerState.filter}
              visible={contactsViewerState.visible}
              onChangeVisibility={onContactsViewerVisibility}
            />
          </div>
        </ReportCanvasStateContext.Provider>
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
