import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Alert, Button, Descriptions, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { HelpCenterPromptButton } from "components/common/help-center/embeddable-components/help-center-prompt-button";
import {
  AddConnectionLayout,
  AddConnectionModalContainer,
  AddConnectionStepsContainer,
  IntegrationSuccessfulScreen,
  StepContainer,
} from "components/modules/connections/add-connection-modal-templates";
import { ConnectionIcons } from "components/modules/connections/helpers/connection-icons";
import { push } from "connected-react-router";
import { useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { UserTracker } from "user-tracker";

export const AddWACloudConnectionModal = ({
  visible,
  onChangeVisibility: _onChangeVisibility,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = useForm();
  const [createdConnection, setCreatedConnection] = useState("");

  const { doAction: completeIntegration, isProcessing: isActionProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (val) =>
          SDK.connections.create(val).then((d) => {
            setCreatedConnection(d.id!);
            setCurrentStep(2);
          }),
        successMessage: "Integration has been created",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const onChangeVisibility = (visibility: boolean) => {
    _onChangeVisibility(visibility);
    setCurrentStep(0);
  };

  const dispatch = useDispatch();
  const store = useStore();

  return (
    <>
      <AddConnectionModalContainer
        title="Connect Whatsapp"
        icon={<i className="ri-whatsapp-line"></i>}
        isVisible={visible}
        onChangeVisibility={onChangeVisibility}
      >
        <AddConnectionLayout
          currentStep={currentStep}
          totalSteps={2}
          stepsWithSidebars={[0, 1]}
          topBar={
            <AddConnectionStepsContainer
              currentStep={currentStep}
              steps={[
                {
                  label: "Pre-Requisites",
                  icon: <i className="ri-info-line"></i>,
                },
                {
                  label: "Setup Account",
                  icon: <i className="ri-whatsapp-line"></i>,
                },
                {
                  label: "Done",
                  icon: <i className="ri-check-line"></i>,
                },
              ]}
            />
          }
          sidebarImage={
            <div className="text-10xl opacity-50">
              <i className={ConnectionIcons.WHATS_APP_CLOUD}></i>
            </div>
          }
        >
          <StepContainer stepNumber={0} currentStep={currentStep}>
            <div className="description text-gray-600 dark:text-gray-400 mb-4 mode_transition">
              Connect WA Cloud API With your own Developer App
            </div>

            <Alert
              message={
                "This is a technical process. However, we will be releasing a simpler way to connect to WhatsApp for free in the next few weeks. Stay tuned as it will be a recommended method. We are becoming a tech provider with Meta :-)"
              }
              type="warning"
              showIcon
              className="mb-2"
            />

            <HelpCenterPromptButton
              type="BANNER"
              text="Learn to setup Facebook App to Connect WhatsApp Cloud API"
              description="It'll be a little technical."
              articleId="fc89e0-04ac8"
              // collectionId="fc89e0-d943b"
              className="mb-2"
            />

            <Descriptions
              title="Required Data"
              bordered
              layout="horizontal"
              column={1}
            >
              <Descriptions.Item label="Phone Number">
                Phone number you have configured in Facebook Developer App
              </Descriptions.Item>
              <Descriptions.Item label="Phone Number ID">
                The phone number ID for the number as obtained in your Developer
                App
              </Descriptions.Item>
              <Descriptions.Item label="Business Account ID">
                The Business Account ID for the number as obtained in your
                Developer App
              </Descriptions.Item>
              <Descriptions.Item label="Access Token">
                The Business Account User's access token for the number as
                obtained in your Business Manager
              </Descriptions.Item>
              <Descriptions.Item label="Webhook Verify Token">
                A random secret word that you will use when configuring the
                webhook
              </Descriptions.Item>
            </Descriptions>
            <div className="button-container my-4 flex flex-row justify-center items-center">
              <Button
                type="primary"
                loading={isActionProcessing}
                size="large"
                onClick={() => {
                  setCurrentStep(1);
                }}
                icon={<i className="ri-arrow-right-circle-fill"></i>}
              >
                Continue
              </Button>
            </div>
          </StepContainer>

          <StepContainer stepNumber={1} currentStep={currentStep}>
            <Form layout="vertical" form={form} initialValues={{}}>
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid WA Phone Number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Phone Number ID"
                name="phoneId"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid Phone Number ID",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Business Account ID"
                name="wabaId"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid Business Account ID",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Access Token"
                name="accessToken"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid Access Token",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Webhook Verify Token"
                name="webhookVerifyToken"
                rules={[
                  {
                    required: true,
                    message: "Please enter a Webhook Verify Token",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>

            <div className="flex flex-row justify-between items-center mt-4">
              <Button
                onClick={() => {
                  setCurrentStep(0);
                }}
                size="large"
                icon={<i className="ri-arrow-left-circle-fill"></i>}
              >
                Back
              </Button>
              <Button
                type="primary"
                loading={isActionProcessing}
                size="large"
                onClick={async () => {
                  try {
                    await form.validateFields();
                    const formValues = form.getFieldsValue();

                    completeIntegration({
                      type: "WHATS_APP_CLOUD",
                      label: `WA - ${formValues.phoneNumber}`,
                      data: {
                        ...formValues,
                      },
                    });
                    UserTracker.track("Add WA Cloud Connection", {
                      ...formValues,
                    });
                  } catch (e) {
                    message.warning("Please check your input");
                  }
                }}
                icon={<i className="ri-arrow-right-circle-fill"></i>}
              >
                Continue
              </Button>
            </div>
          </StepContainer>

          <StepContainer stepNumber={2} currentStep={currentStep}>
            <IntegrationSuccessfulScreen
              onManageConnection={() => {
                dispatch(
                  push(`/configurations/connections/${createdConnection}/`),
                );
              }}
              onHideWizard={() => onChangeVisibility(false)}
              subTitle="Now you will be able to reply to WhatsApp messages from Click Connector"
              title="Your Integration is ready!"
            >
              <Alert
                message={`Your Whatsapp Webhook URL: https://engine.clickconnector.app/api/v1/whatsapp-cloud/webhook?connectionId=${createdConnection}`}
                type="info"
                showIcon
                className="mb-2"
              />
            </IntegrationSuccessfulScreen>
          </StepContainer>
        </AddConnectionLayout>
      </AddConnectionModalContainer>
    </>
  );
};
