import { Alert, Form } from "antd";
import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React, { Suspense, useState } from "react";

import { useSelector } from "react-redux";
import { selectShouldEnforceStrongPassword } from "store/modules/workspace/workspace.selectors";

const PasswordStrengthBar = React.lazy(
  () => import("react-password-strength-bar"),
);

export const PasswordStrengthIndicators = ({
  fieldName = ["password"],
}: {
  fieldName?: string[];
}) => {
  const shouldEnforceStrongPassword = useSelector(
    selectShouldEnforceStrongPassword,
  );

  const [score, setScore] = useState(0);

  const [feedback, setFeedback] = useState<{
    warning?: string;
    suggestions?: string[];
  }>({});

  return (
    <>
      {shouldEnforceStrongPassword && (
        <Form.Item
          shouldUpdate
          noStyle
          rules={[
            ({ getFieldValue }) => ({
              validator() {
                const password = getFieldValue(fieldName);
                if (score < 3) {
                  return Promise.reject(
                    new Error("Password is not strong enough"),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        ></Form.Item>
      )}

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const password = getFieldValue(fieldName);
          return (
            <>
              <Suspense fallback={<SuspenseLoadingIndicator />}>
                <PasswordStrengthBar
                  password={password}
                  onChangeScore={(score, feedback) => {
                    setFeedback(feedback);
                    setScore(score);
                  }}
                />
              </Suspense>

              {shouldEnforceStrongPassword &&
                score < 3 &&
                password.length >= 1 && (
                  <Alert
                    type="error"
                    message="Password is not strong enough"
                    className="my-2"
                  />
                )}

              {feedback.warning && (
                <Alert
                  className="my-2"
                  type="warning"
                  message={feedback.warning}
                />
              )}
              {feedback.suggestions && feedback.suggestions.length > 0 && (
                <Alert
                  className="my-2"
                  type="info"
                  message={`${feedback.suggestions.join(`\n`)}`}
                />
              )}
            </>
          );
        }}
      </Form.Item>
    </>
  );
};
