import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iShopifyOrder } from "@sdk/shopify/shopify.service";
import { Checkbox, Form, Modal, Radio, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useMemo } from "react";

export const cancellationReasons = [
  {
    label: "Customer changed/cancelled order",
    value: "CUSTOMER",
  },
  {
    label: "Fraudulent order",
    value: "FRAUD",
  },
  {
    label: "Items unavailable",
    value: "INVENTORY",
  },
  {
    label: "Payment declined",
    value: "DECLINED",
  },
  {
    label: "Other",
    value: "OTHER",
  },
];

export const CancelShopifyOrderModal = ({
  visible,
  onChangeVisibility,
  onCancelled,
  customerId,
  order,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCancelled?: () => any;
  customerId: string;
  order: iShopifyOrder;
}) => {
  const [form] = useForm();

  const { doAction: cancelOrder, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (orderId, req) =>
        SDK.shopify.cancelAnOrder(orderId, req).then((res) => {
          onCancelled && onCancelled();
          form.resetFields();
          onChangeVisibility(false);
          form.resetFields();
        }),
      successMessage: "Order has been cancelled",
      failureMessage: "Something went wrong",
    }),
    [form, onChangeVisibility, onCancelled],
  );

  const currentOrderValue = useMemo(() => {
    return order?.current_total_price_set?.shop_money;
  }, [order?.current_total_price_set?.shop_money]);

  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      const { refund, reason, notification } = form.getFieldsValue();
      cancelOrder(order.id, {
        amount: refund === "NO_REFUND" ? undefined : order.current_total_price,
        reason: (reason as string).toLowerCase(),
        email: Boolean(notification),
      });
    } catch (e) {
      message.error("Please check your input");
    }
  }, [cancelOrder, form, order?.current_total_price, order?.id]);

  return (
    <>
      <Modal
        title={
          <ModalTitle
            icon={<i className="ri-close-line"></i>}
            title={"Cancel Order"}
          />
        }
        open={visible}
        onOk={onSubmit}
        onCancel={() => {
          onChangeVisibility(false);
          form.resetFields();
        }}
        okText={
          <>
            <i className="ri-close-line"></i> Cancel Order
          </>
        }
        cancelText="Keep Order"
        okButtonProps={{ loading: isProcessing }}
        data-click-context="Create Order Modal"
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          className="bold-form-labels"
        >
          {(order?.financial_status === "paid" ||
            order?.financial_status === "partially_refunded") && (
            <Form.Item name="refund" label="Refund Payment" className="w-full">
              <Radio.Group
                defaultValue="FULL_REFUND"
                className="flex flex-col gap-4"
              >
                <Radio value="FULL_REFUND">
                  Refund {currentOrderValue?.currency_code}{" "}
                  {currentOrderValue?.amount}
                </Radio>
                <Radio value="NO_REFUND">Refund Later</Radio>
              </Radio.Group>
            </Form.Item>
          )}

          <Form.Item
            label="Reason for cancellation"
            name={["reason"]}
            rules={[{ required: true, message: "Missing reason" }]}
          >
            <Select
              placeholder="Select a reason"
              options={cancellationReasons}
            ></Select>
          </Form.Item>

          <Form.Item
            name="notification"
            label="Notification"
            valuePropName="checked"
          >
            <Checkbox>Send a notification to the customer</Checkbox>
          </Form.Item>
        </Form>

        <DarkModeBg />
      </Modal>
    </>
  );
};
