import { LazyJSONViewer } from "@libs/lazy-json-viewer/lazy-json-viewer";
import {
  iMessage,
  iMessageSenderType,
} from "@sdk/conversations/conversations.models";
import { Button, Dropdown, Modal, Tooltip } from "antd";
import classNames from "classnames";
import { ModalTitle } from "components/common/modal-title";
import { PrintUserNameById } from "components/modules/user-management/helpers/get-user-name-by-id";
import { memo, useCallback, useMemo, useState } from "react";

import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import { LazyMarkdownPreview } from "components/pages/knowledge-kit-page/query-knowledge-playground/lazy-mark-down-preview";
import dayjs from "dayjs";
import { AddTask, MagicAssistant, OpenAiAssistant } from "modal-registry";
import Linkify from "react-linkify";
import { useSelector } from "react-redux";
import { selectConversationProperties } from "store/modules/conversations/conversations.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { useSelectorWithMemoize } from "store/utils/use-selector-with-memoize";
import { GlobalEventEmitter } from "utils/global-event-emitter";
import { PDFDrawer } from "./components/pdf-drawer";
import { MessageAttachments } from "./message-attachment";
import "./message-list.scss";
import { HtmlEmailRenderer } from "./rich-messsage-list";

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noreferrer">
    {text}
  </a>
);

export const MessagesListItem = memo(
  ({
    message,
    index,
    forwardMessage,
    ignoreTasks,
    ignoreAttachments,
    conversationId,
    shouldShowContactAvatar,
    isFirstMessageOfGroup,
    isLastMessageOfGroup,
    hideActions,
    useMarkdownRender,
  }: {
    message: iMessage;
    index: number;
    forwardMessage?: (message: iMessage) => any;
    ignoreTasks?: boolean;
    ignoreAttachments?: boolean;
    conversationId: string;
    shouldShowContactAvatar: boolean;
    isFirstMessageOfGroup: boolean;
    isLastMessageOfGroup: boolean;
    hideActions?: boolean;
    useMarkdownRender?: boolean;
  }) => {
    const isAdvancedMode = useSelector(selectIsAdvancedMode);
    const [PDFDrawerVisible, setPDFDrawerVisible] = useState(false);
    const [PDFFileUrl, setPDFFileUrl] = useState("");

    const { contactId, clientReadWaterMark } = useSelectorWithMemoize(
      () =>
        selectConversationProperties(conversationId, (conversation) => ({
          contactId: conversation?.contactId,
          clientReadWaterMark: conversation?.metaData?.readWatermarks?.client,
        })),
      [conversationId],
      true,
    );

    const showPDFDrawer = (url) => {
      setPDFFileUrl(url);
      setPDFDrawerVisible(true);
    };
    const onPDFDrawerClose = () => {
      setPDFFileUrl("");
      setPDFDrawerVisible(false);
    };
    const viewOriginalEmail = useCallback((message: iMessage) => {
      // const win = window.open("", data.subject, "location=no");
      // if (win) {
      //   win.document.documentElement.innerHTML = data["stripped-html"];
      // }
      Modal.info({
        title: (
          <ModalTitle
            title={message?.data?.subject || "Original Email"}
            icon={<i className="ri-bill-line"></i>}
          />
        ),
        icon: false,
        content: (
          <div style={{ width: 750, height: 600, overflow: "auto" }}>
            <HtmlEmailRenderer message={message} />
          </div>
        ),
        okText: "Close",
        width: 800,
      });
    }, []);

    const viewEmailReceipt = useCallback((data) => {
      // const win = window.open("", data.subject, "location=no");
      // if (win) {
      //   win.document.documentElement.innerHTML = JSON.stringify(data, null, 2);
      // }
      Modal.info({
        title: (
          <ModalTitle
            title={data?.subject || "Receipt"}
            icon={<i className="ri-bill-line"></i>}
          />
        ),
        icon: false,
        content: (
          <div
            style={{
              width: 750,
              height: 600,
              overflow: "auto",
              lineBreak: "anywhere",
            }}
          >
            <LazyJSONViewer src={data || {}} collapsed={false} />
          </div>
        ),
        okText: "Close",
        width: 800,
      });
    }, []);

    const attachments = useMemo(() => {
      return (message.attachments || []).filter(
        (attachment) =>
          attachment.type !== "QUICK_REPLIES" &&
          attachment.type !== "SELECTED_QUICK_REPLY",
      );
    }, [message]);
    const user = useSelector(selectCurrentUser);

    const { triggerTempModal } = useModalPanels();

    if (message.isNotes) {
      return (
        <div className="notes-message-container m-4">
          <div className="border border-gray-200 shadow-lg p-4">
            <div className="title font-bold text-gray-600">
              Note by <PrintUserNameById userId={message.from.id} />
            </div>
            <div className="body">{message.message}</div>
          </div>
          <MessageAttachments
            attachments={message.attachments}
            showPDFDrawer={showPDFDrawer}
            ignoreTasks={ignoreTasks}
          />
        </div>
      );
    }

    return (
      <>
        <PDFDrawer
          PDFDrawerVisible={PDFDrawerVisible}
          onPDFDrawerClose={onPDFDrawerClose}
          PDFFileUrl={PDFFileUrl}
        />
        <div
          className={classNames([
            "message-row",
            {
              me: message.from.senderType === "USER",
              contact: message.from.senderType === "CLIENT",
              system: message.from.senderType === "SYSTEM",
              campaign: message.from.senderType === "CAMPAIGN",
              "first-of-group": isFirstMessageOfGroup,
              "last-of-group": isLastMessageOfGroup,
            },
          ])}
          key={index.toString()}
        >
          {shouldShowContactAvatar && (
            <div className="avatar avatar-cont">
              <ContactAvatar contactId={contactId!} hideMedium={true} />
            </div>
          )}
          {message.message !== "📎" && (
            <div
              className={classNames(
                "bubble",
                message.from.senderType === "USER" &&
                  message.from.id !== user?.id &&
                  " bg-blue-500",
              )}
            >
              <Tooltip
                title={
                  <>
                    {message.from.senderType === "USER" ? (
                      <>
                        <PrintUserNameById userId={message.from.id} />:{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {dayjs(message.metaData.sentAt).format(
                      "dddd, h:mm:ss a - MMMM Do YYYY",
                    )}
                  </>
                }
                mouseEnterDelay={1.5}
              >
                <div className="message">
                  {!hideActions &&
                    (message.from.senderType === "USER" ||
                      message.from.senderType === "CLIENT") && (
                      <div
                        className="message-item-menu"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Dropdown
                          trigger={["click"]}
                          menu={{
                            items: [
                              {
                                key: "MagicAssistant",
                                icon: <i className="ri-magic-line"></i>,
                                label: "Process with Magic Assistant",
                                onClick: () => {
                                  triggerTempModal(MagicAssistant, {
                                    query: message.message,
                                  });
                                },
                              },
                              {
                                key: "CHAT_GPT",
                                icon: <i className="ri-magic-line"></i>,
                                label: "Process with Chat GPT",
                                onClick: () => {
                                  triggerTempModal(OpenAiAssistant, {
                                    input: message.message,
                                    instruction: "HELPFUL_ASSISTANT",
                                  });
                                },
                              },
                              {
                                key: "TASK",
                                icon: <i className="ri-task-line"></i>,
                                label: "Create a Task",
                                onClick: () => {
                                  triggerTempModal(AddTask, {
                                    conversationId: message.conversationId,
                                    messageId: message.id,
                                  });
                                },
                              },
                              ...(message.connectionType === "WEB_CHAT"
                                ? [
                                    {
                                      key: "REPLY",
                                      icon: <i className="ri-reply-fill"></i>,
                                      label: "Reply",
                                      onClick: () => {
                                        GlobalEventEmitter.emit(
                                          "REPLY_MESSAGE",
                                          {
                                            conversationId:
                                              message.conversationId,
                                            contactId: message.contactId,
                                            messageId: message.id,
                                          },
                                        );
                                      },
                                    },
                                  ]
                                : []),
                            ],
                          }}
                        >
                          <Button
                            icon={<i className="ri-more-2-line"></i>}
                            type="text"
                          />
                        </Dropdown>
                      </div>
                    )}

                  {useMarkdownRender ? (
                    <LazyMarkdownPreview
                      source={message.message}
                      wrapperElement={{
                        "data-color-mode": "dark",
                      }}
                    />
                  ) : (
                    <Linkify componentDecorator={componentDecorator}>
                      {message.message}
                    </Linkify>
                  )}

                  {message?.data?.translatedText && (
                    <div className="text-sm mt-4">
                      Translated: {message.data.translatedText}
                    </div>
                  )}
                  {message?.data?.customerReaction && (
                    <div
                      className={classNames([
                        "message-reaction absolute",
                        {
                          "left-0": message.from.senderType === "USER",
                          "right-0": message.from.senderType === "CLIENT",
                        },
                      ])}
                    >
                      {message?.data?.customerReaction}
                    </div>
                  )}
                  {message.connectionType === "EMAIL" &&
                    message.from.senderType !== iMessageSenderType.SYSTEM && (
                      <div className="email-actions">
                        {/* // eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={() => viewOriginalEmail(message)}>
                          View Original
                        </a>
                        |{" "}
                        {/* // eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        {}
                        <a
                          onClick={() =>
                            viewEmailReceipt((message as any).data)
                          }
                        >
                          View Email Receipt
                        </a>
                        {/* Todo */}
                        {/* <a onClick={() => forwardMessage(message)}>
                        Forward this message
                      </a> */}
                      </div>
                    )}
                </div>
              </Tooltip>
              {message.attachments.length === 0 && (
                <div className="time">
                  {message.from.senderType === "USER" &&
                    (clientReadWaterMark || 0) >= message.metaData.sentAt && (
                      <i className="ri-check-double-line mr-2 text-lg read-indicator"></i>
                    )}
                  {message.from.senderType === "USER" &&
                    (clientReadWaterMark || 0) < message.metaData.sentAt && (
                      <i className="ri-check-line mr-2 text-lg  read-indicator"></i>
                    )}
                  {dayjs(message.metaData.sentAt).format("h:mm:ss a LL")}
                </div>
              )}
            </div>
          )}

          {!ignoreAttachments && attachments && attachments.length > 0 && (
            <div className="attachments-cont">
              <MessageAttachments
                attachments={message.attachments}
                showPDFDrawer={showPDFDrawer}
                ignoreTasks={ignoreTasks}
              />
              <div className="time secondary-text mat-caption">
                {message.from.senderType === "USER" &&
                  (clientReadWaterMark || 0) >= message.metaData.sentAt && (
                    <i className="ri-check-double-line mr-2 text-lg read-indicator"></i>
                  )}
                {message.from.senderType === "USER" &&
                  (clientReadWaterMark || 0) < message.metaData.sentAt && (
                    <i className="ri-check-line mr-2 text-lg read-indicator"></i>
                  )}
                {dayjs(message.metaData.sentAt).format("h:mm:ss a LL")} |{" "}
              </div>
            </div>
          )}
        </div>
      </>
    );
  },
);
