import { iHolidayQuote } from "@sdk/holiday-quotes/holiday-quotes-model";
import { ItineraryBlockTypeMap, iPriceSummaryData } from "../itineries-model";
import { WhatsIncludedBlock } from "./whats-included-block";

export const PriceSummaryBlock = ({
  data,
  holidayQuote
}: {
  data: iPriceSummaryData;
  holidayQuote: iHolidayQuote;
}) => {
  const background = "#0166FF";
  return (
    <div className="flex flex-row">
      <div className="flex-1">
        <div className="flex flex-row justify-start items-center">
          <div className="icon text-4xl mr-2">
            {ItineraryBlockTypeMap["WHATS_INCLUDED"].icon}
          </div>
          <div className="label text-2xl font-bold">
            {ItineraryBlockTypeMap["WHATS_INCLUDED"].label}:
          </div>
        </div>
        <WhatsIncludedBlock data={data} holidayQuote={holidayQuote} />
      </div>
      <div className="flex-1">
        {/*  eslint-disable-next-line react/jsx-pascal-case */}
        <_PriceSummaryBlock data={data} />
      </div>
    </div>
  );
};

export const _PriceSummaryBlock = ({ data }: { data: iPriceSummaryData }) => {
  const background = "#0166FF";
  return (
    <div
      className="text-white rounded-lg w-full p-4 flex flex-col"
      style={{ background }}
    >
      <div className="text-xl font-bold">Price Summary:</div>
      <div className="text-2xl">
        {data.currencySymbol || data.currency} {data.price}
      </div>
      <div className="mt-2">{data.discountText}</div>
    </div>
  );
};
