import { useMemo } from "react";

export const GoogleMapImage = ({
  center,
  size = "900x400",
  zoom = 13,
  marker,
  mapType = "roadmap"
}: {
  center: string;
  size?: string;
  mapType?: string;
  zoom?: number;
  marker?: string;
}) => {
  // API https://developers.google.com/maps/documentation/maps-static/start
  const url = useMemo(() => {
    const markersStr = marker ? `&markers=color:red%7C${marker}` : "";
    return `https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=${zoom}&size=${size}&maptype=${mapType}${markersStr}&key=AIzaSyDgKBIsjuhnfxjV-7Vfv8q7P59AWTVopLo`;
  }, [center, mapType, marker, size, zoom]);
  return <img src={url} alt="Maps" />;
};
