import { iEmailEditorTypes } from "@sdk/campaigns/campaigns-model";

import { message, Modal, Tag, Tooltip } from "antd";
import { ExtendControlType } from "braft-editor";
import { defaultAdditionalExcludedBraftControllers } from "components/common/draft-js-advanced/default-excluded-braft-controllers";
import { BraftJSInput } from "components/common/draft-js-advanced/draft-js-advanced";
import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { AvailableCampaignTokenTable } from "components/modules/conversations/components/chat-input/available-message-tokens";
import { DarkModeBg } from "dark-mode-bg";
import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import { cleanHtmlForSimpleEmail } from "utils/clean-html-for-plain-email";
import "./sequence-email-editor-modal.scss";

const EasyEmail = React.lazy(
  () => import("../../campaigns/easy-email/easy-email"),
);

const CodeEditor = React.lazy(() => import("@uiw/react-textarea-code-editor"));

const SimpleEmailCreator = React.lazy(
  () => import("../../campaigns/simple-email-creator/simple-email-creator"),
);

const defaultHtmlBody = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    
</body>
</html>`;

export interface iEmailEditorData {
  body: string;
  emailEditorType?: iEmailEditorTypes;
  advancedEmailPageData?: any;
  simpleMailBlocks?: any;
}

export const SequenceEmailEditorModal = ({
  visible,
  onChangeVisibility,
  data,
  onSave,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  data: iEmailEditorData;
  onSave: (data: iEmailEditorData) => any;
}) => {
  const [code, setCode] = useState(defaultHtmlBody);
  const [text, setText] = useState("");
  const draftJsRef = useRef<any>();

  const isDarkMode = useSelector(selectIsDarkMode);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.setAttribute("data-color-mode", "dark");
    } else {
      document.documentElement.setAttribute("data-color-mode", "light");
    }
  }, [isDarkMode]);

  const easyEmailRef = useRef<any>();
  const simpleEmailRef = useRef<any>();

  useEffect(() => {
    if (!data) {
      return;
    }
    switch (data.emailEditorType) {
      case "HTML": {
        setCode(data.body || defaultHtmlBody);
        return;
      }
      case "SIMPLE_TEXT_EMAIL": {
        draftJsRef.current.setHtmlContent(data.body || "");
        return;
      }
      case "SIMPLE_EMAIL": {
        return;
      }
      case "ADVANCED_EMAIL_EDITOR": {
        return;
      }
    }
  }, [data]);

  const extendControls = useMemo(() => {
    const extendControls: ExtendControlType[] = [
      {
        key: "message-presets",
        type: "modal",
        text: (
          <Tooltip title="Message Personalization">
            <i className="ri-braces-line"></i>
          </Tooltip>
        ),
        modal: {
          id: "message-personalization-modal",
          title: (
            <span>
              <ModalTitle
                title="Message Personalization"
                icon={<i className="ri-braces-line"></i>}
              />
            </span>
          ) as any,
          showFooter: false,
          closeOnBlur: true,
          className: "message-personalization-modal",
          children: (
            <div style={{ width: 400, padding: "0 10px" }}>
              <div className="my-4">
                You can use variable tokens in your messages
                <div className="font-bold my-4">Available Tokens:</div>
                <table className="simple-table-style w-full">
                  <thead>
                    <tr>
                      <th className="font-bold">Variable</th>
                      <th className="font-bold">Token</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AvailableCampaignTokenTable.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.label}</td>
                          <td>
                            <Tag
                              onClick={() => {
                                draftJsRef.current.insertHtmlToCursor(
                                  `<span class="variable-token-item">{${item.token}}</span>`,
                                );
                                const cb =
                                  draftJsRef.current.getControlBarInstance();
                                cb?.extendedModals?.[
                                  "message-personalization-modal"
                                ].close();
                              }}
                              className="cursor-pointer"
                            >
                              {item.token}
                            </Tag>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ),
        },
      },
    ];
    return extendControls;
  }, []);

  return (
    <Modal
      title={
        <ModalTitle
          icon={<i className="ri-mail-send-fill"></i>}
          title="Setup Email"
        />
      }
      open={visible}
      onOk={async () => {
        try {
          switch (data.emailEditorType) {
            case "SIMPLE_TEXT_EMAIL": {
              const html = draftJsRef.current.getHtmlContent();
              onSave({
                body: html,
                emailEditorType: data.emailEditorType,
              });
              onChangeVisibility(false);
              return;
            }
            case "HTML": {
              onSave({
                body: code,
                emailEditorType: data.emailEditorType,
              });
              onChangeVisibility(false);
              return;
            }
            case "SIMPLE_EMAIL": {
              const blocks = simpleEmailRef.current.blocks;
              const html = simpleEmailRef.current.getHtml();
              // console.log("html", html, blocks);
              onSave({
                body: html,
                simpleMailBlocks: blocks,
                emailEditorType: data.emailEditorType,
              });
              onChangeVisibility(false);
              return;
            }
            case "ADVANCED_EMAIL_EDITOR": {
              const pageData = easyEmailRef.current.getValues();
              const html = easyEmailRef.current.getHtml();
              // console.log("html", html, pageData);
              onSave({
                body: html,
                advancedEmailPageData: pageData,
                emailEditorType: data.emailEditorType,
              });
              onChangeVisibility(false);
              return;
            }
          }
          //
        } catch (e) {
          message.error("Please check your input");
        }
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okButtonProps={{ icon: <i className="ri-check-line"></i> }}
      okText="Save Email"
      data-click-context="Create New Campaign"
      width={data?.emailEditorType === "SIMPLE_TEXT_EMAIL" ? 680 : "100%"}
      className={
        data?.emailEditorType === "ADVANCED_EMAIL_EDITOR"
          ? "advanced-email-editor-modal"
          : "sequence-email-editor-modal"
      }
    >
      {data && (
        <div className="editor">
          {data.emailEditorType === "ADVANCED_EMAIL_EDITOR" && (
            <Suspense
              fallback={
                <SuspenseLoadingIndicator
                  style={{ height: "calc(100vh - 240px) !important" }}
                />
              }
            >
              <EasyEmail
                editorRef={easyEmailRef}
                initialValues={data.advancedEmailPageData}
              />
            </Suspense>
          )}
          {data.emailEditorType === "SIMPLE_EMAIL" && (
            <Suspense fallback={<SuspenseLoadingIndicator />}>
              <SimpleEmailCreator
                ref={simpleEmailRef}
                initialValue={data.simpleMailBlocks}
              />
            </Suspense>
          )}
          {data.emailEditorType === "HTML" && (
            <div
              style={{ height: "calc(100vh - 240px)" }}
              className="overflow-auto"
            >
              <Suspense fallback={<SuspenseLoadingIndicator />}>
                <CodeEditor
                  value={code}
                  language="html"
                  placeholder="Enter HTML for your email"
                  onChange={(evn) => setCode(evn.target.value)}
                  padding={15}
                  style={{
                    fontSize: 12,
                    fontFamily:
                      "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                    height: "calc(100vh - 240px)",
                    overflow: "auto",
                  }}
                />
              </Suspense>
            </div>
          )}
          {data.emailEditorType === "SIMPLE_TEXT_EMAIL" && (
            <div>
              <div className="text-gray-600 p-2">Draft your email:</div>

              <div className="draft-js-container border border-gray-200 dark:border-gray-700 rounded-lg">
                <BraftJSInput
                  initialValue={data?.body}
                  ref={draftJsRef}
                  additionalExcludedControls={
                    defaultAdditionalExcludedBraftControllers
                  }
                  extendControls={extendControls}
                  onChange={(html) => {
                    const text = cleanHtmlForSimpleEmail(html);
                    // const text = convertHtmlToPlainTextWithLineBreaks(html);
                    setText(text.trim());
                  }}
                  showControlsOnTop={true}
                />
              </div>

              <div className="footer p-4 text-gray-600">
                {text.length} Characters
              </div>
            </div>
          )}
        </div>
      )}
      <DarkModeBg />
    </Modal>
  );
};
