import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { useSDKWithRemotePagination } from "@sdk/sdk.hooks";
import { Button, Divider, List, Pagination } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import "./knowledge-query-logs-viewer.scss";

const allDocumentsQuery = {};

export const KnowledgeQueryLogsViewer = ({
  hideTitle,
}: {
  hideTitle?: boolean;
}) => {
  const isAdvancedMode = useSelector(selectIsAdvancedMode);
  const { changePanelState } = useModalPanels();

  const {
    paginationState,
    setPaginationState,
    data: itemsInView,
    setData: setItemsInView,
    isLoading,
    reload,
  } = useSDKWithRemotePagination(
    (SDK) => (query, options) =>
      SDK.knowledgeDocuments.querySearchLogs({ query, options }),
    {},
    [],
    {
      paginationOptions: {
        sort: "-createdAt",
      },
    },
  );

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin manage-chat-bots">
      <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4">
        {!hideTitle && (
          <>
            <SectionHeader
              title="Knowledge Query Logs"
              icon={<i className="ri-folder-5-line"></i>}
              description="Review all queries processed by the Knowledge Kit"
              addons={<></>}
            />
            <Divider />
          </>
        )}
        <div className="flex flex-row justify-between items-center mb-4">
          <div className="text-gray-600">{paginationState.totalItems} Logs</div>

          <Button
            type="link"
            onClick={() => reload()}
            loading={isLoading}
            icon={<i className="ri-refresh-line"></i>}
          >
            Reload
          </Button>
        </div>
        <List
          className="comment-list"
          itemLayout="horizontal"
          dataSource={itemsInView}
          loading={
            isLoading && {
              indicator: <LoadingIndicatorWithoutSpin />,
            }
          }
          renderItem={(item) => (
            <div className="flex flex-col mb-2 border-b border-gray-200 dark:border-gray-800 p-4 hover:bg-gray-100 dark:hover:bg-gray-900">
              <div className="font-bold">Query:{item.query}</div>
              <div className="text-gray-600">
                Response: {item.response}
                <br /> Generated from ({item.matchedDocuments.length}) docs
              </div>
              <div className="text-gray-600">
                {dayjs(item.createdAt).format("LL h:mm:ss a ")}
              </div>
            </div>
          )}
          locale={{
            emptyText: <EmptyData text="No Logs" icon="ri-search-line" />,
          }}
        />

        <div className="flex flex-row justify-end items-center py-4">
          <Pagination
            pageSize={paginationState.pageSize}
            current={paginationState.currentPage}
            total={paginationState.totalItems}
            onChange={(currentPage, pageSize) =>
              setPaginationState({
                ...paginationState,
                pageSize: pageSize || 20,
                currentPage,
              })
            }
          />
        </div>
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
