import "./drag-handler.scss";

export const DragHandler = () => (
  <div className="flex flex-col cc-drag-handle">
    <div className="flex flex-row">
      <i className="ri-more-line"></i>
      <i className="ri-more-line"></i>
    </div>
    <div className="flex flex-row  -mt-4">
      <i className="ri-more-line"></i>
      <i className="ri-more-line"></i>
    </div>
  </div>
);
