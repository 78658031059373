import { axios } from "@sdk/axios";
import { EntityServices } from "@sdk/utils/entity.service";
import {
  PaginatedOptions,
  PaginatedResults,
} from "@sdk/utils/paginated-results";
import { AxiosResponse } from "axios";
import { UserTracker } from "user-tracker";
import { iDynamicForm, iDynamicFormResponse } from "./dynamic-forms-model";

export class DynamicFormsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  dynamicForms = Object.assign(
    new EntityServices<iDynamicForm>(this.config, "dynamic-forms", {
      onCreate: () => {
        UserTracker.track("DYNAMIC_FORMS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("DYNAMIC_FORMS - Delete", {});
      },
    }),
    {
      queryTemplates: async (query: any, options: PaginatedOptions) => {
        const results: AxiosResponse<PaginatedResults<iDynamicForm>> =
          await axios.post(
            this.config.basePath + `/dynamic-forms/query-templates`,
            { query, options },
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
    },
    {
      queryResponses: async (query: any, options: PaginatedOptions) => {
        const results: AxiosResponse<PaginatedResults<iDynamicFormResponse>> =
          await axios.post(
            this.config.basePath + `/dynamic-forms/query-responses`,
            {
              query,
              options: {
                ...options,
                sort: "-startAt",
              },
            },
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
    },
  );
}
