import { iPlaceData_Hotel } from "../place-data-hotel-model";

export const DummyHotelPlaceData: iPlaceData_Hotel = {
  address_components: [
    {
      long_name: "77",
      short_name: "77",
      types: ["street_number"]
    },
    {
      long_name: "Galle Road",
      short_name: "Galle Rd",
      types: ["route"]
    },
    {
      long_name: "Colombo 03",
      short_name: "Colombo 03",
      types: ["sublocality_level_1", "sublocality", "political"]
    },
    {
      long_name: "Colombo",
      short_name: "Colombo",
      types: ["locality", "political"]
    },
    {
      long_name: "Colombo",
      short_name: "Colombo",
      types: ["administrative_area_level_2", "political"]
    },
    {
      long_name: "Western Province",
      short_name: "WP",
      types: ["administrative_area_level_1", "political"]
    },
    {
      long_name: "Sri Lanka",
      short_name: "LK",
      types: ["country", "political"]
    },
    {
      long_name: "00300",
      short_name: "00300",
      types: ["postal_code"]
    }
  ],
  adr_address:
    '<span class="street-address">77 Galle Rd</span>, <span class="locality">Colombo</span> <span class="postal-code">00300</span>, <span class="country-name">Sri Lanka</span>',
  business_status: "OPERATIONAL",
  current_opening_hours: {
    open_now: true,
    periods: [
      {
        close: {
          date: "2023-05-03",
          day: 3,
          time: "2359",
          truncated: true
        },
        open: {
          date: "2023-04-27",
          day: 4,
          time: "0000",
          truncated: true
        }
      }
    ],
    weekday_text: [
      "Monday: Open 24 hours",
      "Tuesday: Open 24 hours",
      "Wednesday: Open 24 hours",
      "Thursday: Open 24 hours",
      "Friday: Open 24 hours",
      "Saturday: Open 24 hours",
      "Sunday: Open 24 hours"
    ]
  },
  editorial_summary: {
    language: "en",
    overview:
      "Stately resort with 16 restaurants & bars, a spa complex & an outdoor pool, plus a shopping mall."
  },
  formatted_address: "77 Galle Rd, Colombo 00300, Sri Lanka",
  formatted_phone_number: "0112 437 437",
  geometry: {
    location: {
      lat: 6.918108999999999,
      lng: 79.8487401
    },
    viewport: {
      northeast: {
        lat: 6.919842380291501,
        lng: 79.8503467302915
      },
      southwest: {
        lat: 6.917144419708497,
        lng: 79.84764876970848
      }
    }
  },
  icon:
    "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/lodging-71.png",
  icon_background_color: "#909CE1",
  icon_mask_base_uri:
    "https://maps.gstatic.com/mapfiles/place_api/icons/v2/hotel_pinlet",
  international_phone_number: "+94 112 437 437",
  name: "Cinnamon Grand Colombo",
  opening_hours: {
    open_now: true,
    periods: [
      {
        open: {
          day: 0,
          time: "0000"
        }
      }
    ],
    weekday_text: [
      "Monday: Open 24 hours",
      "Tuesday: Open 24 hours",
      "Wednesday: Open 24 hours",
      "Thursday: Open 24 hours",
      "Friday: Open 24 hours",
      "Saturday: Open 24 hours",
      "Sunday: Open 24 hours"
    ]
  },
  photos: [
    {
      height: 1152,
      html_attributions: [
        '<a href="https://maps.google.com/maps/contrib/104022330821654249010">Cinnamon Grand Colombo</a>'
      ],
      photo_reference:
        "AUjq9jlRKwY9NOgZZg_2nt5T-_3xtqhQcuGgDGF-0BbW0QU2Xkb2kFRWivYucvUfPoKFG61pa4gChSS3URQWomOevytwQilZ2B1ikb4zGKgjMT_KguOz-Ikg8OqjnzgMa2NoIUT26WmvVKBxRcOGu0DRJciuBnbwvyBrs-0MQYUd743XS2n_",
      width: 2048
    },
    {
      height: 1152,
      html_attributions: [
        '<a href="https://maps.google.com/maps/contrib/104022330821654249010">Cinnamon Grand Colombo</a>'
      ],
      photo_reference:
        "AUjq9jnZunHRp8O8hkD8Bch-KxrCw-aNk5vnxYqqQhyRX2L5HDRnol_zJldwxZcH2MEGT856dQ3hy8qMnRSg_4qa7HSWfeB2GrGfdXPZl2l3lhaMxTjC5rCuTkh95P018khWE1PKd-KcUO80wspKTaGfH6L4kqHIvodUUKoOAVM7yVpJ30ng",
      width: 2048
    },
    {
      height: 2330,
      html_attributions: [
        '<a href="https://maps.google.com/maps/contrib/114712813516403497062">Ahmad AL-Mulla</a>'
      ],
      photo_reference:
        "AUjq9jk6lHj51qr3tlYh0a8FqJscss02ddKJGtWbfTGbmqnEPLJtxL_b2HN353skBuBwY9fk972eKxcAB03f2A54Xz6h1_yd8DNfLeNZN2llDfNy5e2LNjjBmx_RDSKib3iIKRz6G36SuxKoHtAu7X09o0ZMsjp345JxNRlKiKCrtlQpgpmw",
      width: 4617
    },
    {
      height: 3265,
      html_attributions: [
        '<a href="https://maps.google.com/maps/contrib/105832827924253924783">Chethana Adhikari</a>'
      ],
      photo_reference:
        "AUjq9jlYOiX2OyzUHmw9gIbfF7fyviP5xhHoNdRZp9u7TirBFI0euc_afQ5onN8f0zepBpiSYwTWR7aQeISWU74USNYBpyKJC0WgYaQWx6wyckkgm0NGdCHb8exQiicIXGR1DqiEOdyUinKLGdGzx2sjqye0mWkzvl-GSYEfHJcFsKfq2IGy",
      width: 4898
    },
    {
      height: 1000,
      html_attributions: [
        '<a href="https://maps.google.com/maps/contrib/104022330821654249010">Cinnamon Grand Colombo</a>'
      ],
      photo_reference:
        "AUjq9jlVWChkPCFft9-8nMSLsvxj0CJPw_YgV-AmYWsGFIiFR_sf0S1nVrjLMvuy-rdMAZD_c-hMmDIKr1YoHttTCztWqe-cnX0NfrFFBFY09O4uZChWjxiaSDxZHlrOqOuuI22jB9ZalfI6tnZptTMKCdVMxlx1ipkmhj2XoC0KwA2philT",
      width: 667
    },
    {
      height: 1000,
      html_attributions: [
        '<a href="https://maps.google.com/maps/contrib/104022330821654249010">Cinnamon Grand Colombo</a>'
      ],
      photo_reference:
        "AUjq9jkx-MPB8QSz3EmOp6782-zCihK834YbW2pXmzjVyeoA_0QBtGPDGwhrllmh6K4clAw-TLdlTwy0WM6vpr0wBUaqBL_jbF6SOz6hXNRKSdcEdJGhpEm2mRvYUVNnUpyTjudqUvu_muhw-dTF-BYLTwYnytdsri3UkOkODNwi6nYqoU0o",
      width: 1500
    },
    {
      height: 1000,
      html_attributions: [
        '<a href="https://maps.google.com/maps/contrib/104022330821654249010">Cinnamon Grand Colombo</a>'
      ],
      photo_reference:
        "AUjq9jm0IixFpzddpUyaTVLv6duSlSQ6ZVPZPpO2pIWQq_ns2pwUCwHYtoBgAhJsFUHfIBsXQ8CJNvFyNceVBsog6wR8xZhh8KaRg0N4ybBgg1YKcfz9wNN8rCZiztI0MkocTKoi4U_woahXXoJd4-bmBUAa_mkShMgkC0XndbMkeqYuTkWM",
      width: 1500
    },
    {
      height: 1366,
      html_attributions: [
        '<a href="https://maps.google.com/maps/contrib/104022330821654249010">Cinnamon Grand Colombo</a>'
      ],
      photo_reference:
        "AUjq9jmgJtMonc3hf03G8F127HvTy9bVo93IevvFUyvcUUoB8fokes1Y7aFn-lAxjhxP0NgY9NdpTMS1dciRrm3YLn1EmsDNte6AqI8hGIoRA-lGQUg6-XMuIU42AZyOOEzpVCY5HulApMf6oPAO7Re8iIWZI78yjWsBeCayxDtDoD8Zp21n",
      width: 2048
    },
    {
      height: 1365,
      html_attributions: [
        '<a href="https://maps.google.com/maps/contrib/104022330821654249010">Cinnamon Grand Colombo</a>'
      ],
      photo_reference:
        "AUjq9jnpLNraFsdJxMAvy46DV9--ZNZPdF3hHg6kNEqMOe6Ftjd78M58D5YRVbbbWqtVgv8rLCkk25IAttufl1qFhmmMll2E9DSKOSWbnInjhzJuWfQUAYrTca8kO9b2BS8cDfW9A3spDgyct-kk4xCOtq8XXoQu4MdDi_yQYqtGCcvYH42k",
      width: 2048
    },
    {
      height: 1152,
      html_attributions: [
        '<a href="https://maps.google.com/maps/contrib/104022330821654249010">Cinnamon Grand Colombo</a>'
      ],
      photo_reference:
        "AUjq9jmBWLfoWExzcEw2tFnTd93wpPcKvnV4m9Z_UgwHNMUaP0Yvp9ytI6RX1Q2TqaKnVSiCwrPBTNnaqvscWEhYgCKsh_oWy8w6Z4AmTwimz_1NGYil5Oc0IV4NbSpDVzOQ59Ne664n8chdH0H2cV6YuvwOgy6XMFY-hRYNnvyd92-hLwZM",
      width: 2048
    }
  ],
  place_id: "ChIJM5ab7UBZ4joRDjyehWiBZbA",
  plus_code: {
    compound_code: "WR9X+6F Colombo, Sri Lanka",
    global_code: "6JRXWR9X+6F"
  },
  rating: 4.6,
  reference: "ChIJM5ab7UBZ4joRDjyehWiBZbA",
  reviews: [
    {
      author_name: "H N S Fernando",
      author_url:
        "https://www.google.com/maps/contrib/113138895949473750323/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/AGNmyxbw3I12JmmIhb-JT_tkxU4C6SOgQnRSRJ2HIHX_=s128-c0x00000000-cc-rp-mo",
      rating: 5,
      relative_time_description: "5 months ago",
      text:
        "I came to the Cinnamon Grand Hotel for one of my friend's wedding. Room was very clean & comfortable. Food was Excellent. They have many variations of menus. Staff members were friendly and helpful. My special thanks to Chandana, Housekeeping manager. He is very friendly & helpful. Highly recommend this hotel.",
      time: 1667143682,
      translated: false
    },
    {
      author_name: "Kaveen Minura",
      author_url:
        "https://www.google.com/maps/contrib/106190979306562264775/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ACB-R5TxfbxYAAhUsWp_6QX5FITdxvmYW0u_XbQrKDaGBg=s128-c0x00000000-cc-rp-mo-ba5",
      rating: 5,
      relative_time_description: "3 months ago",
      text:
        "I came to the Cinnamon Grand Hotel for one of my friend's weddings. The room was very clean & comfortable. The food was Excellent. They have many variations of menus. Staff members were friendly and helpful. My special thanks to Chandana, the Housekeeping manager. He is very friendly & helpful. Highly recommend this hotel.",
      time: 1673337423,
      translated: false
    },
    {
      author_name: "Ushan Perera",
      author_url:
        "https://www.google.com/maps/contrib/113363342507574733005/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ACB-R5RM_jPBGhSpsmRlE3h2YYuJuHucF5iHpdUVcL_93A=s128-c0x00000000-cc-rp-mo-ba3",
      rating: 5,
      relative_time_description: "5 months ago",
      text:
        "Went to the Pool Bar to have a beer 🍻\nThe experience was really nice. Staff was really helpful and pleasant.\n\nCould order bites from the Plates restaurant too. Food is really nice. Hot buttered Cuttlefish is recommended. They played live music using a saxophone 🎷 which was so relaxing 💆\n\nTruly a place that you can relax on and have a beer 🍺\nLoved it ❤️",
      time: 1667900752,
      translated: false
    },
    {
      author_name: "Dilrukshi Wimalaratne",
      author_url:
        "https://www.google.com/maps/contrib/103350795174608528318/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/AGNmyxbkmz-TkvzJc_Kt1y0rl2Rbt7rTN8_-imbzklFF=s128-c0x00000000-cc-rp-mo",
      rating: 4,
      relative_time_description: "4 months ago",
      text:
        "Had lunch buffet yesterday. As usual they had a good variety at the salad station. Excellent selection of sushi. The main course had a decent variety especially had lots of pork varieties yesterday. But wasn't happy with the dessert. It had no variety..most were gelatin based. Loved the hot pudding. Hated the chocolate mouse.  Hardly any chocolate  in there. Service  staff were  friendly  and courteous.",
      time: 1670581146,
      translated: false
    },
    {
      author_name: "Sashitha Imalka",
      author_url:
        "https://www.google.com/maps/contrib/110143670533279764358/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ACB-R5THuAdj9lNvG6u3ydWcg_ENvcu4tem4XgEmRSs3qQ=s128-c0x00000000-cc-rp-mo-ba5",
      rating: 5,
      relative_time_description: "6 months ago",
      text:
        "We had lunch at Platz restaurant on a weekday. Lunch buffet had so many meats, sweets and many more verities to choose. Delicious food. It was a really good time and worth the money we paid. The hotel's swimming pool and surrounding environment are nice. I really recommend this place.",
      time: 1666075583,
      translated: false
    }
  ],
  types: [
    "spa",
    "lodging",
    "restaurant",
    "food",
    "point_of_interest",
    "establishment"
  ],
  url: "https://maps.google.com/?cid=12710707809241414670",
  user_ratings_total: 15528,
  utc_offset: 330,
  vicinity: "77 Galle Road, Colombo",
  website: "http://www.cinnamonhotels.com/cinnamongrandcolombo/",
  wheelchair_accessible_entrance: true
};
