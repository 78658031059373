import { useSDK } from "@sdk";
import { iWACloudConnectionData } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Alert, Button, Descriptions, Divider, Space, Spin } from "antd";
import { ExternalAuthConnector } from "components/common/external-auth-connector-button/external-auth-connector-button";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { SectionHeader } from "components/common/section-header";
import dayjs from "dayjs";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { stringArrayToSentence } from "utils/string-array-to-sentence";

export const ManageWACloudNativeConnection = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const wabaConnectionData = connection?.data as iWACloudConnectionData;

  const {
    data: connectionStatus,
    isLoading,
    error,
    reload: reloadDebug,
  } = useSDK(
    (SDK) =>
      SDK.connections.checkWhatsappCloudNativeAuthenticationStatus(
        connectionId,
      ),
    [connectionId],
    !connectionId,
  );

  const {
    doAction: reAuthenticateWhatsappConnection,
    isProcessing: isAuthenticating,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (connectionId: string, accessToken: string) =>
        SDK.connections
          .reAuthenticateWhatsappCloudNativeConnection(
            connectionId,
            accessToken,
          )
          .then((d) => {
            return reloadDebug();
          }),
      successMessage: "Re-authentication has been successful",
      failureMessage: "Something went wrong",
    }),
    [reloadDebug],
  );

  return (
    <Spin
      spinning={!error && isLoading}
      indicator={<LoadingIndicatorWithoutSpin />}
      wrapperClassName="flex-1 flex fill-spinner"
    >
      <div className="animated fadeInLeftMin  flex flex-col w-full">
        <SectionHeader
          title="Connected Account (Beta)"
          icon={<i className="ri-whatsapp-line"></i>}
          description="Channel Integration"
        />
        <Divider />
        <div className="flex flex-row w-full justify-end items-center mb-4">
          <Space>
            <Button
              type="link"
              href={`https://business.facebook.com/wa/manage/message-templates/?waba_id=${wabaConnectionData?.wabaId}`}
              icon={<i className="ri-message-2-line"></i>}
              target="_blank"
              referrerPolicy="no-referrer"
            >
              Manage Templates
            </Button>
            <Button
              type="link"
              href={`https://business.facebook.com/wa/manage/insights/?waba_id=${wabaConnectionData?.wabaId}`}
              icon={<i className="ri-bar-chart-box-line"></i>}
              target="_blank"
              referrerPolicy="no-referrer"
            >
              Message Insights
            </Button>
          </Space>
        </div>

        {error || (!isLoading && !connectionStatus?.debugResponse) ? (
          <>
            <Alert
              message={
                <div className="font-bold">
                  It looks like your Whatsapp Integration is broken
                </div>
              }
              description={
                <div>
                  It could happen for the following reasons <br />
                  <ul>
                    <li>👉 You disconnected Click Connector Manually</li>
                    <li>
                      👉 The user who gave permission is no longer part of the
                      page
                    </li>
                    <li>
                      👉 The user who gave permission changed their password
                    </li>
                  </ul>
                  <div
                    className="flex flex-row justify-end items-center"
                    style={{ width: 202 }}
                  >
                    <Spin
                      spinning={isAuthenticating || (!error && isLoading)}
                      indicator={<LoadingIndicatorWithoutSpin />}
                      wrapperClassName="flex-1 flex fill-spinner"
                    >
                      <ExternalAuthConnector
                        type="WHATSAPP"
                        onAuthenticated={({ accessToken }) => {
                          reAuthenticateWhatsappConnection(
                            connection.id,
                            accessToken,
                          );
                        }}
                        label="Re-Connect Whatsapp"
                      />
                    </Spin>
                  </div>
                </div>
              }
              type="error"
              showIcon
              className="p-4 rounded-lg my-2 mb-4"
            />
          </>
        ) : (
          <></>
        )}

        <Descriptions
          title="Integration Details"
          bordered
          layout="horizontal"
          column={1}
          style={{ maxWidth: 600 }}
        >
          <Descriptions.Item label="Connected WABA">
            {wabaConnectionData?.wabaId}
          </Descriptions.Item>
          <Descriptions.Item label="Connection Phone Id">
            {wabaConnectionData?.phoneId}
          </Descriptions.Item>
          <Descriptions.Item label="Connection Phone Number">
            {wabaConnectionData?.phoneNumber ||
              (wabaConnectionData as any)?.mobileNumber}
          </Descriptions.Item>
          {!error && (
            <>
              <Descriptions.Item label="Application">
                {connectionStatus?.debugResponse?.data.application} (
                {connectionStatus?.debugResponse?.data.app_id})
              </Descriptions.Item>
              <Descriptions.Item label="Data Access Expiry">
                {dayjs(
                  connectionStatus?.debugResponse?.data.data_access_expires_at *
                    1000,
                ).toString()}
              </Descriptions.Item>
              <Descriptions.Item label="Access Issued at">
                {dayjs(
                  connectionStatus?.debugResponse?.data.issued_at * 1000,
                ).toString()}
              </Descriptions.Item>
              <Descriptions.Item label="Granted Permissions">
                {stringArrayToSentence(
                  connectionStatus?.debugResponse?.data.scopes || [],
                )}
              </Descriptions.Item>
              <Descriptions.Item label="UserId">
                {connectionStatus?.debugResponse?.data.user_id}
              </Descriptions.Item>
            </>
          )}
        </Descriptions>

        {!error && (
          <>
            <div
              className="p-4 rounded-lg border border-gray-300 dark:border-gray-700 flex flex-col cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800 mt-8"
              onClick={() => {
                (window as any).ccWidget?.onWidgetActivated(() => {
                  (window as any).ccWidget?.triggerCampaign?.({
                    message: "How can we improve WhatsApp Integration for you?",
                  });
                  (window as any).ccWidget.setWidgetVisibility(true);
                });
              }}
            >
              <div className="flex flex-row items-center">
                <div className="text-2xl">💡</div>
                <div className="text-xl font-bold ml-3">
                  Suggest WhatsApp Integration Improvement
                </div>
              </div>

              <div className="text-gray-600">
                Share your feedback with us to improve WhatsApp Integration
              </div>
            </div>
          </>
        )}
      </div>
    </Spin>
  );
};
