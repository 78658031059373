import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import { Button, Collapse, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useState } from "react";
import { uuidv4 } from "utils/generate-uuid";
import "./single-editable-faq-collection.scss";
const { Panel } = Collapse;

const AddFAQCollection = ({ dispatch }: { dispatch: React.Dispatch<any> }) => {
  const [activeKey, setActiveKey] = useState([] as string[]);
  const [AddFAQCollectionForm] = useForm();
  return (
    <Collapse
      activeKey={activeKey}
      className="p-0 m-2 border-none faq-add w-full"
      style={{ background: "#00000000" }}
    >
      <Panel
        header={
          <div
            className="flex flex-col items-center text-gray-600 dark:text-gray-400 mt-6 mb-2 mode_transition"
            onClick={() => {
              setActiveKey(activeKey.length === 0 ? [`AddFAQCollection`] : []);
            }}
          >
            <div className="flex items-center font-semibold">
              <PlusCircleOutlined className="text-xl mr-2" /> Add New FAQ
              Collection
            </div>
          </div>
        }
        key="AddFAQCollection"
        showArrow={false}
      >
        <Form
          layout="vertical"
          form={AddFAQCollectionForm}
          onFinish={() => {
            dispatch({
              type: "ADD_FAQ_COLLECTION",
              payload: {
                collection: {
                  id: uuidv4(),
                  label:
                    AddFAQCollectionForm.getFieldValue("FAQCollectionLabel"),
                  faqs: [],
                },
              },
            });
            setActiveKey([]);
            AddFAQCollectionForm.resetFields();
          }}
        >
          <div className="mb-3">
            <Form.Item
              label=""
              name="FAQCollectionLabel"
              rules={[
                {
                  required: true,
                  message: "Go ahead and enter a collection title",
                },
              ]}
            >
              <Input placeholder="Enter a collection title" />
            </Form.Item>
          </div>

          <Button type="primary" htmlType="submit">
            Add
          </Button>

          <Button
            type="text"
            onClick={() => {
              setActiveKey([]);
            }}
          >
            Cancel
          </Button>
        </Form>
      </Panel>
    </Collapse>
  );
};
export default AddFAQCollection;
