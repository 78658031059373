import {
  iArticle,
  iArticleCollection,
} from "@sdk/knowledge-base/knowledge-base.models";
import { Collapse } from "antd";
import classnames from "classnames";
import _ from "lodash";
import { useMemo } from "react";
import { convertHtmlToPlainText } from "utils/html-to-plain-text";
import "./articles-folder-viewer.scss";

export const ArticleItem = ({
  article,
  onSelected,
  isActive,
  onMouseOver,
}: {
  article: iArticle;
  onSelected: (preset: iArticle) => any;
  isActive?: boolean;
  onMouseOver?: (e) => any;
}) => {
  return (
    <div
      className={classnames(
        "flex flex-row items-center w-full rounded-md hover:bg-gray-200 dark:hover:bg-gray-800 cursor-pointer p-2 overflow-hidden",
        {
          "bg-gray-200 dark:bg-gray-800": isActive,
        },
      )}
      onClick={() => onSelected(article)}
      onMouseOver={onMouseOver}
    >
      <div className="template-type">
        <i className="ri-article-line"></i>
      </div>
      <div className="flex flex-col ml-3 overflow-hidden">
        <div className="template-label font-normal truncate">
          {article.title}
        </div>
        <div className="template-label flex-1 text-sm font-normal text-gray-600 truncate">
          {convertHtmlToPlainText(article.body)}
        </div>
      </div>
    </div>
  );
};

export const ArticlesFolderViewer = ({
  showRichText,
  collection,
  articleList,
  onSelected,
  articleUnderCursor,
  setArticleUnderCursor,
  ...collapsiblePanelProps
}: {
  showRichText?: boolean;
  collection: iArticleCollection;
  articleList: iArticle[];
  onSelected: (article: iArticle) => any;
  articleUnderCursor: string;
  setArticleUnderCursor: (articleId: string) => any;
}) => {
  const articles = useMemo(() => {
    return _.filter(articleList, (item) =>
      item.collections.includes(collection.id!),
    );
  }, [articleList, collection]);

  return (
    <Collapse.Panel
      key={collection.id!}
      {...collapsiblePanelProps}
      // showArrow={false}
      header={
        <div className="flex flex-row justify-center items-center flex-1 text-gray-900 dark:text-gray-100">
          <div className="icon">
            <i className="ri-folder-2-line"></i>
          </div>
          <div className="texts flex flex-row pl-2 flex-grow flex-1">
            <div className="font-semibold truncate">{collection.label}</div>
          </div>
        </div>
      }
      extra={
        <div
          className="text-sm font-normal text-gray-900 dark:text-gray-100 whitespace-nowrap"
          style={{ width: "5em" }}
        >
          {articles.length} Article(s)
        </div>
      }
      className="hover:bg-gray-100 dark:hover:bg-gray-900 rounded-md articles-folder-viewer"
    >
      <div>
        {articles.map((preset) => (
          <ArticleItem
            article={preset}
            key={preset.id}
            onSelected={onSelected}
            isActive={articleUnderCursor === preset.id}
            onMouseOver={() => setArticleUnderCursor(preset.id!)}
          />
        ))}
      </div>
    </Collapse.Panel>
  );
};
