import { iIndexedWebPage } from "@sdk/indexed-web-pages/indexed-web-pages-model";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectIndexedWebPageQueryMap,
  selectEntityMap: selectIndexedWebPagesMap,
  selectQueryMapAll: selectIndexedWebPagesMapAll,
  selectQueryAllList: selectIndexedWebPagesAllList,
  selectAllEntities: selectAllIndexedWebPages,
  selectAllEntitiesQuery: selectAllIndexedWebPagesQuery,
  selectEntityById: selectIndexedWebPageById,
  selectEntityQuery: selectIndexedWebPageQuery,
  selectEntityQueryList: selectIndexedWebPageQueryList
} = CreateSelectorForEntities<iStore, iIndexedWebPage>({
  sliceName: "indexedWebPages"
});

export const selectIndexedWebPageByConnectionId = (connectionId: string) => (
  state: iStore
) => {
  return _.find(selectAllIndexedWebPages(state), {
    connectionId
  });
};
