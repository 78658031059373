import { iCustomField } from "@sdk/dangerous-deletion/dangerous-deletion-model";
import { Rule } from "antd/es/form";
import { useMemo } from "react";

export const useDynamicFormItemProps = (config: iCustomField) => {
  const rules = useMemo(() => {
    let arr: Rule[] = [];
    if (config.inputConfig?.isRequired) {
      arr.push({
        required: true,
        message: "This field is required"
      });
    }
    if (config.inputType === "EMAIL") {
      arr.push({
        type: "email",
        message: "Please input a valid email"
      });
    }
    if (config.inputConfig?.regex) {
      arr.push({
        type: "regexp",
        pattern: new RegExp(config.inputConfig.regex),
        message: "Input is not valid"
      });
    }
    if (config.inputConfig?.validations) {
      arr = [...arr, ...config.inputConfig.validations];
    }
    return arr;
  }, [config]);

  const label = useMemo(() => {
    if (config.inputType === "CHECK_BOX") {
      return "";
    }
    return config.label;
  }, [config.inputType, config.label]);

  const additionProps = useMemo(() => {
    if (config.inputType === "SWITCH") {
      return {
        valuePropName: "checked"
      };
    }
    return {};
  }, [config.inputType]);

  const returnValue = useMemo(() => ({ rules, label, additionProps }), [
    additionProps,
    label,
    rules
  ]);

  return returnValue;
};
