import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Descriptions, Spin, Timeline } from "antd";
import {
  ExternalAuthCallbackData,
  ExternalAuthConnector
} from "components/common/external-auth-connector-button/external-auth-connector-button";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import {
  AddConnectionLayout,
  AddConnectionModalContainer,
  AddConnectionStepsContainer,
  IntegrationSuccessfulScreen,
  StepContainer
} from "components/modules/connections/add-connection-modal-templates";
import { generateDefaultConnectionConfig } from "components/modules/connections/common-connection-settings/default-connection-config-generator";
import { push } from "connected-react-router";
import { useCallback, useState } from "react";
import { useDispatch, useStore } from "react-redux";

export const AddTwitterConnectionModal = ({
  visible,
  onChangeVisibility: _onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const dispatch = useDispatch();
  const store = useStore();

  const [currentStep, setCurrentStep] = useState(0);
  const onManageConnection = (connectionId: string) => {};
  const [oath2Code, setOath2Code] = useState("");
  const [oath1Data, setOath1Data] = useState({
    oauth_verifier: "",
    oauth_token: ""
  } as ExternalAuthCallbackData);
  const [createdConnection, setCreatedConnection] = useState("");

  const {
    doAction: completeIntegration,
    isProcessing: isActionProcessing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => val =>
        SDK.connections.create(val).then(d => {
          setCreatedConnection(d.id!);
          setCurrentStep(2);
        }),
      successMessage: "Integration has been created",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const onChangeVisibility = (visibility: boolean) => {
    _onChangeVisibility(visibility);
    setCurrentStep(0);
  };

  const onCompleteIntegration = useCallback(
    oath1Data => {
      const accountName = "";
      const label = `Twitter: ${accountName}`;
      const configData = generateDefaultConnectionConfig("TWITTER", store);
      completeIntegration({
        type: "TWITTER",
        label,
        oathData: {
          ...oath1Data,
          code: oath2Code
        },
        data: configData
      });
    },
    [completeIntegration, oath2Code, store]
  );

  return (
    <>
      <AddConnectionModalContainer
        title="Connect Twitter"
        icon={<i className="ri-twitter-line"></i>}
        isVisible={visible}
        onChangeVisibility={onChangeVisibility}
      >
        <AddConnectionLayout
          currentStep={currentStep}
          totalSteps={1}
          stepsWithSidebars={[0]}
          topBar={
            <AddConnectionStepsContainer
              currentStep={currentStep}
              steps={[
                {
                  label: "Authenticate",
                  icon: <i className="ri-lock-line"></i>
                },
                {
                  label: "Done",
                  icon: <i className="ri-check-line"></i>
                }
              ]}
            />
          }
          sidebarImage={
            <i className="ri-twitter-line text-10xl text-gray-200 dark:text-gray-700"></i>
          }
        >
          <StepContainer stepNumber={0} currentStep={currentStep}>
            <div className="description text-gray-600 dark:text-gray-400 mb-4 mode_transition">
              Now click on authenticate button and authenticate to allow Click
              Connector to access your twitter account
            </div>

            <Descriptions
              title="Required Permissions"
              bordered
              layout="horizontal"
              column={1}
            >
              <Descriptions.Item label="tweet.read">
                This will allow Click Connector to see your tweets
              </Descriptions.Item>
              <Descriptions.Item label="users.read">
                This will allow Click Connector to see profile information
              </Descriptions.Item>
              <Descriptions.Item label="offline.access">
                This will allow Click Connector to sync tweets and messages in
                the background
              </Descriptions.Item>
            </Descriptions>
            <div className="p-4 mt-8">
              <Timeline>
                <Timeline.Item key={"1"} dot={<i className="ri-number-1"></i>}>
                  <div className="flex flex-col">
                    <div className="title font-bold mb-4">Step 1</div>
                    {!oath2Code && (
                      <ExternalAuthConnector
                        type="TWITTER_V2"
                        onAuthenticated={({ code }) => {
                          console.log("Received Twitter V2 code", code);
                          setOath2Code(code!);
                        }}
                        label="Give Permission to Sync Mentions"
                      />
                    )}
                    {oath2Code && (
                      <div className="flex flex-row w-full justify-center items-center">
                        <i className="ri-check-line pr-2"></i>
                        <div className="">Proceed to Step 2</div>
                      </div>
                    )}
                  </div>
                </Timeline.Item>
                <Timeline.Item key={"2"} dot={<i className="ri-number-2"></i>}>
                  <div className="flex flex-col">
                    <div className="title font-bold mb-4">Step 2</div>
                    {!oath2Code && (
                      <div className="flex flex-row w-full justify-center items-center">
                        <i className="ri-arrow-up-fill pr-2"></i>
                        <div className="">Complete Step 1</div>
                      </div>
                    )}
                    {oath2Code && (
                      <Spin
                        spinning={isActionProcessing}
                        indicator={<LoadingIndicatorWithSpin />}
                      >
                        <ExternalAuthConnector
                          type="TWITTER_V1"
                          onAuthenticated={data => {
                            console.log("Received Twitter V1 data", data);
                            setOath1Data(data);
                            onCompleteIntegration(data);
                          }}
                          label="Give Permission to Sync Messages"
                        />
                      </Spin>
                    )}
                  </div>
                </Timeline.Item>
              </Timeline>
            </div>
          </StepContainer>

          <StepContainer stepNumber={2} currentStep={currentStep}>
            <IntegrationSuccessfulScreen
              onManageConnection={() => {
                dispatch(
                  push(`/configurations/connections/${createdConnection}/`)
                );
              }}
              onHideWizard={() => onChangeVisibility(false)}
              subTitle="Now you will be able to reply to Twitter DMs and mentions from Click Connector"
            ></IntegrationSuccessfulScreen>
          </StepContainer>
        </AddConnectionLayout>
      </AddConnectionModalContainer>
    </>
  );
};
