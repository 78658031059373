import PlusOutlined from "@ant-design/icons/PlusOutlined";
import {
  iModalPanelRegistryItem,
  useModalPanels
} from "@libs/modal-panels/modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iCustomInbox } from "@sdk/user-management/user-management.models";
import { Button, Divider, Dropdown, Menu, Tag } from "antd";
import { KeyboardShortcut } from "components/common/keyboard-shortcut/keyboard-shortcut";
import { MangeCustomInboxViews } from "components/modules/conversations/components/manage-custom-views/manage-custom-views";
import { QueryTasksBadgeCount } from "components/modules/tasks/query-tasks-count-badge/query-tasks-count-badge";
import { InboxStatusWidget } from "components/modules/user-management/users/components/inbox-status-widget/inbox-status-widget";
import { TeamStatusWidget } from "components/modules/user-management/users/components/team-status-widget/team-status-widget";
import { useRegisterActions } from "kbar";
import _ from "lodash";
import { AddQuickCustomInbox, NewMessageCreator } from "modal-registry";
import React, { useCallback, useMemo, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector, useStore } from "react-redux";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { selectInboxConfig } from "store/modules/workspace/workspace.selectors";
import { QueryUnreadCountBadge } from "./components/conversation-query-unread-count-badge/conversation-query-unread-count-badge";
import { QueryUnresolvedFbCOmmentsCountBadge } from "./components/fb-posts-query-unread-count-badge/fb-posts-query-unread-count-badge";
import {
  QueryConversationsBadgeCount,
  QueryConversationsBadgeCountText
} from "./components/query-conversations-count-badge/query-conversations-count-badge";
import "./inbox-selector-v2.scss";
import {
  InboxMenuItem,
  defaultInboxViewTemplate,
  useInboxes
} from "./use-inboxes";
import { useUniboxView } from "./use-unibox-view";

const { SubMenu } = Menu;

const renderMenuItem = (
  item: InboxMenuItem,
  index: number,
  onInboxSelected: (item: InboxMenuItem) => any,
  sidebarCollapsed: boolean,
  changePanelState: <T>(
    panel: iModalPanelRegistryItem<T>,
    visibility: boolean,
    state: T
  ) => any,
  setManageCustomInboxModalVisibility,
  onRemoveCustomInbox: (inboxId: string) => any,
  showCount?: boolean
) => {
  if (item.menuItemType === "DIVIDER") {
    return (
      <Divider
        type="horizontal"
        className="my-0"
        key={`Diver_${index.toString()}`}
      ></Divider>
    );
  }

  if (item.menuItemType === "TITLE") {
    return (
      <div
        className="inboxes-title flex flex-row justify-between items-center ml-5 mt-2"
        key={`Title_${index.toString()}`}
      >
        <div className="title font-bold text-xl">{item.label}</div>
      </div>
    );
  }

  if (item.menuItemType === "CUSTOM_COMPONENT") {
    if (item.component === "QUICK_INBOX_ADD") {
      return (
        <React.Fragment key={"QUICK_INBOX_ADD_USER_FRAGMENT"}>
          {!sidebarCollapsed && (
            <Menu.Item
              key={"QUICK_INBOX_ADD_USER"}
              title={"Watch a User"}
              icon={<i className="ri-add-line"></i>}
              onClick={() => {
                changePanelState(AddQuickCustomInbox, true, {});
              }}
              // className="text-sm"
            >
              <span className="pl-2">Watch a User / Tag / Team</span>
            </Menu.Item>
          )}
        </React.Fragment>
      );
    }
    return <React.Fragment key={`CUSTOM_${index}`}></React.Fragment>;
  }

  if (item.menuItemType === "ITEM") {
    const Badge = (() => {
      if (item.type === "CHAT") {
        return item.label === "Unassigned" ? (
          <>
            {!sidebarCollapsed && (
              <QueryConversationsBadgeCount
                query={
                  "alias" in item.queryConfig
                    ? item.queryConfig.alias
                    : JSON.stringify(item.queryConfig)
                }
              />
            )}
          </>
        ) : (
          <>
            {!sidebarCollapsed && showCount && (
              <QueryConversationsBadgeCountText
                query={
                  "alias" in item.queryConfig
                    ? item.queryConfig.alias
                    : JSON.stringify(item.queryConfig)
                }
              />
            )}

            <QueryUnreadCountBadge
              query={
                "alias" in item.queryConfig
                  ? item.queryConfig.alias
                  : JSON.stringify(item.queryConfig)
              }
            />
          </>
        );
      }
      if (item.type === "FB_POSTS") {
        return (
          <QueryUnresolvedFbCOmmentsCountBadge
            query={
              "alias" in item.queryConfig
                ? item.queryConfig.alias
                : JSON.stringify(item.queryConfig)
            }
            dotOnly={sidebarCollapsed}
          />
        );
      }
      // if (item.type === "IG_MEDIAS") {
      //   return (
      //     <QueryUnresolvedIgCOmmentsCountBadge
      //       query={
      //         "alias" in item.queryConfig
      //           ? item.queryConfig.alias
      //           : JSON.stringify(item.queryConfig)
      //       }
      //       dotOnly={sidebarCollapsed}
      //     />
      //   );
      // }
      if (item.type === "TASKS") {
        return (
          <>
            {!sidebarCollapsed && (
              <QueryTasksBadgeCount
                query={
                  "alias" in item.queryConfig
                    ? item.queryConfig.alias
                    : JSON.stringify(item.queryConfig)
                }
              />
            )}
          </>
        );
      }
      return <></>;
    })();

    return (
      <Menu.Item
        id={`INBOX_ITEM_${item.id}`}
        key={item.id}
        title={item.label}
        icon={
          <div>
            {item.icon}
            {sidebarCollapsed && Badge}
          </div>
        }
        onClick={() => onInboxSelected(item)}
      >
        <span className="pl-2 w-full flex flex-row">
          {item.label}
          {Badge}
          {item.keyboardShortcut && (
            <KeyboardShortcut label={item.keyboardShortcut} />
          )}
          {item.viewConfig.isBeta && <Tag className="ml-2">Beta</Tag>}
          {item.id.includes("custom-") && (
            <div className="inbox-item-menu" onClick={e => e.stopPropagation()}>
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      key: "Remove Inbox",
                      icon: <i className="ri-delete-bin-2-line"></i>,
                      label: "Remove Inbox",
                      onClick: () => {
                        const inboxLabel = item.id.replace("custom-", "");
                        onRemoveCustomInbox(inboxLabel);
                      }
                    },
                    {
                      key: "Re-order Inbox",
                      icon: <i className="ri-sort-desc"></i>,
                      label: "Re-order Inbox",
                      onClick: () => {
                        setManageCustomInboxModalVisibility(true);
                      }
                    }
                  ]
                }}
              >
                <Button icon={<i className="ri-more-2-line"></i>} type="text" />
              </Dropdown>
            </div>
          )}
        </span>
      </Menu.Item>
    );
  }

  return (
    <SubMenu key={item.label} title={item.label} icon={item.icon}>
      {(item.children || []).map((item, index) =>
        renderMenuItem(
          item,
          index,
          onInboxSelected,
          false,
          changePanelState,

          setManageCustomInboxModalVisibility,
          onRemoveCustomInbox,
          showCount
        )
      )}
    </SubMenu>
  );
};

export const InboxSelectorV2 = ({
  sidebarCollapsed,
  onActionCompleted
}: {
  sidebarCollapsed: boolean;
  onActionCompleted: () => any;
}) => {
  const inboxConfig = useUniboxView();
  const store = useStore();
  const currentUser = useSelector(selectCurrentUser);

  const menu = useInboxes();

  const workspaceInboxConfig = useSelector(selectInboxConfig);

  const { doAction: editCustomInboxes, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: SDK => (customInboxes: iCustomInbox[]) =>
        SDK.editCurrentUser({
          data: {
            customInboxes
          }
        }),
      failureMessage: "Something went wrong"
    }),
    []
  );

  const onRemoveCustomInbox = useCallback(
    (inboxId: string) => {
      const currentUser = selectCurrentUser(store.getState());
      const customInboxes = currentUser?.data.customInboxes || [];
      editCustomInboxes(customInboxes.filter(item => item.label !== inboxId));
    },
    [editCustomInboxes, store]
  );

  const [
    isManageCustomInboxModalVisible,
    setManageCustomInboxModalVisibility
  ] = useState(false);

  const onInboxSelected = useCallback(
    (selection: InboxMenuItem) => {
      if (selection.menuItemType === "ITEM") {
        inboxConfig.setEntityId(
          selection.type === inboxConfig.inboxType ? undefined : null,
          selection.id
        );
        onActionCompleted();
      }
    },
    [inboxConfig, onActionCompleted]
  );

  const { changePanelState } = useModalPanels();

  const kbBindings = useMemo(() => {
    const inboxShortcuts = _.cloneDeep(defaultInboxViewTemplate)
      .map(item => ({
        menuItemType: "ITEM" as "ITEM",
        ...item
      }))
      .map(item => ({
        id: item.id,
        name: `Switch to ${item.label}`,
        section: "Inboxes",
        icon: <i className="ri-inbox-line"></i>,
        shortcut: item.keyboardShortcut ? [item.keyboardShortcut] : undefined,
        keywords: `Switch to ${item.label}`,
        perform: () => onInboxSelected(item as InboxMenuItem)
      }));

    const otherShortcuts = [
      {
        id: "New Conversation",
        name: `Start New Conversation`,
        section: "Actions",
        icon: <i className="ri-chat-new-line"></i>,
        shortcut: ["n"],
        keywords: `Start New Conversation`,
        perform: () => changePanelState(NewMessageCreator, true, {})
      }
    ];
    return [...inboxShortcuts, ...otherShortcuts];
  }, [changePanelState, onInboxSelected]);

  useRegisterActions(kbBindings, [kbBindings]);

  return (
    <>
      <div
        className="inbox-panel h-full flex flex-col"
        data-click-context="Inbox View"
      >
        <div className="new-button-container-big rounded-lg w-full flex flex-col justify-center items-center">
          {/* {(window.location.href.includes("cc.clickconnector") ||
            window.location.href.includes("localhost")) && (
            <Button
              icon={<MDIIcon icon={mdiSchoolOutline} />}
              size="small"
              type="text"
              onClick={() => setTourOpen(true)}
              className="mb-4"
            ></Button>
          )} */}

          <Button
            icon={<PlusOutlined />}
            size="large"
            type="primary"
            onClick={() => changePanelState(NewMessageCreator, true, {})}
          >
            Create New
          </Button>
          <div className="text-gray-700 dark:text-gray-200 text-sm mt-2 help-text mode_transition">
            Create a new conversation
          </div>
        </div>

        <div className="new-button-container-small rounded-lg w-full flex flex-col justify-center items-center">
          <Button
            icon={<PlusOutlined />}
            size="large"
            type="primary"
            onClick={() => changePanelState(NewMessageCreator, true, {})}
          ></Button>
        </div>

        <Divider type="horizontal" className="m-0"></Divider>

        <div className="hide-on-collapsed">
          <TeamStatusWidget />
        </div>

        <div className="hide-on-collapsed">
          <InboxStatusWidget />
        </div>

        <div
          className="inbox-section flex-1 relative overflow-hidden"
          id="inbox-section"
        >
          <PerfectScrollbar className="h-full w-full">
            <Menu
              selectedKeys={[inboxConfig.inboxId]}
              mode="inline"
              theme="light"
              inlineCollapsed={false}
            >
              {menu.map((item, index) =>
                renderMenuItem(
                  item,
                  index,
                  onInboxSelected,
                  sidebarCollapsed,
                  changePanelState as any,
                  setManageCustomInboxModalVisibility,
                  onRemoveCustomInbox,
                  workspaceInboxConfig.showInboxCounts
                )
              )}
            </Menu>
            <Divider type="horizontal" className="m-0"></Divider>
            <div className="flex flex-row justify-end items-center manage-channel-link">
              {(currentUser?.data.customInboxes || []).length > 0 && (
                <Button
                  icon={<i className="ri-settings-2-line"></i>}
                  type="text"
                  // size="small"
                  onClick={() => {
                    setManageCustomInboxModalVisibility(true);
                  }}
                  className="font-bold"
                >
                  Manage Custom Views
                </Button>
              )}

              <MangeCustomInboxViews
                visible={isManageCustomInboxModalVisible}
                onChangeVisibility={visibility =>
                  setManageCustomInboxModalVisibility(visibility)
                }
              />
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </>
  );
};

export const InboxSelectorMenu = ({
  onInboxSelected,
  sidebarCollapsed,
  changePanelState,
  setManageCustomInboxModalVisibility,
  onRemoveCustomInbox,
  showCount,
  options
}: {
  onInboxSelected: (item: InboxMenuItem) => any;
  sidebarCollapsed: boolean;
  changePanelState: <T>(
    panel: iModalPanelRegistryItem<T>,
    visibility: boolean,
    state: T
  ) => any;
  setManageCustomInboxModalVisibility;
  onRemoveCustomInbox: (inboxId: string) => any;
  showCount?: boolean;
  options?: {
    omitTitle?: boolean;
    omitTasks?: boolean;
  };
}) => {
  const menu = useInboxes(options);
  return (
    <>
      {menu.map((item, index) =>
        renderMenuItem(
          item,
          index,
          onInboxSelected,
          sidebarCollapsed,
          changePanelState,
          setManageCustomInboxModalVisibility,
          onRemoveCustomInbox,
          showCount
        )
      )}
    </>
  );
};
