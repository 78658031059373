import _ from "lodash";

export function getDayNameFromDayNumber(dayNumber: number) {
  switch (dayNumber) {
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fr";
    case 6:
      return "Sat";
    case 7:
      return "Sun";
    default:
      return "N/A";
  }
}

export function generateHeatMapData(
  report: {
    dayOfTheWeek: number;
    hour: number;
    count: number;
  }[]
) {
  const heatMapData: any[] = [];

  for (let w = 0; w < 7; w++) {
    const heatMapWeekDayRecord = {
      name: getDayNameFromDayNumber(w + 1),
      series: [] as any[]
    };
    for (let h = 0; h < 24; h++) {
      const reportData = _.find(report, {
        dayOfTheWeek: w,
        hour: h
      });
      heatMapWeekDayRecord.series.push({
        name: h.toString(),
        value: reportData ? reportData.count : 0
      });
    }
    heatMapData.push(heatMapWeekDayRecord);
  }
  return heatMapData;
}
