import { SDK } from "@sdk";
import { BulkConversationsAction } from "@sdk/conversations/conversations.controller-models";
import { processServerError } from "@sdk/sdk.hooks";
import { Badge, Form, Input, message, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { BadgesController } from "badge-controller";
import { ModalTitle } from "components/common/modal-title";
import { SingleUserSelector } from "components/modules/user-management/users/components/single-user-selector/single-user-selector";
import { WithAttachments } from "components/pages/bot-builder/node-types/message-node/with-attachments";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import {
  selectConversationTags,
  selectOrganizationId
} from "store/modules/workspace/workspace.selectors";

const { Option } = Select;

export const BulkEditConversations = ({
  visible,
  onChangeVisibility,
  onActionCompleted,
  action,
  query
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onActionCompleted: () => any;
  action: BulkConversationsAction;
  query: any;
}) => {
  const [bulkEditForm] = useForm();
  const [isProcessingBulkEdit, setIsProcessingBulkEdit] = useState(false);
  const organizationId = useSelector(selectOrganizationId);

  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  const onComplete = async () => {
    try {
      await bulkEditForm.validateFields();
    } catch (e) {
      message.warning("Please check your input");
      return;
    }
    const { ...formData } = bulkEditForm.getFieldsValue();
    setIsProcessingBulkEdit(true);
    try {
      const res = await SDK.bulkEditConversations({
        ...formData,
        action: action,
        query: query,
        skipSafeCheck: isAdvancedMode
      });
      BadgesController.processBadgeEvent("use-bulk-actions");
      switch (action) {
        case "TRANSFER": {
          message.success("Conversations has been transferred");
          break;
        }
        case "ADD_TAG":
        case "REMOVE_TAG":
        case "ASSIGN_USER":
        case "UNASSIGN_USER":
        case "OPEN_CONVERSATION":
        case "CLOSE_CONVERSATION":
        case "DISMISS_ALERT": {
          message.success("Operation has been executed");
          Modal.info({
            title: "Bulk Action Status",
            content: (
              <div className="flex flex-col">
                <div className="">Successful: {res.successful.length}</div>
                <div className="">Failed: {res.failed.length}</div>
              </div>
            )
          });
          break;
        }
        case "SEND_MESSAGE": {
          message.success("Operation has been executed");
          Modal.info({
            title: "Bulk Send Message Status",
            content: (
              <div className="flex flex-col">
                <div className="">Successful: {res.successful.length}</div>
                <div className="">Failed: {res.failed.length}</div>
              </div>
            )
          });
          break;
        }
      }
      onActionCompleted();
      onChangeVisibility(false);
      setIsProcessingBulkEdit(false);
      bulkEditForm.resetFields();
    } catch (e) {
      console.log("Error while bulk action", e);
      message.error(processServerError(e, "Something went wrong"));
      setIsProcessingBulkEdit(false);
    }
  };

  const title = (() => {
    switch (action) {
      case "TRANSFER": {
        return "Transfer Conversations";
      }
      case "ADD_TAG": {
        return "Add Tags";
      }
      case "REMOVE_TAG": {
        return "Remove Tags";
      }
      case "ASSIGN_USER": {
        return "Assign User";
      }
      case "UNASSIGN_USER": {
        return "Unassign User";
      }
      case "OPEN_CONVERSATION": {
        return "Open Conversations";
      }
      case "CLOSE_CONVERSATION": {
        return "Close Conversations";
      }
      case "DISMISS_ALERT": {
        return "Dismiss Alert";
      }
      case "SEND_MESSAGE": {
        return "Send Message";
      }
    }
  })();

  const conversationTags = useSelector(selectConversationTags);

  const tagGroups = useMemo(() => _.groupBy(conversationTags, "tagGroup"), [
    conversationTags
  ]);

  const tagSelector = {
    mode: "multiple" as const,
    style: { width: "100%" },
    placeholder: "Tag...",
    maxTagCount: "responsive" as const
  };

  return (
    <Modal
      title={
        <ModalTitle
          title={title || ""}
          icon={<i className="ri-pencil-line"></i>}
        />
      }
      open={visible}
      onOk={async () => onComplete()}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText={title}
      okButtonProps={{
        className: "font-bold",
        icon: <i className="ri-check-line"></i>
      }}
      data-click-context="Bulk Edit Conversations Modal"
    >
      <Form layout="vertical" form={bulkEditForm} preserve={true}>
        <div className="text-gray">You are about to perform a bulk action</div>
        {(action === "ADD_TAG" || action === "REMOVE_TAG") && (
          <Form.Item label="Tags" name="tags">
            <Select {...tagSelector}>
              {Object.keys(tagGroups).map(tagGroup => (
                <Select.OptGroup
                  label={tagGroup === "undefined" ? "General" : tagGroup}
                  key={tagGroup}
                >
                  {tagGroups[tagGroup].map(tag => (
                    <Select.Option value={tag.label}>
                      <span>
                        <Badge color={tag.color} dot></Badge> {tag.label}
                      </span>
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Form.Item>
        )}
        {(action === "TRANSFER" ||
          action === "ASSIGN_USER" ||
          action === "UNASSIGN_USER") && (
          <Form.Item name="user" label={"User"} rules={[]}>
            <SingleUserSelector />
          </Form.Item>
        )}
        {action === "SEND_MESSAGE" && (
          <div className="mt-8">
            <Form.Item
              name={["message", "attachments"]}
              valuePropName="initialAttachments"
              trigger={"onAttachmentsChange"}
            >
              <WithAttachments
                id={`BULK_CONVERSATION_EDIT`}
                withoutBorder={true}
                entityType="CONVERSATION_BULK_EDIT"
                entityId={organizationId!}
              >
                <Form.Item
                  name={["message", "text"]}
                  label="Your Message"
                  rules={[
                    { required: true, message: "Please enter your message" }
                  ]}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>
                <div className="text-gray-600 text-center">
                  Drag your files here to upload attachments
                </div>
              </WithAttachments>
            </Form.Item>
          </div>
        )}
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
