import { mdiWebhook } from "@mdi/js";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Modal, Spin } from "antd";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { MDIIcon } from "components/common/mdi-icon";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useEffect } from "react";

export const TestWebhookAppModel = ({
  visible,
  onChangeVisibility,
  connectedAppId,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  connectedAppId: string;
}) => {
  const {
    doAction: testWebhookSubscription,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (connectionAppId: string) =>
        SDK.connectedApps.testWebhook(connectionAppId),
      failureMessage: "Something went wrong",
    }),
    [],
  );

  useEffect(() => {
    testWebhookSubscription(connectedAppId);
  }, [connectedAppId, testWebhookSubscription]);

  return (
    <Modal
      title={
        <ModalTitle
          title={"Test Webhook Subscription"}
          icon={<MDIIcon icon={mdiWebhook} size={"2rem"} />}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Test Webhook Subscription  Modal"
    >
      <Spin spinning={isProcessing} indicator={<LoadingIndicatorWithSpin />}>
        <div style={{ minHeight: 200 }}>
          <div className="font-bold">
            Results :{" "}
            {isProcessing ? "" : response?.success ? "Success" : "Failed"}
          </div>
          <div className="font-bold">URL : {response?.url}</div>
          <div className="font-bold">Status Code : {response?.statusCode}</div>
          <div className="font-bold">Payload</div>
          <div className="text-gray-600">
            <code>{JSON.stringify(response?.payload || {}, null, 2)}</code>
          </div>
        </div>
      </Spin>
      <DarkModeBg />
    </Modal>
  );
};
