import { iMailgunWebhookEvent } from "@sdk/campaigns/mailgun-event-model";
import { useSDKWithRemotePagination } from "@sdk/sdk.hooks";
import { Pagination, Table } from "antd";
import dayjs from "dayjs";
import { sumBy } from "lodash";
import { useMemo } from "react";

export const EmailCampaignLogsViewer = ({
  logType,
  query,
  campaignId
}: {
  logType: string;
  query: any;
  campaignId: string;
}) => {
  const {
    paginationState,
    setPaginationState,
    data: itemsInView,
    setData: setItemsInView,
    isLoading,
    reload: resetAndReloadComments
  } = useSDKWithRemotePagination(
    SDK => (query, options) =>
      SDK.campaigns.queryLogs(campaignId, { query, options }),
    {
      ...query
    },
    [campaignId, query],
    {}
  );

  // Timestamp
  // Event
  // Sender
  // Recipient
  // Delivery Status Message

  const columns = useMemo(() => {
    if (logType === "delivered") {
      return [
        {
          title: "Event",
          key: "event",
          width: 120,
          render: (data, record: iMailgunWebhookEvent, index) =>
            record.event.event
        },
        {
          title: "Timestamp",
          key: "timestamp",
          width: 220,
          render: (data, record: iMailgunWebhookEvent, index) => (
            <div>
              {dayjs(new Date(record.event.timestamp).valueOf()).format(
                "YYYY-MM-DD HH:mm:ss"
              )}
            </div>
          )
        },
        {
          title: "From",
          key: "from",
          width: 160,
          render: (data, record: iMailgunWebhookEvent, index) =>
            (record.event.message.headers as any).from
        },
        {
          title: "Subject",
          key: "subject",
          width: 240,
          render: (data, record: iMailgunWebhookEvent, index) =>
            (record.event.message.headers as any).subject
        },
        {
          title: "To",
          key: "to",
          width: 160,
          render: (data, record: iMailgunWebhookEvent, index) =>
            (record.event.message.headers as any).to
        },
        {
          title: "Recipient",
          key: "recipient",
          width: 160,
          render: (data, record: iMailgunWebhookEvent, index) =>
            record.event.recipient
        },
        {
          title: "Recipient Domain",
          key: "recipient-domain",
          width: 120,
          render: (data, record: iMailgunWebhookEvent, index) =>
            record.event["recipient-domain"]
        },
        {
          title: "Tags",
          key: "tags",
          width: 120,
          render: (data, record: iMailgunWebhookEvent, index) =>
            ((record.event as any).tags || []).join(", ")
        }
      ];
    } else if (logType === "opened") {
      return [
        {
          title: "Event",
          key: "event",
          width: 120,
          render: (data, record: iMailgunWebhookEvent, index) =>
            record.event.event
        },
        {
          title: "Timestamp",
          key: "timestamp",
          width: 220,
          render: (data, record: iMailgunWebhookEvent, index) => (
            <div>
              {dayjs(new Date(record.event.timestamp).valueOf()).format(
                "YYYY-MM-DD HH:mm:ss"
              )}
            </div>
          )
        },
        {
          title: "Recipient",
          key: "recipient",
          width: 160,
          render: (data, record: iMailgunWebhookEvent, index) =>
            record.event.recipient
        },
        {
          title: "Tags",
          key: "tags",
          width: 120,
          render: (data, record: iMailgunWebhookEvent, index) =>
            ((record.event as any).tags || []).join(", ")
        },
        {
          title: "IP",
          key: "ip",
          width: 120,
          render: (data, record: iMailgunWebhookEvent, index) =>
            (record.event as any).ip
        },
        {
          title: "Location",
          key: "location",
          width: 180,
          render: (data, record: iMailgunWebhookEvent, index) =>
            (record.event as any).geolocation
              ? `${(record.event as any).geolocation?.city} ${
                  (record.event as any).geolocation?.country
                }`
              : ""
        },
        {
          title: "Useragent",
          key: "useragent",
          width: 280,
          render: (data, record: iMailgunWebhookEvent, index) =>
            (record.event as any)["client-info"]
              ? `${(record.event as any)["client-info"]?.["client-name"]} ${
                  (record.event as any)["client-info"]?.["user-agent"]
                }`
              : ""
        }
      ];
    } else if (logType === "complained") {
      //
    } else if (logType === "clicked") {
      //
    } else if (logType === "temporary_fail") {
      //
    } else if (logType === "permanent_fail") {
      //
    } else if (logType === "unsubscribed") {
      //
    }

    return [
      {
        title: "Event",
        key: "event",
        width: 120,
        render: (data, record: iMailgunWebhookEvent, index) =>
          record.event.event
      },
      {
        title: "Timestamp",
        key: "timestamp",
        width: 220,
        render: (data, record: iMailgunWebhookEvent, index) => (
          <div>
            {dayjs(new Date(record.event.timestamp).valueOf()).format(
              "YYYY-MM-DD HH:mm:ss"
            )}
          </div>
        )
      },
      {
        title: "From",
        key: "from",
        width: 160,
        render: (data, record: iMailgunWebhookEvent, index) =>
          (record.event.message.headers as any).from
      },
      {
        title: "Subject",
        key: "subject",
        width: 240,
        render: (data, record: iMailgunWebhookEvent, index) =>
          (record.event.message.headers as any).subject
      },
      {
        title: "To",
        key: "to",
        width: 160,
        render: (data, record: iMailgunWebhookEvent, index) =>
          (record.event.message.headers as any).to
      },
      {
        title: "Recipient",
        key: "recipient",
        width: 160,
        render: (data, record: iMailgunWebhookEvent, index) =>
          record.event.recipient
      },
      {
        title: "Recipient Domain",
        key: "recipient-domain",
        width: 120,
        render: (data, record: iMailgunWebhookEvent, index) =>
          record.event["recipient-domain"]
      },
      {
        title: "Tags",
        key: "tags",
        width: 120,
        render: (data, record: iMailgunWebhookEvent, index) =>
          ((record.event as any).tags || []).join(", ")
      },
      {
        title: "IP",
        key: "ip",
        width: 120,
        render: (data, record: iMailgunWebhookEvent, index) =>
          (record.event as any).ip
      },
      {
        title: "Location",
        key: "location",
        width: 180,
        render: (data, record: iMailgunWebhookEvent, index) =>
          (record.event as any).geolocation
            ? `${(record.event as any).geolocation?.city} ${
                (record.event as any).geolocation?.country
              }`
            : ""
      },
      {
        title: "Useragent",
        key: "useragent",
        width: 280,
        render: (data, record: iMailgunWebhookEvent, index) =>
          (record.event as any)["client-info"]
            ? `${(record.event as any)["client-info"]?.["client-name"]} ${
                (record.event as any)["client-info"]?.["user-agent"]
              }`
            : ""
      }
    ];
  }, [logType]);

  const totalWidth = useMemo(() => sumBy(columns, "width"), [columns]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={itemsInView}
        pagination={false}
        loading={isLoading}
        scroll={{ x: totalWidth }}
      />

      <Pagination
        pageSize={paginationState.pageSize}
        current={paginationState.currentPage}
        total={paginationState.totalItems}
        onChange={(currentPage, pageSize) => {
          setPaginationState({
            ...paginationState,
            pageSize: pageSize || 20,
            currentPage
          });
          console.log("currentPage", currentPage);
        }}
      />
    </>
  );
};
