import { Store } from "@reduxjs/toolkit";
import _ from "lodash";
import { QueryConfig } from "store/utils/query-config";
import { iFilterConfigSchema, iFilterOption } from "./filter-config-schema";

export const BuildViewQuery = ({
  searchText,
  searchQueryBuilder,
  selectedFilters,
  defaultQuery,
  schema,
  store,
}: {
  searchText?: string;
  searchQueryBuilder?: (searchTerm: string) => any;
  selectedFilters: iFilterOption[];
  defaultQuery: QueryConfig;
  schema: iFilterConfigSchema;
  store: Store;
}) => {
  let queryObj = {};
  let searchQuery = {}; // Eg: {$or:[]} {$text: {}}
  if (searchText && searchQueryBuilder) {
    searchQuery = searchQueryBuilder(searchText);
  }

  if (selectedFilters && selectedFilters.length > 0) {
    for (const filter of selectedFilters) {
      const filterConfig = _.find(schema.filters, {
        key: filter.key,
      });
      const operator = _.find(filterConfig?.fieldOptions?.operators, {
        id: filter.operator,
      });
      const operatorConfig = filter.operatorConfig;
      if (operator?.queryGenerator) {
        const generatedQuery = operator?.queryGenerator(
          operatorConfig,
          store.getState(),
        );
        Object.assign(queryObj, generatedQuery);
      } else if (operatorConfig) {
        queryObj[filter.key] = operatorConfig.value;
      }
    }
  }
  // if (orOperators.length === 1) {
  //   queryObj["$or"] = orOperators[0];
  // } else if (orOperators.length > 1) {
  //   queryObj["$and"] = orOperators;
  // }
  queryObj = { ...queryObj, ...searchQuery };
  return {
    ...defaultQuery,
    query: queryObj,
  };
};
