import _, { escapeRegExp } from "lodash";
import { buildNameSearchQuery } from "./build-name-search";

export const CompanySearchQueryBuilder = (searchQuery: string) => {
  const query = {
    $or: _.flattenDeep([
      ...buildNameSearchQuery(searchQuery.split(" ")),
      {
        "data.emailDomain": {
          $regex: `${escapeRegExp(searchQuery)}`,
          $options: "i"
        }
      }
    ])
  };
  return query;
};
