import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";

import { DarkModeBg } from "dark-mode-bg";
import { ContactProfileV2Inner } from "./contact-profile-v2-inner";
import "./contact-profile.scss";

export const ContactProfileV2 = ({
  visible,
  onChangeVisibility,
  contactId,
  hideHeader,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  contactId: string;
  hideHeader?: boolean;
}) => {
  return (
    <>
      <Modal
        title={
          <ModalTitle
            title="Customer 360"
            icon={<i className="ri-global-line"></i>}
          />
        }
        open={visible}
        footer={null}
        onCancel={() => {
          onChangeVisibility(false);
        }}
        okText="Save"
        width={960}
        data-click-context="Contacts Profile Modal"
        bodyStyle={{ padding: 0 }}
      >
        <ContactProfileV2Inner
          contactId={contactId}
          hideHeader={hideHeader}
          onDeleteContact={() => onChangeVisibility(false)}
        />
        <DarkModeBg />
      </Modal>
    </>
  );
};
