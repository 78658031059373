import { iEmailConnectionData } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Alert, Spin } from "antd";
import { ExternalAuthConnector } from "components/common/external-auth-connector-button/external-auth-connector-button";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";
const debounce = require("debounce-promise");

export const ConnectGmailToConnection = ({
  connectionId,
  onMailboxRead,
}: {
  connectionId: string;
  onMailboxRead?: () => any;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const { gmailMailboxData } = (connection?.data as iEmailConnectionData) || {};

  const { doAction: authenticateGoogle, isProcessing: isAuthenticating } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (connectionId: string, code: string) =>
          SDK.connections.completeGmailAuth(connectionId, code).then(() => {
            onMailboxRead && onMailboxRead();
          }),
        successMessage: "Authentication has been successful",
        failureMessage: "Something went wrong",
      }),
      [onMailboxRead],
    );

  return (
    <div className="animated fadeInLeftMin">
      <div className="text-gray-700 dark:text-gray-200  mode_transition mb-4">
        ClickConnector provides a direct integration with Google's Gmail
        accounts. Once you authenticate, you'll be able to send and receive
        emails through Click Connector
      </div>

      <div className="text-gray-700 dark:text-gray-200  mode_transition mb-4">
        ClickConnector's use and transfer of information received from Google
        APIs to any other app will adhere to{" "}
        <a
          href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
          target="_blank"
          rel="noreferrer"
        >
          &nbsp;<u>Google API Services User Data Policy</u>
        </a>{" "}
        including the Limited Use requirements
      </div>

      <Alert
        message="We are currently awaiting the completion of Google Team's ClickConnector App Review. As a result, you may encounter a warning when attempting to integrate. If you wish to proceed with the integration despite the warning, you have the option to do so."
        type="info"
        showIcon
        className="mb-4"
      />
      {gmailMailboxData && (
        <>
          <div className="py-8">
            Connected Account : {gmailMailboxData.account}
          </div>
          <div className="flex flex-row justify-center items-center">
            <Spin
              spinning={isAuthenticating}
              indicator={<LoadingIndicatorWithoutSpin />}
              wrapperClassName="flex fill-spinner"
            >
              <ExternalAuthConnector
                type="GMAIL"
                onAuthenticated={({ code }) => {
                  authenticateGoogle(connection.id, code);
                }}
                label="Re-Connect Gmail"
              />
            </Spin>
          </div>
        </>
      )}

      {!gmailMailboxData && (
        <>
          <div className="flex flex-row justify-center items-center">
            <Spin
              spinning={isAuthenticating}
              indicator={<LoadingIndicatorWithoutSpin />}
              wrapperClassName="flex fill-spinner"
            >
              <ExternalAuthConnector
                type="GMAIL"
                onAuthenticated={({ code }) => {
                  authenticateGoogle(connection.id, code);
                }}
                label="Connect Gmail"
              />
            </Spin>
          </div>
        </>
      )}
    </div>
  );
};
