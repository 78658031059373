import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import classnames from "classnames";
import { CSSProperties } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  Droppable,
} from "react-beautiful-dnd";
import "./dnd-item.scss";

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#f4f4f4" : "transparent",
  minHeight: "50px",
});

const getItemStyle = (draggableStyle, isDragging) => ({
  // change background colour if dragging
  background: isDragging ? "#F4F4F4" : "#fff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

// a little function to help us with reordering the result
function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

function move<T>(
  source: T[],
  destination: T[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
) {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: { [droppableId: string]: T[] } = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
}

export const DraggableItem = ({
  key,
  draggableId,
  index,
  children,
  dragHandleContainerStyle,
}: {
  key: string;
  draggableId: string;
  index: number;
  children;
  dragHandleContainerStyle?: CSSProperties;
}) => {
  return (
    <Draggable key={key} draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            // styles we need to apply on draggables
            ...provided.draggableProps.style,
          }}
          className="dnd-item"
        >
          <div className="relative">
            <div
              className={classnames("w-6 flex items-center absolute move-icon")}
              {...provided.dragHandleProps}
              style={{
                left: -30,
                ...{ ...(dragHandleContainerStyle || {}) },
              }}
            >
              <EllipsisOutlined className="text-gray-700 dark:text-gray-200 text-2xl transform rotate-90  mode_transition" />
              <EllipsisOutlined
                className="text-gray-700 dark:text-gray-200 text-2xl transform rotate-90  mode_transition"
                style={{ marginLeft: -17 }}
              />
            </div>
            {children}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export const SimpleDNDList = <T extends { id: string }>({
  list,
  onListChange,
  renderItem,
  dragHandleContainerStyle,
}: {
  list: T[];
  onListChange: (list: T[]) => any;
  renderItem: (item: T, index: number) => JSX.Element;
  dragHandleContainerStyle?: CSSProperties;
}) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items: any = reorder(
      list,
      result.source.index,
      result.destination.index,
    );
    onListChange(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className=""
          >
            {list.map((item, index) => (
              <DraggableItem
                key={item.id}
                draggableId={`item-${item.id}`}
                index={index}
                dragHandleContainerStyle={dragHandleContainerStyle}
              >
                {renderItem(item, index)}
              </DraggableItem>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
