export const DynamicFormsUseCases = [
  {
    icon: "🔖",
    label: "Customer Feedback",
    description:
      "Collect feedback from a customer after they've interacted with you.",
  },
  {
    icon: "📁",
    label: "General",
    description: "General forms",
  },
  {
    icon: "✍️",
    label: "Registration",
    description: "Collect details before joining an event or community",
  },
  {
    icon: "👩‍💻",
    label: "Support",
    description:
      "CCollect the information required to solve a customer's problem",
  },
  {
    icon: "🧲",
    label: "Lead Capture",
    description: "Collect customer requirements and contact information",
  },
  {
    icon: "💼",
    label: "HR",
    description: "Forms related to your team",
  },
];
