import { ReportSchema } from "./backend-report-schema-model";

export const ActivitiesReportsBackendSchema: ReportSchema = {
  filters: {
    organizationId: {
      type: "EQUALITY",
      path: "organizationId"
    },
    contactId: {
      type: "EQUALITY",
      path: "contactId"
    },
    activityType: {
      type: "EQUALITY",
      path: "type"
    },
    createdBy: {
      type: "EQUALITY",
      path: "createdBy"
    },
    tagsIn: {
      type: "IN",
      path: "data.tags"
    },
    tags: {
      type: "ALL",
      path: "data.tags"
    }
  },
  dateFilterTypes: {
    createdTime: "timestamp"
  },
  // Todo: Wherever there is an array, unwind is required
  groupByOptions: {
    activityType: {
      path: "type"
    },
    createdBy: {
      path: "createdBy"
    }
  },
  unwinds: {}
};
