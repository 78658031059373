import { Alert, Button, Form, Input, Popover, Select, Space } from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectChatWidgetByConnectionId } from "store/modules/chat-widgets/chat-widgets.selectors";
import { useQueryWithStore } from "store/store.hooks";

const customTexts = [
  {
    label: "Greeting Text",
    value: "GREETINGS_MESSAGE",
    placeholder: "Hey 👋",
    imagePreview: "/assets/live-chat-text-help/home-screen-preview.png",
  },
  {
    label: "Texts below greetings",
    value: "GUIDE_MESSAGE",
    placeholder: "We usually reply within minutes",
    imagePreview: "/assets/live-chat-text-help/home-screen-preview.png",
  },
  {
    label: "Team Name",
    value: "TEAM_NAME",
    placeholder: "Sales Team",
    imagePreview: "/assets/live-chat-text-help/home-screen-preview.png",
  },
  {
    label: "Team Tag Line",
    value: "SALES_TEAM_TAGLINE",
    placeholder: "We usually reply within minutes",
    // imagePreview: "/assets/live-chat-text-help/home-screen-preview.png",
  },
  {
    label: "Message to send when chat routing starts",
    value: "CHAT_ROUTE_IN_PROGRESS",
    placeholder: "Chat is being routed. An available agent will join shortly",
    imagePreview: "/assets/live-chat-text-help/chat-screen-preview.png",
  },
  {
    label: "Message to send when there is no available users",
    value: "NO_AVAILABLE_AGENT",
    placeholder:
      "Oops, It look's like none of the operators are available now. Please leave your message Our operators will get back to you as soon as possible",
    imagePreview: "/assets/live-chat-text-help/chat-screen-preview.png",
  },
  // New Texts
  {
    label: "Pre Chat Form Title",
    value: "FILL_BELOW_MESSAGE",
    placeholder: "Fill in the below form to get started 👇",
  },
  {
    label: "Magic Assistant Exit Button",
    value: "NOT_HELPFUL",
    placeholder: "Not Helpful?",
  },
];

export const CustomTexts = ({
  connectionId,
}: {
  connectionId: string;
  onSave?: () => any;
}) => {
  const { state: widget, retry: reload } = useQueryWithStore(
    selectChatWidgetByConnectionId(connectionId),
    loadAllChatWidgets(),
    [connectionId],
  );

  return (
    <ConfigurationEditorComponent
      icon={"ri-text"}
      title="Customize Widget Content"
      description="Customize Greeting Messages, Team Name, and other texts/content in chat widget"
      entityId={widget?.id!}
      entityType="WIDGET"
    >
      <Form.Item
        name={["configurations", "locale", "language"]}
        label={<div className="font-bold">Widget Language</div>}
      >
        <Select placeholder="Select Language" size="large" defaultValue={"en"}>
          <Select.Option value="bg">Bulgarian</Select.Option>
          <Select.Option value="cs">Czech</Select.Option>
          <Select.Option value="de">German</Select.Option>
          <Select.Option value="en">English</Select.Option>
          <Select.Option value="es">Spanish</Select.Option>
          <Select.Option value="fr">French</Select.Option>
          <Select.Option value="he">Hebrew</Select.Option>
          <Select.Option value="it">Italian</Select.Option>
          <Select.Option value="nl">Dutch</Select.Option>
          <Select.Option value="pt">Portuguese</Select.Option>
          <Select.Option value="sl">Slovenian</Select.Option>
          <Select.Option value="sv">Swedish</Select.Option>
          <Select.Option value="vi">Vietnamese</Select.Option>
          <Select.Option value="tr">Turkish</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const language = getFieldValue([
            "configurations",
            "locale",
            "language",
          ]);
          if (language && language !== "en") {
            return (
              <div className="mb-4">
                <Alert
                  showIcon
                  message="The language you selected is in beta mode. The phrases are translated using OPEN AI Translator and can be misleading or wrong. Please help us validate the translation."
                  description={
                    <div>
                      <Space>
                        <Button
                          href={`https://widget.clickconnector.app/locales/en/common.json`}
                          target="_blank"
                        >
                          View English Phrases
                        </Button>
                        <Button
                          href={`https://widget.clickconnector.app/locales/${language}/common.json`}
                          target="_blank"
                        >
                          View auto translated phrases
                        </Button>
                      </Space>
                    </div>
                  }
                />
              </div>
            );
          }
          return <></>;
        }}
      </Form.Item>

      {customTexts.map((customText) => (
        <Form.Item
          label={
            <div className="flex flex-row">
              {customText.label}{" "}
              {customText.imagePreview && (
                <Popover
                  content={
                    <div>
                      <img src={customText.imagePreview} alt="" width={500} />
                    </div>
                  }
                  title="Custom Text Configuration"
                  trigger={["hover"]}
                >
                  <i className="ri-question-line cursor-pointer ml-2"></i>
                </Popover>
              )}
            </div>
          }
          name={["configurations", "locale", "custom", customText.value]}
          rules={[]}
        >
          <Input
            size="large"
            placeholder={customText.placeholder}
            suffix={
              customText.imagePreview ? (
                <Popover
                  content={
                    <div>
                      <img src={customText.imagePreview} alt="" width={500} />
                    </div>
                  }
                  title="Custom Text Configuration"
                  trigger={["click"]}
                >
                  <Button
                    type="text"
                    icon={<i className="ri-question-mark"></i>}
                  />
                </Popover>
              ) : undefined
            }
          />
        </Form.Item>
      ))}
    </ConfigurationEditorComponent>
  );
};
