import { Modal } from "antd";
import React from "react";
import AudioPlayer from "react-h5-audio-player";

export const showAudioModal = url => {
  Modal.info({
    title: "",
    icon: "",
    content: (
      <AudioPlayer
        autoPlay
        src={url}
        onPlay={e => console.log("onPlay")}
        // other props here
      />
    ),
    okText: "Close"
  });
};
