import { Tooltip } from "antd";
import { useMemo } from "react";
import { selectConversationQueryWithIds } from "store/modules/conversations/conversations.selectors";
import { useSelectorWithMemoize } from "store/utils/use-selector-with-memoize";

const query = "Unassigned";

// eslint-disable-next-line no-empty-pattern
export const InboxStatusWidget = ({}: {}) => {
  const unassignedInbox = useSelectorWithMemoize(
    () => selectConversationQueryWithIds("Unassigned"!),
    []
  );

  const requiresAttentionInbox = useSelectorWithMemoize(
    () => selectConversationQueryWithIds("Requires Attention"!),
    []
  );

  const isHealthy = useMemo(() => {
    const isUnAssignedHealthy = (unassignedInbox?.list || []).length < 2;
    const isRequiresAttentionHealthy =
      (requiresAttentionInbox?.list || []).length < 5;
    return isUnAssignedHealthy && isRequiresAttentionHealthy;
  }, [requiresAttentionInbox?.list, unassignedInbox?.list]);

  if (isHealthy) {
    return <></>;
  }

  return (
    <div className="p-4">
      <Tooltip title="Make sure that you clear unassigned, awaiting reply and followup inboxes">
        <div className="bg-yellow-200 dark:bg-yellow-400 font-bold border rounded-lg border-orange-700 text-orange-700 flex justify-center items-center p-2">
          Inbox Health: Low
        </div>
      </Tooltip>
    </div>
  );
};
