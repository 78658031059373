import { message } from "antd";

export const uploadImageWithValidation = (
  entityId: string,
  file: File,
  uploadFileFunction: (file: File, entityId: string) => any,
  setSelectedFile,
) => {
  if (!file) {
    return;
  }
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  if (isJpgOrPng && isLt2M) {
    uploadFileFunction(file, entityId);
  } else setSelectedFile(undefined);
};
