import { SocketConnector } from "@sdk/@libs/socket-connector";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider } from "antd";
import {
  AddConnectionLayout,
  AddConnectionModalContainer,
  AddConnectionStepsContainer,
  IntegrationSuccessfulScreen,
  StepContainer
} from "components/modules/connections/add-connection-modal-templates";
import { generateDefaultConnectionConfig } from "components/modules/connections/common-connection-settings/default-connection-config-generator";
import { ConnectionIcons } from "components/modules/connections/helpers/connection-icons";
import { push } from "connected-react-router";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { ConfigureWhatsappPrivateConnection } from "../configure-whatsapp-private-connection/configure-whatsapp-private-connection";

export const AddWhatsappConnectionModal = ({
  visible,
  onChangeVisibility: _onChangeVisibility
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
}) => {
  const dispatch = useDispatch();
  const store = useStore();

  const [currentStep, setCurrentStep] = useState(0);
  const [createdConnection, setCreatedConnection] = useState("");

  const {
    doAction: createInstance,
    isProcessing: isActionProcessing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => val =>
        SDK.connections.create(val).then(d => {
          setCreatedConnection(d.id!);
          setCurrentStep(1);
        }),
      successMessage: "Integration has been created",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const onChangeVisibility = (visibility: boolean) => {
    _onChangeVisibility(visibility);
    setCurrentStep(0);
  };

  const onCreateInstance = useCallback(async () => {
    const phoneNumber = "";
    const label = `WA: ${phoneNumber}`;

    const configData = generateDefaultConnectionConfig(
      "WHATS_APP_PRIVATE",
      store
    );

    createInstance({
      type: "WHATS_APP_PRIVATE",
      label
    });
  }, [createInstance, store]);

  useEffect(() => {
    const listener = (data: {
      name: string;
      data: { connectionId: string } & { type: string };
    }) => {
      console.log("Received Special Socket Event", data);
      if (
        data.name === "WHATSAPP_CONNECTION_STATUS" &&
        data.data.connectionId === createdConnection
      ) {
        if (data.data.type === "AUTHENTICATED") {
          setCurrentStep(2);
        }
      }
    };
    SocketConnector.socket?.on("SPECIAL_EVENTS", listener);
    return () => {
      SocketConnector.socket?.off("SPECIAL_EVENTS", listener);
    };
  }, [createdConnection]);

  return (
    <>
      <AddConnectionModalContainer
        title="Connect Whatsapp"
        icon={<i className="ri-whatsapp-line"></i>}
        isVisible={visible}
        onChangeVisibility={onChangeVisibility}
      >
        <AddConnectionLayout
          currentStep={currentStep}
          totalSteps={2}
          stepsWithSidebars={[0, 1]}
          topBar={
            <AddConnectionStepsContainer
              currentStep={currentStep}
              steps={[
                {
                  label: "Begin",
                  icon: <i className="ri-flag-line"></i>
                },
                {
                  label: "Scan QR Code",
                  icon: <i className="ri-file-shield-line"></i>
                },
                {
                  label: "Done",
                  icon: <i className="ri-check-line"></i>
                }
              ]}
            />
          }
          sidebarImage={
            <div className="text-10xl opacity-50">
              <i className={ConnectionIcons.WHATS_APP_PRIVATE}></i>
            </div>
          }
        >
          <StepContainer stepNumber={0} currentStep={currentStep}>
            <div className="button-container my-4 flex flex-row justify-center items-center">
              <Button
                type="primary"
                icon={<i className="ri-arrow-right-circle-fill"></i>}
                onClick={onCreateInstance}
                size="large"
              >
                Create Whatsapp Connector
              </Button>
            </div>
            <Divider />
            <div className="description text-gray-600 dark:text-gray-400 mb-4 mode_transition">
              You are about to connect a new whatsapp to Click Connector via
              private APIs (unofficial). This method spins up a windows instance
              where whatsapp web will be launched via a browser.
            </div>

            <div className="text-gray-600 dark:text-gray-400 mb-6 mode_transition">
              You will authenticate this instance via QR code just like you do
              in Whatsapp Web. Click Connector then connects to this instance
              and handle chats and messages via it.
            </div>
            <div className="text-gray-600 dark:text-gray-400 mb-6 mode_transition">
              * Use at your own risk. If you spam or get reported your Whatsapp
              account will be banned.
            </div>
          </StepContainer>

          <StepContainer stepNumber={1} currentStep={currentStep}>
            <div className="description text-gray-600 dark:text-gray-400 mb-4 mode_transition text-center">
              Scan QR code and complete the integration
            </div>
            <ConfigureWhatsappPrivateConnection
              connectionId={createdConnection}
              hideHeader={true}
              showOnlyQR={true}
            />

            <div className="flex flex-row justify-end items-center mt-4 gap-4">
              <Button
                onClick={() => {
                  setCurrentStep(2);
                }}
                size="large"
                icon={<i className="ri-arrow-right-circle-fill"></i>}
              >
                Skip
              </Button>
              <Button
                type="primary"
                icon={<i className="ri-check-line"></i>}
                onClick={() => setCurrentStep(2)}
                size="large"
              >
                I have scanned
              </Button>
            </div>
          </StepContainer>

          <StepContainer stepNumber={2} currentStep={currentStep}>
            <IntegrationSuccessfulScreen
              onManageConnection={() => {
                dispatch(
                  push(`/configurations/connections/${createdConnection}/`)
                );
              }}
              onHideWizard={() => onChangeVisibility(false)}
              subTitle="Now you will be able to reply to Whatsapp messages from Click Connector"
            ></IntegrationSuccessfulScreen>
          </StepContainer>
        </AddConnectionLayout>
      </AddConnectionModalContainer>
    </>
  );
};
