import { SDK } from "@sdk";

import { iIndexedWebPage } from "@sdk/indexed-web-pages/indexed-web-pages-model";
import { Store } from "redux";
import { QueryConfig } from "store/utils/query-config";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { indexedWebPagesDefaultQueryConfig } from "./indexed-web-pages-default-query";
import { IndexedWebPagesSlice } from "./indexed-web-pages.slice";

export const loadAllIndexedWebPages = () => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  await loadIndexedWebPagesQuery(indexedWebPagesDefaultQueryConfig, "all")(
    store,
    forceReload
  );
};

export const loadQueryIndexedWebPages = (
  queryConfig: QueryConfig,
  queryAlias?: string
) => async (store: Store<iStore>, forceReload?: boolean) => {
  queryAlias = queryAlias || JSON.stringify(queryConfig);
  await loadIndexedWebPagesQuery(queryConfig, queryAlias)(store, forceReload);
};

export const {
  loadEntityQueries: loadIndexedWebPagesQuery,
  loadEntityById: loadIndexedWebPageById,
  loadMoreEntityQueries: loadMoreIndexedWebPagesQueries
} = CreateHelpersForEntity<iStore, iIndexedWebPage>({
  sliceName: "indexedWebPages",
  slice: IndexedWebPagesSlice,
  isPaginatedQueries: true,
  fetchPageSize: 50,
  loadEntityQueryProvider: query => SDK.indexedWebPages.query(query),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.indexedWebPages.getById(entityId)
});
