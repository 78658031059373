import React, { Suspense } from "react";
import { SuspenseLoadingIndicator } from "../loading-indicator/loading-indicator";
const Lottie = React.lazy(() => import("react-lottie-player"));
export const LottiePlayer = ({
  animation,
  ...props
}: {
  animation;
  play?: boolean;
  loop?: boolean;
}) => {
  return (
    <Suspense fallback={<SuspenseLoadingIndicator />}>
      <Lottie animationData={animation} {...props} />
    </Suspense>
  );
};
