import { iAppFeatureRequest } from "@sdk/app-feature-requests/app-feature-requests-model";

export const dummyFeatureRequests: iAppFeatureRequest[] = [
  {
    id: "403.22550707867765",
    submittedBy: {
      userId: "demo01-xxxxx",
      organizationId: "demo01",
      name: "Thomas",
      avatar: "",
    },
    submittedAt: 1693804894476,
    votes: [],
    comments: [],
    title: "Custom fields",
    shortDescription: "We need to be able to add custom fields to the forms.",
    status: "In Build",
    attachments: [],
    body: "",
    tags: [],
    teamComment: "",
  },
  {
    id: "625.7842911379685",
    submittedBy: {
      userId: "demo01-xxxxx",
      organizationId: "demo01",
      name: "Thomas",
      avatar: "",
    },
    submittedAt: 1693804894476,
    votes: [],
    comments: [],
    title: "Pied Piper integration",
    shortDescription:
      "We would love to have an integration with the world's best compression software!",
    status: "Released",
    attachments: [],
    body: "",
    tags: [],
    teamComment: "",
  },
  {
    id: "11.349650996659744",
    submittedBy: {
      userId: "demo01-xxxxx",
      organizationId: "demo01",
      name: "Thomas",
      avatar: "",
    },
    submittedAt: 1693804894476,
    votes: [],
    comments: [],
    title: "Social media shares",
    shortDescription:
      "Would be cool if our users can share the content from the app.",
    status: "Released",
    attachments: [],
    body: "",
    tags: [],
    teamComment: "",
  },
  {
    id: "189.95792533301858",
    submittedBy: {
      userId: "demo01-xxxxx",
      organizationId: "demo01",
      name: "Thomas",
      avatar: "",
    },
    submittedAt: 1693804894476,
    votes: [],
    comments: [],
    title: "Asana integration",
    shortDescription: "Please make an integration with Asana.",
    status: "Selected for Build",
    attachments: [],
    body: "",
    tags: [],
    teamComment: "",
  },
  {
    id: "908.0419364393904",
    submittedBy: {
      userId: "demo01-xxxxx",
      organizationId: "demo01",
      name: "Thomas",
      avatar: "",
    },
    submittedAt: 1693804894476,
    votes: [],
    comments: [],
    title: "Github integration",
    shortDescription: "Please make an integration with Github.",
    status: "Selected for Build",
    attachments: [],
    body: "",
    tags: [],
    teamComment: "",
  },
  {
    id: "703.5109311352421",
    submittedBy: {
      userId: "demo01-xxxxx",
      organizationId: "demo01",
      name: "Thomas",
      avatar: "",
    },
    submittedAt: 1693804894476,
    votes: [],
    comments: [],
    title: "Add GIFs",
    shortDescription: "Would be cool to add GIFs to new posts.",
    status: "In Review",
    attachments: [],
    body: "",
    tags: [],
    teamComment: "",
  },
  {
    id: "514.7104112693681",
    submittedBy: {
      userId: "demo01-xxxxx",
      organizationId: "demo01",
      name: "Thomas",
      avatar: "",
    },
    submittedAt: 1693804894476,
    votes: [],
    comments: [],
    title: "MacBook native app",
    shortDescription: "Would love to work with a MacBook app.",
    status: "In Review",
    attachments: [],
    body: "",
    tags: [],
    teamComment: "",
  },
  {
    id: "885.4054014458584",
    submittedBy: {
      userId: "demo01-xxxxx",
      organizationId: "demo01",
      name: "Thomas",
      avatar: "",
    },
    submittedAt: 1693804894476,
    votes: [],
    comments: [],
    title: "Markdown",
    shortDescription: "Can you support Markdown for content posts?",
    status: "Released",
    attachments: [],
    body: "",
    tags: [],
    teamComment: "",
  },
  {
    id: "424.2667024690014",
    submittedBy: {
      userId: "demo01-xxxxx",
      organizationId: "demo01",
      name: "Thomas",
      avatar: "",
    },
    submittedAt: 1693804894476,
    votes: [],
    comments: [],
    title: "Chrome plugin",
    shortDescription:
      "Would be very cool to use a Chrome plugin for our content import.",
    status: "In Review",
    attachments: [],
    body: "",
    tags: [],
    teamComment: "",
  },
  {
    id: "883.6050536608894",
    submittedBy: {
      userId: "demo01-xxxxx",
      organizationId: "demo01",
      name: "Thomas",
      avatar: "",
    },
    submittedAt: 1693804894477,
    votes: [],
    comments: [],
    title: "Android app",
    shortDescription: "An Android app for our users.",
    status: "Selected for Build",
    attachments: [],
    body: "",
    tags: [],
    teamComment: "",
  },
  {
    id: "741.742396403181",
    submittedBy: {
      userId: "demo01-xxxxx",
      organizationId: "demo01",
      name: "Thomas",
      avatar: "",
    },
    submittedAt: 1693804894477,
    votes: [],
    comments: [],
    title: "IOs app",
    shortDescription: "We want to create an iOs app for our users.",
    status: "In Review",
    attachments: [],
    body: "",
    tags: [],
    teamComment: "",
  },
  {
    id: "347.8044303351271",
    submittedBy: {
      userId: "demo01-xxxxx",
      organizationId: "demo01",
      name: "Thomas",
      avatar: "",
    },
    submittedAt: 1693804894477,
    votes: [],
    comments: [],
    title: "User roles",
    shortDescription:
      "We want to create more roles for users and admins. Which ones do you need?\n\nWe're thinking of:\n- Developer account\n- Read...",
    status: "In Review",
    attachments: [],
    body: "",
    tags: [],
    teamComment: "",
  },
  {
    id: "761.8105597449887",
    submittedBy: {
      userId: "demo01-xxxxx",
      organizationId: "demo01",
      name: "Thomas",
      avatar: "",
    },
    submittedAt: 1693804894477,
    votes: [],
    comments: [],
    title: "Dark mode",
    shortDescription:
      "We want to create a dark mode version of our application.",
    status: "Selected for Build",
    attachments: [],
    body: "",
    tags: [],
    teamComment: "",
  },
];

export const DummyFeatureRequest: iAppFeatureRequest = {
  id: "xxxx1",
  submittedBy: {
    userId: "demo01-xxxxx",
    organizationId: "demo01",
    name: "Thomas",
    avatar: "",
  },
  submittedAt: Date.now(),
  votes: [],
  comments: [],
  title: "Custom fields",
  shortDescription: "",
  status: "In Review",
  attachments: [],
  body: "We need to be able to add custom fields to the forms.",
  tags: [],
  teamComment: "",
};
