import { Button, Form, Input, Select, Tag, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import { memo, useCallback } from "react";
import { Node, useReactFlow } from "react-flow-renderer";
import { uuidv4 } from "utils/generate-uuid";
import { DragHandler } from "../../components/drag-handler/drag-handler";
import { DragPreventer } from "../../components/drag-preventer/drag-preventer";
import { AvailableNodes, iConditionNode } from "../../data-model";
import { useSetNode } from "../../utils/use-set-node";
import { ChatFlowHandle } from "../chat-flow-handle";
import "./condition-node.scss";

export const MessageIfConditionBlock = ({
  data,
  onChange,
}: {
  data: iConditionNode;
  onChange: (data: iConditionNode) => any;
}) => {
  const [form] = useForm();

  return (
    <div className="flex flex-col items-center">
      <Tag className="mb-8 font-bold mr-0" color={"blue"}>
        If
      </Tag>

      <Form
        layout="vertical"
        form={form}
        initialValues={data}
        onValuesChange={() => {
          onChange(form.getFieldsValue());
        }}
        hideRequiredMark={true}
      >
        <DragPreventer>
          <Form.Item label="" name={["conditionTarget"]}>
            <Select style={{ minWidth: 250 }} bordered={true}>
              <Select.OptGroup label="Reply">
                <Select.Option value="REPLY_TEXT">
                  Replied Message
                </Select.Option>
                <Select.Option value="INTENT">
                  Message Intent (AI)
                </Select.Option>
              </Select.OptGroup>
              <Select.OptGroup label="Contact">
                <Select.Option value="CONTACT_FIRST_NAME">
                  Contact First Name
                </Select.Option>
                <Select.Option value="CONTACT_LAST_NAME">
                  Contact Last Name
                </Select.Option>
                <Select.Option value="CONTACT_EMAIL">
                  Contact Email
                </Select.Option>
                <Select.Option value="CONTACT_PHONE">
                  Contact Phone
                </Select.Option>
              </Select.OptGroup>
              <Select.OptGroup label="Conversation">
                <Select.Option value="CONVERSATION_CONNECTION_TYPE">
                  Connection Type
                </Select.Option>
              </Select.OptGroup>
              <Select.OptGroup label="Context">
                <Select.Option value="CONTEXT">Context Property</Select.Option>
              </Select.OptGroup>
            </Select>
          </Form.Item>
        </DragPreventer>

        <DragPreventer>
          <Form.Item shouldUpdate className="mb-0 empty-form" noStyle>
            {({ getFieldValue }) => {
              const selectedOption = getFieldValue(["conditionTarget"]);
              if (selectedOption === "CONTEXT") {
                return (
                  <Form.Item
                    name={["conditionTargetConfig", "propertyName"]}
                    label={<div className="">Property Name</div>}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a property name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                );
              }
              return <div></div>;
            }}
          </Form.Item>
        </DragPreventer>

        {/* Condition */}

        {/* <div className="flex flex-col items-center">
          <Tag className="mb-8">Condition</Tag>
        </div> */}

        <DragPreventer>
          <Form.Item label="" name={["condition"]}>
            <Select style={{ minWidth: 250 }}>
              <Select.Option value="CONTAINS">Contains</Select.Option>
              <Select.Option value="DOES_NOT_EXIST">
                Does not exists
              </Select.Option>
              <Select.Option value="EXIST">Exists</Select.Option>
            </Select>
          </Form.Item>
        </DragPreventer>

        <DragPreventer>
          <Form.Item shouldUpdate className="mb-0 empty-form">
            {({ getFieldValue }) => {
              const selectedOption = getFieldValue(["condition"]);
              if (selectedOption === "CONTAINS") {
                return (
                  <Form.Item
                    name={["conditionConfig", "input"]}
                    label={<div className="">Value</div>}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a string",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                );
              }
              return <div></div>;
            }}
          </Form.Item>
        </DragPreventer>
      </Form>
      {/* <Tag className="mb-4 font-bold mr-0" color={"blue"}>
        Then
      </Tag> */}
    </div>
  );
};

const defaultConditionNode: iConditionNode = {
  conditionTarget: "REPLY_TEXT",
  condition: "CONTAINS",
  conditionConfig: {
    input: "",
  },
};

export const ConditionNode = memo(
  ({ data, id }: { id: string; data: iConditionNode }) => {
    const setNode = useSetNode<iConditionNode>(id);

    const {
      getNode,
      setNodes,
      setEdges,
      getNodes,
      getEdges,
      getEdge,
    } = useReactFlow<AvailableNodes, any>();

    const cloneNode = useCallback(() => {
      const node = getNode(id) as Node<iConditionNode>;
      const currentNodePosition = node.position;
      const newNodeId = uuidv4();
      const order = 0;
      const newNode = {
        ..._.pick(_.cloneDeep(node), ["type", "data"]),
        id: newNodeId,
        position: {
          x: currentNodePosition.x + 320 + 100,
          y: currentNodePosition.y + (order || 0) * 100,
        },
      };
      setNodes([...getNodes(), newNode]);
    }, [getNode, getNodes, id, setNodes]);

    const deleteNode = useCallback(() => {
      setNodes(_.filter([...getNodes()], (node) => node.id !== id));
    }, [getNodes, id, setNodes]);

    return (
      <div className="p-4 condition-node-container node-container group border-2 dark:border-black hover:border-blue-600">
        <div className="hidden group-hover:show">
          <div
            className="hover-action-box flex flex-row gap-4 justify-center items-center absolute w-full left-0 pb-4"
            style={{ top: -40 }}
          >
            <Tooltip title="Delete Block">
              <Button
                type="link"
                onClick={(e) => {
                  deleteNode();
                }}
                icon={<i className="ri-delete-bin-5-line"></i>}
              />
            </Tooltip>
            <Tooltip title="Clone Block">
              <Button
                type="link"
                onClick={(e) => {
                  cloneNode();
                }}
                icon={<i className="ri-file-copy-line"></i>}
              />
            </Tooltip>
          </div>
        </div>

        <div className="drag-handler-container flex flex-row justify-center items-center">
          <DragHandler />
        </div>
        <ChatFlowHandle handleId="INPUT" nodeId={id} type="INPUT" />
        <ChatFlowHandle handleId="YES" nodeId={id} type="YES" />
        <ChatFlowHandle handleId="NO" nodeId={id} type="NO" />
        <div>
          {/* Testing */}
          <MessageIfConditionBlock
            data={data || defaultConditionNode}
            onChange={setNode}
          />
        </div>
      </div>
    );
  }
);
