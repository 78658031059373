import { message } from "antd";

export const uploadFileWithValidation = (
  entityId: string,
  file: File,
  uploadFileFunction: (file: File, entityId: string) => any,
  setSelectedFile,
  acceptedFileTypes?: string[],
) => {
  if (!file) {
    return;
  }
  const isValidFileType = acceptedFileTypes
    ? acceptedFileTypes?.includes(file.type)
    : true;

  if (!isValidFileType) {
    message.error(`Invalid File Type`);
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("File must smaller than 2MB!");
  }
  if (isValidFileType && isLt2M) {
    uploadFileFunction(file, entityId);
  } else setSelectedFile(undefined);
};
