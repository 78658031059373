import { SDK } from "@sdk";
import { RcFile } from "antd/lib/upload";
import classNames from "classnames";
import { FileUploadSection } from "components/common/reply-input/reply-input";
import _ from "lodash";
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import { useDropzone } from "react-dropzone";
import { uuidv4 } from "utils/generate-uuid";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { justWait } from "utils/just-wait";
import "./with-attachments.scss";

// Todo: Need to refactor this - a lot of unwanted logics, states
export const WithAttachments = forwardRef(
  (
    {
      children,
      initialAttachments,
      onAttachmentsChange,
      id,
      withoutBorder,
      isUploading: _isUploading,
      setIsUploading: _setIsUploading,
      entityType,
      entityId
    }: {
      children;
      initialAttachments?: string[];
      onAttachmentsChange?: (attachments: string[]) => any;
      id: string;
      withoutBorder?: boolean;
      setIsUploading?: Dispatch<SetStateAction<boolean>>;
      isUploading?: boolean;
      entityType: string;
      entityId: string;
    },
    ref
  ) => {
    const [uploadingFiles, setUploadingFiles] = useState([] as string[]);
    const [isUploadingLocal, setUploadingLocal] = useState(false);
    const isUploading =
      typeof _isUploading !== "undefined" ? _isUploading : isUploadingLocal;
    const setIsUploading = _setIsUploading || setUploadingLocal;

    const initialFileListHash = useMemo(
      () => (initialAttachments || [])?.join("-"),
      [initialAttachments]
    );

    const initialFileList = useMemo(
      () =>
        (initialAttachments || [])
          .filter(e => typeof e === "string")
          .map(url => ({
            uid: url || uuidv4(),
            name: url,
            status: "done", // options：uploading, done, error, removed. Intercepted file by beforeUpload don't have status field.
            response: '{"status": "success"}', // response from server
            linkProps: '{"download": "image"}', // additional html props of file link
            xhr: "XMLHttpRequest{ ... }", // XMLHttpRequest Header
            url: url,
            thumbUrl: url
          })),
      [initialAttachments]
    );

    const [fileList, setFileList] = useState<any[]>(initialFileList);

    useEffectWhen(
      () => {
        setFileList(initialFileList);
      },
      [initialFileList, initialFileListHash],
      [initialFileListHash]
    );

    useEffectWhen(
      () => {
        if (onAttachmentsChange) {
          onAttachmentsChange(
            fileList
              .filter(item => item.status === "done")
              .map(item => item.url)
          );
        }
      },
      [fileList, onAttachmentsChange],
      [fileList]
    );

    const uploadFile = useCallback(
      async (file: RcFile) => {
        // console.log("file", file);
        await justWait(3000);
        const fileRecord = await SDK.uploadFile(file, {
          type: entityType,
          entityId: entityId!
        });
        return fileRecord.url;
      },
      [entityId, entityType]
    );

    const onDrop = useCallback(
      async (acceptedFiles: File[]) => {
        // console.log("acceptedFiles", acceptedFiles);
        setIsUploading(true);
        const ids = acceptedFiles.map(
          (file, index) => `${Date.now().toString()}-${index}`
        );
        setUploadingFiles(state => [...state, ...ids]);
        try {
          const newFileListRecords: any[] = [];
          for (const file of acceptedFiles) {
            const url = await uploadFile(file as any);
            newFileListRecords.push({
              uid: new Date().getTime().toString(),
              name: file.name,
              status: "done", // options：uploading, done, error, removed. Intercepted file by beforeUpload don't have status field.
              response: '{"status": "success"}', // response from server
              linkProps: '{"download": "image"}', // additional html props of file link
              xhr: "XMLHttpRequest{ ... }", // XMLHttpRequest Header
              url: url,
              thumbUrl: url
            });
          }
          setUploadingFiles(state => {
            // console.log("state", state, ids);
            return _.without(state, ...ids);
          });
          setIsUploading(false);
          setFileList(state => {
            // console.log("state", state, newFileListRecords);
            return [...state, ...newFileListRecords];
          });
        } catch (e) {
          setUploadingFiles(state => _.without(state, ...ids));
          setIsUploading(false);
          // No need to throw the error from here as the error is handled fully
          // throw e;
        }
      },
      [setIsUploading, uploadFile]
    );

    useImperativeHandle(
      ref,
      () => ({
        onDrop
      }),
      [onDrop]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      noClick: true
    });

    const _attachmentUploader = useCallback(
      async file => {
        if (!uploadFile) {
          throw {
            message: "No File Uploader Provided"
          };
        }
        try {
          const url = await uploadFile(file);
          return url;
        } catch (e) {
          const a = "11";
          throw e;
        }
      },
      [uploadFile]
    );

    const uploadFileX = useCallback(
      async options => {
        console.log("options", options);
        const { onSuccess, onError, file, onProgress } = options;
        if (!_attachmentUploader) {
          console.log("Error: No File Uploader Provided");
          throw {
            message: "No File Uploader Provided"
          };
        }
        try {
          setIsUploading(true);
          const url = await _attachmentUploader(file);
          const fileListRecord = {
            uid: new Date().getTime().toString(),
            name: file.name,
            status: "done", // options：uploading, done, error, removed. Intercepted file by beforeUpload don't have status field.
            response: '{"status": "success"}', // response from server
            linkProps: '{"download": "image"}', // additional html props of file link
            xhr: "XMLHttpRequest{ ... }", // XMLHttpRequest Header
            url: url,
            thumbUrl: url
          };
          setFileList(state => [...state, fileListRecord]);
          setIsUploading(false);

          // Persist Attachments - START
          const attachmentsToPersist = [...fileList, fileListRecord]
            .filter(item => item.status === "done")
            .map(item => {
              delete item.originFileObj;
              return {
                uid: item.uid,
                name: item.name,
                status: item.status, // options：uploading, done, error, removed. Intercepted file by beforeUpload don't have status field.
                response: '{"status": "success"}', // response from server
                linkProps: '{"download": "image"}', // additional html props of file link
                xhr: "XMLHttpRequest{ ... }", // XMLHttpRequest Header
                url: item.url,
                thumbUrl: item.thumbUrl
              };
            });
          // Persist Attachments -END
          onSuccess("Ok");
        } catch (err) {
          console.log("Error while uploading file", err);
          onError({ err });
          setIsUploading(false);
        }
      },
      [_attachmentUploader, fileList, setIsUploading]
    );

    return (
      <div
        className={classNames("attachments-outer flex flex-col relative", {
          "border-gray-200 dark:border-gray-800 border border-solid rounded-lg mode_transition": !withoutBorder
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        {isDragActive && (
          <div className="drop-indicator">Drop the files here ...</div>
        )}

        <div>{children}</div>
        <input
          type="file"
          id={`uploadField_${id}`}
          className="hidden"
          multiple
          onChange={e => {
            const selectedFiles = (document.getElementById(
              `uploadField_${id}`
            )! as any).files;
            onDrop(Array.from(selectedFiles));
            (document.getElementById(`uploadField_${id}`)! as any).value = "";
          }}
        />
        <FileUploadSection
          fileList={fileList}
          isUploading={isUploading}
          uploadingFiles={uploadingFiles}
          setFileList={setFileList}
          onUpload={uploadFileX}
        />
      </div>
    );
  }
);
