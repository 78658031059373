import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Form, Input, message, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useStore } from "react-redux";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { debouncedMailboxEmailAvailability } from "../../helpers/mailbox-availabilty-checker";
import { useEmailTester } from "../../helpers/use-email-tester";
import { ConfigureNewCustomEmailDomainForm } from "../forms/configure-new-custom-email-domain-form";
import { VerifyDNSGuide } from "../verify-dns-guide/verify-dns-guide";
const debounce = require("debounce-promise");

export const ConfigureInbuiltMailServer = ({
  connectionId,
  onMailboxRead
}: {
  connectionId: string;
  onMailboxRead?: () => any;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId
  );

  const customHostname =
    connection?.data.customDomain?.email.split("@")[1] || "";

  const [customDomainForm] = useForm();

  useEffect(() => {
    if (connection) {
      customDomainForm.setFieldsValue({
        email: connection.data.customDomain?.email.split("@")[0] || ""
      });
    }
    if (connection?.data?.customDomain?.data?.isMailBoxReady) {
      onMailboxRead && onMailboxRead();
    }
  }, [connection, customDomainForm, onMailboxRead]);

  const store = useStore();

  const { doAction: _editCustomDomain, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: SDK => (data, created?: boolean) =>
        SDK.connections.editCustomDomain(connection.id!, data).then(d => {
          message.info(
            created
              ? "Custom Domain Record has been recreated"
              : "Custom Email has been modified"
          );
          return loadConnectionById(connection.id!)()(store, true);
        }),
      failureMessage: "Something went wrong"
    }),
    [connection.id, store]
  );

  // Change it to action
  const editCustomDomain = (
    data:
      | {
          domain: string;
          email: string;
          senderName: string;
        }
      | {},
    created?: boolean
  ) => {
    _editCustomDomain(data, created);
  };

  const {
    testIncomingEmail,
    isTestingIncomingEmail,
    testOutgoingEmail,
    isTestingOutgoingEmail
  } = useEmailTester(connectionId!);

  return (
    <div className="bold-form-labels">
      {/* Description */}
      <div className="text-gray-700 dark:text-gray-200 mb-2  mode_transition">
        If you have a domain and if you don't have an email server, you can use
        our inbuilt email server and create your own custom email addresses. It
        will just take 10 minutes!
      </div>
      {!connection?.data.customDomain && (
        <div>
          <ConfigureNewCustomEmailDomainForm formInstance={customDomainForm} />
          <div className="flex flex-row justify-end items-center p-2 mt-4">
            <Button
              type="primary"
              loading={isProcessing}
              onClick={() => {
                const formValues = customDomainForm.getFieldsValue();
                editCustomDomain(
                  {
                    email: formValues.email,
                    domain: formValues.email.split("@")[1]
                  },
                  true
                );
              }}
              icon={<i className="ri-arrow-right-circle-fill"></i>}
            >
              Continue
            </Button>
          </div>
        </div>
      )}
      {connection?.data.customDomain && (
        <div>
          <div className="status-box mb-4">
            {connection.data.customDomain.data.isMailBoxReady && (
              <div className="p-4 w-full font-bold flex flex-row justify-center items-center bg-green-200 text-green-600 rounded-lg">
                <i className="ri-check-line pr-2"></i> Verified
              </div>
            )}
            {!connection.data.customDomain.data.isMailBoxReady && (
              <div className="rounded-lg bg-red-300 font-bold p-4 w-full flex flex-row justify-center items-center text-red-600">
                <i className="ri-error-warning-fill pr-2"></i> Not Verified
              </div>
            )}
          </div>
          {connection.data.customDomain.data.isMailBoxReady && (
            <div className="flex flex-row justify-end items-center">
              <Space>
                <Button
                  type="link"
                  icon={<i className="ri-flask-line"></i>}
                  onClick={testIncomingEmail}
                  loading={isTestingIncomingEmail}
                >
                  Test Incoming Email
                </Button>
                <Button
                  type="link"
                  icon={<i className="ri-flask-line"></i>}
                  onClick={testOutgoingEmail}
                  loading={isTestingOutgoingEmail}
                >
                  Test Outgoing Email
                </Button>
              </Space>
            </div>
          )}

          <div className="domain-entered">
            <Form layout="vertical" form={customDomainForm} initialValues={{}}>
              {/* Email */}
              <Form.Item
                label="Custom Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please select a valid email"
                  },
                  ({ getFieldValue }) => ({
                    validator: async (rule, value) =>
                      await debouncedMailboxEmailAvailability(
                        `${value}@${customHostname}`,
                        connectionId
                      )
                  })
                ]}
              >
                <Input
                  placeholder="support@abc.com"
                  suffix={`@${customHostname}`}
                  size="large"
                />
              </Form.Item>
            </Form>
            <div className="flex flex-row justify-end items-center p-2 mt-4">
              <Space>
                <Button
                  type="primary"
                  className="text-red-600 bg-red-200 border-none"
                  onClick={() => {
                    const formValues = customDomainForm.getFieldsValue();
                    editCustomDomain({});
                  }}
                  icon={<i className="ri-delete-bin-line"></i>}
                >
                  Remove
                </Button>
                <Button
                  type="primary"
                  loading={isProcessing}
                  onClick={() => {
                    const formValues = customDomainForm.getFieldsValue();
                    editCustomDomain({
                      email: `${formValues.email}@${customHostname}`,
                      senderName: formValues.senderName,
                      domain: customHostname
                    });
                  }}
                  icon={<i className="ri-save-2-line"></i>}
                >
                  Update
                </Button>
              </Space>
            </div>
          </div>

          {!connection.data.customDomain.data.isMailBoxReady && (
            <VerifyDNSGuide connectionId={connectionId} />
          )}
        </div>
      )}
    </div>
  );
};
