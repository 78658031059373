const edjsHTML = require("editorjs-html");

function linkBlockParser(block: {
  type: string;
  data: {
    link: string;
    meta: {
      url: string;
      domain: string;
      title: string;
      description: string;
      image: {
        url: string;
      };
    };
  };
}) {
  return `<a class="link-block" target="_blank" href="${block.data.link}">
  <div class="title">${block.data?.meta?.title}</div>
  <div class="description">${block.data?.meta?.description}</div>
  <div class="link">${block.data?.meta?.url}</div>
</a>`;
}

function rawHtmlBlockParser(block: {
  type: string;
  data: {
    html: string;
  };
}) {
  return `<div class="raw-block">
 ${block.data.html}
</div>`;
}

function simpleImageParser(block: {
  type: string;
  data: {
    url: string;
    caption: string;
    withBorder: boolean;
    withBackground: boolean;
    stretched: boolean;
  };
}) {
  return `<div class="image-block">
<img src='${block.data.url}' style="background:${
    block.data.withBackground ? "#f4f4f4" : "none"
  };border:${block.data.withBorder ? "2px solid #c4c4c4" : "none"};width:${
    block.data.stretched ? "100%" : "auto"
  }">
 ${block.data.caption ? `<caption>${block.data.caption}</caption>` : ""} 
</div>`;
}

function delimiterBlockParser(block) {
  return `<div class="delimiter-block"></div>`;
}

function tableBlockParser(block: {
  id: string;
  type: string;
  data: {
    withHeadings: boolean;
    content: string[][];
  };
}) {
  return `<div class="table-block">
<table data-table-theme="default zebra">
${
  block.data.withHeadings
    ? `<thead>
<tr>
${block.data.content[0].map(col => `<th>${col}</th>`).join("")}
<tr>
</thead>`
    : `<tbody>
<tr>
${block.data.content[0].map(col => `<td>${col}</td>`).join("")}
</tr>
`
}

${block.data.content
  .slice(1)
  .map(
    row => `
<tr>
${row.map(col => `<td>${col}</td>`).join("")}
</tr>
`
  )
  .join("")}

</tbody>
</table
</div>`;
}

function warningBlockParser(block: {
  id: string;
  type: string;
  data: {
    title: string;
    message: string;
  };
}) {
  return `<div class="warning-block">
  <div class="title">⚠️ ${block.data?.title}</div>
  <div class="message">${block.data?.message}</div>
</div>`;
}

function checkListBlockParser(block: {
  id: string;
  type: string;
  data: {
    items: {
      text: string;
      checked: boolean;
    }[];
  };
}) {
  return `<div class="check-box-block">
  ${block.data.items
    .map(
      item => `
  <div class="cdx-checklist__item ${
    item.checked ? `cdx-checklist__item--checked` : ``
  }"><span class="cdx-checklist__item-checkbox"></span><div class="cdx-checklist__item-text" contenteditable="false">${
        item.text
      }</div></div>
  `
    )
    .join("")}
</div>`;
}

export const edjsParser = edjsHTML({
  raw: rawHtmlBlockParser,
  rawTool: rawHtmlBlockParser,
  linkTool: linkBlockParser,
  delimiter: delimiterBlockParser,
  table: tableBlockParser,
  warning: warningBlockParser,
  checklist: checkListBlockParser,
  simpleImage: simpleImageParser
});
