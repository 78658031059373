import { useModalPanels } from "@libs/modal-panels/modal-panels";
import { Button, Divider, Tag } from "antd";
import { OpenAiAssistant } from "modal-registry";
import { Node } from "react-flow-renderer";
import { uuidv4 } from "utils/generate-uuid";
import { AvailableNodes } from "../data-model";
import "./bot-builder-side-bar.scss";

export const BotBuilderSideBar = () => {
  const onDragStart = (event, node: Partial<Node<AvailableNodes>>) => {
    const newNode: Partial<Node> = {
      id: uuidv4(),
      // dragHandle: ".cc-drag-handle",
      ...node,
    };
    event.dataTransfer.setData(
      "application/reactflow",
      JSON.stringify(newNode),
    );
    event.dataTransfer.effectAllowed = "move";
  };

  const { triggerTempModal } = useModalPanels();

  return (
    <aside className="bot-builder-sidebar overflow-y-auto overflow-x-hidden p-4">
      <div className="flex flex-row justify-end items-center mb-4">
        <Button
          type="text"
          icon={
            <img
              src="/assets/integrations/openai.png"
              alt="Chat GPT"
              style={{ width: "1rem" }}
              className="dark:invert"
            />
          }
          onClick={() => triggerTempModal(OpenAiAssistant, {})}
        >
          Open Writing Assistant
        </Button>
      </div>
      <div className="description">
        You can drag these blocks to the bot canvas on the right.
      </div>

      <div className="font-bold mb-2">Basic Blocks</div>
      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "MESSAGE_NODE",
            data: {
              text: "Change your Text",
              quickButtons: [],
            },
          })
        }
        draggable
      >
        <i className="ri-message-2-line"></i>
        Send Message
      </div>

      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "CONDITION_NODE",
            data: {
              conditionTarget: "CONTEXT",
              condition: "CONTAINS",
            },
          })
        }
        draggable
      >
        <i className="ri-git-merge-line"></i>
        Create Conditional Branch
      </div>

      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "ACTION_NODE",
            data: {
              action: "ADD_CONTACT_TAG",
              data: {},
            },
          })
        }
        draggable
      >
        <i className="ri-auction-line"></i>
        Perform Action
      </div>

      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "GPT_ASSISTANT_NODE",
            data: {
              inputType: "GPT_ASSISTANT",
              text: "Change your Text",
              quickButtons: [],
            },
          })
        }
        draggable
      >
        <i className="ri-magic-line"></i>
        Magic Assistant
      </div>

      <Divider>
        <Tag>Popular Chatbot Blocks</Tag>
      </Divider>

      <div className="font-bold mb-2">Data Collectors</div>
      <div className="text-gray-600 text-sm mb-2">
        Data collectors allow you to collect data from your customers which are
        automatically saved in Click Connector CRM
      </div>

      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "MESSAGE_NODE",
            data: {
              text: "What is your name?",
              quickButtons: [],
              persistence: {
                target: "CONTACT_NAME",
              },
            },
          })
        }
        draggable
      >
        <i className="ri-user-fill"></i>
        Name
      </div>
      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "MESSAGE_NODE",
            data: {
              text: "What is your email?",
              quickButtons: [],
              validation: {
                type: "EMAIL",
                invalidMessage:
                  "Oops! It does not look like an email. Please retry",
              },
              persistence: {
                target: "CONTACT_EMAIL",
              },
            },
          })
        }
        draggable
      >
        <i className="ri-mail-fill"></i>
        Email
      </div>
      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "MESSAGE_NODE",
            data: {
              text: "What is your email? (professional/work only)",
              quickButtons: [],
              validation: {
                type: "WORK_EMAIL",
                invalidMessage:
                  "Oops! It does not look like your professional email. Please retry",
              },
              persistence: {
                target: "CONTACT_EMAIL",
              },
            },
          })
        }
        draggable
      >
        <i className="ri-mail-fill"></i>
        Work Email
      </div>
      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "MESSAGE_NODE",
            data: {
              text: "What is your mobile number?",
              quickButtons: [],
              validation: {
                type: "MOBILE_NUMBER",
                invalidMessage:
                  "Oops! It does not look like an mobile number. Please retry",
              },
              persistence: {
                target: "CONTACT_MOBILE_NUMBER",
              },
            },
          })
        }
        draggable
      >
        <i className="ri-smartphone-fill"></i>
        Mobile
      </div>
      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "MESSAGE_NODE",
            data: {
              text: "Your message here",
              quickButtons: [],
              validation: {
                type: "DATE",
                invalidMessage:
                  "Oops! It does not look like a valid date. Please retry",
              },
              persistence: {
                target: "CONTEXT",
                key: "date",
              },
            },
          })
        }
        draggable
      >
        <i className="ri-calendar-fill"></i>
        Date
      </div>
      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "MESSAGE_NODE",
            data: {
              text: "Your message here",
              quickButtons: [],
              validation: {
                type: "NUMBER",
                invalidMessage:
                  "Oops! It does not look like a number. Please retry",
              },
              persistence: {
                target: "CONTEXT",
                key: "number",
              },
            },
          })
        }
        draggable
      >
        <i className="ri-number-1"></i>
        Number
      </div>
      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "MESSAGE_NODE",
            data: {
              text: "Your question here",
              quickButtons: [],
              validation: {
                type: "NONE",
              },
              persistence: {
                target: "CONTEXT",
                key: "answer",
              },
            },
          })
        }
        draggable
      >
        <i className="ri-text"></i>
        Simple Text Answer
      </div>

      <div className="font-bold my-2 mt-8">Actions</div>
      <div className="text-gray-600 text-sm mb-2">
        Action Blocks allow you to perform an action in Click Connector
        Workspace automatically
      </div>
      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "ACTION_NODE",
            data: {
              action: "END_CONVERSATION",
              data: {},
            },
          })
        }
        draggable
      >
        <i className="ri-stop-circle-line"></i>
        End Conversations
      </div>
      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "ACTION_NODE",
            data: {
              action: "ROUTE_TO_HUMAN",
              data: {
                routingMethod: "ASSIGN",
                routeOnlyToOnlineUsers: true,
                userGroup: "",
              },
            },
          })
        }
        draggable
      >
        <i className="ri-user-received-fill"></i>
        Route to Human
      </div>
      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "ACTION_NODE",
            data: {
              action: "ADD_CONVERSATION_TAG",
              data: {},
            },
          })
        }
        draggable
      >
        <i className="ri-price-tag-3-line"></i>
        Tag Conversation
      </div>

      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "ACTION_NODE",
            data: {
              action: "ADD_CONVERSATION_TAG",
              data: {},
            },
          })
        }
        draggable
      >
        <i className="ri-auction-line"></i>
        More Actions
      </div>

      <div className="font-bold my-2 mt-8">Conditional Blocks</div>
      <div className="text-gray-600 text-sm mb-2">
        Conditional Blocks allow you to branch out the chatbot flow based on
        specific condition
      </div>
      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "CONDITION_NODE",
            data: {
              conditionTarget: "CONTACT_EMAIL",
              condition: "EXIST",
            },
          })
        }
        draggable
      >
        <i className="ri-mail-fill"></i>
        If email is captured
      </div>
      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "CONDITION_NODE",
            data: {
              conditionTarget: "CONTACT_FIRST_NAME",
              condition: "EXIST",
            },
          })
        }
        draggable
      >
        <i className="ri-user-fill"></i>
        If first name is captured
      </div>
      <div
        className="dndnode"
        onDragStart={(event) =>
          onDragStart(event, {
            type: "CONDITION_NODE",
            data: {
              conditionTarget: "REPLY_TEXT",
              condition: "CONTAINS",
            },
          })
        }
        draggable
      >
        <i className="ri-message-2-line"></i>
        If message contains a keyword
      </div>

      <div
        className="p-4 rounded-lg border border-gray-300 dark:border-gray-700 flex flex-col cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800 mt-8"
        onClick={() => {
          (window as any).ccWidget?.onWidgetActivated(() => {
            (window as any).ccWidget?.triggerCampaign?.({
              message: "How can we improve Chatbots for you?",
            });
            (window as any).ccWidget.setWidgetVisibility(true);
          });
        }}
      >
        <div className="flex flex-row items-center">
          <div className="text-2xl">💡</div>
          <div className="text-xl font-bold ml-3">Request a Feature</div>
        </div>

        <div className="text-gray-600">
          Share your feedback with us to improve Click Connector
        </div>
      </div>
    </aside>
  );
};
